import PropTypes from "prop-types";
import NoResults from "../../../components/noResults/NoResults";
import NoSearchResults from "../../../components/noSearchResults";
import { ReactComponent as NoAssetResult } from "../../../images/group.svg";

function VideosNoResults(props) {
  const descriptionMarkup = (
    <span>
      Looks like you don't have any assets yet.
      <br />
      Drag and drop files here to upload.
    </span>
  );

  return props.filtered ? (
    <NoSearchResults />
  ) : (
    <NoResults title="Drag & Drop Files Here" description={descriptionMarkup} icon={<NoAssetResult />}>
      {props.createAssetButton}
    </NoResults>
  );
}

VideosNoResults.propTypes = {
  filtered: PropTypes.bool,
  createAssetButton: PropTypes.object,
};

export default VideosNoResults;
