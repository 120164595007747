import axios, { CancelTokenSource } from "axios";
import { Button } from "components/buttons/button/Button";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Dispatch, bindActionCreators } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { WithRouterProps, withRouter } from "../../../../../adapters/withRouter/withRouter";
import { DuplicateLinkButton } from "../../../../../components/buttons/linkButtons";
import { Tooltip } from "../../../../../components/common/tooltip";
import ModalTypes from "../../../../../components/modal/ModalTypes";
import RevertConfirmationModalTs from "../../../../../components/modal/RevertConfirmationModalTs";
import Segments from "../../../../../components/navigation/segments/Segments";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import { PublishedStatusTypes, RolePermissions, RouteNames } from "../../../../../enums";
import { IModalManager, ISegment } from "../../../../../interfaces";
import ModalManager from "../../../../../utils/ModalManager";
import WizardStepsManager from "../../../../../utils/WizardStepsManager";
import Restricted from "../../../../Application/Restricted";
import { RootState } from "../../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import Configure from "../EmailDetails/Configure/Configure";
import Content from "../EmailDetails/EmailContent/Content";
import EmailPerformance from "../EmailDetails/Performance/EmailPerformance";
import * as emailAssignedUsersActions from "../state/actions/emailAssignedUsersActions";
import * as emailDetailsActions from "../state/actions/emailDetailsActions";
import * as emailEntityStateActions from "../state/actions/emailEntityStateActions";
import * as emailPublishInfoActions from "../state/actions/emailPublishInfoActions";
import * as emailsOverviewActions from "../state/actions/emailsOverviewActions";
import EmailDetailsSubheaderContainer from "./EmailDetailsSubheaderContainer";
import SendEmailHandlerContainer from "./SendEmailHandlerContainer";
import { EmailDetailsProps, EmailDetailsState, EmailDetailsSteps } from "./types";
import {
  resetAppliedFilter,
  resetPagination,
  resetSortingColumnName,
  resetSortingDirection,
} from "../state/slices/emailFilterSlice";
import { setTerm } from "../state/slices/emailSearchSlice";
import { FeatureFlags } from "featureFlags";
import AssociatedPacks from "features/Licensing/Packs/AssociatedPacks/AssociatedPacks";
import { AddPacksButton } from "features/Licensing/Packs/AssociatedPacks/AddPacksButton";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { noop } from "lodash";

import "../EmailDetails/emailDetails.scss";
import { LDProps } from "../../../../LDProps";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

export type EmailDetailsPropsAll = EmailDetailsProps & PropsFromRedux & WithRouterProps & LDProps;

type EnumDictionary<T extends number, U> = {
  [K in T]: U;
};

export class EmailDetails extends Component<EmailDetailsPropsAll, EmailDetailsState> {
  private readonly stepsManager = new WizardStepsManager();
  private readonly sendModalManager: IModalManager;
  private readonly revertModalManager: IModalManager;
  private showPacksTab: boolean;
  private emailCancelTokenSource: CancelTokenSource;

  private readonly pages: EnumDictionary<EmailDetailsSteps, ISegment>;

  constructor(props: EmailDetailsPropsAll) {
    super(props);
    this.showPacksTab = !!this.props.flags?.[FeatureFlags.AssociatedPacks];

    this.pages = {
      [EmailDetailsSteps.Performance]: {
        to: "",
        label: "Performance",
        onClick: () => this.stepsManager.goToPage(EmailDetailsSteps.Performance),
        init: () => {
          this.setState({ activeStepIndex: EmailDetailsSteps.Performance });
        },
      },
      [EmailDetailsSteps.Configuration]: {
        to: "configuration",
        label: "Configure",
        onClick: () => this.stepsManager.goToPage(EmailDetailsSteps.Configuration),
        init: () => {
          this.setState({ activeStepIndex: EmailDetailsSteps.Configuration });
        },
      },
      [EmailDetailsSteps.Content]: {
        to: "content",
        label: "Content",
        onClick: () => this.stepsManager.goToPage(EmailDetailsSteps.Content),
        init: () => {
          this.setState({ activeStepIndex: EmailDetailsSteps.Content });
          if (!this.props.isTemplateLoaded) {
            this.props.detailsActions.getEmailTemplate(this.props.emailId, true);
          }
        },
      },
      [EmailDetailsSteps.AssociatedPacks]: {
        to: "packs",
        label: "Associated Packs",
        onClick: () => this.stepsManager.goToPage(EmailDetailsSteps.AssociatedPacks),
        init: () => {
          this.setState({ activeStepIndex: EmailDetailsSteps.AssociatedPacks });
        },
      },
    };

    this.state = {
      isValid: true,
      isDirty: false,
      isReverting: false,
      activeStepIndex: EmailDetailsSteps.Performance,
      packsModalShown: false,
    };
    this.stepsManager.subscribeOnActiveIndexChanged((_: any, activeStepIndex: EmailDetailsSteps) => {
      this.pages[activeStepIndex].init?.();
    });

    this.sendModalManager = new ModalManager(ModalTypes.SendEmail, this.publishAndSendEmail);
    this.revertModalManager = new ModalManager(
      ModalTypes.RevertEmail,
      () => {
        this.handleRevert();
        this.setIsReverting(true);
      },
      false,
      this.props.currentUserId,
    );
    this.emailCancelTokenSource = axios.CancelToken.source();
  }

  componentDidMount() {
    this.props.detailsActions.getEmail(this.props.emailId, this.emailCancelTokenSource);
    this.props.detailsActions.getEmailTemplate(this.props.emailId);
    RtnEventsEmitter.subscribe([rtnEvents.EmailPublishSuccess, rtnEvents.EmailRevertSuccess], this.onPublishedEvent);
    RtnEventsEmitter.subscribe([rtnEvents.EmailDuplicationSuccess], this.onDuplicationEmail);
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    this.sendModalManager.dispose();
    this.revertModalManager.dispose();
    this.props.detailsActions.clearEmail();
    this.props.entityStateActions.resetEmailEntityState();
    this.props.emailAssignedActions.reset();
    RtnEventsEmitter.unsubscribe([rtnEvents.EmailPublishSuccess, rtnEvents.EmailRevertSuccess], this.onPublishedEvent);
    RtnEventsEmitter.unsubscribe([rtnEvents.EmailDuplicationSuccess], this.onDuplicationEmail);
    this.emailCancelTokenSource && this.emailCancelTokenSource.cancel();

    if (!window.location.pathname.includes(`/${RouteNames.contentCommunications}`)) this.props.resetSearchFilters();
  }

  onPublishedEvent = () => {
    const { emailId } = this.props;
    this.props.detailsActions.clearEmail();
    this.props.detailsActions.getEmail(emailId);
    this.props.detailsActions.getEmailTemplate(emailId, false);
  };

  handleClose = () => {
    this.props.navigate(`/${RouteNames.contentCommunications}`);
  };

  handleEdit = () => {
    this.props.entityStateActions.fetchDraftEmailEntity(this.props.emailId);
  };

  handlePublish = () => {
    this.props.entityStateActions.publishDraftEmailEntity(this.props.emailId);
  };

  setIsReverting = (isReverting: boolean) => {
    this.setState({ isReverting });
  };

  handleRevert = () => {
    this.props.entityStateActions.revertEmailEntityToPublished(this.props.emailId);
  };

  publishAndSendEmail = () => {
    this.props.entityStateActions.publishDraftEmailEntity(this.props.email.id, true);
  };

  setValidationState = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  };

  onCreateSendClick = () => {
    this.props.navigate("send-sessions/create", { replace: true });
  };

  renderAddSendSessionButton = (isDisabled: boolean = false) => {
    return (
      <RestrictedByTooltip hasPermission={!isDisabled}>
        <Button primary fluid className="control create-send" disabled={isDisabled} onClick={this.onCreateSendClick}>
          <span className="title">{"Send Emails"}</span>
        </Button>
      </RestrictedByTooltip>
    );
  };

  renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton disabled={this.props.email.isDraft || this.props.email.isPurchased} hasPermission={hasAnyPermission} onClick={() => this.setState({ packsModalShown: true })} />
  );

  onDuplicateLinkButtonClick = (id: number) => {
    this.props.entityStateActions.duplicateEmail(id);
    this.props.emailAssignedActions.reset();
  };

  onDuplicationEmail = (payload: any) => {
    const duplicateId = payload.id.toString();
    const currentTab = this.pages[this.state.activeStepIndex];

    this.props.navigate(`/${RouteNames.contentCommunications}/emails/${duplicateId}/${currentTab.to}`);
  };

  renderActionButtons = (id: number, canBeDuplicated: boolean) => {
    return [
      <Tooltip
        key="email-details-duplicate-link-button"
        content={"The creator of this asset has disabled the duplicate option."}
        showAlways={!canBeDuplicated}
        position={"left center"}
        target={
          <DuplicateLinkButton isDisabled={!canBeDuplicated} onClick={() => this.onDuplicateLinkButtonClick(id)} />
        }
      />,
    ];
  };

  onIsDirtyChanged = (isDirty: boolean) => this.setState({ isDirty });

  back = () => {
    if (!this.stepsManager.goBack()) {
      return `/${RouteNames.contentCommunications}`;
    }
  };

  render() {
    const { email, isEmailUpdateInProgress, userPermissions } = this.props;
    const { isValid, isDirty } = this.state;
    const canBePublished = !isEmailUpdateInProgress && !isDirty;
    const isPacksTab = window.location.pathname.endsWith("packs");

    return (
      <div className="nested-content edit-email-details scrollable-content">
        <Restricted
          permissions={[RolePermissions.CommunicationsCreate]}
          renderContent={(hasPermission) => (
            <DetailsHeader
              title={email.title}
              titleForGA="Email Details"
              backButton={this.back}
              defaultURL={`/${RouteNames.contentCommunications}`}
              canBeEdited={!email.isDraft && !email.isPurchased}
              publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!email.isDraft)}
              invalidFormDetails={!isValid}
              isRevertVisible={email.hasBeenPublished}
              canBePublished={canBePublished}
              crudPermission={hasPermission}
              entityStateActions={{
                onPublish: this.handlePublish,
                onRevert: this.revertModalManager.execute,
                onClose: this.handleClose,
                onEdit: this.handleEdit,
              }}
              actionButtons={this.renderActionButtons(
                email.isPurchased ? this.props.emailId : email.id,
                email.canBeDuplicated,
              )}
            >
              {window.location.pathname.endsWith(`/${RouteNames.contentCommunications}/emails/${this.props.emailId}`) &&
                this.renderAddSendSessionButton(
                  email.isDraft || !userPermissions.includes(RolePermissions.CommunicationsManage),
                )}
              {isPacksTab && (
                <Restricted permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]} permissionPredicate={permissionPredicateForPacks} renderContent={(hasPermission) => this.renderAddPackButton(hasPermission)} />
              )}
              <AddAssetsToPackModal
                showModal={this.state.packsModalShown}
                onClose={() => this.setState({ packsModalShown: false })}
                selectedItemIds={[this.props.emailId]}
                contentType="Email"
                onComplete={noop}
              />
            </DetailsHeader>
          )}
        />
        <RevertConfirmationModalTs modalManager={this.revertModalManager} />
        <Dimmer active={this.props.isLoading} inverted>
          <Loader />
        </Dimmer>
        <EmailDetailsSubheaderContainer
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!email.isDraft)}
          isUpdateInProgress={email.isDraft}
          lastModifiedDateTime={email.dateModified}
        >
          <Segments to={`/${RouteNames.contentCommunications}/emails/${this.props.emailId}`}>
            <Segments.Segment {...this.pages[EmailDetailsSteps.Performance]} />
            <Segments.Segment {...this.pages[EmailDetailsSteps.Configuration]} />
            <Segments.Segment {...this.pages[EmailDetailsSteps.Content]} />
            {this.showPacksTab && <Segments.Segment {...this.pages[EmailDetailsSteps.AssociatedPacks]} />}
          </Segments>
        </EmailDetailsSubheaderContainer>
        <Restricted
          permissions={[RolePermissions.CommunicationsCreate]}
          renderContent={(hasPermission) => (
            <div className="scrollable-content">
              <Routes>
                <Route
                  path={this.pages[EmailDetailsSteps.Configuration].to}
                  element={
                    <div className="edit-form">
                      <Configure
                        entityId={this.props.emailId}
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, EmailDetailsSteps.Configuration)
                        }
                        onIsValidChange={this.setValidationState}
                        disabled={!hasPermission || !email.isDraft}
                        disablePreventTransitionPrompt={true}
                        onIsDirtyChanged={this.onIsDirtyChanged}
                      />
                    </div>
                  }
                />
                <Route
                  path={this.pages[EmailDetailsSteps.Performance].to}
                  element={
                    <EmailPerformance
                      acceptHandlers={(handlers) =>
                        this.stepsManager.acceptHandlers(handlers, EmailDetailsSteps.Performance)
                      }
                      emailId={this.props.emailId}
                      emailTitle={email.title}
                    />
                  }
                />
                <Route
                  path={this.pages[EmailDetailsSteps.Content].to}
                  element={
                    <div className="edit-form edit-email-content">
                      <Content
                        entityId={this.props.emailId}
                        acceptHandlers={(handlers) =>
                          this.stepsManager.acceptHandlers(handlers, EmailDetailsSteps.Content)
                        }
                        onIsValidChange={this.setValidationState}
                        disabled={!hasPermission || !email.isDraft}
                        disablePreventTransitionPrompt={true}
                        onIsDirtyChanged={this.onIsDirtyChanged}
                        emailCreating={false}
                        isParentReverting={this.state.isReverting}
                        onParentReverting={this.setIsReverting}
                      />
                    </div>
                  }
                />
                {this.showPacksTab && (
                  <Route
                    path="packs"
                    element={
                      <AssociatedPacks
                        acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, EmailDetailsSteps.AssociatedPacks)}
                        contentType={"Email"}
                        contentId={this.props.emailId}
                        canAddPacks={!email.isDraft && !email.isPurchased}
                        permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
                        permissionPredicate={permissionPredicateForPacks}
                      />
                    }
                  />
                )}
                <Route path="*" element={<Navigate to="../" replace />} />
              </Routes>
            </div>
          )}
        />
        {email.isDraft && <SendEmailHandlerContainer modalManager={this.sendModalManager} />}
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  email: state.library.emails.emailDetailsReducer.email,
  currentUserId: state.userProfile.id,
  isLoading: state.library.emails.emailDetailsReducer.isLoading,
  isEmailUpdateInProgress: state.library.emails.emailEntityStateReducer.isEntityCommandInProgress,
  isEmailLoaded: state.library.emails.emailDetailsReducer.isEmailLoaded,
  isTemplateLoaded: state.library.emails.emailDetailsReducer.isTemplateLoaded,
  hasAddedUsers: state.library.emails.emailAssignedUsersReducer.assignedUsers.addedUsersCount > 0,
  publishInfo: state.library.emails.emailPublishInfoReducer.publishInfo,
  userPermissions: state.userProfile.permissions,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  detailsActions: bindActionCreators(emailDetailsActions, dispatch),
  entityStateActions: bindActionCreators(emailEntityStateActions, dispatch),
  emailAssignedActions: bindActionCreators(emailAssignedUsersActions, dispatch),
  emailPublishInfo: bindActionCreators(emailPublishInfoActions, dispatch),
  emailsOverviewActions: bindActionCreators(emailsOverviewActions, dispatch),
  resetSearchFilters: () => {
    emailsOverviewActions.resetEmails();
    dispatch(setTerm(""));
    dispatch(resetPagination());
    dispatch(resetSortingColumnName());
    dispatch(resetSortingDirection());
    dispatch(resetAppliedFilter());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withLDConsumer()(EmailDetails)));
