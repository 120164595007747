import {
  chartColorScale,
  clicksColor,
  emailReportColorScale,
  fourthColor,
  getBarDomain,
  opensColor,
  roundToTwoDigits,
  sendsColor,
  lineChartFactory,
} from "features/Library/Common/utils/performanceUtils";
import {
  GoalCards,
  GoalCardsSharepointFileShares,
  GoalCardsSharepointFileSyncs,
  GoalCardsSharepointFileViews,
  GoalCardsSharepointPageVisits,
  GoalCardsTeamsMeetings,
  GoalCardsTeamsMessages,
  GoalLineChart,
  GoalTotalSharepointFileShares,
  GoalTotalSharepointFileSyncs,
  GoalTotalSharepointFileViews,
  GoalTotalSharepointPageVisits,
  GoalTotalTeamsMeetings,
  GoalTotalTeamsMessages,
  GoalTotals,
} from "../../types/performance";
import { HorizontalBarProps, LinechartProps, getDateFormatByCount } from "components/charts";
import { Props } from "components/charts/ChartWrapper/ChartWrapper";
import { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { isEmpty } from "lodash";
import { ValidFlowGoalHeader } from "../slices/flowPerformanceSlice";

type LineChartInfo = {
  chartWrapperProps: Props;
  lineProps: LinechartProps;
};

const orgChartTooltipFormatter = (flowAverage: number, orgAverage: number) =>
  `Flow Average: ${flowAverage.toLocaleString()}\nOrg. Average: ${orgAverage.toLocaleString()}`;
const lineTooltipFormatter = (_x: unknown, y: any, _i: number, d: any) => orgChartTooltipFormatter(y, d);
const cardNumberFormat = (stat: number | string) => (stat ?? 0).toLocaleString();

const emptyLineInfo: LineChartInfo = { chartWrapperProps: { titles: [] }, lineProps: { xData: [], yData: [] } };

export const getGoalLineChart = (
  prepackedLineData: GoalLineChart | undefined,
  flowHeader: ValidFlowGoalHeader,
): LineChartInfo => {
  switch (flowHeader) {
    case "Teams Meetings":
      return getTeamsMeetingsLineChart(prepackedLineData);
    case "Teams Messages":
      return getTeamsMessagesLineChart(prepackedLineData);
    case "Teams Calls":
      return getTeamsCallsLineChart(prepackedLineData);
    case "OneDrive Files Viewed":
      return getOnedriveFilesViewedLineChart(prepackedLineData);
    case "OneDrive Files Shared":
      return getOnedriveFilesSharedLineChart(prepackedLineData);
    case "OneDrive Files Synced":
      return getOnedriveFilesSyncedLineChart(prepackedLineData);
    case "SharePoint Files Synced":
      return getSharepointFilesSyncedLine(prepackedLineData);
    case "SharePoint Files Viewed":
      return getSharepointFilesViewedLine(prepackedLineData);
    case "SharePoint Page Visits":
      return getSharepointPageVisitsLine(prepackedLineData);
    case "SharePoint Shared Files":
      return getSharepointSharedFilesLine(prepackedLineData);
    default:
      return emptyLineInfo;
  }
};

// Engagement
export const getGoalEngagementChart = (value: GoalTotals, flowGoalInfo: any): HorizontalBarProps => {
  // Teams meetings
  if ("AdHocMeetingsAttendedCountFlow" in value) {
    return getTeamsMeetingsEngagementChart(value);
  }
  // Teams messages
  else if ("PostMessagesFlow" in value) {
    return getTeamsMessagesEngagementChart(value);
  } else if ("CallCountFlow" in value) {
    const data = [
      {
        id: "1",
        value: value.CallCountFlow,
        category: "Calls",
        fillColor: sendsColor,
      },
    ];

    return {
      data,
      domain: getBarDomain(data),
      tooltipFormatter: () => {
        return orgChartTooltipFormatter(value.CallCountFlow, value.CallCountOrg);
      },
    };
  } else if ("SharedExternallyFileCountFlow" in value) {
    if (flowGoalInfo.applicationLabel === "SharePoint") {
      return getSharepointSharedFilesEngagement(value);
    }
    const data = [
      {
        id: "1",
        value: value.SharedInternallyFileCountFlow,
        category: "Internal",
        fillColor: sendsColor,
      },
      {
        id: "2",
        value: value.SharedExternallyFileCountFlow,
        category: "External",
        fillColor: clicksColor,
      },
    ];

    return {
      data,
      domain: getBarDomain(data),
      tooltipFormatter: (d) => {
        switch (d.id) {
          case "1":
            return orgChartTooltipFormatter(value.SharedInternallyFileCountFlow, value.SharedInternallyFileCountOrg);
          case "2":
          default:
            return orgChartTooltipFormatter(value.SharedExternallyFileCountFlow, value.SharedExternallyFileCountOrg);
        }
      },
    };
    // OneDrive Files viewed | Sharepoint Files viewed
  } else if ("ViewedOrEditedFileCountFlow" in value) {
    if (flowGoalInfo?.applicationLabel === "SharePoint") {
      return getSharepointFilesViewedEngagement(value);
    }
    const data = [
      {
        id: "1",
        value: value.ViewedOrEditedFileCountFlow,
        category: "Files Viewed or Edited",
        fillColor: sendsColor,
      },
    ];

    return {
      data,
      domain: getBarDomain(data),
      tooltipFormatter: () =>
        orgChartTooltipFormatter(value.ViewedOrEditedFileCountFlow, value.ViewedOrEditedFileCountOrg),
    };
  }
  // OneDrive Syncs | Sharepoint Syncs
  else if ("SyncedFileCountFlow" in value) {
    if (flowGoalInfo?.applicationLabel === "SharePoint") {
      return getSharepointFilesSyncedEngagement(value);
    }
    const data = [
      {
        id: "1",
        value: value.SyncedFileCountFlow,
        category: "Files synced",
        fillColor: sendsColor,
      },
    ];

    return {
      data,
      domain: getBarDomain(data),
      tooltipFormatter: () => orgChartTooltipFormatter(value.SyncedFileCountFlow, value.SyncedFileCountOrg),
    };
  } else if ("VisitedPageCountFlow" in value) {
    return getSharepointPageVisitsEngagement(value);
  }

  return { data: [] };
};

export const getGoalCards = (value: GoalCards, flowGoalInfo: any): CardReportingItem[] => {
  // Teams Meetings
  if ("AvgMeetingOrganized" in value) {
    return getTeamsMeetingsCards(value);
  }
  // Teams messages
  else if ("AvgChats" in value) {
    return getTeamsMessagesCards(value);
    // Teams calls
  } else if ("AvgCallDuration" in value) {
    return [
      {
        stat: cardNumberFormat(value.TotalCallCount),
        statDescription: "Total Calls",
      },
      {
        stat: cardNumberFormat(roundToTwoDigits(value.AvgCalls)),
        statDescription: "Avg. Calls/Person",
      },
      {
        stat: cardNumberFormat(roundToTwoDigits(value.AvgCallDuration)),
        statDescription: "Avg. Call Duration",
      },
    ];
  }
  // OneDrive shared | Sharepoint shared
  else if ("AvgExternalShares" in value) {
    if (flowGoalInfo?.applicationLabel === "SharePoint") {
      return getSharepointSharedFilesCards(value);
    }
    // OneDrive shared
    return [
      {
        stat: cardNumberFormat(value.TotalSharedFileCount),
        statDescription: "Total Files Shared",
      },
      {
        stat: cardNumberFormat(roundToTwoDigits(value.AvgInternalShares)),
        statDescription: "Avg. Internal Shares/Person",
      },
      {
        stat: cardNumberFormat(roundToTwoDigits(value.AvgExternalShares)),
        statDescription: "Avg. External Shares/Person",
      },
    ];
  }
  // OneDrive files viewed or Sharepoint files viewed
  else if ("AvgViewedOrEditedFileCount" in value) {
    if (flowGoalInfo?.applicationLabel === "SharePoint") {
      return getSharepointFilesViewedCards(value);
    }
    // OneDrive viewed
    return [
      { stat: cardNumberFormat(value.ViewedOrEditedFileCount), statDescription: "Total Files Viewed or Edited" },
      {
        stat: cardNumberFormat(roundToTwoDigits(value.AvgViewedOrEditedFileCount)),
        statDescription: "Avg. Files Viewed or Edited/Person",
      },
    ];
  } else if ("AvgFileSynced" in value) {
    if (flowGoalInfo?.applicationLabel === "SharePoint") {
      return getSharepointFilesSyncedCards(value);
    }
    return [
      {
        stat: cardNumberFormat(value.TotalSyncedFileCount),
        statDescription: "Total Files Synced",
      },
      {
        stat: cardNumberFormat(roundToTwoDigits(value.AvgFileSynced)),
        statDescription: "Avg. Files Synced/Person",
      },
    ];
  } else if ("VisitedPageCount" in value) {
    return getSharepointPageVisitCards(value);
  }

  return [];
};

// Teams Meetings
const getTeamsMeetingsLineChart = (prepackedLineData: GoalLineChart | undefined): LineChartInfo => {
  const chartWrapperProps = {
    titles: ["Average Meeting Activity"],
    showLegend: true,
    includeOrg: true,
    legendLabels: ["Attended", "Organized"],
    colorRange: chartColorScale,
  };

  if (!prepackedLineData) return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  const lineData = lineChartFactory(prepackedLineData.map((l) => ({ ...l, ...l?.Events })));
  if (isEmpty(lineData)) {
    return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  }

  return {
    chartWrapperProps,
    lineProps: {
      xData: [lineData.Date, lineData.Date],
      yData: [lineData.MeetingsAttendedCountFlow, lineData.MeetingsOrganizedCountFlow],
      dashedYData: [lineData.MeetingsAttendedCountOrg, lineData.MeetingsOrganizedCountOrg],
      xFormatterFunc: getDateFormatByCount(lineData.Date.length),
      colorRange: chartColorScale,
      tooltipFormatter: lineTooltipFormatter,
    },
  };
};

const getTeamsMeetingsEngagementChart = (value: GoalTotalTeamsMeetings): HorizontalBarProps => {
  const data = [
    {
      id: "1",
      value: value.AdHocMeetingsAttendedCountFlow,
      category: "Ad hoc attended",
      fillColor: sendsColor,
    },
    {
      id: "2",
      value: value.AdHocMeetingsOrganizedCountFlow,
      category: "Ad hoc organized",
      fillColor: opensColor,
    },
    {
      id: "3",
      value: value.ScheduledOneTimeMeetingsAttendedCountFlow,
      category: "Scheduled one time attended",
      fillColor: clicksColor,
    },
    {
      id: "4",
      value: value.ScheduledRecurringMeetingsAttendedCountFlow,
      category: "Scheduled recurring attended",
      fillColor: fourthColor,
    },
  ];
  return {
    data,
    domain: getBarDomain(data),
    tooltipFormatter: (d) => {
      switch (d.id) {
        case "1":
          return orgChartTooltipFormatter(value.AdHocMeetingsAttendedCountFlow, value.AdHocMeetingsAttendedCountOrg);
        case "2":
          return orgChartTooltipFormatter(value.AdHocMeetingsOrganizedCountFlow, value.AdHocMeetingsOrganizedCountOrg);
        case "3":
          return orgChartTooltipFormatter(
            value.ScheduledOneTimeMeetingsAttendedCountFlow,
            value.ScheduledOneTimeMeetingsAttendedCountOrg,
          );
        case "4":
        default:
          return orgChartTooltipFormatter(
            value.ScheduledRecurringMeetingsAttendedCountFlow,
            value.ScheduledRecurringMeetingsAttendedCountOrg,
          );
      }
    },
  };
};

const getTeamsMeetingsCards = (value: GoalCardsTeamsMeetings): CardReportingItem[] => [
  {
    stat: cardNumberFormat(value.TotalParticipatedMeetings),
    statDescription: "Total Participated Meetings",
  },
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgMeetingParticipated)),
    statDescription: "Avg. Participated Meetings/Person",
  },
  {
    stat: cardNumberFormat(value.TotalOrganizedMeetings),
    statDescription: "Total Organized Meetings",
  },
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgMeetingOrganized)),
    statDescription: "Avg. Organized Meetings/Person",
  },
];

// Teams messages
const getTeamsMessagesLineChart = (prepackedLineData: GoalLineChart | undefined): LineChartInfo => {
  const chartWrapperProps = {
    titles: ["Average Message Activity"],
    showLegend: true,
    includeOrg: true,
    legendLabels: ["Posts", "Chats", "Replies"],
    colorRange: emailReportColorScale,
  };

  if (!prepackedLineData) return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  const lineData = lineChartFactory(prepackedLineData.map((l) => ({ ...l, ...l?.Events })));
  if (isEmpty(lineData)) {
    return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  }

  return {
    chartWrapperProps,
    lineProps: {
      xData: [lineData.Date, lineData.Date, lineData.Date],
      yData: [lineData.PostMessagesFlow, lineData.PrivateChatMessageCountFlow, lineData.ReplyMessagesCountFlow],
      dashedYData: [lineData.PostMessagesOrg, lineData.PrivateChatMessageCountOrg, lineData.ReplyMessagesCountOrg],
      xFormatterFunc: getDateFormatByCount(lineData.Date.length),
      colorRange: emailReportColorScale,
    },
  };
};

const getTeamsMessagesEngagementChart = (value: GoalTotalTeamsMessages): HorizontalBarProps => {
  const data = [
    {
      id: "1",
      value: value.PostMessagesFlow,
      category: "Posts",
      fillColor: sendsColor,
    },
    {
      id: "2",
      value: value.PrivateChatMessageCountFlow,
      category: "Chats",
      fillColor: opensColor,
    },
    {
      id: "3",
      value: value.ReplyMessagesCountFlow,
      category: "Replies",
      fillColor: clicksColor,
    },
  ];
  return {
    data,
    domain: getBarDomain(data),
    tooltipFormatter: (d) => {
      switch (d.id) {
        case "1":
          return orgChartTooltipFormatter(value.PostMessagesFlow, value.PostMessagesOrg);
        case "2":
          return orgChartTooltipFormatter(value.PrivateChatMessageCountFlow, value.PrivateChatMessageCountOrg);
        case "3":
        default:
          return orgChartTooltipFormatter(value.ReplyMessagesCountFlow, value.ReplyMessagesCountOrg);
      }
    },
  };
};

const getTeamsMessagesCards = (value: GoalCardsTeamsMessages) => [
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgPosts)),
    statDescription: "Avg. Posts/Person",
  },
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgChats)),
    statDescription: "Avg. Chats/Person",
  },
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgReplies)),
    statDescription: "Avg. Replies/Person",
  },
];

// Teams Cards
const getTeamsCallsLineChart = (prepackedLineData: GoalLineChart | undefined): LineChartInfo => {
  const chartWrapperProps = {
    titles: ["Average Call Activity"],
    showLegend: true,
    includeOrg: true,
    legendLabels: ["Calls"],
    colorRange: [sendsColor],
  };
  if (!prepackedLineData) return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  const lineData = lineChartFactory(prepackedLineData.map((l) => ({ ...l, ...l?.Events })));
  if (isEmpty(lineData)) {
    return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  }
  return {
    chartWrapperProps,
    lineProps: {
      xData: [lineData.Date],
      yData: [lineData.CallCountFlow],
      dashedYData: [lineData.CallCountOrg],
      xFormatterFunc: getDateFormatByCount(lineData.Date.length),
      colorRange: [sendsColor],
      tooltipFormatter: lineTooltipFormatter,
    },
  };
};

const getOnedriveFilesViewedLineChart = (prepackedLineData: GoalLineChart | undefined) => {
  const chartWrapperProps = {
    titles: ["Files Viewed Activity"],
    showLegend: true,
    includeOrg: true,
    legendLabels: ["Viewed or Edited"],
    colorRange: [sendsColor],
  };
  if (!prepackedLineData) return { chartWrapperProps, lineProps: { xData: [], yData: [] } };

  const lineData = lineChartFactory(prepackedLineData.map((l) => ({ ...l, ...l?.Events })));
  if (isEmpty(lineData)) {
    return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  }

  return {
    chartWrapperProps,
    lineProps: {
      xData: [lineData.Date],
      yData: [lineData.ViewedOrEditedFileCountFlow],
      dashedYData: [lineData.ViewedOrEditedFileCountOrg],
      xFormatterFunc: getDateFormatByCount(lineData.Date.length),
      colorRange: [sendsColor],
      tooltipFormatter: lineTooltipFormatter,
    },
  };
};

const getOnedriveFilesSharedLineChart = (prepackedLineData: GoalLineChart | undefined) => {
  const chartWrapperProps = {
    titles: ["Files Shared Activity"],
    showLegend: true,
    includeOrg: true,
    legendLabels: ["Internal", "External"],
    colorRange: chartColorScale,
  };
  if (!prepackedLineData) return { chartWrapperProps, lineProps: { xData: [], yData: [] } };

  const lineData = lineChartFactory(prepackedLineData.map((l) => ({ ...l, ...l?.Events })));
  if (isEmpty(lineData)) {
    return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  }

  return {
    chartWrapperProps,
    lineProps: {
      xData: [lineData.Date, lineData.Date],
      yData: [lineData.SharedInternallyFileCountFlow, lineData.SharedExternallyFileCountFlow],
      dashedYData: [lineData.SharedInternallyFileCountOrg, lineData.SharedExternallyFileCountOrg],
      xFormatterFunc: getDateFormatByCount(lineData.Date.length),
      colorRange: chartColorScale,
      tooltipFormatter: lineTooltipFormatter,
    },
  };
};

const getOnedriveFilesSyncedLineChart = (prepackedLineData: GoalLineChart | undefined) => {
  const chartWrapperProps = {
    titles: ["Files Synced Activity"],
    showLegend: true,
    includeOrg: true,
    legendLabels: ["Synced"],
    colorRange: [sendsColor],
  };
  if (!prepackedLineData) return { chartWrapperProps, lineProps: { xData: [], yData: [] } };

  const lineData = lineChartFactory(prepackedLineData.map((l) => ({ ...l, ...l?.Events })));
  if (isEmpty(lineData)) {
    return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  }

  return {
    chartWrapperProps,
    lineProps: {
      xData: [lineData.Date],
      yData: [lineData.SyncedFileCountFlow],
      dashedYData: [lineData.SyncedFileCountOrg],
      xFormatterFunc: getDateFormatByCount(lineData.Date.length),
      colorRange: [sendsColor],
      tooltipFormatter: lineTooltipFormatter,
    },
  };
};

// For now, these behave the same
const getSharepointFilesSyncedLine = getOnedriveFilesSyncedLineChart;
const getSharepointFilesViewedLine = getOnedriveFilesViewedLineChart;

const getSharepointPageVisitsLine = (prepackedLineData: GoalLineChart | undefined): LineChartInfo => {
  const chartWrapperProps = {
    titles: ["Page Visits Activity"],
    showLegend: true,
    includeOrg: true,
    legendLabels: ["Visits"],
    colorRange: [sendsColor],
  };
  if (!prepackedLineData) return { chartWrapperProps, lineProps: { xData: [], yData: [] } };

  const lineData = lineChartFactory(prepackedLineData.map((l) => ({ ...l, ...l?.Events })));
  if (isEmpty(lineData)) {
    return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  }

  return {
    chartWrapperProps,
    lineProps: {
      xData: [lineData.Date],
      yData: [lineData.VisitedPageCountFlow],
      dashedYData: [lineData.VisitedPageCountOrg],
      xFormatterFunc: getDateFormatByCount(lineData.Date.length),
      colorRange: [sendsColor],
      tooltipFormatter: lineTooltipFormatter,
    },
  };
};

const getSharepointSharedFilesLine = (prepackedLineData: GoalLineChart | undefined): LineChartInfo => {
  const chartWrapperProps = {
    titles: ["Shared Files Activity"],
    showLegend: true,
    includeOrg: true,
    legendLabels: ["Internal", "External"],
    colorRange: chartColorScale,
  };
  if (!prepackedLineData) return { chartWrapperProps, lineProps: { xData: [], yData: [] } };

  const lineData = lineChartFactory(prepackedLineData.map((l) => ({ ...l, ...l?.Events })));
  if (isEmpty(lineData)) {
    return { chartWrapperProps, lineProps: { xData: [], yData: [] } };
  }

  return {
    chartWrapperProps,
    lineProps: {
      xData: [lineData.Date, lineData.Date],
      yData: [lineData.SharedInternallyFileCountFlow, lineData.SharedExternallyFileCountFlow],
      dashedYData: [lineData.SharedInternallyFileCountOrg, lineData.SharedExternallyFileCountOrg],
      xFormatterFunc: getDateFormatByCount(lineData.Date.length),
      colorRange: chartColorScale,
      tooltipFormatter: lineTooltipFormatter,
    },
  };
};

const getSharepointFilesSyncedEngagement = (value: GoalTotalSharepointFileSyncs): HorizontalBarProps => {
  const data: DataPoint[] = [
    {
      id: "1",
      value: value.SyncedFileCountFlow,
      category: "Synced",
      fillColor: sendsColor,
    },
  ];
  return {
    data,
    domain: getBarDomain(data),
    tooltipFormatter: () => orgChartTooltipFormatter(value.SyncedFileCountFlow, value.SyncedFileCountOrg),
  };
};

const getSharepointFilesViewedEngagement = (value: GoalTotalSharepointFileViews): HorizontalBarProps => {
  const data = [
    {
      id: "1",
      value: value.ViewedOrEditedFileCountFlow,
      category: "Viewed",
      fillColor: sendsColor,
    },
  ];
  return {
    data,
    domain: getBarDomain(data),
    tooltipFormatter: () =>
      orgChartTooltipFormatter(value.ViewedOrEditedFileCountFlow, value.ViewedOrEditedFileCountOrg),
  };
};

const getSharepointPageVisitsEngagement = (value: GoalTotalSharepointPageVisits): HorizontalBarProps => {
  const data = [
    {
      id: "1",
      value: value.VisitedPageCountFlow,
      category: "Visits",
      fillColor: sendsColor,
    },
  ];
  return {
    data,
    domain: getBarDomain(data),
    tooltipFormatter: () => orgChartTooltipFormatter(value.VisitedPageCountFlow, value.VisitedPageCountOrg),
  };
};

const getSharepointSharedFilesEngagement = (value: GoalTotalSharepointFileShares): HorizontalBarProps => {
  const data = [
    {
      id: "1",
      value: value.SharedInternallyFileCountFlow,
      category: "Internal",
      fillColor: sendsColor,
    },
    {
      id: "2",
      value: value.SharedExternallyFileCountFlow,
      category: "External",
      fillColor: clicksColor,
    },
  ];

  return {
    data,
    domain: getBarDomain(data),
    tooltipFormatter: (d) => {
      if (d.id === "1") {
        return orgChartTooltipFormatter(value.SharedInternallyFileCountFlow, value.SharedExternallyFileCountOrg);
      } else {
        return orgChartTooltipFormatter(value.SharedExternallyFileCountFlow, value.SharedExternallyFileCountOrg);
      }
    },
  };
};

const getSharepointFilesSyncedCards = (value: GoalCardsSharepointFileSyncs): CardReportingItem[] => [
  {
    stat: cardNumberFormat(value.TotalSyncedFileCount),
    statDescription: "Total Files Synced",
  },
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgFileSynced)),
    statDescription: "Avg. Files Synced/Person",
  },
];

const getSharepointFilesViewedCards = (value: GoalCardsSharepointFileViews): CardReportingItem[] => [
  {
    stat: cardNumberFormat(value.ViewedOrEditedFileCount),
    statDescription: "Total Files Viewed",
  },
  {
    stat: cardNumberFormat(value.AvgViewedOrEditedFileCount),
    statDescription: "Avg. Files Viewed/Person",
  },
];

const getSharepointPageVisitCards = (value: GoalCardsSharepointPageVisits): CardReportingItem[] => [
  {
    stat: cardNumberFormat(value.VisitedPageCount),
    statDescription: "Total Pages Visited",
  },
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgVisitedPageCount)),
    statDescription: "Avg. Pages Visited/Person",
  },
];

const getSharepointSharedFilesCards = (value: GoalCardsSharepointFileShares): CardReportingItem[] => [
  {
    stat: cardNumberFormat(value.TotalSharedFileCount),
    statDescription: "Total Files Shared",
  },
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgInternalShares)),
    statDescription: "Avg. Internal Shares/Person",
  },
  {
    stat: cardNumberFormat(roundToTwoDigits(value.AvgExternalShares)),
    statDescription: "Avg. External Shares/Person",
  },
];
