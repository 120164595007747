import * as actionTypes from "../actionTypes/assessmentEntityStateActionTypes";
import midnightService from "../../../../Application/services/midnightService/midnightService";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import DraftEntityTypes from "../../../../../enums/draftEntityTypes";
import { Dispatcher, MidnightActionPayload } from "../../../../../interfaces/redux";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { AssessmentPayload, CreateAssessmentData, ContentPayload } from "../../types/state";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import {
  AssessmentInfoEditSuccess,
  AssessmentQuestionsEditSuccess,
  AssessmentPublishSuccess,
  AssessmentLockSuccess,
  AssessmentDiscardSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { NotifyStepSettings } from "../../../../../interfaces";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";

export const createAssessment = (data: CreateAssessmentData) => {
  const begin = getActionBaseProvider(actionTypes.createAssessmentBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createAssessmentSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createAssessmentFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(DraftEntityTypes.Assessments, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateAssessment = (metadata: AssessmentPayload) => {
  const begin = getActionBaseProvider(actionTypes.updateAssessmentBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateAssessmentFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: AssessmentInfoEditSuccess }));
    try {
      await dataService.assessments.updateAssessmentAsync(metadata);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateAssessmentTags = (id: number, tags: string[], type: "labels" | "softwareApplications") => {
  const begin = getActionBaseProvider(actionTypes.updateAssessmentBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateAssessmentFailure);

  const tagTypeMap = {
    labels: TagsEnum.Label,
    softwareApplications: TagsEnum.SoftwareApplication,
  };

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: id, action: AssessmentInfoEditSuccess }));
    try {
      await dataService.assessments.saveAssessmentTags(id, { tags, tagType: tagTypeMap[type] });
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateContent = (data: ContentPayload) => {
  const begin = getActionBaseProvider(actionTypes.updateAssessmentBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateAssessmentFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: data.id, action: AssessmentQuestionsEditSuccess }));
    try {
      await dataService.assessments.updateContentAsync(data);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const updateAssessmentCommandSuccess = getActionProvider(
  actionTypes.updateAssessmentCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const assessmentUpdated = getActionProvider(actionTypes.assessmentUpdated, (payload: { id: number }) => ({
  payload: {
    entityId: payload.id,
  },
}));

export const resetAssessmentEntityState = getActionBaseProvider(actionTypes.resetAssessmentEntityState);

export const publishDraftAssessmentEntity = (
  id: number,
  notificationSettings?: NotifyStepSettings,
  notifyTypes?: number[],
) => {
  const begin = getActionBaseProvider(actionTypes.publishAssessmentBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishAssessmentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: AssessmentPublishSuccess }));
    try {
      await midnightService.releaseLock(
        DraftEntityTypes.Assessments,
        id,
        notificationSettings ? { notificationSettings, notifyTypes } : undefined,
      );
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};
export const fetchDraftAssessmentEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedAssessmentBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedAssessmentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: AssessmentLockSuccess }));
    try {
      await midnightService.getEntityLock(DraftEntityTypes.Assessments, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const fetchDiscardAssessmentEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchDiscardAssessmentBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchDiscardAssessmentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: AssessmentDiscardSuccess }));
    try {
      await midnightService.discardLock(DraftEntityTypes.Assessments, id);
    } catch (error) {
      dispatch(failure({ error: error as Error }));
    }
  };
};

export const publishDraftAssessmentSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.publishAssessmentSuccess,
);

export const fetchDiscardAssessmentSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchDiscardAssessmentSuccess,
);

export const setAssessmentEntityIdAction = getActionProvider<number>(actionTypes.setAssessmentEntityId);

export const fetchLockedAssessmentSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchLockedAssessmentSuccess,
);
