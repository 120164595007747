/* istanbul ignore file */
//Needed for avoiding stale closures in functional components
//https://medium.com/@luis.roman/react-hooks-how-to-avoid-outdated-data-on-handlers-closures-a27020b68dd9
//fix - https://github.com/facebook/react/issues/16956#issuecomment-636501048
import React, { useLayoutEffect } from "react";

export const useLocalContext = <T>(data: T) => {
  const ctx = React.useRef(data);
  useLayoutEffect(() => {
    ctx.current = data;
  });
  return ctx;
};
