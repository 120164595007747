import React from "react";
import AssetTypes from "../../../enums/assetTypes";
import { CardBase } from "../../../interfaces/CardPropsBase";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import CardContent from "../cardContent/CardContent";
import CardThumbnail from "../cardThumbnail/CardThumbnail";
import SelectableCard from "../selectableCard/SelectableCard";
import { CardVideoItem } from "./types";

export interface VideoCardLayoutProps extends CardBase<CardVideoItem> {}

export function VideoCardLayout(props: React.PropsWithChildren<VideoCardLayoutProps>) {
  const { url, selected, onSelect, onTitleClick, children, className, disabled, icons, item, selectable } = props;

  const { thumbnailUrl, title, publisher, id, durationInSeconds } = item;

  return (
    <SelectableCard
      url={url}
      className={className}
      id={id}
      onCardClick={url ? null : onTitleClick}
      selected={selected}
      onSelect={onSelect}
      disabled={disabled}
    >
      <CardThumbnail
        draggable={false}
        thumbnailUrl={thumbnailUrl}
        selected={selected}
        selectable={selectable}
        disabled={disabled}
      >
        <span className="type">{AssetTypes.Video}</span>
        {<span className="duration">{dateTimeUtils.durationSecondsToTime(durationInSeconds)}</span>}
      </CardThumbnail>
      <CardContent title={title} meta={publisher} icons={icons}>
        {children}
      </CardContent>
    </SelectableCard>
  );
}
