import { isEmpty } from "lodash";

import packDataService from "../../services/packsDataService";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { req, err, got } from "../slices/packPurchasedViewSlice";
import assignmentsDataService from "../../../../Application/services/dataServices/assignmentsDataService";
import { AssignPeopleLicense, EntityToPeopleAssignments } from "../../../../Library/PeopleAssignments/types";
import { pluralize } from "../../../../../utils/stringUtils";
import { DistributedOpUpdateParams } from "../../../../../interfaces";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";
import { ContentToPeopleCommonProps } from "../../../../Library/PeopleAssignments/backgroundTasks/backgroundTasks";

export const fetchPurchasedPack = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(req());
  try {
    const res = await packDataService.getPurchasedPackAsync(id);
    dispatch(got(res.data));
  } catch (e) {
    let errorMessage = (e as Error).message;
    dispatch(err(errorMessage));
  }
};

export const issuePackLicenseToPeople =
  (props: ContentToPeopleCommonProps<EntityToPeopleAssignments>, onCompleted?: () => void) =>
  async (dispatch: AppDispatch) => {
    const { items } = props;
    if (isEmpty(items)) {
      return;
    }
    const idsCount = items.length;
    const licenses: AssignPeopleLicense[] = getLicenses(items);
    const message = `Issuing ${pluralize("license", idsCount)}`;
    const updateParams: DistributedOpUpdateParams = {
      id: "IssuePackLicenceDistributedOperation",
      title: `${message} with Full Access`,
      onCompleted,
      getOperationProps: async () => {
        const result = await assignmentsDataService.assignLicenses(licenses);
        return {
          operationId: result.operationId,
          stepsIds: result.stepsIds,
          statusUrl: result.statusUrl,
        };
      },
      successTransientMessage: `${message} completed!`,
      failureTransientMessage: `${message} failed!`,
    };
    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

export const removePackLicenseFromPeople =
  (props: ContentToPeopleCommonProps<EntityToPeopleAssignments>) => async (dispatch: AppDispatch) => {
    const { items } = props;
    if (isEmpty(items)) {
      return;
    }
    const idsCount = items.length;
    const licenses: AssignPeopleLicense[] = getLicenses(items);
    const updateParams: DistributedOpUpdateParams = {
      id: "RemovePackLicenceDistributedOperation",
      title: `Removing ${pluralize("license", idsCount)}`,
      indeterminate: true,
      getOperationProps: async () => {
        const result = await assignmentsDataService.removeLicenses(licenses);
        return {
          operationId: result.operationId,
          stepsIds: result.stepsIds,
          statusUrl: result.statusUrl,
        };
      },
      successTransientMessage: `${pluralize("License", idsCount)} removed!`,
      failureTransientMessage: `Failed to remove ${pluralize("license", idsCount)}!`,
    };
    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };

const getLicenses = (items: EntityToPeopleAssignments<{}>[]) => {
  return items.map((i) => {
    return { peopleType: i.peopleType, peopleId: i.peopleId, packId: i.contentId } as AssignPeopleLicense;
  });
};
