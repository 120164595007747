import cn from "classnames";
import { TextTruncate } from "../../../../../../components";
import Tooltip from "../../../../../../components/common/tooltip/Tooltip";
import { ReactComponent as SectionHeaderIcon } from "../../../../../../images/section-header-icon.svg";
import { ReactComponent as SectionHeaderIconBlue } from "../../../../../../images/section-header-icon-blue.svg";
import "./sectionHeaderCardInner.scss";

interface Props {
  name?: string;
  attached: boolean;
}

export const SectionHeaderCardInner: React.FC<Props> = ({ attached, name }) => {
  return (
    <div className={cn("section-header-card-inner", { attached })}>
      <div className="thumbnail-icon">
        {attached ? (
          <SectionHeaderIconBlue height={28} width={28} title="Attached section header" />
        ) : (
          <SectionHeaderIcon height={28} width={28} title="Dragged section header" />
        )}
      </div>
      <div className="card-text">
        {name ? (
          <Tooltip target={<TextTruncate lines={2}>{name}</TextTruncate>} content={name} />
        ) : (
          "Section Header title goes here (click to edit)"
        )}
      </div>
    </div>
  );
};
