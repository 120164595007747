import { createAsyncThunk } from "@reduxjs/toolkit";

import { getPrefix } from "../../../../Application/slices/models";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import externalApiDataService from "../../../services/externalApiDataService";
import { DistributedOperationResult } from "../../../../../types";
import { ApiAccessType } from "features/Accounts/types";

export const fetchApiClients = createAsyncThunk(
  getPrefix({
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.API,
    name: "fetchApiClients",
  }),
  (moboId?: string | undefined) => externalApiDataService.getExternalApiClients(moboId),
);

interface UpdateApiClientStatusRequest2 {
  apiAccessType: ApiAccessType;
  moboId?: string;
}

export const createApiClient = createAsyncThunk<DistributedOperationResult, UpdateApiClientStatusRequest2>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.API,
    name: "createApiClient",
  }),
  ({ apiAccessType, moboId }) => externalApiDataService.createExternalApiClient(apiAccessType, moboId),
);

interface UpdateApiClientStatusRequest {
  moboId: number;
  enabled: boolean;
}

export const updateApiClientStatus = createAsyncThunk<DistributedOperationResult, UpdateApiClientStatusRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.API,
    name: "updateApiClientStatus",
  }),
  ({ moboId, enabled }) => externalApiDataService.updateExternalApiClient(moboId, { enabled }),
);

export const regenerateApiClientSecret = createAsyncThunk<string, number>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.API,
    name: "regenerateSecret",
  }),
  externalApiDataService.regenerateClientSecret,
);
