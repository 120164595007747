import AutoenrollmentGroupNotificationsWarning from "./AutoenrollmentGroupNotificationsWarning";
import InfoBanner from "./InfoBanner";

export default function AutoenrollmentGroupNotificationsWarningBanner() {
  return (
    <InfoBanner>
      <AutoenrollmentGroupNotificationsWarning />
    </InfoBanner>
  );
}
