import { useSlate } from "slate-react";
import { Button } from "./Button";
import { Icon, iconType } from "./Icon";
import utils from "../utils";
import { MarkFormats } from "../types";

export interface Props {
  format: MarkFormats;
  iconType: iconType;
}

export const MarkButton = (props: Props) => {
  const editor = useSlate();
  return (
    <Button
      active={utils.isMarkActive(editor, props.format)}
      onClick={(e) => {
        e.preventDefault();
        utils.toggleMark(editor, props.format);
      }}
    >
      <Icon type={props.iconType} />
    </Button>
  );
};
