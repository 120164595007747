import { Icon } from "semantic-ui-react";
import cn from "classnames";

import "./addButton.scss";

export interface AddButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const AddButton = (props: AddButtonProps) => {
  const { onClick, disabled } = props;
  return (
    <div className={cn("add-button control-panel-add push-right", { disabled })}>
      <Icon aria-label="add-button" className={"plus circle"} size={"big"} color={"blue"} onClick={onClick} />
      <div>Add answer</div>
    </div>
  );
};
