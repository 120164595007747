import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/buttons/button/Button";

import { RestrictedByTooltip, NoResults } from "components";
import Restricted from "features/Application/Restricted";
import { RolePermissions } from "enums";

import styles from "./communicationsNoSends.module.scss";

interface Props {
  isDraft: boolean;
  communicationType?: string;
}

const CommunicationsNoSends: FC<Props> = ({ isDraft, communicationType = "email" }) => {
  const navigate = useNavigate();

  const onCreateSendClick = () => {
    navigate("send-sessions/create");
  };

  const renderCreateSendButton = (hasPermissions: boolean) => (
    <RestrictedByTooltip hasPermission={hasPermissions}>
      <Button
        primary
        className={`create-${communicationType} create-button`}
        disabled={!hasPermissions || isDraft}
        onClick={onCreateSendClick}
      >
        <span className={styles.createButtonText}>{`send ${communicationType}s`}</span>
      </Button>
    </RestrictedByTooltip>
  );
  const description = (
    <span>
      {`Looks like you haven't sent any ${communicationType}s yet.`}
      <br />
      {`Send ${communicationType}s using the button below.`}
    </span>
  );
  return (
    <div className={styles.noResultsWrapper}>
      <NoResults
        title={`You don't have any ${communicationType} analytics`}
        description={description}
        iconClassName="send"
      >
        <Restricted
          permissions={[RolePermissions.CommunicationsCreate, RolePermissions.CommunicationsManage]}
          renderContent={renderCreateSendButton}
        />
      </NoResults>
    </div>
  );
};

export default CommunicationsNoSends;
