import React, { FC } from "react";
import { Icon } from "semantic-ui-react";
import cn from "classnames";

import { TextTruncate } from "../../textTruncators/TextTruncators";
import { Tooltip } from "../../common/tooltip";
import { EMPTY_CELL_CONTENT } from "../../../utils/columnUtils";
import dateTimeUtils from "../../../utils/dateTimeUtils";

import styles from "./expiresListTemplate.module.scss";

export interface ExpiresListTemplateProps {
  expirationDate?: string | null;
}

const ExpiresListTemplate: FC<ExpiresListTemplateProps> = ({ expirationDate }) => {
  if (!expirationDate) {
    return <span>{EMPTY_CELL_CONTENT}</span>;
  }
  return (
    <div className={cn(styles["expired-content"])}>
      <Icon name="warning sign" size="large" />
      <Tooltip
        className={styles["tooltip-content"]}
        target={<TextTruncate>{dateTimeUtils.formatDate(expirationDate)}</TextTruncate>}
        content={dateTimeUtils.formatDate(expirationDate)}
      />
    </div>
  );
};

export default ExpiresListTemplate;
