import React from "react";
import { PropTypes } from "prop-types";

import SuppressibleConfirmationModal from "./SuppressibleConfirmationModal";
import ModalTypes from "./ModalTypes";

class RevertConfirmationModal extends React.Component {
  render() {
    return <SuppressibleConfirmationModal {...this.props} />;
  }
}

RevertConfirmationModal.defaultProps = {
  title: "Confirm Revert",
  message: "Performing this operation will result in all changes being lost. Do you wish to continue?",
};

RevertConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  modalUtils: PropTypes.shape({
    renderForm: PropTypes.func,
    executeWithoutModal: PropTypes.func,
    updateSuppressStatus: PropTypes.func,
  }).isRequired,
  modalType: PropTypes.oneOf(Object.getOwnPropertyNames(ModalTypes)),
  open: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default RevertConfirmationModal;
