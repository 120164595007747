import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import {
  createLazyFetchingItemsSlice,
  LazyFetchingItemsState,
} from "../../../../Application/slices/createLazyFetchingItemsSlice";
import { ItemsToDropBase } from "../../../../Library/Flows/Designer/types";

export type PackContentTabItemsToDropState = LazyFetchingItemsState<ItemsToDropBase>;

export const initialState: PackContentTabItemsToDropState = {
  itemsCount: 0,
  items: [],
  isLoading: false,
  areAllLoaded: false,
};

const packContentTabItemsToDropSlice = createLazyFetchingItemsSlice<ItemsToDropBase>({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "itemsToDrop",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = packContentTabItemsToDropSlice.actions;

export default packContentTabItemsToDropSlice.reducer;
