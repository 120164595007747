import { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";

import { ChartPopup } from "../shared/ChartPopup/ChartPopup";

import { useDimensions } from "../../../hooks/useDimensions";
import { useChartResizeTransition } from "../hooks/useChartResizeTransition";

import { DEFAULT_COLOR_RANGE, DEFAULT_MARGIN } from "../shared/constants";
import {
  APPEND_ONCE,
  calculateChartDimensions,
  drawCenteringGroup,
  drawXAxisForBarChart,
  drawYAxis,
  getLogScaleDomain,
  getTranslateString,
  isLogScale,
  textEllipsis,
} from "../shared/helpers";
import { getTicksFromValueTypes } from "../shared/public";
import { Props, SubgroupData } from "../types/GroupedBarChart";
import { BarChartData } from "../types/Shared";
import { useChartPopupReducer } from "../shared/ChartPopup/state";

import "./groupedBarChart.scss";

const defaultFormatter = (d: SubgroupData) => {
  return `Group: ${d.group}\n${d.key}: ${d.value}`;
};

function subgroupDataGenerator<T extends object>(d: BarChartData<T>, dataKeys: (keyof T)[]): SubgroupData[] {
  return dataKeys.map((key) => {
    return {
      key: key as string,
      value: d[key] as number,
      group: d.group,
    };
  });
}

export const GroupedBarChart = <T extends object>({
  margins = DEFAULT_MARGIN,
  data,
  order,
  yScaleRatio = d3.scaleLinear,
  xTickFormatter = (d) => d.toLocaleString(),
  yTickFormatter = (d) => d.toLocaleString(),
  yTickValueType,
  colorRange = DEFAULT_COLOR_RANGE,
  onClick,
  transitionDurationInMs = 750,
  groupPadding = 0.2,
  innerPadding = 0.05,
  tooltipFormatter = defaultFormatter,
}: Props<T>) => {
  const [popupState, dispatchPopup] = useChartPopupReducer();
  const [measurements, measureRef] = useDimensions();
  const svgRef = useRef<SVGSVGElement>(null);
  const onClickRef = useRef(onClick);
  const tooltipFormatterRef = useRef(tooltipFormatter);

  const [transitionDurationRef, resizeRef] = useChartResizeTransition(transitionDurationInMs);

  const { totalWidth, totalHeight, innerWidth, innerHeight } = calculateChartDimensions(measurements, margins);

  useEffect(() => {
    onClickRef.current = onClick;
  }, [onClick]);

  useEffect(() => {
    tooltipFormatterRef.current = tooltipFormatter;
  }, [tooltipFormatter]);

  const groupNames = useMemo(() => data.map((v) => v.group), [data]);
  const dataKeys = useMemo(() => {
    const extractedKeys = Object.keys(data[0]).filter((key) => key !== "group") as (keyof T)[];
    if (!order) return extractedKeys;

    if (extractedKeys.length !== order.length) throw new Error("Key specifiers must be of the same size.");

    for (const key of extractedKeys) {
      if (!order.includes(key)) {
        throw new Error(`Missing key in bar ordering: ${String(key)}`);
      }
    }

    return order;
  }, [data, order]);

  /**
   * SVG methods
   */

  const xGroupScale = useMemo(
    () => d3.scaleBand().domain(groupNames).range([0, innerWidth]).padding(groupPadding),
    [groupNames, innerWidth, groupPadding],
  );

  const xKeysScale = useMemo(
    () =>
      d3
        .scaleBand()
        .domain(dataKeys as string[])
        .range([0, xGroupScale.bandwidth()])
        .padding(innerPadding),
    [dataKeys, xGroupScale, innerPadding],
  );

  const yScale = useMemo(() => {
    let yMax = 0;
    data.forEach((d) => {
      dataKeys.forEach((key) => {
        yMax = Math.max(yMax, +d[key]);
      });
    });
    const yDomain = isLogScale(yScaleRatio) ? getLogScaleDomain(yMax) : [0, yMax];
    return yScaleRatio().domain(yDomain).range([innerHeight, 0]).nice();
  }, [data, innerHeight, dataKeys, yScaleRatio]);

  const colorScale = useMemo(() => {
    const interpolator = d3.interpolateRgbBasis(colorRange);
    return d3
      .scaleOrdinal<string>()
      .domain(dataKeys as string[])
      .range(dataKeys.map((_, i) => interpolator(i / (dataKeys.length - 1))));
  }, [colorRange, dataKeys]);

  const xAxis = useMemo(() => {
    return d3.axisBottom(xGroupScale).tickFormat(xTickFormatter).tickSize(0).tickPadding(5.5);
  }, [xGroupScale, xTickFormatter]);

  const yAxis = useMemo(() => {
    const tickValues = getTicksFromValueTypes(yScale, yTickValueType);
    return d3.axisLeft(yScale).tickSize(-innerWidth).tickValues(tickValues).tickFormat(yTickFormatter);
  }, [yScale, yTickValueType, innerWidth, yTickFormatter]);

  /**
   * End SVG methods
   */

  useEffect(() => {
    if (!svgRef.current) return;

    // On first run its common for the svg measurements to be 0
    // This causes issues when first drawing the line chart
    const invalidDimensions = innerWidth <= 0 || innerHeight <= 0;
    // There are issues when measuring things with JSDOM,
    // so this will always return early in test environments
    if (process.env.NODE_ENV !== "test" && invalidDimensions) return;

    const svg = d3.select(svgRef.current);
    const mainArea = drawCenteringGroup(svg, margins.left, margins.top, transitionDurationRef.current);

    const xAxisGroupedEllipsis = (_data: unknown, index: number, elements: ArrayLike<SVGTextElement>) => {
      textEllipsis(elements[index], xGroupScale.bandwidth(), -8, [
        elements[index - 1],
        elements[index],
        elements[index + 1],
      ]);
    };

    // Drawing axes
    const groupedBarXAxisGroup = drawXAxisForBarChart(
      mainArea,
      xAxis,
      innerHeight,
      transitionDurationRef,
      xAxisGroupedEllipsis,
    );

    groupedBarXAxisGroup.selectAll(".domain").remove();
    groupedBarXAxisGroup.selectAll("line").attr("class", "axisLine");
    groupedBarXAxisGroup
      .selectAll<SVGTextElement, unknown>("text")
      .attr("class", "axisText")
      .each(xAxisGroupedEllipsis)
      .on("mouseover", function () {
        // If the text is not truncated, don't show the tooltip
        if (!this.textContent?.endsWith("\u2026")) {
          return;
        }

        const siblingLine = d3.select(this).node()?.previousElementSibling;
        const originalTextContent = d3.select(this).data()[0] as string;

        if (!siblingLine || !originalTextContent) return;

        const measure = siblingLine.getBoundingClientRect();
        dispatchPopup({
          type: "SET_POPUP",
          payload: {
            isOpen: true,
            textContent: xTickFormatter(originalTextContent)!,
            // Magic 3 is a slight offset to align with the tick
            position: { x: measure.x - 3, y: measure.y },
            isTop: true,
          },
        });
      })
      .on("mouseleave", function () {
        dispatchPopup({ type: "CLOSE_POPUP" });
      });

    // Add y grid lines with labels
    const yAxisGroup = drawYAxis(mainArea, yAxis, transitionDurationRef);
    yAxisGroup.select(".domain").remove();
    yAxisGroup.selectAll("line").attr("class", "axisLine");
    yAxisGroup.selectAll("text").attr("class", "axisText");

    const barContainer = mainArea
      .selectAll(".barContainer")
      .data(APPEND_ONCE)
      .join((enter) => enter.append("g").attr("class", "barContainer"));

    const subgroups = barContainer
      .selectAll(".barGrouping")
      .data(data)
      .join(
        (enter) =>
          enter
            .append("g")
            .attr("class", "barGrouping")
            .attr("transform", (d) => `translate(${xGroupScale(d.group)},0)`),
        (update) => {
          update
            .transition()
            .duration(transitionDurationRef.current)
            .attr("transform", (d) => `translate(${xGroupScale(d.group)},0)`);
          return update;
        },
      );

    const centerTransformCalc = (d: SubgroupData) => {
      const xPos = (xKeysScale(d.key) as number) + xKeysScale.bandwidth() / 2;
      const yPos = yScale(d.value / 2) as number;
      return getTranslateString(xPos, yPos);
    };

    // For aligning tooltip
    subgroups
      .selectAll(".rectCenter")
      .data<SubgroupData>((d) => subgroupDataGenerator(d, dataKeys))
      .join(
        (enter) => enter.append("g").attr("class", "rectCenter").attr("transform", centerTransformCalc),
        (update) => update.attr("class", "rectCenter").attr("transform", centerTransformCalc),
      );

    const mouseOver = (d: SubgroupData, index: number, nodes: ArrayLike<d3.BaseType | SVGRectElement>) => {
      if (!svgRef.current) return;
      // @ts-ignore
      const parent = d3.select(nodes[index]).select((_d, i, n) => n[i].parentNode);
      const centers = parent.selectAll<SVGGElement, unknown>(".rectCenter");
      const relevantCenter = centers.nodes()[index];
      const measured = relevantCenter.getBoundingClientRect();
      const x = measured.x;
      const y = measured.y;

      dispatchPopup({
        type: "SET_POPUP",
        payload: {
          isOpen: true,
          textContent: tooltipFormatterRef.current(d),
          position: { x, y },
          isTop: false,
        },
      });
    };

    const mouseLeave = () => {
      dispatchPopup({ type: "CLOSE_POPUP" });
    };

    function getBarHeight(d: SubgroupData) {
      return innerHeight - yScale(d.value);
    }

    function baseBarTransition(selection: d3.Selection<SVGRectElement, SubgroupData, d3.BaseType, BarChartData<T>>) {
      selection
        .transition()
        .duration(transitionDurationRef.current)
        .attr("y", (d) => innerHeight - (innerHeight - yScale(d.value)) / 2)
        .attr("height", (d) => getBarHeight(d) / 2);
    }

    function roundedBarTransition(selection: d3.Selection<SVGRectElement, SubgroupData, d3.BaseType, BarChartData<T>>) {
      selection
        .transition()
        .duration(transitionDurationRef.current)
        .attr("y", (d) => yScale(d.value))
        .attr("height", getBarHeight);
    }

    function getRectData(data: BarChartData<T>) {
      return subgroupDataGenerator(data, dataKeys);
    }

    function getRectXPos(d: SubgroupData): number {
      return xKeysScale(d.key)!;
    }

    function getRectColor(d: SubgroupData) {
      return colorScale(d.key);
    }

    // TODO: Draw normal / rounded bars
    subgroups
      .selectAll(".baseRect")
      .data(getRectData)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "baseRect")
            .attr("x", getRectXPos)
            .attr("y", innerHeight)
            .attr("width", xKeysScale.bandwidth())
            .attr("height", 0)
            .attr("fill", getRectColor)
            .call(baseBarTransition),
        (update) =>
          update
            .attr("x", getRectXPos)
            .attr("width", xKeysScale.bandwidth())
            .attr("fill", getRectColor)
            // @ts-ignore
            .call(baseBarTransition),
      );

    const rects = subgroups
      .selectAll(".roundedRect")
      .data(getRectData)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "roundedRect")
            .attr("x", getRectXPos)
            .attr("y", innerHeight)
            .attr("rx", xKeysScale.bandwidth() / 2)
            .attr("ry", xKeysScale.bandwidth() / 2)
            .attr("width", xKeysScale.bandwidth())
            .attr("height", 0)
            .attr("fill", getRectColor)
            .call(roundedBarTransition),
        (update) =>
          update
            .attr("x", getRectXPos)
            .attr("rx", xKeysScale.bandwidth() / 2)
            .attr("ry", xKeysScale.bandwidth() / 2)
            .attr("width", xKeysScale.bandwidth())
            .attr("fill", getRectColor)
            // @ts-ignore
            .call(roundedBarTransition),
      )
      .on("mouseover", mouseOver)
      .on("mouseleave", mouseLeave)
      .on("click", (d) => onClickRef.current?.(d));

    return () => {
      rects.on("mouseover", null).on("mouseleave", null).on("click", null);
    };
  }, [
    margins.left,
    margins.top,
    innerWidth,
    innerHeight,
    xGroupScale,
    xKeysScale,
    yScale,
    xAxis,
    yAxis,
    data,
    dataKeys,
    colorScale,
    transitionDurationRef,
    dispatchPopup,
    xTickFormatter,
  ]);

  return (
    <div className="groupedBarRoot" ref={measureRef}>
      <div className="fullSize" ref={resizeRef}>
        <svg ref={svgRef} viewBox={`0 0 ${totalWidth} ${totalHeight}`} data-testid="grouped bar chart svg" />
        <ChartPopup
          open={popupState.isOpen}
          left={popupState.position.x}
          top={popupState.position.y}
          useTop={popupState.isTop}
        >
          <span className="barPopupText">{popupState.textContent}</span>
        </ChartPopup>
      </div>
    </div>
  );
};
