import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormikProps, withFormik } from "formik";

import validationSchemas from "../../../../../utils/validationSchemas";
import Restricted from "../../../../Application/Restricted";

import { ConfigurationForm } from "../../Info/forms/ConfigurationForm/ConfigurationForm";
import { createConfigurationInfoSelector, ConfigurationPayload } from "../../Info/forms/ConfigurationForm/helper";
import { updateFlowInfo, updateFlowInfoSuccess } from "../../state/actions/infoActionCreators";
import { flowEntitySelector, flowInformationSelector } from "../../state/selectors";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { AutosaveProps, withAutosave } from "../../../../../utils/withAutosave";
import { RolePermissions } from "../../../../../enums";
import { WizardFormBase } from "../../../../../interfaces/Form";
import { bindAction } from "../../../../../interfaces";

interface ConfigurationOwnProps extends WizardFormBase {
  validationChanged: (isValid: boolean) => void;
}

export type ConfigurationProps = ConfigurationOwnProps &
  FormikProps<ConfigurationPayload> &
  PropsFromRedux &
  AutosaveProps;

export const Configuration = (props: ConfigurationProps) => {
  const { isDraft, isValid, acceptHandlers } = props;

  useEffect(() => {
    props.validationChanged(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  useEffect(() => {
    const saveContent = () => {
      props.updateInfoSuccess(props.values);
    };

    acceptHandlers?.({
      onPrevious: saveContent,
      onNext: saveContent,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  return (
    <div className="stretch scrollable-content edit-flow-info edit-form">
      <Restricted
        permissions={[RolePermissions.FlowsCreate]}
        renderContent={(hasAnyPermission) => (
          <ConfigurationForm {...props} isDisabled={!(isDraft && hasAnyPermission)} />
        )}
      />
    </div>
  );
};

/* istanbul ignore next */
const ComponentWithAutosave = withAutosave<ConfigurationProps, ConfigurationPayload>({
  getInitValues: (props) => props.configurationInfo,
  stateProvider: (configurationInfo) => configurationInfo,
  entityUpdater: (props) => props.submitForm,
  isDeffered: (props) => !props.isLoaded,
  isValid: (props) => props.isEditable && props.isValid && props.id > 0,
})(Configuration);

export const handleSubmit = (
  configurationInfo: ConfigurationPayload,
  { props }: { props: ConfigurationOwnProps & PropsFromRedux },
) => {
  props.updateInfo(configurationInfo);
};

/* istanbul ignore next */
const ComponentWithFormik = withFormik({
  mapPropsToValues: (props: ConfigurationOwnProps & PropsFromRedux) => props.configurationInfo,
  validationSchema: validationSchemas.flowConfiguration,
  enableReinitialize: true,
  validateOnMount: true,
  handleSubmit,
})(ComponentWithAutosave);

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const flowInformation = flowInformationSelector(state);
  const configurationInfo = createConfigurationInfoSelector(state);

  return {
    configurationInfo,
    isEditable: flowInformation.info.isEditable,
    // Auto save HOC needs the next values
    id: flowInformation.info.id,
    isDraft: flowInformation.info.isDraft,
    isLoading: flowInformation.isLoadingInfo,
    isLoaded: flowInformation.isInfoLoaded,
    isCreating: flowEntitySelector(state).changingEntityState,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateInfo: bindAction(updateFlowInfo, dispatch),
  updateInfoSuccess: bindAction(updateFlowInfoSuccess, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ComponentWithFormik);
