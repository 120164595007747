import { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import ModalWithSteps from "../../modal/ModalWithSteps";
import ConfirmLicensingStep from "../commonSteps/confirmLicensingStep/ConfirmLicensingStep";
import { Button } from "components/buttons/button/Button";
import "./peopleLicenseModal.scss";
import { PacksContextStep } from "../commonSteps";
import { Strings } from "enums";
import { RenderModalActionsParams } from "../commonSteps/packsContextStep/PacksContextStep";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces/redux";
import { resetContentPacksAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { PackOverview } from "features/Licensing/Packs/types/state";

interface ConfirmLicensingRenderModalActionsParams extends RenderModalActionsParams {
  hasPreviousStep: boolean;
}

export interface Props {
  userIds?: number[];
  groupIds?: number[];
  packs?: PackOverview[];
  show: boolean;
  onCancel(): void;
  onConfirm(): void;
  onIssueLicensePacksChange: (ids: number[]) => void;
}

export type AllProps = PropsFromRedux & Props;

const AddPeoplePacksIssueLicenseModal: FC<AllProps> = ({
  userIds,
  groupIds,
  packs,
  show,
  onCancel,
  onConfirm,
  onIssueLicensePacksChange,
  resetContentPacks,
}) => {
  const [isDataValid, setIsDataValid] = useState(false);
  const [skipPacksContextStep, setSkipPacksContextStep] = useState(false);
  const [packsContextStepHeader, setPacksContextStepHeader] = useState<string>();

  const getPacksContextItems = () =>
    packs?.map((x) => {
      return { type: "pack", id: x.id.toString(), title: x.title, thumbnailUrl: x.thumbnailImageUrl };
    });

  const packContextStepModalActionsParams: RenderModalActionsParams = {
    isDataValid: isDataValid,
    onIsDataValidChange: setIsDataValid,
  };

  const confirmLicensingStepModalActionsParams: ConfirmLicensingRenderModalActionsParams = {
    isDataValid: isDataValid,
    onIsDataValidChange: setIsDataValid,
    hasPreviousStep: !skipPacksContextStep,
  };

  const onClose = () => {
    setSkipPacksContextStep(false);
    resetContentPacks();
  };

  const getRenderModalActionsPacksContextStepFunction = (params: RenderModalActionsParams) => {
    return (nextStep: () => void) => {
      return (closeModal: Function) => {
        const { isDataValid, onIsDataValidChange } = params;
        return (
          <>
            <Button
              basic
              className="cancel"
              content="Cancel"
              onClick={() => {
                onCancel();
                closeModal();
              }}
            />
            <Button
              primary
              className="next"
              content="Next"
              onClick={() => {
                onIsDataValidChange(false);
                nextStep();
              }}
              disabled={!isDataValid}
            />
          </>
        );
      };
    };
  };

  const getRenderModalActionsConfirmLicensingStepFunction = (params: ConfirmLicensingRenderModalActionsParams) => {
    return (_: () => void, prevStep: () => void) => {
      return (closeModal: Function) => {
        const { isDataValid, onIsDataValidChange, hasPreviousStep } = params;
        return (
          <>
            <Button
              primary
              className={hasPreviousStep ? "previous" : "cancel"}
              content={hasPreviousStep ? "Previous" : "Cancel"}
              onClick={() => {
                if (hasPreviousStep) {
                  onIsDataValidChange(true);
                  prevStep();
                } else {
                  onCancel();
                  closeModal();
                }
              }}
            />
            <Button
              primary
              className="confirm"
              content="Confirm"
              onClick={() => {
                onConfirm();
                closeModal();
              }}
              disabled={!isDataValid}
            />
          </>
        );
      };
    };
  };

  return (
    <ModalWithSteps
      className="people-license-modal"
      scrolling
      showModal={show}
      onCancel={onCancel}
      onBeforeClose={onClose}
    >
      {!skipPacksContextStep && (
        <PacksContextStep
          header={packsContextStepHeader}
          renderModalActions={getRenderModalActionsPacksContextStepFunction(packContextStepModalActionsParams)}
          info={getPacksContextItems() ?? []}
          onIsDataValidChange={setIsDataValid}
          noDataLoaded={() => setSkipPacksContextStep(true)}
          setModalStepHeader={() => setPacksContextStepHeader(Strings.modalTitles.licensing)}
        />
      )}
      <ConfirmLicensingStep
        onIsDataValidChange={setIsDataValid}
        header="License Confirmation"
        renderModalActions={getRenderModalActionsConfirmLicensingStepFunction(confirmLicensingStepModalActionsParams)}
        info={{
          groupIds: groupIds,
          userIds: userIds,
          packIds: packs?.map((x) => x.id),
        }}
        setIssueLicensePackIds={onIssueLicensePacksChange}
      />
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetContentPacks: bindAction(resetContentPacksAction, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddPeoplePacksIssueLicenseModal);
