import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { resetAppliedFilter } from "../slices/contentAssignmentsFiltersSlice";
import { getContentAssignmentPdfFilterOptions as pdfFilterOptions } from "../../../../Library/Pdfs/state/thunks/pdfFiltersThunk";
import { getContentAssignmentSurveyFilterOptions } from "../../../../Library/Surveys/state/thunks/surveyFiltersThunk";
import { getFilterOptions as eventGetFilterOptions } from "../../../../Library/Events/state/thunks/eventFiltersThunk";
import { resetAppliedFilter as eventResetFilter } from "../../../../Library/Events/state/slices/eventFilterSlice";
import { AddPeopleToContentTypes } from "../../../../../enums";
import { contentContextSelector } from "../../../state/slices/addToContentSlice";
import { getContentAssignmentModalFilterOptions as assessmentFilterOptions } from "../../../../Library/Assessments/state/thunks/assessmentFiltersThunk";
import { getContentAssignmentModalFilterOptions as flowFilterOptions } from "../../../../Library/Flows/state/thunks/flowFiltersThunk";
import { getContentAssignmentModalFilterOptions as videoFilterOptions } from "../../../../Library/Videos/state/thunks/videoFiltersThunk";

/* istanbul ignore next */
export const fetchContentAssignmentsFilterOptions = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const map = {
    [AddPeopleToContentTypes.Flows]: flowFilterOptions,
    [AddPeopleToContentTypes.Videos]: videoFilterOptions,
    [AddPeopleToContentTypes.Events]: eventGetFilterOptions,
    [AddPeopleToContentTypes.Surveys]: getContentAssignmentSurveyFilterOptions,
    [AddPeopleToContentTypes.Assessments]: assessmentFilterOptions,
    [AddPeopleToContentTypes.PDFs]: pdfFilterOptions,
  };

  dispatch(map[contentContextSelector(getState())]());
};

/* istanbul ignore next */
export const resetContentAssignmentsFilterOptions = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const map = {
    [AddPeopleToContentTypes.Flows]: resetAppliedFilter,
    [AddPeopleToContentTypes.Videos]: resetAppliedFilter,
    [AddPeopleToContentTypes.Events]: eventResetFilter,
    [AddPeopleToContentTypes.Surveys]: resetAppliedFilter,
    [AddPeopleToContentTypes.Assessments]: resetAppliedFilter,
    [AddPeopleToContentTypes.PDFs]: resetAppliedFilter,
  };

  dispatch(map[contentContextSelector(getState())]());
};
