import NoResults from "../../../../components/noResults/NoResults";
import NoSearchResults from "../../../../components/noSearchResults";
import { ReactComponent as NoAssetResult } from "../../../../images/assets-icon.svg";

export interface Props {
  filtered: boolean;
  createAssetButton?: React.ReactNode;
}

export const VideoAssetsNoResults: React.FC<Props> = ({ filtered, createAssetButton }) => {
  const descriptionMarkup = "Looks like you don’t have any videos for this group. Add some using the button below.";
  return filtered ? (
    <NoSearchResults />
  ) : (
    <NoResults title="You don’t have any videos" description={descriptionMarkup} icon={<NoAssetResult />}>
      {createAssetButton}
    </NoResults>
  );
};
