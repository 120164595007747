import { FC } from "react";
import { PeopleType } from "../../../features/Library/PeopleAssignments/types";
import { IObservable } from "interfaces";
import { TriggerableConfirmationModal } from "components/triggeredConfirmationModal/TriggerableConfirmationModal";

export interface RemoveContentConfirmationModalProps {
  removeObserver: IObservable<(onSubmit: () => void) => void>;
  peopleType: PeopleType;
}

const RemovePriorityConfirmationModal: FC<RemoveContentConfirmationModalProps> = (
  props: RemoveContentConfirmationModalProps,
) => {
  const { removeObserver, peopleType } = props;

  const content =
    peopleType === PeopleType.User
      ? "Are you sure you want to clear priorities? This action removes all priority settings, causing selected flow(s) to no longer appear on the home page for this user."
      : "Are you sure you want to clear priorities? This action removes all priority settings, causing selected flow(s) to no longer appear on the home page for all users in this group.";

  return (
    <TriggerableConfirmationModal
      onTriggerModalObserver={removeObserver}
      content={content}
      confirmButtonLabel={"Clear Priority"}
      title={"Clear Priority Settings?"}
    />
  );
};

export default RemovePriorityConfirmationModal;
