import { AxiosRequestHeaders, AxiosResponse } from "axios";
import { ILinksDataService } from "../types/services";
import { GetLinksRequest } from "../types/requests";
import { LinkOverview } from "../types/state";

export const getMockedData = (): LinkOverview[] => [
  {
    id: 1,
    dateCreated: new Date(2021, 6, 1),
    dateModified: new Date(2021, 6, 2),
    title: "Link 1",
    isDraft: false,
    publisher: "publisher 1",
  },
  {
    id: 2,
    dateCreated: new Date(2021, 6, 2),
    dateModified: new Date(2021, 6, 3),
    title: "Link 2",
    isDraft: true,
    publisher: "publisher 2",
  },
  {
    id: 3,
    dateCreated: new Date(2021, 6, 3),
    dateModified: new Date(2021, 6, 4),
    title: "Link 3",
    isDraft: false,
    publisher: "publisher 3",
  },
  {
    id: 4,
    dateCreated: new Date(2021, 6, 4),
    dateModified: new Date(2021, 6, 5),
    title: "Link 4",
    isDraft: true,
    publisher: "publisher 4",
  },
  {
    id: 5,
    dateCreated: new Date(2021, 6, 5),
    dateModified: new Date(2021, 6, 6),
    title: "Link 5",
    isDraft: false,
    publisher: "publisher 5",
  },
  {
    id: 6,
    dateCreated: new Date(2021, 6, 6),
    dateModified: new Date(2021, 6, 7),
    title: "Link 6",
    isDraft: true,
    publisher: "publisher 6",
  },
  {
    id: 7,
    dateCreated: new Date(2021, 6, 7),
    dateModified: new Date(2021, 6, 8),
    title: "Link 7",
    isDraft: false,
    publisher: "publisher 7",
  },
  {
    id: 8,
    dateCreated: new Date(2021, 6, 8),
    dateModified: new Date(2021, 6, 9),
    title: "Link 8",
    isDraft: true,
    publisher: "publisher 8",
  },
  {
    id: 9,
    dateCreated: new Date(2021, 6, 9),
    dateModified: new Date(2021, 6, 10),
    title: "Link 9",
    isDraft: false,
    publisher: "publisher 9",
  },
  {
    id: 10,
    dateCreated: new Date(2021, 6, 10),
    dateModified: new Date(2021, 6, 11),
    title: "Link 10",
    isDraft: true,
    publisher: "publisher 10",
  },
  {
    id: 11,
    dateCreated: new Date(2021, 6, 11),
    dateModified: new Date(2021, 6, 12),
    title: "Link 11",
    isDraft: false,
    publisher: "publisher 11",
  },
  {
    id: 12,
    dateCreated: new Date(2021, 6, 12),
    dateModified: new Date(2021, 6, 13),
    title: "Link 12",
    isDraft: true,
    publisher: "publisher 12",
  },
  {
    id: 13,
    dateCreated: new Date(2021, 6, 13),
    dateModified: new Date(2021, 6, 14),
    title: "Link 13",
    isDraft: false,
    publisher: "publisher 13",
  },
  {
    id: 14,
    dateCreated: new Date(2021, 6, 14),
    dateModified: new Date(2021, 6, 15),
    title: "Link 14",
    isDraft: true,
    publisher: "publisher 14",
  },
  {
    id: 15,
    dateCreated: new Date(2021, 6, 15),
    dateModified: new Date(2021, 6, 16),
    title: "Link 15",
    isDraft: false,
    publisher: "publisher 15",
  },
  {
    id: 16,
    dateCreated: new Date(2021, 6, 16),
    dateModified: new Date(2021, 6, 17),
    title: "Link 16",
    isDraft: true,
    publisher: "publisher 16",
  },
  {
    id: 17,
    dateCreated: new Date(2021, 6, 17),
    dateModified: new Date(2021, 6, 18),
    title: "Link 17",
    isDraft: false,
    publisher: "publisher 17",
  },
  {
    id: 18,
    dateCreated: new Date(2021, 6, 18),
    dateModified: new Date(2021, 6, 19),
    title: "Link 18",
    isDraft: true,
    publisher: "publisher 18",
  },
  {
    id: 19,
    dateCreated: new Date(2021, 6, 19),
    dateModified: new Date(2021, 6, 20),
    title: "Link 19",
    isDraft: false,
    publisher: "publisher 19",
  },
  {
    id: 20,
    dateCreated: new Date(2021, 6, 20),
    dateModified: new Date(2021, 6, 21),
    title: "Link 21",
    isDraft: true,
    publisher: "publisher 21",
  },
  {
    id: 21,
    dateCreated: new Date(2021, 6, 21),
    dateModified: new Date(2021, 6, 22),
    title: "Link 21",
    isDraft: false,
    publisher: "publisher 21",
  },
  {
    id: 22,
    dateCreated: new Date(2021, 6, 22),
    dateModified: new Date(2021, 6, 23),
    title: "Link 22",
    isDraft: true,
    publisher: "publisher 22",
  },
];

const linksDataService: ILinksDataService = {
  getLinksAsync(data: GetLinksRequest) {
    // Just for demo
    return new Promise<AxiosResponse<LinkOverview[]>>((resolve) => {
      setTimeout(() => {
        const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
        let headers: any = {};
        headers[countHeaderName] = 22;
        resolve({
          status: 200,
          config: {
            headers: {} as AxiosRequestHeaders,
          },
          statusText: "OK",
          headers,
          data: getMockedData().slice(data.skip, data.skip + data.top),
        });
      }, 500);
    });
  },
};

export default linksDataService;
