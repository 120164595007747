import { NoResultsWithButton, SearchInput } from "../../../../components";
import { RolePermissions, SortingDirection, ViewType } from "../../../../enums";

import UsersFileUploadModal from "components/usersFileUploadModal/UsersFileUploadModal";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import { connect, ConnectedProps } from "react-redux";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { Columns, getColumnOptions } from "./columnOptions";

import {
  SucceedUserImport,
  UserManagerAddedSuccess,
  UserManagerDeleteAllSuccess,
} from "features/Application/services/realTimeNotification/events/people/peopleEvents";

import { IObservable } from "interfaces";
import { bindAction } from "interfaces/redux";
import { showGeneralLoaderWithTimeout } from "../../../Application/actions/generalLoader/generalLoaderActions";
import * as backgroundTasksActionsRedux from "../../../BackgroundTasks/state/backgroundTasksActions";
import { reset as resetPreview } from "../state/slices/csvImport/csvImportPreviewSlice";
import { reset as resetFileUpload, updateUploadProgress } from "../state/slices/csvImport/uploadCsvFileSlice";
import { fetchCountries, fetchDepartments, fetchJobTitles } from "../state/thunks/addUserThunk";
import { fetchUploadedFileColumns, fetchUsersImportPreviewData, uploadCsvFile } from "../state/thunks/csvImportThunk";
import CreateUserModal from "./CreateUserModal";

import RemoveUsersConfirmationModal from "../../../../components/account/removeUsersConfirmationModal/RemoveUsersConfirmationModal";

export interface EditAccountUsersListOwnProps {
  editAccountId: number;
  users: any;
  usersCount: number;
  isLoading: boolean;
  selectedIds: number[];
  disabledByPermission: boolean;
  showUsersFileUploadModal: boolean;
  permissions?: RolePermissions[];
  renderAddUsersButton: () => JSX.Element;
  onSelectedUsersChanged: (ids: number[]) => void;
  onCloseUsersFileUploadModal: () => void;
  fetchUsers: (skip: number, top: number, sortBy?: string, sortOrder?: SortingDirection) => void;
  onAddUserModalObserver: IObservable<(onConfirm: () => void) => void>;
  deleteUsersFromAccount: (userId: number[], accountId: number) => void;
}

export type EditAccountUsersListProps = EditAccountUsersListOwnProps & PropsFromRedux;

const EditAccountUsersList = (props: EditAccountUsersListProps) => {
  const {
    users,
    usersCount,
    editAccountId,
    accountId,
    isLoading,
    fetchUsers,
    onSelectedUsersChanged,
    selectedIds,
    disabledByPermission,
    permissions,
    renderAddUsersButton,
    deleteUsersFromAccount,
    onAddUserModalObserver,
    ...rest
  } = props;

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const onFileDisposed = useCallback(() => setUploadedFile(null), [setUploadedFile]);

  useEffect(() => {
    rest.fetchDepartments();
    rest.fetchJobTitles();
    rest.fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);

  const handleDelete = useCallback((userId: number) => {
    setUserIdToDelete(userId);
    setShowDeleteConfirmationModal(true);
  }, []);

  const onDeleteConfirmed = useCallback(() => {
    if (userIdToDelete !== null) {
      deleteUsersFromAccount([userIdToDelete], editAccountId);
      setShowDeleteConfirmationModal(false);
      setUserIdToDelete(null);
    }
  }, [userIdToDelete, deleteUsersFromAccount, editAccountId]);

  const onDeleteCancelled = useCallback(() => {
    setShowDeleteConfirmationModal(false);
    setUserIdToDelete(null);
  }, []);

  const renderRemoveContactConfirmationModal = () => (
    <RemoveUsersConfirmationModal
      count={1}
      open={showDeleteConfirmationModal}
      onCancel={onDeleteCancelled}
      onContinue={onDeleteConfirmed}
    />
  );

  const columnOptions = useMemo(() => {
    return getColumnOptions({
      handleDelete: handleDelete,
      hasPermission: !disabledByPermission,
      removeButtonDisabled: disabledByPermission,
      selectedIds: selectedIds,
    });
  }, [disabledByPermission, handleDelete, selectedIds]);

  const csvUsersUploadModal = useMemo(() => {
    const {
      actorId,
      csvImport,
      showUsersFileUploadModal,
      onCloseUsersFileUploadModal,
      backgroundTasks,
      addBackgroundTask,
      removeBackgroundTask,
      cancelBackgroundTask,
      showGeneralLoaderWithTimeout,
      uploadUsersFile,
      fetchUploadedFileColumns,
      fetchUsersImportPreviewData,
      resetFileUpload,
      updateUploadProgress,
      resetUsersImportPreviewData,
    } = rest;

    const { uploadStatus, csvColumns, preview } = csvImport;

    return (
      <UsersFileUploadModal
        showGeneralLoaderWithTimeout={showGeneralLoaderWithTimeout}
        addBackgroundTask={addBackgroundTask}
        removeBackgroundTask={removeBackgroundTask}
        cancelBackgroundTask={cancelBackgroundTask}
        importAccountId={editAccountId}
        accountId={accountId}
        actorId={actorId}
        showModal={showUsersFileUploadModal}
        onClose={onCloseUsersFileUploadModal}
        isFileUploading={uploadStatus.isUploading}
        droppedFile={uploadedFile}
        onFileDisposed={onFileDisposed}
        uploadProgress={uploadStatus.progress}
        uploadError={uploadStatus.error?.message ?? null}
        uploadUsersFile={uploadUsersFile}
        getUploadedFileColumns={fetchUploadedFileColumns}
        getUsersImportPreviewData={fetchUsersImportPreviewData}
        uploadedUsersFileColumns={csvColumns}
        cancelUsersFileUpload={resetFileUpload}
        resetUsersFileUpload={resetFileUpload}
        updateUsersFileUploadProgress={(...args: any[]) => updateUploadProgress(args[0])}
        usersImportPreviewData={preview}
        resetUsersImportPreviewData={resetUsersImportPreviewData}
        backgroundTasks={backgroundTasks}
      />
    );
  }, [rest, uploadedFile, onFileDisposed, accountId, editAccountId]);

  return (
    <>
      <GenericItemsView
        viewType={ViewType.LIST}
        items={users}
        isLoading={isLoading}
        columnOptions={columnOptions}
        fetchData={fetchUsers}
        dataCount={usersCount}
        renderSearch={() => <SearchInput placeholder="Search for Users..." disabled />}
        // @ts-ignore
        noResultsContent={
          <NoResultsWithButton
            title="No Users"
            description="It looks like you don't have any users yet."
            iconClassName="fal fa-user"
            actionButton={renderAddUsersButton()}
          />
        }
        selectedIds={selectedIds}
        onSelectedItemChanged={onSelectedUsersChanged}
        listViewRtnEvents={[SucceedUserImport, UserManagerAddedSuccess, UserManagerDeleteAllSuccess]}
        permissions={permissions}
        defaultSortingColumnName={Columns.Added}
      />
      {csvUsersUploadModal}
      {renderRemoveContactConfirmationModal()}
      <CreateUserModal onTriggerModalObserver={onAddUserModalObserver} accountId={editAccountId} />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { csvImport } = state.accounts.edit;
  return {
    accountId: state.userProfile.accountId,
    actorId: state.userProfile.id,
    csvImport: csvImport,
    backgroundTasks: state.backgroundTasks.tasks,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addBackgroundTask: bindAction(backgroundTasksActionsRedux.addTask, dispatch),
    removeBackgroundTask: bindAction(backgroundTasksActionsRedux.deleteTask, dispatch),
    cancelBackgroundTask: bindAction(backgroundTasksActionsRedux.cancelTask, dispatch),
    showGeneralLoaderWithTimeout: bindAction(showGeneralLoaderWithTimeout, dispatch),
    uploadUsersFile: bindAction(uploadCsvFile, dispatch),
    fetchUploadedFileColumns: bindAction(fetchUploadedFileColumns, dispatch),
    fetchUsersImportPreviewData: bindAction(fetchUsersImportPreviewData, dispatch),
    resetFileUpload: bindAction(resetFileUpload, dispatch),
    updateUploadProgress: bindAction(updateUploadProgress, dispatch),
    resetUsersImportPreviewData: bindAction(resetPreview, dispatch),
    fetchDepartments: bindAction(fetchDepartments, dispatch),
    fetchJobTitles: bindAction(fetchJobTitles, dispatch),
    fetchCountries: bindAction(fetchCountries, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditAccountUsersList);
