import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { range } from "lodash";
import cn from "classnames";

import { WizardNewProps } from "./wizardNewPropTypes";
import WizardHeader from "./WizardHeader";
import WizardSubHeader from "./WizardSubHeader";
import WizardFooter from "./WizardFooter";
import WizardStep from "./WizardStep";
import "./wizard.scss";

interface DeferredQueue {
  [key: string]: () => void;
}

const getAvailableStepIndexes = (children: any, activeIndex: number) => {
  const activeStep = children[activeIndex];
  const availableIndexes = range(activeIndex + 1);

  if ((activeStep.props.required && activeStep.props.isLocked) || activeStep.props.isLockNavigation) {
    return availableIndexes;
  }

  const nextRequiredInvalidStepIndex = children.findIndex(
    (x: any, i: number) => i > activeIndex && x.props.isLocked && x.props.required,
  );

  const lastAvailableStepIndex =
    nextRequiredInvalidStepIndex !== -1 ? nextRequiredInvalidStepIndex : children.length - 1;
  return [...availableIndexes, ...range(activeIndex + 1, lastAvailableStepIndex + 1)];
};

export const WizardNew = (props: WizardNewProps) => {
  const {
    children,
    isSaveInProgress,
    onProgressAsync,
    onRegressAsync,
    onFinishAsync,
    canProceedAsync,
    canRecedeAsync,
  } = props;

  const filteredChildren = children.filter((item) => !!item);

  // remember previous values
  const deferredQueueRef = useRef({} as DeferredQueue);
  const isSaveInProgressRef = useRef(isSaveInProgress);

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    props.wizardActions && props.wizardActions.openWizard();

    return () => {
      props.wizardActions && props.wizardActions.closeWizard();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSaveInProgressRef.current && !isSaveInProgress) {
      const actions = Object.values(deferredQueueRef.current);
      actions.forEach((action: () => void) => {
        action();
      });
    }

    isSaveInProgressRef.current = isSaveInProgress;
  }, [isSaveInProgress]);

  const canProceedCallbackAsync = useCallback(
    (currentIndex: number, nextIndex: number) => {
      return (canProceedAsync || (() => Promise.resolve(true)))(currentIndex, nextIndex);
    },
    [canProceedAsync],
  );

  const canRecedeCallbackAsync = useCallback(
    (currentIndex: number, nextIndex: number) => {
      return (canRecedeAsync || (() => Promise.resolve(true)))(currentIndex, nextIndex);
    },
    [canRecedeAsync],
  );

  const onProgressCallbackAsync = useCallback(
    async (currentIndex: number, nextIndex: number) => {
      const errors = await onProgressAsync?.(currentIndex, nextIndex);

      return !(errors && errors.length > 0);
    },
    [onProgressAsync],
  );

  const closeHandler = () => {
    const { onCancel } = props;
    if (isSaveInProgress) {
      deferredQueueRef.current[onCancel.name] = onCancel;
    } else {
      onCancel();
    }
  };

  const onStepSelected = async (nextIndex: number) => {
    if (activeIndex === nextIndex) {
      return;
    }

    if (nextIndex > activeIndex) {
      if (!(await canProceedCallbackAsync(activeIndex, nextIndex))) {
        return;
      }

      const succeed = await onProgressCallbackAsync(activeIndex, nextIndex);
      if (!succeed) {
        return;
      }
      props.onProgress?.(activeIndex, nextIndex);
    }

    if (nextIndex < activeIndex) {
      if (!(await canRecedeCallbackAsync(activeIndex, nextIndex))) {
        return;
      }
      props.onRegress?.(activeIndex, nextIndex);
      await onRegressAsync?.(activeIndex, nextIndex);
    }

    setActiveIndex(nextIndex);
  };

  const onNext = async () => {
    const nextIndex = activeIndex + 1;

    const canProceed = await canProceedCallbackAsync(activeIndex, nextIndex);
    if (canProceed) {
      const succeed = await onProgressCallbackAsync(activeIndex, nextIndex);
      if (succeed) {
        props.onProgress?.(activeIndex, nextIndex);
        setActiveIndex(nextIndex);
      }
    }
  };

  const onPrev = async () => {
    const newIndex = activeIndex - 1;

    const canRecede = await canRecedeCallbackAsync(activeIndex, newIndex);
    if (canRecede) {
      props.onRegress?.(activeIndex, newIndex);
      await onRegressAsync?.(activeIndex, newIndex);
      setActiveIndex(newIndex);
    }
  };

  const onFinish = async () => {
    props.onFinish?.();
    await onFinishAsync?.();
  };

  const availableStepIndexes = useMemo(
    () => getAvailableStepIndexes(filteredChildren, activeIndex),
    [filteredChildren, activeIndex],
  );
  const activeStep = filteredChildren[activeIndex];

  return (
    <div id={props.id} className={cn("main-content wizard", `active-step-${activeIndex}`, props.className)}>
      <WizardHeader
        title={props.title}
        titleForGA={props.titleForGA}
        publishedStatus={props.publishedStatus}
        onClose={closeHandler}
        changeActiveIndex={setActiveIndex}
        renderCustomHeader={props.renderCustomHeader}
      />
      <WizardSubHeader
        progressSavedDate={props.progressSavedDate}
        isSaveInProgress={isSaveInProgress}
        children={filteredChildren}
        activeIndex={activeIndex}
        onStepSelected={onStepSelected}
        availableStepIndexes={availableStepIndexes}
      />
      <WizardStep steps={filteredChildren} activeStepIndex={activeIndex} />
      <WizardFooter
        isNavigationLocked={activeStep.props.isLocked}
        activeIndex={activeIndex}
        stepsCount={filteredChildren.length}
        onNext={onNext}
        onPrev={onPrev}
        onFinish={onFinish}
        finishButtonLabel={props.finishButtonLabel}
        isFinishButtonDisabled={props.isFinishButtonDisabled}
        renderCustomFinishButton={props.renderCustomFinishButton}
        nextButtonLabel={activeStep.props.nextButtonLabel}
      />
    </div>
  );
};

export default WizardNew;
