import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { FormikProps, withFormik } from "formik";
import * as eventDetailsActions from "../../state/actions/eventDetailsActions";
import * as eventEntityStateActions from "../../state/actions/eventEntityStateActions";
import { RootState } from "../../../../Application/globaltypes/redux";
import { bindActionCreators, Dispatch } from "redux";
import { EventPayload, EventView } from "../../types/state";
import { ConfigureOwnProps } from "../types";
import validationSchemas from "../../../../../utils/validationSchemas";
import { AutosaveProps, withAutosave } from "../../../../../utils/withAutosave";
import ViewPayloadConversions from "../../utils/ViewPayloadConversions";
import EventForm from "../EventForm/EventForm";

export type ConfigurePropsAll = ConfigureOwnProps & PropsFromRedux & FormikProps<EventView> & AutosaveProps;

export class Configure extends Component<ConfigurePropsAll> {
  componentDidMount() {
    this.props.subscribeOnDiscarded?.(this.props.resetForm);
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });
  }

  componentWillUnmount() {
    this.props.unSubscribeOnDiscarded?.(this.props.resetForm);
  }

  get isEventCreated() {
    return this.props.id > 0;
  }

  static getEvent = (values: EventView): EventPayload => ({
    id: values.id,
    title: values.title.trim(),
    description: values.description.trim(),
    thumbnailUrl: values.thumbnailUrl,
    externalEventSessions: {
      id: values.id,
      sessions: ViewPayloadConversions.getPayloadFromViewSessions(values.externalEventSessions.sessions),
    },
  });

  onNext = () => {
    const { entityStateActions, values } = this.props;
    this.saveEvent();
    if (!this.isEventCreated) {
      entityStateActions.createEvent(Configure.getEvent(values));
    }
  };

  onPrevious = () => {
    this.saveEvent();
  };

  onClose = () => {
    const { entityStateActions, detailsActions } = this.props;
    detailsActions.clearEvent();
    entityStateActions.resetEventEntityState();
  };

  saveEvent = () => {
    this.props.detailsActions.saveEvent(this.props.values);
  };

  render() {
    return <EventForm disablePreventTransitionPrompt={this.isEventCreated} {...this.props} />;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigureOwnProps) => ({
  id: ownProps.entityId || state.library.events.eventEntityState.entityId,
  event: state.library.events.eventDetails.event,
  isLoaded: state.library.events.eventDetails.isEventLoaded,
  isLoading: state.library.events.eventDetails.isLoading,
  isCreating: state.library.events.eventEntityState.changingEntityState,
  isDraft: state.library.events.eventDetails.event.isDraft,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(eventEntityStateActions, dispatch),
  detailsActions: bindActionCreators(eventDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigurePropsAll, EventView, EventPayload>({
  getInitValues: (props) => Configure.getEvent(props.event),
  stateProvider: Configure.getEvent,
  entityUpdater: (props) => props.entityStateActions.updateEvent,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.eventInfo,
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: (props: ConfigureOwnProps & PropsFromRedux) => props.event,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
