import languages from "./languages";
import { find } from "lodash";

const defaultLanguageCode = "en";
const getLanguage = (code) => {
  return find(languages, (elem) => {
    return elem.code === code;
  });
};

const utils = {
  getLanguageByCode(lang) {
    let result = getLanguage(lang);

    if (!result && lang && lang.length > 2 && lang[2] === "-") {
      result = getLanguage(lang.substring(0, 2));
    }

    return result;
  },

  getLanguageByCodeOrDefault(lang) {
    let result = this.getLanguageByCode(lang);

    if (!result) {
      result = getLanguage(defaultLanguageCode);
    }

    return result;
  },

  browserCurrentLanguageOrDefault() {
    const browserLang = this.browserCurrentLanguage();
    return this.getLanguageByCodeOrDefault(browserLang);
  },

  browserCurrentSupportedLanguage() {
    const browserLang = this.browserCurrentLanguage();
    return this.getLanguageByCode(browserLang);
  },

  browserCurrentLanguage() {
    return navigator.language || navigator.userLanguage;
  },
};

export default utils;
