import { FC, useCallback, useEffect } from "react";
import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";
import { batch, connect, ConnectedProps } from "react-redux";
import { Checkbox } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import {
  addSelectedCards as addSelectedCardsAction,
  clearSelectedCards,
} from "../../../../../../Licensing/Packs/state/actions/packDetailsActions";
import { DraggableAsset } from "../../../../../../../interfaces/assetToDropInfo";
import { isPackReadOnlySelector } from "../../../../../../Application/selectors";
import { pluralize } from "../../../../../../../utils/stringUtils";

import "./cardsCount.scss";

export const CardsCount: FC<PropsFromRedux> = ({
  allSelected,
  itemsToDrop,
  addSelectedCards,
  selectedCards,
  resetSelectedCards,
  isReadOnly,
}) => {
  const selectedCardsCount = selectedCards.length;

  useEffect(() => {
    const cards = selectedCards.filter((card) => itemsToDrop.some((item) => item.id === card.id));
    batch(() => {
      resetSelectedCards();
      addSelectedCards(cards);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsToDrop]);

  const handleClick = useCallback(() => {
    if (allSelected) {
      resetSelectedCards();
    } else {
      const filteredItems = itemsToDrop.filter((item) => !selectedCards.some((card) => card.id === item.id));
      const newCards: DraggableAsset[] = filteredItems.map((item: any) => ({
        id: item.id,
        title: item.title,
        thumbnailUrl: item.thumbnailUrl,
        elementType: item.cardType,
        isReadOnly: false,
        type: "packItem",
      }));

      addSelectedCards(newCards);
    }
  }, [allSelected, selectedCards, itemsToDrop, resetSelectedCards, addSelectedCards]);

  if (isReadOnly) {
    return null;
  }

  return (
    <div className="cards-count-root">
      <div>
        <Checkbox label="Select All" checked={allSelected} onClick={handleClick} />
      </div>
      {selectedCardsCount > 0 && (
        <div>
          <b>{selectedCardsCount}</b> {pluralize("result", selectedCardsCount)} selected.
        </div>
      )}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    isReadOnly: isPackReadOnlySelector(state),
    selectedCards: state.packs.packDetailsReducer.selectedCards,
    itemsToDrop: state.packs.packContentTab.itemsToDrop.items,
    allSelected: state.packs.packContentTab.itemsToDrop.items.every((item) =>
      state.packs.packDetailsReducer.selectedCards.some((card) => card.id === item.id),
    ),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetSelectedCards: bindActionCreators(clearSelectedCards, dispatch),
    addSelectedCards: bindActionCreators(addSelectedCardsAction, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CardsCount);
