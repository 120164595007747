import { FormikProps } from "formik";
import React, { FC } from "react";
import "./recurringSessionForm.scss";
import { SessionsView } from "../../../../types/state";
import { ValidatedForm } from "../../../../../../../components/forms";
import { RecurringSessionFormBuilder } from "./RecurringSessionFormBuilder";

interface RecurringSessionFormPropsOwn {
  isLoading?: boolean;
  onBlur: (propertyName: string) => void;
  disabled?: boolean;
  save: () => void;
}

export type RecurringSessionFormProps = RecurringSessionFormPropsOwn & {
  formik: FormikProps<SessionsView>;
};

export const RecurringSessionForm: FC<RecurringSessionFormProps> = (props: RecurringSessionFormProps) => {
  const { formik } = props;
  const renderFields = (): React.ReactNode => {
    const { onBlur, disabled, save } = props;
    return (
      <RecurringSessionFormBuilder
        onChanged={save}
        session={formik.values.sessions[0]}
        disabled={disabled}
        onBlur={onBlur}
        validatedFieldProps={formik}
      />
    );
  };
  const { isLoading } = props;
  return (
    <ValidatedForm
      dirty={formik.dirty}
      isLoading={isLoading}
      formWidthComputer={16}
      parentWithFormik={true}
      disablePreventTransitionPrompt={true}
    >
      {renderFields}
    </ValidatedForm>
  );
};

export default RecurringSessionForm;
