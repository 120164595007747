import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import FlowsOverview from "./flows/flowsOverview/FlowsOverview";
import CreateFlow from "../../features/Library/Flows/Create/CreateFlow";
import EditFlow from "../../features/Library/Flows/Edit/FlowDetails";
import { Videos, CreateVideo, VideoDetails } from "../../features/Library/Videos";
import CreateEmail from "../../features/Library/Communications/Emails/Create/CreateEmail";
import { CreateEmailSendSession } from "../../features/Library/Communications/Emails/SendSessions/CreateEmailSendSession";
import EmailDetailsWrapper from "../../features/Library/Communications/Emails/Edit/EmailDetailsWrapper";
import { AssessmentsOverview, CreateAssessment, EditAssessment } from "../../features/Library/Assessments";
import { Pdfs, CreatePdf, EditPdf } from "../../features/Library/Pdfs";
import {
  CreateThreatDefenceEmailTemplate,
  EmailTemplateDetails,
  ThreatDefenceEmailTemplatesOverview,
} from "../../features/Library/SimulatedPhishing/EmailTemplates";
import {
  CampaignDetails,
  CreateThreatDefenceCampaign,
  ThreatDefenceCampaignsOverview,
} from "../../features/Library/SimulatedPhishing/Campaigns";
import {
  CreateThreatDefenceLandingPage,
  EditThreatDefenceLandingPage,
  ThreatDefenceLandingPagesOverview,
} from "../../features/Library/SimulatedPhishing/LandingPages";
import {
  CreateFeedbackPage,
  EditFeedbackPage,
  ThreatDefenceFeedbackPagesOverview,
} from "../../features/Library/SimulatedPhishing/FeedbackPages";
import {
  CreateThreatDefenceDirectSendSendingProfile,
  CreateThreatDefenceSmtpAuthSendingProfile,
  ThreatDefenceSendingProfilesOverview,
} from "../../features/Library/SimulatedPhishing/SendingProfiles";
import SurveysOverview from "../../features/Library/Surveys/Overview/SurveysOverview";
import CreateSurvey from "../../features/Library/Surveys/Create/CreateSurvey";
import EditSendingProfile from "../../features/Library/SimulatedPhishing/SendingProfiles/Edit/EditSendingProfile";
import EditSurvey from "../../features/Library/Surveys/Edit/EditSurvey";
import { RolePermissions } from "../../enums";
import NotFoundMessage from "../../components/notFound/NotFoundMessage";
import EventsOverview from "../../features/Library/Events/Overview/EventsOverview";
import CreateEvent from "../../features/Library/Events/Create/CreateEvent";
import EditEvent from "../../features/Library/Events/Edit/EditEvent";
import { CommunicationsOverview } from "../../features/Library/Communications";
import CreateMessage from "../../features/Library/Communications/Messages/Create/CreateMessage";
import { CreateMessageSendSession } from "../../features/Library/Communications/Messages/SendSessions/CreateMessageSendSession";
import EditMessage from "../../features/Library/Communications/Messages/Edit/EditMessage";
import { ProtectedRoute } from "../../components/restrictedRoute/ProtectedRoute";

const LibraryContent: FC = () => {
  return (
    <div className="route-content-container">
      <Routes>
        <Route path="flows">
          <Route
            index
            element={
              <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                <FlowsOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="createFlow"
            element={
              <ProtectedRoute permissions={[RolePermissions.FlowsCreate]}>
                <CreateFlow {...({} as any)} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/*"
            element={
              <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                <EditFlow />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="events">
          <Route
            index
            element={
              <ProtectedRoute permissions={[RolePermissions.EventsView]}>
                <EventsOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="create"
            element={
              <ProtectedRoute permissions={[RolePermissions.EventsCreate]}>
                <CreateEvent {...({} as any)} />
              </ProtectedRoute>
            }
          />
          <Route
            path=":id/*"
            element={
              <ProtectedRoute permissions={[RolePermissions.EventsView]}>
                <EditEvent />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="assets">
          <Route path="videos">
            <Route
              index
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                  <Videos />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/*"
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                  <VideoDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="createAsset"
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsCreate]}>
                  <CreateVideo {...({} as any)} />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="pdfs">
            <Route
              index
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                  <Pdfs />
                </ProtectedRoute>
              }
            />
            <Route
              path="create"
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsCreate]}>
                  <CreatePdf />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/*"
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                  <EditPdf />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="assessments">
            <Route
              index
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                  <AssessmentsOverview />
                </ProtectedRoute>
              }
            />

            <Route
              path="create"
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsCreate]}>
                  <CreateAssessment {...({} as any)} />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/*"
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                  <EditAssessment />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="surveys">
            <Route
              index
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                  <SurveysOverview />
                </ProtectedRoute>
              }
            />

            <Route
              path="create"
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsCreate]}>
                  <CreateSurvey />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/*"
              element={
                <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                  <EditSurvey />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route path="communications">
          <Route
            index
            element={
              <ProtectedRoute permissions={[RolePermissions.CommunicationsView]}>
                <CommunicationsOverview />
              </ProtectedRoute>
            }
          />
          <Route path="emails">
            <Route
              path="create"
              element={
                <ProtectedRoute permissions={[RolePermissions.CommunicationsCreate]}>
                  <CreateEmail />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/send-sessions/create"
              element={
                <ProtectedRoute permissions={[RolePermissions.CommunicationsManage]}>
                  <CreateEmailSendSession />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/*"
              element={
                <ProtectedRoute permissions={[RolePermissions.CommunicationsView]}>
                  <EmailDetailsWrapper />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="messages">
            <Route
              path="create"
              element={
                <ProtectedRoute permissions={[RolePermissions.CommunicationsCreate]}>
                  <CreateMessage {...({} as any)} />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/*"
              element={
                <ProtectedRoute permissions={[RolePermissions.CommunicationsView]}>
                  <EditMessage />
                </ProtectedRoute>
              }
            />
            <Route
              path=":id/send-sessions/create"
              element={
                <ProtectedRoute permissions={[RolePermissions.CommunicationsManage]}>
                  <CreateMessageSendSession />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route path="simulated-phishing">
          <Route path="campaigns">
            <Route index element={<ThreatDefenceCampaignsOverview />} />
            <Route path="create" element={<CreateThreatDefenceCampaign />} />
            <Route path=":id/*" element={<CampaignDetails />} />
          </Route>
          <Route path="email-templates">
            <Route index element={<ThreatDefenceEmailTemplatesOverview />} />
            <Route path="create" element={<CreateThreatDefenceEmailTemplate />} />
            <Route path=":id/*" element={<EmailTemplateDetails />} />
          </Route>
          <Route path="landing-pages">
            <Route index element={<ThreatDefenceLandingPagesOverview />} />
            <Route path="create" element={<CreateThreatDefenceLandingPage />} />
            <Route path=":id/*" element={<EditThreatDefenceLandingPage />} />
          </Route>
          <Route path="feedback-pages">
            <Route index element={<ThreatDefenceFeedbackPagesOverview />} />
            <Route path="create" element={<CreateFeedbackPage />} />
            <Route path=":id/*" element={<EditFeedbackPage />} />
          </Route>
          <Route path="sending-profiles">
            <Route index element={<ThreatDefenceSendingProfilesOverview />} />
            <Route path="create" element={<CreateThreatDefenceDirectSendSendingProfile />} />
            <Route path=":id/*" element={<EditSendingProfile {...({} as any)} />} />
            <Route path="smtp-auth/create" element={<CreateThreatDefenceSmtpAuthSendingProfile {...({} as any)} />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundMessage />} />
      </Routes>
    </div>
  );
};

export default LibraryContent;
