import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/globaltypes/redux";
import { UpdateIdentityProviderConfiguration, IdentityProviderConfiguration, IdentityProviderType } from "../../types";

export interface IdentityProviderConfigurationState extends IdentityProviderConfiguration {
  isLoading: boolean;
  error: string | undefined;
  isAccessAuthorized: boolean;
}

const initialState: IdentityProviderConfigurationState = {
  id: 0,
  identityId: "",
  metadataAutoRefresh: false,
  metadataUrl: "",
  name: "",
  type: IdentityProviderType.None,
  isLoading: false,
  error: undefined,
  isAccessAuthorized: true,
};

const identityProviderConfigurationSlice = createSlice({
  name: "identityProviderConfiguration",
  initialState: initialState,
  reducers: {
    req(state: IdentityProviderConfigurationState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: IdentityProviderConfigurationState, action: PayloadAction<IdentityProviderConfiguration>) {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    },
    err(state: IdentityProviderConfigurationState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    forbidden(state: IdentityProviderConfigurationState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isAccessAuthorized: false,
        error: action.payload,
      };
    },
    updated(state: IdentityProviderConfigurationState, action: PayloadAction<UpdateIdentityProviderConfiguration>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { req, got, err, forbidden, updated } = identityProviderConfigurationSlice.actions;

export const identityProviderConfigurationSelector = (state: RootState) =>
  state.accounts.identityProviders.editIdentityProvider.identityProviderConfiguration;
export const identityProviderConfigurationLoadingSelector = (state: RootState) =>
  state.accounts.identityProviders.editIdentityProvider.identityProviderConfiguration.isLoading;
export default identityProviderConfigurationSlice.reducer;
export type identityProviderConfigurationStateSliceType = ReturnType<typeof identityProviderConfigurationSlice.reducer>;
