import { PayloadAction } from "@reduxjs/toolkit";
import { SortingDirection } from "enums";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { createFiltersSlice, FiltersState } from "../../../../Application/slices/createFiltersSlice";
import { FlowFiltersEnum } from "../../types/models";

export interface FlowFiltersState extends FiltersState<FlowFiltersEnum> {
  search?: string;
}

export const initialState: FlowFiltersState = {
  filterOptions: {},
  appliedFilter: {},
  isLoading: false,
  pagination: 1,
  sortingColumnName: "",
  sortingDirection: SortingDirection.Descending,
  shouldSort: true,
};

const flowFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "filtersOverviewOptions",
  },
  initialState: initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    resetSearch(state) {
      state.search = "";
    },
  },
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  resetSearch,
  setSearch,
  resetShouldSort,
  setShouldSort,
  setPagination,
  resetPagination,
  setSortingColumnName,
  resetSortingColumnName,
  setSortingDirection,
  resetSortingDirection,
} = flowFiltersSlice.actions;

export default flowFiltersSlice.reducer;
