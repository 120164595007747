import { PayloadAction } from "../../../../../interfaces/redux";
import { createReducer } from "../../../../../utils/reduxUtils";
import { commonActionTypes } from "../actionTypes/commonEventActionTypes";
import { actionTypes } from "../actionTypes/eventSessionsValidatorActionTypes";

export interface EventSessionsValidationState {
  initialized: boolean;
  isFormValid?: boolean;
  errorMode: boolean;
  activeErrors: { [key: string]: string };
  touchedSnapshot: object;
  resolved: boolean;
}

export const initialState: EventSessionsValidationState = {
  initialized: false,
  isFormValid: undefined,
  errorMode: false,
  activeErrors: {},
  touchedSnapshot: {},
  resolved: true,
};

const EventSessionsValidationHandlers = () => {
  const handleSetIsValid = (
    state: EventSessionsValidationState,
    action: PayloadAction<boolean>,
  ): EventSessionsValidationState => {
    return {
      ...state,
      isFormValid: action.payload,
    };
  };

  const handleSetErrorMode = (
    state: EventSessionsValidationState,
    action: PayloadAction<boolean>,
  ): EventSessionsValidationState => {
    return {
      ...state,
      errorMode: action.payload,
    };
  };
  const handleSetResolved = (
    state: EventSessionsValidationState,
    action: PayloadAction<boolean>,
  ): EventSessionsValidationState => {
    return {
      ...state,
      resolved: action.payload,
    };
  };

  const handleSetInitialized = (
    state: EventSessionsValidationState,
    action: PayloadAction<boolean>,
  ): EventSessionsValidationState => {
    return {
      ...state,
      initialized: action.payload,
    };
  };

  const handleSetActiveErrors = (
    state: EventSessionsValidationState,
    action: PayloadAction<{ [key: string]: string }>,
  ): EventSessionsValidationState => {
    return {
      ...state,
      activeErrors: action.payload,
    };
  };

  const handleSetTouchedSnapshot = (
    state: EventSessionsValidationState,
    action: PayloadAction<object>,
  ): EventSessionsValidationState => {
    return {
      ...state,
      touchedSnapshot: action.payload,
    };
  };

  const handlerReset = () => ({
    ...initialState,
  });

  const handleDiscard = (state: EventSessionsValidationState): EventSessionsValidationState => ({
    ...state,
    errorMode: false,
  });

  return {
    [actionTypes.setIsValid]: handleSetIsValid,
    [actionTypes.setActiveErrors]: handleSetActiveErrors,
    [actionTypes.setErrorMode]: handleSetErrorMode,
    [actionTypes.setResolved]: handleSetResolved,
    [actionTypes.setInitialized]: handleSetInitialized,
    [actionTypes.setTouchedSnapshot]: handleSetTouchedSnapshot,
    [actionTypes.reset]: handlerReset,
    [commonActionTypes.discard]: handleDiscard,
  };
};

export const eventSessionsValidationReducer = createReducer(initialState, [EventSessionsValidationHandlers]);
