import { FC } from "react";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface Props {
  triggerOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
}

export const RemovePackFromAccountConfirmationModal: FC<Props> = (props: Props) => {
  return (
    <>
      <ActionConfirmationModal
        open={props.triggerOpen}
        title="Remove this pack?"
        withWarningLabel={false}
        confirmLabel="Remove"
        onContinue={props.onContinue}
        onCancel={props.onCancel}
      >
        This will remove Pack access for all users and delete all user data relating to this Pack.
      </ActionConfirmationModal>
    </>
  );
};

export default RemovePackFromAccountConfirmationModal;
