import { Dispatch } from "@reduxjs/toolkit";
import { isEmpty, some } from "lodash";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { DeleteLinkButton } from "../../../components/buttons/linkButtons";
import { RolePermissions } from "../../../enums";
import { RootState } from "../../Application/globaltypes/redux";
import Restricted from "../../Application/Restricted";
import {
  CreateIdentityProviderSuccess,
  DeleteIdentityProvidersSuccess,
} from "../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import DeleteIdentityProvidersConfirmationModal from "./deleteIdentityProviderConfirmationModal/deleteIdentityProviderConfirmationModal";
import { IdentityProvidersList } from "./IdentityProvidersList/IdentityProvidersList";
import * as identityProvidersOverviewActions from "./state/identityProvidersOverviewThunk";
import * as notificationsActions from "../../Notifications/state/notificationsActions.js";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import backgroundTask from "../../BackgroundTasks/backgroundTask";
import DeleteIdentityProvidersTask from "../CreateIdentityProvider/backgroundTasks/deleteIdentityProvidersTask";
import { bindAction } from "../../../interfaces";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./identityProvidersOverview.module.scss";
import initializeHubClient from "features/Application/services/realTimeNotification/initializeHubClient";
import { useRtn } from "hooks/useRtn";

export interface IdentityProvidersOverviewProps extends PropsFromRedux {
  isReadOnly: boolean;
}

export const IdentityProvidersOverview: React.FC<IdentityProvidersOverviewProps> = ({
  items,
  itemsCount,
  isLoading,
  fetchIdentityProviders,
  backgroundNotificationActions,
  asyncOperations,
  dispatch,
  isReadOnly,
}: IdentityProvidersOverviewProps) => {
  const navigate = useNavigate();
  const { moboId } = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedProviderIds, setSelectedProviderIds] = useState<number[]>([]);
  const [deleteIdentityProviderIds, setDeleteIdentityProviderIds] = useState<number[]>([]);

  useEffect(() => {
    if (moboId) {
      const realTimeNotificationClient = initializeHubClient(dispatch, { id: moboId }, () => asyncOperations);
      realTimeNotificationClient.connect();
      fetchIdentityProviders(moboId);
      return () => {
        realTimeNotificationClient.disconnect();
      };
    } else {
      fetchIdentityProviders();
    }
  }, [fetchIdentityProviders, moboId, asyncOperations, dispatch]);

  useRtn([CreateIdentityProviderSuccess, DeleteIdentityProvidersSuccess], () => {
    moboId ? fetchIdentityProviders(moboId) : fetchIdentityProviders();
  });

  const goToIdentityProviderCreation = () => {
    navigate("create");
  };

  const renderIdentityProviderButton = () => (
    <Restricted
      permissions={[RolePermissions.AccountsSettingsManage]}
      renderContent={(hasPermission) => (
        <Button
          floated="right"
          primary
          content="Add ID Provider"
          onClick={goToIdentityProviderCreation}
          className="create-button"
          disabled={!hasPermission}
        />
      )}
    />
  );

  const renderIdentityProviderButtonInHeader = () => isEmpty(selectedProviderIds) && renderIdentityProviderButton();

  const renderDeleteButton = () => some(selectedProviderIds) && <DeleteLinkButton onClick={handleDeleteButtonClick} />;

  const handleDeleteButtonClick = () => {
    setDeleteIdentityProviderIds(selectedProviderIds);
    setIsDeleteModalOpen(true);
  };

  const cancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = () => {
    const providersToDelete = items.filter((idp: { id: number }) => deleteIdentityProviderIds.includes(idp.id));
    const task = new DeleteIdentityProvidersTask(providersToDelete, moboId);
    backgroundTask.updateEntityDistributedOp(task, backgroundNotificationActions);

    setSelectedProviderIds(selectedProviderIds.filter((id) => !deleteIdentityProviderIds.includes(id)));
    setIsDeleteModalOpen(false);
    setDeleteIdentityProviderIds([]);
  };

  const onIdentityProviderEdit = (identityProviderId: number) => {
    navigate(identityProviderId.toString());
  };

  const onIdentityProviderDelete = (identityProviderIds: number[]) => {
    setDeleteIdentityProviderIds(identityProviderIds);
    setIsDeleteModalOpen(true);
  };

  const updateSelectedProviders = (ids: number[]) => {
    setSelectedProviderIds([...ids]);
  };

  return (
    <section className={styles["nested-content"]}>
      <div className={styles["overviewButton"]}>
        {!isReadOnly && (
          <>
            {renderIdentityProviderButtonInHeader()}
            {renderDeleteButton()}
          </>
        )}
      </div>

      <Restricted
        permissions={[RolePermissions.AccountsSettingsManage]}
        renderContent={(hasManagePermission) => (
          <IdentityProvidersList
            items={items}
            itemsCount={itemsCount}
            isLoading={isLoading}
            hasManagePermission={hasManagePermission}
            selectedItemIds={selectedProviderIds}
            updateSelectedProviders={updateSelectedProviders}
            renderCreateIdentityProviderButton={renderIdentityProviderButton}
            onEdit={onIdentityProviderEdit}
            onDelete={(identityProviderId) => onIdentityProviderDelete([identityProviderId])}
            onNavigate={onIdentityProviderEdit}
          />
        )}
      />
      <DeleteIdentityProvidersConfirmationModal
        selectedProviderIds={deleteIdentityProviderIds}
        isOpen={isDeleteModalOpen}
        onCancel={cancelDelete}
        onContinue={confirmDelete}
      />
    </section>
  );
};

const mapStateToProps = (state: RootState) => {
  const overview = state.accounts.identityProviders.overview;
  return {
    items: overview.items,
    itemsCount: overview.itemsCount,
    isLoading: overview.isLoading,
    asyncOperations: state.asyncOperations,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatch,
  fetchIdentityProviders: bindAction(identityProvidersOverviewActions.fetchIdentityProviders, dispatch),
  backgroundNotificationActions: {
    addOperation: bindAction(backgroundTasksActions.addOperationDistributedOp, dispatch),
    sendTransientNotification: bindAction(notificationsActions.sendTransientNotification, dispatch),
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(IdentityProvidersOverview);
