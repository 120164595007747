import { useEffect, FC, Fragment } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Loader } from "semantic-ui-react";

import { LicenseTypes } from "../../../../../enums";
import { RootState } from "../../../../../features/Application/globaltypes/redux";
import { fetchPackLicenseTypes } from "../../../../../features/Licensing/Packs/state/thunks/packLicenseTypesThunk";
import { PackLicenseType, RequestedPackLicenses } from "../../../../../features/Licensing/Packs/types/state";
import CardThumbnail from "../../../../cards/cardThumbnail/CardThumbnail";
import LicensePicker from "../../../../LicensePicker/LicensePicker";
import NoResults from "../../../../noResults/NoResults";
import { MAX_LICENSE_COUNT } from "../../constants";
import { AssignAccountPack, AccountPackOverview } from "../../types";
import { LicenseCountInput } from "./licenseCountInput/LicenseCountInput";

import "./licensesAddingStep.scss";

interface PackAssigningInfoPropsOwn {
  renderModalActions: (nextStep: () => void, prevStep: () => void) => Function;
  header: string;
  selectedPacks: Array<AssignAccountPack>;
  items: Array<AccountPackOverview>;
  loadLicenseTypes?: () => void;
  onLicenseCountChange: (selectedPackId: number, licensesCount?: number) => void;
  onExpirationDateChange: (selectedPackId: number, optionId?: number, date?: Date | null) => void;
  validateFinishBtnEnabling: (maxLicenseCount: number) => void;
  isLoading: boolean;
  packRequestsData?: Map<number, RequestedPackLicenses>;
  error?: Error;
  isRestore?: boolean;
}

export type PackAssigningInfoProps = PackAssigningInfoPropsOwn & PropsFromRedux;

export const LicensesAddingStep: FC<PackAssigningInfoProps> = ({
  selectedPacks,
  items,
  loadLicenseTypes,
  licenseTypes,
  onLicenseCountChange,
  validateFinishBtnEnabling,
  onExpirationDateChange,
  isLoading,
  packRequestsData,
  error,
  isRestore,
}) => {
  const finishButtonDependentField = [
    ...selectedPacks.map((p) => p.licensesCount),
    ...selectedPacks.map((p) => p.expirationDate),
    ...selectedPacks.map((p) => p.licenseType),
  ];

  useEffect(() => {
    validateFinishBtnEnabling(MAX_LICENSE_COUNT);
    // eslint-disable-next-line
  }, finishButtonDependentField);

  useEffect(() => {
    if (licenseTypes.items.length === 0) {
      loadLicenseTypes();
    }
    // eslint-disable-next-line
  }, [licenseTypes.items]);

  const renderPackIdIsNotValid = () =>
    items.length === 0 &&
    !isLoading && <NoResults title="Pack ID is not valid" iconClassName="fa-box-full"></NoResults>;

  const getLicenseOptions = (item: AccountPackOverview | undefined, options: PackLicenseType[]) =>
    options
      .filter((option) => item?.isTrialAllowed || option.id !== LicenseTypes.Trial)
      .map((option: PackLicenseType) => {
        return {
          title: option.id === LicenseTypes.Trial ? `${item?.trialPeriod.name} ${option.name}` : option.name,
          id: option.id,
          duration: option.monthsCount,
        };
      })
      .reverse();

  const renderRestoreSection = () => (
    <div className="bottom-border" data-testid="restore-section">
      <div className="restore-text">
        This will restore all content and user data for this Pack placing it in an active state.
      </div>
      <div className="restore-header">Restore Licenses</div>
    </div>
  );

  return (
    <form className="packs-filter-form" autoComplete="off">
      <div className="licenses-adding-step">
        {isRestore && renderRestoreSection()}
        {items.length > 0 &&
          !isLoading &&
          selectedPacks.map((accountPack: AssignAccountPack, index) => {
            const item = items.find((i) => i.id === accountPack.packId);
            return (
              <Fragment key={item?.id}>
                <div className="pack-assign-to-account-info" key={item?.id}>
                  <div className="thumbnail-image">
                    <CardThumbnail thumbnailUrl={item?.thumbnailImageUrl} />
                  </div>
                  <div className="pack-info">{item?.title}</div>

                  <div className="license-expires">
                    <span className="licenses-wrapper">
                      <LicenseCountInput
                        onLicenseCountChange={(selectedPackId: number, licensesCount?: number) => {
                          onLicenseCountChange(selectedPackId, licensesCount);
                        }}
                        packId={accountPack.packId}
                        licensesCount={accountPack.licensesCount}
                        assingedLicensesCount={accountPack.assignedLicensesCount}
                        maxLicenseCount={MAX_LICENSE_COUNT}
                      />
                    </span>
                    <span className="expires">
                      {licenseTypes.items.length > 0 && (
                        <LicensePicker
                          options={getLicenseOptions(item, licenseTypes.items)}
                          onOptionChange={(optionId?: number) => {
                            onExpirationDateChange(accountPack.packId, optionId);
                          }}
                          onExpirationDateChange={(date?: null | Date) => {
                            onExpirationDateChange(accountPack.packId, undefined, date);
                          }}
                          preselectedOptionId={packRequestsData?.get(accountPack.packId)?.licenseTypeId}
                          preselectedExpirationDate={packRequestsData?.get(accountPack.packId)?.expirationDate}
                          verticalPosition={index > 0 ? "top" : "bottom"}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </Fragment>
            );
          })}
        <Loader active={isLoading} />
        {error && (
          <div className="error-info" data-testid="error-info">
            Error loading data!
          </div>
        )}
        {renderPackIdIsNotValid()}
      </div>
    </form>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    licenseTypes: state.packs.packLicenseTypes,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
  loadLicenseTypes: bindActionCreators(fetchPackLicenseTypes, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LicensesAddingStep);
