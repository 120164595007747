import React from "react";
import { NoResultsWithButton } from "../../../../components";

import { ReactComponent as NoEventResult } from "../../../../images/library-events-icon-big.svg";

export interface EventsNoResultsProps {
  createEventButton?: React.ReactElement;
  filtered?: boolean;
}

const EventsNoResults: React.FC<EventsNoResultsProps> = ({ createEventButton, filtered }) => {
  return (
    <NoResultsWithButton
      title="You don’t have any events"
      description="Looks like you don't have any events."
      icon={<NoEventResult />}
      actionButton={createEventButton}
      filtered={filtered}
    />
  );
};

export default EventsNoResults;
