import { SortingDirection } from "../../../../enums";
import { Columns as UsersColumns } from "../Edit/People/Overview/UsersOverview/getColumnOptions";
import { PeopleAssignmentColumns } from "../../PeopleAssignments/Overview/helpers/getColumnOptions";
export const END_NOTE_TEXT_DEFAULT = "Thanks for taking the survey! Your answers have been recorded.";

export const GET_SURVEY_USERS_DEFAULT_PARAMS = {
  skip: 0,
  top: 10,
  orderBy: UsersColumns.Name,
  sortingDirection: SortingDirection.Ascending,
};

export const GET_SURVEY_GROUPS_DEFAULT_PARAMS = {
  skip: 0,
  top: 10,
  orderBy: PeopleAssignmentColumns.Name,
  sortingDirection: SortingDirection.Ascending,
  appliedFilter: {},
};

export const ALREADY_ASSIGNED_USER_MESSAGE = "This user already belongs to this survey";
export const ALREADY_ASSIGNED_GROUP_MESSAGE = "This group already belongs to this survey";

export const BRANCHING_QUESTION_ANSWERS_MAX_COUNT = 5;
