import { RootState } from "../../../../Application/globaltypes/redux";
import { detectIsReadOnly } from "../FlowDesigner/helpers/isReadOnly";
import { detectHasAnyPermissions } from "../FlowDesigner/helpers/hasPermissions";
import { flowDesignerSelector, flowInformationSelector } from "../../state/selectors";

export const userProfilePermissionsSelector = (state: RootState) => {
  return state.userProfile.permissions;
};

export const activeViewTabSelector = (state: RootState) => {
  return flowDesignerSelector(state).context.activeTab;
};

export const flowInspectorTypeSelectSelector = (state: RootState) => {
  const contextSelector = flowDesignerSelector(state).context;
  return contextSelector.entityType[contextSelector.activeTab];
};

export const isFlowReadOnlySelector = (state: RootState) => {
  return (
    detectIsReadOnly(flowInformationSelector(state).info) ||
    !detectHasAnyPermissions(userProfilePermissionsSelector(state))
  );
};

export const itemsToDropSelector = (state: RootState) => {
  return flowDesignerSelector(state).itemsToDrop;
};

export const itemsToDropFiltersSelector = (state: RootState) => {
  return flowDesignerSelector(state).filters;
};
