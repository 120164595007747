import { TriggerableConfirmationModal } from "components";
import { IObservable } from "interfaces";

interface Props {
  deleteObserver: IObservable<(onSubmit: () => void) => void>;
}

export const DeleteTemplateModal = (props: Props) => {
  const { deleteObserver } = props;
  return (
    <TriggerableConfirmationModal
      content={
        "Deleting this template will remove all Pack content, unlink it from customer accounts, and end your ability to manage its associated users. Are you sure you want to continue?"
      }
      onTriggerModalObserver={deleteObserver}
      confirmButtonLabel={"Delete"}
      title={"Delete Template?"}
    />
  );
};
