import { FC, useEffect } from "react";

import { RolePermissions, UsersGroupsContext } from "../../../../../enums";
import GroupsOverview from "./Overview/GroupsOverview/GroupsOverview";
import UsersOverview from "./Overview/UsersOverview/UsersOverview";
import PeopleWrapper from "../../../PeopleAssignments/Overview/PeopleWrapper/PeopleWrapper";
import { IWizardStep } from "../../../../../interfaces";

export interface EditSurveyPeoplePropsBase extends IWizardStep {
  entityId: number;
  usersGroupsContext: UsersGroupsContext;
  onContextChanged: (value: UsersGroupsContext) => void;
}

export type EditSurveyPeopleProps = EditSurveyPeoplePropsBase;

export const EditSurveyPeople: FC<EditSurveyPeopleProps> = ({
  entityId,
  usersGroupsContext,

  acceptHandlers,
  onContextChanged,
}) => {
  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PeopleWrapper
        permission={RolePermissions.AssetsManage}
        usersGroupsContext={usersGroupsContext}
        onContextChanged={onContextChanged}
        renderGroupsOverview={(customHeaderContent: React.ReactElement) => (
          <GroupsOverview surveyId={entityId} customHeaderContent={customHeaderContent} />
        )}
        renderUsersOverview={(customHeaderContent: React.ReactElement) => (
          <UsersOverview surveyId={entityId} customHeaderContent={customHeaderContent} />
        )}
      />
    </>
  );
};

export default EditSurveyPeople;
