import { SortingDirection } from "../../../../enums";
import { PeopleAssignmentColumns } from "../../PeopleAssignments/Overview/helpers/getColumnOptions";
import { Columns as UsersColumns } from "../../Surveys/Edit/People/Overview/UsersOverview/getColumnOptions";

export const DEFAULT_LOAD_COUNT = 10;
export const DEFAULT_SORTING_COLUMN = "Added";
export const DEFAULT_SORT_BY = "DateCreated";
export const DEFAULT_SORT_DIRECTION = SortingDirection.Descending;
export const DEFAULT_SORT_BY_PARAMS = `${DEFAULT_SORT_BY} ${DEFAULT_SORT_DIRECTION}`;

export const ALREADY_ASSIGNED_USER_MESSAGE = "This user already belongs to this PDF";
export const ALREADY_ASSIGNED_GROUP_MESSAGE = "This group already belongs to this PDF";
export const GET_PDF_GROUPS_DEFAULT_PARAMS = {
  skip: 0,
  top: 10,
  orderBy: PeopleAssignmentColumns.Name,
  sortingDirection: SortingDirection.Descending,
  appliedFilter: {},
};
export const GET_PDF_USERS_DEFAULT_PARAMS = {
  skip: 0,
  top: 10,
  orderBy: UsersColumns.Name,
  sortingDirection: SortingDirection.Descending,
};
