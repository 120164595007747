import React from "react";

import ItemToDropCard from "../ItemToDropCard/ItemToDropCard";

export interface Props {
  id: number;
  title: string;
  thumbnailUrl?: string;
  onClick?: () => void;
  isSelected: boolean;
}

const FlowToDropCard: React.FunctionComponent<Props> = (props) => {
  return <ItemToDropCard className="flow-to-drop-card" dataHookType="flow-to-drop-card" itemType="FLOW" {...props} />;
};

export default FlowToDropCard;
