import SortOptions from "../../../../../enums/SortOptions";

export interface AccountPacksGridSortingOptions {
  text: string;
  value: SortOptions;
  default: boolean;
}

export const gridSortingOptions: Array<AccountPacksGridSortingOptions> = [
  {
    text: "Sorted A-Z",
    value: SortOptions.Title,
    default: true,
  },
];
