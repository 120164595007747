import { useCallback, useEffect, useMemo } from "react";
import { FormikProps, withFormik } from "formik";
import { Icon, Popup } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { ValidatedForm } from "../../../../components";
import fileUtils from "../../../../utils/fileUtils";
import { ensureTrimmed } from "../../../../utils/stringUtils";
import {
  identityProviderSchema,
  multipleMetadataFileUpload,
  validIdentityProviders,
} from "../../../../utils/validationSchemas/identityProviderValidationSchemas";
import { IdentityProviderConfigInfo, IdentityProviderType } from "../../types";
import { LinkLoaderStatus, ParsedLinkData } from "../../../../components/linkLoader/LinkLoader";
import { ApiCallStatus, useApiCall } from "../../../../hooks/useApiCall";
import identityProvidersDataService from "../../services/identityProvidersDataService";
import FieldLabel from "../../../../components/forms/FieldLabel";

import "./identityProviderConfiguration.scss";

const ssoTypeOptions = [
  {
    value: IdentityProviderType.Saml2,
    text: "SAML",
  },
  {
    value: IdentityProviderType.WsFederation,
    text: "WsFederation",
  },
];

const autoRefreshInfo =
  "BrainStorm will check the metadata file in the IdP Server every evening. If there has been an update or change to the file, it will be automatically imported into BrainStorm.";

const mapApiCallStatusToLoaderStatus = (apiCallStatus: ApiCallStatus) => {
  if (apiCallStatus.isLoading) {
    return LinkLoaderStatus.Loading;
  }
  if (apiCallStatus.error) {
    return LinkLoaderStatus.Failure;
  }
  if (apiCallStatus.result) {
    return LinkLoaderStatus.Success;
  }

  return LinkLoaderStatus.None;
};

export interface IdentityProviderConfigOwnProps {
  providerConfiguration: IdentityProviderConfigInfo;
  onSubmit(providerConfig: IdentityProviderConfigInfo): void;
  bindResetForm(resetForm: () => void): void;
  bindSubmitForm(submitForm: () => void): void;
  onMetadataContentUrlChange(metadataContentUrl: string): void;
  onIsValidChange?(isValid: boolean): void;
  onTypeChange?(type: IdentityProviderType): void;
  isEdit?: boolean;
  onDirtyChanged?(isDirty: boolean): void;
  onMetadataUrlChanged?(url?: string): void;
  isReadOnly?: boolean;
  moboId?: string;
  onIdentityProviderChange?: (identityProvider: string) => void;
}

export type IdentityProviderConfigProps = IdentityProviderConfigOwnProps & FormikProps<IdentityProviderConfigInfo>;

export const IdentityProviderConfiguration: React.FC<IdentityProviderConfigProps> = (props) => {
  const {
    values,
    errors,
    dirty,
    touched,
    isValid,
    onIsValidChange,
    handleChange,
    handleReset,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    bindResetForm,
    bindSubmitForm,
    resetForm,
    submitForm,
    providerConfiguration,
    onMetadataContentUrlChange,
    onTypeChange,
    onDirtyChanged,
    onMetadataUrlChanged,
    isReadOnly = false,
    isEdit = false,
  } = props;

  const validatedFieldProps = {
    errors,
    touched,
    dirty,
    isFormValid: isValid,
    handleChange,
    handleReset,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    resetForm,
  };

  useEffect(() => {
    if (bindResetForm) {
      bindResetForm(resetForm);
    }
    if (bindSubmitForm) {
      bindSubmitForm(submitForm);
    }
  }, [bindResetForm, bindSubmitForm, resetForm, submitForm]);

  useEffect(() => {
    onDirtyChanged?.(dirty);
  }, [onDirtyChanged, dirty]);

  useEffect(() => {
    onMetadataUrlChanged?.(values.metadataUrl);
  }, [values.metadataUrl, onMetadataUrlChanged]);

  useEffect(() => {
    if (isEmpty(values.metadataUrl)) {
      setFieldValue("metadataAutoRefresh", false);
    }
  }, [values.metadataUrl, setFieldValue]);

  const onMetadataUrlChange = useCallback(
    (metadataUrl: string) => {
      if (!isEmpty(metadataUrl)) {
        onMetadataContentUrlChange("");
      }
    },
    [onMetadataContentUrlChange],
  );

  const setValidatedFieldError = useCallback(
    (fieldName: string, apiCallStatus: ApiCallStatus) => {
      if (!apiCallStatus.isLoading && apiCallStatus.error?.response?.data?.error) {
        setFieldTouched(fieldName, true, false);
        setFieldError(fieldName, apiCallStatus.error.response.data.error);
      }
    },
    [setFieldError, setFieldTouched],
  );

  const [uploadFile, uploadFileStatus, resetFileUploadStatus] = useApiCall(
    identityProvidersDataService.uploadMetadataFromFile,
    onMetadataContentUrlChange,
    [values.metadataFile],
  );
  const onUploadFile = useCallback((files: File[]) => uploadFile(files[0]), [uploadFile]);
  useEffect(
    () => setValidatedFieldError("metadataFile", uploadFileStatus),
    [setValidatedFieldError, uploadFileStatus, errors],
  );
  const fileUploadingProgress = uploadFileStatus.result || uploadFileStatus.error ? 100 : 0;

  useEffect(() => {
    resetFileUploadStatus();
  }, [resetFileUploadStatus, values.metadataFile]);

  const [uploadUrl, uploadUrlStatus] = useApiCall(
    identityProvidersDataService.uploadMetadataFromUrl,
    onMetadataContentUrlChange,
    [values.metadataUrl],
  );
  const onUploadUrl = useCallback((data: ParsedLinkData) => uploadUrl(data.url), [uploadUrl]);
  useEffect(
    () => setValidatedFieldError("metadataUrl", uploadUrlStatus),
    [setValidatedFieldError, uploadUrlStatus, errors],
  );

  const handleTypeChange = useCallback(
    (_: any, data: { value: IdentityProviderType }) => {
      onTypeChange?.(data.value);
    },
    [onTypeChange],
  );

  const filesValue = useMemo(() => (values.metadataFile ? [values.metadataFile] : undefined), [values.metadataFile]);

  const setSingleFileValue = useCallback(
    (field: string, value: FileList, shouldValidate?: boolean) => {
      const file = value ? fileUtils.toFileLike(value[0]) : undefined;
      if (!file) {
        onMetadataContentUrlChange("");
      }
      setFieldValue(field, file, shouldValidate);
    },
    [onMetadataContentUrlChange, setFieldValue],
  );

  const handleSsoIdpChange = (_propertyName: string, value: string) => {
    setFieldValue("identityProvider", value);
    let newType: IdentityProviderType;
    // Update conditional SSO Type
    switch (value) {
      case "Microsoft Entra ID":
      case "ADFS":
        newType = IdentityProviderType.WsFederation;
        break;
      case "Google or SAML":
      case "Okta":
        newType = IdentityProviderType.Saml2;
        break;
      case "Other":
      default:
        newType = IdentityProviderType.None;
    }
    setFieldValue("type", newType);
    handleTypeChange(null, { value: newType });
    props.onIdentityProviderChange?.(value);
  };

  return (
    <div className="provider-container">
      <ValidatedForm
        disablePreventTransitionPrompt
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message:
            "Are you sure you want to exit without saving this identity provider? All information entered will be lost.",
        }}
        {...props}
      >
        {isEdit && <div className="identity-id">IDENTITY PROVIDER ID: {providerConfiguration.identityProviderId}</div>}
        <ValidatedForm.InputField
          label="SSO Provider"
          value={values.name}
          placeholder="Enter name"
          propertyName="name"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          disabled={isReadOnly}
          onBlur={() => ensureTrimmed(values.name, (value) => setFieldValue("name", value))}
          {...validatedFieldProps}
        />
        {!isEdit && (
          <ValidatedForm.DropdownField
            label="SSO Identity Provider (IdP)"
            value={values.identityProvider}
            options={validIdentityProviders}
            propertyName="identityProvider"
            placeholder="Select Identity Provider"
            markAsRequired
            {...validatedFieldProps}
            setFieldValue={handleSsoIdpChange}
          />
        )}
        <ValidatedForm.DropdownField
          placeholder="Select SSO Type"
          label="SSO Type"
          value={values.type}
          propertyName="type"
          options={ssoTypeOptions}
          disabled={isEdit || values.identityProvider !== "Other"}
          lazyLoad
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          {...validatedFieldProps}
          onChangeCallback={handleTypeChange}
        />
        <div className="metadata-fields">
          <FieldLabel label="METADATA (XML REQUIRED)" markAsRequired />
        </div>
        <ValidatedForm.UploadFileField
          id="fileUpload"
          label="File"
          loadingButtonLabel="Loading..."
          accept=".xml"
          propertyName="metadataFile"
          value={filesValue}
          isUploading={uploadFileStatus.isLoading}
          uploadingProgress={fileUploadingProgress}
          uploadingError={uploadFileStatus.error}
          uploadFilesHandler={onUploadFile}
          filesValidator={multipleMetadataFileUpload}
          onIsFormValidChange={onIsValidChange}
          showPercents={false}
          persistProgressBarOnSuccess
          disabled={isReadOnly || !isEmpty(values.metadataUrl) || uploadUrlStatus.isLoading}
          clearable
          indeterminate
          {...validatedFieldProps}
          setFieldValue={setSingleFileValue}
        />
        <label className="metadata-separator">-or-</label>
        <ValidatedForm.LinkLoader
          label="URL"
          placeholder="Enter URL"
          propertyName="metadataUrl"
          disabled={isReadOnly || uploadUrlStatus.isLoading || !isEmpty(filesValue) || uploadFileStatus.isLoading}
          clearable
          onIsFormValidChange={onIsValidChange}
          onLinkParsed={onUploadUrl}
          buttonName="Insert"
          onBlur={handleBlur}
          indeterminate
          value={values.metadataUrl}
          onChange={onMetadataUrlChange}
          emulateParsing={false}
          loaderStatus={mapApiCallStatusToLoaderStatus(uploadUrlStatus)}
          {...validatedFieldProps}
        />
        <div className="checkbox-field">
          <ValidatedForm.CheckboxField
            {...validatedFieldProps}
            label="Automatically update Metadata"
            data-testid="metadataAutoRefresh"
            value={values.metadataAutoRefresh}
            disabled={isReadOnly || isEmpty(values.metadataUrl)}
            propertyName="metadataAutoRefresh"
          />
          <Popup
            content={autoRefreshInfo}
            trigger={<Icon className="info circle" />}
            hideOnScroll
            position="top center"
            style={{ maxWidth: `${25}rem` }}
            inverted
          />
        </div>
      </ValidatedForm>
    </div>
  );
};

export default withFormik<IdentityProviderConfigOwnProps, IdentityProviderConfigInfo>({
  validationSchema: (props: IdentityProviderConfigOwnProps) => {
    const { providerConfiguration, moboId, isEdit } = props;
    return identityProviderSchema(providerConfiguration.name, moboId, isEdit);
  },
  mapPropsToValues: (props: IdentityProviderConfigOwnProps) => props.providerConfiguration,
  handleSubmit: (values, bag) => {
    const { providerConfiguration } = bag.props;
    const castedValues = identityProviderSchema(providerConfiguration.name).cast(values);
    bag.props.onSubmit(castedValues as IdentityProviderConfigInfo);
  },
  enableReinitialize: true,
  validateOnMount: true,
})(IdentityProviderConfiguration);
