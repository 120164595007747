import { Dispatch } from "redux";
import DataService from "../../services/videoDataService";
import {
  reqEngagementFunnelData,
  reqEngagementLineData,
  reqEngagementPeopleData,
  setEngagementError,
  setEngagementFunnelData,
  setEngagementFunnelError,
  setEngagementLineData,
  setEngagementPeopleData,
  setEngagementPeopleError,
} from "../slices/videoPerformanceSlice";
import { getAxiosError } from "../../../../../utils/axiosError";
import { BasePerformanceRequestFilterParams, VideoPerformanceRequestFilterParams } from "../../../Common/models";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { AlertTypes } from "../../../../../enums";
import { downloadExcelExport } from "../../../Common/utils/performanceUtils";
import { mockEmptyVideoEngagementData } from "../../types/mocks";
import { setExporting } from "features/Reporting/state/export/exportSlice";

export const fetchEngagementLineData = (videoId: number, filterParams: VideoPerformanceRequestFilterParams = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementLineData());
    try {
      const data = await DataService.getVideoEngagement(videoId, filterParams);

      dispatch(
        setEngagementLineData({
          data,
        }),
      );
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        dispatch(reqEngagementLineData());
      } else {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementLineDataV3 = (videoId: number, filterParams: VideoPerformanceRequestFilterParams = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementLineData());
    try {
      const data = await DataService.getVideoEngagementV3(videoId, filterParams);

      dispatch(
        setEngagementLineData({
          data,
        }),
      );
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        dispatch(reqEngagementLineData());
      } else {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementFunnelData = (videoId: number, filterParams: VideoPerformanceRequestFilterParams = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementFunnelData());
    try {
      let data = await DataService.getVideoEngagementFunnel(videoId, filterParams);
      data = {
        ...mockEmptyVideoEngagementData,
        ...data,
      };

      dispatch(
        setEngagementFunnelData({
          data,
        }),
      );
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        dispatch(reqEngagementFunnelData());
      } else {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementFunnelError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementFunnelDataV3 = (videoId: number, filterParams: VideoPerformanceRequestFilterParams = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementFunnelData());
    try {
      let data = await DataService.getVideoEngagementFunnelV3(videoId, filterParams);
      data = {
        ...mockEmptyVideoEngagementData,
        ...data,
      };

      dispatch(
        setEngagementFunnelData({
          data,
        }),
      );
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        dispatch(reqEngagementFunnelData());
      } else {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementFunnelError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementPeopleData = (videoId: number, filterParams: VideoPerformanceRequestFilterParams = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementPeopleData());
    try {
      let data = await DataService.getVideoEngagementPeople(videoId, filterParams);

      dispatch(
        setEngagementPeopleData({
          data,
        }),
      );
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        dispatch(reqEngagementPeopleData());
      } else {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementPeopleError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementPeopleDataV3 = (videoId: number, filterParams: VideoPerformanceRequestFilterParams = {}) => {
  return async (dispatch: Dispatch) => {
    dispatch(reqEngagementPeopleData());
    try {
      let data = await DataService.getVideoEngagementPeopleV3(videoId, filterParams);

      dispatch(
        setEngagementPeopleData({
          data,
        }),
      );
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        dispatch(reqEngagementPeopleData());
      } else {
        const errorMessage = getAxiosError(err);
        dispatch(setEngagementPeopleError({ errorMessage }));
      }
    }
  };
};

export const doExport = (videoId: number, filterParams: BasePerformanceRequestFilterParams, videoTitle: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await DataService.getExport(videoId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, videoTitle);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};

export const doExportV3 = (videoId: number, filterParams: BasePerformanceRequestFilterParams, videoTitle: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await DataService.getExportV3(videoId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, videoTitle);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};