import { GoalView } from "features/Library/Flows/types/models";
import nameof from "utils/nameof";
import * as Yup from "yup";

export const goalValidationSchema: Yup.Schema<GoalView> = Yup.object().shape({
  objectiveId: Yup.number().notRequired().nullable(),
  typeId: Yup.number().notRequired().nullable(),
  metricId: Yup.number().notRequired().nullable(),
  dataSourceId: Yup.number().notRequired().nullable(),
  applicationId: Yup.number()
    .nullable()
    .when([nameof<GoalView>("dataSourceId")], ([source], schema) =>
      source ? schema.required("Application is required.") : schema.notRequired(),
    ),
  measureId: Yup.number()
    .nullable()
    .when([nameof<GoalView>("dataSourceId")], ([source], schema) =>
      source ? schema.required("Measure is required.") : schema.notRequired(),
    ),
});
