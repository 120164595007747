import { createReducer } from "../../../../../../../utils/reduxUtils";
import * as actionTypes from "../../state/actionTypes/threatDefenceSendingProfileDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/threatDefenceSendingProfileEntityStateActionTypes";
import {
  ThreatDefenceDirectSendSendingProfileDetailsState,
  ThreatDefenceDirectSendSendingProfileView,
  ThreatDefenceDirectSendSendingProfile,
  ThreatDefenceDirectSendSendingProfileVerification,
} from "../../types/state";
import { PayloadAction } from "../../../../../../../interfaces/redux";

const initialState: ThreatDefenceDirectSendSendingProfileDetailsState = {
  sendingProfile: {
    id: -1,
    isDraft: true,
    host: "",
    isSsl: false,
    dateModified: undefined,
    hasBeenPublished: false,
    sendingMethodId: -1,
  },
  isVerified: false,
  isLoading: false,
  isSendigProfileLoaded: false,
};

const normalizeSendingProfilePayload = (
  initial: ThreatDefenceDirectSendSendingProfileView,
  payload: ThreatDefenceDirectSendSendingProfile,
): ThreatDefenceDirectSendSendingProfileView => ({
  ...initial,
  ...payload,
});

const getHandlers = (failureHandler: Function) => {
  const {
    getThreatDefenceSendingProfileBegin,
    getThreatDefenceSendingProfileSuccess,
    getThreatDefenceSendingProfileFailure,
  } = actionTypes;

  const getThreatDefenceSendingProfileBeginHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getThreatDefenceSendingProfileSuccessHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceDirectSendSendingProfile>,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    sendingProfile: {
      ...normalizeSendingProfilePayload(state.sendingProfile, action.payload),
    },
    isSendigProfileLoaded: true,
    isLoading: false,
  });

  return {
    [getThreatDefenceSendingProfileBegin]: getThreatDefenceSendingProfileBeginHandler,
    [getThreatDefenceSendingProfileSuccess]: getThreatDefenceSendingProfileSuccessHandler,
    [getThreatDefenceSendingProfileFailure]: failureHandler,
  };
};

const publishHandlers = (failureHandler: Function) => {
  const {
    publishThreatDefenceSendingProfileBegin,
    publishThreatDefenceSendingProfileSuccess,
    publishThreatDefenceSendingProfileFailure,
  } = entityStateActionTypes;

  const publishThreatDefenceSendingProfileBeginHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    isLoading: true,
  });

  const publishThreatDefenceSendingProfileSuccessHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    sendingProfile: {
      ...state.sendingProfile,
      isDraft: false,
    },
    isLoading: false,
  });

  return {
    [publishThreatDefenceSendingProfileBegin]: publishThreatDefenceSendingProfileBeginHandler,
    [publishThreatDefenceSendingProfileSuccess]: publishThreatDefenceSendingProfileSuccessHandler,
    [publishThreatDefenceSendingProfileFailure]: failureHandler,
  };
};

const fetchLockedHandlers = (failureHandler: Function) => {
  const {
    fetchLockedThreatDefenceSendingProfileBegin,
    fetchLockedThreatDefenceSendingProfileSuccess,
    fetchLockedThreatDefenceSendingProfileFailure,
  } = entityStateActionTypes;

  const fetchLockedThreatDefenceSendingProfileBeginHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchLockedThreatDefenceSendingProfileSuccessHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    sendingProfile: {
      ...state.sendingProfile,
      isDraft: true,
    },
    isLoading: false,
  });

  return {
    [fetchLockedThreatDefenceSendingProfileBegin]: fetchLockedThreatDefenceSendingProfileBeginHandler,
    [fetchLockedThreatDefenceSendingProfileSuccess]: fetchLockedThreatDefenceSendingProfileSuccessHandler,
    [fetchLockedThreatDefenceSendingProfileFailure]: failureHandler,
  };
};

const threatDefenceSendingProfileDetailsHandlers = () => {
  const {
    saveThreatDefenceSendingProfile,
    saveThreatDefenceDirectSendSendingProfileVerification,
    clearThreatDefenceSendingProfile,
    setSendingProfile,
    setSendingProfileIsLoaded,
  } = actionTypes;

  const failureHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
    action: PayloadAction<Error>,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const saveThreatDefenceSendingProfileHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceDirectSendSendingProfileView>,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    sendingProfile: action.payload,
  });

  const saveThreatDefenceDirectSendSendingProfileVerificationHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceDirectSendSendingProfileVerification>,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => {
    const payload = action.payload;
    return {
      ...state,
      sendingProfile: {
        ...state.sendingProfile,
      },
      isVerified: payload.isVerified,
    };
  };

  const clearThreatDefenceSendingProfileHandler = (): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...initialState,
  });

  const setSendingProfileHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceDirectSendSendingProfile>,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    sendingProfile: {
      ...normalizeSendingProfilePayload(state.sendingProfile, action.payload),
    },
  });

  const setSendingProfileIsLoadedHandler = (
    state: ThreatDefenceDirectSendSendingProfileDetailsState,
  ): ThreatDefenceDirectSendSendingProfileDetailsState => ({
    ...state,
    isSendigProfileLoaded: true,
  });

  return {
    [saveThreatDefenceSendingProfile]: saveThreatDefenceSendingProfileHandler,
    [saveThreatDefenceDirectSendSendingProfileVerification]:
      saveThreatDefenceDirectSendSendingProfileVerificationHandler,
    [clearThreatDefenceSendingProfile]: clearThreatDefenceSendingProfileHandler,
    [setSendingProfile]: setSendingProfileHandler,
    [setSendingProfileIsLoaded]: setSendingProfileIsLoadedHandler,
    ...getHandlers(failureHandler),
    ...publishHandlers(failureHandler),
    ...fetchLockedHandlers(failureHandler),
  };
};

export const threatDefenceSendingProfileDetailsReducer = createReducer(initialState, [
  threatDefenceSendingProfileDetailsHandlers,
]);
