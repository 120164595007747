import { useFormik } from "formik";
import { noop } from "lodash";

import { PriorityLevels } from "../../../enums";
import LabelWithInfoTooltip from "../../labelWithInfoTooltip/labelWithInfoTooltip";
import PriorityLevelDropdown from "../priorityLevelDropdown/PriorityLevelDropdown";
import validationSchemas from "../../../utils/validationSchemas";
import { PriorityLevelFormValues } from "../../../interfaces";
import DaysToCompleteInput from "../daysToCompleteInput/daysToCompleteInput";
import FlexibleDaysToCompleteInput from "../flexibleDaysToCompleteInput/FlexibleDaysToCompleteInput";

import styles from "./priorityLevel.module.scss";
import { useEffect, useMemo, useRef } from "react";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { PeopleType } from "features/Library/PeopleAssignments/types";

export interface PriorityLevelProps<T> {
  onChange: (propertyName: string, value: any) => void;
  onValidChange: (...args: any[]) => any;
  item: T;
  disableDefault?: boolean;
  peopleType: PeopleType;
}

export const PriorityLevel = <T extends PriorityLevelFormValues>(props: PriorityLevelProps<T>) => {
  const { item, peopleType, disableDefault, onValidChange } = props;
  const { priorityLevel, approxEstimateMinutes } = item;
  const flexiblePriorityDueDate = useFeatureFlag(FeatureFlags.FlexiblePriorityDueDate);

  const initialValues = useRef(props.item);
  const schema = useMemo(() => {
    return flexiblePriorityDueDate
      ? validationSchemas.priorityV2(disableDefault)
      : validationSchemas.priority(disableDefault);
  }, [disableDefault, flexiblePriorityDueDate]);

  const form = useFormik<T>({
    initialValues: initialValues.current,
    validationSchema: schema,
    validateOnMount: true,
    onSubmit: noop,
  });
  const { isValid } = form;

  useEffect(() => {
    onValidChange(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const onFieldChange = (propertyName: string) => async (value: any) => {
    await form.setFieldValue(propertyName, value, true);
    props.onChange(propertyName, value);
  };

  const tooltipText = flexiblePriorityDueDate
    ? "Set the relative priority of this content. Choose 'Required' to specify a number of days or a fixed completion date."
    : "Sets relative priority to other content. Select Required to enter days to complete.";

  return (
    <div className={styles.priorityLevel}>
      <div className={styles.priorityLevelSelection}>
        <LabelWithInfoTooltip width={16} label="Priority Level" info={tooltipText} />
        <PriorityLevelDropdown
          priorityLevel={priorityLevel}
          onChange={onFieldChange("priorityLevel")}
          disableDefault={disableDefault}
        />
      </div>
      {!flexiblePriorityDueDate && priorityLevel === PriorityLevels.required && (
        <DaysToCompleteInput
          onDaysToCompleteChange={props.onChange}
          approxEstimateMinutes={approxEstimateMinutes}
          daysToComplete={form.values.daysToComplete}
          {...form}
        />
      )}
      {flexiblePriorityDueDate && priorityLevel === PriorityLevels.required && (
        <FlexibleDaysToCompleteInput
          peopleType={peopleType}
          approxEstimateMinutes={approxEstimateMinutes}
          daysToComplete={form.values.daysToComplete}
          fixedDueDate={form.values.fixedDueDate}
          isFixedDueDate={form.values.isFixedDueDate}
          onChange={onFieldChange}
          {...form}
        />
      )}
    </div>
  );
};
