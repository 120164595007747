import { Component } from "react";
import { Routes, Route } from "react-router-dom";
import { PackListingsSegments } from "../../features/Licensing/Packs/Listings";
import { CreatePack, EditPack } from "../../features/Licensing/Packs";
import { PacksOwnership, RolePermissions } from "../../enums";
import NotFoundMessage from "../../components/notFound/NotFoundMessage";
import PacksOverviewSegments from "../../features/Licensing/Packs/Overview/PacksOverviewSegments";
import PackPurchasedViewSegments from "../../features/Licensing/Packs/PurchasedView/PackPurchasedViewSegments";

import { ProtectedRoute } from "../../components/restrictedRoute/ProtectedRoute";

class PacksContent extends Component {
  renderCreateEditPackProtectedRoute = (isCreate, permissions) => {
    return <ProtectedRoute permissions={permissions}>{isCreate ? <CreatePack /> : <EditPack />}</ProtectedRoute>;
  };

  render() {
    return (
      <div className="route-content-container">
        <Routes>
          <Route path="packs">
            <Route index element={<PacksOverviewSegments ownership={PacksOwnership.Own} />} />
            <Route path="available" element={<PacksOverviewSegments ownership={PacksOwnership.Available} />} />
            <Route
              path="create"
              element={this.renderCreateEditPackProtectedRoute(true, [
                RolePermissions.PacksManage,
                RolePermissions.FlowsCreate,
              ])}
            />
            <Route path="listings/:id/*" element={<PackListingsSegments />} />
            <Route path="purchased/:id/*" element={<PackPurchasedViewSegments />} />
            <Route
              path=":id/*"
              element={this.renderCreateEditPackProtectedRoute(false, [
                RolePermissions.PacksManage,
                RolePermissions.FlowsCreate,
              ])}
            />
          </Route>
          <Route element={<NotFoundMessage />} />
        </Routes>
      </div>
    );
  }
}

export default PacksContent;
