import React from "react";
import { isNil } from "lodash";

import { ModalWithStepsStep } from "../../../../modal/ModalWithSteps";
import { ListViewWithHeaderAndPanel } from "../../../../listView";
import { GroupFilterForm } from "../../../../filterForms";
import { SortingDirection, RolePermissions } from "../../../../../enums";

import { UserGroupsAssignment } from "../../GroupsAssignmentModal";
import { GroupListRow, columnOptions } from "./GroupListRow/GroupListRow";
import { Group } from "../../../../../interfaces";

import "../../../assignmentListStep.scss";
import GroupsNoResultsWithCreate from "../../../../../features/People/Groups/GroupsOverview/GroupsNoResults/GroupsNoResultsWithCreate";

import Restricted from "../../../../../features/Application/Restricted";
import AccessRestrictedMessage from "../../../../restrictedRoute/AccessRestrictedMessage";

export interface GroupListStepProps extends ModalWithStepsStep {
  accountId: number;
  groups: Group[];
  groupsAmount: number;
  loadPage: (
    skip: number,
    top: number,
    sortingColumnName: any,
    sortingDirection: SortingDirection,
    filter: any,
  ) => void;
  userGroupsAssignments: UserGroupsAssignment[];
  isLoading: boolean;
  filter: any;
  selectedGroupIds: number[];
  selectedUserIds: number[];
  applyFilter: (filter: any) => void;
  resetFilter: () => void;
  filterOptions: any;
  fetchFilterOptions: (accountId: number) => void;
  onSelectedGroupsChanged: (groupIds: number[]) => void;
  hasPermissionPredicate: (userPermissions: RolePermissions[]) => boolean;
}

const GroupListStep = (props: GroupListStepProps) => {
  React.useEffect(() => {
    const { accountId, fetchFilterOptions } = props;
    fetchFilterOptions(accountId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGroupsForAssign = () => {
    const { groups, userGroupsAssignments, selectedUserIds } = props;

    return groups.map((group) => {
      let hasAssignments = false;
      let assignedCount = 0;

      selectedUserIds.forEach((userId) => {
        const isUserAssigned = !isNil(
          userGroupsAssignments.find((x) => x.userId === userId && x.groupIds.includes(group.id)),
        );

        if (isUserAssigned) {
          assignedCount++;
        }

        hasAssignments = hasAssignments || isUserAssigned;
      });

      return {
        ...group,
        isAssigned: assignedCount === selectedUserIds.length,
        hasAssignments,
      };
    });
  };

  const groupsForAssign = getGroupsForAssign();

  const isDisabled = (g: Group) => g.isAssigned;
  const isWarning = (g: Group) => g.hasAssignments;

  return (
    <Restricted
      permissions={[]}
      placeholder={<AccessRestrictedMessage />}
      permissionPredicate={(userPermissions, _) => props.hasPermissionPredicate(userPermissions)}
    >
      <div className="assignment-list-step">
        <ListViewWithHeaderAndPanel
          columnOptions={columnOptions}
          searchPlaceholder={"Search for Groups..."}
          loadPage={props.loadPage}
          itemsAmount={props.groupsAmount}
          isLoading={props.isLoading}
          items={groupsForAssign}
          onSelectedListItemsChanged={props.onSelectedGroupsChanged}
          buildTableBody={(group: any) => (
            <GroupListRow
              group={group}
              userGroupsAssignments={props.userGroupsAssignments}
              issuingUsersCount={props.selectedUserIds.length}
            />
          )}
          filter={props.filter}
          selectedItemIds={props.selectedGroupIds}
          noResultsContent={<GroupsNoResultsWithCreate />}
          applyFilter={props.applyFilter}
          filterOptions={props.filterOptions}
          resetFilter={props.resetFilter}
          filterOptionsLoading={props.filterOptions.isLoading}
          renderFilterForm={() => <GroupFilterForm withEnrollmentFilter={false} />}
          sortingColumnName={columnOptions[0].name}
          sortingDirection={SortingDirection.Ascending}
          isSelectDisabled={isDisabled}
          isWarning={isWarning}
        />
      </div>
    </Restricted>
  );
};

export default GroupListStep;
