import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import { TagsToContent } from "../../../../interfaces";
import { BatchResponse } from "../../../../interfaces/batchResponse";
import { GetPagedDataByIdRequestV3, WithPagedDataV2 } from "../../../../interfaces/getPagedDataRequest";
import { DistributedOperationResult } from "../../../../types";
import {
  Filters,
  formatFiltersV2api as formatUserFiltersV2api,
  mapToV2Filters,
  serializeArrayAndFilterNullable,
} from "../../../../utils/queryUtils";
import { escapeTerm } from "../../../../utils/searchUtils";
import { v2FilterMap } from "../../../People/types";
import { BasePerformanceRequestFilterParams } from "../../Common/models";
import { Reminder } from "../types/models";
import {
  GetRegistrations,
  GetReminders,
  GetSessionReporting,
  SaveReminderRequest,
  SaveTagsRequest,
} from "../types/requests";
import { IEventsDataService } from "../types/services";
import { EventPayload, EventRegistration, SessionReporting, SessionsPayload } from "../types/state";
import { eventRegistrations, getDelayedFunc, sessionReportingItems } from "./helpers";

let exportAbortController = new AbortController();

const ApiUrl = "/api/external-events";

interface ShowPurchasedConfig {
  params: {
    showPurchased: boolean;
  };
}

export interface GetEventsConfig {
  params: {
    skip?: number;
    top?: number;
    orderBy?: string;
    filter?: string;
  };
}

export interface GetEventsConfigV3 {
  params: {
    skip?: number;
    top?: number;
    sortBy?: string;
    sortOrder?: string;
    filter?: Filters;
    term?: string;
  };
}

const eventsDataService: IEventsDataService = {
  getPublishedEventsByIdsAsync(ids: string[], showPurchased: boolean) {
    const config = {
      params: {
        showPurchased,
        filter: ids.map((x) => `(PrimaryId eq ${x})`).join(" or "),
      },
    };

    return axios.get(`${ApiUrl}/published`, config);
  },

  updateEventAsync(data: EventPayload) {
    return axios.put(`${ApiUrl}/${data.id}`, data);
  },

  getEventUsersV2<T>(entityId: number, request: WithPagedDataV2<T>) {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
    };
    return axios.get(`/api/v4/content/externalEvent/${entityId}/users`, config);
  },

  getEventGroupsV2(request: GetPagedDataByIdRequestV3) {
    const { entityId, skip, top, term, filter } = request;
    return axios.get(`/api/v4/content/externalEvent/${entityId}/groups`, {
      params: {
        skip,
        top,
        ...formatUserFiltersV2api(mapToV2Filters(filter!, v2FilterMap)),
        term: escapeTerm(term),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  async getEventDensity(filterParams: BasePerformanceRequestFilterParams, signal: AbortSignal) {
    return axios.get("/api/reports/v2/events/linechart", {
      params: {
        ...filterParams,
      },
      signal,
    });
  },

  async getEventActivity(filterParams: BasePerformanceRequestFilterParams, signal: AbortSignal) {
    return axios.get("/api/reports/v2/events/engagement", {
      params: {
        ...filterParams,
      },
      signal,
    });
  },

  async getSessionDetail(filterParams: BasePerformanceRequestFilterParams) {
    return axios.get("/api/reports/v2/events/sessions", {
      params: {
        ...filterParams,
      },
    });
  },

  async getEventSessionUserDetail(filterParams: BasePerformanceRequestFilterParams) {
    return axios.get("/api/reports/v2/events/people-details", {
      params: {
        ...filterParams,
      },
    });
  },

  async getExport(eventId: number, filterParams: BasePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();
    return axios.get("/api/dataexport/v2/events/sessions", {
      responseType: "blob",
      params: {
        eventId,
        ...filterParams,
      },
      signal: exportAbortController.signal,
    });
  },

  async getDrilldownExport(eventId: number, sessionId: string, sessionStart: string, filterParams: BasePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();
    return axios.get("/api/dataexport/v2/events/people-details", {
      responseType: "blob",
      params: {
        eventId,
        sessionId,
        sessionStart,
        ...filterParams,
      },
      signal: exportAbortController.signal,
    });
  },

  updateSessionsAsync(eventSessions: SessionsPayload): Promise<AxiosResponse<void>> {
    return axios.put(`${ApiUrl}/${eventSessions.id}/sessions`, { externalEventSessions: eventSessions.sessions });
  },

  getEventAsync(id: number): Promise<AxiosResponse<EventPayload>> {
    return axios.get(`${ApiUrl}/${id}`);
  },

  duplicateEventsAsync(externalEventsIds: number[]): Promise<AxiosResponse<DistributedOperationResult>> {
    return axios.post(`${ApiUrl}/duplicate`, { externalEventsIds });
  },

  getEventsTagsAsync(showPurchased: boolean) {
    return axios.get(`/api/v2/external-events/tags`, { params: { showPurchased } });
  },

  getPublishersFilterOptions(showPurchased: boolean) {
    const config: ShowPurchasedConfig = {
      params: {
        showPurchased: showPurchased,
      },
    };
    return axios.get(`/api/external-events/publishers`, config);
  },

  getReminders(request: GetReminders): Promise<AxiosResponse<Reminder[]>> {
    return axios.get(`/api/external-events/${request.eventId}/reminders`, {
      params: {
        skip: request.skip,
        top: request.top,
        orderBy: request.sortOrder ? `${request.sortBy} ${request.sortOrder}` : request.sortBy,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getRegistrations: function (_: GetRegistrations): Promise<AxiosResponse<EventRegistration[]>> {
    return Promise.resolve({
      status: 200,
      statusText: "",
      headers: {
        "x-bsi-recordscount": "12",
      },
      config: {
        headers: {} as AxiosRequestHeaders,
      },
      data: eventRegistrations,
    });
  },

  getSessionReporting(request: GetSessionReporting): Promise<AxiosResponse<SessionReporting[]>> {
    const { skip, top } = request;

    const getItems = (): AxiosResponse => ({
      status: 200,
      statusText: "",
      headers: {
        "x-bsi-recordscount": "40",
      },
      config: {
        headers: {} as AxiosRequestHeaders,
      },
      data: sessionReportingItems.slice(skip, skip + top),
    });

    return getDelayedFunc(1500, getItems);
  },

  saveReminder(request: SaveReminderRequest) {
    return axios.post(`/api/external-events/${request.eventId}/reminders`, request.reminder);
  },

  deleteEvents(ids: Array<number>): Promise<AxiosResponse<BatchResponse>> {
    const payload = { externalEventsIds: ids.map((id) => id.toString()) };
    return axios.delete("/api/assets/external-events", { data: payload });
  },

  deleteReminders(eventId: number, reminderIds: number[]): Promise<AxiosResponse<BatchResponse>> {
    return axios.delete(`/api/external-events/${eventId}/reminders`, {
      data: {
        ids: reminderIds,
      },
    });
  },

  addEventTags(tags: TagsToContent): Promise<AxiosResponse<BatchResponse>> {
    return axios.post("/api/assets/external-events/tags", tags);
  },

  saveTags(request: SaveTagsRequest) {
    return axios.put(`/api/assets/external-events/${request.id}/tags`, {
      tagType: request.tagType,
      tags: request.tags,
    });
  },
};

export default eventsDataService;
