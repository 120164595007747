import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RolePermissions } from "enums";

export interface UserProfileState {
  id: number;
  name: string;
  accountId: number;
  originAccountId: number;
  accountTypeId: number;
  isMobo: boolean;
  isUserMoboDataSet: boolean;
  hasGlobalAccess: boolean;
  permissions: RolePermissions[];
  packIds: number[];
  groupIds: number[];
  isUserProfileLoaded: boolean;
  moboAccountName?: string;
  moboAccountLogoUrl?: string | null;
  department: string | null;
  isDemo: boolean;
  timeZone?: string;
}

const initialState: UserProfileState = {
  id: -1,
  name: "",
  accountId: -1,
  originAccountId: -1,
  accountTypeId: -1,
  isMobo: false,
  isUserMoboDataSet: false,
  hasGlobalAccess: false,
  permissions: [],
  packIds: [],
  groupIds: [],
  isUserProfileLoaded: false,
  department: null,
  isDemo: false
};

interface UserProfileLoadPayload {
  permissions: RolePermissions[];
  hasGlobalAccess: boolean;
  accountTypeId: number;
  packIds: number[];
  groupIds: number[];
  isDemo: boolean;
  timeZone?: string;
}

interface SetMoboPayload {
  moboId: number;
  moboName: string;
  moboLogoUrl?: string | null;
}

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    fetchUserProfileSuccess(state, action: PayloadAction<UserProfileLoadPayload>) {
      const { payload } = action;
      state.permissions = payload.permissions;
      state.accountTypeId = payload.accountTypeId;
      state.packIds = payload.packIds;
      state.groupIds = payload.groupIds;
      state.hasGlobalAccess = payload.hasGlobalAccess;
      state.isUserProfileLoaded = true;
      state.isDemo = payload.isDemo;
      state.timeZone = payload.timeZone;
    },
    setUserProfileMoboDataSuccess(state, action: PayloadAction<SetMoboPayload>) {
      state.accountId = action.payload.moboId;
      state.isMobo = true;
      state.moboAccountName = action.payload.moboName;
      state.moboAccountLogoUrl = action.payload.moboLogoUrl;
      state.isUserMoboDataSet = true;
    },
    profileLoaded(state, action) {
      const profile = action.payload;
      if (!state.isMobo) {
        const originAccountId = parseInt(profile.OriginAccountId);
        state.accountId = originAccountId;
        state.originAccountId = originAccountId;
      }
      state.id = parseInt(profile.sub);
      state.name = profile.name!;
      state.department = profile.Department;
    },
  },
});

export const { fetchUserProfileSuccess, setUserProfileMoboDataSuccess, profileLoaded } = userProfileSlice.actions;

export default userProfileSlice.reducer;
