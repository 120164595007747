import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getAsyncOperations from "./getAsyncOperations";

export interface AsyncOperationState {
  [key: string]: Array<number>;
}

export interface AsyncOperationPayload {
  id: number;
  action: string;
}

const initialize = () => {
  const result: AsyncOperationState = {};
  getAsyncOperations().forEach((asyncOperationName) => (result[asyncOperationName] = []));
  return result;
};

const getIds = (state: AsyncOperationState, action: string) => new Set<number>(state[action] || []);

const initialState: AsyncOperationState = initialize();

const asyncOperationSlice = createSlice({
  name: "asyncOperations",
  initialState,
  reducers: {
    beginAsyncOperation(state: AsyncOperationState, payloadAction: PayloadAction<AsyncOperationPayload>) {
      const { id, action } = payloadAction.payload;
      if (!state[action]) {
        return;
      }
      const ids = getIds(state, action);
      ids.add(id);
      state[action] = Array.from(ids);
    },
    endAsyncOperation(state: AsyncOperationState, payloadAction: PayloadAction<AsyncOperationPayload>) {
      const { id, action } = payloadAction.payload;
      if (!state[action]) {
        return;
      }
      const ids = getIds(state, action);
      if (ids.has(id)) {
        ids.delete(id);
        state[action] = Array.from(ids);
      }
    },
  },
});

export const { beginAsyncOperation, endAsyncOperation } = asyncOperationSlice.actions;

export type AsyncOperationType = ReturnType<typeof asyncOperationSlice.reducer>;

export default asyncOperationSlice.reducer;
