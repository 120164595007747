import React from "react";
import { ReactComponent as CancelIcon } from "../../../../images/cancel-circle.svg";
import BaseCircleIconButton from "../BaseCircleIconButton";
import PropTypes from "prop-types";

function CancelButton(props) {
  let { onClick } = props;
  const tooltipText = "Cancel";

  let icon = <CancelIcon id="cancel-circle-button" onClick={onClick} />;

  return <BaseCircleIconButton icon={icon} tooltipText={tooltipText} />;
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
};

export default CancelButton;
