import { DetailsHeader, NotFoundMessage, Segments } from "components";
import { RouteNames } from "enums";
import { Route, Routes } from "react-router-dom";
import OverviewReport from "./Overview/OverviewReport";

const pages = [{ to: "", label: "Overview" }];

export const DashboardReport = () => (
  <div className="root nested-content">
    <DetailsHeader title="Dashboard" />
    <Segments to={`/${RouteNames.reporting}/${RouteNames.overview}`}>
      {pages.map((page, i) => (
        <Segments.Segment key={i} {...page} />
      ))}
    </Segments>
    <Routes>
      <Route path="" element={<OverviewReport />} />
      <Route path="*" element={<NotFoundMessage />} />
    </Routes>
  </div>
);
