import { useCallback } from "react";

import AnswersBuilderBase from "../../../../Common/Answer/AnswersBuilders/AnswersBuilderBase";

import { IAnswerBase, IQuestion, ValidationContext, ValidationValueBase } from "../../../../Common/models";
import { AnswersBuilderPropsBase } from "../../../../Common/props";
import { AnswersMap } from "../Providers/AnswersMap";
import { getAnswerDefaultData } from "../Providers/defaultAnswerProvider";
import { AssessmentAnswerType } from "../../../types/state";

export const AnswersBuilder = (props: AnswersBuilderPropsBase<IQuestion<any>>) => {
  const { onBlur, onChanged, disabled, question, propertyNamePrefix } = props;

  const renderAnswer = useCallback(
    (
      answer: IAnswerBase,
      index: number,
      validatedFieldProps: ValidationContext<ValidationValueBase<IQuestion<any>>>,
    ) => {
      const { Component } = AnswersMap[answer.answerType as AssessmentAnswerType];
      let answerCopy = answer;
      if (answer.answerType === AssessmentAnswerType.SingleText) {
        answerCopy = {
          ...answer,
          answer: {
            ...answer.answer,
            isCorrect: question.question.correctAnswer === index,
          },
        };
      }
      return (
        <Component
          answer={answerCopy}
          index={index}
          validatedFieldProps={validatedFieldProps}
          disabled={disabled}
          onBlur={onBlur}
          onChanged={onChanged}
          propertyNamePrefix={propertyNamePrefix}
        />
      );
    },
    [disabled, onBlur, onChanged, question.question.correctAnswer, propertyNamePrefix],
  );

  return <AnswersBuilderBase renderAnswer={renderAnswer} defaultDataProvider={getAnswerDefaultData} {...props} />;
};
