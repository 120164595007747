import { tagEntity } from "../../../../components/addTagsModal/helpers";
import { TagsEnum } from "../../../../interfaces/TagsEnum";
import UpdateParams from "../../../../interfaces/updateParams";
import { pluralize } from "../../../../utils/stringUtils";
import { AppDispatch, AppThunk } from "../../../Application/globaltypes/redux";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";

export const getBatchAddEntityTagsThunk =
  (
    entityName: string,
    getMessageIds: (entityIds: number[], tags: string[], tagType: TagsEnum) => () => Promise<string[]>,
  ) =>
  (ids: number[], tags: string[], entityType: TagsEnum): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!tags.length) {
      return;
    }

    const bannerPrefix = pluralize(tagEntity[entityType], tags.length);

    const updateParams: UpdateParams = {
      getMessageIds: getMessageIds(ids, tags, entityType),
      id: `Add${entityName}Tags`,
      title: `${bannerPrefix} adding`,
      successTransientMessage: `${bannerPrefix} ${tags.length === 1 ? "has" : "have"} been added!`,
      failureTransientMessage: `${bannerPrefix} add failed!`,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };
