import { FC, useState } from "react";
import { IObservable } from "interfaces/IObservable";
import TriggeredConfirmationModal from "components/triggeredConfirmationModal/TriggerableConfirmationModal";
import { pluralize } from "utils/stringUtils";

export interface RemovePacksConfirmationModalProps {
  onTriggerRemovePacksObserver: IObservable<(onRemoveConfirm: () => void, selectedItemsCount: number) => void>;
  onConfirmed?: () => void;
}

export const RemovePacksConfirmationModal: FC<RemovePacksConfirmationModalProps> = (
  props: RemovePacksConfirmationModalProps,
) => {
  const { onTriggerRemovePacksObserver, onConfirmed } = props;

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const contentTypeString = pluralize("Pack", selectedItemsCount);

  return (
    <TriggeredConfirmationModal
      title={`Remove ${contentTypeString}?`}
      content={`This will remove all content from this ${contentTypeString} across all customer accounts in this template. Are you sure you want to continue?`}
      confirmButtonLabel={`Remove ${contentTypeString}`}
      onConfirmed={onConfirmed}
      onTriggerModalObserver={onTriggerRemovePacksObserver}
      onTrigger={(input: number) => {
        setSelectedItemsCount(input);
      }}
    />
  );
};
