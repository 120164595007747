import { PackView } from "../types/models";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { createFetchingItemsSlice, FetchingItemsState } from "features/Application/slices/createFetchingItemsSlice";

export interface TemplatePacksState extends FetchingItemsState<PackView> {}

export const initialState: TemplatePacksState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const slice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "Packs",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset } = slice.actions;

export default slice.reducer;
