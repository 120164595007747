import { BRANCHING_QUESTION_ANSWERS_MAX_COUNT } from "../../types/constants";
import { SurveyQuestionType } from "../../types/models";
import { ISurveyQuestion } from "./QuestionForm/types";

export const allowedBranchingQuestionTypes = [SurveyQuestionType.SingleChoiceTextQuestion];

export const getAnswersCount = (question: ISurveyQuestion<any>) => {
  const count = question.answers.length;

  return question.otherAnswerOptions?.includeOtherAsAnswer ? count + 1 : count;
};

export const isAllowedBranchingQuestionType = (type: SurveyQuestionType) => {
  return allowedBranchingQuestionTypes.includes(type);
};

export const answersLimitReached = (question: ISurveyQuestion<any>) => {
  return !!question.isBranching && getAnswersCount(question) >= BRANCHING_QUESTION_ANSWERS_MAX_COUNT;
};

export const nameofWithPrefix = <T>(prefix: string) => {
  return (name: keyof T) => `${prefix}${String(name)}`;
};
