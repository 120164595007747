import * as Yup from "yup";
import { requiredTextFieldMin, requiredTextFieldMinMax } from "../validationSchemas/commonValidationSchemas";
import awesomeDebouncePromise from "awesome-debounce-promise";
import packsDataService from "../../features/Licensing/Packs/services/packsDataService";

const debounceTimeMs = 500;

const isTitleUnique = awesomeDebouncePromise(packsDataService.isTitleUniqueAsync, debounceTimeMs);

const thumbnailUrl = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string().trim().required("Thumbnail Image is required");
const title = (titleInitialValue?: string) =>
  requiredTextFieldMinMax("Title", 3, 64).test("unique-title", "Pack Title should be unique", (value, context) => {
    if (value && value !== titleInitialValue) {
      return isTitleUnique(value, context.parent.id);
    }

    return true;
  });

const longDescription = (longDescriptionInitialValue?: string) =>
  requiredTextFieldMin("Long Description", 100)
    .max(15000, `Long Description exceeds the allowed number of characters`)
    .default(longDescriptionInitialValue);

export const packConfigure = (
  titleInitialValue?: string,
  longDescriptionInitialValue?: string
) =>
  Yup.object().shape({
    title: title(titleInitialValue),
    description: requiredTextFieldMinMax("Short Description", 3, 255),
    longDescription: longDescription(longDescriptionInitialValue),
    thumbnailImageUrl: thumbnailUrl(),
  });
