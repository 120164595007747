import DatePickerField from "components/forms/DatePickerField";
import { ErrorPosition } from "components/forms/ValidatedField";
import LabelWithInfoTooltip from "components/labelWithInfoTooltip/labelWithInfoTooltip";
import { PeopleType } from "features/Library/PeopleAssignments/types";
import { FormikProps } from "formik";
import moment from "moment";
import React from "react";
import { Radio } from "semantic-ui-react";
import dateTimeUtils from "utils/dateTimeUtils";
import cn from "classnames";

import { ValidatedFormWithoutFormik } from "../../forms";

import styles from "./flexibleDaysToCompleteInput.module.scss";

export interface FlexibleDaysToCompleteInputProps {
  peopleType: PeopleType;
  approxEstimateMinutes: number;
  onValidChange?: (...args: any[]) => any;
  daysToComplete?: number;
  fixedDueDate?: string;
  isFixedDueDate?: boolean;
  onChange: Function;
}

function FlexibleDaysToCompleteInput(props: FlexibleDaysToCompleteInputProps & FormikProps<any>) {
  const {
    onValidChange,
    daysToComplete,
    approxEstimateMinutes,
    errors,
    touched,
    isValid,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    dirty,
    resetForm,
    fixedDueDate,
    isFixedDueDate,
    onChange,
    values,
    isSubmitting,
    isValidating,
    submitCount,
    peopleType,
  } = props;

  const validatedFieldProps = {
    errors,
    touched,
    isValid,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    dirty,
    resetForm,
    values,
    isSubmitting,
    isValidating,
    submitCount,
  };

  const days = Number(daysToComplete) > 0 ? daysToComplete : "";
  const minsPerDay = daysToComplete ? Math.ceil(approxEstimateMinutes / daysToComplete) : 0;
  const fixedDate = fixedDueDate ? dateTimeUtils.formatDate(fixedDueDate) : undefined;
  const minDate = moment().add(1, "days");
  const maxDate = moment().add(100, "days");

  const handleDateChange = (_: React.SyntheticEvent<HTMLElement>, data: { name: string; value: string }) => {
    let newDate = moment(new Date(data.value + " " + moment().local().format("HH:mm:ss")));
    onChange("fixedDueDate")(newDate.toISOString() ?? data.value);
  };

  const daysToCompleteTooltipText =
    peopleType === PeopleType.User
      ? "Requires content to be completed within a set number of days from the date this user was added to this Flow."
      : "Requires content to be completed within a set number of days from the date they are added to the group, manually or automatically.";

  const fixedDueDateTooltipText =
    peopleType === PeopleType.User
      ? "Requires content to be completed by a fixed date, regardless of when users are added."
      : "Requires content to be completed by a fixed date, regardless of when users are added to the group.";

  return (
    <div className={styles.root}>
      <ValidatedFormWithoutFormik>
        <div className={styles.section}>
          <Radio
            name={`RadioGroup${values.id}`}
            value={`DaysToComplete${values.id}`}
            checked={!isFixedDueDate}
            onChange={() => {
              onChange("isFixedDueDate")(false);
            }}
          />
          <LabelWithInfoTooltip
            width={16}
            label="Days to complete"
            info={daysToCompleteTooltipText}
            labelClass={cn({ [styles.disabled]: isFixedDueDate })}
          />
          <ValidatedFormWithoutFormik.InputField
            className={styles.days}
            propertyName="daysToComplete"
            value={days}
            isFormValid={isValid}
            onIsFormValidChange={onValidChange}
            autoFocus
            maxLength={7}
            errorPosition="right"
            disabled={isFixedDueDate}
            handleChange={(_, data) => {
              onChange("daysToComplete")(parseInt(data.value) || undefined);
            }}
            mask={/^([1-9]\d*|\b|^$)$/}
            {...validatedFieldProps}
          />
          {!!days && !isNaN(minsPerDay) && (
            <div className={cn(styles.minutes, { [styles.disabled]: isFixedDueDate })}>
              Approx. {minsPerDay} mins per day
            </div>
          )}
        </div>
        <div className={styles.section}>
          <Radio
            name={`RadioGroup${values.id}`}
            value={`FixedDate${values.id}`}
            checked={isFixedDueDate}
            onChange={() => {
              onChange("isFixedDueDate")(true);
            }}
          />
          <LabelWithInfoTooltip
            width={16}
            label="Fixed Date"
            info={fixedDueDateTooltipText}
            labelClass={cn({ [styles.disabled]: !isFixedDueDate })}
          />
          <div className={styles.date}>
            <DatePickerField
              selected={fixedDate}
              propertyName="fixedDueDate"
              minDate={dateTimeUtils.formatDate(minDate)}
              maxDate={dateTimeUtils.formatDate(maxDate)}
              disabled={!isFixedDueDate}
              onDateChange={handleDateChange}
              errorPosition={ErrorPosition.bottom}
              placeholder="Enter Date"
              {...props}
            />
          </div>
        </div>
      </ValidatedFormWithoutFormik>
    </div>
  );
}

export default FlexibleDaysToCompleteInput;
