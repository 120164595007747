import { Dispatch } from "@reduxjs/toolkit";
import {
  fetchBegin as licensesFetchBegin,
  fetchSuccess as licensesFetchSuccess,
  fetchFailure as licensesFetchFailure,
  reset as licensesReset,
} from "../slices/licenseConfirmationSlice";
import {
  fetchBegin as packsFetchBegin,
  fetchSuccess as packsFetchSuccess,
  fetchFailure as packsFetchFailure,
  setContentPack,
  reset as packsReset,
  FlowAssignment,
} from "../slices/packsContextSlice";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { PacksContextItem, PacksContextItemModel } from "../../../../../interfaces";
import AssetTypes from "../../../../../enums/assetTypes";
import EventTypes from "../../../../../enums/eventTypes";
import CommunicationTypes from "../../../../../enums/communicationTypes";
import ItemTypes from "../../../../../enums/packItemsTypes";
import arrayUtils from "../../../../../utils/arrayUtils";
import { Context } from "../../LicenseConfirmationModalContent/licenseConfirmationInfo/LicenseConfirmationInfo";
import {
  FlowConflict,
  PackCombination,
  PacksContextResponseModel,
} from "../../../../../interfaces/licenseConfirmation";
import { AxiosResponse } from "axios";
import { PotentialFlowConflictCollection } from "./potentialFlowConflictCollection";

export const fetchAssignmentInfo =
  (context: Context, packIds?: number[], userIds?: number[], groupIds?: number[], flowId?: number, packId?: number) =>
  async (dispatch: Dispatch) => {
    dispatch(licensesFetchBegin());
    try {
      let packIdsModified: number[] | undefined = packIds;
      if (context === Context.AddPeopleToGroups) {
        const groupPacks = await dataService.packs.getGroupsPacksAsync(groupIds!);
        packIdsModified = groupPacks.data.map((gp) => gp.packs.map((p) => p.id)).flat();
      }

      if (packIdsModified && packIdsModified.length > 0) {
        let userContentAssignmentInfo;
        if (packId) {
          userContentAssignmentInfo = await dataService.packs.getPackPeopleAssignmentInfoAsync(packIdsModified, packId);
        } else if (flowId) {
          userContentAssignmentInfo = await dataService.packs.getPackUserFlowAssignmentInfoAsync(
            packIdsModified,
            flowId,
          );
        } else {
          userContentAssignmentInfo = await dataService.packs.getPackUserContentAssignmentInfoAsync(
            packIdsModified,
            userIds,
            [Context.AddPeopleToContent, Context.AddPacksToUserOrGroup, Context.AddPackToUsersOrGroups].includes(
              context,
            )
              ? groupIds
              : [],
          );
        }

        dispatch(
          licensesFetchSuccess({
            items: userContentAssignmentInfo.data,
            totalCount: userContentAssignmentInfo.data.length,
          }),
        );
      } else {
        dispatch(
          licensesFetchSuccess({
            items: [],
            totalCount: 0,
          }),
        );
      }
    } catch (e) {
      dispatch(licensesFetchFailure(e as Error));
    }
  };

export const licensesResetAction = () => async (dispatch: Dispatch) => {
  dispatch(licensesReset());
};

export const setContentPackAction =
  (itemId: string, itemType: string, packId?: number) => async (dispatch: Dispatch) => {
    dispatch(setContentPack({ itemId, itemType, packId }));
  };

export const resetContentPacksAction = () => async (dispatch: Dispatch) => {
  dispatch(packsReset());
};

export const fetchContentPacksAction = (items: PacksContextItem[]) => async (dispatch: Dispatch) => {
  dispatch(packsFetchBegin());
  try {
    const result: PacksContextItemModel[] = [];
    let flowConflicts: Record<number, PackCombination[]> = {};
    let flowAssignments: FlowAssignment[] = [];
    const itemTypesMeta = {
      [AssetTypes.Video.toLowerCase() as Lowercase<AssetTypes.Video>]: {
        fetchData: dataService.assets.getPublishedVideosByIds,
        newItemsIds: [],
      },
      [`${AssetTypes.Assessment.toLowerCase()}`]: {
        fetchData: dataService.assessments.getPublishedAssessmentsByIdsV2,
        newItemsIds: [],
      },
      [`${AssetTypes.Survey.toLowerCase()}`]: {
        fetchData: dataService.surveys.getPublishedSurveysByIds,
        newItemsIds: [],
      },
      [`${EventTypes.ExternalEvent.toLowerCase()}`]: {
        fetchData: dataService.events.getPublishedEventsByIdsAsync,
        newItemsIds: [],
      },
      [`${CommunicationTypes.Email.toLowerCase()}`]: {
        fetchData: dataService.emails.getPublishedEmailsByIdsAsync,
        newItemsIds: [],
      },
      [`${CommunicationTypes.Message.toLowerCase()}`]: {
        fetchData: dataService.messages.getPublishedMessagesByIds,
        newItemsIds: [],
      },
      [`${ItemTypes.Flow.toLowerCase()}`]: {
        fetchData: dataService.flows.getPublishedFlowsByIdsForItemsToDropDesignerAsync,
        newItemsIds: [],
      },
      [`${AssetTypes.Pdf.toLowerCase()}`]: {
        fetchData: dataService.pdfs.getPublishedPdfsByIdsAsync,
        newItemsIds: [],
      },
      pack: {
        newItemsIds: [],
      },
    };

    const areFlowsOnly = areAllItemsOneType(items, "flow");
    const arePacksOnly = areAllItemsOneType(items, "pack");

    const packsResults = await getContentPacks(itemTypesMeta, items, areFlowsOnly);

    if (areFlowsOnly || arePacksOnly) {
      const conflicts = (packsResults as AxiosResponse<FlowConflict[]>[])[0].data;
      const [realConflicts, assignments] = new PotentialFlowConflictCollection(conflicts).toRealConflicts();

      items.forEach((item) => {
        if (!conflicts.some((conflict) => conflict.id === parseInt(item.id))) {
          assignments.push({ flowId: parseInt(item.id), packIds: [] });
        }
      });

      flowAssignments = assignments;
      flowConflicts = realConflicts.reduce((prev, current) => {
        return { ...prev, [current.id]: current.packCombinations };
      }, {});
    } else {
      processContentPacks(packsResults as AxiosResponse<PacksContextResponseModel[]>[], itemTypesMeta, items, result);

      const itemDataResults = await getItemsData(itemTypesMeta);
      const allItemsData =
        itemDataResults.length > 0 ? itemDataResults.map((r) => r.data).reduce((a, b) => a.concat(b)) : [];

      result.forEach((item) => {
        const itemData = allItemsData.filter((d: any) => d.id.toString() === item.id)[0];

        if (itemData) {
          item.title = itemData.title;
          item.durationInSeconds = itemData.durationInSeconds;
          item.thumbnailUrl = itemData.thumbnailUrl;
        }
      });
    }
    const packsContext = {
      items: arrayUtils.sortArrayAlphabeticallyByProperty(result, (x) => x.title),
      totalCount: result.length,
      flowConflicts: flowConflicts,
      flowAssignments: flowAssignments,
    };

    dispatch(packsFetchSuccess(packsContext));
    return packsContext;
  } catch (e) {
    dispatch(packsFetchFailure(e as Error));
  }
};

export const fetchDraftPackContextAction = (items: PacksContextItem[]) => async (dispatch: Dispatch) => {
  dispatch(packsFetchBegin());
  try {
    const item = items[0];
    let flowConflicts: Record<number, PackCombination[]> = {};
    let flowAssignments: FlowAssignment[] = [];

    const packsResults = await dataService.packs.getDraftPackConflictsInfoAsync(parseInt(item.id));

    const conflicts = packsResults.data;
    const [realConflicts, assignments] = new PotentialFlowConflictCollection(conflicts).toRealConflicts();

    if (!conflicts.some((conflict) => conflict.id === parseInt(item.id))) {
      assignments.push({ flowId: parseInt(item.id), packIds: [] });
    }

    flowAssignments = assignments;
    flowConflicts = realConflicts.reduce((prev, current) => {
      return { ...prev, [current.id]: current.packCombinations };
    }, {});

    const packsContext = {
      items: [],
      totalCount: 0,
      flowConflicts: flowConflicts,
      flowAssignments: flowAssignments,
    };

    dispatch(packsFetchSuccess(packsContext));
    return packsContext;
  } catch (e) {
    dispatch(packsFetchFailure(e as Error));
  }
};

const areAllItemsOneType = (items: PacksContextItem[], itemType: string) =>
  !!items.length && items.every((item) => item.type.toLowerCase() === itemType);

const processContentPacks = (
  packsResults: AxiosResponse<PacksContextResponseModel[]>[],
  itemTypesMeta: any,
  items: PacksContextItem[],
  result: PacksContextItemModel[],
) => {
  packsResults.forEach((contentPacksResult) => {
    const newItems = contentPacksResult.data.filter(
      (d: any) => !items.some((i) => i.id === d.assetId && i.type.toLowerCase() === d.type.toLowerCase()),
    );

    newItems.forEach((newItem: any) => {
      if (
        newItem.packs.length > 1 &&
        itemTypesMeta[newItem.type.toLowerCase()].newItemsIds.indexOf(newItem.assetId as never) < 0
      ) {
        itemTypesMeta[newItem.type.toLowerCase()].newItemsIds.push(newItem.assetId as never);
      }
    });

    contentPacksResult.data.forEach((d: any) => {
      const oldItem = items.filter((i) => i.id === d.assetId && i.type.toLowerCase() === d.type.toLowerCase())[0];
      const existingPackItem = result.filter(
        (r) => r.id === d.assetId && r.type.toLowerCase() === d.type.toLowerCase(),
      )[0];

      if (existingPackItem) {
        existingPackItem.flowIds = existingPackItem.flowIds.concat(d.flowIds ?? []);
      } else {
        result.push({
          id: d.assetId,
          type: d.type,
          packId: d.packs.length === 1 ? d.packs[0].id : undefined,
          packs: arrayUtils.sortArrayAlphabeticallyByProperty(d.packs, (x) => x.name),
          title: oldItem?.title,
          thumbnailUrl: oldItem?.thumbnailUrl,
          durationInSeconds: oldItem?.durationInSeconds,
          flowIds: d.flowIds ?? oldItem?.flowIds ?? [],
        });
      }
    });
  });
};

const getContentPacks = (itemTypesMeta: any, items: PacksContextItem[], areFlowsOnly: boolean) => {
  const filteredContentTypes = Object.keys(itemTypesMeta).filter((type) =>
    items.some((i) => i.type.toLowerCase() === type),
  );

  return Promise.all(
    filteredContentTypes.map((type) => {
      const itemsByType = items.filter((i) => i.type.toLowerCase() === type);
      const ids = itemsByType.map((e) => e.id);

      if (type === "pack") {
        return dataService.packs.getPackConflictsInfoAsync(ids);
      }

      if (areFlowsOnly) {
        return dataService.packs.getFlowConflictsInfoAsync(ids);
      }

      return dataService.packs.getContentPacksInfoAsync(type, ids);
    }),
  );
};

const getItemsData = (itemTypesMeta: any) => {
  return Promise.all(
    Object.keys(itemTypesMeta)
      .filter((type) => itemTypesMeta[type].newItemsIds.length > 0)
      .map((type) => {
        return itemTypesMeta[type].fetchData(itemTypesMeta[type].newItemsIds, true);
      }),
  );
};
