const PREFIX = "EVENT_SESSIONS_VALIDATION";

export const actionTypes = {
  setIsValid: `${PREFIX}_SET_IS_VALID`,
  setResolved: `${PREFIX}_SET_RESOLVED`,
  setErrorMode: `${PREFIX}_SET_ERROR_MODE`,
  setActiveErrors: `${PREFIX}_SET_ACTIVE_ERRORS`,
  setInitialized: `${PREFIX}_SET_INITIALIZED`,
  setTouchedSnapshot: `${PREFIX}_SET_TOUCHED_SNAPSHOT`,
  reset: `${PREFIX}_RESET`,
};
