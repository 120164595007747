import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import publishedStatusPropType from "./publishedStatusPropType";

import "./publishedStatus.scss";

function PublishedStatus(props) {
  const { publishedStatus, altLabel } = props;
  const className = cn("point", publishedStatus);

  return publishedStatus ? (
    <div className="published-status">
      <span className={className} />
      <span className={cn("label", { "alt-label": altLabel })}>{publishedStatus}</span>
    </div>
  ) : null;
}

PublishedStatus.propTypes = {
  publishedStatus: publishedStatusPropType,
  altLabel: PropTypes.string,
};

export default PublishedStatus;
