import React from "react";
import DatePickerWrapper, { DatePickerProps } from "../../stories/wrappers/DatePickerWrapper";
import { FormikProps } from "formik";
import ValidatedField, { ErrorPosition } from "./ValidatedField";

export interface DatePickerFieldOwnProps {
  propertyName: string;
  label?: string;
  markAsRequired?: boolean;
  onBlur?: (propertyName: string) => void;
}

export interface ValidatedFieldProps {
  errorPosition: ErrorPosition;
}

export type DatePickerFieldProps<T> = DatePickerProps & DatePickerFieldOwnProps & FormikProps<T> & ValidatedFieldProps;

export default function DatePickerField<T>(props: DatePickerFieldProps<T>) {
  const handleChange = async (event: React.SyntheticEvent<HTMLElement>, data: { name: string; value: string }) => {
    const { propertyName, setFieldTouched, setFieldValue, onBlur, onDateChange } = props;
    await Promise.all([
      setFieldTouched(propertyName, true, false) as unknown as Promise<void>,
      setFieldValue(propertyName, data.value) as unknown as Promise<void>,
    ]);
    onBlur?.(propertyName);
    onDateChange?.(event, data);
  };

  return (
    <ValidatedField {...props}>
      <DatePickerWrapper {...props} onDateChange={handleChange} />
    </ValidatedField>
  );
}
