import { ISegment } from "../../../../../interfaces";

export interface EmailDetailsProps {
  emailId: number;
}

export interface EmailDetailsState {
  isValid: boolean;
  activeStepIndex: EmailDetailsSteps;
  isDirty: boolean;
  isReverting: boolean;
  packsModalShown: boolean;
}

export interface PageInfo {
  index: number;
  segment: ISegment;
}

export enum EmailDetailsSteps {
  Performance,
  Configuration,
  Content,
  AssociatedPacks,
}

export enum EmailCreateSteps {
  Configuration,
  Content,
}
