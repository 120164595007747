import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { TagsPayloadCamelPlural } from "../../../../../interfaces/Tags";

export interface EventCommonState {
  tags: TagsPayloadCamelPlural;
  error?: Error;
}

const initialState: EventCommonState = {
  tags: {},
  error: undefined,
};

const eventCommonSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Events}/common`,
  initialState: initialState,
  reducers: {
    setTags(state: EventCommonState, action: PayloadAction<TagsPayloadCamelPlural>) {
      state.tags = action.payload;
    },
    setError(state: EventCommonState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    reset(state: EventCommonState) {
      state.tags = initialState.tags;
      state.error = initialState.error;
    },
  },
});

export const { setTags, setError, reset } = eventCommonSlice.actions;

export type EventCommonStateType = ReturnType<typeof eventCommonSlice.reducer>;

export const eventCommonReducer = eventCommonSlice.reducer;
