import React, { FC } from "react";
import { Icon } from "semantic-ui-react";

import { DeleteLinkButton } from "../../../../../components/buttons/linkButtons";
import { Tooltip } from "../../../../../components/common/tooltip";
import { Strings } from "../../../../../enums";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface DeleteVideoButtonProps {
  purchasedAssetsSelected: boolean;
  undeletableSelected: boolean;
  onClick: () => void;
}

export const DeleteVideoButton: FC<DeleteVideoButtonProps> = ({
  purchasedAssetsSelected,
  undeletableSelected,
  onClick,
}) => {
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const deleteDisabled = purchasedAssetsSelected || (!deleteContentEnabled && undeletableSelected);
  const message = purchasedAssetsSelected
    ? Strings.tooltipMessages.purchased.operationsDisabled("assets")
    : Strings.tooltipMessages.deleteDisabled("assets", ["Flows", "Packs", "Users", "Groups"]);

  return (
    <Tooltip
      showAlways={deleteDisabled}
      inverted
      hideOnScroll
      position="left center"
      target={
        <DeleteLinkButton
          after={deleteDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
          isDisabled={deleteDisabled}
          onClick={onClick}
        />
      }
      content={message}
    />
  );
};
