import { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { bindAction } from "interfaces";
import { connect } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { isEmpty, some } from "lodash";

import { AssignmentPeopleContext, RolePermissions, ViewType } from "../../../../enums";
import { Awareness, Enrollment } from "../../../../enums/groups";
import { ContentAssignmentModal, UsersAssignmentModal } from "../../../../components/assignmentModals";
import { DeleteGroupsConfirmationModal } from "../../../../components/people";
import { GroupList } from "../../../../components/people/groupList";
import { usersFilter } from "../../../../components/filterForms/UsersFilterForm/UsersFilterForm";
import { GroupsOverviewHeader } from "./GroupsOverviewHeader/GroupsOverviewHeader";
import { pluralize } from "../../../../utils/stringUtils";
import userListUtils from "../../../../utils/userListUtils";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import dataService from "../../../Application/services/dataServices/dataService";
import Restricted from "../../../Application/Restricted";
import { Context } from "../../../Licensing/ContentAssignmentModalLicensingSteps/LicenseConfirmationModalContent/licenseConfirmationInfo/LicenseConfirmationInfo";

import * as addToContentActionsRedux from "../../state/slices/addToContentSlice";
import * as backgroundTasksActionsRedux from "../../../BackgroundTasks/state/backgroundTasksActions";
import * as editGroupActionsRedux from "../EditGroup/editGroupActions";
import * as filterActionsRedux from "./state/filterActions";
import * as groupsOverviewActionsRedux from "./state/groupsOverviewActions";
import * as notificationsActionsRedux from "../../../Notifications/state/notificationsActions";
import GroupsNoResults from "./GroupsNoResults/GroupsNoResults";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { addUsersToGroups } from "../../state/thunks/peopleThunk";
import { withRouter } from "../../../../adapters/withRouter/withRouter";
import { shouldShowModalForGroupMembership } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

export class GroupsOverview extends Component {
  state = {
    selectedGroups: [],
    viewType: ViewType.LIST,
    isStepLoading: false,
    selectedUsersToAddIds: [],
    focusedGroupId: null,
    deleteGroupsConfirmationModal: {
      show: false,
      groupIds: [],
    },
    showAddToContentModal: false,
    contentTypeToAdd: null,
    addGroupsToFlows: {
      groupIds: [],
      flows: [],
    },
    confirmation: {
      blocks: [],
      priorityItems: [],
      show: false,
      proceed: null,
    },
  };

  onSelectedGroupsChanged = (ids) => {
    const { selectedGroups, viewType } = this.state;
    const updatedSelection = selectedGroups.filter((sg) => ids.includes(sg.id));

    const groups = viewType === ViewType.LIST ? this.props.groupsList.items : this.props.groupsGrid.items;

    const addedGroups = groups.filter((g) => ids.includes(g.id) && selectedGroups.every((sg) => sg.id !== g.id));

    this.setState({
      selectedGroups: [...updatedSelection, ...addedGroups],
    });
  };

  goToGroupCreation = () => {
    this.props.navigate("add");
  };

  goToEditGroup = (groupId) => {
    this.props.navigate(groupId.toString());
  };

  onDuplicateGroup = (groupId) => {
    this.onDuplicateGroups([groupId]);
  };

  onAddToContent = (groupId, contentType) => {
    this.onAddToContentModal([groupId], contentType);
  };

  onDuplicateGroups = async (groupIds) => {
    this.onSelectedGroupsChanged([]);
    const params = {
      id: "DuplicateGroups",
      title: `Duplication of groups`,
      getMessageIds: async () => {
        const {
          data: { messageIds },
        } = await dataService.duplicateGroups(groupIds);
        return messageIds;
      },
      successTransientMessage: `${pluralize("Group", groupIds.length)} ${
        groupIds.length === 1 ? "has" : "have"
      } been duplicated!`,
      failureTransientMessage: `${pluralize("Group", groupIds.length)} duplicate failed!`,
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  onDeleteGroups = (groupIds) => {
    this.setState({
      deleteGroupsConfirmationModal: {
        show: true,
        groupIds,
      },
    });
  };

  onDeleteGroupsConfirmed = async () => {
    const { groupIds } = this.state.deleteGroupsConfirmationModal;

    this.setState(
      {
        deleteGroupsConfirmationModal: {
          show: false,
          groupIds: [],
        },
      },
      () => {
        this.onSelectedGroupsChanged([]);
      },
    );

    const params = {
      id: "DeleteGroups",
      title: "Groups deletion",
      getMessageIdsV2: () => groupIds.map((groupId) => `GroupDeleted_${groupId}`),
      callApi: () => dataService.deleteGroups(groupIds),
      successTransientMessage: `${pluralize("Group", groupIds.length)} ${
        groupIds.length === 1 ? "has" : "have"
      } been deleted successfully.`,
      failureTransientMessage: `${pluralize("Group", groupIds.length)} deletion failed.`,
    };

    const {
      backgroundTasksActions: { addOperationV2 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    await backgroundTask.updateEntityV2(params, {
      addOperation: addOperationV2,
      sendTransientNotification,
    });
  };

  onDeleteGroupsCanceled = () => {
    this.setState({
      deleteGroupsConfirmationModal: {
        show: false,
        groupIds: [],
      },
    });
  };

  renderCreateGroupButton = () => {
    return (
      <Restricted
        permissions={[RolePermissions.GroupsCreate]}
        renderContent={(hasAnyPermission) => (
          <RestrictedByTooltip hasPermission={hasAnyPermission}>
            <Button
              onClick={this.goToGroupCreation}
              className="create-button group"
              content={"Create Group"}
              primary
              disabled={!hasAnyPermission}
            />
          </RestrictedByTooltip>
        )}
      />
    );
  };

  onlyManualGroupsSelected = () => {
    const { selectedGroups } = this.state;
    return some(selectedGroups) && selectedGroups.every((group) => group.isWithAutoEnroll === Enrollment.Manual);
  };

  onViewTypeChange = (viewType) => {
    this.setState({ viewType: viewType });
  };

  loadUsersToAddPage = (skip, top, sortingColumnName, sortingDirection, appliedFilter) => {
    const { fetchGroupUsersToAdd } = this.props.editGroupActions;
    const { addUsersSearch } = this.props;
    const filterParams = addUsersSearch ? appliedFilter : usersFilter.buildFilterQuery(appliedFilter);
    const orderParams = addUsersSearch
      ? userListUtils.formatOrderParamsV2(sortingColumnName, sortingDirection)
      : userListUtils.formatOrderParams(sortingColumnName, sortingDirection);

    fetchGroupUsersToAdd(this.selectedGroupIds(), skip, top, orderParams, filterParams, addUsersSearch);
  };

  onSecondStep = (selectedIds) => {
    const groupIds = this.selectedGroupIds();
    const { shouldShowLicensingModal } = this.props;
    this.setState({ isStepLoading: true, selectedUsersToAddIds: selectedIds });

    return new Promise(async (resolve) => {
      const shouldShow = await shouldShowLicensingModal(groupIds, selectedIds);
      this.setState({ isStepLoading: false });
      resolve(shouldShow);
    });
  };

  selectedGroupIds = () => {
    const { focusedGroupId, selectedGroups } = this.state;
    return focusedGroupId ? [focusedGroupId] : selectedGroups.map((x) => x.id);
  };

  onAddUsersConfirm = async (notificationSettings) => {
    const { selectedUsersToAddIds } = this.state;
    const groupIds = this.selectedGroupIds();
    this.onCloseAddUsersModal();
    this.onSelectedGroupsChanged([]);
    this.props.addUsersToGroups(selectedUsersToAddIds, groupIds, notificationSettings);
  };

  onAddGroupMembers = (groupId) => {
    this.setState({ focusedGroupId: groupId }, this.showAddUsersModal);
  };

  onCloseAddUsersModal = () => {
    this.props.editGroupActions.resetAddMembersSearch();
    this.props.resetLicensingModal();
    this.setState({ focusedGroupId: null, showAddUsersModal: false });
  };

  showAddUsersModal = () => {
    this.setState({ showAddUsersModal: true });
  };

  createReloadListAddItems = (reloadListItems) => {
    this.reloadListAddItems = reloadListItems;
  };

  onSearchUsersAddChanged = (search) => {
    this.props.editGroupActions.setAddMembersSearch(search);
    this.reloadListAddItems?.(isEmpty(search));
  };

  renderUsersAddModal = () => {
    const {
      accountId,
      usersToAdd: { items, itemsCount, isLoading },
      addUsersSearch,
    } = this.props;

    const { isStepLoading, showAddUsersModal, selectedGroups } = this.state;

    const selectedGroupIds = this.selectedGroupIds();
    const allGroupsAware = selectedGroups.every((g) => g.isAware === Awareness.Aware);
    return (
      <UsersAssignmentModal
        accountId={accountId}
        loadPage={this.loadUsersToAddPage}
        usersAmount={itemsCount}
        isListLoading={isLoading}
        isStepLoading={isStepLoading}
        users={items}
        onSecondStep={this.onSecondStep}
        onConfirm={this.onAddUsersConfirm}
        showModal={showAddUsersModal}
        onCancel={this.onCloseAddUsersModal}
        assignmentEntityType="group"
        focusedEntityIds={selectedGroupIds}
        usersAssignments={{
          items: items.flatMap((item) =>
            item.assignedGroupIds.map((groupId) => ({
              userId: item.id,
              groupId,
            })),
          ),
          comparer: (assignment, id) => assignment.groupId === parseInt(id),
        }}
        multipleAssignment={selectedGroupIds.length > 1}
        showRoleFilter={true}
        search={addUsersSearch}
        onSearchChanged={this.onSearchUsersAddChanged}
        setReloadListItems={this.createReloadListAddItems}
        context={Context.AddPeopleToGroups}
        disableNotifications={!allGroupsAware}
      />
    );
  };

  onAddToContentModal = (groupIds, contentType) => {
    this.props.addToContentActions.setSelectedContentType(contentType);
    this.props.addToContentActions.setSelectedPeopleIds(groupIds);
    this.props.addToContentActions.setSelectedPeopleType(AssignmentPeopleContext.Group);
    this.onSelectedGroupsChanged([]);
  };

  renderAddToContentModal = () => {
    const { showAddToContentModal } = this.props;

    return showAddToContentModal && <ContentAssignmentModal />;
  };

  render() {
    const {
      groupsList,
      groupsGrid,
      appliedGroupsFilter,
      filterActions,
      filterOptions,
      accountId,
      groupsOverviewActions: { fetchGroups, resetGroupsFilter, setGroupsFilter, fetchGroupsLazy, resetGridItems },
    } = this.props;

    const { selectedGroups, deleteGroupsConfirmationModal, viewType } = this.state;

    return (
      <section className="nested-content groups">
        <GroupsOverviewHeader
          onAddToContentModal={this.onAddToContentModal}
          onDeleteGroups={this.onDeleteGroups}
          showAddUsersModal={this.showAddUsersModal}
          onDuplicateGroups={this.onDuplicateGroups}
          onlyManualGroupsSelected={this.onlyManualGroupsSelected}
          renderCreateButton={this.renderCreateGroupButton}
          selectedGroups={selectedGroups}
        />
        <GroupList
          viewType={viewType}
          accountId={accountId}
          groupsList={groupsList}
          groupsGrid={groupsGrid}
          fetchGroups={fetchGroups}
          fetchGroupsLazy={fetchGroupsLazy}
          setGroupsFilter={setGroupsFilter}
          resetGroupsFilter={resetGroupsFilter}
          appliedGroupsFilter={appliedGroupsFilter}
          selectedIds={selectedGroups.map((g) => g.id)}
          onSelectedGroupsChanged={this.onSelectedGroupsChanged}
          noResultsContent={() => (
            <GroupsNoResults createGroupButton={this.renderCreateGroupButton()}></GroupsNoResults>
          )}
          resetGridItems={resetGridItems}
          onViewTypeChange={this.onViewTypeChange}
          filterActions={filterActions}
          filterOptions={filterOptions}
          buttonHandlers={{
            onEdit: this.goToEditGroup,
            onDuplicate: this.onDuplicateGroup,
            onAddMembers: this.onAddGroupMembers,
            onDelete: (groupId) => this.onDeleteGroups([groupId]),
            onAddToContent: this.onAddToContent,
          }}
          renderContentSwitcher={this.props.renderContentSwitcher}
        />
        <DeleteGroupsConfirmationModal
          groupsCount={deleteGroupsConfirmationModal.groupIds.length}
          open={deleteGroupsConfirmationModal.show}
          onCancel={this.onDeleteGroupsCanceled}
          onContinue={this.onDeleteGroupsConfirmed}
        />
        {this.renderUsersAddModal()}
        {this.renderAddToContentModal()}
      </section>
    );
  }
}

GroupsOverview.propTypes = {
  navigate: PropTypes.func,
  groupsList: PropTypes.object.isRequired,
  groupsGrid: PropTypes.object.isRequired,
  groupsOverviewActions: PropTypes.object.isRequired,
  renderContentSwitcher: PropTypes.func,
};

const mapStateToProps = (state) => ({
  accountId: state.userProfile.accountId,
  permissions: state.userProfile.permissions,
  groupsList: state.people.groupsOverview.groupsList,
  groupsGrid: state.people.groupsOverview.groupsGrid,
  filterOptions: state.people.groupsOverview.filterOptions,
  groupContentAssignments: state.people.groupsOverview.groupAvailableFlows,
  appliedGroupsFilter: state.people.groupsOverview.groupsFilterState.appliedFilter,
  usersToAdd: state.people.editGroup.editPeople.usersToAdd,
  showAddToContentModal: state.people.addToContentModal.showModal,
  addUsersSearch: state.people.editGroup.addMembersSearch,
});

const mapDispatchToProps = (dispatch) => ({
  groupsOverviewActions: bindActionCreators(groupsOverviewActionsRedux, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActionsRedux, dispatch),
  notificationsActions: bindActionCreators(notificationsActionsRedux, dispatch),
  editGroupActions: bindActionCreators(editGroupActionsRedux, dispatch),
  filterActions: bindActionCreators(filterActionsRedux, dispatch),
  addToContentActions: bindActionCreators(addToContentActionsRedux, dispatch),
  addUsersToGroups: bindActionCreators(addUsersToGroups, dispatch),
  shouldShowLicensingModal: bindAction(shouldShowModalForGroupMembership, dispatch),
  resetLicensingModal: bindAction(reset, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLDConsumer()(GroupsOverview)));
