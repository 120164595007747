import { ActivityLog } from "../../types";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

export type ActivityLogsState = FetchingItemsState<ActivityLog>;

const initialState: ActivityLogsState = {
  items: [],
  isLoading: false,
  itemsCount: 0,
  error: undefined,
};

const activityLogsSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.Integrations,
    name: "activityLogs",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure } = activityLogsSlice.actions;

export default activityLogsSlice.reducer;
export type ActivityLogsSliceType = ReturnType<typeof activityLogsSlice.reducer>;
