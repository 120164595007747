import { FC, useState } from "react";
import { IObservable } from "../../../../../../interfaces/IObservable";
import TriggeredConfirmationModal from "../../../../../../components/triggeredConfirmationModal/TriggerableConfirmationModal";
import { pluralize } from "../../../../../../utils/stringUtils";

export interface DeleteConfirmationModalProps {
  onTriggerRemoveContentObserver: IObservable<(onRemoveConfirm: () => void, selectedItemsCount: number) => void>;
  onConfirmed?: () => void;
}

export const DeleteConfirmationModal: FC<DeleteConfirmationModalProps> = (props: DeleteConfirmationModalProps) => {
  const { onTriggerRemoveContentObserver, onConfirmed } = props;

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const contentTypeString = pluralize("File", selectedItemsCount);

  return (
    <TriggeredConfirmationModal
      title={`Delete Closed Captioning ${contentTypeString}?`}
      content={`Deleting these ${contentTypeString.toLowerCase()} will remove the closed captioning from this video.`}
      confirmButtonLabel={"Delete"}
      onConfirmed={onConfirmed}
      onTriggerModalObserver={onTriggerRemoveContentObserver}
      onTrigger={(input: number) => {
        setSelectedItemsCount(input);
      }}
    />
  );
};

export default DeleteConfirmationModal;
