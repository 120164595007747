import React, { useEffect } from "react";
import { useDrag } from "react-dnd";
import { Ref } from "semantic-ui-react";
import { getEmptyImage } from "react-dnd-html5-backend";
import { FlowDesignerEventCard } from "../../../../../../components/cards";
import { Props } from "../../../../../../components/cards/FlowDesignerEventCard/FlowDesignerEventCard";
import DragNDropTypes from "../../../../../../enums/dragNDropItemTypes";
import { EntityType } from "../../types";

function DraggableEventsCard({ id, title, thumbnailUrl, publisher, hasReminders }: Props) {
  const [, drag, preview] = useDrag({
    type: DragNDropTypes.DESIGNER,
    item: {
      id,
      type: DragNDropTypes.DESIGNER,
      title,
      elementType: EntityType.ExternalEvent,
      thumbnailUrl,
      hasReminders,
      publisher,
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <Ref innerRef={drag}>
      <FlowDesignerEventCard
        id={id}
        title={title}
        thumbnailUrl={thumbnailUrl}
        hasReminders={hasReminders}
        className="asset-draggable-item"
        publisher={publisher}
      />
    </Ref>
  );
}

export default DraggableEventsCard;
