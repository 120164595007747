import { FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { ThreatDefenceCampaign, ThreatDefenceCampaignView } from "../../types/state";
import * as campaignEntityStateActions from "../../state/actions/threatDefenceCampaignsEntityStateActions";
import * as campaignDetailsActions from "../../state/actions/threatDefenceCampaignDetailsActions";
import * as campaignOptionsActions from "../../state/actions/threatDefenceCampaignOptionsActions";
import { ConfigureCampaignProps } from "../types";
import validationSchemas from "../../../../../../utils/validationSchemas";
import CampaignForm from "../CampaignForm/CampaignForm";
import { AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";

export type ConfigureAllProps = ConfigureCampaignProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceCampaignView> &
  AutosaveProps;

export class Configure extends Component<ConfigureAllProps> {
  isCampaignCreated = () => this.props.id > 0;

  componentDidMount() {
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });

    this.props.optionsAction.getCampaignOptions();
    //Synchronizes parent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  onNext = () => {
    this.props.isValid && this.saveCampaign();
    if (!this.isCampaignCreated()) {
      this.props.entityStateActions.createThreatDefenceCampaign(Configure.getCampaign(this.props.values));
    }
  };

  onPrevious = () => {
    this.saveCampaign();
  };

  onClose = () => {
    this.props.detailsActions.clearCampaign();
    this.props.entityStateActions.resetThreatDefenceCampaignEntityState();
  };

  saveCampaign = () => {
    this.props.detailsActions.saveCampaign(this.props.values);
  };

  static getCampaign = (campaign: ThreatDefenceCampaignView): ThreatDefenceCampaign => {
    return {
      name: campaign.name.trim(),
      senderEmail: campaign.senderEmail.trim(),
      senderName: campaign.senderName.trim(),
      senderProfile: campaign.senderProfile,
      campaignTypeId: campaign.campaignTypeId,
      hasBeenPublished: campaign.hasBeenPublished,
      labels: campaign.labels.selected,
      softwareApplications: campaign.softwareApplications.selected,
    };
  };

  render() {
    return (
      <CampaignForm
        disablePreventTransitionPrompt={this.isCampaignCreated()}
        {...this.props.campaignOptions}
        {...this.props}
      />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigureCampaignProps) => ({
  id: ownProps.entityId || state.library.threatDefenceCampaigns.threatDefenceCampaignEntityStateReducer.entityId,
  campaign: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.campaign,
  isLoading: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.isLoading,
  isLoaded: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.isCampaignLoaded,
  campaignOptions: { ...state.library.threatDefenceCampaigns.threatDefenceCampaignOptionsReducer },
  isCreating: state.library.threatDefenceCampaigns.threatDefenceCampaignEntityStateReducer.changingEntityState,
  isDraft: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.campaign.isDraft,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(campaignEntityStateActions, dispatch),
  detailsActions: bindActionCreators(campaignDetailsActions, dispatch),
  optionsAction: bindActionCreators(campaignOptionsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigureAllProps, ThreatDefenceCampaignView, ThreatDefenceCampaign>({
  getInitValues: (props) => Configure.getCampaign(props.campaign),
  stateProvider: Configure.getCampaign,
  entityUpdater: (props) => props.entityStateActions.updateThreatDefenceCampaign,
  isValid: (props) => props.isDraft && props.isValid && !props.isValidating && props.id > 0,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: (props: ConfigureCampaignProps & PropsFromRedux) =>
    validationSchemas.campaignConfigure(props.campaign.name),
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: (props: ConfigureCampaignProps & PropsFromRedux) => props.campaign,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connect(mapStateToProps, mapDispatchToProps)(component);
