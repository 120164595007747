import { FormikProps } from "formik";
import { Component } from "react";

import { ValidatedForm } from "../../../../../../../components";
import { SendingProfileFormPropsOwn } from "../types";
import { ThreatDefenceDirectSendSendingProfileView } from "../../types/state";

export type SendingProfileFormPropsAll = SendingProfileFormPropsOwn &
  FormikProps<ThreatDefenceDirectSendSendingProfileView>;

export default class SendingProfileForm extends Component<SendingProfileFormPropsAll> {
  render() {
    const { dirty, isLoading, disablePreventTransitionPrompt } = this.props;

    return (
      <ValidatedForm
        dirty={dirty}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message:
            "Are you sure you want to exit without saving this sending profile? All information entered will be lost.",
        }}
        isLoading={isLoading}
        isSubmitting={isLoading}
        formWidthComputer={6}
        disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      >
        {this.renderFields()}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, disabled, onBlur, save, onIsValidChange } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    return (
      <div className="sending-profile-form-container">
        <ValidatedForm.InputField
          label="Host"
          value={values.host}
          onIsFormValidChange={onIsValidChange}
          propertyName="host"
          markAsRequired
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Port"
          value={values.port}
          propertyName="port"
          markAsRequired
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.CheckboxField
          {...validatedFieldProps}
          toggle
          label="Use SSL"
          value={values.isSsl}
          propertyName="isSsl"
          handleChange={save}
          disabled={disabled}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
      this.props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}
