import App from "App";
import MoboProvider from "MoboProvider";
import { useAuth } from "oidc-react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "semantic-ui-react";

export const LoggedInApp = () => {
  const auth = useAuth();
  const location = useLocation();

  const onSignout = useCallback(() => auth.signOutRedirect({ state: location }), [auth, location]);

  if (auth.userData && !auth.userData.expired) {
    return (
      <MoboProvider>
        <App onSignout={onSignout} />
      </MoboProvider>
    );
  }

  return <Loader active />;
};
