import { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import DropAreaReactFlow from "../DropAreaReactFlow/DropAreaReactFlow";
import {
  updateLockedFlowCommandBegin,
  updateLockedFlowCommandCompleted,
} from "../../state/actions/flowEntityStateActionCreators";
import { FlowValidator } from "../validator/FlowValidator";
import { isFlowReadOnlySelector } from "../selectors";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import { FlowEditDefinitionSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { flowDesignerSelector, flowInformationSelector } from "../../state/selectors";
import { RootState } from "../../../../Application/globaltypes/redux";
import { IObservable, bindAction, IWizardStep } from "../../../../../interfaces";
import { saveItems } from "../state/slices/flowDesignerDefinitionSlice";
import { prepareFlowDefinitionData } from "./helpers/typesConvertor";
import { fetchTriggers } from "../state/thunks/flowDesignerTriggersThunk";
import { useObserver } from "../../../../../hooks/useObserver";

export interface FlowDesignerProps extends IWizardStep {
  flowValidator: FlowValidator;
  onResetSelectedElementsObserver?: IObservable<() => void>;
  publishOrDiscardClickObserver?: IObservable<() => void>;
}

export type Props = PropsFromRedux & FlowDesignerProps;

export function FlowDesigner(props: Props) {
  const flowDataRef = useRef();
  const [subscribeOnPublishOrDiscardClick] = useObserver(props.publishOrDiscardClickObserver);
  const {
    flowInfo,
    flowDefinition,
    flowDefinitionLoading,
    onSavingProcessBegin,
    onSavingProcessSuccess,
    flowValidator,
    isReadOnly,
    onResetSelectedElementsObserver,
    acceptHandlers,
    triggerTypes,
    triggerTimeUnits,
    saveFlowDefinition,
    externalTriggerGroupsLength,
  } = props;

  useEffect(() => {
    acceptHandlers?.({
      onNext: onSaveFlowDefinition,
      onPrevious: onSaveFlowDefinition,
    });
    !props.isTriggerLoaded && props.fetchTriggers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return subscribeOnPublishOrDiscardClick(onSaveFlowDefinition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnPublishOrDiscardClick]);

  const onSaveFlowDefinition = () => {
    flowDataRef.current && saveFlowDefinition(prepareFlowDefinitionData(flowDataRef.current));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DropAreaReactFlow
        flowInfo={flowInfo}
        flowDefinition={flowDefinition}
        flowDefinitionLoading={flowDefinitionLoading}
        triggerTypes={triggerTypes}
        triggerTimeUnits={triggerTimeUnits}
        isReadOnly={isReadOnly}
        onSavingProcessBegin={() => onSavingProcessBegin(flowInfo.id)}
        onSavingProcessSuccess={onSavingProcessSuccess}
        flowValidator={flowValidator}
        onResetSelectedElementsObserver={onResetSelectedElementsObserver}
        flowDataRef={flowDataRef}
        externalTriggerGroupsLength={externalTriggerGroupsLength}
      />
    </DndProvider>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  flowInfo: flowInformationSelector(state).info,
  flowDefinition: flowDesignerSelector(state).definition.items,
  externalTriggerGroupsLength: flowDesignerSelector(state).externalTriggers.externalTriggerGroups[0]?.triggers.length,
  flowDefinitionLoading: flowDesignerSelector(state).definition.isLoading,
  triggerTypes: flowDesignerSelector(state).triggers.triggerTypes,
  triggerTimeUnits: flowDesignerSelector(state).triggers.triggerTimeUnits,
  isTriggerLoaded: flowDesignerSelector(state).triggers.isLoaded,
  isReadOnly: isFlowReadOnlySelector(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSavingProcessBegin: (id: number) => {
      dispatch(updateLockedFlowCommandBegin());
      dispatch(beginAsyncOperation({ id, action: FlowEditDefinitionSuccess }));
    },
    onSavingProcessSuccess: (id?: string) => {
      return dispatch(updateLockedFlowCommandCompleted(id));
    },
    saveFlowDefinition: bindAction(saveItems, dispatch),
    fetchTriggers: bindAction(fetchTriggers, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowDesigner);
