import cuid from "cuid";
import { groupBy, isEqual, sortBy, uniqBy } from "lodash";
import { v4 } from "uuid";

const areaAllId = cuid();

const mergeLimitations = (limitations) => {
  const mergedLimitations = uniqBy(limitations, (l) => l.id);
  return sortBy(mergedLimitations, (l) => l.id);
};

const mergeRights = (rights) => {
  const groupedRights = groupBy(rights, (r) => r.id);

  const mergedRights = Object.entries(groupedRights).map(([_, rightsGroup]) => ({
    ...rightsGroup[0],
    limitations: mergeLimitations(rightsGroup.flatMap((rg) => rg.limitations)),
  }));

  return sortBy(mergedRights, (r) => r.id);
};

const getAreaAll = (areas) => {
  const allRights = areas.flatMap((a) => a.features.flatMap((f) => f.rights));
  const mergedRights = mergeRights(allRights);

  return {
    ...getAllAreaBase(),
    features: [createFeatureAll(mergedRights)],
  };
};

const getAllAreaBase = () => ({
  areaId: areaAllId,
  areaName: "All",
});

const getAreasWithAllFeatures = (areas) => {
  const addAllFeature = (area) => {
    const allRights = area.features.flatMap((f) => f.rights);
    const mergedRights = mergeRights(allRights);

    return createFeatureAll(mergedRights);
  };

  return areas.map((a) => ({
    ...a,
    features: [addAllFeature(a), ...a.features],
  }));
};

const isAllAreaOrAllFeature = (id) => !Number.isInteger(id);

const createFeatureAll = (rights) => ({
  featureId: areaAllId,
  featureName: "All",
  rights,
});

const mapAllToConfiguredPermissions = (configuredPermissions, permissions) => {
  const mappedPermissions = configuredPermissions.map((p) => {
    return {
      id: v4(),
      area: p.areaId,
      feature: p.featureId,
      right: p.rightId,
      limitation: p.limitationId,
      limitationItems:
        p.limitationItemIds.length > 0
          ? p.limitationItemIds.map((id) => {
              return { id: parseInt(id) };
            })
          : undefined,
    };
  });

  mappedPermissions
    .filter((p) => !p.area)
    .forEach((p) => {
      const permission = permissions.find((perm) => isAllAreaOrAllFeature(perm.areaId));
      p.area = permission.areaId;
      p.feature = permission.features.find((f) => isAllAreaOrAllFeature(f.featureId)).featureId;
    });

  mappedPermissions
    .filter((p) => !p.feature)
    .forEach((p) => {
      p.feature = permissions
        .find((permission) => permission.areaId === p.area)
        .features.find((f) => isAllAreaOrAllFeature(f.featureId)).featureId;
    });

  const isApplicablePermission = (mp) =>
    permissions.some(
      (p) =>
        mp.area === p.areaId &&
        p.features.some(
          (f) =>
            f.featureId === mp.feature &&
            f.rights.some((r) => r.id === mp.right && r.limitations.some((l) => l.id === mp.limitation)),
        ),
    );

  return mappedPermissions.filter(isApplicablePermission);
};

const addAllArea = (areas) => {
  const allLimitations = areas.flatMap((x) => x.limitations);
  const distinctLimitations = uniqBy(allLimitations, (i) => i.id);

  const baseAllItem = getAllAreaBase();

  const allArea = {
    id: baseAllItem.areaId,
    name: baseAllItem.areaName,
    limitations: distinctLimitations,
  };

  return [allArea, ...areas];
};

const checkConfiguredPermissionsEqual = (first, second) => {
  const normalize = (permissions) =>
    permissions.map((item) => ({
      ...item,
      limitationItems: item?.limitationItems?.map((x) => ({ id: x.id })) ?? [],
    }));

  return isEqual(normalize(first), normalize(second));
};

const mapConfiguredPermissionsToRolePermissions = (configuredPermissions) => {
  return configuredPermissions.map((p) => {
    return {
      areaId: isAllAreaOrAllFeature(p.area) ? null : p.area,
      featureId: isAllAreaOrAllFeature(p.feature) ? null : p.feature,
      rightId: p.right,
      limitationId: p.limitation,
      limitationItems: p.limitationItems ? p.limitationItems.map((l) => l.id.toString()) : [],
    };
  });
};

const helper = {
  addAllAreaAndAllFeatures: (areas) => {
    if (Array.isArray(areas) && areas.length > 0) {
      const areasWithAllFeatures = getAreasWithAllFeatures(areas);
      const areaAll = getAreaAll(areas);
      return [areaAll, ...areasWithAllFeatures];
    }

    return areas;
  },
  addAllArea,
  isAllAreaOrAllFeature,
  getAllAreaBase,
  mapAllToConfiguredPermissions,
  checkConfiguredPermissionsEqual,
  mapConfiguredPermissionsToRolePermissions,
};

export default helper;
