import React, { FC, useCallback } from "react";
import { Grid, InputOnChangeData } from "semantic-ui-react";

import { ValidatedForm } from "../../../../../components";
import { validateLicensesCountInputValue } from "../../../../../utils/validationSchemas/packRequestValidationSchema";
import { PackRequestFormFieldProps } from "./types";

export const LicensesCountField: FC<PackRequestFormFieldProps> = ({
  values,
  onIsValidChange,
  handleChange,
  setFieldValue,
  ...props
}) => {
  const handleLicensesCountChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      if (validateLicensesCountInputValue(data.value)) {
        handleChange(e);
      }
    },
    [handleChange],
  );

  const setLicensesCountValue = useCallback(
    (field: string, value: string, shouldValidate?: boolean) => {
      if (validateLicensesCountInputValue(value)) {
        setFieldValue(field, value, shouldValidate);
      }
    },
    [setFieldValue],
  );

  return (
    <Grid.Row>
      <Grid.Column>
        <ValidatedForm.InputField
          label="How Many Licenses?"
          value={values.licensesCount}
          propertyName="licensesCount"
          markAsRequired
          errorPosition="top"
          onIsFormValidChange={onIsValidChange}
          placeholder="Enter Number of Licenses"
          {...props}
          handleChange={handleLicensesCountChange}
          setFieldValue={setLicensesCountValue}
          testId="licenses-count-input"
        />
      </Grid.Column>
    </Grid.Row>
  );
};
