import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { operators, Filter } from "../../../utils/filterUtils";
import { RolePermissions } from "../../../enums";
import { FilterFormPropsBase } from "../types/props";
import { FilterFormBuilder, FilterItemBase, FilterViewType } from "../FilterFormBuilder/FilterFormBuilder";
import { RootState } from "../../../features/Application/globaltypes/redux";
import { isEmpty } from "lodash";

enum UsersFilterPropertyNames {
  departments = "department",
  jobTitles = "title",
  roles = "roleId",
  countries = "countries",
  createDate = "createDate",
  lastLoginDate = "lastLoginDate",
  showRoleFilter = "showRoleFilter",
}

export const usersFilter = new Filter([
  {
    key: "title",
    operator: operators.or,
  },
  {
    key: "department",
    operator: operators.or,
  },
  {
    key: "createDate",
    operator: operators.range,
  },
  {
    key: "roleId",
    operator: operators.ids,
  },
]);

export interface UserFilterFormProps extends FilterFormPropsBase<string>, PropsFromRedux {
  datePickersPosition: "top" | "bottom";
  departmentFilterDisabled?: boolean;
  jobTitleFilterDisabled?: boolean;
  showLastLoginFilter?: boolean;
}

export const UsersFilterForm: React.FC<UserFilterFormProps> = ({
  filterOptions,
  filter,
  updateFilter,
  userPermissions,
  datePickersPosition = "top",
  departmentFilterDisabled,
  jobTitleFilterDisabled,
  showLastLoginFilter,
}) => {
  const hasPermissionToShowRoleFilter = userPermissions.includes(RolePermissions.RolesView);
  const showRoleFilter = filterOptions.showRoleFilter === undefined || filterOptions.showRoleFilter;

  const filters: FilterItemBase<string>[] = [
    {
      type: FilterViewType.MultiSelectWithAddition,
      label: "Departments",
      items: filterOptions.departments,
      propertyName: UsersFilterPropertyNames.departments,
      placeholder: "All Departments",
      otherProps: { disabled: departmentFilterDisabled && isEmpty(filterOptions.departments) },
    },
    {
      type: FilterViewType.MultiSelectWithAddition,
      label: "Job Titles",
      items: filterOptions.jobTitles,
      propertyName: UsersFilterPropertyNames.jobTitles,
      placeholder: "All Job Titles",
      otherProps: { disabled: jobTitleFilterDisabled && isEmpty(filterOptions.jobTitles) },
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Role",
      items: filterOptions.roles,
      propertyName: UsersFilterPropertyNames.roles,
      placeholder: "All",
      condition: () => {
        return !!showRoleFilter && hasPermissionToShowRoleFilter;
      },
      otherProps: { disabled: true },
      hidden: true,
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Country",
      propertyName: UsersFilterPropertyNames.countries,
      placeholder: "All",
      otherProps: { disabled: true },
      hidden: true,
    },
    {
      type: FilterViewType.DateRange,
      label: "Added",
      propertyName: UsersFilterPropertyNames.createDate,
      otherProps: { verticalPosition: datePickersPosition, horizontalPositionSecondPicker: "left", disabled: true },
      hidden: true,
    },
    {
      type: FilterViewType.DateRange,
      label: "Last Login",
      propertyName: UsersFilterPropertyNames.lastLoginDate,
      otherProps: { disabled: false },
      hidden: !showLastLoginFilter,
    },
  ];

  return (
    <FilterFormBuilder filterOptions={filterOptions} filter={filter} updateFilter={updateFilter} items={filters} />
  );
};

const mapStateToProps = (state: RootState) => ({
  userPermissions: state.userProfile.permissions,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersFilterForm);
