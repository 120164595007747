import * as Yup from "yup";

export function requiredTextFieldMinMax(fieldName: string, min: number, max: number) {
  return Yup.string()
    .trim()
    .required(`${fieldName} is required`)
    .min(min, `${fieldName} must have at least ${min} characters`)
    .max(max, `${fieldName} must be maximum of ${max} characters long`);
}

export function requiredTextFieldMin(fieldName: string, min: number) {
  return Yup.string()
    .trim()
    .required(`${fieldName} is required`)
    .min(min, `${fieldName} must have at least ${min} characters`);
}
