import { combineReducers } from "redux";
import * as actionTypes from "./editRoleActionTypes";
import { withFetchingChildEntityListItems } from "../../Application/reducers/hoc/withFetchingItems";
import ReducerNamespaceTypes from "../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../enums/reducer/reducerEntityPrefixTypes";
const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.EditRole;

const initialState = {
  data: {
    permissions: [],
  },
  isLoading: false,
  error: null,
};

export const roleInfo = (currentState, action) => {
  const state = currentState || initialState;
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_ROLE_INFO_BEGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case actionTypes.FETCH_ROLE_INFO_SUCCESS:
      return {
        ...state,
        data: payload.info,
        isLoading: false,
      };

    case actionTypes.FETCH_ROLE_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    case actionTypes.UPDATE_ROLE_INFO:
      return {
        ...state,
        data: {
          ...state.data,
          name: payload.name,
          description: payload.description,
        },
      };

    case actionTypes.UPDATE_ROLE_PERMISSIONS:
      return {
        ...state,
        data: {
          ...state.data,
          permissions: payload.permissions,
        },
      };

    case actionTypes.UPDATE_ROLE_UPDATED:
      return {
        ...state,
        data: { ...state.data, updated: payload.updated },
      };

    default:
      return state;
  }
};

export const editRole = combineReducers({
  roleInfo,
  usersList: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.RoleUsers),
});
