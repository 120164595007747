import { FlowDesignerData, FlowItem } from "../../types";
import { FlowEndError, ValidatorErrorTypes } from "../flowValidatorReducer";
import { ExtrasTypes } from "../../Extras/types";

export const validateFlowEnd = (data: FlowDesignerData): FlowEndError[] => {
  if (!data.headId) return [];

  const endItems: Set<string> = new Set(
    data.items.filter((i) => i.entityType === ExtrasTypes.FlowEnd).map((i) => i.id),
  );

  const requiresEnd = (item: FlowItem): boolean => {
    const isActionItem = data.triggers.some((t) => t.outId === item.id && t.isAction);
    const hasConnectingTriggers = data.triggers.some((t) => t.inId === item.id && !t.isAction);
    return !isActionItem && !hasConnectingTriggers && !endItems.has(item.id);
  };

  const createError = (item?: FlowItem): FlowEndError => ({
    itemId: item?.id,
    errorMessage: "Add an end of flow from the ‘extras’ tab in the inspector panel.",
    type: ValidatorErrorTypes.FlowEndError,
    id: `type-${ValidatorErrorTypes.FlowEndError}` + (item ? `-itemId-${item?.id}` : ``),
  });

  const errors: FlowEndError[] = data.items.filter(requiresEnd).map(createError);

  if (errors.length === 0 && endItems.size === 0) {
    errors.push(createError());
  }

  return errors;
};
