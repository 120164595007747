import { Component } from "react";
import { FormikProps } from "formik";
import EditDirectSend from "../DirectSend/Edit/Edit";
import EditSmtpAuthSendingProfile from "../SmtpAuth/Edit/EditSmtpAuthSendingProfile";
import SendingMethods from "../../../../../enums/sendingMethods";
import { ThreatDefenceDirectSendSendingProfileView } from "../DirectSend/types/state";
import { EditSendingProfileState } from "../types/state";
import { ThreatDefenceSmtpAuthSendingProfileVerificationView } from "../SmtpAuth/types/state";
import dataService from "../services/threatDefenceSendingProfilesDataService";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

export type EditSendingProfileDetailsPropsAll = FormikProps<ThreatDefenceDirectSendSendingProfileView> &
  FormikProps<ThreatDefenceSmtpAuthSendingProfileVerificationView> &
  WithRouterProps;

export class EditSendingProfile extends Component<EditSendingProfileDetailsPropsAll, EditSendingProfileState> {
  private readonly id: number;

  constructor(props: EditSendingProfileDetailsPropsAll) {
    super(props);
    this.id = this.getId();
    this.state = {
      isDirectSend: false,
      isSendingMethodIdLoaded: false,
    };
  }

  getId = () => {
    return Number.parseInt(this.props.params.id!);
  };

  async componentDidMount() {
    const {
      data: { sendingMethodId },
    } = await dataService.getThreatDefenceDirectSendSendingProfileAsync(this.id);
    const isDirectSend = sendingMethodId === SendingMethods.DirectSend;
    this.setState({ isDirectSend, isSendingMethodIdLoaded: true });
  }

  render() {
    const { isDirectSend, isSendingMethodIdLoaded } = this.state;
    return (
      <>
        {isSendingMethodIdLoaded &&
          (isDirectSend ? <EditDirectSend {...this.props} /> : <EditSmtpAuthSendingProfile {...this.props} />)}
      </>
    );
  }
}

export default withRouter(EditSendingProfile);
