import * as actionTypes from "../actionTypes/emailsOverviewActionTypes";
import { createReducer } from "../../../../../../utils/reduxUtils";
import { EmailsOverviewState, EmailOverview } from "../../types/state";
import { FetchActionPayload } from "../../../../../../interfaces/redux";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState: EmailsOverviewState = {
  emails: [],
  isLoading: false,
  isAllLoaded: false,
  totalCount: 0,
  error: undefined,
};

const emailsOverviewHandlers = () => {
  const {
    getEmailsBegin,
    getEmailsSuccess,
    getEmailsFailure,
    getEmailsLazyBegin,
    getEmailsLazySuccess,
    getEmailsLazyFailure,
    resetEmails,
  } = actionTypes;

  const getEmailsBeginHandler = (state: EmailsOverviewState): EmailsOverviewState => ({
    ...state,
    emails: new Array<EmailOverview>(),
    isLoading: true,
  });

  const getEmailsLazyBeginHandler = (state: EmailsOverviewState): EmailsOverviewState => ({
    ...state,
    isLoading: true,
  });

  const getEmailsSuccessHandler = (
    state: EmailsOverviewState,
    action: PayloadAction<FetchActionPayload<EmailOverview>>,
  ): EmailsOverviewState => ({
    ...state,
    emails: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const getEmailsLazySuccessHandler = (
    state: EmailsOverviewState,
    action: PayloadAction<FetchActionPayload<EmailOverview>>,
  ): EmailsOverviewState => {
    const currentItems = state.emails;
    const emailsBatch = action.payload.items;
    const emails = currentItems.concat(emailsBatch);
    const totalCount = action.payload.totalCount;
    const isAllLoaded = emails.length === totalCount;

    return {
      ...state,
      emails,
      totalCount,
      isAllLoaded,
      isLoading: false,
    };
  };

  const getEmailsFailureHandler = (state: EmailsOverviewState, action: PayloadAction<Error>): EmailsOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const resetEmailsHandler = (state: EmailsOverviewState) => {
    return {
      ...state,
      emails: new Array<EmailOverview>(),
      totalCount: 0,
    };
  };

  return {
    [getEmailsBegin]: getEmailsBeginHandler,
    [getEmailsSuccess]: getEmailsSuccessHandler,
    [getEmailsFailure]: getEmailsFailureHandler,
    [getEmailsLazyBegin]: getEmailsLazyBeginHandler,
    [getEmailsLazySuccess]: getEmailsLazySuccessHandler,
    [getEmailsLazyFailure]: getEmailsFailureHandler,
    [resetEmails]: resetEmailsHandler,
  };
};

export const emailsOverviewReducer = createReducer(initialState, [emailsOverviewHandlers]);
