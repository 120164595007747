import { useSlate } from "slate-react";
import { Button } from "./Button";
import utils from "../utils";
import { Icon, iconType } from "./Icon";
import { BlockFormats } from "../types";

export interface Props {
  format: BlockFormats;
  iconType: iconType;
}

export const BlockButton = (props: Props) => {
  const editor = useSlate();
  return (
    <Button
      active={utils.isBlockActive(editor, props.format)}
      onClick={(event) => {
        event.preventDefault();
        utils.toggleBlock(editor, props.format);
      }}
    >
      <Icon type={props.iconType} />
    </Button>
  );
};
