import { isEmpty, isString } from "lodash";

export const toCssClass = (text: string) => {
  return text.split(" ").join("-").split("/").join("-").toLowerCase();
};

export const getFilenameWithoutExtension = (filename: string) => {
  const delimiterIndex = filename.lastIndexOf(".");
  return delimiterIndex > 0 ? filename.substring(0, delimiterIndex) : filename;
};

export const replaceNumericValuesInString = (str: string, newNumber: number) => {
  const numericRegex = /\d+/g;
  return str.replace(numericRegex, `${newNumber}`);
};

export const replaceNonNumericValuesInString = (str: string) => {
  return str.replace(/[\D]/g, ""); // NOSONAR Character classes in regular expressions should not contain only one character
};

function trimItems(array: string[]) {
  return array.map((value) => value.trim());
}

function removeEmptyItems(array: Array<string | null>) {
  return array.filter((testee) => testee);
}

export const StringArrayUtils = {
  trimItems,
  removeEmptyItems,
};
type ComparerType = string | number | undefined | null;
let locale: string;
function comparer(sensivity: "accent" | "variant", first: ComparerType, second: ComparerType) {
  const firstType = typeof first;
  const secondType = typeof second;

  return (
    firstType === secondType &&
    (firstType === "undefined" ||
      (first === null && second === null) ||
      (typeof first === "string" &&
        first.localeCompare(second as string, locale, {
          sensitivity: sensivity,
        }) === 0))
  );
}

export const equalityComparers = {
  caseInsensitive: comparer.bind(null, "accent"),
  caseSensitive: comparer.bind(null, "variant"),
};

export const pluralize = (word: string, count: number) => {
  return count === 1 ? word : `${word}s`;
};

/**
 *
 * @param {string} userName User name to parse(e.g John Doe)
 * @returns {object} Record object with first and last name fields
 */
export function parseUserName(userName: string): { firstName?: string; lastName?: string } {
  const nameChunks = userName.trim().split(" ");
  const result: { firstName?: string; lastName?: string } = {};
  const firstName = nameChunks[0];
  const lastName = nameChunks[1];
  if (firstName !== "" && firstName !== undefined) {
    result.firstName = firstName;
  }
  if (lastName !== undefined) {
    result.lastName = lastName;
  }
  return result;
}

export const isWhiteSpace = (input: any) => isString(input) && input.trim() === "";

export const isEmptyOrWhiteSpace = (input: any) => isEmpty(input) || isWhiteSpace(input);

export const capitalizeFirstLetter = (str: string) => {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
};

export const serialCommaJoin = (arr: string[], conjunction: "or" | "and", defaultValue = "") => {
  const l = arr.length;
  if (!l) return defaultValue;
  if (l < 2) return arr[0];
  if (l < 3) return arr.join(` ${conjunction} `);
  const copy = arr.slice();
  copy[l - 1] = `${conjunction} ${arr[l - 1]}`;
  return copy.join(", ");
};

export const ensureTrimmed = (value: string | undefined | null, onTrim: (value?: string) => void) => {
  const trimmedValue = value?.trim();
  if (trimmedValue === value) {
    return;
  }
  onTrim(trimmedValue);
};

export const getFormattedPhoneNumber = (input: string) => {
  const phone = replaceNonNumericValuesInString(input).substring(0, 10);
  let output = "(";
  return phone.replace(
    /^\(?([\d]{0,3})\)?[-. ]?([\d]{0,3})[-. ]?([\d]{0,4})/, // NOSONAR Character classes in regular expressions should not contain only one character
    function (match, g1: string, g2: string, g3: string) {
      if (g1.length) {
        output += g1;
        if (g1.length === 3) {
          output += ")";
          if (g2.length) {
            output += g2;
            if (g2.length === 3) {
              output += "-";
              if (g3.length) {
                output += g3;
              }
            }
          }
        }
      }
      return output;
    },
  );
};
