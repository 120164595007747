import { FormikProps, withFormik } from "formik";
import { Component } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";

import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import * as backgroundTasksActions from "../../../BackgroundTasks/state/backgroundTasksActions";
import * as userAssignmentActions from "../../PeopleAssignments/state/actions/userAssignmentActions";
import * as videoEntityStateActions from "../state/actions/videoEntityStateActionCreators";
import * as videosActions from "../state/actions/videosActions";

import AssetFormContainer from "../VideoForm/VideoFormContainer/VideoFormContainer";
import validationSchemas from "../../../../utils/validationSchemas";
import VideoUploaderService from "../services/videosUploaderService";
import videosThumbnailUploader from "../videosThumbnailUploader";
import Autosave from "../../../../utils/Autosave";
import Observable from "../../../../utils/Observable";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";

import navigationUtils from "../../../../utils/navigationUtils";
import WizardStepsManager from "../../../../utils/WizardStepsManager";
import ClosedCaptions from "../VideoDetails/ClosedCaptions/ClosedCaptions";
import RemoveClosedCaptionLinkButton from "../VideoDetails/ClosedCaptions/RemoveClosedCaptionLinkButton/RemoveClosedCaptionLinkButton";

import SettingsTab from "../VideoDetails/Settings/SettingsTab";

import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { FeatureFlags } from "featureFlags";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { Dimmer, Loader } from "semantic-ui-react";
import { withRouter, WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { IObservable } from "../../../../interfaces";
import { pluralize } from "../../../../utils/stringUtils";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { VideoAssetTask } from "../../../BackgroundTasks/taskPool";
import { initialNotifyConfigDefault } from "../../../SystemNotifications/config";
import { withNotifyConfig, WithNotifyConfigProps } from "../../../SystemNotifications/containers/withNotifyConfig";
import { NotifySettings } from "../../../SystemNotifications/types";
import { WizardWrapper as Wizard } from "../../../WizardWrapper";
import { videosStateSelector } from "../state/selectors";
import { getAssignedClosedCaptions } from "../state/selectors/detailsSelector";
import { resetTags } from "../state/slices/videoBaseSlice";
import { resetSelectedItems } from "../state/slices/videoClosedCaptionsSlice";
import { deleteClosedCaptions } from "../state/thunks/videoClosedCaptionsThunk";
import { uploadVideoToAws } from "../state/thunks/videoUploadThunk";
import { VideoInfoPayload } from "../types/models";
import { VideoInfo } from "../types/state";
import { CreateVideoStepConfig, CreateVideoSteps } from "./types";
import { RouteNames } from "enums";
import { LDProps } from "../../../LDProps";

export interface CreateVideoOwnProps {
  isValid: boolean;
  submitForm: Function;
  values: VideoInfo;
}

export interface CreateVideoState {
  taskId: number;
  showAddPacks: boolean;
  licensingConfirmProceed?: (value: boolean) => void;
  activeStepIndex: CreateVideoSteps;
}

export type CreateAssetProps = PropsFromRedux &
  CreateVideoOwnProps &
  FormikProps<VideoInfo> &
  WithRouterProps &
  LDProps;
export type Props = CreateAssetProps & WithNotifyConfigProps<NotifySettings>;

export class CreateVideo extends Component<Props, CreateVideoState> {
  cancellationToken: any;
  assetsThumbnailUploader: any;
  videoUploader: any;

  private autosave: Autosave<VideoInfoPayload>;
  private readonly onTriggerClosedCaptionsRemovalObserver: IObservable<
    (onRemoveConfirm: () => void, selectedItemsCount: number) => void
  >;
  private readonly isAssociatedPacksEnabled: boolean;
  private stepsManager = new WizardStepsManager();
  private readonly isAwsEncodingEnabled: boolean;

  constructor(props: Props) {
    super(props);
    this.cancellationToken = {
      isCancelRequested: false,
    };
    this.isAwsEncodingEnabled = !!this.props.flags?.[FeatureFlags.AwsEncoding];
    this.assetsThumbnailUploader = videosThumbnailUploader(() => this.props);
    /* istanbul ignore next */
    this.videoUploader = VideoUploaderService(
      () => this.props,
      () => this.props.entityId,
      this.cancellationToken,
      null,
      this.onTaskIdChanged.bind(this),
      this.isAwsEncodingEnabled,
    );

    this.autosave = new Autosave({
      stateProvider: this.autosaveStateProvider,
      isValid: this.autosaveValidator,
      updateHandler: this.props.videosActions.updateProperties as (state: any) => void,
      isDeferredInitialization: this.props.entityId !== undefined,
    });

    this.state = {
      taskId: -1,
      activeStepIndex: CreateVideoSteps.Configuration,
      showAddPacks: false,
    };
    this.onTriggerClosedCaptionsRemovalObserver = new Observable<
      (onRemoveConfirm: () => void, selectedItemsCount: number) => void
    >();
    this.stepsManager.subscribeOnActiveIndexChanged((_: any, activeStepIndex: number) => {
      this.setState({ activeStepIndex });
    });
    this.isAssociatedPacksEnabled = !!this.props.flags?.[FeatureFlags.AssociatedPacks];
  }

  componentDidUpdate(prev: CreateAssetProps) {
    const { values, entityId } = this.props;

    if (entityId && entityId !== prev.entityId) {
      this.autosave.ensureInitialization(this.getAssetProperties(values, entityId));
    }
  }

  componentDidMount() {
    this.clearComponentState();
    RtnEventsEmitter.subscribe(rtnEvents.VideoAssetsUpdateCommandCompleted, this.updateVideoInfo);
    RtnEventsEmitter.subscribe(rtnEvents.VideoAssetPublishSuccess, this.onVideoPublished);
  }

  componentWillUnmount() {
    this.autosave.dispose();
    RtnEventsEmitter.unsubscribe(rtnEvents.VideoAssetsUpdateCommandCompleted, this.updateVideoInfo);
    RtnEventsEmitter.unsubscribe(rtnEvents.VideoAssetPublishSuccess, this.onVideoPublished);
    this.cancelFileUpload();
    this.cancellationToken.isCancelRequested = true;
    this.clearComponentState();
    this.stepsManager.dispose();
  }

  autosaveStateProvider = () => this.getAssetProperties(this.props.values, this.props.entityId);

  updateVideoInfo = () => {
    this.props.videosActions.saveVideoInfo(this.props.values);
  };

  /* istanbul ignore next */
  onVideoPublished = () => {
    this.isAssociatedPacksEnabled && this.setState({ showAddPacks: true });
  };

  clearComponentState() {
    this.props.videosActions.resetVideoInfo();
    this.props.libraryUserPermissionsActions.resetFilter();
    this.props.videoEntityStateActions.resetVideoEntityState();
    this.props.resetTags();
  }

  onTaskIdChanged(taskId: number) {
    this.setState({ taskId });
  }

  isDraftCreated = () => this.props.entityId > 0;

  configurationIsValid = () => {
    const { uploadingStatus, isValid } = this.props;
    return isValid && uploadingStatus && uploadingStatus.isAssetVideoUploaded && this.isDraftCreated();
  };

  saveVideoInfo = () => {
    const { values } = this.props;
    this.props.videosActions.saveVideoInfo(values);
  };

  onProgressAsync = async (activeStepIndex: number, nextIndex: number) => {
    if (activeStepIndex === 0) {
      this.saveVideoInfo();
      await this.props.submitForm();
    }
    this.stepsManager.onNext(nextIndex);
  };

  onRegressAsync = async (activeStepIndex: number, nextIndex: number) => {
    this.stepsManager.onPrevious(nextIndex);
  };

  getAssetProperties = (values: VideoInfo, id: number): VideoInfoPayload =>
    ({
      id: id,
      title: values.title.trim(),
      description: values.description.trim(),
    }) as VideoInfoPayload;

  autosaveValidator = () => {
    const { isValid } = this.props;
    return isValid && this.isDraftCreated();
  };

  getTask = (): VideoAssetTask | null => {
    const { tasks, values } = this.props;
    if (values.uploadedVideos && values.uploadedVideos.length > 0) {
      return (tasks[this.state.taskId] as VideoAssetTask) || {};
    }
    return null;
  };

  cancelFileUpload = () => {
    const task = this.getTask();
    task && task.onCancel && task.onCancel();
  };

  handleCancelClick = () => {
    navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, `/${RouteNames.contentVideos}`);
  };

  handleOnFinish = () => {
    this.props.videoEntityStateActions.publishVideoAsset(this.props.entityId);

    !this.isAssociatedPacksEnabled &&
      navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, `/${RouteNames.contentVideos}`);
  };

  onRemoveClosedCaptions = () => {
    const { onBatchRemoveClosedCaptions, entityId, selectedClosedCaptions } = this.props;

    this.onTriggerClosedCaptionsRemovalObserver.notify(() => {
      onBatchRemoveClosedCaptions(
        entityId,
        selectedClosedCaptions.map((item) => item.id),
      );
    }, selectedClosedCaptions.length);
  };

  renderCustomHeader = () => {
    if (this.props.selectedClosedCaptions.length > 0) {
      const isDefaultClosedCaptionSelected = this.props.selectedClosedCaptions.some((caption) => caption.isDefault);
      return (
        <RemoveClosedCaptionLinkButton
          multiple
          showTooltip
          isDisabled={isDefaultClosedCaptionSelected}
          onClick={this.onRemoveClosedCaptions}
        />
      );
    }
  };

  getTitle = () => {
    const closedCaptionsSelectedCount = this.props.selectedClosedCaptions.length;
    if (closedCaptionsSelectedCount > 0) {
      return `${closedCaptionsSelectedCount} ${pluralize("File", this.props.selectedClosedCaptions.length)} Selected`;
    }

    return "Create Video";
  };

  render() {
    const task = this.getTask();
    const { dateModified, isSaveInProgress, changingEntityState, navigate } = this.props;
    return (
      <>
        <Dimmer active={changingEntityState} inverted>
          <Loader active={changingEntityState} />
        </Dimmer>
        <Wizard
          title={this.getTitle()}
          finishButtonLabel="Finish"
          finishTooltipMessage={
            !this.props.canBePublished ? "Video is still processing and can be published when finished." : undefined
          }
          onProgressAsync={this.onProgressAsync}
          onRegressAsync={this.onRegressAsync}
          onCancel={this.handleCancelClick}
          onFinish={this.handleOnFinish}
          isSaveInProgress={isSaveInProgress}
          isFinishButtonDisabled={!this.props.canBePublished}
          progressSavedDate={CreateVideoStepConfig[this.state.activeStepIndex]?.showAutoSaveOnTab ? dateModified : null}
          renderCustomHeader={this.renderCustomHeader}
        >
          <Wizard.Step
            label="Configure"
            className="scrollable-content"
            required
            isLocked={!this.configurationIsValid()}
          >
            <AssetFormContainer
              isCreateAction={true}
              handleVideoFileChange={this.videoUploader.handleFileChange}
              uploadFilesHandler={this.videoUploader.uploadFilesHandler}
              onCroppedThumbnailClick={this.assetsThumbnailUploader.onCroppedThumbnailClick}
              onCancelFileUploading={this.cancelFileUpload}
              onBlur={this.autosave.onBlur}
              assetManifests={this.props.streamingManifests}
              generateThumbnail={this.assetsThumbnailUploader.generateThumbnail}
              generateThumbnailFromPosition={this.assetsThumbnailUploader.generateThumbnailFromPosition}
              isLoading={this.props.changingEntityState}
              task={task ? task : {}}
              {...this.props}
            />
          </Wizard.Step>
          <Wizard.Step label="Settings">
            <SettingsTab hasAnyPermission />
          </Wizard.Step>
          <Wizard.Step label="Closed Captions">
            <ClosedCaptions onTriggerClosedCaptionsRemovalObserver={this.onTriggerClosedCaptionsRemovalObserver} />
          </Wizard.Step>
        </Wizard>
        <AddAssetsToPackModal
          showModal={this.state.showAddPacks}
          onClose={/* istanbul ignore next */ () => navigate(`/${RouteNames.contentVideos}`)}
          onComplete={() => {}}
          selectedItemIds={[this.props.entityId]}
          contentType="Video"
        />
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = videosStateSelector(state).base;
  return {
    tasks: state.backgroundTasks.tasks,
    customerId: state.userProfile.accountId,
    selectedClosedCaptions: getAssignedClosedCaptions(state).selectedItems,
    uploadingStatus: base.videoInfoReducer.uploadingStatus,
    entityId: base.videoEntityStateReducer.entityId,
    isSaveInProgress: base.videoEntityStateReducer.isEntityCommandInProgress,
    streamingManifests: base.updateVideoReducer.videoAssetStreamingManifests,
    dateModified: base.updateVideoReducer.dateModified,
    videoInfo: base.videoInfoReducer.videoInfo,
    changingEntityState: base.videoEntityStateReducer.changingEntityState,
    amsJobsStatus: base.updateVideoReducer.amsJobsStatus,
    canBePublished: base.updateVideoReducer.canBePublished,
    thumbnailUrl: base.updateVideoReducer.thumbnailUrl,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    videosActions: bindActionCreators(videosActions, dispatch),
    videoEntityStateActions: bindActionCreators(videoEntityStateActions, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
    libraryUserPermissionsActions: bindActionCreators(userAssignmentActions, dispatch),
    resetTags: () => dispatch(resetTags()),
    onBatchRemoveClosedCaptions: (videoId: number, ids: number[]) =>
      batch(() => {
        dispatch(deleteClosedCaptions(videoId, ids));
        dispatch(resetSelectedItems());
      }),
    uploadAwsVideo: bindActionCreators(uploadVideoToAws, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const component = withFormik({
  validationSchema: validationSchemas.videoInfo,
  enableReinitialize: true,
  /* istanbul ignore next */
  mapPropsToValues: (props: CreateAssetProps) => {
    return props.videoInfo;
  },
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withNotifyConfig(CreateVideo, initialNotifyConfigDefault));

export default connector(withRouter(withLDConsumer()(component)));
