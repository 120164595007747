import { getMapper } from "../../../../../Common/Mappers/QuestionToQuestionMapper";
import { MapResult } from "../../../../../Common/models";
import { ISurveyQuestion } from "../types";
import { getQuestionDefaultData } from "./defaultQuestionProvider";
import { SurveyQuestionType } from "../../../../types/models";

const questionToQuestionMap = (() => {
  const mapper = getMapper();

  mapper
    .map()
    .from(SurveyQuestionType.MultipleChoiceTextQuestion)
    .to(SurveyQuestionType.SingleChoiceTextQuestion)
    .mapper((input: ISurveyQuestion<string>): ISurveyQuestion<string> => {
      return { ...input, questionType: SurveyQuestionType.SingleChoiceTextQuestion };
    });

  mapper
    .map()
    .from(SurveyQuestionType.SingleChoiceTextQuestion)
    .to(SurveyQuestionType.MultipleChoiceTextQuestion)
    .mapper((input: ISurveyQuestion<string>): ISurveyQuestion<string> => {
      return { ...input, questionType: SurveyQuestionType.MultipleChoiceTextQuestion };
    });

  return mapper.create();
})();

export const MapQuestion = (to: SurveyQuestionType, input: ISurveyQuestion<any>): MapResult<ISurveyQuestion<any>> => {
  const mapper = questionToQuestionMap[input.questionType]?.[to];

  if (mapper) {
    return { question: mapper(input), success: true };
  }
  const defaultData = getQuestionDefaultData(to);
  return { question: { ...defaultData }, success: false };
};
