import dateTimeUtils from "../../../utils/dateTimeUtils";
import imageUtils from "../../../utils/imageUtils";

const imageHelper = {
  createImageFromCanvas: (video, currentTime) => {
    const canvas = imageUtils.capture(video);
    const src = canvas.toDataURL("image/png");
    const time = dateTimeUtils.msToTime(currentTime);
    const position = video.currentTime.toString().match(/^-?\d+(?:\.\d?)?/)[0];
    return { src, time, position };
  },
};

export default imageHelper;
