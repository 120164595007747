import { FormikProps } from "formik";

import { ValidatedFormWithoutFormik } from "../../forms";

import "./daysToCompleteInput.scss";

export interface DaysToCompleteInputProps {
  onDaysToCompleteChange: Function;
  approxEstimateMinutes: number;
  onValidChange?: (...args: any[]) => any;
  daysToComplete?: number;
}

function DaysToCompleteInput(props: DaysToCompleteInputProps & FormikProps<any>) {
  const {
    onValidChange,
    onDaysToCompleteChange,
    daysToComplete,
    approxEstimateMinutes,
    errors,
    touched,
    isValid,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    dirty,
    resetForm,
  } = props;

  const validatedFieldProps = {
    errors,
    touched,
    isValid,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    dirty,
    resetForm,
  };

  const days = Number(daysToComplete) > 0 ? daysToComplete : "";
  const minsPerDay = daysToComplete ? Math.ceil(approxEstimateMinutes / daysToComplete) : 0;

  return (
    <div className="days-to-complete">
      <div className={"days-to-complete-label"}>Days to complete</div>
      <ValidatedFormWithoutFormik>
        <div className={"days-to-complete-input"}>
          <ValidatedFormWithoutFormik.InputField
            propertyName="daysToComplete"
            value={days}
            markAsRequired
            isFormValid={isValid}
            onIsFormValidChange={onValidChange}
            autoFocus
            maxLength={7}
            errorPosition="right"
            handleChange={(_, data) => {
              onDaysToCompleteChange("daysToComplete", parseInt(data.value) || undefined); // for triggering "is required" validation
            }}
            mask={/^([1-9]\d*|\b|^$)$/}
            {...validatedFieldProps}
          />
        </div>
      </ValidatedFormWithoutFormik>
      {!!days && isValid && !isNaN(minsPerDay) && (
        <div className={"mins-per-day"}>Approx. {minsPerDay} mins per day</div>
      )}
    </div>
  );
}

export default DaysToCompleteInput;
