import React, { Component } from "react";

import "./multiselectSearchDropdown.scss";

import ValidatedForm from "../forms/ValidatedForm";
import multiselectSearchDropdownPropTypes from "./multiselectSearchDropdownPropTypes";

export default class MultiselectSearchDropdown extends Component {
  state = {
    searchQuery: "",
  };

  onSearchChange = (_, { searchQuery }) => {
    this.setState({ searchQuery });
    this.props.handleSearchQueryChange(searchQuery);
  };

  handleBlur = (e, d) => {
    if (this.state.searchQuery !== "") {
      this.onSearchChange({}, { searchQuery: "" });
    }
    this.props.handleBlur(e, d);
  };

  render() {
    const { searchQuery } = this.state;
    const {
      value,
      dropdownOptions: { items, isLoading },
      renderLabel,
      label,
      multiple,
      placeholder,
      propertyName,
      handleValueChange,
      markAsRequired,

      setFieldTouched,
      setFieldValue,
      errors,
      touched,
      isFormValid,
      disabled,
    } = this.props;

    const validatedFieldProps = {
      setFieldTouched,
      setFieldValue,
      errors,
      touched,
      isFormValid,
      handleBlur: this.handleBlur,
    };

    return (
      <div className="multiselect-search-dropdown">
        <ValidatedForm.DropdownField
          renderLabel={renderLabel}
          fluid
          multiple={multiple}
          search
          selection
          value={value}
          options={items}
          label={label}
          propertyName={propertyName}
          searchQuery={searchQuery}
          placeholder={placeholder}
          markAsRequired={markAsRequired}
          isLoading={isLoading}
          disabled={isLoading || disabled}
          shouldValidate={true}
          onSearchChange={this.onSearchChange}
          onChangeCallback={handleValueChange}
          {...validatedFieldProps}
        />
      </div>
    );
  }
}

MultiselectSearchDropdown.propTypes = multiselectSearchDropdownPropTypes;
