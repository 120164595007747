import { Loader, Dimmer } from "semantic-ui-react";
import styles from "./Root.module.scss";
import cn from "classnames";
import { PreventTransitionPrompt } from "components";
import { isBoolean } from "lodash";

interface Props {
  dirty?: boolean;
  isLoading?: boolean;
  preventTransition?: { title: string; message: string } | boolean;
}

export const Root = (props: React.PropsWithChildren<Props>) => {
  const { isLoading, preventTransition, dirty } = props;
  const renderPreventTransitionPrompt = () => {
    if (!preventTransition) {
      return null;
    }
    const { title, message } = isBoolean(preventTransition)
      ? { title: "Unsaved changes", message: "Leave now and lose all of the amazing changes?" }
      : preventTransition;

    return <PreventTransitionPrompt when={!!dirty} title={title} message={message} />;
  };
  return (
    <Dimmer.Dimmable dimmed={isLoading} className={styles["root"]}>
      <Dimmer active={isLoading} inverted>
        <Loader active={isLoading} />
      </Dimmer>
      <form className={cn(styles["form"], "ui form")}>{props.children}</form>
      {renderPreventTransitionPrompt()}
    </Dimmer.Dimmable>
  );
};
