import * as Yup from "yup";
import { requiredTextFieldMinMax } from "../validationSchemas/commonValidationSchemas";
import { CUSTOM_EMAIL } from "../emailUtils";

const portLengths = {
  min: 1,
  max: 65535,
};

const validIpAddressRegex = RegExp(/^((\d|\d\d|1\d{2}|2\d\d|25\d)\.){3}(\d|\d\d|1\d{2}|2\d\d|25\d)$/);
const validHostnameRegex = RegExp(
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/,
);
const validPasswordRegex = RegExp(/^(?!\s+$).*/);
const numStartWithSignsRegex = RegExp(/^[-+]/);

const hostMaxLength = 255;
const incorrectPortValueMessage = `Port has invalid format. Port should be between ${portLengths.min} and ${portLengths.max}`;
const hostHasIncorrectFormatMessage = `Host has incorrect format`;

const host = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Host is required")
    .max(hostMaxLength, `Host must be maximum of ${hostMaxLength} characters long`)
    .test("valid-host", hostHasIncorrectFormatMessage, (hostValue) => {
      const hostString = String(hostValue);
      const withoutWhiteSpaces = RegExp(/^\S+$/).test(hostString);
      return (validHostnameRegex.test(hostString) || validIpAddressRegex.test(hostString)) && withoutWhiteSpaces;
    });

const port = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Port is required")
    .test("valid-port", incorrectPortValueMessage, (portStr) => {
      const portNumber = Number(portStr);
      const isPortStrNotStartedWithPlus = !numStartWithSignsRegex.test(portStr || "");
      const isPortRangeValid = portNumber >= portLengths.min && portNumber <= portLengths.max;

      return Boolean(portNumber) && isPortStrNotStartedWithPlus && isPortRangeValid;
    });

const password = (min: number, max: number): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .required("Password is required")
    .min(min, `$Password must have at least ${min} characters`)
    .max(max, `$Password must be maximum of ${max} characters long`)
    .test("valid-password", "Password should not contain only whitespaces", (value) =>
      validPasswordRegex.test(String(value)),
    );

const emailVerify = (min: number, max: number): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .min(min, `Email must have at least ${min} characters`)
    .max(max, `Email must be maximum of ${max} characters long`)
    .matches(CUSTOM_EMAIL, "Invalid Email");

export const sendingProfileInfo = Yup.object().shape({
  host: host(),
  port: port(),
});

export const smtpAuthSendingProfileConfigure = Yup.object().shape({
  senderName: requiredTextFieldMinMax("Sender Name", 1, 64),
  senderEmail: requiredTextFieldMinMax("Sender Email", 3, 256)
    .matches(CUSTOM_EMAIL, "Invalid Sender Email"),
});

export const smtpAuthSendingProfileSettings = Yup.object().shape({
  host: host(),
  port: port(),
  userName: requiredTextFieldMinMax("Username", 1, 255),
  password: password(1, 255),
});

export const smtpAuthSendingProfileVerification = Yup.object().shape({
  email: emailVerify(3, 256),
});
