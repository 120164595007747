import { TemplateTypes } from "../../../enums";

export interface EmailTemplateMetadata {
  subject: string;
  previewText: string;
}

export interface TeamsTemplateMetadata {
  headline: string;
  imageUrl: string;
  body: string;
  destinationUrl: string;
  callToAction: string;
}

export const getEmailTemplateMetadata = (templateType: TemplateTypes): EmailTemplateMetadata => {
  if (!emailMetadata.has(templateType)) {
    return defaultEmailMetadata;
  }

  return emailMetadata.get(templateType)!;
};

export const getTeamsTemplateMetadata = (templateType: TemplateTypes): TeamsTemplateMetadata => {
  if (!teamsMetadata.has(templateType)) {
    return defaultTeamsMetadata;
  }

  return teamsMetadata.get(templateType)!;
};

const defaultEmailMetadata: EmailTemplateMetadata = { subject: "", previewText: "" };
const defaultTeamsMetadata: TeamsTemplateMetadata = {
  headline: "",
  imageUrl: "",
  body: "",
  destinationUrl: "",
  callToAction: "",
};

const emailMetadata: Map<TemplateTypes, EmailTemplateMetadata> = new Map([
  [
    TemplateTypes.AddedToFlowV2,
    {
      subject: "{{FirstName}}, a new learning journey awaits you",
      previewText: "Level-up your skillset with this new learning experience!",
    },
  ],
  [
    TemplateTypes.AddedToVideo,
    {
      subject: "New for you, check out the {{Video.Title}} video now available",
      previewText: "Level up your skillset with new content now available to you.",
    },
  ],
  [
    TemplateTypes.VideoChanged,
    {
      subject: "The {{Video.Title}} video has been updated.",
      previewText: "See what's new with the {{Video.Title}} video.",
    },
  ],
  [
    TemplateTypes.AddedToPdf,
    {
      subject: "New for you, check out the {{PDF.Title}} PDF now available",
      previewText: "Level up your skillset with new content now available to you.",
    },
  ],
  [
    TemplateTypes.PdfChanged,
    {
      subject: "The {{PDF.Title}} PDF has been updated.",
      previewText: "See what's new with the {{PDF.Title}} PDF.",
    },
  ],
  [
    TemplateTypes.AddedToAssessment,
    {
      subject: "New for you, check out the {{Assessment.Title}} assessment now available",
      previewText: "Level up your skillset with new content now available to you.",
    },
  ],
  [
    TemplateTypes.AssessmentChanged,
    {
      subject: "The {{Assessment.Title}} assessment has been updated.",
      previewText: "See what's new with the {{Assessment.Title}} assessment.",
    },
  ],
  [
    TemplateTypes.AddedToGroup,
    {
      subject: "{{FirstName}}, Welcome to the New BrainStorm Platform!",
      previewText: "We’re excited to announce that {{AccountName}} has partnered with BrainStorm",
    },
  ],
  [
    TemplateTypes.WelcomeEmail,
    {
      subject: "Your BrainStorm account is ready to go",
      previewText: "Your BrainStorm is set up and ready to go - all that’s left is for you to log in!",
    },
  ],
  [
    TemplateTypes.EventAssigned,
    {
      subject: "New for you, check out the {{EventTitle}} event now available",
      previewText: "Level up your skillset with new content now available to you.",
    },
  ],
  [
    TemplateTypes.AddedToSurvey,
    {
      subject: "New for you, check out the {{Title}} survey now available",
      previewText: "Level up your skillset with new content now available to you.",
    },
  ],
  [
    TemplateTypes.SurveyChanged,
    {
      subject: "The {{Title}} survey has been updated.",
      previewText: "See what's new with the {{Title}} survey.",
    },
  ],
  [
    TemplateTypes.FlowRepublished,
    {
      subject: "The {{Flow.Title}} flow has been updated.",
      previewText: "See what’s new with the {{Flow.Title}} flow.",
    },
  ],
  [
    TemplateTypes.AccountCreated,
    {
      subject: "Create a password for your new BrainStorm account",
      previewText: "Your BrainStorm account is ready to go - all that’s left is for you to create a password.",
    },
  ],
  [
    TemplateTypes.ApproveGroupTemplate,
    {
      subject: "{{FromAccountName}} would like to manage your account via template",
      previewText: "Action Required to allow {{FromAccountName}} to add content and/or users to your account.",
    },
  ],
]);

const teamsMetadata: Map<TemplateTypes, TeamsTemplateMetadata> = new Map([
  [
    TemplateTypes.AddedToFlowV2,
    {
      headline: "Level-up your skillset with this new learning experience!",
      imageUrl: "{{ImagesStorage}}/addedToFlowV2/addedtoflowheader.png",
      body: `{{FirstName}}, a new user journey awaits you.

Great news! Your BrainStorm admin has picked out a new course to help you with your specific responsibilities.`,
      destinationUrl: "{{UserPortalUrl}}/{{Flow.Url}}",
      callToAction: "{{Flow.Title}}",
    },
  ],
  [
    TemplateTypes.FlowRepublished,
    {
      headline: "See what’s new with the {{Flow.Title}} flow.",
      imageUrl: "{{ImagesStorage}}/flowRepublished/flowrepublishedheader.png",
      body: "{{FirstName}}, Please be aware changes have been made to the {{Flow.Title}} course.",
      destinationUrl: "{{UserPortalUrl}}/{{Flow.Url}}",
      callToAction: "{{Flow.Title}}",
    },
  ],
  [
    TemplateTypes.AddedToVideo,
    {
      headline: "Level up your skillset with new content now available to you.",
      imageUrl: "{{ImagesStorage}}/addedToVideo/addedtovideoheader.png",
      body: `{{FirstName}}, a new video awaits you.

Your administrator has added the {{Video.Title}} video for you to complete.`,
      destinationUrl: "{{UserPortalUrl}}/{{Video.UriPath}}",
      callToAction: "{{Video.Title}}",
    },
  ],
  [
    TemplateTypes.VideoChanged,
    {
      headline: "See what's new with the {{Video.Title}} video.",
      imageUrl: "{{ImagesStorage}}/videoChanged/videochangedheader.png",
      body: `{{FirstName}},

Please be aware changes have been made to the {{Video.Title}} video.`,
      destinationUrl: "{{UserPortalUrl}}/{{Video.UriPath}}",
      callToAction: "{{Video.Title}}",
    },
  ],
  [
    TemplateTypes.AddedToPdf,
    {
      headline: "Level up your skillset with new content now available to you.",
      imageUrl: "{{ImagesStorage}}/addedToPdf/addedtopdfheader.png",
      body: `{{FirstName}}, a new PDF awaits you.

Your administrator has added the {{PDF.Title}} PDF for you to complete.`,
      destinationUrl: "{{UserPortalUrl}}/{{PDF.UriPath}}",
      callToAction: "{{PDF.Title}}",
    },
  ],
  [
    TemplateTypes.PdfChanged,
    {
      headline: "See what's new with the {{PDF.Title}} PDF.",
      imageUrl: "{{ImagesStorage}}/addedToPdf/addedtopdfheader.png",
      body: `{{FirstName}},

Please be aware changes have been made to the {{PDF.Title}} PDF.`,
      destinationUrl: "{{UserPortalUrl}}/{{PDF.UriPath}}",
      callToAction: "{{PDF.Title}}",
    },
  ],
  [
    TemplateTypes.AddedToAssessment,
    {
      headline: "Level up your skillset with new content now available to you.",
      imageUrl: "{{ImagesStorage}}/addedToAssessment/addedtoassessmentheader.png",
      body: `{{FirstName}}, a new assessment awaits you.

Your administrator has added the {{Assessment.Title}} assessment for you to complete.`,
      destinationUrl: "{{UserPortalUrl}}/{{Assessment.UriPath}}",
      callToAction: "{{Assessment.Title}}",
    },
  ],
  [
    TemplateTypes.AssessmentChanged,
    {
      headline: "See what's new with the {{Assessment.Title}} assessment.",
      imageUrl: "{{ImagesStorage}}/assessmentChanged/assessmentchangedheader.png",
      body: `Hi {{FirstName}},

Please be aware changes have been made to the {{Assessment.Title}} assessment.`,
      destinationUrl: "{{UserPortalUrl}}/{{Assessment.UriPath}}",
      callToAction: "{{Assessment.Title}}",
    },
  ],
  [
    TemplateTypes.AddedToSurvey,
    {
      headline: "Level up your skillset with new content now available to you.",
      imageUrl: "{{ImagesStorage}}/addedToSurvey/addedtosurveyheader.png",
      body: `Hi, {{FirstName}}

Your administrator has added the {{Title}} survey for you to complete.`,
      destinationUrl: "{{RedirectLink}}",
      callToAction: "{{Title}}",
    },
  ],
  [
    TemplateTypes.SurveyChanged,
    {
      headline: "See what's new with the {{Title}} survey.",
      imageUrl: "{{ImagesStorage}}/surveyChanged/surveychangedheader.png",
      body: `Hi {{FirstName}},

Please be aware changes have been made to the {{Title}} survey.`,
      destinationUrl: "{{RedirectLink}}",
      callToAction: "{{Title}}",
    },
  ],
  [
    TemplateTypes.EventAssigned,
    {
      headline: "Level up your skillset with new content now available to you.",
      imageUrl: "{{ImagesStorage}}/eventAssigned/eventassignedheader.png",
      body: `Hi, {{FirstName}}

Your administrator has invited you to attend the {{EventTitle}} event.
Please register for this event to add an appointment to your calendar.`,
      destinationUrl: "{{UserPortalUrl}}/discover/event/{{ExternalEventId}}",
      callToAction: "Register for the Event",
    },
  ],
  [
    TemplateTypes.AddedToGroup,
    {
      headline: "Welcome to the New BrainStorm Platform!",
      imageUrl: "{{ImagesStorage}}/addedToGroup/bsitopbanner.png",
      body: `Hi {{FirstName}},

We’re excited to announce that {{AccountName}} has partnered with BrainStorm to launch a platform to help you learn more about your software and drive positive change in the organization.

What this means for you:

- You now have access to relevant, up-to-date learning resources at that help you save time and effort in your day-to-day tasks.

- You will begin to receive invitations to responsive, proactive learning experiences that focus on the topics and applications most important for the organization and your role.

What should you do now?

- First, be on the lookout for more messages coming from brainstorminc.com. These messages will direct you to where to get started and other valuable learning.

- Expect to see invitations to hand-picked, short courses that will teach you how to use your software to get your jobs done effectively and efficiently. We ask that you take a few minutes each week to complete these brief courses.

- If you'd like to access the platform now, just visit {{UserPortalUrl}} and follow the prompts to log in.

Thank you for being part of this effort. We're excited to see where this takes us all!`,
      destinationUrl: "{{UserPortalUrl}}",
      callToAction: "Check it out",
    },
  ],
  [
    TemplateTypes.WelcomeEmail,
    {
      headline: "Your BrainStorm is set up and ready to go - all that’s left is for you to log in!",
      imageUrl: "{{ImagesStorage}}/welcomeEmail/welcomeemailheaderimage.png",
      body: `Hi {{FirstName}},

{{AccountName}} has partnered with BrainStorm to help you make the most of your technology and empower you to work more effectively. 

Need a jargon-free translation?

BrainStorm is here to help you:

- Hold fewer meetings

- Manage interruptions

- Cut project time in half

- Blow your boss away with killer presentations

- Plus more

And good news — your account is already set up and ready to go. All that's left is for you is to log in!

What is BrainStorm?

BrainStorm is an online learning experience designed to help you level-up your skill set and get stuff done more efficiently and painlessly — so where do you want to start?

You can choose what you want to learn, whether that's staying up to date with your most-used apps, discovering new ones, or attending live instructor-led events to figure out some best practices.

Happy learning!

[The BrainStorm Team]`,
      destinationUrl: "{{UserPortalUrl}}",
      callToAction: "Log in here",
    },
  ],
  [
    TemplateTypes.AccountCreated,
    {
      headline: "Your BrainStorm account is ready to go - all that’s left is for you to create a password.",
      imageUrl: "{{ImagesStorage}}/accountCreated/accountcreatedheaderimage.png",
      body: `Hi {{FirstName}},

Your organization’s BrainStorm account is ready to go. The only thing left to do is create your password!

With just one click, you’ll be on your way to faster, deeper software adoption, streamlined onboarding, dynamic learning experiences, and more.

Welcome aboard!

[The BrainStorm Team]`,
      destinationUrl: "{{AdminPortalUrl}}",
      callToAction: "Create Password",
    },
  ],
  [
    TemplateTypes.ApproveGroupTemplate,
    {
      headline: "Action Required: Request to Manage Your Account",
      imageUrl: "{{ImagesStorage}}/addedToGroup/bsitopbanner.png",
      body: "Hi {{FirstName}},\n\n{{FromAccountName}} would like to manage your users via template {% if Packs.size != 0 %} and enable the following packs in your account: \n\n{% for pack in Packs %}\n\n - **{{pack.Title}}** - {{pack.PackDescription}} \n\n{% endfor %}{% endif %}\n\nBy approving this request, you are also consenting to allow {{FromAccountName}} to prioritize content for these users.",
      destinationUrl: "{{AdminPortalUrl}}/{{ApproveGroupTemplateUrlPath}}",
      callToAction: "Approve Request",
    },
  ],
]);
