import { MutableRefObject, useCallback } from "react";

import flowsDataService from "../../../services/flowsDataService";

import { IFlowEdge, IFlowNode, IFlowNodeWithEntity } from "../nodes/types";
import { EntityType, FlowDesignerData, FlowItem, TriggersData } from "../../types";
import { FlowValidator } from "../../validator/FlowValidator";
import { DEFAULT_HANDLER_ID, PLACEHOLDER_ELEMENT_ID, START_OF_THE_FLOW_ELEMENT_ID } from "../../constants";
import { useReactFlow } from "reactflow";
import { useLocalContext } from "../../../../../../hooks/useLocalContext";

export interface UseSavingProps {
  id: number;
  headId: string | undefined;
  flowValidator: FlowValidator;
  onSavingProcessBegin: () => void;
  onSavingProcessSuccess: () => void;
  flowDataRef: MutableRefObject<{ nodes: IFlowNode[]; edges: IFlowEdge[]; headId?: string } | undefined>;
}
const getTriggers = (edges: IFlowEdge[]): TriggersData[] => {
  return edges.map(
    ({ data }) =>
      ({
        inId: data?.inId,
        outId: data?.outId,
        timeSpan: data?.timeSpan,
        timeUnitId: data?.timeUnitId || 0,
        typeId: data?.typeId || 0,
        isAction: data?.isAction,
        isDefault: data?.isDefault,
        bulletId: data?.bulletId && data?.bulletId !== DEFAULT_HANDLER_ID ? data?.bulletId : undefined,
      } as TriggersData),
  );
};

const getItems = (nodes: IFlowNode[]) => {
  return nodes
    .filter((node) => node.id !== START_OF_THE_FLOW_ELEMENT_ID && node.id !== PLACEHOLDER_ELEMENT_ID)
    .map((item) => {
      const d = item as IFlowNodeWithEntity;
      const mapped: FlowItem = {
        hasEntity: !!d.data?.hasEntity,
        entityId: `${d.data?.entityId || 0}`,
        entityType: d.data?.elementType as EntityType,
        id: d.id,
        position: { ...d.position },
        branchingQuestion: d.data?.branchingQuestion,
        bag: d.data?.bag,
      };

      if (d.data?.flowEndInfo) {
        mapped.flowEndInfo = {
          completedMessage: d.data?.flowEndInfo?.completedMessage,
          nextFlowId: d.data?.flowEndInfo?.nextFlow?.id,
        };
      }

      if (d.data?.sectionHeader) {
        mapped.sectionHeader = { name: d.data.sectionHeader.name, description: d.data.sectionHeader.description };
      }

      return mapped;
    });
};

function prepareFlowDesignerData(props: {
  id: number;
  headId?: string;
  nodes: IFlowNode[];
  edges: IFlowEdge[];
}): FlowDesignerData {
  const { id, headId, nodes, edges } = props;
  return {
    id,
    headId,
    items: getItems(nodes),
    triggers: getTriggers(edges),
  };
}

function saveOnBackend({
  flowDesignerData,
  onBegin,
  onSuccess,
}: {
  flowDesignerData: FlowDesignerData;
  onBegin: () => void;
  onSuccess: (id?: string) => void;
}) {
  onBegin();
  flowsDataService.updateFlowDesigner(flowDesignerData.id, JSON.stringify(flowDesignerData)).then(() => onSuccess());
}

export const useSaving = (params: UseSavingProps) => {
  const context = useLocalContext(params);
  const { getNodes, getEdges } = useReactFlow();
  const onSave = useCallback(() => {
    const { id, headId, flowValidator, onSavingProcessBegin, onSavingProcessSuccess, flowDataRef } = context.current;

    const nodes = getNodes();
    const edges = getEdges();

    const flowDesignerData = prepareFlowDesignerData({ id, headId, nodes, edges });

    flowDataRef.current = { nodes, edges, headId };
    flowValidator.validate(flowDesignerData);

    saveOnBackend({
      flowDesignerData,
      onBegin: onSavingProcessBegin,
      onSuccess: onSavingProcessSuccess,
    });
  }, [context, getNodes, getEdges]);

  return () => setTimeout(onSave, 0);
};
