import "./noData.scss";

export const NoData = () => {
  return <div className="noData">No data</div>;
};

export const NoDataMonth = () => {
  return <div className="noData">No data for the Last Month</div>;
};

export const NoFilteredData = () => {
  return <div className="noData">No Data for Selected Date Range</div>;
};
