import React, { FC } from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import { Icon } from "semantic-ui-react";

export interface AddGroupsLinkButtonProps {
  onClick: () => void;
  warning?: boolean;
  disabled?: boolean;
}

const AddGroupsLinkButton: FC<AddGroupsLinkButtonProps> = ({ onClick, disabled, warning }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <BasicLinkButton text="Add Groups" iconClassName="fal fa-user" onClick={onClick} isDisabled={disabled} />
    {warning && <Icon size="large" className="fa fa-exclamation-circle" />}
  </div>
);

export default AddGroupsLinkButton;
