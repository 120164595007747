import { Dispatch } from "@reduxjs/toolkit";

import dataService from "../../../../Application/services/dataServices/typedDataService";
import UpdateParams from "../../../../../interfaces/updateParams";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";

import { fetchTags, fetchTagsFailure } from "../slices/pdfBaseSlice";
import { pluralize } from "../../../../../utils/stringUtils";
import { AppDispatch, AppThunk } from "../../../../Application/globaltypes/redux";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import { tagEntity } from "../../../../../components/addTagsModal/helpers";

export const fetchPdfTags = () => async (dispatch: Dispatch) => {
  try {
    const res = await dataService.pdfs.getTagsFilterOptionsAsync(true);
    dispatch(fetchTags(res.data));
  } catch (e) {
    dispatch(fetchTagsFailure(e as Error));
  }
};

export const addTagsToPdfs =
  (ids: number[], tags: string[], entityType: TagsEnum): AppThunk =>
  async (dispatch: AppDispatch) => {
    if (!tags.length) {
      return;
    }
    const getMessageIds = (pdfIds: number[], _tags?: string[]) => async () => {
      const result = await dataService.pdfs.addTagsToPdfAsync({ ids: pdfIds, tags, tagType: entityType });
      return result?.data.messageIds;
    };

    const transientMessagePrefix = pluralize(tagEntity[entityType], tags.length);

    const updateParams: UpdateParams = {
      id: "AddTagsToPdfAsset",
      title: `${transientMessagePrefix} adding`,
      getMessageIds: getMessageIds(ids),
      successTransientMessage: `${transientMessagePrefix} ${tags.length === 1 ? "has" : "have"} been added!`,
      failureTransientMessage: `${transientMessagePrefix} add failed!`,
    };

    await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
  };
