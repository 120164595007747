import { PayloadAction } from "@reduxjs/toolkit";
import { createReducer } from "../../../../../../utils/reduxUtils";
import * as publishInfoActionTypes from "../actionTypes/emailPublishInfoActionTypes";
import { EmailPublishInfoState } from "../../types/state";
import { PublishInfo } from "../../types/models";

export const initialState: EmailPublishInfoState = {
  publishInfo: { notNotifiedPeopleCount: 0 },
  isLoading: false,
};

export const emailPublishInfoHandlers = () => {
  const setEmailPublishInfoHandler = (
    state: EmailPublishInfoState,
    action: PayloadAction<PublishInfo>,
  ): EmailPublishInfoState => {
    return {
      ...state,
      publishInfo: action.payload,
    };
  };

  const setEmailPublishInfoLoadingHandler = (
    state: EmailPublishInfoState,
    action: PayloadAction<boolean>,
  ): EmailPublishInfoState => {
    return {
      ...state,
      isLoading: action.payload,
    };
  };

  const setEmailPublishInfoFetchingFailureHandler = (): EmailPublishInfoState => {
    return {
      publishInfo: { notNotifiedPeopleCount: 0 },
      isLoading: false,
    };
  };

  const { setEmailPublishInfoLoading, setEmailPublishInfo, emailPublishInfoFetchingFailure } = publishInfoActionTypes;

  return {
    [setEmailPublishInfoLoading]: setEmailPublishInfoLoadingHandler,
    [setEmailPublishInfo]: setEmailPublishInfoHandler,
    [emailPublishInfoFetchingFailure]: setEmailPublishInfoFetchingFailureHandler,
  };
};

export const emailPublishInfoReducer = createReducer(initialState, [emailPublishInfoHandlers]);
