import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../../features/Application/actions/actionsBuilder";
import * as fetchingItemsActionTypes from "../../../../../features/Application/actions/fetchingItems/fetchingItemsActionTypes";

export const FETCH_PACKS_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Licensing,
  ReducerEntityPrefixTypes.Packs,
  fetchingItemsActionTypes.FETCH_ITEMS_BEGIN,
);

export const FETCH_PACKS_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Licensing,
  ReducerEntityPrefixTypes.Packs,
  fetchingItemsActionTypes.FETCH_ITEMS_SUCCESS,
);

export const FETCH_PACKS_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Licensing,
  ReducerEntityPrefixTypes.Packs,
  fetchingItemsActionTypes.FETCH_ITEMS_FAILURE,
);

export const RESET_PACKS = buildRootEntityActionType(
  ReducerNamespaceTypes.Licensing,
  ReducerEntityPrefixTypes.Packs,
  fetchingItemsActionTypes.RESET_GRID_ITEMS,
);
