import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums/reducer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPrefix } from "../../../../../Application/slices/models";
import { EntityType } from "../../types";
import AssetTypes from "../../../../../../enums/assetTypes";
import CommunicationTypes from "../../../../../../enums/communicationTypes";
import { ExtrasTypes } from "../../Extras/types";
import EventTypes from "../../../../../../enums/eventTypes";
import { FlowDesignerViewTab } from "../../../../../../enums";

export interface FlowDesignerContextState {
  activeTab: FlowDesignerViewTab;
  entityType: { [key in FlowDesignerViewTab]: EntityType };
}

export const initialState: FlowDesignerContextState = {
  activeTab: FlowDesignerViewTab.Assets,
  entityType: {
    [FlowDesignerViewTab.Assets]: AssetTypes.Video,
    [FlowDesignerViewTab.Events]: EventTypes.ExternalEvent,
    [FlowDesignerViewTab.Communications]: CommunicationTypes.Email,
    [FlowDesignerViewTab.Extras]: ExtrasTypes.Extras,
  },
};

const flowDesignerContextSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "context",
  }),
  initialState: initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<FlowDesignerViewTab>) {
      state.activeTab = action.payload;
    },
    setEntityType(state, action: PayloadAction<EntityType>) {
      state.entityType[state.activeTab] = action.payload;
    },
    resetContext() {
      return initialState;
    },
  },
});

export const { setActiveTab, setEntityType, resetContext } = flowDesignerContextSlice.actions;

export default flowDesignerContextSlice.reducer;
