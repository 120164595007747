import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";
import React from "react";

interface Props {
  totalSeleted: number;
  hiddenSelected: number;
  onClick: () => void;
  disabled?: boolean;
}

export const ToggleFlowsVisibilityButton = (props: Props) => {
  const { totalSeleted, hiddenSelected, disabled, onClick } = props;
  if (hiddenSelected > 0 && totalSeleted !== hiddenSelected) {
    return null;
  }

  return <ToggleVisibilityButton hidden={hiddenSelected > 0} onClick={onClick} isDisabled={disabled} />;
};
