import { connect } from "react-redux";
import { useMemo } from "react";
import cn from "classnames";

import { EventCard } from "../../../../../cards";
import { pluralize } from "../../../../../../utils/stringUtils";
import { CardBase, Icon } from "../../../../../../interfaces";
import { RootState } from "../../../../../../features/Application/globaltypes/redux";
import { PeopleAvailableEvent, WithPeopleContext } from "../../../../../../features/People/ContentAssignments/models";

export interface AddToEventCardProps extends CardBase<WithPeopleContext<PeopleAvailableEvent>> {}

export const AddToEventCard: React.FC<AddToEventCardProps> = (props: AddToEventCardProps) => {
  const { item, selected, onSelect, disabled } = props;
  const { usersCount, selectedPeopleType, assignsCount } = item;

  const someAssigned = assignsCount > 0;
  const allAssigned = someAssigned && assignsCount === usersCount;

  const icons = useMemo((): Icon[] => {
    if (!someAssigned) {
      return [];
    }
    const belong = usersCount > 1 ? "belong" : "belongs";
    const tooltipMessage = allAssigned
      ? `This ${pluralize(selectedPeopleType, usersCount)} already ${belong} to this event.`
      : `(${assignsCount}) of the ${pluralize(
          selectedPeopleType,
          usersCount,
        )} selected already belong to this event and will be skipped.`;

    return [
      {
        iconClassName: "fa fa-exclamation-circle",
        tooltipContent: tooltipMessage,
        tooltipClassName: "warning",
        tooltipPosition: "top center",
      },
    ];
  }, [allAssigned, assignsCount, selectedPeopleType, someAssigned, usersCount]);

  return (
    <EventCard
      item={item}
      selected={selected}
      onSelect={onSelect}
      icons={icons}
      disabled={allAssigned || disabled}
      className={cn({ warning: someAssigned })}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  selectedPeopleType: state.people.addToContentModal.selectedPeopleType,
});

export default connect(mapStateToProps)(AddToEventCard);
