import React, { FC } from "react";
import cn from "classnames";

import dateTimeUtils from "../../../utils/dateTimeUtils";
import { EMPTY_CELL_CONTENT } from "../../../utils/columnUtils";

import styles from "./expiresGridTemplate.module.scss";

export interface ExpiresCardTemplateProps {
  expirationDate?: string | null;
}

const ExpiresCardTemplate: FC<ExpiresCardTemplateProps> = ({ expirationDate }) => {
  return (
    <span className={cn({ [styles.expired]: Boolean(expirationDate) })}>
      Expires {expirationDate ? dateTimeUtils.formatDate(expirationDate) : EMPTY_CELL_CONTENT}
    </span>
  );
};

export default ExpiresCardTemplate;
