import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchActionPayload } from "../../../../../interfaces/redux";
import { RootState } from "../../../../Application/globaltypes/redux";
import { PackResourceItem } from "../../types/state";

export interface PackTypesState {
  items: PackResourceItem[];
  itemsCount: number;
  isLoading: boolean;
  error: string | undefined;
}

const initialState: PackTypesState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const packTypesSlice = createSlice({
  name: "packTypes",
  initialState: initialState,
  reducers: {
    req(state: PackTypesState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: PackTypesState, action: PayloadAction<FetchActionPayload<PackResourceItem>>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
        itemsCount: action.payload.totalCount,
      };
    },
    err(state: PackTypesState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = packTypesSlice.actions;

export const packTypesSelector = (state: RootState) => state.packs.packTypes.items;

export default packTypesSlice.reducer;
export type packTypesStateSliceType = ReturnType<typeof packTypesSlice.reducer>;
