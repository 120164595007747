import { sum } from "lodash";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { Tooltip } from "../../../../components/common/tooltip";
import { EditLinkButton, DeleteLinkButton, DuplicateLinkButton } from "../../../../components/buttons/linkButtons";
import { ContentItemMenu, TextTruncate } from "../../../../components";
import { DeletionRestrictedTooltip } from "../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import Strings from "../../../../enums/strings";
import { PublishedStatus } from "../../../../components/common/publishedStatus";
import { PublishedStatusTypes } from "../../../../enums";
import { getColumnOptions } from "./columnOptions";
import { Title } from "../../../../components/listViewTemplates";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { ObjectiveLabel } from "features/Library/Flows/common/ObjectiveLabel/ObjectiveLabel";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";

function FlowRow({ flow, hasPermission, buttonHandlers, isDuplicateDisabled }) {
  const {
    id,
    publisher,
    title,
    published,
    dateModified,
    isEditable,
    readonly,
    canBeDeleted,
    dependencies,
    isChecked,
    goals,
    hidden,
  } = flow;

  const entityType = "flow";
  const publisherName = publisher ? publisher : Strings.notAvailable;
  const { packsCount = 0, totalAssignments = 0 } = dependencies || {};
  const dependenciesMap = { packs: packsCount, "users or groups": totalAssignments };
  const hasDeletionDependencies = !!sum(Object.values(dependenciesMap || {}));
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility);
  const columnOptions = getColumnOptions();
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <Title
          url={id.toString()}
          title={title}
          onTitleClick={buttonHandlers.handleEditClick(id, true)}
          className="flow-title normal-color"
          clamp={2}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <ObjectiveLabel objective={goals?.objective} />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <Tooltip target={<TextTruncate>{publisherName}</TextTruncate>} content={publisherName} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>
        <PublishedStatus publishedStatus={published ? PublishedStatusTypes.published : PublishedStatusTypes.draft} />
      </Table.Cell>
      <Table.Cell width={columnOptions[4].width}>{dateTimeUtils.formatDate(dateModified)}</Table.Cell>
      <Table.Cell className="align-right" width={columnOptions[5].width}>
        <RestrictedByTooltip hasPermission={hasPermission}>
          <ContentItemMenu
            circle
            outlinedEllipsis
            isChecked={isChecked}
            item={{
              isPurchased: !isEditable,
              canBeDeleted,
            }}
            readonly={readonly}
            entityType={entityType}
            dependencies={dependenciesMap}
            purchasedTooltipContent={Strings.tooltipMessages.purchased.oneDeleteDisabled("flow")}
          >
            {visibility && (
              <ToggleVisibilityButton onClick={buttonHandlers.handleToggleVisibility([id], hidden)} hidden={hidden} />
            )}
            {isEditable && (
              <EditLinkButton onClick={buttonHandlers.handleEditClick(id, published)} isDisabled={!isEditable} />
            )}
            <DuplicateLinkButton onClick={buttonHandlers.handleDuplicateClick([id])} isDisabled={isDuplicateDisabled} />
            {isEditable && (
              <DeleteLinkButton
                isDisabled={(!deleteContentEnabled && !canBeDeleted) || !isEditable}
                after={
                  isEditable &&
                  !deleteContentEnabled &&
                  hasDeletionDependencies && (
                    <DeletionRestrictedTooltip dependencies={dependenciesMap} entityType={entityType} />
                  )
                }
                onClick={buttonHandlers.handleDeleteClick([id], packsCount)}
              />
            )}
          </ContentItemMenu>
        </RestrictedByTooltip>
      </Table.Cell>
    </>
  );
}

FlowRow.defaultProps = {
  isEditable: false,
};

FlowRow.propTypes = {
  flow: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    objective: PropTypes.string,
    objectiveId: PropTypes.number,
    ownerId: PropTypes.number,
    publisher: PropTypes.string,
    published: PropTypes.bool,
    publisherId: PropTypes.number,
    usersCount: PropTypes.number,
    dateCreated: PropTypes.string,
    dateModified: PropTypes.string,
    isEditable: PropTypes.bool,
    readonly: PropTypes.bool,
    canBeDeleted: PropTypes.bool,
    dependencies: PropTypes.object,
    isChecked: PropTypes.bool,
  }),
  buttonHandlers: PropTypes.shape({
    handleEditClick: PropTypes.func,
    handleDuplicateClick: PropTypes.func,
    handleDeleteClick: PropTypes.func,
  }),
  hasPermission: PropTypes.bool,
  isDuplicateDisabled: PropTypes.bool,
};

export default FlowRow;
