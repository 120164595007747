import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Transition } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";

import "./alert.scss";

import { CloseButton } from "../../buttons/closeButton";

import alertPropTypes from "./alertPropTypes";

function BaseAlert(props) {
  const {
    id,
    typeName,
    children,
    textOnly,
    action,
    actionLabel,
    visible,
    dismissInSeconds,
    isPersistant,
    onDismissCompleted,
    animateOnMount,
    animationDuration,
  } = props;

  const handleHide = () => {
    onDismissCompleted && onDismissCompleted(id);
  };

  const handleClose = () => {
    onDismissCompleted && onDismissCompleted(id);
  };

  return (
    <Transition transitionOnMount={animateOnMount} visible={visible} onHide={handleHide} duration={animationDuration}>
      <div className="transition-wrapper">
        <div className={cn("alert", typeName, { "text-only": textOnly })}>
          <div className="content">{children}</div>
          {action && actionLabel && (
            <Button basic inverted className="call-to-action" onClick={action}>
              {actionLabel}
            </Button>
          )}
          {!dismissInSeconds && !isPersistant && <CloseButton inverted onClick={handleClose} />}
        </div>
      </div>
    </Transition>
  );
}

BaseAlert.defaultProps = {
  textOnly: false,
  visible: true,
  animateOnMount: true,
  isPersistant: false,
  animationDuration: 1000,
};

BaseAlert.propTypes = {
  ...alertPropTypes,
  typeName: PropTypes.string,
  children: PropTypes.object,
};

export default BaseAlert;
