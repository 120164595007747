import { Component } from "react";
import uniqBy from "lodash/uniqBy";
import { ValidatedForm } from "../../../../../components";
import ValidatedFormProps from "../../../../../components/forms/validatedFormProps";
import FormSectionHeader from "../../../../../components/forms/FormSectionHeader";
import { CountryModelItem } from "../../../types";
import { Label } from "../../EditUser/EditUserInfo/EditUserInfo";

export interface UserInfoEditFormProps {
  accountId: number;
  isLoading: boolean;
  departments: Label[];
  jobTitles: Label[];
  countries: CountryModelItem[];
  filterOptionActions: {
    getFilterOptions: () => void;
  };
  disabled: boolean;
}

interface UserInfoEditFormState {
  [key: string]: Label | null;
}

interface Data {
  [key: string]: any;
}

type StateProps = "department" | "jobTitle";

export default class UserInfoEditForm extends Component<
  UserInfoEditFormProps & ValidatedFormProps,
  UserInfoEditFormState
> {
  state = {
    department: null,
    jobTitle: null,
  };

  componentDidMount() {
    const { filterOptionActions } = this.props;
    filterOptionActions.getFilterOptions();
  }

  getCountries = () => {
    return this.props.countries.map((x) => {
      return {
        key: x.id,
        value: x.name,
        text: x.name,
      };
    });
  };

  getDepartmentsOptions = () => {
    const { departments, values } = this.props;
    const { department } = this.state;

    let options = [...departments, this.createDropdownOption(values.department)];

    department && options.push(department!); //NOSONAR

    return uniqBy(options, "value");
  };

  getJobTitlesOptions = () => {
    const { jobTitles, values } = this.props;
    const { jobTitle } = this.state;

    let options = [...jobTitles, this.createDropdownOption(values.jobTitle)];

    jobTitle && options.push(jobTitle!); //NOSONAR

    return uniqBy(options, "value");
  };

  createDropdownOption = (value: string) => {
    return { text: value, value: value };
  };

  handleItemAdded = (data: Data, propertyName: StateProps) => {
    this.setState({ [propertyName]: this.createDropdownOption(data.value) });
  };

  renderFormBody = () => {
    const {
      values,
      errors,
      touched,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
      disabled,
    } = this.props;

    const validatedFieldProps = {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };

    return (
      <>
        <FormSectionHeader title="Personal Information" />
        <ValidatedForm.InputField
          label="First Name"
          value={values.firstName}
          propertyName="firstName"
          placeholder="Enter First Name"
          markAsRequired
          {...validatedFieldProps}
          disabled={disabled}
        />
        <ValidatedForm.InputField
          label="Last Name"
          value={values.lastName}
          propertyName="lastName"
          placeholder="Enter Last Name"
          markAsRequired
          {...validatedFieldProps}
          disabled={disabled}
        />
        <ValidatedForm.InputField
          label="Email"
          value={values.email}
          propertyName="email"
          placeholder="Enter Email"
          markAsRequired
          {...validatedFieldProps}
          disabled={disabled}
        />
        <FormSectionHeader title="" />
        <ValidatedForm.DropdownField
          label="Department"
          value={values.department}
          placeholder="Search/select a department"
          propertyName="department"
          options={this.getDepartmentsOptions()}
          onItemAdded={(_: any, data: Data) => this.handleItemAdded(data, "department")}
          defaultSearchQuery={values.department}
          disabled={disabled}
          search
          clearable
          allowAdditions
          {...validatedFieldProps}
        />
        <ValidatedForm.DropdownField
          label="Job Title"
          value={values.jobTitle}
          placeholder="Search/select a job title"
          propertyName="jobTitle"
          options={this.getJobTitlesOptions()}
          onItemAdded={(_: any, data: Data) => this.handleItemAdded(data, "jobTitle")}
          defaultSearchQuery={values.jobTitle}
          disabled={disabled}
          search
          clearable
          allowAdditions
          {...validatedFieldProps}
        />
        <ValidatedForm.DropdownField
          placeholder="Search/select a country"
          label="Country"
          search
          minCharacters={3}
          value={values.country}
          propertyName="country"
          options={this.getCountries()}
          disabled={disabled}
          clearable
          {...validatedFieldProps}
        />
        <ValidatedForm.CheckboxField
          toggle
          propertyName="isBypassSso"
          label="Bypass SSO"
          value={values.isBypassSso}
          disabled={disabled}
          {...validatedFieldProps}
        />
      </>
    );
  };

  render() {
    const { handleSubmit, isLoading, dirty } = this.props;

    return (
      <section className="user-info-form edit-form scrollable-content">
        <ValidatedForm
          handleSubmit={handleSubmit}
          dirty={dirty}
          unsavedChangesPrompt={{
            title: "Exit Without Saving?",
            message: "Are you sure you want to exit without saving this group? All information entered will be lost.",
          }}
          isLoading={isLoading}
        >
          {this.renderFormBody()}
        </ValidatedForm>
      </section>
    );
  }
}
