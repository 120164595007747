import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../Application/actions/entityState/entityStateActionTypes";

export const createAssessmentBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN,
);

export const createAssessmentSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS,
);

export const createAssessmentFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE,
);

export const resetAssessmentEntityState = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const updateAssessmentBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);

export const updateAssessmentFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);

export const updateAssessmentCommandSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const assessmentUpdated = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);

export const publishAssessmentBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);
export const publishAssessmentSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);
export const publishAssessmentFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);

export const fetchLockedAssessmentBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);

export const fetchLockedAssessmentSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);

export const fetchLockedAssessmentFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);

export const fetchDiscardAssessmentBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);

export const fetchDiscardAssessmentSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const fetchDiscardAssessmentFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);

export const setAssessmentEntityId = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  entityStateActionTypes.SET_ENTITY_ID,
);
