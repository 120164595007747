import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { IWizardStep } from "interfaces";
import { connect, ConnectedProps } from "react-redux";
import { GroupTemplatePacks } from "../Details/Packs/GroupTemplatePacks";
import { fetchAvailablePacks } from "../state/thunks/TemplateAvailablePacksThunk";
import { AvailablePacksRequest } from "../types/models";
import { reset, resetGrid, setPacksToAdd } from "../state/TemplateAvailablePacksSlice";
import { useEffect } from "react";
import { PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { noop } from "lodash";

interface Props extends IWizardStep, PropsFromRedux {
  packMap: PackMap;
}

export const CreatePacks = (props: Props) => {
  const {
    groupId,
    items,
    itemsCount,
    isLoading,
    isAllDataLoaded,
    selectedIds,
    setSelectedIds,
    getData,
    reset,
    resetGridItems,
    acceptHandlers,
    packMap,
  } = props;
  useEffect(() => {
    acceptHandlers?.({
      onClose: reset,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPacks = (skip: number, searchTerm: string, orderBy: string) => {
    getData({ templateGroupId: groupId, skip, searchTerm, orderBy });
  };

  return (
    <GroupTemplatePacks
      templateId={groupId}
      items={items}
      itemsCount={itemsCount}
      isLoading={isLoading}
      isAllDataLoaded={isAllDataLoaded}
      getGridData={loadPacks}
      getListData={noop}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      resetGrid={resetGridItems}
      packMap={packMap}
      isCreate={true}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    groupId: state.people.groupTemplate.base.groupId,
    items: state.people.groupTemplate.availablePacks.items,
    itemsCount: state.people.groupTemplate.availablePacks.itemsCount,
    isLoading: state.people.groupTemplate.availablePacks.isLoading,
    isAllDataLoaded: state.people.groupTemplate.availablePacks.areAllLoaded,
    selectedIds: state.people.groupTemplate.availablePacks.packsToAdd,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setSelectedIds: (ids: number[]) => dispatch(setPacksToAdd(ids)),
    getData: (request: AvailablePacksRequest) => dispatch(fetchAvailablePacks(request)),
    reset: () => dispatch(reset()),
    resetGridItems: () => dispatch(resetGrid()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreatePacks);
