import React, { useMemo } from "react";
import DefaultTriggerForm from "./DefaultTriggerForm/DefaultTriggerForm";
import { flowInformationSelector, flowValidatorSelector } from "../../../../state/selectors";
import { RootState } from "../../../../../../Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { DefaultTriggerBase } from "./DefaultTriggerForm/types";
import { getTooltip } from "./DefaultTriggerForm/helpers";
import ExpandableHeader from "../ExpandableHeader/ExpandableHeader";

import styles from "./defaultTrigger.module.scss";

export type DefaultTriggerProps = PropsFromRedux & DefaultTriggerBase;

export const DefaultTrigger = (props: DefaultTriggerProps) => {
  const { isReadOnly, isFlowValid, triggers, onIsDefaultChange, hasOrigin, isDraft = true } = props;
  const defaultTriggers = useMemo(() => triggers.filter((trigger) => !trigger.isAction), [triggers]);

  if (defaultTriggers.length <= 1) {
    return null;
  }

  return (
    <div className={styles.root}>
      <ExpandableHeader tooltip={getTooltip(hasOrigin || !isDraft)} title="Deletion Behavior">
        <DefaultTriggerForm
          isReadOnly={isReadOnly || !isFlowValid || !hasOrigin}
          triggers={defaultTriggers}
          onIsDefaultChange={onIsDefaultChange}
        />
      </ExpandableHeader>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isFlowValid: flowValidatorSelector(state).isFlowValid,
  hasOrigin: flowInformationSelector(state).info.hasOrigin,
  isDraft: flowInformationSelector(state).info.isDraft,
});

/* istanbul ignore next */
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DefaultTrigger);
