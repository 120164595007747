import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { RootState } from "../../../../Application/globaltypes/redux";
import { createSearchSlice, SearchState } from "../../../../Application/slices/createSearchSlice";

export const initialState: SearchState = {
  term: undefined,
};

const packsSearchSlice = createSearchSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Licensing, entity: ReducerEntityPrefixTypes.Packs, name: "search" },
  initialState: initialState,
  reducers: {},
});

export const { setTerm } = packsSearchSlice.actions;

export const packsOverviewSearchSelector = (state: RootState) => state.packs.overviewSearch;

export default packsSearchSlice.reducer;
