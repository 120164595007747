import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import StatusCode from "../../enums/httpStatusCodes";

export interface FailureHttpResponse {
  isSuccess: false;
  message: string;
  code: StatusCode;
}

export interface SuccessHttpResponse<T = any> {
  data: T;
  isSuccess: true;
  headers: AxiosResponse["headers"];
  code: StatusCode;
}

export type HttpResponse<T = any> = FailureHttpResponse | SuccessHttpResponse<T>;

interface IHttp {
  get<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<HttpResponse<T>>;
  post<T = unknown>(url: string, config?: AxiosRequestConfig): Promise<HttpResponse<T>>;
}

const isAxiosError = <T = any>(error: any): error is AxiosError<T> => {
  return error.response !== undefined;
};

const baseRequest = <T = any>(call: (url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>) => {
  return async (url: string, config?: AxiosRequestConfig): Promise<HttpResponse<T>> => {
    try {
      const result = await call(url, config);

      return {
        isSuccess: true,
        data: result.data,
        headers: result.headers,
        code: result.status,
      };
    } catch (error) {
      if (!isAxiosError(error)) {
        throw error;
      }

      return {
        isSuccess: false,
        code: error.response!.status,
        message: error.message,
      };
    }
  };
};

export const http: IHttp = {
  get: baseRequest(axios.get),
  post: baseRequest(axios.post),
};

export default http;
