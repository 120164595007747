import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { keyBy } from "lodash";

const getItems = (state: RootState) => messagesStateSelector(state).overview.messages.items;

export const messagesStateSelector = (state: RootState) => state.library.messages;

export const normalizedMessagesSelector = createSelector([getItems], (items) => {
  return keyBy(items, (item) => item.id);
});
