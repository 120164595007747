import { Icon } from "semantic-ui-react";
import { ValidatedForm } from "../../../../../../../components";
import { Tooltip } from "../../../../../../../components/common/tooltip";
import { nameof } from "../../../../../../../utils/nameof";
import { CommonComponentProps } from "../../../../../Common/props";
import { ISurveyQuestion } from "../types";

export interface RequiredToggleProps extends CommonComponentProps {
  question: ISurveyQuestion<any>;
}

export const RequiredToggle = (props: RequiredToggleProps) => {
  const { validatedFieldProps, question, propertyNamePrefix, onChanged, disabled } = props;

  const disableToggle = !!question.isBranching;

  return (
    <div className="checkbox-field">
      <ValidatedForm.CheckboxField
        {...validatedFieldProps}
        toggle
        label={"Required question"}
        value={question.isRequired}
        propertyName={`${propertyNamePrefix}${nameof("isRequired")}`}
        disabled={disabled || disableToggle}
        handleChange={onChanged}
      />
      <Tooltip
        target={<Icon className="info circle" />}
        content={"Be sure to provide the answers."}
        position="top center"
        styles={{ width: "20rem" }}
        hideOnScroll
        showAlways
      />
    </div>
  );
};
