import React, { useCallback, useMemo } from "react";
import CreateAccount from "./CreateAccount";
import { Preconfiguration } from "../Configuration/Preconfiguration";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import ConfigureExisting from "./ConfigureExisting";
import { Observable } from "utils";
import { TriggerableConfirmationModal } from "components";

interface Account {
  id: number;
  name: string;
  logoUri: Nullable<string>;
  isNew?: boolean;
}

export const AccountPrecreation = () => {
  const flag = useFeatureFlag(FeatureFlags.AccountDuplication);
  const [account, setAccount] = React.useState<Account>();
  const onRemovedObserver = useMemo(() => new Observable<(onConfirm: () => void) => void>(), []);

  const reset = useCallback(() => {
    setAccount(undefined);
  }, []);

  const handleRemove = useCallback(() => {
    onRemovedObserver.notify(() => {
      reset();
    });
  }, [onRemovedObserver, reset]);

  if (!flag) {
    return <CreateAccount />;
  }

  const main = (() => {
    if (account !== undefined) {
      if (account.isNew) {
        return <CreateAccount prefilled={account} onRemoveName={handleRemove} />;
      } else {
        return <ConfigureExisting account={account} onRemoveName={handleRemove} />;
      }
    }

    return (
      <Preconfiguration
        onConfigured={(acc) => {
          setAccount(acc);
        }}
      />
    );
  })();

  return (
    <>
      {main}
      <TriggerableConfirmationModal
        content="This will erase all entered information and restart the account creation process. Do you want to proceed?"
        onTriggerModalObserver={onRemovedObserver}
        title="Start Over?"
        confirmButtonLabel="Start Over"
      />
    </>
  );
};
