import FlowDesignerSectionHeaderCard from "../../../../../../components/cards/FlowDesignerSectionHeaderCard/FlowDesignerSectionHeaderCard";
import { ExtrasOverview, ExtrasTypes } from "../../Extras/types";
import DraggableSectionHeaderCard from "../dragndropCards/DraggableSectionHeaderCard";
import FlowEndCard from "../../../../../../components/cards/FlowEndCard/FlowEndCard";
import DraggableFlowEndCard from "../dragndropCards/DraggableFlowEndCard";

export function ExtrasCard(item: ExtrasOverview) {
  if (item.cardType === ExtrasTypes.SectionHeader) return <FlowDesignerSectionHeaderCard {...item} />;
  if (item.cardType === ExtrasTypes.FlowEnd) return <FlowEndCard {...item} />;
  // add other cards here in the future
  return null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ExtrasDraggableCard(item: ExtrasOverview) {
  if (item.cardType === ExtrasTypes.SectionHeader) return <DraggableSectionHeaderCard {...item} />;
  if (item.cardType === ExtrasTypes.FlowEnd) return <DraggableFlowEndCard {...item} />;

  // add other cards here in the future
  return null;
}
