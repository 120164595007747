import dateTimeUtils from "../../utils/dateTimeUtils";

import "./timestampLabel.scss";
import { timestampLabelPropTypes } from "./timestampLabelPropTypes";

function TimestampLabel(props) {
  const { label, dateTime, format } = props;

  return dateTime ? (
    <div className="timestamp-label">
      {label}: <span className="timestamp-text">{dateTimeUtils.dateToLastSavedTime(dateTime, format)}</span>
    </div>
  ) : null;
}

TimestampLabel.defaultProps = {
  label: "Last Saved",
  format: "h:mm A",
};

TimestampLabel.propTypes = timestampLabelPropTypes;

export default TimestampLabel;
