import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import cn from "classnames";

import "./arrowButtons.scss";

function RightArrowButton(props) {
  const { onClick, size } = props;
  return (
    <div className={cn("arrow-button right", size)} onClick={onClick}>
      <Icon className="fa-chevron-right" />
    </div>
  );
}

RightArrowButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
};

export default RightArrowButton;
