import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../Application/globaltypes/redux";
import { BaseIntegrationConfigs, Integration, IntegrationTypes } from "../../types/types";
import { IntegrationDetailsState } from "../slices/integrationDetailsSlice";

export const integrationBaseSelector = (state: RootState) => state.accounts.integrations;

export const integrationDetailsSelector = <T extends BaseIntegrationConfigs>(state: RootState) =>
  integrationBaseSelector(state).edit.details as IntegrationDetailsState<T>;

export const integrationsOverviewSelector = (state: RootState) =>
  integrationBaseSelector(state).overview.integrationsOverviewReducer;

const getItems = (state: RootState) => integrationsOverviewSelector(state).items;

export const isConnectedSelector = createSelector(getItems, (connectedConnectors: Integration[]) => {
  return (type: IntegrationTypes) => {
    return connectedConnectors.some((c) => c.type.toLowerCase() === type.toLowerCase());
  };
});
