import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { buildFetchAction } from "../../../../Application/actions/actionsBuilder";

export const FETCH_USERS = buildFetchAction(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.Users_Overview);

export const FETCH_USER_GROUPS_ASSIGNMENTS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.UserGroupsAssignments,
);

export const FETCH_USER_ROLES_ASSIGNMENTS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.UserRolesAssignments,
);

export const PEOPLE_USERS_OVERVIEW_USER_CREATED = "PEOPLE_USERS_OVERVIEW_USER_CREATED";

export const UPLOAD_USERS_FILE_BEGIN = `${ReducerNamespaceTypes.People}__${ReducerEntityPrefixTypes.Users_Overview}_UPLOAD_USERS_FILE_BEGIN`;
export const UPLOAD_USERS_FILE_SUCCESS = `${ReducerNamespaceTypes.People}__${ReducerEntityPrefixTypes.Users_Overview}_UPLOAD_USERS_FILE_SUCCESS`;
export const UPLOAD_USERS_FILE_FAIL = `${ReducerNamespaceTypes.People}__${ReducerEntityPrefixTypes.Users_Overview}_UPLOAD_USERS_FILE_FAIL`;
export const UPDATE_USERS_FILE_UPLOAD_PROGRESS = `${ReducerNamespaceTypes.People}__${ReducerEntityPrefixTypes.Users_Overview}_UPDATE_USERS_FILE_UPLOAD_PROGRESS`;
export const CANCEL_USERS_FILE_UPLOAD = `${ReducerNamespaceTypes.People}__${ReducerEntityPrefixTypes.Users_Overview}_CANCEL_USERS_FILE_UPLOAD`;
export const RESET_USERS_FILE_UPLOAD = `${ReducerNamespaceTypes.People}__${ReducerEntityPrefixTypes.Users_Overview}_RESET_USERS_FILE_UPLOAD`;

export const FETCH_UPLOADED_FILE_COLUMNS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.UploadedFileColumns,
);

export const FETCH_USERS_IMPORT_PREVIEW_DATA = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.UsersImportPreviewData,
);
export const RESET_USERS_IMPORT_PREVIEW_DATA = `${ReducerNamespaceTypes.People}___RESET_USERS_IMPORT_PREVIEW_DATA`;
