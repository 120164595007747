import React, { Component } from "react";
import "./VerifiedStatus.scss";
import { ReactComponent as CloseIcon } from "./../close-circle.svg";
import { ReactComponent as CheckIcon } from "./../check-circle-28.svg";

export interface VerifiedStatusProps {
  isVerified: boolean;
}

export default class VerifiedStatus extends Component<VerifiedStatusProps> {
  render() {
    const { isVerified } = this.props;
    return <div className="verified-status">{isVerified ? <CheckIcon /> : <CloseIcon />}</div>;
  }
}
