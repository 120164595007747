import { useCallback, useMemo, useState } from "react";
import { batch, connect, ConnectedProps } from "react-redux";

import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { IObservable } from "../../../../../interfaces/IObservable";
import { getAssignedClosedCaptions } from "../../state/selectors/detailsSelector";
import { deleteClosedCaptions } from "../../state/thunks/videoClosedCaptionsThunk";
import { resetSelectedItems } from "../../state/slices/videoClosedCaptionsSlice";
import RemoveClosedCaptionLinkButton from "../../VideoDetails/ClosedCaptions/RemoveClosedCaptionLinkButton/RemoveClosedCaptionLinkButton";
import { AddPacksButton } from "features/Licensing/Packs/AssociatedPacks/AddPacksButton";
import Restricted from "features/Application/Restricted";
import { ItemsTypes, RolePermissions } from "enums";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { noop } from "lodash";

interface DetailsHeaderContentProps {
  onTriggerClosedCaptionsRemovalObserver: IObservable<
    (onRemoveConfirm: () => void, selectedItemsCount: number) => void
  >;
  videoId: number;
  isClosedCaptionsTab: boolean;
  canAddPacks: boolean;
  showAddPacksButton: boolean;
  permissionPredicate?: (userPermissions: RolePermissions[], permissionsList?: RolePermissions[]) => boolean;
}

export type DetailsHeaderContentPropsBase = DetailsHeaderContentProps & PropsFromRedux;

export const DetailsHeaderContent = (props: DetailsHeaderContentPropsBase) => {
  const { onTriggerClosedCaptionsRemovalObserver, onBatchRemoveClosedCaptions, videoId, selectedClosedCaptions, canAddPacks, showAddPacksButton, permissionPredicate } =
    props;
  const [packsModalShown, setPacksModalShown] = useState(false);
  const renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton disabled={!canAddPacks} hasPermission={hasAnyPermission} onClick={() => setPacksModalShown(true)} />
  );

  const isDefaultClosedCaptionSelected = useMemo(() => {
    return props.selectedClosedCaptions.some((caption) => caption.isDefault);
  }, [props.selectedClosedCaptions]);

  const onRemoveClosedCaptions = useCallback(() => {
    onTriggerClosedCaptionsRemovalObserver.notify(() => {
      onBatchRemoveClosedCaptions(
        videoId,
        selectedClosedCaptions.map((item) => item.id),
      );
    }, selectedClosedCaptions.length);
  }, [onTriggerClosedCaptionsRemovalObserver, onBatchRemoveClosedCaptions, videoId, selectedClosedCaptions]);

  return (
    <>
      {props.isClosedCaptionsTab && props.isClosedCaptionsSelected && (
        <RemoveClosedCaptionLinkButton
          multiple
          showTooltip={isDefaultClosedCaptionSelected}
          isDisabled={isDefaultClosedCaptionSelected}
          onClick={onRemoveClosedCaptions}
        />
      )}
      {showAddPacksButton && (
        <Restricted
          permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
          renderContent={(hasAnyPermission) => renderAddPackButton(hasAnyPermission)}
          permissionPredicate={permissionPredicate}
        />
      )}
      <AddAssetsToPackModal
        showModal={packsModalShown}
        onClose={/* istanbul ignore next */ () => setPacksModalShown(false)}
        selectedItemIds={[videoId]}
        contentType={ItemsTypes.Video}
        onComplete={noop}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isClosedCaptionsSelected: !!getAssignedClosedCaptions(state).selectedItems.length,
  selectedClosedCaptions: getAssignedClosedCaptions(state).selectedItems,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onBatchRemoveClosedCaptions: (videoId: number, ids: number[]) =>
    batch(() => {
      dispatch(deleteClosedCaptions(videoId, ids));
      dispatch(resetSelectedItems());
    }),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DetailsHeaderContent);
