import { FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ValidatedForm } from "../../../../../../components/forms";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { ThreatDefenceLandingPageContent } from "../../types/state";
import { ContentLandingPageProps } from "../types";
import * as landingPageEntityStateActions from "../../state/actions/threatDefenceLandingPageEntityStateActions";
import * as landingPageDetailsActions from "../../state/actions/threatDefenceLandingPageDetailsActions";
import validationSchemas from "../../../../../../utils/validationSchemas";
import schemasUtils from "../../../../../../utils/validationSchemasUtils";
import { AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";

export type ContentAllProps = ContentLandingPageProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceLandingPageContent> &
  AutosaveProps;

export class Content extends Component<ContentAllProps> {
  componentDidMount() {
    this.props.acceptHandlers?.({
      onPrevious: this.onPrevious,
      onFinish: this.onFinish,
    });

    //Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  onPrevious = () => {
    this.props.isValid && this.saveLandingPage();
  };

  onPublishLandingPage = () => {
    this.props.entityStateActions.publishDraftThreatDefenceLandingPageEntity(this.props.id);
  };

  onFinish = () => {
    this.onPublishLandingPage();
  };

  saveLandingPage = () => {
    this.props.detailsActions.saveThreatDefenceLandingPageContent(this.props.values);
  };

  static getLandingPageContent = (values: ThreatDefenceLandingPageContent): ThreatDefenceLandingPageContent => {
    return {
      id: values.id,
      html: values.html?.trim(),
      contentUrlWithSas: values.contentUrlWithSas,
    };
  };

  render() {
    const { dirty, isLoading, isLoaded } = this.props;
    return (
      <ValidatedForm
        dirty={dirty}
        isLoading={isLoading || !isLoaded}
        formWidthComputer={6}
        disablePreventTransitionPrompt={true}
        isInitialValid={false}
      >
        {this.renderFields}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, disabled, onIsValidChange, onBlur } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    return (
      <div className="landing-page-form-container">
        <ValidatedForm.TextAreaField
          label="Body"
          value={values.html}
          propertyName="html"
          markAsRequired
          placeholder=""
          disabled={disabled}
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
      this.props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ContentLandingPageProps) => {
  const detailsReducer = state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails;
  const id =
    ownProps.entityId || state.library.threatDefenceLandingPages.threatDefenceLandingPageEntityStateReducer.entityId;
  return {
    id,
    content: {
      id,
      html: detailsReducer.html,
      contentUrlWithSas: detailsReducer.contentUrlWithSas,
    },
    isLoaded: detailsReducer.isLandingPageContentLoaded,
    isLoading: detailsReducer.isLoading,
    isCreating: state.library.threatDefenceLandingPages.threatDefenceLandingPageEntityStateReducer.changingEntityState,
    isDraft: detailsReducer.landingPage.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(landingPageEntityStateActions, dispatch),
  detailsActions: bindActionCreators(landingPageDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<
  ContentAllProps,
  ThreatDefenceLandingPageContent,
  ThreatDefenceLandingPageContent
>({
  getInitValues: (props) => props.content,
  stateProvider: Content.getLandingPageContent,
  entityUpdater: (props) => props.entityStateActions.updateThreatDefenceLandingPageContent,
  isPeriodicUpdateEnabled: false,
})(Content);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.landingPageContent,
  enableReinitialize: true,
  isInitialValid: (props: ContentLandingPageProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.landingPageContent, props.content),
  mapPropsToValues: (props: ContentLandingPageProps & PropsFromRedux) => props.content,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
