import { SortingDirection } from "enums";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";

import { GenericFiltersMap } from "../../../../../../utils/filterUtils";
import { createFiltersSlice, FiltersState } from "../../../../../Application/slices/createFiltersSlice";
import { MessageFiltersEnum } from "../../types/models";

export const initialState: FiltersState<MessageFiltersEnum> = {
  filterOptions: {} as GenericFiltersMap<MessageFiltersEnum>,
  appliedFilter: {} as GenericFiltersMap<MessageFiltersEnum>,
  isLoading: false,
  shouldSort: true,
  sortingColumnName: "added",
  sortingDirection: SortingDirection.Descending,
};

const messageFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Messages,
    name: "overviewFilters",
  },
  initialState: initialState,
  reducers: {},
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  resetSearch,
  setSearch,
  setPagination,
  resetPagination,
  setSortingColumnName,
  resetSortingColumnName,
  setSortingDirection,
  resetSortingDirection,
  setShouldSort,
  resetShouldSort,
} = messageFiltersSlice.actions;

export default messageFiltersSlice.reducer;
