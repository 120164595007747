import { AppDispatch } from "../../../../Application/globaltypes/redux";
import _ from "lodash";

import objUtils from "../../../../../utils/objectUtils";
import PromiseStatuses from "../../../../../enums/promiseStatuses";
import DataService from "../../../../Application/services/dataServices/typedDataService";

import { FiltersMap, GenericFiltersMap, mapFilterOption } from "../../../../../utils/filterUtils";
import * as contentAssignments from "../../../../People/ContentAssignments/state/slices/contentAssignmentsFiltersSlice";
import { batch } from "react-redux";
import { TagsEnumCamelPlural } from "../../../../../interfaces/TagsEnum";
import { AccountBase, PackName } from "../../../../../interfaces";
import { AssessmentFiltersEnum } from "../../types/state";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  setError as setOverviewError,
  setFilterOptions as setOverviewFilterOptions,
  setIsLoading as setOverviewIsLoading,
} from "../slices/assessmentFiltersSlice";

export interface GetFilterOptionsProps {
  showPurchased?: boolean;
  filters?: AssessmentFiltersEnum[];
}

interface BaseFilterOptionsProps {
  begin: () => void;
  success: (filterOptions: FiltersMap) => void;
  failure: (error: Error) => void;
}

const defaultFiltersOptions = [
  AssessmentFiltersEnum.Publishers,
  AssessmentFiltersEnum.Tags,
  AssessmentFiltersEnum.Packs,
];

export const getFilterOptions = async (
  props: BaseFilterOptionsProps & GetFilterOptionsProps,
  includePublishers: boolean = true,
) => {
  const { showPurchased, begin, success, failure } = props;
  const selectedFilters = !props.filters?.length ? defaultFiltersOptions : props.filters;

  let filterOptions: FiltersMap = {};

  const tagToFilterMap: { [key in TagsEnumCamelPlural]?: AssessmentFiltersEnum } = {
    [TagsEnumCamelPlural.Label]: AssessmentFiltersEnum.Labels,
    [TagsEnumCamelPlural.SoftwareApplication]: AssessmentFiltersEnum.SoftwareApplications,
  };

  const readPublishersData = async () => {
    const publishers = await DataService.assessments.getPublishersFilterOptions();
    filterOptions = mapFilterOption(
      filterOptions,
      publishers.data,
      AssessmentFiltersEnum.Publishers,
      (item: PackName) => ({
        text: item.name,
        value: item.id,
      }),
    );
    return filterOptions;
  };

  const readPacksData = async () => {
    const packs = await DataService.packs.getPacksFilterOptions("assessment", !!showPurchased);
    filterOptions = mapFilterOption(filterOptions, packs.data, AssessmentFiltersEnum.Packs, (item: AccountBase) => ({
      text: item.name,
      value: item.id,
    }));
    return filterOptions;
  };

  const readTagsData = async () => {
    const tags = await DataService.assessments.getTagsFilterOptions(!!showPurchased);
    for (let [key, value] of objUtils.typedEntries<TagsEnumCamelPlural, string[]>(tags.data)) {
      if (tagToFilterMap[key]) {
        filterOptions = mapFilterOption(filterOptions, value, tagToFilterMap[key], (item: string) => ({
          text: item,
          value: item,
        }));
      }
    }
    return filterOptions;
  };

  const filterHandlers: { [key in AssessmentFiltersEnum]?: () => Promise<FiltersMap> } = {
    [AssessmentFiltersEnum.Packs]: readPacksData,
    [AssessmentFiltersEnum.Tags]: readTagsData,
    ...(includePublishers && { [AssessmentFiltersEnum.Publishers]: readPublishersData }),
  };

  begin();

  const filtersHandlersPromises = Object.values(_.pick(filterHandlers, selectedFilters)).map(
    (handler: () => Promise<FiltersMap>) => handler(),
  );

  const result = await Promise.allSettled(filtersHandlersPromises);

  batch(() => {
    let someFulfilled = false;
    result.forEach((item) => {
      if (item.status === PromiseStatuses.rejected) {
        failure(item.reason);
      }
      if (item.status === PromiseStatuses.fulfilled) {
        someFulfilled = true;
      }
    });

    if (someFulfilled) {
      success(filterOptions);
    }
  });
};

const getContentAssignmentFilterOptions = (
  setIsLoading: ActionCreatorWithPayload<boolean>,
  setFilterOptions: ActionCreatorWithPayload<GenericFiltersMap<string>>,
  setError: ActionCreatorWithPayload<Error>,
) => {
  return (dispatch: AppDispatch) =>
    getFilterOptions({
      showPurchased: true,
      begin: () => {
        dispatch(setIsLoading(true));
      },
      success: (filterOptions: FiltersMap) => {
        batch(() => {
          dispatch(setFilterOptions(filterOptions));
          dispatch(setIsLoading(false));
        });
      },
      failure: (error: Error) => {
        batch(() => {
          dispatch(setError(error));
          dispatch(setIsLoading(false));
        });
      },
    });
};

export const getContentAssignmentModalFilterOptions = () =>
  getContentAssignmentFilterOptions(
    contentAssignments.setIsLoading,
    contentAssignments.setFilterOptions,
    contentAssignments.setError,
  );

export const getOverviewFilterOptions = () =>
  getContentAssignmentFilterOptions(setOverviewIsLoading, setOverviewFilterOptions, setOverviewError);
