import * as accountsEventTypes from "../../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { useEffect } from "react";
import EditIntegrationBase from "../EditIntegrationBase/EditIntegrationBase";
import { IntegrationTypes, WebexIntegrationConfigs } from "../../types";
import { getWebexConnector } from "../../connectors/factory";
import { integrationBaseSelector } from "../../state/selectors";
import { bindAction } from "../../../../../interfaces";
import { fetchIntegrationConfigsAction } from "../../state/thunks/integrationDetailsThunk";
import { reset } from "../../state/slices/integrationDetailsSlice";
import { useParams } from "react-router-dom";
import { IntegrationDtoMap } from "../../Shared";

const webexEvents = [accountsEventTypes.UpdateWebexSuccess, accountsEventTypes.UpdateWebexFailure];

const EditWebex: React.FC<PropsFromRedux> = (props) => {
  const { integration, isLoading, webex, fetchConfigs, resetConfigs } = props;
  const { moboId } = useParams();

  const ownAccount = Number.isNaN(moboId) || moboId === undefined;

  const integrationUrl = IntegrationDtoMap.webex.url(ownAccount, moboId);

  useEffect(() => {
    fetchConfigs(moboId);

    return () => {
      resetConfigs();
    };
  }, [resetConfigs, fetchConfigs, moboId]);

  return (
    <EditIntegrationBase
      moboId={ownAccount ? undefined : moboId}
      integration={integration as WebexIntegrationConfigs}
      isLoading={isLoading}
      rtnEvents={webexEvents}
      integrationUrl={integrationUrl}
      integrationType={IntegrationTypes.Webex}
      onSaveHandler={webex.updateWebexWithAuthentication}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const base = integrationBaseSelector(state);
  const webex = getWebexConnector();
  return {
    integration: base.edit.details.data,
    isLoading: base.edit.details.isLoading,
    webex,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchConfigs: bindAction((id) => fetchIntegrationConfigsAction(IntegrationTypes.Webex, id), dispatch),
    resetConfigs: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditWebex);
