import { Table } from "semantic-ui-react";
import { ColumnOptions } from "../../../../../../../interfaces";
import { GroupAvailableForLicensing } from "../../../types";

export const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 8,
    isSortable: true,
  },
  {
    name: "Members",
    width: 8,
    isSortable: true,
  },
];

export interface GroupListRowProps {
  group: GroupAvailableForLicensing;
}

export const GroupListRow = ({ group }: GroupListRowProps) => {
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>{group.name}</Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{group.membersCount}</Table.Cell>
    </>
  );
};

export default GroupListRow;
