import { batch } from "react-redux";
import AlertTypes from "../../../../../enums/alertTypes";
import {
  deleteTask,
  updateFailedTaskDistributedOperation,
  updateProgressTaskDistributedOperation,
  updateSucceededTaskDistributedOperation,
  updateTask,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";
import videoAssetTasks from "../../../../BackgroundTasks/videoAssetTasks";
import {
  draftVideoAssetPublished,
  draftVideoAssetReverted,
  draftVideoInfoUpdateCompleted,
  draftVideoInfoUpdated,
  getEntityLockSuccess,
} from "../../../../Library/Videos/state/actions/videoEntityStateActionCreators";
import {
  mediaEncodingCreatedAlert,
  mediaThumbnailCreatedAlert,
  notificationReceived,
  thumbnailGenerated,
  videoGenerated,
} from "../../../../Notifications/state/notificationsActions";
import { createDispatcher } from "../dispatcher";
import * as libraryEventTypes from "../events/library/libraryEvents";

const updateSuccessTask = (payload, dispatch) => {
  if (payload.canBePublished) {
    const id = payload.videoAssetId;
    const task = videoAssetTasks.getReadyToPublish(id, (taskId) => {
      dispatch(deleteTask(taskId));
    });
    return updateTask(id, task);
  }
  return null;
};

const updateFailedTask = (payload, dispatch) => {
  if (payload.type === AlertTypes.error) {
    const id = payload.videoAssetId;
    const task = videoAssetTasks.getFailed(id, null, (taskId) => {
      dispatch(deleteTask(taskId));
    });
    return updateTask(id, task);
  }
  return null;
};

export const videoAssetsMapping = (mapping, dispatch) => {
  const { dispatchPublishSuccess, dispatchTaskOperationSuccess, dispatchTaskOperationFailure, dispatchAlert } =
    createDispatcher(dispatch);

  const dispatcher = (payload, action) => {
    dispatch(action(payload));
  };

  const dispatchMany = (payload, actions) => {
    batch(() => {
      actions.forEach((action) => {
        const obj = action(payload, dispatch);
        if (obj) {
          dispatch(obj);
        }
      });
    });
  };

  mapping[libraryEventTypes.ManifestEditSuccess] = (payload) =>
    dispatchMany(payload, [videoGenerated, updateSuccessTask, draftVideoInfoUpdated]);
  mapping[libraryEventTypes.ThumbnailEditSuccess] = (payload) =>
    dispatchMany(payload, [thumbnailGenerated, updateSuccessTask]);

  mapping[libraryEventTypes.VideoAssetEditSuccess] = (payload) => dispatcher(payload, draftVideoInfoUpdated);
  mapping[libraryEventTypes.VideoAssetsUpdateCommandCompleted] = (payload) =>
    dispatcher(payload, draftVideoInfoUpdateCompleted);

  mapping[libraryEventTypes.VideoTagsEditSuccess] = (payload) => dispatcher(payload, draftVideoInfoUpdateCompleted);

  mapping[libraryEventTypes.VideoAssetsDiscardSuccess] = (payload) => {
    dispatcher(payload, draftVideoAssetReverted);
  };

  mapping[libraryEventTypes.VideoAssetPublishSuccess] = dispatchPublishSuccess(
    draftVideoAssetPublished,
    (data) => data?.message,
  );

  mapping[libraryEventTypes.DeleteVideoDistributedSuccess] = (payload) =>
    dispatch(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[libraryEventTypes.DeleteAllVideoSuccess] = (payload) =>
    dispatch(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.DeleteVideoDistributedFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[libraryEventTypes.DeleteAllVideoFailure] = (payload) =>
    dispatch(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[libraryEventTypes.ManifestEditFailure] = (payload) => {
    dispatchMany(payload, [mediaEncodingCreatedAlert, updateFailedTask]);
    dispatcher(payload, notificationReceived);
  };

  mapping[libraryEventTypes.ThumbnailEditFailure] = (payload) => {
    dispatchMany(payload, [mediaThumbnailCreatedAlert, updateFailedTask]);
    dispatcher(payload, notificationReceived);
  };

  mapping[libraryEventTypes.LockVideoAssetSuccess] = (payload) => {
    dispatch(getEntityLockSuccess(payload.id));
  };

  mapping[libraryEventTypes.VideoTagsAddSuccess] = dispatchTaskOperationSuccess((data) => data);

  mapping[libraryEventTypes.VideoTagsAddFailure] = dispatchTaskOperationFailure((data) => data);

  mapping[libraryEventTypes.ClosedCaptionsAddSuccess] = dispatchAlert(
    AlertTypes.success,
    "Closed captions has been uploaded successfully!",
  );

  mapping[libraryEventTypes.ClosedCaptionsAddFailure] = dispatchAlert(
    AlertTypes.error,
    "Closed captions uploading failed!",
  );

  mapping[libraryEventTypes.BulkClosedCaptionsDeletionSuccess] = dispatchTaskOperationSuccess((data) => data);

  mapping[libraryEventTypes.BulkClosedCaptionsDeletionFailure] = dispatchTaskOperationFailure((data) => data);
};
