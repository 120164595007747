import { FC, useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { TextTruncate } from "../../../../components";
import { NoFilteredData } from "../../../../components/charts";
import { Tooltip } from "../../../../components/common/tooltip";
import { ListViewBase } from "../../../../components/listView";
import { ColumnOption } from "../../../../interfaces/columnOptions";
import { PerformanceTabSectionList } from "../../../Library/Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";
import { OwnerPacksLicensing } from "../../types/packs";
import utils from "utils/miscellaneousUtils";

import "./spPackReportList.scss";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "features/Application/globaltypes/redux";
import { AccountTypes } from "features/Accounts/types";
export interface Props extends PropsFromRedux {
  reduxFinished: boolean;
  ownerPackLicenseData: OwnerPacksLicensing[];
  onPackSelection: ({ id, name }: { id: number; name: string; }) => void;
}

export const SpPackReportList: FC<Props> = ({
  reduxFinished,
  ownerPackLicenseData,
  onPackSelection,
  accountType,
}) => {
  const [slicedEntities, setSlicedEntities] = useState<OwnerPacksLicensing[]>([]);

  useEffect(() => {
    if (reduxFinished) {
      setSlicedEntities([...ownerPackLicenseData].slice(0, 10));
    }
  }, [ownerPackLicenseData, reduxFinished]);

  const columns: ColumnOption<OwnerPacksLicensing>[] = [
    {
      name: "Pack Name",
      width: 3,
      render(item) {
        return (
          <Tooltip
            target={
              <TextTruncate>
                <a
                  href="./"
                  onClick={(e) => {
                    e.preventDefault();
                    onPackSelection({ id: item.PackId, name: item.PackName });
                  }}
                >
                  {item.PackName}
                </a>
              </TextTruncate>
            }
            content={item.PackName}
          />
        );
      },
    },
    {
      name: "Active Trials",
      width: 2,
      render(item) {
        return item.ActiveTrials.toLocaleString();
      },
    },
    {
      name: "Active Customers",
      width: 3,
      render(item) {
        return item.ActiveCustomers.toLocaleString();
      },
    },
    {
      name: "Expired Customers",
      width: 3,
      render(item) {
        return item.ExpiredCustomers.toLocaleString();
      },
    },
    {
      name: "Total Customers",
      width: 3,
      render(item) {
        return item.TotalCustomers.toLocaleString();
      },
    },
    {
      name: "Total Licenses",
      width: 3,
      render(item) {
        return utils.unlimitedNumber(item.TotalLicenses).toLocaleString();
      },
    },
  ];

  if (accountType === AccountTypes.BSI || accountType === AccountTypes.ChannelPartner || accountType === AccountTypes.MSP) {
    columns.splice(1, 0, {
      name: "Vendor",
      width: 2,
      render(item) {
        return (
          <div className="shortened-pack-name-cell">
            <Tooltip
              position={"top left"}
              target={<TextTruncate>{item.PackOwnerName}</TextTruncate>}
              content={item.PackOwnerName}
            />
          </div>
        );
      },
    });
  }

  const buildTableBody = (item: OwnerPacksLicensing) => {
    return columns.map((column, index) => (
      <Table.Cell className="table-body-cell" width={column.width} key={`${column.name}_${index}`}>
        {column.render(item)}
      </Table.Cell>
    ));
  };

  const getData = (skip: number, top: number) => {
    setSlicedEntities([...ownerPackLicenseData].slice(skip, skip + top));
  };

  return (
    <PerformanceTabSectionList
      title="Packs"
      count={ownerPackLicenseData.length}
      renderList={(page) => {
        const parsedData = slicedEntities.map((entity) => ({
          id: entity.PackId,
          ...entity,
        }));
        return !!ownerPackLicenseData.length ? (
          <ListViewBase
            columnOptions={columns}
            loadPage={getData}
            items={parsedData}
            itemsAmount={ownerPackLicenseData.length}
            buildTableBody={buildTableBody}
            withFooter={false}
            activePage={page}
            withSelection={false}
          />
        ) : (
          <NoFilteredData />
        );
      }}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  accountType: state.userProfile.accountTypeId,
});

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SpPackReportList);