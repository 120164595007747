import { MutableRefObject } from "react";
import { BarEnterSelection, BarUpdateSelection, DataPoint } from "../../types/HorizontalBarChart";

// Transition functions
export const baseEnterTransition = (
  selection: BarEnterSelection,
  xScale: any,
  scrollable: boolean,
  transitionDurationRef: MutableRefObject<number>,
) => {
  let conditionalEnter = !scrollable ? selection.transition().duration(transitionDurationRef.current) : selection;
  conditionalEnter.attr("width", (d) => Math.max(0, xScale(d.value) / 2));
};

export const baseUpdateTransition = (
  selection: BarUpdateSelection,
  xScale: any,
  scrollable: boolean,
  transitionDurationRef: MutableRefObject<number>,
  getYValueForRect: (d: DataPoint) => number,
) => {
  let conditionalUpdate = !scrollable ? selection.transition().duration(transitionDurationRef.current) : selection;
  conditionalUpdate
    .attr("y", getYValueForRect)
    // Need to ignore because of typechecking behaving incorrectly
    // (interpreting the 'y' above as possibly returning a string)
    // @ts-ignore
    .attr("width", (d: DataPoint) => Math.max(0, xScale(d.value) / 2));
};

export const roundedEnterTransition = (
  selection: BarEnterSelection,
  xScale: any,
  scrollable: boolean,
  transitionDurationRef: MutableRefObject<number>,
) => {
  let conditionalEnter = !scrollable ? selection.transition().duration(transitionDurationRef.current) : selection;
  conditionalEnter.attr("width", (d) => xScale(d.value));
};

export const roundedUpdateTransition = (
  selection: BarUpdateSelection,
  xScale: any,
  scrollable: boolean,
  transitionDurationRef: MutableRefObject<number>,
  getYValueForRect: (d: DataPoint) => number,
) => {
  let conditionalUpdate = !scrollable ? selection.transition().duration(transitionDurationRef.current) : selection;
  conditionalUpdate
    .attr("y", getYValueForRect)
    // @ts-ignore
    .attr("width", (d) => xScale(d.value));
};
