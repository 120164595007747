import React from "react";
import sortDirections from "../../../../../enums/sortDirections";
import ViewType from "../../../../../enums/ViewType";
import NoResults from "../../../../../components/noResults/NoResults";
import ItemsView from "../../../../../views/ItemsView/ItemsView";
import Contact from "../../../../../interfaces/contact";
import SearchInput from "../../../../../components/searchInput/SearchInput";
import ContactRow from "./ContactRow";
import columnOptions from "./columnOptions";
import { SortingDirection } from "../../../../../enums";

import "./accountContactsList.scss";

export interface AccountContactsListProps {
  contacts: Contact[];
  contactsCount: number;
  loadPage: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: any,
  ) => void;
  isLoading?: boolean;
  renderAddContactsButton: () => React.ReactElement;
  isReadOnly: boolean;
  goToEditUser?: (userId: number) => void;
  onContactRemoved: (contactId: number) => void;
  onMainContactChanged: (contactId: number) => void;
  mainContactId?: number;
}

export default function AccountContactsList(props: AccountContactsListProps) {
  const defaultSortingColumnName = "FirstName";

  const buildTableBody = (contact: Contact) => (
    <ContactRow
      contact={contact}
      isReadOnly={props.isReadOnly}
      onContactRemoved={props.onContactRemoved}
      onMainContactChanged={props.onMainContactChanged}
      goToEditUser={props.goToEditUser}
      isMainContact={isMainContact(contact)}
    />
  );

  const isMainContact = (contact: Contact) => contact.id === props.mainContactId;

  const noResultsContent = () => (
    <NoResults
      title="No Contacts"
      description={
        <div className="text-muted">
          <i>Use the button above to add contacts for this account</i>
        </div>
      }
      iconClassName="fal fa-user"
    >
      {props.renderAddContactsButton()}
    </NoResults>
  );

  const { contacts, contactsCount, loadPage, isLoading } = props;

  return (
    <ItemsView
      className="account-contacts-list"
      viewType={ViewType.LIST}
      columnOptions={columnOptions}
      getData={loadPage}
      itemsInlineCount={contactsCount}
      isLoading={isLoading}
      items={contacts}
      buildTableBody={(contact: Contact) => buildTableBody(contact)}
      sortingColumnName={defaultSortingColumnName}
      sortingDirection={sortDirections.Asc}
      noResultsContent={noResultsContent()}
      withSelection={false}
      renderSearch={() => <SearchInput placeholder="Search for contacts..." disabled />}
    />
  );
}
