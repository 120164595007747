import React from "react";
import { withFormik } from "formik";

import { Modal } from "../../../../components/modal";
import { ValidatedForm } from "../../../../components";
import { PackRequest } from "../types/state";
import { EnablePackModalProps, EnablePackModaWithFormProps } from "./types";
import EnablePackForm from "./EnablePackForm";
import { packTrialRequestValidationSchema } from "../../../../utils/validationSchemas/packRequestValidationSchema";
import RequestPackModalActions from "./RequestPackModalActions";
import ModalSizes from "../../../../enums/modalSizes";

import "./packRequestModal.scss";

export const EnablePackModal: React.FC<EnablePackModaWithFormProps> = (props) => {
  const { values, isValid, resetForm, onCancelPackRequest, onSubmitEnablePack } = props;

  const renderModalActions = (closeModal: any) => {
    return (
      <RequestPackModalActions
        onSubmit={() => {
          onSubmitEnablePack(values);
          closeModal();
        }}
        onCancel={() => {
          closePackRequestModal();
          closeModal();
        }}
        isValid={isValid}
      />
    );
  };

  const closePackRequestModal = () => {
    resetForm();
    onCancelPackRequest?.();
  };

  return (
    <Modal
      className="pack-request-modal"
      title="Purchase Pack"
      open={props.isOpen}
      renderModalActions={renderModalActions}
      onCancel={closePackRequestModal}
      size={ModalSizes.tiny}
      dataTestId="enable-pack-modal"
    >
      <ValidatedForm formWidthComputer={16} formWidthTablet={16} {...props}>
        <EnablePackForm {...props} />
      </ValidatedForm>
    </Modal>
  );
};

/* istanbul ignore next */
export default withFormik<EnablePackModalProps, PackRequest>({
  validateOnMount: true,
  validationSchema: packTrialRequestValidationSchema,
  mapPropsToValues: (props: EnablePackModalProps) => {
    return {
      packId: props.packId,
    } as PackRequest;
  },
  handleSubmit: () => {}, //NOSONAR
  enableReinitialize: true,
})(EnablePackModal);
