import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

export interface Props {
  onClick: () => void;
  isDisabled?: boolean;
  className?: string;
  text?: string;
  after?: React.ReactNode;
}

const RemoveLinkButton: React.FC<Props> = (props) => {
  const { onClick, isDisabled, className, text, after } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <BasicLinkButton
        text={text || "Remove"}
        iconClassName="far fa-minus-circle"
        onClick={onClick}
        isDisabled={isDisabled}
        className={className}
      />
      {after}
    </div>
  );
};

export default RemoveLinkButton;
