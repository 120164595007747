import { FC } from "react";

import { DetailsHeader } from "../../../../components/sectionHeader";
import { PublishedStatusTypes } from "../../../../enums";
import { IModalManager } from "../../../../interfaces/IModalManager";
import { PackView } from "../types/state";

export interface Props {
  pack: PackView;
  packsOverviewUrl: string;
  isEntityCommandInProgress: boolean;
  isValid: boolean;
  handlePublish: () => void;
  handleClose: () => void;
  handleEdit: () => void;
  revertModalManager: IModalManager;
  renderActionButtons: () => React.ReactElement[];
}

export const EditPackDetailsHeader: FC<Props> = (props: Props) => {
  return (
    <DetailsHeader
      title={props.pack.title}
      titleForGA="Pack Details"
      backButton={props.packsOverviewUrl}
      defaultURL={props.packsOverviewUrl}
      canBeEdited={!props.pack.isDraft}
      isRevertVisible={props.pack.hasBeenPublished}
      canBePublished={!props.isEntityCommandInProgress}
      publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!props.pack.isDraft)}
      invalidFormDetails={!props.isValid}
      entityStateActions={{
        onPublish: props.handlePublish,
        onClose: props.handleClose,
        onEdit: props.handleEdit,
        onRevert: props.revertModalManager.execute,
      }}
      actionButtons={props.renderActionButtons()}
    ></DetailsHeader>
  );
};

export default EditPackDetailsHeader;
