import React, { FC, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "../../../../../Application/globaltypes/redux";
import { PackGroupLicenseView } from "../../../types/state";
import { fetchPackGroupLicenses } from "../../../state/thunks/packGroupLicensesThunk";
import { ItemsTypes } from "../../../../../../enums/itemsTypes";
import { RolePermissions, SortingDirection, UsersGroupsContext, ViewType } from "../../../../../../enums";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import { ItemsView } from "../../../../../../views";
import GroupLicenseInfoCell from "../../../../../../components/groupLicenseInfoCell/GroupLicenseInfoCell";
import { columnsMap, getColumnOptions } from "./columnOptions";
import PeopleLicensesEmpty from "../PeopleLicensesEmpty/PeopleLicensesEmpty";
import { bindAction } from "../../../../../../interfaces/redux";
import { SearchInput } from "../../../../../../components";
import styles from "../peopleLicenses.module.scss";

import {
  AddAllPackGroupSuccess,
  AddAllGroupLicenseMessageSuccess,
  RemoveAllGroupLicenseMessageSuccess,
} from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { RemoveLicenseButton } from "components/packs/packsList/removeLicenseButton/RemoveLicenseButton";

export interface PackGroupLicensesOwnProps {
  packId: number;
  items: PackGroupLicenseView[];
  itemsCount: number;
  isLoading: boolean;
  error?: Error;
  setReloadListItems?: (reloadItems: (enableSorting: boolean) => void) => void;
  renderIssueLicenseBtn: () => React.ReactElement;
  customHeaderContent: React.ReactElement;
  permissionPredicate: (userPermissions: RolePermissions[]) => boolean;
  selectedGroupIds: number[];
  onSelectedGroupsChanged: (ids: number[]) => void;
  onRemoveLicenseClick: (groupId: number) => void;
  licenseRemovalEnabled: boolean;
}

export type PackGroupLicensesProps = PackGroupLicensesOwnProps & PropsFromRedux;

const rtnEvents = [AddAllPackGroupSuccess, AddAllGroupLicenseMessageSuccess, RemoveAllGroupLicenseMessageSuccess];

export const GroupLicenses: FC<PackGroupLicensesProps> = (props) => {
  const {
    packId,
    setReloadListItems,
    renderIssueLicenseBtn,
    permissionPredicate,
    selectedGroupIds,
    onSelectedGroupsChanged,
    onRemoveLicenseClick,
    licenseRemovalEnabled,
  } = props;

  const columnOptions = getColumnOptions(licenseRemovalEnabled);

  const renderRemoveButton = (groupId: number) => {
    const isSelected = selectedGroupIds.includes(groupId);
    return (
      <Table.Cell className={styles["align-right"]} width={columnOptions[3].width}>
        <RemoveLicenseButton
          id={groupId}
          isSelected={isSelected}
          onRemoveLicenseClick={onRemoveLicenseClick}
          issueLicensePermissionPredicate={permissionPredicate}
        />
      </Table.Cell>
    );
  };

  useEffect(() => {
    return () => {
      onSelectedGroupsChanged([]);
    };
  }, [onSelectedGroupsChanged]);

  const buildTableBody = (groupLicense: PackGroupLicenseView) => {
    return (
      <React.Fragment key={groupLicense.groupId}>
        <Table.Cell width={columnOptions[0].width}>
          <GroupLicenseInfoCell group={groupLicense} />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>{groupLicense.membersCount}</Table.Cell>
        <Table.Cell width={columnOptions[2].width}>{dateTimeUtils.formatDate(groupLicense.dateAdded)}</Table.Cell>
        {licenseRemovalEnabled && renderRemoveButton(groupLicense.groupId)}
      </React.Fragment>
    );
  };

  const getPackGroupLicenses = async (
    skip = 0,
    top = 10,
    sortingColumnName = "",
    sortingDirection?: SortingDirection,
  ) => {
    const request = { skip, top, sortBy: `${columnsMap[sortingColumnName]}`, sortOrder: sortingDirection };

    props.fetchPackGroupLicenses(packId, request);
  };

  // implement after search & filters are implemented
  const isFiltered = () => false;

  const { items, isLoading, itemsCount, customHeaderContent } = props;
  const itemsMapped = items.map((i) => ({ ...i, id: i.groupId }));

  return (
    <section className="scrollable-content">
      <ItemsView
        itemsType={ItemsTypes.Group}
        items={itemsMapped}
        searchPlaceholder="Search for Groups..."
        getData={getPackGroupLicenses}
        noResultsContent={
          <PeopleLicensesEmpty
            isFiltered={isFiltered()}
            issueLicenseButton={renderIssueLicenseBtn()}
            usersGroupsContext={UsersGroupsContext.Groups}
          />
        }
        sortingColumnName={columnOptions[2].name}
        sortingDirection={SortingDirection.Descending}
        isLoading={isLoading}
        isAllDataLoaded={false}
        className="packs-group-licenses-view"
        buildTableBody={buildTableBody}
        columnOptions={columnOptions}
        itemsInlineCount={itemsCount}
        viewType={ViewType.LIST}
        renderSearch={() => <SearchInput disabled placeholder="Search for group licenses..." />}
        withSelection={licenseRemovalEnabled}
        setReloadListItems={setReloadListItems}
        hideListGridViewSwitcherButton={true}
        doNotLoadPersistentViewType={true}
        customHeaderContent={customHeaderContent}
        listViewRtnEvents={rtnEvents}
        selectedIds={selectedGroupIds}
        onSelectedListItemsChanged={onSelectedGroupsChanged}
      />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  ...state.packs.purchasedPack.packGroupLicenses,
  accountId: state.userProfile.accountId,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchPackGroupLicenses: bindAction(fetchPackGroupLicenses, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GroupLicenses);
