import { pluralize } from "../../../../../../utils/stringUtils";
import { UpdateParamsV2 } from "../../../../../../interfaces/updateParams";
import groupsDataService from "../../../../services/groupsDataService";

export const removeUsersFromGroupTask = async (
  userIds: number[],
  groupId: number,
  action: (params: UpdateParamsV2) => Promise<void>,
) => {
  const userCount = userIds.length;

  if (!userCount || groupId < 1) {
    return;
  }

  const isSingle = userCount === 1;

  const params: UpdateParamsV2 = {
    id: `RemoveUsersFromGroup_${Date.now()}`,
    title: `Removing ${pluralize("user", userCount)} from group`,
    getMessageIdsV2: () => userIds.map((userId) => `RemoveUserFromGroup_${userId}:${groupId}`),
    callApi: async () => {
      const response = await groupsDataService.removeEnrolledGroupMembers(groupId, userIds);
      return response?.data?.messageIds;
    },
    successTransientMessage: `${pluralize("User", userCount)} ${
      isSingle ? "has" : "have"
    } been removed from group successfully`,
    failureTransientMessage: `Removal ${pluralize("user", userCount)} from group failed!`,
  };

  await action(params);
};
