import { FC, useEffect } from "react";
import moment from "moment";
import { DropdownItemProps, Grid } from "semantic-ui-react";

import { ValidatedForm } from "../../../../../components";
import { LicenseTypes } from "../../../../../enums/licensing/licenseType";
import { PackRequestFormFieldProps } from "./types";

export interface TermFieldOwnProps {
  setExpirationDate: (licenseType?: number, date?: Date | null) => void;
}

type TermFieldProps = PackRequestFormFieldProps & TermFieldOwnProps;

export const TermField: FC<TermFieldProps> = ({ onIsValidChange, setExpirationDate, ...props }) => {
  const annual = "Annual";
  const annualId = 0;
  const termTypes = [{ id: annualId, name: annual }];
  const termTypeOptions: DropdownItemProps[] = termTypes.map((option) => {
    return {
      text: option.name,
      value: option.id,
    };
  });

  useEffect(
    () => setExpirationDate(LicenseTypes.CustomDate, moment().add(1, "years").toDate()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Grid.Row>
      <Grid.Column>
        <ValidatedForm.DropdownField
          {...props}
          label="Term"
          value={annualId}
          propertyName="expirationTypeId"
          options={termTypeOptions}
          placeholder="Select Term"
          onIsFormValidChange={onIsValidChange}
          disabled={true}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
