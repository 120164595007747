import React from "react";
import { Icon } from "semantic-ui-react";

import { DetailsHeader } from "../../../../../components/sectionHeader";
import { PackStatusActionLabel } from "../../../../../components/packs/packStatusActionLabel/PackStatusActionLabel";
import DateLabel from "./DateLabel";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { isBrainStorm } from "components/packs/packPublisherInfo/PackPublisherInfo";
import Logo from "../../../../../images/brainstorm-pack-logo.png";

import "./listingOverviewHeader.scss";

export interface Props {
  title: string;
  titleForGA?: string;
  backButton: string | boolean;
  defaultURL: string;
  isRequested: boolean;
  isPurchased: boolean;
  isExpired: boolean;
  isTrial: boolean;
  expirationDate?: string;
  isOwn: boolean;
  isTrialAllowed: boolean;
  publisher?: string;
  logoUri?: string;
  onPackRequest: () => void;
  onPackTrialRequest: () => void;
  onEditClick: () => void;
  renderActionButtons?: () => React.ReactElement[];
}

export const ListingOverviewHeader: React.FC<Props> = ({
  title,
  titleForGA,
  backButton,
  defaultURL,
  isOwn,
  isRequested,
  isPurchased,
  isExpired,
  isTrial,
  expirationDate,
  onPackRequest,
  onPackTrialRequest,
  onEditClick,
  renderActionButtons,
  isTrialAllowed,
  publisher,
  logoUri,
}) => {
  const isRequestButtonVisible = !(isOwn || isPurchased || isExpired);
  const isPurchasedLabelVisible = !isOwn && isPurchased && !isExpired && !isTrial;
  const isExpiredLabelVisible = isPurchased && isExpired && !isTrial;
  const isTrialExpiredLabelVisible = isTrial && isExpired;
  const isTrialStartedLabelVisible = isTrial && !isExpired;
  const licenseExpirationDate = expirationDate
    ? dateTimeUtils.toLocalFormatDateTime(new Date(expirationDate), "MM/DD/YYYY")
    : "";

  return (
    <>
      <DetailsHeader
        title={title}
        titleForGA={titleForGA}
        backButton={backButton}
        defaultURL={defaultURL}
        canBeEdited={isOwn}
        isRevertVisible={false}
        isReadOnly={!isOwn}
        showReadOnlyLabel={false}
        invalidFormDetails={false}
        canBePublished={false}
        renderEditButton={true}
        entityStateActions={{
          onPublish: /* istanbul ignore next */ () => { }, //NOSONAR
          onClose: /* istanbul ignore next */ () => { }, //NOSONAR
          onEdit: () => onEditClick(),
          onRevert: /* istanbul ignore next */ () => { }, //NOSONAR
        }}
        actionButtons={isOwn ? renderActionButtons?.() : undefined}
      >
        {isRequestButtonVisible && (
          <PackStatusActionLabel
            isExpired={isExpired}
            isOwn={isOwn}
            isPurchased={isPurchased}
            isRequested={isRequested}
            isTrialAllowed={isTrialAllowed}
            onRequestClick={onPackRequest}
            onTrialClick={onPackTrialRequest}
          />
        )}
        {isPurchasedLabelVisible && (
          <span className="header-label header-label__purchased">
            <Icon name="checkmark" />
            <span className="label-with-date">
              <span className="status">Purchased</span>
              <DateLabel label="Expires" date={licenseExpirationDate} />
            </span>
          </span>
        )}
        {isExpiredLabelVisible && (
          <span className="header-label header-label__expired">
            <Icon name="exclamation triangle" />
            <span className="label-with-date">
              <span className="status">Expired</span>
              <DateLabel date={licenseExpirationDate} />
            </span>
          </span>
        )}
        {isTrialStartedLabelVisible && (
          <span className="header-label header-label__purchased">
            <Icon name="checkmark" />
            <span className="label-with-date">
              <span className="status">Trial Started</span>
              <DateLabel label="Expires" date={licenseExpirationDate} />
            </span>
          </span>
        )}
        {isTrialExpiredLabelVisible && (
          <span className="header-label header-label__expired">
            <Icon name="exclamation triangle" />
            <span className="label-with-date">
              <span className="status">Trial Ended</span>
              <DateLabel date={licenseExpirationDate} />
            </span>
          </span>
        )}
      </DetailsHeader>
      {publisher && (
        <div className="secondary-headline">
          <div className="vendor">
            {isBrainStorm(publisher) && (
              <div className="pack-publisher-logo">
                <img src={Logo} className="pack-publisher-logo-center" alt="BrainStorm Logo" />
              </div>
            )}
            By {publisher}
          </div>
        </div>
      )}
      <div className="divider" />
    </>
  );
};
