export const withAutoCancel = <T extends Array<any>, TResult>(func: (...args: [...T, AbortSignal]) => TResult) => {
  let abortController = new AbortController();
  return (...args: T): TResult => {
    abortController.abort();
    abortController = new AbortController();
    return func(...args, abortController.signal);
  };
};

export const withCancel = <T extends Array<any>, TResult>(func: (...args: [...T, AbortSignal]) => TResult) => {
  return (...args: T) => {
    const abortController = new AbortController();
    return {
      promise: func(...args, abortController.signal),
      cancel: abortController.abort,
    };
  };
};
