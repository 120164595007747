import { Dispatch } from "@reduxjs/toolkit";

import * as groupsOverviewActionTypes from "./groupsOverviewActionTypes";
import dataService from "../../../../Application/services/dataServices/dataService";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import { Filters } from "../../../../../utils/queryUtils";

const takeCount = process.env.REACT_APP_LOAD_ITEMS_COUNT;

export const setGroupsFilter = makeActionCreator(groupsOverviewActionTypes.GROUPS_SET_ITEMS_FILTER, "filter");

export const resetGroupsFilter = makeActionCreator(groupsOverviewActionTypes.GROUPS_RESET_ITEMS_FILTER);

export const resetGridItems = makeActionCreator(groupsOverviewActionTypes.FETCH_GROUPS_LAZY.RESET_GRID_ITEMS);

export const fetchGroups = (skip: number, top: number, orderBy: string | null, filterParams: Filters) => {
  const { filter } = filterParams;
  const fetchGroupsBegin = makeActionCreator(groupsOverviewActionTypes.FETCH_GROUPS.FETCH_ITEMS_BEGIN);

  const fetchGroupsSuccess = makeActionCreator(
    groupsOverviewActionTypes.FETCH_GROUPS.FETCH_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  );

  const fetchGroupsFailure = makeActionCreator(groupsOverviewActionTypes.FETCH_GROUPS.FETCH_ITEMS_FAILURE, "error");

  return async (dispatch: Dispatch) => {
    dispatch(fetchGroupsBegin());
    try {
      const result = await dataService.getGroups({
        skip,
        top,
        orderBy,
        filter,
      });
      dispatch(fetchGroupsSuccess(result.items, result.count));
    } catch (error) {
      dispatch(fetchGroupsFailure(error));
    }
  };
};

export const fetchGroupsLazy = (skip: number, orderBy: string, filterParams: Filters) => {
  const { filter } = filterParams;
  const fetchGroupsBegin = makeActionCreator(groupsOverviewActionTypes.FETCH_GROUPS_LAZY.FETCH_GRID_ITEMS_BEGIN);

  const fetchGroupsSuccess = makeActionCreator(
    groupsOverviewActionTypes.FETCH_GROUPS_LAZY.FETCH_GRID_ITEMS_SUCCESS,
    "items",
    "itemsCount",
  );
  const fetchGroupsFailure = makeActionCreator(
    groupsOverviewActionTypes.FETCH_GROUPS_LAZY.FETCH_GRID_ITEMS_FAILURE,
    "error",
  );

  return async (dispatch: Dispatch) => {
    dispatch(fetchGroupsBegin());
    try {
      const result = await dataService.getGroups({
        skip,
        top: takeCount,
        orderBy,
        filter,
      });
      dispatch(fetchGroupsSuccess(result.items, result.count));
    } catch (error) {
      dispatch(fetchGroupsFailure(error));
    }
  };
};
