import { isEmpty } from "lodash";
import { useRef } from "react";
import { Action } from "../interfaces/functionTypes/Action";

export type UseReloadItemViewListItemsReturnFunctions = [
  (reloadListItems: (enableSorting: boolean) => void) => void,
  (search: string) => void,
];

export const useReloadItemViewListItems = (setSearch: Action<string>): UseReloadItemViewListItemsReturnFunctions => {
  const reloadListItemsRef = useRef<(enableSorting: boolean) => void>();
  const refetchItemsFromStart = (enableSorting: boolean) => {
    reloadListItemsRef.current?.(enableSorting);
  };
  const setReloadListItems = (reloadListItems: (enableSorting: boolean) => void) => {
    reloadListItemsRef.current = reloadListItems;
  };

  const onSearchChanged = (search: string) => {
    setSearch(search);
    refetchItemsFromStart(isEmpty(search));
  };

  return [setReloadListItems, onSearchChanged];
};
