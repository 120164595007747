import { createAsyncThunk } from "@reduxjs/toolkit";
import StatusCode from "../../../../../enums/httpStatusCodes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { getPrefix } from "../../../../Application/slices/models";
import { GetPacksRequest, PacksRequest } from "../../types/requests";
import * as editAccountActionTypes from "../../../../../features/Accounts/EditAccount/state/editAccountActionTypes";
import { FetchActionPayload } from "../../../../../interfaces/redux";
import { AccountPackOverview } from "../../../../../components/assignmentModals/packAssignmentModal/types";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const loadGridItemsCount: any = process.env.REACT_APP_LOAD_ITEMS_COUNT;

const fetchAccountPackForbidden = makeActionCreator(editAccountActionTypes.FETCH_ACCOUNT_PACKS_FORBIDDEN, "error");

export const fetchAccountPackLicenses = createAsyncThunk<FetchActionPayload<AccountPackOverview>, PacksRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "accountPackLicenses",
  }),
  async (requestData: PacksRequest, { signal, dispatch }) => {
    const request: GetPacksRequest = {
      top: loadGridItemsCount,
      skip: requestData.skip,
      orderBy: requestData.orderBy,
      searchTerm: requestData.searchTerm,
    };

    try {
      const { getDataPromise, cancel } = dataService.packs.getAccountPacksWithCancel(
        requestData.accountId,
        request,
        requestData.filters || {},
      );

      signal.addEventListener("abort", cancel);

      const result = await getDataPromise;
      const recordsCount = Number.parseInt(result.headers[countHeaderName]);

      return {
        items: result.data,
        totalCount: recordsCount,
      };
    } catch (error: any) {
      if (error.response?.status === StatusCode.Forbidden) {
        dispatch(fetchAccountPackForbidden(error));
      }

      throw error;
    }
  },
);
