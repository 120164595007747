import { v4 } from "uuid";
import { IAnswerBase } from "../../../../../Common/models";
import { SurveyAnswerType, SurveyQuestionType } from "../../../../types/models";

const defaultAnswerData = ((): { [key in SurveyAnswerType]: () => IAnswerBase } => {
  const stringAnswer = (): IAnswerBase => ({
    answer: "",
    answerType: SurveyAnswerType.Text,
    answerOrder: undefined,
    id: v4(),
  });

  return {
    [SurveyAnswerType.Text]: stringAnswer,
    /* istanbul ignore next */
    [SurveyAnswerType.Picture]: () => {
      throw new Error("Not implemented exception");
    },
  };
})();

export const getAnswerDefaultData = (questionType: number): IAnswerBase => {
  const questionTypeToAnswerType = {
    [SurveyQuestionType.SingleChoiceTextQuestion]: SurveyAnswerType.Text,
    [SurveyQuestionType.MultipleChoiceTextQuestion]: SurveyAnswerType.Text,
    [SurveyQuestionType.PictureQuestion]: SurveyAnswerType.Picture,
  };
  return defaultAnswerData[questionTypeToAnswerType[questionType as SurveyQuestionType]]();
};
