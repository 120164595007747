import { makeAspectCrop } from "react-image-crop";

const autoCrop = (image, initialCrop) => {
  const imageAspect = image.naturalWidth / image.naturalHeight;
  if (imageAspect < initialCrop.aspect) {
    const newCrop = makeAspectCrop({ aspect: initialCrop.aspect, width: 100 }, imageAspect);
    const y = (100 - newCrop.height) / 2;
    return Object.assign(newCrop, { y: y });
  }
  if (imageAspect > initialCrop.aspect) {
    const newCrop = makeAspectCrop({ aspect: initialCrop.aspect, height: 100 }, imageAspect);
    const x = (100 - newCrop.width) / 2;
    return Object.assign(newCrop, { x: x });
  }

  return makeAspectCrop(initialCrop, imageAspect);
};

export default autoCrop;
