import { combineReducers } from "redux";

import * as actionTypes from "./userCommonActionTypes";
import { ReducerNamespaceTypes, ReducerEntityPrefixTypes } from "../../../../enums";
import { withFetchingChildEntityListItems } from "../../../Application/reducers/hoc/withFetchingItems";
import { CountryModelItem } from "../../types";

const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.UserCommon;

const userCommonReducer = combineReducers({
  countriesList: withFetchingChildEntityListItems<CountryModelItem>(namespace, entityPrefix, actionTypes.Countries),
});

export default userCommonReducer;

export type UserCommonState = ReturnType<typeof userCommonReducer>;
