import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";
import { isEmpty } from "lodash";
import ValidatedField from "./ValidatedField";
import { Progress } from "../progress";
import fileUtils from "../../utils/fileUtils";
import { ClearInputButton } from "../buttons/clearInputButton/ClearInputButton";
import { Button } from "components/buttons/button/Button";

import "./uploadFileField.scss";

class UploadFileField extends Component {
  constructor(props) {
    super(props);
    this.fileButton = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      !fileUtils.areFileSequencesEqual(this.props.value, prevProps.value) &&
      !fileUtils.areFileSequencesEqual(this.props.value, this.getSelectedFiles())
    ) {
      this.clearFileInput();
    }
  }

  browseFile = () => {
    if (!this.props.isUploading) {
      this.fileButton.current.click();
    }
  };

  formatFilesNames = () =>
    this.props.value
      ? Array.from(this.props.value)
          .map((file) => file.name)
          .join(", ")
      : "";

  getSelectedFiles = () => this.fileButton.current.files;

  onChange = async () => {
    const files = this.getSelectedFiles();
    if (files.length === 0) {
      return;
    }

    this.updateFilesValue(files);
    await this.tryToUploadFiles(files);
  };

  tryToUploadFiles = async (files) => {
    const { propertyName, filesValidator, uploadFilesHandler, touched } = this.props;

    if (filesValidator) {
      try {
        await filesValidator.validate(files);
        uploadFilesHandler && uploadFilesHandler(files);
      } catch (error) {
        touched[propertyName] = true;
      }
    }
  };

  updateFilesValue = (files) => {
    const { onChangeHandler, propertyName } = this.props;
    this.props.setFieldValue(propertyName, files, true);
    onChangeHandler && onChangeHandler(files, this.props);
  };

  cancelFileUploading = () => {
    const { onCancelFileUploading } = this.props;
    onCancelFileUploading && this.props.onCancelFileUploading();
    this.clearFileInput();
  };

  clear = () => {
    this.clearFileInput();
    this.updateFilesValue(undefined);
  };

  clearFileInput = () => {
    this.fileButton.current.value = null;
  };

  renderProgress() {
    const {
      value,
      isUploading,
      uploadingError,
      uploadingProgress,
      showPercents,
      persistProgressBarOnSuccess,
      indeterminate,
    } = this.props;
    const isInErrorState = Boolean(uploadingError);

    if (
      !isEmpty(value) &&
      ((uploadingProgress === 100 && persistProgressBarOnSuccess) || isUploading || isInErrorState)
    ) {
      return (
        <Progress
          label={this.renderProgressLabel(isInErrorState)}
          percent={uploadingProgress}
          onCancel={this.props.canCancel && this.cancelFileUploading}
          error={isInErrorState}
          showPercents={showPercents}
          isUploading={isUploading}
          indeterminate={indeterminate && isUploading}
        />
      );
    }
    return null;
  }

  renderProgressLabel(isInErrorState) {
    const { isUploading } = this.props;

    if (isUploading) {
      return "Uploading...";
    }

    if (isInErrorState) {
      return "File upload failed!";
    }

    return "Success!";
  }

  renderButtonLabel = () => {
    const { isUploading, loadingButtonLabel, buttonLabel } = this.props;
    return isUploading && loadingButtonLabel ? loadingButtonLabel : buttonLabel;
  };

  renderBrowseButton = () => (
    <Button
      blur
      basic
      color="blue"
      className="file-load-browse"
      disabled={this.isInputDisabled()}
      attached="right"
      onClick={this.browseFile}
    >
      {this.renderButtonLabel()}
    </Button>
  );

  renderClearButton = () => {
    const { clearable, value } = this.props;
    if (clearable && !isEmpty(value)) {
      return <ClearInputButton onClick={this.clear} disabled={this.isInputDisabled()} />;
    }
  };

  isInputDisabled = () => this.props.disabled || this.props.isUploading;

  render() {
    const { id, propertyName, accept, allowMultiple } = this.props;

    return (
      <div className="upload-file-field">
        <ValidatedField {...this.props}>
          <div className="input-container">
            <Input
              className="upload-file-input"
              name={propertyName}
              placeholder="Browse File"
              readOnly
              value={this.formatFilesNames()}
              disabled={this.isInputDisabled()}
              onClick={this.browseFile}
              icon={this.renderClearButton()}
            />
            <input
              data-testid="upload-file-input"
              id={id}
              type="file"
              accept={accept}
              multiple={allowMultiple}
              className="hidden"
              ref={this.fileButton}
              disabled={this.isInputDisabled()}
              onChange={this.onChange}
            />
            {this.renderBrowseButton()}
          </div>
          {this.props.value && this.renderProgress()}
        </ValidatedField>
      </div>
    );
  }
}

UploadFileField.defaultProps = {
  buttonLabel: "Browse",
  showPercents: true,
  persistProgressBarOnSuccess: false,
  indeterminate: false,
  canCancel: true,
};

UploadFileField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  clearable: PropTypes.bool,
  propertyName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  onChangeHandler: PropTypes.func,
  accept: PropTypes.string,
  id: PropTypes.string.isRequired,
  allowMultiple: PropTypes.bool,
  buttonLabel: PropTypes.string.isRequired,
  uploadingError: PropTypes.object,
  uploadingProgress: PropTypes.number,
  isUploading: PropTypes.bool,
  onCancelFileUploading: PropTypes.func,
  loadingButtonLabel: PropTypes.string,
  filesValidator: PropTypes.object,
  setFieldValue: PropTypes.func,
  showPercents: PropTypes.bool,
  persistProgressBarOnSuccess: PropTypes.bool,
  indeterminate: PropTypes.bool,
  canCancel: PropTypes.bool,
};

export default UploadFileField;
