import { bindActionCreators } from "@reduxjs/toolkit";
import { isEmpty, noop } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { SearchInput } from "../../../../../../../components";
import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";
import { FiltersMap } from "../../../../../../../utils/filterUtils";
import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";
import Restricted from "../../../../../../Application/Restricted";

import ContentUsersNoResults from "../../../../../Common/Fields/ContentUsersNoResults";
import { DEFAULT_REQUEST_PARAMS } from "../../../../../PeopleAssignments/Overview/helpers/constants";
import {
  resetSearch as resetSearchAction,
  setSearch as setSearchAction,
} from "../../../../../PeopleAssignments/state/actions/userAssignmentActions";
import { resetSelectedItems } from "../../../../state/slices/surveyUsersSlice";
import { fetchSurveyUsers } from "../../../../state/thunks/surveyUsersThunk";
import { GET_SURVEY_USERS_DEFAULT_PARAMS } from "../../../../types/constants";
import { Columns, ColumnToParamMap, getColumnOptions } from "./getColumnOptions";
import UsersListContainer from "./UsersListContainer/UsersListContainer";

export interface UsersOverviewPropsBase {
  surveyId: number;
  customHeaderContent: React.ReactElement;
}

export type UsersOverviewProps = UsersOverviewPropsBase & PropsFromRedux;

export const UsersOverview: FC<UsersOverviewProps> = ({
  surveyId,
  customHeaderContent,
  fetchUsers,
  resetSelectedIds,
  resetSearch,
}) => {
  const renderSearchInput = (): React.ReactElement => (
    <SearchInput placeholder="Search for People..." onChange={onSearchChange} defaultValue={search} />
  );

  useEffect(
    () => () => resetSearch(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(
    () => () => {
      resetSelectedIds();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  const [search, setSearch] = useState<string>("");

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChange = async (searchTerm: string) => {
    setSearch(searchTerm);
    reloadListItems.current?.(isEmpty(searchTerm));
  };

  const getSurveyUsers = (
    skip: number = GET_SURVEY_USERS_DEFAULT_PARAMS.skip,
    top: number = GET_SURVEY_USERS_DEFAULT_PARAMS.top,
    orderBy: string = GET_SURVEY_USERS_DEFAULT_PARAMS.orderBy.toLowerCase(),
    sortingDirection: SortingDirection = GET_SURVEY_USERS_DEFAULT_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = ColumnToParamMap[orderBy.toLowerCase() as Lowercase<Columns>];
    fetchUsers({ entityId: surveyId, skip, top, sortingColumn, sortingDirection, filter: appliedFilter, term: search });
  };

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.User,
    RolePermissions.AssetsManage,
  );

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={() => (
        <>
          <UsersListContainer
            defaultSortingColumnName={Columns.Name}
            sortingDirection={SortingDirection.Ascending}
            columnOptions={getColumnOptions()}
            applyFilter={noop}
            resetFilter={noop}
            fetchData={getSurveyUsers}
            renderSearch={renderSearchInput}
            customHeaderContent={customHeaderContent}
            isSelectDisabled={() => true}
            setReloadListItems={createReloadListItems}
            noResultsContent={<ContentUsersNoResults filtered={!!search} />}
          />
        </>
      )}
    />
  );
};
/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  selectedIds: state.library.surveys.edit.users.selectedIds,
  search: state.library.userAssignment.search,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: bindActionCreators(fetchSurveyUsers, dispatch),
  resetSelectedIds: () => dispatch(resetSelectedItems()),
  setSearch: bindActionCreators(setSearchAction, dispatch),
  resetSearch: () => dispatch(resetSearchAction()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersOverview);
