import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import cn from "classnames";

import "./addToLinkButton.scss";

interface AddToLinkButtonProps {
  onClick?: () => void;
  isDisabled?: boolean;
  expandDirection?: "left" | "right";
  text?: string;
}

function AddToLinkButton(props: AddToLinkButtonProps) {
  const { onClick, isDisabled, expandDirection, text = "Add To" } = props;

  const iconAfterLabelClassName =
    expandDirection && cn("expand-icon", expandDirection === "right" ? "fa-chevron-right" : "fa-chevron-left");

  return (
    <BasicLinkButton
      className="add-to-link-button"
      text={text}
      iconClassName="far fa-plus-circle"
      onClick={onClick}
      isDisabled={isDisabled}
      iconAfterLabelClassName={iconAfterLabelClassName}
    />
  );
}

export default AddToLinkButton;
