import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";

import classNames from "classnames";

import "./horizontalBarTooltip.scss";

interface Props {
  visible: boolean;
  left: number;
  top: number;
  text: string;
  color: string;
}

export type HorizontalBarTooltipRef = {
  isHovered: () => boolean;
};

export const HorizBarTooltip = forwardRef<HorizontalBarTooltipRef, Props>(({ visible, left, top, text }: Props, ref) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipPointer = useRef<HTMLDivElement>(null);
  const tooltipTextRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [measured, setMeasured] = useState(false);

  useImperativeHandle(ref, () => ({
    isHovered: () => isHovered,
  }));

  useEffect(() => {
    if (!tooltipTextRef.current) return;
    if (text === "" || !visible) setMeasured(false);
    else {
      // Setting this manually in order to get measurements for the useMemo's
      // below in time
      tooltipTextRef.current.innerText = text;
      setMeasured(true);
    }
  }, [text, visible]);

  const tooltipPosition = useMemo(() => {
    if (!tooltipRef.current || !measured)
      return {
        top,
        left,
      };
    return {
      top: top - tooltipRef.current.clientHeight / 2,
      left,
    };
  }, [left, top, measured]);

  const pointerPosition = useMemo(() => {
    if (!tooltipRef.current || !tooltipPointer.current || text === "" || !measured)
      return {
        left: 0,
        top: 0,
      };

    return {
      left: -tooltipPointer.current.clientWidth / 2,
      top: tooltipRef.current.clientHeight / 2 - tooltipPointer.current.clientHeight / 2,
    };
  }, [text, measured]);

  return ReactDOM.createPortal(
    <div
      data-testid="horizontal bar tooltip"
      ref={tooltipRef}
      className={classNames("bar-tooltip-root", {
        visible: visible,
        measured: measured,
      })}
      style={{ ...tooltipPosition }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="tooltipPointer" ref={tooltipPointer} style={{ ...pointerPosition }} />
      <div className="tooltipBody">
        <div ref={tooltipTextRef} className="tooltipText">
          {text}
        </div>
      </div>
    </div>,
    document.body,
  );
});
