import NoResults from "../../../../components/noResults/NoResults";
import { OverviewWithInMemorySorting } from "../../../../components/overviewWithInMemorySorting/OverviewWithInMemorySorting";
import { IdentityProvider } from "../../types";
import IdentityProviderRow, { identityProviderColumnOptions } from "./IdentityProviderRow/identityProviderRow";

const columnToMemberNameMap: Record<string, string> = {
  "id provider": "name",
  added: "addedDate",
  type: "type",
};

const mapColumnToMemberName = (columnName: string) => {
  return columnToMemberNameMap[columnName];
};

export const IdentityProvidersList: React.FC<IdentityProvidersListProps> = ({
  items,
  isLoading,
  hasManagePermission,
  selectedItemIds,
  updateSelectedProviders,
  renderCreateIdentityProviderButton,
  onEdit,
  onDelete,
  onNavigate,
}) => {
  const getEmptyListMessage = () => {
    return hasManagePermission
      ? "Looks like you don’t have any ID Providers yet. Create some now using the button below."
      : "Looks like you don’t have any ID Providers yet.";
  };

  return (
    <OverviewWithInMemorySorting
      columnOptions={identityProviderColumnOptions}
      buildTableBody={(identityProvider: IdentityProvider) => (
        <IdentityProviderRow
          {...identityProvider}
          onEdit={onEdit}
          onDelete={onDelete}
          onNavigate={onNavigate}
          isSelected={selectedItemIds.includes(identityProvider.id)}
          hasManagePermission={hasManagePermission}
        />
      )}
      items={items}
      isLoading={isLoading}
      isSelectDisabled={() => !hasManagePermission}
      mapColumnName={mapColumnToMemberName}
      selectedItemIds={selectedItemIds}
      updateSelectedItems={updateSelectedProviders}
      sortingColumnName="Added"
      sortingDirection="desc"
      noResultsContent={
        <NoResults
          title="You don’t have any ID Providers."
          description={getEmptyListMessage()}
          outline={false}
          iconClassName="fa-user-lock"
        >
          {hasManagePermission && renderCreateIdentityProviderButton()}
        </NoResults>
      }
    />
  );
};

export interface IdentityProvidersListProps {
  items: Array<IdentityProvider>;
  itemsCount: number;
  isLoading: boolean;
  hasManagePermission: boolean;
  selectedItemIds: number[];
  updateSelectedProviders: (ids: number[]) => void;
  renderCreateIdentityProviderButton: () => React.ReactElement;
  onEdit: (identityProviderId: number) => void;
  onDelete: (identityProviderId: number) => void;
  onNavigate: (identityProviderId: number) => void;
}
