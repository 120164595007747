import { useAddOnQuery } from "./useAddOnQuery";
import { canViewCustomersInformation } from "features/Library/Common/utils/performanceUtils";

export const useAccountFilterShouldShow = (accountId: number, accountType: number): boolean => {
  const addOnQuery = useAddOnQuery(accountId);
  if (addOnQuery.isFetching) {
    return false;
  }
  return canViewCustomersInformation(accountType, addOnQuery.data);
};
