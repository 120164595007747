import React from "react";
import { Icon } from "semantic-ui-react";
import { Tooltip } from "../../common/tooltip";
import { AddToPacksLinkButton } from "../linkButtons/addToPacksLinkButton/AddToPacksLinkButton";

export interface Props {
  isDisabled: boolean;
  onClick: () => void;
}

export const AddToPacksButton: React.FC<Props> = (props) => {
  const { isDisabled, onClick } = props;

  return (
    <Tooltip
      showAlways={isDisabled}
      inverted
      hideOnScroll
      position="left center"
      content="Selection contains either unpublished or purchased content."
      target={
        <AddToPacksLinkButton
          after={isDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
          isDisabled={isDisabled}
          onClick={onClick}
        />
      }
    />
  );
};
