import { connect } from "react-redux";
import ContentFilterSidebar, {
  ContentFilterSidebarProps,
} from "../../../../../components/ContentFilterSidebar/ContentFilterSidebar";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { fetchFiltersToDrop } from "../../../../Application/thunks/contentFiltersThunk";
import {
  resetAppliedFilter as resetAppliedFilterAction,
  setAppliedFilter as setAppliedFilterAction,
  setIsLoading,
  setError,
  setFilterOptions,
  PackContentFilters,
} from "../../state/slices/packsContentFiltersSlice";
import { packContentTypeSelectSelector } from "../../state/selectors";
import { reset as resetItemsAction } from "../../state/slices/packContentTabItemsToDropSlice";
import { bindActionCreators } from "@reduxjs/toolkit";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const filters = state.packs.packContentTab.filters;
  return {
    entityTypeSelect: packContentTypeSelectSelector(state),
    filterOptions: filters.filterOptions,
    appliedFilter: filters.appliedFilter,
    isLoading: filters.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  applyFilter: bindActionCreators(setAppliedFilterAction, dispatch),
  getFilters: () =>
    dispatch(
      fetchFiltersToDrop({ setIsLoading, setError, setFilterOptions }, false, false, packContentTypeSelectSelector),
    ),
  resetAppliedFilter: bindActionCreators(resetAppliedFilterAction, dispatch),
  resetItems: bindActionCreators(resetItemsAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentFilterSidebar as (props: ContentFilterSidebarProps<PackContentFilters>) => React.ReactElement);
