import React from "react";
import isEmpty from "lodash/isEmpty";
import GroupOwnersList from "../../AddGroup/GroupOwners/GroupOwnerList/GroupOwnersList";
import { operators, formatODataFilterParams } from "../../../../../utils/filterUtils";
import { Filters } from "../../../../../utils/queryUtils";
import userListUtils from "../../../../../utils/userListUtils";
import { IGroupOwnerOptions } from "../../../types";
import { RouteNames, SortingDirection } from "../../../../../enums";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

import "./EditOwners.scss";

const filterParameters = [
  {
    key: "title",
    operator: operators.or,
  },
  {
    key: "department",
    operator: operators.or,
  },
];

export interface EditOwnersProps extends WithRouterProps {
  groupId: number;
  ownersOptions: IGroupOwnerOptions;
  loadOwners: (groupId: number, queryParams: object) => void;
  filterOptions: Filters;
  getFilterOptions: () => void;
  disableSelect?: () => boolean;
}

export interface EditOwnersState {
  selectedIds: number[];
  appliedOwnersFilter: Filters;
}

export class EditOwners extends React.Component<EditOwnersProps, EditOwnersState> {
  constructor(props: EditOwnersProps) {
    super(props);

    this.state = {
      selectedIds: [],
      appliedOwnersFilter: {},
    };
  }

  loadPage = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    filter: Filters,
  ): void => {
    const orderByParams = !isEmpty(sortingColumnName)
      ? userListUtils.formatODataOrderParams(sortingColumnName, sortingDirection)
      : null;
    const filterParams = formatODataFilterParams(filter, filterParameters);
    const { groupId, loadOwners } = this.props;
    const queryParams = { skip, top, count: true, orderBy: orderByParams, filter: filterParams };

    loadOwners(groupId, queryParams);
  };

  updateSelectedOwners = (selectedIds: number[]) => {
    this.setState({ selectedIds: selectedIds });
  };

  applyOwnersFilter = (filter: Filters): void => {
    this.setState({ appliedOwnersFilter: filter });
  };

  resetOwnersFilter = (): void => {
    this.setState({ appliedOwnersFilter: {} });
  };

  goToUserRoles = (userId: number): void => {
    this.props.navigate(`/${RouteNames.peopleUsers}/${userId}`);
  };

  render() {
    const { ownersOptions, filterOptions, getFilterOptions, disableSelect } = this.props;
    const { selectedIds, appliedOwnersFilter } = this.state;

    return (
      <section className="group-owners">
        <div className="stretch">
          <GroupOwnersList
            owners={ownersOptions.items}
            ownersCount={ownersOptions.itemsCount}
            isLoading={ownersOptions.isLoading}
            loadPage={this.loadPage}
            setOwnerFilter={this.applyOwnersFilter}
            resetOwnerFilter={this.resetOwnersFilter}
            appliedOwnerFilter={appliedOwnersFilter}
            selectedItemIds={selectedIds}
            updateSelectedItems={this.updateSelectedOwners}
            filterOptions={filterOptions}
            getFilterOptions={getFilterOptions}
            markAllAsOwners={true}
            onNavigate={this.goToUserRoles}
            disableSearch
            disableSelect={disableSelect}
          />
        </div>
      </section>
    );
  }
}

export default withRouter(EditOwners);
