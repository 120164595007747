import dataService from "features/Application/services/dataServices/dataService";
import DataService from "features/Application/services/dataServices/typedDataService";
import { profileLoaded } from "features/Application/slices/userProfileSlice";
import { AuthProvider, User } from "oidc-react";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LoggedInApp } from "./LoggedInApp";
import { useAlias } from "./useAlias";
import { accessTokenFactory, userManager } from "./userManager";
import { datadogRum } from "@datadog/browser-rum";

dataService.registerAccessTokenFactory(accessTokenFactory);
DataService.base.registerAccessTokenFactory(accessTokenFactory);

export const AppAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alias, aliasInitialized] = useAlias();

  const onSignIn = useCallback(
    (user: User | null) => {
      const redirectLocation = user?.state ?? "/";
      if (user) {
        const { sub: id, OriginAccountId: accountId, name: email } = user.profile;
        datadogRum.setUser({ id, accountId, email });
      }

      navigate(redirectLocation, { replace: true });
    },
    [navigate],
  );

  const onBeforeSignIn = useCallback(() => location, [location]);

  useEffect(() => {
    const dispatchProfileLoaded = (user: User) => {
      dispatch(profileLoaded(user.profile));
    };

    const redirectToSignIn = async () => {
      await userManager.removeUser();
      await userManager.signinRedirect({ state: location });
    };

    userManager.events.addUserLoaded(dispatchProfileLoaded);
    userManager.events.addUserSignedOut(redirectToSignIn);
    return () => {
      userManager.events.removeUserLoaded(dispatchProfileLoaded);
      userManager.events.removeUserSignedOut(redirectToSignIn);
    };
  }, [dispatch, location]);

  useEffect(() => {
    const dispatchInitialProfile = async () => {
      const user = await userManager.getUser();
      if (user && !user.expired) {
        dispatch(profileLoaded(user.profile));
      }
    };

    dispatchInitialProfile();
  }, [dispatch]);

  if (aliasInitialized) {
    const autoSignInArgs = alias ? { extraQueryParams: { alias } } : undefined;

    return (
      <AuthProvider
        userManager={userManager}
        // @ts-ignore
        onBeforeSignIn={onBeforeSignIn}
        onSignIn={onSignIn}
        autoSignInArgs={autoSignInArgs}
      >
        <LoggedInApp />
      </AuthProvider>
    );
  }

  return null;
};
