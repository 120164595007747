import React, { useCallback, useState } from "react";

import { AlignmentHelpers } from "../AlignmentHelpers/AlignmentHelpers";
import { AlignmentUpdateEvent, AlignmentUpdatePayload } from "../../ReactFlowCanvas/emitter/ReactFlowEmitter";
import { AlignedWith } from "../types";
import { IFlowNode } from "../../ReactFlowCanvas/nodes/types";
import { SECTION_HEADER_HEIGHT } from "../../constants";
import { applyNodeChanges, useReactFlow } from "reactflow";
import { useReactFlowActions } from "../../ReactFlowCanvas/Providers/ReactFlowStateProvider";

interface AxisHint {
  leftAligned: AlignedWith | null;
  middleVerticalAligned: AlignedWith | null;
  rightAligned: AlignedWith | null;
  topAligned: AlignedWith | null;
  middleHorizontalAligned: AlignedWith | null;
  bottomAligned: AlignedWith | null;
}

const defaultHelperState = {
  leftAligned: null,
  middleVerticalAligned: null,
  rightAligned: null,
  topAligned: null,
  middleHorizontalAligned: null,
  bottomAligned: null,
};

const getHelperState = (m: AlignmentUpdatePayload) => {
  return {
    leftAligned: m.dragFinished ? null : m.leftAligned,
    middleVerticalAligned: m.dragFinished ? null : m.middleVerticalAligned,
    topAligned: m.dragFinished ? null : m.topAligned,
    bottomAligned: m.dragFinished ? null : m.bottomAligned,
    middleHorizontalAligned: m.dragFinished ? null : m.middleHorizontalAligned,
    rightAligned: m.dragFinished ? null : m.rightAligned,
  };
};

const useGuidesCallback = (nodeId: string, setHelpersState: React.Dispatch<React.SetStateAction<AxisHint>>) => {
  const { setNodes } = useReactFlowActions();
  const onUpdatePos = useCallback(
    (nodeId: string, x: number, y: number) => {
      setNodes((nodes) => applyNodeChanges([{ id: nodeId, type: "position", position: { x, y } }], nodes));
    },
    [setNodes],
  );

  return useCallback(
    (m: AlignmentUpdateEvent) => {
      const payload = m.payload;
      setHelpersState(() => getHelperState(payload));

      if (!payload.dragFinished && payload.leftAligned?.diff) {
        onUpdatePos(nodeId, payload.position.x - payload.leftAligned?.diff, payload.position.y);
      }

      if (!payload.dragFinished && !payload.leftAligned && payload.rightAligned?.diff) {
        onUpdatePos(nodeId, payload.position.x - payload.rightAligned?.diff, payload.position.y);
      }

      if (!payload.dragFinished && payload.topAligned?.diff) {
        onUpdatePos(nodeId, payload.position.x, payload.position.y - payload.topAligned?.diff);
      }

      if (!payload.dragFinished && !payload.topAligned && payload.bottomAligned?.diff) {
        onUpdatePos(nodeId, payload.position.x, payload.position.y - payload.bottomAligned?.diff);
      }
    },
    [nodeId, setHelpersState, onUpdatePos],
  );
};

export const useGuidesOnNode = (nodeId: string) => {
  const [helpersState, setHelpersState] = useState<AxisHint>(defaultHelperState);
  const { getNode } = useReactFlow();
  const node: IFlowNode | undefined = getNode(nodeId);
  let position = node?.position;
  if (node?.position !== undefined && node?.data?.sectionHeader) {
    position = {
      x: node.position.x,
      y: node.position.y + SECTION_HEADER_HEIGHT,
    };
  }

  const cb = useGuidesCallback(nodeId, setHelpersState);

  return {
    cb,
    leftAligned: helpersState.leftAligned,
    rightAligned: helpersState.rightAligned,
    middleVerticalAligned: helpersState.middleVerticalAligned,
    topAligned: helpersState.topAligned,
    middleHorizontalAligned: helpersState.middleHorizontalAligned,
    bottomAligned: helpersState.bottomAligned,
    helpers: (
      <AlignmentHelpers
        leftAligned={helpersState.leftAligned}
        middleVerticalAligned={helpersState.middleVerticalAligned}
        rightAligned={helpersState.rightAligned}
        topAligned={helpersState.topAligned}
        middleHorizontalAligned={helpersState.middleHorizontalAligned}
        bottomAligned={helpersState.bottomAligned}
        nodePosition={position}
      />
    ),
  };
};
