import React from "react";
import { Button } from "components/buttons/button/Button";
import NoResults from "../../../../../components/noResults/NoResults";

const ContentAccessRestricted: React.FC = () => (
  <NoResults
    title="Access Restricted"
    description="You don’t have access to this area. Contact your administrator for more information."
    iconClassName="fal fas fa-lock-alt"
    outline={false}
    customStyles={{
      description: {
        color: "rgba(81, 81, 81, 0.75)",
        fontSize: "16px",
        maxWidth: "80%",
      },
    }}
  >
    <Button primary>Request Access</Button>
  </NoResults>
);

export default ContentAccessRestricted;
