import { UserSource } from "enums";
import { uniqBy } from "lodash";
import { FilterOptionsEnum } from "utils/enrollmentRulesUtils";

export enum TypeOptionsEnum {
  JobTitle = 0,
  Department = 1,
  CreatedDate = 2,
  Country = 3,
  OfficeLocation = 4,
  HiredDate = 5,
  Manager = 6,
  License = 7,
  Group = 8,
  Role = 9,
}

export const getDropdownOptions = (option: string, existing: { text: string; value: string }[]) => {
  let options = [...(existing || []), { text: option, value: option }];
  return uniqBy(options, "value");
};

const textFiltersOptions = [
  {
    value: FilterOptionsEnum.IsEqualTo,
    text: "Is Equal To",
  },
  {
    value: FilterOptionsEnum.IsNotEqualTo,
    text: "Is Not Equal To",
  },
];

const dateFiltersOptions = [
  {
    value: FilterOptionsEnum.IsBetween,
    text: "Is Between",
  },
  {
    value: FilterOptionsEnum.IsNotBetween,
    text: "Is Not Between",
  },
  {
    value: FilterOptionsEnum.IsOnOrAfter,
    text: "Is On Or After",
  },
  {
    value: FilterOptionsEnum.IsOnOrBefore,
    text: "Is On Or Before",
  },
  {
    value: FilterOptionsEnum.IsWithinLastDays,
    text: "Is Within (Days)",
  },
];

export const withinLastDaysOptions = [
  { key: "30", text: "30", value: "30" },
  { key: "60", text: "60", value: "60" },
  { key: "90", text: "90", value: "90" },
];

export const getFilterOptions = (type: number) => {
  switch (type) {
    case TypeOptionsEnum.Department:
    case TypeOptionsEnum.JobTitle:
    case TypeOptionsEnum.Country:
    case TypeOptionsEnum.OfficeLocation:
    case TypeOptionsEnum.Manager:
    case TypeOptionsEnum.License:
    case TypeOptionsEnum.Group:
    case TypeOptionsEnum.Role:
      return textFiltersOptions;
    case TypeOptionsEnum.CreatedDate:
    case TypeOptionsEnum.HiredDate:
      return dateFiltersOptions;
    default:
      return null;
  }
};

export const isMappedCriteriaOptions = (ruleType: TypeOptionsEnum) => {
  return [TypeOptionsEnum.License, TypeOptionsEnum.Group].includes(ruleType);
};

export const getFilterRuleOptions = (userSource: UserSource, isUsedCosmos: boolean, isLicenseGroupEnable: boolean) => {
  const options = isUsedCosmos ? ruleOptions.find((item) => item.key === userSource)?.options : rulesOptions;

  if (isLicenseGroupEnable && userSource === UserSource.MsGraph) {
    return options?.concat(licenseGroupRulesOptions);
  }

  return options;
};

export const getDataSourceOptions = (msGraphOptionsEnabled: boolean) => {
  const systemSource = {
    value: UserSource.None,
    text: "System",
  };
  return msGraphOptionsEnabled
    ? [{ value: UserSource.MsGraph, text: "Microsoft Graph" }, systemSource]
    : [systemSource];
};

const ruleOptions = [
  {
    key: UserSource.MsGraph,
    options: [
      {
        value: TypeOptionsEnum.Department,
        text: "Department",
      },
      {
        value: TypeOptionsEnum.JobTitle,
        text: "Job Title",
      },
      {
        value: TypeOptionsEnum.Country,
        text: "Country",
      },
      {
        value: TypeOptionsEnum.OfficeLocation,
        text: "Office Location",
      },
      {
        value: TypeOptionsEnum.Manager,
        text: "Reports To",
      },
      {
        value: TypeOptionsEnum.CreatedDate,
        text: "Created Date",
      },
      {
        value: TypeOptionsEnum.HiredDate,
        text: "Hired Date",
      },
    ],
  },
  {
    key: UserSource.None,
    options: [
      {
        value: TypeOptionsEnum.Department,
        text: "Department",
      },
      {
        value: TypeOptionsEnum.JobTitle,
        text: "Job Title",
      },
      {
        value: TypeOptionsEnum.Country,
        text: "Country",
      },
      {
        value: TypeOptionsEnum.CreatedDate,
        text: "Created Date",
      },
      {
        value: TypeOptionsEnum.Role,
        text: "System Role",
      },
    ],
  },
];

const rulesOptions = [
  {
    value: TypeOptionsEnum.Department,
    text: "Department",
  },
  {
    value: TypeOptionsEnum.JobTitle,
    text: "Job Title",
  },
  {
    value: TypeOptionsEnum.Country,
    text: "Country",
  },
  {
    value: TypeOptionsEnum.OfficeLocation,
    text: "Office Location",
  },
  {
    value: TypeOptionsEnum.Manager,
    text: "Reports To",
  },
  {
    value: TypeOptionsEnum.CreatedDate,
    text: "Created Date",
  },
  {
    value: TypeOptionsEnum.HiredDate,
    text: "Hired Date",
  },
];

const licenseGroupRulesOptions = [
  {
    value: TypeOptionsEnum.License,
    text: "License Type",
  },
  {
    value: TypeOptionsEnum.Group,
    text: "Group",
  },
];
