import { combineReducers } from "redux";

import assessmentEntityStateReducer from "./reducers/assessmentEntityStateReducer";
import { assessmentDetailsReducer } from "./reducers/assessmentDetailsReducer";
import { assessmentsOverviewReducer } from "./reducers/assessmentsOverviewReducer";
import { assessmentContentValidatorReducer } from "./reducers/assessmentContentValidatorReducer";

import assessmentUsersSlice from "./slices/assessmentUsersSlice";
import assessmentGroupsSlice from "./slices/assessmentGroupsSlice";
import assessmentFiltersSlice from "./slices/assessmentFiltersSlice";
import assessmentBaseSlice from "./slices/assessmentBaseSlice";
import assessmentPerformanceSlice from "./slices/assessmentPerformanceSlice";

const base = combineReducers({
  assessmentEntityStateReducer,
  assessmentDetailsReducer,
  assessmentContentValidatorReducer,
  tagsBase: assessmentBaseSlice,
  performance: assessmentPerformanceSlice,
});

const overview = combineReducers({
  assessmentsOverviewReducer,
  filters: assessmentFiltersSlice,
});

const edit = combineReducers({
  users: assessmentUsersSlice,
  groups: assessmentGroupsSlice,
});

const reducers = combineReducers({
  base,
  edit,
  overview,
});

export type AssessmentState = ReturnType<typeof reducers>;

export default reducers;
