import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { AppThunk } from "features/Application/globaltypes/redux";
import dataService from "features/Application/services/dataServices/dataService";
import usersDataService from "features/Application/services/dataServices/usersDataService";
import { getPrefix } from "features/Application/slices/models";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import { CountryModelItem, ICreateUserModel } from "features/People/types";
import { FetchActionPayload, UpdateParams } from "interfaces";

export const addManagedUser =
  (accountId: number, user: ICreateUserModel): AppThunk =>
  async (dispatch) => {
    try {
      const getMessageIds = (accountId: number, user: ICreateUserModel) => async () => {
        const response = await usersDataService.addManagedUser(accountId, user);
        return [response.data];
      };

      const updateParams: UpdateParams = {
        getMessageIds: getMessageIds(accountId, user),
        id: "AddManagedUser",
        title: `User creation`,
        successTransientMessage: `The user has been created successfully.`,
        failureTransientMessage: `User creation failed!`,
        successTitle: "User Created!",
      };

      await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
    } catch (e) {}
  };

export const fetchDepartments = createAsyncThunk<FetchActionPayload<string>>(
  getPrefix({
    entity: ReducerEntityPrefixTypes.EditAccount,
    name: "Departments",
    namespace: ReducerNamespaceTypes.Accounts,
  }),
  async () => {
    const result = await usersDataService.getDepartments();
    const departments = result.data;
    return {
      items: departments,
      totalCount: departments.length,
    };
  },
);

export const fetchJobTitles = createAsyncThunk<FetchActionPayload<string>>(
  getPrefix({
    entity: ReducerEntityPrefixTypes.EditAccount,
    name: "JobTitles",
    namespace: ReducerNamespaceTypes.Accounts,
  }),
  async () => {
    const result = await usersDataService.getJobTitles();
    const jobTitles = result.data;
    return {
      items: jobTitles,
      totalCount: jobTitles.length,
    };
  },
);

export const fetchCountries = createAsyncThunk<FetchActionPayload<CountryModelItem>>(
  getPrefix({
    entity: ReducerEntityPrefixTypes.EditAccount,
    name: "Countries",
    namespace: ReducerNamespaceTypes.Accounts,
  }),
  async () => {
    const result = await dataService.getCountries();
    const contries = result.data as CountryModelItem[];
    return {
      items: contries,
      totalCount: contries.length,
    };
  },
);
