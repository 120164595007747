import {
  AssessmentPeopleDetails,
  AssessmentQuestionPeopleResponse,
  CardsData,
  EngagementFunnelData,
  EngagementLineData,
  EngagementQuestionsData,
  QuestionResponsesData,
} from "./state";

export function getEntityStateActionsMock() {
  return {
    createAssessment: jest.fn(),
    assessmentUpdated: jest.fn(),
    resetAssessmentEntityState: jest.fn(),
    updateAssessment: jest.fn(),
    updateAssessmentTags: jest.fn(),
    publishDraftAssessmentEntity: jest.fn(),
    fetchDraftAssessmentEntity: jest.fn(),
    fetchDiscardAssessmentEntity: jest.fn(),
    fetchDiscardAssessmentSuccess: jest.fn(),
    setAssessmentEntityIdAction: jest.fn(),
    publishDraftAssessmentSuccess: jest.fn(),
    updateAssessmentCommandSuccess: jest.fn(),
    updateContent: jest.fn(),
    fetchLockedAssessmentSuccess: jest.fn(),
  } as any;
}

export function getDetailsActionsMock() {
  return {
    saveAssessment: jest.fn(),
    saveAssessmentTags: jest.fn(),
    clearAssessment: jest.fn(),
    saveContent: jest.fn(),
    getAssessment: jest.fn(),
    getAssessmentContent: jest.fn(),
  } as any;
}

export function getUsersFilterActionsMock() {
  return {
    getFilterOptions: jest.fn(),
    setUserFilter: jest.fn(),
    resetUserFilter: jest.fn(),
  } as any;
}

export const getContentValidationActionsMock = () =>
  ({
    setIsValidAction: jest.fn(),
    setErrorsResolvedAction: jest.fn(),
    setActiveErrorsAction: jest.fn(),
    setTouchedSnapshotAction: jest.fn(),
    setErrorModeAction: jest.fn(),
    setInitializedAction: jest.fn(),
    resetAction: jest.fn(),
    tryPublish: jest.fn(),
    setActiveErrors: jest.fn(),
    checkForResolvedErrors: jest.fn(),
  } as any);

export const assessmentLineData: EngagementLineData = [
  {
    Date: "2023-06-10",
    Completed: 7,
    CumulativeCompleted: 3,
    NotStarted: 48,
    CumulativeNotStarted: 67,
    Started: 68,
    CumulativeStarted: 78,
  },
  {
    Date: "2023-06-11",
    Completed: 23,
    CumulativeCompleted: 61,
    NotStarted: 60,
    CumulativeNotStarted: 85,
    Started: 38,
    CumulativeStarted: 24,
  },
  {
    Date: "2023-06-12",
    Completed: 3,
    CumulativeCompleted: 62,
    NotStarted: 26,
    CumulativeNotStarted: 40,
    Started: 65,
    CumulativeStarted: 1,
  },
  {
    Date: "2023-06-13",
    Completed: 91,
    CumulativeCompleted: 32,
    NotStarted: 61,
    CumulativeNotStarted: 13,
    Started: 38,
    CumulativeStarted: 46,
  },
  {
    Date: "2023-06-14",
    Completed: 34,
    CumulativeCompleted: 43,
    NotStarted: 51,
    CumulativeNotStarted: 97,
    Started: 67,
    CumulativeStarted: 74,
  },
  {
    Date: "2023-06-15",
    Completed: 67,
    CumulativeCompleted: 36,
    NotStarted: 52,
    CumulativeNotStarted: 92,
    Started: 34,
    CumulativeStarted: 34,
  },
  {
    Date: "2023-06-16",
    Completed: 18,
    CumulativeCompleted: 2,
    NotStarted: 3,
    CumulativeNotStarted: 44,
    Started: 97,
    CumulativeStarted: 17,
  },
  {
    Date: "2023-06-17",
    Completed: 58,
    CumulativeCompleted: 46,
    NotStarted: 4,
    CumulativeNotStarted: 26,
    Started: 78,
    CumulativeStarted: 97,
  },
  {
    Date: "2023-06-18",
    Completed: 21,
    CumulativeCompleted: 1,
    NotStarted: 22,
    CumulativeNotStarted: 23,
    Started: 38,
    CumulativeStarted: 50,
  },
  {
    Date: "2023-06-19",
    Completed: 56,
    CumulativeCompleted: 38,
    NotStarted: 44,
    CumulativeNotStarted: 70,
    Started: 99,
    CumulativeStarted: 16,
  },
  {
    Date: "2023-06-20",
    Completed: 22,
    CumulativeCompleted: 17,
    NotStarted: 21,
    CumulativeNotStarted: 72,
    Started: 89,
    CumulativeStarted: 24,
  },
  {
    Date: "2023-06-21",
    Completed: 74,
    CumulativeCompleted: 62,
    NotStarted: 80,
    CumulativeNotStarted: 14,
    Started: 94,
    CumulativeStarted: 10,
  },
  {
    Date: "2023-06-22",
    Completed: 73,
    CumulativeCompleted: 87,
    NotStarted: 20,
    CumulativeNotStarted: 92,
    Started: 24,
    CumulativeStarted: 61,
  },
  {
    Date: "2023-06-23",
    Completed: 47,
    CumulativeCompleted: 96,
    NotStarted: 20,
    CumulativeNotStarted: 25,
    Started: 38,
    CumulativeStarted: 81,
  },
  {
    Date: "2023-06-24",
    Completed: 38,
    CumulativeCompleted: 89,
    NotStarted: 58,
    CumulativeNotStarted: 11,
    Started: 67,
    CumulativeStarted: 64,
  },
  {
    Date: "2023-06-25",
    Completed: 48,
    CumulativeCompleted: 37,
    NotStarted: 30,
    CumulativeNotStarted: 48,
    Started: 52,
    CumulativeStarted: 74,
  },
  {
    Date: "2023-06-26",
    Completed: 32,
    CumulativeCompleted: 75,
    NotStarted: 76,
    CumulativeNotStarted: 25,
    Started: 88,
    CumulativeStarted: 24,
  },
  {
    Date: "2023-06-27",
    Completed: 67,
    CumulativeCompleted: 16,
    NotStarted: 75,
    CumulativeNotStarted: 78,
    Started: 35,
    CumulativeStarted: 22,
  },
  {
    Date: "2023-06-28",
    Completed: 15,
    CumulativeCompleted: 95,
    NotStarted: 88,
    CumulativeNotStarted: 8,
    Started: 14,
    CumulativeStarted: 7,
  },
  {
    Date: "2023-06-29",
    Completed: 40,
    CumulativeCompleted: 55,
    NotStarted: 20,
    CumulativeNotStarted: 110,
    Started: 35,
    CumulativeStarted: 30,
  },
  {
    Date: "2023-06-30",
    Completed: 45,
    CumulativeCompleted: 13,
    NotStarted: 38,
    CumulativeNotStarted: 52,
    Started: 8,
    CumulativeStarted: 47,
  },
  {
    Date: "2023-07-01",
    Completed: 96,
    CumulativeCompleted: 24,
    NotStarted: 68,
    CumulativeNotStarted: 48,
    Started: 61,
    CumulativeStarted: 75,
  },
  {
    Date: "2023-07-02",
    Completed: 33,
    CumulativeCompleted: 12,
    NotStarted: 81,
    CumulativeNotStarted: 67,
    Started: 5,
    CumulativeStarted: 58,
  },
  {
    Date: "2023-07-03",
    Completed: 45,
    CumulativeCompleted: 43,
    NotStarted: 19,
    CumulativeNotStarted: 100,
    Started: 76,
    CumulativeStarted: 35,
  },
  {
    Date: "2023-07-04",
    Completed: 12,
    CumulativeCompleted: 84,
    NotStarted: 18,
    CumulativeNotStarted: 54,
    Started: 68,
    CumulativeStarted: 97,
  },
  {
    Date: "2023-07-05",
    Completed: 61,
    CumulativeCompleted: 65,
    NotStarted: 37,
    CumulativeNotStarted: 72,
    Started: 80,
    CumulativeStarted: 19,
  },
  {
    Date: "2023-07-06",
    Completed: 4,
    CumulativeCompleted: 8,
    NotStarted: 9,
    CumulativeNotStarted: 13,
    Started: 25,
    CumulativeStarted: 51,
  },
  {
    Date: "2023-07-07",
    Completed: 72,
    CumulativeCompleted: 91,
    NotStarted: 79,
    CumulativeNotStarted: 65,
    Started: 82,
    CumulativeStarted: 43,
  },
  {
    Date: "2023-07-08",
    Completed: 1,
    CumulativeCompleted: 39,
    NotStarted: 6,
    CumulativeNotStarted: 46,
    Started: 85,
    CumulativeStarted: 45,
  },
  {
    Date: "2023-07-09",
    Completed: 14,
    CumulativeCompleted: 5,
    NotStarted: 84,
    CumulativeNotStarted: 59,
    Started: 15,
    CumulativeStarted: 59,
  },
  {
    Date: "2023-07-10",
    Completed: 4,
    CumulativeCompleted: 5,
    NotStarted: 85,
    CumulativeNotStarted: 38,
    Started: 7,
    CumulativeStarted: 22,
  },
];

export const AssessmentEngagementFunnelData: EngagementFunnelData = {
  AssessmentId: 2,
  AccountId: 2,
  NotStarted: 10,
  Completed: 20,
  Started: 30,
};

export const EmptyAssessmentEngagementFunnelData: EngagementFunnelData = {
  AssessmentId: -1,
  AccountId: -1,
  NotStarted: 0,
  Completed: 0,
  Started: 0,
};

export const AssessmentEngagementCardsData: CardsData = {
  AccountId: -1,
  AssessmentId: -1,
  AverageGrade: 10,
  AverageTime: 10,
  Completed: 10,
  CompletionRate: 0.3,
  PassingGrade: 10,
  Customers: 10,
};

export const EmptyAssessmentEngagementCardsData: CardsData = {
  AccountId: -1,
  AssessmentId: -1,
  AverageGrade: 0,
  AverageTime: 0,
  Completed: 0,
  CompletionRate: 0,
  PassingGrade: 0,
  Customers: 0,
};

export const AssessmentEngagementQuestionsDataMock: EngagementQuestionsData[] = [
  {
    Answers: [
      {
        AnswerId: "1000",
        AnswerTitle: "eget eleifend risus1",
        IsAnswerCorrect: true,
        ChoicePercent: 0.2,
      },
      {
        AnswerId: "1001",
        AnswerTitle: "Nullam et risus vel mi. Nullam et risus vel mi.",
        ChoicePercent: 0.3,
        IsAnswerCorrect: false,
      },
      {
        AnswerId: "1002",
        AnswerTitle: "Maecenas ut eleifend nulla. Proin.",
        ChoicePercent: 0.4,
        IsAnswerCorrect: false,
      },
      {
        AnswerId: "1003",
        AnswerTitle: "Aliquam id lorem quam. Proin.",
        ChoicePercent: 0.5,
        IsAnswerCorrect: false,
      },
    ],
    AvgAttempts: 2.33,
    IsRequired: true,
    QuestionId: "1",
    QuestionOrder: 0,
    QuestionText: "Phasellus euismod neque eget lorem?",
    QuestionType: "Multiple Select",
  },
  {
    Answers: [
      {
        AnswerId: "1004",
        AnswerTitle: " Praesent finibus vehicula velit ut placerat.",
        ChoicePercent: 100,
        IsAnswerCorrect: false,
      },
      {
        AnswerId: "1005",
        AnswerTitle: "Donec a sem at leo egestas luctus. Donec a sem at leo egestas luctus.",
        ChoicePercent: 10,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1006",
        AnswerTitle: "Aliquam erat volutpat. Aliquam erat volutpat.",
        ChoicePercent: 20,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1007",
        AnswerTitle: "Proin quis elit sem.",
        ChoicePercent: 40,
        IsAnswerCorrect: false,
      },
    ],
    Attempts: 101,
    AvgAttempts: 5,
    Completed: 4,
    CompletionRate: 10,
    IsRequired: false,
    QuestionId: "2",
    QuestionOrder: 0,
    QuestionText:
      "Curabitur viverra ligula vel nibh. Vestibulum quis est hendrerit, scelerisque ut eleifend nulla. Proin. Euismod neque eget lorem",

    QuestionType: "Multiple Select",
  },
  {
    Answers: [
      {
        AnswerId: "1008",
        AnswerTitle: "ut maecenas",
        ChoicePercent: 50,
        IsAnswerCorrect: false,
      },
      {
        AnswerId: "1009",
        AnswerTitle: "lectus Donec non",
        ChoicePercent: 45,
        IsAnswerCorrect: false,
      },
      {
        AnswerId: "1010",
        AnswerTitle: "Aliquam erat volutpat. Aliquam erat volutpat.",
        ChoicePercent: 20,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1011",
        AnswerTitle: "Proin quis elit sem.",
        ChoicePercent: 25,
        IsAnswerCorrect: true,
      },
    ],
    Attempts: 1010,
    AvgAttempts: 60.46,
    Completed: 45,
    CompletionRate: 70,
    IsRequired: true,
    QuestionId: "3",
    QuestionOrder: 0,
    QuestionText: "Donec non a?",
    QuestionType: "Multiple Select",
  },
  {
    Answers: [
      {
        AnswerId: "1012",
        AnswerTitle: "Morbi libero orci, accumsan quis lacus",
        ChoicePercent: 50,
        IsAnswerCorrect: true,
      },
    ],
    Attempts: 13451,
    AvgAttempts: 50,
    Completed: 8,
    CompletionRate: 15,
    IsRequired: false,
    QuestionId: "4",
    QuestionOrder: 0,
    QuestionText: "Donec in velit posuere orci elementum feugiat at ut dolor.",
    QuestionType: "Single Select",
  },
  {
    Answers: [
      {
        AnswerId: "1013",
        AnswerTitle: "Donec non placerat lectus. Donec non placerat lectus.",
        ChoicePercent: 15,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1014",
        AnswerTitle: "Donec ornare aliquam urna",
        ChoicePercent: 45,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1015",
        AnswerTitle: "Morbi congue est ut justo fermentum Morbi congue est ut justo fermentum",
        ChoicePercent: 40,
        IsAnswerCorrect: true,
      },
    ],
    Attempts: 13451,
    Completed: 8,
    CompletionRate: 25,
    IsRequired: false,
    QuestionId: "5",
    QuestionOrder: 0,
    QuestionText: "Mauris rhoncus mi ut massa",
    QuestionType: "Multiple Select",
  },
  {
    Answers: [
      {
        AnswerId: "1016",
        AnswerTitle: "eget eleifend risus2",
        ChoicePercent: 20,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1016578349",
        AnswerTitle: "Nullam et risus vel mi. Nullam et risus vel mi.",
        ChoicePercent: 50,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1017",
        AnswerTitle: "Maecenas ut eleifend nulla. Proin.",
        ChoicePercent: 18,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1018",
        AnswerTitle: "Aliquam id lorem quam. Proin.",
        ChoicePercent: 12,
        IsAnswerCorrect: false,
      },
    ],
    Attempts: 101,
    AvgAttempts: 45,
    Completed: 4,
    CompletionRate: 72,
    IsRequired: true,
    QuestionId: "6",
    QuestionOrder: 0,
    QuestionText: "Phasellus euismod neque eget lorem?",
    QuestionType: "Multiple Select",
  },
  {
    Answers: [
      {
        AnswerId: "1019",
        AnswerTitle: "Morbi libero orci, accumsan quis lacus",
        ChoicePercent: 100,
        IsAnswerCorrect: true,
      },
    ],
    Attempts: 101,
    AvgAttempts: 50,
    Completed: 4,
    CompletionRate: 100,
    IsRequired: true,
    QuestionId: "7",
    QuestionOrder: 0,
    QuestionText: "Donec in velit posuere orci elementum feugiat at ut dolor.",
    QuestionType: "Single Select",
  },
  {
    Answers: [
      {
        AnswerId: "1020",
        AnswerTitle: "libero",
        ChoicePercent: 50,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1021",
        AnswerTitle: "elementum",
        ChoicePercent: 50,
        IsAnswerCorrect: false,
      },
    ],
    Attempts: 101,
    AvgAttempts: 55,
    Completed: 4,
    CompletionRate: 16,
    IsRequired: true,
    QuestionId: "8",
    QuestionOrder: 0,
    QuestionText: "Morbi?",
    QuestionType: "Single Select",
  },
  {
    Answers: [
      {
        AnswerId: "1022",
        AnswerTitle: "Donec non placerat lectus. Donec non placerat lectus.",
        ChoicePercent: 15,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1023",
        AnswerTitle: "Donec ornare aliquam urna",
        ChoicePercent: 45,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1024",
        AnswerTitle: "Morbi congue est ut justo fermentum Morbi congue est ut justo fermentum",
        ChoicePercent: 40,
        IsAnswerCorrect: true,
      },
    ],
    Attempts: 101,
    AvgAttempts: 75,
    Completed: 4,
    CompletionRate: 34,
    IsRequired: true,
    QuestionId: "9",
    QuestionOrder: 0,
    QuestionText: "Mauris rhoncus mi ut massa",
    QuestionType: "Multiple Select",
  },
  {
    Answers: [
      {
        AnswerId: "1025",
        AnswerTitle: "eleifend eget risus",
        ChoicePercent: 20,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1026",
        AnswerTitle: "risus nullam et vel mi. Nullam et risus vel mi.",
        ChoicePercent: 50,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1027",
        AnswerTitle: "Nulla maecenas ut eleifend. Proin.",
        ChoicePercent: 18,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1028",
        AnswerTitle: ":orem aliquam id quam. Proin.",
        ChoicePercent: 12,
        IsAnswerCorrect: true,
      },
    ],
    Attempts: 101,
    AvgAttempts: 45,
    Completed: 4,
    CompletionRate: 23,
    IsRequired: true,
    QuestionId: "10",
    QuestionOrder: 0,
    QuestionText: "Euismod phasellus neque eget lorem?",
    QuestionType: "Multiple Select",
  },
  {
    Answers: [
      {
        AnswerId: "1029",
        AnswerTitle: "Maecenas ut eleifend nulla. Proin.",
        ChoicePercent: 30,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1030",
        AnswerTitle: "Donec non placerat lectus. Donec non placerat lectus.",
        ChoicePercent: 25,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1031",
        AnswerTitle: "Aliquam erat volutpat. Aliquam erat volutpat.",
        ChoicePercent: 20,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1032",
        AnswerTitle: "Proin quis elit sem.",
        ChoicePercent: 25,
        IsAnswerCorrect: true,
      },
    ],
    Attempts: 1010,
    AvgAttempts: 23.46,
    Completed: 45,
    CompletionRate: 98,
    IsRequired: true,
    QuestionId: "11",
    QuestionOrder: 0,
    QuestionText: "Donec non?",
    QuestionType: "Multiple Select",
  },
  {
    Answers: [
      {
        AnswerId: "1033",
        AnswerTitle: "Donec non placerat lectus. Donec non placerat lectus.",
        ChoicePercent: 15,
        IsAnswerCorrect: true,
      },
      {
        AnswerId: "1034",
        AnswerTitle: "Donec ornare aliquam urna",
        ChoicePercent: 45,
        IsAnswerCorrect: false,
      },
      {
        AnswerId: "1035",
        AnswerTitle: "Morbi congue est ut justo fermentum Morbi congue est ut justo fermentum",
        ChoicePercent: 40,
        IsAnswerCorrect: true,
      },
    ],
    Attempts: 101,
    AvgAttempts: 63,
    Completed: 4,
    CompletionRate: 39,
    IsRequired: true,
    QuestionId: "12",
    QuestionOrder: 0,
    QuestionText: "Mauris rhoncus mi ut massa",
    QuestionType: "Multiple Select",
  },
];

export const QuestionResponsesDataMock: QuestionResponsesData = {
  AssessmentId: 1,
  Attempts: 0,
  AvgAttempts: 4,
  CompletionRate: 6,
  CorrectAttempts: 2,
  IncorrectAttempts: 0,
  Answers: [
    {
      AnswerId: "4",
      IsAnswerCorrect: true,
      AnswerTitle: "Nullam et risus vel mi. Nullam et risus vel mi.",
      ChoicePercent: 20,
    },
    {
      AnswerId: "7",
      IsAnswerCorrect: false,
      AnswerTitle: "eget eleifend risus3",
      ChoicePercent: 80,
    },
  ],
};

export const EmptyQuestionResponsesDataMock: QuestionResponsesData = {
  AssessmentId: 1,
  Attempts: 0,
  Answers: [
    {
      AnswerId: "4",
      IsAnswerCorrect: true,
      AnswerTitle: "Nullam et risus vel mi. Nullam et risus vel mi.",
      ChoicePercent: 0,
    },
    {
      AnswerId: "7",
      IsAnswerCorrect: false,
      AnswerTitle: "eget eleifend risus",
      ChoicePercent: 0,
    },
  ],
};

export const QuestionResponsesPeopleMock: AssessmentQuestionPeopleResponse = {
  AnswerId: "a1",
  AssessmentId: 1,
  People: [
    {
      AccountId: 1,
      AccountName: "Some Account",
      Date: "2022-10-03T00:00:00.000",
      Department: "Some Department",
      Email: "anEmail@brainstorminc.com",
      FirstName: "Example",
      LastName: "User",
      UserId: 1,
    },
  ],
  QuestionId: "1",
};

export const AssessmentPeopleDetailsMock: AssessmentPeopleDetails[] = [
  {
    AccountId: 2,
    AssessmentId: 36966,
    AssessmentTitle: "Assessment show correct",
    CompletionDate: "2023-10-18T12:15:03.427",
    Department: "Vans",
    Email: "Christopher_Santoyo@vfc.com",
    FirstName: "Christopher",
    LastName: "Santoyo",
    Result: "Fail",
    Score: 0,
    StartDate: "2023-10-18T12:12:41.224",
    UserId: 27683,
    UserTitle: "Stockroom Supervisor",
    id: "276832023-10-18T12:15:03.427",
  },
  {
    AccountId: 2,
    AssessmentId: 36966,
    AssessmentTitle: "Assessment show correct",
    CompletionDate: "2023-10-18T12:15:03.427",
    Department: "2 Vans",
    Email: "2Christopher_Santoyo@vfc.com",
    FirstName: "2 Christopher",
    LastName: "2 Santoyo",
    Result: "Fail",
    Score: 50,
    StartDate: "2023-10-18T12:12:41.224",
    UserId: 276833,
    UserTitle: "2 Stockroom Supervisor",
    id: "2768332023-10-18T12:15:03.427",
  },
];
