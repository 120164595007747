import { Component } from "react";
import { flushSync } from "react-dom";
import { isEmpty, debounce } from "lodash";
import { batch, connect, ConnectedProps } from "react-redux";

import { FlowFilterForm } from "../../filterForms";
import PeopleFlowCard from "../cards/peopleFlowCard/PeopleFlowCard";
import ItemsView from "../../../views/ItemsView/ItemsView";
import FlowsNoResults from "../../../views/library/flows/flowsOverview/FlowsNoResults";
import RtnEventsEmitter from "../../../features/Application/services/realTimeNotification/rtnEventsEmitter";
import flowListUtils from "../../../utils/flowListUtils";
import SearchInput from "../../searchInput/SearchInput";
import { FlowsAssignmentListRow, FlowsViewItemModel } from "./FlowsAssignmentListRow/FlowsAssignmentListRow";
import { AppDispatch, RootState } from "../../../features/Application/globaltypes/redux";
import { Filters } from "../../../utils/queryUtils";
import { SortingDirection, ItemsTypes, ViewType, SortOptions, AddPeopleToContentTypes } from "../../../enums";
import { ItemsViewGrid, ItemsViewList } from "../../../features/Application/globaltypes/ItemsView";
import { IFlowAssignmentModelItem } from "../../../features/People/types";
import { defaultSortingColumn, sortOptionsListView, flowColumnOptionsListView } from "../ContentAssignmentConstants";
import { updateSelectedContentItems } from "../utils/contentAssignmentUtils";
import { PeopleType } from "../../../features/Library/PeopleAssignments/types";
import { CardsViewerItem } from "../../cardsViewer/types";
import {
  GroupFlowPriorityChangedSuccess,
  RemoveAllFlowGroupSuccess,
  RemoveAllFlowUserSuccess,
  UserFlowPriorityChangedSuccess,
  AddAllFlowUserSuccess,
  AddAllFlowGroupSuccess,
  SetAllFlowUserPrioritiesSuccess,
  SetAllFlowGroupPrioritySuccess,
  RemoveAllFlowUserPrioritiesSuccess,
  RemoveAllFlowGroupPrioritySuccess,
} from "../../../features/Application/services/realTimeNotification/events/library/libraryEvents";
import { bindAction } from "../../../interfaces";
import { fetchContentAssignmentsOverviewFilterOptions } from "../../../features/People/ContentAssignmentsOverview/thunks/contentAssignmentsOverviewFiltersThunk";
import {
  resetAppliedFilter,
  setAppliedFilter,
  setSearch,
  resetSearch,
} from "../../../features/People/ContentAssignmentsOverview/slices/contentAssignmentsOverviewFiltersSlice";
import { AssignmentListPropsBase, SelectedItem } from "../types";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import "./flowsAssignmentList.scss";
import { LDProps } from "../../../features/LDProps";

const START_POSITION = 0;
const usersRTNEvents = [
  AddAllFlowUserSuccess,
  SetAllFlowUserPrioritiesSuccess,
  RemoveAllFlowUserSuccess,
  UserFlowPriorityChangedSuccess,
  RemoveAllFlowUserPrioritiesSuccess,
];
const groupsRTNEvents = [
  AddAllFlowGroupSuccess,
  SetAllFlowGroupPrioritySuccess,
  RemoveAllFlowGroupSuccess,
  GroupFlowPriorityChangedSuccess,
  RemoveAllFlowGroupPrioritySuccess,
];
const takeDefaultCount = Number.parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);

export interface FlowsAssignmentListProps
  // NOSONAR TODO: would be nice to have uniform props for all assignment lists
  extends Omit<AssignmentListPropsBase, "selectedIds" | "onSelectedChanged" | "resetList"> {
  fetchFlowsList: (skip?: number, top?: number, orderBy?: string, filters?: Filters, term?: string) => void;
  fetchFlowsGrid: (skip?: number, top?: number, orderBy?: string, filters?: Filters, term?: string) => void;
  gridState: ItemsViewGrid<IFlowAssignmentModelItem>;
  listState: ItemsViewList<IFlowAssignmentModelItem>;
  resetFlowsGrid: () => void;
  resetFlowsList: () => void;
  onSelectedFlowsChanged: (selectedItems: SelectedItem[]) => void;
  selectedFlows: number[];
  contextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (ids: number[]) => void;
  };
  hidePriorityColumn?: boolean;
  showExpirationDate?: boolean;
  deepLink?: boolean;
}

export interface FlowsAssignmentListState {
  selectedViewType: ViewType;
  orderBy: string;
}

export type FlowsAssignmentAllProps = FlowsAssignmentListProps & PropsFromRedux & LDProps;

export class FlowsAssignmentList extends Component<FlowsAssignmentAllProps, FlowsAssignmentListState> {
  reloadListItems?: (condition: boolean) => void;

  constructor(props: FlowsAssignmentAllProps) {
    super(props);

    this.state = {
      selectedViewType: ViewType.GRID,
      orderBy: SortOptions.ModifiedDateDesc,
    };
  }

  getRefreshRtnEvents = (peopleType: PeopleType) => {
    return peopleType === PeopleType.User ? usersRTNEvents : groupsRTNEvents;
  };

  componentDidMount() {
    RtnEventsEmitter.subscribe(this.getRefreshRtnEvents(this.props.peopleType), this.refreshOnChange);
  }

  componentDidUpdate(prevProps: Readonly<FlowsAssignmentAllProps>): void {
    if (this.props.peopleType !== prevProps.peopleType) {
      RtnEventsEmitter.unsubscribe(this.getRefreshRtnEvents(prevProps.peopleType), this.refreshOnChange);
      RtnEventsEmitter.subscribe(this.getRefreshRtnEvents(this.props.peopleType), this.refreshOnChange);
    }
  }

  componentWillUnmount() {
    batch(() => {
      this.props.resetFlowsGrid();
      this.props.resetFlowsList();
      this.props.resetFilter();
      this.props.resetSearch();
    });
    RtnEventsEmitter.unsubscribe([...usersRTNEvents, ...groupsRTNEvents], this.refreshOnChange);
  }

  onSortChange = (_: any, data: any) => {
    this.props.resetFlowsGrid();
    this.setState({ orderBy: data.value ?? SortOptions.ModifiedDateDesc }, this.getFlows);
  };

  refreshGridItems = () => {
    const { resetFlowsGrid } = this.props;
    const { selectedViewType } = this.state;
    // list view is refreshed by passing listViewRtnEvents prop
    if (selectedViewType === ViewType.GRID) {
      resetFlowsGrid();
      this.getFlows(START_POSITION);
    }
  };

  refreshOnChange = debounce(this.refreshGridItems, 1000);

  onViewTypeChange = (viewType: ViewType) => {
    const { onViewTypeChange } = this.props;
    this.setState(
      {
        selectedViewType: viewType,
        orderBy: this.props.search ? "" : SortOptions.ModifiedDateDesc,
      },
      () => {
        this.props.resetFlowsGrid();
        if (viewType === ViewType.GRID) {
          this.props.resetFlowsList();
          this.getFlows();
        }
      },
    );
    onViewTypeChange && onViewTypeChange(viewType);
  };

  getFlows = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
  ) => {
    const { appliedFilter, fetchFlowsList, fetchFlowsGrid, gridState, accessRestricted, search } = this.props;
    const { selectedViewType, orderBy } = this.state;
    if (accessRestricted) {
      return;
    }

    if (selectedViewType === ViewType.GRID) {
      fetchFlowsGrid(skip ?? gridState.items.length, takeDefaultCount, orderBy, filters ?? appliedFilter, search);
    } else {
      const orderParams = flowListUtils.formatOrderParams(sortingColumnName, sortingDirection);

      fetchFlowsList(skip, top, orderParams, filters ?? appliedFilter, search);
    }
  };

  // @ts-ignore
  getFilterForm = () => <FlowFilterForm />;

  applyFilter = (filter: Filters) => {
    const { onSelectedFlowsChanged, resetFlowsGrid } = this.props;

    this.props.applyFilter(filter);

    onSelectedFlowsChanged([]);
    if (this.state.selectedViewType === ViewType.GRID) {
      resetFlowsGrid();
      this.getFlows(START_POSITION, undefined, undefined, undefined, filter);
    }
  };

  resetFilter = () => {
    this.props.resetFilter();

    if (this.state.selectedViewType === ViewType.GRID) {
      this.props.resetFlowsGrid();
      this.getFlows(START_POSITION, undefined, undefined, undefined, {});
    }
  };

  onSelectedFlowsGridChanged = (ids: number[]) => {
    this.updateSelectedItems(this.props.gridState.items, ids);
  };

  onSelectedAssetsListChanged = (ids: number[]) => {
    this.updateSelectedItems(this.props.listState.items, ids);
  };

  updateSelectedItems = (collection: IFlowAssignmentModelItem[], ids: number[]) => {
    const { onSelectedFlowsChanged } = this.props;

    const updatedSelectedFlows = updateSelectedContentItems(collection, ids);
    onSelectedFlowsChanged && onSelectedFlowsChanged(updatedSelectedFlows);
  };

  onSearchChange = (newSearchText: string) => {
    const isEmptySearchText = isEmpty(newSearchText);
    this.onSelectedFlowsGridChanged([]);
    // Provide time for redux to update
    flushSync(() => {
      this.props.setSearch(newSearchText);
    });
    if (isEmptySearchText) {
      this.setState({ orderBy: SortOptions.ModifiedDateDesc });
    } else {
      this.setState({ orderBy: "" });
    }
    this.reloadListItems?.(isEmpty(newSearchText));
    this.refreshGridItems();
  };

  renderSearchInput = (accessRestricted: boolean) => (
    <SearchInput
      placeholder="Search for Flows..."
      disabled={accessRestricted}
      onChange={this.onSearchChange}
      defaultValue={this.props.search}
    />
  );

  isDisabled = (flow: FlowsViewItemModel) => this.props.isReadOnly || flow.inherited;

  createReloadListItems = (reloadListItems: (condition: boolean) => void) => {
    this.reloadListItems = reloadListItems;
  };

  getTableOptions = () => {
    const { contextMenuButtonHandlers, selectedFlows, hidePriorityColumn, isReadOnly, peopleType, deepLink } =
      this.props;

    const columnOptions = flowColumnOptionsListView({
      isPriorityHide: hidePriorityColumn,
      isExpiresHide: true,
      isAddedHide: true,
    });

    return {
      columns: columnOptions,
      buildTableBody: (flow: FlowsViewItemModel) => (
        <FlowsAssignmentListRow
          flow={flow}
          peopleType={peopleType}
          columnOptions={columnOptions}
          contextMenuButtonHandlers={contextMenuButtonHandlers}
          isSelected={selectedFlows.includes(flow.id)}
          isReadOnly={isReadOnly}
          deepLink={deepLink}
        />
      ),
    };
  };

  getFilterOptions = () => {
    this.props.getFilterOptions(AddPeopleToContentTypes.Flows);
  };

  render() {
    const {
      gridState,
      listState,
      appliedFilter,
      filterOptions,
      selectedFlows,
      customHeaderContent,
      createButton,
      contextMenuButtonHandlers,
      accessRestricted,
      isReadOnly,
      peopleType,
      isFilterLoading,
      disablePopupMenu,
    } = this.props;

    const viewState = this.state.selectedViewType === ViewType.GRID ? gridState : listState;
    const tableOptions = this.getTableOptions();

    return (
      <ItemsView
        className="create-group-flows"
        viewType={this.state.selectedViewType}
        columnOptions={tableOptions.columns}
        noResultsContent={
          <FlowsNoResults byCriteria={!isEmpty(appliedFilter) || !!this.props.search} createFlowButton={createButton} />
        }
        sortOptions={sortOptionsListView}
        blur
        onSortChange={this.onSortChange}
        setReloadListItems={this.createReloadListItems}
        getData={this.getFlows}
        itemsInlineCount={viewState.itemsCount}
        isLoading={viewState.isLoading}
        items={viewState.items.map((item) => ({ ...item, isEditable: !isReadOnly }))}
        itemsType={ItemsTypes.Flow}
        buildTableBody={tableOptions.buildTableBody}
        orderBy={this.state.orderBy}
        renderFilterForm={this.getFilterForm}
        filterOptions={filterOptions}
        resetFilter={this.resetFilter}
        appliedFilter={appliedFilter}
        filterOptionsLoading={isFilterLoading}
        applyFilter={this.applyFilter}
        getFilterOptions={this.getFilterOptions}
        onSelectedListItemsChanged={this.onSelectedAssetsListChanged}
        onSelectedItemsChanged={this.onSelectedFlowsGridChanged}
        renderCard={(props: CardsViewerItem<IFlowAssignmentModelItem>) => (
          <PeopleFlowCard
            {...props}
            peopleType={this.props.peopleType}
            onRemove={(id) => contextMenuButtonHandlers?.onRemove([id])}
            onEditPriority={contextMenuButtonHandlers?.onEditPriority}
            disablePopupMenu={disablePopupMenu}
            deepLink={this.props.deepLink}
          />
        )}
        selectedIds={selectedFlows}
        isAllDataLoaded={gridState.isAllLoaded}
        onViewTypeChange={this.onViewTypeChange}
        sortingDirection={this.props.search ? "" : SortingDirection.Descending}
        sortingColumnName={this.props.search ? "" : defaultSortingColumn}
        onLoad={this.state.selectedViewType === ViewType.GRID ? this.getFlows : null}
        tabAlias="flows"
        customHeaderContent={customHeaderContent}
        isSelectDisabled={this.isDisabled}
        renderSearch={this.renderSearchInput}
        accessRestricted={accessRestricted}
        listViewRtnEvents={this.getRefreshRtnEvents(peopleType)}
      />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    gridState: state.people.contentAssignment.flowGrid,
    listState: state.people.contentAssignment.flowList,
    search: state.people.contentAssignmentsOverview.filters.search,
    filterOptions: state.people.contentAssignmentsOverview.filters.filterOptions,
    isFilterLoading: state.people.contentAssignmentsOverview.filters.isLoading,
    appliedFilter: state.people.contentAssignmentsOverview.filters.appliedFilter,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getFilterOptions: bindAction(fetchContentAssignmentsOverviewFilterOptions, dispatch),
    setSearch: bindAction(setSearch, dispatch),
    resetSearch: bindAction(resetSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetFilter: bindAction(resetAppliedFilter, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withLDConsumer()(FlowsAssignmentList));
