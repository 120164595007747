import { PackCombination } from "../../../../../interfaces/licenseConfirmation";
import { groupBy } from "lodash";
import { FlowAssignment } from "../slices/packsContextSlice";

interface PotentialFlowConflict {
  id: number;
  packCombinations: PackCombination[];
}

type RealFlowConflict = PotentialFlowConflict;

export class PotentialFlowConflictCollection {
  constructor(private list: PotentialFlowConflict[]) {}

  private mergeCombinations(combinations: PackCombination[]) {
    const ids = combinations.map((combination) => combination.id) as number[];
    const name = combinations.map((combination) => combination.name).join(", ");
    return {
      ids: ids,
      name,
    };
  }

  public toRealConflicts(): [RealFlowConflict[], FlowAssignment[]] {
    const conflicts: RealFlowConflict[] = [];
    const assignments: FlowAssignment[] = [];
    const groupedConflicts = groupBy(this.list, "id");

    Object.keys(groupedConflicts).forEach((key) => {
      const potentialConflicts = groupedConflicts[key];

      if (potentialConflicts.length === 1) {
        const combination = this.mergeCombinations(potentialConflicts[0].packCombinations);

        assignments.push({
          flowId: potentialConflicts[0].id,
          packIds: combination.ids,
        });
      } else {
        const combinations = potentialConflicts.map((item) => this.mergeCombinations(item.packCombinations));

        conflicts.push({
          id: parseInt(key),
          packCombinations: combinations.map(({ ids, name }) => ({ id: ids.join(":"), name })),
        });
      }
    });

    return [conflicts, assignments];
  }
}
