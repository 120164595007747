import { useEffect } from "react";
import { useDrag } from "react-dnd";
import { Ref } from "semantic-ui-react";
import { getEmptyImage } from "react-dnd-html5-backend";

import DragNDropItemTypes from "../../../../../../enums/dragNDropItemTypes";
import FlowEndCard from "../../../../../../components/cards/FlowEndCard/FlowEndCard";
import { EntityType } from "../../types";

export interface DraggableFlowEndCardProps {
  id: string;
  title: string;
}

function DraggableFlowEndCard({ id, title }: DraggableFlowEndCardProps) {
  const [, drag, preview] = useDrag({
    type: DragNDropItemTypes.DESIGNER,
    item: {
      id: id,
      type: DragNDropItemTypes.DESIGNER,
      elementType: EntityType.FlowEnd,
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <Ref innerRef={drag}>
      <FlowEndCard id={id} title={title} />
    </Ref>
  );
}

export default DraggableFlowEndCard;
