import { FC } from "react";
import { Button } from "components/buttons/button/Button";

interface Props {
  onSubmit: () => void;
  onCancel: () => void;
  isValid: boolean;
  submitText?: string;
}

export const RequestPackModalActions: FC<Props> = ({ onSubmit, onCancel, isValid, submitText = "Purchase Now" }) => {
  return (
    <>
      <Button basic color="blue" className="cancel" content="Cancel" onClick={onCancel} />
      <Button
        primary
        className="submit"
        content={submitText}
        data-testid="submit-button"
        disabled={!isValid}
        onClick={onSubmit}
      />
    </>
  );
};

export default RequestPackModalActions;
