import { intersection } from "lodash";
import { AddPeopleToContentTypes, RolePermissions } from "../../../../enums";

export const detectHasAnyPermissions = (userPermissions: RolePermissions[], contentType: AddPeopleToContentTypes) => {
  const permissions = {
    [AddPeopleToContentTypes.Flows]: [RolePermissions.FlowsView],
    [AddPeopleToContentTypes.Videos]: [RolePermissions.AssetsView],
    [AddPeopleToContentTypes.Events]: [RolePermissions.EventsView],
    [AddPeopleToContentTypes.Surveys]: [RolePermissions.AssetsView],
    [AddPeopleToContentTypes.Assessments]: [RolePermissions.AssetsView],
    [AddPeopleToContentTypes.PDFs]: [RolePermissions.AssetsView],
  }[contentType];

  return intersection(userPermissions, permissions).length > 0;
};
