import ColumnOptions from "../../../../../interfaces/columnOptions";

export const columnsMap: { [key: string]: string } = {
  "pack name": "title",
  "license count": "licensesCount",
  "issued date": "issuedDate",
  "expiration date": "expirationDate",
  "date added": "dateAdded",
};

const columnOptions: ColumnOptions[] = [
  {
    name: "Pack Name",
    width: 5,
    isSortable: true,
    className: "pack-name-header",
  },
  {
    name: "License Count",
    width: 3,
    isSortable: true,
  },
  {
    name: "Issued Date",
    width: 3,
    isSortable: true,
  },
  {
    name: "Expiration Date",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    className: "align-right",
    isSortable: false,
  },
];

export default columnOptions;
