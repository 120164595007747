import { FC } from "react";
import { Checkbox, CheckboxProps, Icon } from "semantic-ui-react";
import { Tooltip } from "components/common/tooltip";

import styles from "./packVisibilityToggleButton.module.scss";

export interface PackVisibilityToggleButtonProps {
  checked?: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  disabled: boolean;
}

export const PackVisibilityToggleButton: FC<PackVisibilityToggleButtonProps> = ({ checked, onChange, disabled }) => {
  return (
    <div className={styles.root}>
      <Checkbox
        label="Publicly visible in the Marketplace"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        toggle
      />
      <Tooltip
        className={styles.tooltip}
        target={<Icon className="info circle" />}
        content="Enable this option to make your pack public in the Marketplace. You can then select which accounts and account types can access it. Turning it off makes the pack private, visible only within your account."
        styles={{ width: "20rem" }}
        hideOnScroll
        showAlways
      />
    </div>
  );
};
