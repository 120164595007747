import * as events from "../events/library/libraryEvents";
import {
  updateSurveyCommandSuccess,
  surveyUpdated,
  publishDraftSuccessAction,
  fetchDiscardSuccessAction,
  fetchDraftSuccessAction,
} from "../../../../Library/Surveys/state/actions/surveyEntityStateActions";
import { IMapping } from "./types";
import { Dispatcher, MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";
import {
  updateSucceededTaskDistributedOperation,
  updateFailedTaskDistributedOperation,
  updateProgressTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";

export const surveysMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const {
    dispatch,
    dispatchMap,
    dispatchPublishSuccess,
    dispatchBatchOperationSuccess,
    dispatchBatchOperationFailure,
  } = createDispatcher(dispatcher);

  const handleSurveyUpdated = dispatch(surveyUpdated);

  mapping[events.SurveyUpdateSuccess] = handleSurveyUpdated;
  mapping[events.SurveyQuestionsUpdateSuccess] = handleSurveyUpdated;
  mapping[events.SurveyUpdateCommandCompleted] = dispatch(updateSurveyCommandSuccess);

  mapping[events.SurveyPublishSuccess] = dispatchPublishSuccess<{ id: number; name: string }>(
    publishDraftSuccessAction,
    (data) => `${data.name} was published.`,
  );

  mapping[events.SurveyDiscardSuccess] = dispatch(fetchDiscardSuccessAction);

  mapping[events.SurveyAddTagsSuccess] = dispatchBatchOperationSuccess;

  mapping[events.SurveyAddTagsFailure] = dispatchBatchOperationFailure;

  mapping[events.SurveyEditSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchDraftSuccessAction,
    (message) => ({ entityId: message.id }),
  );

  [events.SurveyAllDeleteSuccess, events.SurveyAllDuplicationSuccess].forEach(
    (eventName) =>
      (mapping[eventName] = (payload) => dispatcher(updateSucceededTaskDistributedOperation(payload.operationId))),
  );

  [events.SurveyAllDeleteFailure, events.SurveyAllDuplicationFailure].forEach(
    (eventName) =>
      (mapping[eventName] = (payload) => dispatcher(updateFailedTaskDistributedOperation(payload.operationId))),
  );

  [events.SurveyDeleteSuccess, events.SurveyDuplicationSuccess].forEach(
    (eventName) =>
      (mapping[eventName] = (payload) =>
        dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId))),
  );
};
