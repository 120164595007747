import { Button } from "components/buttons/button/Button";
import { useNavigate } from "react-router-dom";

import { RolePermissions } from "../../../../../enums";
import { PaywallModalTypes } from "../../../../../components/restrictedByAddOn/paywallModal/types";

import RestrictedByAddOn from "../../../../../components/restrictedByAddOn/RestrictedByAddOn";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";

export const CreateVideoButton = () => {
  const navigate = useNavigate();

  const onCreateBtnClick = () => {
    navigate("createAsset");
  };

  const createVideoButton = (action: () => void, hasAnyPermission: boolean): React.ReactElement => (
    <RestrictedByTooltip hasPermission={hasAnyPermission}>
      <Button primary className="create-asset create-button" onClick={action} disabled={!hasAnyPermission}>
        Create Video
      </Button>
    </RestrictedByTooltip>
  );

  return (
    <RestrictedByAddOn
      permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate]}
      addOnPermissions={[RolePermissions.AssetsCreate]}
      modalType={PaywallModalTypes.UpgradeToCreateContent}
      action={onCreateBtnClick}
      renderContent={createVideoButton}
    />
  );
};
