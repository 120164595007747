import React from "react";
import { Icon } from "semantic-ui-react";
import cn from "classnames";
import "./actualError.scss";

interface Props {
  title: string;
  message: string;
  selected: boolean;
  onClick: () => void;
}

export const ActualError: React.FC<Props> = ({ title, message, selected, onClick }) => {
  return (
    <div
      data-testid="flow-validator-actual-error-item"
      className={cn("actual-flow-designer-errors-item", { selected })}
      onClick={onClick}
    >
      <div className="designer-errors-item-icon">
        <Icon className="exclamation triangle outline error-icon" size="big" color="red" />
      </div>
      <div>
        <div className="designer-errors-item-title">{title}</div>
        {message}
      </div>
    </div>
  );
};
