import React, { useEffect, useState } from "react";
import { useReactFlowCanvasState } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import { ReplacementOverlay } from "../ReplacementOverlay/ReplacementOverlay";

export const useReplacementOnNode = (nodeId: string) => {
  const [show, toggle] = useState(false);
  const { itemToReplace } = useReactFlowCanvasState();
  useEffect(() => {
    if (itemToReplace && itemToReplace.id === nodeId) {
      toggle(true);
    } else {
      toggle(false);
    }
  }, [itemToReplace, nodeId]);
  return { replacementView: show && <ReplacementOverlay /> };
};
