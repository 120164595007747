import { bindActionCreators } from "@reduxjs/toolkit";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FC, useEffect, useMemo, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
  ChartWrapper, HorizontalBarChart,
  LineChart,
  TickValueType,
  getDateFormatByCount
} from "../../../../../../../components/charts";
import { RequestStatusRenderer } from "../../../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";
import {
  PerformanceFilter, lineChartMargins,
  dailyActivity,
  emailReportColorScale,
  getBarDomain, lineChartTitles,
  noBarData,
  noData,
  totalActivity,
  transitionTime,
  validLineData
} from "../../../../../Common/utils/performanceUtils";
import {
  selectSendDetailsBarData,
  selectSendDetailsData,
  selectSendDetailsLineData,
  selectSendDetailsState,
} from "../../../state/slices/emailPerformanceSlice";
import * as emailPerformanceActions from "../../../state/thunks/emailPerformanceThunk";
import { EmailSend } from "../EmailSend/EmailSend";

import "../../../../../Common/utils/performanceSCSSUtils.scss";
import "../emailPerformance.scss";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { RStatus } from "features/Application/globaltypes/fetchRequest";

export interface StateProps extends PropsFromRedux {
  emailId: number;
  sendSessionId: number;
  flowId?: number;
  dateFilter: PerformanceFilter;
}

export interface OwnProps {
  dateRange: Date[];
}

export type Props = StateProps & OwnProps;

export const EmailPerformanceDrilldown: FC<Props> = ({
  emailId,
  sendSessionId,
  flowId,
  dateFilter,
  sendDetailsState,
  sendDetailsData,
  sendDetailsLineData,
  sendDetailsBarData,
  setHasExportData,
  actions,
}) => {
  const [newLineChartState, setNewLineChartState] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  useEffect(() => {
    setHasExportData(sendDetailsState.status === RStatus.Got && !!sendDetailsData.length);
  }, [sendDetailsData, setHasExportData, sendDetailsState.status]);

  useEffect(() => {
    if (reportEnabled) {
      actions.fetchEmailSendDetails(emailId, sendSessionId, { ...dateFilter, flowId });
    }
  }, [actions, emailId, dateFilter, flowId, sendSessionId, reportEnabled]);

  const lineData = useMemo(() => {
    const xData = [sendDetailsLineData.Date, sendDetailsLineData.Date, sendDetailsLineData.Date];
    if (newLineChartState === dailyActivity) {
      return {
        xData,
        yData: [sendDetailsLineData.Sends, sendDetailsLineData.Opens, sendDetailsLineData.Clicks],
      };
    } else {
      return {
        xData,
        yData: [
          sendDetailsLineData.CumulativeSends,
          sendDetailsLineData.CumulativeOpens,
          sendDetailsLineData.CumulativeClicks,
        ],
      };
    }
  }, [newLineChartState, sendDetailsLineData]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="performanceBody">
      <div className="graphs">
        <div className="lineChartContainer">
          <ChartWrapper
            titles={lineChartTitles}
            showLegend
            legendLabels={["Sends", "Opens", "Clicks"]}
            onChange={setNewLineChartState}
            colorRange={emailReportColorScale}
          >
            <RequestStatusRenderer state={sendDetailsState}>
              {validLineData([sendDetailsLineData.Sends, sendDetailsLineData.Opens, sendDetailsLineData.Clicks]) ? (
                <LineChart
                  margins={lineChartMargins}
                  xScaleRatio={timeScale}
                  xFormatterFunc={getDateFormatByCount(sendDetailsLineData.Date.length)}
                  {...lineData}
                  yTickValueType={TickValueType.IntegersOnly}
                  transitionDuration={transitionTime}
                  colorRange={emailReportColorScale}
                />
              ) : (
                noData(dateFilter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div className="funnelChartContainer funnel-chart-container">
          <ChartWrapper
            titles={["Engagement"]}
            showLegend
            legendLabels={["Sends", "Opens", "Clicks"]}
            colorRange={emailReportColorScale}
          >
            <RequestStatusRenderer state={sendDetailsState}>
              {!noBarData(...sendDetailsBarData.map((d) => d.value)) ? (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  data={sendDetailsBarData}
                  domain={getBarDomain(sendDetailsBarData)}
                />
              ) : (
                noData(dateFilter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
      <div>
        <EmailSend loading={sendDetailsState} filter={dateFilter} sendDetailEntities={sendDetailsData} />
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  return {
    sendDetailsState: selectSendDetailsState(state),
    sendDetailsData: selectSendDetailsData(state),
    sendDetailsLineData: selectSendDetailsLineData(state, ownProps),
    sendDetailsBarData: selectSendDetailsBarData(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setHasExportData: bindAction(setHasDataToExport, dispatch),
    actions: bindActionCreators(emailPerformanceActions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmailPerformanceDrilldown);
