import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

export interface Props {
  onClick: () => void;
  isDisabled?: boolean;
}

const RemoveLinkButton: React.FC<Props> = (props) => {
  const { onClick, isDisabled = false } = props;
  return (
    <BasicLinkButton
      text="Archive"
      iconClassName="far fa-archive"
      onClick={onClick}
      isDisabled={isDisabled}
      className="archive-button"
    />
  );
};

export default RemoveLinkButton;
