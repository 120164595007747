import { connect } from "react-redux";

import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { bindAction } from "../../../../../interfaces/redux";
import { addTagsToPdfs, fetchPdfTags as fetchPdfTagsAction } from "../../state/thunks/pdfBaseThunk";
import { pdfsTagsSelector } from "../../selectors";
import TagsManage from "../../../Common/TagsManage/TagsManage";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  tags: pdfsTagsSelector(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchPdfTagsAction, dispatch),
  onConfirmAction: bindAction(addTagsToPdfs, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsManage);
