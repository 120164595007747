import { EventPayload, EventView, SessionsView, SettingsView } from "../../types/state";
import * as actionTypes from "../actionTypes/eventDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { Dispatcher } from "../../../../../interfaces/redux";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { setEventEntityIdAction } from "./eventEntityStateActions";

export const saveEvent = (eventData: EventView) => {
  const saveEventAction = getActionProvider<EventView>(actionTypes.saveEvent);

  return (dispatch: Dispatcher) => {
    dispatch(saveEventAction(eventData));
  };
};

export const saveSessions = (contentMetadata: SessionsView) => {
  const saveContentAction = getActionProvider<SessionsView>(actionTypes.saveSessions);

  return (dispatch: Dispatcher) => {
    dispatch(saveContentAction(contentMetadata));
  };
};

export const saveSettingsAction = getActionProvider<SettingsView>(actionTypes.saveSettings);

export const clearEvent = () => {
  const clearEventAction = getActionBaseProvider(actionTypes.clearEvent);

  return (dispatch: Dispatcher) => {
    dispatch(clearEventAction());
  };
};

export const getEvent = (eventId: number) => {
  const begin = getActionBaseProvider(actionTypes.getEventBegin);
  const success = getActionProvider<EventPayload>(actionTypes.getEventSuccess);
  const failure = getActionProvider<Error>(actionTypes.getEventFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const result = await dataService.events.getEventAsync(eventId);
      dispatch(success(result.data));
      dispatch(setEventEntityIdAction(eventId));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
