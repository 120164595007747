import { FC } from "react";
import { useSelector } from "react-redux";
import { Table } from "semantic-ui-react";

import { GroupEllipsisPopupButton } from "../../people";
import { RolePermissions } from "../../../enums";
import { RootState } from "../../../features/Application/globaltypes/redux";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import GroupInfoCell from "../../groupInfoCell/GroupInfoCell";

import { Group, GroupActionHandlers, ColumnOptions } from "../../../interfaces";
import RestrictedByTooltip from "../../restrictedByTooltip/RestrictedByTooltip";

import styles from "./groupListRow.module.scss";
import { Link } from "react-router-dom";

export const columnOptions: ColumnOptions[] = [
  {
    name: "name",
    width: 7,
    isSortable: true,
    className: "group-name-header",
  },
  {
    name: "members",
    width: 2,
    isSortable: false,
  },
  {
    name: "Added",
    width: 4,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
  },
];

export interface GroupListRowProps extends GroupActionHandlers {
  group: Group;
  selected: boolean;
}

export const GroupListRow: FC<GroupListRowProps> = ({ group, selected, ...buttonHandlers }) => {
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);
  const disableActions = !group.canEdit && !userPermissions.includes(RolePermissions.GroupsCreate);
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <GroupInfoCell group={group} goToEditGroup={buttonHandlers?.onEdit} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <Link className={styles.memberCount} to={`${group.id}/members`}>
          {group.membersCount}
        </Link>
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>{dateTimeUtils.formatDate(group.dateCreated)}</Table.Cell>
      <Table.Cell className={styles["align-right"]} width={columnOptions[3].width}>
        {buttonHandlers?.onEdit && (
          <RestrictedByTooltip hasPermission={!disableActions}>
            <GroupEllipsisPopupButton disabled={disableActions || selected} group={group} {...buttonHandlers} />
          </RestrictedByTooltip>
        )}
      </Table.Cell>
    </>
  );
};

export default GroupListRow;
