import React from "react";
import { TimestampLabel } from "../timestampLabel";
import StepLabelsList from "./StepLabelsList";
import { ProgressSaved } from "../progressSaved";

import "./wizardSubHeader.scss";

export interface Props {
  progressSavedDate: Date | undefined | null | string;
  isSaveInProgress: boolean;
  children: React.ReactElement[];
  activeIndex: number;
  onStepSelected: (nextIndex: number) => void;
  availableStepIndexes: number[];
}

function WizardSubHeader(props: Props) {
  const { progressSavedDate, isSaveInProgress, children, activeIndex, onStepSelected, availableStepIndexes } = props;
  return (
    <div className="wizard-sub-header">
      <StepLabelsList
        steps={children}
        activeIndex={activeIndex}
        onStepSelected={onStepSelected}
        availableStepIndexes={availableStepIndexes}
      />
      {progressSavedDate && (
        <div>
          <ProgressSaved
            timestampLabel={<TimestampLabel dateTime={progressSavedDate} format="MM/DD/YYYY h:mm A" />}
            inProgress={isSaveInProgress}
          />
        </div>
      )}
    </div>
  );
}

export default WizardSubHeader;
