import { bindActionCreators } from "@reduxjs/toolkit";
import { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SearchInput } from "../../../../../../../components";
import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { AppDispatch } from "../../../../../../Application/globaltypes/redux";
import Restricted from "../../../../../../Application/Restricted";
import { resetSelectedItems } from "../../../../state/slices/surveyGroupsSlice";
import { fetchSurveyGroups } from "../../../../state/thunks/surveyGroupsThunk";
import { GET_SURVEY_GROUPS_DEFAULT_PARAMS } from "../../../../types/constants";
import GroupsListContainer from "./GroupsListContainer/GroupsListContainer";
import {
  PeopleAssignmentColumns,
  ColumnToParamMap,
  getGroupColumnOptions,
} from "../../../../../PeopleAssignments/Overview/helpers/getColumnOptions";
import ContentGroupsNoResults from "../../../../../Common/Fields/ContentGroupsNoResults";
import { FiltersMap } from "../../../../../../../utils/filterUtils";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";
import { Columns } from "../UsersOverview/getColumnOptions";

export interface GroupsOverviewPropsBase {
  surveyId: number;
  customHeaderContent: React.ReactElement;
}

export type GroupsOverviewProps = GroupsOverviewPropsBase & PropsFromRedux;

export const GroupsOverview: FC<GroupsOverviewProps> = ({ surveyId, customHeaderContent, fetchGroups, onUnmount }) => {
  const renderSearchInput = (): React.ReactElement => <SearchInput placeholder="Search for Groups..." disabled />;

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.Group,
    RolePermissions.AssetsManage,
  );

  useEffect(
    () => () => {
      onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getSurveyGroups = (
    skip: number = GET_SURVEY_GROUPS_DEFAULT_PARAMS.skip,
    top: number = GET_SURVEY_GROUPS_DEFAULT_PARAMS.top,
    orderBy: string = GET_SURVEY_GROUPS_DEFAULT_PARAMS.orderBy.toLowerCase(),
    sortingDirection: SortingDirection = GET_SURVEY_GROUPS_DEFAULT_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = GET_SURVEY_GROUPS_DEFAULT_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = ColumnToParamMap[orderBy.toLowerCase() as Lowercase<PeopleAssignmentColumns>];

    fetchGroups({
      entityId: surveyId,
      skip: skip,
      top: top,
      sortingColumn,
      sortingDirection,
      filter: appliedFilter,
      term: "",
    });
  };

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={() => (
        <GroupsListContainer
          defaultSortingColumnName={Columns.Name}
          sortingDirection={SortingDirection.Ascending}
          columnOptions={getGroupColumnOptions()}
          applyFilter={/* istanbul ignore next */ () => {}} //NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} //NOSONAR
          fetchData={getSurveyGroups}
          renderSearch={renderSearchInput}
          customHeaderContent={customHeaderContent}
          isSelectDisabled={() => true}
          noResultsContent={<ContentGroupsNoResults />}
        />
      )}
    />
  );
};
/* istanbul ignore next */
const mapStateToProps = () => ({});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchGroups: bindActionCreators(fetchSurveyGroups, dispatch),
  onUnmount: () => dispatch(resetSelectedItems()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GroupsOverview);
