import { useEffect } from "react";
import { useDrag } from "react-dnd";
import { Ref } from "semantic-ui-react";
import { getEmptyImage } from "react-dnd-html5-backend";
import DragNDropItemTypes from "../../../../../../enums/dragNDropItemTypes";
import { EntityType } from "../../types";
import FlowDesignerSectionHeaderCard from "../../../../../../components/cards/FlowDesignerSectionHeaderCard/FlowDesignerSectionHeaderCard";

export interface Props {
  id: string;
  title: string;
  className?: string;
}

function DraggableSectionHeaderCard({ id, title }: Props) {
  const [, drag, preview] = useDrag({
    type: DragNDropItemTypes.DESIGNER,
    item: {
      id,
      type: DragNDropItemTypes.DESIGNER,
      title,
      elementType: EntityType.SectionHeader,
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <Ref innerRef={drag}>
      <FlowDesignerSectionHeaderCard id={id} title={title} className="asset-draggable-item" />
    </Ref>
  );
}

export default DraggableSectionHeaderCard;
