import ReducerNamespaceTypes from "../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../enums/reducer/reducerEntityPrefixTypes";
import { buildChildEntityActionType, buildFetchAction } from "../../Application/actions/actionsBuilder";

const infoPrefix = "INFO";
export const RoleUsers = "ROLE_USERS";

export const FETCH_ROLE_INFO_BEGIN = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditRole,
  infoPrefix,
  "FETCH_BEGIN",
);

export const FETCH_ROLE_INFO_SUCCESS = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditRole,
  infoPrefix,
  "FETCH_SUCCESS",
);

export const FETCH_ROLE_INFO_FAILURE = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditRole,
  infoPrefix,
  "FETCH_FAILURE",
);

export const UPDATE_ROLE_INFO = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditRole,
  infoPrefix,
  "UPDATE_INFO",
);

export const UPDATE_ROLE_PERMISSIONS = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditRole,
  infoPrefix,
  "UPDATE_PERMISSIONS",
);

export const UPDATE_ROLE_UPDATED = buildChildEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditRole,
  infoPrefix,
  "UPDATE_ROLE_UPDATED",
);

export const fetchRoleUsers = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditRole,
  RoleUsers,
);
