import { PackColumns, PackView } from "../../types/models";
import { ItemsTypes, SortingDirection, SortOptions, ViewType } from "enums";
import React, { SyntheticEvent, useCallback, useEffect, useMemo } from "react";
import { NoResults, NoSearchResults, SearchInput } from "components";
import { Observable } from "utils";
import { CardsViewerItem } from "components/cardsViewer/types";
import AddToAccountPackCard, {
  Item,
} from "components/assignmentModals/packAssignmentModal/packAssignmentSteps/packGridStep/addToAccountPackCard/AddToAccountPackCard";
import { isEmpty } from "lodash";
import { gridSortingOptions } from "features/Accounts/CreateAccount/CreateAccountPacksList/sortOptions";
import { DropdownProps } from "semantic-ui-react";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { HandleOnSelectionChanged, SelectionChangedArgs } from "interfaces/onSelectionChanged";
import { PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import {
  AddTemplateGroupPacksAccountMessageSuccess,
  AddTemplateGroupPacksAccountMessageFailure,
  RemoveAllPackFromTemplateGroupMessageSuccess,
  UpdateAllTemplateGroupPackAccountsMessageSuccess,
} from "features/Application/services/realTimeNotification/events/people/peopleEvents";
import { getColumnOptions } from "./getColumnOptions";
import EditPackAccountsModal from "../../Edit/Packs/EditPackAccountsModal";

type Props = {
  templateId: number;
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  items: PackView[];
  isLoading: boolean;
  isAllDataLoaded?: boolean;
  itemsCount: number;
  getGridData: (skip: number, searchTerm: string, orderBy: string) => void;
  getListData: (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection) => void;
  resetGrid?: () => void;
  packMap?: PackMap;
  isCreate: boolean;
  onRemovePackClick?: (id: number) => void;
  className?: string;
  disabled?: boolean;
};

const columnToParamMap: { [key: string]: string } = {
  [PackColumns.Name.toLowerCase()]: "title",
  [PackColumns.Added.toLowerCase()]: "dateAdded",
};

const rtnEvents = [
  AddTemplateGroupPacksAccountMessageSuccess,
  AddTemplateGroupPacksAccountMessageFailure,
  RemoveAllPackFromTemplateGroupMessageSuccess,
  UpdateAllTemplateGroupPackAccountsMessageSuccess,
];

export const GroupTemplatePacks = (props: Props) => {
  const {
    templateId,
    selectedIds,
    setSelectedIds,
    items,
    isLoading,
    isAllDataLoaded,
    itemsCount,
    getGridData,
    getListData,
    resetGrid,
    packMap,
    isCreate,
    onRemovePackClick,
    className,
    disabled,
  } = props;

  const [search, setSearch] = React.useState("");
  const [gridOrderBy, setGridOrderBy] = React.useState(SortOptions.CreatedDateDesc);
  const onTriggerEditLicensingObserver = useMemo(() => new Observable(), []);

  const isGridView = isCreate;

  const viewType = isGridView ? ViewType.GRID : ViewType.LIST;

  useEffect(() => {
    return () => {
      resetGrid?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isGridView && loadPacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, gridOrderBy]);

  const handleEditLicensingClick = useCallback(
    (pack: PackView) => {
      onTriggerEditLicensingObserver.notify(templateId, pack);
    },
    [templateId, onTriggerEditLicensingObserver],
  );

  const loadPacks = useCallback(
    (
      skip: number = 0,
      top: number = 10,
      sortingColumnName: string = PackColumns.Added.toLowerCase(),
      sortingDirection: SortingDirection = SortingDirection.Descending,
    ) => {
      if (isGridView) {
        getGridData(items.length, search, gridOrderBy);
      } else {
        const sortColumn = columnToParamMap[sortingColumnName];
        getListData(skip, top, sortColumn, sortingDirection);
      }
    },
    [isGridView, gridOrderBy, search, getGridData, getListData, items.length],
  );

  const onSortChange = (_: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    resetGrid?.();
    setGridOrderBy(data.value as SortOptions);
  };

  const onSearchChanged = (search: string) => {
    resetGrid?.();
    setSearch(search);
  };

  const onSelectedItemsChanged = (ids: number[]) => {
    setSelectedIds(ids);
  };

  const onSelectionChanged = (args: SelectionChangedArgs<PackView>) => {
    const onAdded = (pack: PackView) => {
      packMap?.set(pack.id, pack);
    };

    const onRemoved = (pack: PackView) => {
      packMap?.delete(pack.id);
    };
    HandleOnSelectionChanged(args, onAdded, onRemoved);
  };

  const renderCard = (props: CardsViewerItem<Item>) => <AddToAccountPackCard {...props} disablePopupMenu={disabled} />;

  const isFiltered = !isEmpty(search);

  const noResultsContent = () =>
    isFiltered ? (
      <NoSearchResults />
    ) : (
      <NoResults title="You don't have any available packs" iconClassName="fa-box-full"></NoResults>
    );

  const columnOptions = useMemo(
    () =>
      isGridView
        ? []
        : getColumnOptions({
            selectedIds: selectedIds,
            buttonHandlers: { onRemovePackClick: onRemovePackClick, onEditLicensingClick: handleEditLicensingClick },
            disabled: disabled,
          }),
    [isGridView, selectedIds, onRemovePackClick, handleEditLicensingClick, disabled],
  );

  return (
    <>
      <GenericItemsView
        viewType={viewType}
        itemsType={ItemsTypes.Pack}
        fetchData={loadPacks}
        items={items}
        isLoading={isLoading}
        isAllDataLoaded={isAllDataLoaded}
        dataCount={itemsCount}
        columnOptions={columnOptions}
        renderCard={renderCard}
        onSelectedItemChanged={onSelectedItemsChanged}
        onSelectedGridItemsChanged={onSelectedItemsChanged}
        onSelectionChanged={onSelectionChanged}
        selectedIds={selectedIds}
        noResultsContent={noResultsContent()}
        renderSearch={() => (
          <SearchInput placeholder="Search for packs..." onChange={onSearchChanged} disabled={!isCreate} />
        )}
        sortOptions={gridSortingOptions}
        blur
        onSortChange={onSortChange}
        doNotLoadPersistentViewType={true}
        hideListGridViewSwitcherButton={true}
        listViewRtnEvents={rtnEvents}
        className={className}
        defaultSortingColumnName={PackColumns.Added}
      />
      <EditPackAccountsModal onTriggerModalObserver={onTriggerEditLicensingObserver} />
    </>
  );
};
