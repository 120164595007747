import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppState {
  isSignalRConnected: boolean;
}

const initialState: AppState = { isSignalRConnected: false };

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setSignalRState(state: AppState, payloadAction: PayloadAction<boolean>) {
      state.isSignalRConnected = payloadAction.payload;
    },
  },
});

export const { setSignalRState } = appStateSlice.actions;

export default appStateSlice.reducer;
