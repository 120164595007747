import { Margin } from "./Shared";

export interface Node {
  // The nodes ID
  // ID will be the one we use in the future
  node?: string;
  id?: number;
  name: string;
  type: EntityType;
  order: number;
}

export interface Link {
  source: string;
  target: string;
  value: any;
}

export interface SankeyData {
  AccountId: number;
  FlowId: number;
  Relations: SankeyRelation[];
}

export interface Props {
  margins?: Margin;
  data: SankeyData;
  onClick?: (node: Node) => void;
}

export interface SankeyFuncs {
  zoomIn(): void;
  zoomOut(): void;
}

export enum EntityType {
  Video = "Video",
  Assessment = "Assessment",
  Survey = "Survey",
  Email = "Email",
  Pdf = "Pdf",
  Event = "ExternalEvent",
}

export interface SankeyRelation {
  ItemId: number;
  ItemOrder: number;
  ItemTitle: string;
  ItemType: EntityType;
  NextItemId: number;
  NextItemOrder: number;
  NextItemTitle: string;
  NextItemType: EntityType;
  UsersCompleted: number;
}
