import { combineReducers } from "redux";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import withGroupsAssignment from "../../../../Application/reducers/hoc/withGroupsAssignment";
import withUsersAssignment from "../../../../Application/reducers/hoc/withUsersAssignment";
import { eventCommonReducer } from "../slices/eventCommonSlice";
import { eventFiltersReducer } from "../slices/eventFilterSlice";
import eventGroupsSlice from "../slices/eventGroupsSlice";
import eventGroupsToAddSlice from "../slices/eventGroupsToAddSlice";
import { eventRegistrationsReducer } from "../slices/eventRegistrationsSlice";
import { eventSearchReducer } from "../slices/eventSearchSlice";
import { eventSessionReportingReducer } from "../slices/eventSessionReportingSlice";
import eventUsersSlice from "../slices/eventUsersSlice";
import eventUsersToAddSlice from "../slices/eventUsersToAddSlice";
import { remindersReducer } from "../slices/remindersSlice";
import { eventDetailsReducer } from "./eventDetailsReducer";
import eventEntityStateReducer from "./eventEntityStateReducer";
import { eventSessionsValidationReducer } from "./eventSessionsValidationReducer";
import { eventsOverviewReducer } from "./eventsOverviewReducer";
import eventsUsersAssignmentReducer from "./eventsUsersAssignmentReducer";

export const eventsReducer = combineReducers({
  eventsOverview: eventsOverviewReducer,
  eventsRegistrations: eventRegistrationsReducer,
  eventDetails: eventDetailsReducer,
  eventSessionReporting: eventSessionReportingReducer,
  eventReminders: remindersReducer,
  eventEntityState: eventEntityStateReducer,
  common: eventCommonReducer,
  items: combineReducers({
    usersToAdd: eventUsersToAddSlice,
    groupsToAdd: eventGroupsToAddSlice,
  }),
  users: eventUsersSlice,
  groups: eventGroupsSlice,
  filters: eventFiltersReducer,
  userAssignment: eventsUsersAssignmentReducer,
  search: eventSearchReducer,
  eventSessionsValidationReducer,
  assignedUsers: withUsersAssignment(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Events),
  assignedGroups: withGroupsAssignment(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Events),
});

export type EventsState = ReturnType<typeof eventsReducer>;
