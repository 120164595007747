import React from "react";
import EllipsisPopupButton from "../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { EditLinkButton } from "../buttons/linkButtons";

export interface ISendingProfileEllipsisPopupButtonProps {
  onEditClicked: () => void;
  circle: boolean;
  outlinedEllipsis: boolean;
  disabled?: boolean;
}

function SendingProfileEllipsisPopupButton(props: ISendingProfileEllipsisPopupButtonProps) {
  const { onEditClicked, ...ellipsisProps } = props;

  return (
    <EllipsisPopupButton {...ellipsisProps}>
      <EditLinkButton onClick={onEditClicked} />
    </EllipsisPopupButton>
  );
}

export default SendingProfileEllipsisPopupButton;
