import dataService from "../../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/threatDefenceEmailTemplatesOverviewActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import { DeleteThreatDefenceEmailTemplatesRequest, GetThreatDefenceEmailTemplatesRequest } from "../../types/requests";
import { Dispatcher, FetchActionPayload } from "../../../../../../interfaces/redux";
import { DeleteThreatDefenceEmailTemplateOverviewResult, ThreatDefenceEmailTemplateOverview } from "../../types/state";
import { Filters } from "../../../../../../utils/queryUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME;

export const getThreatDefenceEmailTemplatesAsync = (take: number, skip: number, orderBy: string, filters: Filters) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceEmailTemplatesBegin);
  const success = getActionProvider<FetchActionPayload<ThreatDefenceEmailTemplateOverview>>(
    actionTypes.getThreatDefenceEmailTemplatesSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceEmailTemplatesFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    const request: GetThreatDefenceEmailTemplatesRequest = {
      top: take,
      skip,
      orderBy,
      filters,
    };
    try {
      const result = await dataService.threatDefence.getThreatDefenceEmailTemplatesAsync(request);
      const recordsCount = Number.parseInt(result.headers[countHeaderName || ""]);

      const data: FetchActionPayload<ThreatDefenceEmailTemplateOverview> = {
        items: result.data,
        totalCount: recordsCount,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const deleteThreatDefenceEmailTemplatesAsync = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.deleteThreatDefenceEmailTemplatesBegin);
  const success = getActionProvider<DeleteThreatDefenceEmailTemplateOverviewResult>(
    actionTypes.deleteThreatDefenceEmailTemplatesSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.deleteThreatDefenceEmailTemplatesFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    const request: DeleteThreatDefenceEmailTemplatesRequest = {
      id,
    };
    try {
      const result = await dataService.threatDefence.deleteThreatDefenceEmailTemplate(request);

      const data = {
        isSuccess: result.data,
        id: id,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
