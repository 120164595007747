import React, { Component } from "react";
import PropTypes from "prop-types";
import Progress from "../Progress";
import { ReactComponent as DownloadIcon } from "../../../images/download.svg";
import { default as cn } from "classnames";
import taskStatuses from "../../../enums/taskStatuses";
import taskTypes from "../../../enums/taskTypes";
import { Icon } from "semantic-ui-react";
import { PauseButton, CancelButton, CloseButton, RestartButton, ResumeButton } from "../../buttons/circleIconButtons";
import { Tooltip } from "../../common/tooltip";

import "./backgroundTaskProgress.scss";

class BackgroundTaskProgress extends Component {
  getButtons = () => {
    const { task, onPause, onResume, onRestart, onCancel, onClose } = this.props;
    let buttons;

    switch (task.status) {
      case taskStatuses.inProgress:
        buttons = (
          <>
            {task.canPause && <PauseButton onClick={() => onPause(task.id)} />}
            {task.canCancel && <CancelButton onClick={() => onCancel(task.id)} />}
          </>
        );
        break;
      case taskStatuses.paused:
        buttons = (
          <>
            {task.canResume && <ResumeButton onClick={() => onResume(task.id)} />}
            {task.canCancel && <CancelButton onClick={() => onCancel(task.id)} />}
          </>
        );
        break;
      case taskStatuses.failed:
        buttons = (
          <>
            {task.canRestart && <RestartButton onClick={() => onRestart(task.id)} />}
            {task.canClose && <CloseButton onClick={() => onClose(task.id)} />}
          </>
        );
        break;
      case taskStatuses.queued:
        buttons = <>{task.canCancel && <CloseButton onClick={() => onCancel(task.id)} />}</>;
        break;
      case taskStatuses.successful:
      case taskStatuses.canceled:
        buttons = (
          <>
            {task.canClose && <CloseButton onClick={() => onClose(task.id)} />}
            {task.info && (
              <Tooltip
                hideOnScroll
                content={task.info}
                position="top center"
                showAlways
                target={<Icon size="large" className="fa fa-exclamation-circle" />}
              />
            )}
          </>
        );
        break;
      default:
        buttons = null;
    }

    return <div className="buttons">{buttons}</div>;
  };

  getStatusLabelInfo = (task) => {
    let className;
    let label = task.label;
    let canClick = false;

    switch (task.status) {
      case taskStatuses.inProgress:
        className = "in-progress";
        label = label || "Processing…";
        canClick = task.canClickOnInProgress;
        break;
      case taskStatuses.paused:
        className = "paused";
        label = label || "Paused";
        canClick = task.canClickOnPaused;
        break;
      case taskStatuses.successful:
        className = "completed";
        label = label || "Finished successfully!";
        canClick = task.canClickOnSuccessful;
        break;
      case taskStatuses.failed:
        className = "failed";
        label = task.errorLabel || "Task failed!";
        canClick = task.canClickOnFailure;
        break;
      case taskStatuses.queued:
        className = "in-progress";
        label = "In queue";
        canClick = task.canClickOnQueued;
        break;
      case taskStatuses.canceled:
        className = "canceled";
        label = "Canceled.";
        canClick = task.canClickOnCanceled;
        break;
      default:
        className = "";
        label = label || "";
    }

    return { className, label, canClick };
  };

  getIcon = () => {
    const { task } = this.props;
    return task.fileUri ? <DownloadIcon /> : null;
  };

  render() {
    const { task, onClick } = this.props;
    let statusLabelInfo = this.getStatusLabelInfo(task);
    let onTitleClick = statusLabelInfo.canClick ? onClick : null;
    let clickableClassName = onTitleClick ? "clickable" : "";

    return (
      <div id="background-task-progress" className="background-task-progress">
        <div className="title-progress">
          <span
            className={cn("title", statusLabelInfo.className, clickableClassName)}
            onClick={() => onTitleClick && onTitleClick(task.id)}
          >
            {this.getIcon()} {task.title}
          </span>
          <div className="progress-bar">
            <Progress
              size="tiny"
              color="yellow"
              completed={taskStatuses.isCompleted(task.status)}
              percent={taskStatuses.isCanceled(task.status) ? 100 : task.percent}
              indeterminate={task.indeterminate}
              isUploading={task.type === taskTypes.fileUpload}
              showPercents={false}
              error={taskStatuses.isFailed(task.status)}
              warning={taskStatuses.isCanceled(task.status)}
            />
            <span className={cn("status-label", statusLabelInfo.className)}>{statusLabelInfo.label}</span>
          </div>
        </div>
        {this.getButtons()}
      </div>
    );
  }
}

BackgroundTaskProgress.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
    taskStatus: PropTypes.number,
    percent: PropTypes.number,
  }),
  onClick: PropTypes.func,
  onPause: PropTypes.func,
  onCancel: PropTypes.func,
  onRestart: PropTypes.func,
  onClose: PropTypes.func,
  onResume: PropTypes.func,
};

export default BackgroundTaskProgress;
