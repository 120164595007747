import React from "react";

import { DuplicateButton } from "../../../../../components/buttons/linkButtons/headerButtons/DuplicateButton";
import { DeleteButton } from "../../../../../components/buttons/linkButtons/headerButtons/DeleteButton";
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { RolePermissions } from "../../../../../enums";
import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";

import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import TagsManageContainer from "../containers/TagsManageContainer";
import Restricted from "../../../../Application/Restricted";
import useToggle from "../../../../../hooks/useToggle";

export interface EventsOverviewHeaderProps {
  purchasedSelected: boolean;
  undeletableSelected: boolean;
  draftSelected: boolean;
  selectedIds: Array<number>;
  duplicationHandler: (selectedIds: number[]) => () => void;
  deletionHandler: (selectedIds: number[]) => () => void;
  renderCreateButton: () => React.ReactElement;
  clearSelection: () => void;
}

const addToPacksPermissions = [RolePermissions.PacksManage, RolePermissions.FlowsCreate];

export const EventsOverviewHeader = (props: EventsOverviewHeaderProps) => {
  const { selectedIds, purchasedSelected, undeletableSelected, draftSelected, clearSelection } = props;

  const [packsModalShown, togglePacksModal] = useToggle();

  const renderBatchButtons = () => {
    return (
      <>
        <Restricted permissions={addToPacksPermissions}>
          <AddToPacksButton onClick={togglePacksModal} isDisabled={purchasedSelected || draftSelected} />
        </Restricted>
        <DuplicateButton
          entityName="events"
          purchasedSelected={purchasedSelected}
          onClick={props.duplicationHandler(selectedIds)}
        />
        <DeleteButton
          entityName="events"
          undeletableSelected={undeletableSelected}
          purchasedSelected={purchasedSelected}
          onClick={props.deletionHandler(selectedIds)}
        />
        <TagsManageContainer
          entity="assets"
          isDisabled={purchasedSelected}
          selectedIds={selectedIds}
          onComplete={clearSelection}
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={togglePacksModal}
          onComplete={clearSelection}
          selectedItemIds={selectedIds}
          contentType="ExternalEvent"
        />
      </>
    );
  };

  const renderHeaderButtons = () => (selectedIds.length ? renderBatchButtons() : props.renderCreateButton());

  return (
    <OverviewHeader title="Events" itemName="Event" selectedItemsAmount={selectedIds.length}>
      {renderHeaderButtons()}
    </OverviewHeader>
  );
};
