import React from "react";
import cn from "classnames";

import "./validationErrorPrompt.scss";

export type ValidationErrorPromptProps = {
  errorPosition: "top" | "bottom" | "right";
  text: string;
  errorClassName?: string;
};

export default function ValidationErrorPrompt({ errorPosition, errorClassName, text }: ValidationErrorPromptProps) {
  return (
    <div
      className={cn("error-message validation", errorPosition, errorClassName)}
      data-hook-id="error-message"
      data-testid="error-message"
      dangerouslySetInnerHTML={{ __html: `<span>${text}</span>` }}
    />
  );
}
