import { FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../../Application/globaltypes/redux";
import LandingPageForm from "../LandingPageForm/LandingPageForm";
import { ThreatDefenceLandingPage, ThreatDefenceLandingPageView } from "../../types/state";
import * as landingPageEntityStateActions from "../../state/actions/threatDefenceLandingPageEntityStateActions";
import * as landingPageDetailsActions from "../../state/actions/threatDefenceLandingPageDetailsActions";
import { ConfigureLandingPageProps } from "../types";
import validationSchemas from "../../../../../../utils/validationSchemas";
import schemasUtils from "../../../../../../utils/validationSchemasUtils";
import { AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";

export type ConfigureAllProps = ConfigureLandingPageProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceLandingPageView> &
  AutosaveProps;

export class Configure extends Component<ConfigureAllProps> {
  isLandingPageCreated = () => this.props.id > 0;

  componentDidMount() {
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });

    //Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  onNext = () => {
    this.saveLandingPage();
    if (!this.isLandingPageCreated()) {
      this.props.entityStateActions.createThreatDefenceLandingPage(Configure.getLandingPage(this.props.values));
    }
  };

  onPrevious = () => {
    this.saveLandingPage();
  };

  onClose = () => {
    this.props.detailsActions.clearThreatDefenceLandingPage();
    this.props.entityStateActions.resetThreatDefenceLandingPageEntityState();
  };

  saveLandingPage = () => {
    this.props.detailsActions.saveThreatDefenceLandingPage(this.props.values);
  };

  static getLandingPage = (values: ThreatDefenceLandingPageView): ThreatDefenceLandingPage => {
    return {
      name: values.name.trim(),
      labels: values.labels.selected,
      softwareApplications: values.softwareApplications.selected,
    };
  };

  render() {
    return <LandingPageForm disablePreventTransitionPrompt={this.isLandingPageCreated()} {...this.props} />;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigureLandingPageProps) => ({
  id: ownProps.entityId || state.library.threatDefenceLandingPages.threatDefenceLandingPageEntityStateReducer.entityId,
  landingPage: state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.landingPage,
  isLoading: state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.isLoading,
  isLoaded: state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.isLandingPageLoaded,
  isCreating: state.library.threatDefenceLandingPages.threatDefenceLandingPageEntityStateReducer.changingEntityState,
  isDraft: state.library.threatDefenceLandingPages.threatDefenceLandingPageDetails.landingPage.isDraft,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(landingPageEntityStateActions, dispatch),
  detailsActions: bindActionCreators(landingPageDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigureAllProps, ThreatDefenceLandingPageView, ThreatDefenceLandingPage>({
  getInitValues: (props) => Configure.getLandingPage(props.landingPage),
  stateProvider: Configure.getLandingPage,
  entityUpdater: (props) => props.entityStateActions.updateThreatDefenceLandingPage,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.landingPageInfo,
  enableReinitialize: true,
  isInitialValid: (props: ConfigureLandingPageProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.landingPageInfo, props.landingPage),
  mapPropsToValues: (props: ConfigureLandingPageProps & PropsFromRedux) => props.landingPage,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connect(mapStateToProps, mapDispatchToProps)(component);
