import { useState, useCallback, useMemo, useEffect } from "react";
import { isEqual } from "lodash";
import { Icon, Label } from "semantic-ui-react";
import FilterSidebar from "../filterSidebar/FilterSidebar";
import { calculateAppliedFilters, FiltersMap } from "../../utils/filterUtils";
import { FilterFormBuilder, FilterItemBase, FilterViewType } from "../filterForms/FilterFormBuilder/FilterFormBuilder";
import { AccountIds, Accounts, AccountType, accountType, dateRange30, longFormDateFilter, validDateWithMoment } from "features/Library/Common/utils/performanceUtils";
import moment from "moment";
import { ReportingChip } from "components/reportingChip/ReportingChip";
import accountsDataService from "../../features/Accounts/services/accountsDataService";
import { SortingDirection } from "enums";

import "./ReportingFilter.scss";
import { RootState } from "features/Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";

const { dateFrom, dateTo } = dateRange30();

type FilterWithoutAccounts = {
  currentFilter: BaseFilter;
  callback: (filter: BaseFilter) => void;
  ignoreInCount?: string[];
  includeAccounts?: never;
  includeAccountsDropdown?: never;
};

type FilterWithAccounts = {
  currentFilter: AccountFilter;
  callback: (filter: AccountFilter) => void;
  ignoreInCount?: string[];
  includeAccounts: boolean;
  includeAccountsDropdown?: boolean;
};

type FilterWithAccountsDropdown = {
  currentFilter: AccountDropdownFilter;
  callback: (filter: AccountDropdownFilter) => void;
  ignoreInCount?: string[];
  includeAccounts?: boolean;
  includeAccountsDropdown: boolean;
};

export type FilterProps = PropsFromRedux & (FilterWithoutAccounts | FilterWithAccounts | FilterWithAccountsDropdown);

export interface BaseFilter {
  dateFrom: string;
  dateTo: string;
}

export interface AccountFilter extends BaseFilter {
  showCustomers?: boolean;
}

export interface AccountDropdownFilter extends BaseFilter {
  accounts?: Accounts[] | AccountIds[] | string[];
  includeMyData?: boolean;
  type?: AccountType;
}

const dateFilterMissingValues = (selectedData: BaseFilter) =>
  selectedData.dateFrom === "" || selectedData.dateTo === "";

export const allCustomerAccounts = { text: "All Customer Accounts", value: "All Customer Accounts" };

function filtersAreIdentical(newFilter: DateFilterCallback, oldFilter: DateFilterCallback): boolean;
function filtersAreIdentical(newFilter: AccountFilterCallback, oldFilter: AccountFilterCallback): boolean;
function filtersAreIdentical(newFilter: AccountDropdownFilterCallback, oldFilter: AccountDropdownFilterCallback): boolean;

function filtersAreIdentical(newFilter: SubmitCallback, oldFilter: SubmitCallback): boolean {
  return isEqual(newFilter, oldFilter);
}
const incomingDateFormat: moment.MomentFormatSpecification = "MM/DD/YYYY";

type DateFilterCallback = {
  dates: { from: string; to: string; };
};

type AccountFilterCallback = DateFilterCallback & {
  showCustomers?: boolean;
};

type AccountDropdownFilterCallback = DateFilterCallback & {
  accounts?: Accounts[] | AccountIds[] | string[];
  includeMyData?: boolean;
  type?: AccountType;
};

type SubmitCallback = DateFilterCallback | AccountFilterCallback | AccountDropdownFilterCallback;

export const fetchAccounts = async (setListOfAccounts: any) => {
  const top = 10000;
  let skip = 0;
  let listOfAccounts: Accounts[] = [];
  let foundAllAccounts = false;
  while (foundAllAccounts === false) {
    const { items } = await accountsDataService.getAccounts(skip, top, "name", SortingDirection.Ascending);
    if (items.flat().length === 0) {
      foundAllAccounts = true;
    } else {
      const tempListOfAccounts = items.flat().map((account) => {
        return { value: account.id, text: account.name };
      });
      listOfAccounts = [...listOfAccounts, ...tempListOfAccounts];
      if (items.flat().length === top) {
        skip += top;
      } else {
        foundAllAccounts = true;
      }
    }
  }

  listOfAccounts.unshift(allCustomerAccounts);
  setListOfAccounts(listOfAccounts.flat());
};

export const ReportingFilter = ({ currentFilter, callback, includeAccounts, includeAccountsDropdown, accountId, ignoreInCount }: FilterProps) => {
  const type = accountType(accountId);

  const filter = useMemo(() => ({
    dates: { from: dateFrom, to: dateTo },
    ...(includeAccounts && { "showCustomers": false }),
    ...(includeAccountsDropdown && { accounts: [allCustomerAccounts.value] }),
    ...(includeAccountsDropdown && { includeMyData: true }),
    ...(includeAccountsDropdown && { type }),
  }), [includeAccounts, includeAccountsDropdown, type]);

  const [show, setShow] = useState(false);
  const [selectedData, setSelectedData] = useState<SubmitCallback>(filter);
  const [listOfAccounts, setListOfAccounts] = useState<any[]>([]);

  useEffect(() => {
    if (includeAccountsDropdown) {
      fetchAccounts(setListOfAccounts);
    }
  }, [includeAccountsDropdown]);

  // Toggle showCustomers to false if the box was checked before and
  // includeAccounts is set to false
  useEffect(() => {
    if (!includeAccounts && (currentFilter as AccountFilter)["showCustomers"] === true) {
      let copy = { ...currentFilter };
      // @ts-ignore
      callback({ ...copy, showCustomers: false });
    }
    // Disabling for now, may put callback back in later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter, includeAccounts, selectedData]);

  // Changing performance filter style to be styled in the way FilterSidebar expects
  const formattedActiveFilter = useMemo<any>(() => {
    let filter: Partial<SubmitCallback> = {};
    if (!dateFilterMissingValues(currentFilter)) {
      filter.dates = {
        from: currentFilter.dateFrom,
        to: currentFilter.dateTo,
      };
    }
    if ((currentFilter as AccountFilter).showCustomers !== undefined) {
      (filter as AccountFilterCallback).showCustomers = (currentFilter as AccountFilter).showCustomers;
    }
    if ((currentFilter as AccountDropdownFilter).accounts) {
      (filter as AccountDropdownFilterCallback).accounts = (currentFilter as AccountDropdownFilter).accounts;
    }
    if ((currentFilter as AccountDropdownFilter).includeMyData !== undefined) {
      (filter as AccountDropdownFilterCallback).includeMyData = (currentFilter as AccountDropdownFilter).includeMyData;
    }
    if ((currentFilter as AccountDropdownFilter).type) {
      (filter as AccountDropdownFilterCallback).type = type;
    }
    return filter;
  }, [currentFilter, type]);

  const hide = useCallback(() => setShow(false), []);

  const handleSubmit = useCallback(
    (filter: SubmitCallback) => {
      let from = "",
        to = "";
      if (filter.dates) {
        // Manage dates
        from = filter.dates.from;
        to = filter.dates.to;
        if (dateFilterMissingValues({ dateFrom: from, dateTo: to })) {
          return;
        }
      }
      if (filtersAreIdentical(filter, formattedActiveFilter)) {
        return;
      }
      let newStateData: AccountFilterCallback & AccountDropdownFilterCallback = {
        dates: {
          from,
          to,
        },
      };
      if (includeAccounts) {
        newStateData.showCustomers = (filter as any).accounts;
      }
      if (includeAccountsDropdown) {
        newStateData.accounts = (filter as any).accounts ?? [];
        newStateData.includeMyData = (filter as any).includeMyData ?? false;
        newStateData.type = type;
      }

      setSelectedData(newStateData);
      setShow(false);

      const longFormDates = longFormDateFilter(from, to);
      callback({
        dateFrom: longFormDates.dateFrom,
        dateTo: longFormDates.dateTo,
        ...(includeAccounts && { showCustomers: !!(filter as AccountFilterCallback).showCustomers, }),
        ...(includeAccountsDropdown && { includeMyData: (newStateData as any).includeMyData }),
        ...(includeAccountsDropdown && {
          accounts: (newStateData as any).accounts.map((account: any) => {
            if (typeof (account) === "string" || typeof (account) === "number") return account;
            return account.value;
          }),
        }),
        ...(includeAccountsDropdown && { type: newStateData.type }),
      });
    },
    [formattedActiveFilter, callback, includeAccounts, includeAccountsDropdown, type],
  );

  const handleReset = useCallback(() => {
    setSelectedData(filter);

    // Applied filter is empty? No need to run callback
    if (Object.keys(formattedActiveFilter).length === 0 || (includeAccountsDropdown && filtersAreIdentical(filter, formattedActiveFilter))) {
      return;
    }

    const { dates, ...rest } = filter;
    callback({ ...rest, dateFrom, dateTo });
  }, [formattedActiveFilter, callback, includeAccountsDropdown, filter]);

  const filterCount = useMemo(() => {
    let filteredFilter: FiltersMap = {};
    for (let filterName in formattedActiveFilter) {
      if (!ignoreInCount?.includes(filterName)) {
        filteredFilter[filterName] = formattedActiveFilter[filterName];
      }
    }

    return calculateAppliedFilters(filteredFilter);
  }, [formattedActiveFilter, ignoreInCount]);

  const updateFilter = useCallback((filter: SubmitCallback) => {
    setSelectedData(filter);
  }, []);

  const shouldApplyFilterBeDisabled = useMemo<boolean>(() => {
    if (includeAccountsDropdown) {
      const noAccountSelected = (selectedData as AccountDropdownFilterCallback).includeMyData === undefined && ((selectedData as any).accounts === undefined || (selectedData as any).accounts.length === 0);

      if (noAccountSelected) {
        return true;
      }
    }

    if (filtersAreIdentical(selectedData, formattedActiveFilter)) return true;
    if (!validDateWithMoment(selectedData.dates.from) || !validDateWithMoment(selectedData.dates.to)) return true;
    if (moment(selectedData.dates.from, incomingDateFormat).isAfter(moment(selectedData.dates.to, incomingDateFormat)))
      return true;
    if (Object.keys(selectedData).length === 0) return true;

    return false;
  }, [selectedData, formattedActiveFilter, includeAccountsDropdown]);

  const filterChipData = [
    {
      name: "Date",
      value: `${currentFilter.dateFrom} - ${currentFilter.dateTo}`,
    },
  ];

  const filterChips = filterChipData.map((chipData) => (
    <ReportingChip key={chipData.name} name={chipData.name} value={chipData.value} />
  ));

  return (
    <div className="filterRoot">
      <FilterSidebar
        visible={show}
        hideFilter={hide}
        applyFilter={handleSubmit}
        resetFilter={handleReset}
        appliedFilter={formattedActiveFilter}
        shouldDisableApply={shouldApplyFilterBeDisabled}
        defaultFilter={filter}
      >
        <Filters
          filter={selectedData}
          accounts={listOfAccounts}
          updateSubFilter={updateFilter}
          includeAccounts={includeAccounts}
          includeAccountsDropdown={includeAccountsDropdown}
        />
      </FilterSidebar>
      {filterChips}
      <button
        className="filterVisibilityToggle"
        data-testid="filterVisiblityToggle"
        onClick={() => setShow((s) => !s)}
        type="button"
      >
        <Icon name="filter" />
        Filter
      </button>
      {filterCount > 0 && (
        <Label circular color="blue">
          {filterCount}
        </Label>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
  };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportingFilter);

interface SubFilterProps {
  includeAccounts?: boolean;
  includeAccountsDropdown?: boolean;
  filter: any;
  updateSubFilter: (filter: SubmitCallback) => void;
  updateFilter?: any;
  accounts?: Accounts[];
}

const Filters = ({ includeAccounts, includeAccountsDropdown, filter, updateSubFilter, updateFilter, accounts }: SubFilterProps) => {
  const items = useMemo(() => {
    let returnVal: FilterItemBase<any>[] = [
      {
        type: FilterViewType.DateRange,
        label: "Dates",
        propertyName: "dates",
        items: [],
        placeholder: "",
      },
    ];
    if (includeAccounts) {
      returnVal.push({
        type: FilterViewType.Checkbox,
        label: "Accounts",
        propertyName: "showCustomers",
        items: [],
        placeholder: "",
      });
    }
    if (includeAccountsDropdown) {
      returnVal.push({
        type: FilterViewType.MultiSelectWithWindow,
        label: "Accounts",
        propertyName: "accounts",
        items: accounts,
        placeholder: "All Customer Accounts",
        otherProps: {
          initialCheckedItems: [allCustomerAccounts],
          searchPredicate: (item: Accounts, term: string) => item.text.toLowerCase().includes(term.toLowerCase()),
          getLabel: (item: Accounts) => item.text,
          // Temporary
          getDescription: (item: Accounts) => item?.text || "",
        },
      });

      returnVal.push({
        type: FilterViewType.Checkbox,
        label: "Include My Account Data",
        className: "check-box-v2",
        propertyName: "includeMyData",
        items: [],
        placeholder: "",
        otherProps: {
          noToggle: true,
        },
      });
    };
    return returnVal;
  }, [includeAccounts, accounts, includeAccountsDropdown]);

  const handleUpdate = useCallback(
    (newFilter: any) => {
      updateSubFilter(newFilter);
      updateFilter(newFilter);
    },
    [updateFilter, updateSubFilter],
  );

  return (
    <FilterFormBuilder
      items={items}
      filterOptions={{}}
      filter={filter}
      // Ignoring because GenericFiltersMap does not definitively
      // assert that the values we expect are present
      // @ts-ignore
      updateFilter={handleUpdate}
    />
  );
};
