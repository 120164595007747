import React from "react";

import { NoResultsWithButton } from "../../../components";

import { ReactComponent as NoUserResult } from "../../../images/group.svg";

interface UsersNoResultsDragNDropProps {
  createUserButton?: React.ReactElement;
  filtered?: boolean;
}

const UsersNoResultsDragNDrop: React.FC<UsersNoResultsDragNDropProps> = ({ createUserButton, filtered }) => {
  return (
    <NoResultsWithButton
      title="Drag & Drop Files Here"
      description="Looks like you don't have any users yet."
      icon={<NoUserResult />}
      actionButton={createUserButton}
      actionText="Drag and drop files here to upload."
      filtered={filtered}
    />
  );
};

export default UsersNoResultsDragNDrop;
