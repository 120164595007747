import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { createSearchSlice, SearchState } from "../../../../../Application/slices/createSearchSlice";

export const initialState: SearchState = {
  term: undefined,
};

const emailSearchSlice = createSearchSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Emails, name: "search" },
  initialState: initialState,
  reducers: {},
});

export const { setTerm } = emailSearchSlice.actions;

export const emailSearchSelector = (state: RootState) => state.library.emails.search.term;

export const emailSearchReducer = emailSearchSlice.reducer;
