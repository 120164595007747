import { DropdownItem } from "../../../components/buttons/multiStateButton/MultiStateButton";
import { AddPeopleToContentTypes } from "../../../enums";

type OnAddToContent = (type: AddPeopleToContentTypes) => void;

export const getAddContentHeaderOptions = (onAddToContent: OnAddToContent): DropdownItem[] => {
  const onClickHandler = (contentType: AddPeopleToContentTypes) => () => onAddToContent(contentType);

  const flows = {
    text: "Flows",
    icon: "code-branch",
    onClick: onClickHandler(AddPeopleToContentTypes.Flows),
  };

  return [flows];
};
