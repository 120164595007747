import { Crop } from "react-image-crop";

import environmentConfig from "configuration/environmentConfig";

const getImageType = async (src: string): Promise<string> => {
  const base64Response = await fetch(src);
  const blob = await base64Response.blob();
  return blob.type;
};

const utils = {
  async getCroppedImg(
    image: HTMLImageElement,
    pixelCrop: Crop,
    fileName: string,
    fileUrl: string,
  ): Promise<{ blob: Blob; newFileUrl: string }> {
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width || 0;
    canvas.height = pixelCrop.height || 0;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Context is empty");
    }

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width || 0,
      pixelCrop.height || 0,
      0,
      0,
      pixelCrop.width || 0,
      pixelCrop.height || 0,
    );

    const type = await getImageType(image.src);

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        interface BlobWithName extends Blob {
          name?: string;
        }
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        let blb: BlobWithName = blob;
        blb.name = fileName;
        window.URL.revokeObjectURL(fileUrl);
        const newFileUrl = window.URL.createObjectURL(blb);
        resolve({ blob: blb, newFileUrl });
      }, type);
    });
  },

  capture(video: HTMLVideoElement, scaleFactor = 0.25) {
    const appliedScaleFactor = scaleFactor == null ? 1 : scaleFactor;
    const w = video.videoWidth * appliedScaleFactor;
    const h = video.videoHeight * appliedScaleFactor;
    const canvas = document.createElement("canvas");
    canvas.width = w;
    canvas.height = h;
    canvas.onclick = () => {
      window.open(canvas.toDataURL());
    };
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Context is empty");
    }
    ctx.drawImage(video, 0, 0, w, h);
    return canvas;
  },

  formatStorageUrl: (url: string): string => {
    return url?.replace(
      "{{ImagesStorage}}",
      `${environmentConfig.systemNotificationsImageStorageUrl}/system-notification-email-templates`,
    );
  },
};

export default utils;
