import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GoalArea } from "../shared/GoalArea";

interface Props {
  dateFilter: PerformanceFilter;
  isBsi: boolean;
}

export const SharepointGoalCharts = ({ dateFilter, isBsi }: Props) => {
  return (
    <div>
      <GoalArea
        dateFilter={dateFilter}
        header="Files Synced"
        application="SharePoint"
        activity="Files Synced"
        isBsi={isBsi}
      />
      <GoalArea
        dateFilter={dateFilter}
        header="Files Viewed"
        application="SharePoint"
        activity="Files Viewed"
        isBsi={isBsi}
      />
      <GoalArea
        dateFilter={dateFilter}
        header="Page Visits"
        application="SharePoint"
        activity="Page Visits"
        isBsi={isBsi}
      />
      <GoalArea
        dateFilter={dateFilter}
        header="Shared Files"
        application="SharePoint"
        activity="Shared Files"
        isBsi={isBsi}
      />
    </div>
  );
};
