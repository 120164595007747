import * as actionTypes from "../actionTypes/packListingActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { EnablePackTrialRequest, PackListingView, PackRequest, EnablePackRequest } from "../../types/state";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { DistributedOpUpdateParams } from "../../../../../interfaces/updateParams";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";

export const showPackRequestModal = getActionBaseProvider(actionTypes.showPackRequestModal);

export const hidePackRequestModal = getActionBaseProvider(actionTypes.hidePackRequestModal);

export const getPackListing = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getPackOverviewBegin);
  const success = getActionProvider<PackListingView>(actionTypes.getPackOverviewSuccess);
  const failure = getActionProvider<Error>(actionTypes.getPackOverviewFailure);

  return (dispatch: Function) => {
    dispatch(begin());
    dataService.packs
      .getPackListingAsync(id)
      .then((result: { data: PackListingView }) => dispatch(success(result.data)))
      .catch((error: Error) => dispatch(failure(error)));
  };
};

export const requestPack = (packRequest: PackRequest) => {
  const begin = getActionBaseProvider(actionTypes.requestPackBegin);
  const success = getActionProvider<any>(actionTypes.requestPackSuccess);
  const failure = getActionProvider<Error>(actionTypes.requestPackFailure);

  return async (dispatch: AppDispatch) => {
    dispatch(begin());
    try {
      await dataService.packs.requestPackAsync(packRequest);
      dispatch(success({}));
      dispatch(sendTransientNotification({ title: "Success!", message: "Request sent successfully", type: 0 }, 5));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const enablePackTrial = (packRequest: EnablePackTrialRequest) => {
  return async (dispatch: AppDispatch) => {
    const updateParams: DistributedOpUpdateParams = {
      id: `EnablePackTrial_${Date.now()}`,
      title: "Starting Pack Trial",
      getOperationProps: async () => {
        const { data } = await dataService.packs.enablePackTrialAsync(packRequest);
        return {
          operationId: data.operationId,
          stepsIds: data.stepsIds,
          statusUrl: data.statusUrl,
        };
      },
      successTransientMessage: "Pack trial successfully enabled",
      failureTransientMessage: "Failed to enable pack trial",
      indeterminate: true,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};

export const enablePack = (packRequest: EnablePackRequest) => {
  return async (dispatch: AppDispatch) => {
    const updateParams: DistributedOpUpdateParams = {
      id: `PurchasePack_${Date.now()}`,
      title: "Purchasing Pack",
      getOperationProps: async () => {
        const { data } = await dataService.packs.enablePackAsync(packRequest);
        return {
          operationId: data.operationId,
          stepsIds: data.stepsIds,
          statusUrl: data.statusUrl,
        };
      },
      successTransientMessage: "Pack successfully purchased",
      failureTransientMessage: "Failed to purchase pack",
      indeterminate: true,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};
