import { Dispatch } from "@reduxjs/toolkit";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import { getBatchAddEntityTagsThunk } from "../../../Common/thunkBuilders/batchAddEntityTagsThunkBuilder";
import eventsDataService from "../../services/eventsDataService";
import { setTags, setError } from "../slices/eventCommonSlice";

export const fetchEventsTags = () => async (dispatch: Dispatch) => {
  try {
    const res = await eventsDataService.getEventsTagsAsync(true);
    dispatch(setTags(res.data));
  } catch (e) {
    dispatch(setError(e as Error));
  }
};

const getMessageIds = (eventIds: number[], tags: string[], tagType: TagsEnum) => async () => {
  const response = await eventsDataService.addEventTags({ ids: eventIds, tags, tagType });
  return response?.data.messageIds;
};

export const addExternalEventTags = getBatchAddEntityTagsThunk("ExternalEvent", getMessageIds);
