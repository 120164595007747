import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { TagsPayloadCamelPlural } from "../../../../../interfaces/Tags";

export type SurveyTagsPayload = {
  [key: string]: string[];
};

export interface SurveyCommonState {
  tags: SurveyTagsPayload;
  error?: Error;
}

const initialState: SurveyCommonState = {
  tags: {},
  error: undefined,
};

const surveyCommonSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Surveys}/common`,
  initialState: initialState,
  reducers: {
    setTags(state: SurveyCommonState, action: PayloadAction<TagsPayloadCamelPlural>) {
      state.tags = action.payload;
    },
    setError(state: SurveyCommonState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    reset(state: SurveyCommonState) {
      state.tags = initialState.tags;
      state.error = initialState.error;
    },
  },
});

export const { setTags, setError, reset } = surveyCommonSlice.actions;

export default surveyCommonSlice.reducer;
export type surveyBaseSliceStateType = ReturnType<typeof surveyCommonSlice.reducer>;
