import * as Yup from "yup";
import validationRules from "../validationRules";

import { labels, softwareApplications } from "./tagsValidationSchema";

const nameLengths = {
  min: 3,
  max: 64,
};

const name = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Name is required")
    .min(nameLengths.min, `Name must have at least ${nameLengths.min} characters`)
    .max(nameLengths.max, `Name must be maximum of ${nameLengths.max} characters long`);

export const landingPageInfo = Yup.object().shape({
  name: name(),
  labels,
  softwareApplications,
});

export const landingPageContent = Yup.object().shape({
  html: validationRules.htmlWithoutBraistormDomains(),
});
