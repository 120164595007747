import React from "react";
import ModalSizes from "../../../enums/modalSizes";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface RemoveContactConfirmationModalProps {
  onCancel(): void;
  onContinue(): void;
  size?: ModalSizes;
  open: boolean;
}

const RemoveContactConfirmationModal = ({ onCancel, onContinue, size, open }: RemoveContactConfirmationModalProps) => (
  <ActionConfirmationModal
    open={open}
    onCancel={onCancel}
    onContinue={onContinue}
    title="Remove this contact?"
    size={size}
    withWarningLabel={false}
    confirmLabel="Continue"
  >
    Removing this contact will remove any specific notifications for this user. Are you sure you want to continue?
  </ActionConfirmationModal>
);

export default RemoveContactConfirmationModal;
