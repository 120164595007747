import { ContentSegmentationDropdown, SearchInput } from "components";
import { ContentTypesEnum, RouteNames, SortingDirection, ViewType } from "enums";
import { PeopleType } from "features/Library/PeopleAssignments/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fetchTemplateContent } from "../../state/thunks/TemplateContentThunk";
import { bindAction } from "interfaces";
import { fetchTemplateContentFilters } from "../../state/thunks/TemplateContentFiltersThunk";
import { setSearch, resetAppliedFilter, setAppliedFilter, resetFilter } from "../../state/TemplateContentFiltersSlice";
import { reset, resetItems, setSelected } from "../../state/TemplateContentSlice";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { useReloadItemViewListItems } from "hooks/useReloadItemViewListItems";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  ContentColumns,
  ContentItem,
  FlowItem,
  SelectedContentMap,
  TemplateContent,
  VideoItem,
} from "../../types/models";
import { ColumnOption } from "interfaces/columnOptions";
import {
  AssessmentFilterForm,
  FlowFilterForm,
  PdfsFilterForm,
  SurveyFilterForm,
  VideosFilterForm,
} from "components/filterForms";
import FlowsNoResults from "views/library/flows/flowsOverview/FlowsNoResults";
import { PeopleEventCard, PeopleFlowCard } from "components/contentAssignment/cards";
import { CardsViewerItem } from "components/cardsViewer/types";
import { VideoAssetsNoResults } from "views/library/videos/VideoAssetsNoResults/VideoAssetsNoResults";
import PeopleVideoCard from "components/cards/VideoCard/People/PeopleVideoCard";
import { EventFilterForm } from "components/filterForms/EventFilterForm/EventFilterForm";
import EventsNoResults from "views/library/events/Overview/EventsNoResults";
import SurveysNoResults from "views/library/surveys/Overview/SurveysNoResults";
import AssessmentsNoResults from "views/library/assessments/Overview/AssessmentsNoResults";
import PdfsNoResults from "views/library/pdfs/contentAssignment/PdfsNoResults";
import { PeoplePdfCard } from "components/contentAssignment/cards/peoplePdfCard/PeoplePdfCard";
import { isEmpty, noop } from "lodash";
import { FiltersMap } from "utils/filterUtils";
import { getColumnOptions, getFlowColumnOptions } from "./getColumnOptions";
import { HandleOnSelectionChanged, SelectionChangedArgs } from "interfaces/onSelectionChanged";
import {
  RemoveAllFlowGroupPrioritySuccess,
  SetAllFlowGroupPrioritySuccess,
} from "features/Application/services/realTimeNotification/events/library/libraryEvents";
import { useRtn } from "hooks/useRtn";

type Props = PropsFromRedux & {
  templateId: number;
  contentMap: SelectedContentMap;
  contextMenuButtonHandlers: {
    onEditPriority: (item: FlowItem) => void;
    onClearPriority: (ids: number[]) => void;
  };
};

const rtnEvents = [SetAllFlowGroupPrioritySuccess, RemoveAllFlowGroupPrioritySuccess];

export const PeopleContent = (props: Props) => {
  const {
    items,
    isLoading,
    itemsCount,
    resetOverview,
    resetItems,
    onSearchChanged,
    applyFilter,
    resetAppliedFilter,
    resetFilterState,
    filters,
    fetchContent,
    fetchFilters,
    templateId,
    areAllLoaded,
    selected,
    contentMap,
    setSelectedIds,
    contextMenuButtonHandlers,
    isPreview,
  } = props;
  const [selectedViewType, setSelectedViewType] = useState<ViewType>(ViewType.GRID);
  const [gridSortBy, setGridSortBy] = useState<[string, SortingDirection]>([...defaultGridSorting]);
  const isGridView = selectedViewType === ViewType.GRID;
  const [context, setContext] = useState(ContentTypesEnum.Flows);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      resetFilterState();
      resetOverview();
      contentMap.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetch = (skip: number, top: number, sortBy: string, sortOrder: SortingDirection, fromStart = false) => {
    if (isGridView) {
      fetchGrid(fromStart);
    } else {
      fetchContent(templateId, context, skip, top, sortBy, sortOrder, fromStart);
    }
  };

  const fetchGrid = useCallback(
    (fromStart: boolean, sorting?: [string, SortingDirection]) => {
      const [sortBy, sortOrder] = sorting ?? gridSortBy;
      fetchContent(templateId, context, fromStart ? 0 : items.length, 30, sortBy, sortOrder, !fromStart);
    },
    [context, fetchContent, gridSortBy, items.length, templateId],
  );

  const refreshContentGrid = useCallback(() => {
    if (isGridView) {
      resetItems();
      fetchGrid(true);
    }
  }, [isGridView, resetItems, fetchGrid]);

  useRtn(rtnEvents, refreshContentGrid);

  const fetchListView = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = ContentColumns.Title,
    sortingDirection: SortingDirection = SortingDirection.Descending,
  ) => {
    const sortBy = columnToParamMap[sortingColumnName.toLowerCase() as Lowercase<ContentColumns>];
    handleFetch(skip, top, sortBy, sortingDirection);
  };

  const [setReloadListItems, handleSearchChange] = useReloadItemViewListItems((term) => {
    onSearchChanged(term);
    if (isGridView) {
      resetItems();
      fetchGrid(true);
    }
  });

  const onSortChange = (_: React.SyntheticEvent<HTMLElement>, data: { value: [string, SortingDirection] }) => {
    resetItems();
    setGridSortBy(data.value);
    fetchGrid(true, data.value);
  };

  const onViewTypeChange = (viewType: ViewType) => {
    setSelectedViewType(viewType);
    resetItems();
    if (viewType === ViewType.GRID) {
      fetchGrid(true);
    }
  };

  const onSelectedItemsChanged = (ids: number[]) => {
    setSelectedIds({ context, selected: ids });
  };

  const onSelectionChanged = (args: SelectionChangedArgs<TemplateContent>) => {
    const onAdded = (item: TemplateContent) => {
      contentMap?.set(item.id, item);
    };

    const onRemoved = (item: TemplateContent) => {
      contentMap?.delete(item.id);
    };
    HandleOnSelectionChanged(args, onAdded, onRemoved);
  };

  const handleApplyFilter = (filter: FiltersMap) => {
    applyFilter(filter);
    resetItems();
    if (isGridView) {
      fetchGrid(true);
    }
  };

  const handleResetFilter = () => {
    resetAppliedFilter();
    resetItems();
    if (isGridView) {
      fetchGrid(true);
    }
  };

  const handleFiltersFetch = () => {
    fetchFilters(context);
  };

  const dropdown = (
    <ContentSegmentationDropdown
      onContextChange={(ctx: ContentTypesEnum) => {
        setSelectedViewType((prev) => {
          return contentPropsPovider(ctx, navigate, selected, contextMenuButtonHandlers, isPreview).renderCard
            ? prev
            : ViewType.LIST;
        });
        resetOverview();
        resetFilterState();
        setContext(ctx);
        contentMap.clear();
      }}
      defaultValue={ContentTypesEnum[context]}
    />
  );

  const {
    getNoResults,
    getColumnOption,
    filterForm: FilterForm,
    renderCard,
  } = useMemo(
    () => contentPropsPovider(context, navigate, selected, contextMenuButtonHandlers, isPreview),
    [context, navigate, selected, contextMenuButtonHandlers, isPreview],
  );
  const noResults = useMemo(
    () => getNoResults(!isEmpty(filters.appliedFilter) || !isEmpty(filters.search)),
    [filters.appliedFilter, filters.search, getNoResults],
  );
  const columnOptions = useMemo(() => getColumnOption() as ColumnOption<TemplateContent>[], [getColumnOption]);

  return (
    <GenericItemsView
      key={context}
      items={items}
      viewType={selectedViewType}
      isLoading={isLoading}
      columnOptions={columnOptions}
      fetchData={fetchListView}
      dataCount={itemsCount}
      customHeaderContent={dropdown}
      renderSearch={() => <SearchInput placeholder={`Search for ${context}`} onChange={handleSearchChange} />}
      // @ts-ignore
      getFilterForm={() => <FilterForm />}
      applyFilter={handleApplyFilter}
      resetFilter={handleResetFilter}
      setReloadListItems={setReloadListItems}
      appliedFilter={filters.appliedFilter}
      filterOptions={filters.filterOptions}
      noResultsContent={noResults}
      filterOptionsLoading={filters.isLoading}
      getFilterOptions={handleFiltersFetch}
      renderCard={renderCard}
      sortOptions={gridSortOptions}
      defaultSortingColumnName={ContentColumns.Title}
      onSortChange={onSortChange}
      onViewTypeChange={onViewTypeChange}
      disableListViewButton={!renderCard}
      doNotLoadPersistentViewType={!renderCard}
      isAllDataLoaded={areAllLoaded}
      onLoad={isGridView ? () => fetchGrid(true) : noop}
      selectedIds={selected[context]}
      onSelectedItemChanged={onSelectedItemsChanged}
      onSelectedGridItemsChanged={onSelectedItemsChanged}
      onSelectionChanged={onSelectionChanged}
      listViewRtnEvents={rtnEvents}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const template = state.people.groupTemplate;
  const { content, filters } = template.content;

  return {
    ...content,
    filters,
    isPreview: !!template.base.isPreview,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetOverview: () => dispatch(reset()),
    resetItems: () => dispatch(resetItems()),
    setSelectedIds: bindAction(setSelected, dispatch),
    fetchContent: bindAction(fetchTemplateContent, dispatch),
    fetchFilters: bindAction(fetchTemplateContentFilters, dispatch),
    onSearchChanged: bindAction(setSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetAppliedFilter: bindAction(resetAppliedFilter, dispatch),
    resetFilterState: bindAction(resetFilter, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PeopleContent);

export const columnToParamMap: {
  [P in Lowercase<ContentColumns>]: string;
} = {
  title: "title",
  status: "isDraft",
  publisher: "publisher",
  "priority level": "priorityLevelId",
  objective: "goalObjectiveId",
};

const defaultGridSorting = ["dateCreated", SortingDirection.Descending] as const;
export const gridSortOptions = [
  {
    text: "Most Recent",
    value: defaultGridSorting,
    default: true,
  },
  {
    text: "Last Modified",
    value: ["dateModified", SortingDirection.Descending],
  },
  {
    text: "Sorted A-Z",
    value: ["title", SortingDirection.Ascending],
  },
];

/* istanbul ignore next */
const getContentPropsMap = (
  navigate: NavigateFunction,
  selected: { [key in ContentTypesEnum]?: number[] },
  contextMenuButtonHandlers: { onEditPriority: (item: FlowItem) => void; onClearPriority: (ids: number[]) => void },
  isPreview: boolean,
) => ({
  [ContentTypesEnum.Flows]: {
    filterForm: FlowFilterForm,
    getColumnOption: () =>
      getFlowColumnOptions({
        onTitleClick: (id: number) => navigate(`/${RouteNames.contentFlows}/${id}`),
        selectedIds: selected.Flows ?? [],
        contextMenuButtonHandlers: contextMenuButtonHandlers,
        isPreview,
      }),
    renderCard: (props: CardsViewerItem<FlowItem>) => (
      <PeopleFlowCard
        {...props}
        peopleType={PeopleType.Group}
        onEditPriority={() => contextMenuButtonHandlers.onEditPriority(props.item)}
        onRemove={() => contextMenuButtonHandlers.onClearPriority([props.item.id])}
        deepLink={!isPreview}
        disablePopupMenu={isPreview}
      />
    ),
    getNoResults: (filtered: boolean) => <FlowsNoResults byCriteria={filtered} />,
  },
  [ContentTypesEnum.Videos]: {
    filterForm: VideosFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentVideos}/${id}`), isPreview),
    renderCard: (item: CardsViewerItem<VideoItem>) => (
      <PeopleVideoCard {...item} disablePopupMenu deepLink={!isPreview} />
    ),
    getNoResults: (filtered: boolean) => <VideoAssetsNoResults filtered={filtered} />,
  },
  [ContentTypesEnum.Events]: {
    filterForm: EventFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentEvents}/${id}`), isPreview),
    renderCard: (item: CardsViewerItem<ContentItem>) => (
      <PeopleEventCard {...item} peopleType={PeopleType.Group} disablePopupMenu deepLink={!isPreview} />
    ),
    getNoResults: (filtered: boolean) => <EventsNoResults filtered={filtered} />,
  },
  [ContentTypesEnum.PDFs]: {
    filterForm: PdfsFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentPdfs}/${id}`), isPreview),
    renderCard: (item: CardsViewerItem<ContentItem>) => (
      <PeoplePdfCard {...item} peopleType={PeopleType.Group} deepLink={!isPreview} />
    ),
    getNoResults: (filtered: boolean) => <PdfsNoResults filtered={filtered} />,
  },
  [ContentTypesEnum.Assessments]: {
    filterForm: AssessmentFilterForm,
    getColumnOption: () =>
      getColumnOptions((id: number) => navigate(`/${RouteNames.contentAssessments}/${id}`), isPreview),
    getNoResults: (filtered: boolean) => <AssessmentsNoResults filtered={filtered} />,
    renderCard: undefined,
  },
  [ContentTypesEnum.Surveys]: {
    filterForm: SurveyFilterForm,
    getColumnOption: () => getColumnOptions((id: number) => navigate(`/${RouteNames.contentSurveys}/${id}`), isPreview),
    getNoResults: (filtered: boolean) => <SurveysNoResults filtered={filtered} />,
    renderCard: undefined,
  },
});

type ContentProps = ReturnType<typeof getContentPropsMap>[keyof ReturnType<typeof getContentPropsMap>];
type ContentMap = {
  [K in ContentTypesEnum]?: ContentProps;
};

const contentPropsPovider = (
  ctx: ContentTypesEnum,
  navigate: NavigateFunction,
  selected: { [key in ContentTypesEnum]?: number[] },
  contextMenuButtonHandlers: { onEditPriority: (item: FlowItem) => void; onClearPriority: (ids: number[]) => void },
  isPreview: boolean,
): ContentProps => {
  const map: ContentMap = getContentPropsMap(navigate, selected, contextMenuButtonHandlers, isPreview);
  return map[ctx]!;
};
