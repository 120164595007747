import { DraggableAsset, DraggableDroppedAsset, DroppedItem } from "../../../interfaces/assetToDropInfo";

interface IndexedCards extends DroppedItem {
  index: number;
}

export const getAssets = (
  haveDuplications: boolean,
  assets: DraggableAsset[],
  cards: IndexedCards[],
): [DraggableAsset[], string | undefined] => {
  let message: string | undefined;
  const assetsWithoutDuplications = assets.filter(
    (asset) => !cards.some((c) => c.id === asset.id && c.type === asset.type),
  );

  if (haveDuplications && assets.length - assetsWithoutDuplications.length > 1) {
    message = "Some of the Items already added";
  }

  return [assetsWithoutDuplications, message];
};

export const mapToDragItem = (item: DraggableDroppedAsset | DraggableAsset): DraggableAsset => ({
  id: item.id,
  title: item.title,
  thumbnailUrl: item.thumbnailUrl || process.env.REACT_APP_PLACEHOLDER_IMAGE_URL,
  isReadOnly: item.isReadOnly,
  elementType: item.elementType as string,
  type: item.elementType,
});
