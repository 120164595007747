import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FC, useCallback, useState } from "react";
import { ContentTypesEnum } from "../../enums";
import { IObservable } from "../../interfaces/IObservable";
import { pluralize } from "../../utils/stringUtils";
import TriggeredConfirmationModal from "../triggeredConfirmationModal/TriggerableConfirmationModal";

export interface RemoveContentObserverInput {
  selectedItemsCount: number;
  flowsCount?: number;
  packsCount?: number;
}

export interface DeleteContentConfirmationModalProps {
  contentType: ContentTypesEnum;
  onTriggerRemoveContentObserver: IObservable<
    (onRemoveConfirm: () => void, removeContentInput: RemoveContentObserverInput) => void
  >;
  content?: string;
}

const ContentTypeToStringMap: { [P in ContentTypesEnum]: string } = {
  [ContentTypesEnum.Assessments]: "Assessment",
  [ContentTypesEnum.Assets]: "Asset",
  [ContentTypesEnum.Events]: "Event",
  [ContentTypesEnum.Flows]: "Flow",
  [ContentTypesEnum.Links]: "Link",
  [ContentTypesEnum.Surveys]: "Survey",
  [ContentTypesEnum.Videos]: "Video",
  [ContentTypesEnum.Emails]: "Email",
  [ContentTypesEnum.Messages]: "Message",
  [ContentTypesEnum.PDFs]: "PDF",
};

export const DeleteContentConfirmationModal: FC<DeleteContentConfirmationModalProps> = (
  props: DeleteContentConfirmationModalProps,
) => {
  const { contentType, onTriggerRemoveContentObserver, content } = props;
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const [{ selectedItemsCount, flowsCount, packsCount }, setRemoveContentInput] = useState<RemoveContentObserverInput>({
    flowsCount: 0,
    packsCount: 0,
    selectedItemsCount: 0,
  });

  const getContent = useCallback(() => {
    const flows = flowsCount ?? 0;
    const packs = packsCount ?? 0;

    if (flows > 0 && packs === 0) {
      return (
        <>
          This action will permanently delete the asset(s) from the associated flow(s).{" "}
          <b>This includes the loss of user access, progress, and reporting data</b> and cannot be undone. Are you sure
          you want to proceed?
        </>
      );
    }

    if (flows === 0 && packs > 0) {
      return (
        <>
          This action will permanently delete the content from the associated pack(s).{" "}
          <b>This includes the loss of user access, progress, and reporting data</b> and cannot be undone. Are you sure
          you want to proceed?
        </>
      );
    }

    if (flows > 0 && packs > 0) {
      return (
        <>
          This action will permanently delete the asset(s) from the associated pack(s) and flow(s).{" "}
          <b>This includes the loss of user access, progress, and reporting data</b> and cannot be undone. Are you sure
          you want to proceed?
        </>
      );
    }

    return (
      <>
        This action will permanently{" "}
        <b>delete the content along with all associated user access, progress, and reporting data</b>. This action
        cannot be undone. Are you sure you want to proceed?
      </>
    );
  }, [flowsCount, packsCount]);

  const contentTypeString = pluralize(ContentTypeToStringMap[contentType], selectedItemsCount);
  let defaultContent = `Delete ${selectedItemsCount} ${contentTypeString}? This will remove all data and cannot be undone.`;

  if (!!content) {
    defaultContent = content;
  }
  return (
    <TriggeredConfirmationModal
      title={`Delete ${contentTypeString}?`}
      content={deleteContentEnabled ? getContent() : defaultContent}
      confirmButtonLabel={"Delete"}
      onTriggerModalObserver={onTriggerRemoveContentObserver}
      onTrigger={(input: RemoveContentObserverInput) => {
        setRemoveContentInput(input);
      }}
    />
  );
};

export default DeleteContentConfirmationModal;
