import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { buildFetchAction } from "../../../../Application/actions/actionsBuilder";
import { PackOverview } from "../../../../Licensing/Packs/types/state";

export const Permissions = "PERMISSIONS";
export const Roles = "ROLES";

export const FETCH_PERMISSIONS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.AddUser,
  Permissions,
);
export const FETCH_ROLES = buildFetchAction(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.AddUser, Roles);

export const FINISH_USER_CREATION = "FINISH_USER_CREATION";
export const NOTIFY_USER = "NOTIFY_USER";
export const UPDATE_ROLE_ASSIGNMENT = "UPDATE_ROLE_ASSIGNMENT";
export const ADD_USER_CANCEL = "ADD_USER_CANCEL";

export const USER_INFO_SAVE_BEGIN = "USER_INFO_SAVE_BEGIN";
export const USER_INFO_SAVE_SUCCESS = "USER_INFO_SAVE_SUCCESS";
export const USER_INFO_SAVE_FAILURE = "USER_INFO_SAVE_FAILURE";

export const FETCH_LICENSES_BEGIN = "FETCH_LICENSES_BEGIN";
export const FETCH_LICENSES_SUCCESS = "FETCH_LICENSES_SUCCESS";
export const RESET_LICENSES_ITEMS = "RESET_LICENSES_ITEMS";

export const USER_INFO_ROLES_CHANGED = "USER_INFO_ROLES_CHANGED";

export interface SaveUserInfoBegin {
  type: typeof USER_INFO_SAVE_BEGIN;
  payload?: any;
}

export interface SaveUserInfoSuccess {
  type: typeof USER_INFO_SAVE_SUCCESS;
  payload?: any;
}

export interface SaveUserInfoFailure {
  type: typeof USER_INFO_SAVE_FAILURE;
  payload?: any;
}

export interface FinishUserCreation {
  type: typeof FINISH_USER_CREATION;
  payload?: any;
}

export interface UpdateRoleAssignment {
  type: typeof UPDATE_ROLE_ASSIGNMENT;
  payload?: any;
}

export interface AddUserCancel {
  type: typeof ADD_USER_CANCEL;
  payload?: any;
}

export interface UserInfoRolesChanged {
  type: typeof USER_INFO_ROLES_CHANGED;
  payload?: any;
}

export interface FetchLicensesSuccess {
  type: typeof FETCH_LICENSES_SUCCESS;
  payload: { items: PackOverview[]; totalCount: number };
}

export type SaveUserInfo = SaveUserInfoBegin | SaveUserInfoSuccess | SaveUserInfoFailure;
