import { TagsEnum } from "../../interfaces/TagsEnum";

export type TagEntity = {
  [key in TagsEnum]: string;
};

export const tagEntity: TagEntity = {
  [TagsEnum.Label]: "Label",
  [TagsEnum.SoftwareApplication]: "Software Application",
};

export const tagEntityPlural: TagEntity = {
  [TagsEnum.Label]: "Labels",
  [TagsEnum.SoftwareApplication]: "Software Applications",
};
