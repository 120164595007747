import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";

import { ReactComponent as Unaware } from "../../../images/privacy-unaware.svg";
import { ReactComponent as Aware } from "../../../images/privacy-aware.svg";

export default function GroupAwarenessIcon(props) {
  const { isAware } = props;

  const popupTrigger = isAware ? <Aware className="aware-icon" /> : <Unaware className="unaware-icon" />;

  const popupHeader = isAware ? "AWARE" : "UNAWARE";

  const popupContent = isAware
    ? "Members are aware that this group exists."
    : "Members are unaware that this group exists.";

  return (
    <Popup position="top left" id="awarePopup" trigger={<span>{popupTrigger}</span>} inverted>
      <Popup.Header>{popupHeader}</Popup.Header>
      <Popup.Content>{popupContent}</Popup.Content>
    </Popup>
  );
}

GroupAwarenessIcon.defaultProps = {
  isAware: false,
};

GroupAwarenessIcon.propTypes = {
  isAware: PropTypes.bool,
};
