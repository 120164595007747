import React from "react";
import cn from "classnames";
import { Button } from "components/buttons/button/Button";
import { Tooltip } from "../common/tooltip";

import "./wizardFooter.scss";

interface Props {
  activeIndex: number;
  stepsCount: number;
  onNext: () => void;
  onPrev: () => void;
  onFinish: () => void;
  onPreviewFinishAsync?: () => Promise<boolean>;
  renderCustomFinishButton?: (onFinish: () => Promise<void>) => React.ReactElement;
  isPreviousDisabled?: boolean;
  isNavigationLocked?: boolean;
  isFinishButtonDisabled?: boolean;
  nextButtonLabel?: string;
  finishButtonLabel?: string;
  finishTooltipMessage?: string;
}

const WizardFooter: React.FC<Props> = (props) => {
  const isFirstStep = () => {
    return props.activeIndex === 0;
  };

  const isLastStep = () => {
    return props.activeIndex + 1 === props.stepsCount;
  };

  const onFinishHandler = async () => {
    if (props.onPreviewFinishAsync && !(await props.onPreviewFinishAsync())) {
      return;
    }
    props.onFinish();
  };

  const renderNextButton = () => {
    const {
      activeIndex,
      onNext,
      stepsCount,
      isNavigationLocked,
      isFinishButtonDisabled,
      nextButtonLabel,
      finishTooltipMessage,
      finishButtonLabel = "Finish & Publish",
    } = props;
    const nextLabel = nextButtonLabel ? nextButtonLabel : "Next";
    if (isFirstStep() && !nextButtonLabel) {
      return (
        <Button
          blur
          primary
          fluid
          id="wizard-save-and-continue"
          className="finish control"
          onClick={onNext}
          disabled={isNavigationLocked}
        >
          Save & Continue
        </Button>
      );
    }
    if (stepsCount === activeIndex + 1) {
      return (
        <Tooltip
          showAlways={!!finishTooltipMessage}
          inverted
          hideOnScroll
          position="right center"
          content={finishTooltipMessage}
          className="control"
          target={
            <Button
              primary
              fluid
              id="wizard-finish"
              data-testid="wizard-finish"
              className="finish"
              onClick={onFinishHandler}
              disabled={isNavigationLocked || isFinishButtonDisabled}
            >
              {finishButtonLabel}
            </Button>
          }
        />
      );
    } else {
      return (
        <Button
          blur
          primary
          fluid
          id="wizard-next"
          className="next control"
          onClick={onNext}
          disabled={isNavigationLocked}
        >
          {nextLabel}
        </Button>
      );
    }
  };

  const renderControls = () => {
    const { activeIndex, onPrev, renderCustomFinishButton, isPreviousDisabled = false } = props;
    const classNames = cn("controls", { "first-step": isFirstStep() });
    return (
      <div className={classNames}>
        <Button
          blur
          primary
          fluid
          className="previous control"
          disabled={activeIndex === 0 || isPreviousDisabled}
          onClick={onPrev}
        >
          Previous
        </Button>
        {isLastStep() && renderCustomFinishButton ? renderCustomFinishButton(onFinishHandler) : renderNextButton()}
      </div>
    );
  };

  return <div className="footer">{renderControls()}</div>;
};

export default WizardFooter;
