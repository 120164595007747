import midnightService from "../../../../../Application/services/midnightService/midnightService";
import * as actionTypes from "../actionTypes/threatDefenceCampaignEntityStateActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../../enums/draftEntityTypes";
import { ThreatDefenceCampaign, ThreatDefenceCampaignContent } from "../../types/state";
import { Dispatcher, MidnightActionPayload } from "../../../../../../interfaces/redux";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { setCampaignAction } from "./threatDefenceCampaignDetailsActions";
import { saveThreatDefenceCampaignContent } from "../actionTypes/threatDefenceCampaignDetailsActionTypes";
import { beginAsyncOperation } from "../../../../../Application/slices/asyncOperationSlice";
import {
  CampaignEditSuccess,
  CampaignContentEditSuccess,
  CampaignPublishSuccess,
  CampaignLockSuccess,
  CampaignDiscardSuccess,
} from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const createThreatDefenceCampaign = (data: ThreatDefenceCampaign) => {
  const begin = getActionBaseProvider(actionTypes.createThreatDefenceCampaignBegin);
  const success = getActionProvider<MidnightActionPayload>(actionTypes.createThreatDefenceCampaignSuccess);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.createThreatDefenceCampaignFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(draftEntityTypes.Campaigns, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceCampaignEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.publishThreatDefenceCampaignBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.publishThreatDefenceCampaignFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: CampaignPublishSuccess }));
    try {
      await midnightService.releaseLock(draftEntityTypes.Campaigns, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceCampaignSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.publishThreatDefenceCampaignSuccess,
);

export const updateThreatDefenceCampaign = (metadata: ThreatDefenceCampaign) => {
  const begin = getActionBaseProvider(actionTypes.updateThreatDefenceCampaignBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceCampaignFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: metadata.id!, action: CampaignEditSuccess }));
    try {
      await dataService.threatDefence.updateThreatDefenceCampaignAsync(metadata);
      dispatch(setCampaignAction(metadata));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const updateThreatDefenceCampaignContent = (data: ThreatDefenceCampaignContent) => {
  const begin = getActionBaseProvider(actionTypes.updateThreatDefenceCampaignBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.updateThreatDefenceCampaignFailure);
  const saveContentAction = getActionProvider<ThreatDefenceCampaignContent>(saveThreatDefenceCampaignContent);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: data.id, action: CampaignContentEditSuccess }));
    try {
      await dataService.threatDefence.updateThreatDefenceCampaignContentAsync(data);
      dispatch(saveContentAction(data));
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const updateThreatDefenceCampaignCommandSuccess = getActionProvider(
  actionTypes.updateThreatDefenceCampaignCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const threatDefenceCampaignUpdated = getActionProvider(
  actionTypes.threatDefenceCampaignUpdated,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const resetThreatDefenceCampaignEntityState = getActionBaseProvider(
  actionTypes.resetThreatDefenceCampaignEntityState,
);

export const setCampaignEntityIdAction = getActionProvider<number>(actionTypes.setCampaignEntityId);

export const fetchDraftThreatDefenceCampaignEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedThreatDefenceCampaignBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedThreatDefenceCampaignFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: CampaignLockSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.Campaigns, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchLockedTdCampaignSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchLockedThreatDefenceCampaignSuccess,
);

export const discardCampaign = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.discardCampaignBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.discardCampaignFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: CampaignDiscardSuccess }));
    try {
      await midnightService.discardLock(draftEntityTypes.Campaigns, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const discardCampaignSuccess = getActionProvider<MidnightActionPayload>(actionTypes.discardCampaignSuccess);
