import { ModalWithStepsStep } from "../../../../../modal/ModalWithSteps";
import { SortingDirection, ViewType } from "../../../../../../enums";

import { GroupListRow, columnOptions } from "./groupListRow/GroupListRow";
import { GroupInfo } from "../../../../../../interfaces";

import "../../../../assignmentListStep.scss";
import Restricted from "../../../../../../features/Application/Restricted";
import AccessRestrictedMessage from "../../../../../restrictedRoute/AccessRestrictedMessage";
import { GroupAvailableForLicensing } from "../../types";
import { connect } from "react-redux";
import { ItemsView } from "../../../../../../views";
import { ItemsTypes } from "../../../../../../enums/itemsTypes";

import "./groupListStep.scss";
import GroupsNoResults from "../../../../../../features/People/Groups/GroupsOverview/GroupsNoResults/GroupsNoResults";

export interface GroupListStepProps extends ModalWithStepsStep {
  accountId: number;
  groups: GroupInfo[];
  groupsAmount: number;
  loadPage: (
    skip: number,
    top: number,
    sortingColumnName: any,
    sortingDirection: SortingDirection,
    filter: any,
  ) => void;
  isLoading: boolean;
  selectedGroupIds: number[];
  onSelectedGroupsChanged: (groupIds: number[]) => void;
  hasPermissionPredicate: (userPermissions: string[]) => boolean;
  setReloadListItems?: (reloadItems: (enableSorting: boolean) => void) => void;
}

const GroupListStep = (props: GroupListStepProps) => {
  const groupsForLicensing: GroupAvailableForLicensing[] = props.groups.map((group) => ({
    id: group.id,
    name: group.name,
    membersCount: group.membersCount,
  }));

  return (
    <Restricted
      permissions={[]}
      placeholder={<AccessRestrictedMessage />}
      permissionPredicate={(userPermissions: string[], _) => props.hasPermissionPredicate(userPermissions)}
    >
      <div className="group-list-step">
        <ItemsView
          columnOptions={columnOptions}
          hideListGridViewSwitcherButton={true}
          viewType={ViewType.LIST}
          getData={props.loadPage}
          itemsInlineCount={props.groupsAmount}
          isLoading={props.isLoading}
          items={groupsForLicensing}
          itemsType={ItemsTypes.Group}
          onSelectedListItemsChanged={props.onSelectedGroupsChanged}
          selectedIds={props.selectedGroupIds}
          buildTableBody={(group: GroupAvailableForLicensing) => <GroupListRow group={group} />}
          selectedItemIds={props.selectedGroupIds}
          noResultsContent={<GroupsNoResults />}
          sortingColumnName={columnOptions[0].name}
          sortingDirection={SortingDirection.Ascending}
        />
      </div>
    </Restricted>
  );
};

const mapStateToProps = (state: any) => {
  return {
    accountId: state.userProfile.accountId,
  };
};

const connector = connect(mapStateToProps);

export default connector(GroupListStep);
