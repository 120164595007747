import React from "react";
import { Button } from "components/buttons/button/Button";

import "./formFooter.scss";

export interface FormFooterProps {
  onCancel: any;
  onSave: any;
  isCancelBtnDisabled: boolean;
  isSaveBtnDisabled: boolean;
  isSaving?: boolean;
  saveButtonTitle?: string;
}

export const FormFooter: React.FC<FormFooterProps> = ({
  onCancel,
  onSave,
  isCancelBtnDisabled,
  isSaveBtnDisabled,
  isSaving,
  saveButtonTitle = "Save Changes",
}) => (
  <div className="form-footer">
    <Button
      basic
      color="blue"
      fluid
      className="cancel-btn"
      disabled={isCancelBtnDisabled || !!isSaving}
      onClick={onCancel}
    >
      Cancel
    </Button>
    <Button
      primary
      fluid
      className="save-btn"
      disabled={isSaveBtnDisabled || !!isSaving}
      loading={isSaving}
      onClick={onSave}
    >
      {saveButtonTitle}
    </Button>
  </div>
);

export default FormFooter;
