import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../../Application/actions/entityState/entityStateActionTypes";

export const createThreatDefenceCampaignBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN,
);

export const createThreatDefenceCampaignSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS,
);

export const createThreatDefenceCampaignFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE,
);

export const updateThreatDefenceCampaignBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);

export const updateThreatDefenceCampaignCommandSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const updateThreatDefenceCampaignFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);

export const threatDefenceCampaignUpdated = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);

export const resetThreatDefenceCampaignEntityState = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const publishThreatDefenceCampaignBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);
export const publishThreatDefenceCampaignSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);
export const publishThreatDefenceCampaignFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);

export const setCampaignEntityId = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.SET_ENTITY_ID,
);

export const fetchLockedThreatDefenceCampaignBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);

export const fetchLockedThreatDefenceCampaignSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);

export const fetchLockedThreatDefenceCampaignFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);

export const discardCampaignBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);

export const discardCampaignSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const discardCampaignFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceCampaigns,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);
