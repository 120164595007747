import { useState } from "react";
import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";
import { DeleteButton } from "../../../../../components/buttons/linkButtons/headerButtons/DeleteButton";
import { DuplicateButton } from "../../../../../components/buttons/linkButtons/headerButtons/DuplicateButton";
import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { RolePermissions } from "../../../../../enums";
import Restricted from "../../../../Application/Restricted";
import TagsManageContainer from "../containers/TagsManageContainer";

export interface SurveyOverviewHeaderProps {
  purchasedSurveySelected: boolean;
  undeletableSelected: boolean;
  draftSelected: boolean;
  selectedIds: Array<number>;
  surveyDuplicationHandler: (selectedIds: number[]) => () => void;
  surveyDeletionHandler: (selectedIds: number[]) => () => void;
  renderCreateButton: () => React.ReactElement;
  clearSelection: () => void;
}

const addToPacksPermissions = [RolePermissions.PacksManage, RolePermissions.FlowsCreate];

function SurveyOverviewHeader(props: SurveyOverviewHeaderProps) {
  const { selectedIds, purchasedSurveySelected, undeletableSelected, draftSelected } = props;
  const [packsModalShown, setPacksModalShown] = useState(false);

  const renderBatchButtons = () => {
    return (
      <>
        <Restricted permissions={addToPacksPermissions}>
          <AddToPacksButton
            onClick={() => setPacksModalShown(true)}
            isDisabled={purchasedSurveySelected || draftSelected}
          />
        </Restricted>
        <DuplicateButton
          entityName="surveys"
          purchasedSelected={purchasedSurveySelected}
          onClick={props.surveyDuplicationHandler(selectedIds)}
        />
        <DeleteButton
          entityName="surveys"
          undeletableSelected={undeletableSelected}
          purchasedSelected={purchasedSurveySelected}
          onClick={props.surveyDeletionHandler(selectedIds)}
        />
        <TagsManageContainer
          entity="assets"
          isDisabled={purchasedSurveySelected}
          selectedIds={selectedIds}
          onComplete={props.clearSelection}
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={/* istanbul ignore next */ () => setPacksModalShown(false)}
          onComplete={props.clearSelection}
          selectedItemIds={selectedIds}
          contentType="Survey"
        />
      </>
    );
  };

  const renderHeaderButtons = () => (selectedIds.length ? renderBatchButtons() : props.renderCreateButton());

  return (
    <OverviewHeader
      title="Surveys"
      titleForGA="Assets - Surveys"
      itemName="Survey"
      selectedItemsAmount={selectedIds.length}
    >
      {renderHeaderButtons()}
    </OverviewHeader>
  );
}

export default SurveyOverviewHeader;
