import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../Application/globaltypes/redux";
import { WizardWrapper as Wizard } from "../../../../WizardWrapper";
import WizardStepsManager from "../../../../../utils/WizardStepsManager";
import * as campaignEntityStateActions from "../state/actions/threatDefenceCampaignsEntityStateActions";
import * as campaignDetailsActions from "../state/actions/threatDefenceCampaignDetailsActions";
import { CreateCampaignState } from "./types";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import Configure from "../Details/Configure/Configure";
import Content from "../Details/Content/Content";
import navigationUtils from "../../../../../utils/navigationUtils";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const campaignsOverviewUrl = "/content/simulated-phishing/campaigns";

export type CreateCampaignPropsAll = PropsFromRedux & WithRouterProps;

export class CreateThreatDefenceCampaign extends Component<CreateCampaignPropsAll, CreateCampaignState> {
  private stepsManager = new WizardStepsManager();

  constructor(props: CreateCampaignPropsAll) {
    super(props);
    this.state = {
      isValid: false,
      canPublish: false,
    };
  }

  onCancel = () => {
    navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, "/content/simulated-phishing/campaigns");
  };

  componentDidMount() {
    RtnEventsEmitter.subscribe([rtnEvents.CampaignPublishSuccess], this.onPublishedEvent);
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe([rtnEvents.CampaignPublishSuccess], this.onPublishedEvent);
  }

  onPublishedEvent = () => this.props.navigate(campaignsOverviewUrl);

  onIsValidChange = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  };

  onContentIsValidChange = (isValid: boolean) => {
    if (this.state.canPublish !== isValid) {
      this.setState({ canPublish: isValid });
    }
  };

  render() {
    const { isValid, canPublish } = this.state;
    const { isSaving, dateModified } = this.props;

    return (
      <Wizard
        title="Create Campaign"
        titleForGA="Create Phishing Campaign"
        finishButtonLabel="Publish"
        onCancel={this.onCancel}
        onProgressAsync={(_: any, nextIndex: number) => this.stepsManager.onNext(nextIndex)}
        onRegressAsync={(_: any, nextIndex: number) => this.stepsManager.onPrevious(nextIndex)}
        onFinishAsync={this.stepsManager.onFinish}
        isSaveInProgress={isSaving}
        progressSavedDate={dateModified}
        isFinishButtonDisabled={!canPublish}
      >
        <Wizard.Step label="Configure" className="scrollable-content" isLocked={!isValid} required>
          <Configure
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 0)}
            onIsValidChange={this.onIsValidChange}
            disabled={false}
          />
        </Wizard.Step>
        <Wizard.Step label="Content" className="scrollable-content" required>
          <Content
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 1)}
            onIsValidChange={this.onContentIsValidChange}
            disabled={false}
          />
        </Wizard.Step>
      </Wizard>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isSaving: state.library.threatDefenceCampaigns.threatDefenceCampaignEntityStateReducer.isEntityCommandInProgress,
  dateModified: state.library.threatDefenceCampaigns.threatDefenceCampaignEntityStateReducer.lastModifiedDateTime,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(campaignEntityStateActions, dispatch),
  detailsActions: bindActionCreators(campaignDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(CreateThreatDefenceCampaign));
