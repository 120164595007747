import React, { Component } from "react";
import { PacksFilterForm } from "../../../../filterForms";
import PacksNoResults from "../../../../../views/packs/Overview/PacksNoResults";
import { PackGridStepOwnProps } from "../../types";
import { LazyCardsViewerWithHeaderAndPanel } from "../../../../cardsViewer";
import AddToAccountPackCard, { Item } from "./addToAccountPackCard/AddToAccountPackCard";
import { CardsViewerItem } from "../../../../cardsViewer/types";

export type PackGridStepProps = PackGridStepOwnProps;

export class PackGridStep extends Component<PackGridStepProps> {
  renderCard = (props: CardsViewerItem<Item>) => <AddToAccountPackCard {...props} />;

  render() {
    const {
      items,
      isLoading,
      filterOptions,
      appliedFilter,
      selectedIds,
      sortOptions,
      onSelectedItemsChanged,
      blur,
      onSortOptionsChanged,
      applyFilter,
      resetFilter,
      loadItemsToAddPage,
      itemsCount,
      renderSearch,
      isPacksModalFiltered,
      areAllLoaded,
      renderFilterForm,
      hideFilterPanel,
      createPackButton,
    } = this.props;
    return (
      <div className="assignment-grid-step">
        <LazyCardsViewerWithHeaderAndPanel
          getData={loadItemsToAddPage}
          items={items}
          itemsAmount={itemsCount}
          isLoading={isLoading}
          isAllLoaded={areAllLoaded}
          onSelectedItemsChanged={onSelectedItemsChanged}
          blur={blur}
          onSortOptionsChanged={onSortOptionsChanged}
          selectedItemIds={selectedIds}
          sortOptions={sortOptions}
          filter={appliedFilter}
          filterOptions={filterOptions}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          renderFilterForm={renderFilterForm ? renderFilterForm : () => <PacksFilterForm />}
          noResultsContent={<PacksNoResults filtered={isPacksModalFiltered} createButton={createPackButton?.()} />}
          renderCard={this.renderCard}
          renderSearch={renderSearch}
          hideFilterPanel={hideFilterPanel}
        />
      </div>
    );
  }
}

export default PackGridStep;
