import React, { Dispatch, SetStateAction, useMemo } from "react";
import { SavedFlowData } from "../../types";
import { useElements } from "../hooks/useElements";
import { IFlowEdge, IFlowNode } from "../nodes/types";
import { EdgeChange } from "reactflow";

import { NodeChange } from "@reactflow/core/dist/esm/types";

interface ReactFlowStateContextProps {
  edges: IFlowEdge[];
  nodes: IFlowNode[];
}

interface ReactFlowStateContextActionsProps {
  setNodes: Dispatch<SetStateAction<IFlowNode[]>>;
  setEdges: Dispatch<SetStateAction<IFlowEdge[]>>;
  onNodesChange: (changes: NodeChange[]) => void;
  onEdgesChange: (changes: EdgeChange[]) => void;
}

const defaultAction = (funcName: string) => () => {
  throw new Error(`'${funcName}()' is not defined`);
};

export const ReactFlowStateContext = React.createContext<ReactFlowStateContextProps>({
  edges: [],
  nodes: [],
});
export const ReactFlowStateContextActions = React.createContext<ReactFlowStateContextActionsProps>({
  setNodes: defaultAction("setNodes"),
  setEdges: defaultAction("setEdges"),
  onNodesChange: defaultAction("onNodesChange"),
  onEdgesChange: defaultAction("onEdgesChange"),
});

interface ReactFlowStateProviderProps {
  flowData: SavedFlowData | undefined;
  children: React.ReactNode;
}

export const ReactFlowStateProvider: React.FC<ReactFlowStateProviderProps> = ({ flowData, children }) => {
  const [nodes, edges, setNodes, setEdges, onNodesChange, onEdgesChange] = useElements(flowData);

  const canvasContextValue = useMemo(() => ({ edges, nodes }), [edges, nodes]);
  const canvasContextValueActions = useMemo(
    () => ({
      setNodes,
      setEdges,
      onNodesChange,
      onEdgesChange,
    }),
    [setNodes, setEdges, onNodesChange, onEdgesChange],
  );
  return (
    <ReactFlowStateContextActions.Provider value={canvasContextValueActions}>
      <ReactFlowStateContext.Provider value={canvasContextValue}>{children}</ReactFlowStateContext.Provider>
    </ReactFlowStateContextActions.Provider>
  );
};

export function useReactFlowState() {
  return React.useContext(ReactFlowStateContext);
}

export function useReactFlowActions() {
  return React.useContext(ReactFlowStateContextActions);
}
