import { combineReducers } from "redux";
import identityProvidersOverviewReducer from "../../IdentityProvidersOverview/state/identityProvidersOverviewSlice";
import claimTypesSlice from "../slices/claimTypesSlice";
import encodingTypesSlice from "../slices/encodingTypesSlice";
import identityProviderConfigurationSlice from "../slices/identityProviderConfigurationSlice";
import identityProviderAttributeMappingSlice from "../slices/identityProviderAttributeMappingSlice";
import identityProviderSamlSettingsSlice from "../slices/identityProviderSamlSettingsSlice";
import signatureAlgorithmsSlice from "../slices/signatureAlgorithmsSlice";
import signingCertificatesSlice from "../slices/signingCertificatesSlice";

const identityProvidersReducer = combineReducers({
  overview: identityProvidersOverviewReducer,
  claimTypes: claimTypesSlice,
  encodingTypes: encodingTypesSlice,
  signatureAlgorithms: signatureAlgorithmsSlice,
  signingCertificates: signingCertificatesSlice,
  editIdentityProvider: combineReducers({
    identityProviderConfiguration: identityProviderConfigurationSlice,
    attributeMapping: identityProviderAttributeMappingSlice,
    identityProviderSamlSettings: identityProviderSamlSettingsSlice,
  }),
});

export default identityProvidersReducer;

export type IdentityProvidersState = ReturnType<typeof identityProvidersReducer>;
