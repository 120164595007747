import { Icon } from "semantic-ui-react";
import styles from "../emailSettings.module.scss";
import ValidatedForm from "../../../../components/forms/ValidatedFormWithoutFormik";
import nameof from "../../../../utils/nameof";
import { VerifyEmailModel } from "../../types";
import { FormikProps } from "formik";

export interface VerifyEmailProps {
  isVerified: boolean | undefined;
  isVerifying: boolean;
  verify: () => void;
  receiverValidationEmail: string | undefined;
  formik: FormikProps<Partial<VerifyEmailModel>>;
  disabled: boolean;
  errorMessage: string | undefined;
}

export const VerifyEmail = (props: VerifyEmailProps) => {
  const renderVerifyIcon = () => {
    if (props.isVerified === undefined) {
      return <></>;
    }

    return (
      <>
        {props.isVerified ? (
          <>
            <Icon name={"check circle"} className={styles["icon-green"]} /> Verified
          </>
        ) : (
          <>
            <Icon name={"warning sign"} className={styles["icon-red"]} />
            <span className={styles["icon-red"]}>Error</span>
            <div>{props.errorMessage || "Unverified"}</div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className={styles["email-settings-header"]}>VERIFY SETTINGS</div>
      <div className={styles.info}>
        Enter your email address below and we will send you a verification email to confirm settings above are working
        properly.
      </div>
      <ValidatedForm.InputField
        propertyName={nameof<VerifyEmailModel>("receiverValidationEmail")}
        placeholder="myname@mydomain.com"
        action={{ color: "blue", content: "Verify", onClick: props.verify, disabled: props.disabled }}
        loading={props.isVerifying}
        value={props.receiverValidationEmail}
        iconPosition={props.isVerifying ? "left" : undefined}
        className={styles["verify-input"]}
        {...props.formik}
      />
      {renderVerifyIcon()}
    </>
  );
};
