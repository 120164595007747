import { useState, useEffect, useCallback } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { connect, ConnectedProps } from "react-redux";
import PublishValidationInfo from "../../../../components/publishValidationInfo/PublishValidationInfo";
import { WizardNew as Wizard } from "../../../../components/wizard";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import Configure from "../SurveyDetails/Configure/Configure";
import Content from "../SurveyDetails/Content/Content";
import { CreateSurveySteps, showLastModified } from "./types";
import { tryPublish, resetAction } from "../state/actions/surveyContentValidationActions";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { surveysStateSelector } from "../state/surveyReducer";
import { clearSurvey } from "../state/actions/surveyDetailsActions";
import { useWizardStepsManager } from "../../../../hooks/useWizardStepsManager";
import Section from "../../../../components/wizard/Section";
import { useRtn } from "../../../../hooks/useRtn";
import navigationUtils from "../../../../utils/navigationUtils";
import { PublishedStatusTypes, RouteNames } from "../../../../enums";
import SettingsTab from "../SurveyDetails/Settings/SettingsTab";
import { bindAction } from "../../../../interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetAppliedFilter,
  resetPagination,
  resetSearch,
  resetSortingColumnName,
  resetSortingDirection,
} from "../state/slices/surveyFiltersSlice";
import * as surveyOverviewActions from "../state/actions/surveyOverviewActions";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";

const overviewUrl = `/${RouteNames.contentSurveys}`;

export type CreateSurveyPropsAll = PropsFromRedux;
const pageIndexes = [CreateSurveySteps.Configuration, CreateSurveySteps.Settings, CreateSurveySteps.Content];
const events = [rtnEvents.SurveyPublishSuccess];
export const CreateSurvey = (props: CreateSurveyPropsAll) => {
  const isAssociatedPacksEnabled = useFeatureFlag(FeatureFlags.AssociatedPacks);
  const { resetSearchFilters } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isValid, setIsValid] = useState(false);
  const [showAddPacks, setShowAddPacks] = useState(false);
  const [wizardPages, { onNext, onPrevious, goToPage, onFinish }, subscribeOnActiveIndexChanged] =
    useWizardStepsManager(pageIndexes);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes(`/${RouteNames.contentSurveys}`)) resetSearchFilters();
    };
  }, [resetSearchFilters]);

  useEffect(
    () => {
      return () => {
        props.onUnmount();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const handler = (_: any, activeStepIndex: CreateSurveySteps) => {
      setActiveTab(activeStepIndex);
    };
    return subscribeOnActiveIndexChanged(handler);
  }, [subscribeOnActiveIndexChanged]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPublished = useCallback(() => {
    isAssociatedPacksEnabled && setShowAddPacks(true);
    return !isAssociatedPacksEnabled && navigate(overviewUrl);
  }, [isAssociatedPacksEnabled, navigate]);

  useRtn(events, onPublished);

  const handleCancelClick = () => navigationUtils.goBackOrDefault(location, navigate, `/${RouteNames.contentSurveys}`);

  const handleOnFinish = async () => {
    onFinish?.();
    props.tryPublish(props.surveyId);
    !isAssociatedPacksEnabled && navigationUtils.goBackOrDefault(location, navigate, `/${RouteNames.contentSurveys}`);
  };

  const getPublishedStatus = () => (props.isEntityCreated ? PublishedStatusTypes.draft : null);

  const renderCustomHeader = (changeActiveIndex: (index: number) => void) => {
    const hasActiveErrors = !props.contentResolved;
    const onErrorClick = () => {
      changeActiveIndex(CreateSurveySteps.Content);
      goToPage(CreateSurveySteps.Content);
    };
    return (
      props.contentErrorMode && (
        <PublishValidationInfo
          unableToPublish={hasActiveErrors}
          readyToPublish={!hasActiveErrors}
          onErrorButtonClick={onErrorClick}
        />
      )
    );
  };

  const onPreviousAsync = async (activeStepIndex: number, nextIndex: number) => {
    onPrevious(activeStepIndex, nextIndex);
  };

  return (
    <>
      <Dimmer active={props.isCreating} inverted>
        <Loader active={props.isCreating} />
      </Dimmer>
      <Wizard
        title="Create Survey"
        finishButtonLabel="Finish"
        onCancel={handleCancelClick}
        onProgress={onNext}
        onRegressAsync={onPreviousAsync}
        onFinish={handleOnFinish}
        publishedStatus={getPublishedStatus()}
        isSaveInProgress={props.isSaving}
        progressSavedDate={showLastModified.has(activeTab) ? props.dateModified : undefined}
        isFinishButtonDisabled={!props.contentResolved}
        renderCustomHeader={renderCustomHeader}
      >
        <Section label="Configuration" className="scrollable-content" isLocked={!isValid} required>
          <Configure
            acceptHandlers={wizardPages[CreateSurveySteps.Configuration]}
            onIsValidChange={setIsValid}
            mode={"create"}
          />
        </Section>
        <Section label="Settings" className="scrollable-content">
          <SettingsTab acceptHandlers={wizardPages[CreateSurveySteps.Settings]} />
        </Section>
        <Section label="Content" className="scrollable-content">
          <Content acceptHandlers={wizardPages[CreateSurveySteps.Content]} />
        </Section>
      </Wizard>
      <AddAssetsToPackModal
        showModal={showAddPacks}
        onClose={/* istanbul ignore next */ () => navigate(`/${RouteNames.contentSurveys}`)}
        onComplete={() => { }}
        selectedItemIds={[props.surveyId]}
        contentType="Survey"
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const surveys = surveysStateSelector(state);
  return {
    surveyId: surveys.base.surveysEntityStateReducer.entityId,
    isEntityCreated: surveys.base.surveysEntityStateReducer.entityId > 0,
    isSaving: surveys.base.surveysEntityStateReducer.isEntityCommandInProgress,
    isCreating: surveys.base.surveysEntityStateReducer.changingEntityState,
    dateModified: surveys.base.surveysEntityStateReducer.lastModifiedDateTime,
    contentErrorMode: surveys.base.surveysContentValidationReducer.errorMode,
    contentFormIsValid: surveys.base.surveysContentValidationReducer.isFormValid,
    contentResolved: surveys.base.surveysContentValidationReducer.resolved,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  tryPublish: bindAction(tryPublish, dispatch),
  onUnmount: () => {
    dispatch(resetAction());
    dispatch(clearSurvey());
  },
  resetSearchFilters: () => {
    dispatch(surveyOverviewActions.resetAction());
    dispatch(resetSearch());
    dispatch(resetAppliedFilter());
    dispatch(resetPagination());
    dispatch(resetSortingColumnName());
    dispatch(resetSortingDirection());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateSurvey);
