import LabelWithInfoTooltip from "../labelWithInfoTooltip/labelWithInfoTooltip";
import cn from "classnames";

export interface FieldLabelProps {
  tooltip?: {
    info: React.ReactNode;
    width: number;
    hoverable?: boolean;
  };
  label: string;
  markAsRequired?: boolean;
  isInErrorState?: boolean;
}

export const FieldLabel: React.FC<FieldLabelProps> = ({ tooltip, label, markAsRequired, isInErrorState }) => {
  const labelClasses = {
    "validated-field-label": true,
    error: isInErrorState,
    "required-asterisk": markAsRequired,
  };
  return (
    <>
      {!tooltip && (
        <label data-testid="field-label" className={cn(labelClasses)}>
          {label}
        </label>
      )}
      {tooltip && (
        <LabelWithInfoTooltip
          label={label}
          width={tooltip.width}
          hoverable={tooltip.hoverable}
          info={tooltip.info}
          labelClass={cn(labelClasses)}
        />
      )}
    </>
  );
};

export default FieldLabel;
