import { get } from "lodash";

import { useCallback } from "react";

import { ValidationContext } from "../models";
import { isPropertyTouched } from "./helpers";

export interface RenderPayload {
  error: string;
  touched: boolean;
  selfValidation?: boolean;
  onMount?: (propName: string) => void;
  onUnmount?: (propName: string) => void;
  validateField?: (propName: string, value: any) => Promise<void> | void;
  showError: boolean;
  propertyName: string;
}

export interface WithFormikPayloadProps<T> extends ValidationContext<T> {
  propertyName: string;
  pathToValidate?: string;
  selfValidation?: boolean;
  render: (props: RenderPayload) => React.ReactElement;
}

const getBaseProps = <T,>(props: WithFormikPayloadProps<T>) => {
  const { errors, touched, propertyName, forceShowError, errorModeDisabled } = props;

  const error = get(errors, propertyName);
  const isTouched = isPropertyTouched(touched, propertyName);

  return { propertyName, error, touched: isTouched, showError: (isTouched || !!forceShowError) && !errorModeDisabled };
};

export const WithFormikPayload = <T,>(props: WithFormikPayloadProps<T>) => {
  const { render, pathToValidate, runMultiLevelValidation, runSingleLevelValidation, selfValidation } = props;

  const validateField = useCallback(
    async (name: string, value: any) => {
      !!pathToValidate
        ? await runMultiLevelValidation?.(pathToValidate, name, value)
        : await runSingleLevelValidation?.(name, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return render({ ...getBaseProps(props), validateField, selfValidation });
};
