import {
  createCancelingLazyFetchingItemsSlice,
  CancelingLazyFetchingItemsState,
} from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import { PackOverview } from "../../types/state";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { fetchUserPacksGrid } from "../thunks/userPacksGridThunk";

export type UserPacksState = CancelingLazyFetchingItemsState<PackOverview>;

export const initialState: UserPacksState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const userPacksGridSlice = createCancelingLazyFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "userPacks",
  },
  initialState: initialState,
  reducers: {},
  thunk: fetchUserPacksGrid,
});

export const resetUserPacksGrid = userPacksGridSlice.actions.reset;

export default userPacksGridSlice.reducer;
