import { Icon, Label as SemanticLabel } from "semantic-ui-react";
import { Tooltip } from "../tooltip";
import { TextTruncate } from "components/textTruncators/TextTruncators";

import styles from "./Label.module.scss";
import cn from "classnames";

export type Color = "yellow" | "blue" | "black" | "green" | "darker-green" | "gray";

interface Props {
  text: string;
  icon?: string;
  color?: Color;
}

export const Label = (props: Props) => {
  const { text, icon, color = "black" } = props;
  return (
    <div className={cn(styles["root"], styles[color])}>
      <SemanticLabel className={styles["label"]} basic>
        <Tooltip
          target={
            <TextTruncate>
              {icon && <Icon className={icon} />} {text}
            </TextTruncate>
          }
          content={text}
        />
      </SemanticLabel>
    </div>
  );
};
