import { KeyboardEvent, ClipboardEvent, BaseSyntheticEvent } from "react";

const intRegex = /^[0-9]*$/; //NOSONAR

const interceptorBase =
  <T extends BaseSyntheticEvent>(interceptor: (event: T) => void) =>
  (event: T, next?: (event: T) => void) => {
    interceptor(event);
    if (!event.defaultPrevented) {
      next?.(event);
    }
  };

export const keyboardInterceptors = {
  integerOnly: interceptorBase(
    (event: KeyboardEvent<HTMLInputElement>) => !intRegex.test(event.key) && event.preventDefault(),
  ),
};

export const clipboardInterceptors = {
  integerOnly: interceptorBase(
    (event: ClipboardEvent<HTMLInputElement>) =>
      !intRegex.test(event.clipboardData.getData("text")) && event.preventDefault(),
  ),
};
