import { connect } from "react-redux";

import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { bindAction } from "../../../../../interfaces/redux";
import { addAssessmentTags, fetchAssessmentTags } from "../../state/thunks/assessmentBaseThunk";
import { assessmentsTagsSelector } from "../../state/selectors";
import TagsManage from "../../../Common/TagsManage/TagsManage";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  tags: assessmentsTagsSelector(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchAssessmentTags, dispatch),
  onConfirmAction: bindAction(addAssessmentTags, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsManage);
