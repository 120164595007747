import { FC } from "react";
import { TitleSubtitle } from "../listViewTemplates";
import GroupPicture from "../groupPicture/GroupPicture";
import GroupIcons from "../groupIcons/GroupIcons";
import { Group } from "../../interfaces";
import cn from "classnames";
import { RouteNames } from "enums";

import "./groupInfoCell.scss";
export interface GroupInfoCellProps {
  group: Group;
  goToEditGroup?(id?: string | number): void;
  deepLink?: boolean;
}

const GroupInfoCell: FC<GroupInfoCellProps> = ({ group, goToEditGroup, deepLink }) => {
  return (
    <div className="name-column-value-container">
      <GroupPicture imageUrl={group.imageUrl} />
      <div className="group-title-column">
        <TitleSubtitle
          url={deepLink ? `/${RouteNames.peopleGroups}/${group.id}` : undefined}
          classNameTitle={cn({ "normal-color clickable": deepLink })}
          id={group.id}
          title={group.name}
          onNavigate={goToEditGroup}
        />
        <GroupIcons
          isAware={group.isAware}
          isOpen={group.isOpen}
          isVisibleToAll={group.isVisibleToAll}
          isWithAutoEnroll={group.isWithAutoEnroll}
        />
      </div>
    </div>
  );
};

export default GroupInfoCell;
