import { useFormik } from "formik";
import { noop } from "lodash";
import { CheckboxProps, Header, Icon, Radio } from "semantic-ui-react";
import ScheduleTypes from "../../../../../enums/scheduleTypes";
import { ScheduleConfiguration, ScheduleProps } from "./types";
import { DateTimePicker } from "../../../../../components/calendar/DateTimePicker";
import { useCallback } from "react";
import { TimezoneDropdown } from "features/Library/Common/Fields/timezone-dropdown/TimezoneDropdown";

import "./schedule.scss";

export const Schedule = (props: ScheduleProps) => {
  const { communicationType, useTimeZone, configuration, onScheduleChange } = props;

  const formik = useFormik<Partial<ScheduleConfiguration>>({
    initialValues: configuration,
    onSubmit: noop,
  });

  const onTimeZoneChange = async (propertyName: string, value: string, shouldValidate?: boolean) => {
    const { setFieldValue } = formik;

    await setFieldValue(propertyName, value, shouldValidate);
    onScheduleChange(configuration.type, configuration.date, configuration.time, value);
  };

  const onChange = useCallback(
    (_: any, data: CheckboxProps) => {
      onScheduleChange(data.value as ScheduleTypes, configuration.date, configuration.time, configuration.timeZone);
    },
    [onScheduleChange, configuration.date, configuration.time, configuration.timeZone],
  );

  const setSelectedDate = useCallback(
    (newDateString: string, newTimeString: string) => {
      onScheduleChange(configuration.type, newDateString, newTimeString, configuration.timeZone);
    },
    [onScheduleChange, configuration.type, configuration.timeZone],
  );

  return (
    <>
      <div className="schedule-container">
        <Header>Delivery Schedule</Header>
        <div className="radio-with-description">
          <Radio
            className="schedule-radio-button"
            label="Send Now"
            name="radioGroup"
            value={ScheduleTypes.SendNow}
            checked={configuration.type === ScheduleTypes.SendNow}
            onChange={onChange}
          />
          <span>{communicationType} will be sent immediately.</span>
        </div>
        <div className="radio-with-description">
          <Radio
            className="schedule-radio-button"
            label="Send On"
            name="radioGroup"
            value={ScheduleTypes.SendOn}
            checked={configuration.type === ScheduleTypes.SendOn}
            onChange={onChange}
          />
          <span>{communicationType}s will be sent on the scheduled date.</span>
        </div>
        {configuration.type === ScheduleTypes.SendOn && (
          <div className="schedule-configuration">
            <DateTimePicker dateValue={configuration.date} timeValue={configuration.time} onChange={setSelectedDate} />
            {useTimeZone && (
              <TimezoneDropdown
                propertyName={"scheduleTimeZone"}
                setFieldTouched={formik.setFieldTouched}
                setFieldValue={onTimeZoneChange}
                value={configuration.timeZone as string}
                icon={<Icon className="fa-clock" />}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
