import React, { useState, SyntheticEvent } from "react";
import { DropdownProps, DropdownItemProps, Icon } from "semantic-ui-react";
import cn from "classnames";

import { DropdownWrapper as Dropdown } from "../dropdownWrapper/DropdownWrapper";

import "./contextSwitcherDropdown.scss";

export interface ContextSwitcherOption {
  key: string;
  label: string;
  iconName?: string;
  iconComponent?: React.ReactElement;
}

export interface ContextSwitcherDropdownProps extends DropdownProps {
  options: ContextSwitcherOption[];
  defaultOption?: string;
  className?: string;
  disableSelectionChange?: boolean;
}

function ContextSwitcherDropdown(props: ContextSwitcherDropdownProps) {
  const { options, className, onChange, defaultOption, disableSelectionChange, ...rest } = props;

  const [selectedItem, setSelectedItem] = useState(defaultOption ? defaultOption : options[0].key);

  const renderIcon = (option: ContextSwitcherOption) => {
    return option.iconComponent ? (
      <span>{option.iconComponent}</span>
    ) : (
      <Icon className={cn(option.iconName)} size="big" />
    );
  };

  const onChangeHandler = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    setSelectedItem(data.value as string);
    onChange && onChange(event, data);
  };

  const segments: DropdownItemProps[] = options.map((option) => {
    return {
      key: option.key,
      text: option.label,
      value: option.key,
      content: (
        <>
          {renderIcon(option)}
          <span data-hook-id={option.key}>{option.label}</span>
        </>
      ),
    };
  });

  return (
    <div className={cn("context-switcher-dropdown", className)}>
      {renderIcon(options.find((o) => o.key === selectedItem) as ContextSwitcherOption)}
      <Dropdown
        items={segments}
        fluid
        defaultValue={selectedItem}
        onChange={onChangeHandler}
        disabled={disableSelectionChange}
        {...rest}
      />
    </div>
  );
}

export default ContextSwitcherDropdown;
