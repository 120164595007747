import { Component } from "react";
import { Table } from "semantic-ui-react";
import { isEmpty } from "lodash";
import sortDirections from "../../../../../enums/sortDirections";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import ViewType from "../../../../../enums/ViewType";
import UserInfoCell from "../../../../../components/userInfoCell/UserInfoCell";
import { SearchInput, TextTruncate } from "../../../../../components";
import NoResults from "../../../../../components/noResults/NoResults";
import { UsersFilterForm } from "../../../../../components/filterForms";
import { ColumnOptions, User } from "../../../../../interfaces";
import MinusButton from "../../../../../components/buttons/iconButtons/minusButton/MinusButton";
import Tooltip from "../../../../../components/common/tooltip/Tooltip";
import ItemsView from "../../../../../views/ItemsView/ItemsView";
import NoSearchResults from "../../../../../components/noSearchResults";

import "./roleUsersList.scss";

export interface RoleUsersListProps {
  users: User[];
  usersCount: number;
  loadPage: Function;
  selectedItemIds: number[];
  isLoading?: boolean;
  appliedUserFilter?: object;
  filterOptions: { isLoading: boolean; showRoleFilter: boolean };
  onRemoveMemberClick(id: number): void;
  addPeopleButton: Function;
  setUserFilter?: Function;
  resetUserFilter?: Function;
  getFilterOptions?: Function;
  updateSelectedItems?: Function;
  goToEditUser: Function;
  isReadOnly: boolean;
}

const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 4,
    isSortable: true,
  },
  {
    name: "Department",
    width: 4,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 3,
    isSortable: true,
  },
  {
    name: "Added",
    width: 2,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export default class RoleUsersList extends Component<RoleUsersListProps> {
  defaultSortingColumnName = "Added";

  buildTableBody = (user: User) => (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <UserInfoCell deepLink user={user} goToEditUser={() => this.props.goToEditUser(user.id)} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <Tooltip target={<TextTruncate>{user.department}</TextTruncate>} content={user.department} />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <Tooltip target={<TextTruncate>{user.title}</TextTruncate>} content={user.title} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(user.createDate)}</Table.Cell>
      {this.renderRemoveButton(user.id)}
    </>
  );

  removeRoleUser = (userId: number) => {
    this.props.onRemoveMemberClick(userId);
  };

  renderRemoveButton(userId: number) {
    return (
      <Table.Cell className="align-right" width={columnOptions[4].width}>
        {
          <Tooltip
            position="top center"
            hideOnScroll
            showAlways
            target={<MinusButton onClick={() => this.removeRoleUser(userId)} isDisabled={this.props.isReadOnly} />}
            content="Remove"
          />
        }
      </Table.Cell>
    );
  }

  noResultsContent = () => {
    return isEmpty(this.props.appliedUserFilter) ? (
      <NoResults
        title="No results found"
        description={<span>Add people using the button below.</span>}
        iconClassName="fa-user-alt-slash"
      >
        {this.props.addPeopleButton}
      </NoResults>
    ) : (
      <NoSearchResults />
    );
  };

  // @ts-ignore
  getFilterForm = () => <UsersFilterForm />;

  render() {
    let {
      users,
      usersCount,
      loadPage,
      appliedUserFilter,
      filterOptions,
      setUserFilter,
      resetUserFilter,
      isLoading,
      updateSelectedItems,
      selectedItemIds,
      getFilterOptions,
    } = this.props;

    return (
      <ItemsView
        className="role-users-list"
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        getData={loadPage}
        itemsInlineCount={usersCount}
        isLoading={isLoading}
        items={users}
        buildTableBody={(user: User) => this.buildTableBody(user)}
        onSelectedListItemsChanged={updateSelectedItems}
        renderFilterForm={this.getFilterForm}
        filterOptionsLoading={filterOptions.isLoading}
        appliedFilter={appliedUserFilter}
        filterOptions={filterOptions}
        getFilterOptions={getFilterOptions}
        applyFilter={setUserFilter}
        resetFilter={resetUserFilter}
        sortingColumnName={this.defaultSortingColumnName}
        sortingDirection={sortDirections.Asc}
        noResultsContent={this.noResultsContent()}
        selectedIds={selectedItemIds}
        renderSearch={() => <SearchInput placeholder="Search for Users..." disabled />}
      />
    );
  }
}
