import { SortingDirection } from "../../../../../enums";

export const DEFAULT_LOAD_COUNT = 10;
export const DEFAULT_SORTING_COLUMN = "Added";
export const DEFAULT_SORT_BY = "DateCreated";
export const DEFAULT_SORT_DIRECTION = SortingDirection.Descending;
export const DEFAULT_SORT_BY_PARAMS = `${DEFAULT_SORT_BY} ${DEFAULT_SORT_DIRECTION}`;

export enum EditMessageSteps {
  Performance,
  Configuration,
  Content,
  AssociatedPacks,
}
