import { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { useObserver } from "../../../../hooks/useObserver";
import { AppDispatch, RootState } from "../../../../features/Application/globaltypes/redux";
import ModalWithSteps from "../../../modal/ModalWithSteps";
import LicensesAddingStep from "../packAssignmentSteps/licensesAddingStep/LicensesAddingStep";
import { ExtendLicensesModalProps } from "./types";
import {
  accountPackLicenseSelector,
  reset,
} from "../../../../features/Accounts/EditAccount/state/slices/editAccountPackLicenseSlice";
import { fetchAccountPackLicense } from "../../../../features/Accounts/EditAccount/state/thunks/editAccountPackLicenseThunk";
import { AccountPackOverview, AssignAccountPack } from "../types";

import "./extendLicensesModal.scss";

export type ExtendLicensesModalAllProps = PropsFromRedux & ExtendLicensesModalProps;

export const ExtendLicensesModal: FC<ExtendLicensesModalAllProps> = ({
  onConfirm,
  selectedViewType,
  showModalObserver,
  accountPackLicense,
  fetchAccountPackLicense,
  resetAccountPackLicense,
  isLoading,
  error,
  header,
  isRestore,
}) => {
  const [isDataValid, setIsDataValid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [packId, setPackId] = useState(0);
  const [selectedPack, setSelectedPack] = useState<AssignAccountPack>();
  const [extendLicensesPackItem, setExtendLicensesPackItem] = useState<AccountPackOverview>();

  useEffect(() => {
    setIsDataValid(false);
    const selectedPack: AssignAccountPack = {
      packId: packId,
      licensesCount: accountPackLicense?.totalLicensesCount,
      assignedLicensesCount: accountPackLicense?.assingedLicensesCount,
      isRequested: false,
      edited: false,
    };
    const packItem = {
      id: packId,
      title: accountPackLicense?.packTitle,
      thumbnailImageUrl: accountPackLicense?.thumbnailImageUrl,
    };
    setExtendLicensesPackItem(packItem as AccountPackOverview);
    setSelectedPack(selectedPack);
  }, [showModal, packId, accountPackLicense]);

  /* istanbul ignore next */
  const onExtendeLicensesButtonClick = (packId: number, accountId: number) => {
    fetchAccountPackLicense(packId, accountId);
    setPackId(packId);
    setShowModal(true);
  };
  const [subscribeOnExtendeLicensesButtonClick] = useObserver(showModalObserver);

  useEffect(() => {
    return subscribeOnExtendeLicensesButtonClick(onExtendeLicensesButtonClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnExtendeLicensesButtonClick]);

  const onCloseModal = () => {
    setShowModal(false);
    resetAccountPackLicense();
  };

  const onLicenseCountChange = (licensesCount?: number) => {
    if (selectedPack) {
      selectedPack.licensesCount = licensesCount;
      selectedPack.edited = true;
      const updatedPack = { ...selectedPack };
      setSelectedPack(updatedPack);
    }
  };

  const validateFinishBtnEnabling = (maxLicensesCount: number) => {
    const isNotValid =
      !selectedPack?.licensesCount ||
      selectedPack.licensesCount > maxLicensesCount ||
      (selectedPack.assignedLicensesCount && selectedPack.assignedLicensesCount > selectedPack.licensesCount) ||
      !selectedPack.expirationDate ||
      error;
    setIsDataValid(!isNotValid);
  };

  const onExpirationDateChange = (optionId?: number, date?: Date | null) => {
    if (selectedPack) {
      if (optionId) {
        selectedPack.licenseType = optionId;
      }
      if (date) {
        selectedPack.expirationDate = date;
      }

      const updatedPack = { ...selectedPack };
      setSelectedPack(updatedPack);
    }
  };

  const renderModalLicensesAddingStep = (_nextStep: () => void, _prevStep: () => void) => (closeModal: Function) =>
    (
      <>
        <Button
          basic
          className="cancel"
          content="Cancel"
          onClick={() => {
            onCloseModal();
            closeModal();
          }}
        />

        <Button
          primary
          className="confirm"
          content="Save"
          onClick={() => {
            onConfirm(selectedPack ? [selectedPack] : [], selectedViewType);
            onCloseModal();
            closeModal();
          }}
          disabled={!isDataValid}
        />
      </>
    );

  return (
    <ModalWithSteps className={"extend-licenses-modal"} scrolling={false} showModal={showModal} onCancel={onCloseModal}>
      <LicensesAddingStep
        header={header}
        isLoading={isLoading}
        selectedPacks={selectedPack ? [selectedPack] : []}
        renderModalActions={renderModalLicensesAddingStep}
        items={extendLicensesPackItem ? [extendLicensesPackItem] : []}
        onLicenseCountChange={(_, licensesCount?: number) => onLicenseCountChange(licensesCount)}
        validateFinishBtnEnabling={validateFinishBtnEnabling}
        onExpirationDateChange={(_, optionId?: number, date?: Date | null) => onExpirationDateChange(optionId, date)}
        error={error}
        isRestore={isRestore}
      />
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isLoading: accountPackLicenseSelector(state).isLoading,
  error: accountPackLicenseSelector(state).error,
  accountPackLicense: accountPackLicenseSelector(state).accountPackLicense,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetAccountPackLicense: () => dispatch(reset()),
    fetchAccountPackLicense: (packId: number, accountId: number) =>
      dispatch(fetchAccountPackLicense(packId, accountId)),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ExtendLicensesModal);
