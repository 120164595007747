import React, { useState } from "react";
import WeekDayButton, { WeekDays } from "./WeekDayButton";
import classNames from "classnames";
import { concat, filter, some } from "lodash";

import "./weekDayPicker.scss";

export interface WeekDayPickerProps {
  selectedPredicate?: (weekDay: WeekDays) => boolean;
  order?: WeekDays[];
  classname?: string;
  onChange?: (weekDays: number[]) => void;
  disabled?: boolean;
  name?: string;
  minSelectionCount?: 0 | 1;
}

export const defaultWeekDayOrder = [
  WeekDays.Monday,
  WeekDays.Tuesday,
  WeekDays.Wednesday,
  WeekDays.Thursday,
  WeekDays.Friday,
  WeekDays.Saturday,
  WeekDays.Sunday,
];

export const dayOfWeekMap = {
  [WeekDays.Monday]: 1,
  [WeekDays.Tuesday]: 2,
  [WeekDays.Wednesday]: 3,
  [WeekDays.Thursday]: 4,
  [WeekDays.Friday]: 5,
  [WeekDays.Saturday]: 6,
  [WeekDays.Sunday]: 0,
};

export const dayOfWeeks = [
  WeekDays.Sunday,
  WeekDays.Monday,
  WeekDays.Tuesday,
  WeekDays.Wednesday,
  WeekDays.Thursday,
  WeekDays.Friday,
  WeekDays.Saturday,
];

const WeekDayPicker: React.FC<WeekDayPickerProps> = ({
  classname,
  onChange,
  selectedPredicate,
  disabled,
  minSelectionCount = 0,
  order = defaultWeekDayOrder,
}): React.ReactElement => {
  const [selectedWeekDays, setSelectedWeekDays] = useState<WeekDays[]>(order.filter((x) => selectedPredicate?.(x)));

  const handleToggle = (weekDay: WeekDays) => {
    if (disabled) {
      return;
    }
    let selection = some(selectedWeekDays, (x) => x === weekDay)
      ? filter(selectedWeekDays, (x) => x !== weekDay)
      : concat(selectedWeekDays, weekDay);

    if (minSelectionCount > selection.length) {
      return;
    }
    setSelectedWeekDays(selection);
    const mapped = selection.map((wd) => dayOfWeekMap[wd]);
    onChange?.(mapped);
  };

  const getWeekDayButtons = () =>
    order.map((day) => (
      <WeekDayButton
        disabled={disabled}
        key={day}
        weekDay={day}
        onToggle={handleToggle}
        selected={selectedWeekDays.some((x) => x === day)}
      />
    ));

  return <div className={classNames("weekday-picker", classname)}>{getWeekDayButtons()}</div>;
};

export default WeekDayPicker;
