import { ReducerEntityPrefixTypes, ReducerNamespaceTypes, SortingDirection } from "enums";
import DataService from "features/Application/services/dataServices/typedDataService";
import { AppDispatch, AppThunk } from "features/Application/globaltypes/redux";
import { DistributedOpUpdateParams, FetchActionPayload } from "interfaces";
import backgroundTask from "features/BackgroundTasks/backgroundTask";
import { pluralize } from "utils/stringUtils";
import { fetchBegin, fetchFailure, fetchSuccess } from "../TemplatePacksSlice";
import { PackAccountsContextItem } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { Pack, PackLicenseInfo } from "../../DataService/types";
import dateTimeUtils from "utils/dateTimeUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPrefix } from "features/Application/slices/models";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const getPacks =
  (id: number, skip: number, top: number, sortBy: string, sortOrder: SortingDirection): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchBegin());

      const result = await DataService.groupTemplates.getPacks(id, {
        top,
        skip,
        sortBy,
        sortOrder,
      });

      const recordsCount = Number.parseInt(result.headers[countHeaderName]);
      dispatch(
        fetchSuccess({
          items: result.data,
          totalCount: recordsCount,
        }),
      );
    } catch (error) {
      dispatch(fetchFailure(error as Error));
    }
  };

  export const getTemplatePacks = createAsyncThunk<FetchActionPayload<Pack>, number>(
    getPrefix({
      namespace: ReducerNamespaceTypes.People,
      entity: ReducerEntityPrefixTypes.TemplateGroups,
      name: "AddCustomersPacks",
    }),
    async (templateId: number) => {
      const result = await DataService.groupTemplates.getPacksWithTrialInfo(templateId);
      return {
        items: result.data,
        totalCount: result.data.length,
      };
    },
  );

export const removePacks = (templateId: number, packIds: number[]) => {
  return async (dispatch: AppDispatch) => {
    if (packIds.length === 0) return;

    const getOperationProps = async () => {
      const data = await DataService.groupTemplates.removePacks(templateId, packIds);
      return data;
    };

    const isSingle = packIds.length === 1;

    const updateParams: DistributedOpUpdateParams = {
      id: "RemovePacksFromTemplateAccounts",
      title: `Removing ${pluralize("pack", packIds.length)} from template`,
      getOperationProps,
      indeterminate: true,
      successTransientMessage: `${pluralize("Pack", packIds.length)} ${isSingle ? "has" : "have"} been removed from template successfully`,
      failureTransientMessage: `Removal ${pluralize("pack", packIds.length)} from template failed!`,
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};

export const getAccountPacks = (templateId: number, accountId: number) => async () => {
  const result = await DataService.groupTemplates.getAccountPacks(templateId, accountId);
  return result.data;
};

export const editAccountPacks = (templateId: number, packs: PackAccountsContextItem[], accountId: number) => {
  return async (dispatch: AppDispatch) => {
    if (packs.length === 0) return;

    const accountPacks: PackLicenseInfo[] = packs
      .filter((p) => !p.isTrial && p.isChanged)
      .map((p) => ({ packId: p.id, expirationDate: new Date(dateTimeUtils.toServerFormatDate(p.expirationDate!)) }));

    const getOperationProps = async () => {
      const data = await DataService.groupTemplates.saveAccountPacks(templateId, accountId, accountPacks);
      return data;
    };

    const updateParams: DistributedOpUpdateParams = {
      id: "EditTemplateAccountPacks",
      title: "Edit pack licenses for account",
      getOperationProps,
      indeterminate: true,
      successTransientMessage: "Licensing has been updated successfully.",
      failureTransientMessage: "Licensing update has been failed!",
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};

export const editPackAccounts = (templateId: number, packId: number, expirationDate: Date) => {
  return async (dispatch: AppDispatch) => {
    const getOperationProps = async () => {
      const formattedExpirationDate = new Date(dateTimeUtils.toServerFormatDate(expirationDate));
      const data = await DataService.groupTemplates.savePackAccounts(templateId, packId, formattedExpirationDate);
      return data;
    };

    const updateParams: DistributedOpUpdateParams = {
      id: "EditTemplatePackAccounts",
      title: "Edit account licenses for pack",
      getOperationProps,
      indeterminate: true,
      successTransientMessage: "Licensing has been updated successfully.",
      failureTransientMessage: "Licensing update has been failed!",
    };

    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};
