import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import * as entityStateActionTypes from "../../../../Application/actions/entityState/entityStateActionTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";

const pdfActionBuilder = (type: string) => {
  return buildRootEntityActionType(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Pdfs, type);
};

export const publishPdfBegin = pdfActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN);
export const publishPdfSuccess = pdfActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS);
export const publishPdfFailure = pdfActionBuilder(entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE);
export const fetchLockedPdfBegin = pdfActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN);
export const fetchLockedPdfSuccess = pdfActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS);
export const fetchLockedPdfFailure = pdfActionBuilder(entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE);
export const updatePdfBegin = pdfActionBuilder(entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN);
export const updatePdfFailure = pdfActionBuilder(entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE);
export const updatePdfCommandSuccess = pdfActionBuilder(entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS);
export const resetPdfEntityState = pdfActionBuilder(entityStateActionTypes.RESET_ENTITY_STATE);
export const createPdfBegin = pdfActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN);
export const createPdfSuccess = pdfActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS);
export const createPdfFailure = pdfActionBuilder(entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE);
export const fetchDiscardPdfSuccess = pdfActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);
export const fetchDiscardPdfFailure = pdfActionBuilder(
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);
export const fetchDiscardPdfBegin = pdfActionBuilder(entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN);
export const pdfUpdated = pdfActionBuilder(entityStateActionTypes.LOCKED_ENTITY_UPDATED);
export const setPdfEntityId = pdfActionBuilder(entityStateActionTypes.SET_ENTITY_ID);
