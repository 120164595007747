import { useCallback, useEffect, useMemo } from "react";
import { Icon } from "semantic-ui-react";
import { Tooltip } from "../../../../../../../components/common/tooltip";
import CheckboxField from "../../../../../Common/Fields/CheckboxField";
import { WithFormikPayload } from "../../../../../Common/Fields/WithFormikPayload";
import { CommonComponentProps } from "../../../../../Common/props";
import { BRANCHING_QUESTION_ANSWERS_MAX_COUNT } from "../../../../types/constants";
import { useContentContext } from "../../state/ContentContext";
import { getAnswersCount, isAllowedBranchingQuestionType, nameofWithPrefix } from "../../utils";
import { ISurveyQuestion } from "../types";
import { getBranchingQuestionTooltipMessage } from "./utils";

export interface BranchingToggleProps extends CommonComponentProps {
  question: ISurveyQuestion<any>;
  index: number;
}

export const BranchingToggle = (props: BranchingToggleProps) => {
  const { validatedFieldProps, question, propertyNamePrefix, onChanged, disabled, index } = props;
  const { toggleBranchingQuestion, branchingQuestionId, turnOffBranchingQuestion } = useContentContext();
  const nameof = useMemo(() => {
    return nameofWithPrefix<ISurveyQuestion<any>>(propertyNamePrefix);
  }, [propertyNamePrefix]);

  const isBranching = !!question.isBranching;
  const id = question.id!;

  useEffect(() => {
    return () => {
      turnOffBranchingQuestion(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBranching || branchingQuestionId === id) {
      toggleBranchingQuestion(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBranching]);

  const { setFieldValue } = validatedFieldProps;
  const { isRequired } = question;

  const setValue = useCallback(
    async (field: string, value: boolean, shouldValidate?: boolean) => {
      await (setFieldValue(field, value, shouldValidate) as unknown as Promise<void>);
      if (value && !isRequired) {
        await (setFieldValue(nameof("isRequired"), true, shouldValidate) as unknown as Promise<void>);
      }
    },
    [isRequired, setFieldValue, nameof],
  );

  const shouldRender = branchingQuestionId === undefined || branchingQuestionId === id;

  const branchingDisabled =
    getAnswersCount(question) > BRANCHING_QUESTION_ANSWERS_MAX_COUNT ||
    !isAllowedBranchingQuestionType(question.questionType);

  const tooltipMessage = getBranchingQuestionTooltipMessage(question);

  return shouldRender ? (
    <div className="checkbox-field">
      <WithFormikPayload
        propertyName={nameof("isBranching")}
        {...validatedFieldProps}
        render={(formikProps) => (
          <Tooltip
            target={
              <CheckboxField
                {...formikProps}
                toggle
                disableValidation
                index={index}
                value={isBranching}
                disabled={disabled || branchingDisabled}
                setFieldTouched={validatedFieldProps.setFieldTouched}
                setFieldValue={setValue}
                onChange={onChanged}
                label="Branching question"
              />
            }
            content={tooltipMessage}
            position="top center"
            styles={{ width: "20rem" }}
            hideOnScroll
            showAlways={!!tooltipMessage}
          />
        )}
      />

      <Tooltip
        target={<Icon className="info circle" />}
        content={
          "Only one question per survey can be assigned as a branching question. A branching question cannot have more than 5 answers. "
        }
        position="top center"
        styles={{ width: "20rem" }}
        hideOnScroll
        showAlways
      />
    </div>
  ) : null;
};
