import { asAnswersMap } from "../../../../../Common/Answer/Answers/AnswerProvider";
import { PictureAnswer } from "../../../../../Common/Answer/Answers/PictureAnswer";
import { TextAnswer } from "../../../../../Common/Answer/Answers/TextAnswer";
import { IAnswerBase, IAnswer } from "../../../../../Common/models";
import { Picture } from "../../../../../Common/Question/Questions/PictureQuestion";
import { SurveyAnswerType } from "../../../../types/models";

/* istanbul ignore next */
export const AnswersMap = asAnswersMap({
  [SurveyAnswerType.Text]: { Component: TextAnswer, castAnswer: (answer: IAnswerBase) => answer as IAnswer<string> },
  [SurveyAnswerType.Picture]: {
    Component: PictureAnswer,
    castAnswer: (answer: IAnswerBase) => answer as IAnswer<Picture>,
  },
});
