import React from "react";

import styles from "./dateLabel.module.scss";

interface Props {
  date?: string | null;
  label?: string;
}

const DateLabel: React.FC<Props> = ({ label, date }) => {
  if (!date) {
    return null;
  }
  return (
    <span className={styles.dateLabel}>
      {label && <>{label}:&nbsp;</>}
      <span className="expiration-date">{date}</span>
    </span>
  );
};

export default DateLabel;
