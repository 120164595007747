import React from "react";
import InformationModal from "../modal/InformationModal";
import { Icon, List } from "semantic-ui-react";
import BasicLinkButton from "../buttons/linkButtons/baseLinkButton/BasicLinkButton";
import * as stringUtils from "../../utils/stringUtils";

import "./DeleteEmailTemplatesInformationModal.scss";
import { ThreatDefenceCampaignOption } from "../../features/Library/SimulatedPhishing/EmailTemplates/types/state";

export interface DeleteEmailTemplatesInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  emailTemplateCampaigns: ThreatDefenceCampaignOption[];
  emailTemplatesCount: number;
}

export const DeleteEmailTemplatesInformationModal: React.FC<DeleteEmailTemplatesInformationModalProps> = (
  props: DeleteEmailTemplatesInformationModalProps,
) => {
  const handleClick = (campaignId: number) => {
    window.open(`/content/simulated-phishing/campaigns/${campaignId}/content`);
  };

  const pluralizeEmailTemplateBelongsMessage = () => {
    const { emailTemplatesCount } = props;
    return emailTemplatesCount > 1 ? "These email templates belong" : "This email template belongs";
  };

  const pluralizeBottomInformationMessage = () => {
    const { emailTemplatesCount } = props;
    return emailTemplatesCount > 1
      ? "Make sure the campaigns with changes were published!"
      : "Make sure the campaign with changes was published!";
  };

  const pluralizeEmailTemplateDeleteInformationMessage = () => {
    const { emailTemplatesCount } = props;
    return emailTemplatesCount > 1
      ? "In order to delete these Email Templates, please remove them from all the Campaigns they belong to:"
      : " In order to delete this Email Template, please remove it from all the Campaigns it belongs to:";
  };

  const renderContent = () => {
    const { emailTemplateCampaigns } = props;
    return (
      <div>
        <div className="warning">
          <div className="warning-message">
            <Icon className="fa-exclamation-triangle icon" size="large" />
            <span>Warning! Further action is required.</span>
          </div>
        </div>
        <br />
        <div>
          {pluralizeEmailTemplateBelongsMessage()} to{" "}
          <span className="published-flows">
            {emailTemplateCampaigns.length} {stringUtils.pluralize("campaign", emailTemplateCampaigns.length)}.
          </span>
          {` ${pluralizeEmailTemplateDeleteInformationMessage()}`}
        </div>

        <List className="campaigns-list">
          {emailTemplateCampaigns.map((campaign) => {
            return (
              <List.Item key={campaign.id}>
                <BasicLinkButton text={campaign.name} onClick={() => handleClick(campaign.id)} />
              </List.Item>
            );
          })}
        </List>
        <div>
          <span>{pluralizeBottomInformationMessage()}</span>
        </div>
        <br />
      </div>
    );
  };

  return (
    <InformationModal
      className="delete-email-template-popup"
      title="Cannot perform Deletion"
      message={renderContent()}
      open={props.isOpen && props.emailTemplateCampaigns.length > 0}
      {...props}
    />
  );
};

export default DeleteEmailTemplatesInformationModal;
