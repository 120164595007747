import { FC } from "react";
import { Table } from "semantic-ui-react";

import { ColumnOptions, Group, GroupOwner } from "../../../interfaces";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import GroupInfoCell from "../../groupInfoCell/GroupInfoCell";
import { Tooltip } from "../../common/tooltip";

export interface IGroupsTableBody {
  group: Group;
}

const getGroupOwners = (group: Group): string => {
  return group.owners && group.owners.map((x: GroupOwner) => `${x.firstName} ${x.lastName}`).join(", ");
};

export const groupsColumnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 5,
    isSortable: true,
  },
  {
    name: "Owners",
    width: 4,
    isSortable: false,
  },
  {
    name: "Members",
    width: 4,
    isSortable: false,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
];

const GroupsTableBody: FC<IGroupsTableBody> = ({ group }): React.ReactElement => (
  <>
    <Table.Cell width={groupsColumnOptions[0].width}>
      <GroupInfoCell group={group} />
    </Table.Cell>
    <Table.Cell width={groupsColumnOptions[1].width}>
      <Tooltip content={getGroupOwners(group)} target={<TextTruncate>{getGroupOwners(group)}</TextTruncate>} />
    </Table.Cell>
    <Table.Cell width={groupsColumnOptions[2].width}>{group.membersCount}</Table.Cell>
    <Table.Cell width={groupsColumnOptions[3].width}>{dateTimeUtils.formatDate(group.dateCreated)}</Table.Cell>
  </>
);

export default GroupsTableBody;
