import { TemplateTypes } from "../../../../enums";
import {
  fetchTemplateFail,
  fetchTemplateSuccess,
  renderPreviewFail,
  renderPreviewSuccess,
  renderPreviewBegin,
  fetchSmtpSenderSettingsSuccess,
  fetchSmtpSenderSettingsFail,
  fetchSmtpSenderSettingsNotExist,
} from "../slices/notifyStepSlice";
import { templateService } from "../../services/templateService";
import { getTeamsTemplateMetadata } from "features/SystemNotifications/services/templateMetadata";
import { AppDispatch } from "features/Application/globaltypes/redux";
import accountsDataService from "features/Accounts/services/accountsDataService";
import { SmtpSenderInfo } from "features/SystemNotifications/types";

export const fetchNotificationTemplate =
  (type: TemplateTypes, notificationId?: string) => async (dispatch: AppDispatch) => {
    let templateResult;
    try {
      if (notificationId && (await templateService.isNotifyOptionsSaved(notificationId))) {
        templateResult = await templateService.getTemplateById(notificationId);
        dispatch(fetchTemplateSuccess({ ...templateResult, templateType: type }));
      } else {
        const defaultTeamsTemplateMetadata = getTeamsTemplateMetadata(type);
        templateResult = await templateService.getTemplate(type);
        dispatch(fetchTemplateSuccess({ ...templateResult, ...defaultTeamsTemplateMetadata, templateType: type }));
      }
    } catch (err) {
      dispatch(fetchTemplateFail());
    }
  };

export const fetchSmtpSenderSettings = (accountId: number) => async (dispatch: AppDispatch) => {
  try {
    const result = (await accountsDataService.getSmtpSenderSettings(accountId)) as SmtpSenderInfo;
    if (result && result.smtpSettingsExist) {
      dispatch(
        fetchSmtpSenderSettingsSuccess({
          customizeSender: false,
          defaultSenderName: result.sender.senderName,
          defaultSenderEmail: result.sender.senderEmail,
          smtpSettingsExist: true,
        }),
      );
    } else {
      dispatch(fetchSmtpSenderSettingsNotExist());
    }
  } catch (err) {
    dispatch(fetchSmtpSenderSettingsFail());
  }
};

export async function getTemplateSasUrls(templateSasUrl: string, rawTemplateSasUrl: string) {
  if (!templateSasUrl || !rawTemplateSasUrl) {
    const { templateUri, rawTemplateUri } = await templateService.getUrlWithSasTokenV2();
    return {
      templateSasUrl: templateUri,
      rawTemplateSasUrl: rawTemplateUri,
    };
  }
  return { templateSasUrl, rawTemplateSasUrl };
}

export const renderEmailPreview =
  (
    templateSasUrl: string,
    rawTemplateSasUrl: string,
    template: string,
    rawTemplateBody: string,
    notificationType: TemplateTypes,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(renderPreviewBegin());
    try {
      const urls = await getTemplateSasUrls(templateSasUrl, rawTemplateSasUrl);

      await templateService.saveTemplate(urls.templateSasUrl, template);
      await templateService.saveTemplate(urls.rawTemplateSasUrl, rawTemplateBody);

      const emailPreview = await templateService.getEmailPreview(notificationType, urls.templateSasUrl);
      dispatch(
        renderPreviewSuccess({
          templateSasUrl: urls.templateSasUrl,
          rawTemplateSasUrl: urls.rawTemplateSasUrl,
          emailPreview,
        }),
      );
    } catch (err) {
      dispatch(renderPreviewFail());
    }
  };
