import PropTypes from "prop-types";

const types = {
  value: PropTypes.array.isRequired,
  propertyName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  dropdownOptions: PropTypes.shape({
    items: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string.isRequired,
  handleSearchQueryChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,

  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  isFormValid: PropTypes.bool.isRequired,

  handleValueChange: PropTypes.func,
  renderLabel: PropTypes.func,

  disabled: PropTypes.bool,
};
export default types;
