import React, { Component } from "react";
import PropTypes from "prop-types";

import MultiselectSearchDropdown from "./MultiselectSearchDropdown";
import multiselectSearchDropdownPropTypes from "./multiselectSearchDropdownPropTypes";
import uniqBy from "lodash/uniqBy";

export default class MultiselectSearchDropdownWithCache extends Component {
  selectedDropdownItems = [];
  state = {
    mergedDropdownOptions: {
      items: [],
      isLoading: false,
    },
  };

  componentDidUpdate(prevProps) {
    const { dropdownOptions } = this.props;
    if (prevProps.dropdownOptions.isLoading !== dropdownOptions.isLoading) {
      this.syncSelectedDropdownItems();
    }
  }

  componentDidMount() {
    const { selectedDropdownItems } = this.props;
    if (selectedDropdownItems.length > 0) {
      this.syncSelectedDropdownItems();
    }
  }

  syncSelectedDropdownItems = () => {
    const { selectedDropdownItems, dropdownOptions } = this.props;
    if (this.state.mergedDropdownOptions.items.length === 0) {
      this.selectedDropdownItems = selectedDropdownItems;
    }

    this.setState({
      mergedDropdownOptions: {
        ...dropdownOptions,
        items: uniqBy(selectedDropdownItems.concat(dropdownOptions.items), "key"),
      },
    });
  };

  handleValueChange = (_, d) => {
    const { value, options } = d;
    const valueSet = new Set(value);
    const newItems = options.filter((x) => valueSet.has(x.value));
    this.selectedDropdownItems = newItems;
    this.props.selectDropdownItem && this.props.selectDropdownItem(this.selectedDropdownItems);
  };

  render() {
    const { mergedDropdownOptions } = this.state;
    return (
      <MultiselectSearchDropdown
        {...this.props}
        dropdownOptions={mergedDropdownOptions}
        handleValueChange={this.handleValueChange}
      />
    );
  }
}

MultiselectSearchDropdownWithCache.propTypes = {
  selectedDropdownItems: PropTypes.array,
  selectDropdownItem: PropTypes.func,
  ...multiselectSearchDropdownPropTypes,
};
