import { FilterTypesMap, FilterType } from "utils/filterUtils";
import { Group, User } from "../../../../interfaces";
import { Removable, WithId } from "../../../../types";

export enum PdfFiltersEnum {
  Tags = "tags",
  SoftwareApplications = "softwareApplications",
  Labels = "labels",
  Packs = "packIds",
  Publishers = "publisherIds",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}

export const pdfFilterTypes: FilterTypesMap<PdfFiltersEnum> = {
  dateCreated: FilterType.DateRange,
  dateModified: FilterType.DateRange,
  labels: FilterType.Array,
  packIds: FilterType.Array,
  publisherIds: FilterType.Array,
  softwareApplications: FilterType.Array,
  tags: FilterType.Array,
};

export interface PdfPublishConfirmationParams {
  id: number;
  isDraft: boolean;
  flowsCount: number;
  assignmentsCount: number;
  hasBeenPublished: boolean;
}

export interface PdfPayload {
  id?: number;
  title: string;
  description: string;
}

export interface SasToken {
  uri: string;
  fileName: string;
  expired: string;
}

export interface AllocatedResource {
  id: number;
  sasToken: SasToken;
}

interface PdfPeople extends Removable, WithId {
  priorityLevelId: number;
  dueDate?: Date;
  permissionIssuedDate: Date;
  editable: boolean;
  inherited: boolean;
}

export interface PdfUser extends User, PdfPeople {
  userId: number;
  isCompleted: boolean;
  expirationDate?: string;
}

export interface PdfGroup extends Group, PdfPeople {
  groupId: string;
}

export interface PdfUrlParams {
  id: string;
}

export interface ModalHandlers {
  publish: (params: PdfPublishConfirmationParams) => void;
  delete: (ids: number[], flowsCount?: number, packsCount?: number) => void;
  revert: () => void;
}
