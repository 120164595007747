import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { AppDispatch } from "../../../../../Application/globaltypes/redux";
import { EmailSendSession } from "../../SendSessions/CreateEmailSendSession";
import emailsDataService from "../../services/emailsDataService";
import { EmailSendsRequest } from "../../types/requests";
import { cancelBegin, cancelFailure, cancelSuccess } from "../slices/emailCancelSendSlice";
import { createBegin, createFailure, createSuccess } from "../slices/emailCreateSendSlice";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/emailSendsSlice";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

const fetchEmailSends =
  (emailId: number, config: EmailSendsRequest, filter: PerformanceFilter) => async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const res = await emailsDataService.getEmailSends(emailId, config, filter);
      dispatch(fetchSuccess({ items: res.data, totalCount: Number(res.headers[countHeaderName]) }));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };

const createEmailSend = (emailId: number, config: EmailSendSession) => async (dispatch: AppDispatch) => {
  dispatch(createBegin());
  try {
    await emailsDataService.createEmailSend(emailId, config);
    dispatch(createSuccess());
  } catch (e) {
    dispatch(createFailure(e.message));
  }
};

const cancelEmailSend = (sendId: number) => async (dispatch: AppDispatch) => {
  dispatch(cancelBegin());
  try {
    await emailsDataService.cancelSendSession(sendId);
    dispatch(cancelSuccess());
  } catch (e) {
    dispatch(cancelFailure(e.message));
  }
};

export { cancelEmailSend, createEmailSend, fetchEmailSends };
