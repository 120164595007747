import React, { useMemo, useRef } from "react";
import { Icon, Loader, Message } from "semantic-ui-react";
import { Props, SankeyData, SankeyFuncs } from "../../types/Sankey";
import { SankeyChart } from "../SankeyChart";

import "./sankeyWrapper.scss";

export type WrapperProps = {
  title: string;
  error?: string;
  loading?: boolean;
} & Props;

const hasItems = (graph: SankeyData) => graph?.Relations?.length > 0;

export const SankeyWrapper: React.FC<WrapperProps> = ({ title, loading, error, ...sankeyProps }) => {
  const sankeyRef = useRef<SankeyFuncs>(null);

  const zoomOut = () => {
    sankeyRef.current?.zoomOut();
  };

  const zoomIn = () => {
    sankeyRef.current?.zoomIn();
  };

  const body = useMemo(() => {
    if (loading) return <Loader className="sankeyLoader" active />;
    if (error) return <Message color="red">{error}</Message>;
    if (sankeyProps.data) {
      return (
        <div className="sankeyChildWrapper">
          {hasItems(sankeyProps.data) ? (
            <SankeyChart ref={sankeyRef} {...sankeyProps} />
          ) : (
            <div className="noSankeyData" data-testid="no sankey data">
              Once users begin this Flow, engagement data will appear here.
            </div>
          )}
        </div>
      );
    }
  }, [error, loading, sankeyProps]);

  return (
    <div className="root">
      <div className="headerContainer">
        <h2 className="title">{title}</h2>
        <div className="actions">
          <span>Zoom</span>
          <Icon name="zoom in" data-testid="sankey zoom in" link onClick={zoomIn} />
          <Icon name="zoom out" data-testid="sankey zoom out" link onClick={zoomOut} />
        </div>
      </div>
      {body}
    </div>
  );
};
