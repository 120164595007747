import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "components/buttons/button/Button";
import { Routes, Route, Navigate } from "react-router-dom";

import { RestrictedResource, NotImplemented, TimestampLabel, GenericSectionHeader } from "../../../components";
import Segments from "../../../components/navigation/segments/Segments";
import * as editRoleActionsRedux from "./editRoleActions";
import * as createRoleActionsRedux from "../CreateRoles/state/createRoleActions";
import * as notificationsActions from "../../Notifications/state/notificationsActions.js";
import * as backgroundTasksActions from "../../BackgroundTasks/state/backgroundTasksActions";
import * as usersFilterActionsRedux from "../../People/Users/UsersOverview/state/filterActionCreators";
import EditInfo from "./EditInfo/EditInfo";
import EditAssignPermissions from "./EditAssignPermissions/EditAssignPermissions";
import EditPeople from "./EditPeople/EditPeople";
import RemoveMembersConfirmationModal from "../../../components/people/removeMembersConfirmationModal/RemoveMembersConfirmationModal";
import DeleteRoleConfirmationModal from "../../../components/people/deleteRoleConfirmationModal/DeleteRoleConfirmationModal";
import { RemoveLinkButton, DeleteLinkButton } from "../../../components/buttons/linkButtons";
import rolesDataService from "../services/rolesDataService";
import usersDataService from "../../Application/services/dataServices/usersDataService";
import backgroundTask from "../../BackgroundTasks/backgroundTask";
import DeleteRoleTask from "../Roles/backgroundTasks/deleteRoleTask";
import RolePermissions from "../../../enums/rolePermissions";
import * as rolesOverviewActions from "../RolesOverview/state/rolesOverviewActions";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { withRouter } from "../../../adapters/withRouter/withRouter";
import navigationUtils from "../../../utils/navigationUtils";
import { RouteNames } from "enums";

import "./editRole.scss";

export class EditRole extends Component {
  segmentPaths = {
    performance: "performance",
    permissions: "permissions",
    people: "people",
  };

  state = {
    selectedUsersIds: [],
    clickedRoleUserIdToRemove: null,
    isRemoveUserConfirmationShown: false,
    showAddPeopleModal: false,
    showDeleteRoleConfirmation: false,
  };

  componentDidMount() {
    const { editRoleActions, createRoleActions } = this.props;
    editRoleActions.fetchRoleInfo(this.getRoleId());
    createRoleActions.fetchPermissions();
    createRoleActions.fetchAvailableAccounts();
    createRoleActions.fetchAvailableGroups();
  }

  getRoleId = () => parseInt(this.props.params.id);

  goToRolesOverview = () => {
    this.props.navigate(`/${RouteNames.peopleRoles}`);
  };

  goToEditUser = (userId) => {
    this.props.navigate(userId.toString());
  };

  getFilterOptions = () => {
    const { usersFilterActions } = this.props;
    usersFilterActions.getFilterOptions(true);
  };

  onSelectedUsersChanged = (ids) => {
    this.setState({ selectedUsersIds: ids });
  };

  onCloseAddPeopleModal = (isConfirmed, userIds) => {
    const { resetAddUsersSearch } = this.props;
    resetAddUsersSearch();
    this.setState({ showAddPeopleModal: false });
    if (!isConfirmed || userIds.length === 0) {
      return;
    }

    const roleId = this.getRoleId();
    const userInMessageSingular = userIds.length === 1;
    const params = {
      id: "AssignUsersToRole",
      title: "Assign users to role",
      indeterminate: true,
      getMessageIds: async () => {
        const response = await usersDataService.assignUsersToRoles(userIds, [roleId]);
        return [response.data];
      },
      successTransientMessage: userInMessageSingular
        ? "User has been assigned to role successfully"
        : "Users have been assigned to role successfully",
      failureTransientMessage: userInMessageSingular
        ? "Assigning user to role failed!"
        : "Assigning users to role failed!",
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  onRemoveRoleUsers = async () => {
    const { selectedUsersIds, clickedRoleUserIdToRemove } = this.state;
    const roleId = this.getRoleId();
    const usersToRemoveIds = clickedRoleUserIdToRemove ? [clickedRoleUserIdToRemove] : selectedUsersIds;
    const userInMessageSingular = usersToRemoveIds.length === 1;
    const params = {
      id: "UnassignUsersToRole",
      title: "Unassign users to role",
      indeterminate: true,
      getMessageIds: async () => {
        const data = await rolesDataService.unassignRolesFromUsers(usersToRemoveIds, [roleId]);
        return [data];
      },
      successTransientMessage: userInMessageSingular
        ? "User has been unassigned to role successfully"
        : "Users have been unassigned to role successfully",
      failureTransientMessage: userInMessageSingular
        ? "Unassigning user to role failed!"
        : "Unassigning users to role failed!",
    };

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    await backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });

    this.closeRemoveUserConfirmation();
    this.onSelectedUsersChanged([]);
  };

  updateRoleInfoHandler = async (roleId, name, description) => {
    const {
      editRoleActions: { updateRoleInfo },
    } = this.props;

    const params = {
      id: "UpdateRoleInformation",
      title: "Update role information",
      indeterminate: true,
      getMessageIds: async () => {
        const messageId = await updateRoleInfo(roleId, name, description);
        return [messageId];
      },
      successTransientMessage: "Role information update succeeded!",
      failureTransientMessage: "Role information update failed!",
    };
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  closeDeleteRoleModal = () => this.setState({ showDeleteRoleConfirmation: false });

  deleteRole = () => {
    const task = new DeleteRoleTask(this.getRoleId(), this.props.roleInfo.name);

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(task, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  onDeleteRole = () => this.setState({ showDeleteRoleConfirmation: true });

  onDeleteRoleConfirmed = () => {
    this.closeDeleteRoleModal();
    this.deleteRole();
    this.goToRolesOverview();
  };

  updateRolePermissionsHandler = async (permissions) => {
    const {
      editRoleActions: { updateRolePermissions },
    } = this.props;
    const roleId = this.getRoleId();

    const params = {
      id: "UpdateRolePermissions",
      title: "Update role permissions",
      indeterminate: true,
      getMessageIds: async () => {
        const messageId = await updateRolePermissions(roleId, permissions);
        return [messageId];
      },
      successTransientMessage: "Role permissions update succeeded!",
      failureTransientMessage: "Role permissions update failed!",
    };
    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    backgroundTask.updateEntity(params, {
      addOperation: addOperationV1,
      sendTransientNotification,
    });
  };

  doesUserCanManage() {
    const { userPermissions } = this.props;
    return userPermissions.includes(RolePermissions.RolesManage);
  }

  doesUserCanView() {
    const { userPermissions } = this.props;
    return userPermissions.includes(RolePermissions.RolesView);
  }

  addPeopleBtn = (
    <Button
      className="add-member create-button"
      primary
      onClick={() => {
        this.setState({ showAddPeopleModal: true });
      }}
    >
      Add People
    </Button>
  );

  renderAddPeopleButton = () => {
    const { selectedUsersIds } = this.state;

    return selectedUsersIds.length > 0 ? this.removeUsersBtn : this.addPeopleBtn;
  };

  isPeopleTab() {
    return this.props.location.pathname.endsWith(this.segmentPaths.people);
  }

  showRemoveUserConfirmation = () => {
    this.setState({
      isRemoveUserConfirmationShown: true,
    });
  };

  showRemoveUserConfirmationForId = (clickedRoleUserIdToRemove) => {
    this.setState({
      isRemoveUserConfirmationShown: true,
      clickedRoleUserIdToRemove,
    });
  };

  closeRemoveUserConfirmation = () => {
    this.setState({
      isRemoveUserConfirmationShown: false,
      clickedRoleUserIdToRemove: null,
    });
  };

  removeUsersBtn = (
    <RemoveLinkButton
      className="remove-member-button"
      data-name="remove-member-button"
      onClick={this.showRemoveUserConfirmation}
    />
  );

  renderRemoveRoleUsersModal = () => {
    const { selectedUsersIds, clickedRoleUserIdToRemove, isRemoveUserConfirmationShown } = this.state;

    return (
      <RemoveMembersConfirmationModal
        open={isRemoveUserConfirmationShown}
        usersCount={clickedRoleUserIdToRemove ? 1 : selectedUsersIds.length}
        onCancel={this.closeRemoveUserConfirmation}
        onContinue={this.onRemoveRoleUsers}
        featureName="role"
      />
    );
  };

  renderDeleteRoleConfirmationModal = () => (
    <DeleteRoleConfirmationModal
      open={this.state.showDeleteRoleConfirmation}
      onContinue={this.onDeleteRoleConfirmed}
      onCancel={this.closeDeleteRoleModal}
    />
  );

  renderHeaderButtons = () => {
    const buttons = [];
    const userCanManage = this.doesUserCanManage();

    if (this.isPeopleTab()) {
      buttons.push(this.renderAddPeopleButton());
    }

    if (
      !this.props.isInfoLoading &&
      !this.props.roleInfo.isDefault &&
      userCanManage &&
      this.state.selectedUsersIds.length === 0
    ) {
      buttons.push(<DeleteLinkButton key={this.props.roleInfo.id} onClick={this.onDeleteRole} />);
    }

    return buttons;
  };

  render() {
    const {
      roleInfo,
      isInfoLoading,
      availablePermissions,
      availableAccounts,
      isLoadingAvailableAccounts,
      availableGroups,
      isLoadingAvailableGroups,
      editRoleActions,
      editPeople,
      addUsersSearch,
      params,
      location,
      navigate,
    } = this.props;

    const { selectedUsersIds, showAddPeopleModal } = this.state;
    const userCanManage = this.doesUserCanManage();
    const userCanView = this.doesUserCanView();

    return (
      <RestrictedResource isAuthorized={userCanView}>
        <section className="edit-role nested-content">
          <GenericSectionHeader
            title={roleInfo.name || ""}
            titleForGA="Role Details"
            goBackAction={() => navigationUtils.goBackOrDefault(location, navigate, `/${RouteNames.peopleRoles}`)}
            buttons={this.renderHeaderButtons()}
            isReadOnly={!userCanManage || (roleInfo.isDefault && !this.isPeopleTab())}
          />
          <div className="last-modified-label-container">
            <TimestampLabel label="Last modified" dateTime={roleInfo.updated} format="MM/DD/YYYY h:mm A" />
          </div>

          {this.renderDeleteRoleConfirmationModal()}
          {this.renderRemoveRoleUsersModal()}

          <Segments to={`/${RouteNames.peopleRoles}/${params.id}`}>
            <Segments.Segment to={this.segmentPaths.performance} label="Performance" />
            <Segments.Segment label="Configure" />
            <Segments.Segment to={this.segmentPaths.permissions} label="Permissions" />
            <Segments.Segment to={this.segmentPaths.people} label="People" />
          </Segments>
          <Routes>
            <Route
              path="/"
              element={
                <EditInfo
                  roleInfo={roleInfo}
                  isLoading={isInfoLoading}
                  isReadOnly={!userCanManage || roleInfo.isDefault}
                  updateRoleInfo={this.updateRoleInfoHandler}
                />
              }
            />
            <Route
              path={this.segmentPaths.performance}
              element={<NotImplemented message="Check back for analytics, reports, and more!" />}
            />
            <Route
              path={this.segmentPaths.permissions}
              element={
                <EditAssignPermissions
                  permissions={availablePermissions}
                  configuredPermissions={roleInfo.permissions}
                  isLoading={isInfoLoading}
                  availableAccounts={availableAccounts}
                  isLoadingAvailableAccounts={isLoadingAvailableAccounts}
                  availableGroups={availableGroups}
                  isLoadingAvailableGroups={isLoadingAvailableGroups}
                  isReadOnly={!userCanManage || roleInfo.isDefault}
                  updateRolePermissions={this.updateRolePermissionsHandler}
                />
              }
            />
            <Route
              path={this.segmentPaths.people}
              element={
                <EditPeople
                  roleId={this.getRoleId()}
                  editRoleActions={editRoleActions}
                  editPeople={editPeople}
                  showAddPeopleModal={showAddPeopleModal}
                  onCloseAddPeopleModal={this.onCloseAddPeopleModal}
                  isRoleInfoLoading={isInfoLoading}
                  addPeopleButton={this.renderAddPeopleButton()}
                  selectedUsers={selectedUsersIds}
                  onSelectedUsersChanged={this.onSelectedUsersChanged}
                  getFilterOptions={this.getFilterOptions}
                  goToEditUser={this.goToEditUser}
                  onRemoveMemberClick={this.showRemoveUserConfirmationForId}
                  isReadOnly={!userCanManage}
                  addUsersSearch={addUsersSearch}
                />
              }
            />
            <Route path="*" element={<Navigate to="../" replace />} />
          </Routes>
        </section>
      </RestrictedResource>
    );
  }
}

EditRole.propTypes = {
  accountId: PropTypes.number,
  navigate: PropTypes.func,
  params: PropTypes.object,
  location: PropTypes.object,
  roleInfo: PropTypes.object,
  isInfoLoading: PropTypes.bool,
  availablePermissions: PropTypes.array,
  isLoadingAvailableAccounts: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    createRole: {
      permissions,
      availableAccounts,
      isLoadingAvailableAccounts,
      availableGroups,
      isLoadingAvailableGroups,
    },
    editRole,
    usersOverview,
    rolesOverview,
  } = state.people;

  return {
    accountId: state.userProfile.accountId,
    userPermissions: state.userProfile.permissions,
    roleInfo: editRole.roleInfo.data,
    isInfoLoading: editRole.roleInfo.isLoading,
    availablePermissions: permissions,
    availableAccounts: availableAccounts,
    isLoadingAvailableAccounts: isLoadingAvailableAccounts,
    availableGroups: availableGroups,
    isLoadingAvailableGroups: isLoadingAvailableGroups,
    editPeople: {
      list: editRole.usersList,
      filterOptions: { showRoleFilter: false, ...usersOverview.filterOptions },
    },
    addUsersSearch: rolesOverview.addUsersSearch,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetAddUsersSearch: bindActionCreators(rolesOverviewActions.resetAddUsersSearch, dispatch),
    editRoleActions: bindActionCreators(editRoleActionsRedux, dispatch),
    createRoleActions: bindActionCreators(createRoleActionsRedux, dispatch),
    usersFilterActions: bindActionCreators(usersFilterActionsRedux, dispatch),
    notificationsActions: bindActionCreators(notificationsActions, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  };
};

/* istanbul ignore next */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withLDConsumer()(EditRole)));
