import React from "react";

import { RolePermissions } from "../../../../enums";
import Restricted from "../../../Application/Restricted";
import ApiKeys from "../ApiKeys/ApiKeys";

import "./EditPublicApi.scss";

const EditPublicApi: React.FC = () => {
  const renderContent = (canManage: boolean) => {
    const readonly = !canManage;

    return (
      <section className="nested-content edit-api">
        <div className="edit-api-header">Keys</div>
        <ApiKeys readonly={readonly} />
      </section>
    );
  };

  return <Restricted permissions={[RolePermissions.APIsManage]} renderContent={renderContent} />;
};

export default EditPublicApi;
