import { Component } from "react";
import { PropTypes } from "prop-types";
import PlusButton from "../buttons/iconButtons/plusButton/PlusButton";
import MinusButton from "../buttons/iconButtons/minusButton/MinusButton";
import LabelWithInfoTooltip from "../labelWithInfoTooltip/labelWithInfoTooltip";
import { Grid } from "semantic-ui-react";
import cn from "classnames";

import "./expressionBuilder.scss";

class ExpressionBuilder extends Component {
  addItem = () => {
    const { onChange, items, onAdd, getDefaultNewItem } = this.props;
    const newItems = [...items];
    newItems.unshift(getDefaultNewItem());
    onAdd?.();
    onChange?.(newItems);
  };

  removeItem = (index) => {
    const { onChange, items, onRemove } = this.props;
    const newItems = [...items];
    newItems.splice(index, 1);
    onRemove?.(index);
    onChange?.(newItems);
  };

  updateFieldData = (valueName, value, i) => {
    const { items, onChange } = this.props;
    const newItems = [...items];
    newItems[i][valueName] = value;
    onChange?.(newItems);
  };

  isTitleVisible = (titleItem) => {
    const { items } = this.props;

    return (
      items.filter((item) => {
        return item[titleItem.fieldName] !== undefined;
      }).length > 0
    );
  };

  getTitle(titleItem, index) {
    if (this.isTitleVisible(titleItem, index)) {
      return titleItem.info ? (
        <LabelWithInfoTooltip label={titleItem.title} info={titleItem.info} width={titleItem.width ?? 23} />
      ) : (
        titleItem.title
      );
    }

    return "";
  }

  renderGrid = () => {
    const { items, columnTitles, getInputs, columnsAmount, canAdd, isReadOnly, children, classNameButtons } =
      this.props;
    return (
      <Grid columns={columnsAmount + 1}>
        <Grid.Row className="expression-builder-titles">
          {columnTitles.map((titleItem, i) => (
            <Grid.Column key={i}>{this.getTitle(titleItem, i)}</Grid.Column>
          ))}
        </Grid.Row>
        {items.map((item, i) => {
          return (
            <Grid.Row key={item.id ?? i}>
              {/*updateFieldData func for managing values without formik*/}
              {children
                ? children({ items: items, index: i, onChange: this.updateFieldData })
                : getInputs(items, i, this.updateFieldData).map((input, index) => (
                    <Grid.Column key={index}>{input}</Grid.Column>
                  ))}
              <Grid.Column className="controlPanel" width={1}>
                <div className={cn("control-panel", classNameButtons, { "one-button": i !== 0 })}>
                  <MinusButton isDisabled={isReadOnly || items.length === 1} onClick={() => this.removeItem(i)} />
                  {i === 0 && <PlusButton onClick={this.addItem} isDisabled={isReadOnly || !canAdd} />}
                </div>
              </Grid.Column>
            </Grid.Row>
          );
        })}
      </Grid>
    );
  };

  render() {
    return <div className={cn("expression-builder", this.props.className)}>{this.renderGrid()}</div>;
  }
}

ExpressionBuilder.defaultProps = {
  canAdd: true,
  isReadOnly: false,
};

ExpressionBuilder.propTypes = {
  getInputs: PropTypes.func,
  onChange: PropTypes.func,
  items: PropTypes.array.isRequired,
  columnTitles: PropTypes.array.isRequired,
  getDefaultNewItem: PropTypes.func.isRequired,
  columnsAmount: PropTypes.number.isRequired,
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  isReadOnly: PropTypes.bool,
  classNameButton: PropTypes.string,
};

export default ExpressionBuilder;
