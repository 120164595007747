import { ReceiveUpdatesTypes } from "../../types/flowBase";
import * as infoActionTypes from "../actionTypes/infoActionTypes";
import flowsDataService from "../../services/flowsDataService";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { createAction } from "@reduxjs/toolkit";

export const changeReceiveUpdatesType =
  (flowId: number, updateType: ReceiveUpdatesTypes) => async (dispatch: AppDispatch) => {
    try {
      await flowsDataService.changeReceiveUpdatesType(flowId, updateType);
    } catch (e) {}
    const saveFlowInfoProperty = createAction<object>(infoActionTypes.FLOW_SAVE_INFO_PROPERTY);
    dispatch(saveFlowInfoProperty({ info: { property: "updateType", value: updateType } }));
  };
