import React, { FC, SyntheticEvent, useState } from "react";
import { DropdownProps } from "semantic-ui-react";

import { UsersGroupsContext } from "../../../../../enums";
import { PeopleWrapperProps } from "../types";
import { PeopleSegmentationDropdown } from "../../../../../components";

import Restricted from "../../../../Application/Restricted";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

const PeopleWrapper: FC<PeopleWrapperProps> = ({
  permission,
  renderUsersOverview,
  renderGroupsOverview,
  renderTemplateGroupsOverview,
  usersGroupsContext,
  onContextChanged,
  disableSelectionChange,
}): React.ReactElement => {
  const [context, setContext] = useState<UsersGroupsContext>(
    disableSelectionChange ? UsersGroupsContext.TemplateGroups : usersGroupsContext ?? UsersGroupsContext.Groups,
  );
  const groupTemplatesFeature = useFeatureFlag(FeatureFlags.GroupTemplates);
  const [includeTemplateGroupsOption] = useState<boolean>(
    renderTemplateGroupsOverview && groupTemplatesFeature ? true : false,
  );

  const onChangeContext = (_: SyntheticEvent<HTMLElement, Event>, data: DropdownProps): void => {
    const value = data.value as UsersGroupsContext;
    setContext(value);
    onContextChanged?.(value);
  };

  const renderContextSwitcher = (): React.ReactElement => (
    <PeopleSegmentationDropdown
      onChange={onChangeContext}
      defaultOption={context}
      includeTemplateGroupsOption={includeTemplateGroupsOption}
      disableSelectionChange={disableSelectionChange}
    />
  );

  const contentList = (isReadOnly: boolean) => ({
    [UsersGroupsContext.Users]: renderUsersOverview(renderContextSwitcher(), isReadOnly),
    [UsersGroupsContext.Groups]: renderGroupsOverview(renderContextSwitcher(), isReadOnly),
    [UsersGroupsContext.TemplateGroups]: renderTemplateGroupsOverview ? (
      renderTemplateGroupsOverview?.(renderContextSwitcher(), isReadOnly)
    ) : (
      <></>
    ),
  });

  return (
    <Restricted
      permissions={[permission]}
      renderContent={(hasAnyPermission) => contentList(!hasAnyPermission)[context]}
    />
  );
};
export default PeopleWrapper;
