import { Dispatch, useEffect } from "react";
import RtnEventsEmitter from "../features/Application/services/realTimeNotification/rtnEventsEmitter";
import initializeHubClient from "features/Application/services/realTimeNotification/initializeHubClient";
import { AnyAction } from "@reduxjs/toolkit";
import { AsyncOperationState } from "features/Application/slices/asyncOperationSlice";

export const useRtn = (
  rtnTypes: Array<string> | string,
  handler: Function,
  moboId?: string,
  dispatch?: Dispatch<AnyAction>,
  asyncOperations?: AsyncOperationState,
) => {
  useEffect(() => {
    if (moboId && dispatch && asyncOperations) {
      const handlerWithMoboId = () => {
        handler(moboId);
      };

      const realTimeNotificationClient = initializeHubClient(dispatch, { id: moboId }, () => asyncOperations);
      realTimeNotificationClient.connect();
      RtnEventsEmitter.subscribe(rtnTypes, handlerWithMoboId);
      return () => {
        RtnEventsEmitter.unsubscribe(rtnTypes, handlerWithMoboId);
        realTimeNotificationClient.disconnect();
      };
    } else {
      RtnEventsEmitter.subscribe(rtnTypes, handler);
      return () => RtnEventsEmitter.unsubscribe(rtnTypes, handler);
    }
  }, [rtnTypes, handler, dispatch, asyncOperations, moboId]);
};
