import { Moment } from "moment/moment";
import dateTimeUtils from "./dateTimeUtils";
import { PeriodicRule, PeriodType } from "../features/Library/Events/types/state";

enum DayOfWeekNumber {
  First = "first",
  Second = "second",
  Third = "third",
  Fourth = "fourth",
}

const dayOfWeeks = [
  DayOfWeekNumber.First,
  DayOfWeekNumber.Second,
  DayOfWeekNumber.Third,
  DayOfWeekNumber.Fourth,
  DayOfWeekNumber.Fourth,
];
const weekMonthlyMap = {
  [DayOfWeekNumber.First]: PeriodicRule.MonthlyFirstDayOfWeekInMonth,
  [DayOfWeekNumber.Second]: PeriodicRule.MonthlySecondDayOfWeekInMonth,
  [DayOfWeekNumber.Third]: PeriodicRule.MonthlyThirdDayOfWeekInMonth,
  [DayOfWeekNumber.Fourth]: PeriodicRule.MonthlyFourthDayOfWeekInMonth,
};
const weekYearlyMap = {
  [DayOfWeekNumber.First]: PeriodicRule.YearlyFirstDayOfWeekInMonth,
  [DayOfWeekNumber.Second]: PeriodicRule.YearlySecondDayOfWeekInMonth,
  [DayOfWeekNumber.Third]: PeriodicRule.YearlyThirdDayOfWeekInMonth,
  [DayOfWeekNumber.Fourth]: PeriodicRule.YearlyFourthDayOfWeekInMonth,
};

const eventSessionUtils = {
  getRadioOptions: (date: Moment, type?: PeriodType) => {
    const dayOfWeekNumber = dayOfWeeks[dateTimeUtils.getWeekNumber(date)];
    const weekDay = dateTimeUtils.getWeekDayName(date);
    const isLastWeek = dateTimeUtils.isLastWeekInMonth(date);
    const day = date.date();

    const radioOptions: { [key: number]: string } = {};
    switch (type) {
      case PeriodType.Month:
        radioOptions[PeriodicRule.MonthlyOnExactDayInMonth] = `On day ${day}`;
        if (dayOfWeekNumber) {
          radioOptions[weekMonthlyMap[dayOfWeekNumber]] = `On the ${dayOfWeekNumber} ${weekDay}`;
        }
        if (isLastWeek) {
          radioOptions[PeriodicRule.MonthlyLastDayOfWeekInMonth] = `On the last ${weekDay}`;
        }
        break;
      case PeriodType.Year:
        const month = dateTimeUtils.getMonthName(date);
        radioOptions[PeriodicRule.YearlyOnExactDayAndMonth] = `On ${month} ${day}`;
        if (dayOfWeekNumber) {
          radioOptions[weekYearlyMap[dayOfWeekNumber]] = `On the ${dayOfWeekNumber} ${weekDay} of ${month}`;
        }
        if (isLastWeek) {
          radioOptions[PeriodicRule.YearlyLastDayOfWeekInMonth] = `On the last ${weekDay} of ${month}`;
        }
        break;
    }
    return radioOptions;
  },
};

export default eventSessionUtils;
