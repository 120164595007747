import { connect } from "react-redux";

import { RootState } from "../../../../Application/globaltypes/redux";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import { getPeopleEntityLabel } from "../../../../../utils/peopleAssignmentModalUtils";
import { assessmentsStateSelector } from "../../state/selectors";
import SelectedItemsLabel from "../../../../../components/sectionHeader/selectedItemsLabel/SelectedItemsLabel";

const mapStateToProps = (state: RootState, ownProps: any) => {
  const edit = assessmentsStateSelector(state).edit;

  const selectedUsersCount = edit.users.selectedIds.length;
  const selectedGroupsCount = edit.groups.selectedIds.length;
  const selectedPeopleCount = selectedUsersCount + selectedGroupsCount;

  const getHeaderTitle = () => {
    let headerTitle;

    if (selectedPeopleCount) {
      headerTitle = (
        <SelectedItemsLabel
          itemName={getPeopleEntityLabel(selectedUsersCount, selectedGroupsCount)}
          selectedItemsAmount={selectedPeopleCount}
          shouldPluralize={false}
        />
      );
    } else {
      headerTitle = ownProps.title;
    }

    return headerTitle;
  };

  return {
    title: getHeaderTitle(),
  };
};

export default connect(mapStateToProps)(DetailsHeader);
