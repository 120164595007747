import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PropTypes } from "prop-types";

import * as wizardWrapperActions from "./state/wizardWrapperActions";
import { Wizard, wizardPropTypes } from "../../components/wizard";

export class WizardWrapper extends Component {
  static Step = Wizard.Step;

  render() {
    return <Wizard {...this.props} />;
  }
}

WizardWrapper.propTypes = {
  wizardActions: PropTypes.object.isRequired,
  ...wizardPropTypes,
};

const mapDispatchToProps = (dispatch) => {
  return {
    wizardActions: bindActionCreators(wizardWrapperActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(WizardWrapper);
