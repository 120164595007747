import { sum } from "lodash";

import { Title } from "../../../../components/listViewTemplates";
import { Tooltip } from "../../../../components/common/tooltip";
import { TextTruncate } from "../../../../components";
import { PublishedStatus } from "../../../../components/common/publishedStatus";
import { EditLinkButton, DuplicateLinkButton, DeleteLinkButton } from "../../../../components/buttons/linkButtons";
import { ContentItemMenu } from "../../../../components/buttons/contentItemMenu/ContentItemMenu";
import { AssessmentOverview } from "../types/state";
import { ColumnOption } from "../../../../interfaces/columnOptions";
import { PublishedStatusTypes } from "../../../../enums";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import DeletionRestrictedTooltip from "../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";

export enum Columns {
  Title = "Title", //NOSONAR
  Publisher = "Publisher",
  Status = "Status",
  Modified = "Modified",
  Added = "Added",
}

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  title: "title",
  status: "isDraft",
  modified: "dateModified",
  added: "dateCreated",
  publisher: "publisher",
};

export interface GetColumnOptionsParams {
  readonly: boolean;
  deleteContentWithDependenciesFeatureEnabled: boolean;
  handleTitleClick: (id: number) => () => void;
  handleEditClick: (id: number, isDraft?: boolean) => () => void;
  handleDuplicateClick: (ids: number[]) => () => void;
  handleDeleteClick: (ids: number[], flowsCount?: number, packsCount?: number) => () => void;
}

export const getColumnOptions = (params: GetColumnOptionsParams): ColumnOption<AssessmentOverview>[] => [
  {
    name: Columns.Title,
    width: 4,
    isSortable: true,
    render: (assessment) => (
      <Title
        url={assessment.id.toString()}
        title={assessment.title}
        onTitleClick={params.handleTitleClick(assessment.id)}
        clamp={2}
        className="normal-color"
      />
    ),
  },
  {
    name: Columns.Publisher,
    width: 3,
    isSortable: false,
    render: (assessment) => (
      <Tooltip target={<TextTruncate>{assessment.publisher}</TextTruncate>} content={assessment.publisher} />
    ),
  },
  {
    name: Columns.Status,
    width: 4,
    isSortable: true,
    render: (assessment) => (
      <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!assessment.isDraft)} />
    ),
  },
  {
    name: Columns.Modified,
    width: 2,
    isSortable: true,
    render: (assessment) => dateTimeUtils.formatDate(assessment.dateModified),
  },
  {
    name: Columns.Added,
    width: 2,
    isSortable: true,
    render: (assessment) => dateTimeUtils.formatDate(assessment.dateCreated),
  },
  {
    name: "",
    width: 1,
    className: "align-right",
    isSortable: false,
    render: (assessment, isChecked) => {
      const { flowsCount = 0, packsCount = 0, assignmentsCount = 0 } = assessment.dependencies || {};
      const dependencies = { flows: flowsCount, packs: packsCount, "users or groups": assignmentsCount };
      const entityType = "assessment";
      return (
        <RestrictedByTooltip hasPermission={!params.readonly}>
          <ContentItemMenu
            circle
            outlinedEllipsis
            isChecked={isChecked}
            item={assessment}
            readonly={params.readonly || assessment.isPurchased}
            entityType={entityType}
            dependencies={dependencies}
          >
            <EditLinkButton
              isDisabled={assessment.isPurchased}
              onClick={params.handleEditClick(assessment.id, assessment.isDraft)}
            />
            <DuplicateLinkButton
              isDisabled={assessment.isPurchased}
              onClick={params.handleDuplicateClick([assessment.id])}
            />
            <DeleteLinkButton
              isDisabled={!params.deleteContentWithDependenciesFeatureEnabled && !assessment.canBeDeleted}
              after={
                !params.deleteContentWithDependenciesFeatureEnabled &&
                !assessment.isPurchased &&
                !!sum(Object.values(dependencies)) && (
                  <DeletionRestrictedTooltip dependencies={dependencies} entityType={entityType} />
                )
              }
              onClick={params.handleDeleteClick(
                [assessment.id],
                assessment.dependencies?.flowsCount,
                assessment.dependencies?.packsCount,
              )}
            />
          </ContentItemMenu>
        </RestrictedByTooltip>
      );
    },
  },
];
