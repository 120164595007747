import * as Yup from "yup";
import { CustomContent, PackContent } from "../../features/Accounts/Integrations/types/successFactors";

const maxStringLength = 256;

export const successFactorValidationSchema = Yup.object().shape({
  sftpSettings: Yup.object().shape({
    host: Yup.string().strict().label("Host").trim().required().max(maxStringLength),
    username: Yup.string().strict().label("Username").trim().required().max(maxStringLength),
    password: Yup.string().label("Password").trim().required().max(maxStringLength),
    fileLocation: Yup.string().strict().label("File location").trim().max(maxStringLength).nullable(),
  }),
  contentSettings: Yup.object().shape({
    contentType: Yup.mixed<CustomContent>().defined(),
  }),
  isActive: Yup.boolean().defined(),
  packSettings: Yup.object().shape({
    contentType: Yup.mixed<PackContent>().defined(),
    packIds: Yup.array(Yup.number().defined()).defined(),
  }),
  tenantId: Yup.string().strict().label("Tenant ID").trim().required().max(maxStringLength),
  completionHistorySettings: Yup.object()
    .nullable()
    .when("isHistoryEnabled", {
      is: true,
      then: (schema) =>
        schema
          .transform((value) => (value === null ? undefined : value)) // cast null to undefined to get inner fields validated
          .shape({
            learningAdministrationUrl: Yup.string()
              .strict()
              .label("Learning Administration URL")
              .trim()
              .required()
              .max(maxStringLength),
            companyId: Yup.string().strict().label("Company ID").trim().required().max(maxStringLength),
            clientId: Yup.string().strict().label("Client ID").trim().required().max(maxStringLength),
            clientSecret: Yup.string().strict().label("Client Secret").required().trim().max(maxStringLength),
            adminUserId: Yup.string().strict().label("Admin User ID").required().trim().max(maxStringLength),
          }),
    }),
  isHistoryEnabled: Yup.bool().defined(),
  columnValues: Yup.object()
    .nullable()
    .shape({
      domain: Yup.string().strict().label("Domain ID").trim().max(30),
    }),
});
