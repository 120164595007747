import React, { useState } from "react";
import Triggers from "./Triggers/Triggers";
import { Trigger, TriggersChanges } from "../../types";
import TriggerType from "../../../../../../enums/flowDesigner/triggerType";
import { BackButton } from "../BackButton/BackButton";
import DefaultTrigger from "./DefaultTrigger/DefaultTrigger";
import "./triggerView.scss";

export interface Props {
  title: string;
  onBackButtonClick: () => void;
  triggers: Trigger[];
  updateTriggerDataChanges: (changedData: TriggersChanges[]) => void;
  isReadOnly: boolean;
}

function TriggerView(props: Props) {
  const { title, onBackButtonClick, triggers, updateTriggerDataChanges, isReadOnly } = props;
  const [triggerChanges, setTriggerChanges] = useState<TriggersChanges[]>([]);

  const onTriggerChange = (value: number, source: string, destination: string) => {
    let updates: any = {
      typeId: value,
      timeSpan: 0,
    };
    if (value === TriggerType.NotConsumed) {
      updates = {
        ...updates,
        timeSpan: undefined,
      };
    }
    updateTriggerChanges(updates, source, destination);
  };
  const onUnitsChange = (value: string, source: string, destination: string) => {
    updateTriggerChanges({ timeUnitId: value }, source, destination);
  };
  const onTimeSpanChange = (value: string, source: string, destination: string) => {
    updateTriggerChanges({ timeSpan: value }, source, destination);
  };
  const onDestinationChange = (/*event, data*/) => {
    // update flow designer state with data.value
  };
  const onIsActionChange = (value: boolean, source: string, destination: string, bulletId?: string | null) => {
    updateTriggerChanges({ isAction: value, isDefault: false }, source, destination, bulletId);
  };
  const onIsDefaultChange = (id: string) => {
    updateTriggerDataChanges(
      triggers.map((item) => ({ ...item, isDefault: !item.isAction && `${item.typeId}-${item.bulletId}` === id })),
    );
  };
  const onDelayBlur = (source: string, destination: string) => {
    const index = triggerChanges.findIndex((item) => item.inId === source && item.outId === destination);

    if (index > -1 && !triggerChanges[index]?.timeSpan) {
      updateTriggerChanges({ timeSpan: 0 }, source, destination);
    }
  };

  const updateTriggerChanges = (value: object, source: string, destination: string, bulletId?: string | null) => {
    const index = triggerChanges.findIndex(
      (item) => item.inId === source && item.outId === destination && (!bulletId || item.bulletId === bulletId),
    );

    const deltaObj: TriggersChanges = {
      ...value,
      inId: source,
      outId: destination,
    };

    if (bulletId) {
      deltaObj.bulletId = bulletId;
    }

    const changedData = [...triggerChanges];

    if (index !== -1) {
      const f = changedData[index];
      changedData[index] = {
        ...f,
        ...value,
      };
      setTriggerChanges(changedData);
    } else {
      changedData.push(deltaObj);
      setTriggerChanges(changedData);
    }

    updateTriggerDataChanges(changedData);
  };

  return (
    <div className="flow-designer-trigger-view">
      <BackButton title={title} onClick={onBackButtonClick} />
      <div className="flow-designer-triggers ui segment">
        <Triggers
          triggers={triggers}
          isReadOnly={isReadOnly}
          onTriggerChange={onTriggerChange}
          onDestinationChange={onDestinationChange}
          onTimeSpanChange={onTimeSpanChange}
          onUnitsChange={onUnitsChange}
          onDelayBlur={onDelayBlur}
          onIsActionChange={onIsActionChange}
        />
        <DefaultTrigger triggers={triggers} isReadOnly={isReadOnly} onIsDefaultChange={onIsDefaultChange} />
      </div>
    </div>
  );
}

export default TriggerView;
