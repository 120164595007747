import React, { useMemo, useReducer } from "react";
import { contentReducer } from "./ContentReducer";

interface ContentContextState {
  branchingQuestionId?: string;
  toggleBranchingQuestion: (index: string) => void;
  turnOffBranchingQuestion: (index: string) => void;
}

export const ContentContext = React.createContext<ContentContextState | undefined>(undefined);

export const ContentContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [contentState, dispatch] = useReducer(contentReducer, { branchingQuestionId: undefined });

  const memorizedValue = useMemo(() => {
    return {
      ...contentState,
      toggleBranchingQuestion: (index: string) => dispatch({ type: "TOGGLE_BRANCHING_QUESTION", payload: index }),
      turnOffBranchingQuestion: (index: string) => dispatch({ type: "TURN_OFF_BRANCHING_QUESTION", payload: index }),
    };
  }, [contentState]);

  return <ContentContext.Provider value={memorizedValue}>{props.children}</ContentContext.Provider>;
};

export const useContentContext = () => {
  const context = React.useContext(ContentContext);

  if (!context) {
    throw new Error("ContentContext has not been defined");
  }

  return context;
};
