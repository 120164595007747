import { PayloadAction } from "../../../../../../interfaces/redux";
import { createReducer } from "../../../../../../utils/reduxUtils";
import * as assignedUsersActionTypes from "../actionTypes/emailAssignedUsersActionTypes";
import { AssignedUsersStateType, EmailAssignedUsersState } from "../../types/state";
import { withFetchingEntityListItems } from "../../../../../Application/reducers/hoc/withFetchingItems";
import withFilteringItems from "../../../../../Application/reducers/hoc/withFilteringItems";
import { combineReducers, Reducer } from "redux";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import reduceReducers from "reduce-reducers";
import { EmailAssignedUser } from "../../types/models";

export const initialState: EmailAssignedUsersState = {
  assignedUsers: {
    itemsCount: 0,
    items: [],
    addedUsersCount: 0,
    isLoading: false,
    error: null,
  },
  filter: {},
};

export const emailAssignedUsersHandlers = () => {
  const setUsersHandler = (
    state: EmailAssignedUsersState,
    action: PayloadAction<EmailAssignedUser[]>,
  ): EmailAssignedUsersState => {
    return {
      ...state,
      assignedUsers: {
        ...state.assignedUsers,
        items: action.payload,
      },
    };
  };

  const setIsLoadingHandler = (
    state: EmailAssignedUsersState,
    action: PayloadAction<boolean>,
  ): EmailAssignedUsersState => {
    return {
      ...state,
      assignedUsers: {
        ...state.assignedUsers,
        isLoading: action.payload,
      },
    };
  };

  const setUserCountHandler = (
    state: EmailAssignedUsersState,
    action: PayloadAction<number>,
  ): EmailAssignedUsersState => {
    return {
      ...state,
      assignedUsers: {
        ...state.assignedUsers,
        itemsCount: action.payload,
      },
    };
  };

  const setAddedUsersCountHandler = (
    state: EmailAssignedUsersState,
    action: PayloadAction<number>,
  ): EmailAssignedUsersState => {
    return {
      ...state,
      assignedUsers: {
        ...state.assignedUsers,
        addedUsersCount: action.payload,
      },
    };
  };

  const resetHandler = (): EmailAssignedUsersState => initialState;

  const { setEmailAssignedUsers, setAreUsersLoading, setEmailAssignedUsersCount, setEmailAddedUsersCount, reset } =
    assignedUsersActionTypes;

  return {
    [setEmailAssignedUsers]: setUsersHandler,
    [setAreUsersLoading]: setIsLoadingHandler,
    [setEmailAssignedUsersCount]: setUserCountHandler,
    [setEmailAddedUsersCount]: setAddedUsersCountHandler,
    [reset]: resetHandler,
  };
};

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.Emails;

export const emailAssignedUsersReducer = createReducer(initialState, [emailAssignedUsersHandlers]);

export const combinedBase = combineReducers({
  assignedUsers: withFetchingEntityListItems<AssignedUsersStateType>(namespace, entityPrefix),
  filter: withFilteringItems(namespace, entityPrefix),
});

const EmailsAssignaedUsers = reduceReducers<EmailAssignedUsersState>(combinedBase, emailAssignedUsersReducer);

export type EmailsAssignedUsersState = ReturnType<typeof EmailsAssignaedUsers>;

export default EmailsAssignaedUsers as Reducer<EmailsAssignedUsersState>;
