import * as actionTypes from "./generalLoaderActionTypes";

export const showGeneralLoader = { type: actionTypes.SHOW_GENERAL_LOADER };

export const hideGeneralLoader = { type: actionTypes.HIDE_GENERAL_LOADER };

export const showGeneralLoaderWithTimeout = (timeoutMilliseconds, callbackOnTimeout) => (dispatch) => {
  dispatch({ type: actionTypes.SHOW_GENERAL_LOADER });

  setTimeout(() => {
    dispatch({ type: actionTypes.HIDE_GENERAL_LOADER });
    callbackOnTimeout && callbackOnTimeout();
  }, timeoutMilliseconds);
};
