import { EngagementLineData, EngagementPeopleData, VideoEngagementData } from "../state/slices/videoPerformanceSlice";

export const VideoEngagementLineData: EngagementLineData = [
  {
    Date: "2022-05-02",
    Events: {
      NotStartedCount: 1,
      StartedCount: 3,
      CompletedCount: 6,
      CumulativeNotStartedCount: 1,
      CumulativeStartedCount: 3,
      CumulativeCompletedCount: 6,
    },
  },
  {
    Date: "2022-05-03",
    Events: {
      NotStartedCount: 7,
      StartedCount: 9,
      CompletedCount: 20,
      CumulativeNotStartedCount: 8,
      CumulativeStartedCount: 12,
      CumulativeCompletedCount: 26,
    },
  },
  {
    Date: "2022-05-04",
    Events: {
      NotStartedCount: 5,
      StartedCount: 8,
      CompletedCount: 0,
      CumulativeNotStartedCount: 13,
      CumulativeStartedCount: 20,
      CumulativeCompletedCount: 26,
    },
  },
  {
    Date: "2022-05-05",
    Events: {
      NotStartedCount: 3,
      StartedCount: 5,
      CompletedCount: 7,
      CumulativeNotStartedCount: 16,
      CumulativeStartedCount: 25,
      CumulativeCompletedCount: 33,
    },
  },
  {
    Date: "2022-05-06",
    Events: {
      NotStartedCount: 6,
      StartedCount: 9,
      CompletedCount: 12,
      CumulativeNotStartedCount: 22,
      CumulativeStartedCount: 34,
      CumulativeCompletedCount: 45,
    },
  },
  {
    Date: "2022-05-07",
    Events: {
      NotStartedCount: 15,
      StartedCount: 17,
      CompletedCount: 36,
      CumulativeNotStartedCount: 37,
      CumulativeStartedCount: 51,
      CumulativeCompletedCount: 81,
    },
  },
  {
    Date: "2022-05-08",
    Events: {
      NotStartedCount: 3,
      StartedCount: 6,
      CompletedCount: 9,
      CumulativeNotStartedCount: 40,
      CumulativeStartedCount: 57,
      CumulativeCompletedCount: 90,
    },
  },
  {
    Date: "2022-05-09",
    Events: {
      NotStartedCount: 0,
      StartedCount: 0,
      CompletedCount: 0,
      CumulativeNotStartedCount: 40,
      CumulativeStartedCount: 57,
      CumulativeCompletedCount: 90,
    },
  },
  {
    Date: "2022-05-10",
    Events: {
      NotStartedCount: 0,
      StartedCount: 0,
      CompletedCount: 0,
      CumulativeNotStartedCount: 40,
      CumulativeStartedCount: 57,
      CumulativeCompletedCount: 90,
    },
  },
  {
    Date: "2022-05-11",
    Events: {
      NotStartedCount: 3,
      StartedCount: 6,
      CompletedCount: 9,
      CumulativeNotStartedCount: 43,
      CumulativeStartedCount: 63,
      CumulativeCompletedCount: 99,
    },
  },
  {
    Date: "2022-05-12",
    Events: {
      NotStartedCount: 1,
      StartedCount: 3,
      CompletedCount: 6,
      CumulativeNotStartedCount: 44,
      CumulativeStartedCount: 66,
      CumulativeCompletedCount: 105,
    },
  },
  {
    Date: "2022-05-13",
    Events: {
      NotStartedCount: 0,
      StartedCount: 0,
      CompletedCount: 0,
      CumulativeNotStartedCount: 44,
      CumulativeStartedCount: 66,
      CumulativeCompletedCount: 105,
    },
  },
  {
    Date: "2022-05-14",
    Events: {
      NotStartedCount: 1,
      StartedCount: 3,
      CompletedCount: 6,
      CumulativeNotStartedCount: 45,
      CumulativeStartedCount: 69,
      CumulativeCompletedCount: 111,
    },
  },
  {
    Date: "2022-05-15",
    Events: {
      NotStartedCount: 7,
      StartedCount: 9,
      CompletedCount: 20,
      CumulativeNotStartedCount: 52,
      CumulativeStartedCount: 78,
      CumulativeCompletedCount: 131,
    },
  },
  {
    Date: "2022-05-16",
    Events: {
      NotStartedCount: 3,
      StartedCount: 6,
      CompletedCount: 9,
      CumulativeNotStartedCount: 55,
      CumulativeStartedCount: 84,
      CumulativeCompletedCount: 140,
    },
  },
  {
    Date: "2022-05-17",
    Events: {
      NotStartedCount: 5,
      StartedCount: 8,
      CompletedCount: 0,
      CumulativeNotStartedCount: 60,
      CumulativeStartedCount: 92,
      CumulativeCompletedCount: 140,
    },
  },
  {
    Date: "2022-05-18",
    Events: {
      NotStartedCount: 35,
      StartedCount: 0,
      CompletedCount: 6,
      CumulativeNotStartedCount: 95,
      CumulativeStartedCount: 92,
      CumulativeCompletedCount: 146,
    },
  },
  {
    Date: "2022-05-19",
    Events: {
      NotStartedCount: 1,
      StartedCount: 7,
      CompletedCount: 5,
      CumulativeNotStartedCount: 96,
      CumulativeStartedCount: 99,
      CumulativeCompletedCount: 151,
    },
  },
  {
    Date: "2022-05-20",
    Events: {
      NotStartedCount: 5,
      StartedCount: 8,
      CompletedCount: 0,
      CumulativeNotStartedCount: 101,
      CumulativeStartedCount: 107,
      CumulativeCompletedCount: 151,
    },
  },
  {
    Date: "2022-05-21",
    Events: {
      NotStartedCount: 3,
      StartedCount: 6,
      CompletedCount: 9,
      CumulativeNotStartedCount: 104,
      CumulativeStartedCount: 113,
      CumulativeCompletedCount: 160,
    },
  },
  {
    Date: "2022-05-22",
    Events: {
      NotStartedCount: 1,
      StartedCount: 3,
      CompletedCount: 6,
      CumulativeNotStartedCount: 105,
      CumulativeStartedCount: 116,
      CumulativeCompletedCount: 166,
    },
  },
  {
    Date: "2022-05-23",
    Events: {
      NotStartedCount: 9,
      StartedCount: 8,
      CompletedCount: 5,
      CumulativeNotStartedCount: 114,
      CumulativeStartedCount: 124,
      CumulativeCompletedCount: 171,
    },
  },
  {
    Date: "2022-05-24",
    Events: {
      NotStartedCount: 1,
      StartedCount: 4,
      CompletedCount: 6,
      CumulativeNotStartedCount: 115,
      CumulativeStartedCount: 128,
      CumulativeCompletedCount: 177,
    },
  },
  {
    Date: "2022-05-25",
    Events: {
      NotStartedCount: 5,
      StartedCount: 8,
      CompletedCount: 9,
      CumulativeNotStartedCount: 120,
      CumulativeStartedCount: 136,
      CumulativeCompletedCount: 186,
    },
  },
  {
    Date: "2022-05-26",
    Events: {
      NotStartedCount: 7,
      StartedCount: 9,
      CompletedCount: 20,
      CumulativeNotStartedCount: 127,
      CumulativeStartedCount: 145,
      CumulativeCompletedCount: 206,
    },
  },
  {
    Date: "2022-05-27",
    Events: {
      NotStartedCount: 9,
      StartedCount: 6,
      CompletedCount: 5,
      CumulativeNotStartedCount: 136,
      CumulativeStartedCount: 151,
      CumulativeCompletedCount: 211,
    },
  },
  {
    Date: "2022-05-28",
    Events: {
      NotStartedCount: 3,
      StartedCount: 6,
      CompletedCount: 9,
      CumulativeNotStartedCount: 139,
      CumulativeStartedCount: 157,
      CumulativeCompletedCount: 220,
    },
  },
  {
    Date: "2022-05-29",
    Events: {
      NotStartedCount: 1,
      StartedCount: 6,
      CompletedCount: 4,
      CumulativeNotStartedCount: 140,
      CumulativeStartedCount: 163,
      CumulativeCompletedCount: 224,
    },
  },
  {
    Date: "2022-05-30",
    Events: {
      NotStartedCount: 1,
      StartedCount: 3,
      CompletedCount: 6,
      CumulativeNotStartedCount: 141,
      CumulativeStartedCount: 166,
      CumulativeCompletedCount: 230,
    },
  },
  {
    Date: "2022-05-31",
    Events: {
      NotStartedCount: 0,
      StartedCount: 0,
      CompletedCount: 0,
      CumulativeNotStartedCount: 141,
      CumulativeStartedCount: 166,
      CumulativeCompletedCount: 230,
    },
  },
  {
    Date: "2022-06-01",
    Events: {
      NotStartedCount: 3,
      StartedCount: 6,
      CompletedCount: 9,
      CumulativeNotStartedCount: 144,
      CumulativeStartedCount: 172,
      CumulativeCompletedCount: 239,
    },
  },
  {
    Date: "2022-06-02",
    Events: {
      NotStartedCount: 7,
      StartedCount: 9,
      CompletedCount: 20,
      CumulativeNotStartedCount: 151,
      CumulativeStartedCount: 181,
      CumulativeCompletedCount: 259,
    },
  },
];

export const VideoEngagementPeopleDataMock: EngagementPeopleData = [
  {
    UserId: 1,
    UserFirstName: "John",
    UserLastName: "Smith",
    UserDepartment: "IT Dept, Irradiated Fuel Handler",
    UserEmail: "jsmith@gmail.com",
    DateWatched: "2022-02-12 00:00:00.000",
    Status: "Complete",
    VideoCompletions: 1,
    VideoStarts: 2,
    id: "10",
  },
  {
    UserId: 2,
    UserFirstName: "John",
    UserLastName: "Smith2",
    UserDepartment: "IT Dept, Irradiated Fuel Handler2",
    UserEmail: "jsmith@gmail.com2",
    DateWatched: "2022-02-13 00:00:00.000",
    Status: "In Progress",
    id: "21",
  },
  {
    UserId: 3,
    UserFirstName: "John",
    UserLastName: "Smith3",
    UserDepartment: "IT Dept, Irradiated Fuel Handler3",
    UserEmail: "jsmith@gmail.com3",
    DateWatched: "2022-02-14 00:00:00.000",
    Status: "Not Started",
    VideoCompletions: 3,
    VideoStarts: 4,
    id: "32",
  },
  {
    UserId: 4,
    UserFirstName: "John",
    UserLastName: "Smith4",
    UserDepartment: "IT Dept, Irradiated Fuel Handler4",
    UserEmail: "jsmith@gmail.com4",
    DateWatched: "2022-02-15 00:00:00.000",
    Status: "Complete",
    VideoCompletions: 4,
    VideoStarts: 5,
    id: "43",
  },
  {
    UserId: 5,
    UserFirstName: "John",
    UserLastName: "Smith5",
    UserDepartment: "IT Dept, Irradiated Fuel Handler5",
    UserEmail: "jsmith@gmail.com5",
    DateWatched: "2022-02-16 00:00:00.000",
    Status: "In Progress",
    VideoCompletions: 5,
    VideoStarts: 6,
    id: "54",
  },
  {
    UserId: 6,
    UserFirstName: "John",
    UserLastName: "Smith6",
    UserDepartment: "IT Dept, Irradiated Fuel Handler6",
    UserEmail: "jsmith@gmail.com6",
    DateWatched: "2022-02-17 00:00:00.000",
    Status: "Not Started",
    VideoCompletions: 6,
    VideoStarts: 7,
    id: "65",
  },
  {
    UserId: 7,
    UserFirstName: "John",
    UserLastName: "Smith7",
    UserDepartment: "IT Dept, Irradiated Fuel Handler7",
    UserEmail: "jsmith@gmail.com7",
    DateWatched: "2022-02-18 00:00:00.000",
    Status: "Complete",
    VideoCompletions: 7,
    VideoStarts: 8,
    id: "76",
  },
  {
    UserId: 8,
    UserFirstName: "John",
    UserLastName: "Smith8",
    UserDepartment: "IT Dept, Irradiated Fuel Handler8",
    UserEmail: "jsmith@gmail.com8",
    DateWatched: "2022-02-19 00:00:00.000",
    Status: "In Progress",
    VideoCompletions: 8,
    VideoStarts: 9,
    id: "87",
  },
  {
    UserId: 9,
    UserFirstName: "John",
    UserLastName: "Smith9",
    UserDepartment: "IT Dept, Irradiated Fuel Handler9",
    UserEmail: "jsmith@gmail.com9",
    DateWatched: "2022-02-20 00:00:00.000",
    Status: "Not Started",
    VideoCompletions: 9,
    VideoStarts: 10,
    id: "98",
  },
  {
    UserId: 10,
    UserFirstName: "John",
    UserLastName: "Smith10",
    UserDepartment: "IT Dept, Irradiated Fuel Handler10",
    UserEmail: "jsmith@gmail.com10",
    DateWatched: "2022-02-21 00:00:00.000",
    Status: "Complete",
    VideoCompletions: 10,
    VideoStarts: 11,
    id: "109",
  },
  {
    UserId: 11,
    UserFirstName: "John",
    UserLastName: "Smith11",
    UserDepartment: "IT Dept, Irradiated Fuel Handler11",
    UserEmail: "jsmith@gmail.com11",
    DateWatched: "2022-02-22 00:00:00.000",
    Status: "In Progress",
    VideoCompletions: 11,
    VideoStarts: 12,
    id: "1110",
  },
  {
    UserId: 12,
    UserFirstName: "John",
    UserLastName: "Smith12",
    UserDepartment: "IT Dept, Irradiated Fuel Handler12",
    UserEmail: "jsmith@gmail.com12",
    DateWatched: "2022-02-23 00:00:00.000",
    Status: "Not Started",
    VideoCompletions: 12,
    VideoStarts: 23,
    id: "1211",
  },
];

export const mockVideoEngagementData: VideoEngagementData = {
  CompletedCount: 56,
  CompletionRate: 0.595744,
  Customers: 385,
  StartedCount: 94,
  VideoDuration: 25.588235,
  Videos: 34,
};

export const mockEmptyVideoEngagementData: VideoEngagementData = {
  CompletedCount: 0,
  CompletionRate: 0,
  Customers: 0,
  StartedCount: 0,
  VideoDuration: 0,
  Videos: 0,
};
