export const FETCH_ALL_VENDORS_BEGIN = "FETCH_ALL_VENDORS_BEGIN";
export const FETCH_ALL_VENDORS_SUCCESS = "FETCH_ALL_VENDORS_SUCCESS";
export const FETCH_ALL_VENDORS_FAILURE = "FETCH_ALL_VENDORS_FAILURE";

export const FETCH_ALL_VENDOR_COLLECTIONS_BEGIN = "FETCH_ALL_VENDOR_COLLECTIONS_BEGIN";
export const FETCH_ALL_VENDOR_COLLECTIONS_SUCCESS = "FETCH_ALL_VENDOR_COLLECTIONS_SUCCESS";
export const FETCH_ALL_VENDOR_COLLECTIONS_FAILURE = "FETCH_ALL_VENDOR_COLLECTIONS_FAILURE";

export const FETCH_LAZY_VENDOR_ASSETS_BEGIN = "FETCH_LAZY_VENDOR_ASSETS_BEGIN";
export const FETCH_LAZY_VENDOR_ASSETS_SUCCESS = "FETCH_LAZY_VENDOR_ASSETS_SUCCESS";
export const FETCH_LAZY_VENDOR_ASSETS_FAILURE = "FETCH_LAZY_VENDOR_ASSETS_FAILURE";

export const RESET_VENDOR_ASSETS_LIST = "RESET_VENDOR_ASSETS_LIST";
