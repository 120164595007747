import { getNotifyTypesWithProgress } from "./helpers";
import { INotifiable, NotifySettings, ShouldNotifyWithProgress } from "./types";
import { CommunicationChannels } from "../../enums";

export const initialNotifyConfigWithProgress: INotifiable<ShouldNotifyWithProgress> = {
  shouldNotifyNotStarted: false,
  shouldNotifyInProgress: false,
  shouldNotifyCompleted: false,
  getShouldNotify: (config) =>
    config.shouldNotifyNotStarted || config.shouldNotifyInProgress || config.shouldNotifyCompleted,
  communicationChannel: CommunicationChannels.Email,
  getCommunicationChannel: (config) => config.communicationChannel,
  notifyTypes: getNotifyTypesWithProgress,
};

export const initialNotifyConfigDefault: INotifiable<NotifySettings> = {
  shouldNotifyUsers: true,
  communicationChannel: CommunicationChannels.Email,
  getCommunicationChannel: (config) => config.communicationChannel,
  getShouldNotify: (config) => config.shouldNotifyUsers,
};
