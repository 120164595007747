import * as updateVideoActionTypes from "../actionTypes/updateVideoActionTypes";
import * as videoEntityStateActionTypes from "../actionTypes/videoEntityStateActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import moment from "moment";
import alertTypes from "../../../../../enums/alertTypes";

const initialState = {
  error: null,
  id: -1,
  thumbnailUrl: "",
  dateModified: null,
  canBePublished: false,
  amsJobsStatus: {
    isEncodingFailed: false,
    isThumbnailGenerationFailed: false,
  },
  isManuallyUploadedThumbnail: false,
  videoAssetStreamingManifests: [],
  isThumbnailGenerationProcessing: false,
};

const filterRtnMessageByAssetId = (payload, state, stateProducer) => {
  return payload.videoAssetId && payload.videoAssetId !== state.id ? { ...state } : stateProducer();
};

const utcNow = () => moment.utc().toDate();

const getVideoInfoHandlers = () => {
  const { GET_VIDEO_INFO_BEGIN, GET_VIDEO_INFO_SUCCESS, GET_VIDEO_INFO_FAIL, RESET_VIDEO_INFO } =
    updateVideoActionTypes;

  const getVideoInfoBegin = () => {
    return { ...initialState };
  };

  const getVideoInfoSuccess = (state, action) => {
    const videoInfo = action.payload;

    return {
      ...state,
      id: videoInfo.id,
      thumbnailUrl: videoInfo.thumbnailUrl,
      dateModified: videoInfo.dateModified,
      canBePublished: videoInfo.canBePublished,
      amsJobsStatus: {
        isEncodingFailed: videoInfo.isEncodingFailed,
        isThumbnailGenerationFailed: videoInfo.isThumbnailGenerationFailed,
      },
    };
  };

  const getVideoInfoFail = (state, action) => {
    return {
      ...state,
      error: action.payload.error,
    };
  };

  const reset = () => {
    return { ...initialState };
  };

  return {
    [GET_VIDEO_INFO_BEGIN]: getVideoInfoBegin,
    [GET_VIDEO_INFO_SUCCESS]: getVideoInfoSuccess,
    [GET_VIDEO_INFO_FAIL]: getVideoInfoFail,
    [RESET_VIDEO_INFO]: reset,
  };
};

const getThumbnailHandlers = () => {
  const { CREATE_THUMBNAIL_SUCCESS, SET_THUMBNAIL_FROM_POSITION } = updateVideoActionTypes;

  const setThumbnail = (state, action, isManuallyUploadedThumbnail) => {
    return {
      ...state,
      thumbnailUrl: action.payload.thumbnailUrl,
      canBePublished: isManuallyUploadedThumbnail ? state.canBePublished : false,
      amsJobsStatus: {
        ...state.amsJobsStatus,
        isThumbnailGenerationFailed: false,
      },
      isManuallyUploadedThumbnail: isManuallyUploadedThumbnail || false,
    };
  };

  const generatedThumbanilFromPosition = (state, action) => {
    const payload = action.payload;
    return filterRtnMessageByAssetId(payload, state, () => {
      return {
        ...state,
        thumbnailUrl: payload.thumbnailUrl,
        canBePublished: payload.canBePublished,
        isThumbnailGenerationProcessing: false,
      };
    });
  };

  return {
    [CREATE_THUMBNAIL_SUCCESS]: generatedThumbanilFromPosition,
    [SET_THUMBNAIL_FROM_POSITION]: (state, action) => setThumbnail(state, action, false),
  };
};

const getStreamingHandlers = () => {
  const { GET_STREAMING_INFO_BEGIN, GET_STREAMING_INFO_SUCCESSED, GET_STREAMING_INFO_FAILED } = updateVideoActionTypes;

  const getStreamingInfoBegin = (state) => {
    return {
      ...state,
      videoAssetStreamingManifests: [],
      error: null,
    };
  };

  const getStreamingInfoSuccessed = (state, action) => {
    return {
      ...state,
      videoAssetStreamingManifests: action.payload.streamingManifests?.map((item) => ({
        type: item.type,
        url: item.uri,
      })),
    };
  };

  const getStreamingInfoFailed = (state, action) => {
    return {
      ...state,
      videoAssetStreamingManifests: [],
      error: action.payload.error,
    };
  };

  return {
    [GET_STREAMING_INFO_BEGIN]: getStreamingInfoBegin,
    [GET_STREAMING_INFO_SUCCESSED]: getStreamingInfoSuccessed,
    [GET_STREAMING_INFO_FAILED]: getStreamingInfoFailed,
  };
};

const alertsHandler = () => {
  const { MEDIA_ENCODING_CREATED_ALERT, MEDIA_THUMBNAIL_CREATED_ALERT } = updateVideoActionTypes;

  const mediaEncodingCreatedAlert = (state, action) => {
    const payload = action.payload;
    return filterRtnMessageByAssetId(payload, state, () => {
      return {
        ...state,
        amsJobsStatus: {
          ...state.amsJobsStatus,
          isEncodingFailed: payload.type === alertTypes.error,
        },
      };
    });
  };

  const mediaThumbnailCreatedAlert = (state, action) => {
    const payload = action.payload;
    return filterRtnMessageByAssetId(payload, state, () => {
      return {
        ...state,
        amsJobsStatus: {
          ...state.amsJobsStatus,
          isThumbnailGenerationFailed: payload.type === alertTypes.error,
        },
      };
    });
  };

  return {
    [MEDIA_ENCODING_CREATED_ALERT]: mediaEncodingCreatedAlert,
    [MEDIA_THUMBNAIL_CREATED_ALERT]: mediaThumbnailCreatedAlert,
  };
};

const updateAssetHandler = () => {
  const { GENERATE_VIDEO_SUCCESS, CHANGE_IMAGE_PROCESSING_STATE, UPLOAD_VIDEO_SUCCESS } = updateVideoActionTypes;

  const { CREATE_VIDEO_DRAFT_SUCCESS, DRAFT_VIDEO_INFO_UPDATED } = videoEntityStateActionTypes;

  const generateAssetSuccess = (state, action) => {
    const payload = action.payload;
    return filterRtnMessageByAssetId(payload, state, () => {
      return {
        ...state,
        canBePublished: payload.canBePublished,
        videoAssetStreamingManifests: payload.streamingManifests.map((item) => ({
          type: item.type,
          url: item.uri,
        })),
      };
    });
  };

  const createAssetDraftSuccess = (state, action) => {
    return {
      ...state,
      id: action.payload.entityId,
      isThumbnailGenerationProcessing: true,
      dateModified: utcNow(),
    };
  };

  const changeImageProcessingState = (state, action) => {
    return {
      ...state,
      isThumbnailGenerationProcessing: action.payload.isProcessing,
    };
  };

  const assetWasUpdated = (state, action) => {
    return filterRtnMessageByAssetId(action.payload, state, () => {
      return {
        ...state,
        dateModified: utcNow(),
      };
    });
  };

  const uploadVideoSuccess = (state) => {
    return {
      ...state,
      canBePublished: false,
      videoAssetStreamingManifests: [],
      amsJobsStatus: {
        ...state.amsJobsStatus,
        isEncodingFailed: false,
      },
    };
  };

  return {
    [CREATE_VIDEO_DRAFT_SUCCESS]: createAssetDraftSuccess,
    [GENERATE_VIDEO_SUCCESS]: generateAssetSuccess,
    [CHANGE_IMAGE_PROCESSING_STATE]: changeImageProcessingState,
    [DRAFT_VIDEO_INFO_UPDATED]: assetWasUpdated,
    [UPLOAD_VIDEO_SUCCESS]: uploadVideoSuccess,
    ...getVideoInfoHandlers(),
    ...getThumbnailHandlers(),
    ...getStreamingHandlers(),
    ...alertsHandler(),
  };
};

export const updateVideoReducer = createReducer(initialState, [updateAssetHandler]);
