import { IntegrationMenu } from "../../../Accounts/Integrations/types";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../Application/slices/createFetchingItemsSlice";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { RootState } from "features/Application/globaltypes/redux";
import { createSelector } from "@reduxjs/toolkit";

export type IntegrationOverviewState = FetchingItemsState<IntegrationMenu>;

const initialState: IntegrationOverviewState = {
  items: [],
  isLoading: false,
  itemsCount: 0,
  error: undefined,
};

const integrationsMenuSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Reporting,
    entity: ReducerEntityPrefixTypes.Integrations,
    name: "menu",
  },
  reducers: {},
  initialState,
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = integrationsMenuSlice.actions;

export const selectMenuitemsState = (state: RootState) => state.reporting.menu.integrationMenuReducer.items;
export const selectMenuItemsLoading = (state: RootState) => state.reporting.menu.integrationMenuReducer.areAllLoaded;
export const selectMenuItemsData = createSelector(selectMenuitemsState, (menuItem) => {
  const returnObj: Record<string, boolean> = {};
  for (const element of menuItem) {
    returnObj[element.type] = element.isActive;
  }
  return returnObj;
});

export default integrationsMenuSlice.reducer;
export type integrationsMenuSliceType = ReturnType<typeof integrationsMenuSlice.reducer>;
