import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { RightButton, LeftButton } from "../../buttons/doubleButtons";
import ViewType from "../../../enums/ViewType";

import "./viewTypeButtons.scss";

function ViewTypeButtons(props) {
  const { disableListViewButton, onListView, onGridView, viewType } = props;

  let listViewActive = !onListView || viewType === ViewType.LIST;
  let gridViewActive = !onGridView || viewType === ViewType.GRID;

  return (
    <>
      <LeftButton
        className={cn("view-type-button", { active: listViewActive })}
        iconName="list ul"
        onClick={onListView}
        disabled={disableListViewButton || listViewActive}
        aria-label="switch to list view"
      />
      <RightButton
        className={cn("view-type-button", { active: gridViewActive })}
        iconName="th large"
        onClick={onGridView}
        disabled={disableListViewButton || gridViewActive}
        aria-label="switch to grid view"
      />
    </>
  );
}

ViewTypeButtons.propTypes = {
  viewType: PropTypes.string,
  onListView: PropTypes.func,
  onGridView: PropTypes.func,
  disableListViewButton: PropTypes.bool,
};

export default ViewTypeButtons;
