import React from "react";
import { Card } from "semantic-ui-react";
import cn from "classnames";

import CardThumbnail from "../cardThumbnail/CardThumbnail";
import CardContent from "../cardContent/CardContent";

import "./ItemToDropCard.scss";

export interface Props {
  id: number;
  title: string;
  thumbnailUrl?: string;
  className?: string;
  dataHookType?: string;
  itemType?: string;
  metaString?: string;
  onClick?: () => void;
  isSelected?: boolean;
}

const ItemToDropCard: React.FunctionComponent<Props> = (props) => {
  const { id, title, thumbnailUrl, className, dataHookType, itemType, metaString, onClick, isSelected } = props;

  return (
    <Card
      key={id}
      onClick={() => onClick?.()}
      className={cn("draggable-item-card", className, { selected: isSelected })}
      tabIndex="0"
      data-hook-type={dataHookType}
      data-hook-id={id}
      as="div"
    >
      <CardThumbnail thumbnailUrl={thumbnailUrl} draggable={false}>
        <>
          {itemType ? itemType : null}
          <span className="meta-2">{metaString ? metaString : null}</span>
        </>
      </CardThumbnail>
      <CardContent title={title} />
    </Card>
  );
};

export default ItemToDropCard;
