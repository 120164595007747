import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { RootState } from "features/Application/globaltypes/redux";

const namespace = ReducerNamespaceTypes.Reporting;
const entityPrefix = ReducerEntityPrefixTypes.Reporting_Toolbar;

type ReportingState = {
  enabled: boolean;
  navigation: boolean;
};

const initialState: ReportingState = {
  enabled: false,
  navigation: true,
};

const toolbarSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    setIsReportEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setIsNavigationEnabled(state, action: PayloadAction<boolean>) {
      state.navigation = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { setIsReportEnabled, reset, setIsNavigationEnabled } = toolbarSlice.actions;

export const selectIsReportEnabled = (state: RootState) => {
  return state.reporting.toolbar.enabled;
};

export const selectIsNavigationEnabled = (state: RootState) => {
  return state.reporting.toolbar.navigation;
};

export default toolbarSlice.reducer;
