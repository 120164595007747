import { AppDispatch } from "features/Application/globaltypes/redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import PacksContextInfo from "features/Licensing/ContentAssignmentModalLicensingSteps/PackContextModalContent/packsContextInfo/PacksContextInfo";
import { PacksContextItem } from "../../../../interfaces";
import { ModalWithStepsStep } from "../../../modal/ModalWithSteps";
import { fetchContentPacksAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";

import "./packsContextStep.scss";

export interface PacksContextStepProps extends ModalWithStepsStep {
  info: Array<PacksContextItem>;
  onIsDataValidChange: (isValid: boolean) => void;
  noDataLoaded: () => void;
  setModalStepHeader: () => void;
}

export type RenderModalActionsParams = {
  isDataValid: boolean;
  onIsDataValidChange: (isDataValid: boolean) => void;
};

export type PacksContextStepAllProps = PacksContextStepProps & PropsFromRedux;

function PacksContextStep(props: PacksContextStepAllProps) {
  return (
    <div className="packs-context-step">
      <PacksContextInfo {...props} />
    </div>
  );
}

export const getRenderModalActionsPacksContextStepFunction = (params: RenderModalActionsParams) => {
  return (nextStep: () => void, prevStep: () => void) => {
    return () => {
      const { isDataValid, onIsDataValidChange } = params;
      return (
        <>
          <Button
            primary
            className="previous"
            content="Previous"
            onClick={() => {
              onIsDataValidChange(true);
              prevStep();
            }}
          />
          <Button
            primary
            className="next"
            content="Next"
            onClick={() => {
              onIsDataValidChange(false);
              nextStep();
            }}
            disabled={!isDataValid}
          />
        </>
      );
    };
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchContentPacks: bindActionCreators(fetchContentPacksAction, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PacksContextStep);
