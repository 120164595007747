import React from "react";

import VideosAssignmentList from "./videosAssignmentList/VideosAssignmentList";
import FlowsAssignmentList from "./flowsAssignmentList/FlowsAssignmentList";
import EventsAssignmentList from "./eventsAssignmentList/EventsAssignmentList";
import PdfAssignmentList from "./pdfsAssignmentList/PdfsAssignmentList";
import { PriorityLevel } from "../../interfaces";
import { Filters } from "../../utils/queryUtils";
import { PriorityItem } from "../../features/People/types";
import { AssessmentList, SurveyList } from "./assignmentList/assignmentListComponents";
import { detectHasAnyPermissions } from "../assignmentModals/contentAssignmentModal/helpers/hasPermissions";
import { AddPeopleToContentTypes, ContentTypesEnum, RolePermissions, UsersGroupsContext, ViewType } from "../../enums";
import { ContentType, PeopleType } from "../../features/Library/PeopleAssignments/types";
import { IContentAssignmentActions, SelectedItem } from "./types";
import { ContentSegmentationDropdown } from "../dropdowns";
import { UserAssignmentModalPriorityLicensing } from "../assignmentModals";

import "./contentAssignment.scss";

export interface SelectedFlow {
  canAutoStart: boolean;
}

export interface SelectedItemsState {
  selectedVideos: number[];
  selectedSurveys: number[];
  selectedAssessments: number[];
  selectedEvents: number[];
  selectedPdfs: number[];
}

export interface ContentAssignmentProps extends SelectedItemsState {
  contentAssignmentActions: IContentAssignmentActions;
  onSelectedFlowsChanged: (selectedItems: SelectedItem[]) => void;
  onSelectedVideosChanged: (selectedItems: SelectedItem[]) => void;
  onSelectedEventsChanged: (selectedItems: SelectedItem[]) => void;
  onSelectedSurveysChanged: (selectedItems: SelectedItem[]) => void;
  onSelectedAssessmentsChanged: (selectedItems: SelectedItem[]) => void;
  onSelectedPdfsChanged: (selectedItems: SelectedItem[]) => void;
  onPriorityItemsUpdate?: (priorityLevel: PriorityLevel[]) => void;
  surveyButton?: React.ReactElement;
  flowButton?: React.ReactElement;
  videoButton?: React.ReactElement;
  selectedFlows: number[];
  eventButton?: React.ReactElement;
  assessmentButton?: React.ReactElement;
  pdfButton?: React.ReactElement;
  flowContextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (ids: number[]) => void;
  };
  videoContextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  eventContextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  surveyContextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  assessmentContextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  pdfContextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  confirmation: {
    priorityItems: PriorityItem[];
    show: boolean;
  };
  onLicenseConfirmationClosed?: () => void;
  onLicenseConfirmed?: () => void;
  isReadOnly?: boolean;
  userPermissions?: RolePermissions[];
  selectedPeopleIds?: number[];
  usersGroupsContext?: UsersGroupsContext;
  peopleType: PeopleType;
  hidePriorityColumn?: boolean;
  showExpirationDate?: boolean;
  setSelectedContentType?: (payload: AddPeopleToContentTypes) => void;
  onViewTypeChange?: (payload: ViewType) => void;
  deepLink?: boolean;
}

export interface ContentAssignmentState {
  currentContext: AddPeopleToContentTypes;
  appliedFlowsFilter: Filters;
  appliedEventsFilter: Filters;
  appliedVideosFilter: Filters;
  appliedPdfsFilter: Filters;
}

export default class ContentAssignment extends React.Component<ContentAssignmentProps, ContentAssignmentState> {
  contentList: { [name: string]: (accessRestricted?: boolean) => React.ReactElement };

  constructor(props: ContentAssignmentProps) {
    super(props);

    this.state = {
      currentContext: AddPeopleToContentTypes.Flows,
      appliedVideosFilter: {},
      appliedFlowsFilter: {},
      appliedEventsFilter: {},
      appliedPdfsFilter: {},
    };

    this.contentList = {
      Flows: this.renderFlowsAssignmentList,
      Videos: this.renderVideosAssignmentList,
      Events: this.renderEventsAssignmentList,
      Surveys: this.renderSurveysAssignmentList,
      Assessments: this.renderAssessmentsAssignmentList,
      PDFs: this.renderPdfsAssignmentList,
    };
  }

  componentDidMount() {
    this.props.setSelectedContentType?.(this.state.currentContext);
  }

  onContentSegmentationChange = (context: string) => {
    if (!Object.keys(this.contentList).includes(context)) {
      return;
    }

    this.props.setSelectedContentType?.(context as AddPeopleToContentTypes);
    this.setState({ currentContext: context as AddPeopleToContentTypes });
  };

  renderContextSwitcher = (): React.ReactElement => (
    <ContentSegmentationDropdown
      onContextChange={this.onContentSegmentationChange}
      defaultValue={ContentTypesEnum[this.state.currentContext]}
    />
  );

  renderSurveysAssignmentList = (accessRestricted?: boolean): React.ReactElement => {
    const {
      isReadOnly,
      surveyButton,
      contentAssignmentActions,
      onSelectedSurveysChanged,
      selectedSurveys,
      surveyContextMenuButtonHandlers,
      peopleType,
      hidePriorityColumn,
      showExpirationDate,
      userPermissions,
      deepLink,
    } = this.props;

    return (
      <SurveyList
        hidePriorityColumn={hidePriorityColumn}
        createButton={surveyButton}
        isReadOnly={isReadOnly}
        onSelectedChanged={onSelectedSurveysChanged}
        selectedIds={selectedSurveys}
        accessRestricted={accessRestricted}
        customHeaderContent={this.renderContextSwitcher()}
        fetchSurveys={contentAssignmentActions.fetchSurveysList}
        resetList={contentAssignmentActions.resetSurveysList}
        contextMenuButtonHandlers={surveyContextMenuButtonHandlers}
        peopleType={peopleType}
        showExpirationDate={showExpirationDate}
        disablePopupMenu={!userPermissions?.includes(RolePermissions.AssetsView)}
        deepLink={deepLink}
      />
    );
  };

  renderAssessmentsAssignmentList = (accessRestricted?: boolean): React.ReactElement => {
    const {
      isReadOnly,
      assessmentButton,
      contentAssignmentActions,
      onSelectedAssessmentsChanged,
      selectedAssessments,
      assessmentContextMenuButtonHandlers,
      peopleType,
      hidePriorityColumn,
      showExpirationDate,
      userPermissions,
      deepLink,
    } = this.props;

    return (
      <AssessmentList
        hidePriorityColumn={hidePriorityColumn}
        createButton={assessmentButton}
        isReadOnly={isReadOnly}
        onSelectedChanged={onSelectedAssessmentsChanged}
        selectedIds={selectedAssessments}
        accessRestricted={accessRestricted}
        customHeaderContent={this.renderContextSwitcher()}
        fetchAssessments={contentAssignmentActions.fetchAssessmentsList}
        resetList={contentAssignmentActions.resetAssessmentsList}
        contextMenuButtonHandlers={assessmentContextMenuButtonHandlers}
        peopleType={peopleType}
        showExpirationDate={showExpirationDate}
        disablePopupMenu={!userPermissions?.includes(RolePermissions.AssetsView)}
        deepLink={deepLink}
      />
    );
  };

  renderFlowsAssignmentList = (accessRestricted?: boolean): React.ReactElement => {
    const {
      contentAssignmentActions,
      selectedFlows,
      flowButton,
      flowContextMenuButtonHandlers,
      onSelectedFlowsChanged,
      isReadOnly,
      peopleType,
      hidePriorityColumn,
      showExpirationDate,
      onViewTypeChange,
      userPermissions,
      deepLink,
    } = this.props;

    return (
      <FlowsAssignmentList
        hidePriorityColumn={hidePriorityColumn}
        createButton={flowButton}
        fetchFlowsList={contentAssignmentActions.fetchFlowsList}
        fetchFlowsGrid={contentAssignmentActions.fetchFlowsGrid}
        resetFlowsGrid={contentAssignmentActions.resetFlowsGrid}
        resetFlowsList={contentAssignmentActions.resetFlowsList}
        contextMenuButtonHandlers={flowContextMenuButtonHandlers}
        selectedFlows={selectedFlows}
        onSelectedFlowsChanged={onSelectedFlowsChanged}
        customHeaderContent={this.renderContextSwitcher()}
        isReadOnly={isReadOnly}
        accessRestricted={accessRestricted}
        peopleType={peopleType}
        showExpirationDate={showExpirationDate}
        onViewTypeChange={onViewTypeChange}
        disablePopupMenu={!userPermissions?.includes(RolePermissions.FlowsView)}
        deepLink={deepLink}
      />
    );
  };

  renderVideosAssignmentList = (accessRestricted?: boolean): React.ReactElement => {
    const {
      selectedVideos,
      onSelectedVideosChanged,
      contentAssignmentActions,
      isReadOnly,
      videoButton,
      videoContextMenuButtonHandlers,
      hidePriorityColumn,
      showExpirationDate,
      peopleType,
      onViewTypeChange,
      userPermissions,
      deepLink,
    } = this.props;

    return (
      <VideosAssignmentList
        hidePriorityColumn={hidePriorityColumn}
        fetchVideosList={contentAssignmentActions.fetchVideosList}
        fetchVideosGrid={contentAssignmentActions.fetchVideosGrid}
        resetVideosGrid={contentAssignmentActions.resetVideoGrid}
        resetVideosList={contentAssignmentActions.resetVideoList}
        contextMenuButtonHandlers={videoContextMenuButtonHandlers}
        selectedVideos={selectedVideos}
        onSelectedVideosChanged={onSelectedVideosChanged}
        customHeaderContent={this.renderContextSwitcher()}
        isReadOnly={isReadOnly}
        createButton={videoButton}
        accessRestricted={accessRestricted}
        showExpirationDate={showExpirationDate}
        peopleType={peopleType}
        onViewTypeChange={onViewTypeChange}
        disablePopupMenu={!userPermissions?.includes(RolePermissions.AssetsView)}
        deepLink={deepLink}
      />
    );
  };

  renderEventsAssignmentList = (accessRestricted?: boolean): React.ReactElement => {
    const {
      selectedEvents,
      onSelectedEventsChanged,
      contentAssignmentActions,
      isReadOnly,
      eventButton,
      eventContextMenuButtonHandlers,
      hidePriorityColumn,
      showExpirationDate,
      peopleType,
      onViewTypeChange,
      userPermissions,
      deepLink,
    } = this.props;
    const { appliedEventsFilter } = this.state;
    return (
      <EventsAssignmentList
        hidePriorityColumn={hidePriorityColumn}
        fetchEventsList={contentAssignmentActions.fetchEventsList}
        fetchEventsGrid={contentAssignmentActions.fetchEventsGrid}
        resetEventsGrid={contentAssignmentActions.resetEventsGrid}
        resetEventsList={contentAssignmentActions.resetEventsList}
        contextMenuButtonHandlers={eventContextMenuButtonHandlers}
        onSelectedEventsChanged={onSelectedEventsChanged}
        customHeaderContent={this.renderContextSwitcher()}
        isReadOnly={!!isReadOnly}
        createButton={eventButton}
        selectedEventsIds={selectedEvents}
        appliedFilter={appliedEventsFilter}
        resetFilter={() => this.setState({ appliedEventsFilter: {} })}
        applyFilter={(filter) => this.setState({ appliedEventsFilter: filter })}
        showExpirationDate={showExpirationDate}
        peopleType={peopleType}
        onViewTypeChange={onViewTypeChange}
        disablePopupMenu={!userPermissions?.includes(RolePermissions.EventsView)}
        accessRestricted={accessRestricted}
        deepLink={deepLink}
      />
    );
  };

  renderPdfsAssignmentList = (accessRestricted?: boolean): React.ReactElement => {
    const {
      selectedPdfs,
      onSelectedPdfsChanged,
      contentAssignmentActions,
      isReadOnly,
      pdfButton,
      pdfContextMenuButtonHandlers,
      hidePriorityColumn,
      showExpirationDate,
      peopleType,
      deepLink,
    } = this.props;
    return (
      <PdfAssignmentList
        hidePriorityColumn={hidePriorityColumn}
        fetchPdfsList={contentAssignmentActions.fetchPdfsList}
        fetchPdfsGrid={contentAssignmentActions.fetchPdfsGrid}
        resetPdfsGrid={contentAssignmentActions.resetPdfsGrid}
        resetPdfsList={contentAssignmentActions.resetPdfsList}
        contextMenuButtonHandlers={pdfContextMenuButtonHandlers}
        onSelectedPdfsChanged={onSelectedPdfsChanged}
        customHeaderContent={this.renderContextSwitcher()}
        isReadOnly={!!isReadOnly}
        createButton={pdfButton}
        selectedPdfIds={selectedPdfs}
        showExpirationDate={showExpirationDate}
        peopleType={peopleType}
        accessRestricted={accessRestricted}
        deepLink={deepLink}
      />
    );
  };

  onPriorityItemsUpdate = (priorityLevel: PriorityLevel[]) => {
    this.props.onPriorityItemsUpdate?.(priorityLevel);
  };

  render(): React.ReactElement {
    const { onLicenseConfirmed, confirmation, onLicenseConfirmationClosed, userPermissions } = this.props;
    const accessRestricted = userPermissions && !detectHasAnyPermissions(userPermissions, this.state.currentContext);

    const contentListItemComponent = this.contentList[this.state.currentContext](accessRestricted);

    const packContextItems = confirmation.priorityItems.map((i) => {
      return { ...i, id: i.id.toString(), type: i.blockType === "Asset" ? ContentType.Video : i.blockType ?? "" };
    });

    return (
      <section className="content-assignment">
        {contentListItemComponent}
        <UserAssignmentModalPriorityLicensing
          onConfirm={onLicenseConfirmed}
          onCancel={onLicenseConfirmationClosed}
          showModal={confirmation.show}
          onItemsUpdate={this.onPriorityItemsUpdate}
          isStepLoading={false}
          onSecondStep={() => Promise.resolve()}
          priorityItems={confirmation.priorityItems}
          packContextItems={packContextItems}
          selectedPeopleIds={this.props.selectedPeopleIds}
          usersGroupsContext={this.props.usersGroupsContext}
        />
      </section>
    );
  }
}
