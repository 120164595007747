import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import cn from "classnames";
import { max } from "d3";
import { useEffect, useRef, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Icon } from "semantic-ui-react";

import { RootState } from "../../../../Application/globaltypes/redux";
import dataservice from "../../services/surveysDataService";
import {
  resetSelectedAnswer,
  selectSurveySelectedAnswerData,
  selectSurveySelectedAnswerValue,
  selectSurveySelectedQuestionData,
  selectSurveySelectedQuestionValue,
} from "../../state/slices/surveyPerformanceSlice";
import {
  fetchOtherOptionInformation,
  fetchSelectedAnswerData,
  fetchSelectedQuestionData,
} from "../../state/thunks/surveyPerformanceThunk";
import { SurveyQuestion, SurveyQuestionAnswer } from "../../types/state";

import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import CardReporting from "../../../../../components/cards/CardReporting/CardReporting";
import { HorizontalBarWrapper, TickValueType } from "../../../../../components/charts";
import { RequestStatusRenderer } from "../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { OtherAnswerOptions } from "../../SurveyDetails/ContentForm/QuestionForm/types";
import { SurveyPerformanceDrilldownList } from "../DrilldownList/SurveyPerformanceDrilldownList";

import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { PerformanceFilter, horizontalBarChartMargins, roundToTwoDigits, performanceGray } from "features/Library/Common/utils/performanceUtils";
import "./SurveyPerformanceQuestionDrillDown.scss";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { RStatus } from "features/Application/globaltypes/fetchRequest";

const lookupAnswerInQuestion = (question: SurveyQuestion, answerId: string) => {
  return question.Answers.find((a) => a.AnswerId === answerId);
};

export interface Props extends PropsFromRedux {
  surveyId: number;
  flowId?: number;
  question: SurveyQuestion;
  answer: SurveyQuestionAnswer | undefined;
  otherOptionInformation: OtherAnswerOptions | undefined;
  hideHeader?: boolean;
  filter: PerformanceFilter;
  includeAccounts?: boolean;
  onSelectAnswer: (answer: SurveyQuestionAnswer | undefined) => void;
  onNextQuestion: () => void;
  onPrevQuestion: () => void;
}

export interface FormattedAnswers {
  id: string;
  category: string;
  value: number;
  fillColor?: string;
  iconClass?: string;
}

export const SurveyPerformanceQuestionDrillDown: React.FC<Props> = ({
  fetchQuestionData,
  fetchAnswerData,
  fetchOtherOptionInformation,

  accountId,
  surveyId,
  flowId,
  question,
  answer,
  otherOptionInformation,
  filter,
  hideHeader,
  includeAccounts,

  onSelectAnswer,
  onNextQuestion,
  onPrevQuestion,

  selectedQuestionStatus,
  selectedQuestion,
  selectedAnswerStatus,
  selectedAnswer,

  resetAnswerTable,
  setHasExportData,
}) => {
  const [formattedData, setFormattedData] = useState<FormattedAnswers[]>([]);
  const reportEnabled = useFeatureFlag(FeatureFlags.SurveyReport);

  const sendOnMount = useRef(false);

  useEffect(() => {
    setHasExportData(selectedAnswerStatus.status === RStatus.Got && !!selectedAnswer?.Users.length);
  }, [setHasExportData, selectedAnswerStatus, selectedAnswer]);

  useEffect(() => {
    // Possible that we navigated here from the Flow Performance drilldown pathway
    if (otherOptionInformation === undefined && !sendOnMount.current && !includeAccounts) {
      fetchOtherOptionInformation(surveyId);
    }
    sendOnMount.current = true;
  }, [fetchOtherOptionInformation, otherOptionInformation, surveyId, includeAccounts]);

  useEffect(() => {
    if (reportEnabled) {
      fetchQuestionData(surveyId, question.QuestionId, { ...filter, flowId });
    }
  }, [fetchQuestionData, filter, flowId, reportEnabled, question.QuestionId, surveyId, accountId]);

  useEffect(() => {
    if (!reportEnabled) {
      return;
    }
    // Only try to fetch the table if the answer has people to get information on
    if (answer && answer.AnsweredPeople !== 0) {
      fetchAnswerData(surveyId, question.QuestionId, answer.AnswerId, { ...filter, flowId });
    } else {
      dataservice.cancelSurveyAnswerDetails(); // Prevents table from showing old answer request
      resetAnswerTable();
    }
  }, [fetchAnswerData, answer, question.QuestionId, surveyId, resetAnswerTable, filter, flowId, reportEnabled, accountId]);

  useEffect(() => {
    const data = selectedQuestion?.Answers?.map((a) => {
      return {
        id: a.AnswerId,
        category: a.AnswerId === "OTHER" ? "Other" : a.AnswerText,
        value: a.AnsweredPeople,
        fillColor: performanceGray,
      };
    });

    setFormattedData(data);
  }, [selectedQuestion]);

  const selectAnswer = (index: number | null): void => {
    if (index !== null && index !== -1) {
      onSelectAnswer(selectedQuestion.Answers[index]);
    }
  };

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div data-testid="survey-drill-down" className="surveyDrillDownRoot">
      {!hideHeader && (
        <div className="drill-down-navigation ">
          <div className="questionTitle">{question.QuestionText}</div>
          <div className="assessmentActions">
            <button
              title="Previous Question"
              type="button"
              data-testid="previous-question"
              className={cn("invisibleButton", "leftSpacing")}
              onClick={onPrevQuestion}
            >
              <Icon className="chevron left" />
            </button>
            <button
              title="Next Question"
              type="button"
              data-testid="next-question"
              className={cn("invisibleButton", "leftSpacing")}
              onClick={onNextQuestion}
            >
              <Icon className="chevron right" />
            </button>
          </div>
        </div>
      )}

      <div className="summary-fields-container">
        <RequestStatusRenderer state={selectedQuestionStatus}>
          <CardReporting
            items={[
              {
                statDescription: "Completions",
                stat: selectedQuestion.Completions?.toLocaleString() ?? 0,
                popupHeader: "Completions",
                popupBody: "Number of completions",
              },
              {
                statDescription: "Completion Rate",
                stat: selectedQuestion.CompletionRate ? `${roundToTwoDigits(selectedQuestion.CompletionRate * 100)}%` : "0%",
                popupHeader: "Completion Rate",
                popupBody: "Percentage of users that have completed the question.",
                width: "13.75rem",
              },
              {
                statDescription: "Skipped",
                stat: selectedQuestion.Skipped,
                popupHeader: "Skipped",
                popupBody: "Times question has been skipped",
                width: "7.5rem",
              },
            ]}
          />
        </RequestStatusRenderer>
      </div>

      <div className="question-details-container">
        <RequestStatusRenderer state={selectedQuestionStatus}>
          {!!formattedData?.length && (
            <HorizontalBarWrapper
              title="Responses"
              margins={horizontalBarChartMargins}
              data={formattedData}
              selectedValue={answer?.AnswerId}
              onChange={selectAnswer}
              countBarsToShowAtOnce={7}
              domain={[0, max(formattedData, (f) => f.value) || 1]}
              tooltipFormatter={(d) => {
                const hoveredAnswer = d as unknown as FormattedAnswers;
                const foundAnswer = lookupAnswerInQuestion(selectedQuestion, hoveredAnswer.id);
                const answerPercent = foundAnswer?.AnswerPercent ? ` (${roundToTwoDigits(foundAnswer.AnswerPercent * 100)}%)` : "";
                return `${d.value.toLocaleString()}${answerPercent}`;
              }}
              xTickValueType={TickValueType.IntegersOnly}
            />
          )}
        </RequestStatusRenderer>
      </div>

      <div className="answer-details-container">
        <RequestStatusRenderer state={selectedAnswerStatus}>
          {selectedAnswer && (
            <SurveyPerformanceDrilldownList
              answers={selectedAnswer.Users}
              otherOptionInformation={otherOptionInformation}
              includeAccounts={includeAccounts}
            />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    selectedQuestionStatus: selectSurveySelectedQuestionData(state),
    selectedQuestion: selectSurveySelectedQuestionValue(state),
    selectedAnswerStatus: selectSurveySelectedAnswerData(state),
    selectedAnswer: selectSurveySelectedAnswerValue(state),
    accountId: state.userProfile.accountId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchQuestionData: bindActionCreators(fetchSelectedQuestionData, dispatch),
    fetchAnswerData: bindActionCreators(fetchSelectedAnswerData, dispatch),
    fetchOtherOptionInformation: bindActionCreators(fetchOtherOptionInformation, dispatch),
    resetAnswerTable: bindActionCreators(resetSelectedAnswer, dispatch),
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyPerformanceQuestionDrillDown);
