import React, { FC } from "react";

import { default as SuppressibleConfirmationModal } from "../../../../../../components/modal/SuppressibleConfirmationModalTs";
import { IModalManager } from "../../../../../../interfaces/IModalManager";

export interface CancelEmailSendConfirmationModalProps {
  modalManager: IModalManager;
}

export const CancelSendSessionModal: FC<CancelEmailSendConfirmationModalProps> = ({ modalManager }) => {
  const message = (
    <div>
      Are you sure you want to cancel this send? Canceling a send will stop the scheduled email from being sent to
      selected users.
    </div>
  );

  return (
    <>
      <SuppressibleConfirmationModal
        className="send-email-popup"
        title={`Cancel Send?`}
        message={message}
        confirmButtonName={"Cancel Send"}
        cancelButtonName={"Nevermind"}
        checkboxIsHidden={true}
        modalManager={modalManager}
      />
    </>
  );
};
