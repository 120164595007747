import { useCallback } from "react";
import { IObservable, IObserver } from "../interfaces/IObservable";

export const useObserver = <TObserver extends IObserver>(observer: IObservable<TObserver> | undefined) => {
  const subscribe = useCallback(
    (subscriber: TObserver) => {
      observer?.subscribe(subscriber);
      return () => observer?.unsubscribe(subscriber);
    },
    [observer],
  );

  return [subscribe];
};
