import { Middleware } from "redux";
import TaskStatuses from "../../../enums/taskStatuses";
import backgroundTasksEventsEmitter from "./backgroundTasksEventsEmitter";
import {
  updateFailedTaskDistributedOperation,
  updateFailedTaskOperation,
  updateSucceededTaskDistributedOperation,
  updateSucceededTaskOperation,
} from "../state/backgroundTasksActions";
import { isAction } from "@reduxjs/toolkit";

const supportedDistributedOpAction = [
  updateSucceededTaskDistributedOperation.type,
  updateFailedTaskDistributedOperation.type,
];
const supportedOperationActions = [updateSucceededTaskOperation.type, updateFailedTaskOperation.type];

type DisctributedOp =
  | ReturnType<typeof updateSucceededTaskDistributedOperation>
  | ReturnType<typeof updateFailedTaskDistributedOperation>;

type Operation = ReturnType<typeof updateSucceededTaskOperation> | ReturnType<typeof updateFailedTaskOperation>;

export const operationMiddleware: Middleware = (storeApi) => (next) => (a) => {
  if (!isAction(a)) {
    return;
  }

  const isDistributedOpAction = supportedDistributedOpAction.includes(a.type);

  if (!isDistributedOpAction && !supportedOperationActions.includes(a.type)) {
    return next(a);
  }
  const result = next(a);
  const action = a as DisctributedOp | Operation;

  const tasks = storeApi.getState().backgroundTasks.tasks;
  const task: any =
    "operationId" in action
      ? getDistributedOperationTask(tasks, action.operationId)
      : getOperationTask(tasks, action.messageId);

  if (task && TaskStatuses.isCompleted(task.status)) {
    backgroundTasksEventsEmitter.raise(task.status, task.id);
  }

  return result;
};

const getOperationTask = (tasks: any, messageId: string) =>
  Object.values(tasks).find((x: any) => x.operation?.messages.includes(messageId));

const getDistributedOperationTask = (tasks: any, operationId: string) =>
  Object.values(tasks).find((x: any) => x.distributedOperation?.operationId === operationId);
