import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { withFormik } from "../../../../../../utils/formikUtils";
import { FormikProps } from "formik";

import { AppDispatch, RootState } from "../../../../../Application/globaltypes/redux";
import EmailForm from "../EmailForm/EmailForm";
import validationSchemas from "../../../../../../utils/validationSchemas";
import * as emailEntityStateActions from "../../state/actions/emailEntityStateActions";
import * as emailDetailsActions from "../../state/actions/emailDetailsActions";

import { ConfigureProps } from "../types";
import { Email, EmailView } from "../../types/state";
import { AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";
import { emailsTagsSelector } from "../../state/selector/emailCommonSelector";

export type ConfigurePropsAll = ConfigureProps & PropsFromRedux & FormikProps<EmailView> & AutosaveProps;

export class Configure extends Component<ConfigurePropsAll> {
  async componentDidMount() {
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });

    const errors = await this.props.validateForm(this.props.values);

    //Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(!Object.keys(errors).length);

    this.props.detailsActions.fetchTags();
  }

  componentWillUnmount() {
    this.props.onIsDirtyChanged?.(false);
  }

  componentDidUpdate(prevProps: ConfigurePropsAll) {
    if (prevProps.dirty !== this.props.dirty) {
      this.props.onIsDirtyChanged?.(this.props.dirty);
    }
  }

  isEmailCreated = () => this.props.id > 0;

  onNext = () => {
    this.saveEmail();
    if (!this.isEmailCreated()) {
      this.props.entityStateActions.createEmail(Configure.getEmail(this.props.values));
    }
  };

  onPrevious = () => {
    this.saveEmail();
  };

  onClose = () => {
    this.props.detailsActions.clearEmail();
    this.props.entityStateActions.resetEmailEntityState();
  };

  saveEmail = () => {
    this.props.detailsActions.saveEmail(this.props.values);
  };

  static getEmail = (values: EmailView): Email => {
    return {
      title: values.title.trim(),
      subject: values.subject.trim(),
      hasBeenPublished: values.hasBeenPublished,
      previewText: values.previewText.trim(),
      labels: values.labels.selected,
      softwareApplications: values.softwareApplications.selected,
      isPurchasedDuplicationAllowed: values.isPurchasedDuplicationAllowed,
      packs: values.packs.map((pack) => ({ name: pack.title, id: pack.id })),
      utmCampaign: values.utmCampaign.trim(),
    } as Email;
  };

  render() {
    return (
      <EmailForm tags={this.props.emailTags} disablePreventTransitionPrompt={this.isEmailCreated()} {...this.props} />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigureProps) => ({
  id: ownProps.entityId || state.library.emails.emailEntityStateReducer.entityId,
  email: state.library.emails.emailDetailsReducer.email,
  isLoading: state.library.emails.emailDetailsReducer.isLoading,
  isLoaded: state.library.emails.emailDetailsReducer.isEmailLoaded,
  isDraft: state.library.emails.emailDetailsReducer.email.isDraft,
  isCreating: state.library.emails.emailEntityStateReducer.changingEntityState,
  hasBeenPublished: state.library.emails.emailDetailsReducer.email.hasBeenPublished,
  emailTags: emailsTagsSelector(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  entityStateActions: bindActionCreators(emailEntityStateActions, dispatch),
  detailsActions: bindActionCreators(emailDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigurePropsAll, EmailView, Email>({
  getInitValues: (props) => Configure.getEmail(props.email),
  stateProvider: Configure.getEmail,
  entityUpdater: (props) => props.entityStateActions.updateEmail,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.emailInfo,
  enableReinitialize: true,
  mapPropsToValues: (props: ConfigureProps & PropsFromRedux) => props.email,
  validateOnMount: true,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
