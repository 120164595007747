import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "featureFlags";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { PeopleOverviewAccountsItem } from "features/Reporting/types/people";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ColumnOption } from "interfaces/columnOptions";
import { fetchPeopleOverviewAccountsTable } from "../utils";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import "../../peopleStyles.scss";

export interface Props {
  dateFilter: PerformanceFilter;
  selectedAccountId: number | null;
  handleAccountSelection: (id: number, name: string) => void;
}

export const AccountsTable = ({ dateFilter, selectedAccountId, handleAccountSelection }: Props) => {
  const reportEnabled = !!useFeatureFlag(FeatureFlags.PeopleReports);

  const accountsTableQuery = useQuery({
    queryFn: fetchPeopleOverviewAccountsTable,
    queryKey: ["PeopleOverviewAccountsTable", dateFilter, selectedAccountId, [], true],
    enabled: reportEnabled,
  });

  const columns: ColumnOption<PeopleOverviewAccountsItem>[] = useMemo(
    () => [
      {
        name: "Name",
        width: 7,
        render(item) {
          return (
            <Tooltip
              target={
                <TextTruncate
                  className="invisibleButton"
                  onClick={() => handleAccountSelection(item.AccountId, item.AccountName)}
                >
                  {item.AccountName}
                </TextTruncate>
              }
              content={item.AccountName}
            />
          );
        },
      },
      {
        name: "Licensed Users",
        width: 4,
        render(item) {
          return <span>{item.LicenseCount.toLocaleString()}</span>;
        },
      },
      {
        name: "Interactions",
        width: 4,
        render(item) {
          return <span>{item.InteractionCount.toLocaleString()}</span>;
        },
      },
    ],
    [handleAccountSelection],
  );

  return (
    <div className="tabSpacer">
      <RequestStatusRenderer state={accountsTableQuery.status}>
        <GenericPerformanceList rows={accountsTableQuery.data!} columns={columns} filter={dateFilter} />
      </RequestStatusRenderer>
    </div>
  );
};
