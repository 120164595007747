import * as actionTypes from "../actionTypes/assessmentsOverviewActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { FetchActionPayload } from "../../../../../interfaces/redux";
import { AssessmentOverview } from "../../types/state";

export const loadAssessmentsBeginAction = getActionBaseProvider(actionTypes.getAssessmentsBegin);
export const loadAssessmentsSuccessAction = getActionProvider<FetchActionPayload<AssessmentOverview>>(
  actionTypes.getAssessmentsSuccess,
);
export const loadAssessmentsFailureAction = getActionProvider<Error>(actionTypes.getAssessmentsFailure);
