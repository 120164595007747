import React from "react";
import { sum } from "lodash";
import {
  DeleteLinkButton,
  EventCard,
  ContentItemMenu,
  EditLinkButton,
  DuplicateLinkButton,
  DeletionRestrictedTooltip,
} from "../../../../../components";
import { Strings } from "../../../../../enums";
import { CardBase } from "../../../../../interfaces";
import { EventOverview } from "../../types/state";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface OverviewCardProps extends CardBase<EventOverview> {
  onCopy: (id: number[]) => () => void;
  onDelete: (id: number[], flowsCount?: number, packsCount?: number) => () => void;
  onEdit: (id: number, isDraft?: boolean) => () => void;
  readonly?: boolean;
}

export const OverviewCard: React.FC<OverviewCardProps> = (props: OverviewCardProps): React.ReactElement => {
  const { item, readonly, selected, onEdit, onCopy, onDelete, onSelect, disabled } = props;
  const { id, isPurchased, canBeDeleted, isDraft } = item;
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);

  const entityType = "event";
  const { flowsCount = 0, packsCount = 0, assignmentsCount = 0 } = item.dependencies || {};
  const dependencies = { flows: flowsCount, packs: packsCount, "users or groups": assignmentsCount };

  const renderPopupMenu = () => (
    <ContentItemMenu
      circle
      outlinedEllipsis
      isChecked={selected}
      item={{
        isPurchased: isPurchased,
        canBeDeleted: canBeDeleted,
      }}
      readonly={readonly || isPurchased}
      entityType={entityType}
      dependencies={dependencies}
      purchasedTooltipContent={Strings.tooltipMessages.purchased.oneDeleteDisabled(entityType)}
    >
      <EditLinkButton onClick={onEdit?.(id, isDraft)} />
      <DuplicateLinkButton onClick={onCopy?.([id])} />
      <DeleteLinkButton
        isDisabled={deleteContentEnabled ? isPurchased : !canBeDeleted}
        after={
          !deleteContentEnabled &&
          !!sum(Object.values(dependencies)) && (
            <DeletionRestrictedTooltip dependencies={dependencies} entityType={entityType} />
          )
        }
        onClick={onDelete?.([id], flowsCount, packsCount)}
      />
    </ContentItemMenu>
  );

  return (
    <EventCard
      item={item}
      disabled={disabled}
      url={props.item.id.toString()}
      onSelect={onSelect}
      popupMenu={renderPopupMenu()}
      selected={selected}
    />
  );
};
