import * as actionTypes from "../actionTypes/threatDefenceCampaignsOverviewActionTypes";
import { createReducer } from "../../../../../../utils/reduxUtils";
import { ThreatDefenceCampaignsOverviewState, ThreatDefenceCampaignOverview } from "../../types/state";
import { PayloadAction, FetchActionPayload } from "../../../../../../interfaces/redux";

const initialState: ThreatDefenceCampaignsOverviewState = {
  campaigns: [],
  isLoading: false,
  totalCount: 0,
  error: undefined,
};

const threatDefenceCampaignsOverviewHandlers = () => {
  const {
    getThreatDefenceCampaignsBegin: getCampaignsBegin,
    getThreatDefenceCampaignsSuccess: getCampaignsSuccess,
    getThreatDefenceCampaignsFailure: getCampaignsFailure,
  } = actionTypes;

  const getCampaignsBeginHandler = (
    state: ThreatDefenceCampaignsOverviewState,
  ): ThreatDefenceCampaignsOverviewState => ({
    ...state,
    campaigns: new Array<ThreatDefenceCampaignOverview>(),
    isLoading: true,
  });

  const getCampaignsSuccessHandler = (
    state: ThreatDefenceCampaignsOverviewState,
    action: PayloadAction<FetchActionPayload<ThreatDefenceCampaignOverview>>,
  ): ThreatDefenceCampaignsOverviewState => ({
    ...state,
    campaigns: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const failureHandler = (
    state: ThreatDefenceCampaignsOverviewState,
    action: PayloadAction<Error>,
  ): ThreatDefenceCampaignsOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  return {
    [getCampaignsBegin]: getCampaignsBeginHandler,
    [getCampaignsSuccess]: getCampaignsSuccessHandler,
    [getCampaignsFailure]: failureHandler,
  };
};

export const threatDefenceCampaignsOverviewReducer = createReducer(initialState, [
  threatDefenceCampaignsOverviewHandlers,
]);
