import { FC, useState } from "react";
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { RolePermissions, Strings } from "../../../../../enums";
import { Icon } from "semantic-ui-react";
import { Tooltip } from "../../../../../components/common/tooltip";
import { DeleteLinkButton, DuplicateLinkButton } from "../../../../../components/buttons/linkButtons";
import { AddLabelsEmailButton } from "./AddLabelsEmailButton";
import { bindActionCreators } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import * as emailDetailsActions from "../state/actions/emailDetailsActions";
import Observable from "../../../../../utils/Observable";
import { connect, ConnectedProps } from "react-redux";
import AddLabelsModalContainer from "./AddLabelsModalContainer";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";
import Restricted from "../../../../Application/Restricted";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface Props {
  purchasedEmailSelected: boolean;
  publishedEmailsSelected: boolean;
  draftEmailsSelected: boolean;
  undeletableSelected: boolean;
  unduplicatableSelected: boolean;
  selectedIds: Array<number>;
  setSelectedIds: (ids: number[]) => void;
  deletionHandler: () => void;
  duplicationHandler: () => void;
  onAddedToPacks: () => void;
  renderCreateButton: () => React.ReactElement;
}

export type EmailsOverviewHeaderProps = Props & PropsFromRedux;

export const EmailsOverviewHeader: FC<EmailsOverviewHeaderProps> = (props) => {
  const {
    selectedIds,
    setSelectedIds,
    draftEmailsSelected,
    purchasedEmailSelected,
    publishedEmailsSelected,
    undeletableSelected,
    unduplicatableSelected,
    deletionHandler,
    duplicationHandler,
    onAddedToPacks,
    fetchTags,
  } = props;
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const deleteDisabled = purchasedEmailSelected || (!deleteContentEnabled && undeletableSelected);
  const deleteTooltipMessage = purchasedEmailSelected
    ? Strings.tooltipMessages.purchased.deleteDisabled("emails")
    : Strings.tooltipMessages.deleteDisabled("emails", ["Flows", "Packs"]);
  const onTriggerAddLabelsObserver = new Observable();
  const duplicateTooltipMessage = "One or more selected emails is from a Marketplace Pack and cannot be duplicated.";
  const addToPacksDisabled = draftEmailsSelected || purchasedEmailSelected;

  const [packsModalShown, setPacksModalShown] = useState(false);

  const renderBatchButtons = () => {
    return (
      <>
        <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
          <AddToPacksButton isDisabled={addToPacksDisabled} onClick={handleAddToPacksClick} />
        </Restricted>
        <Tooltip
          showAlways={unduplicatableSelected}
          inverted
          hideOnScroll
          position="left center"
          target={
            <DuplicateLinkButton
              after={unduplicatableSelected && <Icon size="large" className="fa fa-exclamation-circle" />}
              isDisabled={unduplicatableSelected}
              onClick={duplicationHandler}
            />
          }
          content={duplicateTooltipMessage}
        />
        <Tooltip
          showAlways={deleteDisabled}
          inverted
          hideOnScroll
          position="left center"
          target={
            <DeleteLinkButton
              after={deleteDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
              isDisabled={deleteDisabled}
              onClick={deletionHandler}
            />
          }
          content={deleteTooltipMessage}
        />
        <AddLabelsEmailButton isDisabled={publishedEmailsSelected} onClick={handleAddLabelsClick} />
        <AddLabelsModalContainer
          onTriggerModalObserver={onTriggerAddLabelsObserver}
          tagType={TagsEnum.Label}
          propertyName="labels"
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={/* istanbul ignore next */ () => setPacksModalShown(false)}
          onComplete={handleAddedToPacks}
          selectedItemIds={selectedIds}
          contentType="Email"
        />
      </>
    );
  };

  const handleAddLabelsClick = () => {
    fetchTags();
    onTriggerAddLabelsObserver.notify(() => {
      setSelectedIds([]);
    }, selectedIds);
  };

  const handleAddToPacksClick = () => {
    setPacksModalShown(true);
  };

  const handleAddedToPacks = () => {
    setSelectedIds([]);
    onAddedToPacks();
  };

  const renderHeaderButtons = () => (selectedIds.length ? renderBatchButtons() : props.renderCreateButton());

  return (
    <OverviewHeader
      title="Communications"
      titleForGA="Emails"
      itemName="Email"
      selectedItemsAmount={selectedIds.length}
    >
      {renderHeaderButtons()}
    </OverviewHeader>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindActionCreators(emailDetailsActions.fetchTags, dispatch),
});

const connector = connect(undefined, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EmailsOverviewHeader);
