import React, { Component, cloneElement } from "react";
import PropTypes from "prop-types";
import { Icon, Popup } from "semantic-ui-react";
import { Button } from "../button/Button";
import cn from "classnames";

import "./ellipsisPopupButton.scss";

class EllipsisPopupButton extends Component {
  state = { isOpen: false };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.closeTimeoutId = setTimeout(() => this.setState({ isOpen: false }), 0);
  };

  handleOnClickBtn = (rootOnClickHandler) => {
    return () => {
      this.handleClose();
      rootOnClickHandler && rootOnClickHandler();
    };
  };

  componentWillUnmount() {
    clearTimeout(this.closeTimeoutId);
  }

  getPropsForButton(btn, key) {
    return btn.type === EllipsisPopupButton
      ? {
          horizontalOffset: -10,
          hoverable: true,
          key,
        }
      : {
          onClick: this.handleOnClickBtn(btn.props.onClick),
          key,
        };
  }

  assignPropsToButton = (buttons) => {
    let key = 0;
    if (buttons instanceof Array) {
      return buttons.map((btn) => {
        if (!btn) {
          return null;
        }

        key++;

        let btnProps = this.getPropsForButton(btn, key);
        return cloneElement(btn, btnProps);
      });
    }
    return buttons;
  };

  render() {
    const { children, circle, position, outlinedEllipsis, disabled, on, trigger, horizontalOffset, hoverable } =
      this.props;
    const buttonClassName = cn(
      "ellipsis-button",
      circle ? "circle" : "rectangle",
      outlinedEllipsis ? "hover-color-blue" : "",
    );
    const iconClassName = cn(outlinedEllipsis ? "fa-ellipsis-h" : "ellipsis horizontal");

    const buttons = this.assignPropsToButton(React.Children.toArray(children));

    return (
      <Popup
        hoverable={hoverable}
        positionFixed
        hideOnScroll
        horizontaloffset={horizontalOffset}
        position={position}
        className={cn("ellipsis-button-popup", this.props.className)}
        trigger={
          trigger || (
            <Button
              className={buttonClassName}
              disabled={disabled}
              key="show-more-button"
              data-testid="ellipsis-button"
            >
              <Icon className={iconClassName} />
            </Button>
          )
        }
        on={on}
        open={this.state.isOpen}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        {buttons}
      </Popup>
    );
  }
}

EllipsisPopupButton.defaultProps = {
  position: "bottom right",
  disabled: false,
  on: "click",
};

EllipsisPopupButton.propTypes = {
  circle: PropTypes.bool,
  position: PropTypes.string,
  outlinedEllipsis: PropTypes.bool,
  disabled: PropTypes.bool,
  trigger: PropTypes.node,
  on: PropTypes.string,
  horizontalOffset: PropTypes.number,
  hoverable: PropTypes.bool,
  className: PropTypes.string,
};

export default EllipsisPopupButton;
