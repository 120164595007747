import orderBy from "lodash/orderBy";
import isEqual from "lodash/isEqual";
import React, { Component } from "react";
import PropTypes from "prop-types";

export default class ListViewWithInMemorySorting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
      sortingColumn: null,
      sortingDirection: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    const { sortingColumn, sortingDirection } = this.state;

    if (!isEqual(prevProps.items, items)) {
      this.sortItems(items, sortingColumn, sortingDirection);
    }
  }

  loadPage = (_skip, _take, sortingColumn, sortingDirection) => {
    const { items } = this.state;
    this.sortItems(items, sortingColumn, sortingDirection);
  };

  getSortedItems = (items, sortingProp, sortingDirection) => {
    return orderBy(
      items,
      (item) => {
        const sortPropValue = item[sortingProp];
        return typeof sortPropValue === "string" ? sortPropValue.toUpperCase() : sortPropValue;
      },
      sortingDirection,
    );
  };

  getSortingPropName = (sortingColumn) => {
    return this.props.mapColumnName ? this.props.mapColumnName(sortingColumn) : sortingColumn;
  };

  sortItems = (items, sortingColumn, sortingDirection) => {
    let sortedItems;

    if (!sortingColumn) {
      sortedItems = items;
    } else {
      const sortingProp = this.getSortingPropName(sortingColumn);
      sortedItems = this.getSortedItems(items, sortingProp, sortingDirection);
    }

    this.setState({ items: sortedItems, sortingColumn, sortingDirection });
  };

  render() {
    const { as: ListComponent, ...otherProps } = this.props;
    const { items } = this.state;
    return <ListComponent {...otherProps} items={items} loadPage={this.loadPage} itemsAmount={items.length} />;
  }
}

ListViewWithInMemorySorting.defaultProps = {
  items: [],
};

ListViewWithInMemorySorting.propTypes = {
  as: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  items: PropTypes.array,
  mapColumnName: PropTypes.func,
};
