import { createAsyncThunk } from "@reduxjs/toolkit";

import { getPrefix } from "../../../../Application/slices/models";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import accountsDataService from "../../../services/accountsDataService";

export const accountTypesPrefixPayload = {
  namespace: ReducerNamespaceTypes.Accounts,
  entity: ReducerEntityPrefixTypes.AccountCreation,
  name: "accountTypes",
};

export const fetchAccountTypes = createAsyncThunk(getPrefix(accountTypesPrefixPayload), async () => {
  const items = await accountsDataService.getAccountTypes();
  return { items, totalCount: items.length };
});

export const globalRolesStatePrefixPayload = {
  namespace: ReducerNamespaceTypes.Accounts,
  entity: ReducerEntityPrefixTypes.AccountCreation,
  name: "globalRoles",
};

export const fetchGlobalRoles = createAsyncThunk(
  getPrefix(globalRolesStatePrefixPayload),
  async (accountTypeId: number) => {
    const items = await accountsDataService.getAccountTypeRoles(accountTypeId);
    return { items, totalCount: items.length };
  },
);
