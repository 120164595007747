import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/globaltypes/redux";
import { IdentityProviderSamlSettings } from "../../types";

export interface IdentityProviderSamlSettingsState extends IdentityProviderSamlSettings {
  isLoading: boolean;
  error: string | undefined;
  isAccessAuthorized: boolean;
}

const initialState: IdentityProviderSamlSettingsState = {
  metadataAutoRefresh: false,
  omitAssertionSignatureCheck: false,
  signRequest: false,
  isSiteMinder: false,
  forceAuth: false,
  encodingTypeId: 0,
  isPassive: false,
  certificateValidationType: 0,
  signingCertificateThumbprint: "",
  signatureAlgorithmId: 0,
  isLoading: false,
  error: undefined,
  isAccessAuthorized: true,
};

const identityProviderSamlSettingsSlice = createSlice({
  name: "identityProviderSamlSettings",
  initialState: initialState,
  reducers: {
    req(state: IdentityProviderSamlSettingsState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: IdentityProviderSamlSettingsState, action: PayloadAction<IdentityProviderSamlSettings>) {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    },
    err(state: IdentityProviderSamlSettingsState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    forbidden(state: IdentityProviderSamlSettingsState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isAccessAuthorized: false,
        error: action.payload,
      };
    },
    updated(state: IdentityProviderSamlSettingsState, action: PayloadAction<IdentityProviderSamlSettings>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { req, got, err, updated, forbidden } = identityProviderSamlSettingsSlice.actions;

export const identityProviderSamlSettingsSelector = (state: RootState) =>
  state.accounts.identityProviders.editIdentityProvider.identityProviderSamlSettings;
export const identityProviderSamlIsSettingsLoadingSelector = (state: RootState) =>
  state.accounts.identityProviders.editIdentityProvider.identityProviderSamlSettings.isLoading;

export default identityProviderSamlSettingsSlice.reducer;
export type identityProviderSamlSettingsStateSliceType = ReturnType<typeof identityProviderSamlSettingsSlice.reducer>;
