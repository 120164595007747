import { useCallback, useEffect, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RemindersHeader } from "./RemindersHeader";
import { LazyLoadingList } from "../../../../../components/lazyLoadingList/LazyLoadingList";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { deleteReminders, fetchReminders, refetchReminders } from "../../state/thunks/remindersThunk";
import { SortingDirection } from "../../../../../enums";
import { getRemindersColumnOptions, RemindersTabColumns } from "./RemindersColumnOptions";
import { reset, selectedItemsChanged } from "../../state/slices/remindersSlice";
import { bindAction } from "../../../../../interfaces/redux";
import { useLazyLoadingController } from "../../../../../components/lazyLoadingList/useLazyLoadingController";
import { REMINDERS } from "../../types/constants";

import "./Reminders.scss";
import { IObservable } from "../../../../../interfaces/IObservable";
import { useObserver } from "../../../../../hooks/useObserver";
import { bindActionCreators } from "@reduxjs/toolkit";
import DeleteRemindersConfirmationModal from "./modals/DeleteRemindersConfirmationModal";

const DEFAULT_ORDER_COLUMN_NAME = RemindersTabColumns.Title;

export type RemindersProps = PropsFromRedux & {
  noResultContent: React.ReactElement;
  onAddButtonClick: () => void;
  disabled?: boolean;
  id: number;
  onDiscardedObserver?: IObservable<() => void>;
  onTriggerRemoveRemindersObserver: IObservable<(onRemoveConfirm: () => void, selectedItemsCount: number) => void>;
};

export const Reminders = (props: RemindersProps) => {
  const {
    onAddButtonClick,
    disabled,
    id,
    items,
    isAllDataLoaded,
    isLoading,
    noResultContent,
    selectedItems,
    selectedItemsChanged,
    fetchRemindersItems,
    resetState,
    refetchRemindersItems,
    deleteReminders,
    onDiscardedObserver,
    onTriggerRemoveRemindersObserver,
  } = props;

  const lazyLoadingListProps = useLazyLoadingController({
    items,
    selectedItems,
    onChangeSelected: selectedItemsChanged,
  });

  const [subscribeOnDiscarded] = useObserver(onDiscardedObserver);

  useEffect(() => {
    fetchItems();
    return () => {
      resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return subscribeOnDiscarded(() => refetchRemindersItems(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnDiscarded, id]);

  const handleDeleteClick = useCallback(
    (selectedId: number) => () => {
      onTriggerRemoveRemindersObserver.notify(() => {
        deleteReminders(id, [selectedId]);
      }, 1);
    },
    [id, deleteReminders, onTriggerRemoveRemindersObserver],
  );

  const fetchItems = () => {
    fetchRemindersItems({
      eventId: id,
      top: REMINDERS.TOP,
      skip: items.length,
    });
  };

  const columnOptions = useMemo(() => {
    return getRemindersColumnOptions({
      handleDeleteClick,
      readonly: disabled,
    });
  }, [disabled, handleDeleteClick]);

  return (
    <div className="event-reminders">
      {items.length > 0 && <RemindersHeader disabled={disabled} onAddButtonClick={onAddButtonClick} />}
      <div className="event-reminders__content">
        <LazyLoadingList
          {...lazyLoadingListProps}
          items={items}
          hasPermission={true}
          isLoading={isLoading}
          isAllDataLoaded={isAllDataLoaded}
          disabled={!!disabled}
          withSelection={true}
          fetchItems={fetchItems}
          columnOptions={columnOptions}
          defaultSortingColumnName={DEFAULT_ORDER_COLUMN_NAME}
          sortingDirection={SortingDirection.Descending}
          noResultsContent={noResultContent}
        />
        <DeleteRemindersConfirmationModal
          onTriggerRemoveRemindersObserver={onTriggerRemoveRemindersObserver}
          onConfirmed={lazyLoadingListProps.reset}
        />
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const reminders = state.library.events.eventReminders;
  return {
    isLoading: reminders.isLoading,
    items: reminders.items,
    isAllDataLoaded: reminders.areAllLoaded,
    selectedItems: reminders.selectedItems,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchRemindersItems: bindAction(fetchReminders, dispatch),
  resetState: bindAction(reset, dispatch),
  refetchRemindersItems: bindAction(refetchReminders, dispatch),
  deleteReminders: bindActionCreators(deleteReminders, dispatch),
  selectedItemsChanged: bindActionCreators(selectedItemsChanged, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Reminders);
