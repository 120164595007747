import * as actionTypes from "./wizardWrapperActionTypes";

const initialState = {
  isWizardOpened: false,
};

export default function wizardWrapperReducer(currentState, action) {
  const state = currentState || initialState;

  switch (action.type) {
    case actionTypes.OPEN_WIZARD:
      return Object.assign({
        ...state,
        isWizardOpened: true,
      });

    case actionTypes.CLOSE_WIZARD:
      return Object.assign({
        ...state,
        isWizardOpened: false,
      });

    default:
      return state;
  }
}
