import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

import "./previewLinkButton.scss";

export interface PreviewLinkButtonProps {
  onClick: () => void;
  text?: string;
  isDisabled?: boolean;
}

const PreviewLinkButton: React.FC<PreviewLinkButtonProps> = ({ onClick, isDisabled, text }) => (
  <BasicLinkButton
    className={"preview-link-button"}
    text={text ?? "Preview"}
    iconClassName="fa-eye"
    onClick={onClick}
    isDisabled={isDisabled}
  />
);

export default PreviewLinkButton;
