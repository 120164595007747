import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import uniqWith from "lodash/uniqWith";
import ValidatedField from "./ValidatedField";
import { MultiSelectWithAddition } from "../multiSelect";
import * as stringUtils from "../../utils/stringUtils";
import labelsValidationUtils from "../../utils/labelsValidationUtils";
import { KEY_CODES } from "../../enums/keyCodes";
import miscUtils from "../../utils/miscellaneousUtils";

const AUTOCOMPLETE_SEARCH_VALUE_MIN_LENGTH = 3;

const {
  equalityComparers: { caseInsensitive: caseInsensitiveStringComparer, caseSensitive: caseSensitiveStringComparer },
  StringArrayUtils: { trimItems, removeEmptyItems },
} = stringUtils;

function MultiSelectWithAdditionField(props) {
  const {
    placeholder,
    propertyName,
    value,
    options,
    setFieldValue,
    shouldValidate,
    isCaseSensitive,
    validator,
    setFieldTouched,
    className,
    onBlur,
    onChanged,
    onItemRemove,
    handleBlur,
    disabled,
    fillOptionsCallback,
    resetOptionsCallback,
    onAddItem,
    renderLabel,
    readonly,
  } = props;

  const [items, setItems] = useState(options);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    if (options) {
      setItems(options);
    }
  }, [options]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const valueComparer = isCaseSensitive ? caseSensitiveStringComparer : caseInsensitiveStringComparer;

  const setValue = async (fieldName, fieldValue, validate) => {
    await setFieldValue(fieldName, fieldValue, validate);
    onChanged?.();
  };

  const handleChange = (_, data) => {
    if (data.disabled || (readonly && value.length > data.value.length)) {
      return;
    }

    let selectedValues = data.value;
    const normalizedSelectedValues = uniqWith(removeEmptyItems(trimItems(selectedValues)), valueComparer);

    setSelected(normalizedSelectedValues);
    setValue(propertyName, { ...miscUtils.normalizeDropdownItems(normalizedSelectedValues) }, shouldValidate);
    setFieldTouched(propertyName, true, false);
    labelsValidationUtils.validateLabels(validator, items, selectedValues, setItems);
    if (value.length > selectedValues.length) {
      onItemRemove && onItemRemove(propertyName);
    }
  };

  const handleAddition = (_, data) => {
    const itemsToAdd = labelsValidationUtils.getItemsToAdd(data.value, items, valueComparer);
    if (itemsToAdd) {
      setItems(itemsToAdd);
      onAddItem?.(_, data);
    }
  };

  const handleSearchInputKeyDown = (e) => {
    if (e.keyCode === KEY_CODES.SPACE && e.target.value.trim() === "") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleSearchInputKeyUp = (e) => {
    if (fillOptionsCallback && e.target.value.trim().length >= AUTOCOMPLETE_SEARCH_VALUE_MIN_LENGTH) {
      fillOptionsCallback(e.target.value.trim());
    }
  };

  const handleFieldBlur = (e) => {
    handleBlur(e);
    onBlur && onBlur(propertyName);
    resetOptionsCallback && resetOptionsCallback();
  };

  return (
    <ValidatedField {...props}>
      <MultiSelectWithAddition
        id={propertyName}
        name={propertyName}
        className={className}
        placeholder={placeholder}
        selected={selected}
        items={items}
        handleMultiSelectChange={handleChange}
        handleMultiSelectAddition={handleAddition}
        searchInput={{
          onKeyDown: handleSearchInputKeyDown,
          onKeyUp: handleSearchInputKeyUp,
        }}
        onBlur={handleFieldBlur}
        renderLabel={renderLabel}
        disabled={disabled}
      />
    </ValidatedField>
  );
}

MultiSelectWithAdditionField.defaultProps = {
  isCaseSensitive: false,
  readonly: false,
};

MultiSelectWithAdditionField.propTypes = {
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  propertyName: PropTypes.string.isRequired,
  shouldValidate: PropTypes.bool,
  isCaseSensitive: PropTypes.bool,
  validator: PropTypes.object,
  onBlur: PropTypes.func,
  onChanged: PropTypes.func,
  onAddItem: PropTypes.func,
  onItemRemove: PropTypes.func,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  label: PropTypes.string,

  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  renderLabel: PropTypes.func,
  className: PropTypes.string,
  errorPosition: PropTypes.string,
};

export default MultiSelectWithAdditionField;
