import React, { FC } from "react";

import { Filters } from "../../../utils/queryUtils";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { getEventFilters } from "./eventFilters";

export interface EventFilterFormProps {
  filterOptions: Filters;
  updateFilter: (filter: Filters) => void;
  filter: Filters;
}
export const EventFilterForm: FC<EventFilterFormProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getEventFilters()}
  />
);
