import { noop } from "lodash";
import React from "react";
import { Grid, InputProps } from "semantic-ui-react";
import { FormikProps } from "formik";
import { ValidatedForm } from "../../../../../../../components";
import TriggerTypeEnum from "../../../../../../../enums/flowDesigner/triggerType";
import { Trigger } from "../../../types";

export interface DetailInputOptions {
  markAsRequired?: boolean;
  label: string;
  defaultValue?: number;
  onBlur?: () => void;
}

export type TriggerTypeDetailMap = {
  [key in TriggerTypeEnum]?: React.ReactElement;
};

export interface FormValues {
  delay?: string;
  units?: string;
}

export interface OwnProps {
  isReadOnly: boolean;
  validatedFieldProps: FormikProps<FormValues>;
  onDelayBlur: (source: string, destination: string) => void;
  onUnitsChange: (value: string, source: string, destination: string) => void;
  onTimeSpanChange: (value: string, source: string, destination: string) => void;
}

export type Props = Trigger & OwnProps;

export const TriggerTypeDetail: React.FC<Props> = ({
  delay,
  isReadOnly,
  validatedFieldProps,
  onDelayBlur,
  source,
  destination,
  trigger,
  units,
  unitsOptions,
  onUnitsChange,
  onTimeSpanChange,
}) => {
  const onTimeSpanValueChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: InputProps) => {
    let resultValue = data.value;
    if (resultValue !== "") {
      resultValue = parseInt(resultValue);
    }
    if (isNaN(resultValue)) {
      return;
    }
    onTimeSpanChange(resultValue, source, destination);
  };

  const onUnitsValueChange = (_: React.SyntheticEvent<HTMLElement, Event>, data: InputProps) => {
    onUnitsChange(data.value, source, destination);
  };

  const renderDetailInput = ({ markAsRequired, label, defaultValue, onBlur }: DetailInputOptions) => (
    <ValidatedForm.InputField
      {...validatedFieldProps}
      markAsRequired={markAsRequired}
      label={label}
      propertyName="delay"
      value={delay || defaultValue}
      maxLength={2}
      errorPosition="bottom"
      handleChange={onTimeSpanValueChange}
      onBlur={onBlur || noop}
      disabled={isReadOnly}
    />
  );

  const renderWithinInput = renderDetailInput({ label: "Within", markAsRequired: true });

  const renderDelayInput = renderDetailInput({
    label: "Delay",
    defaultValue: 0,
    onBlur: () => {
      onDelayBlur(source, destination);
    },
  });

  const triggerTypeDetailMap: TriggerTypeDetailMap = {
    [TriggerTypeEnum.NotConsumed]: renderWithinInput,
    [TriggerTypeEnum.Completion]: renderDelayInput,
    [TriggerTypeEnum.Pass]: renderDelayInput,
    [TriggerTypeEnum.Fail]: renderDelayInput,
    [TriggerTypeEnum.Open]: renderDelayInput,
    [TriggerTypeEnum.ClickThrough]: renderDelayInput,
    [TriggerTypeEnum.Registered]: renderDelayInput,
    [TriggerTypeEnum.Sent]: renderDelayInput,
  };

  const triggerTypeDetail = triggerTypeDetailMap[trigger];
  if (!triggerTypeDetail) return <></>;

  return (
    <Grid stackable columns="equal" verticalAlign="bottom">
      <Grid.Column width={5}>{triggerTypeDetail}</Grid.Column>
      <Grid.Column width={11}>
        <ValidatedForm.DropdownField
          value={units}
          options={unitsOptions}
          propertyName="units"
          onChangeCallback={onUnitsValueChange}
          disabled={isReadOnly}
          {...validatedFieldProps}
        />
      </Grid.Column>
    </Grid>
  );
};
