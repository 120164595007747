import { addTemplatesThunk } from "features/Accounts/state/thunks/AccountTemplatesThunk";
import Templates from "features/Accounts/Templates/Templates";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { useEventCallback } from "hooks/useEventCallback";
import { bindAction, IWizardStep } from "interfaces";
import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

type Props = IWizardStep &
  PropsFromRedux & {
    accountId: number;
  };

export const CreateAccountTemplates = (props: Props) => {
  const { accountId, addTemplates, templates } = props;

  const cb = useEventCallback(() => {
    addTemplates(accountId, templates);
  }, [accountId, addTemplates, templates]);

  useEffect(() => {
    props.acceptHandlers?.({
      onFinish: cb,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Templates accountId={accountId} />;
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    templates: state.accounts.availableTemplates.overview.selected,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addTemplates: bindAction(addTemplatesThunk, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateAccountTemplates);
