import { FC } from "react";

import PaginationButtons from "../../../../../components/itemsViewHeader/paginationButtons/PaginationButtons";
import { PerformanceTabSection } from "../PerformanceTabSection/PerformanceTabSection";
import { usePagination } from "../../Hooks/usePagination";
import { PaginationFrame } from "../../../../../components/pagination/PaginationFrame";

export type PerformanceTabSectionProps = {
  renderList: (page: number) => React.ReactElement;
  title: string;
  count: number;
  hideControls?: boolean;
};

export const PerformanceTabSectionList: FC<PerformanceTabSectionProps> = (props) => {
  const { count, hideControls } = props;
  const { page, pageRange, onNextPage, onPrevPage } = usePagination(count);

  const { title, renderList } = props;
  return (
    <PerformanceTabSection
      hideControls={hideControls}
      className="performance-tab-people"
      title={<span className="main-title-text">{title}</span>}
      controls={
        <>
          <PaginationFrame range={pageRange} count={count} />
          <PaginationButtons
            onPrevPage={onPrevPage}
            onNextPage={onNextPage}
            itemsInlineCount={count}
            itemsPageRange={pageRange}
            activePage={page}
          />
        </>
      }
      content={renderList(page)}
    />
  );
};
