import { combineReducers, Reducer } from "redux";

import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../enums";
import { withFetchingChildEntityListItems } from "../../../Application/reducers/hoc/withFetchingItems";
import withSearch from "../../../Application/reducers/hoc/withSearch";
import groupPacksGridSlice from "../../../Licensing/Packs/state/slices/groupPacksGridSlice";
import * as actionTypes from "./editGroupActionTypes";
import { editGroupInfoReducer } from "./state/slices/editGroupInfoSlice";

const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.EditGroup;

const delimiter = "__";

export const editGroup: Reducer = combineReducers({
  editInfo: editGroupInfoReducer,
  editPeople: combineReducers({
    usersList: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.GroupUsers),
    usersToAdd: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.GroupUsersToAdd),
  }),
  ownersList: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.GroupOwners),
  groupAssignedOwners: withFetchingChildEntityListItems(namespace, entityPrefix, actionTypes.GroupAssignedOwners),
  membersSearch: withSearch(namespace, entityPrefix.concat(delimiter, actionTypes.GroupUsers)),
  groupPacks: combineReducers({
    packsGrid: groupPacksGridSlice,
  }),
  addMembersSearch: withSearch(namespace, entityPrefix.concat(delimiter, actionTypes.GroupUsersToAdd)),
});

export type EditGroupState = ReturnType<typeof editGroup>;
