import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";

const namespace = ReducerNamespaceTypes.Reporting;
const entityPrefix = ReducerEntityPrefixTypes.Reporting_Export;

type FuncType = (...args: any[]) => (dispatch: AppDispatch) => void | Promise<void>;

type ExportState<U extends FuncType> = {
  method?: U | null;
  args: Parameters<U>;
  exporting: boolean;
  hasDataToExport: boolean;
  isExportEnabled: boolean;
};

const initialState: ExportState<any> = {
  method: null,
  args: [],
  exporting: false,
  hasDataToExport: false,
  isExportEnabled: false,
};

const exportSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    setExportAction(
      state,
      action: PayloadAction<{ method: ExportState<FuncType>["method"]; args?: any[]; isExportEnabled?: boolean; }>,
    ) {
      state.method = action.payload.method;
      state.args = action.payload.args || [];
      state.isExportEnabled = action.payload.isExportEnabled || false;
    },
    setExporting(state, action: PayloadAction<boolean>) {
      state.exporting = action.payload;
    },
    setHasDataToExport(state, { payload }: PayloadAction<boolean>) {
      state.hasDataToExport = payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const { setExportAction, setExporting, setHasDataToExport, reset } = exportSlice.actions;

export const selectExportInfo = createSelector(
  (s: RootState) => s.reporting.export,
  (s) => ({
    method: s.method,
    args: s.args,
  }),
);
export const selectExporting = (state: RootState) => state.reporting.export.exporting;
export const selectHasDataToExport = (state: RootState) => state.reporting.export.hasDataToExport;
export const selectIsExportActive = (state: RootState) => state.reporting.export.isExportEnabled;
export default exportSlice.reducer;
