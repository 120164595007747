import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { some } from "lodash";
import { LeftArrowButton, RightArrowButton } from "../../components/buttons/arrowButtons";
import { ProgressDrawerButton } from "../../components/buttons/progressDrawerButton";
import TopMenuAccordion from "./TopMenuAccordion";
import ProgressDrawer from "../progressDrawer/ProgressDrawer";
import { RouteNames } from "../../enums";
import arrayUtils from "../../utils/arrayUtils";
import Logo from "./Logo";
import FeedbackButton from "../feedbackButton/FeedbackButton";
import { useLocation } from "react-router-dom";
import AccountSettingsMenu from "./AccountSettingsMenu";

import "./navigation.scss";

export function getActiveRouteItems(pathname) {
  const possibleRoutes = RouteNames.allRoutes;
  const twoLevels = pathname.split("/").slice(1, 3);
  const activeRouteItems = [];
  for (let i = 1; i <= twoLevels.length; i++) {
    const route = twoLevels.slice(0, i).join("/");
    if (possibleRoutes.includes(route)) {
      activeRouteItems.push(route);
    }
  }

  return activeRouteItems;
}

const collapsedStateInitiallyExpandedItems = [
  RouteNames.linkedAccountsManagement,
  RouteNames.accountSettingsManagement,
  RouteNames.content,
];

const collapsedStateAvailableItems = [...collapsedStateInitiallyExpandedItems, RouteNames.contentSimulatedPhishing];

export function Navigation(props) {
  const {
    children,
    isWizardOpened,
    collapsed,
    isCollapsedByUser,
    sidebarActions,
    backgroundTasks,
    backgroundTasksElapsedTime,
    onDismissAllClick,
    moboAccountName,
    moboAccountLogoUrl,
    isProgressDrawerVisible,
    showProgressDrawer,
    hideProgressDrawer,
    accountId,
    onSignout,
  } = props;

  const [activeItems, setActiveItems] = useState([]);
  // Redraws accountName to determine if popup is necessary
  const [disableTooltip, setDisableTooltip] = useState(true);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    const activeRouteItems = getActiveRouteItems(location.pathname);
    if (collapsed) {
      const routeItems = activeRouteItems.filter((a) => collapsedStateAvailableItems.includes(a));
      setActiveItems([...collapsedStateInitiallyExpandedItems, ...routeItems]);
    } else {
      setActiveItems(activeRouteItems);
    }
  }, [collapsed, location.pathname]);

  useEffect(() => {
    if (!isCollapsedByUser && isWizardOpened) {
      sidebarActions?.autoCollapseSidebar();
      setActiveItems(collapsedStateInitiallyExpandedItems);
    } else if (!isCollapsedByUser && !isWizardOpened) {
      sidebarActions?.expandSidebar();
    }
  }, [isWizardOpened]); // eslint-disable-line

  const handleTopLevelItemClick = (path) => {
    if (collapsed && !collapsedStateAvailableItems.includes(path)) {
      return;
    }

    if (activeItems.includes(path)) {
      setActiveItems(arrayUtils.withoutItem(activeItems, path));
    } else {
      setActiveItems([...activeItems, path]);
    }
  };

  const onCollapse = () => {
    sidebarActions?.collapseSidebarByUser();
  };

  const onExpand = () => {
    sidebarActions?.expandSidebar();
  };

  const renderExpandCollapseControls = () => {
    let activeButton;
    if (collapsed) {
      activeButton = <RightArrowButton onClick={onExpand} />;
    } else {
      activeButton = <LeftArrowButton onClick={onCollapse} />;
    }
    return <div className="panel-width-controls">{activeButton}</div>;
  };

  useEffect(() => {
    if (!collapsed) {
      const delayWhileOpening = 600;
      setTimeout(() => {
        setDisableTooltip(false);
        dropdownRef.current.style.setProperty(
          "--dynamic-position",
          `${iconRef.current.getBoundingClientRect().x - 9}px`,
        );
      }, delayWhileOpening);
    } else setDisableTooltip(true);
  }, [collapsed]);

  return (
    <div className={cn("navigation", { collapsed: collapsed })}>
      <nav className="navbar pusher scrollable-content">
        <Logo moboAccountName={moboAccountName} moboAccountLogoUrl={moboAccountLogoUrl} collapsed={collapsed} />
        {renderExpandCollapseControls()}

        <TopMenuAccordion activeItems={activeItems} collapsed={collapsed} topLevelItemClick={handleTopLevelItemClick} />

        <ProgressDrawer
          visible={isProgressDrawerVisible}
          hideDrawer={hideProgressDrawer}
          tasks={backgroundTasks}
          elapsedTime={backgroundTasksElapsedTime}
          onDismissAllClick={onDismissAllClick}
        />
        <div
          className={cn("bottom-buttons", {
            "bottom-buttons-collapsed": collapsed,
          })}
        >
          {backgroundTasks && some(backgroundTasks, (x) => !x.isHidden) && (
            <ProgressDrawerButton
              tasks={backgroundTasks}
              onClick={showProgressDrawer}
              isCollapsed={collapsed || isCollapsedByUser}
            />
          )}
          <AccountSettingsMenu
            collapsed={collapsed}
            accountId={accountId}
            disableTooltip={disableTooltip}
            iconRef={iconRef}
            dropdownRef={dropdownRef}
            onSignout={onSignout}
          />
        </div>
      </nav>
      <main className="pushable">{children}</main>

      <FeedbackButton />
    </div>
  );
}

Navigation.defaultProps = {
  initiallyExpanded: true,
};

Navigation.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onSignout: PropTypes.func.isRequired,

  isWizardOpened: PropTypes.bool,
  isCollapsedByUser: PropTypes.bool,
  isProgressDrawerVisible: PropTypes.bool,
  showProgressDrawer: PropTypes.func,
  hideProgressDrawer: PropTypes.func,
  moboAccountName: PropTypes.string,
  moboAccountLogoUrl: PropTypes.string,
  sidebarActions: PropTypes.object,
  initiallyExpanded: PropTypes.bool,
  backgroundTasks: PropTypes.object,
  backgroundTasksElapsedTime: PropTypes.string,
  accountId: PropTypes.number,
};

export default Navigation;
