import { createAsyncThunk } from "@reduxjs/toolkit";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { FetchActionPayload } from "../../../../../interfaces/redux";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { getPrefix } from "../../../../Application/slices/models";
import { GetPacksRequest, PacksRequest } from "../../types/requests";
import { PackOverview } from "../../types/state";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const loadGridItemsCount: any = process.env.REACT_APP_LOAD_ITEMS_COUNT;

export const fetchOverviewGridPacks = createAsyncThunk<FetchActionPayload<PackOverview>, PacksRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "overviewGrid",
  }),
  async (requestData: PacksRequest, { signal }) => {
    const request: GetPacksRequest = {
      top: loadGridItemsCount,
      skip: requestData.skip,
      orderBy: requestData.orderBy,
      searchTerm: requestData.searchTerm,
    };

    const { getDataPromise, cancel } = dataService.packs.getPacksWithCancel(request, requestData.filters || {});

    signal.addEventListener("abort", cancel);

    const result = await getDataPromise;
    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: result.data,
      totalCount: recordsCount,
    };
  },
);
