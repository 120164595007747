import React, { memo, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import FlowContentView from "./contentView/FlowContentView";
import TriggerView from "./triggerView/TriggerView";
import DesignerErrorsView from "../DesignerErrorsView/DesignerErrorsView";
import SectionHeaderForm from "./sectionHeaderForm/SectionHeaderForm";

import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { toggleErrorMessagesBar as toggleErrorMessagesBarActionCreator } from "../validator/flowValidatorActionCreators";
import { useNavigation } from "./hooks/useNavigation";
import { Trigger, TriggersChanges } from "../types";
import { useSectionHeader } from "./hooks/useSectionHeader";
import { flowValidatorSelector } from "../../state/selectors";
import FlowEndPanel from "./flowEnd/FlowEndPanel";
import { useFlowEnd } from "./hooks/useFlowEnd";

import "./flowComponentsPanel.scss";

export interface Props {
  sourceTitle: string;
  triggerView: boolean;
  sectionHeaderView: string | undefined;
  flowEndView: string | undefined;
  triggers: Trigger[];
  deselectFlowItems: () => void;
  updateTriggerDataChanges: (changedData: TriggersChanges[]) => void;
  isReadOnly: boolean;
}

export function FlowComponentsPanel(props: Props & PropsFromRedux) {
  const {
    triggerView,
    sectionHeaderView,
    flowEndView,
    triggers,
    deselectFlowItems,
    updateTriggerDataChanges,
    sourceTitle,
    isReadOnly,
    showErrorMessagesBar,
    errorViewErrors,
    fixedErrors,
    toggleErrorMessagesBar,
  } = props;

  const { sectionHeader, onTitleChange, onDescriptionChange } = useSectionHeader(sectionHeaderView);
  const { flowEndInfo, onFlowEndChange } = useFlowEnd(flowEndView);

  const { navigateTo } = useNavigation(
    errorViewErrors,
    fixedErrors,
    triggerView,
    showErrorMessagesBar,
    !!sectionHeaderView,
  );

  useEffect(() => {
    return () => {
      toggleErrorMessagesBar(false);
    };
  }, [toggleErrorMessagesBar]);

  const renderView = () => {
    if (!!sectionHeaderView) {
      return (
        <SectionHeaderForm
          key={sectionHeaderView}
          sectionHeaderView={sectionHeaderView}
          sectionHeader={sectionHeader}
          isReadOnly={isReadOnly}
          onTitleChange={onTitleChange}
          onDescriptionChange={onDescriptionChange}
        />
      );
    }

    if (!!flowEndView) {
      return (
        <FlowEndPanel
          key={flowEndView}
          onBackButtonClick={deselectFlowItems}
          flowEndInfo={flowEndInfo}
          onFlowEndChange={onFlowEndChange}
          isReadOnly={isReadOnly}
        />
      );
    }

    if (triggerView) {
      return (
        <TriggerView
          title={sourceTitle}
          isReadOnly={isReadOnly}
          triggers={triggers}
          onBackButtonClick={deselectFlowItems}
          updateTriggerDataChanges={updateTriggerDataChanges}
        />
      );
    }
    if (showErrorMessagesBar) {
      return <DesignerErrorsView errorViewErrors={errorViewErrors} fixedErrors={fixedErrors} navigateTo={navigateTo} />;
    }
    return <FlowContentView />;
  };

  return <div className="flow-components-panel">{renderView()}</div>;
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const flowValidator = flowValidatorSelector(state);

  return {
    showErrorMessagesBar: flowValidator.showErrorMessagesBar,
    errorViewErrors: flowValidator.errorViewErrors,
    fixedErrors: flowValidator.fixedErrors,
  };
};
/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    toggleErrorMessagesBar: (visible: boolean) =>
      dispatch(toggleErrorMessagesBarActionCreator({ showErrorMessagesBar: visible })),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default memo(connector(FlowComponentsPanel));
