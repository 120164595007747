import { Table, Icon, Checkbox } from "semantic-ui-react";

import { SortingDirectionType } from "../../../../enums/sortingDirection";
import { ColumnOptions } from "../../../../interfaces";

export interface HeaderProps {
  columnOptions: ColumnOptions[];
  isAllItemsSelected: boolean;
  isAllItemsDisabled: boolean;
  sortingColumnName: string;
  className: string;
  hasPermission: boolean;
  onSelectAll: () => void;
  withSelection: boolean;
  onSort?: (columnName: string) => void;
  sortingDirection?: SortingDirectionType;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const {
    columnOptions,
    onSort,
    sortingColumnName,
    sortingDirection,
    onSelectAll,
    isAllItemsSelected,
    isAllItemsDisabled,
    withSelection,
    className,
    hasPermission,
  } = props;

  return (
    <Table.Header className={className}>
      <Table.Row key={1} className="list-view-row">
        {withSelection && (
          <Table.HeaderCell className="checkbox-cell">
            <Checkbox
              onChange={onSelectAll}
              checked={isAllItemsSelected}
              disabled={isAllItemsDisabled || !hasPermission}
            />
          </Table.HeaderCell>
        )}
        {columnOptions.map((c, index) => {
          let columnNameLowCase = c.name.toLowerCase();
          let sortingColumnNameLowCase = sortingColumnName && sortingColumnName.toLowerCase();
          let thClass = c.name && c.isSortable ? "hover-cell" : "";

          return (
            <Table.HeaderCell
              className={thClass}
              width={c.width}
              key={index}
              sorted={sortingColumnNameLowCase === columnNameLowCase && c.isSortable ? sortingDirection : undefined}
              onClick={c.isSortable ? onSort && onSort(columnNameLowCase) : null}
            >
              <div className={c.className}>
                {c.name}
                {c.renderIcon && c.renderIcon()}
                {c.isSortable && sortingColumnNameLowCase !== columnNameLowCase && <Icon name="sort" />}
              </div>
            </Table.HeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

export default Header;
