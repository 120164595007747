import React, { FC } from "react";

import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterFormPropsBase } from "../types/props";
import { getAssessmentFilters } from "./assessmentFilters";
import { AssessmentFiltersEnum } from "../../../features/Library/Assessments/types/state";
export interface AssessmentFilterFormForPacksProps extends FilterFormPropsBase<AssessmentFiltersEnum> {}

const AssessmentFilterFormForPacks: FC<AssessmentFilterFormForPacksProps> = ({
  filterOptions,
  filter,
  updateFilter,
}) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getAssessmentFilters(false)}
  />
);

export default AssessmentFilterFormForPacks;
