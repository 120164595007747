import { FormikProps } from "formik";
import { Component } from "react";

import { ValidatedForm } from "../../../../../../components/forms";
import { EmailFormProps, EmailFormState } from "../types";
import { EmailView } from "../../types/state";
import ExpandableLinks from "../../../../../../components/ExpandableItems/ExpandableLinks/ExpandableLinks";
import { RolePermissions } from "../../../../../../enums";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../../Application/globaltypes/redux";

export type EmailFormPropsAll = EmailFormProps & FormikProps<EmailView> & PropsFromRedux;
const utmCampaignField = "utmCampaign";

export class EmailForm extends Component<EmailFormPropsAll, EmailFormState> {
  onTitleBlur = (propertyName: string) => {
    const { values, setFieldValue, onBlur, touched } = this.props;
    if (values.id <= 0 && !touched[utmCampaignField]) {
      setFieldValue(utmCampaignField, values.title, true);
    }

    onBlur(propertyName);
  };

  render() {
    const { dirty, isLoading, disablePreventTransitionPrompt } = this.props;

    return (
      <ValidatedForm
        dirty={dirty}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message: "Are you sure you want to exit without saving this email? All information entered will be lost.",
        }}
        isLoading={isLoading}
        isSubmitting={isLoading}
        formWidthComputer={6}
        parentWithFormik
        disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      >
        {this.renderFields}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { tags, values, disabled, save, onBlur, onIsValidChange, userPermissions } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    return (
      <div className="email-form-container">
        <ValidatedForm.InputField
          label="Title"
          placeholder="Enter title"
          value={values.title}
          propertyName="title"
          markAsRequired
          onBlur={this.onTitleBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Subject Line"
          placeholder="Enter subject"
          propertyName="subject"
          markAsRequired
          value={values.subject}
          onBlur={onBlur}
          onIsFormValidChange={onIsValidChange}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Preview Text"
          value={values.previewText}
          placeholder="Enter preview text"
          propertyName="previewText"
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Software Application"
          propertyName="softwareApplications"
          className="software-applications"
          value={values.softwareApplications.selected}
          options={tags?.softwareApplications}
          onBlur={onBlur}
          onChanged={save}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Labels"
          propertyName="labels"
          value={values.labels.selected}
          options={tags?.labels}
          onBlur={onBlur}
          onChanged={save}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ExpandableLinks
          label="Associated Flows"
          linkFormatter={(linkId) => `/content/flows/${linkId}/items`}
          iconName="fa-code-branch"
          links={values.flows}
        />
        {userPermissions.includes(RolePermissions.PacksManage) &&
          !values.isPurchased &&
          !values.isDuplicatedFromPurchased && (
            <ValidatedForm.CheckboxField
              toggle
              label={"Allow customers to duplicate this email?"}
              value={values.isPurchasedDuplicationAllowed}
              propertyName={"isPurchasedDuplicationAllowed"}
              disabled={disabled}
              onChanged={save}
              {...validatedFieldProps}
            />
          )}
        <ValidatedForm.InputField
          label="Campaign Name"
          placeholder="Campaign Name"
          value={values.utmCampaign}
          propertyName="utmCampaign"
          markAsRequired
          onBlur={onBlur}
          disabled={disabled}
          tooltip={{
            info: "Sets the campaign name for tracking in analytics. Defaults to your email title but can be customized.",
            width: 20,
          }}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, dirty, resetForm } =
      this.props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange: handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  userPermissions: state.userProfile.permissions,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmailForm);
