import { open } from "./consentWindowService";
import integrationsDataService from "../../services/integrationsDataService";
import { WebexIntegrationConfigs, IntegrationTypes } from "../types";
import IConnector from "./IConnector";

export class WebexConnector implements IConnector<WebexIntegrationConfigs> {
  private _authenticationCode: string = "";
  private _redirect: string | undefined = undefined;
  private readonly _isConnected: boolean;

  constructor(isConnected: boolean) {
    this._isConnected = isConnected;
  }

  public get info() {
    return {
      id: IntegrationTypes.Webex,
      connectorTitle: "Webex",
      type: IntegrationTypes.Webex,
      description: "Webex",
      thumbnailUrl: "/connectors/webex.png",
      isConnected: this._isConnected,
    };
  }

  public get configuration() {
    return {
      isActive: false,
      syncUsers: true,
      importUsageData: true,
      userNotificationId: "",
    };
  }

  readonly shouldLogin = () => true;

  readonly shouldNotify = () => true;

  readonly login = async (config: WebexIntegrationConfigs) => {
    const { syncUsers, importUsageData } = config;
    const redirect = `${window.location.origin}/consent`;
    const { redirectLocation } = await integrationsDataService.authorizeWebexIntegration(
      syncUsers,
      importUsageData,
      redirect,
    );

    const authResult = await open({
      location: redirectLocation,
      title: "Webex Sign In",
      parseResult: (params) => {
        const code = params.get("code") as string;
        return {
          isSuccess: !!code,
          error: params.get("error"),
          result: code,
          redirect: redirect,
        };
      },
    });
    this._authenticationCode = authResult.result;
    this._redirect = authResult.redirect;
    return authResult;
  };

  readonly updateWebexWithAuthentication = async (
    originConfigs: WebexIntegrationConfigs,
    updatedConfigs: WebexIntegrationConfigs,
    moboId?: string,
  ) => {
    const resultConfig = { ...updatedConfigs };
    if (
      originConfigs.syncUsers !== updatedConfigs.syncUsers ||
      originConfigs.importUsageData !== updatedConfigs.importUsageData
    ) {
      const result = await this.login(updatedConfigs);
      if (!result.isSuccess) {
        return { isSuccess: false };
      }
      resultConfig.redirect = result.redirect;
      resultConfig.authenticationCode = result.result;
    }
    await integrationsDataService.updateIntegrationConfigs(IntegrationTypes.Webex, resultConfig, moboId);
    return { isSuccess: true };
  };

  readonly save = async (config: WebexIntegrationConfigs, moboId?: string) => {
    await integrationsDataService.createWebexIntegration(
      {
        ...config,
        redirect: this._redirect,
        authenticationCode: this._authenticationCode,
      },
      moboId,
    );
  };
}
