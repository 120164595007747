import { PriorityLevel } from "../../../interfaces";
import { PriorityLevelThumbnail } from "./PriorityLevelThumbnail";
import { PriorityLevel as PriorityLevelCommon, PriorityLevelProps } from "./PriorityLevel";

import "./priorityLevel.scss";

const PriorityLevelWithThumbnail = <T extends PriorityLevel>(props: PriorityLevelProps<T>) => {
  const { thumbnailUrl, title } = props.item;

  return (
    <div id="priority-level-item" className="priority-level-item priority-level-with-thumbnail">
      <PriorityLevelThumbnail title={title} thumbnailUrl={thumbnailUrl} />
      <PriorityLevelCommon {...props} />
    </div>
  );
};

export default PriorityLevelWithThumbnail;
