const POLL_INTERVAL_MS: number = 50;
const W_Height: number = 800;
const W_Width: number = 800;

export interface ConsentResult {
  isSuccess: boolean;
  result: any;
  error: any | null;
  redirect?: string;
}

export interface ConsentWindowParams {
  title: string;
  location: string;
  parseResult: (resultUrl: URLSearchParams) => ConsentResult;
}

export const open = async (params: ConsentWindowParams): Promise<ConsentResult> => {
  try {
    const popup = window.open(params.location, params.title, `width=${W_Width},height=${W_Height},scrollbars=yes`);
    return await monitorPopupStatus(popup, params.parseResult);
  } catch (error) {
    return {
      isSuccess: false,
      error,
    } as ConsentResult;
  }
};

const monitorPopupStatus = (
  popup: any,
  parseResult: (resultUrl: URLSearchParams) => ConsentResult,
): Promise<ConsentResult> => {
  return new Promise((resolve, reject) => {
    if (!popup) {
      reject();
      return;
    }
    const intervalId = setInterval(() => {
      if (popup.closed) {
        clearInterval(intervalId);
        reject();
        return;
      }

      let href = "";
      try {
        href = popup.location.href;
      } catch (e) {}

      if (href === "" || href === "about:blank") {
        return;
      }

      const urlSearchParams = new URLSearchParams(popup.location.search);
      const result = parseResult(urlSearchParams);

      resolve(result);
      close();
    }, POLL_INTERVAL_MS);

    const close = () => {
      popup.close();
      clearInterval(intervalId);
    };
  });
};
