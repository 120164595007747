import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  EndpointsToGetSasLink,
  FileStoragePermissionTypes,
  ReducerEntityPrefixTypes,
  ReducerNamespaceTypes,
} from "enums";
import { AppThunk } from "features/Application/globaltypes/redux";
import usersDataService from "features/Application/services/dataServices/usersDataService";
import { getPrefix } from "features/Application/slices/models";
import { UserPreviewMappingPayload } from "features/People/types";
import { FetchActionPayload, SasConfig } from "interfaces";
import fileUtils, { AcceptCancelationHandler, OnUploadProgressHandler } from "utils/fileUtils";
import {
  fetchBegin as fetchPreviewBegin,
  fetchFailure as fetchPreviewFailure,
  fetchSuccess as fetchPreviewSuccess,
} from "../slices/csvImport/csvImportPreviewSlice";
import { uploadBegin, uploadFail, uploadSuccess } from "../slices/csvImport/uploadCsvFileSlice";

export const uploadCsvFile =
  (
    accountId: number,
    actorId: number,
    contentType: string,
    file: File,
    onUploadProgressHandler: OnUploadProgressHandler,
    acceptCancellationHandler: AcceptCancelationHandler,
  ): AppThunk =>
  async (dispatch) => {
    const fileStoragePermissions = [FileStoragePermissionTypes.Write, FileStoragePermissionTypes.Read];
    const sasConfig: SasConfig = {
      endpointToGetSasLink: EndpointsToGetSasLink.People.CsvImport,
      sasRequest: {
        permissions: fileStoragePermissions,
      },
    };
    const uploadOptions = {
      contentType: contentType,
      metadata: {
        account_id: String(accountId),
        actor_id: String(actorId),
      },
      blockSize: file.size / 5,
      parallelism: 1,
    };

    try {
      dispatch(uploadBegin());
      const uploadResult = await fileUtils.uploadFileToBlobStorage(
        file,
        uploadOptions,
        onUploadProgressHandler,
        acceptCancellationHandler,
        sasConfig,
      );
      dispatch(uploadSuccess());
      return uploadResult;
    } catch (e) {
      const error = e as Error;
      dispatch(uploadFail(error));
    }
  };

export const fetchUploadedFileColumns = createAsyncThunk<FetchActionPayload<string>, string>(
  getPrefix({
    entity: ReducerEntityPrefixTypes.AccountUploadCsvFile,
    name: "CsvColumns",
    namespace: ReducerNamespaceTypes.Accounts,
  }),
  async (fileName: string) => {
    const columns = await usersDataService.getUploadedFileColumns(fileName);
    return {
      items: columns.data,
      totalCount: columns.data.length,
    };
  },
);

export const fetchUsersImportPreviewData =
  (fileName: string, itemsCount: number, mappedFields: UserPreviewMappingPayload[]): AppThunk =>
  async (dispatch) => {
    dispatch(fetchPreviewBegin());
    try {
      const { data } = await usersDataService.getUsersImportPreviewData(fileName, itemsCount, mappedFields);
      dispatch(
        fetchPreviewSuccess({
          items: data.items,
          totalCount: data.count,
        }),
      );
    } catch (e) {
      dispatch(fetchPreviewFailure(e as Error));
    }
  };
