import Form from "components/forms/layout";
import { useFormik } from "formik";
import { noop } from "lodash";
import { AccountName } from "./AccountName";
import { WizardNew, Section } from "components/wizard";
import { AccountInfoLayout } from "./AccountInfoLayout";
import { ImagePreview } from "components/forms";
import ImageAspectRatio from "enums/imageAspectRatio";
import { EndpointsToGetSasLink, RouteNames } from "enums";
import ValidatedField from "components/forms/ValidatedField";
import { useBackNavigation } from "utils/navigationUtils";

interface Props {
  onConfigured: (account: { name: string; id: number; isNew?: boolean; logoUri: Nullable<string> }) => void;
}

export const Preconfiguration = (props: Props) => {
  const { onConfigured } = props;
  const back = useBackNavigation(RouteNames.linkedAccountsManagement);

  const formik = useFormik({
    initialValues: {
      logoUri: null,
    },
    onSubmit: noop,
  });
  return (
    <WizardNew
      id="create-account-wizard"
      className="create-account"
      title="Create Account"
      finishButtonLabel="Finish"
      isSaveInProgress={false}
      onCancel={back}
    >
      <Section label="Configuration" required isLocked>
        <Form.Root>
          <Form.Block title="Account Details">
            <AccountInfoLayout
              image={
                <ImagePreview
                  value={formik.values.logoUri}
                  propertyName={"logoUri"}
                  label="Logo"
                  placeholder="Logo"
                  endpointToGetSasLink={EndpointsToGetSasLink.Account}
                  isHidden={true}
                  aspectRatio={ImageAspectRatio.SQUARE_1X1}
                  shouldValidate={false}
                  {...formik}
                />
              }
              name={
                <ValidatedField propertyName="" markAsRequired label="Company Name">
                  <AccountName
                    onAccountSelected={(acc) => {
                      onConfigured({ ...acc, logoUri: acc.isNew ? formik.values.logoUri : acc.logoUri });
                    }}
                  />
                </ValidatedField>
              }
            />
          </Form.Block>
        </Form.Root>
      </Section>
      <Section label="Contacts" isLocked required />
      <Section label="Packs" isLocked />
      <Section label="Add-ons" isLocked />
      <Section label="Notify" isLocked />
    </WizardNew>
  );
};
