import { FC, useMemo } from "react";

import { DropdownWrapper } from "../../dropdowns";
import { PriorityLevels } from "../../../enums";
import { getPriorityLevels } from "../priorityLevel/priorityLevel";

import "./priorityLevelDropdown.scss";

interface Props {
  priorityLevel?: PriorityLevels;
  onChange?: (priorityLevel: PriorityLevels) => void;
  disableDefault?: boolean;
}

const PriorityLevelDropdown: FC<Props> = ({ priorityLevel = PriorityLevels.none, onChange, disableDefault }) => {
  const options = useMemo(() => {
    return getPriorityLevels(disableDefault);
  }, [disableDefault]);
  return (
    <DropdownWrapper
      blur
      items={options}
      id="priority-level-dropdown"
      className="priority-level-dropdown"
      selection
      value={priorityLevel}
      handleOptionChange={(e, data) => onChange?.(data.value as PriorityLevels)}
    />
  );
};

export default PriorityLevelDropdown;
