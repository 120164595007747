import { isEmpty } from "lodash";

import dataService from "../../../../Application/services/dataServices/typedDataService";
import * as userAssignmentActionTypes from "../actionTypes/userAssignmentActionTypes";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import { Dispatcher } from "../../../../../interfaces/redux";
import { FiltersMap } from "../../../../../utils/filterUtils";

const fetchUsersToBeAssignedBegin = makeActionCreator(
  userAssignmentActionTypes.LIBRARY_USER_ASSIGNMENTS_FETCH_USERS_BEGIN,
);
const fetchUsersToBeAssignedSuccess = makeActionCreator(
  userAssignmentActionTypes.LIBRARY_USER_ASSIGNMENTS_FETCH_USERS_SUCCESS,
  "items",
  "itemsCount",
);
const fetchUsersToBeAssignedFailure = makeActionCreator(
  userAssignmentActionTypes.LIBRARY_USER_ASSIGNMENTS_FETCH_USERS_FAILURE,
  "error",
);

export const fetchUsersToBeAssigned = (
  skip: number,
  top: number,
  orderByParams: string | null,
  filter: FiltersMap,
  search: string,
): ((dispatch: Dispatcher) => Promise<void>) => {
  return async (dispatch: Dispatcher) => {
    dispatch(fetchUsersToBeAssignedBegin());
    try {
      const { items, count } = isEmpty(search)
        ? await dataService.users.getUsers(skip, top, orderByParams as string, filter)
        : await dataService.users.getUsersV2(skip, top, orderByParams as string, filter, search);
      dispatch(fetchUsersToBeAssignedSuccess(items, count));
    } catch (error) {
      dispatch(fetchUsersToBeAssignedFailure(error));
    }
  };
};

export const resetUsersToBeAssigned = makeActionCreator(
  userAssignmentActionTypes.LIBRARY_USER_ASSIGNMENTS_RESET_USERS_TO_BE_ASSIGNED,
);
export const setFilter = makeActionCreator(
  userAssignmentActionTypes.LIBRARY_USER_ASSIGNMENTS_SET_ITEMS_FILTER,
  "filter",
);
export const resetFilter = makeActionCreator(userAssignmentActionTypes.LIBRARY_USER_ASSIGNMENTS_RESET_ITEMS_FILTER);

export const setSearch = makeActionCreator(userAssignmentActionTypes.LIBRARY_USER_ASSIGNMENTS_SET_SEARCH, "search");
export const resetSearch = makeActionCreator(userAssignmentActionTypes.LIBRARY_USER_ASSIGNMENTS_RESET_SEARCH);
