import { combineReducers, Reducer } from "redux";
import { InfoState } from "../types";
import { flowValidatorReducer } from "../Designer/validator/flowValidatorReducer";

import flowEntityStateReducer from "./reducers/flowEntityStateReducer";
import infoReducer from "./reducers/infoReducer";
import flowBaseSlice from "./slices/flowBaseSlice";
import flowUserFiltersSlice from "./slices/flowUserFiltersSlice";
import flowLicensingSlice from "./slices/flowLicensingSlice";
import flowDesignerReducer from "../Designer/state/flowDesignerReducer";
import flowsOverviewSlice from "./slices/flowOverviewSlice";
import flowUsersToAddSlice from "./slices/flowUsersToAddSlice";
import flowGroupsToAddSlice from "./slices/flowGroupsToAddSlice";
import flowUsersSlice from "./slices/flowUsersSlice";
import flowGroupsSlice from "./slices/flowGroupsSlice";
import flowPerformanceSlice from "./slices/flowPerformanceSlice";
import flowFiltersSlice from "./slices/flowFiltersSlice";
import flowGoalSlice from "./slices/flowGoalSlice";

const base = combineReducers({
  entity: flowEntityStateReducer,
  information: infoReducer as Reducer<InfoState>,
  validation: flowValidatorReducer,
  designer: flowDesignerReducer,
  // TO-DO: split this slice on tagsSlice and externalTriggersSlice
  flowBase: flowBaseSlice,
  licensing: flowLicensingSlice,
  performance: flowPerformanceSlice,
  goal: flowGoalSlice,
});

const overview = combineReducers({
  flowOverview: flowsOverviewSlice,
  filters: flowFiltersSlice,
});

const edit = combineReducers({
  userFilters: flowUserFiltersSlice,
  users: flowUsersSlice,
  groups: flowGroupsSlice,
  items: combineReducers({
    usersToAdd: flowUsersToAddSlice,
    groupsToAdd: flowGroupsToAddSlice,
  }),
});

const reducers = combineReducers({
  base,
  edit,
  overview,
});

export type FlowState = ReturnType<typeof reducers>;

export default reducers;
