import { timeFormat } from "d3";

const SMALL = 300;
const LARGE = 10000;

export function getDateFormatByCount(count: number) {
  if (count < SMALL) {
    return timeFormat("%b %d");
  }
  if (count < LARGE) {
    return timeFormat("%b %y");
  }
  return timeFormat("%Y");
}

export enum TickValueType {
  IntegersOnly,
}

export function getTicksFromValueTypes(scale: any, tickValueType?: TickValueType, tickCount?: number) {
  if (tickValueType === TickValueType.IntegersOnly) {
    return scale.ticks(tickCount).filter(Number.isInteger);
  }

  return null;
}

type Params = (domainValue: d3.AxisDomain, index: number) => string;
export function getFormatFromValueTypes(tickValueType: undefined): null;
export function getFormatFromValueTypes(tickValueType: TickValueType): Params;
export function getFormatFromValueTypes(tickValueType?: TickValueType): Params | null {
  // Use default d3 tick formatting
  if (tickValueType === undefined) {
    return null;
  }

  return function (axisValue: d3.AxisDomain): string {
    let currentString = axisValue.valueOf();
    if (tickValueType === TickValueType.IntegersOnly) {
      return (+currentString).toLocaleString();
    }

    return currentString.toLocaleString();
  };
}
