import * as actionTypes from "../actions/generalLoader/generalLoaderActionTypes";

const initialState = {
  showGeneralLoader: false,
};

const generalLoaderReducer = (currentState, action) => {
  const state = currentState || initialState;

  switch (action.type) {
    case actionTypes.SHOW_GENERAL_LOADER:
      return {
        ...state,
        showGeneralLoader: true,
      };

    case actionTypes.HIDE_GENERAL_LOADER:
      return {
        ...state,
        showGeneralLoader: false,
      };

    default:
      return state;
  }
};

export default generalLoaderReducer;
