import _, { once } from "lodash";
import React, { useMemo } from "react";
import momentTZ from "moment-timezone";
import { mappings } from "./TimezoneMappings";
import { DropdownItemProps } from "semantic-ui-react";
import Dropdown, { DropdownFieldProps } from "../Dropdown";
import ObjectUtils from "utils/objectUtils";

import styles from "./timezoneDropdown.module.scss";

interface TimezoneMappingOptions {
  name: string;
  description: string;
  offset: string;
  abbr: string | undefined;
  iana: string[];
}

interface TimezoneItemProps {
  text: string;
  offset: string;
  icon?: React.ReactElement;
}

function getOffset(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const remainderMinutes = Math.abs(minutes % 60);

  const sign = hours >= 0 ? "+" : "";

  if (remainderMinutes !== 0) {
    return `${sign}${hours}:${remainderMinutes}`;
  } else {
    return `${sign}${hours}`;
  }
}

type DropdownItem = DropdownItemProps & { filterer: string };

const filter = (items: DropdownItemProps[], query: string) => {
  const strippedQuery = _.deburr(query);

  const re = new RegExp(_.escapeRegExp(strippedQuery), "i");

  return _.filter(items as DropdownItem[], (opt) => re.test(_.deburr(opt.filterer)));
};

const TimezoneItem = React.memo((props: TimezoneItemProps) => (
  <div className={styles.item}>
    <div className={styles.icon}>{props.icon}</div>
    <span className={styles.text}>{props.text}</span>
    <span className={styles.abbr}>GMT {props.offset}</span>
  </div>
));

const initializer = once(() => {
  const result: TimezoneMappingOptions[] = [];
  for (const entry of ObjectUtils.typedEntries<string, string[]>(mappings)) {
    const [description, iana] = entry;
    const name = iana[0];

    const tz = momentTZ.tz(name);
    const abbr = tz.zoneAbbr();
    const props = {
      name: name,
      description: description,
      offset: getOffset(tz.utcOffset()),
      abbr: /^[A-Z]+$/.test(abbr) ? abbr : undefined,
      iana,
    };
    result.push(props);
  }
  return result;
});

const getDropdownItems = (timezones: TimezoneMappingOptions[], icon?: React.ReactElement): DropdownItem[] =>
  timezones.map((timezone: TimezoneMappingOptions) => {
    const abbr = timezone.abbr ? `(${timezone.abbr})` : "";
    const text = `${timezone.description} ${abbr}`;
    const name = timezone.iana[0];

    return {
      filterer: `${timezone.description}|${timezone.abbr}|${timezone.offset}|${timezone.iana.join("|")}`,
      key: name,
      text: <TimezoneItem text={text} offset={timezone.offset} icon={icon} />,
      value: name,
      content: <TimezoneItem text={text} offset={timezone.offset} />,
    };
  });

interface Props extends Omit<DropdownFieldProps, "options" | "search"> {
  icon?: React.ReactElement;
}

export const TimezoneDropdown = (props: Props) => {
  const mappedTimezones = useMemo(() => {
    return initializer();
  }, []);

  const values = useMemo(() => {
    return getDropdownItems(mappedTimezones, props.icon);
  }, [mappedTimezones, props.icon]);

  return (
    <div className={styles.root}>
      <Dropdown lazyLoad options={values} search={filter} {...props} />
    </div>
  );
};
