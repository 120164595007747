import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import cn from "classnames";
import { Button } from "../button/Button";

import "./doubleButton.scss";

function LeftButton(props) {
  const { onClick, className, iconName, disabled, ...otherProps } = props;

  return (
    <Button
      blur
      basic
      className={cn("double-button", "left", className)}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      <Icon className={cn(iconName)} size="large" />
    </Button>
  );
}

LeftButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default LeftButton;
