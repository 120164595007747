import React, { useState } from "react";
import { Icon } from "semantic-ui-react";
import { Swiper, Controller, Navigation, Pagination } from "swiper";
import { Swiper as Carousel, SwiperSlide } from "swiper/react";
import { MediaItem } from "../../../features/Licensing/Packs/types/state";
import useToggle from "../../../hooks/useToggle";
import CardThumbnail from "../../cards/cardThumbnail/CardThumbnail";
import { OverlayModal } from "../../modal/overlayModal/OverlayModal";
import "./mediaItemsCarousel.scss";

const prevButtonClass = "swiper-button-prev";
const nextButtonClass = "swiper-button-next";
const paginationClass = "swiper-pagination";
export interface MediaItemsCarouselProps {
  items: MediaItem[];
}

export const MediaItemsCarousel: React.FC<MediaItemsCarouselProps> = ({ items }) => {
  const [openOverlay, toggleOpenOverlay] = useToggle(false);
  const [mainSwiper, setMainSwiper] = useState<Swiper>();

  const renderSlideContent = (item: MediaItem) => <CardThumbnail thumbnailUrl={item.url}></CardThumbnail>;

  const renderOverlaySwiper = () => (
    <Carousel
      className="overlay-swiper"
      modules={[Navigation, Pagination, Controller]}
      spaceBetween={60}
      slidesPerView={1}
      navigation={{ nextEl: `.${nextButtonClass}`, prevEl: `.${prevButtonClass}` }}
      centeredSlides={true}
      initialSlide={mainSwiper?.activeIndex}
      controller={{ control: mainSwiper }}
      data-testid="overlay-swiper"
    >
      <div className={prevButtonClass} />
      {items.map((value, index) => (
        <SwiperSlide key={index}>{renderSlideContent(value)}</SwiperSlide>
      ))}
      <div className={nextButtonClass} />
    </Carousel>
  );

  return (
    <div className="carousel-container">
      <Carousel
        className="main-swiper"
        modules={[Navigation, Pagination, Controller]}
        spaceBetween={16}
        slidesPerView={2.28}
        navigation={{ nextEl: `.${nextButtonClass}`, prevEl: `.${prevButtonClass}` }}
        pagination={{ clickable: true, el: `.${paginationClass}`, type: "bullets" }}
        grabCursor={true}
        slideToClickedSlide={true}
        centeredSlides={true}
        watchOverflow={false}
        onSwiper={setMainSwiper}
      >
        <div className={prevButtonClass}>
          <Icon className={`fa-chevron-left`} size={"large"} />
        </div>
        {items.map((value, index) => (
          <SwiperSlide key={index} onClick={toggleOpenOverlay} data-testid={`slide#${index}`}>
            {renderSlideContent(value)}
          </SwiperSlide>
        ))}
        <div className={nextButtonClass}>
          <Icon className={`fa-chevron-right`} size={"large"} />
        </div>
        <div className={paginationClass} />
      </Carousel>
      <OverlayModal open={openOverlay} children={renderOverlaySwiper()} onClose={toggleOpenOverlay} />
    </div>
  );
};
