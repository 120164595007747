export type SortingDirectionType = "ascending" | "descending";

export enum SortingDirection {
  Ascending = "asc",
  Descending = "desc",
}

export enum SortingDirectionLong {
  Ascending = "ascending",
  Descending = "descending",
}

export const sortingDirectionsLong = {
  asc: SortingDirectionLong.Ascending,
  desc: SortingDirectionLong.Descending,
};
