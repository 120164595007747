import dataService, { DataService } from "../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/eventsOverviewActionTypes";
import { getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { FetchActionPayload } from "../../../../../interfaces";
import { EventOverview, FetchEventsActionPayload, eventFilterTypes } from "../../types/state";
import { SortingDirection } from "../../../../../enums";
import { Filters } from "../../../../../utils/queryUtils";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { pluralize } from "../../../../../utils/stringUtils";
import UpdateParams, { DistributedOpUpdateParams } from "../../../../../interfaces/updateParams";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";
import { formatFilters } from "utils/filterMapUtils";
import { escapeTerm } from "utils/searchUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const takeDefaultCount = Number.parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);

type GetEventsArguments = {
  lazy: boolean;
  term?: string;
  take?: number;
  skip?: number;
  sortColumn?: string;
  sortDirection?: SortingDirection;
  filters?: Filters;
};

export const getEvents = (args: GetEventsArguments) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { lazy, term, take, skip, sortColumn, sortDirection, filters } = args;
  const begin = getActionProvider<boolean>(actionTypes.getEventsBegin);
  const success = getActionProvider<FetchActionPayload<EventOverview>>(actionTypes.getEventsSuccess);
  const failure = getActionProvider<Error>(actionTypes.getEventsFailure);

  const skipItems = skip || 0;

  dispatch(begin(lazy));

  const request = {
    top: take || takeDefaultCount,
    skip: skipItems,
    sortBy: sortColumn,
    term: escapeTerm(term),
    sortOrder: sortDirection,
    filters: filters && formatFilters(filters, eventFilterTypes),
    showPurchased: true,
  };

  try {
    const result = await dataService.content.getContent({ ...request, contentType: "externalevent" });

    const formattedData: EventOverview[] = result.data.map((event) => ({
      ...event,
      thumbnailUrl: event.bag.thumbnailUrl,
    }));

    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    const data: FetchEventsActionPayload<EventOverview> = {
      items: formattedData,
      totalCount: recordsCount,
      concatItems: lazy && skipItems !== 0,
    };

    dispatch(success(data));
  } catch (error: any) {
    dispatch(failure(error));
  }
};

export const resetEvents = () => ({
  type: actionTypes.resetEvents,
});

export const duplicateEvents = (ids: number[], onCompleted: () => void) => async (dispatch: AppDispatch) => {
  const getOperationProps = async () => {
    const { data } = await DataService.events.duplicateEventsAsync(ids);
    return data;
  };

  const idsCount: number = ids.length;
  const bannerPrefix = pluralize("Event", idsCount);

  const updateParams: DistributedOpUpdateParams = {
    id: "DuplicateEvents",
    title: `Duplication of events`,
    onCompleted,
    getOperationProps,
    successTransientMessage: `${bannerPrefix} ${idsCount === 1 ? "has" : "have"} been duplicated!`,
    failureTransientMessage: `${bannerPrefix} duplicate failed!`,
  };

  await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
};

export const deleteEvents = (ids: number[], onCompleted: () => void) => async (dispatch: AppDispatch) => {
  const getMessageIds = async () => {
    const { data } = await DataService.events.deleteEvents(ids);
    return data.messageIds;
  };

  const idsCount = ids.length;
  const bannerPrefix = pluralize("Event", idsCount);

  const updateParams: UpdateParams = {
    id: "DeleteEvents",
    title: `Deletion of events`,
    onCompleted,
    getMessageIds,
    successTransientMessage: `${bannerPrefix} ${idsCount === 1 ? "has" : "have"} been deleted!`,
    failureTransientMessage: `${bannerPrefix} delete failed!`,
  };

  await backgroundTask.initializeBackgroundTask(updateParams, dispatch);
};
