import { Dispatch } from "@reduxjs/toolkit";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/integrationPacksSlice";
import DataService from "../../../../Application/services/dataServices/typedDataService";

export const fetchPacksAction = (moboId?: string) => async (dispatch: Dispatch) => {
  dispatch(fetchBegin());
  try {
    const result = (await DataService.packs.getPacksFilterOptions("flow", true, moboId)).data;
    const packsMap = result.map((item) => ({ text: item.name, value: item.id }));
    dispatch(fetchSuccess({ items: packsMap, totalCount: result.length }));
  } catch (error) {
    dispatch(fetchFailure(error as Error));
  }
};
