import { Dispatch } from "@reduxjs/toolkit";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import surveysDataService from "../../services/surveysDataService";
import { setTags, setError } from "../slices/surveyCommonSlice";
import { getBatchAddEntityTagsThunk } from "../../../Common/thunkBuilders/batchAddEntityTagsThunkBuilder";

export const fetchSurveysTags = () => async (dispatch: Dispatch) => {
  try {
    const res = await surveysDataService.getSurveysTagsAsync(true);
    dispatch(setTags(res.data));
  } catch (e) {
    dispatch(setError(e as Error));
  }
};

export const fetchSurveyDependencies = (id: number) => async () => {
  try {
    const res = await surveysDataService.getSurveyDependenciesAsync(id);
    return res.data;
  } catch (e) {}
};

const getMessageIds = (surveyIds: number[], tags: string[], tagType: TagsEnum) => async () => {
  const response = await surveysDataService.addSurveyTags({ ids: surveyIds, tags, tagType });
  return response?.data.messageIds;
};

export const addSurveyTags = getBatchAddEntityTagsThunk("Survey", getMessageIds);
