import { PropTypes } from "prop-types";

import GroupSettingsToggleButton from "./GroupSettingsToggleButton/GroupSettingsToggleButton";

import "./groupSettings.scss";

function GroupSettings(props) {
  const {
    isAware,
    isVisibleToAll,
    onIsAwareChanged,
    onVisibleToAllChanged,
    isWithAutoEnroll,
    onIsWithAutoEnrollChanged,
    disableEnrollmentEdit,
    disabled,
    autoEnroll,
  } = props;

  const unavailableEnrollOption = isWithAutoEnroll ? "manual" : "automatic";

  return (
    <>
      <GroupSettingsToggleButton
        key={`Enrollment_${isWithAutoEnroll}`}
        label="Enrollment"
        labelTooltip="Allows people to be added to this group automatically based on a criteria you define (e.g. Department, Job Title, etc.)."
        disabledTooltip={`Enrollment cannot be changed to ${unavailableEnrollOption} once the group has been created. Create a new group instead.`}
        hint="Define criteria to add people automatically."
        checked={isWithAutoEnroll}
        onChange={onIsWithAutoEnrollChanged}
        disabled={disableEnrollmentEdit || disabled || autoEnroll}
      />
      <GroupSettingsToggleButton
        label="Aware"
        labelTooltip={`Enabling this setting allows users to see they are a member of this group, receive group notifications, and interact with the group. Disabling this setting allows admins to manage the group without group member knowledge.`}
        hint="Allow users to be aware they belong to this group."
        checked={isAware}
        onChange={onIsAwareChanged}
        disabled={disableEnrollmentEdit || disabled}
      />
      {isAware && (
        <div className="subsettings">
          <GroupSettingsToggleButton
            key={`Visibility_${isVisibleToAll}`}
            label="Visibility"
            labelTooltip="Enabling this option makes the group visible to all users in the End-User Portal and users may request to join this group. If disabled, only group members will see the group."
            hint="Make group visible to all users in the End-User Portal."
            checked={isVisibleToAll}
            onChange={onVisibleToAllChanged}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
}

GroupSettings.defaultProps = {
  disableEnrollmentEdit: false,
};

GroupSettings.propTypes = {
  isAware: PropTypes.bool,
  isWithAutoEnroll: PropTypes.bool,
  isVisibleToAll: PropTypes.bool,
  isOpen: PropTypes.bool,
  onIsAwareChanged: PropTypes.func,
  onIsWithAutoEnrollChanged: PropTypes.func,
  onVisibleToAllChanged: PropTypes.func,
  onIsOpenChanged: PropTypes.func,
  disableEnrollmentEdit: PropTypes.bool,
  disabled: PropTypes.bool,
  autoEnroll: PropTypes.bool,
};

export default GroupSettings;
