import { FC } from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import styles from "./link.module.scss";

interface Props {
  to: string;
  text: string;
  openInNewTab?: boolean;
}

export const Link: FC<Props> = ({ to, text, openInNewTab = true }) => {
  return (
    <ReactRouterLink to={to} className={styles.link} {...(openInNewTab ? { target: "_blank" } : {})}>
      {text}
    </ReactRouterLink>
  );
};
