import PriorityLevelItemsBlock from "../../../../priorityLevels/priorityLevelItemsBlock/priorityLevelItemsBlock";
import { ModalWithStepsStep } from "../../../../modal/ModalWithSteps";
import { PriorityLevel } from "../../../../../interfaces";

import "./priorityLevelsStep.scss";
import { PeopleType } from "features/Library/PeopleAssignments/types";

interface PriorityLevelsStepProps extends ModalWithStepsStep {
  peopleType: PeopleType;
  priorityItems: PriorityLevel[];
  onIsDataValidChange: Function;
  onItemsUpdate: Function;
}

function PriorityLevelsStep(props: PriorityLevelsStepProps) {
  const { priorityItems, onItemsUpdate, onIsDataValidChange, peopleType } = props;

  return (
    <div className="priority-levels-step">
      <PriorityLevelItemsBlock
        peopleType={peopleType}
        items={priorityItems}
        onItemsUpdate={onItemsUpdate}
        onIsDataValidChange={onIsDataValidChange}
      />
    </div>
  );
}

export default PriorityLevelsStep;
