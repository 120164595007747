import { SyntheticEvent, useCallback, useEffect } from "react";
import { Props as LicensesStepProps } from "../LicensesStep";
import SortOptions from "../../../../../../enums/SortOptions";
import { DropdownProps } from "semantic-ui-react";
import { useRequestOptions } from "./useRequestOptions";
import { useSorting } from "./useSorting";
import { useSearch } from "./useSearch";
import { useLoadedItemsCount } from "./useLoadedItemsCount";

interface GridLoader {
  fetchPackGrid(skip?: number): void;
}

interface SearchResult {
  term: string;
  onChange(term: string): void;
}

interface SortResult {
  value: SortOptions;
  onChange(event: SyntheticEvent<HTMLElement>, props: DropdownProps): void;
}

export interface HookResult extends GridLoader {
  search: SearchResult;
  sorting: SortResult;
}

export function useLicensesGrid({ packsGrid, accountId, fetchLicenses, resetPacks }: LicensesStepProps): HookResult {
  const loadedItemsCountValue = useLoadedItemsCount(packsGrid.items.length);
  const [sorting, onSortingChange] = useSorting({ resetPacks });
  const [searchTerm, onSearchChange] = useSearch({ resetPacks });
  const opts = useRequestOptions({
    accountId,
    sorting,
    searchTerm,
    loadedItemsCount: packsGrid.items.length,
  });

  const loadPacksGrid = useCallback(
    (skip?: number) => {
      // There is only one case where the skip value is undefined.
      // This case is when fetching items during lazy loading.
      // In this case, use the length of the loaded items for paging.
      const skipValue = skip ?? loadedItemsCountValue.current;
      fetchLicenses({ ...opts, skip: skipValue });
    },
    [fetchLicenses, opts, loadedItemsCountValue],
  );

  useEffect(() => {
    loadPacksGrid(0);
    // load packs every time the sort or search changes
  }, [loadPacksGrid]);

  useEffect(() => {
    return () => {
      resetPacks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    search: {
      term: searchTerm,
      onChange: onSearchChange,
    },
    fetchPackGrid: loadPacksGrid,
    sorting: {
      value: sorting,
      onChange: onSortingChange,
    },
  };
}
