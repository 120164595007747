import { Table } from "semantic-ui-react";
import UserInfoCell from "../../userInfoCell/UserInfoCell";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import React, { FC } from "react";
import { ColumnOptions, User } from "../../../interfaces";
import { Tooltip } from "../../common/tooltip";

export const usersColumnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 6,
    isSortable: true,
  },
  {
    name: "Department",
    width: 4,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 4,
    isSortable: true,
  },
  {
    name: "Added",
    width: 2,
    isSortable: true,
  },
];

interface IUsersTableBody {
  user: User;
}

export const UsersTableBody: FC<IUsersTableBody> = ({ user }): React.ReactElement => (
  <>
    <Table.Cell width={usersColumnOptions[0].width}>
      <UserInfoCell user={user} />
    </Table.Cell>
    <Table.Cell width={usersColumnOptions[1].width}>
      <Tooltip target={<TextTruncate>{user.department}</TextTruncate>} content={user.department} />
    </Table.Cell>
    <Table.Cell width={usersColumnOptions[2].width}>
      <Tooltip target={<TextTruncate>{user.title}</TextTruncate>} content={user.title} />
    </Table.Cell>
    <Table.Cell width={usersColumnOptions[3].width}>{dateTimeUtils.formatDate(user.createDate)}</Table.Cell>
  </>
);
