import { FC, useEffect } from "react";

import { RolePermissions, UsersGroupsContext } from "../../../../../enums";
import { IWizardStep } from "../../../../../interfaces";
import GroupsOverview from "./Overview/GroupsOverview/GroupsOverview";
import UsersOverview from "./Overview/UsersOverview/UsersOverview";
import PeopleWrapper from "../../../PeopleAssignments/Overview/PeopleWrapper/PeopleWrapper";

export interface EditEventPeoplePropsBase extends IWizardStep {
  eventId: number;
  title: string;
  thumbnailUrl: string;
  usersGroupsContext: UsersGroupsContext;
  setUsersGroupsContext: (viewType: UsersGroupsContext) => void;
}

export type EditEventPeopleProps = EditEventPeoplePropsBase;

export const EditEventPeople: FC<EditEventPeopleProps> = ({
  eventId,
  acceptHandlers,
  usersGroupsContext,
  setUsersGroupsContext,
}) => {
  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PeopleWrapper
        permission={RolePermissions.EventsManage}
        usersGroupsContext={usersGroupsContext}
        onContextChanged={setUsersGroupsContext}
        renderGroupsOverview={(customHeaderContent: React.ReactElement) => (
          <GroupsOverview eventId={eventId} customHeaderContent={customHeaderContent} />
        )}
        renderUsersOverview={(customHeaderContent: React.ReactElement) => (
          <UsersOverview eventId={eventId} customHeaderContent={customHeaderContent} />
        )}
      />
    </>
  );
};

export default EditEventPeople;
