import { bindActionCreators } from "@reduxjs/toolkit";
import cn from "classnames";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { setExportAction } from "features/Reporting/state/export/exportSlice";
import { reset, setIsNavigationEnabled, setIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { bindAction } from "interfaces";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import {
  ChartWrapper,
  HorizontalBarChart,
  LineChart,
  TickValueType,
  getDateFormatByCount,
} from "../../../../../../components/charts";
import { RequestStatusRenderer } from "../../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import { AppDispatch, RootState } from "../../../../../Application/globaltypes/redux";
import { RegisterBreadcrumbCallback } from "../../../../Common/Hooks/usePerformanceBreadcrumbs";
import {
  PerformanceFilter, lineChartMargins,
  clicksColor,
  createDateRange,
  dailyActivity,
  emailReportColorScale,
  getBarDomain, lineChartTitles,
  noBarData,
  noData,
  opensColor,
  sendsColor,
  totalActivity,
  transitionTime,
  validLineData
} from "../../../../Common/utils/performanceUtils";
import {
  selectSendDensityLineData,
  selectSendDensityState,
  selectSendFunnelData,
  selectSendFunnelState,
} from "../../state/slices/emailPerformanceSlice";
import * as emailPerformanceActions from "../../state/thunks/emailPerformanceThunk";
import EmailPerformanceDrilldown from "./Drilldown/EmailPerformanceDrilldown";
import EmailSendsList from "./EmailSendsList/EmailSendsList";
import { EmailSendSession, EmailStatusToColor, EmailStatusToIcon } from "./types";

import "../../../../Common/utils/performanceSCSSUtils.scss";
import "./emailPerformance.scss";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";

export interface Props extends PropsFromRedux {
  emailId: number;
  emailTitle: string;
  flowId?: number;
  dateFilter: PerformanceFilter;
  registerBreadcrumb: RegisterBreadcrumbCallback;
}

export const EmailPerformanceBody: FC<Props> = ({
  emailId,
  emailTitle,
  flowId,
  dateFilter,
  registerBreadcrumb,
  sendDensityState,
  sendDensityLineData,
  sendFunnelState,
  sendFunnelData,
  setExportAction,
  actions,
  setIsReportEnabled,
  resetIsReportEnabled,
  setIsNavigationEnabled
}) => {
  const [newLineChartState, setNewLineChartState] = useState(totalActivity);
  const [selectedSendEntity, setSelectedSendEntity] = useState<EmailSendSession>();

  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setIsNavigationEnabled(!selectedSendEntity);
    if (selectedSendEntity) {
      const fileName = `${emailTitle} ${moment(selectedSendEntity.SendSession).format("MM-DD-YYYY h-mm A")}`;
      setExportAction({
        method: actions.handleSendSessionExport,
        args: [selectedSendEntity.SendSessionId, { ...dateFilter, flowId }, fileName],
        isExportEnabled: true,
      });
      return;
    }

    setExportAction({
      method: actions.handleCommunicationsExport,
      args: [emailId, { ...dateFilter, flowId }, emailTitle],
      isExportEnabled: true,
    });
  }, [
    actions.handleCommunicationsExport,
    actions.handleSendSessionExport,
    dateFilter,
    emailId,
    flowId,
    selectedSendEntity,
    setExportAction,
    emailTitle,
    setIsNavigationEnabled
  ]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  useEffect(() => {
    if (reportEnabled) {
      actions.fetchEmailSendDensity(emailId, { ...dateFilter, flowId });
      actions.fetchEmailSendFunnel(emailId, { ...dateFilter, flowId });
    }
  }, [actions, emailId, flowId, dateFilter, reportEnabled]);

  const barChartData = useMemo<DataPoint[]>(() => {
    if (sendFunnelState.status !== RStatus.Got) return [];

    return [
      {
        id: "sends",
        category: "Sends",
        fillColor: sendsColor,
        value: sendFunnelData.Sends,
      },
      {
        id: "opens",
        category: "Opens",
        fillColor: opensColor,
        value: sendFunnelData.Opens,
      },
      {
        id: "clicks",
        category: "Clicks",
        fillColor: clicksColor,
        value: sendFunnelData.Clicks,
      },
    ];
  }, [sendFunnelData, sendFunnelState.status]);

  const lineData = useMemo(() => {
    const xData = [sendDensityLineData.Date, sendDensityLineData.Date, sendDensityLineData.Date];
    if (newLineChartState === dailyActivity) {
      return {
        xData,
        yData: [sendDensityLineData.Sends, sendDensityLineData.Opens, sendDensityLineData.Clicks],
      };
    } else {
      return {
        xData,
        yData: [
          sendDensityLineData.CumulativeSends,
          sendDensityLineData.CumulativeOpens,
          sendDensityLineData.CumulativeClicks,
        ],
      };
    }
  }, [newLineChartState, sendDensityLineData]);

  const renderTopLevel = useMemo(
    () => (
      <div className="performanceBody">
        {/* Top charts */}
        <div className="graphs">
          <div className="lineChartContainer">
            <ChartWrapper
              titles={lineChartTitles}
              showLegend
              legendLabels={["Sends", "Opens", "Clicks"]}
              onChange={setNewLineChartState}
              colorRange={emailReportColorScale}
            >
              <RequestStatusRenderer state={sendDensityState}>
                {validLineData([
                  sendDensityLineData.Sends,
                  sendDensityLineData.Opens,
                  sendDensityLineData.Clicks,
                  sendDensityLineData.CumulativeSends,
                  sendDensityLineData.CumulativeOpens,
                  sendDensityLineData.CumulativeClicks,
                ]) ? (
                  <LineChart
                    margins={lineChartMargins}
                    {...lineData}
                    xScaleRatio={timeScale}
                    yTickValueType={TickValueType.IntegersOnly}
                    xFormatterFunc={getDateFormatByCount(sendDensityState.value.length)}
                    transitionDuration={transitionTime}
                    colorRange={emailReportColorScale}
                  />
                ) : (
                  noData(dateFilter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
          <div className="funnelChartContainer funnel-chart-container">
            <ChartWrapper
              titles={["Engagement"]}
              showLegend
              legendLabels={["Sends", "Opens", "Clicks"]}
              colorRange={emailReportColorScale}
            >
              <RequestStatusRenderer state={sendFunnelState}>
                {!noBarData(sendFunnelData.Sends, sendFunnelData.Opens, sendFunnelData.Clicks) ? (
                  <HorizontalBarChart
                    {...sharedAccountReportingHorizontalBarProps}
                    data={barChartData}
                    domain={getBarDomain(barChartData)}
                  />
                ) : (
                  noData(dateFilter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </div>
        {/* Table */}
        <div>
          <EmailSendsList
            emailId={emailId}
            emailFilter={dateFilter}
            flowId={flowId}
            selectSend={(entity) => {
              setSelectedSendEntity(entity);
              registerBreadcrumb({
                text: (
                  <>
                    {dateTimeUtils.localFormatWithValidation(new Date(entity.SendSession))}{" "}
                    <Icon
                      name={EmailStatusToIcon[entity.Status]}
                      className={cn(EmailStatusToColor[entity.Status], "offset")}
                    />
                  </>
                ),
                action: () => {
                  setSelectedSendEntity(undefined);
                },
              });
            }}
          />
        </div>
      </div>
    ),
    [
      barChartData,
      dateFilter,
      emailId,
      flowId,
      lineData,
      registerBreadcrumb,
      sendDensityLineData,
      sendDensityState,
      sendFunnelData,
      sendFunnelState,
    ],
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return selectedSendEntity ? (
    <EmailPerformanceDrilldown
      emailId={emailId}
      flowId={flowId}
      sendSessionId={selectedSendEntity.SendSessionId}
      dateFilter={dateFilter}
      dateRange={createDateRange(dateFilter.dateFrom, dateFilter.dateTo)}
    />
  ) : (
    renderTopLevel
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    sendDensityState: selectSendDensityState(state),
    sendFunnelState: selectSendFunnelState(state),
    sendDensityLineData: selectSendDensityLineData(state),
    sendFunnelData: selectSendFunnelData(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    actions: bindActionCreators(emailPerformanceActions, dispatch),
    setExportAction: bindAction(setExportAction, dispatch),
    setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
    resetIsReportEnabled: bindAction(reset, dispatch),
    setIsNavigationEnabled: bindAction(setIsNavigationEnabled, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmailPerformanceBody);
