import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import TopMenuItem from "./TopMenuItem";
import { MenuAccordionItem } from "./MenuAccordionItem";
import { useLocation } from "react-router-dom";

export function TopMenuAccordionItem(props) {
  const { label, path, className, iconName, expanded, onClick, notificationsCount } = props;
  const location = useLocation();

  const isMenuActive = () => {
    return location.pathname.indexOf(`/${path}`) === 0;
  };

  return (
    <MenuAccordionItem
      className={cn(className, { "active-route": isMenuActive() })}
      expanded={expanded}
      path={path}
      onClick={onClick}
    >
      <TopMenuItem label={label} iconName={iconName} notificationsCount={notificationsCount} />
    </MenuAccordionItem>
  );
}

TopMenuAccordionItem.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  active: PropTypes.bool,
  expanded: PropTypes.bool,
  className: PropTypes.string,
  iconName: PropTypes.string,
  onClick: PropTypes.func,
  notificationsCount: PropTypes.number,
};

export default TopMenuAccordionItem;
