import { FormikProps } from "formik";
import { ClosedCaptionsValues } from "../../types/models";
import { UploadTypes } from "./types";

export const getValidateFieldProps = (formikProps: FormikProps<ClosedCaptionsValues>) => {
  const { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm } = formikProps;

  return { errors, touched, isValid, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm };
};

export const defaultInfo =
  "The most recently uploaded closed captioning file will be set as the default for this video unless another file is selected. Users may change which closed captioning language they wish to use.";

export const getUploadType = (isAutoTranslated: boolean) =>
  isAutoTranslated ? UploadTypes.Automatic : UploadTypes.Manual;

export const languageFieldTooltip = {
  info: "Only one WebVTT (.vtt) file per language may be uploaded.",
  width: 13,
};

export const fileFieldTooltip = {
  info: "Only WebVTT (.vtt) files may be added for closed captioning.",
  width: 12.25,
};
