import React from "react";
import cn from "classnames";
import { Table, Popup, Icon } from "semantic-ui-react";
import GroupInfoCell from "../../../../../groupInfoCell/GroupInfoCell";
import { UserGroupsAssignment } from "../../../GroupsAssignmentModal";
import { ColumnOptions } from "../../../../../../interfaces";
import { pluralize } from "../../../../../../utils/stringUtils";

export const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 10,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
  {
    name: "Users",
    width: 2,
    isSortable: false,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export interface GroupListRowProps {
  group: any;
  userGroupsAssignments: UserGroupsAssignment[];
  issuingUsersCount: number;
}

export const GroupListRow = ({ group, userGroupsAssignments, issuingUsersCount }: GroupListRowProps) => {
  const readOnlyClass = cn({
    rowDisabled: group.isAssigned,
  });

  const getTooltip = () => {
    const usersAlreadyInGroup = userGroupsAssignments.filter((x) => x.groupIds.some((id) => id === parseInt(group.id)));

    if (!usersAlreadyInGroup.length) {
      return null;
    }

    const belong = issuingUsersCount > 1 ? "belong" : "belongs";

    const tooltipMessage =
      issuingUsersCount === usersAlreadyInGroup.length
        ? `This ${pluralize("user", issuingUsersCount)} already ${belong} to this group.`
        : `(${usersAlreadyInGroup.length}) of the users selected already belong to this group and will be skipped.`;

    return (
      <Popup
        hideOnScroll
        position="top center"
        size="small"
        className="popup-warning warning"
        content={tooltipMessage}
        trigger={<Icon link className="fa fa-exclamation-circle" size="huge" />}
      />
    );
  };

  return (
    <>
      <Table.Cell width={columnOptions[0].width} className={readOnlyClass}>
        <GroupInfoCell group={group} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}></Table.Cell>
      <Table.Cell width={columnOptions[2].width} className={readOnlyClass}>
        {group.membersCount}
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width} className="cell-warning">
        {getTooltip()}
      </Table.Cell>
    </>
  );
};

export default GroupListRow;
