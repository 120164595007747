import { createReducer } from "../../../../../../../utils/reduxUtils";
import * as actionTypes from "../actionTypes/threatDefenceSmtpAuthSendingProfileDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/threatDefenceSmtpAuthSendingProfileEntityStateActionTypes";
import {
  ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ThreatDefenceSmtpAuthSendingProfileView,
  VerificationPayload,
  ThreatDefenceSmtpAuthSendingProfile,
  ThreatDefenceSmtpAuthSendingProfileSettingsView,
  ThreatDefenceSmtpAuthSendingProfileVerificationView,
} from "../../types/state";
import { PayloadAction } from "../../../../../../../interfaces/redux";
import { SMTP_PASSWORD_PLACEHOLDER } from "../../types/constants";

const initialState: ThreatDefenceSmtpAuthSendingProfileDetailsState = {
  sendingProfile: {
    id: -1,
    isDraft: true,
    senderName: "",
    senderEmail: "",
    sendingMethodId: -1,
    dateModified: undefined,
    hasBeenPublished: false,
  },
  settings: {
    id: -1,
    host: "",
    isSsl: false,
    userName: "",
    password: "",
  },
  verification: {
    id: -1,
    email: "",
    isVerified: false,
    isFailed: false,
    errorMessage: undefined,
  },
  isLoading: false,
  isSendigProfileLoaded: false,
  isSendigProfileSettingsLoaded: false,
};

const wereSettingsSet = (settings: ThreatDefenceSmtpAuthSendingProfileSettingsView) => {
  return !!(settings.host && settings.userName);
};

const normalizeSendingProfilePayload = (
  initial: ThreatDefenceSmtpAuthSendingProfileView,
  payload: ThreatDefenceSmtpAuthSendingProfile,
): ThreatDefenceSmtpAuthSendingProfileView => ({
  ...initial,
  ...payload,
});

const getHandlers = (failureHandler: Function) => {
  const {
    getSmtpAuthSendingProfileBegin,
    getSmtpAuthSendingProfileSuccess,
    getSmtpAuthSendingProfileFailure,
    getSmtpAuthSendingProfileSettingsBegin,
    getSmtpAuthSendingProfileSettingsSuccess,
    getSmtpAuthSendingProfileSettingsFailure,
  } = actionTypes;

  const getSmtpAuthSendingProfileBeginHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getSmtpAuthSendingProfileSuccessHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceSmtpAuthSendingProfile>,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    sendingProfile: {
      ...normalizeSendingProfilePayload(state.sendingProfile, action.payload),
    },
    verification: { ...state.verification, id: action.payload.id!, isVerified: action.payload.isVerified! },
    isSendigProfileLoaded: true,
    isLoading: false,
  });

  const getSmtpAuthSendingProfileSettingsBeginHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getSmtpAuthSendingProfileSettingsSuccessHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceSmtpAuthSendingProfileSettingsView>,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    settings: {
      ...state.settings,
      ...action.payload,
      password: wereSettingsSet(action.payload) ? SMTP_PASSWORD_PLACEHOLDER : "",
    },
    isSendigProfileSettingsLoaded: true,
    isLoading: false,
  });

  return {
    [getSmtpAuthSendingProfileBegin]: getSmtpAuthSendingProfileBeginHandler,
    [getSmtpAuthSendingProfileSuccess]: getSmtpAuthSendingProfileSuccessHandler,
    [getSmtpAuthSendingProfileFailure]: failureHandler,
    [getSmtpAuthSendingProfileSettingsBegin]: getSmtpAuthSendingProfileSettingsBeginHandler,
    [getSmtpAuthSendingProfileSettingsSuccess]: getSmtpAuthSendingProfileSettingsSuccessHandler,
    [getSmtpAuthSendingProfileSettingsFailure]: failureHandler,
  };
};

const publishHandlers = (failureHandler: Function) => {
  const {
    publishThreatDefenceSmtpAuthSendingProfileBegin,
    publishThreatDefenceSmtpAuthSendingProfileSuccess,
    publishThreatDefenceSmtpAuthSendingProfileFailure,
  } = entityStateActionTypes;

  const publishThreatDefenceSmtpAuthSendingProfileBeginHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    isLoading: true,
  });

  const publishThreatDefenceSmtpAuthSendingProfileSuccessHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    sendingProfile: {
      ...state.sendingProfile,
      isDraft: false,
    },
    isLoading: false,
  });

  return {
    [publishThreatDefenceSmtpAuthSendingProfileBegin]: publishThreatDefenceSmtpAuthSendingProfileBeginHandler,
    [publishThreatDefenceSmtpAuthSendingProfileSuccess]: publishThreatDefenceSmtpAuthSendingProfileSuccessHandler,
    [publishThreatDefenceSmtpAuthSendingProfileFailure]: failureHandler,
  };
};

const fetchLockedHandlers = (failureHandler: Function) => {
  const { fetchLockedSendingProfileBegin, fetchLockedSendingProfileSuccess, fetchLockedSendingProfileFailure } =
    entityStateActionTypes;

  const fetchLockedSendingProfileBeginHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchLockedSendingProfileSuccessHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    sendingProfile: {
      ...state.sendingProfile,
      isDraft: true,
    },
    isLoading: false,
  });

  return {
    [fetchLockedSendingProfileBegin]: fetchLockedSendingProfileBeginHandler,
    [fetchLockedSendingProfileSuccess]: fetchLockedSendingProfileSuccessHandler,
    [fetchLockedSendingProfileFailure]: failureHandler,
  };
};

const threatDefenceSmtpAuthSendingProfileDetailsHandlers = () => {
  const {
    saveThreatDefenceSmtpAuthSendingProfile,
    saveThreatDefenceSmtpAuthSendingProfileSettings,
    saveSmtpAuthSendingProfileVerification,
    updateSmtpAuthSendingProfileVerificationStatus,
    resetSmtpAuthSendingProfileVerificationStatus,
    clearSmtpAuthSendingProfileVerificationStatus,
    clearThreatDefenceSmtpAuthSendingProfile,
    setSmtpAuthSendingProfile,
    setSmtpAuthSendingProfileIsLoaded,
  } = actionTypes;

  const failureHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
    action: PayloadAction<Error>,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const saveThreatDefenceSmtpAuthSendingProfileHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceSmtpAuthSendingProfileView>,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    sendingProfile: action.payload,
  });

  const saveThreatDefenceSmtpAuthSendingProfileSettingsHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceSmtpAuthSendingProfileSettingsView>,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    settings: action.payload,
  });

  const saveSmtpAuthSendingProfileVerificationHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceSmtpAuthSendingProfileVerificationView>,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    verification: action.payload,
  });

  const updateSmtpAuthSendingProfileVerificationHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
    action: PayloadAction<VerificationPayload>,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    ...(state.sendingProfile.isDraft &&
      state.verification.id === action.payload.id && { verification: { ...state.verification, ...action.payload } }),
  });

  const resetSmtpAuthSendingProfileVerificationHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    verification: { ...state.verification, isVerified: false, isFailed: false, errorMessage: "" },
  });

  const clearSmtpAuthSendingProfileVerificationHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    verification: initialState.verification,
  });

  const clearThreatDefenceSmtpAuthSendingProfileHandler = (): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...initialState,
  });

  const setSmtpAuthSendingProfileHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
    action: PayloadAction<ThreatDefenceSmtpAuthSendingProfile>,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    sendingProfile: {
      ...normalizeSendingProfilePayload(state.sendingProfile, action.payload),
    },
  });

  const setSmtpAuthSendingProfileIsLoadedHandler = (
    state: ThreatDefenceSmtpAuthSendingProfileDetailsState,
  ): ThreatDefenceSmtpAuthSendingProfileDetailsState => ({
    ...state,
    isSendigProfileLoaded: true,
  });

  return {
    [saveThreatDefenceSmtpAuthSendingProfile]: saveThreatDefenceSmtpAuthSendingProfileHandler,
    [saveThreatDefenceSmtpAuthSendingProfileSettings]: saveThreatDefenceSmtpAuthSendingProfileSettingsHandler,
    [saveSmtpAuthSendingProfileVerification]: saveSmtpAuthSendingProfileVerificationHandler,
    [clearThreatDefenceSmtpAuthSendingProfile]: clearThreatDefenceSmtpAuthSendingProfileHandler,
    [updateSmtpAuthSendingProfileVerificationStatus]: updateSmtpAuthSendingProfileVerificationHandler,
    [resetSmtpAuthSendingProfileVerificationStatus]: resetSmtpAuthSendingProfileVerificationHandler,
    [clearSmtpAuthSendingProfileVerificationStatus]: clearSmtpAuthSendingProfileVerificationHandler,
    [setSmtpAuthSendingProfile]: setSmtpAuthSendingProfileHandler,
    [setSmtpAuthSendingProfileIsLoaded]: setSmtpAuthSendingProfileIsLoadedHandler,
    ...getHandlers(failureHandler),
    ...publishHandlers(failureHandler),
    ...fetchLockedHandlers(failureHandler),
  };
};

export const threatDefenceSmtpAuthSendingProfileDetailsReducer = createReducer(initialState, [
  threatDefenceSmtpAuthSendingProfileDetailsHandlers,
]);
