import ActionConfirmationModal from "../../../../../components/modal/ActionConfirmationModal/ActionConfirmationModal";
import { pluralize } from "../../../../../utils/stringUtils";

export interface DeleteIntegrationsConfirmationModalProps {
  isOpen: boolean;
  selectedIntegrations: any[];
  onCancel: () => void;
  onContinue: () => void;
}

const DeleteIntegrationsConfirmationModal: React.FC<DeleteIntegrationsConfirmationModalProps> = (
  props: DeleteIntegrationsConfirmationModalProps,
) => {
  const { isOpen, selectedIntegrations, onCancel, onContinue } = props;
  const title = `Delete ${pluralize("Integration", selectedIntegrations.length)}`;

  return (
    <ActionConfirmationModal
      open={isOpen}
      title={title}
      onCancel={onCancel}
      onContinue={onContinue}
      withWarningLabel={false}
      confirmLabel="Delete"
    >
      {`${title}?
            This will remove all integration data and cannot be undone.`}
    </ActionConfirmationModal>
  );
};

export default DeleteIntegrationsConfirmationModal;
