import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { GenericFiltersMap } from "../../../../../utils/filterUtils";
import { createFiltersSlice, FiltersState } from "../../../../Application/slices/createFiltersSlice";
import { AssessmentFiltersEnum } from "../../types/state";

export interface AssessmentFiltersState extends FiltersState<AssessmentFiltersEnum> {
  search: string;
}

export const initialState: AssessmentFiltersState = {
  filterOptions: {} as GenericFiltersMap<AssessmentFiltersEnum>,
  appliedFilter: {} as GenericFiltersMap<AssessmentFiltersEnum>,
  isLoading: false,
  search: "",
};

const assessmentFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Assessments,
    name: "overviewFilters",
  },
  initialState: initialState,
  reducers: {},
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  resetSearch,
  setSearch,
} = assessmentFiltersSlice.actions;

export default assessmentFiltersSlice.reducer;
