import React from "react";
import { isEmpty } from "lodash";

import { ValidatedForm } from "../../../../../../components/forms";
import { ConfigurationPayload } from "./helper";
import EndpointsToGetSasLink from "../../../../../../enums/endpointsToGetSasLink";
import { maxFlowDescriptionLength } from "../../../../../../utils/validationSchemas";
import { AutosaveFormProps } from "../../../../../../interfaces/Form";

import styles from "./ConfigurationForm.module.scss";

export interface ConfigurationFormOwnProps {
  isDisabled: boolean;
  isLoading?: boolean;
  disablePreventTransitionPrompt?: boolean;
}

export type ConfigurationFormProps = ConfigurationFormOwnProps & AutosaveFormProps<ConfigurationPayload>;

export const ConfigurationForm = (props: ConfigurationFormProps) => {
  const { dirty, isLoading, disablePreventTransitionPrompt = true } = props;

  const getValidateFieldProps = () => {
    return {
      errors: props.errors,
      touched: props.touched,
      isFormValid: props.isValid,
      handleChange: props.handleChange,
      handleBlur: props.handleBlur,
      setFieldValue: props.setFieldValue,
      setFieldTouched: props.setFieldTouched,
      dirty: props.dirty,
      resetForm: props.resetForm,
    };
  };

  const defferedOnBlur = (field: string) => {
    setTimeout(() => {
      props.onBlur(field);
    }, 0);
  };

  const setField = async (field: string, value: string, shouldValidate?: boolean) => {
    const { setFieldValue } = props;

    setFieldValue(field, value, shouldValidate);
    defferedOnBlur(field);
  };

  const onTitleBlur = async (field: string) => {
    const { values, id, onBlur } = props;

    if (isEmpty(values.description) && !id) {
      const descriptionValue = values.title.substring(0, maxFlowDescriptionLength);
      await setField("description", descriptionValue, true);
    }

    onBlur(field);
  };

  const renderFields = () => {
    const { values, isDisabled, onBlur } = props;
    const validatedFieldProps = getValidateFieldProps();

    return (
      <div className={styles.root}>
        <div className={styles["configuration-fields"]}>
          <ValidatedForm.InputField
            {...validatedFieldProps}
            label="Title"
            value={values.title}
            propertyName="title"
            onBlur={onTitleBlur}
            markAsRequired
            disabled={isDisabled}
            tooltip={{
              info: "This field is visible to end users.",
              width: 17,
            }}
            placeholder="Enter Flow Title"
          />
          <ValidatedForm.TextAreaField
            {...validatedFieldProps}
            label="Description"
            value={values.description}
            propertyName="description"
            onBlur={onBlur}
            markAsRequired
            disabled={isDisabled}
            tooltip={{
              info: "This field is visible to users and is displayed by the title.",
              width: 17,
            }}
            placeholder="Enter Description"
          />
          <ValidatedForm.ImagePreview
            {...validatedFieldProps}
            label="Image"
            value={values.thumbnailUrl}
            propertyName="thumbnailUrl"
            onImageChanged={defferedOnBlur}
            markAsRequired
            disabled={isDisabled}
            endpointToGetSasLink={EndpointsToGetSasLink.Flow}
          />
        </div>
      </div>
    );
  };

  return (
    <ValidatedForm
      disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      unsavedChangesPrompt={{
        title: "Exit Without Saving?",
        message: "Are you sure you want to exit without saving this Flow? All information entered will be lost.",
      }}
      dirty={dirty}
      isLoading={isLoading}
      formWidthComputer={12}
      parentWithFormik={true}
    >
      {renderFields}
    </ValidatedForm>
  );
};
