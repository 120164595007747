import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import * as eventsUserAssignmentActionTypes from "../actionTypes/eventsUserAssignmentActionTypes";

export const setSearch = makeActionCreator(
  eventsUserAssignmentActionTypes.LIBRARY_USER_EVENT_ASSIGNMENTS_SET_SEARCH,
  "search",
);
export const resetSearch = makeActionCreator(
  eventsUserAssignmentActionTypes.LIBRARY_USER_EVENT_ASSIGNMENTS_RESET_SEARCH,
);
