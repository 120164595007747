import * as actionTypes from "../actionTypes/threatDefenceCampaignDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import {
  ThreatDefenceCampaign,
  ThreatDefenceCampaignContent,
  ThreatDefenceCampaignView,
  ThreatDefenceCampaignRun,
} from "../../types/state";
import { Dispatcher, FetchActionPayload } from "../../../../../../interfaces/redux";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { setCampaignEntityIdAction } from "./threatDefenceCampaignsEntityStateActions";
import { GetThreatDefenceCampaignRunsRequest } from "../../types/requests";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const clearCampaign = () => {
  const resetThreatDefenceCampaignAction = getActionBaseProvider(actionTypes.clearThreatDefenceCampaign);

  return (dispatch: Dispatcher) => {
    dispatch(resetThreatDefenceCampaignAction());
  };
};

export const saveCampaign = (campaign: ThreatDefenceCampaignView) => {
  const saveCampaignAction = getActionProvider<ThreatDefenceCampaignView>(actionTypes.saveThreatDefenceCampaign);

  return (dispatch: Dispatcher) => {
    dispatch(saveCampaignAction(campaign));
  };
};

export const saveCampaignContent = (content: ThreatDefenceCampaignContent) => {
  const saveContentAction = getActionProvider<ThreatDefenceCampaignContent>(
    actionTypes.saveThreatDefenceCampaignContent,
  );
  return (dispatch: Dispatcher) => {
    dispatch(saveContentAction(content));
  };
};

export const getCampaign = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceCampaignBegin);
  const success = getActionProvider<ThreatDefenceCampaign>(actionTypes.getThreatDefenceCampaignSuccess);
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceCampaignFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const result = await dataService.threatDefence.getThreatDefenceCampaignAsync(id);
      dispatch(success(result.data));
      dispatch(setCampaignEntityIdAction(result.data.id || 0));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const getCampaignContent = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceCampaignContentBegin);
  const success = getActionProvider<ThreatDefenceCampaignContent>(actionTypes.getThreatDefenceCampaignContentSuccess);
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceCampaignContentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());

    try {
      const result = await dataService.threatDefence.getThreatDefenceCampaignContentAsync(id);
      dispatch(success(result.data));
      dispatch(setCampaignEntityIdAction(result.data.id || 0));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const getCampaignRuns = (id: number, take: number, skip: number, orderBy: string) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceCampaignRunsBegin);
  const success = getActionProvider<FetchActionPayload<ThreatDefenceCampaignRun>>(
    actionTypes.getThreatDefenceCampaignRunsSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceCampaignRunsFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    const request: GetThreatDefenceCampaignRunsRequest = {
      top: take,
      skip,
      orderBy,
    };
    try {
      const result = await dataService.threatDefence.getThreatDefenceCampaignRunsAsync(request, id);

      const recordsCount = Number.parseInt(result.headers[countHeaderName]);

      const data: FetchActionPayload<ThreatDefenceCampaignRun> = {
        items: result.data,
        totalCount: recordsCount,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const setCampaignAction = getActionProvider<ThreatDefenceCampaign>(actionTypes.setCampaign);
