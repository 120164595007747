enum Sections {
  flows = "flows",
  assets = "assets",
  assessments = "assessments",
  emails = "emails",
  messages = "messages",
  events = "events",
  surveys = "surveys",
  pdfs = "pdfs",
}
export default Sections;
