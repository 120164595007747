import React from "react";
import { isEmpty } from "lodash";

import ExpandableLinks from "../../../../../../components/ExpandableItems/ExpandableLinks/ExpandableLinks";
import MultiSelectWithAdditionField from "../../../../../../components/forms/MultiSelectWithAdditionField";

import { NormalizedDropdown } from "../../../../../../utils/miscellaneousUtils";
import { ValidatedForm } from "../../../../../../components/forms";
import { SettingsFormProps } from "../types";

import styles from "./settingsForm.module.scss";

const SettingsForm = (props: SettingsFormProps) => {
  const { assessmentInfo, isLoading, tagsFormik, entityId } = props;

  const handleChangeTag = async (
    field: "labels" | "softwareApplications",
    value: NormalizedDropdown,
    shouldValidate?: boolean,
  ) => {
    const errors = await (tagsFormik.setFieldValue(field, value.selected, shouldValidate) as unknown as Promise<void>);
    if (isEmpty(errors?.[field])) {
      props.updateTags(entityId, value.selected, field);
    }
  };
  const renderFields = () => {
    const { normalizedTags, isDisabled } = props;
    return (
      <div className={styles.root}>
        <div className={styles["settings-fields"]}>
          <MultiSelectWithAdditionField
            {...tagsFormik}
            shouldValidate
            isCaseSensitive
            label="Software Application"
            propertyName="softwareApplications"
            disabled={isDisabled}
            value={tagsFormik.values.softwareApplications}
            options={normalizedTags?.softwareApplications}
            setFieldValue={handleChangeTag}
          />
          <MultiSelectWithAdditionField
            {...tagsFormik}
            shouldValidate
            isCaseSensitive
            label="Labels"
            propertyName="labels"
            disabled={isDisabled}
            value={tagsFormik.values.labels}
            options={normalizedTags?.labels}
            setFieldValue={handleChangeTag}
          />
          <ExpandableLinks
            label="Associated Flows"
            linkFormatter={(id) => `/content/flows/${id}/items`}
            iconName="fa-code-branch"
            links={assessmentInfo.flows}
          />
        </div>
      </div>
    );
  };
  return (
    <ValidatedForm parentWithFormik isLoading={isLoading} formWidthComputer={6} disablePreventTransitionPrompt>
      {renderFields}
    </ValidatedForm>
  );
};
export default SettingsForm;
