import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CardReportingItem } from "../../../../components/cards/CardReporting/CardReporting";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../enums/reducer";
import { RequestState, RStatus } from "../../../Application/globaltypes/fetchRequest";
import { RootState } from "../../../Application/globaltypes/redux";
import { CustomerCards, CustomerPacks, OwnerPacksLicensing, PackLicenses } from "../../types/packs";
import utils from "../../../../utils/miscellaneousUtils";

const namespace = ReducerNamespaceTypes.Reporting;
const entityPrefix = ReducerEntityPrefixTypes.Reporting_Packs;

export interface PackReportingState {
  ownerPacksLicensing: RequestState<OwnerPacksLicensing[]>;
  packLicenses: RequestState<PackLicenses[]>;
  customerPacks: RequestState<CustomerPacks[]>;
  customerCards: RequestState<CustomerCards>;
}

const initialArrayRequestState: RequestState<any[]> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

export const initialState: PackReportingState = {
  ownerPacksLicensing: { ...initialArrayRequestState },
  packLicenses: { ...initialArrayRequestState },
  customerPacks: { ...initialArrayRequestState },
  customerCards: {
    status: RStatus.Idle,
    value: {
      LicensesCount: 0,
      LicensesIssued: 0,
      PacksCount: 0,
      PercentIssued: 0,
    },
    errorMessage: undefined,
  },
};

const packReportingSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    reqOwnerPacksLicensing(state) {
      state.ownerPacksLicensing.status = RStatus.Pending;
    },
    setOwnerPacksLicensing(state, action: PayloadAction<{ data: OwnerPacksLicensing[] }>) {
      state.ownerPacksLicensing = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setOwnerPacksLicensingError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.ownerPacksLicensing.status = RStatus.Error;
      state.ownerPacksLicensing.errorMessage = action.payload.errorMessage;
    },
    reqPackLicenses(state) {
      state.packLicenses.status = RStatus.Pending;
    },
    setPackLicenses(state, action: PayloadAction<{ data: PackLicenses[] }>) {
      state.packLicenses = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setPackLicensesError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.packLicenses.status = RStatus.Error;
      state.packLicenses.errorMessage = action.payload.errorMessage;
    },
    reqCustomerPacks(state) {
      state.customerPacks.status = RStatus.Pending;
    },
    setCustomerPacks(state, action: PayloadAction<{ data: CustomerPacks[] }>) {
      state.customerPacks = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setCustomerPacksError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.customerPacks.status = RStatus.Error;
      state.customerPacks.errorMessage = action.payload.errorMessage;
    },
    reqCustomerCards(state) {
      state.customerCards.status = RStatus.Pending;
    },
    setCustomerCards(state, action: PayloadAction<{ data: CustomerCards }>) {
      state.customerCards = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setCustomerCardsError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.customerCards.status = RStatus.Error;
      state.customerCards.errorMessage = action.payload.errorMessage;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  reqOwnerPacksLicensing,
  setOwnerPacksLicensing,
  setOwnerPacksLicensingError,
  reqPackLicenses,
  setPackLicenses,
  setPackLicensesError,
  reqCustomerPacks,
  setCustomerPacks,
  setCustomerPacksError,
  reqCustomerCards,
  setCustomerCards,
  setCustomerCardsError,
  reset,
} = packReportingSlice.actions;

// Selectors
export const selectOwnerPacksLicensingState = (state: RootState) => state.reporting.packs.ownerPacksLicensing;
export const selectPackLicensesState = (state: RootState) => state.reporting.packs.packLicenses;
export const selectCustomerPacksState = (state: RootState) => state.reporting.packs.customerPacks;
export const selectCustomerCardsState = (state: RootState) => state.reporting.packs.customerCards;

export const selectOwnerPacksLicensingStatus = createSelector(selectOwnerPacksLicensingState, (state) => state.status);
export const selectOwnerPacksLicensingData = createSelector(selectOwnerPacksLicensingState, (state) => state.value);
export const selectOwnerPacksLicensingError = createSelector(
  selectOwnerPacksLicensingState,
  (state) => state.errorMessage,
);

export const selectPackLicensesStatus = createSelector(selectPackLicensesState, (state) => state.status);
export const selectPackLicensesData = createSelector(selectPackLicensesState, (state) => state.value);
export const selectPackLicensesError = createSelector(selectPackLicensesState, (state) => state.errorMessage);

export const selectCustomerPacksStatus = createSelector(selectCustomerPacksState, (state) => state.status);
export const selectCustomerPacksData = createSelector(selectCustomerPacksState, (state) => state.value);
export const selectCustomerPacksError = createSelector(selectCustomerPacksState, (state) => state.errorMessage);

export const selectCustomerCardsStatus = createSelector(selectCustomerCardsState, (state) => state.status);
export const selectCustomerCardsData = createSelector(selectCustomerCardsState, (state) => state.value);
export const selectCustomerCardsError = createSelector(selectCustomerCardsState, (state) => state.errorMessage);

function statusFormatter(status: string): string {
  return status === "Purchase" ? "Purchased" : status;
}

export const selectFormattedPackLicensesData = createSelector(selectPackLicensesData, (packData): PackLicenses[] => {
  return packData.map((pack) => ({
    ...pack,
    Status: statusFormatter(pack.Status),
  }));
});

export const selectFormattedCustomerPacksData = createSelector(selectCustomerPacksData, (packData): CustomerPacks[] => {
  return packData.map((pack) => ({
    ...pack,
    Status: statusFormatter(pack.Status),
  }));
});

export const selectFormattedCardsData = createSelector(selectCustomerCardsData, (data): CardReportingItem[] => {
  return [
    {
      statDescription: "Packs",
      stat: data.PacksCount,
    },
    {
      statDescription: "Licenses",
      stat: data.LicensesCount,
    },
    {
      statDescription: "Licenses Issued",
      stat: utils.unlimitedNumber(data.LicensesIssued),
    },
    {
      statDescription: "Percent Issued",
      // This should already be rounded to two decimals
      stat: `${data.PercentIssued}%`,
    },
  ];
});

export const selectOverviewFormattedCardsData = createSelector(selectCustomerCardsData, (data): CardReportingItem[] => {
  return [
    {
      statDescription: "Total Packs",
      stat: data.PacksCount.toLocaleString(),
    },
    {
      statDescription: "Total Licenses",
      stat: data.LicensesCount.toLocaleString(),
    },
    {
      statDescription: "Licenses Issued",
      stat: data.LicensesIssued.toLocaleString(),
    },
    {
      statDescription: "Percent Issued",
      // This should already be rounded to two decimals
      stat: `${data.PercentIssued}%`,
    },
  ];
});

export default packReportingSlice.reducer;
