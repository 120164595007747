import { AddOperationParamsV2 } from "../../../interfaces/updateParams";
import * as actionTypes from "./actionTypes";
import dataService from "../../Application/services/dataServices/dataService";
import videoAssetTasks from "../videoAssetTasks";

import TaskStatuses from "../../../enums/taskStatuses";
import * as generalLoaderActions from "../../../features/Application/actions/generalLoader/generalLoaderActions";
import backgroundTasksEventsEmitter from "../../../features/BackgroundTasks/events/backgroundTasksEventsEmitter";
import { Dispatcher } from "../../../interfaces/redux";
import BackgroundTaskLocalStorage from "../storage/BackgroundTaskLocalStorage";
import {
  AddOperationParamsDistributedOp,
  AddOperationParamsV1,
} from "../../../interfaces/backgroundAddOperationParams";
import environmentConfig from "configuration/environmentConfig";

const NOT_FOUND_STATUS_CODE = 404;

export const addTask = (task: any) => ({
  type: actionTypes.ADD_BACKGROUND_TASK,
  task,
});

export const addTaskDistributedOp = (task: any) => ({
  type: actionTypes.ADD_BACKGROUND_TASK_DISTRIBUTED_OP,
  task,
});

export const addOrUpdate = (task: any) => ({
  type: actionTypes.ADD_OR_UPDATE,
  task,
});

export const updateProgress = (taskId: number, percent: number, indeterminate: boolean) => ({
  type: actionTypes.UPDATE_PROGRESS_OF_BACKGROUND_TASK,
  taskId,
  percent,
  indeterminate: !!indeterminate,
});

export const updateStatus = (taskId: number, status: number) => ({
  type: actionTypes.UPDATE_STATUS_OF_BACKGROUND_TASK,
  taskId,
  status,
});

export const updateId = (oldId: number | string, newId: number) => ({
  type: actionTypes.UPDATE_ID_OF_BACKGROUND_TASK,
  oldId,
  newId,
});

export const updateTask = (taskId: number | string, properties: any) => ({
  type: actionTypes.UPDATE_BACKGROUND_TASK_PROPERTIES,
  taskId,
  properties,
});

export const deleteTask = (taskId: number | string, force?: boolean) => ({
  type: actionTypes.DELETE_BACKGROUND_TASK,
  taskId,
  force,
});

export const cancelTask = (taskId: number) => ({
  type: actionTypes.CANCEL_BACKGROUND_TASK,
  taskId,
});

export const dismissCompleted = () => ({
  type: actionTypes.DISMISS_COMPLETED_TASKS,
});

export const updateSucceededTaskOperation = (messageId: string, entityId?: number) =>
  ({
    type: actionTypes.UPDATE_OPERATION_SUCCESS_OF_BACKGROUND_TASK,
    messageId,
    entityId,
  }) as const;
updateSucceededTaskOperation.type = actionTypes.UPDATE_OPERATION_SUCCESS_OF_BACKGROUND_TASK;

export const updateFailedTaskOperation = (messageId: string, entityId?: number, errorLabel?: string) =>
  ({
    type: actionTypes.UPDATE_OPERATION_FAIL_OF_BACKGROUND_TASK,
    messageId,
    entityId,
    errorLabel,
  }) as const;
updateFailedTaskOperation.type = actionTypes.UPDATE_OPERATION_FAIL_OF_BACKGROUND_TASK;

export const updateSucceededTaskDistributedOperation = (operationId: string) =>
  ({
    type: actionTypes.UPDATE_DISTRIBUTED_OP_SUCCESS_OF_BACKGROUND_TASK,
    operationId,
  }) as const;
updateSucceededTaskDistributedOperation.type = actionTypes.UPDATE_DISTRIBUTED_OP_SUCCESS_OF_BACKGROUND_TASK;

export const updateProgressTaskDistributedOperation = (operationId: string, stepId: string) => ({
  type: actionTypes.UPDATE_DISTRIBUTED_OP_PROGRESS_OF_BACKGROUND_TASK,
  operationId,
  stepId,
});

export const updateFailedTaskDistributedOperation = (operationId: string) =>
  ({
    type: actionTypes.UPDATE_DISTRIBUTED_OP_FAIL_OF_BACKGROUND_TASK,
    operationId,
  }) as const;
updateFailedTaskDistributedOperation.type = actionTypes.UPDATE_DISTRIBUTED_OP_FAIL_OF_BACKGROUND_TASK;

export const showProgressDrawer = () => ({
  type: actionTypes.SHOW_PROGRESS_DRAWER,
});

export const hideProgressDrawer = () => ({
  type: actionTypes.HIDE_PROGRESS_DRAWER,
});

export interface BgEventEmit {
  isSuccessful: boolean;
  taskId: number | string;
}

/* istanbul ignore next */
const addOperation = (
  task: any,
  onCompletedInTime: any,
  orchestrateOperation: (dispatch: Dispatcher) => Promise<any>,
) => {
  return async (dispatch: Dispatcher): Promise<void> => {
    const executeOpt = (): Promise<BgEventEmit> => {
      return new Promise(async (resolve, reject) => {
        dispatch(generalLoaderActions.showGeneralLoader);

        const timerId = setTimeout(() => reject(task.id), environmentConfig.backgroundActivityTimeoutMs || 3000);

        const onCompleted = (isSuccessful: boolean) => () => {
          clearTimeout(timerId);
          resolve({ taskId: task.id, isSuccessful });
        };

        backgroundTasksEventsEmitter.updateHandlerOnce(TaskStatuses.successful, task.id, onCompleted(true));
        backgroundTasksEventsEmitter.updateHandlerOnce(TaskStatuses.failed, task.id, onCompleted(false));

        try {
          await orchestrateOperation(dispatch);
        } catch {
          reject(task.id);
        }
      });
    };

    let result = null;
    try {
      result = await executeOpt();
    } catch {
      dispatch(updateTask(task.id, { isHidden: false }));
      dispatch(showProgressDrawer());
    } finally {
      dispatch(generalLoaderActions.hideGeneralLoader);
    }

    if (result) {
      onCompletedInTime?.(result);
      dispatch(deleteTask(result.taskId, true));
    }
  };
};

export const addOperationV1 = ({ task, onCompletedInTime, getMessageIds }: AddOperationParamsV1) =>
  addOperation(task, onCompletedInTime, async (dispatch: Dispatcher) => {
    const messages = await getMessageIds();
    const operation = {
      status: messages.length ? TaskStatuses.inProgress : TaskStatuses.successful,
      percent: messages.length ? 0 : 100,
      isHidden: true,
      operation: {
        messages,
        succeeded: [],
        failed: [],
      },
    };

    dispatch(addTask({ ...task, ...operation }));
  });

export const addOperationDistributedOp = ({
  task,
  onCompletedInTime,
  getOperationProps,
}: AddOperationParamsDistributedOp) =>
  addOperation(task, onCompletedInTime, async (dispatch: Dispatcher) => {
    const operationProps = await getOperationProps();
    const operation = {
      status: TaskStatuses.inProgress,
      percent: 0,
      isHidden: true,
      distributedOperation: operationProps,
    };

    dispatch(addTaskDistributedOp({ ...task, ...operation }));
  });

export const addOperationV2 = ({ task, onCompletedInTime, getMessageIdsV2, callApi }: AddOperationParamsV2) =>
  addOperation(task, onCompletedInTime, async (dispatch) => {
    const operation = {
      status: TaskStatuses.inProgress,
      isHidden: true,
      percent: 0,
      operation: {
        messages: getMessageIdsV2(),
        succeeded: [],
        failed: [],
      },
    };

    dispatch(addTask({ ...task, ...operation }));

    await callApi();
  });

export const loadAssetTasks = (assetIds: number[]) => {
  return (dispatch: Dispatcher) => {
    assetIds.forEach((assetId) => {
      dataService
        .getVideoInfo(assetId)
        .then((result: any) => {
          const asset = result.data;
          const id = asset.id;
          const onClose = (taskId: number) => dispatch(deleteTask(taskId));
          let task: any;
          if (asset.isEncodingFailed || asset.isThumbnailGenerationFailed) {
            task = videoAssetTasks.getFailed(id, null, onClose);
          } else if (asset.canBePublished || asset.hasBeenPublished) {
            task = videoAssetTasks.getReadyToPublish(id, onClose);
          } else {
            task = videoAssetTasks.getProcessing(id);
          }
          task.title = asset.title;
          dispatch(addTask(task));
        })
        .catch((e: any) => {
          if (e.response.status === NOT_FOUND_STATUS_CODE) {
            new BackgroundTaskLocalStorage().removeTask(assetId);
          }
        });
    });
  };
};
