import cn from "classnames";

import { TextTruncate } from "../../textTruncators/TextTruncators";
import Logo from "../../../images/brainstorm-pack-logo.png";

import "./packPublisherInfo.scss";

export const isBrainStorm = (accountName: string) => accountName === "BrainStorm Global";

export interface PackPublisherInfoProps {
  publisherName: string;
  disabled?: boolean;
}

export const PackPublisherInfo: React.FC<PackPublisherInfoProps> = (props) => {
  const { publisherName, disabled } = props;

  const renderContent = () => {
    if (publisherName) {
      const publishedBy = `By ${publisherName}`;
      return (
        <div className={cn("pack-publisher-info-container", { disabled: disabled })}>
          {isBrainStorm(publisherName) && (
            <div className="pack-publisher-logo">
              <img src={Logo} className="pack-publisher-logo-center" alt="BrainStorm Logo" />
            </div>
          )}
          <div className="pack-publisher-name">
            <TextTruncate>{publishedBy}</TextTruncate>
          </div>
        </div>
      );
    }
    return null;
  };

  return renderContent();
};
