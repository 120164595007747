enum SortOptions {
  Id = "id desc",
  Title = "title asc",
  TitleDesc = "title desc",

  CreatedDateDesc = "DateCreated desc",
  ModifiedDateDesc = "DateModified desc",
  LicenseIssuedDesc = "licenseIssued desc",
  Access = "isFullAccess asc",
}
export default SortOptions;
