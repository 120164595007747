import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../Application/globaltypes/redux";
import { ApiAccessType } from "../../../types";

const selectApiClients = (state: RootState) => state.accounts.apiKeys.items;

export const selectApiClient = createSelector(
  [selectApiClients, (_: RootState, accessType: ApiAccessType) => accessType],
  (clients, accessType) => clients.find((c) => c.accessType === accessType),
);
