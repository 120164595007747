import { FC, useEffect, useMemo, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { Icon, Placeholder, Table } from "semantic-ui-react";

import { noop } from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { ListViewBase } from "../../../../../../../components/listView";
import ModalTypes from "../../../../../../../components/modal/ModalTypes";
import { IModalManager } from "../../../../../../../interfaces/IModalManager";
import { ColumnOption } from "../../../../../../../interfaces/columnOptions";
import ModalManager from "../../../../../../../utils/ModalManager";
import dateTimeUtils from "../../../../../../../utils/dateTimeUtils";
import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { PerformanceTabSectionList } from "../../../../../Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";
import { CancelSendSessionModal } from "../../../SendSessions/CancelSendSessionModal/CancelSendSessionModal";
import { reset } from "../../../state/slices/emailCancelSendSlice";
import { reset as resetState } from "../../../state/slices/emailSendsSlice";
import { fetchEmailSendSessions } from "../../../state/thunks/emailPerformanceThunk";
import { cancelEmailSend } from "../../../state/thunks/emailSendsThunk";

import { FeatureFlags } from "featureFlags";
import { PerformanceFilter, roundToTwoDigits } from "features/Library/Common/utils/performanceUtils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { TextTruncate } from "../../../../../../../components";
import { Tooltip } from "../../../../../../../components/common/tooltip";
import { RStatus } from "../../../../../../Application/globaltypes/fetchRequest";
import { selectSendSessions } from "../../../state/slices/emailPerformanceSlice";
import { EmailSendSession, EmailStatusToColor, EmailStatusToIcon, EmailStatusToString } from "../types";
import "./emailSendsList.scss";
import { bindAction } from "interfaces";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";

export type EmailSendsListProps = {
  selectSend: (sendEntity: EmailSendSession) => void;
  emailId: number;
  emailFilter: PerformanceFilter;
  flowId?: number;
} & PropsFromRedux;

export type EmailSendsListListViewBase = {
  CTR: number;
  Clicks: number;
  OpenRate: number;
  Opens: number;
  SendSessionId: number;
  SendSessionDate: string;
  SendSessionStatusId: number;
  Sends: number;
  id: number;
};

const showPlaceholder = () => (
  <Placeholder fluid={true}>
    <Placeholder.Line length="full" />
  </Placeholder>
);

const showStatus = (entity: EmailSendSession) => {
  const status = entity.Status;
  return (
    <div className="status">
      <Icon name={EmailStatusToIcon[status]} className={EmailStatusToColor[status]} />
      &nbsp;
      {EmailStatusToString[status]}
    </div>
  );
};

const getRateString = (rate: number | undefined) => {
  if (rate === undefined) {
    return "-";
  }

  return `${roundToTwoDigits(rate * 100)}%`;
};

export const EmailSendsListComponent: FC<EmailSendsListProps> = ({
  selectSend,
  sendSessions,
  getEmailSends,
  cancelSend,
  sendStatusUpdating,
  resetCancelSend,
  resetListState,
  emailId,
  emailFilter,
  flowId,
  setHasExportData,
}) => {
  const modalManager = useRef<null | IModalManager>(null);
  const [sendId, setSendId] = useState(-1);
  const [slicedEntities, setSliceEntities] = useState<EmailSendSession[]>([]);

  const reportEnabled = useFeatureFlag(FeatureFlags.EmailReport);

  const loading = useMemo(() => {
    return sendSessions.status === RStatus.Pending;
  }, [sendSessions.status]);

  const onCancelConfirm = useRef(noop);

  useEffect(() => {
    setHasExportData(sendSessions.status === RStatus.Got && !!sendSessions.value?.length);
  }, [setHasExportData, sendSessions]);

  useEffect(() => {
    modalManager.current = new ModalManager(ModalTypes.CancelEmailSend, () => onCancelConfirm.current());
    return () => {
      resetCancelSend();
      modalManager.current!.dispose();
    };
  }, [resetCancelSend]);

  useEffect(() => {
    if (!sendStatusUpdating.canceling && (sendStatusUpdating.canceled || sendStatusUpdating.error)) {
      resetCancelSend();
    }
  }, [sendStatusUpdating, resetCancelSend]);

  // Fetch all sends
  useEffect(() => {
    // These parameters aren't currently available for this item,
    // but we should update the API for it later
    getEmailSends(emailId, { ...emailFilter, flowId });
  }, [emailId, getEmailSends, emailFilter, flowId]);

  useEffect(() => {
    const reduxCompleted = !loading;
    if (reduxCompleted) {
      setSliceEntities([...sendSessions.value].slice(0, 10));
    }
  }, [loading, reportEnabled, sendSessions.value]);

  useEffect(() => {
    return () => {
      resetListState();
    };
  }, [resetListState]);

  const columnOptions: ColumnOption<EmailSendSession>[] = [
    {
      name: "Session",
      width: 3,
      isSortable: false,
      render: (entity) => {
        const session = dateTimeUtils.localFormatWithValidation(new Date(entity.SendSession));
        return (
          <a
            className="sessionTooltip email-sends-title"
            href="./"
            onClick={(e) => {
              e.preventDefault();
              selectSend(entity);
            }}
          >
            <Tooltip target={<TextTruncate>{session}</TextTruncate>} content={session} position="left center" />
          </a>
        );
      },
    },
    {
      name: "Origin",
      width: 2,
      isSortable: false,
      render: (entity) => entity.Origin || "-",
    },
    {
      name: "Sends",
      width: 2,
      isSortable: false,
      render: (entity) => entity.Sends ? entity.Sends.toLocaleString() : "-",
    },
    {
      name: "Opens",
      width: 2,
      isSortable: false,
      render: (entity) => entity.Opens ? entity.Opens.toLocaleString() : "-",
    },
    {
      name: "Open rate",
      width: 2,
      isSortable: false,
      render: (entity) => getRateString(entity.OpenRate),
    },
    {
      name: "Clicks",
      width: 2,
      isSortable: false,
      render: (entity) => entity.Clicks ? entity.Clicks.toLocaleString() : "-",
    },
    {
      name: "CTR",
      width: 2,
      isSortable: false,
      render: (entity) => getRateString(entity.ClickRate),
    },
    {
      name: "Status",
      width: 3,
      isSortable: false,
      render: (entity) =>
        entity.SendSessionId === sendId && sendStatusUpdating.canceling ? showPlaceholder() : showStatus(entity),
    },
    {
      name: "",
      width: 1,
      isSortable: false,
      render: (entity) => {
        return (
          <a
            className="email-sends-title"
            href="./"
            onClick={(e) => {
              e.preventDefault();
              onCancelClick(entity);
            }}
          >
            Cancel
          </a>
        );
      },
      disabled: (entity) => {
        return entity.Status !== "Scheduled";
      },
    },
  ];

  const onCancelClick = (sendToCancel: EmailSendSession) => {
    setSendId(sendToCancel.SendSessionId);
    onCancelConfirm.current = () => {
      cancelSend(sendToCancel.SendSessionId);
    };
    modalManager.current!.execute();
  };

  const buildTableBody = (listItem: EmailSendSession) => (
    <>
      {columnOptions.map((item, index) => (
        <Table.Cell width={item.width} key={index} disabled={item.disabled?.(listItem)}>
          {item.render(listItem)}
        </Table.Cell>
      ))}
    </>
  );
  const getData = (skip: number, top: number) => {
    setSliceEntities(sendSessions.value.slice(skip, skip + top));
  };

  return (
    <>
      <PerformanceTabSectionList
        title="Sends"
        count={sendSessions.value.length}
        renderList={(page) => {
          const sendEntitiesParsed = slicedEntities.map(({ SendSessionId: id, ...rest }) => ({
            id,
            ...rest,
          }));

          return (
            <ListViewBase
              filter={emailFilter}
              columnOptions={columnOptions}
              loadPage={getData}
              isLoading={loading}
              loadSpinner={true}
              items={sendEntitiesParsed}
              itemsAmount={sendSessions.value.length}
              buildTableBody={(e: EmailSendsListListViewBase) => {
                const { id, ...entityUnparsed } = e;
                entityUnparsed.SendSessionId = e.id;
                return buildTableBody(entityUnparsed as any);
              }}
              withFooter={false}
              activePage={page}
              withSelection={false}
              updateComponentRtnEvents={[rtnEvents.CancelSendSessionSuccess]}
            />
          );
        }}
      />
      {modalManager.current && <CancelSendSessionModal modalManager={modalManager.current} />}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  sendStatusUpdating: state.library.emails.emailSendSession.cancel,
  sendSessions: selectSendSessions(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmailSends: bindActionCreators(fetchEmailSendSessions, dispatch),
  cancelSend: bindActionCreators(cancelEmailSend, dispatch),
  resetCancelSend: bindActionCreators(reset, dispatch),
  resetListState: bindActionCreators(resetState, dispatch),
  setHasExportData: bindAction(setHasDataToExport, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export const EmailSendsList = connector(EmailSendsListComponent);
export default connector(EmailSendsListComponent);
