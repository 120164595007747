import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import styles from "./ToggleVisibilityButton.module.scss";
interface Props {
  onClick: () => void;
  isDisabled?: boolean;
  hidden: boolean;
}

export const ToggleVisibilityButton = (props: Props) => {
  const { onClick, isDisabled, hidden } = props;
  return (
    <BasicLinkButton
      text={hidden ? "Unhide" : "Hide"}
      iconClassName={hidden ? "fa-regular fa-eye" : "fa-solid fa-eye-slash"}
      onClick={onClick}
      isDisabled={isDisabled}
      className={styles.root}
    />
  );
};
