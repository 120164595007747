import { useCallback, useEffect, useState } from "react";
import { batch, connect, ConnectedProps } from "react-redux";

import Section from "../../../../components/wizard/Section";
import navigationUtils from "../../../../utils/navigationUtils";
import Configure from "../Details/Configure/Configure";
import SettingsTab from "../Details/Settings/SettingsTab";
import { PdfPublishSuccess } from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { WizardNew as Wizard } from "../../../../components/wizard";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { CreatePdfSteps, showLastModified } from "./types";
import { useWizardStepsManager } from "../../../../hooks/useWizardStepsManager";
import { useRtn } from "../../../../hooks/useRtn";
import { PublishedStatusTypes, RouteNames } from "../../../../enums";
import { pdfsStateSelector } from "../selectors";
import { clearPdf } from "../state/slices/pdfDetailsSlice";
import { publishPdf } from "../state/actions/pdfEntityStateActions";
import { bindAction } from "../../../../interfaces";
import {
  resetSearch,
  resetPagination,
  resetSortingColumnName,
  resetSortingDirection,
  resetAppliedFilter,
} from "../state/slices/pdfFiltersSlice";
import { reset } from "../state/slices/pdfOverviewSlice";
import { resetTags } from "../state/slices/pdfBaseSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";

const overviewUrl = `/${RouteNames.contentPdfs}`;

export type CreatePdfPropsAll = PropsFromRedux;

export const CreatePdf = (props: CreatePdfPropsAll) => {
  const isAssociatedPackEnabled = useFeatureFlag(FeatureFlags.AssociatedPacks);
  const location = useLocation();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(false);
  const [showAddPacks, setShowAddPacks] = useState(false);
  const pageIndexes = [CreatePdfSteps.Configuration, CreatePdfSteps.Settings];

  const [wizardPages, { onNext, onPrevious, onFinish }, subscribeOnActiveIndexChanged] =
    useWizardStepsManager(pageIndexes);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(
    () => {
      return () => {
        if (!window.location.pathname.includes(`/${RouteNames.contentPdfs}`)) props.resetSearchFilters();
        props.onUnmount();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const handler = (_: any, activeStepIndex: CreatePdfSteps) => {
      setActiveTab(activeStepIndex);
    };
    return subscribeOnActiveIndexChanged(handler);
  }, [subscribeOnActiveIndexChanged]);

  const onPublished = useCallback(() => {
    isAssociatedPackEnabled && setShowAddPacks(true);
    return !isAssociatedPackEnabled && navigate(overviewUrl);
  }, [navigate, isAssociatedPackEnabled]);

  useRtn([PdfPublishSuccess], onPublished);

  const handleCancelClick = useCallback(() => {
    navigationUtils.goBackOrDefault(location, navigate, overviewUrl);
  }, [location, navigate]);

  const handleOnFinish = async () => {
    onFinish?.();
    props.publishPdf(props.pdfId);
  };

  const getPublishedStatus = () => (props.isEntityCreated ? PublishedStatusTypes.draft : null);

  const onPreviousAsync = async (activeStepIndex: number, nextIndex: number) => {
    onPrevious(activeStepIndex, nextIndex);
  };

  const configurationIsValid = isValid && props.isUploaded;

  return (
    <>
      <Wizard
        title="Create PDF"
        finishButtonLabel="Finish"
        onCancel={handleCancelClick}
        onProgress={onNext}
        onRegressAsync={onPreviousAsync}
        onFinish={handleOnFinish}
        publishedStatus={getPublishedStatus()}
        isSaveInProgress={props.isSaving}
        progressSavedDate={showLastModified.has(activeTab) ? props.dateModified : undefined}
      >
        <Section label="Configuration" className="scrollable-content" isLocked={!configurationIsValid} required>
          <Configure
            acceptHandlers={wizardPages[CreatePdfSteps.Configuration]}
            onIsValidChange={setIsValid}
            mode={"create"}
          />
        </Section>
        <Section label="Settings" className="scrollable-content">
          <SettingsTab acceptHandlers={wizardPages[CreatePdfSteps.Settings]} />
        </Section>
      </Wizard>
      <AddAssetsToPackModal
        showModal={showAddPacks}
        onClose={/* istanbul ignore next */ () => navigate(`/${RouteNames.contentPdfs}`)}
        onComplete={() => { }}
        selectedItemIds={[props.pdfId]}
        contentType="Pdf"
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const pdfs = pdfsStateSelector(state);
  return {
    pdfId: pdfs.base.pdfEntityStateReducer.entityId,
    isEntityCreated: pdfs.base.pdfEntityStateReducer.entityId > 0,
    isSaving: pdfs.base.pdfEntityStateReducer.isEntityCommandInProgress,
    dateModified: pdfs.base.pdfEntityStateReducer.lastModifiedDateTime,
    isUploaded: pdfs.base.details.isUploaded,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  publishPdf: bindAction(publishPdf, dispatch),
  onUnmount: () => {
    dispatch(clearPdf());
  },
  resetSearchFilters: () => {
    batch(() => {
      dispatch(reset());
      dispatch(resetTags());
      dispatch(resetSearch());
      dispatch(resetPagination());
      dispatch(resetAppliedFilter());
      dispatch(resetSortingColumnName());
      dispatch(resetSortingDirection());
    });
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreatePdf);
