import ColumnOption from "../../../../interfaces/columnOptions";

export enum Columns {
  Title = "Title", //NOSONAR
  Objective = "Objective",
  Publisher = "Publisher",
  Status = "Status",
  Added = "Added",
  Modified = "Modified",
}

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  title: "title",
  objective: "goalObjectiveId",
  status: "isDraft",
  modified: "dateModified",
  added: "dateCreated",
  publisher: "publisher",
};

export const getColumnOptions = (): ColumnOption[] => [
  {
    name: Columns.Title,
    width: 4,
    isSortable: true,
  },
  {
    name: Columns.Objective,
    width: 3,
    isSortable: true,
  },
  {
    name: Columns.Publisher,
    width: 3,
  },
  {
    name: Columns.Status,
    width: 3,
    isSortable: true,
  },
  {
    name: Columns.Modified,
    width: 4,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
  },
];
