import React from "react";
import { Card, Image } from "semantic-ui-react";

import { Tooltip } from "../../common/tooltip";
import { TextDotDotDot } from "../../textTruncators/TextTruncators";

import FlowEndIcon from "../../../images/flow-end-icon.png";

import "./flowEndCard.scss";

export interface FlowEndCardProps {
  id: string;
  title: string;
}

function FlowEndCard(props: FlowEndCardProps) {
  const { id, title } = props;

  return (
    <Card key={id} className="flow-end-card" tabIndex="0" data-hook-type="flow-designer-flow-end" data-hook-id={id}>
      <div className="thumbnail">
        <Image src={FlowEndIcon} draggable={false} />
      </div>
      <div className="type">
        <Tooltip target={<TextDotDotDot clamp={2}>{title}</TextDotDotDot>} content={title} />
      </div>
    </Card>
  );
}

export default FlowEndCard;
