import React from "react";
import ValidatedForm from "../../../../../../components/forms/ValidatedForm";
import validationSchemas from "../../../../../../utils/validationSchemas";
import "./sendEmailFormContainer.scss";
import { SendEmailForm } from "./sendEmailForm/sendEmailForm";

export interface SendEmailFormContainerProps {
  onSubmit: (email: string) => void;
  email?: string;
  disabled?: boolean;
  hasPermission?: boolean;
}

export const SendEmailFormContainer: React.FC<SendEmailFormContainerProps> = ({
  email,
  onSubmit,
  disabled,
  hasPermission,
}) => {
  const validationSchema = validationSchemas.sendEmailValidationSchema();

  return (
    <div className={"send-email-form-container"}>
      <ValidatedForm
        disablePreventTransitionPrompt
        isInitialValid={true}
        validationSchema={validationSchema}
        initialValues={{ email }}
      >
        {(props) => <SendEmailForm onSubmit={onSubmit} disabled={disabled} hasPermission={hasPermission} {...props} />}
      </ValidatedForm>
    </div>
  );
};
