import React, { FC, useCallback, useRef, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";

import userPermissionActions from "../../state/actions/userPermissionActions";
import ItemsView from "../../../../../views/ItemsView/ItemsView";
import Restricted from "../../../../Application/Restricted";
import NoSearchResults from "../../../../../components/noSearchResults";

import { default as userListUtils } from "../../../../../utils/userListUtils";
import { default as UsersFilterForm } from "../../../../../components/filterForms/UsersFilterForm/UsersFilterForm";
import { UsersTableBody, usersColumnOptions } from "../../../../../components/people/overview/usersTable";
import { getFilterOptions as getFilterOptionsAction } from "../../../../People/Users/UsersOverview/state/filterActionCreators";
import { SearchInput } from "../../../../../components";
import { UsersOverviewProps } from "../types";
import { User } from "../../../../../interfaces";
import { RolePermissions, SortingDirection, ViewType } from "../../../../../enums";
import {
  fetchUsersToBeAssigned as fetchUsersToBeAssignedAction,
  setFilter as setFilterAction,
  resetFilter as resetFilterAction,
  setSearch as setSearchAction,
  resetSearch as resetSearchAction,
} from "../../state/actions/userAssignmentActions";
import { FiltersMap } from "../../../../../utils/filterUtils";
import { DEFAULT_SORTING_COLUMN_NAME } from "../helpers/constants";
import { Filters } from "../../../../../utils/queryUtils";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";

export type UsersOverviewPropsAll = PropsFormRedux & UsersOverviewProps;

export const UsersOverview: FC<UsersOverviewPropsAll> = (props: UsersOverviewPropsAll): React.ReactElement => {
  const reloadListItems = useRef<(enableSorting: boolean) => void>();
  const { resetSearch } = props;

  useEffect(
    () => () => resetSearch(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  let getUsers = userListUtils.getUsersFactory(props.fetchUsersToBeAssigned, props.search);

  const renderSearchInput = (accessRestricted: boolean): React.ReactElement => (
    <SearchInput
      disabled={accessRestricted}
      placeholder="Search for People..."
      defaultValue={props.search}
      onChange={onSearchChanged}
    />
  );

  // @ts-ignore
  const renderFilterForm = (): React.ReactElement => <UsersFilterForm />;
  const renderTableBody = (user: User): React.ReactElement => <UsersTableBody user={user} />;

  const { getFilterOptions, updateUsersAssignment, setFilter, resetFilter } = props;
  const getFilterOptionsMemo = useCallback(() => getFilterOptions(), [getFilterOptions]);
  const onSelectedListItemsChangedMemo = useCallback(
    (userIds: number[]) => updateUsersAssignment(userIds),
    [updateUsersAssignment],
  );
  const applyFilterMemo = useCallback((filter: Filters) => setFilter(filter), [setFilter]);
  const resetFilterMemo = useCallback(() => resetFilter(), [resetFilter]);

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChanged = (search: string): void => {
    props.setSearch(search);
    getUsers = userListUtils.getUsersFactory(props.fetchUsersToBeAssigned, search);
    reloadListItems.current?.(isEmpty(search));
  };

  const renderPeopleList = () => {
    const {
      usersToBeAssigned,
      assignedPeopleIds,
      isLoading,
      usersCount,
      filterOptions,
      appliedFilter,
      customHeaderContent,
      isReadOnly,
    } = props;

    return (
      <Restricted
        permissions={[RolePermissions.UsersView]}
        renderContent={(hasAnyPermission: boolean) => (
          <ItemsView
            viewType={ViewType.LIST}
            columnOptions={usersColumnOptions}
            getData={getUsers}
            itemsInlineCount={usersCount}
            isLoading={isLoading}
            items={usersToBeAssigned}
            buildTableBody={renderTableBody}
            onSelectedListItemsChanged={onSelectedListItemsChangedMemo}
            renderFilterForm={renderFilterForm}
            filterOptions={filterOptions}
            filterOptionsLoading={filterOptions.isLoading}
            applyFilter={applyFilterMemo}
            appliedFilter={appliedFilter}
            resetFilter={resetFilterMemo}
            getFilterOptions={getFilterOptionsMemo}
            selectedIds={assignedPeopleIds}
            sortingDirection={SortingDirection.Descending}
            sortingColumnName={DEFAULT_SORTING_COLUMN_NAME}
            setReloadListItems={createReloadListItems}
            renderSearch={renderSearchInput}
            noResultsContent={<NoSearchResults />}
            customHeaderContent={customHeaderContent}
            accessRestricted={!hasAnyPermission}
            isSelectDisabled={() => isReadOnly}
          />
        )}
      />
    );
  };

  return renderPeopleList();
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  usersToBeAssigned: state.library.userAssignment.usersToBeAssigned.items,
  isLoading: state.library.userAssignment.usersToBeAssigned.isLoading,
  usersCount: state.library.userAssignment.usersToBeAssigned.itemsCount,
  filterOptions: state.people.usersOverview.filterOptions,
  appliedFilter: state.library.userAssignment.filter.appliedFilter,
  search: state.library.userAssignment.search,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch, ownProps: UsersOverviewProps) => ({
  fetchUsersToBeAssigned: (
    skip: number,
    top: number,
    orderParams: string | null,
    filterParams: FiltersMap,
    search: string,
  ) => dispatch(fetchUsersToBeAssignedAction(skip, top, orderParams, filterParams, search)),
  getFilterOptions: () => dispatch(getFilterOptionsAction()),
  updateUsersAssignment: (userIds: number[]) =>
    dispatch(userPermissionActions(ownProps.section).updateUsersAssignment(userIds)),
  setFilter: (filter: Filters) => dispatch(setFilterAction(filter)),
  resetFilter: () => dispatch(resetFilterAction()),
  setSearch: (search: string) => dispatch(setSearchAction(search)),
  resetSearch: () => dispatch(resetSearchAction()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFormRedux = ConnectedProps<typeof connector>;

export default connector(UsersOverview);
