import axios, { AxiosResponse } from "axios";
import { SortingDirection } from "enums";
import { FiltersMap } from "utils/filterUtils";
import { overviewFiltering } from "utils/queryUtils";

type BaseArgs<T = ""> = {
  top?: number;
  skip?: number;
  showPurchased?: boolean;
  published?: boolean;
  filters?: FiltersMap;
  term?: string;
  sortBy?: string;
  sortOrder?: SortingDirection | string;
  contentType: T;
};

export type FlowArgs = BaseArgs<"flow">;
export type VideoArgs = BaseArgs<"video">;
export type PdfArgs = BaseArgs<"pdf">;
export type AssessmentArgs = BaseArgs<"assessment">;
export type SurveyArgs = BaseArgs<"survey">;
export type ExternalEventArgs = BaseArgs<"externalevent">;
export type PackArgs = BaseArgs<"pack">;
export type EmailArgs = BaseArgs<"email">;
export type MessageArgs = BaseArgs<"message">;

export type ContentResponse<TBag = Record<string, any>> = {
  publisherId: number;
  publisher: string;
  id: number;
  title: string;
  description: string;
  dateCreated: string;
  dateModified: string;
  isDraft: boolean;
  isPurchased: boolean;
  hasBeenPublished: boolean;
  ownerId: number;
  dependencies: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
    isEmpty: boolean;
  };
  canBeDeleted: boolean;
  bag: TBag;
  canBePublished: boolean;
};

export type VideoContentResponse = ContentResponse<{
  /**
   * in seconds
   */
  duration: number;
  thumbnailUrl: string;
}>;

export type PdfContentResponse = ContentResponse<{
  thumbnailUrl: string | null;
}>;

export type EmailResponse = ContentResponse<{
  isPurchasedDuplicationAllowed: boolean;
}> & {
  subject: string;
  dateSent: string;
};

export type AssessmentContentResponse = ContentResponse<{
  questionsCount: number;
}>;

export type SurveyContentResponse = ContentResponse<{
  questionsCount: number;
  isBranching: boolean;
}>;

export type ExternalEventContentResponse = ContentResponse<{
  thumbnailUrl: string;
  hasReminders: boolean;
}>;

// Overloads
function getContent<T extends FlowArgs>(params: T, signal?: AbortSignal): Promise<AxiosResponse<ContentResponse[]>>;

function getContent<T extends VideoArgs>(
  params: T,
  signal?: AbortSignal,
): Promise<AxiosResponse<VideoContentResponse[]>>;

function getContent<T extends PdfArgs>(params: T, signal?: AbortSignal): Promise<AxiosResponse<PdfContentResponse[]>>;

function getContent<T extends AssessmentArgs>(
  params: T,
  signal?: AbortSignal,
): Promise<AxiosResponse<ContentResponse[]>>;

function getContent<T extends SurveyArgs>(
  params: T,
  signal?: AbortSignal,
): Promise<AxiosResponse<SurveyContentResponse[]>>;

function getContent<T extends ExternalEventArgs>(
  params: T,
  signal?: AbortSignal,
): Promise<AxiosResponse<ExternalEventContentResponse[]>>;

function getContent<T extends PackArgs>(params: T, signal?: AbortSignal): Promise<AxiosResponse<ContentResponse[]>>;

function getContent<T extends EmailArgs>(params: T, signal?: AbortSignal): Promise<AxiosResponse<EmailResponse[]>>;

function getContent<T extends MessageArgs>(params: T, signal?: AbortSignal): Promise<AxiosResponse<ContentResponse[]>>;
// End Overloads

function getContent<T extends BaseArgs>(params: T, signal?: AbortSignal) {
  const { filters, ...other } = params;
  return axios.get<ContentResponse[]>("/api/content", {
    params: { ...other, ...filters },
    paramsSerializer: overviewFiltering,
    signal: signal,
  });
}

export const contentDataService = {
  getContent,
};
