import { get } from "lodash";

const getPrefix = (prefix: string) => (!!prefix ? `${prefix}.` : "");

export const getAllErrorsLeafs = (errors: object, callback: (key: string, value: string) => void, prefix = "") => {
  if (errors === null || errors === undefined) {
    return;
  }
  if (Array.isArray(errors)) {
    errors.forEach((val: object, index) => {
      getAllErrorsLeafs(val, callback, `${getPrefix(prefix)}${index}`);
    });
    return;
  }
  Object.keys(errors).forEach((key) => {
    const currentError = get(errors, key);
    if (Array.isArray(currentError)) {
      currentError.forEach((val: object, index: number) => {
        getAllErrorsLeafs(val, callback, `${getPrefix(prefix)}${key}.${index}`);
      });
    } else if (typeof currentError !== "string") {
      getAllErrorsLeafs(currentError, callback, `${getPrefix(prefix)}${key}`);
    } else {
      callback(`${getPrefix(prefix)}${key}`, currentError);
    }
  });
};
