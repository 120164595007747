import { FieldArray } from "formik";
import { get, isEqual } from "lodash";
import React from "react";
import { Divider, Icon } from "semantic-ui-react";
import { MIN_QUESTIONS_LENGTH } from "../../constants";
import { QuestionBuilderPropsBase, QuestionFormComponents } from "../../props";
import nameof from "../../../../../utils/nameof";
import { IQuestion, IQuestionBase } from "../../models";

export type AnswersQuestionBuilderProps<TQuestion extends IQuestion<TData>, TData> = QuestionBuilderPropsBase<TData> &
  QuestionFormComponents<TQuestion, TData> & {
    onReplaceQuestion: (index: number) => (question: IQuestionBase) => void;
  };

export const QuestionWithAnswers = <TQuestion extends IQuestion<TData>, TData>(
  props: AnswersQuestionBuilderProps<TQuestion, TData>,
) => {
  const {
    question,
    validatedFieldProps,
    index,
    onBlur,
    onChanged,
    disabled,
    onRemove,
    onReplaceQuestion,
    propertyNamePrefix,
  } = props;
  const { QuestionRenderer, QuestionHeader, AnswersBuilder } = props;

  return (
    <>
      <QuestionHeader
        onQuestionTypeChanged={onReplaceQuestion(index)}
        question={question as TQuestion}
        validatedFieldProps={validatedFieldProps}
        onBlur={onBlur}
        onChanged={onChanged}
        disabled={disabled}
        index={index}
        propertyNamePrefix={propertyNamePrefix}
      />

      <div className="questions-block">
        <div className={"question-content"}>
          <QuestionRenderer
            disabled={disabled}
            index={index}
            onBlur={onBlur}
            onChanged={onChanged}
            question={question as TQuestion}
            validatedFieldProps={validatedFieldProps}
            propertyNamePrefix={propertyNamePrefix}
          />
          <div className={"control-panel-minus"}>
            {!disabled && index >= MIN_QUESTIONS_LENGTH && (
              <Icon className={"minus circle"} size={"big"} color={"blue"} onClick={onRemove(index)} />
            )}
          </div>
        </div>

        <div className="answers-block">
          <FieldArray
            validateOnChange={false}
            name={`${propertyNamePrefix}${nameof<IQuestionBase>("answers")}`}
            render={(answerArrayHelpers) => (
              <AnswersBuilder
                question={question as TQuestion}
                questionId={index}
                arrayHelpers={answerArrayHelpers}
                validatedFieldProps={validatedFieldProps}
                onBlur={onBlur}
                onChanged={onChanged}
                disabled={disabled}
                propertyNamePrefix={propertyNamePrefix}
              />
            )}
          />
          <Divider className="divider-content" />
        </div>
      </div>
    </>
  );
};

/* istanbul ignore next */
const getNeededProps = (props: AnswersQuestionBuilderProps<IQuestion<unknown>, unknown>) => {
  const { arrayHelpers, validatedFieldProps, ...rest } = props;

  const errors = get(props.validatedFieldProps.errors, `questions.${props.index}`);
  const touched = get(props.validatedFieldProps.touched, `questions.${props.index}`);

  return { ...rest, errors, touched };
};

/* istanbul ignore next */
export default React.memo(QuestionWithAnswers, (prev, next) => {
  return isEqual(getNeededProps(prev), getNeededProps(next));
});
