import React from "react";
import { Modal } from "semantic-ui-react";
import modalSizes from "../../../enums/modalSizes";
import { CloseButton } from "../../buttons/closeButton";

import "./overlayModal.scss";

export interface OverlayProps {
  open: boolean;
  children: React.ReactElement;
  onClose: () => void;
}

export const OverlayModal: React.FC<OverlayProps> = ({ open, children, onClose }) => {
  return (
    <Modal open={open} className="overlay" size={modalSizes.large}>
      <CloseButton onClick={onClose} className="overlay-close-button" />
      <Modal.Content className="overlay-content" scrolling>
        {children}
      </Modal.Content>
    </Modal>
  );
};
