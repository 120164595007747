import { ColumnOption } from "../../../../interfaces/columnOptions";
import { Title } from "../../../../components/listViewTemplates";
import { Tooltip } from "../../../../components/common/tooltip";
import { TextTruncate } from "../../../../components";
import { PublishedStatus } from "../../../../components/common/publishedStatus";
import { VideoContentItemMenu } from "./VideoContentItemMenu/VideoContentItemMenu";
import { VideoColumns, VideoOverview } from "../types/models";
import { PublishedStatusTypes, SortOptions } from "../../../../enums";
import dateTimeUtils from "../../../../utils/dateTimeUtils";

export const sortOptions = [
  {
    text: "Most Recent",
    value: SortOptions.CreatedDateDesc,
    default: true,
  },
  {
    text: "Last Modified",
    value: SortOptions.ModifiedDateDesc,
  },
  {
    text: "Sorted A-Z",
    value: SortOptions.Title,
  },
];

export interface GetColumnOptionsParams {
  readonly?: boolean;
  buttonHandlers: {
    handleEditClick: (id: number, isDraft: boolean) => () => void;
    handlePublishClick: (
      id: number,
      isDraft: boolean,
      flowsCount: number,
      assignmentsCount: number,
      hasBeenPublished: boolean,
    ) => () => void;
    handleDeleteClick: (ids: number[], flowsCount?: number, packsCount?: number) => () => void;
    onNavigate: (id: number, isLoading: boolean) => void;
  };
}

export const getColumnOptions = (params: GetColumnOptionsParams): ColumnOption<VideoOverview>[] => {
  return [
    {
      name: VideoColumns.Title,
      width: 4,
      isSortable: true,
      render: (video) => (
        <Title
          url={video.id.toString()}
          title={video.title}
          onTitleClick={() => params.buttonHandlers.onNavigate(video.id, Boolean(video.isLoading))}
          clamp={2}
          className="normal-color"
        />
      ),
    },
    {
      name: VideoColumns.Publisher,
      width: 3,
      isSortable: false,
      render: (video) => <Tooltip target={<TextTruncate>{video.publisher}</TextTruncate>} content={video.publisher} />,
    },
    {
      name: VideoColumns.Status,
      width: 3,
      isSortable: false,
      render: (video) => (
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!video.isDraft)} />
      ),
    },
    {
      name: VideoColumns.Added,
      width: 2,
      isSortable: true,
      render: (video) => dateTimeUtils.formatDate(video.dateCreated),
    },
    {
      name: VideoColumns.Modified,
      width: 2,
      isSortable: true,
      render: (video) => dateTimeUtils.formatDate(video.dateModified),
    },
    {
      name: "",
      width: 1,
      className: "align-right",
      isSortable: false,
      render: (video, isChecked) => {
        return (
          <VideoContentItemMenu
            {...params.buttonHandlers}
            circle
            outlinedEllipsis
            item={video}
            isChecked={isChecked}
            readonly={params.readonly || video.isPurchased}
            hasPermission={!params.readonly}
          />
        );
      },
    },
  ];
};
