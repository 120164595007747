import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../Application/globaltypes/redux";

export interface SendingProfileSettingsValidationState {
  initialized: boolean;
  isFormValid?: boolean;
  errorMode: boolean;
  activeErrors: { [key: string]: string };
  resolved: boolean;
}

export const initialState: SendingProfileSettingsValidationState = {
  initialized: false,
  isFormValid: undefined,
  errorMode: false,
  activeErrors: {},
  resolved: true,
};

const sendingProfileSettingsValidationSlice = createSlice({
  name: "sendingProfileSettingsValidation",
  initialState: initialState,
  reducers: {
    setIsValid(state: SendingProfileSettingsValidationState, action: PayloadAction<boolean>) {
      return {
        ...state,
        isFormValid: action.payload,
      };
    },
    setErrorMode(state: SendingProfileSettingsValidationState, action: PayloadAction<boolean>) {
      return {
        ...state,
        errorMode: action.payload,
      };
    },
    setResolved(state: SendingProfileSettingsValidationState, action: PayloadAction<boolean>) {
      return {
        ...state,
        resolved: action.payload,
      };
    },
    setInitialized(state: SendingProfileSettingsValidationState, action: PayloadAction<boolean>) {
      return {
        ...state,
        initialized: action.payload,
      };
    },
    setActiveErrors(state: SendingProfileSettingsValidationState, action: PayloadAction<{ [key: string]: string }>) {
      return {
        ...state,
        activeErrors: action.payload,
      };
    },
    reset() {
      return { ...initialState };
    },
    discard(state: SendingProfileSettingsValidationState) {
      return { ...state, errorMode: false };
    },
  },
});

export const { setIsValid, setErrorMode, setResolved, setInitialized, setActiveErrors, reset, discard } =
  sendingProfileSettingsValidationSlice.actions;

export const sendingProfileSettingsValidationSelector = (state: RootState) =>
  state.library.threatDefenceSmtpAuthSendingProfiles.settingsValidation;

export default sendingProfileSettingsValidationSlice.reducer;
