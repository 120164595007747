import { combineReducers } from "redux";

import { withFetchingChildEntityListItems } from "../../../../Application/reducers/hoc/withFetchingItems";
import { withFetchingEntityGridItems } from "../../../../Application/reducers/hoc/withFetchingItemsLazy";
import withFilteringItems from "../../../../Application/reducers/hoc/withFilteringItems";
import withFilterOptions from "../../../../Application/reducers/hoc/withFilterOptions";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { fetchGroupOwnersAction } from "./editUserGroupsActionTypes";
import { mergeReducers } from "../../../../../utils/reduxUtils";
import { mergeOwners } from "../../../../../utils/groupsUtils";

const groupOwnersReducer = (state: any, action: any) => {
  state = state || {};
  const { type, error } = action;
  switch (type) {
    case fetchGroupOwnersAction.FETCH_ITEMS_FAILURE:
      return { ...state, error };

    case fetchGroupOwnersAction.FETCH_ITEMS_SUCCESS: {
      return {
        ...state,
        items: mergeOwners(state.items, action.owners),
      };
    }

    default:
      return state;
  }
};

export const editUserGroupsReducer = combineReducers({
  groupsList: mergeReducers(
    withFetchingChildEntityListItems(
      ReducerNamespaceTypes.People,
      ReducerEntityPrefixTypes.EditUser,
      ReducerEntityPrefixTypes.Groups,
    ),
    groupOwnersReducer,
  ),
  groupsGrid: mergeReducers(
    withFetchingEntityGridItems(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.EditUser),
    groupOwnersReducer,
  ),
  filterState: withFilteringItems(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.EditUser),
  filterOptions: withFilterOptions(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.EditUser),
});
