import { AccountsFilterPropertyNames } from "../../../../../components/filterForms/AccountsFilterForm/AccountsFilterForm";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { GenericFiltersMap } from "../../../../../utils/filterUtils";
import { createFiltersSlice, FiltersState } from "../../../../Application/slices/createFiltersSlice";

export const initialState: FiltersState<AccountsFilterPropertyNames> = {
  filterOptions: {} as GenericFiltersMap<AccountsFilterPropertyNames>,
  appliedFilter: {} as GenericFiltersMap<AccountsFilterPropertyNames>,
  isLoading: false,
};

export const accountFilterSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "accountFilters",
  },
  initialState: initialState,
  reducers: {},
});
export const { setAppliedFilter, resetAppliedFilter, setIsLoading, setError, setFilterOptions } =
  accountFilterSlice.actions;

export const accountFiltersReducer = accountFilterSlice.reducer;
