import { RootState } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import {
  selectGoalCards,
  selectGoalEngagementChartInfo,
  selectGoalLine,
  selectGoalLineChart,
  selectGoalTotals,
  selectGoalCardsInfo,
  selectGoalHeaderLabels,
} from "../../state/slices/flowPerformanceSlice";

import { UsageCharts } from "./UsageCharts";

export interface Props extends PropsFromRedux {}

export const FlowApplicationUsage = ({
  goalLineState,
  goalLineChart,
  goalTotalsState,
  goalEngagement,
  goalCardsState,
  goalCardsItems,
  goalHeaders,
}: Props) => {
  return (
    <div>
      {goalHeaders.map((header, index) => (
        <UsageCharts
          key={header}
          header={header}
          goalLineChart={goalLineChart[index]}
          goalLineState={goalLineState}
          goalEngagement={goalEngagement[index]}
          goalTotalsState={goalTotalsState}
          goalCardsItems={goalCardsItems[index]}
          goalCardsState={goalCardsState}
        />
      ))}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    goalLineState: selectGoalLine(state),
    goalLineChart: selectGoalLineChart(state),
    goalTotalsState: selectGoalTotals(state),
    goalEngagement: selectGoalEngagementChartInfo(state),
    goalCardsState: selectGoalCards(state),
    goalCardsItems: selectGoalCardsInfo(state),
    goalHeaders: selectGoalHeaderLabels(state),
  };
};

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowApplicationUsage);
