import { Dispatch, bindActionCreators } from "@reduxjs/toolkit";
import cn from "classnames";
import { max, scaleLinear } from "d3";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "../../../Application/globaltypes/redux";

import { timeScale } from "components/charts/shared/bsi-time-scale";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { setExportAction } from "features/Reporting/state/export/exportSlice";
import { reset, setIsNavigationEnabled, setIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { bindAction } from "interfaces";
import CardReporting from "../../../../components/cards/CardReporting/CardReporting";
import {
  ChartWrapper,
  HorizontalBarChart,
  LineChart,
  TickValueType,
  getDateFormatByCount,
} from "../../../../components/charts";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { RegisterBreadcrumbCallback } from "../../Common/Hooks/usePerformanceBreadcrumbs";
import {
  PerformanceFilter, chartColorScale,
  chartLegendLabels,
  lineChartMargins,
  completes,
  completesColor,
  dailyActivity,
  formattedAverageTime, lineChartTitles,
  noBarData,
  noData,
  roundToTwoDigits,
  starts,
  startsColor,
  totalActivity,
  transitionTime,
  validLineData
} from "../../Common/utils/performanceUtils";
import {
  resetAnswerData,
  selectCardData,
  selectCardsValue,
  selectEngagementCompleted,
  selectEngagementCumulativeCompleted,
  selectEngagementCumulativeInProgress,
  selectEngagementDates,
  selectEngagementFunnelData,
  selectEngagementFunnelValue,
  selectEngagementInProgress,
  selectEngagementLineData,
  selectEngagementQuestionsValue,
  selectPeopleDetailsStatus,
  selectPeopleDetailsValue,
} from "../state/slices/assessmentPerformanceSlice";
import * as assessmentPerformanceActions from "../state/thunks/assessmentPerformanceThunk";
import { AssessmentQuestionAnswer, EngagementQuestionsData } from "../types/state";
import { CardsDataFormatted } from "./AssessmentPerformance";
import AssessmentPerformancePeopleComponent from "./AssessmentPerformancePeople";
import AssessmentPerformanceDrillDown from "./Drilldown/AssessmentPerformanceDrillDown";
import AssessmentPerformanceList from "./List/AssessmentPerformanceList";

import "../../Common/utils/performanceSCSSUtils.scss";
import "./AssessmentPerformance.scss";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";

export interface Props extends PropsFromRedux {
  entityId: number;
  entityTitle: string;
  dateFilter: PerformanceFilter;
  registerBreadcrumb: RegisterBreadcrumbCallback;
  flowId?: number;
  hideDrilldownHeader?: boolean;
}

export type cardsDataFormatted = {
  PassingGrade: string;
  AverageGrade: string;
  AverageTime: string;
  CompletedCount: number;
};

const getSelectedQuestion = (
  selectedQuestion: EngagementQuestionsData | undefined,
  engagementQuestionsData: EngagementQuestionsData[],
  setSelectedQuestionIndex: (value: React.SetStateAction<number>) => void,
) => {
  if (selectedQuestion) {
    const index = engagementQuestionsData.findIndex((question) => question.QuestionId === selectedQuestion.QuestionId);
    setSelectedQuestionIndex(index);
  }
};

export const AssessmentPerformanceBody: FC<Props> = ({
  entityId,
  entityTitle,
  flowId,
  dateFilter,
  registerBreadcrumb,
  hideDrilldownHeader,
  cardStatus,
  cardsData,
  actions,
  setExportAction,

  engagementLineStatus,

  engagementDates,
  engagementInProgress,
  engagementCompleted,
  lineCumulativeCompleted,
  lineCumulativeInProgress,

  engagementFunnelData,
  engagementFunnelStatus,

  engagementQuestionsData,

  resetSelectedAnswerState,
  setIsReportEnabled,
  resetIsReportEnabled,
  setIsNavigationEnabled,
}) => {
  const [newLineChartState, setNewLineChartState] = useState(totalActivity);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState<EngagementQuestionsData>();
  const [selectedAnswer, setSelectedAnswer] = useState<AssessmentQuestionAnswer>();
  const reportEnabled = useFeatureFlag(FeatureFlags.AssessmentReport);

  const horizontalBarDomain = useMemo(() => {
    return [0, max([engagementFunnelData.Started, engagementFunnelData.Completed]) || 1];
  }, [engagementFunnelData]);

  const horizontalBarData = useMemo(
    () => [
      {
        id: "started-bar",
        category: starts,
        value: engagementFunnelData.Started ?? 0,
        fillColor: startsColor,
      },
      {
        id: "completed-bar",
        category: completes,
        value: engagementFunnelData.Completed ?? 0,
        fillColor: completesColor,
      },
    ],
    [engagementFunnelData],
  );

  const lineInfo = useMemo(() => {
    const yData =
      newLineChartState === dailyActivity
        ? [engagementInProgress, engagementCompleted]
        : [lineCumulativeInProgress, lineCumulativeCompleted];
    return {
      xData: [engagementDates, engagementDates],
      yData,
      yFormatterFunc: ",d",
      yScaleRatio: scaleLinear,
      colorRange: chartColorScale,
    };
  }, [
    engagementCompleted,
    engagementDates,
    engagementInProgress,
    lineCumulativeCompleted,
    lineCumulativeInProgress,
    newLineChartState,
  ]);

  const formattedCardsData = useMemo<CardsDataFormatted>(() => {
    return {
      PassingGrade: `${roundToTwoDigits(cardsData.PassingGrade * 100)}%`,
      AverageGrade: `${Math.round(cardsData.AverageGrade * 100)}%`,
      AverageTime: formattedAverageTime(cardsData.AverageTime),
      CompletedCount: cardsData.Completed,
    };
  }, [cardsData]);

  useEffect(() => {
    if (reportEnabled) {
      actions.fetchCardData(entityId, { ...dateFilter, flowId });
      actions.fetchEngagementLineData(entityId, { ...dateFilter, flowId });
      actions.fetchEngagementFunnelData(entityId, { ...dateFilter, flowId });
      actions.fetchPeopleDetails(entityId, { ...dateFilter, flowId });
    }
  }, [actions, dateFilter, entityId, flowId, reportEnabled]);

  useEffect(() => {
    setExportAction({
      method: actions.doExport,
      args: [entityId, { ...dateFilter, flowId }, entityTitle],
      isExportEnabled: true,
    });
  }, [actions.doExport, dateFilter, entityId, selectedAnswer, flowId, setExportAction, entityTitle]);

  const breadCrumbCallback = useCallback(() => {
    setSelectedQuestionIndex(0);
    setSelectedQuestion(undefined);
    setSelectedAnswer(undefined);
  }, []);

  useEffect(
    () => getSelectedQuestion(selectedQuestion, engagementQuestionsData, setSelectedQuestionIndex),
    [selectedQuestion, engagementQuestionsData],
  );

  const handleQuestionSelection = useCallback(
    (question: EngagementQuestionsData) => {
      registerBreadcrumb({ text: question.QuestionText, action: breadCrumbCallback });
      setSelectedQuestion(question);
    },
    [breadCrumbCallback, registerBreadcrumb],
  );

  const selectionExists = useMemo(() => {
    setIsNavigationEnabled(!selectedQuestion);
    return !!selectedQuestion;
  }, [selectedQuestion, setIsNavigationEnabled]);

  const getPrevQuestion = () => {
    const previousIndex = selectedQuestionIndex - 1;
    const wrappedIndex = (previousIndex + engagementQuestionsData.length) % engagementQuestionsData.length;
    setSelectedQuestionIndex(wrappedIndex);
    setSelectedQuestion(engagementQuestionsData[wrappedIndex]);
    setSelectedAnswer(undefined);
    resetSelectedAnswerState();
  };

  const getNextQuestion = () => {
    const nextIndex = selectedQuestionIndex + 1;
    const wrappedIndex = nextIndex % engagementQuestionsData.length;
    setSelectedQuestionIndex(wrappedIndex);
    setSelectedQuestion(engagementQuestionsData[wrappedIndex]);
    setSelectedAnswer(undefined);
    resetSelectedAnswerState();
  };

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const barChart = useMemo(() => {
    if (noBarData(...horizontalBarData.map((d) => d.value))) {
      return noData(dateFilter);
    }
    return (
      <HorizontalBarChart
        {...sharedAccountReportingHorizontalBarProps}
        data={horizontalBarData}
        domain={horizontalBarDomain}
      />
    );
  }, [dateFilter, horizontalBarData, horizontalBarDomain]);

  const renderMain = useCallback(() => {
    return (
      <div className="body">
        <div className="chartsSection">
          <div className="graphs">
            <div className={cn("lineChartContainer", "user_chart_container")}>
              <ChartWrapper
                showLegend
                titles={lineChartTitles}
                legendLabels={chartLegendLabels}
                onChange={setNewLineChartState}
                colorRange={chartColorScale}
              >
                <RequestStatusRenderer state={engagementLineStatus}>
                  {validLineData([...lineInfo.yData]) ? (
                    <LineChart
                      margins={lineChartMargins}
                      xScaleRatio={timeScale}
                      xFormatterFunc={getDateFormatByCount(engagementDates.length)}
                      yTickValueType={TickValueType.IntegersOnly}
                      transitionDuration={transitionTime}
                      {...lineInfo}
                    />
                  ) : (
                    noData(dateFilter)
                  )}
                </RequestStatusRenderer>
              </ChartWrapper>
            </div>
            <div className={cn("funnelChartContainer", "user_chart_container")}>
              <ChartWrapper titles={["Engagement"]}>
                <RequestStatusRenderer state={engagementFunnelStatus}>{barChart}</RequestStatusRenderer>
              </ChartWrapper>
            </div>
          </div>
          <div className="cardSection">
            <RequestStatusRenderer state={cardStatus}>
              <CardReporting
                items={[
                  {
                    statDescription: "Passing Grade",
                    stat: formattedCardsData.PassingGrade,
                    popupHeader: "Passing Grade",
                    popupBody: "Grade needed to pass assessment",
                    width: "8rem",
                  },
                  {
                    statDescription: "Average Grade",
                    stat: formattedCardsData.AverageGrade,
                    popupHeader: "Average Grade",
                    popupBody: "Average user grade for completed assessments",
                    width: "11rem",
                  },
                  {
                    statDescription: "Average Time",
                    stat: formattedCardsData.AverageTime,
                    popupHeader: "Average Time",
                    popupBody: "Average user time to complete the assessment",
                    width: "14.2rem",
                  },
                  {
                    statDescription: `Total ${completes}`,
                    stat: formattedCardsData.CompletedCount,
                    popupHeader: `Total ${completes}`,
                    popupBody: "Total number of times users have completed the assessment",
                    width: "13.75rem",
                  },
                ]}
              />
            </RequestStatusRenderer>
          </div>
          <div className="tableSection">
            <AssessmentPerformanceList
              hideControls={true}
              entityId={entityId}
              flowId={flowId}
              selectQuestion={handleQuestionSelection}
              selectAnswer={setSelectedAnswer}
              dateFilter={dateFilter}
              hasData={
                engagementFunnelStatus.status === RStatus.Got && !noBarData(...horizontalBarData.map((d) => d.value))
              }
            />
          </div>
          <div className="performanceTableSection">
            <AssessmentPerformancePeopleComponent assessmentId={entityId} flowId={flowId} filter={dateFilter} />
          </div>
        </div>
      </div>
    );
  }, [
    barChart,
    cardStatus,
    dateFilter,
    engagementDates.length,
    engagementFunnelStatus,
    engagementLineStatus,
    entityId,
    flowId,
    formattedCardsData.AverageGrade,
    formattedCardsData.AverageTime,
    formattedCardsData.CompletedCount,
    formattedCardsData.PassingGrade,
    handleQuestionSelection,
    horizontalBarData,
    lineInfo,
  ]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return selectionExists ? (
    <AssessmentPerformanceDrillDown
      assessmentId={entityId}
      flowId={flowId}
      selectedQuestionIndex={selectedQuestionIndex}
      totalQuestionCount={engagementQuestionsData.length}
      dateFilter={dateFilter}
      hideHeader={hideDrilldownHeader}
      selectedQuestion={selectedQuestion!}
      selectedAnswer={selectedAnswer}
      onSelectAnswer={setSelectedAnswer}
      onNextQuestion={getNextQuestion}
      onPrevQuestion={getPrevQuestion}
    />
  ) : (
    renderMain()
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    cardStatus: selectCardData(state),
    cardsData: selectCardsValue(state),

    engagementLineStatus: selectEngagementLineData(state),
    engagementDates: selectEngagementDates(state),
    engagementInProgress: selectEngagementInProgress(state),
    engagementCompleted: selectEngagementCompleted(state),
    lineCumulativeCompleted: selectEngagementCumulativeCompleted(state),
    lineCumulativeInProgress: selectEngagementCumulativeInProgress(state),
    engagementFunnelStatus: selectEngagementFunnelData(state),
    engagementFunnelData: selectEngagementFunnelValue(state),

    engagementQuestionsData: selectEngagementQuestionsValue(state),

    peopleDetailsStatus: selectPeopleDetailsStatus(state),
    peopleDetailsData: selectPeopleDetailsValue(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(assessmentPerformanceActions, dispatch),
    resetSelectedAnswerState: bindAction(resetAnswerData, dispatch),
    setExportAction: bindAction(setExportAction, dispatch),
    setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
    resetIsReportEnabled: bindAction(reset, dispatch),
    setIsNavigationEnabled: bindAction(setIsNavigationEnabled, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssessmentPerformanceBody);
