import AccountPacksFilters from "../../../../../enums/licensing/AccountPacksFilters";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { GenericFiltersMap } from "../../../../../utils/filterUtils";
import { RootState } from "../../../../Application/globaltypes/redux";
import { createFiltersSlice, FiltersState } from "../../../../Application/slices/createFiltersSlice";

export const initialState: FiltersState<AccountPacksFilters> = {
  filterOptions: {} as GenericFiltersMap<AccountPacksFilters>,
  appliedFilter: {} as GenericFiltersMap<AccountPacksFilters>,
  isLoading: false,
};

export const accountPacksFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AccountPacks,
    name: "filters",
  },
  initialState: initialState,
  reducers: {},
});

export const { setAppliedFilter, resetAppliedFilter, setIsLoading, setError, setFilterOptions } =
  accountPacksFiltersSlice.actions;

export const appliedFiltersSelector = (state: RootState) => state.packs.accountPacksFilters.appliedFilter;
export const filterOptionsSelector = (state: RootState) => state.packs.accountPacksFilters.filterOptions;
export const isLoadingSelector = (state: RootState) => state.packs.accountPacksFilters.isLoading;

export default accountPacksFiltersSlice.reducer;
