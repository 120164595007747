import { isEqual } from "lodash";
import QhValueMenuItem from "./qhValueMenuItem";
import QhNavigationMenuItem from "./qhNavigationMenuItem";
import QhNavigationBackMenuItem from "./qhNavigationBackMenuItem";
import vjsLanguageUtils from "./utils/vjsLanguageUtils";
import languageUtils from "./utils/languageUtils";
import videoSettingsProvider from "./utils/videoSettingsProvider";

class SettingsMenuConfigurator {
  constructor(player, menu, videoInfo) {
    this.player_ = player;
    this.menu = menu;
    this.player_.selectedSpeed = this.player_.selectedSpeed || this._getSavedSpeedOrDefault();
    this.player_.selectedQuality =
      this.player_.selectedQuality || videoInfo.savedVideoQuality || videoSettingsProvider.quality.default.label;
    this.player_.selectedLanguage =
      this.player_.selectedLanguage || languageUtils.browserCurrentLanguageOrDefault().code;
    this.assetId = videoInfo.assetId;
    this.showCaptionSettings = videoInfo.hasCaptions;
  }

  renderMenu() {
    this.menu.renderMenuItems(this._createItems());
    return this.menu;
  }

  _createItems() {
    const result = [];
    const speedMenuTitle = this._createNavigateBackMenuItem("Speed");
    const speedMenu = this._createNavigateMenuItem("Speed", speedMenuTitle.el());
    videoSettingsProvider.speed.all
      .map((s) => this._createSpeedValueItem(s.label, s.value))
      .forEach((e) => speedMenu.addChild(e));
    result.push(speedMenu);

    const qualityMenuTitle = this._createNavigateBackMenuItem("Quality");
    const qualityMenu = this._createNavigateMenuItem("Quality", qualityMenuTitle.el());
    videoSettingsProvider.quality.all
      .map((s) => this._createQualityValueItem(s.label, s.value))
      .forEach((e) => qualityMenu.addChild(e));
    result.push(qualityMenu);

    if (this.showCaptionSettings) {
      const captionsMenu = this._createCaptionSubMenu();
      result.push(captionsMenu);
    }
    return result;
  }

  _createCaptionSubMenu() {
    const header = this._createNavigateBackMenuItem("Captions");
    const footer = this._createCaptionSubMenuFooter();
    const captionsMenu = this._createNavigateMenuItem("Captions", header.el(), footer);
    const languages = this.player_.options_.textTracks;
    languages.forEach((elem) => {
      captionsMenu.addChild(this._createCaptionValueItem(elem));
    });
    return captionsMenu;
  }

  _createCaptionSubMenuFooter() {
    const footer = document.createElement("div");
    footer.className = "caption-menu-footer";
    const translationLabel = document.createElement("div");
    translationLabel.innerHTML = "Translation by";
    footer.appendChild(translationLabel);
    const translationProviderLabel = document.createElement("div");
    translationProviderLabel.innerHTML = "Microsoft";
    footer.appendChild(translationProviderLabel);
    return footer;
  }

  _createNavigateMenuItem(label, header, title) {
    return new QhNavigationMenuItem(this.player_, {
      label,
      selectable: false,
      onSelected: (menuItem) => {
        this.menu.renderMenuItems(menuItem.children(), header, title);
      },
    });
  }

  _createNavigateBackMenuItem(label) {
    return new QhNavigationBackMenuItem(this.player_, {
      label,
      selectable: false,
      onSelected: () => {
        this.menu.renderMenuItems(this._createItems());
      },
    });
  }

  _createSpeedValueItem(label, value) {
    return new QhValueMenuItem(this.player_, {
      label,
      speed: value,
      selectable: true,
      selected: this.player_.selectedSpeed === label,
      onSelected: this._speedSelected.bind(this),
    });
  }

  _createQualityValueItem(qualityName, qualityValue) {
    return new QhValueMenuItem(this.player_, {
      label: qualityName,
      selectable: true,
      selected: this.player_.selectedQuality === qualityName,
      onSelected: this._qualitySelected.bind(this),
      dashQualityValue: qualityValue,
    });
  }

  _createCaptionValueItem(caption) {
    return new QhValueMenuItem(this.player_, {
      label: caption.label,
      langCode: caption.srclang,
      selectable: true,
      selected: this.player_.selectedLanguage === caption.srclang,
      onSelected: this._captionSelected.bind(this),
    });
  }

  _speedSelected(item) {
    const speed = item.options_.speed;
    this.player_.selectedSpeed = item.options_.label;
    this.player_.playbackRate(speed);
    this.onMenuClose();

    this.player_.trigger("speedchanged", speed);
    sessionStorage.setItem("playerSpeed", speed);
  }

  _getSavedSpeed() {
    return JSON.parse(sessionStorage.getItem("playerSpeed"));
  }

  _getSavedSpeedOrDefault() {
    const savedSpeed = this._getSavedSpeed();
    let speed;

    if (savedSpeed) {
      speed = this._getLabelForSavedSpeed(savedSpeed);
    } else {
      speed = videoSettingsProvider.speed.default.label;
    }
    return speed;
  }

  _getLabelForSavedSpeed(savedSpeed) {
    return videoSettingsProvider.speed.all.filter((s) => {
      return s.value === savedSpeed;
    })[0].label;
  }

  _qualitySelected(item) {
    const videoQualityValue = item.options_.dashQualityValue;
    const videoQualityLabel = item.options_.label;
    this.player_.selectedQuality = item.options_.label;
    if (this.player_.mediaPlayer) {
      this.player_.mediaPlayer.setQualityFor("video", videoQualityValue);
    }
    this.onMenuClose();
    localStorage.setItem("playerVideoQuality", videoQualityLabel);
    this.player_.trigger("qualitychanged", videoQualityValue);
  }

  _captionSelected(item) {
    const languages = this.player_.options_.textTracks;
    const textTrackToSet = vjsLanguageUtils.remoteTextTrack(
      languages.find((language) => isEqual(language.srclang, item.options_.langCode)),
    );
    this.player_.selectedLanguage = item.options_.langCode;

    const ccBtn = this.player_.controlBar.childNameIndex_.CloseCaptionToggleButton;

    ccBtn.setLanguage(textTrackToSet);
    this.onMenuClose();

    this.player_.trigger("langchanged", item.options_.langCode);
  }
}

export default SettingsMenuConfigurator;
