import { isEmpty, once } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { Button } from "components/buttons/button/Button";
import { connect, ConnectedProps } from "react-redux";

import { initialNotifyConfigDefault } from "../../../features/SystemNotifications/config";
import { ModalWithSteps } from "../../modal";
import { ConfirmLicensingStep, PacksContextStep, PriorityLevelsStep } from "../commonSteps";
import NotifyStep from "../commonSteps/notifyStep/NotifyStep";
import { ModalActionConfig, RenderActions, RenderModalActionsType, StepsOptions } from "../types";
import { renderSteps } from "../utils/renderSteps";
import { IPeopleAssignmentModalProps } from "./types";
import { TemplateTypes, RolePermissions, UsersGroupsContext } from "../../../enums";
import { bindAction, NotifyStepSettings } from "../../../interfaces";
import { AppDispatch } from "../../../features/Application/globaltypes/redux";
import { resetState } from "../../../features/SystemNotifications/state/slices/notifyStepSlice";
import Restricted from "../../../features/Application/Restricted";
import useNotifyConfig from "../../../features/SystemNotifications/hooks/useNotifyConfig";
import { NotifyStepSwitch } from "../../notifyStep/NotifyStepSwitch";
import { PeopleType } from "features/Library/PeopleAssignments/types";

import "./peopleAssignmentModal.scss";

export type PeopleAssignmentModalProps = IPeopleAssignmentModalProps & PropsFromRedux;

export const PeopleAssignmentModal: FC<PeopleAssignmentModalProps> = ({
  showModal,
  onPeopleSelected,
  onConfirm,
  onCancel,
  onItemsUpdate,
  itemsPacks,
  priorityItems,
  isStepLoading,
  renderTrigger,
  renderFirstCustomStep,
  usersGroupsContext,
  selectedIds,
  skipNotifyStep = true,
  notifyStepDisabled = false,
  // Default value of notifyTemplateType will be removed after integration to other assets
  notifyTemplateType = TemplateTypes.AddedToFlowV2,
  resetNotificationState,
}) => {
  const [isDataValid, setIsDataValid] = useState(true);
  const [isNotifyTabDataValid, setIsNotifyTabDataValid] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [skipPacksContextStep, setSkipPacksContextStep] = useState(false);
  const [packsContextStepHeader, setPacksContextStepHeader] = useState<string>();
  const onPreviousNotifyStepRef = useRef<() => Promise<void>>();
  const getNotifySettingsRef = useRef<() => Promise<NotifyStepSettings | undefined>>();
  const [notifyConfig, { setNotifyConfig, resetNotifyConfig, shouldNotify, communicationChannel }] =
    useNotifyConfig(initialNotifyConfigDefault);

  useEffect(() => {
    setIsDataLoaded(false);
    setIsDataValid(true);
    setSkipPacksContextStep(false);
    setPacksContextStepHeader(undefined);
  }, [showModal]);

  useEffect(() => {
    !showModal && resetNotifyConfig();
  }, [showModal, resetNotifyConfig]);

  const onResetNotifyStep = () => {
    resetNotificationState();
  };

  const renderModalActionsFirstStep =
    ({ next }: ModalActionConfig) =>
    (nextStep: () => void) =>
    (closeModal: Function) => {
      return (
        <Restricted
          permissions={
            usersGroupsContext === UsersGroupsContext.Users ? [RolePermissions.UsersView] : [RolePermissions.GroupsView]
          }
        >
          <Button
            basic
            color="blue"
            className="cancel"
            content="Cancel"
            onClick={() => {
              onCancel();
              closeModal();
            }}
          />
          <Button
            primary
            className="next"
            content="Next"
            onClick={() => {
              next?.onClick?.();
              nextStep();
            }}
            disabled={isStepLoading || next?.disabled}
          />
        </Restricted>
      );
    };

  const renderModalActionsMiddleStep =
    ({ previous, next }: ModalActionConfig) =>
    (nextStep: () => void, prevStep: () => void) =>
    () => (
      <>
        <Button
          blur
          primary
          className="previous"
          content="Previous"
          onClick={() => {
            previous?.onClick?.();
            prevStep();
          }}
          disabled={isStepLoading || previous?.disabled}
        />
        <Button
          blur
          primary
          className="next"
          content="Next"
          onClick={() => {
            next?.onClick?.();
            nextStep();
          }}
          disabled={isStepLoading || next?.disabled}
        />
      </>
    );

  const renderModalActionsLastStep =
    ({ previous, confirm }: ModalActionConfig) =>
    (_: () => void, prevStep: () => void) =>
    (closeModal: Function) => {
      const confirmHandler = async () => {
        const onClickResult = await confirm?.onClick?.();
        onConfirm(onClickResult);
        closeModal();
      };
      return (
        <>
          <Button
            blur
            primary
            className="previous"
            content="Previous"
            onClick={() => {
              previous?.onClick?.();
              prevStep();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Finish"
            onClick={once(confirmHandler)}
            disabled={confirm?.disabled}
          />
        </>
      );
    };

  const renderPriorityLevelsStep = (renderModalActions: RenderModalActionsType) => (
    <PriorityLevelsStep
      peopleType={usersGroupsContext === UsersGroupsContext.Users ? PeopleType.User : PeopleType.Group}
      header="Preferences"
      renderModalActions={renderModalActions}
      priorityItems={priorityItems}
      onItemsUpdate={onItemsUpdate}
      onIsDataValidChange={setIsDataValid}
    />
  );

  const renderPacksContextStep = (renderModalActions: RenderModalActionsType) => (
    <PacksContextStep
      header={packsContextStepHeader}
      renderModalActions={renderModalActions}
      info={itemsPacks}
      onIsDataValidChange={setIsDataValid}
      noDataLoaded={() => setSkipPacksContextStep(true)}
      setModalStepHeader={() => setPacksContextStepHeader("Licensing")}
    />
  );

  const renderConfirmLicensingStep = (renderModalActions: RenderModalActionsType) => (
    <ConfirmLicensingStep
      header="License Confirmation"
      renderModalActions={renderModalActions}
      info={{
        userIds: usersGroupsContext === UsersGroupsContext.Users ? selectedIds : [],
        groupIds: usersGroupsContext === UsersGroupsContext.Groups ? selectedIds : [],
      }}
      onIsDataValidChange={setIsDataValid}
    />
  );

  const renderNotifyStep = (renderModalActions: RenderModalActionsType) => (
    <NotifyStep
      preRender
      header="Notify"
      renderModalActions={renderModalActions}
      onIsDataValidChange={setIsNotifyTabDataValid}
      onIsDataLoaded={setIsDataLoaded}
      isDataLoaded={isDataLoaded}
      onPreviousNotifyStepRef={onPreviousNotifyStepRef}
      getNotifySettingsRef={getNotifySettingsRef}
      notifyStepDisabled={notifyStepDisabled}
      notifyTemplateType={notifyTemplateType}
      renderSwitch={(switchProps) => (
        <NotifyStepSwitch config={notifyConfig} onNotifyConfigChange={setNotifyConfig} switchProps={switchProps} />
      )}
      shouldNotify={shouldNotify}
      communicationChannel={communicationChannel}
    />
  );

  const notifyStepNotReady = !(isDataLoaded && isNotifyTabDataValid);
  const needNotification = !notifyStepDisabled && shouldNotify;
  const stepsOptions: StepsOptions[] = [
    {
      renderStep: renderFirstCustomStep,
      modalActionConfig: {
        next: {
          disabled: isEmpty(selectedIds),
          onClick: () => onPeopleSelected(selectedIds),
        },
      },
    },
    {
      renderStep: renderPriorityLevelsStep,
      modalActionConfig: {
        previous: {
          disabled: !isDataValid,
        },
        next: {
          disabled: !isDataValid,
          onClick: () => setIsDataValid(false),
        },
      },
    },
    {
      renderStep: renderPacksContextStep,
      skipStep: skipPacksContextStep,
      modalActionConfig: {
        previous: {
          onClick: () => setIsDataValid(true),
        },
        next: {
          disabled: !isDataValid,
          onClick: () => setIsDataValid(false),
        },
      },
    },
    {
      renderStep: renderConfirmLicensingStep,
      modalActionConfig: {
        previous: {
          onClick: () => setIsDataValid(true),
        },
        next: {
          disabled: !isDataValid,
          onClick: () => {
            setIsDataValid(false);
          },
        },
        confirm: {
          disabled: !isDataValid,
        },
      },
    },
    {
      renderStep: renderNotifyStep,
      skipStep: skipNotifyStep,
      modalActionConfig: {
        previous: {
          onClick: () => onPreviousNotifyStepRef?.current?.(),
        },
        confirm: {
          disabled: needNotification && notifyStepNotReady,
          onClick: () => getNotifySettingsRef?.current?.(),
        },
      },
    },
  ];

  const renderActions: RenderActions = {
    renderModalActionsFirstStep,
    renderModalActionsLastStep,
    renderModalActionsMiddleStep,
  };

  return (
    <ModalWithSteps
      className={"people-assignment-modal"}
      scrolling
      renderTrigger={renderTrigger}
      isLoading={isStepLoading}
      showModal={showModal}
      onCancel={onCancel}
      onBeforeClose={onResetNotifyStep}
    >
      {renderSteps(stepsOptions, renderActions)}
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetNotificationState: bindAction(resetState, dispatch),
});

/* istanbul ignore next */
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PeopleAssignmentModal);
