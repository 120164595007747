import { FC } from "react";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterFormPropsBase } from "../types/props";
import { getFlowFilters } from "./flowFilters";
import { FlowFiltersEnum } from "features/Library/Flows/types/models";

export interface FlowFilterFormProps extends FilterFormPropsBase<FlowFiltersEnum> {
  disableDateFilters: boolean;
}

export const FlowFilterForm: FC<FlowFilterFormProps> = ({
  filterOptions,
  filter,
  updateFilter,
  disableDateFilters,
}) => {
  return (
    <FilterFormBuilder
      filterOptions={filterOptions}
      filter={filter}
      updateFilter={updateFilter}
      items={getFlowFilters(true, disableDateFilters)}
    />
  );
};

export default FlowFilterForm;
