import React from "react";
import { Dimmer, Segment } from "semantic-ui-react";

import "./notImplemented.scss";

export interface NotImplementedProps {
  message: string;
  children?: React.ReactNode;
}

export const NotImplemented: React.FC<NotImplementedProps> = ({ message, children }) => {
  return (
    <Dimmer.Dimmable as={Segment} dimmed blurring className="not-implemented-container">
      <Dimmer active inverted>
        <div className="not-implemented-content">
          <h2 className="not-implemented-header">Cool stuff is coming soon!</h2>
          <p className="not-implemented-message">{message}</p>
        </div>
      </Dimmer>
      {children}
    </Dimmer.Dimmable>
  );
};
