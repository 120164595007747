import { FC } from "react";
import { SurveyFiltersEnum } from "../../../features/Library/Surveys/types/models";
import { FilterFormPropsBase } from "../types/props";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { getSurveyFilters } from "./surveyFilters";

export interface SurveyFilterFormForPacksProps extends FilterFormPropsBase<SurveyFiltersEnum> {}

const SurveyFilterFormForPacks: FC<SurveyFilterFormForPacksProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getSurveyFilters(false)}
  />
);

export default SurveyFilterFormForPacks;
