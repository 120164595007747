import { useCallback, useMemo, useState } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import userListUtils from "../../../../utils/userListUtils";
import { usersFilter } from "../../../../components/filterForms/UsersFilterForm/UsersFilterForm";
import { User } from "../../../../interfaces";
import { SortingDirection } from "../../../../enums";
import RoleUsersList from "./RoleUsersList/RoleUsersList";
import UsersToRolesAssignmentModal from "../../Roles/UsersToRolesAssignmentModal/UsersToRolesAssignmentModal";

import "./editPeople.scss";

export interface EditPeopleProps {
  roleId: number;
  isRoleInfoLoading: boolean;
  editRoleActions: { fetchRoleUsers: Function };
  editPeople: {
    list: { items: User[]; isLoading: boolean; itemsCount: number };
    filterOptions: { isLoading: boolean; showRoleFilter: boolean };
  };
  showAddPeopleModal: boolean;
  getFilterOptions: Function;
  addPeopleButton: Function;
  onCloseAddPeopleModal: (isConfirmed: boolean, usersIds?: number[]) => void;
  onRemoveMemberClick(id: number): void;
  onSelectedUsersChanged?: Function;
  goToEditUser: Function;
  isReadOnly: boolean;
  selectedUsers: number[];
  addUsersSearch: string;
}

const EditPeople: React.FC<EditPeopleProps> = ({
  roleId,
  isRoleInfoLoading,
  editPeople,
  editRoleActions,
  onSelectedUsersChanged,
  onRemoveMemberClick,
  getFilterOptions,
  addPeopleButton,
  goToEditUser,
  isReadOnly,
  selectedUsers,
  showAddPeopleModal,
  onCloseAddPeopleModal,
  addUsersSearch,
}) => {
  const [usersAppliedFilter, setUsersAppliedFilter] = useState({});

  const focusedRolesIds = useMemo(() => [roleId], [roleId]);

  const loadMembersPage = useCallback(
    (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection, appliedFilter: any) => {
      const { fetchRoleUsers } = editRoleActions;

      const orderParams = userListUtils.formatOrderParams(sortingColumnName, sortingDirection);
      const filterParams = usersFilter.buildFilterQuery(appliedFilter);

      fetchRoleUsers(roleId, skip, top, orderParams, filterParams);
    },
    [editRoleActions, roleId],
  );

  const resetUserFilter = useCallback(() => setUsersAppliedFilter({}), []);

  const confirmAddingUsers = useCallback(
    (selectedIds: number[]) => onCloseAddPeopleModal(true, selectedIds),
    [onCloseAddPeopleModal],
  );
  const cancelAddingUsers = useCallback(() => onCloseAddPeopleModal(false), [onCloseAddPeopleModal]);

  if (isRoleInfoLoading) {
    return (
      <Dimmer.Dimmable className="edit-people-dimmer" as={Segment} dimmed>
        <Dimmer active inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    );
  }

  return (
    <>
      <RoleUsersList
        users={editPeople.list.items}
        usersCount={editPeople.list.itemsCount}
        isLoading={editPeople.list.isLoading}
        appliedUserFilter={usersAppliedFilter}
        setUserFilter={setUsersAppliedFilter}
        resetUserFilter={resetUserFilter}
        updateSelectedItems={onSelectedUsersChanged}
        loadPage={loadMembersPage}
        addPeopleButton={addPeopleButton}
        onRemoveMemberClick={onRemoveMemberClick}
        filterOptions={editPeople.filterOptions}
        getFilterOptions={getFilterOptions}
        goToEditUser={goToEditUser}
        selectedItemIds={selectedUsers}
        isReadOnly={isReadOnly}
      />
      <UsersToRolesAssignmentModal
        onConfirm={confirmAddingUsers}
        showModal={showAddPeopleModal}
        onCancel={cancelAddingUsers}
        focusedRolesIds={focusedRolesIds}
        addUsersSearch={addUsersSearch}
      />
    </>
  );
};

export default EditPeople;
