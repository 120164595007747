import React, { useEffect, useMemo, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { noop } from "lodash";
import { useFormik } from "formik";

import { bindAction } from "../../../../../interfaces/redux";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { IWizardStep } from "../../../../../interfaces/IWizardStepsManager";
import { videosStateSelector } from "../../state/selectors";
import { SettingsOwnProps } from "./types";
import { updateVideoTags } from "../../state/actions/videosActions";
import { videosTagsSelector } from "../../state/selectors/detailsSelector";
import { fetchVideoTags } from "../../state/thunks/videoBaseThunk";

import SettingsForm from "./SettingsForm/SettingsForm";
import validationSchemas from "../../../../../utils/validationSchemas";
import { saveVideoInfoTagsAction } from "../../state/actions/videoActions";

export type SettingsProps = SettingsOwnProps & IWizardStep & PropsFromRedux;

export const SettingsTab = (props: SettingsProps) => {
  const { videoInfo, normalizedTags, isLoading, hasAnyPermission, entityId, acceptHandlers } = props;
  const initialValues = useMemo(
    () => ({
      labels: videoInfo.labels,
      softwareApplications: videoInfo.softwareApplications,
    }),
    [videoInfo],
  );
  const tagsFormik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemas.videoSettings,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const valuesRef = useRef(tagsFormik.values);

  useEffect(() => {
    acceptHandlers?.({
      onNext: saveTags,
      onPrevious: saveTags,
    });
    props.fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    valuesRef.current = tagsFormik.values;
  }, [tagsFormik.values]);

  const saveTags = () => {
    props.saveTags(valuesRef.current);
  };

  return (
    <div className="stretch scrollable-content edit-form">
      <SettingsForm
        tagsFormik={tagsFormik}
        normalizedTags={normalizedTags}
        updateTags={props.updateTags}
        entityId={entityId}
        videoInfo={videoInfo}
        isDisabled={videoInfo.isPurchased || !hasAnyPermission}
        isLoading={isLoading}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = videosStateSelector(state).base;

  return {
    entityId: base.videoInfoReducer.videoInfo.id || base.videoEntityStateReducer.entityId,
    videoInfo: base.videoInfoReducer.videoInfo,
    normalizedTags: videosTagsSelector(state),
    isLoading: base.videoInfoReducer.isLoadingVideoInfo,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchVideoTags, dispatch),
  updateTags: bindAction(updateVideoTags, dispatch),
  saveTags: bindAction(saveVideoInfoTagsAction, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SettingsTab);
