import { buildFetchAction, buildFetchGridAction } from "../../Application/actions/actionsBuilder";
import ReducerNamespaceTypes from "../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../enums/reducer/reducerEntityPrefixTypes";

export const Assets = "ASSETS";
export const AssetsLazy = "ASSETS_LAZY";

export const Flows = "FLOWS";
export const FlowsLazy = "FLOWS_LAZY";

export const Events = "EVENTS";
export const EventsLazy = "EVENTS_LAZY";

export const Surveys = "SURVEYS";
export const Assessments = "ASSESSMENTS";

export const Pdfs = "PDFS";
export const PdfsLazy = "PDFS_LAZY";

export const FETCH_PEOPLE_ASSETS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  Assets,
);

export const FETCH_PEOPLE_ASSETS_LAZY = buildFetchGridAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  AssetsLazy,
);

export const FETCH_PEOPLE_FLOWS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  Flows,
);

export const FETCH_PEOPLE_FLOWS_LAZY = buildFetchGridAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  FlowsLazy,
);

export const FETCH_PEOPLE_EVENTS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  Events,
);

export const FETCH_PEOPLE_EVENTS_LAZY = buildFetchGridAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  EventsLazy,
);

export const FETCH_PEOPLE_SURVEYS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  Surveys,
);

export const FETCH_PEOPLE_ASSESSMENTS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  Assessments,
);

export const FETCH_PEOPLE_PDFS = buildFetchAction(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.Content, Pdfs);

export const FETCH_PEOPLE_PDFS_LAZY = buildFetchGridAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Content,
  PdfsLazy,
);
