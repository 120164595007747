import React, { FC, useEffect, useRef } from "react";
import { noop } from "lodash";

import RevertConfirmationModalTs from "../../../../../components/modal/RevertConfirmationModalTs";
import { ModalTypes } from "../../../../../components";
import ModalManager from "../../../../../utils/ModalManager";

export interface MessageModalsProps {
  revertModal?: {
    acceptHandler: (handler: () => void) => void;
    onConfirm: () => void;
    userId: number;
    enabled: boolean;
  };
}

export const MessageModalsContainer: FC<MessageModalsProps> = ({ revertModal }) => {
  const revertModalManager = useRef<ModalManager>(
    new ModalManager(ModalTypes.RevertMessage, revertModal?.onConfirm ?? noop, false, revertModal?.userId),
  );

  useEffect(() => {
    revertModal?.acceptHandler(handleRevertClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRevertClick = () => {
    revertModalManager.current.execute();
  };

  if (revertModal?.enabled) {
    return <RevertConfirmationModalTs modalManager={revertModalManager.current} />;
  }
  return <></>;
};
