import EventEmitter from "events";

const eventEmitter = new EventEmitter();

const getEventName = (action, taskId) => `${action}_${taskId}`;

const backgroundTasksEventsEmitter = {
  subscribe: (action, taskId, handler) => {
    eventEmitter.on(getEventName(action, taskId), handler);
  },

  subscribeOnce: (action, taskId, handler) => {
    eventEmitter.once(getEventName(action, taskId), handler);
  },

  unsubscribe: (action, taskId, handler) => {
    eventEmitter.off(getEventName(action, taskId), handler);
  },

  raise: (action, taskId) => eventEmitter.emit(getEventName(action, taskId), taskId),

  updateSubscription: (action, oldTaskId, newTaskId) => {
    const oldEvent = getEventName(action, oldTaskId);
    const newEvent = getEventName(action, newTaskId);
    const handlers = eventEmitter.rawListeners(oldEvent);
    eventEmitter.removeAllListeners(oldEvent);
    handlers.forEach((handler) => {
      eventEmitter.on(newEvent, handler);
    });
  },

  unsubscribeAll: (action, taskId) => {
    eventEmitter.removeAllListeners(getEventName(action, taskId));
  },

  updateHandler: (action, taskId, handler) => {
    const eventName = getEventName(action, taskId);
    eventEmitter.removeAllListeners(eventName);
    eventEmitter.on(eventName, handler);
  },

  updateHandlerOnce: (action, taskId, handler) => {
    const eventName = getEventName(action, taskId);
    eventEmitter.removeAllListeners(eventName);
    eventEmitter.once(eventName, handler);
  },
};

export default backgroundTasksEventsEmitter;
