import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../Application/globaltypes/redux";
import { PackPurchasedView } from "../../types/state";

export interface PackPurchasedViewState {
  pack: PackPurchasedView;
  isLoading: boolean;
  error: string | undefined;
}

const initialState: PackPurchasedViewState = {
  pack: {} as PackPurchasedView,
  isLoading: false,
  error: undefined,
};

const packPurchasedViewSlice = createSlice({
  name: "packPurchasedView",
  initialState: initialState,
  reducers: {
    req(state: PackPurchasedViewState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: PackPurchasedViewState, action: PayloadAction<PackPurchasedView>) {
      return {
        ...state,
        isLoading: false,
        pack: action.payload,
      };
    },
    err(state: PackPurchasedViewState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    issueLicenseBegin: (state: PackPurchasedViewState) => {
      state.isLoading = true;
    },
    issueLicenseAccepted: (state: PackPurchasedViewState) => {
      state.isLoading = false;
    },
    issueLicenseFailure(state: PackPurchasedViewState, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { req, got, err, issueLicenseBegin, issueLicenseAccepted, issueLicenseFailure } =
  packPurchasedViewSlice.actions;

export const packPurchasedViewSelector = (state: RootState) => state.packs.purchasedPack.packPurchasedView.pack;

export default packPurchasedViewSlice.reducer;
export type packPurchasedViewSliceType = ReturnType<typeof packPurchasedViewSlice.reducer>;
