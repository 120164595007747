import { FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../../../Application/globaltypes/redux";
import { ThreatDefenceDirectSendSendingProfile, ThreatDefenceDirectSendSendingProfileView } from "../../types/state";
import * as sendingProfileEntityStateActions from "../../state/actions/threatDefenceSendingProfileEntityStateActions";
import * as sendingProfileDetailsActions from "../../state/actions/threatDefenceSendingProfileDetailsActions";
import { ConfigureSendingProfileProps } from "../types";
import validationSchemas from "../../../../../../../utils/validationSchemas";
import DirectSendSendingProfileForm from "../DirectSendSendingProfileForm/DirectSendSendingProfileForm";
import schemasUtils from "../../../../../../../utils/validationSchemasUtils";
import { AutosaveProps, withAutosave } from "../../../../../../../utils/withAutosave";

export type ConfigureAllProps = ConfigureSendingProfileProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceDirectSendSendingProfileView> &
  AutosaveProps;

export class Configure extends Component<ConfigureAllProps> {
  isSendingProfileCreated = () => {
    return this.props.id > 0;
  };

  componentDidMount() {
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });

    //Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  onNext = () => {
    this.props.isValid && this.saveSendingProfile();

    if (!this.isSendingProfileCreated()) {
      this.props.entityStateActions.createThreatDefenceSendingProfile(Configure.getSendingProfile(this.props.values));
    }
  };

  onPrevious = () => {
    this.saveSendingProfile();
  };

  onClose = () => {
    this.props.detailsActions.clearThreatDefenceSendingProfile();
    this.props.entityStateActions.resetThreatDefenceSendingProfileEntityState();
  };

  saveSendingProfile = () => {
    this.props.detailsActions.saveThreatDefenceDirectSendSendingProfile(this.props.values);
  };

  static getSendingProfile = (
    values: ThreatDefenceDirectSendSendingProfileView,
  ): ThreatDefenceDirectSendSendingProfile => {
    return {
      host: values.host.trim(),
      port: values.port,
      isSsl: values.isSsl,
    };
  };

  render() {
    return (
      <DirectSendSendingProfileForm disablePreventTransitionPrompt={this.isSendingProfileCreated()} {...this.props} />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigureSendingProfileProps) => {
  const sendingProfileDetails =
    state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileDetailsReducer;
  const sendingProfileEntityState =
    state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileEntityStateReducer;

  return {
    id: ownProps.entityId || sendingProfileEntityState.entityId,
    sendingProfile: sendingProfileDetails.sendingProfile,
    isLoading:
      sendingProfileDetails.isLoading ||
      (!sendingProfileDetails.isSendigProfileLoaded && sendingProfileEntityState.entityId > 0),
    isLoaded: sendingProfileDetails.isSendigProfileLoaded,
    isCreating: sendingProfileEntityState.changingEntityState,
    isDraft: sendingProfileDetails.sendingProfile.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(sendingProfileEntityStateActions, dispatch),
  detailsActions: bindActionCreators(sendingProfileDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<
  ConfigureAllProps,
  ThreatDefenceDirectSendSendingProfileView,
  ThreatDefenceDirectSendSendingProfile
>({
  getInitValues: (props) => Configure.getSendingProfile(props.sendingProfile),
  stateProvider: Configure.getSendingProfile,
  entityUpdater: (props) => props.entityStateActions.updateThreatDefenceSendingProfile,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.sendingProfileInfo,
  enableReinitialize: true,
  isInitialValid: (props: ConfigureSendingProfileProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.sendingProfileInfo, props.sendingProfile),
  mapPropsToValues: (props: ConfigureSendingProfileProps & PropsFromRedux) => props.sendingProfile,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connect(mapStateToProps, mapDispatchToProps)(component);
