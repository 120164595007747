import { Component } from "react";
import FormFooter from "../../../../../components/forms/FormFooter";
import UserInfoEditForm from "../../AddEditUserForms/UserInfoEditForm/UserInfoEditForm";
import ValidatedFormProps from "../../../../../components/forms/validatedFormProps";
import { Dimmer, Loader } from "semantic-ui-react";
import { CountryModelItem } from "../../../types";

export interface Label {
  text: string;
  value: string;
}

export interface EditUserInfoProps {
  accountId: number;
  departments: Label[];
  jobTitles: Label[];
  filterOptionActions: {
    getFilterOptions: () => void;
  };
  saveUserInfo: () => void;
  isReadOnly: boolean;
  isLoadingUserInfo?: boolean;
  countries?: CountryModelItem[];
}

class EditUserInfo extends Component<EditUserInfoProps & ValidatedFormProps> {
  componentWillUnmount() {
    this.resetForm();
  }

  onSave = () => {
    const { values, resetForm, saveUserInfo } = this.props;
    saveUserInfo();
    resetForm({ values: { ...values } });
  };

  resetForm = () => {
    const { resetForm, initialValues } = this.props;
    resetForm({ values: { ...initialValues } });
  };

  render() {
    const {
      errors,
      touched,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      values,
      resetForm,
      initialValues,

      isLoadingUserInfo,
      accountId,
      countries = [],
      departments = [],
      jobTitles = [],
      filterOptionActions,
      isReadOnly,
    } = this.props;

    const validatedFieldProps = {
      errors,
      touched,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      values,
      resetForm,
      initialValues,
    };

    return (
      <>
        <Dimmer active={isLoadingUserInfo} inverted>
          <Loader />
        </Dimmer>
        <div className="user-info-form-container stretch scrollable-content edit-form">
          <UserInfoEditForm
            {...validatedFieldProps}
            accountId={accountId}
            isLoading={isLoadingUserInfo || false}
            countries={countries}
            departments={departments}
            jobTitles={jobTitles}
            filterOptionActions={filterOptionActions}
            disabled={isReadOnly}
          />
        </div>
        {!isReadOnly && (
          <FormFooter
            isSaveBtnDisabled={!(dirty && isValid)}
            isCancelBtnDisabled={!dirty}
            onSave={this.onSave}
            onCancel={this.resetForm}
          />
        )}
      </>
    );
  }
}

export default EditUserInfo;
