export const calculateHalfWayToTarget = ({
  fromX,
  fromY,
  toX,
  toY,
}: {
  fromX: number;
  fromY: number;
  toX: number;
  toY: number;
}): { x: number; y: number } => ({
  x: toX - (toX - fromX) / 2,
  y: toY - (toY - fromY) / 2,
});
