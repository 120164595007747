import moment from "moment";
import { createSelector } from "@reduxjs/toolkit";
import { DataPoint } from "../../../../../components/charts/types/HorizontalBarChart";
import { selectTeamsDeviceUsage, selectTeamsUserActivityCounts } from "../graphSlice";
import { selectUsageLineData } from "./deviceSelectors";
import { startsColor } from "features/Library/Common/utils/performanceUtils";

export const totalActivityCounts = createSelector(selectTeamsUserActivityCounts, (state) => {
  return state.value.reduce(
    (prev, curr) => {
      return {
        PrivateChatMessages: prev.PrivateChatMessages + curr.PrivateChatMessages,
        Calls: prev.Calls + curr.Calls,
        Meetings: prev.Meetings + curr.Meetings,
        PostMessages: prev.PostMessages + curr.PostMessages,
      };
    },
    {
      PrivateChatMessages: 0,
      Calls: 0,
      Meetings: 0,
      PostMessages: 0,
    },
  );
});

export const totalActivityPercents = createSelector(totalActivityCounts, (counts) => {
  const totalSum = Object.values(counts).reduce((prev, curr) => prev + curr, 0);
  return {
    PrivateChatMessages: Math.round((counts.PrivateChatMessages / totalSum) * 100) || 0,
    Calls: Math.round((counts.Calls / totalSum) * 100) || 0,
    Meetings: Math.round((counts.Meetings / totalSum) * 100) || 0,
    PostMessages: Math.round((counts.PostMessages / totalSum) * 100) || 0,
  };
});

export const totalActivityBarFormatted = createSelector(totalActivityPercents, (percents): DataPoint[] => {
  let returnVal = Object.entries(percents).map(([category, value]) => {
    return {
      id: category,
      category,
      value,
      fillColor: startsColor,
    };
  });

  returnVal.sort((a, b) => b.value - a.value);
  return returnVal;
});

export const selectActivityOverTimeLine = createSelector(selectTeamsUserActivityCounts, (state) => {
  const totalSize = state.value.length;
  let returnVal = {
    dates: Array<Date>(totalSize),
    PrivateChatMessages: Array<number>(totalSize),
    Calls: Array<number>(totalSize),
    Meetings: Array<number>(totalSize),
    PostMessages: Array<number>(totalSize),
  };
  state.value.forEach((s, index) => {
    returnVal.dates[index] = moment(s.ReportDate).toDate();
    returnVal.PrivateChatMessages[index] = s.PrivateChatMessages;
    returnVal.Calls[index] = s.Calls;
    returnVal.Meetings[index] = s.Meetings;
    returnVal.PostMessages[index] = s.PostMessages;
  });

  return returnVal;
});

function setDeviceValueConditionally(value: number, percentage: number) {
  if (value === 0) return 0;
  return percentage;
}

function getStartingReturnValue(length: number) {
  return {
    dates: Array<Date>(length),
    Android: Array<number>(length),
    ChromeOS: Array<number>(length),
    Linux: Array<number>(length),
    Mac: Array<number>(length),
    Web: Array<number>(length),
    Windows: Array<number>(length),
    WindowsPhone: Array<number>(length),
    iOS: Array<number>(length),
  };
}

export const meetingDeviceLineData = createSelector(
  selectTeamsDeviceUsage,
  selectUsageLineData,
  selectTeamsUserActivityCounts,
  (teamsUsageState, devicePercentages, userActivityState) => {
    const returnVal = getStartingReturnValue(userActivityState.value.length);

    userActivityState.value.forEach((value, i) => {
      const idxInDevicePercentage = teamsUsageState.value.findIndex((v) => v.ReportDate === value.ReportDate);
      returnVal.dates[i] = moment(value.ReportDate).toDate();
      if (idxInDevicePercentage !== -1) {
        returnVal.Android[i] = setDeviceValueConditionally(
          value.Meetings,
          devicePercentages.Android[idxInDevicePercentage],
        );
        returnVal.ChromeOS[i] = setDeviceValueConditionally(
          value.Meetings,
          devicePercentages.ChromeOS[idxInDevicePercentage],
        );
        returnVal.Linux[i] = setDeviceValueConditionally(
          value.Meetings,
          devicePercentages.Linux[idxInDevicePercentage],
        );
        returnVal.Mac[i] = setDeviceValueConditionally(value.Meetings, devicePercentages.Mac[idxInDevicePercentage]);
        returnVal.Web[i] = setDeviceValueConditionally(value.Meetings, devicePercentages.Web[idxInDevicePercentage]);
        returnVal.Windows[i] = setDeviceValueConditionally(
          value.Meetings,
          devicePercentages.Windows[idxInDevicePercentage],
        );
        returnVal.WindowsPhone[i] = setDeviceValueConditionally(
          value.Meetings,
          devicePercentages.WindowsPhone[idxInDevicePercentage],
        );
        returnVal.iOS[i] = setDeviceValueConditionally(value.Meetings, devicePercentages.iOS[idxInDevicePercentage]);
      } else {
        // Maybe we'll throw an error later. For now, push back some default
        returnVal.Android[i] = 0;
        returnVal.ChromeOS[i] = 0;
        returnVal.Linux[i] = 0;
        returnVal.Mac[i] = 0;
        returnVal.Web[i] = 0;
        returnVal.Windows[i] = 0;
        returnVal.WindowsPhone[i] = 0;
        returnVal.iOS[i] = 0;
      }
    });

    return returnVal;
  },
);

export const callDeviceLineData = createSelector(
  selectTeamsDeviceUsage,
  selectUsageLineData,
  selectTeamsUserActivityCounts,
  (teamsUsageState, devicePercentages, userActivityState) => {
    const returnVal = getStartingReturnValue(userActivityState.value.length);

    userActivityState.value.forEach((value, i) => {
      const idxInDevicePercentage = teamsUsageState.value.findIndex((v) => v.ReportDate === value.ReportDate);
      returnVal.dates[i] = moment(value.ReportDate).toDate();
      if (idxInDevicePercentage !== -1) {
        returnVal.Android[i] = setDeviceValueConditionally(
          value.Calls,
          devicePercentages.Android[idxInDevicePercentage],
        );
        returnVal.ChromeOS[i] = setDeviceValueConditionally(
          value.Calls,
          devicePercentages.ChromeOS[idxInDevicePercentage],
        );
        returnVal.Linux[i] = setDeviceValueConditionally(value.Calls, devicePercentages.Linux[idxInDevicePercentage]);
        returnVal.Mac[i] = setDeviceValueConditionally(value.Calls, devicePercentages.Mac[idxInDevicePercentage]);
        returnVal.Web[i] = setDeviceValueConditionally(value.Calls, devicePercentages.Web[idxInDevicePercentage]);
        returnVal.Windows[i] = setDeviceValueConditionally(
          value.Calls,
          devicePercentages.Windows[idxInDevicePercentage],
        );
        returnVal.WindowsPhone[i] = setDeviceValueConditionally(
          value.Calls,
          devicePercentages.WindowsPhone[idxInDevicePercentage],
        );
        returnVal.iOS[i] = setDeviceValueConditionally(value.Calls, devicePercentages.iOS[idxInDevicePercentage]);
      } else {
        // Maybe we'll throw an error later. For now, push back some default
        returnVal.Android[i] = 0;
        returnVal.ChromeOS[i] = 0;
        returnVal.Linux[i] = 0;
        returnVal.Mac[i] = 0;
        returnVal.Web[i] = 0;
        returnVal.Windows[i] = 0;
        returnVal.WindowsPhone[i] = 0;
        returnVal.iOS[i] = 0;
      }
    });

    return returnVal;
  },
);
