import { FormikProps, withFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../../Application/globaltypes/redux";
import FeedbackPageForm from "../Form/FeedbackPageForm";
import { FeedbackPage, FeedbackPageView } from "../../types/state";
import * as feedbackPageEntityStateActions from "../../state/actions/FeedbackPageEntityStateActions";
import * as feedbackPageDetailsActions from "../../state/actions/FeedbackPageDetailsActions";
import { ConfigureFeedbackPageProps } from "../types";
import validationSchemas from "../../../../../../utils/validationSchemas";
import schemasUtils from "../../../../../../utils/validationSchemasUtils";
import { AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";
import * as videoUploadService from "../../services/FeedbackPageVideoUploaderService";
import * as backgroundTasksActions from "../../../../../BackgroundTasks/state/backgroundTasksActions";
import { VideoFeedbackPageTask } from "../../../../../BackgroundTasks/taskPool";
import fileUtils from "../../../../../../utils/fileUtils";

export type ConfigureAllProps = ConfigureFeedbackPageProps &
  PropsFromRedux &
  FormikProps<FeedbackPageView> &
  AutosaveProps;

export const Configure = (props: ConfigureAllProps) => {
  const { onIsValidChange, values, isValid, id, acceptHandlers, setValues, feedbackPage } = props;
  const { createFeedbackPage, resetFeedbackPageEntityState } = props.entityStateActions;
  const { clearFeedbackPage, saveFeedbackPage } = props.detailsActions;

  const [videoUploadTaskId, setVideoUploadTaskId] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>(feedbackPage.videoUrl);

  const isFeedbackPageCreated = useCallback(() => id > 0, [id]);
  const uploadFilesHandler = async (files: FileList) => {
    const uploadedVideoUrl = await videoUploadService.uploadFilesHandler(
      props.backgroundTasksActions,
      files,
      setVideoUploadTaskId,
    );
    setVideoUrl(uploadedVideoUrl);
  };
  const handleFileChange = (files: FileList) => {
    const newValues = {
      ...values,
      uploadedVideos: [fileUtils.toFileLike(files[0])],
    };
    setValues(newValues);
  };
  const cancelFileUploading = () => {
    videoUploadService.cancelFileUploading(props.backgroundTasksActions, videoUploadTaskId);
    const newValues = {
      ...values,
      uploadedVideos: [],
    };
    setValues(newValues);
  };
  const getTask = () => {
    return (props.tasks[videoUploadTaskId] as VideoFeedbackPageTask) || {};
  };

  useEffect(() => {
    const onNext = () => {
      const newValues = {
        ...values,
        videoUrl,
        uploadedVideos: [fileUtils.toFileLike(values.uploadedVideos[0])],
      };
      setValues(newValues);

      saveFeedbackPage(newValues);
      if (!isFeedbackPageCreated()) {
        createFeedbackPage(getFeedbackPage(newValues));
      }
    };

    const onPrevious = () => {
      saveFeedbackPage(values);
    };

    const onClose = () => {
      clearFeedbackPage();
      resetFeedbackPageEntityState();
    };

    acceptHandlers?.({
      onNext: onNext,
      onPrevious: onPrevious,
      onClose: onClose,
    });

    //Synchronizes parent component state with formik onMount validation
    onIsValidChange?.(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onIsValidChange,
    values,
    isValid,
    id,
    createFeedbackPage,
    resetFeedbackPageEntityState,
    clearFeedbackPage,
    saveFeedbackPage,
    isFeedbackPageCreated,
    videoUrl,
  ]);

  const task = getTask();
  return (
    <FeedbackPageForm
      disablePreventTransitionPrompt={isFeedbackPageCreated()}
      uploadFilesHandler={uploadFilesHandler}
      handleFileChange={handleFileChange}
      cancelFileUploading={cancelFileUploading}
      task={task}
      {...props}
    />
  );
};

const getFeedbackPage = (values: FeedbackPageView): FeedbackPage => {
  const uploadedVideos = [fileUtils.toFileLike(values.uploadedVideos[0])];

  return {
    name: values.name.trim(),
    labels: values.labels.selected,
    softwareApplications: values.softwareApplications.selected,
    uploadedVideos,
    videoUrl: values.videoUrl,
  };
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigureFeedbackPageProps) => ({
  id: ownProps.entityId || state.library.threatDefenceFeedbackPages.feedbackPageEntityState.entityId,
  feedbackPage: state.library.threatDefenceFeedbackPages.feedbackPageDetails.feedbackPage,
  isLoading: state.library.threatDefenceFeedbackPages.feedbackPageDetails.isLoading,
  isLoaded: state.library.threatDefenceFeedbackPages.feedbackPageDetails.isFeedbackPageLoaded,
  isCreating: state.library.threatDefenceFeedbackPages.feedbackPageEntityState.changingEntityState,
  isDraft: state.library.threatDefenceFeedbackPages.feedbackPageDetails.feedbackPage.isDraft,
  tasks: state.backgroundTasks.tasks,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(feedbackPageEntityStateActions, dispatch),
  detailsActions: bindActionCreators(feedbackPageDetailsActions, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigureAllProps, FeedbackPageView, FeedbackPage>({
  getInitValues: (props) => getFeedbackPage(props.feedbackPage),
  stateProvider: getFeedbackPage,
  entityUpdater: (props) => props.entityStateActions.updateFeedbackPage,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.feedbackPageInfo,
  enableReinitialize: true,
  isInitialValid: (props: ConfigureFeedbackPageProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.feedbackPageInfo, props.feedbackPage),
  mapPropsToValues: (props: ConfigureFeedbackPageProps & PropsFromRedux) => props.feedbackPage,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connect(mapStateToProps, mapDispatchToProps)(component);
