
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { CreateAssessmentButton } from "./CreateAssessmentButton";
import { RolePermissions } from "../../../../../enums";
import { AddToPacksButton } from "../../../../../components/buttons/AddToPacksButton/AddToPacksButton";
import { DuplicateButton } from "../../../../../components/buttons/linkButtons/headerButtons/DuplicateButton";
import { DeleteButton } from "../../../../../components/buttons/linkButtons/headerButtons/DeleteButton";

import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import TagsManageContainer from "../containers/TagsManageContainer";
import Restricted from "../../../../Application/Restricted";
import useToggle from "../../../../../hooks/useToggle";

export interface AssessmentOverviewHeaderProps {
  selectedIds: Array<number>;
  purchasedSelected: boolean;
  undeletableSelected: boolean;
  draftSelected: boolean;
  deletionHandler: (selectedIds: number[]) => () => void;
  onDuplicate: (selectedIds: number[]) => () => void;
  clearSelection: () => void;
}

function AssessmentOverviewHeader(props: AssessmentOverviewHeaderProps) {
  const { selectedIds, purchasedSelected, undeletableSelected, draftSelected } = props;

  const [packsModalShown, togglePacksModal] = useToggle();

  const renderBatchButtons = () => {
    return (
      <>
        <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
          <AddToPacksButton onClick={togglePacksModal} isDisabled={purchasedSelected || draftSelected} />
        </Restricted>
        <DuplicateButton
          entityName="assessments"
          purchasedSelected={purchasedSelected}
          onClick={props.onDuplicate(selectedIds)}
        />
        <DeleteButton
          entityName="assessments"
          undeletableSelected={undeletableSelected}
          purchasedSelected={purchasedSelected}
          onClick={props.deletionHandler(selectedIds)}
        />
        <TagsManageContainer
          entity="assets"
          isDisabled={purchasedSelected}
          selectedIds={selectedIds}
          onComplete={props.clearSelection}
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={togglePacksModal}
          onComplete={props.clearSelection}
          selectedItemIds={selectedIds}
          contentType="Assessment"
        />
      </>
    );
  };

  return (
    <OverviewHeader
      selectedItemsAmount={selectedIds.length}
      itemName="Assessment"
      title="Assessments"
      titleForGA="Assets - Assessments"
    >
      {selectedIds.some(Boolean) ? renderBatchButtons() : <CreateAssessmentButton />}
    </OverviewHeader>
  );
}

export default AssessmentOverviewHeader;
