import { FC, useCallback, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import PublishValidationInfo from "../../../../../components/publishValidationInfo/PublishValidationInfo";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import { OutstandingDetailsHeaderOwnProps } from "../../../../../components/sectionHeader/detailsHeader/types";
import { getPeopleEntityLabel } from "../../../../../utils/peopleAssignmentModalUtils";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import * as surveyEntityStateActions from "../../state/actions/surveyEntityStateActions";
import { surveysStateSelector } from "../../state/surveyReducer";
import SelectedItemsLabel from "../../../../../components/sectionHeader/selectedItemsLabel/SelectedItemsLabel";
import { useNavigate } from "react-router-dom";
import { AddPacksButton } from "features/Licensing/Packs/AssociatedPacks/AddPacksButton";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { RolePermissions } from "enums";
import Restricted from "features/Application/Restricted";
import { noop } from "lodash";

interface EditSurveyDetailsHeaderContainerPropsBase extends OutstandingDetailsHeaderOwnProps {
  surveyId: number;
  surveysOverviewUrl: string;
  back: () => void;
  onErrorClick: () => void;
  onPublishClick: () => Promise<void>;
  canAddPacks: boolean;
  showAddPacksButton: boolean;
  permissionPredicate?: (userPermissions: RolePermissions[], permissionsList?: RolePermissions[]) => boolean;
}

export type EditSurveyDetailsHeaderProps = EditSurveyDetailsHeaderContainerPropsBase & PropsFromRedux;

export const EditSurveyDetailsHeader: FC<EditSurveyDetailsHeaderProps> = (props: EditSurveyDetailsHeaderProps) => {
  const navigate = useNavigate();
  const { title, selectedUserIds, selectedGroupIds, back, canAddPacks, showAddPacksButton, surveyId, permissionPredicate } = props;
  const [packsModalShown, setPacksModalShown] = useState(false);
  const addToPacksPermissions = [RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage];
  const renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton disabled={!canAddPacks} hasPermission={hasAnyPermission} onClick={() => setPacksModalShown(true)} />
  );

  const renderCustomHeader = () => {
    const hasActiveErrors = !props.contentResolved;
    return (
      props.contentErrorMode && (
        <PublishValidationInfo
          unableToPublish={hasActiveErrors}
          readyToPublish={!hasActiveErrors}
          onErrorButtonClick={props.onErrorClick}
        />
      )
    );
  };

  const onClose = () => navigate(props.surveysOverviewUrl);
  const onEdit = () => props.surveyEntityStateActions.fetchDraftSurveyEntity(surveyId);

  const getHeaderTitle = useCallback(() => {
    const selectedPeopleCount = selectedUserIds.length + selectedGroupIds.length;

    if (selectedPeopleCount) {
      return (
        <SelectedItemsLabel
          itemName={getPeopleEntityLabel(selectedUserIds.length, selectedGroupIds.length)}
          selectedItemsAmount={selectedPeopleCount}
          shouldPluralize={false}
        />
      );
    } else {
      return title;
    }
  }, [selectedGroupIds.length, selectedUserIds.length, title]);

  return (
    <DetailsHeader
      title={getHeaderTitle()}
      pageTitle={title}
      titleForGA="Asset Details"
      backButton={back || props.surveysOverviewUrl}
      defaultURL={props.surveysOverviewUrl}
      canBeEdited={props.canBeEdited}
      crudPermission={props.crudPermission}
      canBePublished={props.canBePublished}
      isRevertVisible={props.isRevertVisible}
      invalidFormDetails={props.invalidFormDetails}
      publishedStatus={props.publishedStatus}
      entityStateActions={{
        onClose: onClose,
        onPublish: props.onPublishClick,
        onEdit: onEdit,
        onRevert: props.entityStateActions.onRevert,
      }}
      hideEntityStateButtons={props.hideEntityStateButtons}
    >
      {props.hideEntityStateButtons || renderCustomHeader()}
      {showAddPacksButton && (
        <Restricted
          permissions={addToPacksPermissions}
          renderContent={(hasanyPermission) => renderAddPackButton(hasanyPermission)}
          permissionPredicate={permissionPredicate}
        />
      )}
      <AddAssetsToPackModal
        showModal={packsModalShown}
        onClose={/* istanbul ignore next */ () => setPacksModalShown(false)}
        selectedItemIds={[surveyId]}
        contentType="Survey"
        onComplete={noop}
      />
    </DetailsHeader>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const surveys = surveysStateSelector(state);
  return {
    contentResolved: surveys.base.surveysContentValidationReducer.resolved,
    contentErrorMode: surveys.base.surveysContentValidationReducer.errorMode,
    selectedUserIds: surveys.edit.users.selectedIds,
    selectedGroupIds: surveys.edit.groups.selectedIds,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  surveyEntityStateActions: bindActionCreators(surveyEntityStateActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditSurveyDetailsHeader);
