import React from "react";
import { Filter, operators } from "../../../utils/filterUtils";
import { FilterFormBuilder, FilterItemBase, FilterViewType } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterFormPropsBase } from "../types/props";

export enum AccountsFilterPropertyNames {
  Name = "name",
  AccountTypeName = "accountTypeName",
  Added = "added",
}

export const accountsFilter = new Filter([
  {
    key: AccountsFilterPropertyNames.AccountTypeName,
    operator: operators.or,
  },
  {
    key: AccountsFilterPropertyNames.Added,
    operator: operators.range,
  },
]);

export interface AccountFilterFormProps extends FilterFormPropsBase<string> {
  datePickersPosition: "top" | "bottom";
}

const AccountsFilterForm: React.FC<AccountFilterFormProps> = ({
  filterOptions,
  filter,
  updateFilter,
  datePickersPosition = "bottom",
}) => {
  const filters: FilterItemBase<string>[] = [
    {
      type: FilterViewType.MultiSelectWithAddition,
      label: "Account Types",
      propertyName: AccountsFilterPropertyNames.AccountTypeName,
      placeholder: "All",
      otherProps: { disabled: true },
    },
    {
      type: FilterViewType.DateRange,
      label: "Added",
      propertyName: AccountsFilterPropertyNames.Added,
      otherProps: { disabled: true, verticalPosition: datePickersPosition, horizontalPositionSecondPicker: "left" },
    },
  ];

  return (
    <FilterFormBuilder filterOptions={filterOptions} filter={filter} updateFilter={updateFilter} items={filters} />
  );
};

export default AccountsFilterForm;
