import { useEffect, useRef, useState } from "react";
import { Checkbox, Icon, Loader, Input } from "semantic-ui-react";
import { TextDotDotDot } from "../../../textTruncators/TextTruncators";
import cn from "classnames";
import globalEvents from "../../../../utils/globalEvents";
import MultipleItems from "../../../multipleItems/MultipleItems";
import { sortBy, intersectionBy, keyBy, omit, pick } from "lodash";

import { FixedSizeList as LazyList } from "react-window";

import "./ChecklistDropdownV2.scss";

const getOrderedItems = <T extends Item>(
  itemsMap: ItemsMap<T>,
  checkedItemsMap: ItemsMap<T>,
  sortPredicate?: (items: T) => unknown,
): T[] => {
  const keys = Object.keys(checkedItemsMap);
  const uncheckedItems = Object.values(omit(itemsMap, keys));
  const checkedItems = Object.values(pick(itemsMap, keys));

  return sortPredicate
    ? sortBy(checkedItems, sortPredicate).concat(sortBy(uncheckedItems, sortPredicate))
    : checkedItems.concat(uncheckedItems);
};

const getCheckedItems = <T extends Item>(items: T[], initialCheckedItems: T[] | undefined) => {
  if (!initialCheckedItems) {
    return {};
  }
  return keyBy(
    intersectionBy(items, initialCheckedItems, (i) => i.id),
    (x) => x.id,
  );
};

type Item = { id: number | string };
type ItemsMap<T extends Item> = { [id: string | number]: T };

const ITEM_SIZE = 40;
const MAX_LIST_HEIGHT = 350;
const getListHeight = (itemsCount: number) => {
  const itemsHeight = itemsCount * ITEM_SIZE + 1; // 1 pixel for correct scroll behavior
  return Math.min(itemsHeight, MAX_LIST_HEIGHT);
};

export interface ChecklistDropdownV2Props<T extends Item> {
  items: T[];
  initialCheckedItems?: T[];
  placeholder?: string;
  isLoading?: boolean;
  onCheckedItemsChanged: (newChecked: T[]) => void;
  isReadOnly?: boolean;
  sortPredicate?: (items: T) => unknown;
  searchPredicate: (item: T, term: string) => boolean;
  getLabel: (item: T) => string;
  getDescription?: (item: T) => string;
}

const ChecklistDropdownV2 = <T extends Item>(props: ChecklistDropdownV2Props<T>) => {
  const { items, initialCheckedItems, searchPredicate, sortPredicate, getLabel, getDescription } = props;

  const listRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<Input>(null);
  const listenerId = useRef("");
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [viewItems, setViewItems] = useState<T[]>([]);

  const [checkedItemsMap, setCheckedItemsMap] = useState(getCheckedItems(items, initialCheckedItems));

  useEffect(() => {
    return () => globalEvents.unsubscribe(listenerId.current);
  }, []);

  const updateListView = (allItems: T[], term: string, transformFiltered?: (filtered: T[]) => T[]) => {
    const filtered = !term ? allItems : allItems.filter((item) => searchPredicate(item, term));

    setViewItems(transformFiltered ? transformFiltered(filtered) : filtered);
  };

  const initListView = (checkedItems: ItemsMap<T>, term: string) => {
    updateListView(items, term, (filtered) =>
      getOrderedItems(
        keyBy(filtered, (x) => x.id),
        checkedItems,
        sortPredicate,
      ),
    );
  };

  useEffect(() => {
    const checkedMap = getCheckedItems(items, initialCheckedItems);
    setCheckedItemsMap(checkedMap);
    initListView(checkedMap, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, initialCheckedItems]);

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const open = () => {
    listenerId.current = globalEvents.subscribeToOutsideClick(listRef.current!, close);
    setIsOpen(true);
    initListView(checkedItemsMap, search);
  };

  const close = () => {
    globalEvents.unsubscribe(listenerId.current);
    setIsOpen(false);
    setSearch("");
  };

  const searchItem = (_: any, { value }: { value: string }) => {
    setSearch(value);
    updateListView(items, value);
  };

  const clearSearchInput = () => {
    // issue with outside click handler
    setTimeout(() => {
      setSearch("");
      initListView(checkedItemsMap, "");
      inputRef.current?.focus();
    }, 0);
  };

  const isClearable = () => {
    return search.length !== 0;
  };

  const checkItem = (checkedItem: T) => {
    let newItems = { ...checkedItemsMap };

    if (checkedItem.id in newItems) {
      newItems = omit(newItems, checkedItem.id);
    } else {
      newItems[checkedItem.id] = checkedItem;
    }

    setTimeout(() => {
      setCheckedItemsMap(newItems);
      props.onCheckedItemsChanged?.(Object.values(newItems));
    }, 0);
  };

  const renderInputContent = () => {
    const { placeholder } = props;
    const checkedItems = Object.values(checkedItemsMap);

    if (checkedItems.length > 0) {
      const checkedNames = checkedItems.map((item) => getLabel(item));

      return <MultipleItems className="input-content" items={checkedNames} singleLine />;
    }

    if (placeholder) {
      return <span className="placeholder">{placeholder}</span>;
    }

    return null;
  };

  const renderDescription = (item: T) => {
    const desc = getDescription?.(item);
    if (desc) {
      // TextTruncate with lines={2} is not working correct here
      return (
        <TextDotDotDot clamp={2} className="item-description text-muted">
          {desc}
        </TextDotDotDot>
      );
    }

    return null;
  };

  const renderInputIcon = (isLoading?: boolean) => {
    return isLoading ? <Loader data-testid="dropdown-loader" active size="mini" /> : <Icon className="expander" />;
  };

  const renderLazyItem = (index: number, style: object) => {
    const item = viewItems[index];
    return (
      <div style={style}>
        <div className="item" data-testid="dropdown-item" onClick={() => checkItem(item)}>
          <Checkbox label={getLabel(item)} checked={!!checkedItemsMap[item.id]} />
          {renderDescription(item)}
        </div>
      </div>
    );
  };

  const renderLazyList = () => {
    if (isOpen) {
      return (
        <LazyList
          className="checklist"
          height={getListHeight(viewItems.length)}
          itemCount={viewItems.length}
          itemSize={ITEM_SIZE}
          width={"100%"}
        >
          {({ index, style }) => renderLazyItem(index, style)}
        </LazyList>
      );
    }
  };

  const { isLoading, isReadOnly } = props;

  return (
    <div
      ref={listRef}
      className={cn("checklist-dropdown-v2", {
        disabled: isLoading || isReadOnly,
      })}
      tabIndex={1}
    >
      <div data-testid="checklist-dropdown" className={cn("checklist-input", { open: isOpen })} onClick={toggle}>
        {renderInputContent()}
        {renderInputIcon(isLoading)}
      </div>
      {isOpen && (
        <Input
          ref={inputRef}
          onChange={searchItem}
          autoFocus={true}
          icon="search"
          iconPosition="left"
          action={{
            icon: isClearable() ? <Icon disabled className="times" /> : undefined,
            onClick: clearSearchInput,
          }}
          value={search}
          fluid
        />
      )}
      {renderLazyList()}
    </div>
  );
};

export default ChecklistDropdownV2;
