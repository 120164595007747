import dataService from "../../../../Application/services/dataServices/dataService";
import midnightService from "../../../../Application/services/midnightService/midnightService";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import DraftEntityTypes from "../../../../../enums/draftEntityTypes";

import * as infoActionTypes from "../actionTypes/infoActionTypes";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import { updateLockedFlowCommandBegin, setExistingFlowEntityAsDraft } from "./flowEntityStateActionCreators";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import { FlowEditSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import { flowInformationSelector } from "../selectors";

const updateFlowInfoBegin = makeActionCreator(infoActionTypes.FLOW_UPDATE_INFO_BEGIN);
export const updateFlowInfoSuccess = makeActionCreator(infoActionTypes.FLOW_UPDATE_INFO_SUCCESS, "info");
const updateFlowInfoFailure = makeActionCreator(infoActionTypes.FLOW_UPDATE_INFO_FAILURE, "error");

export const saveDraftInfo = (baseFlowInfo, midnightFlowInfo) => async (dispatch) => {
  const saveFlowInfoBegin = makeActionCreator(infoActionTypes.FLOW_SAVE_INFO_BEGIN);
  const saveFlowInfoSuccess = makeActionCreator(infoActionTypes.FLOW_SAVE_INFO_SUCCESS, "info");
  const saveFlowInfoFailure = makeActionCreator(infoActionTypes.FLOW_SAVE_INFO_FAILURE, "error");

  dispatch(saveFlowInfoBegin());

  try {
    const result = await midnightService.createLock(DraftEntityTypes.Flows, midnightFlowInfo);

    const info = {
      ...baseFlowInfo,
      id: result.data,
      dateModified: dateTimeUtils.getCurrentDate(),
      isDraft: true,
    };

    dispatch(setExistingFlowEntityAsDraft(info.id));
    dispatch(saveFlowInfoSuccess(info));
  } catch (error) {
    dispatch(saveFlowInfoFailure(error));
  }
};

export const resetFlowInfo = makeActionCreator(infoActionTypes.FLOW_RESET_INFO);

export const fetchFlowInfo = (id, onSuccess) => {
  const fetchFlowInfoBegin = makeActionCreator(infoActionTypes.FLOW_FETCH_INFO_BEGIN);

  const fetchFlowInfoSuccess = makeActionCreator(infoActionTypes.FLOW_FETCH_INFO_SUCCESS, "info");

  const fetchFlowInfoFailure = makeActionCreator(infoActionTypes.FLOW_FETCH_INFO_FAILURE, "error");

  return async (dispatch) => {
    dispatch(fetchFlowInfoBegin());

    try {
      const result = await dataService.getFlowDetails(id);
      const data = result.data;
      dispatch(fetchFlowInfoSuccess(data));
      dispatch(setExistingFlowEntityAsDraft(parseInt(id)));
      onSuccess && onSuccess(data);
    } catch (error) {
      dispatch(fetchFlowInfoFailure(error));
    }
  };
};

export const updateFlowInfo = (info) => async (dispatch) => {
  try {
    dispatch(updateFlowInfoBegin());
    dispatch(updateLockedFlowCommandBegin());
    dispatch(beginAsyncOperation({ id: info.id, action: FlowEditSuccess }));

    await dataService.saveFlowDetails(info);
  } catch (error) {
    dispatch(updateFlowInfoFailure(error));
  }
};

const tagTypeMap = {
  labels: TagsEnum.Label,
  softwareApplications: TagsEnum.SoftwareApplication,
};
export const updateFlowTags = (id, tags, type) => async (dispatch, getState) => {
  const state = getState();

  const newTags = tags.map((tag) => {
    return flowInformationSelector(state).info[type][tag] || { title: tag, isPurchased: false };
  });

  try {
    dispatch(updateFlowInfoBegin());
    dispatch(beginAsyncOperation({ id: id, action: FlowEditSuccess }));

    await dataService.saveFlowTagDetails(id, { tags, tagType: tagTypeMap[type] });

    dispatch(updateFlowInfoSuccess({ [type]: newTags }));
  } catch (error) {
    dispatch(updateFlowInfoFailure(error));
  }
};
