import React from "react";

import Tabs from "../tabs/Tabs";

import "./contentView.scss";

export interface ContentTab {
  label: string;
  component: React.ReactElement;
  id?: string;
}

export interface Props {
  tabs: ContentTab[];
  onTabChange?: (...args: any[]) => any;
  activeIndex?: number;
}

function ContentView({ tabs, onTabChange, activeIndex }: Props) {
  return (
    <Tabs className="content-view" onTabChange={onTabChange} activeIndex={activeIndex || undefined}>
      {tabs.map((tab) => (
        <Tabs.Pane label={tab.label} id={tab.id} key={tab.label}>
          {tab.component}
        </Tabs.Pane>
      ))}
    </Tabs>
  );
}

export default ContentView;
