import React from "react";
import { Icon } from "semantic-ui-react";

import styles from "./InheritedPriorityWarning.module.scss";
import { RouteNames } from "enums";

export type Context = "content" | "people";

interface Props {
  context: Context;
  multi: boolean;
}

export const InheritedPriorityWarning = (props: Props) => {
  const { context, multi } = props;

  return (
    <div className={styles.root}>
      <Icon size="large" className="fa fa-exclamation-circle" />
      <div className={styles.text}>
        <b>Inherited priority.</b> {getMessage(context, multi)}&nbsp;
        <a href={"/" + RouteNames.peopleGroups} target="_blank" rel="noopener noreferrer">
          group(s)
        </a>
        .
      </div>
    </div>
  );
};

const getMessage = (ctx: Context, multi: boolean) => {
  if (!multi) {
    return "The priority of this flow has been set at the group level and cannot be altered for this user until they are removed from the";
  }

  if (ctx === "people") {
    return "The priority of these flows has been set at the group level and cannot be altered for this user until they are removed from the";
  }

  if (ctx === "content") {
    return "The priority of this flow has been set at the group level and cannot be altered for these users until they are removed from the";
  }
};
