import { Dispatch } from "@reduxjs/toolkit";
import accountsDataService from "../../../../Accounts/services/accountsDataService";
import packsDataService from "../../services/packsDataService";
import * as actionTypes from "../actionTypes/packEntityStateActionType";
import {
  fetchBegin as fetchAssignedBegin,
  fetchSuccess as fetchAssignedSuccess,
  fetchFailure as fetchAssignedFailure,
} from "../slices/packAccountTypesVisibilitySlice";
import {
  fetchBegin as fetchAvailableBegin,
  fetchSuccess as fetchAvailableSuccess,
  fetchFailure as fetchAvailableFailure,
} from "../slices/accountTypesAvailableForPackSlice";
import { getActionBaseProvider } from "../../../../Application/actions/actionsBuilder";
import { AccountTypeVisibility, PackAccountType } from "../../PackDetails/Visibility/AccountTypes/types";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import { EditAccountTypePackVisibilitySuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { AppDispatch } from "features/Application/globaltypes/redux";

const fetchAccountTypesVisibility =
  <T>(
    packId: number,
    fetchBegin: any,
    fetchSuccess: any,
    fetchFailure: any,
    getAccountTypesItems: (accountTypes: PackAccountType[], ids: number[]) => T[],
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const packAccountTypeIdsResult = await packsDataService.getAccountTypesVisibilityForPackAsync(packId);
      const packAccountTypeIds = packAccountTypeIdsResult.data;

      const accountTypes = await accountsDataService.getAllAccountTypes();
      const accountTypesItems = getAccountTypesItems(accountTypes as PackAccountType[], packAccountTypeIds);

      dispatch(
        fetchSuccess({
          items: accountTypesItems,
          totalCount: accountTypesItems.length,
        }),
      );
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };

export const fetchAccountTypesVisibilityForPack = (packId: number) => {
  const getAssignedAccountTypesItems = (accountTypes: PackAccountType[], ids: number[]) =>
    accountTypes.filter((i) => ids.includes(i.id));

  return fetchAccountTypesVisibility(
    packId,
    fetchAssignedBegin,
    fetchAssignedSuccess,
    fetchAssignedFailure,
    getAssignedAccountTypesItems,
  );
};

export const fetchAvailableAccountTypesVisibilityForPack = (packId: number) => {
  const getAvailableAccountTypesItems = (accountTypes: PackAccountType[], ids: number[]) =>
    accountTypes.map(
      (accountsType): AccountTypeVisibility => ({
        ...accountsType,
        isPackVisible: ids.includes(accountsType.id),
      }),
    );

  return fetchAccountTypesVisibility(
    packId,
    fetchAvailableBegin,
    fetchAvailableSuccess,
    fetchAvailableFailure,
    getAvailableAccountTypesItems,
  );
};

export const addAccountTypesVisibilityForPack =
  (packId: number, accountTypeIdsToAdd: number[]) => async (dispatch: AppDispatch) => {
    const begin = getActionBaseProvider(actionTypes.updatePackBegin);
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: packId, action: EditAccountTypePackVisibilitySuccess }));
    try {
      const packAccountTypeIdsResult = await packsDataService.getAccountTypesVisibilityForPackAsync(packId);
      await packsDataService.updateAccountTypesVisibilityForPackAsync(packId, {
        visibleForAccountTypeIds: packAccountTypeIdsResult.data.concat(accountTypeIdsToAdd),
      });
    } catch (e) {
      dispatch(fetchAssignedFailure(e as Error));
    }
  };

export const deleteAccountTypesVisibilityForPack =
  (packId: number, accountTypeIdsToRemove: number[]) => async (dispatch: AppDispatch) => {
    const begin = getActionBaseProvider(actionTypes.updatePackBegin);
    dispatch(begin());
    dispatch(beginAsyncOperation({ id: packId, action: EditAccountTypePackVisibilitySuccess }));
    try {
      const packAccountTypeIdsResult = await packsDataService.getAccountTypesVisibilityForPackAsync(packId);
      await packsDataService.updateAccountTypesVisibilityForPackAsync(packId, {
        visibleForAccountTypeIds: packAccountTypeIdsResult.data.filter(
          (acountTypeId: number) => !accountTypeIdsToRemove.includes(acountTypeId),
        ),
      });
    } catch (e) {
      dispatch(fetchAssignedFailure(e as Error));
    }
  };
