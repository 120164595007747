import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { createFetchingItemsSlice, FetchingItemsState } from "features/Application/slices/createFetchingItemsSlice";
import { fetchDepartments } from "../../thunks/addUserThunk";

export const initialState: FetchingItemsState<string> = {
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const departmentsSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.EditAccount,
    name: "Departments",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  thunk: fetchDepartments,
  reducers: {},
});

export default departmentsSlice.reducer;
