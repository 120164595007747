import { Dispatch } from "redux";

import { AppDispatch } from "../globaltypes/redux";
import { createHttpErrorHandler } from "../httpErrorHandler";
import { fetchUserProfileSuccess, setUserProfileMoboDataSuccess } from "../slices/userProfileSlice";
import usersDataService from "../services/dataServices/usersDataService";
import accountsDataService from "features/Accounts/services/accountsDataService";
import packsDataService from "features/Licensing/Packs/services/packsDataService";

export const fetchUserProfile = () => async (dispatch: Dispatch) => {
  const handler = createHttpErrorHandler(dispatch);

  const permissions = await handler(usersDataService.getPermissions);
  const ownAccountInfo = await handler(accountsDataService.getOwnAccountInfo);
  const packIds = await handler(packsDataService.getOwnLicenses);
  const groupIds = await handler(usersDataService.getOwnGroups);

  if (!permissions || !ownAccountInfo) return;

  dispatch(
    fetchUserProfileSuccess({
      permissions: permissions.formattedPermissions,
      hasGlobalAccess: permissions.hasGlobalAccess,
      accountTypeId: ownAccountInfo.accountTypeId,
      packIds: packIds || [],
      groupIds: groupIds || [],
      isDemo: ownAccountInfo.isDemo,
      timeZone: ownAccountInfo.timeZone,
    }),
  );
};

export const setUserProfileMoboData = (moboId: number) => async (dispatch: AppDispatch) => {
  const handler = createHttpErrorHandler(dispatch);
  const moboAccountInfo = await handler(accountsDataService.getOwnAccountInfo);
  if (!moboAccountInfo) return;

  dispatch(
    setUserProfileMoboDataSuccess({
      moboId,
      moboName: moboAccountInfo.name,
      moboLogoUrl: moboAccountInfo.logoUri,
    }),
  );
};
