import React from "react";
import cn from "classnames";
import { Icon, IconProps } from "semantic-ui-react";
import { ReactComponent as AllArea } from "../../images/check-square-solid.svg";

import "./areaIcon.scss";

const withIcon = (className: string) => (props: Readonly<IconProps>) =>
  <Icon className={cn(className, "fixedIconSize")} style={{ fontSize: 20 }} {...props} />;

const iconsMap: Record<string, Function> = {
  All: (props: any) => <AllArea className="icon area-icon" {...props} />,
  Accounts: withIcon("building"),
  People: withIcon("user"),
  Reporting: withIcon("chart pie"),
  Marketplace: withIcon("fa-shopping-cart"),
  Content: withIcon("folder open"),
  "Threat Defense": withIcon("fa-shield-alt"),
};

export interface AreaIconProps {
  areaName: string;
}

const AreaIcon = ({ areaName, ...otherProps }: AreaIconProps) => {
  const component = iconsMap[areaName];
  return component(otherProps);
};

export default AreaIcon;
