import { ElementAndPosition, Point } from "../../types";

import { IFlowNode, IReactFlowEntityType } from "../../ReactFlowCanvas/nodes/types";

export default function getNodesWithPositions(nodes: IFlowNode[]): ElementAndPosition[] {
  let itemCoordsExtended: ElementAndPosition[] = [];
  for (let el of nodes) {
    const topLeft: Point = {
      x: Math.round(el.position.x),
      y: Math.round(el.position.y),
    };
    itemCoordsExtended.push({
      id: el.id,
      type: el.type as IReactFlowEntityType,
      topLeft,
    });
  }
  return itemCoordsExtended;
}
