import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import StatusCode from "../../../enums/httpStatusCodes";

export interface AppErrorState {
  errorCode?: StatusCode;
  message?: string;
  isErrorState: boolean;
  container: string;
}

const initial: AppErrorState = {
  isErrorState: false,
  container: "app-error-root",
};

const slice = createSlice({
  name: "appError",
  initialState: initial,
  reducers: {
    setError(state: AppErrorState, action: PayloadAction<{ errorCode?: StatusCode; message?: string }>) {
      const { errorCode, message } = action.payload;
      state.errorCode = errorCode;
      state.message = message;
      state.isErrorState = true;
    },
    setIsInErrorState(state, action: PayloadAction<boolean>) {
      state.isErrorState = action.payload;
    },
    reset(state: AppErrorState) {
      state.isErrorState = false;
      state.errorCode = undefined;
      state.message = undefined;
    },
    setContainer(state: AppErrorState, action: PayloadAction<string>) {
      state.container = action.payload;
    },
  },
});

export const { reset, setError, setContainer, setIsInErrorState } = slice.actions;

export default slice.reducer;
