import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { some, isEmpty, partial } from "lodash";
import { Icon } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";

import { RootState, AppDispatch } from "../../../Application/globaltypes/redux";

import * as usersOverviewActionsRedux from "./state/usersOverviewActions";
import * as usersFilterActionsRedux from "./state/filterActionCreators";
import * as addToContentActionsRedux from "../../state/slices/addToContentSlice";
import * as groupsOverviewActionsRedux from "../../Groups/GroupsOverview/state/groupsOverviewActions";
import * as groupsFilterActionsRedux from "../../Groups/GroupsOverview/state/filterActions";
import * as generalLoaderActionsRedux from "../../../Application/actions/generalLoader/generalLoaderActions";
import * as rolesOverviewActionsRedux from "../../RolesOverview/state/rolesOverviewActions";
import * as rolesFilterActionsRedux from "../../RolesOverview/state/filterActionCreators";
import * as backgroundTasksActionsRedux from "../../../BackgroundTasks/state/backgroundTasksActions";
import * as notificationsActionsRedux from "../../../Notifications/state/notificationsActions";

import { DropdownItem, MultiStateButton } from "../../../../components/buttons/multiStateButton/MultiStateButton";
import { DeleteLinkButton, ExportLinkButton, AddToLinkButton } from "../../../../components/buttons/linkButtons";
import EllipsisPopupButton from "../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { OverviewHeader } from "../../../../components/sectionHeader";
import {
  RolesAssignmentModal,
  GroupsAssignmentModal,
  ContentAssignmentModal,
  UserGroupsAssignment,
  UserRolesAssignment,
} from "../../../../components/assignmentModals";
import { DeleteUsersConfirmationModal } from "../../../../components/people";
import UsersFileUploadModal from "../../../../components/usersFileUploadModal/UsersFileUploadModal";

import {
  UserDeleteAllSuccess,
  UserDeleteAllFailure,
  SucceedUserImport,
  AddUserToGroupAllSuccess,
} from "../../../Application/services/realTimeNotification/events/people/peopleEvents";
import {
  RolePermissions,
  AddPeopleToContentTypes,
  AssignmentPeopleContext,
  Strings,
  TemplateTypes,
} from "../../../../enums";
import { assignUsersToRoleTask } from "../backgroundTasks/assignUsersToRoleTask";
import { setSearch, resetSearch } from "./state/searchActionCreators";

import UserList from "../UserList/UserList";
import backgroundTask from "../../../BackgroundTasks/backgroundTask";
import Restricted from "../../../Application/Restricted";
import dataService from "../../../Application/services/dataServices/typedDataService";
import { getAddContentHeaderOptions } from "../../AddContentButtonOptions/AddContentButtonOptions";
import { bindAction, NotifyStepSettings } from "../../../../interfaces";
import AddUserButton from "./AddUserButton";
import { addUsersToGroups, deleteUsers } from "../../state/thunks/peopleThunk";
import { withRouter, WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { shouldShowModalForGroupMembership } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import { LDProps } from "../../../LDProps";
import { PeopleType } from "features/Library/PeopleAssignments/types";

const usersListViewRtnEvents = [
  UserDeleteAllSuccess,
  UserDeleteAllFailure,
  SucceedUserImport,
  AddUserToGroupAllSuccess,
];

const createPermissions = [RolePermissions.UsersCreate];
const managePermissions = [RolePermissions.UsersManage];

type PropsFromRedux = ConnectedProps<typeof connector>;

interface UsersOverviewProps extends WithRouterProps {}

interface UsersOverviewState {
  selectedUsers: number[];
  showUsersFileUploadModal: boolean;
  isDeleteConfirmationOpened: boolean;
  focusedUserId: number;
  showAddToGroupModal: boolean;
  showAddToRoleModal: boolean;
  showAddToContentModal: boolean;
  addUsersToGroups: {
    userIds: number[];
    groupIds: number[];
  };
  addToRolesUserIds: number[];
  droppedFile: File | null;
}

const initialState: UsersOverviewState = {
  selectedUsers: [],
  showUsersFileUploadModal: false,
  isDeleteConfirmationOpened: false,
  focusedUserId: 0,
  showAddToGroupModal: false,
  showAddToRoleModal: false,
  showAddToContentModal: false,
  addUsersToGroups: {
    userIds: [],
    groupIds: [],
  },
  addToRolesUserIds: [],
  droppedFile: null,
};

export interface UsersOverviewCombinedProps extends UsersOverviewProps, PropsFromRedux, LDProps {}

export class UsersOverview extends Component<UsersOverviewCombinedProps, UsersOverviewState> {
  state = initialState;

  componentWillUnmount = () => {
    this.props.resetSearch();
  };

  reloadListItems?: (enableSorting: boolean) => void;

  goToAddUser = () => {
    this.props.navigate("add");
  };

  goToEditUser = (userId: number) => {
    this.props.navigate(userId.toString());
  };

  onCloseUsersFileUploadModal = () => {
    this.setState({ showUsersFileUploadModal: false });
  };

  onAddToGroup = (userId: number) => {
    this.onAddToGroupModal([userId]);
  };

  onAddToRole = (userId: number) => {
    this.onAddToRoleModal([userId]);
  };

  onAddToContent = (userId: number, contentType: AddPeopleToContentTypes) => {
    this.onAddToContentModal([userId], contentType);
  };

  onSelectedUsersChanged = (ids: number[]) => {
    this.setState({ selectedUsers: ids });
  };

  onDeleteUser = (id: number) => {
    this.setState({ focusedUserId: id, isDeleteConfirmationOpened: true });
  };

  onFileDropped = (file: any) => {
    this.setState({
      droppedFile: file,
      showUsersFileUploadModal: true,
    });
  };

  onFileDisposed = () => {
    this.setState({
      droppedFile: null,
    });
  };

  onDeleteUsersConfirm = async (userIds: number[]) => {
    this.setState({
      focusedUserId: 0,
      selectedUsers: [],
      isDeleteConfirmationOpened: false,
    });

    this.props.deleteUsers(userIds);
  };

  onDeleteUsersCancel = () => {
    this.setState({
      focusedUserId: 0,
      isDeleteConfirmationOpened: false,
    });
  };

  onAddUsersConfirm = async (notifyStepSettings?: NotifyStepSettings) => {
    const { groupIds, userIds } = this.state.addUsersToGroups;
    this.onSelectedUsersChanged([]);
    this.onCloseAddToGroupModal();
    this.props.addUsersToGroups(userIds, groupIds, notifyStepSettings);
  };

  onCloseAddToGroupModal = () => {
    this.props.resetLicensingModal();
    this.setState({
      showAddToGroupModal: false,
      addUsersToGroups: { userIds: [], groupIds: [] },
    });
  };

  onAddToGroupModal = (userIds: number[]) => {
    this.props.usersOverviewActions.fetchUserGroupsAssignments(userIds);
    this.setState((state) => ({
      showAddToGroupModal: true,
      addUsersToGroups: {
        ...state.addUsersToGroups,
        userIds,
      },
    }));
  };

  onAddToRoleModal = (userIds: number[]) => {
    this.props.usersOverviewActions.fetchUserRolesAssignments(userIds);
    this.setState(() => ({
      showAddToRoleModal: true,
      addToRolesUserIds: userIds,
    }));
  };

  onAddToContentModal = (userIds: number[], contentType: AddPeopleToContentTypes) => {
    this.props.addToContentActions.setSelectedContentType(contentType);
    this.props.addToContentActions.setSelectedPeopleIds(userIds);
    this.props.addToContentActions.setSelectedPeopleType(AssignmentPeopleContext.User);
  };

  /* istanbul ignore next */
  onExportUsers = (ids: number[]) => {
    dataService.users.startUsersExport({ userIds: ids, contentType: "text/csv" });
  };

  onGroupsSelected = (groupIds: number[]) => {
    const { userIds } = this.state.addUsersToGroups;
    const { shouldShowLicensingModal } = this.props;

    this.setState((state) => ({
      addUsersToGroups: {
        ...state.addUsersToGroups,
        groupIds,
      },
    }));

    return new Promise<boolean>(async (resolve) => {
      const shouldShow = await shouldShowLicensingModal(groupIds, userIds);
      resolve(shouldShow);
    });
  };

  renderAddToGroupModal = () => {
    const {
      groupsFilterOptions,
      accountId,
      groupsFilterActions,
      groupsToAdd,
      userGroupsAssignments,
      groupsOverviewActions,
      isShouldShowModalLoading,
    } = this.props;

    const { showAddToGroupModal, addUsersToGroups } = this.state;

    return (
      <GroupsAssignmentModal
        accountId={accountId}
        showModal={showAddToGroupModal}
        fetchGroups={groupsOverviewActions.fetchGroups}
        groups={groupsToAdd.items}
        groupsCount={groupsToAdd.itemsCount}
        isGroupsLoading={groupsToAdd.isLoading || userGroupsAssignments.isLoading}
        userGroupsAssignments={userGroupsAssignments.items as UserGroupsAssignment[]}
        fetchFilterOptions={groupsFilterActions.fetchFilterOptions}
        filterOptions={groupsFilterOptions}
        selectedUserIds={addUsersToGroups.userIds}
        onGroupsSelected={this.onGroupsSelected}
        onConfirm={this.onAddUsersConfirm}
        onCancel={this.onCloseAddToGroupModal}
        notifyTemplateType={TemplateTypes.AddedToGroup}
        isStepLoading={isShouldShowModalLoading}
      />
    );
  };

  onConfirmContent = () => {
    this.onSelectedUsersChanged([]);
  };

  renderAddToContentModal = () =>
    this.props.showAddToContentModal && (
      <ContentAssignmentModal onConfirm={this.onConfirmContent} peopleType={PeopleType.User} />
    );

  onCloseAddToRolesModal = () => {
    this.setState({
      showAddToRoleModal: false,
      addToRolesUserIds: [],
    });
  };

  onAddToRolesConfirm = async (roleIds: number[]) => {
    const userIds = this.state.addToRolesUserIds;

    this.onSelectedUsersChanged([]);
    this.onCloseAddToRolesModal();

    const {
      backgroundTasksActions: { addOperationV1 },
      notificationsActions: { sendTransientNotification },
    } = this.props;

    const params = assignUsersToRoleTask(userIds, roleIds);
    if (params) {
      await backgroundTask.updateEntity(params, {
        addOperation: addOperationV1,
        sendTransientNotification,
      });
    }
  };

  renderAddToRoleModal = () => {
    const {
      rolesFilterOptions,
      accountId,
      rolesFilterActions,
      rolesToAdd,
      userRolesAssignments,
      rolesOverviewActions: { fetchRoles },
    } = this.props;

    const { showAddToRoleModal, addToRolesUserIds } = this.state;

    return (
      showAddToRoleModal && (
        <RolesAssignmentModal
          dataLoader={fetchRoles}
          accountId={accountId}
          rolesCount={rolesToAdd.itemsCount}
          isListLoading={rolesToAdd.isLoading}
          roles={rolesToAdd.items}
          filterActions={rolesFilterActions}
          filterOptions={rolesFilterOptions}
          selectedUserIds={addToRolesUserIds}
          onConfirm={this.onAddToRolesConfirm}
          showModal={showAddToRoleModal}
          onCancel={this.onCloseAddToRolesModal}
          userRolesAssignments={userRolesAssignments.items as UserRolesAssignment[]}
        />
      )
    );
  };

  renderAddContentButton = () => {
    const { selectedUsers } = this.state;
    const options: DropdownItem[] = getAddContentHeaderOptions(partial(this.onAddToContentModal, selectedUsers));

    return some(selectedUsers) ? (
      <MultiStateButton
        id="add-to"
        isLinkStyle
        trigger={<AddToLinkButton text={Strings.modalTitles.addContent} />}
        items={options}
        simple
      />
    ) : null;
  };

  renderAddRoleButton = () =>
    some(this.state.selectedUsers) && (
      <AddToLinkButton text="Add To Role" onClick={() => this.onAddToRoleModal(this.state.selectedUsers)} />
    );

  renderAddGroupButton = () =>
    some(this.state.selectedUsers) && (
      <AddToLinkButton text="Add To Group" onClick={() => this.onAddToGroupModal(this.state.selectedUsers)} />
    );

  renderEllipsisPopupButtons = () => {
    return (
      some(this.state.selectedUsers) && (
        <EllipsisPopupButton
          trigger={
            <Button className="ellipsis-button">
              <Icon color="blue" className="ellipsis horizontal" />
            </Button>
          }
        >
          <DeleteLinkButton onClick={() => this.setState({ isDeleteConfirmationOpened: true })} />
        </EllipsisPopupButton>
      )
    );
  };

  renderExportUsersButton = () =>
    some(this.state.selectedUsers) && <ExportLinkButton onClick={() => this.onExportUsers(this.state.selectedUsers)} />;

  renderAddUserButton = () => {
    const { selectedUsers } = this.state;

    return (
      <AddUserButton
        selectedUsers={selectedUsers}
        createPermissions={createPermissions}
        onCreateUser={this.goToAddUser}
        onImportUsers={() =>
          this.setState({
            showUsersFileUploadModal: true,
          })
        }
      />
    );
  };

  renderCsvUsersUploadModal = () => {
    const {
      accountId,
      actorId,
      usersFileUploadStatus,
      usersOverviewActions,
      uploadedUsersFileColumns,
      usersImportPreviewData,
      backgroundTasksActions,
      showGeneralLoaderWithTimeout,
      backgroundTasks,
    } = this.props;
    const { showUsersFileUploadModal, droppedFile } = this.state;
    return (
      <UsersFileUploadModal
        showGeneralLoaderWithTimeout={showGeneralLoaderWithTimeout}
        addBackgroundTask={backgroundTasksActions.addTask}
        removeBackgroundTask={backgroundTasksActions.deleteTask}
        cancelBackgroundTask={backgroundTasksActions.cancelTask}
        accountId={accountId}
        actorId={actorId}
        showModal={showUsersFileUploadModal}
        onClose={this.onCloseUsersFileUploadModal}
        isFileUploading={usersFileUploadStatus.isUploading}
        droppedFile={droppedFile}
        onFileDisposed={this.onFileDisposed}
        uploadProgress={usersFileUploadStatus.progress}
        uploadError={usersFileUploadStatus.error}
        uploadUsersFile={usersOverviewActions.uploadUsersFile}
        getUploadedFileColumns={usersOverviewActions.fetchUploadedFileColumns}
        getUsersImportPreviewData={usersOverviewActions.fetchUsersImportPreviewData}
        uploadedUsersFileColumns={uploadedUsersFileColumns}
        cancelUsersFileUpload={usersOverviewActions.cancelUsersFileUpload}
        resetUsersFileUpload={usersOverviewActions.resetUsersFileUpload}
        updateUsersFileUploadProgress={usersOverviewActions.updateUsersFileUploadProgress}
        usersImportPreviewData={usersImportPreviewData}
        resetUsersImportPreviewData={usersOverviewActions.resetUsersImportPreviewData}
        backgroundTasks={backgroundTasks}
      />
    );
  };

  isAnyUserExists = () => {
    const { usersList, appliedFilter } = this.props;
    return (some(usersList.items) || !isEmpty(appliedFilter)) && isEmpty(this.state.selectedUsers);
  };

  createReloadListItems = (reloadListItems: (enableSorting: boolean) => void) => {
    this.reloadListItems = reloadListItems;
  };

  onSearchChange = (search: string): void => {
    this.props.setSearch(search);
    this.reloadListItems?.(isEmpty(search));
  };

  render() {
    const { accountId, usersList, filterOptions, appliedFilter, usersOverviewActions, usersFilterActions, search } =
      this.props;

    const { selectedUsers, focusedUserId } = this.state;
    const focusedUserIds = focusedUserId ? [focusedUserId] : selectedUsers;
    return (
      <section className="nested-content users">
        <OverviewHeader title="Users" itemName="User" selectedItemsAmount={selectedUsers.length}>
          {this.isAnyUserExists() && (
            <Restricted permissions={createPermissions}>
              <div className="drag-drop-title">Drag &amp; Drop to Upload Users</div>
            </Restricted>
          )}
          {this.renderAddRoleButton()}
          {this.renderAddGroupButton()}
          {this.renderAddContentButton()}
          {this.renderExportUsersButton()}
          {this.renderEllipsisPopupButtons()}
          {this.renderAddUserButton()}
        </OverviewHeader>
        <Restricted
          permissions={managePermissions}
          renderContent={(hasAnyPermission) => (
            <UserList
              accountId={accountId}
              users={usersList.items}
              usersCount={usersList.itemsCount}
              isLoading={usersList.isLoading}
              filterOptions={filterOptions}
              appliedFilter={appliedFilter}
              search={search}
              setReloadListItems={this.createReloadListItems}
              onSearchChange={this.onSearchChange}
              usersOverviewActions={usersOverviewActions}
              usersFilterActions={usersFilterActions}
              onSelectedUsersChanged={this.onSelectedUsersChanged}
              renderAddUserButton={this.renderAddUserButton}
              onAddToGroup={this.onAddToGroup}
              onAddToRole={this.onAddToRole}
              onAddToContent={this.onAddToContent}
              goToEditUser={this.goToEditUser}
              onDeleteUser={this.onDeleteUser}
              onFileDropped={this.onFileDropped}
              selectedIds={selectedUsers}
              hasDragAndDrop={hasAnyPermission}
              disabledByPermission={!hasAnyPermission}
              listViewRtnEvents={usersListViewRtnEvents}
              permissions={createPermissions}
            />
          )}
        />
        <Restricted permissions={createPermissions}>{this.renderCsvUsersUploadModal()}</Restricted>
        <DeleteUsersConfirmationModal
          isOpen={this.state.isDeleteConfirmationOpened}
          selectedUsers={focusedUserIds}
          onCancel={this.onDeleteUsersCancel}
          onContinue={() => {
            this.onDeleteUsersConfirm(focusedUserIds);
          }}
        />
        {this.renderAddToGroupModal()}
        {this.renderAddToRoleModal()}
        {this.renderAddToContentModal()}
      </section>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { userProfile } = state;
  const { usersOverview, groupsOverview, rolesOverview } = state.people;
  return {
    accountId: state.userProfile.accountId,
    permissions: userProfile.permissions,
    actorId: state.userProfile.id,
    usersList: usersOverview.usersList,
    filterOptions: { showRoleFilter: true, ...usersOverview.filterOptions },
    appliedFilter: usersOverview.appliedFilter,
    search: usersOverview.search,
    usersFileUploadStatus: usersOverview.usersFileUploadStatus,
    uploadedUsersFileColumns: usersOverview.uploadedFileColumns,
    usersImportPreviewData: usersOverview.usersImportPreviewData,
    userGroupsAssignments: usersOverview.userGroupsAssignments,
    userRolesAssignments: usersOverview.userRolesAssignments,
    groupsToAdd: groupsOverview.groupsList,
    groupsFilterOptions: groupsOverview.filterOptions,
    rolesFilterOptions: rolesOverview.filterOptions,
    backgroundTasks: state.backgroundTasks.tasks,
    rolesToAdd: rolesOverview.overviewList,
    groupsAppliedFilter: groupsOverview.groupsFilterState.appliedFilter,
    showAddToContentModal: state.people.addToContentModal.showModal,
    isShouldShowModalLoading: state.licensing.licensingModal.isLoading,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  usersOverviewActions: bindActionCreators(usersOverviewActionsRedux, dispatch),
  groupsOverviewActions: bindActionCreators(groupsOverviewActionsRedux, dispatch),
  rolesOverviewActions: bindActionCreators(rolesOverviewActionsRedux, dispatch),
  usersFilterActions: bindActionCreators(usersFilterActionsRedux, dispatch),
  addToContentActions: bindActionCreators(addToContentActionsRedux, dispatch),
  groupsFilterActions: bindActionCreators(groupsFilterActionsRedux, dispatch),
  rolesFilterActions: bindActionCreators(rolesFilterActionsRedux, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActionsRedux, dispatch),
  notificationsActions: bindActionCreators(notificationsActionsRedux, dispatch),
  setSearch: bindActionCreators(setSearch, dispatch),
  resetSearch: bindActionCreators(resetSearch, dispatch),
  showGeneralLoaderWithTimeout: bindActionCreators(generalLoaderActionsRedux.showGeneralLoaderWithTimeout, dispatch),
  addUsersToGroups: bindActionCreators(addUsersToGroups, dispatch),
  deleteUsers: bindActionCreators(deleteUsers, dispatch),
  shouldShowLicensingModal: bindAction(shouldShowModalForGroupMembership, dispatch),
  resetLicensingModal: bindAction(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(withLDConsumer()(UsersOverview)));
