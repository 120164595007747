import { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import RoleConfiguration from "./../../CreateRoles/RoleConfiguration/RoleConfiguration";
import FormFooter from "../../../../components/forms/FormFooter";

import "./editInfo.scss";

const EditInfo = ({ roleInfo, isLoading, isReadOnly, updateRoleInfo }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const submitFormRef = useRef();
  const resetFormRef = useRef();

  const currentConfiguration = {
    name: roleInfo.name,
    description: roleInfo.description,
  };

  const bindResetForm = (resetForm) => {
    resetFormRef.current = resetForm;
  };
  const bindSubmitForm = (submitForm) => {
    submitFormRef.current = submitForm;
  };
  const onSubmit = async (newRoleInfo) => {
    await updateRoleInfo(roleInfo.id, newRoleInfo.name, newRoleInfo.description);
  };

  return (
    <>
      <Dimmer.Dimmable as={Segment} dimmed={isLoading} className="role-edit-info-container scrollable-content">
        <Dimmer active={isLoading} inverted>
          <Loader />
        </Dimmer>
        <div className="stretch scrollable-content edit-form role-edit-info">
          <RoleConfiguration
            currentConfiguration={currentConfiguration}
            isReadOnly={isReadOnly}
            bindSubmitForm={bindSubmitForm}
            bindResetForm={bindResetForm}
            onIsValidChange={setIsValid}
            onDirtyChanged={setIsDirty}
            onSubmit={onSubmit}
            isEdit
          />
        </div>
      </Dimmer.Dimmable>
      {!isReadOnly && (
        <FormFooter
          isSaveBtnDisabled={!(isDirty && isValid)}
          isCancelBtnDisabled={!isDirty}
          onSave={submitFormRef.current}
          onCancel={resetFormRef.current}
        />
      )}
    </>
  );
};

EditInfo.propTypes = {
  roleInfo: PropTypes.object,
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  updateRoleInfo: PropTypes.func,
};

export default EditInfo;
