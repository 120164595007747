import { FiltersMap } from "../../../../../utils/filterUtils";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/packUsersAvailableForLicensingSlice";
import userListUtils from "../../../../../utils/userListUtils";
import { SortingDirection } from "../../../../../enums";
import nameof from "../../../../../utils/nameof";
import { UserInfo, v2FilterMap } from "../../../../People/types";
import packsDataService from "../../services/packsDataService";
import { GetUsersWithAccessTypeRequest } from "../../types/requests";
import { formatFiltersV2api, mapToV2Filters } from "../../../../../utils/queryUtils";
import { escapeTerm } from "../../../../../utils/searchUtils";

interface AvailableUsersPagingParams {
  skip: number;
  top: number;
  sortingColumnName: string;
  sortingDirection: SortingDirection;
  appliedFilter: FiltersMap;
  search?: string;
}

export const fetchUsersAvailableForLicensing =
  (
    packId: number,
    { search, appliedFilter, sortingDirection, sortingColumnName, skip, top }: AvailableUsersPagingParams,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const orderByParams = userListUtils.formatOrderParamsV2(
        sortingColumnName || nameof<UserInfo>("id"),
        sortingDirection || SortingDirection.Descending,
      );
      const [sortBy, sortDirection] = orderByParams?.split(" ") || [undefined, undefined];
      const params: GetUsersWithAccessTypeRequest = {
        packId,
        skip,
        top,
        sortBy: sortBy,
        sortOrder: sortDirection,
        ...formatFiltersV2api(mapToV2Filters(appliedFilter, v2FilterMap)),
        term: escapeTerm(search),
      };

      const res = await packsDataService.getAvailablePackUsers(params);
      dispatch(
        fetchSuccess({
          items: res.items,
          totalCount: res.count,
        }),
      );
    } catch (e: any) {
      dispatch(fetchFailure(e));
    }
  };
