import React from "react";
import { Button } from "components/buttons/button/Button";

import NoResults from "../noResults/NoResults";

interface Props {}

const AccessRestrictedMessage: React.FC<Props> = (): React.ReactElement => (
  <NoResults
    title="Access Restricted"
    description="You don't have access to this area. Contact your administrator for more information."
    iconClassName="fa-lock-alt"
    outline={false}
    dataId={"access-restricted"}
  >
    <Button primary>Request Access</Button>
  </NoResults>
);

export default AccessRestrictedMessage;
