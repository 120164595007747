import React from "react";
import useNotifyConfig from "../hooks/useNotifyConfig";
import { INotifiable, NotifyStepConfigPayload } from "../types";

export interface WithNotifyConfigProps<T> {
  notifyConfigPayload: NotifyStepConfigPayload<T>;
  notifyConfig: T;
}

export const withNotifyConfig = <T extends WithNotifyConfigProps<TConfig>, TConfig>(
  Component: React.ComponentType<T>,
  initialNotifyConfig: INotifiable<TConfig>,
): React.ComponentType<Omit<T, keyof WithNotifyConfigProps<TConfig>>> => {
  return (props: Omit<T, keyof WithNotifyConfigProps<TConfig>>) => {
    const [notifyConfig, notifyConfigPayload] = useNotifyConfig(initialNotifyConfig);

    const combinedProps = {
      ...props,
      notifyConfig,
      notifyConfigPayload,
    } as T & WithNotifyConfigProps<TConfig>;

    return <Component {...combinedProps} />;
  };
};
