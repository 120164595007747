import { Popup } from "semantic-ui-react";
import cn from "classnames";

import styles from "./CardReporting.module.scss";
import { useDimensions } from "hooks/useDimensions";

export interface CardReportingItem {
  stat: string | number;
  statDescription: string;
  popupHeader?: string;
  popupBody?: string;
  width?: string;
  onClick?: () => void;
}

export interface CardReportingProps {
  items: CardReportingItem[];
}

const CardReporting = ({ items }: CardReportingProps) => {
  const results = items.map((card) => {
    return <Card card={card} key={card.statDescription} />;
  });
  return <div className={styles.reportCardGroup}>{results}</div>;
};

const Card = ({ card }: { card: CardReportingItem }) => {
  const [dimensions, measureRef] = useDimensions();

  const measuredWidth = dimensions?.width || "auto";

  return (
    <Popup
      key={card.statDescription}
      trigger={
        <div
          ref={measureRef}
          onClick={card.onClick}
          className={cn(styles.reportCard, { [styles.onClick]: card.onClick })}
        >
          <div className={styles.popupInfo}></div>
          <p className={styles.stat}>{card.stat?.toLocaleString() ?? 0}</p>
          <p className={styles.statDescription}>{card.statDescription}</p>
        </div>
      }
      content={
        <div className={styles.tooltipMessage} style={{ width: card.width ?? measuredWidth }}>
          {card.popupHeader && <h2>{card.popupHeader}</h2>}
          {card.popupBody && <p>{card.popupBody}</p>}
        </div>
      }
      id={styles.popupDark}
      position="top center"
      disabled={!card.popupBody && !card.popupHeader}
      on={["hover", "focus"]}
    />
  );
};

export default CardReporting;
