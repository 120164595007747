import { FC, useMemo } from "react";
import { Icon, Loader } from "semantic-ui-react";

import "./reportingExport.scss";

export interface Props {
  onClick: () => void;
  currentlyExporting: boolean;
  disabled?: boolean;
}

export const ReportingExport: FC<Props> = ({ onClick, currentlyExporting, disabled = false }) => {
  const shouldUseDisabledView = useMemo(() => currentlyExporting || disabled, [currentlyExporting, disabled]);
  return (
    <button className="reporting-export-button" onClick={onClick} disabled={shouldUseDisabledView} type="button">
      {currentlyExporting ? (
        <Loader active />
      ) : (
        <>
          <Icon className="fal fa-download" />
          <span>Export</span>
        </>
      )}
    </button>
  );
};
