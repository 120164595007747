import React, { useEffect, useRef, useState } from "react";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";

import validationSchemas from "../../../../../utils/validationSchemas";
import TaskStatuses from "../../../../../enums/taskStatuses";
import pdfUploaderService from "../../services/pdfUploaderService";
import fileUtils from "../../../../../utils/fileUtils";
import {
  addTask,
  deleteTask,
  updateId,
  updateProgress,
  updateTask,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";

import { ValidatedForm } from "../../../../../components/forms";
import { PdfTask } from "../../../../BackgroundTasks/taskPool";
import { updatePdfFile, uploadPdf } from "../../state/thunks/pdfDetailsThunk";
import { createPdf } from "../../state/actions/pdfEntityStateActions";
import { uploadPdfCancel } from "../../state/slices/pdfDetailsSlice";
import { RootState } from "../../../../Application/globaltypes/redux";
import { FileLike } from "../../../../../interfaces";
import { getFilenameWithoutExtension } from "../../../../../utils/stringUtils";
import { bindAction } from "../../../../../interfaces/redux";
import { FormikProps } from "formik";
import { EntityMode, PdfFormFields } from "../types";
import { PdfFormPropsAll } from "./PdfForm";

interface UploadPdfFieldProps {
  id: number;
  value: FileLike[];
  disabled: boolean;
  firstUpload: boolean;
  onFileUpload: (fileName: string, uploadedPdfs: FileLike[], title: string, description: string) => void;
  formik: { [key in keyof FormikProps<PdfFormFields>]: PdfFormPropsAll[key] };
  mode: EntityMode;
}

export type UploadPdfFieldPropsAll = UploadPdfFieldProps & PropsFromRedux;

export const UploadPdfField = (props: UploadPdfFieldPropsAll) => {
  const [taskId, setTaskId] = useState<number>(-1);
  const [task, setTask] = useState<PdfTask>({} as PdfTask);
  const canCancel = props.mode === "create";
  const idProvider = useRef(props.id);

  useEffect(() => {
    idProvider.current = props.id;
  }, [props.id]);

  useEffect(() => {
    taskId > 0 && setTask((props.tasks[taskId] || {}) as PdfTask);
  }, [props.tasks, taskId]);

  const handlePdfFileChange = (files: FileLike[]) => {
    const file = files[0];
    const fileName = getFilenameWithoutExtension(file.name);

    props.onFileUpload(
      file.name,
      [fileUtils.toFileLike(file)],
      props.firstUpload ? fileName : props.formik.values.title,
      props.firstUpload ? fileName : props.formik.values.description,
    );
  };

  const pdfUploaderServiceRef = useRef(
    pdfUploaderService({
      canCancel,
      createPdf: props.createPdf,
      updatePdfFile: props.updatePdfFile,
      uploadPdf: props.uploadPdf,
      uploadPdfCancel: props.uploadPdfCancel,
      idProvider: idProvider,
      onTaskIdChanged: setTaskId,
      tasksActions: {
        addTask: props.addBackgroundTask,
        deleteTask: props.deleteBackgroundTask,
        updateTask: props.updateBackgroundTask,
        updateId: props.updateBackgroundTaskId,
        updateProgress: props.updateBackgroundTaskProgress,
      },
    }),
  );

  return (
    <ValidatedForm.UploadFileField
      id="fileUpload"
      label="PDF"
      loadingButtonLabel="Loading..."
      accept="application/pdf"
      propertyName="uploadedPdfs"
      value={props.value}
      disabled={props.disabled}
      filesValidator={validationSchemas.getUploadedPdfConfigurationSchema().fields.uploadedPdfs}
      onChangeHandler={handlePdfFileChange}
      uploadFilesHandler={pdfUploaderServiceRef.current.uploadFilesHandler}
      uploadingError={TaskStatuses.isFailed(task.status)}
      isUploading={task.isPdfUploading}
      uploadingProgress={task.percent}
      onCancelFileUploading={pdfUploaderServiceRef.current.onCancelFileUploading}
      canCancel
      markAsRequired
      showPercents={false}
      {...props.formik}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  tasks: state.backgroundTasks.tasks,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  uploadPdf: bindAction(uploadPdf, dispatch),
  uploadPdfCancel: bindAction(uploadPdfCancel, dispatch),
  createPdf: bindAction(createPdf, dispatch),
  updatePdfFile: bindAction(updatePdfFile, dispatch),
  addBackgroundTask: bindAction(addTask, dispatch),
  deleteBackgroundTask: bindAction(deleteTask, dispatch),
  updateBackgroundTask: bindAction(updateTask, dispatch),
  updateBackgroundTaskId: bindAction(updateId, dispatch),
  updateBackgroundTaskProgress: bindAction(updateProgress, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UploadPdfField);
