import { noop } from "lodash";
import { batch } from "react-redux";

import { AppDispatch, AppThunk } from "../../../Application/globaltypes/redux";
import { AddPeopleToContentTypes } from "../../../../enums";
import { setError, setFilterOptions, setIsLoading } from "../slices/contentAssignmentsOverviewFiltersSlice";
import { getFilterOptions as getVideoFilters } from "../../../Library/Videos/state/thunks/videoFiltersThunk";
import { getFilterOptions as getAssessmentFilters } from "../../../Library/Assessments/state/thunks/assessmentFiltersThunk";
import { getFilterOptions as getFlowFilters } from "../../../Library/Flows/state/thunks/flowFiltersThunk";
import { getFilterOptions as getPdfsFilters } from "../../../Library/Pdfs/state/thunks/pdfFiltersThunk";

export const fetchContentAssignmentsOverviewFilterOptions =
  (contentType: AddPeopleToContentTypes) => (dispatch: AppDispatch) => {
    const map = {
      [AddPeopleToContentTypes.Videos]: fetchVideoFilters,
      [AddPeopleToContentTypes.Assessments]: fetchAssessmentFilters,
      [AddPeopleToContentTypes.PDFs]: fetchPdfFilters,
      [AddPeopleToContentTypes.Surveys]: noop as any,
      [AddPeopleToContentTypes.Events]: noop as any,
      [AddPeopleToContentTypes.Flows]: fetchFlowFilters,
    };

    return dispatch(map[contentType]());
  };

const fetchOptionsTemplate = (dispatch: AppDispatch) => {
  return {
    begin: () => dispatch(setIsLoading(true)),
    success: (options: any) =>
      batch(() => {
        dispatch(setFilterOptions(options));
        dispatch(setIsLoading(false));
      }),
    failure: (error: any) =>
      batch(() => {
        dispatch(setError(error));
        dispatch(setIsLoading(false));
      }),
  };
};

const fetchVideoFilters = (): AppThunk => async (dispatch: AppDispatch) => {
  await getVideoFilters({ ...fetchOptionsTemplate(dispatch), showPurchased: true }, true);
};

const fetchPdfFilters = (): AppThunk => async (dispatch: AppDispatch) => {
  await getPdfsFilters({ ...fetchOptionsTemplate(dispatch), showPurchased: true }, true);
};

export const fetchAssessmentFilters = (): AppThunk => async (dispatch: AppDispatch) => {
  await getAssessmentFilters({ ...fetchOptionsTemplate(dispatch), showPurchased: true }, true);
};

export const fetchFlowFilters = (): AppThunk => async (dispatch: AppDispatch) => {
  await getFlowFilters({ ...fetchOptionsTemplate(dispatch), showPurchased: true }, true);
};
