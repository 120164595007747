import React, { useEffect, useState } from "react";
import { Modal as SemanticModal } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import cn from "classnames";

import ModalSizes from "../../enums/modalSizes";
import ModalHeader from "./header/ModalHeader";
import { WithTestId } from "../../types";

import "./modal.scss";

export interface Props extends WithTestId {
  title?: string;
  open?: boolean;
  className?: string;
  onOk?: () => void;
  onCancel?: () => void;
  onBeforeClose?: () => void;
  renderModalActions?: (closeModal: () => void) => React.ReactElement;
  renderTrigger?: (openModal: () => void) => React.ReactNode;
  size?: ModalSizes;
  alert?: React.ReactNode;
  scrolling?: boolean;
}

const Modal: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    title,
    children,
    dataTestId,
    open,
    onOk,
    onCancel,
    onBeforeClose,
    renderModalActions,
    renderTrigger,
    className,
    size,
    scrolling = true,
  } = props;

  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(!!open);
  }, [open]);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    onBeforeClose?.();
    setIsOpen(false);
  };

  const handleOk = () => {
    onOk?.();
    closeModal();
  };

  const handleCancel = () => {
    onCancel?.();
    closeModal();
  };

  const getModalActions = () => {
    let actions: React.ReactElement;
    // Use render props pattern to pass close modal handler to custom footer buttons
    if (renderModalActions) {
      actions = renderModalActions(closeModal);
    } else {
      actions = (
        <>
          <Button className="cancel" onClick={handleCancel} basic color="blue">
            Cancel
          </Button>
          <Button className="ok" onClick={handleOk} primary>
            Ok
          </Button>
        </>
      );
    }
    return <SemanticModal.Actions>{actions}</SemanticModal.Actions>;
  };

  const classNames = cn("modal-wrapper", className);

  return (
    <SemanticModal
      trigger={renderTrigger?.(openModal)}
      open={isOpen}
      className={classNames}
      size={size}
      data-testid={dataTestId || "modal-dialog"}
    >
      <>
        <SemanticModal.Header>
          <ModalHeader title={title} onClose={handleCancel} />
        </SemanticModal.Header>

        {alert}
        <SemanticModal.Content scrolling={scrolling}>{children}</SemanticModal.Content>
        {getModalActions()}
      </>
    </SemanticModal>
  );
};

export default Modal;
