import { PayloadAction } from "../../../../../interfaces/redux";
import { createReducer } from "../../../../../utils/reduxUtils";
import { actionTypes } from "../actionTypes/assessmentContentValidatorActionTypes";
import { commonActionTypes } from "../actionTypes/commonAssessmentActionTypes";

export interface AssessmentContentValidationState {
  initialized: boolean;
  isFormValid?: boolean;
  errorMode: boolean;
  activeErrors: { [key: string]: string };
  touchedSnapshot: object;
  resolved: boolean;
}

export const initialState: AssessmentContentValidationState = {
  initialized: false,
  isFormValid: undefined,
  errorMode: false,
  activeErrors: {},
  resolved: true,
  touchedSnapshot: {},
};

const assessmentContentValidationHandlers = () => {
  const handleSetIsValid = (
    state: AssessmentContentValidationState,
    action: PayloadAction<boolean>,
  ): AssessmentContentValidationState => {
    return {
      ...state,
      isFormValid: action.payload,
    };
  };

  const handleSetErrorMode = (
    state: AssessmentContentValidationState,
    action: PayloadAction<boolean>,
  ): AssessmentContentValidationState => {
    return {
      ...state,
      errorMode: action.payload,
    };
  };
  const handleSetResolved = (
    state: AssessmentContentValidationState,
    action: PayloadAction<boolean>,
  ): AssessmentContentValidationState => {
    return {
      ...state,
      resolved: action.payload,
    };
  };

  const handleSetInitialized = (
    state: AssessmentContentValidationState,
    action: PayloadAction<boolean>,
  ): AssessmentContentValidationState => {
    return {
      ...state,
      initialized: action.payload,
    };
  };

  const handleSetActiveErrors = (
    state: AssessmentContentValidationState,
    action: PayloadAction<{ [key: string]: string }>,
  ): AssessmentContentValidationState => {
    return {
      ...state,
      activeErrors: action.payload,
    };
  };

  const handleSetTouchedSnapshot = (
    state: AssessmentContentValidationState,
    action: PayloadAction<object>,
  ): AssessmentContentValidationState => {
    return {
      ...state,
      touchedSnapshot: action.payload,
    };
  };

  const handlerReset = () => ({
    ...initialState,
  });

  const handleDiscard = (state: AssessmentContentValidationState): AssessmentContentValidationState => ({
    ...state,
    errorMode: false,
  });

  return {
    [actionTypes.setIsValid]: handleSetIsValid,
    [actionTypes.setActiveErrors]: handleSetActiveErrors,
    [actionTypes.setErrorMode]: handleSetErrorMode,
    [actionTypes.setResolved]: handleSetResolved,
    [actionTypes.setInitialized]: handleSetInitialized,
    [actionTypes.setTouchedSnapshot]: handleSetTouchedSnapshot,
    [actionTypes.reset]: handlerReset,
    [commonActionTypes.discard]: handleDiscard,
  };
};

export const assessmentContentValidatorReducer = createReducer(initialState, [assessmentContentValidationHandlers]);
