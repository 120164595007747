import { setExporting } from "features/Reporting/state/export/exportSlice";
import AlertTypes from "../../../../../../enums/alertTypes";
import { getAxiosError } from "../../../../../../utils/axiosError";
import { AppDispatch } from "../../../../../Application/globaltypes/redux";
import { sendTransientNotification } from "../../../../../Notifications/state/notificationsActions";
import { BasePerformanceRequestFilterParams } from "../../../../Common/models";
import { downloadExcelExport } from "../../../../Common/utils/performanceUtils";
import { EmailActivity } from "../../EmailDetails/Performance/types";
import emailsDataService from "../../services/emailsDataService";
import {
  reqSendDensity,
  setSendDensity,
  setSendDensityError,
  reqSendFunnel,
  setSendFunnel,
  setSendFunnelError,
  reqSendsDetails,
  setSendsDetails,
  setSendsDetailsError,
  reqSendSessions,
  setSendSessions,
  setSendSessionsError,
} from "../slices/emailPerformanceSlice";

export const fetchEmailSendDensity = (emailId: number, filterParams: BasePerformanceRequestFilterParams = {}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqSendDensity());
    try {
      const { data } = await emailsDataService.getEmailLineChart(emailId, filterParams);
      dispatch(setSendDensity({ data }));
    } catch (err) {
      // Cancellation token error
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setSendDensityError({ errorMessage }));
      }
    }
  };
};

export const fetchEmailSendFunnel = (emailId: number, filterParams: BasePerformanceRequestFilterParams = {}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqSendFunnel());
    try {
      const missingRates = (await emailsDataService.getEmailEngagement(emailId, filterParams)).data;
      const data: EmailActivity = {
        ...missingRates,
        OpenRate: 0,
        ClickRate: 0,
      };
      dispatch(setSendFunnel({ data }));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setSendFunnelError({ errorMessage }));
      }
    }
  };
};

export const fetchEmailSendSessions = (emailId: number, filterParams: BasePerformanceRequestFilterParams = {}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqSendSessions());
    try {
      const { data } = await emailsDataService.getEmailSendSessions(emailId, filterParams);
      dispatch(setSendSessions({ data }));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setSendSessionsError({ errorMessage }));
      }
    }
  };
};

export const fetchEmailSendDetails = (
  emailId: number,
  sendSessionId: number,
  filterParams: BasePerformanceRequestFilterParams = {},
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqSendsDetails());

    try {
      const results = (await emailsDataService.getEmailSendDetailsV3(emailId, sendSessionId, filterParams)).data;

      const resultsWithId = results.map((item, index) => {
        return {
          ...item,
          id: index.toString(),
        };
      });

      dispatch(
        setSendsDetails({
          data: resultsWithId,
        }),
      );
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(setSendsDetailsError({ errorMessage }));
    }
  };
};

export const handleCommunicationsExport = (
  emailId: number,
  filterParams: BasePerformanceRequestFilterParams,
  emailTitle: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setExporting(true));
    try {
      const response = await emailsDataService.getExport(emailId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, emailTitle);
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        return;
      }
      const errorMessage = getAxiosError(err);
      dispatch(
        sendTransientNotification(
          {
            title: "Export Failed!",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
      console.error("Unable to export", errorMessage);
    } finally {
      dispatch(setExporting(false));
    }
  };
};

export const handleSendSessionExport = (
  sendSessionId: number,
  filterParams: BasePerformanceRequestFilterParams,
  fileName: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setExporting(true));
    try {
      const response = await emailsDataService.getSendSessionExport(sendSessionId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, fileName);
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        return;
      }
      const errorMessage = getAxiosError(err);
      dispatch(
        sendTransientNotification(
          {
            title: "Export Failed!",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
      console.error("Unable to export", errorMessage);
    } finally {
      dispatch(setExporting(false));
    }
  };
};
