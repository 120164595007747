import React from "react";
import ReadyToPublish from "./ReadyToPublish";
import UnableToPublish from "./UnableToPublish";

export interface Props {
  unableToPublish: boolean;
  readyToPublish: boolean;
  onErrorButtonClick: () => void;
}

function PublishValidationInfo({ unableToPublish, readyToPublish, onErrorButtonClick }: Props) {
  const renderSuccessInfo = () => readyToPublish && <ReadyToPublish />;

  const renderErrorInfo = () => <UnableToPublish isVisible={unableToPublish} onErrorButtonClick={onErrorButtonClick} />;

  return (
    <>
      {renderSuccessInfo()}
      {renderErrorInfo()}
    </>
  );
}

export default PublishValidationInfo;
