import React, { ReactNode } from "react";
import cn from "classnames";
import NoSearchResults from "../../../../../../components/noSearchResults";
import NoResults from "../../../../../../components/noResults/NoResults";
import { UsersGroupsContext } from "../../../../../../enums";
import { capitalizeFirstLetter } from "utils/stringUtils";

export interface PeopleLicensesEmptyProps {
  usersGroupsContext: UsersGroupsContext;
  issueLicenseButton: ReactNode;
  isFiltered: boolean;
}

const PeopleLicensesEmpty: React.FC<PeopleLicensesEmptyProps> = ({
  issueLicenseButton,
  isFiltered,
  usersGroupsContext,
}) => {
  const iconClassName = usersGroupsContext === UsersGroupsContext.Users ? "fa-user" : "fa-users";
  const description = (
    <span>
      It looks like you haven't issued any licenses to {usersGroupsContext.toLowerCase()} yet.
      <br />
      Click the button below to get started!
    </span>
  );

  return isFiltered ? (
    <NoSearchResults />
  ) : (
    <NoResults
      title={`No Licenses Issued to ${capitalizeFirstLetter(usersGroupsContext)}`}
      description={description}
      iconClassName={cn("fal", iconClassName)}
    >
      {issueLicenseButton}
    </NoResults>
  );
};

export default PeopleLicensesEmpty;
