import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "../../../../../interfaces/redux";
import { AssignmentPeopleContext } from "../../../../../enums";

export interface PackIssueLicenseModalState {
  show: boolean;
  selectedPeopleType: AssignmentPeopleContext;
  selectedPeopleIds: number[];
}

const initialState: PackIssueLicenseModalState = {
  show: false,
  selectedPeopleType: AssignmentPeopleContext.Group,
  selectedPeopleIds: [],
};

const packIssueLicenseModalSlice = createSlice({
  name: "packIssueLicenseModal",
  initialState: initialState,
  reducers: {
    show: (state: PackIssueLicenseModalState) => {
      state.show = true;
    },
    hide: (state: PackIssueLicenseModalState) => {
      state.show = false;
    },
    setSelectedPeopleIds: (state: PackIssueLicenseModalState, action: PayloadAction<number[]>) => {
      state.selectedPeopleIds = action.payload;
    },
    resetContext: (state: PackIssueLicenseModalState, action: PayloadAction<AssignmentPeopleContext>) => {
      state.selectedPeopleType = action.payload;
      state.selectedPeopleIds = [];
    },
  },
});

export const { show, hide, setSelectedPeopleIds, resetContext } = packIssueLicenseModalSlice.actions;

export default packIssueLicenseModalSlice.reducer;
export type packIssueLicenseModalSliceType = ReturnType<typeof packIssueLicenseModalSlice.reducer>;
