import React from "react";
import { ReactComponent as RoleIconAdmin } from "../../../../../images/role-icon-admin.svg";
import { Popup, Icon } from "semantic-ui-react";

import "./roleIcon.scss";

export interface RoleIconProps {
  isDefaultRole: boolean;
}

const RoleIcon: React.FC<RoleIconProps> = ({ isDefaultRole }) => (
  <>
    <RoleIconAdmin className="role-icon" />
    {isDefaultRole && (
      <Popup
        className="default-role-popup"
        hideOnScroll
        position="top center"
        size="small"
        content={"This is a default role and cannot be edited."}
        trigger={<Icon className="fa-lock-alt default-role-icon" />}
        inverted
      />
    )}
  </>
);

export default RoleIcon;
