enum RolePermissions {
  UsersView = "Users:View",
  UsersManage = "Users:Manage",
  UsersCreate = "Users:Create",
  APIsView = "APIs:View",
  APIsManage = "APIs:Manage",
  APIsCreate = "APIs:Create",
  FlowsView = "Flows:View",
  FlowsManage = "Flows:Manage",
  FlowsCreate = "Flows:Create",
  RolesView = "Roles:View",
  RolesManage = "Roles:Manage",
  RolesCreate = "Roles:Create",
  GroupsView = "Groups:View",
  GroupsManage = "Groups:Manage",
  GroupsCreate = "Groups:Create",
  AssetsView = "Assets:View",
  AssetsManage = "Assets:Manage",
  AssetsCreate = "Assets:Create",
  EventsView = "Events:View",
  EventsManage = "Events:Manage",
  EventsCreate = "Events:Create",
  LibraryEmailsView = "LibraryEmails:View",
  LibraryEmailsManage = "LibraryEmails:Manage",
  LibraryEmailsCreate = "LibraryEmails:Create",
  AccountsSettingsView = "AccountsSettings:View",
  AccountsSettingsManage = "AccountsSettings:Manage",
  AccountsSettingsCreate = "AccountsSettings:Create",
  TemplatesView = "Templates:View",
  TemplatesManage = "Templates:Manage",
  AddonsView = "Addons:View",
  AddonsManage = "Addons:Manage",
  PacksManage = "Packs:Manage",
  PacksPurchase = "Packs:Purchase",
  CommunicationsView = "Communications:View",
  CommunicationsManage = "Communications:Manage",
  CommunicationsCreate = "Communications:Create",
  CampaignsManage = "Campaigns:Manage",
}

export function toFeatureRight(permission: RolePermissions) {
  const [feature, right] = permission.split(":");
  return { feature, right };
}

export default RolePermissions;
