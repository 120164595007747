export enum ContentTypesEnum {
  Flows = "Flows",
  Assets = "Assets",
  Videos = "Videos",
  Links = "Links",
  Surveys = "Surveys",
  Assessments = "Assessments",
  Events = "Events",
  Emails = "Emails",
  Messages = "Messages",
  PDFs = "PDFs",
}
