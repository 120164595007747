import React from "react";
import cn from "classnames";

import ItemToDropCard from "../ItemToDropCard/ItemToDropCard";
import dateTimeUtils from "../../../utils/dateTimeUtils";

import { VideoAsset } from "../../../interfaces/assetToDropInfo";

export interface Props {
  id: VideoAsset["id"];
  title: VideoAsset["title"];
  thumbnailUrl: VideoAsset["thumbnailUrl"];
  durationInSeconds: VideoAsset["durationInSeconds"];
  className?: string;
  isSelected?: boolean;
  onClick?: () => void;
}

const FlowDesignerVideoAssetCard: React.FunctionComponent<Props> = ({ className, durationInSeconds, ...props }) => {
  return (
    <ItemToDropCard
      className={cn("flow-designer-video-asset-card", className)}
      dataHookType="flow-designer-video-asset-card"
      itemType="VIDEO"
      metaString={dateTimeUtils.durationSecondsToTime(durationInSeconds)}
      {...props}
    />
  );
};

export default FlowDesignerVideoAssetCard;
