import cn from "classnames";
import { Icon } from "semantic-ui-react";
import UserInfoCell from "../../../../../../../components/userInfoCell/UserInfoCell";
import dateTimeUtils from "../../../../../../../utils/dateTimeUtils";
import { ColumnOption } from "../../../../../../../interfaces/columnOptions";
import { AssessmentUser } from "../../../../types/state";
import { EMPTY_CELL_CONTENT } from "../../../../../../../utils/columnUtils";

const getStatusIcon = (isPassed?: boolean | null) =>
  isPassed !== null &&
  isPassed !== undefined && <Icon className={cn("fal fa-check-circle outline", isPassed ? "green" : "red")} />;

/* istanbul ignore next */
export enum Columns {
  Name = "Name",
  Status = "Status",
  LastAttempt = "Last Attempt",
  Priority = "Priority",
  DueDate = "Due Date",
  PermissionIssuedDate = "Permission Issued",
  Expires = "Expires",
}

enum Passed {
  True = "true",
  False = "false",
  Null = "null",
  Undefined = "undefined",
}

enum AssessmentStatuses {
  Pass = "PASS",
  Fail = "FAIL",
}

const statuses: { [key in Passed]: string } = {
  [Passed.True]: AssessmentStatuses.Pass,
  [Passed.False]: AssessmentStatuses.Fail,
  [Passed.Null]: EMPTY_CELL_CONTENT,
  [Passed.Undefined]: EMPTY_CELL_CONTENT,
};

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "firstName",
  status: "isCompleted",
  "last attempt": "lastAttempt",
  priority: "priority",
  "due date": "dueDate",
  "permission issued": "permissionIssuedDate",
  expires: "expires",
};

/* istanbul ignore next */
export const getColumnOptions = (): ColumnOption<AssessmentUser>[] => {
  return [
    {
      name: Columns.Name,
      width: 9,
      isSortable: true,
      disabled: (item) => item.inherited,
      render: (item) => <UserInfoCell deepLink={!item.inherited} user={item} />,
    },
    {
      name: Columns.Status,
      width: 3,
      isSortable: false,
      disabled: (item) => item.inherited,
      render: (item) => (
        <>
          {getStatusIcon(item.passed)} {statuses[String(item.passed) as Passed]}
        </>
      ),
    },
    {
      name: Columns.LastAttempt,
      width: 3,
      isSortable: false,
      disabled: (item) => item.inherited,
      render: (item) => dateTimeUtils.localFormatWithValidation(item.lastAttemptDate),
    },
  ];
};
