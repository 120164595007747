import {
  createFetchingItemsSlice,
  FetchingItemsState,
} from "../../../../../Application/slices/createFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import { EmailSendSession } from "../../EmailDetails/Performance/types";

export interface EmailsSendsState extends FetchingItemsState<EmailSendSession> {}

export const initialState: EmailsSendsState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const emailSendsSlice = createFetchingItemsSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Emails, name: "sends" },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = emailSendsSlice.actions;

export const emailSendsReducer = emailSendsSlice.reducer;
export type emailsSendsSliceStateType = ReturnType<typeof emailSendsReducer>;
