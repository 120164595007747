import { Component } from "react";
import { shape, array, arrayOf, bool } from "prop-types";
import { FilterMultiSelect } from "../../multiSelect";
import { commonFilterFormPropTypes } from "../types/commonFilterFormPropTypes";
import FilterDateRange from "../../../components/datePicker/FilterDateRange";
import AreaIcon from "../../areaIcon/AreaIcon";
import { uniqBy, intersection } from "lodash";

const getOptions = (options = []) =>
  options.map((x) => ({
    key: x.id,
    value: x.id,
    text: x.name,
  }));

const getAreaOptions = (options) =>
  getOptions(options).map((a) => ({
    ...a,
    icon: <AreaIcon areaName={a.text} />,
  }));

const renderLabel = (label) => ({
  content: label.text,
  icon: <AreaIcon areaName={label.text} />,
});

class RolesFilterForm extends Component {
  onAreasChange = (newFilter) => {
    const { updateFilter } = this.props;

    const possibleLimitationIds = this.getLimitations(newFilter).map((l) => l.id);

    newFilter.limitationIds = intersection(possibleLimitationIds, newFilter.limitationIds);

    if (newFilter.limitationIds.length === 0) {
      delete newFilter.limitationIds;
    }

    updateFilter(newFilter);
  };

  getLimitations = (filter) => {
    const {
      filterOptions: { areas = [] },
    } = this.props;
    const selectedAreas = filter.areaIds || [];

    return uniqBy(
      areas.filter((a) => selectedAreas.includes(a.id)).flatMap((a) => a.limitations),
      "id",
    );
  };

  render() {
    const { filterOptions, updateFilter, filter, isModalAppearance } = this.props;
    const limitations = getOptions(this.getLimitations(filter));
    const horizontalPositionSecondPicker = isModalAppearance ? "left" : "auto";

    return (
      <>
        <div className="filter-item">
          <div className="label">Area</div>
          <FilterMultiSelect
            propertyName="areaIds"
            placeholder="Select Area"
            items={getAreaOptions(filterOptions.areas)}
            filter={filter}
            updateFilter={this.onAreasChange}
            renderLabel={renderLabel}
          />
        </div>
        {!!limitations.length && (
          <div className="filter-item">
            <div className="label">Limitation Type</div>
            <FilterMultiSelect
              propertyName="limitationIds"
              placeholder="Select Limitation Types"
              items={limitations}
              filter={filter}
              updateFilter={updateFilter}
            />
          </div>
        )}
        <div className="filter-item">
          <FilterDateRange
            className="filter-item"
            label="Added"
            propertyName={"added"}
            filter={filter}
            updateFilter={updateFilter}
            horizontalPositionSecondPicker={horizontalPositionSecondPicker}
          />
          <FilterDateRange
            className="filter-item"
            label="Last Modified"
            propertyName={"updated"}
            filter={filter}
            updateFilter={updateFilter}
            horizontalPositionSecondPicker={horizontalPositionSecondPicker}
          />
        </div>
      </>
    );
  }
}

RolesFilterForm.propTypes = {
  filterOptions: shape({
    areas: arrayOf(
      shape({
        limitations: array,
      }),
    ),
  }),
  filter: shape({
    areaIds: array,
    limitationIds: array,
  }),
  isModalAppearance: bool,
  ...commonFilterFormPropTypes,
};

export default RolesFilterForm;
