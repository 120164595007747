import React, { FC } from "react";
import { Icon } from "semantic-ui-react";

import "./modalBanner.scss";
import { pluralize } from "../../../../utils/stringUtils";

export interface ModalBannerProps {
  contentType: string;
  dependencyType: string;
  dependencyCount: number;
}

export const ModalBanner: FC<ModalBannerProps> = ({ contentType, dependencyType, dependencyCount }) => {
  const dependency = pluralize(dependencyType, dependencyCount);
  return (
    <div className="modal-banner-root">
      <Icon className="info circle" />
      <b>{`This ${contentType} belongs to ${dependencyCount} published ${dependency}.`}</b>
      <span>{`Publishing changes will update ${dependency} containing this ${contentType}.`}</span>
    </div>
  );
};
