import { FormikProps, withFormik } from "formik";
import { useRef, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import { RootState } from "../../../../Application/globaltypes/redux";
import { SurveyView } from "../../types/state";
import SurveyForm from "../SurveyForm/SurveyForm";
import { ConfigureProps } from "../types";
import * as surveyEntityStateActions from "../../state/actions/surveyEntityStateActions";
import * as surveyDetailsActions from "../../state/actions/surveyDetailsActions";
import validationSchemas from "../../../../../utils/validationSchemas";
import { END_NOTE_TEXT_DEFAULT } from "../../types/constants";
import { withAutosave, AutosaveProps } from "../../../../../utils/withAutosave";
import { SurveyPayload } from "../../types/models";
import Restricted from "../../../../Application/Restricted";
import { RolePermissions } from "../../../../../enums";
import { surveysStateSelector } from "../../state/surveyReducer";

export type ConfigurePropsAll = ConfigureProps & FormikProps<SurveyView> & PropsFromRedux & AutosaveProps;

export const Configure = (props: ConfigurePropsAll) => {
  const isSurveyCreated = () => props.id > 0 || props.isCreating;

  const values = useRef(props.values);
  useEffect(() => {
    values.current = props.values;
  }, [props.values]);

  const saveSurvey = () => props.detailsActions.saveSurvey(values.current);

  const onNext = () => {
    saveSurvey();
    if (!isSurveyCreated()) {
      props.entityStateActions.createSurvey(mapSurveyViewToSurvey(values.current));
    }
  };

  const onPrevious = saveSurvey;

  const onClose = () => {
    props.entityStateActions.resetSurveyEntityState();
  };

  const { isValid, onIsValidChange } = props;

  useEffect(() => {
    onIsValidChange?.(isValid);
  }, [isValid, onIsValidChange]);

  useEffect(() => {
    props.acceptHandlers?.({
      onNext,
      onPrevious,
      onClose,
    });
    props.subscribeOnDiscarded?.(props.resetForm);
    return () => {
      props.unSubscribeOnDiscarded?.(props.resetForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Restricted
      permissions={[RolePermissions.AssetsCreate]}
      renderContent={(hasPermission) => (
        <SurveyForm
          {...props}
          disablePreventTransitionPrompt={isSurveyCreated()}
          disabled={props.disabled || !hasPermission}
        />
      )}
    />
  );
};

const mapSurveyViewToSurvey = (surveyView: SurveyView): SurveyPayload => ({
  title: surveyView.title.trim(),
  description: surveyView.description.trim(),
  endNoteText: surveyView.endNoteTextEnabled && surveyView.endNoteText ? surveyView.endNoteText : END_NOTE_TEXT_DEFAULT,
  endNoteTextEnabled: surveyView.endNoteTextEnabled,
});

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigureProps) => {
  const base = surveysStateSelector(state).base;
  return {
    id: ownProps.entityId || base.surveysEntityStateReducer.entityId,
    isCreating: base.surveysEntityStateReducer.changingEntityState,
    survey: base.surveysDetailsReducer.survey,
    isLoaded: base.surveysDetailsReducer.isSurveyInfoLoaded,
    isLoading: base.surveysDetailsReducer.isLoading,
    isDraft: base.surveysDetailsReducer.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(surveyEntityStateActions, dispatch),
  detailsActions: bindActionCreators(surveyDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigurePropsAll, SurveyView, SurveyPayload>({
  getInitValues: (props) => mapSurveyViewToSurvey(props.survey),
  stateProvider: mapSurveyViewToSurvey,
  entityUpdater: (props) => props.entityStateActions.updateSurvey,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.surveyInfo,
  enableReinitialize: true,
  mapPropsToValues: (props: ConfigureProps & PropsFromRedux) => props.survey,
  validateOnMount: true,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
