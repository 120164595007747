import React, { useMemo, useState } from "react";
import { FlowValidationError } from "../validator/flowValidatorReducer";

interface IErrorNavigationContext {
  state: {
    currentError: FlowValidationError | null;
  };
  actions: {
    setCurrentError: (error: FlowValidationError | null) => void;
  };
}

const defaultAction = (funcName: string) => () => {
  throw new Error(`'${funcName}()' is not defined`);
};

export const ErrorNavigationContext = React.createContext<IErrorNavigationContext>({
  state: {
    currentError: null,
  },
  actions: {
    setCurrentError: defaultAction("setCurrentError"),
  },
});

interface ErrorNavigationProviderProps {
  children: React.ReactNode;
}

export const ErrorNavigationProvider: React.FC<ErrorNavigationProviderProps> = ({ children }) => {
  const [currentError, setCurrentError] = useState<FlowValidationError | null>(null);

  const navigationContextValue = useMemo(
    () => ({
      state: { currentError },
      actions: {
        setCurrentError,
      },
    }),
    [currentError],
  );
  return <ErrorNavigationContext.Provider value={navigationContextValue}>{children}</ErrorNavigationContext.Provider>;
};

export function useErrorNavigationState() {
  return React.useContext(ErrorNavigationContext).state;
}

export function useErrorNavigationActions() {
  return React.useContext(ErrorNavigationContext).actions;
}
