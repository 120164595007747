import cn from "classnames";
import { filter, some } from "lodash";
import moment from "moment";
import { useCallback, useEffect } from "react";
import Alert, { AlertProps } from "./types/Alert";
import { createPortal } from "react-dom";
import styles from "./toastNotifications.module.scss";

let dismissInterval: string | number | NodeJS.Timeout | undefined = undefined;

export type Props = {
  dismissAlerts: (alertsToDismiss: AlertProps[]) => void;
  alerts: AlertProps[];
  onRemoveAlert: (id: number | string) => void;
};

export const ToastNotifications = ({ alerts, dismissAlerts, onRemoveAlert }: Props) => {
  const runDismissAlerts = useCallback(() => {
    const currentTime = moment().valueOf();
    const alertsToDismiss = filter(alerts, (alert) => {
      return (
        alert.dismissInSeconds && alert.createTime && currentTime - alert.createTime > alert.dismissInSeconds * 1000
      );
    });

    if (alertsToDismiss.length > 0) {
      dismissAlerts(alertsToDismiss as AlertProps[]);
    }
  }, [alerts, dismissAlerts]);

  const initDismissInterval = useCallback(
    (alerts: AlertProps[]) => {
      const autoDismissableAlerts = some(alerts, (alert) => alert.dismissInSeconds);
      if (autoDismissableAlerts) {
        clearInterval(dismissInterval);
        dismissInterval = setInterval(runDismissAlerts, 1000);
      } else {
        clearInterval(dismissInterval);
      }
    },
    [runDismissAlerts],
  );

  useEffect(() => {
    initDismissInterval(alerts);
  }, [alerts, initDismissInterval]);

  // This places alerts next to the global navigation, so when it opens/closes, alerts will move with it.
  const ref = document.getElementsByClassName("pushable")[0];

  return ref
    ? createPortal(
        <div className={cn(styles.alerts)}>
          {alerts.map((alert) => (
            <Alert key={alert.id} {...alert} onDismissCompleted={onRemoveAlert} />
          ))}
        </div>,
        ref,
      )
    : null;
};
