import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { ConnectedProps, connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { selectPageVisitsLine, usageColumn, usageLine } from "../../state/msgraph/selectors/sharepoint/usageSelectors";
import { fetchSharepointActivityFileCounts, fetchSharepointActivityPages } from "../../state/msgraph/graphActions";
import { ChartWrapper, ColumnChart, LineChart, PieChart, TickValueType } from "../../../../components/charts";
import { selectSharepointActivityFileCounts, selectSharepointActivityPages } from "../../state/msgraph/graphSlice";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import {
  selectFileSharingLine,
  selectFileSharingSums,
  selectFileSyncedLine,
  selectFilesViewedLine,
} from "../../state/msgraph/selectors/sharepoint/activitySelectors";
import ReportingFilter from "../../../../components/reportingFilter/ReportingFilter";
import { barDataHasValues } from "../utils/utils";
import * as performanceUtils from "../../../Library/Common/utils/performanceUtils";
import { graphReportingService } from "features/Reporting/services/graphReportingService";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { useGoogleTooltipFormats } from "features/Reporting/Google/utils/useGoogleTooltipFormats";
import { SharepointGoalCharts } from "./SharepointGoalCharts";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { selectMenuItemsData } from "features/Reporting/state/menu/integrationMenuSlice";

import "./sharepointReport.scss";

const COLOR_RANGE = [performanceUtils.startsColor, performanceUtils.completesColor];

function lookupPieTooltipFormattedKey(key: "internalSum" | "externalSum") {
  return key === "internalSum" ? "Files Shared Internally" : "Files Shared Externally";
}
enum ActiveUsersChartType {
  Column,
  Line,
}

export interface Props extends PropsFromRedux { }

export const SharepointReport: FC<Props> = ({
  fileCountsState,
  pageVisitsState,
  pageVisitsLine,
  filesViewedLine,
  filesSharedLine,
  fileSharingSums,
  filesSyncedLine,
  integrations,
  accountId,
  getSharepointFileCounts,
  getSharepointPages,
}) => {
  const isBsiAccount = performanceUtils.isBsi(accountId);

  const [activeUsersChartType, setActiveUsersChartType] = useState<ActiveUsersChartType>(ActiveUsersChartType.Column);
  const [dateFilter, setDateFilter] = useState(
    performanceUtils.defaultDateFilter({ accountId, includeAccounts: isBsiAccount, includeAccountsDropdown: false }),
  );
  const goalAreaEnabled = useFeatureFlag(FeatureFlags.MsGraphGoalReporting);
  const isMicrosoftIntegrationEnabled = integrations["MSGraph"];
  const getAllCustomerAccounts = dateFilter?.showCustomers === true;

  const dateRange = useMemo(
    () => performanceUtils.createDateRange(dateFilter.dateFrom, dateFilter.dateTo),
    [dateFilter.dateFrom, dateFilter.dateTo],
  );

  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    performanceUtils.lineChartMargins.left + performanceUtils.lineChartMargins.right,
  );

  const { lineChartFormatter: lineChartTooltipFormatter, groupedBarFormatter } = useGoogleTooltipFormats(
    chartPeriod,
    dateRange,
  );

  const userCountsQuery = useQuery({
    queryKey: ["sharepoint user counts", dateFilter, chartPeriod],
    queryFn: graphReportingService.getSharepointActivityUserCounts,
  });

  const handleActiveUsersChange = useCallback((option: string) => {
    option === "Active Users - Column Chart"
      ? setActiveUsersChartType(ActiveUsersChartType.Column)
      : setActiveUsersChartType(ActiveUsersChartType.Line);
  }, []);

  const lineChartTickFormat = useCallback(
    (xValue: string) => performanceUtils.getFormattedTimeStringFromPeriod(xValue, chartPeriod, dateRange),
    [chartPeriod, dateRange],
  );

  useEffect(() => {
    if (chartPeriod !== "UNSET") {
      getSharepointFileCounts({ ...dateFilter, aggregation: chartPeriod });
      getSharepointPages({ ...dateFilter, aggregation: chartPeriod });
    }
  }, [getSharepointFileCounts, getSharepointPages, dateFilter, chartPeriod]);

  const pieTooltipFormatter = useCallback(
    (key: string, value: number) => {
      const sum = fileSharingSums.internalSum + fileSharingSums.externalSum;
      let beginningText = lookupPieTooltipFormattedKey(key as keyof typeof fileSharingSums);
      return `${beginningText} | ${value.toLocaleString()} | ${Math.round((value / sum) * 100)}%`;
    },
    [fileSharingSums],
  );

  const usageColumnData = useMemo(() => usageColumn(userCountsQuery), [userCountsQuery]);
  const usageLineData = useMemo(() => usageLine(userCountsQuery), [userCountsQuery]);

  const activeUsersColumnChart = useMemo(
    () =>
      barDataHasValues(usageColumnData) ? (
        <ColumnChart
          margins={performanceUtils.columnChartMargins}
          data={usageColumnData}
          domain={[0, Math.max(...usageColumnData.map((d) => d.value))]}
          xAxisTickFormat={(d) =>
            performanceUtils.getFormattedTimeStringFromPeriod(d as string, chartPeriod, dateRange)
          }
          tooltipFormatter={groupedBarFormatter}
        />
      ) : (
        performanceUtils.noData(dateFilter)
      ),
    [usageColumnData, groupedBarFormatter, dateFilter, chartPeriod, dateRange],
  );

  const activeUsersLineChart = useMemo(
    () =>
      performanceUtils.validLineData([usageLineData.values]) ? (
        <LineChart
          margins={performanceUtils.lineChartMargins}
          xData={[usageLineData.dates]}
          yData={[usageLineData.values]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yTickValueType={TickValueType.IntegersOnly}
          yFormatterFunc={performanceUtils.linearChartState.yFormatterFunc}
          tooltipFormatter={lineChartTooltipFormatter}
        />
      ) : (
        performanceUtils.noData(dateFilter)
      ),
    [usageLineData.values, usageLineData.dates, lineChartTickFormat, lineChartTooltipFormatter, dateFilter],
  );

  const sharepointReportingArea = useMemo(() => {
    return (
      <>
        <h2>Usage</h2>
        <section>
          <div className="chart-container">
            <ChartWrapper
              titles={["Active Users - Column Chart", "Active Users - Line Chart"]}
              onChange={handleActiveUsersChange}
            >
              <RequestStatusRenderer state={userCountsQuery.status}>
                {activeUsersChartType === ActiveUsersChartType.Column ? activeUsersColumnChart : activeUsersLineChart}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
          <div className="chart-container">
            <ChartWrapper titles={["Page Visits"]}>
              <RequestStatusRenderer state={pageVisitsState}>
                {performanceUtils.validLineData([pageVisitsLine.visits]) ? (
                  <LineChart
                    margins={performanceUtils.lineChartMargins}
                    xData={[pageVisitsLine.dates]}
                    yData={[pageVisitsLine.visits]}
                    xScaleRatio={timeScale}
                    xFormatterFunc={lineChartTickFormat}
                    yTickValueType={TickValueType.IntegersOnly}
                    yFormatterFunc={performanceUtils.linearChartState.yFormatterFunc}
                    colorRange={[performanceUtils.startsColor]}
                    tooltipFormatter={lineChartTooltipFormatter}
                  />
                ) : (
                  performanceUtils.noData(dateFilter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </section>
        <section>
          <h2 className="activity-header">Activity</h2>
          <div className="chart-container">
            <ChartWrapper titles={["Files Viewed Or Edited"]}>
              <RequestStatusRenderer state={fileCountsState}>
                {performanceUtils.validLineData([filesViewedLine.files]) ? (
                  <LineChart
                    margins={performanceUtils.lineChartMargins}
                    xData={[filesViewedLine.dates]}
                    yData={[filesViewedLine.files]}
                    xScaleRatio={timeScale}
                    xFormatterFunc={lineChartTickFormat}
                    yTickValueType={TickValueType.IntegersOnly}
                    yFormatterFunc={performanceUtils.linearChartState.yFormatterFunc}
                    colorRange={[performanceUtils.startsColor]}
                    tooltipFormatter={lineChartTooltipFormatter}
                  />
                ) : (
                  performanceUtils.noData(dateFilter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
          <div className="chart-container">
            <div className="file-sharing-line" ref={measureRef}>
              <ChartWrapper
                titles={["File Sharing"]}
                colorRange={COLOR_RANGE}
                showLegend
                legendLabels={["Shared Internally", "Shared Externally"]}
              >
                <RequestStatusRenderer state={fileCountsState}>
                  {performanceUtils.validLineData([filesSharedLine.internal, filesSharedLine.external]) ? (
                    <LineChart
                      margins={performanceUtils.lineChartMargins}
                      xData={[filesSharedLine.dates, filesSharedLine.dates]}
                      yData={[filesSharedLine.internal, filesSharedLine.external]}
                      xScaleRatio={timeScale}
                      xFormatterFunc={lineChartTickFormat}
                      yTickValueType={TickValueType.IntegersOnly}
                      yFormatterFunc={performanceUtils.linearChartState.yFormatterFunc}
                      colorRange={COLOR_RANGE}
                      tooltipFormatter={lineChartTooltipFormatter}
                    />
                  ) : (
                    performanceUtils.noData(dateFilter)
                  )}
                </RequestStatusRenderer>
              </ChartWrapper>
            </div>
            <div className="pie-container">
              <ChartWrapper
                titles={["File Sharing"]}
                colorRange={COLOR_RANGE}
                showLegend
                legendLabels={["Internal", "External"]}
              >
                <RequestStatusRenderer state={fileCountsState}>
                  {Object.values(fileSharingSums).some((v) => v > 0) ? (
                    <PieChart
                      data={fileSharingSums}
                      innerRadiusPercentage={75}
                      innerText={{
                        title: (fileSharingSums.externalSum + fileSharingSums.internalSum).toLocaleString(),
                        value: "Total",
                        reverseStyle: true,
                      }}
                      colorRange={COLOR_RANGE}
                      tooltipFormatter={pieTooltipFormatter}
                    />
                  ) : (
                    performanceUtils.noData(dateFilter)
                  )}
                </RequestStatusRenderer>
              </ChartWrapper>
            </div>
          </div>
          <div className="chart-container">
            <ChartWrapper titles={["Files Synced"]}>
              <RequestStatusRenderer state={fileCountsState}>
                {performanceUtils.validLineData([filesSyncedLine.files]) ? (
                  <LineChart
                    margins={performanceUtils.lineChartMargins}
                    xData={[filesSyncedLine.dates]}
                    yData={[filesSyncedLine.files]}
                    xScaleRatio={timeScale}
                    xFormatterFunc={lineChartTickFormat}
                    yTickValueType={TickValueType.IntegersOnly}
                    yFormatterFunc={performanceUtils.linearChartState.yFormatterFunc}
                    colorRange={[performanceUtils.startsColor]}
                    tooltipFormatter={lineChartTooltipFormatter}
                  />
                ) : (
                  performanceUtils.noData(dateFilter)
                )}
              </RequestStatusRenderer>
            </ChartWrapper>
          </div>
        </section>
      </>
    );
  }, [
    activeUsersChartType,
    activeUsersColumnChart,
    activeUsersLineChart,
    dateFilter,
    fileCountsState,
    fileSharingSums,
    filesSharedLine,
    filesSyncedLine,
    filesViewedLine,
    handleActiveUsersChange,
    lineChartTickFormat,
    lineChartTooltipFormatter,
    measureRef,
    pageVisitsLine,
    pageVisitsState,
    pieTooltipFormatter,
    userCountsQuery.status,
  ]);

  return (
    <div className="sharepoint-root">
      <section>
        <div className="filter-header">
          <h2>Summary</h2>
          <ReportingFilter currentFilter={dateFilter} callback={setDateFilter} includeAccounts={isBsiAccount} />
        </div>
        {goalAreaEnabled && <SharepointGoalCharts dateFilter={dateFilter} isBsi={getAllCustomerAccounts} />}
        {isMicrosoftIntegrationEnabled && !getAllCustomerAccounts && sharepointReportingArea}
      </section>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  fileCountsState: selectSharepointActivityFileCounts(state),
  pageVisitsState: selectSharepointActivityPages(state),
  pageVisitsLine: selectPageVisitsLine(state),
  filesViewedLine: selectFilesViewedLine(state),
  filesSharedLine: selectFileSharingLine(state),
  fileSharingSums: selectFileSharingSums(state),
  filesSyncedLine: selectFileSyncedLine(state),
  accountId: state.userProfile.accountId,
  integrations: selectMenuItemsData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSharepointFileCounts: bindActionCreators(fetchSharepointActivityFileCounts, dispatch),
  getSharepointPages: bindActionCreators(fetchSharepointActivityPages, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SharepointReport);
