import { Icon } from "semantic-ui-react";
import cn from "classnames";

import "./addOnIcon.scss";

export interface AddOnIconProps {
  addOnName: string;
}

const iconMap = new Map([
  ["Create Content", "folder"],
  ["Create Accounts", "building"],
  ["Marketplace", "fa-box-full"],
  ["Threat Defense", "fa-shield-alt"],
  ["Purchase Packs", "fa-box-full"],
]);

export default function AddOnIcon({ addOnName }: AddOnIconProps) {
  const className = cn(iconMap.get(addOnName), "addon-icon ");
  return (
    <div className="addon-icon-container">
      <Icon className={className} />
    </div>
  );
}
