import { PayloadAction } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import {
  createFetchingItemsSlice,
  FetchingItemsState,
} from "../../../../../Application/slices/createFetchingItemsSlice";
import { MessageOverview } from "../../types/state";
import { fetchOverviewMessages } from "../thunks/messagesOverviewThunk";

export type MessageOverviewState = FetchingItemsState<MessageOverview>;

export const initialState: MessageOverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const messagesOverviewSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Messages,
    name: "overview",
  },
  initialState: initialState,
  reducers: {
    updateMessageBegin(state: MessageOverviewState) {
      state.isLoading = true;
    },
    updateMessageSuccess(state: MessageOverviewState, action: PayloadAction<MessageOverview>) {
      const index = findIndex(state.items, { id: action.payload.id });
      state.items.splice(index, 1, action.payload);
      state.isLoading = false;
    },
  },
  thunk: fetchOverviewMessages,
});

export const { reset, fetchFailure, updateMessageBegin, updateMessageSuccess } = messagesOverviewSlice.actions;

export default messagesOverviewSlice.reducer;
