import { useEffect, useMemo, useState } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import FormFooter from "../../../../components/forms/FormFooter";
import { SignatureAlgorithm, SigningCertificate, EncodingType, IdentityProviderSamlSettings } from "../../types";
import { clone, isEqual, set } from "lodash";
import PreventTransitionPrompt from "../../../../components/preventTransition/PreventTransitionPrompt";
import Miscellaneous from "../../CreateIdentityProvider/Miscellaneous/Miscellaneous";
import { certificateValidationTypes } from "../../CreateIdentityProvider/CreateIdentityProvider";

import "./editMiscellaneous.scss";

export interface EditMiscellaneousProps {
  isLoading: boolean;
  isReadOnly: boolean;
  signatureAlgorithms: SignatureAlgorithm[];
  isSignatureAlgorithmsLoading: boolean;
  fetchSignatureAlgorithms: () => void;
  signingCertificates: SigningCertificate[];
  isSigningCertificatesLoading: boolean;
  fetchSigningCertificates: () => void;
  encodingTypes: EncodingType[];
  isEncodingTypesLoading: boolean;
  fetchEncodingTypes: () => void;
  fetchSamlSettings: () => void;
  onUpdate(samlSettings: IdentityProviderSamlSettings): void;
  samlSettings: IdentityProviderSamlSettings;
}

const EditMiscellaneous: React.FC<EditMiscellaneousProps> = ({
  isLoading = false,
  signatureAlgorithms,
  isSignatureAlgorithmsLoading,
  fetchSignatureAlgorithms,
  signingCertificates,
  isSigningCertificatesLoading,
  fetchSigningCertificates,
  encodingTypes,
  isEncodingTypesLoading,
  fetchEncodingTypes,
  samlSettings,
  fetchSamlSettings,
  onUpdate,
  isReadOnly,
}) => {
  const [currSettings, setCurrSettings] = useState(clone(samlSettings));

  useEffect(() => {
    fetchSamlSettings();
  }, [fetchSamlSettings]);
  // eslint-disable-next-line
  useEffect(() => resetCurrSettings(), [samlSettings]);

  const currSignatureAlgorithm = useMemo(
    () => signatureAlgorithms.find((i) => i.id === currSettings.signatureAlgorithmId),
    [signatureAlgorithms, currSettings],
  );
  const currSigningCertificate = useMemo(
    () => signingCertificates.find((i) => i.thumbprint === currSettings.signingCertificateThumbprint),
    [signingCertificates, currSettings],
  );
  const currEncodingType = useMemo(
    () => encodingTypes.find((i) => i.id === currSettings.encodingTypeId),
    [encodingTypes, currSettings],
  );
  const currCertificateValidationType = useMemo(
    () => certificateValidationTypes.find((i) => i.type === currSettings.certificateValidationType),
    [currSettings],
  );
  const isDirty = useMemo(() => !isEqual(samlSettings, currSettings), [samlSettings, currSettings]);

  const resetCurrSettings = () => {
    const newSamlSettings = clone(samlSettings);
    setCurrSettings(newSamlSettings);
  };

  const onSave = () => {
    const result: IdentityProviderSamlSettings = {
      metadataAutoRefresh: currSettings.metadataAutoRefresh,
      omitAssertionSignatureCheck: currSettings.omitAssertionSignatureCheck,
      signRequest: currSettings.signRequest,
      isSiteMinder: currSettings.isSiteMinder,
      forceAuth: currSettings.forceAuth,
      encodingTypeId: currSettings.encodingTypeId,
      isPassive: currSettings.isPassive,
      certificateValidationType: currSettings.certificateValidationType,
      signingCertificateThumbprint: currSettings.signingCertificateThumbprint,
      signatureAlgorithmId: currSettings.signatureAlgorithmId,
    };
    onUpdate(result);
  };

  const onCancel = () => resetCurrSettings();

  const updateField = (fieldName: keyof IdentityProviderSamlSettings, newValue: any) => {
    const newSamlSettings = clone(currSettings);
    set(newSamlSettings, fieldName, newValue);
    setCurrSettings(newSamlSettings);
  };

  return (
    <Dimmer.Dimmable as={Segment} dimmed={isLoading} className="edit-idp-misc-container">
      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
      <div className="stretch scrollable-content edit-form idp-misc-info">
        <Miscellaneous
          omitAssertionSignatureCheck={currSettings.omitAssertionSignatureCheck}
          onOmitAssertionSignatureCheckChange={() =>
            updateField("omitAssertionSignatureCheck", !currSettings.omitAssertionSignatureCheck)
          }
          useSiteMinder={currSettings.isSiteMinder}
          onUseSiteMinderChange={() => updateField("isSiteMinder", !currSettings.isSiteMinder)}
          signRequest={currSettings.signRequest}
          onSignRequestChange={() => updateField("signRequest", !currSettings.signRequest)}
          signatureAlgorithms={{
            items: signatureAlgorithms,
            fetch: fetchSignatureAlgorithms,
            current: currSignatureAlgorithm,
            onChange: (newVal) => updateField("signatureAlgorithmId", newVal.id),
            isLoading: isSignatureAlgorithmsLoading,
          }}
          signingCertificates={{
            items: signingCertificates,
            fetch: fetchSigningCertificates,
            current: currSigningCertificate,
            onChange: (newVal) => updateField("signingCertificateThumbprint", newVal.thumbprint),
            isLoading: isSigningCertificatesLoading,
          }}
          encodingTypes={{
            items: encodingTypes,
            fetch: fetchEncodingTypes,
            current: currEncodingType,
            onChange: (newVal) => updateField("encodingTypeId", newVal.id),
            isLoading: isEncodingTypesLoading,
          }}
          certificateValidationTypes={{
            items: certificateValidationTypes,
            fetch: () => {}, //NOSONAR
            current: currCertificateValidationType,
            onChange: (newVal) => updateField("certificateValidationType", newVal.type),
            isLoading: false,
          }}
          forceAuth={currSettings.forceAuth}
          onForceAuthChange={() => updateField("forceAuth", !currSettings.forceAuth)}
          isPassive={currSettings.isPassive}
          onIsPassiveChange={() => updateField("isPassive", !currSettings.isPassive)}
          isReadOnly={isReadOnly}
        />
      </div>
      <FormFooter isSaveBtnDisabled={!isDirty} isCancelBtnDisabled={!isDirty} onSave={onSave} onCancel={onCancel} />
      <PreventTransitionPrompt
        when={isDirty}
        title="Exit Without Saving?"
        message="Are you sure you want to exit without saving this Identity Provider? All information entered will be lost."
      />
    </Dimmer.Dimmable>
  );
};

export default EditMiscellaneous;
