import { AxisDomain, format } from "d3";
import { TeamsDeviceUsage } from "../../types/graph";
import { DataPoint } from "../../../../components/charts/types/HorizontalBarChart";
import { ColumnDataPoint } from "../../../../components/charts/types/ColumnChart";
import { completesColor, fourthColor, opensColor, startsColor } from "features/Library/Common/utils/performanceUtils";

export function FormatActivityTick(s: string): string {
  switch (s) {
    case "PrivateChatMessages":
      return "Private Messages";
    case "PostMessages":
      return "Post Messages";
    default:
      return s;
  }
}

export const deviceList: string[] = ["Android", "Chrome OS", "IOS", "Linux", "Mac", "Web", "Windows", "Windows Phone"];

export const deviceUsageColorRange: string[] = [
  startsColor,
  completesColor,
  "#9c989e",
  "#ffbf48",
  "#90c2f3",
  opensColor,
  fourthColor,
  "#232328",
];

// Type better with key later
export function lineDataIndexLookup(dataIndex: number, deviceUsage: TeamsDeviceUsage): number {
  switch (dataIndex) {
    case 0:
      return deviceUsage.Android;
    case 1:
      return deviceUsage.ChromeOS;
    case 2:
      return deviceUsage.iOS;
    case 3:
      return deviceUsage.Linux;
    case 4:
      return deviceUsage.Mac;
    case 5:
      return deviceUsage.Web;
    case 6:
      return deviceUsage.Windows;
    case 7:
    default:
      return deviceUsage.WindowsPhone;
  }
}

export function lookupDeviceFriendlyName(d: AxisDomain): string {
  switch (d.toString()) {
    case "iOS":
      return "IOS";
    case "ChromeOS":
      return "Chrome OS";
    case "WindowsPhone":
      return "Windows Phone";
    default:
      return d.toString();
  }
}

export function barDataHasValues(data: DataPoint[] | ColumnDataPoint[]) {
  return data.some((item) => item.value > 0);
}

export function lineDataHasValues(data: number[]) {
  return data.some((item) => item > 0);
}

export const leftBlackBoxFormatter = (_d: any, y: number) => `${y}%`;

export const formatNumberString = (n: number): string => n < 1000 ? n.toLocaleString() : format(".2s")(n);

