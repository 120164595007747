export default class EndpointsToGetSasLink {
  static Flow = "/api/flows/sas";
  static Account = "/api/accounts/images/sas";
  static Pack = "/api/packs/generate-sas";
  static ExternalEvent = "/api/external-events/thumbnail-sas";
  static Pdf = (id: number) => `/api/assets/pdfs/${id}/generateSas`;
  static People = {
    CsvImport: "/api/users/import/sas",
    TempImages: "/api/users/images/sas",
  };
  static Messages = (id: number) => `/api/communications/messages/${id}/image-sas`;
  static SystemNotifications = "/api/system-notifications/notification-options/image/sas";
}
