import dataService from "../../../../Application/services/dataServices/typedDataService";

import { Dispatcher } from "../../../../../interfaces/redux";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { AssessmentContentView, AssessmentTags, AssessmentView } from "../../types/state";
import { setAssessmentEntityIdAction } from "./assessmentEntityStateActions";
import { assessmentPayloadToAssessmentView, contentPayloadToQuestions } from "../../Details/Utils/TypesConvertor";
import { AssessmentDetailsActionTypes } from "../actionTypes/assessmentDetailsActionTypes";

export const saveAssessment = (assessmentMetadata: AssessmentView) => {
  const saveAssessmentAction = getActionProvider<AssessmentView>(AssessmentDetailsActionTypes.SaveAssessment);

  return (dispatch: Dispatcher) => {
    dispatch(saveAssessmentAction(assessmentMetadata));
  };
};

export const saveAssessmentTags = getActionProvider<AssessmentTags>(AssessmentDetailsActionTypes.SaveAssessmentTags);

export const saveContent = (contentMetadata: AssessmentContentView) => {
  const saveContentAction = getActionProvider<AssessmentContentView>(AssessmentDetailsActionTypes.SaveContent);

  return (dispatch: Dispatcher) => {
    dispatch(saveContentAction(contentMetadata));
  };
};

export const clearAssessment = () => {
  const clearAssessmentAction = getActionBaseProvider(AssessmentDetailsActionTypes.ClearAssessment);

  return (dispatch: Dispatcher) => {
    dispatch(clearAssessmentAction());
  };
};

export const getAssessment = (id: number) => {
  const begin = getActionBaseProvider(AssessmentDetailsActionTypes.GetAssessmentBegin);
  const success = getActionProvider<AssessmentView>(AssessmentDetailsActionTypes.GetAssessmentSuccess);
  const failure = getActionProvider<Error>(AssessmentDetailsActionTypes.GetAssessmentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const result = await dataService.assessments.getAssessmentV2Async(id);
      const assessmentView: AssessmentView = assessmentPayloadToAssessmentView(result.data);
      dispatch(success(assessmentView));
      dispatch(setAssessmentEntityIdAction(id));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const getAssessmentContent = (id: number) => {
  const begin = getActionBaseProvider(AssessmentDetailsActionTypes.GetContentBegin);
  const success = getActionProvider<AssessmentContentView>(AssessmentDetailsActionTypes.GetContentSuccess);
  const failure = getActionProvider<Error>(AssessmentDetailsActionTypes.GetContentFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const result = await dataService.assessments.getAssessmentContentAsync(id);
      const assessmentContentView: AssessmentContentView = {
        questions: contentPayloadToQuestions(result.data),
      };

      dispatch(success(assessmentContentView));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
