import StatusCode from "../../../../enums/httpStatusCodes";
import { AppDispatch } from "../../../Application/globaltypes/redux";
import identityProvidersDataService from "../../services/identityProvidersDataService";
import { IdentityProviderSamlSettings } from "../../types";
import { req, err, got, updated, forbidden } from "../slices/identityProviderSamlSettingsSlice";

export const fetchIdentityProviderSamlSettings = (id: number, moboId?: number) => async (dispatch: AppDispatch) => {
  dispatch(req());
  try {
    const data = await identityProvidersDataService.getIdentityProviderSamlSettings(id, moboId);
    dispatch(got(data));
  } catch (error) {
    const errorMessage = (error as Error).message;
    const errorAction = error?.response.status === StatusCode.Forbidden ? forbidden(errorMessage) : err(errorMessage);
    dispatch(errorAction);
  }
};

export const dispatchUpdateIdentityProviderSamlSettings =
  (configuration: IdentityProviderSamlSettings) => (dispatch: AppDispatch) => {
    dispatch(updated(configuration));
  };
