import { PeopleGroupAssignment } from "../../../../../../PeopleAssignments/Overview/helpers/getColumnOptions";
import { ColumnOption } from "../../../../../../../../interfaces/columnOptions";
import { EditPriorityLinkButton } from "../../../../../../../../components/buttons/linkButtons";
import StartFlowButton from "../../../../Common/StartFlowButton/StartFlowButton";
import GroupInfoCell from "components/groupInfoCell/GroupInfoCell";
import EllipsisPopupButton from "components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import PriorityCell from "components/priorityLevels/priorityCell/PriorityCell";
import dateTimeUtils from "utils/dateTimeUtils";
import { ContentType, PeopleAssignmentsHandlers, PeopleType } from "features/Library/PeopleAssignments/types";
import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";

export enum FlowGroupAssignmentColumns {
  Name = "Name",
  Priority = "Priority Level",
}

export const FlowColumnToParamMap: {
  [P in Lowercase<FlowGroupAssignmentColumns>]: string;
} = {
  name: "firstName",
  "priority level": "priority",
};

export interface GetFlowOverviewColumnOptionsParams extends PeopleAssignmentsHandlers {
  readonly?: boolean;
  isSortable?: boolean;
  startFlow: (ids: number[]) => void;
  canAutoStart: boolean;
}

export const getFlowGroupColumnOptions = <T extends PeopleGroupAssignment>(
  params: GetFlowOverviewColumnOptionsParams,
): ColumnOption<T>[] => {
  return [
    {
      name: FlowGroupAssignmentColumns.Name,
      width: 5,
      isSortable: true,
      disabled: (item) => !!item.isInherited,
      render: (item) => <GroupInfoCell deepLink={!item.isInherited} group={item} />,
    },
    {
      name: FlowGroupAssignmentColumns.Priority,
      width: 4,
      isSortable: params.isSortable,
      render: (item) => (
        <PriorityCell
          priorityLevel={item.priorityLevelId}
          inherited={item.isInherited}
          peopleType={PeopleType.Group}
          contentType={ContentType.Flow}
          dueDate={dateTimeUtils.localFormatWithValidation(item.dueDate)}
          daysToComplete={item.daysToComplete}
        />
      ),
    },
    {
      name: "",
      width: 2,
      className: "align-right",
      isSortable: false,
      render: (item, isChecked) => (
        <EllipsisPopupButton circle outlinedEllipsis disabled={params.readonly || isChecked}>
          <StartFlowButton onClick={() => params.startFlow([item.id])} isDisabled={!params.canAutoStart} />
          <EditPriorityLinkButton onClick={() => params.onEditPriority?.({ [PeopleType.Group]: [item] })} />
          <RemovePriorityButton
            onClick={() => {
              params.onRemove({ [PeopleType.Group]: [item.id] });
            }}
            isDisabled={params.readonly || !item.allowRemovePriority}
            peopleType={PeopleType.Group}
            context="people"
            entityType={ContentType.Flow}
            multiple={false}
            showTooltip={!item.allowRemovePriority}
          />
        </EllipsisPopupButton>
      ),
    },
  ];
};
