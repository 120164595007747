import { createReducer } from "../../../utils/reduxUtils";
import { cloneDeep } from "lodash";
import * as actionTypes from "./actionTypes";
import arrayUtils from "../../../utils/arrayUtils";
import TaskStatuses from "../../../enums/taskStatuses";
import BackgroundTaskLocalStorage from "../storage/BackgroundTaskLocalStorage";
import { DistributedOperationTask, Operations, TaskBase, TaskId, TaskPool } from "../taskPool";

enum OperationResult {
  Succeeded = "succeeded",
  Failed = "failed",
}

type CompletedOperations = {
  succeeded: TaskId[];
  failed: TaskId[];
  [key: string]: TaskId[];
};

export interface BackgroundActivityState {
  tasks: TaskPool;
  completedOperations: CompletedOperations;
  isProgressDrawerVisible: boolean;
}

export const initialState: BackgroundActivityState = {
  tasks: {},
  completedOperations: {
    succeeded: [],
    failed: [],
  },
  isProgressDrawerVisible: false,
};
const storage = new BackgroundTaskLocalStorage();

const updateTask = (
  state: BackgroundActivityState,
  taskId: TaskId,
  updater: (tasks: TaskPool, taskId: TaskId, task: TaskBase) => void,
) => {
  const tasks = { ...state.tasks };
  const task = tasks[taskId];

  if (task) {
    updater(tasks, taskId, { ...task });
    return { ...state, tasks };
  }
  return { ...state };
};

const backgroundTasksHandler = () => {
  const {
    ADD_BACKGROUND_TASK,
    ADD_BACKGROUND_TASK_DISTRIBUTED_OP,
    ADD_OR_UPDATE,
    UPDATE_STATUS_OF_BACKGROUND_TASK,
    UPDATE_PROGRESS_OF_BACKGROUND_TASK,
    UPDATE_ID_OF_BACKGROUND_TASK,
    UPDATE_BACKGROUND_TASK_PROPERTIES,
    DELETE_BACKGROUND_TASK,
    CANCEL_BACKGROUND_TASK,
    DISMISS_COMPLETED_TASKS,
    UPDATE_OPERATION_SUCCESS_OF_BACKGROUND_TASK,
    UPDATE_OPERATION_FAIL_OF_BACKGROUND_TASK,
    UPDATE_DISTRIBUTED_OP_SUCCESS_OF_BACKGROUND_TASK,
    UPDATE_DISTRIBUTED_OP_PROGRESS_OF_BACKGROUND_TASK,
    UPDATE_DISTRIBUTED_OP_FAIL_OF_BACKGROUND_TASK,
    SHOW_PROGRESS_DRAWER,
    HIDE_PROGRESS_DRAWER,
  } = actionTypes;

  const addBackgroundTask = (state: BackgroundActivityState, action: { task: TaskBase }) => {
    const tasks = { ...state.tasks };
    const task = cloneDeep(action.task);
    tasks[task.id] = task;

    const nextState = { ...state, tasks };

    return moveCompletedMessages(nextState, task);
  };

  const addBackgroundTaskDistributedOp = (
    state: BackgroundActivityState,
    action: { task: DistributedOperationTask },
  ) => {
    const tasks = { ...state.tasks };
    const task = { ...action.task };
    tasks[task.id] = task;

    return { ...state, tasks };
  };

  const moveCompletedMessages = (state: BackgroundActivityState, task: TaskBase) => {
    if (!task.operation) {
      return state;
    }

    const completedOperationsTypes = Object.entries(state.completedOperations)
      .filter(([_, value]) => value.length > 0)
      .map(([key]) => key) as unknown as (keyof Operations)[];

    if (completedOperationsTypes.length === 0) {
      return state;
    }

    const completedOperations = cloneDeep(state.completedOperations);

    task.operation.messages.forEach((messageId) => {
      completedOperationsTypes.forEach((type) =>
        moveCompletedMessageToTask(task, messageId, completedOperations, type),
      );
    });

    const nextState = { ...state, completedOperations };
    return updateStatusTask(nextState, task);
  };

  const moveCompletedMessageToTask = (
    task: TaskBase,
    messageId: string,
    completedOperations: CompletedOperations,
    type: keyof Operations,
  ) => {
    const messageIndex = completedOperations[type].indexOf(messageId);
    if (messageIndex !== -1) {
      task.operation[type].push(messageId);
      arrayUtils.removeByIndex(completedOperations[type], messageIndex);
    }
  };

  const addOrUpdate = (state: BackgroundActivityState, action: { task: TaskBase }) => {
    const tasks = { ...state.tasks };
    const taskId = action.task.id;
    const existingTask = tasks[taskId];

    if (existingTask) {
      tasks[taskId] = { ...existingTask, ...action.task };
    } else {
      tasks[taskId] = cloneDeep(action.task);
    }
    const nextState = { ...state, tasks };
    return moveCompletedMessages(nextState, tasks[taskId]);
  };

  const updateStatus = (state: BackgroundActivityState, action: { taskId: TaskId; status: any }) => {
    return updateTask(state, action.taskId, (tasks, taskId, task) => {
      tasks[taskId] = { ...task, status: action.status };
    });
  };

  const updateProgress = (
    state: BackgroundActivityState,
    action: { taskId: string; percent: number; indeterminate: boolean },
  ) => {
    return updateTask(state, action.taskId, (tasks, taskId, task) => {
      const { percent, indeterminate } = action;
      tasks[taskId] = { ...task, percent, indeterminate };
    });
  };

  const updateId = (state: BackgroundActivityState, action: { oldId: string; newId: string }) => {
    const tasks = { ...state.tasks };
    const task = tasks[action.oldId];
    if (task) {
      const updatedTask = { ...task, id: action.newId };
      delete tasks[action.oldId];
      tasks[action.newId] = updatedTask;
      return { ...state, tasks };
    }
    return { ...state };
  };

  const updateTaskProperties = (state: BackgroundActivityState, action: { properties: any; taskId: TaskId }) => {
    return updateTask(state, action.taskId, (tasks, taskId, task) => {
      tasks[taskId] = { ...task, ...action.properties };
    });
  };

  const deleteTask = (
    state: BackgroundActivityState,
    action: { state: TaskStatuses; taskId: TaskId; force: boolean },
  ) => {
    return updateTask(state, action.taskId, (tasks, taskId, task) => {
      if (action.force || !TaskStatuses.isInProgress(task.state)) {
        delete tasks[taskId];
        storage.removeTask(taskId);
      }
    });
  };

  const cancelTask = (state: BackgroundActivityState, action: { taskId: TaskId }) => {
    return updateTask(state, action.taskId, (tasks, taskId, task) => {
      if (TaskStatuses.isInProgress(task.status)) {
        tasks[taskId] = { ...task, status: TaskStatuses.canceled };
      }
    });
  };

  const dismissCompleted = (state: BackgroundActivityState) => {
    const result: TaskPool = {};
    Object.keys(state.tasks).forEach((key) => {
      const existedTask = state.tasks[key];
      if (!TaskStatuses.isCompleted(existedTask.status)) {
        result[key] = { ...existedTask };
      } else {
        storage.removeTask(existedTask.id);
      }
    });
    return { ...state, tasks: result };
  };

  const updateTaskOperation = (type: OperationResult) => {
    return (state: BackgroundActivityState, action: { messageId: string; entityId: string; errorLabel: string }) => {
      const { messageId, entityId, errorLabel } = action;
      const task = Object.values(state.tasks).find((x) => x.operation && x.operation.messages?.includes(messageId));

      if (!task) {
        const completedOperationsByType = [...state.completedOperations[type]];
        completedOperationsByType.push(messageId);
        return { ...state, completedOperations: { ...state.completedOperations, [type]: completedOperationsByType } };
      }

      const updatedTask = cloneDeep(task);
      updatedTask.operation[type].push(entityId || messageId);

      return updateStatusTask(state, updatedTask, errorLabel);
    };
  };

  const findDistributedTaskByOperationId = (tasks: DistributedOperationTask[], operationId: string) => {
    return Object.values(tasks).find(
      (x) => x.distributedOperation && x.distributedOperation.operationId === operationId,
    );
  };

  const updateDistributedTaskOperation = (type: OperationResult) => {
    return (state: BackgroundActivityState, action: { operationId: string }) => {
      const task = findDistributedTaskByOperationId(
        state.tasks as unknown as DistributedOperationTask[],
        action.operationId,
      );

      if (task) {
        if (type === OperationResult.Succeeded) {
          return updateTask(state, task.id, (tasks, taskId, existingTask) => {
            tasks[taskId] = {
              ...existingTask,
              percent: 100,
              status: TaskStatuses.successful,
              indeterminate: false,
            };
          });
        }
        return updateTask(state, task.id, (tasks, taskId, existingTask) => {
          tasks[taskId] = {
            ...existingTask,
            status: TaskStatuses.failed,
            indeterminate: false,
          };
        });
      }
      return state;
    };
  };

  const updateDistributedTaskProgress = (
    state: BackgroundActivityState,
    action: { operationId: string; taskId: string },
  ) => {
    const task = findDistributedTaskByOperationId(
      state.tasks as unknown as DistributedOperationTask[],
      action.operationId,
    );
    if (task) {
      // if distributed task is becoming the standard it will replace existing tasks in state
      // we'll omit type juggling for now for the case of time saving
      // @ts-ignore
      const steps = task.distributedOperation.stepsIds;
      const progressIncrement = steps.length ? Math.floor(100 / steps.length) : 100;
      return updateTask(state, task.id, (tasks, taskId, existingTask) => {
        tasks[taskId] = {
          ...existingTask,
          percent: existingTask.percent + progressIncrement,
        };
      });
    }
    return state;
  };

  const updateStatusTask = (state: BackgroundActivityState, task: TaskBase, errorLabel?: string) => {
    const operation = { ...task.operation };

    const { messages, succeeded, failed } = operation;
    const messagesReceived = succeeded.length + failed.length;

    const percent = (messagesReceived * 100) / messages.length;

    let status = TaskStatuses.inProgress;
    if (percent === 100) {
      status = failed.length === messages.length ? TaskStatuses.failed : TaskStatuses.successful;
    }

    return updateTask(state, task.id, (tasks, taskId, existingTask) => {
      tasks[taskId] = {
        ...existingTask,
        operation,
        percent,
        status,
        indeterminate: false,
        errorLabel,
      };
    });
  };

  const showProgressDrawer = (state: BackgroundActivityState) => {
    return {
      ...state,
      isProgressDrawerVisible: true,
    };
  };

  const hideProgressDrawer = (state: BackgroundActivityState) => {
    return {
      ...state,
      isProgressDrawerVisible: false,
    };
  };

  return {
    [ADD_BACKGROUND_TASK]: addBackgroundTask,
    [ADD_BACKGROUND_TASK_DISTRIBUTED_OP]: addBackgroundTaskDistributedOp,
    [UPDATE_STATUS_OF_BACKGROUND_TASK]: updateStatus,
    [UPDATE_PROGRESS_OF_BACKGROUND_TASK]: updateProgress,
    [UPDATE_ID_OF_BACKGROUND_TASK]: updateId,
    [UPDATE_BACKGROUND_TASK_PROPERTIES]: updateTaskProperties,
    [DELETE_BACKGROUND_TASK]: deleteTask,
    [CANCEL_BACKGROUND_TASK]: cancelTask,
    [DISMISS_COMPLETED_TASKS]: dismissCompleted,
    [ADD_OR_UPDATE]: addOrUpdate,
    [UPDATE_OPERATION_SUCCESS_OF_BACKGROUND_TASK]: updateTaskOperation(OperationResult.Succeeded),
    [UPDATE_DISTRIBUTED_OP_PROGRESS_OF_BACKGROUND_TASK]: updateDistributedTaskProgress,
    [UPDATE_OPERATION_FAIL_OF_BACKGROUND_TASK]: updateTaskOperation(OperationResult.Failed),
    [UPDATE_DISTRIBUTED_OP_SUCCESS_OF_BACKGROUND_TASK]: updateDistributedTaskOperation(OperationResult.Succeeded),
    [UPDATE_DISTRIBUTED_OP_FAIL_OF_BACKGROUND_TASK]: updateDistributedTaskOperation(OperationResult.Failed),
    [SHOW_PROGRESS_DRAWER]: showProgressDrawer,
    [HIDE_PROGRESS_DRAWER]: hideProgressDrawer,
  };
};

export type BackgroundTasksType = ReturnType<typeof backgroundTasksReducer>;
export const backgroundTasksReducer = createReducer(initialState, [backgroundTasksHandler]);
