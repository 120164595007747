import { makeActionCreator, buildFetchAction } from "../../../../Application/actions/actionsBuilder";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";

export const fetchAction = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  ReducerEntityPrefixTypes.Roles,
);

export const fetchUserEditRolesItemsActions = {
  begin: makeActionCreator(fetchAction.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(fetchAction.FETCH_ITEMS_SUCCESS, "items", "itemsCount"),
  failure: makeActionCreator(fetchAction.FETCH_ITEMS_FAILURE, "error"),
};

export const FETCH_EDIT_USER_ROLES_ASSIGNMENTS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  ReducerEntityPrefixTypes.Roles,
);
