import { FilterType, FilterTypesMap } from "utils/filterUtils";
import { Link } from "../../../../components/ExpandableItems/types";
import AssetTypes from "../../../../enums/assetTypes";
import PriorityLevels from "../../../../enums/priorityLevels";
import { AssignedUser, Group, PackName, User } from "../../../../interfaces";
import { Tag } from "../../../../interfaces/Tag";
import AssignedGroup from "../../../../interfaces/assignedGroup";
import { FlowBase } from "../../../../interfaces/flowInfo";
import { Removable, WithId } from "../../../../types";
import { IQuestion } from "../../Common/models";

export interface CreateAssessmentData {}

export interface AssessmentOverview {
  id: number;
  title: string;
  publisher: string;
  isDraft: boolean;
  isPurchased: boolean;
  dateCreated: string;
  dateModified: string;
  canBeDeleted: boolean;
  dependencies?: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
  };
}

export interface PublishedAssessmentOverview {
  id: number;
  title: string;
  publisherId: number;
  publisher: string;
  questionsCount?: number;
  cardType?: AssetTypes;
}

export type AssessmentsOverviewState = {
  assessments: Array<AssessmentOverview>;
  isLoading: boolean;
  totalCount: number;
  error?: Error;
};

export interface AssessmentDetailsState {
  assessment: AssessmentView;
  content: AssessmentContentView;
  isAssessmentLoading: boolean;
  isContentLoading: boolean;
  error?: Error;
  isAssessmentLoaded: boolean;
  isContentLoaded: boolean;
}

export interface AssessmentView extends AssessmentQuestionSettings {
  id: number;
  isDraft: boolean;
  isPurchased: boolean;
  hasBeenPublished: boolean;
  title: string;
  description: string;
  dateCreated?: Date;
  dateModified?: Date;
  labels: string[];
  softwareApplications: string[];
  dependencies?: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
  };
  flows: Link[];
  packs: Link[];
}

export interface AssessmentTags {
  labels: string[];
  softwareApplications: string[];
}

export interface AssessmentQuestionSettings {
  showCorrectAnswers: boolean;
  percentageRequired: boolean;
  percentageValue: number;
  attemptsAllowed: boolean;
  attemptsCount: number;
  periodValue: number;
  periodType: number;
}

export interface AssessmentPayload {
  id?: number;
  isPurchased: boolean;
  isDraft?: boolean;
  hasBeenPublished?: boolean;
  title: string;
  description: string;
  labels: string[];
  softwareApplications: string[];
  showCorrectAnswers: boolean;
  percentageRequired: boolean;
  percentageValue: number;
  attemptsAllowed: boolean;
  attemptsCount: number;
  periodValue: number;
  periodType: number;
  packs: PackName[];
  flows: FlowBase[];
}

interface AssessmentPeople extends Removable, WithId {
  priorityLevelId: PriorityLevels;
  dueDate?: Date;
  permissionIssuedDate: Date;
  inherited: boolean;
}

export interface AssessmentUser extends User, AssessmentPeople {
  userId: number;
  isPassed: boolean | null;
  lastAttempt?: Date;
  expirationDate?: string;
  passed?: boolean;
  lastAttemptDate?: string;
}

export interface AssessmentGroup extends Group, AssessmentPeople {
  groupId: number;
}

export type AssessmentUsersState = {
  usersOverview: {
    users: Array<AssessmentUser>;
    isLoading: boolean;
    totalCount: number;
  };
  usersToAdd: {
    items: Array<AssignedUser>;
    isLoading: boolean;
    itemsCount: number;
  };
  groupsToAdd: {
    items: Array<AssignedGroup>;
    isLoading: boolean;
    itemsCount: number;
  };
  error?: Error;
};

export interface AssessmentContentView {
  questions: IQuestion<Question>[];
}

export interface ContentPayload {
  id: number;
  multipleChoiceTextQuestions: MultipleChoicePayloadQuestion[];
  singleChoiceTextQuestions: SingleChoicePayloadQuestion[];
}

type QuestionType = MultipleChoiceQuestion | SingleChoiceQuestion;

export type Questions = QuestionType[];
export type Question = QuestionType;

type QuestionTypePayload = MultipleChoicePayloadQuestion | SingleChoicePayloadQuestion;

export type PayloadQuestions = QuestionTypePayload[];
export type PayloadQuestion = QuestionTypePayload;

export interface MultipleChoicePayloadQuestion {
  questionOrder: number;
  requireAllCorrectAnswers: boolean;
  text: string;
  answers: Answer[];
}

export interface SingleChoicePayloadQuestion {
  questionOrder: number;
  correctAnswer: number;
  text: string;
  answers: Answer[];
}

export interface MultipleChoiceQuestion {
  requireAllCorrectAnswers: boolean;
  text: string;
}

export interface SingleChoiceQuestion {
  correctAnswer: number;
  text: string;
}

export interface Answer {
  isCorrect: boolean;
  text: string;
}

export interface AssessmentTagsPayload {
  [key: string]: Tag[];
}

export interface IDataItem {
  text: string;
  value: number;
}

export enum AssessmentDetailsCreateSteps {
  Configuration,
  Settings,
  Content,
}

export const CreateAssessmentStepConfig = {
  [AssessmentDetailsCreateSteps.Configuration]: { showAutoSaveOnTab: true },
  [AssessmentDetailsCreateSteps.Settings]: { showAutoSaveOnTab: false },
  [AssessmentDetailsCreateSteps.Content]: { showAutoSaveOnTab: true },
};

export enum AssessmentDetailsSteps {
  Performance,
  Configuration,
  Settings,
  Content,
  People,
  AssociatedPacks,
}

export const EditAssessmentStepConfig = {
  [AssessmentDetailsSteps.Performance]: { showAutoSaveOnTab: false },
  [AssessmentDetailsSteps.Configuration]: { showAutoSaveOnTab: true },
  [AssessmentDetailsSteps.Settings]: { showAutoSaveOnTab: false },
  [AssessmentDetailsSteps.Content]: { showAutoSaveOnTab: true },
  [AssessmentDetailsSteps.People]: { showAutoSaveOnTab: false },
  [AssessmentDetailsSteps.AssociatedPacks]: { showAutoSaveOnTab: false },
};

export enum AssessmentQuestionType {
  MultipleChoiceTextQuestion = 1,
  SingleChoiceTextQuestion,
  PictureQuestion,
}

export enum AssessmentAnswerType {
  MultipleText = 1,
  SingleText,
  Picture,
}

export enum AssessmentFiltersEnum {
  Publishers = "publisherIds",
  Tags = "tags",
  SoftwareApplications = "softwareApplications",
  Labels = "labels",
  Packs = "packIds",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}

export const assessmentFilterTypes: FilterTypesMap<AssessmentFiltersEnum> = {
  dateCreated: FilterType.DateRange,
  dateModified: FilterType.DateRange,
  labels: FilterType.Array,
  packIds: FilterType.Array,
  publisherIds: FilterType.Array,
  softwareApplications: FilterType.Array,
  tags: FilterType.Array,
};

// Performance
export type EngagementLineData = {
  Date: string;
  Completed: number;
  CumulativeCompleted: number;
  NotStarted: number;
  CumulativeNotStarted: number;
  Started: number;
  CumulativeStarted: number;
}[];

export type EngagementFunnelData = {
  AssessmentId: number;
  AccountId: number;
  NotStarted: number;
  Completed: number;
  Started: number;
};

export type CardsData = {
  AccountId: number;
  AssessmentId: number;
  AverageGrade: number;
  AverageTime: number; // in seconds
  Completed: number;
  CompletionRate: number; // between 0 & 1
  Customers: number;
  PassingGrade: number; // between 0 & 1
};

export type QuestionResponsesData = {
  AssessmentId: number;
  Attempts: number;
  AvgAttempts?: number;
  CompletionRate?: number;
  CorrectAttempts?: number;
  IncorrectAttempts?: number;

  Answers: {
    AnswerId: string;
    AnswerTitle: string;
    IsAnswerCorrect: boolean;
    ChoicePercent: number;
  }[];
};

export enum EngagementLineStatus {
  completed,
  inProgress,
  notStarted,
}
export interface EngagementQuestionsData {
  Answers: AssessmentQuestionAnswer[];
  IsRequired: boolean;
  QuestionId: string;
  QuestionOrder: number;
  QuestionText: string;
  QuestionType: string;
  AvgAttempts?: number;
  Attempts?: number;
  Completed?: number;
  CompletionRate?: number;
}

export interface AssessmentQuestionAnswer {
  AnswerId: string;
  AnswerTitle: string;
  IsAnswerCorrect: boolean;
  ChoicePercent: number;
}

export interface AssessmentQuestionPeopleResponse {
  AnswerId: string;
  AssessmentId: number;
  People: AssessmentPersonResponse[];
  QuestionId: string;
}

export interface AssessmentPersonResponse {
  AccountId: number;
  AccountName: string;
  Date: string;
  Department: string;
  Email: string;
  FirstName: string;
  LastName: string;
  UserId: number;
}

export type AssessmentPeopleDetails = {
  AccountId: number;
  AssessmentId: number;
  AssessmentTitle: string;
  CompletionDate: string;
  Department: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Result: string;
  Score: number;
  StartDate: string;
  UserId: number;
  UserTitle: string;
  id: string;
};

export type AssessmentPeopleDetailsSansId = Omit<AssessmentPeopleDetails, "id">;
