import { connect, ConnectedProps } from "react-redux";
import { Icon, Popup, Table } from "semantic-ui-react";
import cn from "classnames";
import { ListViewWithHeaderAndPanel } from "../../../../../../components/listView";
import OwnerInfoCell from "../../../AddGroup/GroupOwners/OwnerInfoCell/OwnerInfoCell";
import { Tooltip } from "../../../../../../components/common/tooltip";
import NoResults from "../../../../../../components/noResults/NoResults";
import { UsersFilterForm } from "../../../../../../components/filterForms";
import SortDirections from "../../../../../../enums/sortDirections";
import { ColumnOptions } from "../../../../../../interfaces";
import { ModalWithStepsStep } from "../../../../../../components/modal/ModalWithSteps";
import { IOwnerItemModel } from "../../../../types";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { Filters } from "../../../../../../utils/queryUtils";
import { SortingDirection } from "../../../../../../enums";
import { FiltersMap } from "../../../../../../utils/filterUtils";
import "./ownersListStep.scss";
import { TextTruncate } from "components";

export interface IAssignedOwnerItemModel extends IOwnerItemModel {
  isAssigned: boolean;
}

export interface OwnerListStepProps extends ModalWithStepsStep {
  loadPage: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: FiltersMap,
  ) => void;
  owners: IAssignedOwnerItemModel[];
  ownersCount: number;
  isLoading: boolean;
  filter?: Filters;
  selectedIds: number[];
  applyFilter: (filter: Filters) => void;
  resetFilter: () => void;
  onSelectedListItemsChanged?: (selectedIds: number[]) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

export const OwnerListStep = (props: OwnerListStepProps & PropsFromRedux) => {
  const columnOptions: ColumnOptions[] = [
    {
      name: "Name",
      width: 6,
      isSortable: true,
    },
    {
      name: "Department",
      width: 5,
      isSortable: true,
    },
    {
      name: "Job title",
      width: 5,
      isSortable: true,
    },
  ];

  const buildTableBody = () => {
    return (owner: IAssignedOwnerItemModel) => {
      const readOnlyClass = cn({
        rowDisabled: owner.isAssigned,
      });

      const jobTitleClass = cn({
        rowDisabled: owner.isAssigned,
        "job-title": true,
      });

      return (
        <>
          <Table.Cell width={columnOptions[0].width} className={readOnlyClass}>
            <OwnerInfoCell user={owner} isSelected={owner.isAssigned} />
          </Table.Cell>
          <Table.Cell width={columnOptions[1].width} className={readOnlyClass}>
            <Tooltip target={<TextTruncate>{owner.department}</TextTruncate>} content={owner.department} />
          </Table.Cell>
          <Table.Cell width={columnOptions[2].width}>
            <Tooltip
              target={<TextTruncate>{owner.title}</TextTruncate>}
              content={owner.title}
              className={jobTitleClass}
            />
            {owner.isAssigned && (
              <Popup
                hideOnScroll
                position="top center"
                size="small"
                className="popup-warning warning"
                content={"This user is already an owner of the group"}
                trigger={<Icon link className="fa fa-exclamation-circle" size="huge" />}
              />
            )}
          </Table.Cell>
        </>
      );
    };
  };

  const noResultsContent = () => <NoResults title="No Owners" iconClassName="fa-user-alt-slash" />;

  // @ts-ignore
  const getFilterForm = () => <UsersFilterForm />;

  let {
    loadPage,
    owners,
    ownersCount,
    isLoading,
    filter,
    selectedIds,
    applyFilter,
    resetFilter,
    onSelectedListItemsChanged,
    usersFilterOptions,
  } = props;

  return (
    <div className="assignment-list-step">
      <ListViewWithHeaderAndPanel
        columnOptions={columnOptions}
        searchPlaceholder={`Search for Owners...`}
        loadPage={loadPage}
        itemsAmount={ownersCount}
        isLoading={isLoading}
        items={owners}
        onSelectedListItemsChanged={onSelectedListItemsChanged}
        buildTableBody={buildTableBody()}
        filter={filter}
        selectedItemIds={selectedIds}
        noResultsContent={noResultsContent()}
        applyFilter={applyFilter}
        filterOptions={usersFilterOptions}
        resetFilter={resetFilter}
        filterOptionsLoading={usersFilterOptions.isLoading}
        renderFilterForm={getFilterForm}
        sortingColumnName={columnOptions[0].name}
        sortingDirection={SortDirections.Asc}
        isSelectDisabled={(u: IAssignedOwnerItemModel) => u.isAssigned}
        isWarning={(u: IAssignedOwnerItemModel) => u.isAssigned}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { usersOverview } = state.people;

  return {
    usersFilterOptions: {
      showRoleFilter: true,
      isLoading: usersOverview.filterOptions.isLoading,
      ...usersOverview.filterOptions,
    },
  };
};

const connector = connect(mapStateToProps);

export default connector(OwnerListStep);
