import { FormSectionHeader } from "components";
import { ValidatedForm } from "components/forms";
import Dropdown from "features/Library/Common/Fields/Dropdown";
import InputField from "features/Library/Common/Fields/InputField";
import { WithFormikPayload } from "features/Library/Common/Fields/WithFormikPayload";
import { CountryModelItem, ICreateUserModel } from "features/People/types";
import { FormikProps } from "formik";
import { useMemo, useState } from "react";
import { DropdownProps } from "semantic-ui-react";
import { nameofFactory } from "utils/nameof";

export interface AddUserFormProps extends FormikProps<ICreateUserModel> {
  departments: string[];
  jobTitles: string[];
  countries: CountryModelItem[];
  disabled?: boolean;
}

export const AddUserForm = (props: AddUserFormProps) => {
  const { values, disabled, setFieldTouched, setFieldValue, departments, jobTitles, countries } = props;
  const nameof = nameofFactory<ICreateUserModel>();

  const [departmentOptions, setDepartmentOptions] = useState(
    departments.map((department) => ({ text: department, value: department })),
  );
  const [jobTitleOptions, setJobTitleOptions] = useState(
    jobTitles.map((jobTitle) => ({ text: jobTitle, value: jobTitle })),
  );

  const countryOptions = useMemo(
    () => countries.map((country) => ({ key: country.id, value: country.name, text: country.name })),
    [countries],
  );

  /* istanbul ignore next */
  const handleItemAdded = (data: DropdownProps, propertyName: string) => {
    const value = data.value as string;
    switch (propertyName) {
      case "department":
        setDepartmentOptions((prevOptions) => [...prevOptions, { text: value, value: value }]);
        break;
      case "jobTitle":
        setJobTitleOptions((prevOptions) => [...prevOptions, { text: value, value: value }]);
        break;
      default:
        console.warn(`Unknown propertyName: ${propertyName}`);
    }
  };

  const getFields = () => (
    <>
      <FormSectionHeader title="Personal Information" />
      <WithFormikPayload
        propertyName={nameof("firstName")}
        {...props}
        render={(formikProps) => (
          <InputField
            label="First Name"
            placeholder="Enter First Name"
            value={values.firstName}
            disabled={disabled}
            errorPosition={"bottom"}
            markAsRequired
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            {...formikProps}
          />
        )}
      />
      <WithFormikPayload
        propertyName={nameof("lastName")}
        {...props}
        render={(formikProps) => (
          <InputField
            label="Last Name"
            placeholder="Enter Last Name"
            value={values.lastName}
            disabled={disabled}
            setFieldTouched={setFieldTouched}
            errorPosition={"bottom"}
            markAsRequired
            setFieldValue={setFieldValue}
            {...formikProps}
          />
        )}
      />
      <WithFormikPayload
        propertyName={nameof("email")}
        {...props}
        render={(formikProps) => (
          <InputField
            label="Email"
            placeholder="Enter Email"
            value={values.email}
            disabled={disabled}
            setFieldTouched={setFieldTouched}
            errorPosition={"bottom"}
            markAsRequired
            setFieldValue={setFieldValue}
            {...formikProps}
          />
        )}
      />
      <WithFormikPayload
        {...props}
        propertyName={nameof("department")}
        render={(formikProps) => (
          <Dropdown
            {...formikProps}
            label="Department"
            placeholder="Search/select a department"
            value={values.department}
            options={departmentOptions}
            errorPosition={"bottom"}
            clearable
            search
            allowAdditions
            onAddItem={(_, data) => handleItemAdded(data, "department")}
            disabled={disabled}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        )}
      />
      <WithFormikPayload
        {...props}
        propertyName={nameof("jobTitle")}
        render={(formikProps) => (
          <Dropdown
            {...formikProps}
            label="Job Title"
            placeholder="Search/select a job title"
            value={values.jobTitle}
            options={jobTitleOptions}
            errorPosition={"bottom"}
            disabled={disabled}
            clearable
            search
            allowAdditions
            onAddItem={(_, data) => handleItemAdded(data, "jobTitle")}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        )}
      />
      <WithFormikPayload
        {...props}
        propertyName={nameof("country")}
        render={(formikProps) => (
          <Dropdown
            {...formikProps}
            label="Country"
            placeholder="Search/select a country"
            value={values.country}
            options={countryOptions}
            errorPosition={"bottom"}
            minCharacters={3}
            disabled={disabled}
            clearable
            search
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
          />
        )}
      />
    </>
  );

  return (
    <div className="create-reminder-form">
      <ValidatedForm dirty={props.dirty} formWidthComputer={16} parentWithFormik={true}>
        {getFields}
      </ValidatedForm>
    </div>
  );
};

export default AddUserForm;
