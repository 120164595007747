/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { CommonBuilderProps, QuestionFormComponents } from "./props";
import QuestionWithAnswers from "./Question/QuestionsBuilders/QuestionWithAnswers";
import { MAX_QUESTIONS_LENGTH } from "./constants";
import { Icon } from "semantic-ui-react";
import { IQuestionBase } from "./models";

export interface QuestionsBuilderProps extends CommonBuilderProps, QuestionFormComponents<IQuestionBase> {
  questions: IQuestionBase[];
  defaultDataProvider: () => IQuestionBase;
}

export const QuestionsBuilder = (props: QuestionsBuilderProps) => {
  const {
    questions,
    validatedFieldProps,
    arrayHelpers,
    onBlur,
    onChanged,
    disabled,
    defaultDataProvider,
    propertyNamePrefix,
  } = props;
  const { QuestionRenderer, AnswersBuilder, QuestionHeader } = props;

  const onAddQuestion = () => {
    arrayHelpers.push(defaultDataProvider());
    setTimeout(() => {
      onChanged?.();
    }, 0);
  };

  const onRemoveQuestion = useCallback(
    (index: number) => () => {
      arrayHelpers.remove(index);
      setTimeout(() => {
        onChanged?.();
      }, 0);
    },
    [],
  );

  const onReplaceQuestion = useCallback(
    (index: number) => (question: IQuestionBase) => {
      arrayHelpers.replace(index, question);
      setTimeout(() => {
        onChanged?.();
      }, 0);
    },
    [],
  );

  const onHandleBlur = useCallback((propsName: string) => onBlur(propsName), []);

  return (
    <>
      {questions.map((question: IQuestionBase, index: number) => (
        <QuestionWithAnswers
          question={question}
          index={index}
          onRemove={onRemoveQuestion}
          onBlur={onHandleBlur}
          onChanged={onChanged}
          arrayHelpers={arrayHelpers}
          validatedFieldProps={validatedFieldProps}
          key={question.id ?? index}
          QuestionRenderer={QuestionRenderer}
          AnswersBuilder={AnswersBuilder}
          QuestionHeader={QuestionHeader}
          onReplaceQuestion={onReplaceQuestion}
          disabled={disabled}
          propertyNamePrefix={`${propertyNamePrefix}questions.${index}.`}
        />
      ))}

      {!disabled && questions.length < MAX_QUESTIONS_LENGTH && (
        <div className={"control-panel-add"}>
          <Icon className={"plus circle icon"} size={"big"} color={"blue"} onClick={onAddQuestion} />
          <div>Add question</div>
        </div>
      )}
    </>
  );
};

export default QuestionsBuilder;
