import { toCssClass } from "../../utils/stringUtils";
import StepLabel from "./StepLabel";

import "./stepLabelsList.scss";

interface StepLabelsListProps {
  steps: any[];
  onStepSelected: (index: number) => void;
  activeIndex: number;
  availableStepIndexes: number[];
}

const StepLabelsList: React.FC<StepLabelsListProps> = (props) => {
  const { steps, onStepSelected, activeIndex, availableStepIndexes } = props;

  return (
    <div className="wizard-step-labels-list">
      {steps.map((step, index) => (
        <StepLabel
          index={index}
          text={step.props.label}
          className={toCssClass(step.props.label)}
          onClick={onStepSelected}
          active={index === activeIndex}
          required={step.props.required}
          key={index}
          disabled={!availableStepIndexes.includes(index)}
        />
      ))}
    </div>
  );
};

export default StepLabelsList;
