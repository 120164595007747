import React, { useEffect, useState } from "react";
import { DropdownWrapper } from "../dropdowns";
import { DropdownItemProps, DropdownProps, Icon } from "semantic-ui-react";
import moment from "moment";
import { isNil } from "lodash";
import classNames from "classnames";

import "./timePicker.scss";

export interface TimeInterval {
  value: number;
  unit: "hours" | "minutes";
}

export interface TimePickerProps {
  interval?: TimeInterval;
  className?: string;
  selected?: string | number | boolean | (string | number | boolean)[];
  disabled?: boolean;
  placeholder?: string;
  useAutoSelection?: boolean;
  onTimeChange?: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  autoSelectionStrategy?: (interval: TimeInterval) => (time: moment.Moment) => boolean;
}

export const closestTimeToNowStrategy = (interval: TimeInterval) => {
  const now = moment();
  const median = Math.ceil(interval.value / 2);
  return (time: moment.Moment): boolean => time.add(median, interval.unit) >= now;
};

const TimePicker: React.FC<TimePickerProps> = ({
  interval = { unit: "minutes", value: 30 },
  placeholder = "Select time",
  useAutoSelection = true,
  disabled,
  selected,
  className,
  onTimeChange,
  autoSelectionStrategy = closestTimeToNowStrategy,
}): React.ReactElement => {
  const [selectedTime, setSelectedTime] = useState<string | number | boolean | (string | number | boolean)[]>();
  const [hasBeenSelected, setHasBeenSelected] = useState<boolean>();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setSelectedTime(selected);
    setHasBeenSelected(!isNil(selected));
  }, [selected]);

  function* timeGenerator() {
    let current = start;
    while (current < end) {
      yield moment(current);
      current.add(interval.value, interval.unit);
    }
  }

  const format = (time?: moment.Moment) => {
    return time?.format("hh:mm A");
  };

  const start = moment().startOf("day");
  const end = moment().endOf("day");

  const times = [...timeGenerator()];

  const segments: DropdownItemProps[] = times.map((time) => {
    const label = format(time);
    return {
      key: label,
      text: label,
      value: label,
    };
  });

  const handleChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    if (!hasBeenSelected) {
      setHasBeenSelected(true);
    }
    setSelectedTime(data.value);
    onTimeChange?.(event, data);
  };

  const handleOpen = () => {
    setIsFocused(true);

    if (hasBeenSelected || !useAutoSelection) {
      return;
    }

    const strategy = autoSelectionStrategy(interval);
    const time = format(times.find((x) => strategy(moment(x))));
    if (time) {
      setSelectedTime(time);
    }
  };

  const handleClose = () => setIsFocused(false);

  return (
    <div className={classNames("time-picker", className, { selected: isFocused, disabled })}>
      <Icon className="fa-clock" disabled={disabled} />
      <DropdownWrapper
        inline
        value={selectedTime}
        items={segments}
        onChange={handleChange}
        placeholder={placeholder}
        onOpen={handleOpen}
        onClose={handleClose}
        disabled={disabled}
      />
    </div>
  );
};

export default TimePicker;
