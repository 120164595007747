import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { EventGroup } from "../../types/state";
import { createSelectedItemsSlice, SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface EventGroupsState extends SelectedItemsState<EventGroup> {}

const initialState: EventGroupsState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const eventGroupsSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Events, name: "groups" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  eventGroupsSlice.actions;

export default eventGroupsSlice.reducer;
export type eventGroupsSliceStateType = ReturnType<typeof eventGroupsSlice.reducer>;
