import { Component } from "react";
import { Button } from "components/buttons/button/Button";
import ModalWithSteps from "../../../modal/ModalWithSteps";
import { PriorityLevelsStep, PacksContextStep, ConfirmLicensingStep } from "../../commonSteps";
import { PacksContextItem, PriorityLevel } from "../../../../interfaces";
import {
  getRenderModalActionsPacksContextStepFunction,
  RenderModalActionsParams,
} from "../../commonSteps/packsContextStep/PacksContextStep";
import { UsersGroupsContext } from "../../../../enums";

import "./userAssignmentModalPriorityLicensing.scss";
import { PeopleType } from "features/Library/PeopleAssignments/types";

export interface UserAssignmentModalPriorityLicensingProps {
  showModal: boolean;
  onCancel?: Function;
  onConfirm?: Function;
  priorityItems: PriorityLevel[];
  packContextItems?: PacksContextItem[];
  onItemsUpdate: (priorityLevel: PriorityLevel[]) => void;
  isStepLoading: boolean;
  onSecondStep: Function;
  renderTrigger?: (closeModal: () => void) => React.ReactElement;
  selectedPeopleIds?: number[];
  usersGroupsContext?: UsersGroupsContext;
  selectedUserIds?: number[];
  selectedGroupIds?: number[];
}

interface UserAssignmentModalPriorityLicensingState {
  isDataValid: boolean;
  skipPacksContextStep: boolean;
  packsContextStepHeader?: string;
}

export default class UserAssignmentModalPriorityLicensing extends Component<
  UserAssignmentModalPriorityLicensingProps,
  UserAssignmentModalPriorityLicensingState
> {
  constructor(props: UserAssignmentModalPriorityLicensingProps) {
    super(props);

    this.state = {
      isDataValid: true,
      skipPacksContextStep: false,
      packsContextStepHeader: undefined,
    };
  }

  renderModalActionsPriorityStep = (nextStep: Function) => {
    const { onSecondStep, isStepLoading } = this.props;
    const { isDataValid } = this.state;

    return (closeModal: Function) => (
      <>
        <Button
          basic
          color="blue"
          className="cancel"
          content="Cancel"
          onClick={() => {
            this.onCloseModal();
            closeModal();
          }}
          disabled={!isDataValid || isStepLoading}
        />
        <Button
          primary
          className="next"
          content="Next"
          onClick={() => {
            this.onIsDataValidChange(false);
            onSecondStep().then(() => nextStep());
          }}
          disabled={!isDataValid || isStepLoading}
        />
      </>
    );
  };

  renderModalActionsLicensingStep = (_: Function, prevStep: Function) => {
    const { onConfirm } = this.props;

    return (closeModal: Function) => (
      <>
        <Button
          primary
          content="Previous"
          className="previous"
          onClick={() => {
            this.onIsDataValidChange(true);
            prevStep();
          }}
        />
        <Button
          primary
          className="confirm"
          content="Confirm"
          disabled={!this.state.isDataValid}
          onClick={() => {
            onConfirm?.();
            closeModal();
          }}
        />
      </>
    );
  };

  onCloseModal = () => {
    this.onIsDataValidChange(true);
    this.setSkipPacksContextStep(false);
    this.setPacksContextStepHeader();
    this.props.onCancel?.();
  };

  onIsDataValidChange = (isDataValid: boolean) => {
    if (this.state.isDataValid !== isDataValid) {
      this.setState({ isDataValid });
    }
  };

  setSkipPacksContextStep = (skipPacksContextStep: boolean) => {
    this.setState({ skipPacksContextStep });
  };

  setPacksContextStepHeader = (packsContextStepHeader?: string) => {
    if (this.state.packsContextStepHeader !== packsContextStepHeader) {
      this.setState({ packsContextStepHeader });
    }
  };

  render() {
    const { showModal, renderTrigger, isStepLoading, priorityItems, onItemsUpdate, packContextItems } = this.props;
    const { isDataValid, skipPacksContextStep, packsContextStepHeader } = this.state;
    const packContextStepModalActionsParams: RenderModalActionsParams = {
      isDataValid: isDataValid,
      onIsDataValidChange: this.onIsDataValidChange,
    };

    return (
      <ModalWithSteps
        className={"users-assignment-modal"}
        scrolling
        renderTrigger={renderTrigger}
        isLoading={isStepLoading}
        showModal={showModal}
        onCancel={this.onCloseModal}
      >
        <PriorityLevelsStep
          peopleType={PeopleType.User}
          header="Preferences"
          renderModalActions={this.renderModalActionsPriorityStep}
          priorityItems={priorityItems}
          onItemsUpdate={onItemsUpdate}
          onIsDataValidChange={this.onIsDataValidChange}
        />
        {!skipPacksContextStep && (
          <PacksContextStep
            header={packsContextStepHeader}
            renderModalActions={getRenderModalActionsPacksContextStepFunction(packContextStepModalActionsParams)}
            info={packContextItems ?? []}
            onIsDataValidChange={this.onIsDataValidChange}
            noDataLoaded={() => this.setSkipPacksContextStep(true)}
            setModalStepHeader={() => this.setPacksContextStepHeader("Licensing")}
          />
        )}
        <ConfirmLicensingStep
          header="License Confirmation"
          renderModalActions={this.renderModalActionsLicensingStep}
          info={{
            userIds:
              this.props.usersGroupsContext && this.props.usersGroupsContext === UsersGroupsContext.Users
                ? this.props.selectedPeopleIds
                : this.props.selectedUserIds ?? [],
            groupIds:
              this.props.usersGroupsContext && this.props.usersGroupsContext === UsersGroupsContext.Groups
                ? this.props.selectedPeopleIds
                : this.props.selectedGroupIds ?? [],
          }}
          onIsDataValidChange={this.onIsDataValidChange}
        />
      </ModalWithSteps>
    );
  }
}
