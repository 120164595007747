import { XYCoord } from "react-dnd";
import { useIntersection } from "../../intersection/hooks/useIntersection";
import {
  useReactFlowCanvasActions,
  useReactFlowCanvasState,
} from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import { ExtrasTypes } from "../../Extras/types";

type UseDragLayerItem = any; // @see useDragLayer typings

export const useReplacementOnOuterLayer = ({
  draggedItem,
  clientOffset,
}: {
  draggedItem: UseDragLayerItem;
  clientOffset: XYCoord | null;
}) => {
  const { headId, itemToReplace } = useReactFlowCanvasState();
  const { setItemToReplace } = useReactFlowCanvasActions();

  const { intersectedElement } = useIntersection({
    intersectionItem: itemToReplace,
    updateIntersectionItem: setItemToReplace,
    createIntersectionFilter: (currentNode) => (el) => {
      return currentNode.id !== el.id && (el.type === currentNode.type || el.type === ExtrasTypes.Deleted);
    },
    headId,
    draggedItem,
    clientOffset,
  });

  return { elementToReplace: intersectedElement };
};
