import { FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ValidatedForm } from "../../../../../../components";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { ThreatDefenceCampaignContent, ThreatDefenceCampaignOption } from "../../types/state";
import { ContentCampaignProps } from "../types";
import * as campaignEntityStateActions from "../../state/actions/threatDefenceCampaignsEntityStateActions";
import * as campaignDetailsActions from "../../state/actions/threatDefenceCampaignDetailsActions";
import * as campaignOptionsActions from "../../state/actions/threatDefenceCampaignOptionsActions";
import validationSchemas from "../../../../../../utils/validationSchemas";
import schemasUtils from "../../../../../../utils/validationSchemasUtils";
import { AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";

export type ContentAllProps = ContentCampaignProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceCampaignContent> &
  AutosaveProps;

export class Content extends Component<ContentAllProps> {
  componentDidMount() {
    this.props.acceptHandlers?.({
      onPrevious: this.onPrevious,
      onFinish: this.onFinish,
      onClose: this.onClose,
    });
    this.props.optionsActions.getCampaignEmailTemplates();
    this.props.optionsActions.getCampaignLandingPages();

    //Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  onPrevious = () => {
    this.saveContent();
  };

  onPublish = () => {
    this.props.entityStateActions.publishDraftThreatDefenceCampaignEntity(this.props.id);
  };

  onFinish = () => {
    this.onPublish();
  };

  onClose = () => {
    this.props.detailsActions.clearCampaign();
  };

  saveContent = () => {
    this.props.detailsActions.saveCampaignContent(this.props.values);
  };

  render() {
    const { dirty, isLoading } = this.props;
    return (
      <ValidatedForm dirty={dirty} isLoading={isLoading} formWidthComputer={6} disablePreventTransitionPrompt={true}>
        {this.renderFields()}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, disabled, onIsValidChange, landingPages, emailTemplates, areOptionsLoading, onBlur } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    const dropdownSelector = ({ id, name }: ThreatDefenceCampaignOption) => ({
      key: id,
      value: id,
      text: name,
    });

    const landingPagesOptions = landingPages.map(dropdownSelector);
    const emailTemplateOptions = emailTemplates.map(dropdownSelector);

    return (
      <div className="">
        <ValidatedForm.DropdownField
          label="Email Templates"
          value={values.emailTemplateId}
          propertyName="emailTemplateId"
          options={emailTemplateOptions}
          isLoading={areOptionsLoading}
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          search
          disabled={disabled}
          onBlur={onBlur}
          {...validatedFieldProps}
        />
        <ValidatedForm.DropdownField
          label="Landing Pages"
          value={values.landingPageId}
          propertyName="landingPageId"
          options={landingPagesOptions}
          isLoading={areOptionsLoading}
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          search
          disabled={disabled}
          onBlur={onBlur}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const {
      errors,
      touched,
      isValid,
      isContentValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    } = this.props;

    return {
      errors,
      touched,
      isFormValid: isValid && isContentValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ContentCampaignProps) => {
  const detailsReducer = state.library.threatDefenceCampaigns.threatDefenceCampaignDetails;
  const optionsReducer = state.library.threatDefenceCampaigns.threatDefenceCampaignOptionsReducer;
  const id = ownProps.entityId || state.library.threatDefenceCampaigns.threatDefenceCampaignEntityStateReducer.entityId;

  return {
    id,
    content: {
      ...detailsReducer.content,
      id,
    },
    isLoaded: detailsReducer.isContentLoaded,
    isLoading: detailsReducer.isLoading,
    isCreating: state.library.threatDefenceCampaigns.threatDefenceCampaignEntityStateReducer.changingEntityState,
    landingPages: optionsReducer.landingPages,
    emailTemplates: optionsReducer.emailTemplates,
    areOptionsLoading: optionsReducer.areOptionsLoading,
    isDraft: detailsReducer.campaign.isDraft,
    isContentValid: !!detailsReducer.content.emailTemplateId && !!detailsReducer.content.landingPageId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(campaignEntityStateActions, dispatch),
  detailsActions: bindActionCreators(campaignDetailsActions, dispatch),
  optionsActions: bindActionCreators(campaignOptionsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ContentAllProps, ThreatDefenceCampaignContent>({
  getInitValues: (props) => props.content,
  stateProvider: (values) => values,
  entityUpdater: (props) => props.entityStateActions.updateThreatDefenceCampaignContent,
})(Content);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.campaignContent,
  enableReinitialize: true,
  isInitialValid: (props: ContentCampaignProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.campaignContent, props.content),
  mapPropsToValues: (props: ContentCampaignProps & PropsFromRedux) => props.content,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
