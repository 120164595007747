import { Dispatch } from "@reduxjs/toolkit";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/surveyGroupsSlice";
import surveysDataService from "../../services/surveysDataService";
import { GetContentUsersParams } from "../../../../People/types";
import userListUtils from "../../../../../utils/userListUtils";

export const fetchSurveyGroups =
  ({ entityId, skip, top, sortingColumn, sortingDirection, filter, term }: GetContentUsersParams) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const { filterParams } = userListUtils.getFetchUsersParams(term, sortingColumn, sortingDirection, filter);

      const { items, count } = await surveysDataService.getSurveyGroupsAsyncV2({
        entityId,
        skip,
        top,
        filter: filterParams,
        term,
      });
      items.forEach((item: any) => (item.id = item.groupId));
      dispatch(fetchSuccess({ items, totalCount: count }));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
