import React, { ChangeEvent, useEffect } from "react";
import { Form } from "semantic-ui-react";
import cn from "classnames";
import get from "lodash/get";

import ValidationErrorPrompt from "./ValidationErrorPrompt";
import FieldLabel from "./FieldLabel";

import "./validatedField.scss";

export enum ErrorPosition {
  right = "right",
  bottom = "bottom",
  top = "top",
}

interface Tooltip {
  info: React.ReactNode;
  width: number;
  hoverable?: boolean;
}

export const HiddenError = "hidden-error";

export interface ValidatedFieldProps {
  label?: string;
  propertyName: string;
  children: React.ReactNode;

  tooltip?: Tooltip;
  markAsRequired?: boolean;
  value?: string | number | boolean | object | [any];
  placeholder?: string;
  errorPosition?: ErrorPosition;
  errors?: any;
  touched?: any;
  handleChange?: (e: ChangeEvent<any>) => void;
  handleBlur?: (e: ChangeEvent<any>) => void;
  isFormValid?: boolean;
  onIsFormValidChange?: (isFormValid: boolean) => void;
  pushdown?: boolean;
  withoutErrorMessage?: boolean;
  inline?: boolean;
  className?: string;
  errorClassName?: string;
}

export default function ValidatedField(props: ValidatedFieldProps) {
  const {
    label,
    tooltip,
    propertyName,
    errors,
    touched,
    markAsRequired,
    pushdown,
    onIsFormValidChange,
    errorPosition = ErrorPosition.right,
    withoutErrorMessage = false,
    isFormValid = true,
    inline = false,
    className,
  } = props;

  useEffect(() => {
    onIsFormValidChange?.(isFormValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormValid]);

  const errorMessage = get(errors, propertyName);
  const isInErrorState = errorMessage && isPropertyTouched(touched, propertyName);

  return (
    <React.Fragment>
      {label && (
        <FieldLabel label={label} tooltip={tooltip} markAsRequired={markAsRequired} isInErrorState={isInErrorState} />
      )}
      <Form.Field
        error={isInErrorState}
        className={cn("validated-field", errorPosition, className, {
          pushdown,
          inline,
        })}
      >
        {props.children}
        {!withoutErrorMessage && isInErrorState && errorMessage !== HiddenError && (
          <ValidationErrorPrompt
            errorPosition={errorPosition}
            text={errorMessage}
            errorClassName={props.errorClassName}
          />
        )}
      </Form.Field>
    </React.Fragment>
  );
}

function isPropertyTouched(touched: boolean, propertyName: string) {
  let value = get(touched, propertyName);
  // To work around the issue with multivalue field validation:
  // https://github.com/jaredpalmer/formik/issues/793
  if (value instanceof Array && value.length === 0) {
    return true;
  }
  // Otherwise should work fine
  return !!value;
}
