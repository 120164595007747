import { ELEMENT_HALF_HEIGHT, ELEMENT_HALF_WIDTH } from "../../constants";
import { ElAndPos, AlignedWith } from "../types";
import isInZone from "./isInZone";

// detect whether current axis position is near some element
// it works with sorted array of items using the binary search algorytm
// is recursive
export default function isParalellWith(
  nodesCoordsSorted: ElAndPos[],
  axis: "x" | "y",
  axisPosition: number,
  otherAxisPosition: number, // is needed for distance calculation
  shift?: { from: number; to: number },
  marked?: { [key: string]: true },
): AlignedWith | null {
  const markedMap = marked || {};
  if (nodesCoordsSorted.length === 0) {
    return null;
  }
  const shiftPos = typeof shift === "undefined" ? { from: 0, to: nodesCoordsSorted.length - 1 } : shift;
  const length = shiftPos.to - shiftPos.from + 1;
  const picked = Math.round(length / 2) - 1 + shiftPos.from;

  const elem = nodesCoordsSorted[picked];
  const checkIfInZone = isInZone(axisPosition, elem[axis]);
  if (checkIfInZone.inZone) {
    let distance = 0;

    if (axis === "x") {
      distance = elem.y - otherAxisPosition - (elem.edge - 1) * ELEMENT_HALF_HEIGHT;
    } else if (axis === "y") {
      distance = elem.x - otherAxisPosition - (elem.edge - 1) * ELEMENT_HALF_WIDTH;
    }

    return {
      id: elem.id, // main calculation result

      // additional useful information
      edge: elem.edge,
      distance: distance,
      diff: checkIfInZone.diff,
    };
  }

  markedMap[`${picked}`] = true;

  if (axisPosition > elem[axis]) {
    const nextIndex = picked + 1;
    // go to right part of array
    if (typeof nodesCoordsSorted[nextIndex] !== "undefined" && typeof markedMap[`${nextIndex}`] === "undefined") {
      return isParalellWith(
        nodesCoordsSorted,
        axis,
        axisPosition,
        otherAxisPosition,
        {
          from: nextIndex,
          to: shiftPos.to,
        },
        markedMap,
      );
    }
    return null;
  }

  if (axisPosition < elem[axis]) {
    const nextIndex = picked - 1;
    // go to right left of array
    if (typeof nodesCoordsSorted[nextIndex] !== "undefined" && typeof markedMap[`${nextIndex}`] === "undefined") {
      return isParalellWith(
        nodesCoordsSorted,
        axis,
        axisPosition,
        otherAxisPosition,
        {
          from: shiftPos.from,
          to: nextIndex,
        },
        markedMap,
      );
    }
    return null;
  }
  return null;
}
