import { pluralize } from "../../../utils/stringUtils";

import "./selectedItemsLabel.scss";

interface Props {
  itemName: string;
  selectedItemsAmount: number;
  shouldPluralize?: boolean;
}

export default function SelectedItemsLabel({ itemName, selectedItemsAmount, shouldPluralize = true }: Props) {
  let label: string;

  if (shouldPluralize) {
    label = `${selectedItemsAmount} ${pluralize(itemName, selectedItemsAmount)} Selected`;
  } else {
    label = `${selectedItemsAmount} ${itemName} Selected`;
  }

  return (
    <span data-testid="selected-items-label" className="selected-items-label">
      {label}
    </span>
  );
}
