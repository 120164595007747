import { ColumnOption } from "../../../../../../../../interfaces/columnOptions";
import { EditPriorityLinkButton } from "../../../../../../../../components/buttons/linkButtons";
import { FlowItemMenu } from "./ContentItemMenu/FlowItemMenu";
import { CompletionStatusEnum } from "../../../../../../../../enums/сompletionStatusEnum";

import UserInfoCell from "../../../../../../../../components/userInfoCell/UserInfoCell";
import StartFlowButton from "../../../../Common/StartFlowButton/StartFlowButton";
import UserStatus from "../../../../../common/UserStatus/UserStatus";
import {
  ContentType,
  PeopleAssignment,
  PeopleAssignmentsHandlers,
  PeopleType,
} from "../../../../../../PeopleAssignments/types";
import dateTimeUtils from "utils/dateTimeUtils";
import PriorityCell from "components/priorityLevels/priorityCell/PriorityCell";
import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";

export enum FlowUserAssignmentColumns {
  Name = "Name",
  Priority = "Priority Level",
  Status = "Status",
}

export const FlowColumnToParamMap: {
  [P in Lowercase<FlowUserAssignmentColumns>]: string;
} = {
  name: "firstName",
  "priority level": "priority",
  status: "completionStatus",
};

export interface FlowPeopleAssignment extends PeopleAssignment {
  completionStatus: CompletionStatusEnum;
  expirationDate?: string | null;
}

export interface GetFlowOverviewColumnOptionsParams extends PeopleAssignmentsHandlers {
  readonly?: boolean;
  isSortable?: boolean;
  startFlow: (ids: number[]) => void;
  canAutoStart: boolean;
}

export const getFlowUserColumnOptions = <T extends FlowPeopleAssignment>(
  params: GetFlowOverviewColumnOptionsParams,
): ColumnOption<T>[] => {
  return [
    {
      name: FlowUserAssignmentColumns.Name,
      width: 5,
      isSortable: true,
      render: (item) => <UserInfoCell deepLink={true} user={item} />,
    },
    {
      name: FlowUserAssignmentColumns.Priority,
      width: 4,
      isSortable: params.isSortable,
      render: (item) => (
        <PriorityCell
          priorityLevel={item.priorityLevelId}
          peopleType={PeopleType.User}
          contentType={ContentType.Flow}
          dueDate={dateTimeUtils.localFormatWithValidation(item.dueDate)}
          daysToComplete={item.daysToComplete}
        />
      ),
    },
    {
      name: FlowUserAssignmentColumns.Status,
      width: 3,
      isSortable: params.isSortable,
      render: (item) => <UserStatus completionStatus={item.completionStatus} canAutoStart={params.canAutoStart} />,
    },
    {
      name: "",
      width: 2,
      className: "align-right",
      isSortable: false,
      render: (item, isChecked) => (
        <FlowItemMenu circle outlinedEllipsis isChecked={isChecked} item={item} readonly={params.readonly}>
          <StartFlowButton onClick={() => params.startFlow([item.id])} isDisabled={!params.canAutoStart} />
          <EditPriorityLinkButton onClick={() => params.onEditPriority?.({ [PeopleType.User]: [item] })} />
          <RemovePriorityButton
            onClick={() => {
              params.onRemove({ [PeopleType.User]: [item.id] });
            }}
            isDisabled={params.readonly || !item.allowRemovePriority}
            peopleType={PeopleType.User}
            context="people"
            entityType={ContentType.Flow}
            multiple={false}
            showTooltip={!item.allowRemovePriority}
          />
        </FlowItemMenu>
      ),
    },
  ];
};
