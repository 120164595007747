import { useState } from "react";

interface Props {
  resetPacks(): void;
}

type hookResult = [string, (term: string) => void];

export function useSearch({ resetPacks }: Props): hookResult {
  const [term, setTerm] = useState<string>("");

  const onChange = (searchTerm: string) => {
    resetPacks();
    setTerm(searchTerm);
  };

  return [term, onChange];
}
