import { TextTruncate } from "components";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { FC } from "react";
import { ColumnOption } from "../../../../../../../interfaces/columnOptions";
import dateTimeUtils from "../../../../../../../utils/dateTimeUtils";
import { EmailSendDetailEntity, EmailSendDetailStatus } from "../types";
import { RequestState } from "features/Application/globaltypes/fetchRequest";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelopeOpen,
  faCircleCheck,
  faBullseyePointer,
  faCircleExclamation,
  faCircleXmark,
  faCalendarCheck,
} from "@fortawesome/pro-solid-svg-icons";

import "../emailPerformance.scss";
import styles from "./statuses.module.scss";
import { Tooltip } from "components/common/tooltip";
import { Link } from "react-router-dom";
import { RouteNames } from "enums";
import moboConfigurator from "moboConfigurator";

export interface EmailSendProps {
  sendDetailEntities: EmailSendDetailEntity[];
  filter: PerformanceFilter;
  loading: RequestState<EmailSendDetailEntity[]>;
}

export const EmailSendDetailStatusToString: { [key in EmailSendDetailStatus]: string } = {
  [EmailSendDetailStatus.Scheduled]: "Scheduled",
  [EmailSendDetailStatus.Send]: "Sent",
  [EmailSendDetailStatus.Sent]: "Sent",
  [EmailSendDetailStatus.Opened]: "Opened",
  [EmailSendDetailStatus.Clicked]: "Clicked",
  [EmailSendDetailStatus.Failed]: "Failed",
  [EmailSendDetailStatus.Cancelled]: "Cancelled",
};

export const EmailSendDetailStatusToIcon: { [key in EmailSendDetailStatus]: IconDefinition } = {
  [EmailSendDetailStatus.Scheduled]: faCalendarCheck,
  [EmailSendDetailStatus.Send]: faCircleCheck,
  [EmailSendDetailStatus.Sent]: faCircleCheck,
  [EmailSendDetailStatus.Opened]: faEnvelopeOpen,
  [EmailSendDetailStatus.Clicked]: faBullseyePointer,
  [EmailSendDetailStatus.Failed]: faCircleExclamation,
  [EmailSendDetailStatus.Cancelled]: faCircleXmark,
};

export const EmailSendDetailStatusToColor: { [key in EmailSendDetailStatus]: string } = {
  [EmailSendDetailStatus.Scheduled]: "icon-send-scheduled",
  [EmailSendDetailStatus.Send]: "icon-send-complete",
  [EmailSendDetailStatus.Sent]: "icon-send-complete",
  [EmailSendDetailStatus.Opened]: "icon-send-complete",
  [EmailSendDetailStatus.Clicked]: "icon-send-complete",
  [EmailSendDetailStatus.Failed]: "icon-send-failed",
  [EmailSendDetailStatus.Cancelled]: "icon-send-canceled",
};

const columnOptions: ColumnOption<EmailSendDetailEntity>[] = [
  {
    name: "Name",
    width: 4,
    isSortable: false,
    render: (entity) => {
      return (
        <div className="emailListClamp">
          <Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.peopleUsers}/${entity.UserId}`)} className={styles.linkButton}>
            <Tooltip
              target={<TextTruncate>{entity.UserFullName}</TextTruncate>}
              content={entity.UserFullName}
            />
          </Link>
        </div>
      );
    },
  },
  {
    name: "Email",
    width: 3,
    isSortable: false,
    render: (entity) => (
      <div className="emailListClamp">
        <Tooltip target={<TextTruncate>{entity.UserEmail}</TextTruncate>} content={entity.UserEmail} />
      </div>
    ),
  },
  {
    name: "Send date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.SendDate),
  },
  {
    name: "Open date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.OpenDate),
  },
  {
    name: "Click date",
    width: 3,
    isSortable: false,
    render: (entity) => dateTimeUtils.formatWithValidation(entity.ClickDate),
  },
  {
    name: "Status",
    width: 3,
    isSortable: false,
    render: (entity) => (
      <div className="emailSendStatusIcon">
        <FontAwesomeIcon
          icon={EmailSendDetailStatusToIcon[entity.Status]}
          className={`${styles[EmailSendDetailStatusToColor[entity.Status]]}`}
        />
        &nbsp;
        {EmailSendDetailStatusToString[entity.Status]}
      </div>
    ),
  },
];

export const EmailSendComponent: FC<EmailSendProps> = (props) => {
  const { sendDetailEntities, filter, loading } = props;
  return (
    <RequestStatusRenderer state={loading}>
      <GenericPerformanceList rows={sendDetailEntities} columns={columnOptions} title="People" filter={filter} />
    </RequestStatusRenderer>
  );
};

export const EmailSend = EmailSendComponent;
