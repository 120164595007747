import React from "react";
import { Icon, Input } from "semantic-ui-react";
import DropdownWrapper from "../../../../../../components/dropdowns/dropdownWrapper/DropdownWrapper";
import RequiredLabel from "../../../../../../components/fieldsMapper/requiredLabel/RequiredLabel";
import { ensureTrimmed } from "../../../../../../utils/stringUtils";
import { MappedField, MapToField } from "../../types";

import "./mappingRow.scss";

type UpdateValueFunction = (index: number, value: any) => void;

export interface MappingRowProps {
  index: number;
  mappedField: MappedField;
  requiredMapToField: MapToField | undefined;
  mapToDropdownOptions: { text: string; value: string }[];
  onMapFromChange: UpdateValueFunction;
  onMapToChange: UpdateValueFunction;
  isReadOnly: boolean;
}

const MappingRow: React.FC<MappingRowProps> = ({
  index,
  mappedField,
  requiredMapToField,
  mapToDropdownOptions,
  onMapToChange,
  onMapFromChange,
  isReadOnly,
}) => {
  return (
    <div className="attribute-mapping-row" key={index}>
      <Input
        className="mapping-input"
        data-testid="map-from"
        placeholder="Value"
        value={mappedField.mapFrom}
        onBlur={() => ensureTrimmed(mappedField.mapFrom, (value) => onMapFromChange(index, value))}
        onChange={(_, data) => onMapFromChange(index, data.value)}
      />
      <Icon className="fal fa-arrow-right outline" />

      {requiredMapToField ? (
        <div className="required-field">
          <div className="field-name">{requiredMapToField.name}</div>
          <RequiredLabel />
        </div>
      ) : (
        <DropdownWrapper
          className="mapping-dropdown"
          data-testid="map-to"
          items={mapToDropdownOptions}
          placeholder="Value"
          selection
          clearable
          search
          value={mappedField.mapTo}
          allowAdditions
          onChange={(_, data) => onMapToChange(index, data.value)}
          onBlur={() => ensureTrimmed(mappedField.mapTo, (value) => onMapToChange(index, value))}
          disabled={isReadOnly}
        />
      )}
    </div>
  );
};

export default MappingRow;
