import { combineReducers } from "redux";
import { withFetchingEntityListItems } from "../../../../../features/Application/reducers/hoc/withFetchingItems";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { packDetailsReducer } from "./packDetailsReducer";
import packEntityStateReducer from "./packEntityStateReducer";
import { packListingReducer } from "./packListingReducer";
import packTypesSlice from "../slices/packTypesSlice";
import packLicenseTypesSlice from "../slices/packLicenseTypesSlice";
import packsSearchSlice from "../slices/packsSearchSlice";
import availablePacksFiltersSlice from "../slices/availablePacksFiltersSlice";
import packsOverviewFiltersSlice from "../slices/packsOverviewFiltersSlice";
import accountPacksFiltersSlice from "../slices/accountPacksFiltersSlice";
import packsOverviewGridSlice, { OverviewState } from "../slices/packsOverviewSlice";
import availablePacksSearchSlice from "../slices/availablePacksSearchSlice";
import availablePacksSlice from "../slices/availablePacksSlice";
import packVisibilityForAccountsSlice from "../slices/packVisibilityForAccountsSlice";
import packAccountTypesVisibilitySlice from "../slices/packAccountTypesVisibilitySlice";
import accountPacksSearchSlice from "../slices/accountPacksSearchSlice";
import requestedPacksLicensesSlice from "../slices/requestedPacksLicensesSlice";
import packContentTab from "./packContentTabReducer";
import packPurchasedViewSlice from "../slices/packPurchasedViewSlice";
import packUserLicensesSlice from "../slices/packUserLicensesSlice";
import packGroupLicensesSlice from "../slices/packGroupLicensesSlice";
import packIssueLicenseModalSlice from "../slices/packIssueLicenseModalSlice";
import packUsersAvailableForLicensingSlice from "../slices/packUsersAvailableForLicensingSlice";
import { accountFiltersReducer } from "../slices/accountFilterSlice";
import accountTypesAvailableForPackSlice from "../slices/accountTypesAvailableForPackSlice";
import packTrialPeriodsSlice from "../slices/packTrialPeriodsSlice";
import accountPackLicensesSlice from "../slices/accountPackLicensesSlice";
import packFiltersSlice from "../slices/packFiltersSlice";
import packGroupsAvailableForLicensingSlice from "../slices/packGroupsAvailableForLicensingSlice";
import userAvailablePacksGridSlice from "../slices/userAvailablePacksGridSlice";
import peopleIssueLicenseModalSlice from "../slices/peopleIssueLicenseModalSlice";
import groupAvailablePacksGridSlice from "../slices/groupAvailablePacksGridSlice";
import packPerformanceSlice from "../slices/packPurchasedPerformanceSlice";
import associatedPacksSlice from "../slices/associatedPacksSlice";

const namespace = ReducerNamespaceTypes.Licensing;
const entityPrefix = ReducerEntityPrefixTypes.Packs;

const packsReducer = combineReducers({
  overviewList: withFetchingEntityListItems<OverviewState>(namespace, entityPrefix),
  overviewGrid: packsOverviewGridSlice,
  packDetailsReducer,
  packListingReducer,
  packEntityStateReducer,
  packFilters: packFiltersSlice,
  packsModal: availablePacksSlice,
  packsVisibilityForAccount: packVisibilityForAccountsSlice,
  accountTypesVisibility: packAccountTypesVisibilitySlice,
  availableAccountTypesForPack: accountTypesAvailableForPackSlice,
  packTypes: packTypesSlice,
  requestedPacksLicenses: requestedPacksLicensesSlice,
  packLicenseTypes: packLicenseTypesSlice,
  overviewSearch: packsSearchSlice,
  packsModalFilters: availablePacksFiltersSlice,
  packsOverviewFilters: packsOverviewFiltersSlice,
  accountPacksFilters: accountPacksFiltersSlice,
  packsModalSearch: availablePacksSearchSlice,
  accountPacksSearch: accountPacksSearchSlice,
  packContentTab,
  purchasedPack: combineReducers({
    packPurchasedView: packPurchasedViewSlice,
    packUserLicenses: packUserLicensesSlice,
    packGroupLicenses: packGroupLicensesSlice,
    issueLicenseModal: combineReducers({
      modal: packIssueLicenseModalSlice,
      usersAvailableForLicensing: packUsersAvailableForLicensingSlice,
      groupsAvailableForLicensing: packGroupsAvailableForLicensingSlice,
    }),
  }),
  accountsFilters: accountFiltersReducer,
  packTrialPeriods: packTrialPeriodsSlice,
  peopleIssueLicenseModal: combineReducers({
    modal: peopleIssueLicenseModalSlice,
    userAvailablePacks: userAvailablePacksGridSlice,
    groupAvailablePacks: groupAvailablePacksGridSlice,
  }),
  shared: combineReducers({
    accountPacks: accountPackLicensesSlice,
  }),
  performance: packPerformanceSlice,
  associatedPacks: associatedPacksSlice,
});

export type PacksState = ReturnType<typeof packsReducer>;

export { packsReducer };
