import React, { useCallback } from "react";
import { isEmpty, noop, partial } from "lodash";
import { useFormik } from "formik";
import { connect, ConnectedProps } from "react-redux";
import { Header, Icon, Radio } from "semantic-ui-react";
import Tooltip from "../../../../../../components/common/tooltip/Tooltip";
import ExpandableLinks from "../../../../../../components/ExpandableItems/ExpandableLinks/ExpandableLinks";
import validationSchemas from "../../../../../../utils/validationSchemas";
import { ValidatedForm } from "../../../../../../components/forms";
import { NormalizedDropdown } from "../../../../../../utils/miscellaneousUtils";
import { bindAction } from "../../../../../../interfaces/redux";
import { AppDispatch, RootState } from "../../../../../Application/globaltypes/redux";
import { updateFlowTags } from "../../../state/actions/infoActionCreators";
import { setNewTag } from "../../../state/slices/flowBaseSlice";
import { getReceiveUpdatesModalData, SettingsPayload } from "./helper";
import { TagsEnum } from "../../../../../../interfaces/TagsEnum";
import { renderCustomLabel } from "../../../../../../components/multiSelect/helpers";
import { flowInformationSelector, getMapTagsSelector } from "../../../state/selectors";
import styles from "./SettingsForm.module.scss";
import { changeReceiveUpdatesType } from "features/Library/Flows/state/thunks/flowInfoThunk";
import { ReceiveUpdatesTypes } from "features/Library/Flows/types/flowBase";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface SettingsFormOwnProps {
  settingsInfo: SettingsPayload;
  isDisabled: boolean;
  showReceiveUpdatesSection?: boolean;
  isLoading?: boolean;
}

export type SettingsFormProps = SettingsFormOwnProps & PropsFromRedux;

export const SettingsForm = (props: SettingsFormProps) => {
  const { settingsInfo, isLoading } = props;
  const {
    allUsersReceiveUpdatesTooltip,
    usersWhoHaveNotStartedReceiveUpdatesTooltip,
  } = getReceiveUpdatesModalData();

  const showPacks = !useFeatureFlag(FeatureFlags.AssociatedPacks);

  const tagsFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      labels: settingsInfo.labels,
      softwareApplications: settingsInfo.softwareApplications,
    },
    validationSchema: validationSchemas.flowSettings,
    onSubmit: noop,
  });

  const handleChangeTag = async (
    field: "labels" | "softwareApplications",
    value: NormalizedDropdown,
    shouldValidate?: boolean,
  ) => {
    const { updateTags } = props;
    const validationErrors = await tagsFormik.setFieldValue(field, value.selected, shouldValidate);
    if (isEmpty(validationErrors?.[field])) {
      updateTags(settingsInfo.id, value.selected, field);
    }
  };

  const isReadonlyTags = useCallback(
    (filed: "labels" | "softwareApplications") => {
      const tags = {
        softwareApplications: props.softwareApplications,
        labels: props.labels,
      };
      return tagsFormik.values[filed].every((tag) => tags[filed][tag]?.isPurchased);
    },
    [tagsFormik.values, props.labels, props.softwareApplications],
  );

  const onAddTag = (type: TagsEnum, _event: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
    const { setNewFlowTag } = props;
    setNewFlowTag({ type, value });
  };

  const changeReceiveUpdatesType = (type: ReceiveUpdatesTypes) => {
    const { changeReceiveUpdatesType } = props;
    changeReceiveUpdatesType(settingsInfo.id, type);
  };

  const renderFields = () => {
    const { normalizedTags, isDisabled } = props;

    const renderReceiveUpdatesSection = () => {
      return (
        <>
          <Header>Who should receive flow updates?</Header>
          <div className={styles["receive-updates-options"]}>
            <div className={styles["receive-updates-options-item"]}>
              <Radio
                id="allUsersRadio"
                className={styles["receive-updates-toggle"]}
                label="All users"
                checked={settingsInfo.updateType === ReceiveUpdatesTypes.All}
                onClick={() => changeReceiveUpdatesType(ReceiveUpdatesTypes.All)}
              />
              <Tooltip
                tooltipClassName={styles["receive-updates-tooltip-item"]}
                target={<Icon className="info circle" />}
                content={allUsersReceiveUpdatesTooltip}
                position="top center"
                hideOnScroll
                showAlways
              />
            </div>
            <div className={styles["receive-updates-options-item"]}>
              <Radio
                id="usersWhoHaveNotStartedRadio"
                className={styles["receive-updates-toggle"]}
                label="Users who haven't started"
                checked={settingsInfo.updateType === ReceiveUpdatesTypes.NotStarted}
                onClick={() => changeReceiveUpdatesType(ReceiveUpdatesTypes.NotStarted)}
              />
              <Tooltip
                tooltipClassName={styles["receive-updates-tooltip"]}
                target={<Icon className="info circle" />}
                content={usersWhoHaveNotStartedReceiveUpdatesTooltip}
                position="top center"
                hideOnScroll
                showAlways
              />
            </div>
          </div>
        </>
      );
    };

    return (
      <div className={styles.root}>
        {!isLoading && renderReceiveUpdatesSection()}
        <Header>Labeling</Header>
        <div className={styles["settings-fields"]}>
          <ValidatedForm.MultiSelectWithAdditionField
            {...tagsFormik}
            options={normalizedTags?.softwareApplications}
            label="Software Application"
            value={tagsFormik.values.softwareApplications}
            propertyName="softwareApplications"
            disabled={isDisabled}
            shouldValidate={true}
            isCaseSensitive={true}
            setFieldValue={handleChangeTag}
            readonly={isReadonlyTags("softwareApplications")}
            onAddItem={partial(onAddTag, TagsEnum.SoftwareApplication)}
            renderLabel={renderCustomLabel}
          />
          <ValidatedForm.MultiSelectWithAdditionField
            {...tagsFormik}
            options={normalizedTags?.labels}
            label="Labels"
            value={tagsFormik.values.labels}
            propertyName="labels"
            disabled={isDisabled}
            shouldValidate={true}
            isCaseSensitive={true}
            setFieldValue={handleChangeTag}
            readonly={isReadonlyTags("labels")}
            onAddItem={partial(onAddTag, TagsEnum.Label)}
            renderLabel={renderCustomLabel}
          />
          {showPacks && (
            <ExpandableLinks
              label="Associated Packs"
              linkFormatter={(linkId) => `/licensing/packs/listings/${linkId}`}
              iconName="fa-box-full"
              links={settingsInfo.packs}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <ValidatedForm
      disablePreventTransitionPrompt={true}
      dirty={tagsFormik.dirty}
      isLoading={isLoading}
      formWidthComputer={12}
      parentWithFormik={true}
    >
      {renderFields}
    </ValidatedForm>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    normalizedTags: getMapTagsSelector(state),
    labels: flowInformationSelector(state).info.labels,
    softwareApplications: flowInformationSelector(state).info.softwareApplications,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeReceiveUpdatesType: bindAction(changeReceiveUpdatesType, dispatch),
    updateTags: bindAction(updateFlowTags, dispatch),
    setNewFlowTag: bindAction(setNewTag, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SettingsForm);
