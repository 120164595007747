import React, { FC } from "react";
import { Icon } from "semantic-ui-react";

import { DeleteLinkButton } from "../../../../../components/buttons/linkButtons";
import { Tooltip } from "../../../../../components/common/tooltip";
import { Strings } from "../../../../../enums";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface DeletePdfButtonProps {
  purchasedSelected: boolean;
  undeletableSelected: boolean;
  onClick: () => void;
}

export const DeletePdfButton: FC<DeletePdfButtonProps> = ({ purchasedSelected, undeletableSelected, onClick }) => {
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const deleteDisabled = purchasedSelected || (!deleteContentEnabled && undeletableSelected);
  const message = purchasedSelected
    ? Strings.tooltipMessages.purchased.operationsDisabled("PDFs")
    : Strings.tooltipMessages.deleteDisabled("PDFs", ["Flows", "Packs", "Users", "Groups"]);

  return (
    <Tooltip
      hideOnScroll
      content={message}
      position="left center"
      showAlways={deleteDisabled}
      target={
        <DeleteLinkButton
          after={deleteDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
          isDisabled={deleteDisabled}
          onClick={onClick}
        />
      }
    />
  );
};
