import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { BaseIntegrationConfigs } from "../../types";

export type IntegrationDetailsState<T extends BaseIntegrationConfigs = BaseIntegrationConfigs> = {
  data: T;
  isLoading: boolean;
  error?: Error;
};

const initialState: IntegrationDetailsState = {
  data: {} as BaseIntegrationConfigs,
  isLoading: false,
  error: undefined,
};

const integrationDetailsSlice = createSlice({
  name: `${ReducerNamespaceTypes.Accounts}/${ReducerEntityPrefixTypes.Integrations}/details`,
  initialState: initialState,
  reducers: {
    fetchBegin(state) {
      state.isLoading = true;
    },
    fetchSuccess(state: IntegrationDetailsState, action: PayloadAction<BaseIntegrationConfigs>) {
      state.data = action.payload;
      state.isLoading = false;
    },
    fetchFailure(state: IntegrationDetailsState, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = integrationDetailsSlice.actions;

export default integrationDetailsSlice.reducer;
