import { IStripoClient, StripoInitOpts } from "../../../lib/stripo";
import { bindActionCreators } from "redux";
import { Store } from "@reduxjs/toolkit";

import { templateUtils } from "../utils/templateUtils";
import { updateRawTemplate, updateTemplate } from "../../../features/SystemNotifications/state/slices/notifyStepSlice";
import { RootState } from "../../../features/Application/globaltypes/redux";
import { inlineStyles } from "./inlineStyles";
import { baseLayout } from "./baseLayout";
import { renderEmailPreview } from "../../../features/SystemNotifications/state/thunks/notifyStepThunks";

export interface IEmailEditorController {
  getTemplate(): Promise<string>;
  compileEmail(): Promise<string>;
  renderPreview(): Promise<void>;
  updateTemplate(newTemplate: string): void;
  updateRawTemplate(newTemplate: string): void;
  initOnce(opts: StripoInitOpts): void;
  reInitializeEditor(opts: StripoInitOpts): void;
}

export class EmailEditorController implements IEmailEditorController {
  constructor(private stripo: IStripoClient, private store: Store<RootState>) {}

  getTemplate(): Promise<string> {
    return this.stripo.getTemplate();
  }

  compileEmail(): Promise<string> {
    return this.stripo.compileEmail();
  }

  async renderPreview(): Promise<void> {
    const { templateType, templateSasUrl, rawTemplateSasUrl, updatedRawTemplate } =
      this.store.getState().systemNotifications.notifyStep;
    const html = await this.compileEmail();
    const extractedTemplate = templateUtils.extractTemplateFromLayout(html);
    const renderPreview = bindActionCreators(renderEmailPreview, this.store.dispatch);

    const rawTemplate = await this.getTemplate();
    const rawTemplateBody = updatedRawTemplate
      ? updatedRawTemplate
      : templateUtils.extractTemplateFromLayout(rawTemplate);

    renderPreview(templateSasUrl, rawTemplateSasUrl, extractedTemplate, rawTemplateBody, templateType);
  }

  updateTemplate(newTemplate: string): void {
    bindActionCreators(updateTemplate, this.store.dispatch)(templateUtils.extractTemplateFromLayout(newTemplate));
  }

  updateRawTemplate(newTemplate: string): void {
    bindActionCreators(updateRawTemplate, this.store.dispatch)(templateUtils.extractTemplateFromLayout(newTemplate));
  }

  initOnce(opts: StripoInitOpts) {
    this.stripo.initOnce({
      ...opts,
      css: inlineStyles,
      template: templateUtils.mergeTemplate(baseLayout, opts.template),
    });
  }

  reInitializeEditor(opts: StripoInitOpts) {
    this.stripo.stop();
    this.stripo.reInitialize({
      ...opts,
      css: inlineStyles,
      template: templateUtils.mergeTemplate(baseLayout, opts.template),
    });
  }
}
