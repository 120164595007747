import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { createSearchSlice, SearchState } from "../../../../Application/slices/createSearchSlice";

export const initialState: SearchState = {
  term: undefined,
};

const eventSearchSlice = createSearchSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Events, name: "search" },
  initialState: initialState,
  reducers: {},
});

export const { setTerm } = eventSearchSlice.actions;

export const eventSearchReducer = eventSearchSlice.reducer;
