import { connect } from "react-redux";
import GenericItemsView, { GenericItemsViewProps } from "../../../../../../../../views/ItemsView/GenericItemsView";
import { AppDispatch, RootState } from "../../../../../../../Application/globaltypes/redux";
import { selectedItemsChanged } from "../../../../../state/slices/flowGroupsSlice";
import { FlowGroup } from "../../../../../types";
import { flowsStateSelector } from "../../../../../state/selectors";

const mapStateToProps = (state: RootState) => {
  const groups = flowsStateSelector(state).edit.groups;
  return {
    items: groups.items,
    isLoading: groups.isLoading,
    dataCount: groups.itemsCount,
    selectedIds: groups.selectedIds,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSelectedItemChanged: (ids: number[]) => dispatch(selectedItemsChanged(ids)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<FlowGroup>) => React.ReactElement);
