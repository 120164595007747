import { GenericFiltersMap } from "../../../../../../../../utils/filterUtils";
import { createFiltersSlice } from "../../../../../../../Application/slices/createFiltersSlice";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../../../enums/reducer";
import { FlowFiltersEnum } from "features/Library/Flows/types/models";

export const initialState = {
  filterOptions: {} as GenericFiltersMap<FlowFiltersEnum>,
  appliedFilter: {} as GenericFiltersMap<FlowFiltersEnum>,
  search: "",
  isLoading: false,
};

const nextFlowFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "nextFlowFilters",
  },
  initialState: initialState,
  reducers: {},
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  setSearch,
  resetSearch,
  resetFilter,
} = nextFlowFiltersSlice.actions;

export default nextFlowFiltersSlice.reducer;
