import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

interface ExportLinkButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  className?: string;
}

function ExportLinkButton(props: ExportLinkButtonProps) {
  const { onClick, isDisabled, className = "" } = props;

  return (
    <BasicLinkButton
      className={className}
      text="Export"
      iconClassName="fal fa-download"
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}

export default ExportLinkButton;
