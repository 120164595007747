import { useEffect } from "react";
import PropTypes from "prop-types";
import SelectedItemsLabel from "../selectedItemsLabel/SelectedItemsLabel";
import miscUtils from "../../../utils/miscellaneousUtils";
import { SendPageView } from "../../../utils/googleAnalytics";
import "./outstandingHeaderTitle.scss";

function OutstandingHeaderTitle(props) {
  const { title, titleForGA, itemName, selectedItemsAmount } = props;

  useEffect(() => {
    miscUtils.setPageTitle(title);

    let titleToSend = titleForGA ?? title;

    if (titleToSend) {
      SendPageView({
        title: titleToSend,
      });
    }
  }, [title, titleForGA]);

  let headerTitle;

  if (selectedItemsAmount) {
    headerTitle = <SelectedItemsLabel itemName={itemName} selectedItemsAmount={selectedItemsAmount} />;
  } else {
    headerTitle = <h2>{title}</h2>;
  }

  return <div className="title-container outstanding-header-title">{headerTitle}</div>;
}

OutstandingHeaderTitle.propTypes = {
  title: PropTypes.string,
  titleForGA: PropTypes.string,
  itemName: PropTypes.string,
  selectedItemsAmount: PropTypes.number,
};

export default OutstandingHeaderTitle;
