import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../../Application/globaltypes/redux";
import { TagsPayloadCamelPlural } from "../../../../../interfaces/Tags";
import { mapTagsToView } from "../../../Common/helpers/tagsHelper";

export const assessmentsStateSelector = (state: RootState) => state.library.assessments;

export const assessmentsAppliedFilterSelector = (state: RootState) =>
  assessmentsStateSelector(state).overview.filters.appliedFilter;

export const assessmentFilterOptionsSelector = (state: RootState) =>
  assessmentsStateSelector(state).overview.filters.filterOptions;

export const assessmentSearchSelector = (state: RootState) => assessmentsStateSelector(state).overview.filters.search;

export const assessmentsTagsSelector = createSelector(
  [(state: RootState): TagsPayloadCamelPlural => state.library.assessments.base.tagsBase.tags],
  mapTagsToView,
);
