import { NotifyStepSettings } from "../../interfaces";
import { CommunicationChannels, TemplateTypes } from "../../enums";

export interface NotifySettings {
  shouldNotifyUsers: boolean;
  communicationChannel: CommunicationChannels;
}

export interface ShouldNotifyWithProgress {
  shouldNotifyNotStarted: boolean;
  shouldNotifyInProgress: boolean;
  shouldNotifyCompleted: boolean;
  communicationChannel: CommunicationChannels;
}

export enum NotifyStepConfig {
  Default,
  WithProgress,
}

export enum ShouldNotifyWithProgressIds {
  ShouldNotifyNotStarted = 0,
  ShouldNotifyInProgress = 1,
  ShouldNotifyCompleted = 2,
  All = 3,
}

export type INotifiable<T> = T & {
  getShouldNotify: (config: T) => boolean;
  getCommunicationChannel: (config: T) => CommunicationChannels;
  notifyTypes?: (config: T) => number[];
};

export interface NotifyConfigEntry<T> {
  key: keyof T;
  value: any;
}

export interface NotifyStepConfigPayload<T> {
  setNotifyConfig: (shouldNotifyConfigEntry: NotifyConfigEntry<T>) => void;
  resetNotifyConfig: () => void;
  shouldNotify: boolean;
  communicationChannel: CommunicationChannels;
  notifyTypes?: number[];
}

export interface NotifyStepConfirmPayload {
  notificationSettings: NotifyStepSettings;
  notifyTypes?: number[];
}

export interface GetNotificationOptionsPayload {
  id?: string;
  subject: string;
  templateUri: string;
  rawTemplateUri: string;
  previewText: string;
  overrideNotification: boolean;
  suppressNotification: boolean;
  notificationType?: TemplateTypes;
  communicationChannel: CommunicationChannels;
  headLine: string;
  imageUrl: string;
  body: string;
  callToAction: string;
  destinationUrl: string;
}

export type SmtpSenderInfo = {
  smtpSettingsExist: boolean;
  sender: {
    id: number;
    senderName: string;
    senderEmail: string;
  };
};
