import { Divider } from "semantic-ui-react";
import FlowEndForm from "./flowEndForm/FlowEndForm";
import NextFlow from "./nextFlow/NextFlow";
import { FlowEndInfo } from "../../types";
import { BackButton } from "../BackButton/BackButton";
import styles from "./flowEndPanel.module.scss";

export interface FlowEndPanelProps {
  onBackButtonClick: () => void;
  flowEndInfo: FlowEndInfo;
  onFlowEndChange: (flowEnd: FlowEndInfo) => void;
  isReadOnly: boolean;
}

const FlowEndPanel = (props: FlowEndPanelProps) => {
  return (
    <div className={styles.root}>
      <BackButton title="End of Flow" onClick={props.onBackButtonClick} />
      <Divider />
      <div className={styles["info-content"]}>
        <span>This will trigger the end of flow state for end users.</span>
      </div>
      <FlowEndForm
        isReadOnly={props.isReadOnly}
        flowEndInfo={props.flowEndInfo}
        onFlowEndChange={props.onFlowEndChange}
      />
      <NextFlow
        isReadOnly={props.isReadOnly}
        flowEndInfo={props.flowEndInfo}
        onFlowEndChange={props.onFlowEndChange}
      />
    </div>
  );
};

export default FlowEndPanel;
