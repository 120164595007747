import { Icon } from "semantic-ui-react";
import { ValidatedForm } from "../../../../../../components";
import { Tooltip } from "../../../../../../components/common/tooltip";
import { nameofFactory } from "../../../../../../utils/nameof";
import InputField from "../../../../Common/Fields/InputField";
import { ISurveyQuestion, OtherAnswerOptions, SurveyQuestionOtherAnswerOptionsProps } from "./types";
import "./SurveyQuestionOtherAnswerOptions.scss";

export const SurveyQuestionOtherAnswerOptions = (props: SurveyQuestionOtherAnswerOptionsProps) => {
  const { onBlur, onChanged, otherAnswerOptions, propertyNamePrefix, validatedFieldProps, disabled } = props;
  const otherAnswerText = "Other";

  const questionNameOf = nameofFactory<ISurveyQuestion<any>>();
  const optionsNameOf = nameofFactory<OtherAnswerOptions>();

  return (
    <div className="additional-options">
      <div className="text-block">
        <InputField
          placeholder={otherAnswerText}
          value={otherAnswerText}
          errorPosition={"bottom"}
          onBlur={onBlur}
          disabled={true}
          maxLength={otherAnswerText.length}
          propertyName={otherAnswerText}
          error={""}
          touched={false}
          setFieldTouched={/* istanbul ignore next */ () => {}} //NOSONAR
          setFieldValue={/* istanbul ignore next */ () => {}} //NOSONAR
        />
      </div>
      <div className="checkbox-field">
        <ValidatedForm.CheckboxField
          {...validatedFieldProps}
          toggle
          label={"Include Text Field for User"}
          value={otherAnswerOptions.includeUserTextField}
          propertyName={`${propertyNamePrefix}${questionNameOf("otherAnswerOptions")}.${optionsNameOf(
            "includeUserTextField",
          )}`}
          handleChange={onChanged}
        />
        <Tooltip
          target={<Icon className="info circle" />}
          content={`Allows end-user to enter additional text when "Other" is selected as an answer`}
          position="top center"
          styles={{ width: "20rem" }}
          hideOnScroll
          showAlways
        />
      </div>
      <div className="checkbox-field">
        <ValidatedForm.CheckboxField
          {...validatedFieldProps}
          toggle
          label={"Require Text in Text Field"}
          value={otherAnswerOptions.isUserTextFieldRequired}
          propertyName={`${propertyNamePrefix}${questionNameOf("otherAnswerOptions")}.${optionsNameOf(
            "isUserTextFieldRequired",
          )}`}
          handleChange={onChanged}
          disabled={disabled || !otherAnswerOptions.includeUserTextField}
        />
        <Tooltip
          target={<Icon className="info circle" />}
          content={`End-user will be required to enter additional text before continuing the survey when "Other" is selected as an answer`}
          position="top center"
          styles={{ width: "20rem" }}
          hideOnScroll
          showAlways
        />
      </div>
    </div>
  );
};
