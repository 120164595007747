import { FC, useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { NoFilteredData } from "../../../../../components/charts";
import { ListViewBase } from "../../../../../components/listView";
import { ColumnOption } from "../../../../../interfaces/columnOptions";
import { PerformanceTabSectionList } from "../../../../Library/Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";
import { PackLicenses } from "../../../types/packs";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { TextTruncate } from "../../../../../components";
import utils from "../../../../../utils/miscellaneousUtils";

import "./SpPackReportDrilldownList.scss";
import { Tooltip } from "components/common/tooltip";
import { AccountTypes } from "features/Accounts/types";
import { ConnectedProps, connect } from "react-redux";
import { RootState } from "features/Application/globaltypes/redux";

export interface Props extends PropsFromRedux {
  reduxFinished: boolean;
  packLicensesData: PackLicenses[];
  setSelectedCustomer: ({ customerId, customerName }: { customerId: number; customerName: string; }) => void;
}

const isAllowedAccountTypes = [AccountTypes.BSI, AccountTypes.ChannelPartner, AccountTypes.MSP, AccountTypes.StrategicPartner];

export const SpPackReportDrilldownList: FC<Props> = ({
  reduxFinished,
  packLicensesData,
  setSelectedCustomer,
  accountType,
}) => {
  const [slicedEntities, setSlicedEntities] = useState<PackLicenses[]>([]);

  useEffect(() => {
    if (reduxFinished) {
      setSlicedEntities([...packLicensesData].slice(0, 10));
    }
  }, [packLicensesData, reduxFinished]);

  const columns: ColumnOption<PackLicenses>[] = [
    {
      name: "Customer Name",
      width: 7,
      render(item) {
        return isAllowedAccountTypes.includes(accountType) ? (
          <Tooltip
            target={
              <TextTruncate className="customerName">
                <a
                  href="./"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedCustomer({ customerId: item.CustomerId, customerName: item.CustomerName });
                  }}
                >
                  {item.CustomerName}
                </a>
              </TextTruncate>
            }
            content={`${item.CustomerName}`}
          />
        ) : (
          <Tooltip
            target={
              <TextTruncate>
                {item.CustomerName}
              </TextTruncate>
            }
            content={`${item.CustomerName}`}
          />
        );
      },
    },
    {
      name: "Account Type",
      width: 3,
      render(item) {
        return <Tooltip
          target={
            <TextTruncate>
              {item.AccountType}
            </TextTruncate>
          }
          content={`${item.AccountType}`}
        />;
      },
    },
    {
      name: "Status",
      width: 3,
      render(item) {
        return item.Status;
      },
    },
    {
      name: "Licenses",
      width: 3,
      render(item) {
        return utils.unlimitedNumber(item.TotalLicenses).toLocaleString();
      },
    },
    {
      name: "Enabled",
      width: 3,
      render(item) {
        return dateTimeUtils.formatEmptyDate(item.DateEnabled);
      },
    },
    {
      name: "Expires",
      width: 3,
      render(item) {
        return dateTimeUtils.formatEmptyDate(item.Expiration);
      },
    },
  ];

  const buildTableBody = (item: PackLicenses) => {
    return columns.map((column, index) => (
      <Table.Cell className="table-body-cell" width={column.width} key={`${column.name}_${index}`}>
        {column.render(item)}
      </Table.Cell>
    ));
  };

  const getData = (skip: number, top: number) => {
    setSlicedEntities([...packLicensesData.slice(skip, skip + top)]);
  };
  // NOTE: The company name should drilldown further if BSI
  return (
    <PerformanceTabSectionList
      title="Accounts"
      count={packLicensesData.length}
      renderList={(page) => {
        const parsedData = slicedEntities.map((entity) => ({
          id: entity.CustomerId,
          ...entity,
        }));
        return !!packLicensesData.length ? (
          <ListViewBase
            columnOptions={columns}
            loadPage={getData}
            items={parsedData}
            itemsAmount={packLicensesData.length}
            buildTableBody={buildTableBody}
            withFooter={false}
            activePage={page}
            withSelection={false}
          />
        ) : (
          <NoFilteredData />
        );
      }}
    />
  );
};


const mapStateToProps = (state: RootState) => ({
  accountType: state.userProfile.accountTypeId,
});

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SpPackReportDrilldownList);