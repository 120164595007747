import axios from "axios";
import {
  AddedUsers,
  CompletedUsers,
  StartedUsers,
  ActiveUsers,
  ConsumedLicenses,
  EmailActivity,
  EmailPerformance,
} from "../types/overview";
import { CustomerCards, CustomerPacks, OwnerPacksLicensing, PackLicenses } from "../types/packs";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";

export const addedUsersEndpoint = "/api/reports/flows/added-users";
export const completedUsersEndpoint = "/api/reports/flows/completed";
export const startedUsersEndpoint = "/api/reports/flows/started";
export const activeUsersEndpoint = "/api/reports/users/active";
export const consumedLicensesEndpoint = "/api/reports/packs/consumed-licenses";
export const emailActivityEndpoint = "/api/reports/communications/email-activity";
export const emailPerformanceEndpoint = "/api/reports/communications/email-performance";

export const packPerformanceEndpoint = "/api/reports/v1/packs/owner-packs-licensing";
export const packCustomersEndpoint = "/api/reports/v1/packs/pack-licenses";
export const customerPackEndpoint = "/api/reports/v1/packs/customer-packs";
export const customerCardsEndpoint = "/api/reports/v1/packs/customer-cards";

let ownerPacksLicensingAbortController = new AbortController();

export const reportingDataService = {
  getAddedUsers() {
    return axios.get<AddedUsers[]>(addedUsersEndpoint);
  },
  getCompletedUsers() {
    return axios.get<CompletedUsers[]>(completedUsersEndpoint);
  },
  getStartedUsers() {
    return axios.get<StartedUsers[]>(startedUsersEndpoint);
  },
  getActiveUsers() {
    return axios.get<ActiveUsers[]>(activeUsersEndpoint);
  },
  getConsumedLicenses() {
    return axios.get<ConsumedLicenses[]>(consumedLicensesEndpoint);
  },
  getEmailActivity() {
    return axios.get<EmailActivity[]>(emailActivityEndpoint);
  },
  getEmailPerformance() {
    return axios.get<EmailPerformance>(emailPerformanceEndpoint);
  },
  // For BSI / Partners
  getOwnerPacksLicensing(customerId: number, filter?: PerformanceFilter) {
    ownerPacksLicensingAbortController.abort();
    ownerPacksLicensingAbortController = new AbortController();
    return axios.get<OwnerPacksLicensing[]>(packPerformanceEndpoint, {
      params: { licenseOwnerId: customerId, ...filter },
      signal: ownerPacksLicensingAbortController.signal,
    });
  },
  // For drilldown
  getPackLicenses(packId: number, customerId?: number) {
    return axios.get<PackLicenses[]>(packCustomersEndpoint, { params: { packId, customerId } });
  },
  // For normal customers
  getCustomerPacks(customerId: number) {
    return axios.get<CustomerPacks[]>(customerPackEndpoint, { params: { customerId } });
  },
  getCustomerCards(customerId: number) {
    return axios.get<CustomerCards>(customerCardsEndpoint, { params: { customerId } });
  },
};
