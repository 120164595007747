import dataService from "../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/packsOverviewActionTypes";
import {
  getActionBaseProvider,
  getActionProvider,
  makeActionCreator,
} from "../../../../Application/actions/actionsBuilder";
import { GetPacksRequest } from "../../types/requests";
import { Dispatcher, FetchActionPayload } from "../../../../../interfaces/redux";
import { PackOverview } from "../../types/state";
import { Filters } from "../../../../../utils/queryUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const resetPacksGrid = makeActionCreator(actionTypes.RESET_PACKS);

export const getPacksAsync = (skip: number, take: number, orderBy: string, filters?: Filters, search?: string) => {
  const begin = getActionBaseProvider(actionTypes.FETCH_PACKS_BEGIN);
  const success = getActionProvider<FetchActionPayload<PackOverview>>(actionTypes.FETCH_PACKS_SUCCESS);
  const failure = getActionProvider<Error>(actionTypes.FETCH_PACKS_FAILURE);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    const request: GetPacksRequest = {
      top: take,
      skip,
      orderBy,
      searchTerm: search,
    };

    try {
      const result = await dataService.packs.getPacksAsync(request, filters || {});
      const recordsCount = Number.parseInt(result.headers[countHeaderName]);

      const data: any = {
        items: result.data,
        itemsCount: recordsCount,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
