import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { noop } from "lodash";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";

import { EnumDictionary, bindAction, ISegment } from "../../../../../interfaces";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { RolePermissions, PublishedStatusTypes, RouteNames } from "../../../../../enums";
import { useWizardStepsManager } from "../../../../../hooks/useWizardStepsManager";
import { useRtn } from "../../../../../hooks/useRtn";
import {
  MessageRevertSuccess,
  MessageMadeDraftSuccess,
  MessagePublishSuccess
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import Segments from "../../../../../components/navigation/segments/Segments";
import Restricted from "../../../../Application/Restricted";
import { EditMessageSteps } from "../types/constants";
import { DetailsHeader, RestrictedByTooltip } from "../../../../../components";
import {
  fetchDraftMessageEntity,
  publishDraftMessageEntity,
  resetMessageEntityState,
  revertMessageEntity,
} from "../state/actions/messageEntityStateActions";
import MessageDetailsSubheaderContainer from "./MessageDetailsSubheaderContainer";
import MessageInfoForm from "../Info/MessageInfoForm";
import MessageContentForm from "../Content/MessageContentForm";
import { getMessage as getMessageDetailsThunk } from "../state/thunks/messageDetailsThunk";
import { reset } from "../state/slices/messageDetailsSlice";
import { MessageModalsContainer } from "../common/MessageModalsContainer";
import { ModalHandlers } from "../types/models";
import { MessagePerformance } from "./MessagePerformance";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import AssociatedPacks from "features/Licensing/Packs/AssociatedPacks/AssociatedPacks";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { AddPacksButton } from "features/Licensing/Packs/AssociatedPacks/AddPacksButton";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

const messagesOverviewUrl = `/${RouteNames.contentCommunications}/messages`;

export const EditMessage = (props: PropsFromRedux) => {
  const {
    messageInfo,
    isDraft,
    isPurchased,
    hasBeenPublished,
    entityState,
    isLoaded,
    isLoading,
    getMessage,
    fetchDraftMessage,
    publishMessage,
    discardMessage,
    resetEntityState,
    resetMessage,
    userId,
    userPermissions,
  } = props;
  const showPacksTab = useFeatureFlag(FeatureFlags.AssociatedPacks);
  const [packsModalShown, setPacksModalShown] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const messageId = Number(params.id);
  const pageIndexes = [EditMessageSteps.Performance, EditMessageSteps.Configuration, EditMessageSteps.Content, EditMessageSteps.AssociatedPacks];
  const [, { goToPage }, subscribeOnActiveIndexChanged] = useWizardStepsManager(pageIndexes);
  const modalHandlers = useRef<ModalHandlers>({ revert: () => noop });

  const pages: EnumDictionary<EditMessageSteps, ISegment> = useMemo(() => {
    return {
      [EditMessageSteps.Performance]: {
        to: "",
        label: "Performance",
        onClick: () => {
          goToPage(EditMessageSteps.Performance);
        },
      },
      [EditMessageSteps.Configuration]: {
        to: "configuration",
        label: "Configuration",
        onClick: () => {
          goToPage(EditMessageSteps.Configuration);
        },
      },
      [EditMessageSteps.Content]: {
        to: "content",
        label: "Content",
        onClick: () => {
          goToPage(EditMessageSteps.Content);
        },
      },
      [EditMessageSteps.AssociatedPacks]: {
        to: "packs",
        label: "Associated Packs",
        onClick: () => {
          goToPage(EditMessageSteps.AssociatedPacks);
        },
      },
    };
  }, [goToPage]);

  const [isInfoValid, setIsInfoValid] = useState(true);
  const [isContentValid, setIsContentValid] = useState(true);

  useEffect(() => {
    if (!isLoaded) {
      getMessage(messageId);
    }
    return () => {
      resetMessage();
      resetEntityState();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useLayoutEffect(() => {
    const handler = (_: any, activeStepIndex: EditMessageSteps) => {
      pages[activeStepIndex].init?.();
    };
    return subscribeOnActiveIndexChanged(handler);
  }, [pages, subscribeOnActiveIndexChanged]);

  const reloadMessage = () => {
    getMessage(messageId);
  };

  useRtn([MessagePublishSuccess, MessageRevertSuccess, MessageMadeDraftSuccess], reloadMessage);

  const handleClose = () => {
    navigate(`/${RouteNames.contentCommunications}`);
  };

  const entityStateActions = useMemo(
    () => ({
      onPublish: () => {
        publishMessage(messageId);
      },
      onRevert: () => {
        modalHandlers.current.revert();
      },
      onClose: handleClose,
      onEdit: () => {
        fetchDraftMessage(messageId);
      },
    }),
    [messageId],
  );

  const handleRevert = () => {
    discardMessage(messageId);
  };

  const memoizedNavigateToCreate = useCallback(() => {
    navigate("send-sessions/create");
  }, [navigate]);

  const renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton disabled={isDraft || isPurchased} hasPermission={hasAnyPermission} onClick={() => setPacksModalShown(true)} />
  );

  const renderAddSendSessionButton = (isDisabled: boolean) => {
    return (
      <RestrictedByTooltip hasPermission={!isDisabled}>
        <Button primary fluid className="control create-send" disabled={isDisabled} onClick={memoizedNavigateToCreate}>
          <span className="title">{"Send Messages"}</span>
        </Button>
      </RestrictedByTooltip>
    );
  };

  const isPacksTab = window.location.pathname.endsWith("packs");
  const isPublishedAndNotPurchased = !isDraft && !isPurchased;

  return (
    <Restricted
      permissions={[RolePermissions.CommunicationsCreate]}
      renderContent={(hasPermission: boolean) => (
        <div className="nested-content edit-message scrollable-content">
          <DetailsHeader
            title={messageInfo.title}
            titleForGA="Message Details"
            backButton={`/${RouteNames.contentCommunications}`}
            defaultURL={`/${RouteNames.contentCommunications}`}
            canBeEdited={isPublishedAndNotPurchased}
            publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!isDraft)}
            invalidFormDetails={!isInfoValid || !isContentValid}
            isRevertVisible={hasBeenPublished}
            canBePublished={!entityState.isEntityCommandInProgress && isInfoValid && isContentValid}
            crudPermission={hasPermission}
            entityStateActions={entityStateActions}
          >
            {window.location.pathname.endsWith(`${messagesOverviewUrl}/${messageId}`) &&
              renderAddSendSessionButton(isDraft || !userPermissions.includes(RolePermissions.CommunicationsManage))}
            {isPacksTab && (
              <Restricted permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]} permissionPredicate={permissionPredicateForPacks} renderContent={(hasPermission) => renderAddPackButton(hasPermission)} />
            )}
            <AddAssetsToPackModal
              showModal={packsModalShown}
              onClose={() => setPacksModalShown(false)}
              selectedItemIds={[messageId]}
              contentType="Message"
              onComplete={noop}
            />
          </DetailsHeader>
          <MessageModalsContainer
            revertModal={{
              acceptHandler: (handler) => (modalHandlers.current.revert = handler),
              enabled: hasBeenPublished,
              userId,
              onConfirm: handleRevert,
            }}
          />
          <Dimmer active={isLoading} inverted>
            <Loader active={isLoading} />
          </Dimmer>
          <MessageDetailsSubheaderContainer
            publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!isDraft)}
            isUpdateInProgress={entityState.isEntityCommandInProgress && isDraft}
            lastModifiedDateTime={entityState.lastModifiedDateTime?.toString()}
          >
            <Segments to={`${messagesOverviewUrl}/${messageId}`}>
              <Segments.Segment {...pages[EditMessageSteps.Performance]} />
              <Segments.Segment {...pages[EditMessageSteps.Configuration]} />
              <Segments.Segment {...pages[EditMessageSteps.Content]} />
              {showPacksTab && <Segments.Segment {...pages[EditMessageSteps.AssociatedPacks]} />}
            </Segments>
          </MessageDetailsSubheaderContainer>
          <div className="scrollable-content">
            <Routes>
              <Route
                path={pages[EditMessageSteps.Performance].to}
                element={<MessagePerformance messageId={messageId} isDraft={isDraft} />}
              />
              <Route
                path={pages[EditMessageSteps.Configuration].to}
                element={
                  <div className="edit-form">
                    <MessageInfoForm disabled={!hasPermission || !isDraft} onIsValidChange={setIsInfoValid} />
                  </div>
                }
              />
              <Route
                path={pages[EditMessageSteps.Content].to}
                element={
                  <div className="edit-form">
                    <MessageContentForm disabled={!hasPermission || !isDraft} onIsValidChange={setIsContentValid} />
                  </div>
                }
              />
              {showPacksTab && (
                <Route
                  path={pages[EditMessageSteps.AssociatedPacks].to}
                  element={
                    <AssociatedPacks
                      contentType={"Message"}
                      contentId={messageId}
                      canAddPacks={isPublishedAndNotPurchased}
                      permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
                      permissionPredicate={permissionPredicateForPacks}
                    />}
                />)}
              <Route path="*" element={<Navigate to="../" replace />} />
            </Routes>
          </div>
        </div>
      )}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    entityState: state.library.messages.details.messageEntityStateReducer,
    messageInfo: state.library.messages.details.values.message,
    messageContent: state.library.messages.details.values.messageContent,
    isLoading: state.library.messages.details.values.isLoading,
    isLoaded: state.library.messages.details.values.isLoaded,
    isDraft: state.library.messages.details.values.isDraft,
    isPurchased: state.library.messages.details.values.isPurchased,
    hasBeenPublished: state.library.messages.details.values.hasBeenPublished,
    userId: Number(state.userProfile.id),
    userPermissions: state.userProfile.permissions,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchDraftMessage: bindAction(fetchDraftMessageEntity, dispatch),
  publishMessage: bindAction(publishDraftMessageEntity, dispatch),
  discardMessage: bindAction(revertMessageEntity, dispatch),
  resetEntityState: bindAction(resetMessageEntityState, dispatch),
  getMessage: bindAction(getMessageDetailsThunk, dispatch),
  resetMessage: bindAction(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditMessage);
