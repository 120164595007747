import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FlowChanges } from "../../types/flowLicensing";

export interface FlowLicensingState {
  flowChanges: FlowChanges;
  isLoading: boolean;
  error: Error | null;
}

export const initialState: FlowLicensingState = {
  flowChanges: { isDefinitionChanged: false },
  isLoading: false,
  error: null,
};

const flowLicensingSlice = createSlice({
  name: "flowLicensing",
  initialState: initialState,
  reducers: {
    startFetchLicensingInfo: (state: FlowLicensingState) => {
      state.isLoading = true;
    },
    fetchLicensingInfoSuccess: (state: FlowLicensingState, action: PayloadAction<FlowChanges>) => {
      state.flowChanges = action.payload;
      state.isLoading = false;
    },
    fetchLicensingInfoFailure: (state: FlowLicensingState, action: PayloadAction<Error>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetLicensingInfo: () => {
      return initialState;
    },
  },
});

export const { startFetchLicensingInfo, fetchLicensingInfoSuccess, fetchLicensingInfoFailure, resetLicensingInfo } =
  flowLicensingSlice.actions;

export default flowLicensingSlice.reducer;
