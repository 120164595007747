import { Table } from "semantic-ui-react";
import { ColumnOptions } from "../../../../../interfaces";
import { IdentityProvider, IdentityProviderType } from "../../../types";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import IdentityProviderTitle from "./IdentityProviderTitle/identityProviderTitle";
import IdentityProviderEllipsisPopupButton from "./identityProviderEllipsisPopupButton/identityProviderEllipsisPopupButton";

import "./identityProviderRow.scss";

export type IdentityProviderRowProps = IdentityProvider & IdentityProviderRowOwnProps;

export const identityProviderColumnOptions: ColumnOptions[] = [
  {
    name: "ID Provider",
    width: 8,
    isSortable: true,
    className: "identity-provider-name-header",
  },
  {
    name: "Type",
    width: 4,
    isSortable: true,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

const IdentityProviderRow: React.FC<IdentityProviderRowProps> = ({
  id,
  name,
  type,
  addedDate,
  identityId,
  hasManagePermission,
  isSelected,
  onEdit,
  onDelete,
  onNavigate,
}) => {
  return (
    <>
      <Table.Cell width={identityProviderColumnOptions[0].width} collapsing>
        <IdentityProviderTitle name={name} identityId={identityId} onNavigate={() => onNavigate(id)} />
      </Table.Cell>
      <Table.Cell width={identityProviderColumnOptions[1].width}>{IdentityProviderType[type]}</Table.Cell>
      <Table.Cell width={identityProviderColumnOptions[2].width}>{dateTimeUtils.formatDate(addedDate)}</Table.Cell>
      <Table.Cell className="align-right" width={identityProviderColumnOptions[3].width}>
        <IdentityProviderEllipsisPopupButton
          onEdit={() => onEdit(id)}
          onDelete={() => onDelete(id)}
          isDisabled={isSelected || !hasManagePermission}
        />
      </Table.Cell>
    </>
  );
};

export interface IdentityProviderRowOwnProps {
  hasManagePermission: boolean;
  isSelected: boolean;
  onEdit: (identityProviderId: number) => void;
  onDelete: (identityProviderId: number) => void;
  onNavigate: (identityProviderId: number) => void;
}

export default IdentityProviderRow;
