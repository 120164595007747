import { AsyncThunk, createSlice, Draft, SliceCaseReducers } from "@reduxjs/toolkit";
import { FetchActionPayload } from "../../../interfaces/redux";
import { LazyFetchingItemsState } from "./createLazyFetchingItemsSlice";
import { CreateAsyncSlicePayload, getPrefix } from "./models";

export interface CancelingLazyFetchingItemsState<T> extends LazyFetchingItemsState<T> {
  currentRequestId?: string;
}

export const createCancelingLazyFetchingItemsSlice = <
  T,
  TArgs,
  TState extends CancelingLazyFetchingItemsState<T>,
  Reducers extends SliceCaseReducers<TState>,
>({
  namePayload,
  initialState,
  reducers,
  thunk,
}: CreateAsyncSlicePayload<TState, Reducers, AsyncThunk<Draft<FetchActionPayload<T>>, TArgs, {}>>) => {
  return createSlice({
    name: getPrefix(namePayload),
    initialState,
    reducers: {
      reset() {
        return initialState;
      },
      ...reducers,
    },
    extraReducers: (builder) => {
      builder
        .addCase(thunk.pending, (state, action) => {
          state.isLoading = true;
          state.currentRequestId = action.meta.requestId;
        })
        .addCase(thunk.fulfilled, (state, action) => {
          const { requestId } = action.meta;
          if (state.isLoading && state.currentRequestId === requestId) {
            state.isLoading = false;
            state.items = state.items.concat(action.payload.items);
            state.itemsCount = action.payload.totalCount;
            state.areAllLoaded = state.items.length >= state.itemsCount;
            state.currentRequestId = undefined;
          }
        })
        .addCase(thunk.rejected, (state, action) => {
          const { requestId } = action.meta;
          if (state.isLoading && state.currentRequestId === requestId) {
            state.isLoading = false;
            state.areAllLoaded = false;
            state.error = action.error as Error;
            state.currentRequestId = undefined;
          }
        });
    },
  });
};
