import { useMemo } from "react";
import { useSelector } from "react-redux";
import intersection from "lodash/intersection";

import { RootState } from "../../../Application/globaltypes/redux";
import { RolePermissions } from "../../../../enums";

export function useHasPurchasingPower() {
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);

  return useMemo(() => {
    return intersection(userPermissions, [RolePermissions.PacksPurchase]).length > 0;
  }, [userPermissions]);
}
