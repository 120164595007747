import { bindActionCreators } from "redux";
import { Store } from "@reduxjs/toolkit";

import { NotifyStepSettings } from "../../interfaces";
import { IEmailEditorController } from "./EmailEditor/emailEditorController";
import { RootState } from "../../features/Application/globaltypes/redux";
import { templateService } from "../../features/SystemNotifications/services/templateService";
import { templateUtils } from "./utils/templateUtils";
import { resetState } from "../../features/SystemNotifications/state/slices/notifyStepSlice";
import { StripoInitOpts } from "../../lib/stripo/stripoInitOpts";
import { CommunicationChannels } from "../../enums";

export interface INotifyByEmailController {
  saveChanges(): Promise<{ template: string }>;
  getSettings(
    shouldNotify: boolean,
    communicationChannel: CommunicationChannels,
    isDisabled?: boolean,
  ): Promise<NotifyStepSettings>;
  resetState(): void;
  getTemplate(): Promise<string>;
  reInitializeEmailEditor?(opts: StripoInitOpts): void;
}

export class NotifyByEmailController implements INotifyByEmailController {
  constructor(
    private emailEditor: IEmailEditorController,
    private store: Store,
  ) {}

  private async compileTemplateString(): Promise<string> {
    const template = await this.emailEditor.compileEmail();
    return templateUtils.extractTemplateFromLayout(template);
  }

  private async getRawTemplateString(): Promise<string> {
    const rawTemplate = await this.emailEditor.getTemplate();
    return templateUtils.extractTemplateFromLayout(rawTemplate);
  }

  async saveChanges() {
    const template = await this.getRawTemplateString();
    this.emailEditor.updateTemplate(template);
    this.emailEditor.updateRawTemplate(template);
    return { template };
  }

  async getSettings(shouldNotify: boolean, communicationChannel: CommunicationChannels, isDisabled?: boolean) {
    const rootState = this.store.getState() as RootState;
    const isNotifyEnabled = !isDisabled && shouldNotify;
    const { form, templateSasUrl, rawTemplateSasUrl, templateType, teamsForm } =
      rootState.systemNotifications.notifyStep;

    const newTemplate = await this.compileTemplateString();
    const rawTemplate = await this.getRawTemplateString();
    await templateService.saveTemplate(templateSasUrl, newTemplate);
    await templateService.saveTemplate(rawTemplateSasUrl, rawTemplate);

    return {
      subject: form.subject,
      previewText: form.previewText,
      senderName: form.customizeSender ? form.senderName : "",
      senderEmail: form.customizeSender ? form.senderEmail : "",
      templateUri: templateSasUrl,
      rawTemplateUri: rawTemplateSasUrl,
      suppressNotification: !isNotifyEnabled,
      overrideNotification: isNotifyEnabled,
      notificationType: templateType,
      communicationChannel: communicationChannel,
      headline: teamsForm.headline,
      body: teamsForm.body,
      imageUrl: teamsForm.imageUrl,
      callToAction: teamsForm.callToAction,
      destinationUrl: teamsForm.destinationUrl,
    };
  }

  async getTemplate(): Promise<string> {
    return this.emailEditor.getTemplate();
  }

  reInitializeEmailEditor(opts: StripoInitOpts): void {
    this.emailEditor.reInitializeEditor(opts);
  }

  resetState(): void {
    bindActionCreators(resetState, this.store.dispatch)();
  }
}
