import React from "react";
import { Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Tooltip } from "../common/tooltip";

import "./labelWithInfoTooltip.scss";

function LabelWithInfoTooltip(props) {
  const { label, labelClass, info, width, styles, position, hoverable } = props;
  return (
    <div className="label-with-info-tooltip">
      <label className={labelClass}>{label}</label>
      <Tooltip
        tooltipClassName="priority-label-info-tooltip"
        target={<Icon className="info circle" />}
        content={info}
        position={position}
        styles={{ width: `${width}rem`, ...styles }}
        hoverable={hoverable}
        hideOnScroll
        showAlways
      />
    </div>
  );
}

LabelWithInfoTooltip.defaultProps = {
  width: 13,
  labelClass: "",
  position: "top center",
};

LabelWithInfoTooltip.propTypes = {
  label: PropTypes.string,
  hoverable: PropTypes.bool,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  width: PropTypes.number,
  styles: PropTypes.object,
  labelClass: PropTypes.string,
  position: PropTypes.oneOf([
    "top left",
    "top center",
    "top right",
    "left center",
    "right center",
    "bottom left",
    "bottom center",
    "bottom right",
  ]),
};

export default LabelWithInfoTooltip;
