import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { ValidatedForm } from "../../../../../components/forms";
import LabelWithInfoTooltip from "../../../../../components/labelWithInfoTooltip/labelWithInfoTooltip";
import { nameofFactory } from "../../../../../utils/nameof";
import InputField from "../../../Common/Fields/InputField";
import { WithFormikPayload } from "../../../Common/Fields/WithFormikPayload";
import { END_NOTE_TEXT_DEFAULT } from "../../types/constants";
import { SurveyView } from "../../types/state";
import { SurveyFormProps } from "../types";
import "./SurveyForm.scss";

export type SurveyFormPropsAll = FormikProps<SurveyView> & SurveyFormProps;

const SurveyForm = (props: SurveyFormPropsAll) => {
  const {
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    dirty,
    resetForm,
    mode,
    values: surveyValues,
    onBlur,
  } = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const descriptionHasInitialValue = useMemo(() => !isEmpty(surveyValues.description), []);

  const getValidateFieldProps = (): any => {
    return {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };

  const nameof = nameofFactory<SurveyView>();

  const onTitleBlur = (propertyName: string) => {
    const descriptionField = nameof("description");
    if (mode === "create" && !descriptionHasInitialValue && !touched[descriptionField]) {
      setFieldValue(descriptionField, surveyValues.title, true);
    }

    onBlur(propertyName);
  };

  const renderFields = (): any => {
    const { disabled, save } = props;
    const validatedFieldProps = getValidateFieldProps();

    return (
      <div className="survey-form-container">
        <ValidatedForm.InputField
          label="Title"
          tooltip={{
            info: "This field is for organizational purposes and is surfaced to end users.",
            width: 17,
          }}
          placeholder={"Enter Survey Title"}
          value={surveyValues.title}
          propertyName="title"
          onBlur={onTitleBlur}
          markAsRequired
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.TextAreaField
          tooltip={{
            info: "This field is for organizational purposes and is surfaced to end users.",
            width: 17,
          }}
          label="Description"
          placeholder={"Enter Description"}
          value={surveyValues.description}
          propertyName="description"
          hidden={true}
          onBlur={onBlur}
          disabled={disabled}
          markAsRequired
          {...validatedFieldProps}
        />
        <div className="check-block">
          <ValidatedForm.CheckboxField
            {...validatedFieldProps}
            label="End Note Text"
            value={surveyValues.endNoteTextEnabled}
            propertyName={nameof("endNoteTextEnabled")}
            handleChange={save}
            disabled={disabled}
          />
          <LabelWithInfoTooltip
            width={18}
            info="This is an optional field to surface to end users to introduce a survey closing experience"
          />
        </div>
        <WithFormikPayload
          propertyName={nameof("endNoteText")}
          {...validatedFieldProps}
          selfValidation={false}
          forceShowError={true}
          render={(formikProps) => (
            <InputField
              placeholder={END_NOTE_TEXT_DEFAULT}
              value={surveyValues.endNoteText}
              onBlur={onBlur}
              disabled={disabled || !surveyValues.endNoteTextEnabled}
              setFieldTouched={validatedFieldProps.setFieldTouched}
              setFieldValue={validatedFieldProps.setFieldValue}
              {...formikProps}
            />
          )}
        />
      </div>
    );
  };

  const { isLoading, disablePreventTransitionPrompt } = props;
  return (
    <ValidatedForm
      dirty={props.dirty}
      unsavedChangesPrompt={{
        title: "Exit Without Saving?",
        message: "Are you sure you want to exit without saving this survey? All information entered will be lost.",
      }}
      disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      isLoading={isLoading}
      formWidthComputer={6}
      parentWithFormik={true}
    >
      {renderFields}
    </ValidatedForm>
  );
};

export default SurveyForm;
