import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { Reminder } from "../../types/models";
import {
  createLazyFetchingItemsSlice,
  LazyFetchingItemsState,
} from "../../../../Application/slices/createLazyFetchingItemsSlice";
import { PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";

export interface RemindersState extends LazyFetchingItemsState<Reminder> {
  selectedItems: Reminder[];
}

export const initialState: RemindersState = {
  items: [],
  isLoading: false,
  itemsCount: 0,
  error: undefined,
  areAllLoaded: false,
  selectedItems: [],
};

const remindersSlice = createLazyFetchingItemsSlice<Reminder, RemindersState, SliceCaseReducers<RemindersState>>({
  namePayload: { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Events, name: "reminders" },
  initialState: initialState,
  reducers: {
    selectedItemsChanged: (state: RemindersState, action: PayloadAction<Reminder[]>) => {
      state.selectedItems = action.payload;
    },
    resetSelectedItems: (state: RemindersState) => {
      state.selectedItems = [];
    },
  },
});

export const {
  fetchBegin,
  fetchSuccess,
  fetchFailure,
  reset,
  refetchSuccess,
  selectedItemsChanged,
  resetSelectedItems,
} = remindersSlice.actions;

export type EventRemindersState = ReturnType<typeof remindersSlice.reducer>;

export const remindersReducer = remindersSlice.reducer;
