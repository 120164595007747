import Strings from "../../../../../../enums/strings";
import { Icon, Popup, Table } from "semantic-ui-react";
import { Tooltip } from "../../../../../common/tooltip";
import PermissionsList from "../../../../../../features/People/RoleList/Permissions/PermissionsList";
import RoleIcon from "../../../../../../features/People/RoleList/RoleRow/RoleIcon/RoleIcon";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { pluralize } from "../../../../../../utils/stringUtils";
import { UserRolesAssignment } from "../../../RolesAssignmentModal";
import { ColumnOptions, Role } from "../../../../../../interfaces";
import { TextTruncate } from "../../../../../textTruncators/TextTruncators";
import "./addToRoleRow.scss";

const formatUsersCountLabel = (usersCount: number | null) => {
  if (usersCount === null) {
    return Strings.notAvailable;
  }

  return usersCount;
};

export interface AddToRoleRowProps {
  role: Role;
  userRolesAssignments: UserRolesAssignment[];
  issuingUsersCount: number;
  columnOptions: ColumnOptions[];
}

const AddToRoleRow = ({ role, userRolesAssignments, issuingUsersCount, columnOptions }: AddToRoleRowProps) => {
  const readOnlyClass = classNames({
    rowDisabled: role.isAssigned,
  });

  const getTooltip = () => {
    const usersAssignedToRole = userRolesAssignments.filter((x) => x.roleIds.some((roleId) => roleId === role.id));

    if (isEmpty(usersAssignedToRole)) {
      return null;
    }

    const tooltipMessage =
      issuingUsersCount === usersAssignedToRole.length
        ? `This ${pluralize("user", issuingUsersCount)} already assigned to this role.`
        : `(${usersAssignedToRole.length}) of the users selected already assigned to this role and will be skipped.`;

    return (
      <Popup
        hideOnScroll
        position="top center"
        size="small"
        className="popup-warning warning"
        content={tooltipMessage}
        trigger={<Icon link className="fa fa-exclamation-circle" size="huge" />}
      />
    );
  };

  return (
    <>
      <Table.Cell className={`role-icons ${readOnlyClass}`} width={columnOptions[0].width}>
        <RoleIcon isDefaultRole={role.isDefault} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width} className={readOnlyClass}>
        <Tooltip content={role.name} target={<TextTruncate className="name">{role.name}</TextTruncate>} />
        <Tooltip
          content={role.description}
          target={<TextTruncate className="text-muted">{role.description}</TextTruncate>}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width} className={readOnlyClass}>
        {formatUsersCountLabel(role.usersCount)}
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width} className={readOnlyClass}>
        <PermissionsList areas={role.areas} />
      </Table.Cell>
      <Table.Cell width={columnOptions[4].width} className="cell-warning">
        {getTooltip()}
      </Table.Cell>
    </>
  );
};

export default AddToRoleRow;
