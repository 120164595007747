import React from "react";
import { ReactComponent as NoAssetResult } from "../../../../images/assets-icon.svg";
import { NoResultsWithButton } from "../../../../components";

export interface SurveysNoResultsProps {
  description?: string;
  createSurveyButton?: React.ReactElement;
  filtered?: boolean;
}

const SurveysNoResults: React.FC<SurveysNoResultsProps> = ({
  createSurveyButton,
  filtered,
  description = "Looks like you don't have any survey assets yet.",
}) => {
  return (
    <NoResultsWithButton
      title="You don't have any survey assets"
      description={description}
      icon={<NoAssetResult />}
      actionButton={createSurveyButton}
      filtered={filtered}
    />
  );
};

export default SurveysNoResults;
