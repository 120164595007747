import { open } from "./consentWindowService";
import integrationsDataService from "../../services/integrationsDataService";
import IConnector from "./IConnector";
import { GoogleIntegrationConfigs, IntegrationTypes } from "../types";

export const login = async (importUsageData: boolean, syncUsers: boolean) => {
  const redirect = `${window.location.origin}/consent`;
  const { redirectLocation } = await integrationsDataService.authorizeGoogleIntegration(
    syncUsers,
    importUsageData,
    redirect,
  );

  return open({
    location: redirectLocation,
    title: "Google Sign In",
    parseResult: (params) => {
      const code = params.get("code") as string;
      return {
        isSuccess: !!code,
        error: params.get("error"),
        result: code,
        redirect: redirect,
      };
    },
  });
};

export class GoogleConnector implements IConnector<GoogleIntegrationConfigs> {
  private _authenticationCode: string = "";
  private _redirect: string | undefined = undefined;
  private readonly _isConnected: boolean;

  constructor(isConnected: boolean) {
    this._isConnected = isConnected;
  }

  public get info() {
    return {
      id: IntegrationTypes.Google,
      connectorTitle: "Google",
      type: IntegrationTypes.Google,
      description: "Google",
      thumbnailUrl: "/connectors/google.png",
      isConnected: this._isConnected,
    };
  }

  public get configuration() {
    return {
      isActive: false,
      syncUsers: true,
      importUsageData: true,
      userNotificationId: "",
      isAuthorized: false,
    };
  }

  readonly shouldLogin = () => true;

  readonly shouldNotify = () => true;

  readonly login = async (config: GoogleIntegrationConfigs) => {
    const { syncUsers, importUsageData } = config;
    const authResult = await login(importUsageData, syncUsers);
    this._authenticationCode = authResult.result;
    this._redirect = authResult.redirect;
    return authResult;
  };

  readonly save = async (config: GoogleIntegrationConfigs, moboId?: string) => {
    await integrationsDataService.createGoogleIntegration(
      {
        ...config,
        redirect: this._redirect,
        authenticationCode: this._authenticationCode,
      },
      moboId,
    );
  };
}
