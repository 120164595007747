import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as groupsAssignmentActionTypes from "../../../../Application/actions/groupsAssignment/groupsAssignmentActionTypes";

export const FLOW_ASSIGN_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  groupsAssignmentActionTypes.ASSIGN_GROUPS_PERMISSIONS,
);

export const FLOW_RESET_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Flows,
  groupsAssignmentActionTypes.RESET_GROUPS_PERMISSIONS,
);

export const ASSET_ASSIGN_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  groupsAssignmentActionTypes.ASSIGN_GROUPS_PERMISSIONS,
);

export const ASSET_RESET_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Videos,
  groupsAssignmentActionTypes.RESET_GROUPS_PERMISSIONS,
);

export const EMAIL_ASSIGN_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Emails,
  groupsAssignmentActionTypes.ASSIGN_GROUPS_PERMISSIONS,
);

export const EMAIL_RESET_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Emails,
  groupsAssignmentActionTypes.RESET_GROUPS_PERMISSIONS,
);

export const MESSAGE_ASSIGN_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Messages,
  groupsAssignmentActionTypes.ASSIGN_GROUPS_PERMISSIONS,
);

export const MESSAGE_RESET_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Messages,
  groupsAssignmentActionTypes.RESET_GROUPS_PERMISSIONS,
);

export const EVENT_ASSIGN_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  groupsAssignmentActionTypes.ASSIGN_GROUPS_PERMISSIONS,
);

export const EVENT_RESET_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  groupsAssignmentActionTypes.RESET_GROUPS_PERMISSIONS,
);

export const ASSESSMENT_ASSIGN_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  groupsAssignmentActionTypes.ASSIGN_GROUPS_PERMISSIONS,
);

export const ASSESSMENT_RESET_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Assessments,
  groupsAssignmentActionTypes.RESET_GROUPS_PERMISSIONS,
);

export const SURVEY_ASSIGN_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Surveys,
  groupsAssignmentActionTypes.ASSIGN_GROUPS_PERMISSIONS,
);

export const SURVEY_RESET_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Surveys,
  groupsAssignmentActionTypes.RESET_GROUPS_PERMISSIONS,
);

export const PDF_ASSIGN_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Pdfs,
  groupsAssignmentActionTypes.ASSIGN_GROUPS_PERMISSIONS,
);

export const PDF_RESET_GROUPS_PERMISSIONS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Pdfs,
  groupsAssignmentActionTypes.RESET_GROUPS_PERMISSIONS,
);
