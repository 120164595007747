import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../../../features/Application/globaltypes/redux";
import { reset } from "../../../../features/Licensing/Packs/state/slices/userAvailablePacksGridSlice";
import { fetchUserAvailablePacks } from "../../../../features/Licensing/Packs/state/thunks/userAvailablePacksGridThunk";
import { bindAction } from "../../../../interfaces/redux";
import EditPeoplePacksIssueLicenseModal from "../EditPeoplePacksIssueLicenseModal";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    packsGrid: state.packs.peopleIssueLicenseModal.userAvailablePacks,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    loadPacks: bindActionCreators(fetchUserAvailablePacks, dispatch),
    resetGridItems: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(EditPeoplePacksIssueLicenseModal);
