import { Button } from "components/buttons/button/Button";
import RestrictedByAddOn from "../../../../../components/restrictedByAddOn/RestrictedByAddOn";
import { RolePermissions } from "../../../../../enums";
import { PaywallModalTypes } from "../../../../../components/restrictedByAddOn/paywallModal/types";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { useNavigate } from "react-router-dom";

export const CreateSurveyButton = () => {
  const navigate = useNavigate();
  const onCreateSurveyBtnClick = () => navigate("create");

  const createSurveyButton = (action: () => void, hasAnyPermission: boolean): React.ReactElement => (
    <RestrictedByTooltip hasPermission={hasAnyPermission}>
      <Button primary className="create-survey create-button" onClick={action} disabled={!hasAnyPermission}>
        Create Survey
      </Button>
    </RestrictedByTooltip>
  );

  return (
    <RestrictedByAddOn
      permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate]}
      addOnPermissions={[RolePermissions.AssetsCreate]}
      modalType={PaywallModalTypes.UpgradeToCreateContent}
      action={onCreateSurveyBtnClick}
      renderContent={createSurveyButton}
    />
  );
};

export default CreateSurveyButton;
