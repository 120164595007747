import { SemanticWIDTHS, Table } from "semantic-ui-react";

import { ExpireLabel } from "./ExpireLabel/ExpireLabel";

export interface Props {
  date: string;
  isExpired?: boolean;
  width?: SemanticWIDTHS;
  className?: string;
}

export const ExpirationCell: React.FC<Props> = ({ isExpired = true, width = 4, date, className }) => {
  return (
    <Table.Cell width={width} className={className}>
      {isExpired ? <ExpireLabel date={date} /> : date}
    </Table.Cell>
  );
};
