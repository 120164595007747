import { useEffect } from "react";
import cn from "classnames";

import LeftArrowButton from "../buttons/arrowButtons/LeftArrowButton";
import miscUtils from "../../utils/miscellaneousUtils";
import { SendPageView } from "../../utils/googleAnalytics";
import LabelWithInfoTooltip from "../labelWithInfoTooltip/labelWithInfoTooltip";
import ObjectUtils from "../../utils/objectUtils";

import "./backTitle.scss";

export interface BackTitleProps {
  title: string;
  onClick: () => void;
  className: string;
  renderBackButton?: boolean;
  titleForGA?: string;
  pageTitle?: string;
  small?: boolean;
  tooltip?: {
    width: number;
    info: string;
  };
}

function BackTitle(props: BackTitleProps) {
  const { title, pageTitle, titleForGA, renderBackButton, onClick, className, small, tooltip } = props;

  useEffect(() => {
    miscUtils.setPageTitle(pageTitle ?? title);

    let titleToSend = titleForGA ?? title;

    if (titleToSend) {
      SendPageView({
        title: titleToSend,
      });
    }
  }, [title, pageTitle, titleForGA]);

  return (
    <div className={cn("title-container", "back-button-title", className)}>
      {renderBackButton && <LeftArrowButton onClick={onClick} size="large" />}
      {small ? <h4>{title}</h4> : <h2>{title}</h2>}
      {ObjectUtils.isNotEmpty(tooltip) && <LabelWithInfoTooltip width={tooltip.width} info={tooltip.info} />}
    </div>
  );
}

BackTitle.defaultProps = {
  renderBackButton: true,
  small: false,
};

export default BackTitle;
