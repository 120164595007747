import { Reducer, Action } from "redux";

export function createReducer<StateType, ActionType extends Action = Action>(
  initialState: StateType,
  handlers: Array<() => any>,
): Reducer<StateType, ActionType> {
  const handler = Object.assign({}, ...handlers.map((item) => item()));
  return function reducer(currentState: StateType | undefined, action: { type: string }) {
    const state = currentState || initialState;

    if (handler.hasOwnProperty(action.type)) {
      return handler[action.type](state, action);
    } else {
      return state;
    }
  };
}

export function mergeReducers<StateType extends object = {}, ActionType extends Action = Action>(
  parentReducer: (state: StateType, action: ActionType) => StateType,
  nextReducer: (state: StateType, action: ActionType) => StateType,
): (state: StateType, action: ActionType) => StateType {
  return (state: StateType, action: ActionType) => {
    const reducerExecutionResult = parentReducer(state, action);
    return nextReducer(reducerExecutionResult, action);
  };
}
