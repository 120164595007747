import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/packGroupsAvailableForLicensingSlice";
import { SortingDirection } from "../../../../../enums";
import groupsDataService from "../../../../People/services/groupsDataService";
import groupListUtils from "../../../../../utils/groupListUtils";
import nameof from "../../../../../utils/nameof";
import { GroupInfo } from "../../../../../interfaces";
import { isEmpty } from "lodash";

export const fetchGroupsAvailableForLicensing =
  (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      sortingColumnName = !isEmpty(sortingColumnName) ? sortingColumnName : (nameof<GroupInfo>("name") as string);
      const orderBy = groupListUtils.formatOrderParams(sortingColumnName, sortingDirection);

      const res = await groupsDataService.getGroups({ skip, top, orderBy });

      dispatch(
        fetchSuccess({
          items: res.items,
          totalCount: res.count,
        }),
      );
    } catch (e: any) {
      dispatch(fetchFailure(e));
    }
  };
