import React from "react";
import { Icon } from "semantic-ui-react";

import styles from "./bottomContent.module.scss";

export interface IBottomContentOwnProps {
  cardLabel: string;
  metaLabel?: string;
  icon?: string;
}
const BottomContent: React.FC<IBottomContentOwnProps> = ({ cardLabel, metaLabel, icon }) => (
  <div className={styles.root}>
    <div className={styles.labels}>
      {icon && <Icon className={icon} size="small" />}
      <div data-testid="card-label" className={styles["labels-card"]}>
        {cardLabel.toUpperCase()}
      </div>
      {metaLabel && <div className={styles["labels-meta"]}>{metaLabel}</div>}
    </div>
  </div>
);

export default BottomContent;
