import { ValidatedForm } from "../../../../../../components";
import { FC, useMemo } from "react";
import cn from "classnames";
import styles from "./packsDropdown.module.scss";

interface Option {
  id: number | string;
  name: string;
}

export interface Props {
  options: Option[];
  value?: number | string;
  withTruncation?: boolean;
  onChange(packId: number | string): void;
}

export const MAX_NAME_LENGTH = 52;

export const PacksDropdown: FC<Props> = ({ options, value, onChange, withTruncation = true }) => {
  const optionList = useMemo(() => {
    return options.map(({ id, name }) => ({
      value: id,
      text: withTruncation && name.length > MAX_NAME_LENGTH ? name.slice(0, MAX_NAME_LENGTH - 1).trim() + "…" : name,
    }));
  }, [options, withTruncation]);

  return (
    <div className="pack-context-selection" data-testid="context-items-row-packs">
      <ValidatedForm.DropdownField
        className={cn(styles["packs-dropdown"], { [styles["trunc-label"]]: !withTruncation })}
        label="Issue License From"
        propertyName="packId"
        placeholder="Select pack"
        markAsRequired
        setFieldTouched={() => {}} //NOSONAR
        setFieldValue={(_: any, id: number | string) => onChange(id)}
        value={value}
        options={optionList}
        autoFocus
        errorPosition="bottom"
      />
    </div>
  );
};
