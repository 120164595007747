import { get } from "lodash";

export function isPropertyTouched(touched: object, propertyName: string) {
  let value = get(touched, propertyName);
  // To work around the issue with multivalue field validation:
  // https://github.com/jaredpalmer/formik/issues/793
  if (value instanceof Array && value.length === 0) {
    return true;
  }
  // Otherwise should work fine
  return !!value;
}
