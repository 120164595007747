import { SessionView, SessionsView } from "../../../../types/state";
import React from "react";
import { Icon } from "semantic-ui-react";
import "./SingleSessionBuilder.scss";
import { ArrayHelpers, FormikProps } from "formik";
import SingleSessionBuilderRow from "./SingleSessionBuilderRow";
import { getDefaultSession } from "../../Utils/utils";

export interface SingleSessionBuilderProps extends CommonSessionBuilderProps {
  sessions: SessionView[];
}

export interface CommonSessionBuilderProps {
  validatedFieldProps: FormikProps<SessionsView>;
  arrayHelpers: ArrayHelpers;
  disabled?: boolean;
  onBlur: (propertyName: string) => void;
  save: () => void;
}

export const SingleSessionBuilder = (props: SingleSessionBuilderProps) => {
  const { sessions, validatedFieldProps, arrayHelpers, disabled, onBlur, save } = props;

  const onAddSession = () => {
    arrayHelpers.push(getDefaultSession(false));
    setTimeout(() => {
      save?.();
    }, 0);
  };

  const onRemoveSession = (index: number) => {
    arrayHelpers.remove(index);
    setTimeout(() => {
      save?.();
    }, 0);
  };

  return (
    <div className="single-session-builder">
      {sessions.map((session: SessionView, index: number) => (
        <SingleSessionBuilderRow
          key={index}
          session={session}
          arrayHelpers={arrayHelpers}
          validatedFieldProps={validatedFieldProps}
          disabled={disabled}
          index={index}
          hideRemove={sessions.length < 2}
          onRemove={onRemoveSession}
          onBlur={onBlur}
          save={save}
        />
      ))}
      {!disabled && (
        <div className="control-panel-add" onClick={onAddSession}>
          <Icon className="plus circle icon" size="big" color="blue" />
          <span>Add event session</span>
        </div>
      )}
    </div>
  );
};
