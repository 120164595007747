import TaskStatuses from "../../enums/taskStatuses";
import TaskActions from "../../enums/TaskActions";
import backgroundTasksEventsEmitter from "./events/backgroundTasksEventsEmitter";
import { PdfTask } from "./taskPool";

const tasks = {
  getFailed: (id: number, onClose: (id: number) => void): PdfTask => {
    backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.close, id, onClose);

    return {
      id,
      status: TaskStatuses.failed,
      percent: 100,
      indeterminate: false,
      canClose: true,
      isPdfUploading: false,
      isPdfUploaded: false,
    } as PdfTask;
  },

  getCanceled: (id: number, onClose: (id: number) => void): PdfTask => {
    backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.close, id, onClose);

    return {
      id,
      status: TaskStatuses.canceled,
      percent: 100,
      indeterminate: false,
      canClose: true,
      isPdfUploading: false,
      isPdfUploaded: false,
    } as PdfTask;
  },

  getUploadedSuccessfuly: (id: number): PdfTask =>
    ({
      id,
      status: TaskStatuses.successful,
      label: "Uploaded successfully!",
      percent: 100,
      indeterminate: false,
      isPdfUploading: false,
      isPdfUploaded: true,
      canCancel: false,
    } as PdfTask),

  getStarted: (id: string | number, title: string, onCancel?: () => void): PdfTask =>
    ({
      id,
      status: TaskStatuses.inProgress,
      title,
      label: "Uploading...",
      isPdfUploading: true,
      canCancel: !!onCancel,
      onCancel,
    } as PdfTask),
};

export default tasks;
