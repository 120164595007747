import React from "react";

import { FlowItemAssessment } from "../../../../features/Library/Flows/Designer/types";
import { pluralize } from "../../../../utils/stringUtils";

import TopContent from "../BaseCard/TopContent";
import BottomContent from "../BaseCard/BottomContent";

export interface IAssessmentCardOwnProps {
  item: FlowItemAssessment;
}

const AssessmentCard: React.FC<IAssessmentCardOwnProps> = ({ item }) => {
  const metaLabel = item.questionsCount
    ? `${item.questionsCount} ${pluralize("Question", parseInt(item.questionsCount))}`
    : "";
  return (
    <>
      <TopContent {...item} />
      <BottomContent cardLabel={item.elementType} metaLabel={metaLabel} />
    </>
  );
};

export default AssessmentCard;
