import { IMapping } from "./types";
import { Dispatcher, MidnightActionPayload } from "../../../../../interfaces";
import * as events from "../events/library/libraryEvents";
import {
  updateEventCommandSuccess,
  publishDraftEventSuccess,
  eventUpdated,
  fetchDiscardEventSuccess,
  fetchLockedEventSuccess,
} from "../../../../Library/Events/state/actions/eventEntityStateActions";
import { createDispatcher } from "../dispatcher";
import {
  updateFailedTaskDistributedOperation,
  updateSucceededTaskDistributedOperation,
  updateProgressTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";

export const eventsMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const {
    dispatch,
    dispatchMap,
    dispatchPublishSuccess,
    dispatchBatchOperationSuccess,
    dispatchBatchOperationFailure,
  } = createDispatcher(dispatcher);

  mapping[events.EventPublishSuccess] = dispatchPublishSuccess<{ id: number; title: string }>(
    publishDraftEventSuccess,
    (data) => `${data.title} was published.`,
  );

  mapping[events.EventDiscardedSuccess] = dispatch(fetchDiscardEventSuccess);
  mapping[events.EventInfoEditSuccess] = dispatch(eventUpdated);
  mapping[events.EventEditSessionsSuccess] = dispatch(eventUpdated);
  mapping[events.EventUpdateCommandCompleted] = dispatch(updateEventCommandSuccess);

  mapping[events.DuplicateExternalEventDistributedSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
  mapping[events.DuplicateAllExternalEventSuccess] = (payload) =>
    dispatcher(updateSucceededTaskDistributedOperation(payload.operationId));
  mapping[events.DuplicateExternalEventDistributedFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));
  mapping[events.DuplicateAllExternalEventFailure] = (payload) =>
    dispatcher(updateFailedTaskDistributedOperation(payload.operationId));

  mapping[events.ExternalEventEditModeSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedEventSuccess,
    (message) => ({ entityId: message.id }),
  );

  mapping[events.EventDeleteSuccess] = dispatchBatchOperationSuccess;
  mapping[events.EventDeleteFailure] = dispatchBatchOperationFailure;

  mapping[events.ExternalEventAddTagsSuccess] = dispatchBatchOperationSuccess;
  mapping[events.ExternalEventAddTagsFailure] = dispatchBatchOperationFailure;

  mapping[events.ExternalEventReminderDeletionSuccess] = dispatchBatchOperationSuccess;
  mapping[events.ExternalEventReminderDeletionFailure] = dispatchBatchOperationFailure;
};
