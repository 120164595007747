import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import { getRootEntityActionTypeBuilder } from "../../../../../Application/actions/actionsBuilder";
import * as fetchingItemsActionTypes from "../../../../../Application/actions/fetchingItems/fetchingItemsActionTypes";

const actionTypeBuilder = getRootEntityActionTypeBuilder(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Emails,
);

export const emailPublishInfoFetchingBegin = actionTypeBuilder(fetchingItemsActionTypes.FETCH_ITEMS_BEGIN);

export const emailPublishInfoFetchingSuccess = actionTypeBuilder(fetchingItemsActionTypes.FETCH_ITEMS_SUCCESS);

export const emailPublishInfoFetchingFailure = actionTypeBuilder(fetchingItemsActionTypes.FETCH_ITEMS_FAILURE);

export const setEmailPublishInfoLoading = "SET_PUBLISH_INFO_LOADING";
export const setEmailPublishInfo = "SET_EMAIL_PUBLISH_INFO";
