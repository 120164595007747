import { PeriodType } from "../../types/state";

export const sessionTypes = [
  { text: "Recurring Event", value: true },
  { text: "Single Event", value: false },
];

export const periodTypes = [PeriodType.Day, PeriodType.Week, PeriodType.Month, PeriodType.Year];

export const periodValueOptions = [
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
];
