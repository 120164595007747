import cn from "classnames";
import { partial } from "lodash";
import Title from "../title/Title";

import "./titleSubtitle.scss";

export interface TitleSubtitleProps {
  id?: string | number;
  title?: string;
  subtitleTitle?: string;
  subtitle?: string;
  subtitleClamp?: number;
  className?: string;
  classNameTitle?: string;
  label?: React.ReactNode;
  url?: string;
  onNavigate?(id?: string | number): void;
}

const TitleSubtitle: React.FC<TitleSubtitleProps> = ({
  id,
  title,
  subtitleTitle,
  subtitle,
  subtitleClamp,
  onNavigate,
  url,
  className,
  classNameTitle,
  label,
}) => {
  const handleTitleClick = onNavigate ? partial(onNavigate, id) : undefined;

  let titleBlock;

  if (label) {
    titleBlock = (
      <div className="labeled">
        <Title title={title} onTitleClick={handleTitleClick} className="title" />
        {label}
      </div>
    );
  } else {
    titleBlock = (
      <Title url={url} title={title} className={cn("title", classNameTitle)} onTitleClick={handleTitleClick} />
    );
  }

  return (
    <div className={cn("title-subtitle-container", className)}>
      {titleBlock}
      <Title title={subtitleTitle} className="subtitle text-muted" />
      <Title title={subtitle} className="subtitle text-muted" clamp={subtitleClamp} />
    </div>
  );
};

export default TitleSubtitle;
