import { fetchOverviewFlows } from "../thunks/flowOverviewThunk";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { FlowOverview } from "../../types/models";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

export type OverviewState = FetchingItemsState<FlowOverview>;

export const initialState: OverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const flowsOverviewSlice = createFetchingItemsSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Flows, entity: ReducerEntityPrefixTypes.Flows, name: "overviewGrid" },
  initialState: initialState,
  reducers: {},
  thunk: fetchOverviewFlows,
});

export const { reset } = flowsOverviewSlice.actions;

export default flowsOverviewSlice.reducer;
