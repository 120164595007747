import cn from "classnames";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Icon, Popup, Table } from "semantic-ui-react";
import { SearchInput } from "../../../../../../../components";
import AccountPicture from "../../../../../../../components/accountPicture/AccountPicture";
import AccountsFilterForm from "../../../../../../../components/filterForms/AccountsFilterForm/AccountsFilterForm";
import { ListViewWithHeaderAndPanel } from "../../../../../../../components/listView";
import { Title } from "../../../../../../../components/listViewTemplates";
import { ModalWithStepsStep } from "../../../../../../../components/modal/ModalWithSteps";
import { SortingDirection } from "../../../../../../../enums";
import PackVisibilityForAccountsNoResults from "../../../../../../../views/packs/PackVisibility/PackVisibilityForAccountsNoResults";

import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";
import { accountFilterSlice } from "../../../../state/slices/accountFilterSlice";
import { getFilterOptions } from "../../../../state/thunks/accountFilterThunk";
import { AccountVisibility, columnOptions } from "../types";
import { FiltersMap } from "../../../../../../../utils/filterUtils";
import { Filters } from "../../../../../../../utils/queryUtils";

const defaultSortColumn = "added";
export interface PackVisibilityForAccountsStepProps extends ModalWithStepsStep {
  loadPage: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    filter: FiltersMap,
  ) => void;
  accounts: AccountVisibility[];
  accountsCount: number;
  isLoading: boolean;
  filter?: object;
  selectedIds: number[];
  applyFilter?: (filter: Filters) => void;
  resetFilter?: () => void;
  onSelectedListItemsChanged?: (ids: number[]) => void;
  id: number;
}

export type PackVisibilityForAccountsStepPropsAll = PropsFromRedux & PackVisibilityForAccountsStepProps;

export const PackVisibilityForAccountsStep: React.FC<PackVisibilityForAccountsStepPropsAll> = ({
  selectedIds,
  onSelectedListItemsChanged,
  loadPage,
  filterOptions,
  appliedFilter,
  applyFilter,
  resetFilter,
  getAccountsFilterOptions,
  accounts,
  accountsCount,
  isLoading,
  filter,
}) => {
  const reloadListItemsRef = useRef<() => void>();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    reloadListItemsRef.current && reloadListItemsRef.current();
  }, [searchTerm]);

  const onSetReloadListItems = (callback: () => void) => {
    reloadListItemsRef.current = callback;
  };

  useEffect(() => {
    getAccountsFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTooltip = (accountPackVisibility: AccountVisibility) => {
    if (!accountPackVisibility.isPackVisible) {
      return null;
    }

    const tooltipMessage = "This account already associated with pack.";

    return (
      <Popup
        hideOnScroll
        position="top center"
        size="small"
        className="popup-warning warning"
        content={tooltipMessage}
        trigger={<Icon link className="fa fa-exclamation-circle" size="huge" />}
      />
    );
  };

  const buildTableBody = (accountPackVisibility: AccountVisibility) => {
    const readOnlyClass = cn({
      rowDisabled: accountPackVisibility.isPackVisible,
    });
    return (
      <React.Fragment>
        <Table.Cell width={columnOptions[0].width} className={readOnlyClass}>
          <div className="account-info">
            <AccountPicture className="account-info-picture" imageUrl={accountPackVisibility.logoUri} />
            <Title title={accountPackVisibility.name} clamp={2} />
          </div>
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width} className={readOnlyClass}>
          <Title title={accountPackVisibility.accountTypeName} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width} className={readOnlyClass}>
          {accountPackVisibility.userCount}
        </Table.Cell>
        <Table.Cell width={columnOptions[3].width} className={readOnlyClass}>
          {getTooltip(accountPackVisibility)}
        </Table.Cell>
      </React.Fragment>
    );
  };

  const onSearchChange = (searchText: string) => {
    setSearchTerm(searchText);
  };

  // @ts-ignore
  const getFilterForm = () => <AccountsFilterForm />;

  return (
    <div className="assignment-list-step">
      <ListViewWithHeaderAndPanel
        columnOptions={columnOptions}
        loadPage={loadPage}
        itemsAmount={accountsCount}
        isLoading={isLoading}
        items={accounts}
        onSelectedListItemsChanged={onSelectedListItemsChanged}
        buildTableBody={buildTableBody}
        filter={filter}
        selectedItemIds={selectedIds}
        noResultsContent={<PackVisibilityForAccountsNoResults filtered={!isEmpty(appliedFilter)} />}
        appliedFilter={appliedFilter}
        getFilterOptions={getAccountsFilterOptions}
        filterOptions={filterOptions}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        renderFilterForm={getFilterForm}
        sortingColumnName={defaultSortColumn}
        sortingDirection={SortingDirection.Ascending}
        isSelectDisabled={(u: AccountVisibility) => u.isPackVisible}
        renderSearch={() => <SearchInput placeholder="Search for Accounts..." onChange={onSearchChange} disabled />}
        setReloadListItems={onSetReloadListItems}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    filterOptions: state.packs.accountsFilters.filterOptions,
    appliedFilter: state.packs.accountsFilters.appliedFilter,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getAccountsFilterOptions: () => dispatch(getFilterOptions(accountFilterSlice)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PackVisibilityForAccountsStep);
