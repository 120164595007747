import * as actionTypes from "./sidebarActtionTypes";

const initialState = {
  collapsed: true,
  isCollapsedByUser: true,
};

export default function sidebarReducer(currentState, action) {
  const state = currentState || initialState;

  switch (action.type) {
    case actionTypes.AUTO_COLLAPSE_SIDEBAR:
      return Object.assign({
        ...state,
        collapsed: true,
      });

    case actionTypes.COLLAPSE_SIDEBAR_BY_USER:
      return Object.assign({
        ...state,
        collapsed: true,
        isCollapsedByUser: true,
      });

    case actionTypes.EXPAND_SIDEBAR:
      return Object.assign({
        ...state,
        collapsed: false,
        isCollapsedByUser: false,
      });

    default:
      return state;
  }
}
