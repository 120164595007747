import dataService from "../../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/threatDefenceCampaignsOverviewActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import { DeleteThreatDefenceCampaignRequest, GetThreatDefenceCampaignsRequest } from "../../types/requests";
import { Dispatcher, FetchActionPayload } from "../../../../../../interfaces/redux";
import { DeleteThreatDefenceCampaignOverviewResult, ThreatDefenceCampaignOverview } from "../../types/state";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME;

export const getThreatDefenceCampaignsAsync = (take: number, skip: number, orderBy: string) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceCampaignsBegin);
  const success = getActionProvider<FetchActionPayload<ThreatDefenceCampaignOverview>>(
    actionTypes.getThreatDefenceCampaignsSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceCampaignsFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    const request: GetThreatDefenceCampaignsRequest = {
      top: take,
      skip,
      orderBy,
    };
    try {
      const result = await dataService.threatDefence.getThreatDefenceCampaignsAsync(request);
      const recordsCount = Number.parseInt(result.headers[countHeaderName || ""]);

      const data: FetchActionPayload<ThreatDefenceCampaignOverview> = {
        items: result.data,
        totalCount: recordsCount,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const deleteThreatDefenceCampaignsAsync = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.deleteThreatDefenceCampaignsBegin);
  const success = getActionProvider<DeleteThreatDefenceCampaignOverviewResult>(
    actionTypes.deleteThreatDefenceCampaignsSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.deleteThreatDefenceCampaignsFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    const request: DeleteThreatDefenceCampaignRequest = {
      id,
    };
    try {
      const result = await dataService.threatDefence.deleteThreatDefenceEmailTemplate(request);

      const data = {
        isSuccess: result.data,
        id: id,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
