import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";

import PublishValidationInfo from "../../../../../components/publishValidationInfo/PublishValidationInfo";
import { toggleErrorMessagesBar as toggleErrorMessagesBarActionCreator } from "../../Designer/validator/flowValidatorActionCreators";
import { flowValidatorSelector } from "../../state/selectors";

export interface Props {
  onErrorClick: () => void;
}

export function HeaderValidationInfo(props: PropsFromRedux & Props) {
  const { isErrorViewMode, onErrorClick, areErrorsResolved, toggleErrorMessagesBar, showErrorMessagesBar } = props;

  return (
    <PublishValidationInfo
      unableToPublish={!showErrorMessagesBar && isErrorViewMode}
      onErrorButtonClick={() => {
        onErrorClick();
        toggleErrorMessagesBar(true);
      }}
      readyToPublish={areErrorsResolved}
    />
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const validator = flowValidatorSelector(state);

  return {
    isErrorViewMode: validator.isErrorViewMode,
    areErrorsResolved: validator.areErrorsResolved,
    showErrorMessagesBar: validator.showErrorMessagesBar,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    toggleErrorMessagesBar: (visible: boolean) =>
      dispatch(toggleErrorMessagesBarActionCreator({ showErrorMessagesBar: visible })),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HeaderValidationInfo);
