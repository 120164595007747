import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import {
  createLazyFetchingItemsSlice,
  LazyFetchingItemsState,
} from "../../../Application/slices/createLazyFetchingItemsSlice";
import { AvailableItemBase } from "../models";

export type ContentToPeopleAssignmentsState = LazyFetchingItemsState<AvailableItemBase>;

export const initialState: ContentToPeopleAssignmentsState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const slice = createLazyFetchingItemsSlice<AvailableItemBase, ContentToPeopleAssignmentsState>({
  namePayload: {
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.AddContent,
    name: "contentToPeopleAssignments",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = slice.actions;

export default slice.reducer;

export type ContentAssignmentState = ReturnType<typeof slice.reducer>;
