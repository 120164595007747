import { FC, SVGProps } from "react";

export interface PaywallModalProps {
  onCancel: () => void;
  show: boolean;
  modalType: PaywallModalTypes;
}

export interface PaywallModalOption {
  icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  actionText: string;
}

export enum PaywallModalTypes {
  UpgradeToCreateContent,
  RenewCreateContent,
}

export type PaywallModalOptions = {
  [key in PaywallModalTypes]: PaywallModalOption;
};
