import React from "react";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Table } from "semantic-ui-react";
import { Title } from "../../../../../../components/listViewTemplates";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import { ThreatDefenceCampaignsRunsOverviewProps } from "../types";
import ThreatDefenceCampaignRunsNoResults from "../../../../../../views/library/campaignRuns/Overview/ThreatDefenceCampaignRunsNoResults";
import { AppDispatch, RootState } from "../../../../../Application/globaltypes/redux";
import * as campaignDetailsActions from "../../state/actions/threatDefenceCampaignDetailsActions";
import columnOptions from "./columnOptions";
import threatDefenceFetchDataUtils from "../../../../../../utils/threatDefenceFetchDataUtils";
import { ThreatDefenceCampaignRun } from "../../types/state";
import ViewType from "../../../../../../enums/ViewType";
import { ItemsView } from "../../../../../../views";
import { SortingDirection } from "../../../../../../enums/sortingDirection";

const defaultSortColumn = "id";

export type CampaignRunsProps = ThreatDefenceCampaignsRunsOverviewProps & PropsFromRedux;

export const CampaignRuns: React.FC<CampaignRunsProps> = (props: CampaignRunsProps) => {
  const getCampaignRuns = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
  ) => {
    const orderParams = threatDefenceFetchDataUtils.formatOrderParams(
      sortingColumnName || defaultSortColumn,
      sortingDirection || SortingDirection.Descending,
    );
    props.campaignDetailsActions.getCampaignRuns(props.campaignId, top ?? 10, skip ?? 0, orderParams);
  };

  const buildTableBody = (run: ThreatDefenceCampaignRun) => {
    return (
      <React.Fragment>
        <Table.Cell width={columnOptions[0].width}>
          <Title title={run.id.toString()} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <Title title={run.statusName} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>{dateTimeUtils.formatDate(run.startDate)}</Table.Cell>
      </React.Fragment>
    );
  };

  const { campaignRuns } = props;
  return (
    <section className="scrollable-content">
      <ItemsView
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        getData={getCampaignRuns}
        itemsInlineCount={campaignRuns.totalCount}
        isLoading={campaignRuns.isLoading}
        items={campaignRuns.runs}
        buildTableBody={buildTableBody}
        noResultsContent={<ThreatDefenceCampaignRunsNoResults />}
        sortingColumnName={defaultSortColumn}
        sortingDirection={SortingDirection.Descending}
        withSelection={false}
      />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  campaignRuns: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.runsOverview,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  campaignDetailsActions: bindActionCreators(campaignDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CampaignRuns);
