import { SyntheticEvent } from "react";

import { useHasEnablePackPermissions } from "../../../features/Licensing/Packs/hooks/useHasEnablePackPermissions";
import { MultiStateButton } from "../../buttons/multiStateButton/MultiStateButton";
import { PackStatusLabelWithIcon } from "../packStatusLabelWithIcon/PackStatusLabelWithIcon";

import "./packStatusActionLabel.scss";

export interface PackStatusActionLabelProps {
  isTrialAllowed: boolean;
  isOwn: boolean;
  isExpired: boolean;
  isPurchased: boolean;
  isRequested: boolean;
  isTrial?: boolean;
  onRequestClick: (e: SyntheticEvent) => void;
  onTrialClick: (e: SyntheticEvent) => void;
}

export const PackStatusActionLabel: React.FC<PackStatusActionLabelProps> = (props: PackStatusActionLabelProps) => {
  const { isTrialAllowed, isOwn, isExpired, isPurchased, isRequested, isTrial, onTrialClick, onRequestClick } = props;
  const hasEnablePackPermissions = useHasEnablePackPermissions();
  const requestOrEnablePackLabel = hasEnablePackPermissions ? "Purchase Pack" : "Request Pack";
  const isTrialButtonVisible = () => {
    return isTrialAllowed && !isOwn && !isExpired && !isPurchased && !isRequested && !isTrial;
  };

  const isRequestOnly = () => {
    return !isOwn && !isRequested && !isPurchased && !isTrial;
  };

  const getMenuItems = () => {
    return [
      {
        text: requestOrEnablePackLabel,
        onClick: onRequestClick,
        divider: false,
      },
      {
        text: "Start Pack Trial",
        onClick: onTrialClick,
        divider: false,
      },
    ];
  };

  const renderContent = () => {
    if (isTrialButtonVisible())
      return (
        <MultiStateButton
          className="pack-status-action-label-container trial-unavailable-action-label"
          id="request-pack"
          data-testid="request-pack-buttons"
          trigger={hasEnablePackPermissions ? "Purchase" : "Request"}
          simple
          items={getMenuItems()}
          disabled={!isRequestOnly()}
          upward={false}
        />
      );
    if (isRequestOnly()) {
      return (
        <div
          id="request-pack-button"
          className="pack-status-action-label-container trial-unavailable-action-label"
          data-testid="open-pack-request-modal-button"
          onClick={onRequestClick}
        >
          {requestOrEnablePackLabel}
        </div>
      );
    }
    return (
      <PackStatusLabelWithIcon
        isPurchased={isPurchased}
        isRequested={isRequested}
        isExpired={isExpired}
        isTrial={isTrial}
        isOwn={isOwn}
      />
    );
  };

  return renderContent();
};
