import { AxiosResponse } from "axios";
import { AlertTypes, SortingDirection } from "../../../../../enums";
import { AppDispatch, AppThunk } from "../../../../Application/globaltypes/redux";
import DataService from "../../../../Application/services/dataServices/typedDataService";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { REMINDERS } from "../../types/constants";
import { Reminder, ReminderBase } from "../../types/models";
import { GetReminders } from "../../types/requests";
import { eventUpdated } from "../actions/eventEntityStateActions";
import { fetchBegin, fetchFailure, fetchSuccess, refetchSuccess } from "../slices/remindersSlice";
import { pluralize } from "utils/stringUtils";
import backgroundTask, { UpdateFeatureProps } from "features/BackgroundTasks/backgroundTask";
import { AddOperationParamsV1, UpdateParams } from "interfaces";
import { addOperationV1 } from "features/BackgroundTasks/state/backgroundTasksActions";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

//will be tested after merge with SAAS-14721
/* istanbul ignore next */
export const saveReminder =
  (reminder: ReminderBase, eventId: number): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    try {
      await DataService.events.saveReminder({ reminder: reminder, eventId });
      dispatch(
        sendTransientNotification({
          type: AlertTypes.success,
          message: "Reminder has been added!",
        }),
      );

      await dispatch(refetchReminders(eventId));
      dispatch(eventUpdated({ id: eventId }));
    } catch (error) {
      dispatch(
        sendTransientNotification({
          type: AlertTypes.error,
          message: "Reminder wasn’t created!",
        }),
      );
    }
  };

const fetchRemindersBase =
  (request: GetReminders, success: (res: AxiosResponse<Reminder[]>) => void) => async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const res = await DataService.events.getReminders({
        ...request,
        sortBy: REMINDERS.SORT_BY,
        sortOrder: SortingDirection.Descending,
      });

      success(res);
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };

export const fetchReminders = (request: GetReminders) => async (dispatch: AppDispatch) => {
  await dispatch(
    fetchRemindersBase(request, (res) => {
      dispatch(fetchSuccess({ items: res.data, totalCount: Number(res.headers[countHeaderName]) }));
    }),
  );
};

export const refetchReminders = (eventId: number) => async (dispatch: AppDispatch) => {
  await dispatch(
    fetchRemindersBase({ eventId: eventId, top: REMINDERS.TOP, skip: 0 }, (res) => {
      dispatch(refetchSuccess({ items: res.data, totalCount: Number(res.headers[countHeaderName]) }));
    }),
  );
};

export const deleteReminders =
  (eventId: number, reminderIds: number[]): AppThunk<Promise<void>> =>
  async (dispatch: AppDispatch) => {
    const getMessageIds = async () => {
      const result = await DataService.events.deleteReminders(eventId, reminderIds);
      const messageIds = result?.data.messageIds;
      return messageIds;
    };

    const idsCount = reminderIds.length;
    const bannerPrefix = pluralize("Reminder", idsCount);

    const updateParams: UpdateParams = {
      id: "DeleteEventReminders",
      title: "Deletion of event reminders",
      onCompleted: () => dispatch(refetchReminders(eventId)),
      getMessageIds,
      successTransientMessage: `${bannerPrefix} ${idsCount === 1 ? "has" : "have"} been deleted!`,
      failureTransientMessage: `${bannerPrefix} delete failed!`,
    };

    await initializeBackgroundTask(updateParams, dispatch);
  };

const initializeBackgroundTask = async (updateParams: UpdateParams, dispatch: AppDispatch) => {
  /* istanbul ignore next */
  const updateFeatureProps: UpdateFeatureProps = {
    addOperation: (addOperationParams: AddOperationParamsV1) => dispatch(addOperationV1(addOperationParams)),
    sendTransientNotification: (payload: any) => dispatch(sendTransientNotification(payload)),
  };

  await backgroundTask.updateEntity(updateParams, updateFeatureProps);
};
