import packDataService from "../../services/packsDataService";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/packTrialPeriodsSlice";

export const fetchPackTrialPeriods = () => async (dispatch: AppDispatch) => {
  dispatch(fetchBegin());
  try {
    const res = await packDataService.getPackTrialPeriodsAsync();
    dispatch(
      fetchSuccess({
        items: res.data,
        totalCount: res.data.length,
      }),
    );
  } catch (e: any) {
    dispatch(fetchFailure(e));
  }
};
