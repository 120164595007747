import cn from "classnames";

import { PublishedStatusTypes, RouteNames } from "../../../../enums";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import AssetMenuButtons from "../../../buttons/assetMenuButtons/AssetMenuButtons";
import { PublishedStatus } from "../../../common/publishedStatus";
import ExpiresCardTemplate from "../../../ExpiresTemplate/ExpiresCardTemplate/ExpiresCardTemplate";
import { VideoCardLayout } from "../VideoCardLayout";
import { CardVideoItem, VideoCardBaseProps } from "../types";
import Icon from "../../../../interfaces/icon";
import ObjectUtils from "utils/objectUtils";

import "./peopleVideoCard.scss";

export interface PeopleVideoCardItem extends CardVideoItem {
  expirationDate?: string;
  inherited?: boolean;
}

export interface PeopleVideoCardProps<T extends PeopleVideoCardItem = PeopleVideoCardItem>
  extends VideoCardBaseProps<T> {
  className?: string;
  icons?: (Icon | null)[];
  showExpirationDate?: boolean;
  deepLink?: boolean;
  showAdded?: boolean;
}

export const PeopleVideoCard = <T extends PeopleVideoCardItem = PeopleVideoCardItem>(
  props: PeopleVideoCardProps<T>,
) => {
  const {
    item,
    className,
    disabled,
    selected,
    showExpirationDate,
    onSelect,
    onTitleClick,
    icons,
    handlers,
    disablePopupMenu,
    deepLink,
    showAdded,
    selectable,
  } = props;
  const { isDraft, inherited } = item;

  return (
    <VideoCardLayout
      url={!inherited && deepLink ? `/${RouteNames.contentVideos}/${item.id.toString()}` : undefined}
      onSelect={onSelect}
      onTitleClick={onTitleClick}
      item={item}
      selected={selected}
      disabled={disabled}
      icons={icons}
      className={cn("asset-card no-hover-state", className)}
      selectable={selectable}
    >
      <div className="top-row">
        {showExpirationDate && <ExpiresCardTemplate expirationDate={item.expirationDate} />}
        {showAdded && <div>Added {dateTimeUtils.formatDate(item.dateCreated)}</div>}
      </div>

      <div className="bottom-row">
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!isDraft)} />
        {ObjectUtils.isNotEmpty(handlers) && (
          <div onClick={(e) => e.preventDefault()}>
            <AssetMenuButtons item={item} disabled={disabled || selected || disablePopupMenu} circle {...handlers} />
          </div>
        )}
      </div>
    </VideoCardLayout>
  );
};

export default PeopleVideoCard;
