import { connect } from "react-redux";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { DetailsSubHeader } from "../../../../../../components/sectionHeader";
import { Props as DetailsSubHeaderProps } from "../../../../../../components/sectionHeader/sectionSubHeader/SectionSubHeader";

const mapStateToProps = (state: RootState, ownProps: DetailsSubHeaderProps) => {
  const directSend = state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileEntityStateReducer;

  return {
    isUpdateInProgress: directSend.isEntityCommandInProgress && ownProps.isUpdateInProgress,
    lastModifiedDateTime: directSend.lastModifiedDateTime ?? ownProps.lastModifiedDateTime,
  };
};

export default connect(mapStateToProps)(DetailsSubHeader);
