import { DetailsSubHeader } from "../../../../../components/sectionHeader";
import { Props as DetailsSubHeaderProps } from "../../../../../components/sectionHeader/sectionSubHeader/SectionSubHeader";
import { RootState } from "../../../../Application/globaltypes/redux";
import { connect } from "react-redux";
import { assessmentsStateSelector } from "../../state/selectors";

export type AssessmentSubheaderContainerProps = DetailsSubHeaderProps & {
  showAutoSaveOnTab: boolean;
};

const mapStateToProps = (state: RootState, ownProps: AssessmentSubheaderContainerProps) => {
  const base = assessmentsStateSelector(state).base;

  return {
    isUpdateInProgress: base.assessmentEntityStateReducer.isEntityCommandInProgress && ownProps.isUpdateInProgress,
    lastModifiedDateTime: ownProps.showAutoSaveOnTab
      ? base.assessmentEntityStateReducer.lastModifiedDateTime ?? ownProps.lastModifiedDateTime
      : undefined,
  };
};

export default connect(mapStateToProps)(DetailsSubHeader);
