import { FieldArray } from "formik";
import React from "react";
import { QuestionFormProps } from "./props";
import "./QuestionForm.scss";

export const QuestionForm = (props: QuestionFormProps) => {
  return (
    <div className="multiple-questions">
      <FieldArray name={"questions"} validateOnChange={false} render={props.renderQuestionBuilder} />
    </div>
  );
};

export default QuestionForm;
