import { useQuery } from "@tanstack/react-query";
import { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import {
  PerformanceFilter,
  QueryFilterFlow,
  completes,
  completesColor,
  lineChartFactory,
  roundToTwoDigits,
  starts,
  startsColor,
} from "features/Library/Common/utils/performanceUtils";

import * as contentReportingService from "features/Reporting/services/contentReportingService";
import { mockFlowEmptyEngagement } from "features/Reporting/mocks/contentData";

const fetchDailyData = async ({ queryKey }: QueryFilterFlow) => {
  const [, filter] = queryKey;
  const result = await contentReportingService.getFlowsDailyData(filter);
  return lineChartFactory(result.data);
};

export const fetchEngagementQuery = async ({ queryKey }: QueryFilterFlow) => {
  const [, filter] = queryKey;

  const result = await contentReportingService.getFlowsEngagement(filter);
  const data = {
    ...mockFlowEmptyEngagement,
    ...result.data[0],
  };

  const barData: DataPoint[] = [
    {
      id: starts.toLowerCase(),
      category: starts,
      value: data.InProgress,
      fillColor: startsColor,
    },
    {
      id: completes.toLowerCase(),
      category: completes,
      value: data.Completed,
      fillColor: completesColor,
    },
  ];

  const cardData: CardReportingItem[] = [
    {
      stat: data.Customers,
      statDescription: "Customers",
    },
    {
      stat: roundToTwoDigits(data.AverageCustomerSize),
      statDescription: "Avg. Customer Size",
    },
    {
      stat: data.Flows,
      statDescription: "Flows",
    },
    {
      stat: data.Users,
      statDescription: "Users",
    },
    {
      stat: roundToTwoDigits(data.AverageFlowsUser),
      statDescription: "Avg. Flows/User",
    },
  ];

  return { ...data, barData, cardData };
};

export const useFlowLineChartQuery = (filter: PerformanceFilter | null, flowId?: string | number) => {
  return useQuery({
    queryKey: ["FlowReportDailyData", { ...filter, flowId }],
    queryFn: fetchDailyData,
    enabled: !!filter,
  });
};

export const useFlowEngagementQuery = (filter: PerformanceFilter | null, flowId?: string | number) => {
  return useQuery({
    queryKey: ["FlowReportEngagement", { ...filter, flowId }],
    queryFn: fetchEngagementQuery,
    enabled: !!filter,
  });
};
