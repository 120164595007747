export const getThreatDefenceCampaignOptionsBegin = "GET_THREAT_DEFENCE_CAMPAIGN_OPTIONS_BEGIN";
export const getThreatDefenceCampaignOptions = "GET_THREAT_DEFENCE_CAMPAIGN_OPTIONS_SUCCESS";
export const getThreatDefenceCampaignOptionsFailure = "GET_THREAT_DEFENCE_CAMPAIGN_OPTIONS_FAILURE";

export const getThreatDefenceCampaignEmailTemplatesBegin = "GET_THREAT_DEFENCE_CAMPAIGN_EMAIL_TEMPLATES_BEGIN";
export const getThreatDefenceCampaignEmailTemplatesSuccess = "GET_THREAT_DEFENCE_CAMPAIGN_EMAIL_TEMPLATES_SUCCESS";
export const getThreatDefenceCampaignEmailTemplatesFailure = "GET_THREAT_DEFENCE_CAMPAIGN_EMAIL_TEMPLATES_FAILURE";

export const getThreatDefenceCampaignLandingPagesBegin = "GET_THREAT_DEFENCE_CAMPAIGN_LANDING_PAGES_BEGIN";
export const getThreatDefenceCampaignLandingPagesSuccess = "GET_THREAT_DEFENCE_CAMPAIGN_LANDING_PAGES_SUCCESS";
export const getThreatDefenceCampaignLandingPagesFailure = "GET_THREAT_DEFENCE_CAMPAIGN_LANDING_PAGES_FAILURE";
