import * as Yup from "yup";

const licensesCountRange = {
  min: 1,
  max: 5000000,
};

const requestPackLicensesCount = Yup.number()
  .required("Number of Licenses is required")
  .min(licensesCountRange.min, "Please enter a number larger than 0")
  .max(licensesCountRange.max, "Number of Licenses can be maximum " + licensesCountRange.max);

export const validateLicensesCountInputValue = (input: string) => {
  return /^(0|[1-9]\d*)$/.test(input) || input === "";
};

export const packRequestValidationSchema = Yup.object().shape({
  licensesCount: requestPackLicensesCount,
  contactName: Yup.string()
    .trim()
    .required("Contact name is required")
    .max(128, "Contact name must be maximum of 128 characters long"),
  contactPhone: Yup.string().trim().required("Contact phone is required").min(13, "Please enter correct phone number"),
});

export const enablePackValidationSchema = Yup.object().shape({
  licensesCount: requestPackLicensesCount,
});

export const packTrialRequestValidationSchema = Yup.object().shape({
  licensesCount: requestPackLicensesCount,
});
