import React, { useState, useEffect, useCallback } from "react";
import { useReactFlow, useStore } from "reactflow";
import { useObserver } from "../../../../../hooks/useObserver";
import { IObservable } from "../../../../../interfaces";
import {
  getDestinationOption,
  getResponseTypeContent,
  getTimeUnitsOptions,
  getTypeOptions,
} from "../../utils/triggersDataUtils";
import FlowComponentsPanel from "../FlowComponentsPanel/FlowComponentsPanel";
import { IFlowNode, IFlowEdge, FlowItemBase } from "../ReactFlowCanvas/nodes/types";
import { EntityTriggerTypes, EntityType, Trigger, TriggersChanges, TriggersData, TriggerTimeUnit } from "../types";
import { getSelectedNode } from "../ReactFlowCanvas/hooks/useReactFlowCanvas";
import { useReactFlowActions, useReactFlowState } from "../ReactFlowCanvas/Providers/ReactFlowStateProvider";
import {
  useReactFlowCanvasActions,
  useReactFlowCanvasState,
} from "../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import "./assetInspector.scss";
import { setActionNode } from "../utils/nodesHelpers";
import { isEmpty } from "lodash";

interface IAssetInspectorOwnProps {
  isReadOnly: boolean;
  triggerTypes: EntityTriggerTypes[];
  triggerTimeUnits: TriggerTimeUnit[];
  onResetSelectedElementsObserver?: IObservable<() => void>;
}

interface ITriggersToUpdate {
  [key: string]: TriggersData;
}

export interface IItemsToUpdate {
  [key: string]: FlowItemBase;
}

export const prepareTrigger = (
  edgeId: string,
  nodes: IFlowNode[],
  edges: IFlowEdge[],
  triggerTypes: EntityTriggerTypes[],
  triggerTimeUnits: TriggerTimeUnit[],
): Trigger | undefined => {
  const selectedEdge: IFlowEdge | undefined = edges.find((edge) => edge.id === edgeId) as IFlowEdge;

  if (edges) {
    const { source, target, data, sourceHandle } = selectedEdge;
    const sourceNodeOfSelectedEdge = nodes.find((node) => node.id === selectedEdge?.data?.inId);
    const targetNode = nodes.find((node) => node.id === selectedEdge?.data?.outId);
    const destinationOption = getDestinationOption(targetNode);
    const typeId = data?.typeId || 0;
    const triggerOptions = getTypeOptions(
      triggerTypes,
      (sourceNodeOfSelectedEdge?.type !== "StartOfTheFlow" && sourceNodeOfSelectedEdge?.type !== "Placeholder"
        ? sourceNodeOfSelectedEdge?.type || ""
        : "") as "" | EntityType,
      typeId,
    );
    const unitsOptions = getTimeUnitsOptions(triggerTimeUnits);
    const timeUnitId = data?.timeUnitId || 0;
    const responseTypeContent = getResponseTypeContent(
      typeId,
      sourceNodeOfSelectedEdge?.data?.branchingQuestion,
      sourceHandle,
    );

    // to-do Extend triggerTypeContent property
    return {
      inId: source,
      outId: target,
      bulletId: sourceHandle,
      timeSpan: data?.timeSpan || 0,
      typeId,
      timeUnitId,
      triggerTypeContent: { ...responseTypeContent },
      source,
      destination: target,
      destinationOption,
      trigger: typeId,
      triggerOptions,
      units: timeUnitId,
      unitsOptions,
      delay: data?.timeSpan?.toString() || "",
      isAction: data?.isAction || false,
      isDefault: data?.isDefault || false,
    };
  }
};

export const prepareTriggers = (
  nodes: IFlowNode[],
  edges: IFlowEdge[],
  selectedEdges: IFlowEdge[],
  triggerTypes: EntityTriggerTypes[],
  triggerTimeUnits: TriggerTimeUnit[],
): Trigger[] => {
  const updatedTriggersData: Trigger[] = [];

  if (selectedEdges && selectedEdges.length) {
    selectedEdges.forEach(({ id }) => {
      const trigger = prepareTrigger(id, nodes, edges, triggerTypes, triggerTimeUnits);
      trigger && updatedTriggersData.push(trigger);
    });
  }
  return updatedTriggersData;
};

const initialTriggers: Trigger[] = [];
export const AssetInspector: React.FC<IAssetInspectorOwnProps> = ({
  isReadOnly,
  triggerTypes,
  triggerTimeUnits,
  onResetSelectedElementsObserver,
}): React.ReactElement => {
  const [triggers, setTriggers] = useState<Trigger[]>(initialTriggers);
  const { save, setShowTriggers, setFlowEndView, setSectionHeaderView } = useReactFlowCanvasActions();
  const { nodes, edges } = useReactFlowState();
  const { setNodes, setEdges } = useReactFlowActions();
  const { getNode } = useReactFlow();
  const { showTriggers, sectionHeaderView, flowEndView } = useReactFlowCanvasState();
  const resetSelectedElements = useStore((store) => store.resetSelectedElements);
  const [subscribeOnResetSelectedElements] = useObserver(onResetSelectedElementsObserver);

  const getSourceNodeByEdge = (edges?: IFlowEdge[]): IFlowNode | undefined => {
    const selectedEdge = edges?.find((edge) => edge.selected);
    return selectedEdge?.source ? getNode(selectedEdge?.source) : undefined;
  };

  const updateEdges = useCallback(
    (triggersData: TriggersChanges[]): void => {
      const triggersToUpdate: ITriggersToUpdate = {};
      const itemsToUpdate: IItemsToUpdate = {};

      triggersData.forEach(({ inId, outId, bulletId, ...rest }) => {
        const triggersToUpdateId = bulletId ? `el-${outId}-${inId}-${bulletId}` : `el-${outId}-${inId}`;
        triggersToUpdate[triggersToUpdateId] = rest as TriggersData;

        if (rest.isAction !== undefined) {
          itemsToUpdate[outId] = { canConnect: !rest.isAction } as FlowItemBase;
        }
      });
      setEdges((edges) => {
        const newEdges = edges
          .filter(({ data }) => data?.inId && (!itemsToUpdate[data.inId] || itemsToUpdate[data.inId]?.canConnect))
          .map((edge) =>
            triggersToUpdate[edge.id] ? { ...edge, data: { ...edge.data, ...triggersToUpdate[edge.id] } } : edge,
          );
        !isEmpty(itemsToUpdate) && setNodes((nodes) => setActionNode(newEdges, nodes, itemsToUpdate));
        return newEdges;
      });

      save();
    },
    [setEdges, setNodes, save],
  );

  const deselectFlowItems = useCallback(() => {
    resetSelectedElements();
    setShowTriggers(false);
    setSectionHeaderView(undefined);
    setFlowEndView(undefined);
  }, [resetSelectedElements, setShowTriggers, setSectionHeaderView, setFlowEndView]);

  let selectedNode = getSelectedNode(nodes);

  if (!selectedNode) {
    selectedNode = getSourceNodeByEdge(edges);
  }

  useEffect(() => {
    if (showTriggers) {
      const selectedEdges = edges.filter((edge) => edge.selected);

      const triggersToSet = selectedEdges.length
        ? prepareTriggers(nodes, edges, selectedEdges, triggerTypes, triggerTimeUnits)
        : initialTriggers;

      setTriggers(triggersToSet);
    }
  }, [
    // we need elements to get actual data for edges
    // it's not affect rendering
    // because rendering here depends on triggers and they don't change
    edges,
    nodes,
    triggerTypes,
    triggerTimeUnits,
    showTriggers,
  ]);

  useEffect(() => {
    return subscribeOnResetSelectedElements(deselectFlowItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnResetSelectedElements]);

  return (
    <div className="flow-components-panel-container">
      <FlowComponentsPanel
        sourceTitle={selectedNode?.data?.title || ""}
        triggers={triggers}
        updateTriggerDataChanges={updateEdges}
        triggerView={showTriggers}
        flowEndView={flowEndView}
        sectionHeaderView={sectionHeaderView}
        deselectFlowItems={deselectFlowItems}
        isReadOnly={isReadOnly}
      />
    </div>
  );
};

export default AssetInspector;
