import GoalForm from "./GoalForm";
import { GoalView } from "../types/models";
import { UpdateGoalPayload } from "../types/requests";
import { IObservable, IWizardStep, bindAction } from "interfaces";
import { updateGoal } from "../state/thunks/flowGoalThunk";
import { FormikProps, withFormik } from "formik";
import { AutosaveProps, withAutosave } from "utils";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { extractFormikProps } from "utils/formikUtils";
import { useEffect } from "react";
import { saveGoal } from "../state/slices/flowGoalSlice";
import { useLocalContext } from "hooks/useLocalContext";
import { goalValidationSchema } from "utils/validationSchemas/flowValidationSchema";
import Restricted from "features/Application/Restricted";
import { RolePermissions } from "enums";

interface Props extends IWizardStep {
  flowId?: number;
  onDiscardObserver?: IObservable<() => void>;
  onMount?: () => void;
}

export type PropsAll = Props & FormikProps<GoalView> & PropsFromRedux & AutosaveProps;

export const Goal = (props: PropsAll) => {
  const { onDiscardObserver, saveGoal, onIsValidChange, acceptHandlers, onMount } = props;
  const formik = extractFormikProps<GoalView, PropsAll>(props);
  const { resetForm } = formik;

  const ctx = useLocalContext({ values: formik.values });

  useEffect(() => {
    const cb = () => saveGoal(ctx.current.values);
    acceptHandlers?.({
      onNext: cb,
      onPrevious: cb,
    });
    onMount?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onIsValidChange?.(formik.isValid);
  }, [formik.isValid, onIsValidChange]);

  useEffect(() => {
    onDiscardObserver?.subscribe(resetForm);
    return () => onDiscardObserver?.unsubscribe(resetForm);
  }, [onDiscardObserver, resetForm]);

  return (
    <Restricted
      permissions={[RolePermissions.FlowsCreate]}
      renderContent={(hasAnyPermission) => (
        <GoalForm
          onChanged={props.save}
          formik={formik}
          isLoading={props.isLoading}
          disabled={!(props.isDraft && hasAnyPermission)}
          options={props.options}
        />
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: Props) => {
  const flow = state.library.flows.base.entity;
  const info = state.library.flows.base.information;
  const goal = state.library.flows.base.goal;
  return {
    id: ownProps.flowId || flow.entityId,
    options: goal.options,
    initValues: goal.values,
    isLoaded: goal.isLoaded,
    isLoading: goal.isLoadingOptions || goal.isLoading,
    isDraft: info.info.isDraft,
    isCreating: flow.changingEntityState,
  };
};

const mapViewToPayload = (id: number, view: GoalView): UpdateGoalPayload => {
  return { id, ...view };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  update: bindAction(updateGoal, dispatch),
  saveGoal: bindAction(saveGoal, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<PropsAll, GoalView, UpdateGoalPayload>({
  getInitValues: (props) => mapViewToPayload(props.id, props.values),
  stateProvider: (values, props) => mapViewToPayload(props.id, values),
  entityUpdater: (props) => props.update,
  isValid: (props) => props.isDraft && props.id > 0 && props.isValid,
})(Goal);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: goalValidationSchema,
  enableReinitialize: true,
  mapPropsToValues: (props: Props & PropsFromRedux) => props.initValues,
  validateOnMount: true,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
