import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../../Application/actions/entityState/entityStateActionTypes";

export const createThreatDefenceEmailTemplateBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN,
);

export const createThreatDefenceEmailTemplateSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS,
);

export const createThreatDefenceEmailTemplateFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE,
);

export const updateThreatDefenceEmailTemplateBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);

export const updateThreatDefenceEmailTemplateSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const updateThreatDefenceEmailTemplateFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);

export const threatDefenceEmailTemplateUpdated = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);

export const resetThreatDefenceEmailTemplateEntityState = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const publishThreatDefenceEmailTemplateBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);
export const publishThreatDefenceEmailTemplateSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);
export const publishThreatDefenceEmailTemplateFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);

export const setEmailTemplateEntityId = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.SET_ENTITY_ID,
);

export const fetchLockedThreatDefenceEmailTemplateBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);

export const fetchLockedThreatDefenceEmailTemplateSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);

export const fetchLockedThreatDefenceEmailTemplateFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);

export const fetchDiscardThreatDefenceEmailTemplateBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);

export const fetchDiscardThreatDefenceEmailTemplateSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);

export const fetchDiscardThreatDefenceEmailTemplateFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceEmailTemplates,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);
