import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DropdownItemProps, DropdownProps, Icon } from "semantic-ui-react";
import { DropdownWrapper, FilterSidebar, TextTruncate } from "../../../../../components";
import { DateRange } from "../../types/performance";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { Tooltip } from "components/common/tooltip";

import "./FlowPerformanceFilter.scss";

type VersionFilter = {
  date: number;
};

function CreateDateString(range?: DateRange): string {
  if (!range) return "";
  const stringStartDate = dateTimeUtils.formatDateWithHour(range.DATETIME_START);
  const stringEndDate =
    range.DATETIME_END !== null ? dateTimeUtils.formatDateWithHour(range.DATETIME_END) : "Present";

  return `${stringStartDate} - ${stringEndDate}`;
}

// All the +1/-1 requirements are because when a number is 0 the FilterSidebar's
// change handler returns undefined instead. So counting instead goes 1,2,...
const FilterToSidebarFilter = (num: number) => num + 1;
const SidebarFilterToFilter = (num: number) => num - 1;

export interface Props {
  dateRanges: DateRange[];
  currentFilter: number;
  setCurrentFilter: (newFilter: number) => void;
  handleReset: () => void;
  disabled?: boolean;
}

export const FlowFilter: FC<Props> = ({
  currentFilter,
  dateRanges,
  setCurrentFilter,
  handleReset,
  disabled = false,
}) => {
  const [currentBoxFilter, setCurrentBoxFilter] = useState(FilterToSidebarFilter(currentFilter));
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setCurrentBoxFilter(FilterToSidebarFilter(currentFilter));
  }, [currentFilter]);

  const defaultFilter = useMemo<VersionFilter>(
    () => ({
      date: dateRanges.length,
    }),
    [dateRanges.length],
  );

  const updateFilter = useCallback((newFilter: VersionFilter) => {
    setCurrentBoxFilter(newFilter.date);
  }, []);

  const handleSubmit = useCallback(
    (newFilter: VersionFilter) => {
      setCurrentFilter(SidebarFilterToFilter(newFilter.date));
      setVisible(false);
    },
    [setCurrentFilter],
  );

  const handleLocalReset = useCallback(() => {
    setCurrentBoxFilter(dateRanges.length);
    handleReset();
  }, [dateRanges.length, handleReset]);

  const mappedRanges = useMemo<DropdownItemProps[]>(() => {
    return dateRanges.map<DropdownItemProps>((v, i) => ({
      key: i,
      value: i + 1,
      text: CreateDateString(v),
    }));
  }, [dateRanges]);

  return (
    <div className="flowPerformanceFilter">
      <FilterSidebar
        visible={visible}
        hideFilter={() => setVisible(false)}
        resetFilter={handleLocalReset}
        applyFilter={handleSubmit}
        appliedFilter={{ date: FilterToSidebarFilter(currentFilter) }}
        defaultFilter={defaultFilter}
        shouldDisableApply={SidebarFilterToFilter(currentBoxFilter) === currentFilter}
        filterOptions={mappedRanges}
        getFilterOptions={() => mappedRanges}
        dis
      >
        <LocalDropdownFilter
          currentFilter={currentBoxFilter}
          updateLocalFilter={updateFilter}
          filterOptions={dateRanges}
        />
      </FilterSidebar>
      {currentFilter >= 0 && (
        <div className="chip">
          <span>Date: </span>
          {CreateDateString(dateRanges[currentFilter])}
        </div>
      )}
      <button
        data-testid="flowFilterVisibilityToggle"
        className="flowFilterButton"
        onClick={() => setVisible((v) => !v)}
        type="button"
        disabled={!dateRanges.length || disabled}
      >
        <Icon name="filter" fitted /> Filter
      </button>
    </div>
  );
};

interface FiltersFromSidebarParent {
  updateFilter?: (...args: any[]) => void;
}
interface CustomDropdownProps extends FiltersFromSidebarParent {
  currentFilter: number;
  updateLocalFilter: (newIndex: VersionFilter) => void;
  filterOptions: DropdownItemProps[];
}

const LocalDropdownFilter: FC<CustomDropdownProps> = ({
  currentFilter,
  filterOptions,
  updateLocalFilter,
  updateFilter,
}) => {
  const handleChange = useCallback(
    (_e: any, { value }: DropdownProps) => {
      if (!updateFilter || !updateLocalFilter) return;
      updateFilter({ date: value });
      updateLocalFilter({ date: value as number });
    },
    [updateFilter, updateLocalFilter],
  );

  return (
    <>
      <label>Date</label>
      <DropdownWrapper
        items={filterOptions}
        selection
        fluid
        onChange={handleChange}
        value={currentFilter}
        trigger={
          <Tooltip
            inverted
            target={<TextTruncate>{filterOptions?.[SidebarFilterToFilter(currentFilter)]?.text}</TextTruncate>}
            content={filterOptions?.[SidebarFilterToFilter(currentFilter)]?.text}
          />
        }
      />
    </>
  );
};
