import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/globaltypes/redux";
import { ClaimType } from "../../types";

export interface ClaimTypesState {
  items: ClaimType[];
  isLoading: boolean;
  error: string | undefined;
}

const initialState: ClaimTypesState = {
  items: [],
  isLoading: false,
  error: undefined,
};

const claimTypesSlice = createSlice({
  name: "claimTypes",
  initialState: initialState,
  reducers: {
    req(state: ClaimTypesState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: ClaimTypesState, action: PayloadAction<ClaimType[]>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    },
    err(state: ClaimTypesState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = claimTypesSlice.actions;

export const claimTypesSelector = (state: RootState) => state.accounts.identityProviders.claimTypes.items;
export const claimTypesLoadingSelector = (state: RootState) => state.accounts.identityProviders.claimTypes.isLoading;

export default claimTypesSlice.reducer;
export type claimTypesStateSliceType = ReturnType<typeof claimTypesSlice.reducer>;
