import React from "react";

import InputField from "../../../../Common/Fields/InputField";
import CheckboxField from "../../../../Common/Fields/CheckboxField";

import { AnswerPropsBase } from "../../../../Common/Answer/types";
import { Answer } from "../../../types/state";
import { WithFormikPayload } from "../../../../Common/Fields/WithFormikPayload";
import { MAX_ANSWER_FIELD_LENGTH } from "../../../../Common/constants";

export type SingleTextAnswerProps = AnswerPropsBase<Answer>;

export const SingleTextAnswer = (props: SingleTextAnswerProps) => {
  const { index, answer, onBlur, onChanged, disabled, validatedFieldProps, propertyNamePrefix } = props;
  const { setFieldValue, setFieldTouched } = validatedFieldProps;

  const checkboxFieldPropertyName = `${propertyNamePrefix}question.correctAnswer`;
  const inputFieldPropertyName = `${propertyNamePrefix}answers.${index}.answer.text`;

  return (
    <>
      <div className="answer-check-block">
        <WithFormikPayload
          propertyName={checkboxFieldPropertyName}
          {...validatedFieldProps}
          render={(formikProps) => (
            <CheckboxField
              id={`${propertyNamePrefix}.${index}`}
              radio
              disableValidation
              withoutErrorMessage
              index={index}
              value={answer.answer.isCorrect}
              disabled={disabled}
              onChange={onChanged}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              {...formikProps}
            />
          )}
        />
      </div>

      <div className="answer-text-block">
        <WithFormikPayload
          propertyName={inputFieldPropertyName}
          {...validatedFieldProps}
          selfValidation={true}
          render={(formikProps) => (
            <InputField
              placeholder={`Answer ${index + 1} text`}
              value={answer.answer.text}
              maxLength={MAX_ANSWER_FIELD_LENGTH}
              errorPosition={"bottom"}
              onBlur={onBlur}
              disabled={disabled}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              {...formikProps}
            />
          )}
        />
      </div>
    </>
  );
};
