import { ModalWithStepsStep } from "components/modal/ModalWithSteps";
import React from "react";
import { GroupTemplatePacks } from "../../Details/Packs/GroupTemplatePacks";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { AvailablePacksRequest } from "../../types/models";
import { noop } from "lodash";
import { fetchAvailablePacks } from "../../state/thunks/TemplateAvailablePacksThunk";
import { setPacksToAdd, resetGrid } from "../../state/TemplateAvailablePacksSlice";
import { PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import styles from "./addPacksModal.module.scss";

export interface AddPacksModalStepProps extends ModalWithStepsStep {
  templateId: number;
  packMap: PackMap;
}

export type AddPacksModalStepPropsAll = PropsFromRedux & AddPacksModalStepProps;

export const AddPacksModalStep: React.FC<AddPacksModalStepPropsAll> = ({
  templateId,
  items,
  itemsCount,
  isLoading,
  isAllDataLoaded,
  selectedIds,
  setSelectedIds,
  getData,
  resetGridItems,
  packMap,
}) => {
  const loadPacks = (skip: number, searchTerm: string, orderBy: string) => {
    getData({ templateGroupId: templateId, skip, searchTerm, orderBy });
  };

  return (
    <GroupTemplatePacks
      templateId={templateId}
      packMap={packMap}
      items={items}
      itemsCount={itemsCount}
      isLoading={isLoading}
      isAllDataLoaded={isAllDataLoaded}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      isCreate={true}
      getGridData={loadPacks}
      getListData={noop}
      resetGrid={resetGridItems}
      className={styles.step}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.people.groupTemplate.availablePacks.items,
    itemsCount: state.people.groupTemplate.availablePacks.itemsCount,
    isLoading: state.people.groupTemplate.availablePacks.isLoading,
    isAllDataLoaded: state.people.groupTemplate.availablePacks.areAllLoaded,
    selectedIds: state.people.groupTemplate.availablePacks.packsToAdd,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSelectedIds: (ids: number[]) => dispatch(setPacksToAdd(ids)),
  getData: (request: AvailablePacksRequest) => dispatch(fetchAvailablePacks(request)),
  resetGridItems: () => dispatch(resetGrid()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddPacksModalStep);
