import React from "react";
import { QuestionPropsBase } from "../types";

export interface Picture {
  url: string;
}

export type PictureQuestionProps = QuestionPropsBase<Picture>;

export const PictureQuestion = (props: PictureQuestionProps) => {
  return <div className="answer-picture-block">{props.question.url}</div>;
};
