import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataPoint } from "../../../../../components/charts/types/HorizontalBarChart";
import { RequestState, RStatus } from "../../../../Application/globaltypes/fetchRequest";
import { RootState } from "../../../../Application/globaltypes/redux";
import { LicenseUsage, PerformanceSlice, TotalAccountLicenses } from "../../types/state";
import { completesColor, startsColor } from "features/Library/Common/utils/performanceUtils";

const initialRequestState: RequestState<any> = {
  status: RStatus.Idle,
  value: {},
  errorMessage: undefined,
};

const initialState: PerformanceSlice = {
  totalAccountLicenses: { ...initialRequestState },
  licenseUsage: { ...initialRequestState },
};

const packPerformanceSlice = createSlice({
  name: "packPerformanceSlice",
  initialState,
  reducers: {
    reqTotalAccountLicenses(state) {
      state.totalAccountLicenses = {
        ...state.totalAccountLicenses,
        status: RStatus.Pending,
      };
    },
    setTotalAccountLicenses(state, action: PayloadAction<{ data: TotalAccountLicenses }>) {
      state.totalAccountLicenses = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setTotalAccountLicensesError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.totalAccountLicenses = {
        ...state.totalAccountLicenses,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqLicenseUsage(state) {
      state.licenseUsage = {
        ...state.licenseUsage,
        status: RStatus.Pending,
        errorMessage: undefined,
      };
    },
    setLicenseUsage(state, action: PayloadAction<{ data: LicenseUsage }>) {
      state.licenseUsage = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setLicenseUsageError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.licenseUsage = {
        ...state.licenseUsage,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  reqTotalAccountLicenses,
  setTotalAccountLicenses,
  setTotalAccountLicensesError,
  reqLicenseUsage,
  setLicenseUsage,
  setLicenseUsageError,
  reset,
} = packPerformanceSlice.actions;

export const selectTotalAccountLicenses = (state: RootState) => state.packs.performance.totalAccountLicenses;
export const selectLicenseUsage = (state: RootState) => state.packs.performance.licenseUsage;

export const selectTotalAccountPieChartData = createSelector(
  (state: RootState) => selectTotalAccountLicenses(state).value,
  (value) => {
    return [
      {
        id: "issued",
        category: "Issued",
        fillColor: startsColor,
        value: value.AssignedActiveLicenses,
      },
      {
        id: "remaining",
        category: "Remaining",
        fillColor: completesColor,
        value: value.RemainingActiveLicenses,
      },
    ];
  },
);

export const selectLicenseDepartmentBarChartData = createSelector(
  (state: RootState) => selectLicenseUsage(state).value,
  (data): DataPoint[] => {
    const NO_DEPARTMENT_KEY = "No Department";
    let mappedDepartments: { [key: string]: DataPoint } = {};
    data?.Departments?.forEach((department, i) => {
      if (department.Department === "" || department.Department === null) {
        if (mappedDepartments[NO_DEPARTMENT_KEY]) {
          mappedDepartments[NO_DEPARTMENT_KEY].value += department.ActiveUsersCount;
        } else {
          mappedDepartments[NO_DEPARTMENT_KEY] = {
            id: i.toString(),
            category: NO_DEPARTMENT_KEY,
            value: department.ActiveUsersCount,
            fillColor: startsColor,
          };
        }
      } else {
        // Preventing duplicate category keys (just in case one is named identical to our null case)
        const category =
          department.Department === NO_DEPARTMENT_KEY ? department.Department.toUpperCase() : department.Department;
        mappedDepartments[category] = {
          id: i.toString(),
          category,
          value: department.ActiveUsersCount,
          fillColor: startsColor,
        };
      }
    });

    return Object.values(mappedDepartments);
  },
);

export default packPerformanceSlice.reducer;
