import moment from "moment";
import { PeriodType, SessionView } from "../../../types/state";
import dateTimeUtils from "utils/dateTimeUtils";

export const defaultPeriodicRule = {
  periodType: PeriodType.Day,
  periodValue: 1,
};

export const getDefaultSession = (periodic: boolean): SessionView => {
  const step = 30;
  const normalizedTime = moment().add(step - (moment().minute() % step), "minutes");

  return {
    id: null,
    timeZone: dateTimeUtils.getBrowserTimezone(),
    startDate: normalizedTime.format("MM/DD/yyyy"),
    startsAt: normalizedTime.format("hh:mm A"),
    endsAt: normalizedTime.add(step, "minutes").format("hh:mm A"),
    isPeriodic: periodic,
    webinarUrl: "",
    recurrenceSession: defaultPeriodicRule,
  };
};
