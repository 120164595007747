import { RolePermissions } from "../../../../../enums";
import { RootState } from "../../../../Application/globaltypes/redux";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import { fetchPermissionsToAccountPyaload, fetchPermissionsToAccount } from "../thunks/permissionsToAccountThunk";

export type PermissionsToAccountState = FetchingItemsState<RolePermissions>;

const potentialContactsInitialState: PermissionsToAccountState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
};

const permissionsToAccountSlice = createFetchingItemsSlice({
  namePayload: fetchPermissionsToAccountPyaload,
  initialState: potentialContactsInitialState,
  thunk: fetchPermissionsToAccount,
  reducers: {},
});

export const permissionsToAccountSelector = (state: RootState) => state.accounts.edit.permissionsToAccount.items;

export default permissionsToAccountSlice.reducer;
