import React from "react";

import { Trigger as ITrigger } from "../../../types";
import { ResponseTriggerLayout, DefaultTriggerLayout } from "./layouts";
import { getTriggersMap } from "./utils/triggersUtils";

import Trigger from "../Trigger/Trigger";
import TriggerGroupType from "../../../../../../../enums/flowDesigner/triggerGroupType";

export interface Props {
  triggers: ITrigger[];
  onTriggerChange: (value: number, source: string, destination: string) => void;
  onDestinationChange: () => void;
  onTimeSpanChange: (value: string, source: string, destination: string) => void;
  onUnitsChange: (value: string, source: string, destination: string) => void;
  onDelayBlur: (source: string, destination: string) => void;
  onIsActionChange: (value: boolean, source: string, destination: string, bulletId?: string | null) => void;
  isReadOnly: boolean;
}

const Triggers: React.FC<Props> = (props) => {
  const {
    triggers,
    onTriggerChange,
    onDestinationChange,
    onTimeSpanChange,
    onUnitsChange,
    onDelayBlur,
    onIsActionChange,
    isReadOnly,
  } = props;

  const getTriggerProps = (key: number, trigger: ITrigger) => ({
    ...trigger,
    key,
    isReadOnly,
    onTriggerChange,
    onDestinationChange,
    onTimeSpanChange,
    onUnitsChange,
    onDelayBlur,
    onIsActionChange,
  });

  const renderTrigger = (key: number, trigger: ITrigger) => <Trigger {...getTriggerProps(key, trigger)} />;

  const getTriggersLayoutMap = ([triggerGroupType, triggersGroup]: [TriggerGroupType, ITrigger[]]) => {
    const layoutByType: { [key in TriggerGroupType]?: React.ReactElement } = {
      [TriggerGroupType.General]: (
        <DefaultTriggerLayout key="Default-Trigger-Layout" triggers={triggersGroup} renderTrigger={renderTrigger} />
      ),
      [TriggerGroupType.Response]: (
        <ResponseTriggerLayout key="Response-Trigger-Layout" triggers={triggersGroup} renderTrigger={renderTrigger} />
      ),
    };
    return layoutByType[triggerGroupType];
  };

  return <div className="triggers-list">{getTriggersMap(triggers).map(getTriggersLayoutMap)}</div>;
};

export default Triggers;
