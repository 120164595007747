import * as actionTypes from "../actionTypes/threatDefenceSmtpAuthSendingProfileDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../../Application/actions/actionsBuilder";
import {
  ThreatDefenceSmtpAuthSendingProfile,
  ThreatDefenceSmtpAuthSendingProfileView,
  ThreatDefenceSmtpAuthSendingProfileSettingsView,
  ThreatDefenceSmtpAuthSendingProfileVerificationView,
  VerificationPayload,
} from "../../types/state";
import { Dispatcher } from "../../../../../../../interfaces/redux";
import { setSendingProfileEntityIdAction } from "./threatDefenceSmtpAuthSendingProfileEntityStateActions";
import dataService from "../../../../../../Application/services/dataServices/typedDataService";

export const saveThreatDefenceSmtpAuthSendingProfile = (
  sendingProfileMetadata: ThreatDefenceSmtpAuthSendingProfileView,
) => {
  const saveThreatDefenceSmtpAuthSendingProfileAction = getActionProvider<ThreatDefenceSmtpAuthSendingProfileView>(
    actionTypes.saveThreatDefenceSmtpAuthSendingProfile,
  );
  return (dispatch: Dispatcher) => {
    dispatch(saveThreatDefenceSmtpAuthSendingProfileAction(sendingProfileMetadata));
  };
};

export const saveThreatDefenceSmtpAuthSendingProfileSettings = (
  sendingProfileSettings: ThreatDefenceSmtpAuthSendingProfileSettingsView,
) => {
  const saveThreatDefenceSmtpAuthSendingProfileSettingsAction =
    getActionProvider<ThreatDefenceSmtpAuthSendingProfileSettingsView>(
      actionTypes.saveThreatDefenceSmtpAuthSendingProfileSettings,
    );
  return (dispatch: Dispatcher) => {
    dispatch(saveThreatDefenceSmtpAuthSendingProfileSettingsAction(sendingProfileSettings));
  };
};

export const clearThreatDefenceSmtpAuthSendingProfile = () => {
  const resetThreatDefenceSmtpAuthSendingProfileAction = getActionBaseProvider(
    actionTypes.clearThreatDefenceSmtpAuthSendingProfile,
  );
  return (dispatch: Dispatcher) => {
    dispatch(resetThreatDefenceSmtpAuthSendingProfileAction());
  };
};

export const setSmtpAuthSendingProfileAction = getActionProvider<ThreatDefenceSmtpAuthSendingProfile>(
  actionTypes.setSmtpAuthSendingProfile,
);

export const saveSmtpAuthSendingProfileVerification = (
  sendingProfileVerification: ThreatDefenceSmtpAuthSendingProfileVerificationView,
) => {
  const saveSmtpAuthSendingProfileVerificationAction =
    getActionProvider<ThreatDefenceSmtpAuthSendingProfileVerificationView>(
      actionTypes.saveSmtpAuthSendingProfileVerification,
    );
  return (dispatch: Dispatcher) => {
    dispatch(saveSmtpAuthSendingProfileVerificationAction(sendingProfileVerification));
  };
};

export const updateSmtpAuthSendingProfileVerificationStatus = (sendingProfileVerification: VerificationPayload) =>
  getActionProvider<VerificationPayload>(actionTypes.updateSmtpAuthSendingProfileVerificationStatus)(
    sendingProfileVerification,
  );

export const resetSmtpAuthSendingProfileVerificationStatusAction = getActionBaseProvider(
  actionTypes.resetSmtpAuthSendingProfileVerificationStatus,
);

export const clearSmtpAuthSendingProfileVerificationStatusAction = getActionBaseProvider(
  actionTypes.clearSmtpAuthSendingProfileVerificationStatus,
);

export const getSmtpAuthSendingProfile = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getSmtpAuthSendingProfileBegin);
  const success = getActionProvider<ThreatDefenceSmtpAuthSendingProfile>(actionTypes.getSmtpAuthSendingProfileSuccess);
  const failure = getActionProvider<Error>(actionTypes.getSmtpAuthSendingProfileFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const result: { data: ThreatDefenceSmtpAuthSendingProfile } =
        await dataService.threatDefence.getSmtpAuthSendingProfileAsync(id);
      dispatch(success(result.data));
      dispatch(setSendingProfileEntityIdAction(result.data.id || 0));
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};

export const getSmtpAuthSendingProfileSettings = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getSmtpAuthSendingProfileSettingsBegin);
  const success = getActionProvider<ThreatDefenceSmtpAuthSendingProfileSettingsView>(
    actionTypes.getSmtpAuthSendingProfileSettingsSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getSmtpAuthSendingProfileSettingsFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const result = await dataService.threatDefence.getSmtpAuthSendingProfileSettingsAsync(id);
      dispatch(success(result.data));
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};

export const setSmtpAuthSendingProfileIsLoaded = getActionBaseProvider(actionTypes.setSmtpAuthSendingProfileIsLoaded);
