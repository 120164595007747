import axios from "axios";
import { overviewFiltering } from "../../../../../utils/queryUtils";
import { UpdateMessageRequest, MessageContent } from "../types";
import { IMessageDataService } from "../types/services";
import { formatFilters } from "../../../../../utils/filterMapUtils";
import { messageFilterTypes } from "../types/models";

const baseMessageApiUrl = "/api/communications/messages";
const baseMessageApiUrlV4 = "/api/v4/communications/messages";

const messagesDataService: IMessageDataService = {
  updateMessage(data: UpdateMessageRequest) {
    const { id, ...payload } = data;
    return axios.put(`${baseMessageApiUrl}/${id}`, payload);
  },
  updateMessageContent(data: MessageContent) {
    const { id, ...payload } = data;
    return axios.put(`${baseMessageApiUrl}/${id}/content`, payload);
  },
  getMessageWithCancel({ id }) {
    const source = axios.CancelToken.source();
    return {
      getDataPromise: axios.get(`${baseMessageApiUrl}/${id}`, {
        cancelToken: source?.token,
      }),
      cancel: source.cancel,
    };
  },
  createMessageSend(messageId, opts) {
    return axios.post(baseMessageApiUrlV4 + `/${messageId}/send-sessions`, opts);
  },
  deleteMessages(ids: number[]) {
    const data = {
      messageIds: ids,
    };
    return axios.delete(baseMessageApiUrl, { data });
  },

  getPublishedMessages({ filters, ...rest }, abortController: AbortController) {
    return axios.get(`${baseMessageApiUrl}/published`, {
      params: { ...rest, ...formatFilters(filters!, messageFilterTypes) },
      paramsSerializer: overviewFiltering,
      signal: abortController.signal,
    });
  },

  getPublishedMessagesByIds(ids, showPurchased) {
    const config = {
      params: {
        showPurchased,
        filter: ids.map((x) => `(PrimaryId eq ${x})`).join(" or "),
      },
    };

    return axios.get(`${baseMessageApiUrl}/published`, config);
  },

  getMessagesV4Async({ filterQueryParams, ...rest }) {
    const source = axios.CancelToken.source();
    return {
      getDataPromise: axios.get("/api/content", {
        params: { ...rest, ...filterQueryParams },
        cancelToken: source?.token,
        paramsSerializer: overviewFiltering,
      }),
      cancel: source.cancel,
    };
  },
};

export default messagesDataService;
