import PropTypes from "prop-types";

const types = {
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  isValid: PropTypes.bool,
  dirty: PropTypes.bool,
  setFieldTouched: PropTypes.func,
  resetForm: PropTypes.func,
  initialValues: PropTypes.object,
};

export default types;
