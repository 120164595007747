import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

interface MakeIntegrationInactiveLinkButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
}

function MakeIntegrationInactiveLinkButton(props: MakeIntegrationInactiveLinkButtonProps) {
  const { onClick, isDisabled } = props;

  return (
    <BasicLinkButton
      text="Make Inactive"
      iconClassName="fal fa-pause-circle"
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}

export default MakeIntegrationInactiveLinkButton;
