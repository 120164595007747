import { FilterItemBase, FilterViewType } from "../FilterFormBuilder/FilterFormBuilder";
import { MessageFiltersEnum } from "../../../features/Library/Communications/Messages/types/models";

export const getMessagesFilters = (disableDateFilters: boolean = false): FilterItemBase<MessageFiltersEnum>[] => {
  let filters: FilterItemBase<MessageFiltersEnum>[] = [
    {
      type: FilterViewType.DateRange,
      label: "Date Added",
      propertyName: MessageFiltersEnum.DateCreated,
      otherProps: { disabled: disableDateFilters },
    },
    {
      type: FilterViewType.DateRange,
      label: "Last Modified",
      propertyName: MessageFiltersEnum.DateModified,
      otherProps: { disabled: disableDateFilters },
    },
  ];

  return filters;
};
