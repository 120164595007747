import QhMenuItem from "./qhMenuItem";

class QhValueMenuItem extends QhMenuItem {
  createEl() {
    const el = super.createEl();
    el.classList.add("vjs-value-menu-item");

    const iconContainer = document.createElement("div");
    iconContainer.classList.add("vjs-value-menu-checkmark-cell");
    const checkmarkIcon = document.createElement("i");
    checkmarkIcon.className = "checkmark icon";
    iconContainer.appendChild(checkmarkIcon);
    el.appendChild(iconContainer);

    const label = document.createElement("div");
    label.className = "vjs-menu-item-label";
    label.innerHTML = this.localize(this.options_.label);
    el.appendChild(label);
    return el;
  }
}

export default QhValueMenuItem;
