import { ObjectSchema } from "yup";

const utils = {
  isValidSync: <TData>(template: ObjectSchema<any>, data: TData) => {
    try {
      template.validateSync(data);
      return true;
    } catch {
      return false;
    }
  },
};

export default utils;
