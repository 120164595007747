import { connect } from "react-redux";

import GenericItemsView, { GenericItemsViewProps } from "../../../../../../../../views/ItemsView/GenericItemsView";
import { AppDispatch, RootState } from "../../../../../../../Application/globaltypes/redux";
import { selectedItemsChanged } from "../../../../../state/slices/pdfGroupsSlice";
import { pdfsStateSelector } from "../../../../../selectors";
import { PdfGroup } from "../../../../../types/models";
import { bindAction } from "../../../../../../../../interfaces/redux";

const mapStateToProps = (state: RootState) => {
  const edit = pdfsStateSelector(state).edit;
  return {
    items: edit.groups.items,
    isLoading: edit.groups.isLoading,
    dataCount: edit.groups.itemsCount,
    selectedIds: edit.groups.selectedIds,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSelectedItemChanged: bindAction(selectedItemsChanged, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<PdfGroup>) => React.ReactElement);
