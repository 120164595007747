import React from "react";
import { TitleSubtitle } from "../../../../../../components/listViewTemplates";
import UserProfilePicture from "../../../../../../components/userProfilePicture/UserProfilePicture";
import { Tooltip } from "../../../../../../components/common/tooltip";
import { IOwnerItemModel } from "../../../../types";
import "./ownerInfoCell.scss";

const style = {
  width: "17rem",
};

export interface OwnerInfoCellProps {
  user: IOwnerItemModel;
  isSelected: boolean;
  onNavigate?: (id: number) => void;
}

const OwnerInfoCell = ({ user, isSelected, onNavigate }: OwnerInfoCellProps) => (
  <div className="name-column-value-container">
    <UserProfilePicture imageUrl={user.imageUrl} />
    <TitleSubtitle
      id={user.id}
      title={`${user.firstName} ${user.lastName}`}
      subtitle={user.email}
      onNavigate={onNavigate}
      label={
        isSelected ? (
          <Tooltip
            target={<div className={"owner"}>OWNER</div>}
            content="A group owner has permissions to add, remove or approve group members."
            position="top center"
            styles={style}
            hideOnScroll
            showAlways
          />
        ) : undefined
      }
    />
  </div>
);

export default OwnerInfoCell;
