import { FC, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Table } from "semantic-ui-react";
import { NoData } from "../../../../components/charts";
import { ListViewBase } from "../../../../components/listView";
import { ColumnOption } from "../../../../interfaces/columnOptions";
import { PerformanceTabSectionList } from "../../../Library/Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";
import { CustomerPacks } from "../../types/packs";
import utils from "../../../../utils/miscellaneousUtils";
import { Tooltip } from "components/common/tooltip";
import { TextTruncate } from "components";
import moboConfigurator from "../../../../moboConfigurator";

export interface Props {
  reduxFinished: boolean;
  customerPackData: CustomerPacks[];
}

export const CustomerPackReportList: FC<Props> = ({ reduxFinished, customerPackData }) => {
  const [slicedEntities, setSlicedEntities] = useState<CustomerPacks[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (reduxFinished) {
      setSlicedEntities([...customerPackData].slice(0, 10));
    }
  }, [customerPackData, reduxFinished]);

  const columns: ColumnOption<CustomerPacks>[] = [
    {
      name: "Pack Name",
      width: 7,
      render(item) {
        return (
          <Tooltip
            target={
              <TextTruncate><Link to={moboConfigurator.appendMoboIdToUrl(`/licensing/packs/purchased/${item.PackId}`)} state={{ from: location.pathname }}>
                {item.PackName}
              </Link></TextTruncate>
            }
            content={`${item.PackName}`}
          />
        );
      },
    },
    {
      name: "Status",
      width: 3,
      render(item) {
        return item.Status;
      },
    },
    {
      name: "Total Licenses",
      width: 3,
      render(item) {
        return utils.unlimitedNumber(item.TotalLicenses);
      },
    },
    {
      name: "Licenses Issued",
      width: 3,
      render(item) {
        return item.LicensesIssued.toLocaleString();
      },
    },
  ];

  const buildTableBody = (item: CustomerPacks) => {
    return columns.map((column, index) => (
      <Table.Cell className="table-body-cell" width={column.width} key={`${column.name}_${index}`}>
        {column.render(item)}
      </Table.Cell>
    ));
  };

  const getData = (skip: number, top: number) => {
    setSlicedEntities([...customerPackData].slice(skip, skip + top));
  };

  return (
    <PerformanceTabSectionList
      title="Packs"
      count={customerPackData.length}
      renderList={(page) => {
        const parsedData = slicedEntities.map((entity) => ({
          id: entity.PackId,
          ...entity,
        }));
        return !!customerPackData.length ? (
          <ListViewBase
            columnOptions={columns}
            loadPage={getData}
            items={parsedData}
            itemsAmount={customerPackData.length}
            buildTableBody={buildTableBody}
            withFooter={false}
            activePage={page}
            withSelection={false}
          />
        ) : (
          <NoData />
        );
      }}
    />
  );
};
