import React from "react";
import PropTypes from "prop-types";
import ChecklistDropdown from "../dropdowns/checklistDropdown/ChecklistDropdown";
import ValidatedField from "./ValidatedField";

const ChecklistDropdownField = (props) => {
  const { items, placeholder, isLoading, ...fieldProps } = props;

  const setValue = (value) => {
    const { setFieldValue, setFieldTouched, shouldValidate, propertyName } = props;

    setFieldValue(propertyName, value, shouldValidate);
    setFieldTouched(propertyName, true);
  };

  return (
    <ValidatedField {...fieldProps}>
      <ChecklistDropdown
        items={items}
        placeholder={placeholder}
        onCheckedItemsChanged={setValue}
        initialCheckedItems={fieldProps.value}
        isLoading={isLoading}
      />
    </ValidatedField>
  );
};

ChecklistDropdownField.propTypes = {
  items: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  shouldValidate: PropTypes.bool,
  propertyName: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  errorPosition: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default ChecklistDropdownField;
