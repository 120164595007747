import { Table } from "semantic-ui-react";

import dateTimeUtils from "../../../../utils/dateTimeUtils";
import PriorityCell from "../../../priorityLevels/priorityCell/PriorityCell";
import { Tooltip } from "../../../common/tooltip";
import { PublishedStatus } from "../../../common/publishedStatus";
import { TextTruncate } from "../../../textTruncators/TextTruncators";
import { ColumnOptions } from "../../../../interfaces";
import { ContentType, PeopleType } from "../../../../features/Library/PeopleAssignments/types";
import { PriorityLevels, PublishedStatusTypes, RouteNames } from "../../../../enums";
import AssetMenuButtons from "../../../../components/buttons/assetMenuButtons/AssetMenuButtons";
import ExpiresListTemplate from "../../../ExpiresTemplate/ExpiresListTemplate/ExpiresListTemplate";
import { Title } from "components/listViewTemplates";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import styles from "./videosAssignmentListRow.module.scss";

export interface AssetViewItemModel {
  title: string;
  id: number;
  isDraft: boolean;
  publisher: string;
  priorityLevelId: number;
  dueDate?: string;
  inherited: boolean;
  permissionIssuedDate: string;
  dateCreated: string;
  expirationDate?: string;
  peopleType: PeopleType;
}

export interface VideosAssignmentListRowProps {
  asset: AssetViewItemModel;
  columnOptions: ColumnOptions[];
  isEllipsisMenuDisabled: boolean;
  editPriorityLevel?: {
    onClick?: (item: any) => void;
    isDisabled?: (item: any) => boolean;
  };
  removeAssignment?: {
    onClick?: (item: any) => void;
    isDisabled?: (item: any) => boolean;
  };
  showButtons: boolean;
  isReadOnly?: boolean;
  peopleType: PeopleType;
  deepLink?: boolean;
}

export const VideosAssignmentListRow = (props: VideosAssignmentListRowProps): React.ReactElement => {
  const { asset, columnOptions, isEllipsisMenuDisabled, showButtons, isReadOnly, peopleType, deepLink } = props;
  const navigate = useNavigate();
  return (
    <>
      <Table.Cell width={columnOptions[0].width} disabled={asset.inherited}>
        <Tooltip
          target={
            deepLink ? (
              <Title
                url={!asset.inherited ? `/${RouteNames.contentVideos}/${asset.id}` : undefined}
                title={asset.title}
                className={cn({ "normal-color": !asset.inherited })}
                onTitleClick={() => navigate(`/${RouteNames.contentVideos}/${asset.id}`)}
              />
            ) : (
              <TextTruncate>{asset.title}</TextTruncate>
            )
          }
          content={asset.title}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width} disabled={asset.inherited}>
        <Tooltip target={<TextTruncate>{asset.publisher}</TextTruncate>} content={asset.publisher} />
      </Table.Cell>
      {!columnOptions[2].isHide && (
        <Table.Cell width={columnOptions[2].width} disabled={asset.inherited}>
          <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!asset.isDraft)} />
        </Table.Cell>
      )}
      {!columnOptions[3].isHide && (
        <Table.Cell width={columnOptions[3].width}>
          <PriorityCell
            inherited={asset.inherited}
            peopleType={peopleType}
            contentType={ContentType.Video}
            priorityLevel={asset.priorityLevelId as PriorityLevels}
            dueDate={asset.dueDate}
          />
        </Table.Cell>
      )}
      {!columnOptions[3].isHide && (
        <Table.Cell width={columnOptions[4].width} disabled={asset.inherited}>
          <Tooltip
            target={<TextTruncate>{dateTimeUtils.formatDate(asset.dateCreated)}</TextTruncate>}
            content={dateTimeUtils.formatDate(asset.dateCreated)}
          />
        </Table.Cell>
      )}
      {!columnOptions[5].isHide && (
        <Table.Cell width={columnOptions[5].width} disabled={asset.inherited}>
          <ExpiresListTemplate expirationDate={asset.expirationDate} />
        </Table.Cell>
      )}
      <Table.Cell className={styles["align-right"]} width={columnOptions[6].width} disabled={asset.inherited}>
        {showButtons && (
          <AssetMenuButtons
            {...props}
            item={asset}
            disabled={isEllipsisMenuDisabled || asset.inherited || isReadOnly}
            circle
          />
        )}
      </Table.Cell>
    </>
  );
};
