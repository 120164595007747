import { FC } from "react";
import { Filters } from "../../../utils/queryUtils";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { getMessageFilters } from "./messageFilters";

export interface MessageFilterFormForPacksProps {
  filterOptions: Filters;
  updateFilter: (filter: Filters) => void;
  filter: Filters;
}
export const MessageFilterFormForPacks: FC<MessageFilterFormForPacksProps> = ({
  filterOptions,
  filter,
  updateFilter,
}) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getMessageFilters()}
  />
);
