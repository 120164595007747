import { Icon } from "semantic-ui-react";
import "./editorControls.scss";

export const EditorControls = () => {
  return (
    <ul className="editor-controls">
      <li className="control-item">
        <Icon id="code-editor-js" name="code" fitted={true} />
      </li>
    </ul>
  );
};
