import { combineReducers } from "redux";

import { withFetchingChildEntityListItems } from "../../../../Application/reducers/hoc/withFetchingItems";
import withFilteringItems from "../../../../Application/reducers/hoc/withFilteringItems";
import withFilterOptions from "../../../../Application/reducers/hoc/withFilterOptions";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { Role } from "../../../../../interfaces";

const rolesList = withFetchingChildEntityListItems<Role>(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.EditUser,
  ReducerEntityPrefixTypes.Roles,
);

export const editUserRolesReducer = combineReducers({
  rolesList,
  filterState: withFilteringItems(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.EditUser),
  filterOptions: withFilterOptions(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.EditUser),
});
