import midnightService from "../../../../../../Application/services/midnightService/midnightService";
import * as actionTypes from "../actionTypes/threatDefenceSmtpAuthSendingProfileEntityStateActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../../Application/actions/actionsBuilder";
import draftEntityTypes from "../../../../../../../enums/draftEntityTypes";
import {
  ThreatDefenceSmtpAuthSendingProfile,
  ThreatDefenceSmtpAuthSendingProfileSettingsView,
} from "../../types/state";
import { Dispatcher, MidnightActionPayload } from "../../../../../../../interfaces/redux";
import dataService from "../../../../../../Application/services/dataServices/typedDataService";
import {
  setSmtpAuthSendingProfileAction,
  resetSmtpAuthSendingProfileVerificationStatusAction,
  setSmtpAuthSendingProfileIsLoaded,
} from "./threatDefenceSmtpAuthSendingProfileDetailsActions";
import { batch } from "react-redux";
import { beginAsyncOperation } from "../../../../../../Application/slices/asyncOperationSlice";
import {
  SmtpSendingProfileConfigEditSuccess,
  SmtpSendingProfileSettingsEditSuccess,
  SmtpSendingProfilePublishSuccess,
  SmtpSendingProfileLockSuccess,
  SmtpSendingProfileDiscardSuccess,
} from "../../../../../../Application/services/realTimeNotification/events/library/libraryEvents";

const updateBeginAction = getActionBaseProvider(actionTypes.updateThreatDefenceSmtpAuthSendingProfileBegin);
const updateFailureAction = getActionProvider<MidnightActionPayload>(
  actionTypes.updateThreatDefenceSmtpAuthSendingProfileFailure,
);

export const createThreatDefenceSmtpAuthSendingProfile = (data: ThreatDefenceSmtpAuthSendingProfile) => {
  const begin = getActionBaseProvider(actionTypes.createThreatDefenceSmtpAuthSendingProfileBegin);
  const success = getActionProvider<MidnightActionPayload>(
    actionTypes.createThreatDefenceSmtpAuthSendingProfileSuccess,
  );
  const failure = getActionProvider<MidnightActionPayload>(
    actionTypes.createThreatDefenceSmtpAuthSendingProfileFailure,
  );

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await midnightService.createLock(draftEntityTypes.SmtpAuthSendingProfile, data);
      const entityId = result.data as number;
      dispatch(success({ entityId }));
      dispatch(setSmtpAuthSendingProfileIsLoaded());
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const updateThreatDefenceSmtpAuthSendingProfile =
  (data: ThreatDefenceSmtpAuthSendingProfile) => async (dispatch: Dispatcher) => {
    dispatch(updateBeginAction());
    dispatch(beginAsyncOperation({ id: data.id!, action: SmtpSendingProfileConfigEditSuccess }));
    try {
      await dataService.threatDefence.updateThreatDefenceSmtpAuthSendingProfileAsync(data);
      batch(() => {
        dispatch(setSmtpAuthSendingProfileAction(data));
        dispatch(resetSmtpAuthSendingProfileVerificationStatusAction());
      });
    } catch (error: any) {
      dispatch(updateFailureAction({ error }));
    }
  };

export const updateThreatDefenceSmtpAuthSendingProfileSettings =
  (data: ThreatDefenceSmtpAuthSendingProfileSettingsView) => async (dispatch: Dispatcher) => {
    dispatch(updateBeginAction());
    dispatch(beginAsyncOperation({ id: data.id, action: SmtpSendingProfileSettingsEditSuccess }));
    try {
      await dataService.threatDefence.updateThreatDefenceSmtpAuthSendingProfileSettingsAsync(data);
      dispatch(resetSmtpAuthSendingProfileVerificationStatusAction());
    } catch (error: any) {
      dispatch(updateFailureAction({ error }));
    }
  };

export const threatDefenceSmtpAuthSendingProfileUpdated = getActionProvider(
  actionTypes.threatDefenceSmtpAuthSendingProfileUpdated,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const updateThreatDefenceSmtpAuthSendingProfileCommandSuccess = getActionProvider(
  actionTypes.updateThreatDefenceSmtpAuthSendingProfileCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const resetThreatDefenceSmtpAuthSendingProfileEntityState = getActionBaseProvider(
  actionTypes.resetThreatDefenceSmtpAuthSendingProfileEntityState,
);

export const publishDraftThreatDefenceSmtpAuthSendingProfileEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.publishThreatDefenceSmtpAuthSendingProfileBegin);
  const failure = getActionProvider<MidnightActionPayload>(
    actionTypes.publishThreatDefenceSmtpAuthSendingProfileFailure,
  );

  return async (dispatch: Function) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: SmtpSendingProfilePublishSuccess }));
    try {
      await midnightService.releaseLock(draftEntityTypes.SmtpAuthSendingProfile, id);
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const publishDraftThreatDefenceSmtpAuthSendingProfileSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.publishThreatDefenceSmtpAuthSendingProfileSuccess,
);

export const fetchDraftSendingProfileEntity = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.fetchLockedSendingProfileBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedSendingProfileFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    dispatch(beginAsyncOperation({ id, action: SmtpSendingProfileLockSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.SmtpAuthSendingProfile, id);
      dispatch(resetSmtpAuthSendingProfileVerificationStatusAction());
    } catch (error: any) {
      dispatch(failure({ error }));
    }
  };
};

export const fetchLockedSendingProfileSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchLockedSendingProfileSuccess,
);

export const discardSendingProfileEntity = (id: number) => async (dispatch: Function) => {
  const begin = getActionBaseProvider(actionTypes.discardSendingProfileBegin);
  const failure = getActionProvider<MidnightActionPayload>(actionTypes.discardSendingProfileFailure);

  dispatch(begin());
  dispatch(beginAsyncOperation({ id, action: SmtpSendingProfileDiscardSuccess }));
  try {
    await midnightService.discardLock(draftEntityTypes.SmtpAuthSendingProfile, id);
  } catch (error: any) {
    dispatch(failure({ error }));
  }
};

export const discardSendingProfileSuccess = getActionProvider<MidnightActionPayload>(
  actionTypes.discardSendingProfileSuccess,
);

export const setSendingProfileEntityIdAction = getActionProvider<number>(actionTypes.setSendingProfileEntityId);
