import React, { Component } from "react";
import { Pagination } from "semantic-ui-react";
import PropTypes from "prop-types";

import { DropdownWrapper } from "../../../dropdowns";

import "./footer.scss";

export default class Footer extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      (this.props.isLoading && !nextProps.isLoading) ||
      this.props.totalPages !== nextProps.totalPages ||
      this.props.itemsAmount !== nextProps.itemsAmount
    );
  }

  onPageChange = (_, { activePage }) => {
    this.props.onPageChange(activePage);
  };

  renderResultsRangeLabel = () => {
    const { itemsAmount, itemsPageRange } = this.props;

    if (itemsAmount && itemsPageRange) {
      return (
        <span className="range">{`${itemsPageRange.from.toLocaleString()}-${itemsPageRange.to.toLocaleString()} of ${itemsAmount.toLocaleString()}`}</span>
      );
    }
    return null;
  };

  render() {
    const { itemsPerPageAmount, itemsPerPageOptions, activePage, onItemsPerPageChange, totalPages } = this.props;

    let disableLeftButtons = activePage === 1 || totalPages === 0;
    let disableRightButtons = activePage === totalPages || totalPages === 0;

    return (
      <div className="grid-footer">
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={this.onPageChange}
          prevItem={{
            ...Pagination.defaultProps.prevItem,
            disabled: disableLeftButtons,
          }}
          firstItem={{
            ...Pagination.defaultProps.firstItem,
            disabled: disableLeftButtons,
          }}
          nextItem={{
            ...Pagination.defaultProps.nextItem,
            disabled: disableRightButtons,
          }}
          lastItem={{
            ...Pagination.defaultProps.lastItem,
            disabled: disableRightButtons,
          }}
        />
        {this.renderResultsRangeLabel()}
        <span className="results-per-page">
          Results per page
          <DropdownWrapper
            className="items-per-page"
            selected={itemsPerPageAmount}
            items={itemsPerPageOptions}
            handleOptionChange={onItemsPerPageChange}
          />
        </span>
      </div>
    );
  }
}

Footer.propTypes = {
  itemsPerPageAmount: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  itemsAmount: PropTypes.number,
  itemsPageRange: PropTypes.object,
  totalPages: PropTypes.number.isRequired,
};
