import Validator from "./Validator";
import TaskStatuses from "../enums/taskStatuses";
import arrayUtils from "./arrayUtils";

interface Task {
  [id: string]: {
    status: number;
    item: any;
  };
}

export interface ConfigParams {
  concurrentItemsCount: number;
  runningTasksCount: number;
  tasks: Task;
  onTaskQueued: (task: { id: string; status: number | undefined }, item: any) => void;
  onTaskCanceled: (taskId: string) => void;
  taskAction: (id: string, item: any) => any;
}

let configuration: ConfigParams | null = null;

const initValidationSchema = {
  concurrentItemsCount: Validator.create().isPositiveInteger(),
  taskAction: Validator.create().isFunction(),
};

const pushValidationSchema = {
  id: Validator.create().isNotNullOrUndefined(),
  item: Validator.create().isNotNullOrUndefined(),
};

const run = async (config: ConfigParams) => {
  const { concurrentItemsCount, runningTasksCount } = config;
  let availableCount = concurrentItemsCount - runningTasksCount;
  if (availableCount <= 0) {
    return;
  }

  const keys = Object.keys(config.tasks);

  for (let index = 0; index < keys.length && availableCount > 0; ++index) {
    let id = keys[index];
    let task = config.tasks[id];
    if (task.status === TaskStatuses.queued) {
      task.status = TaskStatuses.inProgress;
      --availableCount;
      ++config.runningTasksCount;
      config
        .taskAction(id, task.item)
        .then(() => handleTaskCompletion(id, config))
        .catch(() => handleTaskCompletion(id, config));
    }
  }
};

const handleTaskCompletion = (id: string, config: ConfigParams) => {
  delete config.tasks[id];
  --config.runningTasksCount;
  run(config);
};

const getRunningTasksCount = (tasks: Task) => {
  return arrayUtils.count(Object.values(tasks), (item) => item.status === TaskStatuses.inProgress);
};

const onTaskQueued = (config: ConfigParams, id: string, item: any) => {
  config.onTaskQueued && config.onTaskQueued({ id, status: TaskStatuses.queued }, item);
};

const taskPool = {
  init: (config: ConfigParams) => {
    Validator.validateSchemaAndThrow(initValidationSchema, config);

    if (configuration) {
      configuration.tasks = Object.assign(configuration.tasks, config.tasks);
    } else {
      configuration = config;
    }

    if (!configuration.tasks) {
      configuration.tasks = {};
    }

    configuration.runningTasksCount = getRunningTasksCount(configuration.tasks);
    run(configuration);
  },

  push(id: string, item: any) {
    Validator.validateSchemaAndThrow(pushValidationSchema, { id, item });
    if (!configuration) {
      return;
    }

    const existingTask = configuration.tasks[id];
    if (existingTask) {
      throw new Error("Task with the same id already exists");
    }

    configuration.tasks[id] = {
      item,
      status: TaskStatuses.queued,
    };

    onTaskQueued(configuration, id, item);

    run(configuration);
  },

  cancelTask(taskId: string) {
    if (!configuration) {
      return;
    }

    const task = configuration.tasks[taskId];
    if (task && task.status === TaskStatuses.queued) {
      delete configuration.tasks[taskId];
      configuration.onTaskCanceled && configuration.onTaskCanceled(taskId);
    }
  },

  runningTasksCount() {
    if (!configuration) {
      return;
    }
    return getRunningTasksCount(configuration.tasks);
  },
};

export default taskPool;
