import React from "react";
import NoResults from "../../../components/noResults/NoResults";
import NoSearchResults from "../../../components/noSearchResults";

export interface AccountsNoResultsProps {
  filtered: boolean;
  isMobo: boolean;
  renderCreateAccountButton?(): React.ReactNode;
}

const renderNoResults = (props: AccountsNoResultsProps) => {
  let noResultDescription = "Looks like you don't have any accounts yet.";
  let noResultsContent = null;

  if (!props.isMobo) {
    noResultDescription += " Create some now using the button below.";
    noResultsContent = props.renderCreateAccountButton?.();
  }

  return (
    <NoResults title="You don't have any accounts." description={noResultDescription} iconClassName="building">
      {noResultsContent}
    </NoResults>
  );
};

const AccountsNoResults: React.FC<AccountsNoResultsProps> = (props) =>
  props.filtered ? <NoSearchResults /> : renderNoResults(props);

export default AccountsNoResults;
