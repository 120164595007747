import axios, { Canceler } from "axios";
import { SasConfig } from "../../../../interfaces";

const service = {
  createSas: (config: SasConfig, acceptCancelationHandler: (c: { abort: Canceler }) => void) => {
    return axios.post(config.endpointToGetSasLink, config.sasRequest, {
      cancelToken: new axios.CancelToken((c) => acceptCancelationHandler && acceptCancelationHandler({ abort: c })),
    });
  },
};

export default service;
