import React, { FC } from "react";

import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

import "./addLabelsLinkButton.scss";

export interface AddLabelsLinkButtonProps {
  isDisabled?: boolean;
  onClick?: () => void;
  after?: React.ReactNode;
}

export const AddLabelsLinkButton: FC<AddLabelsLinkButtonProps> = ({ isDisabled, onClick, after }) => {
  return (
    <div className="add-label-link-button">
      <BasicLinkButton text="Add Labels" iconClassName="fal fa-tags" onClick={onClick} isDisabled={isDisabled} />
      {after}
    </div>
  );
};
