enum ModalTypes {
  PublishVideoAsset = "PublishVideoAsset",
  DeleteVideoAsset = "DeleteVideoAsset",
  RevertVideoAsset = "RevertVideoAsset",
  RevertCollection = "RevertCollection",
  RevertFlow = "RevertFlow",
  RevertEmail = "RevertEmail",
  RevertAssessment = "RevertAssessment",
  RevertEmailTemplate = "RevertEmailTemplate",
  RevertLandingPage = "RevertLandingPage",
  RevertCampaign = "RevertCampaign",
  RevertSendingProfile = "RevertSendingProfile",
  RevertSmtpAuthSendingProfile = "RevertSmtpAuthSendingProfile",
  RevertFeedbackPage = "RevertFeedbackPage",
  SendEmail = "SendEmail",
  CancelEmailSend = "CancelEmailSend",
  RevertPack = "RevertPack",
  RevertEvent = "RevertEvent",
  RevertSurvey = "RevertSurvey",
  RevertPdf = "RevertPdf",
  DeletePack = "DeletePack",
  RevertMessage = "RevertMessage",
  PublishPack = "PublishPack",
}

export default ModalTypes;
