import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { RequestState, RStatus } from "../../../../Application/globaltypes/fetchRequest";
import { RootState } from "../../../../Application/globaltypes/redux";
import {
  AssessmentPeopleDetails,
  AssessmentQuestionPeopleResponse,
  CardsData,
  EngagementFunnelData,
  EngagementLineData,
  EngagementQuestionsData,
  QuestionResponsesData,
} from "../../types/state";
import { cloneDeep } from "lodash";
import { normalizeDateString } from "features/Library/Common/utils/performanceUtils";

const namespace = ReducerNamespaceTypes.Assessments;
const entityPrefix = ReducerEntityPrefixTypes.Assessments_Performance;

const initialRequestState: RequestState<any> = {
  status: RStatus.Idle,
  value: {},
  errorMessage: undefined,
};

const initialRequestStateList: RequestState<any> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

export interface AssessmentPerformanceState {
  cardData: RequestState<CardsData>;
  engagementLineData: RequestState<EngagementLineData>;
  engagementFunnelData: RequestState<EngagementFunnelData>;
  engagementQuestionsData: RequestState<EngagementQuestionsData[]>;
  questionResponsesData: RequestState<QuestionResponsesData>;
  questionResponsePeople: RequestState<AssessmentQuestionPeopleResponse>;
  assessmentPeopleDetails: RequestState<AssessmentPeopleDetails[]>;
}

export const initialState: AssessmentPerformanceState = {
  cardData: initialRequestState,
  engagementLineData: initialRequestStateList,
  engagementFunnelData: initialRequestState,
  engagementQuestionsData: initialRequestStateList,
  questionResponsesData: initialRequestState,
  questionResponsePeople: initialRequestState,
  assessmentPeopleDetails: initialRequestStateList,
};

const assessmentPerformanceSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    reqCardData(state) {
      state.cardData = {
        ...state.cardData,
        status: RStatus.Pending,
      };
    },
    reqQuestionResponsesData(state) {
      state.questionResponsesData = {
        ...state.questionResponsesData,
        status: RStatus.Pending,
      };
    },
    reqQuestionResponsesPeople(state) {
      state.questionResponsePeople = {
        ...state.questionResponsePeople,
        status: RStatus.Pending,
      };
    },
    reqEngagementLineData(state) {
      state.engagementLineData = {
        ...state.engagementLineData,
        status: RStatus.Pending,
      };
    },
    reqEngagementFunnelData(state) {
      state.engagementFunnelData = {
        ...state.engagementFunnelData,
        status: RStatus.Pending,
      };
    },
    reqEngagementQuestionsData(state) {
      state.engagementQuestionsData = {
        ...state.engagementQuestionsData,
        status: RStatus.Pending,
      };
    },
    reqPeopleDetails(state) {
      state.assessmentPeopleDetails = {
        ...state.assessmentPeopleDetails,
        status: RStatus.Pending,
      };
    },
    setCardData(state, action: PayloadAction<{ data: AssessmentPerformanceState["cardData"]["value"]; }>) {
      state.cardData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setQuestionResponsesData(state, action: PayloadAction<{ data: QuestionResponsesData; }>) {
      state.questionResponsesData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setQuestionResponsesPeople(state, action: PayloadAction<{ data: any; }>) {
      state.questionResponsePeople = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementLineData(state, action: PayloadAction<{ data: EngagementLineData; }>) {
      state.engagementLineData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementFunnelData(state, action: PayloadAction<{ data: EngagementFunnelData; }>) {
      state.engagementFunnelData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementQuestionsData(state, action: PayloadAction<{ data: EngagementQuestionsData[]; }>) {
      state.engagementQuestionsData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setPeopleDetails(state: AssessmentPerformanceState, action: PayloadAction<{ data: AssessmentPeopleDetails[]; }>) {
      state.assessmentPeopleDetails = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setCardError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.cardData = {
        ...state.cardData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setQuestionResponsesError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.questionResponsesData = {
        ...state.questionResponsesData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setQuestionResponsesPeopleError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.questionResponsePeople = {
        ...state.questionResponsePeople,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setEngagementError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.engagementLineData = {
        ...state.engagementLineData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setEngagementFunnelError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.engagementFunnelData = {
        ...state.engagementFunnelData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setEngagementQuestionsError(
      state,
      action: PayloadAction<{
        errorMessage: string;
      }>,
    ) {
      state.engagementQuestionsData = {
        ...state.engagementQuestionsData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setPeopleDetailsError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.assessmentPeopleDetails = {
        ...state.assessmentPeopleDetails,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return {
        ...initialState,
      };
    },
    resetDrilldownState(state) {
      state.questionResponsesData = initialRequestState;
      state.questionResponsePeople = initialRequestState;
    },
    resetAnswerData(state) {
      state.questionResponsePeople = initialRequestState;
    },
  },
});

// Actions
export const {
  reqCardData,
  reqQuestionResponsesData,
  reqQuestionResponsesPeople,
  reqEngagementLineData,
  reqEngagementFunnelData,
  reqEngagementQuestionsData,
  reqPeopleDetails,
  setCardData,
  setQuestionResponsesData,
  setQuestionResponsesPeople,
  setEngagementLineData,
  setEngagementFunnelData,
  setEngagementQuestionsData,
  setPeopleDetails,
  setCardError,
  setQuestionResponsesError,
  setQuestionResponsesPeopleError,
  setEngagementError,
  setEngagementFunnelError,
  setEngagementQuestionsError,
  setPeopleDetailsError,
  reset,
  resetDrilldownState,
  resetAnswerData,
} = assessmentPerformanceSlice.actions;

// Selectors
export const selectCardData = (state: RootState) => state.library.assessments.base.performance.cardData;
export const selectEngagementLineData = (state: RootState) =>
  state.library.assessments.base.performance.engagementLineData;
export const selectEngagementFunnelData = (state: RootState) =>
  state.library.assessments.base.performance.engagementFunnelData;
export const selectEngagementQuestionsData = (state: RootState) =>
  state.library.assessments.base.performance.engagementQuestionsData;
export const selectQuestionResponsesData = (state: RootState) =>
  state.library.assessments.base.performance.questionResponsesData;
export const selectQuestionResponsePeople = (state: RootState) =>
  state.library.assessments.base.performance.questionResponsePeople;
export const selectPeopleDetails = (state: RootState) =>
  state.library.assessments.base.performance.assessmentPeopleDetails;

// Cards
export const selectCardsStatus = createSelector(selectCardData, (cardData) => cardData.status);
export const selectCardsValue = createSelector(selectCardData, (cardData) => cardData.value);
export const selectCardsError = createSelector(selectCardData, (cardData) => cardData.errorMessage);

// Question Responses
export const selectQuestionResponsesStatus = createSelector(
  selectQuestionResponsesData,
  (questionResponses) => questionResponses.status,
);
export const selectQuestionResponsesValue = createSelector(
  selectQuestionResponsesData,
  (questionResponses) => questionResponses.value,
);
export const selectQuestionResponsesError = createSelector(
  selectQuestionResponsesData,
  (questionResponses) => questionResponses.errorMessage,
);

export const selectQuestionResponsesPeopleStatus = createSelector(selectQuestionResponsePeople, (req) => req.status);
export const selectQuestionResponsesPeopleValue = createSelector(selectQuestionResponsePeople, (req) => req.value);
export const selectQuestionResponses = createSelector(selectQuestionResponsePeople, (req) => req.value.People);
export const selectQuestionResponsesPeopleError = createSelector(
  selectQuestionResponsePeople,
  (req) => req.errorMessage,
);

// Line Chart
export const selectEngagementDates = createSelector(selectEngagementLineData, (engagementLineData) =>
  engagementLineData.value.map((activity) => normalizeDateString(activity.Date)),
);

export const selectEngagementInProgress = createSelector(selectEngagementLineData, (engagementLineData) => {
  return engagementLineData.value.map((activity) => activity.Started);
});

export const selectEngagementCompleted = createSelector(selectEngagementLineData, (engagementLineData) => {
  return engagementLineData.value.map((activity) => activity.Completed);
});

export const selectEngagementCumulativeCompleted = createSelector(selectEngagementLineData, (engagementLineData) => {
  return engagementLineData.value.map((activity) => activity.CumulativeCompleted);
});

export const selectEngagementCumulativeInProgress = createSelector(selectEngagementLineData, (engagementLineData) => {
  return engagementLineData.value.map((activity) => activity.CumulativeStarted);
});

export const selectEngagementLineStatus = createSelector(
  selectEngagementLineData,
  (engagementLineData) => engagementLineData.status,
);

export const selectEngagementLineValue = createSelector(
  selectEngagementLineData,
  (engagementLineData) => engagementLineData.value,
);

export const selectEngagementLineError = createSelector(
  selectEngagementLineData,
  (engagementLineData) => engagementLineData.errorMessage,
);

// Funnel
export const selectEngagementFunnelStatus = createSelector(
  selectEngagementFunnelData,
  (engagementFunnelData) => engagementFunnelData.status,
);
export const selectEngagementFunnelValue = createSelector(selectEngagementFunnelData, (engagementFunnelData) => {
  return {
    Completed: engagementFunnelData.value.Completed,
    Started: engagementFunnelData.value.Started,
  };
});
export const selectEngagementFunnelError = createSelector(
  selectEngagementFunnelData,
  (engagementFunnelData) => engagementFunnelData.errorMessage,
);

// Questions
export const selectEngagementQuestionsStatus = createSelector(
  selectEngagementQuestionsData,
  (engagementQuestionsData) => engagementQuestionsData.status,
);

export const selectEngagementQuestionsValue = createSelector(
  selectEngagementQuestionsData,
  (engagementQuestionsData) => engagementQuestionsData.value,
);

export const selectEngagementQuestionsError = createSelector(
  selectEngagementQuestionsData,
  (engagementQuestionsData) => engagementQuestionsData.errorMessage,
);

// People table
export const selectPeopleDetailsStatus = createSelector(selectPeopleDetails, (peopleDetails) => peopleDetails.status);
export const selectPeopleDetailsValue = createSelector(selectPeopleDetails, (peopleDetails) => {
  const sortAndFilterUnique = (arr: AssessmentPeopleDetails[], track = new Set()) => {
    const sortedArray = cloneDeep(arr);
    sortedArray.sort((a, b) => Date.parse(b.CompletionDate) - Date.parse(a.CompletionDate));
    return sortedArray.filter(({ UserId }) => (track.has(UserId) ? false : track.add(UserId)));
  };
  return sortAndFilterUnique(peopleDetails.value);
});
export const selectPeopleDetailsError = createSelector(
  selectPeopleDetails,
  (peopleDetails) => peopleDetails.errorMessage,
);

export default assessmentPerformanceSlice.reducer;
