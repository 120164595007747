import { DropdownProps, Icon } from "semantic-ui-react";

import Dropdown from "../../../../Common/Fields/Dropdown";

import { ValidatedForm } from "../../../../../../components";
import { Tooltip } from "../../../../../../components/common/tooltip";
import { ValidationThrottler } from "../../../../Common/Fields/ValidationThrottler";
import { WithFormikPayload } from "../../../../Common/Fields/WithFormikPayload";
import { IQuestion, QuestionTypeOptions } from "../../../../Common/models";
import { QuestionHeaderPropsBase } from "../../../../Common/props";
import { AssessmentQuestionType, MultipleChoiceQuestion } from "../../../types/state";
import { MapQuestion } from "../Providers/QuestionMapper";

export type AssessmentQuestionHeaderProps = QuestionHeaderPropsBase<IQuestion<any>>;

export const QuestionHeaderBuilder = (props: AssessmentQuestionHeaderProps) => {
  const { question, validatedFieldProps, index, onChanged, disabled, onQuestionTypeChanged, propertyNamePrefix } =
    props;

  const questionTypeOptions: Array<QuestionTypeOptions> = [
    { text: "Multiple Choice Text - Multi-Select", value: AssessmentQuestionType.MultipleChoiceTextQuestion },
    { text: "Multiple Choice Text - Single-Select", value: AssessmentQuestionType.SingleChoiceTextQuestion },
  ];

  const handleTypeDropdownChange = (_: any, data: DropdownProps) => {
    const value = data.value as AssessmentQuestionType;
    const mapResult = MapQuestion(value, question);

    onQuestionTypeChanged(mapResult.question);
    onChanged();
  };

  return (
    <>
      <div className="question-type-block">
        <div className="dropdown-field">
          <WithFormikPayload
            propertyName={`${propertyNamePrefix}questionType`}
            pathToValidate={`questions.${index}`}
            {...validatedFieldProps}
            selfValidation={true}
            render={(formikProps) => (
              <ValidationThrottler {...formikProps} value={question.questionType}>
                <Dropdown
                  label="Question type"
                  value={question.questionType}
                  options={questionTypeOptions}
                  errorPosition={"bottom"}
                  disabled={disabled}
                  setFieldTouched={validatedFieldProps.setFieldTouched}
                  setFieldValue={validatedFieldProps.setFieldValue}
                  onChange={handleTypeDropdownChange}
                  {...formikProps}
                  disableValidation={true}
                />
              </ValidationThrottler>
            )}
          />
        </div>
        {question.questionType === AssessmentQuestionType.MultipleChoiceTextQuestion && (
          <div className="checkbox-field">
            <ValidatedForm.CheckboxField
              {...validatedFieldProps}
              toggle
              label={"Require all correct answers to be selected"}
              value={(question as IQuestion<MultipleChoiceQuestion>).question.requireAllCorrectAnswers}
              propertyName={`${propertyNamePrefix}question.requireAllCorrectAnswers`}
              disabled={disabled}
              handleChange={onChanged}
            />

            <Tooltip
              target={<Icon className="info circle" />}
              content={"Be sure to select ALL correct answers."}
              position="top center"
              styles={{ width: `${20}rem` }}
              hideOnScroll
              showAlways
            />
          </div>
        )}
      </div>
    </>
  );
};
