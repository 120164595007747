import { FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../Application/globaltypes/redux";
import PackForm from "../PackForm/PackForm";
import { Pack, PackView } from "../../types/state";
import * as packEntityStateActions from "../../state/actions/packEntityStateActions";
import * as packDetailsActions from "../../state/actions/packDetailsActions";
import { fetchPackTypes } from "../../state/thunks/packTypesThunk";
import { fetchPackFilters } from "../../state/thunks/packFiltersThunk";
import { packTypesSelector } from "../../state/slices/packTypesSlice";
import { packFiltersSelector } from "../../state/slices/packFiltersSlice";
import { ConfigurePackProps } from "../types";
import validationSchemas from "../../../../../utils/validationSchemas";
import schemasUtils from "../../../../../utils/validationSchemasUtils";
import { AutosaveProps, withAutosave } from "../../../../../utils/withAutosave";
import { packTrialPeriodsSelector } from "../../state/slices/packTrialPeriodsSlice";
import { fetchPackTrialPeriods } from "../../state/thunks/packTrialPriodsThunk";
import { DEFAULT_PRIVATE_PACK_DESCRIPTION } from "../../types/constants";

export type ConfigureAllProps = ConfigurePackProps & PropsFromRedux & FormikProps<PackView> & AutosaveProps;

export class Configure extends Component<ConfigureAllProps> {
  isPackCreated = () => this.props.id > 0;

  componentDidMount() {
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });

    this.props.fetchPackFilters();
    this.props.fetchPackTypes();
    this.props.fetchPackTrialPeriods();

    //Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  onNext = () => {
    this.props.isValid && this.savePack();
    if (!this.isPackCreated()) {
      this.props.entityStateActions.createPack(Configure.getPack(this.props.values, this.props.isPrivatePack));
    }
  };

  onPrevious = () => {
    this.savePack();
  };

  onClose = () => {
    this.props.detailsActions.clearPack();
    this.props.entityStateActions.resetPackEntityState();
  };

  savePack = () => {
    this.props.detailsActions.savePack(this.props.values);
  };

  static getPack = (values: PackView, isPrivatePack: boolean): Pack => {
    return {
      id: values.id,
      title: values.title.trim(),
      thumbnailImageUrl: values.thumbnailImageUrl.trim(),
      description: values.description.trim(),
      mediaItems: values.mediaItems.filter((mi) => !!mi.url).map((mi) => ({ ...mi, url: mi.url?.trim() })),
      overviewVideoUrl: values.overviewVideoUrl.trim(),
      longDescription: isPrivatePack && !values.longDescription.trim() ? DEFAULT_PRIVATE_PACK_DESCRIPTION : values.longDescription.trim(),
      packFilters: values.packFilters,
      isDraft: values.isDraft,
      hasBeenPublished: values.hasBeenPublished,
      typeId: values.typeId,
      isTrialAllowed: values.isTrialAllowed,
      trialPeriodId: values.trialPeriodId,
    };
  };

  render() {
    return <PackForm disablePreventTransitionPrompt={this.isPackCreated()} {...this.props} />;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigurePackProps) => ({
  id: ownProps.entityId || state.packs.packEntityStateReducer.entityId,
  pack: state.packs.packDetailsReducer.pack,
  isLoading: state.packs.packDetailsReducer.isLoading,
  isLoaded: state.packs.packDetailsReducer.isPackLoaded,
  packFilters: packFiltersSelector(state),
  isCreating: state.packs.packEntityStateReducer.changingEntityState,
  isDraft: state.packs.packDetailsReducer.pack.isDraft,
  packTypes: packTypesSelector(state),
  packTrialPeriods: packTrialPeriodsSelector(state),
  accountId: state.userProfile.accountId,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(packEntityStateActions, dispatch),
  detailsActions: bindActionCreators(packDetailsActions, dispatch),
  fetchPackFilters: bindActionCreators(fetchPackFilters, dispatch),
  fetchPackTypes: bindActionCreators(fetchPackTypes, dispatch),
  fetchPackTrialPeriods: bindActionCreators(fetchPackTrialPeriods, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigureAllProps, PackView, Pack>({
  getInitValues: (props) => Configure.getPack(props.pack, props.isPrivatePack),
  stateProvider: (values, props) => Configure.getPack(values, props.isPrivatePack),
  entityUpdater: (props) => props.entityStateActions.updatePack,
  isValid: (props) => props.isDraft && props.isValid && !props.isValidating && props.id > 0,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: (props: ConfigurePackProps & PropsFromRedux) =>
    validationSchemas.packConfigure(
      props.pack.title,
      props.isPrivatePack ? DEFAULT_PRIVATE_PACK_DESCRIPTION : undefined,
    ),
  isInitialValid: (props: ConfigurePackProps & PropsFromRedux) =>
    schemasUtils.isValidSync(
      validationSchemas.packConfigure(
        props.pack.title,
        props.isPrivatePack ? DEFAULT_PRIVATE_PACK_DESCRIPTION : undefined,
      ),
      props.pack,
    ),
  mapPropsToValues: (props: ConfigurePackProps & PropsFromRedux) => props.pack,
  validateOnBlur: false,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connect(mapStateToProps, mapDispatchToProps)(component);
