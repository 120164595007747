import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import cn from "classnames";

const renderMenuContent = function (label) {
  return (
    <span className="label">
      <TextTruncate lines={2}>{label}</TextTruncate>
    </span>
  );
};
const MenuItem = function (props) {
  const { label, path, className, onClick, hideNavigation } = props;

  if (onClick) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a className={className} onClick={onClick}>
        {renderMenuContent(label)}
      </a>
    );
  }

  return (
    <NavLink
      onClick={hideNavigation}
      to={path}
      className={({ isActive }) => cn(className, { "active-route": isActive })}
    >
      {renderMenuContent(label)}
    </NavLink>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  hideNavigation: PropTypes.func,
};

export default MenuItem;
