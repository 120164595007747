import { connect, ConnectedProps } from "react-redux";
import cn from "classnames";
import { useMemo } from "react";

import { RootState } from "../../../../../../features/Application/globaltypes/redux";
import { pluralize } from "../../../../../../utils/stringUtils";

import { PeopleVideoCard } from "../../../../../cards/VideoCard/People/PeopleVideoCard";
import { VideoCardBaseProps } from "../../../../../cards/VideoCard/types";
import { PeopleAvailableVideo, WithPeopleContext } from "../../../../../../features/People/ContentAssignments/models";
import Icon from "../../../../../../interfaces/icon";

import "./addToVideoCard.scss";

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AddToVideoCardProps = PropsFromRedux & VideoCardBaseProps<WithPeopleContext<PeopleAvailableVideo>>;

export const AddToVideoCard = (props: AddToVideoCardProps) => {
  const { item, selectedPeopleType, selected, onSelect, disabled } = props;
  const { assignsCount, usersCount } = item;

  const someAssigned = assignsCount > 0;
  const allAssigned = someAssigned && assignsCount === usersCount;

  const icons: Icon[] = useMemo(() => {
    if (!someAssigned) {
      return [];
    }

    let tooltipMessage = "";

    if (assignsCount === usersCount) {
      tooltipMessage = `This ${pluralize(selectedPeopleType, usersCount)} already ${
        usersCount > 1 ? "belong" : "belongs"
      } to this asset.`;
    } else {
      tooltipMessage = `(${assignsCount}) of the ${pluralize(
        selectedPeopleType,
        usersCount,
      )} selected already belong to this asset and will be skipped.`;
    }

    return [
      {
        iconClassName: "fa fa-exclamation-circle",
        tooltipContent: tooltipMessage,
        tooltipClassName: "warning",
      },
    ];
  }, [assignsCount, selectedPeopleType, someAssigned, usersCount]);

  return (
    <PeopleVideoCard
      onSelect={onSelect}
      selected={selected}
      item={item}
      icons={icons}
      disabled={allAssigned || disabled}
      className={cn({ warning: someAssigned })}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { addToContentModal } = state.people;

  return {
    selectedPeopleType: addToContentModal.selectedPeopleType,
  };
};

const connector = connect(mapStateToProps, {});

export default connector(AddToVideoCard);
