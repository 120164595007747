import React, { FC, useEffect, useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { IModalManager } from "../../interfaces/IModalManager";
import { PropertyChangedEvent } from "../../interfaces/functionTypes/PropertyChangedEvent";

export interface Props {
  className?: string;
  title: string;
  message: string | object;
  modalManager: IModalManager;
  confirmButtonName?: string;
  cancelButtonName?: string;
  checkboxIsHidden?: boolean;
}

const SuppressibleConfirmationModalTs: FC<Props> = ({ modalManager, ...props }: Props) => {
  const [open, setOpen] = useState(modalManager.isVisible);

  useEffect(() => {
    const onIsValidChanged: PropertyChangedEvent<boolean> = (_, isVisible: boolean) => setOpen(isVisible);
    modalManager.subscribeOnIsVisibleChanged(onIsValidChanged);
    return () => modalManager.unsubscribeOnIsVisibleChanged(onIsValidChanged);
  }, [modalManager]);

  const form = (
    <ConfirmationModal
      key={modalManager.modalType}
      onConfirm={modalManager.onConfirm}
      onSuppressPopupStatusChanged={modalManager.onSuppressStatusChanged}
      onClose={modalManager.onCancel}
      {...props}
      open={open}
    />
  );

  return modalManager.renderForm(form);
};

export default SuppressibleConfirmationModalTs;
