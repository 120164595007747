import * as Yup from "yup";
import { requiredTextFieldMinMax } from "../validationSchemas/commonValidationSchemas";

import { labels, softwareApplications } from "./tagsValidationSchema";
import awesomeDebouncePromise from "awesome-debounce-promise";
import dataService from "../../features/Library/SimulatedPhishing/Campaigns/services/threatDefenceCampaignsDataService";
import { CUSTOM_EMAIL } from "../emailUtils";

const debounceTimeMs = 500;

const isTitleUnique = awesomeDebouncePromise(
  async (name, id) => !(await dataService.titleExists(name, id)),
  debounceTimeMs,
);

const title = (titleInitialValue?: string) =>
  requiredTextFieldMinMax("Title", 3, 64).test("unique-title", "Campaign Title should be unique", (value, context) => {
    if (value && value !== titleInitialValue) {
      return isTitleUnique(value, context.parent.id);
    }
    return true;
  });

export const campaignConfigure = (titleInitialValue?: string) =>
  Yup.object().shape({
    name: title(titleInitialValue),
    senderName: requiredTextFieldMinMax("Sender Name", 1, 64),
    senderEmail: requiredTextFieldMinMax("Sender Email", 3, 256).matches(CUSTOM_EMAIL, "Invalid Sender Email"),
    campaignTypeId: Yup.number().positive("Select Campaign Type").required(),
    labels,
    softwareApplications,
  });

export const campaignContent = Yup.object().shape({
  landingPageId: Yup.number().positive("Select Landing Page").required(),
  emailTemplateId: Yup.number().positive("Select Email Template").required(),
});
