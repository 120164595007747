import { EmailsFilterEnum } from "features/Library/Communications/Emails/types/models";
import { FlowFiltersEnum, FlowGoals } from "features/Library/Flows/types/models";
import { PdfFiltersEnum } from "features/Library/Pdfs/types/models";
import { Filters } from "utils/queryUtils";
import { AddPeopleToContentTypes, AssignmentPeopleContext, SortingDirection } from "../../enums";
import { Access, Awareness, Enrollment, Visibility } from "../../enums/groups";
import { BaseGetOptions, BasePagingOptions, BaseParamsApi, ConfigApi, PriorityLevel } from "../../interfaces";
import { WithId } from "../../types";
import { FiltersMap } from "../../utils/filterUtils";
import { ItemsViewGrid, ItemsViewList } from "../Application/globaltypes/ItemsView";
import { FiltersState } from "../Application/slices/createFiltersSlice";
import { AssessmentFiltersEnum } from "../Library/Assessments/types/state";
import { SurveyFiltersEnum } from "../Library/Surveys/types/models";
import { VideoFiltersEnum } from "../Library/Videos/types/models";
import { ContentAssignmentState } from "./ContentAssignments/state/contentAssignmentsSlice";
import { GroupTemplateState } from "./GroupTemplate/state/reducer";
import { EnrollmentRule } from "./Groups/AddEditGroupForms/AutomaticallyDetect/AutomaticallyDetect";
import { AddGroupState } from "./Groups/AddGroup/state/addGroupReducer";
import { EditGroupState } from "./Groups/EditGroup/editGroupReducer";
import { PeopleGroupsOverviewState } from "./Groups/GroupsOverview/state/groupsOverviewReducer";
import { EnrollmentUsersState } from "./Groups/state/types";
import { RolesOverviewState } from "./RolesOverview/state/rolesOverviewReducer";
import { AddUserState } from "./Users/AddUser/state/addUserReducer";
import { EditUserState } from "./Users/EditUser/state/editUserRootReducer";
import { UserAssetTypes } from "./Users/Performance/types";
import { PeopleUsersOverviewState } from "./Users/UsersOverview/state/usersOverviewReducer";
import { UserCommonState } from "./Users/state/userCommonReducer";

interface GetPeopleContentOptionsBase extends BasePagingOptions {
  filters?: FiltersMap;
  searchTerm?: string;
  sortBy: string;
  sortOrder: SortingDirection;
}

export interface GetPeopleFlowsOptions extends BasePagingOptions {
  filters?: FiltersMap;
  searchTerm?: string;
  orderBy: string;
}

export interface GetPeopleAssetsOptions extends BasePagingOptions {
  filters?: FiltersMap;
  searchTerm?: string;
  sortBy: string;
  sortOrder: string;
}

export interface GetPeopleContentOptions extends BasePagingOptions {
  filters?: FiltersMap;
  searchTerm?: string;
  sortBy?: string;
  sortOrder?: string;
  contentType: string;
}

export interface GetPeopleSurveysOptions extends GetPeopleContentOptionsBase {}

export interface GetPeopleEventsOptions extends GetPeopleContentOptionsBase {}

export interface GetPeoplePdfsOptions extends GetPeopleContentOptionsBase {}

export interface GetPeopleAssessmentsOptions extends GetPeopleContentOptionsBase {
  filters?: FiltersMap;
  searchTerm?: string;
  sortingColumnName?: string;
  sortingDirection?: SortingDirection;
}

export interface GetPeoplePdfsOptions extends GetPeopleContentOptionsBase {}

export interface GetGroupsOptions extends BaseGetOptions {
  owners?: number[];
}

export interface GetGroupEnrollmentUsersPreviewRequest {
  top: number;
  rules: EnrollmentRule[];
}

export interface AddGroupCreationDataPayload {
  flows: ContentPriorityItem;
  videos: ContentPriorityItem;
  userIds: number[];
}

export interface ContentPriorityItem {
  id: number;
  priority: number;
  dueDate: number;
}

export interface PeopleFlow {
  id: number;
  peopleId: number;
  peopleType: string;
  accountId: number;
  flowId: number;
  title?: string;
  ownerId: number;
  description?: string;
  thumbnailUrl?: string;
  objective?: string;
  dateCreated: Date;
  dateModified: Date;
  isDraft: boolean;
  published: boolean;
}

export interface GetGroupsParamsApi extends BaseParamsApi {
  owners?: number[];
}

export interface GetGroupsParamsApiV2 extends BaseParamsApi {
  searchFields?: string[];
  searchValue?: string;
}

export interface GetGroupsConfigApi extends ConfigApi<GetGroupsParamsApi> {}

export interface GetGroupsUsersToAddParamsApi extends BaseParamsApi {
  groupId?: number[];
}

export interface GetGroupsUsersToAddApi extends ConfigApi<GetGroupsUsersToAddParamsApi> {}

export interface GetUsersConfigApi extends BaseParamsApi {
  accountId?: number;
}

export interface GetUsersConfigApiV2 extends GetUsersParamsV2 {
  accountId?: number;
}

export interface GetUsersParamsV2 {
  skip?: number;
  top?: number;
  sortOrder?: string;
  sortBy?: string;
  term?: string;
}

export interface GetUsersParamsApiV2 {
  params: GetUsersParamsV2;
}

export interface GetUserEventsParamsV2 extends GetUsersParamsV2 {
  showPurchased: boolean;
}

export interface GetUserEventsParamsApiV2 {
  params: GetUserEventsParamsV2;
}

export type GetUsersListAction = (
  skip: number,
  top: number,
  orderBy: string,
  filter: FiltersMap,
  search: string,
) => void;

export type GetUsersListActionParams = Parameters<GetUsersListAction>;

export interface GetContentUsersParams {
  entityId: number;
  skip: number;
  top: number;
  sortingColumn: string;
  sortingDirection: SortingDirection;
  filter: FiltersMap;
  term: string;
}

export interface PeopleFlowApi {
  peopleId: number;
  peopleType: string;
  accountId: number;
  flowId: number;
  title?: string;
  ownerId: number;
  description?: string;
  thumbnailUrl?: string;
  objective?: string;
  dateCreated: Date;
  dateModified: Date;
  priorityLevelId: number;
  permissionIssuedDate: Date;
  dueDate?: Date;
  isDraft: boolean;
  published: boolean;
}

export interface IManualUsersItemModel {
  country: string | null;
  createdDate: Date | string;
  department: string;
  email: string;
  firstName: string;
  id: number;
  imageUrl: string;
  lastName: string;
  title: string;
}

export interface IEnrollmentRuleModel {
  [key: string]: any;
}

export interface ICreatedGroupState {
  ownersIds: number[];
  name: string;
  description: string;
  imageUrl: string;
  isAware: boolean;
  isVisibleToAll: boolean | null;
  isOpen: boolean;
  isWithAutoEnroll: boolean;
  enrolmentRules: IEnrollmentRuleModel[];
  groupId: number;
  roleId: number;
}

export interface IEnrollmentUsersFilterOptionsState
  extends ItemsViewList<IEnrollmentUsersFilterModel>,
    IEnrollmentRuleModel {}

export interface IOwnerOptions {
  AccountId: number;
  Email: string;
  Id: number;
  LastName: string;
  UserId: string;
}

export interface IEnrollmentUsersFilterModel {
  country: string | null;
  createdDate: Date | string;
  department: string;
  email: string;
  firstName: string;
  id: number;
  imageUrl: string;
  lastName: string;
  title: string;
}

export interface IFlowAssignmentModelItem extends ContentAssignmentModelItem {
  isEditable: boolean;
  published: boolean;
  hasBeenPublished: boolean;
  canBeDeleted: boolean;
  canRemove: boolean;
  canAutoStart: boolean;
  dateCreated: string;
  dateModified: string;
  isInherited: boolean;
  priorityLevelId: number;
  dueDate: Nullable<string>;
  publisher: string;
  goals?: FlowGoals;
}

export interface IVideoAssignmentModelItem extends ContentAssignmentModelItem {
  canBePublished: boolean;
  canEdit: boolean;
  description: string;
  durationInSeconds: number;
  isDraft: boolean;
  ownerId: number;
  primaryId: number;
  publisher: string;
  type: string;
  manifests: IAssetModelManifest[];
  dateModified: string;
  dateCreated: string;
}

export interface IEventAssignmentModelItem extends ContentAssignmentModelItem {
  isDraft: boolean;
  publisher: string;
  dateCreated: string;
  dateModified: string;
}

export interface IPdfAssignmentModelItem extends ContentAssignmentModelItem {
  isDraft: boolean;
  publisher: string;
  dateCreated: string;
  dateModified: string;
}

export interface AssignmentModelItemBase extends ContentAssignmentModelItem {
  publisher: string;
  isDraft: boolean;
  isPurchased: boolean;
  permissionIssuedDate: string;
}

export interface ContentAssignmentModelItem {
  id: number;
  title: string;
  dateCreated: string | Date;
  dateModified: string | Date;
  expirationDate?: string;
  daysToComplete?: number;
  dueDate?: Nullable<string>;
  inherited?: boolean;
  priorityLevelId?: number;
  thumbnailUrl?: string | null;
  isInherited?: boolean;
}

export interface PeopleEventApi {
  peopleId: number;
  peopleType: string;
  externalEventId: number;
  title?: string;
  ownerId: number;
  description?: string;
  thumbnailUrl?: string;
  objective?: string;
  dateCreated: Date;
  dateModified: Date;
  priorityLevelId: number;
  permissionIssuedDate: Date;
  dueDate?: Date;
  isDraft: boolean;
}

export interface PeopleEvent extends PeopleEventApi {
  id: number;
}

export interface IAssetModelManifest {
  type: string;
  uri: string;
}

export interface ICreateUserModel {
  lastName: string;
  firstName: string;
  email: string;
  department?: string;
  jobTitle?: string;
  country?: string;
  password: string;
}

export interface IUserModel extends ICreateUserModel {
  id: number;
}

export interface ConfirmationBlock {
  id: number;
  name: string;
  licensesAmount: number;
  usedLicensesAmount: number;
  issuing: number;
}

export interface ConfirmationState {
  blocks: ConfirmationBlock[];
  priorityItems: PriorityItem[];
  show: boolean;
  proceed?: (value: boolean) => void;
}

export interface PriorityItem extends PriorityLevel {
  blockType: string;
  durationInSeconds?: number;
  packIds?: number[];
  hasBeenPublished?: boolean;
}

export interface FlowPriorityItem extends PriorityItem {
  autoStart: boolean;
  canAutoStart: boolean;
}

export interface GroupAvailableFlows {
  id: number;
  title: string;
  published: boolean;
  ownerId: number;
  publisherId: number;
  dateCreated: Date;
  dateModified: Date;
  assignedPeopleIds?: number[];
}

export interface GroupAvailableAssets {
  id: number;
  title: string;
  isDraft: boolean;
  ownerId: number;
  publisherId: number;
  durationInSeconds: number;
  dateCreated: Date;
  dateModified: Date;
  assignedPeopleIds?: number[];
}

export interface UserPreviewMappingPayload {
  mapFrom: string;
  mapTo: string;
  isCustomField: boolean;
}

export interface UserPreview {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  department: string;
  country: string;
}

export interface UserInfoBase {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  department?: string;
  title?: string;
  imageUrl?: string;
  country?: string;
  officeLocation?: string;
  createDate?: string;
  jobTitle?: string;
  isBypassSso?: boolean;
}

export interface UserInfo extends UserInfoBase {
  canEdit?: boolean;
}

export interface UserInfoWithGroups extends UserInfoBase {
  assignedGroups: number[];
}

export interface UserInfoAction<T> {
  type: string;
  payload: T;
}

export interface EditUserInfoState {
  info?: UserInfo;
  isLoading?: boolean;
  error?: string | null;
  isAccessAuthorized?: boolean;
}

export interface IOwnerItemModel {
  canEdit: boolean;
  country: string | null;
  createDate: Date | string;
  department: string;
  email: string;
  firstName: string;
  id: number;
  imageUrl: string;
  lastName: string;
  title: string;
}

export interface ContentAssignment {
  id: number;
  title: string;
  dateCreated: string | Date;
  dateModified: string | Date;
  expirationDate?: string;
  dueDate?: string;
  inherited: boolean;
  canBeDeleted: boolean;
  priorityLevelId: number;
  contentType: string;
  publisher: string;
  publisherId: number;
  isDraft: boolean;
  ownerId: number;
  isPurchased: boolean;
  hasBeenPublished: boolean;
  canBePublished: boolean;
}

export interface VideoAssignmentApi extends ContentAssignment {
  bag?: {
    thumbnailUrl?: string | null;
    duration?: number;
  };
}

export interface EventAssignmentApi extends ContentAssignment {
  bag?: {
    thumbnailUrl?: string | null;
  };
}

export interface PdfAssignmentApi extends ContentAssignment {
  bag?: {
    thumbnailUrl?: string | null;
  };
}

export interface PeopleVideo extends ContentAssignment {
  thumbnailUrl?: string | null;
  durationInSeconds?: number;
}

export interface PeoplEvent extends ContentAssignment {
  thumbnailUrl?: string | null;
}

export interface PeoplePdf extends ContentAssignment {
  thumbnailUrl?: string | null;
}

export interface IGroupOwnerOptions {
  items: IOwnerItemModel[];
  itemsCount: number;
  isLoading: boolean;
}

export interface CountryModelItem {
  id: number;
  code: string;
  name: string;
}

export interface UserDetails extends UserInfo {
  isLoading?: boolean;
  error?: string | null;
  roleIds: number[];
}

export interface UsersOverviewState {
  isUploading: boolean;
  progress: number;
  error: string | null;
}

export interface AddToContentModalState {
  selectedPeopleIds: number[];
  selectedContentType: AddPeopleToContentTypes;
  selectedPeopleType: AssignmentPeopleContext;
  showModal: boolean;
  priorityItems: PriorityItem[];
}

export const v2FilterMap = {
  title: "jobTitles",
  department: "departments",
  createDate: "dateCreated",
  lastLoginDate: "dateLogin",
  roleId: "roleIds",
};

export interface RoleAssignedUsers {
  roleId: number;
  userIds: number[];
}

export interface GroupInfoForm {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  enrollmentRules: EnrollmentRule[];
  isOpen: Access;
  isVisibleToAll: Visibility;
  isAware: Awareness;
  isWithAutoEnroll: Enrollment;
  membersCount: number;
  dateCreated: Date;
  dateModified: Date;
  owners: Array<{ id: number }>;
  ownerIds: number[];
  settings: GroupSettings;
  canEdit: boolean;
  canView: boolean;
  readOnly: boolean;
}

export interface GroupSettings {
  isWithAutoEnroll: boolean | null;
  isVisibleToAll: boolean | null;
  isAware: boolean | null;
  isOpen: boolean | null;
}

export interface StartUsersImportResponse {
  id: string;
  code: string;
}

const UniqueValues = {
  ...SurveyFiltersEnum,
  ...VideoFiltersEnum,
  ...FlowFiltersEnum,
  ...AssessmentFiltersEnum,
  ...PdfFiltersEnum,
  ...EmailsFilterEnum,
};
export type ContentFilters = (typeof UniqueValues)[keyof typeof UniqueValues];

export type ContentFiltersState = FiltersState<ContentFilters>;

export interface GroupUsersState {
  enrollmentRules: EnrollmentRule[];
  userIds: number[];
}

export interface SelectedItemsState {
  selectedFlows: WithId[];
  selectedVideos: WithId[];
  selectedSurveys: WithId[];
  selectedAssessments: WithId[];
  selectedEvents: WithId[];
  selectedPdfs: WithId[];
}

export interface GetPeopleContentParams extends BasePagingOptions {
  userId: number;
  sortingColumnName?: string;
  sortingDirection?: SortingDirection;
  filters?: Filters;
  searchTerm?: string;
}

export type UserLineChart = {
  Date: string;
  Events: {
    AssessmentCompletes: number;
    AssessmentStarts: number;
    CumulativeAssessmentCompletes: number;
    CumulativeAssessmentStarts: number;
    CumulativeEmailClicks: number;
    CumulativeEmailOpens: number;
    CumulativeEmailSends: number;
    CumulativeFlowCompletes: number;
    CumulativeFlowStarts: number;
    CumulativePdfCompletes: number;
    CumulativeSurveyCompletes: number;
    CumulativeSurveyStarts: number;
    CumulativeVideoCompletes: number;
    CumulativeVideoStarts: number;
    CumulativeEventRegistered?: number;
    CumulativeEventAttended?: number;
    EmailClicks: number;
    EmailOpens: number;
    EmailSends: number;
    FlowCompletes: number;
    FlowStarts: number;
    PdfCompletes: number;
    SurveyCompletes: number;
    SurveyStarts: number;
    VideoCompletes: number;
    VideoStarts: number;
    EventRegistered?: number;
    EventAttended?: number;
  };
};

export type UserActivityByType = {
  AssessmentCompletes: number;
  AssessmentStarts: number;
  EmailClicks: number;
  EmailOpens: number;
  EmailSends: number;
  FlowCompletes: number;
  FlowStarts: number;
  Groups: number;
  Licenses: number;
  PdfCompletes: number;
  SurveyCompletes: number;
  SurveyStarts: number;
  VideoCompletes: number;
  VideoStarts: number;
  PlatformInteractions: number;
  PlatformOutreach: number;
  EventRegistered: number;
  EventAttended: number;
};

export type UserList = {
  EventCompletedate: string;
  EventStartdate: string;
  Id: number;
  Title: string;
  Type: UserAssetTypes;
  UserId: number;
};

//NOSONAR TODO: remove, use type from: src/features/People/state/peopleReducer.ts
export type PeopleState = {
  contentToPeople: {
    assignments: ContentAssignmentState;
    filters: ContentFiltersState;
  };
  contentAssignmentsOverview: {
    filters: ContentFiltersState;
  };
  contentAssignment: {
    assetGrid: ItemsViewGrid<IVideoAssignmentModelItem>;
    assetList: ItemsViewList<IVideoAssignmentModelItem>;
    flowGrid: ItemsViewGrid<IFlowAssignmentModelItem>;
    flowList: ItemsViewList<IFlowAssignmentModelItem>;
    eventGrid: ItemsViewGrid<IEventAssignmentModelItem>;
    eventList: ItemsViewList<IEventAssignmentModelItem>;
    surveyList: ItemsViewList<AssignmentModelItemBase>;
    assessmentList: ItemsViewList<AssignmentModelItemBase>;
    pdfList: ItemsViewList<IPdfAssignmentModelItem>;
    pdfGrid: ItemsViewGrid<IPdfAssignmentModelItem>;
  };
  addGroup: AddGroupState;
  editUser: EditUserState;
  rolesOverview: RolesOverviewState;
  userCommon: UserCommonState;
  addToContentModal: AddToContentModalState;
  addUserReducer: AddUserState;
  usersOverview: PeopleUsersOverviewState;
  groupsOverview: PeopleGroupsOverviewState;
  editGroup: EditGroupState;
  groupTemplate: GroupTemplateState;
  enrollmentUsers: EnrollmentUsersState;
};

export enum GroupContentTypesEnum {
  Groups = "Groups",
  Templates = "Templates",
}
