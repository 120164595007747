import { useEffect, useRef, useState } from "react";
import ItemsView from "../../../views/ItemsView/ItemsView";
import AccountRow from "./AccountRow";
import { SortingDirection, ViewType } from "../../../enums";
import SearchInput from "../../../components/searchInput/SearchInput";
import { Account } from "../../../interfaces/Account";
import AccountsNoResults from "./AccountsNoResults";
import { DeleteAccountOperationSuccess } from "../../Application/services/realTimeNotification/events/accounts/accountsEvents";

const AccountList: React.FC<AccountListProps> = ({
  accounts,
  accountsTotalCount,
  isLoading,
  onEditAccount,
  onDeleteAccount,
  onSelectedAccountsChanged,
  isMobo,
  onFetchAccounts,
  renderCreateAccountButton,
  selectedAccountsAmount,
  onOpenMobo,
}) => {
  const reloadListItemsRef = useRef<() => void>();
  const [searchTerm, setSearchTerm] = useState<string>();

  useEffect(() => {
    reloadListItemsRef.current && reloadListItemsRef.current();
  }, [searchTerm]);

  const onSetReloadListItems = (callback: () => void) => {
    reloadListItemsRef.current = callback;
  };

  const onSearchChange = (searchText: string) => {
    setSearchTerm(searchText);
  };

  const getAccounts = (skip: number, top: number, sortingColumnName: string, sortingDirection: SortingDirection) => {
    const sortingPropName = AccountRow.getColumnPropertyName(sortingColumnName);
    onFetchAccounts(skip, top, sortingPropName, sortingDirection, searchTerm);
  };

  const actionsDisabled = selectedAccountsAmount > 0;
  return (
    <ItemsView
      className="alignment-padding"
      viewType={ViewType.LIST}
      columnOptions={AccountRow.columnOptions}
      buildTableBody={(account: Account) => (
        <AccountRow
          account={account}
          onEdit={onEditAccount}
          actionsDisabled={actionsDisabled}
          onDelete={onDeleteAccount}
          onOpenMobo={onOpenMobo}
        />
      )}
      getData={getAccounts}
      items={accounts}
      itemsInlineCount={accountsTotalCount}
      isLoading={isLoading}
      onSelectedListItemsChanged={onSelectedAccountsChanged}
      sortingColumnName={AccountRow.defaultSorting.columnName}
      sortingDirection={AccountRow.defaultSorting.direction}
      renderSearch={() => <SearchInput placeholder="Search for Accounts..." onChange={onSearchChange} />}
      setReloadListItems={onSetReloadListItems}
      noResultsContent={
        <AccountsNoResults
          renderCreateAccountButton={renderCreateAccountButton}
          isMobo={isMobo}
          filtered={!!searchTerm}
        />
      }
      listViewRtnEvents={[DeleteAccountOperationSuccess]}
    />
  );
};

export interface AccountListProps {
  accounts: Account[];
  accountsTotalCount: number;
  isLoading: boolean;
  onFetchAccounts: (
    skip: number,
    top: number,
    sortingPropName: string,
    sortingDirection: SortingDirection,
    searchText?: string,
  ) => void;
  renderCreateAccountButton: () => React.ReactElement;
  onSelectedAccountsChanged: any;
  onEditAccount?: any;
  onDeleteAccount?: (id: number, name: string) => void;
  selectedAccountsAmount: number;
  isMobo: boolean;
  onOpenMobo?: any;
}

export default AccountList;
