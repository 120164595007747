import * as events from "../events/library/libraryEvents";
import {
  updateThreatDefenceSmtpAuthSendingProfileCommandSuccess,
  threatDefenceSmtpAuthSendingProfileUpdated,
  publishDraftThreatDefenceSmtpAuthSendingProfileSuccess,
  discardSendingProfileSuccess,
  fetchLockedSendingProfileSuccess,
} from "../../../../Library/SimulatedPhishing/SendingProfiles/SmtpAuth/state/actions/threatDefenceSmtpAuthSendingProfileEntityStateActions";
import { updateSmtpAuthSendingProfileVerificationStatus } from "../../../../Library/SimulatedPhishing/SendingProfiles/SmtpAuth/state/actions/threatDefenceSmtpAuthSendingProfileDetailsActions";
import { VerificationPayload } from "../../../../Library/SimulatedPhishing/SendingProfiles/SmtpAuth/types/state";
import * as backgroundTasksActions from "../../../../BackgroundTasks/state/backgroundTasksActions";
import { IMapping } from "./types";
import { Dispatcher, MidnightActionPayload } from "../../../../../interfaces/redux";
import { createDispatcher } from "../dispatcher";

export const smtpAuthSendingProfilesMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatch, dispatchMap, dispatchPublishSuccess } = createDispatcher(dispatcher);

  mapping[events.SmtpSendingProfileConfigEditSuccess] = dispatch(
    updateThreatDefenceSmtpAuthSendingProfileCommandSuccess,
  );
  mapping[events.SmtpSendingProfileSettingsEditSuccess] = dispatch(
    updateThreatDefenceSmtpAuthSendingProfileCommandSuccess,
  );
  mapping[events.SmtpSendingProfileUpdateCommandCompleted] = dispatch(threatDefenceSmtpAuthSendingProfileUpdated);

  mapping[events.SmtpSendingProfilePublishSuccess] = dispatchPublishSuccess<{ id: number; name: string }>(
    publishDraftThreatDefenceSmtpAuthSendingProfileSuccess,
    () => "SMTP Auth Sending Profile was published.",
  );

  mapping[events.SmtpSendingProfileVerifySuccess] = (payload) => {
    const data: { id: number; messageId: string } = payload;
    dispatcher(backgroundTasksActions.updateSucceededTaskOperation(data.messageId));

    const actionPayload: VerificationPayload = { id: data.id, isVerified: true, isFailed: false };
    dispatcher(updateSmtpAuthSendingProfileVerificationStatus(actionPayload));
  };

  mapping[events.SmtpSendingProfileVerifyFail] = (payload) => {
    const data: { id: number; messageId: string; errorMessage: string } = payload;
    dispatcher(backgroundTasksActions.updateFailedTaskOperation(data.messageId, undefined, data.errorMessage));

    const actionPayload: VerificationPayload = {
      id: data.id,
      isVerified: false,
      isFailed: true,
      errorMessage: data.errorMessage,
    };
    dispatcher(updateSmtpAuthSendingProfileVerificationStatus(actionPayload));
  };

  mapping[events.SmtpSendingProfileDiscardSuccess] = dispatch(discardSendingProfileSuccess);

  mapping[events.SmtpSendingProfileLockSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedSendingProfileSuccess,
    (message) => ({ entityId: message.id }),
  );
};
