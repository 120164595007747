import { SurveyUser } from "../../types/models";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createSelectedItemsSlice, SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface SurveyUsersState extends SelectedItemsState<SurveyUser> {}

const initialState: SurveyUsersState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const surveyUsersSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Surveys, name: "users" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  surveyUsersSlice.actions;

export default surveyUsersSlice.reducer;
export type surveyUsersSliceStateType = ReturnType<typeof surveyUsersSlice.reducer>;
