import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { FlowUser } from "../../types";
import { createSelectedItemsSlice, SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface FlowUsersState extends SelectedItemsState<FlowUser> {}

const initialState: FlowUsersState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const flowUsersSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Flows, name: "users" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  flowUsersSlice.actions;

export default flowUsersSlice.reducer;
export type flowUsersSliceStateType = ReturnType<typeof flowUsersSlice.reducer>;
