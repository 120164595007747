import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../enums/reducer";
import { RequestState, RStatus } from "../../../Application/globaltypes/fetchRequest";
import { RootState } from "../../../Application/globaltypes/redux";
import {
  OnedriveActivityFileCounts,
  SharepointActivityFileCounts,
  SharepointActivityPages,
  TeamsDeviceUsage,
  TeamsUserActivityCounts,
} from "../../types/graph";

const namespace = ReducerNamespaceTypes.Reporting;
const entityPrefix = ReducerEntityPrefixTypes.Reporting_Graph;

export interface GraphState {
  teamsDeviceUsage: RequestState<TeamsDeviceUsage[]>;
  teamsUserActivityCounts: RequestState<TeamsUserActivityCounts[]>;
  sharepointActivityFileCounts: RequestState<SharepointActivityFileCounts[]>;
  sharepointActivityPages: RequestState<SharepointActivityPages[]>;
  onedriveActivityFileCounts: RequestState<OnedriveActivityFileCounts[]>;
}

const initialRequestState: RequestState<any[]> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

const initialState: GraphState = {
  teamsDeviceUsage: { ...initialRequestState },
  teamsUserActivityCounts: { ...initialRequestState },
  sharepointActivityFileCounts: { ...initialRequestState },
  sharepointActivityPages: { ...initialRequestState },
  onedriveActivityFileCounts: { ...initialRequestState },
};

const graphSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    reqTeamsDeviceUsage(state) {
      state.teamsDeviceUsage = {
        ...state.teamsDeviceUsage,
        status: RStatus.Pending,
      };
    },
    setTeamsDeviceUsage(state, action: PayloadAction<{ data: TeamsDeviceUsage[] }>) {
      state.teamsDeviceUsage = {
        status: RStatus.Got,
        value: action.payload.data,
      };
    },
    setTeamsDeviceUsageError(state, action: PayloadAction<{ errorMessage?: string }>) {
      state.teamsDeviceUsage = {
        ...state.teamsDeviceUsage,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqTeamsUserActivityCounts(state) {
      state.teamsUserActivityCounts = {
        ...state.teamsUserActivityCounts,
        status: RStatus.Pending,
      };
    },
    setTeamsUserActivityCounts(state, action: PayloadAction<{ data: TeamsUserActivityCounts[] }>) {
      state.teamsUserActivityCounts = {
        status: RStatus.Got,
        value: action.payload.data,
      };
    },
    setTeamsUserActivityCountsError(state, action: PayloadAction<{ errorMessage?: string }>) {
      state.teamsUserActivityCounts = {
        ...state.teamsUserActivityCounts,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqSharepointActivityFileCounts(state) {
      state.sharepointActivityFileCounts = {
        ...state.sharepointActivityFileCounts,
        status: RStatus.Pending,
      };
    },
    setSharepointActivityFileCounts(state, action: PayloadAction<{ data: SharepointActivityFileCounts[] }>) {
      state.sharepointActivityFileCounts = {
        status: RStatus.Got,
        value: action.payload.data,
      };
    },
    setSharepointActivityFileCountsError(state, action: PayloadAction<{ errorMessage?: string }>) {
      state.sharepointActivityFileCounts = {
        ...state.sharepointActivityFileCounts,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqSharepointActivityPages(state) {
      state.sharepointActivityPages = {
        ...state.sharepointActivityPages,
        status: RStatus.Pending,
      };
    },
    setSharepointActivityPages(state, action: PayloadAction<{ data: SharepointActivityPages[] }>) {
      state.sharepointActivityPages = {
        status: RStatus.Got,
        value: action.payload.data,
      };
    },
    setSharepointActivityPagesError(state, action: PayloadAction<{ errorMessage?: string }>) {
      state.sharepointActivityPages = {
        ...state.sharepointActivityPages,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqOnedriveActivityFileCounts(state) {
      state.onedriveActivityFileCounts = {
        ...state.onedriveActivityFileCounts,
        status: RStatus.Pending,
      };
    },
    setOnedriveActivityFileCounts(state, action: PayloadAction<{ data: OnedriveActivityFileCounts[] }>) {
      state.onedriveActivityFileCounts = {
        status: RStatus.Got,
        value: action.payload.data,
      };
    },
    setOnedriveActivityFileCountsError(state, action: PayloadAction<{ errorMessage?: string }>) {
      state.onedriveActivityFileCounts = {
        ...state.onedriveActivityFileCounts,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return { ...initialState };
    },
  },
});

export const {
  reqTeamsDeviceUsage,
  setTeamsDeviceUsage,
  setTeamsDeviceUsageError,
  reqTeamsUserActivityCounts,
  setTeamsUserActivityCounts,
  setTeamsUserActivityCountsError,
  reqSharepointActivityFileCounts,
  setSharepointActivityFileCounts,
  setSharepointActivityFileCountsError,
  reqSharepointActivityPages,
  setSharepointActivityPages,
  setSharepointActivityPagesError,
  reqOnedriveActivityFileCounts,
  setOnedriveActivityFileCounts,
  setOnedriveActivityFileCountsError,
  reset,
} = graphSlice.actions;

export const selectTeamsDeviceUsage = (state: RootState) => state.reporting.graph.teamsDeviceUsage;
export const selectTeamsUserActivityCounts = (state: RootState) => state.reporting.graph.teamsUserActivityCounts;
export const selectSharepointActivityFileCounts = (state: RootState) =>
  state.reporting.graph.sharepointActivityFileCounts;
export const selectSharepointActivityPages = (state: RootState) => state.reporting.graph.sharepointActivityPages;
export const selectOnedriveActivityFileCounts = (state: RootState) => state.reporting.graph.onedriveActivityFileCounts;

export default graphSlice.reducer;
