import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";
import cn from "classnames";

import CroppedThumbnail from "../../croppedThumbnail/CroppedThumbnail";
import RestrictedByTooltip from "../../restrictedByTooltip/RestrictedByTooltip";

import "./cardThumbnail.scss";

function CardThumbnail(props) {
  const {
    thumbnailUrl,
    handleImageClick,
    selected,
    children,
    disabled,
    hasEditPermission,
    isRadioButton,
    draggable,
    selectable = true,
  } = props;

  const renderClickInput = () => {
    if (isRadioButton) {
      return renderRadioButton();
    } else {
      return renderCheckbox();
    }
  };

  const renderCheckbox = () => {
    return (
      <RestrictedByTooltip hasPermission={hasEditPermission ?? true} className="selection-checkbox-tooltip">
        <Checkbox disabled={disabled} className="selection-checkbox selection-field" checked={selected} />
      </RestrictedByTooltip>
    );
  };

  const renderRadioButton = () => {
    return (
      <RestrictedByTooltip hasPermission={hasEditPermission ?? true} className="selection-checkbox-tooltip">
        <Checkbox disabled={disabled} className="radio checkbox selection-field" checked={selected} type="radio" />
      </RestrictedByTooltip>
    );
  };

  return (
    <CroppedThumbnail
      thumbnailUrl={thumbnailUrl}
      onClick={handleImageClick}
      draggable={draggable}
      className={cn("card-thumbnail", { disabled: disabled })}
      placeholderContent={props.placeholderContent}
    >
      {selected !== undefined && selectable ? renderClickInput() : null}
      <div className="thumbnail-content">{children}</div>
    </CroppedThumbnail>
  );
}

CardThumbnail.propTypes = {
  thumbnailUrl: PropTypes.string,
  handleImageClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.object,
  hasEditPermission: PropTypes.bool,
  isRadioButton: PropTypes.bool,
  draggable: PropTypes.bool,
  placeholderContent: PropTypes.node,
  selectable: PropTypes.bool,
};

export default CardThumbnail;
