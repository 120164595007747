import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import PackDrops from "./PackDrops";
import { RootState } from "../../../../Application/globaltypes/redux";
import { isPackReadOnlySelector } from "../../../../Application/selectors";
import * as packEntityStateActions from "../../state/actions/packEntityStateActions";
import * as packDetailsActions from "../../state/actions/packDetailsActions";
import { DropItemsList } from "../../../../../components/dragAndDrop";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";

import "./packContent.scss";
import { DroppedItem } from "../../../../../interfaces/assetToDropInfo";
import DragNDropItemTypes from "../../../../../enums/dragNDropItemTypes";
import { LazyLoader } from "../../../../../components";
import { AlertTypes } from "../../../../../enums";
import { clearSelectedCards } from "../../state/actions/packDetailsActions";
import NoResults from "../../../../../components/noResults/NoResults";
import { ReactComponent as UploadMultiIcon } from "../../../../../images/upload-multi-icon.svg";
import { Loader } from "semantic-ui-react";
import { PackContentProps } from "../types";

export type PackContentAllProps = PackContentProps & PropsFromRedux;
export class PackContent extends Component<PackContentAllProps> {
  async componentDidMount() {
    if (this.props.id > 0) {
      this.props.detailsActions.getPackContentWithDetails(this.props.id);
    }
  }

  addAsset = (assets: DroppedItem[]) => {
    this.props.detailsActions.addItemToPackAndUpdate(this.props.id, assets);
  };

  removeItem = (itemId: number, itemType?: string) => {
    if (this.props.onPackItemDelete) {
      this.props.onPackItemDelete();
    }
    this.props.detailsActions.removeFromPackAndUpdate(this.props.id, itemId, itemType);
  };

  loadData = () => {
    const displayItemsCount = this.props.displayItemsCount + Number(process.env.REACT_APP_LOAD_ITEMS_COUNT);

    this.props.detailsActions.updatePackDisplayItemsCount(displayItemsCount);
    this.props.detailsActions.getPackContentDetails(
      this.props.items.slice(this.props.displayItemsCount, displayItemsCount),
    );
  };

  onDropFailed = (message?: string) =>
    this.props.sendTransientNotification({
      title: "Warning!",
      message: message ?? "Item has already been added!",
      type: AlertTypes.warning,
    });

  render() {
    const { isContentLoading, items, displayItemsCount, isReadOnly } = this.props;
    const isPackCreated = this.props.id > 0;
    return (
      <DndProvider backend={HTML5Backend}>
        {!isPackCreated && <Loader active />}
        {isPackCreated && (
          <div className="pack-droppable-items-container">
            <div className="pack-content-items-row">
              <div className="droppable-items-column">
                <LazyLoader
                  getData={this.loadData}
                  isFirstLoad={items.length === 0}
                  loadingData={isContentLoading}
                  isAllDataLoaded={displayItemsCount > items.length}
                >
                  <DropItemsList
                    selectedCards={this.props.selectedCards}
                    items={this.props.items}
                    displayItemsCount={this.props.displayItemsCount}
                    addItems={this.addAsset}
                    removeItem={this.removeItem}
                    isReadonly={isReadOnly}
                    acceptType={DragNDropItemTypes.PACK}
                    isDraggingDisabled={true}
                    onDropFailed={this.onDropFailed}
                    noResults={
                      <NoResults
                        title="Looks like you don't have any content for this Pack."
                        description="Drag content from the panel on the right to include in this Pack."
                        icon={<UploadMultiIcon />}
                      />
                    }
                    resetSelectedCards={this.props.resetSelectedCards}
                  />
                </LazyLoader>
              </div>
              <div className="droppable-asset-items-column">
                <PackDrops />
              </div>
            </div>
          </div>
        )}
      </DndProvider>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: { id?: number }) => ({
  id: ownProps.id || state.packs.packEntityStateReducer.entityId,
  items: state.packs.packDetailsReducer.pack.items,
  displayItemsCount: state.packs.packDetailsReducer.pack.displayItemsCount,
  isContentLoading: state.packs.packDetailsReducer.isContentLoading,
  isReadOnly: isPackReadOnlySelector(state),
  selectedCards: state.packs.packDetailsReducer.selectedCards,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(packEntityStateActions, dispatch),
  detailsActions: bindActionCreators(packDetailsActions, dispatch),
  sendTransientNotification: bindActionCreators(sendTransientNotification, dispatch),
  resetSelectedCards: bindActionCreators(clearSelectedCards, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(PackContent);
