import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { get, partial, set } from "lodash";
import { Grid } from "semantic-ui-react";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";

import ExpressionFields from "../DependentFields/ExpressionFields";

import { ExternalTrigger, SourceType } from "../../../../../../types/externallTriggersTypes";
import { getDependencyFields, getFormSettings, getOptions } from "../../helpers/utils";
import { AppDispatch, RootState } from "../../../../../../../../../Application/globaltypes/redux";
import { flowDesignerSelector } from "../../../../../../../state/selectors";
import { bindAction } from "../../../../../../../../../../interfaces";
import { fetchSourceEventsAction } from "../../../../../../state/thunks/flowDesignerExternalTriggersThunk";
import { DropdownTrigger } from "../../../../../../../../../../components/dropdowns/dropdownTrigger/DropdownTrigger";

export interface TriggerRowProps extends PropsFromRedux {
  isReadOnly: boolean;
  handleChange: (field: string, trigger: ExternalTrigger, index: number) => void;
  item: ExternalTrigger;
  index: number;
  vendorOptions: DropdownItemProps[];
}

export const TriggerRow = (props: TriggerRowProps) => {
  const { sourceEvents, vendorOptions, item, index } = props;
  const sourceEventsByType = (item.software && sourceEvents[item.software]) || {};

  const onChange = (field: string, _: React.SyntheticEvent<HTMLElement>, data: any) => {
    const trigger = { ...item };
    const { completionField, getCompletionPath, dependencyFields } = getFormSettings(
      field === "software" ? (data.value as SourceType) : item.software!,
    );

    if (completionField === field) {
      const { id, fullText } = get(sourceEventsByType, getCompletionPath(trigger, data.value));
      props.handleChange(field, { ...trigger, eventId: id, fullText, [field]: data.value }, index);
    } else {
      const restFields = {};
      getDependencyFields(field, dependencyFields).forEach((item) => set(restFields, `${item}`, null));
      props.handleChange(field, { ...trigger, ...restFields, [field]: data.value }, index);
    }
  };

  const onVendorChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
    props.fetchSourceEvents(data.value as SourceType);
    onChange("software", event, data);
  };

  const getDependentFields = () => {
    const baseProps = {
      item: item,
      isReadOnly: props.isReadOnly,
      handleChange: onChange,
      sourceEvents: sourceEventsByType,
    };
    switch (item.software) {
      case "BrainStorm":
      case "Microsoft": {
        return <ExpressionFields {...baseProps} />;
      }
      default:
        return (
          <>
            <Grid.Column></Grid.Column>
            <Grid.Column></Grid.Column>
          </>
        );
    }
  };

  return (
    <>
      <Grid.Column>
        <DropdownTrigger
          blur
          fluid
          items={vendorOptions}
          selected={item.software}
          disabled={props.isReadOnly}
          placeholder="Select Vendor"
          handleOptionChange={onVendorChange}
        />
      </Grid.Column>
      <Grid.Column>
        <DropdownTrigger
          blur
          fluid
          items={getOptions(sourceEventsByType)}
          selected={item.application}
          disabled={!item.software || props.isReadOnly}
          placeholder="Select Application"
          handleOptionChange={partial(onChange, "application")}
        />
      </Grid.Column>
      <Grid.Column>
        <DropdownTrigger
          blur
          fluid
          selected={item.action}
          placeholder="Select Action"
          disabled={!item.application || props.isReadOnly}
          handleOptionChange={partial(onChange, "action")}
          items={getOptions(get(sourceEventsByType, `${item.application}`))}
        />
      </Grid.Column>
      {getDependentFields()}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    sourceEvents: flowDesignerSelector(state).externalTriggers.sourceEvents,
    isLoading: flowDesignerSelector(state).externalTriggers.loading.events,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchSourceEvents: bindAction(fetchSourceEventsAction, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TriggerRow);
