import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { RootState } from "../../../../Application/globaltypes/redux";
import { createSearchSlice, SearchState } from "../../../../Application/slices/createSearchSlice";

export const initialState: SearchState = {
  term: undefined,
};

const availablePacksSearchSlice = createSearchSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AvailablePacks,
    name: "search",
  },
  initialState: initialState,
  reducers: {},
});

export const { setTerm } = availablePacksSearchSlice.actions;

export const packsModalSearchSelector = (state: RootState) => state.packs.packsModalSearch;

export default availablePacksSearchSlice.reducer;
