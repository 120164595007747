import dataService from "../../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/threatDefenceLandingPagesOverviewActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import { GetThreatDefenceLandingPagesRequest } from "../../types/requests";
import { Dispatcher, FetchActionPayload } from "../../../../../../interfaces/redux";
import { ThreatDefenceLandingPageOverview } from "../../types/state";
import { Filters } from "../../../../../../utils/queryUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const getThreatDefenceLandingPagesAsync = (take: number, skip: number, orderBy: string, filters: Filters) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceLandingPagesBegin);
  const success = getActionProvider<FetchActionPayload<ThreatDefenceLandingPageOverview>>(
    actionTypes.getThreatDefenceLandingPagesSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceLandingPagesFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    const request: GetThreatDefenceLandingPagesRequest = {
      top: take,
      skip,
      orderBy,
      filters,
    };
    try {
      const result = await dataService.threatDefence.getThreatDefenceLandingPagesAsync(request);
      const recordsCount = Number.parseInt(result.headers[countHeaderName]);

      const data: FetchActionPayload<ThreatDefenceLandingPageOverview> = {
        items: result.data,
        totalCount: recordsCount,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
