import { useHasEnablePackPermissions } from "../hooks/useHasEnablePackPermissions";
import { useHasPurchasingPower } from "../hooks/useHasPurchasingPower";
import EnablePackModal from "./EnablePackModal";
import EnablePackTrialModal from "./EnablePackTrialModal";
import PackRequestModal from "./PackRequestModal";
import PackTrialRequestModal from "./PackTrialRequestModal";
import {
  EnablePackModalPropsOwn,
  EnableTrialModalPropsOwn,
  PackRequestCommonProps,
  PackRequestModalPropsOwn,
  PackTrialRequestModalPropsOwn,
} from "./types";

export interface Props
  extends Omit<PackRequestCommonProps, "isOpen">,
    PackRequestModalPropsOwn,
    EnablePackModalPropsOwn,
    PackTrialRequestModalPropsOwn,
    EnableTrialModalPropsOwn {
  showModal: boolean;
  isTrialRequest: boolean;
}

export const RequestPackModals: React.FC<Props> = (props) => {
  const hasEnablePackPermissions = useHasEnablePackPermissions();
  const hasPurchasingPower = useHasPurchasingPower();
  const {
    onSubmitEnablePack,
    onSubmitPackRequest,
    onTrialRequest,
    onTrialEnable,
    trialDuration,
    showModal,
    isTrialRequest,
    ...commonBag
  } = props;

  return (
    <>
      <EnablePackModal
        {...commonBag}
        onSubmitEnablePack={onSubmitEnablePack}
        isOpen={showModal && !isTrialRequest && hasEnablePackPermissions}
      />
      <PackRequestModal
        {...commonBag}
        onSubmitPackRequest={onSubmitPackRequest}
        isOpen={showModal && !isTrialRequest && !hasEnablePackPermissions}
      />
      <PackTrialRequestModal
        {...commonBag}
        onTrialRequest={onTrialRequest}
        isOpen={showModal && isTrialRequest && !hasPurchasingPower}
        trialDuration={trialDuration}
      />
      <EnablePackTrialModal
        {...commonBag}
        onTrialEnable={onTrialEnable}
        isOpen={showModal && isTrialRequest && hasPurchasingPower}
        trialDuration={trialDuration}
      />
    </>
  );
};
