import { createAsyncThunk } from "@reduxjs/toolkit";

import { ReducerNamespaceTypes, ReducerEntityPrefixTypes } from "../../../../../enums/reducer";
import { getPrefix } from "../../../../Application/slices/models";
import { Filters } from "../../../../../utils/queryUtils";
import usersDataService from "../../../../Application/services/dataServices/usersDataService";
import { usersFilter } from "../../../../../components/filterForms/UsersFilterForm/UsersFilterForm";

export const fetchPotentialContactsNamePayload = {
  namespace: ReducerNamespaceTypes.Accounts,
  entity: ReducerEntityPrefixTypes.EditAccount,
  name: "fetchPotentialContacts",
};

export interface PotentialContactRequest {
  skip: number;
  top: number;
  orderBy: string;
  filter: Filters;
  accountId?: number;
  search?: string;
}

export const fetchPotentialContacts = createAsyncThunk(
  getPrefix(fetchPotentialContactsNamePayload),
  async ({ accountId, skip, top, orderBy, filter, search }: PotentialContactRequest) => {
    const filters = search ? filter : usersFilter.buildFilterQuery(filter);
    const usersPage = !!search
      ? await usersDataService.getAvailableContactsV2(skip, top, orderBy, filters, search, accountId)
      : await usersDataService.getAvailableContacts(skip, top, orderBy, filters, accountId);

    return {
      items: usersPage.items,
      totalCount: usersPage.count,
    };
  },
);
