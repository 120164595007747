import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../enums/reducer";
import { createFiltersSlice } from "../../../Application/slices/createFiltersSlice";
import { ContentFilters, ContentFiltersState } from "../../types";
import { GenericFiltersMap } from "../../../../utils/filterUtils";

export const initialState: ContentFiltersState = {
  filterOptions: {} as GenericFiltersMap<ContentFilters>,
  appliedFilter: {} as GenericFiltersMap<ContentFilters>,
  isLoading: false,
  search: "",
};

const contentAssignmentsOverviewFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.Content,
    name: "contentAssignmentsOverviewFilters",
  },
  initialState: initialState,
  reducers: {},
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  setSearch,
  resetSearch,
} = contentAssignmentsOverviewFiltersSlice.actions;

export default contentAssignmentsOverviewFiltersSlice.reducer;
