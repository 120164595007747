import React, { FC } from "react";

import { Filters } from "../../../utils/queryUtils";
import { FilterFormPropsBase } from "../types/props";
import { FilterFormBuilder, FilterItemBase, FilterViewType } from "../FilterFormBuilder/FilterFormBuilder";
import { EmailsFilterEnum } from "../../../features/Library/Communications/Emails/types/models";

export interface EmailFilterFormProps {
  filterOptions: Filters;
  updateFilter: (filter: Filters) => void;
  filter: Filters;
}
export interface EmailFilterFormProps extends FilterFormPropsBase<EmailsFilterEnum> {}

export const EmailFilterForm: FC<EmailFilterFormProps> = ({ filterOptions, filter, updateFilter }) => {
  const filters: FilterItemBase<EmailsFilterEnum>[] = [
    {
      type: FilterViewType.DateRange,
      label: "Date Added",
      propertyName: EmailsFilterEnum.DateCreated,
    },
    {
      type: FilterViewType.DateRange,
      label: "Last Modified",
      propertyName: EmailsFilterEnum.DateModified,
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Publisher",
      propertyName: EmailsFilterEnum.Publishers,
      placeholder: "All Publishers",
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Packs",
      propertyName: EmailsFilterEnum.Packs,
      placeholder: "All Packs",
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Software Applications",
      propertyName: EmailsFilterEnum.SoftwareApplications,
      placeholder: "All Applications",
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Labels",
      propertyName: EmailsFilterEnum.Labels,
      placeholder: "All Labels",
    },
  ];

  return (
    <FilterFormBuilder filterOptions={filterOptions} filter={filter} updateFilter={updateFilter} items={filters} />
  );
};
