import { PayloadAction } from "@reduxjs/toolkit";
import { WithId } from "../../../types";
import { filterSelectedAssignments } from "../../Library/PeopleAssignments/utils/peopleAssignmentUtils";
import { createFetchingItemsSlice, FetchingItemsState } from "./createFetchingItemsSlice";
import { NamePayload } from "./models";

export interface SelectedItemsState<T> extends FetchingItemsState<T> {
  selectedIds: number[];
  selectedItems: T[];
}

export const createSelectedItemsSlice = <
  T extends WithId,
  TState extends SelectedItemsState<T> = SelectedItemsState<T>,
>(
  namePayload: NamePayload,
  initialState: TState,
) => {
  return createFetchingItemsSlice({
    namePayload,
    initialState,
    reducers: {
      selectedItemsChanged(state, action: PayloadAction<number[]>) {
        return {
          ...state,
          selectedIds: [...action.payload],
          selectedItems: filterSelectedAssignments(state.items, state.selectedItems, action.payload),
        };
      },
      resetSelectedItems(state) {
        return {
          ...state,
          selectedIds: [],
          selectedItems: [],
        };
      },
    },
  });
};
