import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { RouteNames } from "enums";
import { BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import { PeopleActivityDetails } from "features/Reporting/types/people";
import { ColumnOption } from "interfaces/columnOptions";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import dateTimeUtils from "utils/dateTimeUtils";
import moboConfigurator from "../../../../moboConfigurator";

const getDetails = async ({
  queryKey,
}: QueryFunctionContext<
  [_name: string, filters: BasePerformanceRequestFilterParams, selectedType: string, selectedCategory: string]
>) => {
  let data: PeopleActivityDetails[];
  if (queryKey[3] === "outreach") {
    ({ data } = await peopleReportingService.activity.getPeopleOutreachDetails({ ...queryKey[1], type: queryKey[2] }));
  } else {
    ({ data } = await peopleReportingService.activity.getPeopleInteractionDetails({
      ...queryKey[1],
      type: queryKey[2],
    }));
  }
  return data.map((d, i) => ({ ...d, id: i }));
};

export interface Props {
  dateFilter: PerformanceFilter;
  selectedType: string;
  selectedCategory: string;
}

export const PeopleActivityDrilldown = ({ dateFilter, selectedType, selectedCategory }: Props) => {
  const detailsQuery = useQuery({
    queryFn: getDetails,
    queryKey: ["people activity details", dateFilter, selectedType, selectedCategory],
    enabled: !!selectedCategory,
  });

  const columnOptions: ColumnOption<PeopleActivityDetails>[] = useMemo(
    () => [
      {
        name: "TITLE",
        width: 8,
        render(item) {
          if (selectedType === "Flow Outreach" || selectedType.match(/email/i)) {
            return (
              <Tooltip
                target={
                  <TextTruncate><Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.contentCommunications}/${RouteNames.emails}/${item.Id}`)}>{item.Title}</Link></TextTruncate>
                }
                content={`${item.Title}`}
              />
            );
          } else if (selectedType.match(/flow/i)) {
            return (<Tooltip
              target={
                <TextTruncate><Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.contentFlows}/${item.Id}`)}>{item.Title}</Link></TextTruncate>
              }
              content={`${item.Title}`
              }
            />);
          } else if (selectedType.match(/assessment/i)) {
            return (<Tooltip
              target={
                <TextTruncate><Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.contentAssessments}/${item.Id}`)}>{item.Title}</Link></TextTruncate>
              }
              content={`${item.Title}`
              }
            />);
          } else if (selectedType.match(/video/i)) {
            return (<Tooltip
              target={
                <TextTruncate><Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.contentVideos}/${item.Id}`)}>{item.Title}</Link></TextTruncate>
              }
              content={`${item.Title}`
              }
            />);
          } else if (selectedType.match(/pdf/i)) {
            return (<Tooltip
              target={
                <TextTruncate><Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.contentPdfs}/${item.Id}`)}>{item.Title}</Link></TextTruncate>
              }
              content={`${item.Title}`
              }
            />);
          } else if (selectedType.match(/survey/i)) {
            return (<Tooltip
              target={
                <TextTruncate><Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.contentSurveys}/${item.Id}`)}>{item.Title}</Link></TextTruncate>
              }
              content={`${item.Title}`
              }
            />);
          } else if (selectedType.match(/event/i)) {
            return (<Tooltip
              target={
                <TextTruncate><Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.contentEvents}/${item.Id}`)}>{item.Title}</Link></TextTruncate>
              }
              content={`${item.Title}`
              }
            />);
          }
          return (<Tooltip
            target={
              <TextTruncate>{item.Title}</TextTruncate>
            }
            content={`${item.Title}`
            }
          />);
        },
      },
      {
        name: "USER",
        width: 8,
        render(item) {
          return (
            <Tooltip
              target={
                <TextTruncate className="userLink">
                  <Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.peopleUsers}/${item.UserId}`)}>
                    {item.FirstName} {item.LastName}
                  </Link>
                </TextTruncate>
              }
              content={`${item.FirstName} ${item.LastName}`}
            />
          );
        },
      },
      {
        name: "Department",
        width: 5,
        render(item) {
          const department = item.Department ?? "-";
          return <Tooltip target={<TextTruncate>{department}</TextTruncate>} content={department} />;
        },
      },
      {
        name: "JOB TITLE",
        width: 5,
        render(item) {
          const title = item.JobTitle ?? "-";
          return <Tooltip target={<TextTruncate>{title}</TextTruncate>} content={title} />;
        },
      },
      {
        name: "DATE",
        width: 5,
        render(item) {
          return dateTimeUtils.formatDate(item.Date);
        },
      },
      {
        name: "TIME",
        width: 5,
        render(item) {
          return item.Time;
        },
      },
    ],
    [selectedType],
  );

  return (
    <div className="people-activity-drilldown">
      <RequestStatusRenderer state={detailsQuery.status}>
        {detailsQuery.data && (
          <GenericPerformanceList
            title={selectedType}
            rows={detailsQuery.data}
            columns={columnOptions}
            filter={dateFilter}
          />
        )}
      </RequestStatusRenderer>
    </div>
  );
};
