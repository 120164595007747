import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { withRouter, WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { RouteNames } from "../../../../enums";
import { bindAction } from "../../../../interfaces/redux";
import navigationUtils from "../../../../utils/navigationUtils";
import WizardStepsManager from "../../../../utils/WizardStepsManager";
import { RootState } from "../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { WizardWrapper as Wizard } from "../../../WizardWrapper";
import Configure from "../PackDetails/Configure/Configure";
import PackContent from "../PackDetails/Content/PackContent";
import PackVisibilityTabs from "../PackDetails/Visibility/PackVisibilityTabs";
import * as packDetailsActions from "../state/actions/packDetailsActions";
import * as packEntityStateActions from "../state/actions/packEntityStateActions";
import { hasFlowsCreatePermissionSelector, hasPacksManagePermissionSelector } from "../state/selectors";
import { resetContext } from "../state/slices/packContentTabContextSlice";
import { CreatePackState } from "./types";

import "./createPack.scss";

export type CreatePackPropsAll = PropsFromRedux & WithRouterProps;
const packsOverviewUrl = `/${RouteNames.licensingPacks}`;

export class CreatePack extends Component<CreatePackPropsAll, CreatePackState> {
  private stepsManager = new WizardStepsManager();

  constructor(props: CreatePackPropsAll) {
    super(props);
    this.state = {
      isValid: false,
      canPublish: false,
    };
  }

  onCancel = () => {
    navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, "/licensing/packs");
  };

  componentDidMount() {
    RtnEventsEmitter.subscribe(rtnEvents.PackPublishSuccess, this.onPublishedEvent);
  }

  componentWillUnmount() {
    this.props.resetContentTabContext();
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe(rtnEvents.PackPublishSuccess, this.onPublishedEvent);
  }

  onPublishedEvent = () => this.props.navigate(packsOverviewUrl);

  onIsValidChange = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
      this.setState({ canPublish: isValid });
    }
  };

  onFinish = () => {
    this.props.publishDraftPackEntity(this.props.id);
  };

  isPackCreated = () => this.props.id > 0;

  isPrivatePack = () => !this.props.hasPacksManagePermissions && this.props.hasFlowsCreatePermissions;

  render() {
    const { isValid } = this.state;
    const { isSaving, dateModified } = this.props;

    return (
      <Wizard
        title="Create Pack"
        finishButtonLabel="Publish"
        onCancel={this.onCancel}
        onProgressAsync={(_: any, nextIndex: number) => this.stepsManager.onNext(nextIndex)}
        onRegressAsync={(_: any, nextIndex: number) => this.stepsManager.onPrevious(nextIndex)}
        onFinishAsync={this.onFinish}
        isSaveInProgress={isSaving}
        progressSavedDate={dateModified}
        isFinishButtonDisabled={!this.isPackCreated() || isSaving}
        className="create-pack-wizard"
      >
        <Wizard.Step label="Configure" className="scrollable-content" isLocked={!isValid} required>
          <Configure
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 0)}
            onIsValidChange={this.onIsValidChange}
            disabled={false}
            isPrivatePack={this.isPrivatePack()}
            {...this.props}
          />
        </Wizard.Step>
        <Wizard.Step label="Content" className="scrollable-content" isLocked={!isValid} required>
          <PackContent key="create-pack-content" />
        </Wizard.Step>
        {!this.isPrivatePack() && (
          <Wizard.Step label="Visibility" className="scrollable-content visibility-step-content" isLocked={!isValid}>
            <PackVisibilityTabs packId={this.props.id} isReadOnlyMode={false} />
          </Wizard.Step>
        )}
      </Wizard>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    isSaving: state.packs.packEntityStateReducer.isEntityCommandInProgress,
    dateModified: state.packs.packEntityStateReducer.lastModifiedDateTime,
    id: state.packs.packEntityStateReducer.entityId,
    hasPacksManagePermissions: hasPacksManagePermissionSelector(state),
    hasFlowsCreatePermissions: hasFlowsCreatePermissionSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  publishDraftPackEntity: bindAction(packEntityStateActions.publishDraftPackEntity, dispatch),
  detailsActions: bindActionCreators(packDetailsActions, dispatch),
  resetContentTabContext: bindAction(resetContext, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(CreatePack));
