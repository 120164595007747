import { createFetchingItemsSlice, FetchingItemsState } from "features/Application/slices/createFetchingItemsSlice";
import { TemplateContent } from "../types/models";
import { ContentTypesEnum, ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { PayloadAction } from "@reduxjs/toolkit";

interface TemplateContentState extends FetchingItemsState<TemplateContent> {
  selected: { [key in ContentTypesEnum]?: number[] };
}

const initialState: TemplateContentState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
  selected: {},
};

const slice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "TemplateContent",
    namespace: ReducerNamespaceTypes.People,
  },
  reducers: {
    setSelected: (state, action: PayloadAction<{ context: ContentTypesEnum; selected: number[] }>) => {
      const { context, selected } = action.payload;
      state.selected[context] = selected;
    },
    resetItems: (state) => {
      return { ...state, ...initialState, selected: state.selected };
    },
  },
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset, resetItems, setSelected } = slice.actions;

export default slice.reducer;
