import { FC } from "react";
import cn from "classnames";
import { Checkbox, Divider, Loader } from "semantic-ui-react";

import { ApiCredentials } from "../ApiCredentials/ApiCredentials";

import "./ApiConfiguration.scss";

export interface Props {
  label: string;
  enabled: boolean;
  loading: boolean;
  readonly?: boolean;
  credentials?: {
    clientId: string;
    clientSecret?: string;
  };
  onEnabledChange(enabled: boolean): void;
  onSecretRegenerate(): void;
}

export const ApiConfiguration: FC<Props> = ({
  label,
  enabled,
  loading,
  readonly,
  credentials,
  onEnabledChange,
  onSecretRegenerate,
  ...otherProps
}) => {
  const renderToggleHint = () => {
    if (loading) {
      return <Loader active inline size="small" />;
    }

    if (!readonly) {
      return <span className="hint">Enable to generate client ID and secret</span>;
    }
  };

  return (
    <div className={cn("api-configuration", { enabled })} {...otherProps}>
      <div className="api-toggle">
        <Checkbox
          disabled={readonly}
          toggle
          label={label}
          checked={enabled}
          readOnly={loading}
          onChange={(_, { checked }) => onEnabledChange(Boolean(checked))}
        />
        {!enabled && renderToggleHint()}
      </div>
      {enabled && (loading || credentials) ? (
        <ApiCredentials
          loading={loading}
          readonly={readonly}
          clientId={credentials?.clientId}
          clientSecret={credentials?.clientSecret}
          onSecretRegenerate={onSecretRegenerate}
        />
      ) : (
        <Divider />
      )}
    </div>
  );
};
