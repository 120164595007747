import { AppDispatch } from "../../../../Application/globaltypes/redux";
import flowsDataService, { BackendFlowsConfigV2 } from "../../services/flowsDataService";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slices/flowUsersToAddSlice";

export const fetchFlowUsersToAdd =
  (flowIds: number[], config: BackendFlowsConfigV2) => async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const { items, count } = await flowsDataService.getFlowUsersToAddV2(flowIds, config);

      dispatch(fetchSuccess({ items, totalCount: count }));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
