import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums/reducer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPrefix } from "../../../../../Application/slices/models";
import { SavedFlowData } from "../../types";

export interface FlowDesignerDefinitionState {
  items: SavedFlowData;
  isLoading: boolean;
  error?: Error;
}

export const initialState: FlowDesignerDefinitionState = {
  isLoading: false,
  items: {
    flowItems: [],
    flowTriggers: [],
  },
};

const flowDesignerDefinitionSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "designerDefinition",
  }),
  initialState: initialState,
  reducers: {
    fetchBegin(state) {
      state.isLoading = true;
    },

    fetchSuccess(state: FlowDesignerDefinitionState, action: PayloadAction<SavedFlowData>) {
      state.isLoading = false;
      state.items = action.payload;
    },

    fetchFailure(state: FlowDesignerDefinitionState, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    saveItems(state: FlowDesignerDefinitionState, action: PayloadAction<SavedFlowData>) {
      state.items = action.payload;
    },

    reset: () => {
      return initialState;
    },
  },
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset, saveItems } = flowDesignerDefinitionSlice.actions;

export default flowDesignerDefinitionSlice.reducer;
