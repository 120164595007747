import React, { FC } from "react";
import NoResults from "../../../../components/noResults/NoResults";
import { ThreatDefenceEmailTemplatesNoResultsProps } from "../types";
import { ReactComponent as NoEmailTemplates } from "../../../../images/assets-icon.svg";
import NoSearchResults from "../../../../components/noSearchResults";

const noEmailTemplatesCreated = (createButton: React.ReactElement | null | undefined): React.ReactElement => {
  const description = (
    <span>
      Looks like you don't have any email templates for this group.
      <br />
      Add some using the button below.
    </span>
  );

  return (
    <NoResults title="You don’t have any email templates" description={description} icon={<NoEmailTemplates />}>
      {createButton}
    </NoResults>
  );
};

const ThreatDefenceEmailTemplatesNoResults: FC<ThreatDefenceEmailTemplatesNoResultsProps> = ({
  createButton,
  filtered,
}): React.ReactElement => (filtered ? <NoSearchResults /> : noEmailTemplatesCreated(createButton));

export default ThreatDefenceEmailTemplatesNoResults;
