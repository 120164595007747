import { useCallback } from "react";
import AnswersBuilderBase from "../../../../Common/Answer/AnswersBuilders/AnswersBuilderBase";
import { ValidationContext, IAnswerBase, ValidationValueBase } from "../../../../Common/models";
import { AnswersBuilderPropsBase } from "../../../../Common/props";
import { AnswersMap } from "./Providers/AnswersMap";
import { getAnswerDefaultData } from "./Providers/defaultAnswerProvider";
import { SurveyQuestionOtherAnswerOptions } from "./SurveyQuestionOtherAnswerOptions";
import { ISurveyQuestion } from "./types";
import { SurveyAnswerType } from "../../../types/models";
import { AddSurveyAnswerButton } from "./Answers/AddSurveyAnswerButton";

export const SurveyAnswersBuilder = (props: AnswersBuilderPropsBase<ISurveyQuestion<any>>) => {
  const { onBlur, onChanged, disabled, question } = props;
  const renderAnswer = useCallback(
    (
      answer: IAnswerBase,
      index: number,
      validatedFieldProps: ValidationContext<ValidationValueBase<ISurveyQuestion<any>>>,
      propertyNamePrefix: string,
    ) => {
      const { Component } = AnswersMap[answer.answerType as SurveyAnswerType];
      return (
        <Component
          answer={answer}
          index={index}
          validatedFieldProps={validatedFieldProps}
          disabled={disabled}
          onBlur={onBlur}
          onChanged={onChanged}
          propertyNamePrefix={propertyNamePrefix}
        />
      );
    },
    [disabled, onBlur, onChanged],
  );

  const renderAdditionalAnswerOptions = useCallback(
    (validatedFieldProps: ValidationContext<ValidationValueBase<ISurveyQuestion<any>>>, propertyNamePrefix: string) => {
      return question.otherAnswerOptions?.includeOtherAsAnswer ? (
        <SurveyQuestionOtherAnswerOptions
          onBlur={onBlur}
          onChanged={onChanged}
          validatedFieldProps={validatedFieldProps}
          otherAnswerOptions={question.otherAnswerOptions}
          disabled={disabled}
          propertyNamePrefix={propertyNamePrefix}
        />
      ) : null;
    },
    [disabled, onBlur, onChanged, question.otherAnswerOptions],
  );

  const renderAddButton = useCallback(
    (onClick: () => void) => {
      return <AddSurveyAnswerButton disabled={disabled} onAddAnswer={onClick} question={question} />;
    },
    [question, disabled],
  );

  return (
    <AnswersBuilderBase
      renderAddAnswerButton={renderAddButton}
      renderAnswer={renderAnswer}
      renderAdditionalOptions={renderAdditionalAnswerOptions}
      defaultDataProvider={getAnswerDefaultData}
      {...props}
    />
  );
};
