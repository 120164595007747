import TriggerType from "../../../../../../../../enums/flowDesigner/triggerType";
import { Trigger } from "../../../../types";
import { DropdownItemProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";

export const getTooltip = (hasBeenPublished: boolean) => ({
  info: hasBeenPublished
    ? "This asset action is automatically applied if this asset is deleted."
    : "This Trigger is automatically applied if this asset is deleted. To modify it, you must first publish this flow.",
  width: 22,
});

export const defaultText = "Publish this flow to adjust this setting";

export const TriggerToString: { [key in TriggerType]: string } = {
  [TriggerType.Completion]: "Completion",
  [TriggerType.NotConsumed]: "No Activity",
  [TriggerType.Open]: "Open",
  [TriggerType.ClickThrough]: "Click Through",
  [TriggerType.Pass]: "Pass",
  [TriggerType.Fail]: "Fail",
  [TriggerType.Registered]: "Registered",
  [TriggerType.Response]: "Response",
  [TriggerType.Sent]: "Sent",
};
const mapToOption = (trigger: Trigger, index: number): DropdownItemProps => ({
  text: trigger.bulletId ? `${TriggerToString[trigger.typeId]}-${index + 1}` : TriggerToString[trigger.typeId],
  value: `${trigger.typeId}-${trigger.bulletId}`,
});

export const getTriggersInfo = (
  triggers: Trigger[],
): { options: DropdownItemProps[]; selected: string | undefined } => {
  const defaultTrigger = triggers.find((trigger) => trigger.isDefault);
  return {
    options: triggers.map(mapToOption),
    selected: defaultTrigger ? `${defaultTrigger.typeId}-${defaultTrigger.bulletId}` : undefined,
  };
};
