import { Component } from "react";
import isEmpty from "lodash/isEmpty";
import sortDirections from "../../../../../enums/sortDirections";
import RolesNoResults from "../../../../../features/People/RoleList/RolesNoResults";
import { ListViewWithHeaderAndPanel } from "../../../../listView";
import RolesFilterForm from "../../../../filterForms/RolesFilterForm/RolesFilterForm";
import AddToRoleRow from "./AddToRoleRow/AddToRoleRow";

import "../../../../../components/assignmentModals/assignmentListStep.scss";
import "./roleListStep.scss";
import { isNil } from "lodash";
import { Filters } from "../../../../../utils/queryUtils";
import { ModalWithStepsStep } from "../../../../modal/ModalWithSteps";
import { UserRolesAssignment } from "../../RolesAssignmentModal";
import { ColumnOptions, Role } from "../../../../../interfaces";
import Restricted from "../../../../../features/Application/Restricted";
import AccessRestrictedMessage from "../../../../restrictedRoute/AccessRestrictedMessage";
import { RolePermissions } from "../../../../../enums";

interface RoleListStepProps extends ModalWithStepsStep {
  rolesCount: number;
  dataLoader: () => void;
  roles: Role[];
  accountId: number;
  userRolesAssignments: UserRolesAssignment[];
  selectedUserIds: number[];
  isLoading: boolean;
  appliedFilter: Filters;
  selectedRoleIds: number[];
  filterOptions: Filters;
  onSelectedListItemsChanged: (selectedIds: number[]) => void;
  createRoleButton?: React.ReactElement;
  filterActions: any;
  applyFilter: (filter: Filters) => void;
  resetFilter: () => void;
  hasPermissionPredicate: (userPermissions: RolePermissions[]) => boolean;
}

export const columnOptions: ColumnOptions[] = [
  {
    name: " ",
    width: 1,
    isSortable: false,
  },
  {
    name: "Name",
    width: 5,
    isSortable: true,
  },
  {
    name: "Users",
    width: 2,
    isSortable: false,
  },
  {
    name: "Permissions",
    width: 4,
    isSortable: false,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export default class RoleListStep extends Component<RoleListStepProps> {
  getFilterOptions = () => {
    const { accountId, filterActions } = this.props;
    filterActions.fetchFilterOptions(accountId);
  };

  componentDidMount() {
    this.getFilterOptions();
  }

  buildTableBody = (role: Role) => {
    const { userRolesAssignments, selectedUserIds } = this.props;
    return (
      <AddToRoleRow
        role={role}
        userRolesAssignments={userRolesAssignments}
        issuingUsersCount={selectedUserIds.length}
        columnOptions={columnOptions}
      />
    );
  };

  renderFilterForm = () => <RolesFilterForm isModalAppearance={true} />;

  isSelectDisabled = (r: Role) => r.isAssigned;

  isWarning = (r: Role) => r.hasAssignments;

  getRolesForAssign = () => {
    const { roles = [], userRolesAssignments, selectedUserIds } = this.props;

    return roles.map((role) => {
      let hasAssignments = false;
      let assignedCount = 0;

      selectedUserIds.forEach((userId) => {
        const isUserAssigned = !isNil(
          userRolesAssignments.find((x) => x.userId === userId && x.roleIds.includes(role.id)),
        );

        if (isUserAssigned) {
          assignedCount++;
        }

        hasAssignments = hasAssignments || isUserAssigned;
      });

      return {
        ...role,
        isAssigned: assignedCount === selectedUserIds.length,
        hasAssignments,
      };
    });
  };

  render() {
    const {
      rolesCount,
      isLoading,
      onSelectedListItemsChanged,
      filterOptions,
      appliedFilter,
      selectedRoleIds,
      applyFilter,
      resetFilter,
      createRoleButton,
      dataLoader,
      hasPermissionPredicate,
    } = this.props;

    const assignedRoles = this.getRolesForAssign();

    return (
      <Restricted
        permissions={[]}
        placeholder={<AccessRestrictedMessage />}
        permissionPredicate={(userPermissions, _) => hasPermissionPredicate(userPermissions)}
      >
        <div className="assignment-list-step role-assignment-list">
          <ListViewWithHeaderAndPanel
            columnOptions={columnOptions}
            searchPlaceholder="Search for Roles..."
            noResultsContent={<RolesNoResults createRoleButton={createRoleButton} filtered={!isEmpty(appliedFilter)} />}
            loadPage={dataLoader}
            itemsAmount={rolesCount}
            isLoading={isLoading}
            items={assignedRoles}
            buildTableBody={this.buildTableBody}
            onSelectedListItemsChanged={onSelectedListItemsChanged}
            renderFilterForm={this.renderFilterForm}
            applyFilter={applyFilter}
            filter={appliedFilter}
            resetFilter={resetFilter}
            filterOptions={filterOptions}
            filterOptionsLoading={filterOptions.isLoading}
            getFilterOptions={this.getFilterOptions}
            selectedItemIds={selectedRoleIds}
            sortingDirection={sortDirections.Asc}
            sortingColumnName={columnOptions[1].name}
            isSelectDisabled={this.isSelectDisabled}
            isWarning={this.isWarning}
          />
        </div>
      </Restricted>
    );
  }
}
