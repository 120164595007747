import { Component } from "react";
import PropTypes from "prop-types";
import isString from "lodash/isString";
import cn from "classnames";
import { DropdownWrapper as Dropdown } from "../dropdowns";
import ValidatedField from "./ValidatedField";

class DropdownField extends Component {
  handleChange = async (event, data) => {
    const {
      shouldValidate,
      propertyName,
      onChangeCallback,
      setFieldTouched,
      setFieldValue,
      onFieldValueChanged,
      defaultValue,
    } = this.props;

    await setFieldTouched(propertyName, true, false);
    const value = DropdownField.isWhiteSpace(data.value) ? defaultValue : data.value;
    await setFieldValue(propertyName, value, shouldValidate);
    onChangeCallback && onChangeCallback(event, data);
    onFieldValueChanged && onFieldValueChanged(event, data);
  };

  handleAddItem = async (event, data) => {
    await this.handleChange(event, data);

    const { onItemAdded } = this.props;

    if (onItemAdded && !DropdownField.isWhiteSpace(data.value)) {
      onItemAdded(event, data);
    }
  };

  render() {
    const {
      value,
      propertyName,
      handleBlur,
      placeholder,
      search,
      minCharacters,
      onSearchChange,
      searchQuery,
      multiple,
      clearable,
      lazyLoad,
      disabled,
      isLoading,
      renderLabel,
      allowAdditions,
      defaultSearchQuery,
      options,
      testID,
      children,
      className,
      trigger,
      noResultsMessage,
    } = this.props;

    return (
      <ValidatedField {...this.props}>
        <Dropdown
          noResultsMessage={noResultsMessage}
          fluid
          search={!disabled && (search || null)}
          selection
          minCharacters={minCharacters || null}
          // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625
          value={value === undefined ? "" : value}
          id={propertyName}
          name={propertyName}
          handleAddItem={this.handleAddItem}
          blur
          handleOptionChange={this.handleChange}
          onBlur={handleBlur}
          options={options}
          placeholder={placeholder}
          data-testid={testID}
          className={cn("field", className)}
          searchQuery={searchQuery}
          onSearchChange={onSearchChange}
          multiple={multiple}
          clearable={clearable && !disabled}
          lazyLoad={lazyLoad}
          disabled={disabled}
          loading={isLoading}
          renderLabel={renderLabel}
          allowAdditions={allowAdditions}
          defaultSearchQuery={defaultSearchQuery}
          trigger={trigger}
        />
        {children}
      </ValidatedField>
    );
  }

  static isWhiteSpace(value) {
    return isString(value) && value.trim() === "";
  }
}

DropdownField.defaultProps = {
  label: "",
  options: [],
  shouldValidate: true,
  isLoading: false,
  testID: "dropdown-field",
  defaultValue: "",
};

DropdownField.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  propertyName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  onItemAdded: PropTypes.func,
  onChangeCallback: PropTypes.func,
  handleBlur: PropTypes.func,
  shouldValidate: PropTypes.bool,
  isLoading: PropTypes.bool,
  clearable: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  renderLabel: PropTypes.func,
  allowAdditions: PropTypes.bool,
  defaultSearchQuery: PropTypes.string,
  testID: PropTypes.string,
  className: PropTypes.string,
  onFieldValueChanged: PropTypes.func,
};

export default DropdownField;
