import { combineReducers } from "@reduxjs/toolkit";
import { createReducer } from "../../../../../utils/reduxUtils";
import * as actionTypes from "../actionTypes/packListingActionTypes";
import { PackListingState, PackListingView } from "../../types/state";
import { PayloadAction } from "../../../../../interfaces/redux";
import ownAccountInfoSlice from "../slices/ownAccountInfoSlice";

const initialState: PackListingState = {
  pack: {
    id: -1,
    title: "",
    longDescription: "",
    overviewImageUrl: "",
    thumbnailImageUrl: "",
    assetsCount: 100,
    flowsCount: 50,
    isDraft: false,
    isOwn: false,
    publisherId: -1,
    dateModified: undefined,
    isPurchased: false,
    isRequested: false,
    hasBeenPublished: false,
    mediaItems: [],
    isTrialAllowed: false,
    isExpired: false,
    isTrial: false,
    expirationDate: "",
    trialDuration: 0,
  },
  isLoading: false,
  isPackLoaded: false,
  packRequestModal: {
    show: false,
  },
};

const getHandlers = (failureHandler: Function) => {
  const {
    showPackRequestModal,
    hidePackRequestModal,
    getPackOverviewBegin,
    getPackOverviewSuccess,
    getPackOverviewFailure,
    requestPackBegin,
    requestPackSuccess,
    requestPackFailure,
    enablePackTrialBegin,
    enablePackTrialSuccess,
    enablePackTrialFailure,
    enablePackBegin,
    enablePackSuccess,
    enablePackFailure,
  } = actionTypes;

  const showPackRequestModalHandler = (state: PackListingState): PackListingState => ({
    ...state,
    packRequestModal: {
      show: true,
    },
  });

  const hidePackRequestModalHandler = (state: PackListingState): PackListingState => ({
    ...state,
    packRequestModal: {
      show: false,
    },
  });

  const getPackBeginHandler = (state: PackListingState): PackListingState => ({
    ...state,
    pack: initialState.pack,
    isLoading: true,
    packRequestModal: {
      show: false,
    },
  });

  const getPackSuccessHandler = (
    state: PackListingState,
    action: PayloadAction<PackListingView>,
  ): PackListingState => ({
    ...state,
    pack: {
      ...state.pack,
      ...action.payload,
    },
    isPackLoaded: true,
    isLoading: false,
    error: undefined,
  });

  const requestPackBeginHandler = (state: PackListingState): PackListingState => ({
    ...state,
    isLoading: true,
  });

  const requestPackSuccessHandler = (
    state: PackListingState,
    action: PayloadAction<PackListingView>,
  ): PackListingState => {
    return {
      ...state,
      pack: {
        ...state.pack,
        ...action.payload,
        isRequested: true,
      },
      isLoading: false,
    };
  };

  const purchasedPackBeginHandler = (state: PackListingState): PackListingState => ({
    ...state,
    isLoading: true,
  });

  const purchasedPackSuccessHandler = (
    state: PackListingState,
    action: PayloadAction<PackListingView>,
  ): PackListingState => {
    return {
      ...state,
      pack: {
        ...state.pack,
        ...action.payload,
        isPurchased: true,
      },
      isLoading: false,
    };
  };

  return {
    [getPackOverviewBegin]: getPackBeginHandler,
    [getPackOverviewSuccess]: getPackSuccessHandler,
    [getPackOverviewFailure]: failureHandler,
    [requestPackBegin]: requestPackBeginHandler,
    [requestPackSuccess]: requestPackSuccessHandler,
    [requestPackFailure]: failureHandler,
    [enablePackTrialBegin]: requestPackBeginHandler,
    [enablePackTrialSuccess]: requestPackSuccessHandler,
    [enablePackTrialFailure]: failureHandler,
    [showPackRequestModal]: showPackRequestModalHandler,
    [hidePackRequestModal]: hidePackRequestModalHandler,
    [enablePackBegin]: purchasedPackBeginHandler,
    [enablePackSuccess]: purchasedPackSuccessHandler,
    [enablePackFailure]: failureHandler,
  };
};

const packDetailsHandlers = () => {
  const failureHandler = (state: PackListingState, action: PayloadAction<Error>): PackListingState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  return {
    ...getHandlers(failureHandler),
  };
};

export const packListingReducer = combineReducers({
  details: createReducer(initialState, [packDetailsHandlers]),
  ownAccountInfo: ownAccountInfoSlice,
});
