import { AxiosResponse } from "axios";
import { EventRegistration, SessionReporting } from "../types/state";

export const getDelayedFunc = (delay: number, func: () => AxiosResponse<SessionReporting[]>) =>
  new Promise<AxiosResponse<SessionReporting[]>>((resolve) => {
    setTimeout(() => resolve(func()), delay);
  });

export const sessionReportingItems: SessionReporting[] = [
  {
    id: 1,
    startDate: "3/16/2021",
    startsAt: "12:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 2,
    startDate: "3/23/2021",
    startsAt: "5:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 3,
    startDate: "10/23/2021",
    startsAt: "10:30 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 4,
    startDate: "10/16/2021",
    startsAt: "9:30 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 5,
    startDate: "10/5/2021",
    startsAt: "10:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 6,
    startDate: "10/5/2021",
    startsAt: "10:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 7,
    startDate: "11/16/2021",
    startsAt: "5:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 8,
    startDate: "10/16/2021",
    startsAt: "5:00 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 9,
    startDate: "10/23/2021",
    startsAt: "5:00 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 10,
    startDate: "12/23/2021",
    startsAt: "8:30 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 11,
    startDate: "12/23/2021",
    startsAt: "8:30 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 12,
    startDate: "12/16/2021",
    startsAt: "10:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 13,
    startDate: "12/16/2021",
    startsAt: "10:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 14,
    startDate: "5/5/2021",
    startsAt: "10:00 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 15,
    startDate: "5/23/2021",
    startsAt: "9:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 16,
    startDate: "9/23/2021",
    startsAt: "9:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 17,
    startDate: "9/5/2021",
    startsAt: "7:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 18,
    startDate: "9/16/2021",
    startsAt: "5:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 19,
    startDate: "9/23/2021",
    startsAt: "5:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 20,
    startDate: "4/23/2021",
    startsAt: "5:00 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 21,
    startDate: "4/16/2021",
    startsAt: "4:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 22,
    startDate: "4/5/2021",
    startsAt: "8:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 23,
    startDate: "8/5/2021",
    startsAt: "7:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 24,
    startDate: "7/30/2021",
    startsAt: "7:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 25,
    startDate: "7/23/2021",
    startsAt: "1:30 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 26,
    startDate: "7/23/2021",
    startsAt: "8:30 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 27,
    startDate: "8/30/2021",
    startsAt: "8:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 28,
    startDate: "1/16/2021",
    startsAt: "1:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 29,
    startDate: "1/16/2021",
    startsAt: "4:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 30,
    startDate: "1/16/2021",
    startsAt: "4:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 31,
    startDate: "4/16/2021",
    startsAt: "4:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 32,
    startDate: "4/5/2021",
    startsAt: "8:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 33,
    startDate: "8/5/2021",
    startsAt: "7:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 34,
    startDate: "7/30/2021",
    startsAt: "7:00 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 35,
    startDate: "7/23/2021",
    startsAt: "1:30 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 36,
    startDate: "7/23/2021",
    startsAt: "8:30 AM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 37,
    startDate: "8/30/2021",
    startsAt: "8:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
  {
    id: 38,
    startDate: "1/16/2021",
    startsAt: "1:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Pending",
  },
  {
    id: 39,
    startDate: "1/16/2021",
    startsAt: "4:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "Completed",
  },
  {
    id: 40,
    startDate: "1/16/2021",
    startsAt: "4:30 PM EST",
    duration: "1:00",
    registrations: 7,
    attendees: 5,
    status: "In Progress",
  },
];

export const eventRegistrations: EventRegistration[] = [
  {
    id: 0,
    name: "Steve Smith",
    email: "smith@corp.com",
    account: "Next1",
    sessionTime: "11:23 ET",
    flowName: "Flow1",
    registrationDate: "11:05 EET",
    status: "Waiting1",
  },
  {
    id: 1,
    name: "Abdula Grey",
    email: "abdula@next.com",
    account: "Next2",
    sessionTime: "11:25 EET",
    flowName: "Flow2",
    registrationDate: "09:05 EET",
    status: "Waiting2",
  },
  {
    id: 2,
    name: "Ivan Ivanenko",
    email: "ivan@corp.com",
    account: "Next3",
    sessionTime: "11:07 EET",
    flowName: "Flow3",
    registrationDate: "08:05 EET",
    status: "In Past1",
  },
  {
    id: 3,
    name: "Oleg Olegenko",
    email: "oleg@next.com",
    account: "Next4",
    sessionTime: "11:55 EET",
    flowName: "Flow4",
    registrationDate: "11:05 EET",
    status: "Waiting4",
  },
  {
    id: 4,
    name: "Steve Bob",
    email: "steve.bob@corp.com",
    account: "Next5",
    sessionTime: "11:23 EET",
    flowName: "Flow5",
    registrationDate: "11:05 EET",
    status: "Waiting5",
  },
  {
    id: 5,
    name: "Abdula Ahmet",
    email: "abdula@next.com",
    account: "Next6",
    sessionTime: "09:25 EET",
    flowName: "Flow6",
    registrationDate: "10:05 EET",
    status: "Waiting6",
  },
  {
    id: 6,
    name: "Vasyl Vasylenko",
    email: "vasyl@corp.com",
    account: "Next",
    sessionTime: "15:07 EET",
    flowName: "Flow7",
    registrationDate: "18:05 EET",
    status: "In Past",
  },
  {
    id: 7,
    name: "Maryna Marynenko",
    email: "maryna@next.com",
    account: "Next8",
    sessionTime: "12:25 EET",
    flowName: "Flow8",
    registrationDate: "12:35 EET",
    status: "Waiting1",
  },
  {
    id: 8,
    name: "Steve Red",
    email: "csteve.red@corp.com",
    account: "Next9",
    sessionTime: "08:23 EET",
    flowName: "Flow9",
    registrationDate: "09:35 EET",
    status: "Waiting9",
  },
  {
    id: 9,
    name: "John Smith",
    email: "john.smith@next.com",
    account: "Next10",
    sessionTime: "17:25 EET",
    flowName: "Flow10",
    registrationDate: "09:05 EET",
    status: "Waiting3",
  },
  {
    id: 10,
    name: "Monica brown",
    email: "monica.brown@corp.com",
    account: "Next11",
    sessionTime: "11:11 EET",
    flowName: "Flow11",
    registrationDate: "08:11 EET",
    status: "In Past11",
  },
  {
    id: 12,
    name: "Mary Jones",
    email: "mary@next.com",
    account: "Next12",
    sessionTime: "11:12 EET",
    flowName: "Flow12",
    registrationDate: "11:12 EET",
    status: "Waiting12",
  },
];
