import { ContentTypesEnum } from "enums";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { fetchBegin, fetchSuccess } from "../TemplateContentSlice";
import DataService from "features/Application/services/dataServices/typedDataService";
import { videoFilterTypes } from "features/Library/Videos/types/models";
import { assessmentFilterTypes } from "features/Library/Assessments/types/state";
import { pdfFilterTypes } from "features/Library/Pdfs/types/models";
import { surveyFilterTypes } from "features/Library/Surveys/types/models";
import { flowFilterTypes } from "features/Library/Flows/types/models";
import { eventFilterTypes } from "features/Library/Events/types/state";
import { formatFilters } from "utils/filterMapUtils";
import { ContentResponse } from "../../DataService/types";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

const contentMap: { [key in ContentTypesEnum]?: { contentType: string; filterTypes: any } } = {
  [ContentTypesEnum.Videos]: { contentType: "video", filterTypes: videoFilterTypes },
  [ContentTypesEnum.Assessments]: { contentType: "assessment", filterTypes: assessmentFilterTypes },
  [ContentTypesEnum.PDFs]: { contentType: "pdf", filterTypes: pdfFilterTypes },
  [ContentTypesEnum.Surveys]: { contentType: "survey", filterTypes: surveyFilterTypes },
  [ContentTypesEnum.Flows]: { contentType: "flow", filterTypes: flowFilterTypes },
  [ContentTypesEnum.Events]: { contentType: "externalEvent", filterTypes: eventFilterTypes },
};

export const fetchTemplateContent =
  (
    templateId: number,
    content: ContentTypesEnum,
    skip: number,
    top: number,
    sortBy: string,
    sortOrder: string,
    append: boolean,
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(fetchBegin());
    const state = getState().people.groupTemplate;
    const { filterTypes, contentType } = contentMap[content]!;
    const filtersState = state.content.filters;
    const isPreview = state.base.isPreview;
    const filters = formatFilters(filtersState.appliedFilter, filterTypes);
    const request = {
      skip,
      top,
      sortBy,
      sortOrder,
      term: filtersState.search,
      ...filters,
    };
    try {
      let data: ContentResponse[] = [];
      let count: number = 0;
      if (content === ContentTypesEnum.Flows) {
        const flows = await DataService.groupTemplates.getFlows(templateId, !!isPreview, request);
        data = flows.data;
        count = flows.headers[countHeaderName];
      } else {
        const response = await DataService.groupTemplates.getContent(templateId, contentType, request);
        count = response.headers[countHeaderName];
        data = response.data.map((x) => ({ ...x, ...x.bag }));
      }
      dispatch(fetchSuccess({ items: data, totalCount: Number(count), append }));
    } catch (error) {
      // Handle
    }
  };
