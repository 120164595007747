import { FC } from "react";
import { Grid } from "semantic-ui-react";

import { EnablePackModaWithFormProps } from "./types";
import { TermField } from "./fields/TermField";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { RouteNames } from "../../../../enums";
import { LicensesCountField } from "./fields";
import { Link } from "./Link";

import styles from "./requestOrEnablePackForm.module.scss";

const accountHomeLegalPath = `/${RouteNames.accounts}/home/legal`;

export const EnablePackForm: FC<EnablePackModaWithFormProps> = (props) => {
  const { packTitle } = props;

  const onExpirationDateChange = (licenseType?: number, date?: Date | null) => {
    if (licenseType) {
      props.values.licenseType = licenseType;
    }
    if (date) {
      props.values.expirationDate = date;
    }
  };

  const getPackAgreementInfo = () => {
    const expirationDate = props.values.expirationDate;
    return (
      <div className={styles["agreement-info"]}>
        <p>
          You are purchasing the <b>{packTitle}</b> pack. This purchase is governed by the&nbsp;
          <Link to={accountHomeLegalPath} text="agreement you have on file" />
          &nbsp;with BrainStorm. Access to this pack will expire on the&nbsp;
          <b>{dateTimeUtils.formatDate(expirationDate)}</b> if not renewed.
        </p>
      </div>
    );
  };

  return (
    <div className="pack-request-container">
      <Grid stackable rows="equal" verticalAlign="bottom">
        <LicensesCountField {...props} />
        <TermField {...props} setExpirationDate={onExpirationDateChange} />
        {getPackAgreementInfo()}
      </Grid>
    </div>
  );
};

export default EnablePackForm;
