import { Dispatch } from "@reduxjs/toolkit";
import { GetContentUsersParams } from "../../../../People/types";
import flowsDataService from "../../services/flowsDataService";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slices/flowGroupsSlice";
import userListUtils from "../../../../../utils/userListUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const fetchFlowGroups =
  ({ entityId, skip, top, sortingColumn, sortingDirection, filter, term }: GetContentUsersParams) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const { filterParams } = userListUtils.getFetchUsersParams(term, sortingColumn, sortingDirection, filter);

      const { data, headers } = await flowsDataService.getFlowGroupsV2(
        {
          entityId,
          skip,
          top,
          filter: filterParams,
          term,
        }
      );
      data.forEach((item: any) => (item.id = item.groupId));
      dispatch(fetchSuccess({ items: data, totalCount: Number(headers[countHeaderName]) }));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
