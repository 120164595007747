import { AxiosResponse } from "axios";
import { FlowFiltersEnum, flowFilterTypes } from "features/Library/Flows/types/models";
import { AddPeopleToContentTypes, AssignmentPeopleContext } from "../../../../enums";
import { FilterTypesMap, GenericFiltersMap } from "../../../../utils/filterUtils";
import { escapeTerm } from "../../../../utils/searchUtils";
import { formatSortingV2api } from "../../../../utils/sortingUtils";
import { AppDispatch, AppThunk, RootState } from "../../../Application/globaltypes/redux";
import DataService from "../../../Application/services/dataServices/typedDataService";
import { AvailableItemBase } from "../models";
import { fetchBegin, fetchFailure, fetchSuccess } from "./contentAssignmentsSlice";
import { SurveyFiltersEnum, surveyFilterTypes } from "../../../Library/Surveys/types/models";
import { contentContextSelector } from "../../state/slices/addToContentSlice";
import { EventFilterPropertyNames, eventFilterTypes } from "../../../Library/Events/types/state";
import { formatFilters } from "../../../../utils/filterMapUtils";
import { VideoFiltersEnum, videoFilterTypes } from "../../../Library/Videos/types/models";
import { AssessmentFiltersEnum, assessmentFilterTypes } from "../../../Library/Assessments/types/state";
import { PdfFiltersEnum, pdfFilterTypes } from "../../../Library/Pdfs/types/models";

const takeCount = Number(process.env.REACT_APP_LOAD_ITEMS_COUNT) || 30;
const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export interface AvailableItemsPayload<TFilters extends string | number> {
  userIds: number[];
  search?: string;
  skip: number;
  orderBy: string;
  filterParams: GenericFiltersMap<TFilters>;
}

export const fetchPeopleAvailableItems =
  <TFilters extends string | number>(
    payload: AvailableItemsPayload<TFilters>,
    context: AssignmentPeopleContext,
  ): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const map = {
      [AddPeopleToContentTypes.Videos]: () =>
        fetchPeopleAvailableVideos(payload as AvailableItemsPayload<VideoFiltersEnum>, context),

      [AddPeopleToContentTypes.Events]: () =>
        fetchPeopleAvailableEvents(payload as AvailableItemsPayload<any>, context),

      [AddPeopleToContentTypes.Flows]: () =>
        fetchPeopleAvailableFlows(payload as AvailableItemsPayload<FlowFiltersEnum>, context),

      [AddPeopleToContentTypes.Surveys]: () =>
        fetchPeopleAvailableSurveys(payload as AvailableItemsPayload<SurveyFiltersEnum>, context),

      [AddPeopleToContentTypes.Assessments]: () =>
        fetchPeopleAvailableAssessments(payload as AvailableItemsPayload<AssessmentFiltersEnum>, context),

      [AddPeopleToContentTypes.PDFs]: () =>
        fetchPeopleAvailablePdfs(payload as AvailableItemsPayload<PdfFiltersEnum>, context),
    };

    await dispatch(map[contentContextSelector(getState())]());
  };

const fetchPeopleAvailableVideos =
  (payload: AvailableItemsPayload<VideoFiltersEnum>, context: AssignmentPeopleContext) =>
  async (dispatch: AppDispatch) => {
    const { userIds } = payload;

    const request = (() => {
      const map = {
        [AssignmentPeopleContext.Group]: DataService.groups.getGroupAvailableVideos,
        [AssignmentPeopleContext.User]: DataService.users.getUserAvailableVideos,
      };
      return () => map[context](userIds, setConfig(payload, videoFilterTypes));
    })();

    await dispatch(doFetch(request, AddPeopleToContentTypes.Videos));
  };

const fetchPeopleAvailableFlows =
  (payload: AvailableItemsPayload<FlowFiltersEnum>, context: AssignmentPeopleContext): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { userIds } = payload;

    const request = (() => {
      const map = {
        [AssignmentPeopleContext.Group]: DataService.groups.getGroupAvailableFlowsV4,
        [AssignmentPeopleContext.User]: DataService.users.getUserAvailableFlowsV4,
      };
      return () => map[context](userIds, setConfig(payload, flowFilterTypes));
    })();

    await dispatch(doFetch(request, AddPeopleToContentTypes.Flows));
  };

const fetchPeopleAvailableSurveys =
  (payload: AvailableItemsPayload<SurveyFiltersEnum>, context: AssignmentPeopleContext): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { userIds } = payload;

    const request = (() => {
      const map = {
        [AssignmentPeopleContext.Group]: DataService.groups.getGroupAvailableSurveys,
        [AssignmentPeopleContext.User]: DataService.users.getUserAvailableSurveys,
      };
      return () => map[context](userIds, setConfig(payload, surveyFilterTypes));
    })();

    await dispatch(doFetch(request, AddPeopleToContentTypes.Surveys));
  };

const fetchPeopleAvailablePdfs =
  (payload: AvailableItemsPayload<PdfFiltersEnum>, context: AssignmentPeopleContext): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { userIds } = payload;

    const request = (() => {
      const map = {
        [AssignmentPeopleContext.Group]: DataService.groups.getGroupAvailablePdfs,
        [AssignmentPeopleContext.User]: DataService.users.getUserAvailablePdfs,
      };
      return () => map[context](userIds, setConfig(payload, pdfFilterTypes));
    })();

    await dispatch(doFetch(request, AddPeopleToContentTypes.PDFs));
  };

export const fetchPeopleAvailableEvents =
  (payload: AvailableItemsPayload<EventFilterPropertyNames>, context: AssignmentPeopleContext) =>
  async (dispatch: AppDispatch) => {
    const { userIds } = payload;

    const request = (() => {
      const map = {
        [AssignmentPeopleContext.Group]: DataService.groups.getGroupAvailableEvents,
        [AssignmentPeopleContext.User]: DataService.users.getUserAvailableEvents,
      };
      return () => map[context](userIds, setConfig(payload, eventFilterTypes));
    })();

    await dispatch(doFetch(request, AddPeopleToContentTypes.Events));
  };

const fetchPeopleAvailableAssessments =
  (payload: AvailableItemsPayload<AssessmentFiltersEnum>, context: AssignmentPeopleContext): AppThunk =>
  async (dispatch: AppDispatch) => {
    const { userIds } = payload;

    const request = (() => {
      const map = {
        [AssignmentPeopleContext.Group]: DataService.groups.getGroupAvailableAssessments,
        [AssignmentPeopleContext.User]: DataService.users.getUserAvailableAssessments,
      };
      return () => map[context](userIds, setConfig(payload, assessmentFilterTypes));
    })();

    await dispatch(doFetch(request, AddPeopleToContentTypes.Assessments));
  };

const doFetch =
  (request: () => Promise<AxiosResponse<AvailableItemBase[]>>, context: AddPeopleToContentTypes): AppThunk =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(fetchBegin());

    try {
      const result = await request();

      //data should not be re-writed by outdated requests
      if (contentContextSelector(getState()) !== context) {
        return;
      }

      dispatch(fetchSuccess({ items: result.data, totalCount: Number.parseInt(result.headers[countHeaderName]) }));
    } catch (error: any) {
      const err = error as Error;
      dispatch(fetchFailure({ message: err.message, name: err.name }));
    }
  };

const setConfig = <TFilters extends string | number>(
  payload: AvailableItemsPayload<TFilters>,
  filterTypes: FilterTypesMap<TFilters>,
) => {
  const { filterParams, orderBy, skip, search } = payload;

  const { sortBy, sortOrder } = formatSortingV2api(orderBy);
  return {
    skip,
    top: takeCount,
    ...formatFilters(filterParams, filterTypes),
    term: escapeTerm(search),
    sortBy,
    sortOrder,
    showPurchased: true,
  };
};
