import { AppDispatch } from "../../../../../Application/globaltypes/redux";
import { fetchSuccess, fetchFailure } from "../slices/flowDesignerTriggersSlice";

import flowsDataService from "../../../services/flowsDataService";

export const fetchTriggers = () => async (dispatch: AppDispatch) => {
  try {
    const [triggerTypesResponse, triggerTimeUnitsResponse] = await Promise.all([
      flowsDataService.getTriggerTypes(),
      flowsDataService.getTimeUnits(),
    ]);

    dispatch(
      fetchSuccess({
        triggerTypes: triggerTypesResponse.data,
        triggerTimeUnits: triggerTimeUnitsResponse.data,
      }),
    );
  } catch (e) {
    dispatch(fetchFailure(e));
  }
};
