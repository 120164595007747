import { keyBy } from "lodash";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/globaltypes/redux";
import { TagsPayloadCamelPlural } from "../../../../interfaces/Tags";
import { mapTagsToView } from "../../Common/helpers/tagsHelper";
import { PdfFormFields } from "../Details/types";
import { PdfDetailsState } from "../types/state";

const getItems = (state: RootState) => pdfsStateSelector(state).overview.pdfs.items;

export const pdfsStateSelector = (state: RootState) => state.library.pdfs;

export const pdfsTagsSelector = createSelector(
  [(state: RootState): TagsPayloadCamelPlural => pdfsStateSelector(state).base.tagsBase.tags],
  mapTagsToView,
);

export const pdfFormFieldsSelector = createSelector(
  [(state: RootState) => pdfsStateSelector(state).base.details],
  (value: PdfDetailsState): PdfFormFields => ({
    title: value.pdf.title,
    description: value.pdf.description,
    fileName: value.pdf.fileName,
    thumbnailUrl: value.pdf.thumbnailUrl,
    uploadedPdfs: value.uploadedPdfs,
  }),
);

export const pdfsAppliedFilterSelector = (state: RootState) => pdfsStateSelector(state).overview.filters.appliedFilter;

export const pdfSearchSelector = (state: RootState) => pdfsStateSelector(state).overview.filters.search;

export const pdfPaginationSelector = (state: RootState) => pdfsStateSelector(state).overview.filters.pagination;

export const pdfSortingColumnNameSelector = (state: RootState) =>
  pdfsStateSelector(state).overview.filters.sortingColumnName;

export const pdfSortingDirectionSelector = (state: RootState) =>
  pdfsStateSelector(state).overview.filters.sortingDirection;

export const normalizedPdfsSelector = createSelector([getItems], (items) => {
  return keyBy(items, (item) => item.id);
});

export const pdfDetailsSelector = (state: RootState) => pdfsStateSelector(state).base.details;

export const pdfSettingsSelector = (state: RootState) => pdfsStateSelector(state).base.details.pdf.settings;
