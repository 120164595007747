import React from "react";
import { Image } from "semantic-ui-react";

import { NextFlowInfo } from "../../../../types";
import { TextTruncate } from "../../../../../../../../components";
import { PublishedStatus } from "../../../../../../../../components/common/publishedStatus";
import { PublishedStatusTypes } from "../../../../../../../../enums";

import Tooltip from "../../../../../../../../components/common/tooltip/Tooltip";

import styles from "./nextFlowCard.module.scss";

export interface NextFlowCardProps extends NextFlowInfo {
  onRemove: () => void;
  isReadOnly: boolean;
}

const NextFlowCard = (props: NextFlowCardProps) => {
  const { title, publisher, thumbnailUrl, isReadOnly } = props;
  return (
    <div className={styles.root} data-testid="next-flow-card">
      {!isReadOnly && <div className={styles["remove-icon"]} data-testid="remove-icon" onClick={props.onRemove} />}
      <div className={styles["top-row"]}>
        <Image className={styles.thumbnail} src={thumbnailUrl} />
        <div className={styles.description}>
          <span className={styles.label}>{publisher}</span>
          <Tooltip className={styles.title} target={<TextTruncate lines={2}>{title}</TextTruncate>} content={title} />
        </div>
      </div>
      <div className={styles["bottom-row"]}>
        <PublishedStatus publishedStatus={PublishedStatusTypes.published} />
      </div>
    </div>
  );
};

export default NextFlowCard;
