type Action =
  | { type: "TOGGLE_BRANCHING_QUESTION"; payload: string }
  | { type: "TURN_OFF_BRANCHING_QUESTION"; payload: string };

export interface ContentState {
  branchingQuestionId?: string;
}

export const contentReducer = (state: ContentState, action: Action): ContentState => {
  switch (action.type) {
    case "TOGGLE_BRANCHING_QUESTION": {
      const index = action.payload;

      return { branchingQuestionId: state.branchingQuestionId === index ? undefined : index };
    }

    case "TURN_OFF_BRANCHING_QUESTION": {
      return state.branchingQuestionId === action.payload ? { branchingQuestionId: undefined } : state;
    }

    default: {
      return state;
    }
  }
};
