import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import { FormikProps, withFormik } from "formik";

import Sections from "../../../../enums/sections";
import PackConfirmationModal from "../../../../components/modal/PacksConfirmationModal/PackConfirmationModal";
import groupPermissionActions from "../../PeopleAssignments/state/actions/groupPermissionActions";
import userPermissionActions from "../../PeopleAssignments/state/actions/userPermissionActions";
import Section from "../../../../components/wizard/Section";
import FlowDesigner from "../Designer/FlowDesigner/FlowDesigner";

import HeaderValidationInfo from "../common/HeaderValidationInfo/HeaderValidationInfo";
import Observable from "../../../../utils/Observable";
import validationSchemas from "../../../../utils/validationSchemas";
import navigationUtils from "../../../../utils/navigationUtils";
import Restricted from "../../../Application/Restricted";
import ObjectUtils from "../../../../utils/objectUtils";
import Settings from "../Edit/Info/Settings";
import { WizardNew as Wizard } from "../../../../components/wizard";
import { ConfigurationForm } from "../Info/forms/ConfigurationForm/ConfigurationForm";
import { initialNotifyConfigDefault } from "../../../SystemNotifications/config";
import { AutosaveProps, withAutosave } from "../../../../utils";
import { withNotifyConfig, WithNotifyConfigProps } from "../../../SystemNotifications/containers/withNotifyConfig";
import { FlowValidator } from "../Designer/validator/FlowValidator";
import { createConfigurationInfoSelector, ConfigurationPayload } from "../Info/forms/ConfigurationForm/helper";
import { bindAction } from "../../../../interfaces";
import { getActionProvider } from "../../../Application/actions/actionsBuilder";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { resetFilter as resetGroupFilters } from "../../PeopleAssignments/state/actions/groupAssignmentActions";
import { resetFilter as resetUserFilters } from "../../PeopleAssignments/state/actions/userAssignmentActions";
import { setIsErrorModeEnabled } from "../Designer/validator/flowValidatorActionTypes";
import { publishNewFlowEntity, resetFlowEntityState } from "../state/actions/flowEntityStateActionCreators";
import { resetFlowInfo, saveDraftInfo, updateFlowInfo } from "../state/actions/infoActionCreators";
import { resetContext } from "../Designer/state/slices/flowDesignerContextSlice";
import { resetFlowBase } from "../state/slices/flowBaseSlice";
import { resetState as resetNotifyState } from "../../../SystemNotifications/state/slices/notifyStepSlice";
import { PublishedStatusTypes, RolePermissions } from "../../../../enums";
import { NotifySettings } from "../../../SystemNotifications/types";
import { CreateFlowStepConfig, FlowCreateSteps, pageIndexes } from "./config";
import { getFlowDesignerData } from "../Designer/state/thunks/flowDesignerDefinitionThunk";
import { useWizardStepsManager } from "../../../../hooks/useWizardStepsManager";
import { reset as resetDefinition } from "../Designer/state/slices/flowDesignerDefinitionSlice";
import { fetchContentPacksAction } from "../../../Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import {
  flowDesignerSelector,
  flowEntitySelector,
  flowInformationSelector,
  flowPackContextItemsSelector,
  flowValidatorSelector,
} from "../state/selectors";
import { useLocation, useNavigate } from "react-router-dom";
import { resetSearchFilters } from "views/library/flows/flowsOverview/FlowsOverview";
import Goal from "../Goal/Goal";
import { reset as resetGoal } from "../state/slices/flowGoalSlice";
import { fetchGoalOptions } from "../state/thunks/flowGoalThunk";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { Dimmer, Loader } from "semantic-ui-react";
import { useRtn } from "hooks/useRtn";
import { FlowPublishSuccess } from "features/Application/services/realTimeNotification/events/library/libraryEvents";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";

export type CreateFlowProps = FormikProps<ConfigurationPayload> & PropsFromRedux & AutosaveProps;
export type Props = CreateFlowProps & WithNotifyConfigProps<NotifySettings>;

export const CreateFlow = (props: Props) => {
  const {
    isSaveInProgress,
    configurationInfo,
    dateModified,
    flowBaseValidation,
    isValid: isConfigurationValid,
    resetSearchFilters,
    changingEntityState,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState(FlowCreateSteps.Configuration);
  const [isGoalValid, setIsGoalValid] = useState(false);
  const flowValidatorRef = useRef<FlowValidator>(new FlowValidator());
  const onTriggerPackSelectionObserver = useMemo(() => new Observable(), []);
  const [showAddPacks, setShowAddPacks] = useState(false);
  const [wizardPages, { onNext, onPrevious, goToPage }, subscribeOnActiveIndexChanged] =
    useWizardStepsManager(pageIndexes);

  const packs = useFeatureFlag(FeatureFlags.AssociatedPacks);

  useRtn(
    FlowPublishSuccess,
    useCallback(() => {
      packs && setShowAddPacks(true);
    }, [packs]),
  );

  useEffect(() => {
    const flowValidator = flowValidatorRef.current;
    return () => {
      flowValidator?.clearState();
      if (!window.location.pathname.includes("/content/flows")) resetSearchFilters();
      props.onUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = (_: any, activeStepIndex: FlowCreateSteps) => {
      setActivePage(activeStepIndex);
    };
    return subscribeOnActiveIndexChanged(handler);
  }, [subscribeOnActiveIndexChanged]);

  const onProgressAsync = (activeStepIndex: number, newStepIndex: number) => {
    onNext(activeStepIndex, newStepIndex);
    if (activeStepIndex === FlowCreateSteps.Configuration && !configurationInfo.id) {
      props.submitForm();
    }
  };

  const onRegressAsync = async (activeStepIndex: number, newStepIndex: number) => {
    onPrevious(activeStepIndex, newStepIndex);
  };

  const renderCustomHeader = (changeActiveIndex: (index: number) => void) => {
    const onErrorClick = () => {
      changeActiveIndex(FlowCreateSteps.Content);
      goToPage(FlowCreateSteps.Content);
    };

    return <HeaderValidationInfo onErrorClick={onErrorClick} />;
  };

  const onCancel = useCallback(() => {
    navigationUtils.goBackOrDefault(location, navigate, "/content/flows");
  }, [location, navigate]);

  const publishFlow = async () => {
    if (!flowBaseValidation.isFlowValid) {
      props.setFlowErrorModeEnabled({
        isErrorViewMode: true,
      });
      flowValidatorRef.current.validate(getFlowDesignerData(props.id, props.flowDefinition));
      return;
    }
    await props.publishNewFlowEntity(configurationInfo.id, undefined);

    !packs && navigate("/content/flows", { replace: true });
  };

  const triggerPackSelection = async (condition: boolean, then: () => void) => {
    if (condition) {
      const result = await props.fetchContentPacks(props.packContextItems);
      if (result?.items.some((item) => item.packs.length > 1)) {
        onTriggerPackSelectionObserver.notify(publishFlow);
      } else then();
    } else then();
  };

  const onFinishAsync = async () => {
    await triggerPackSelection(flowBaseValidation.isFlowValid && props.hasExternalTrigger, publishFlow);
  };

  return (
    <>
      <Dimmer active={changingEntityState} inverted>
        <Loader active={changingEntityState} />
      </Dimmer>
      <Wizard
        id="create-flow-wizard"
        finishButtonLabel="Finish"
        className="create-flow"
        title="Create Flow"
        isSaveInProgress={isSaveInProgress}
        publishedStatus={configurationInfo.id ? PublishedStatusTypes.draft : null}
        onProgressAsync={onProgressAsync}
        onRegressAsync={onRegressAsync}
        onCancel={onCancel}
        onFinish={onFinishAsync}
        progressSavedDate={CreateFlowStepConfig[activePage]?.showAutoSaveOnTab ? dateModified : null}
        isFinishButtonDisabled={flowBaseValidation.isErrorViewMode}
        renderCustomHeader={renderCustomHeader}
      >
        <Section label="Configuration" className="scrollable-content" required isLocked={!isConfigurationValid}>
          <Restricted
            permissions={[RolePermissions.FlowsCreate]}
            renderContent={(hasAnyPermission) => (
              <ConfigurationForm {...props} isDisabled={!hasAnyPermission} disablePreventTransitionPrompt={false} />
            )}
          />
        </Section>
        <Section label="Settings" className="scrollable-content">
          <Settings acceptHandlers={wizardPages[FlowCreateSteps.Settings]} />
        </Section>
        <Section label="Content" className="no-scroll no-padding">
          <FlowDesigner
            flowValidator={flowValidatorRef.current}
            acceptHandlers={wizardPages[FlowCreateSteps.Content]}
          />
        </Section>
        <Section label="Goal" className="scrollable-content" isLocked={!isGoalValid}>
          <Goal
            onIsValidChange={setIsGoalValid}
            acceptHandlers={wizardPages[FlowCreateSteps.Goal]}
            onMount={props.fetchGoalOptions}
          />
        </Section>
      </Wizard>
      <PackConfirmationModal onTriggerModalObserver={onTriggerPackSelectionObserver} />
      <AddAssetsToPackModal
        showModal={showAddPacks}
        onClose={/* istanbul ignore next */ () => navigate("/content/flows")}
        onComplete={() => {}}
        selectedItemIds={[configurationInfo.id]}
        contentType="Flow"
      />
    </>
  );
};

/* istanbul ignore next */
const ComponentWithAutosave = withAutosave<Props, ConfigurationPayload>({
  getInitValues: (props) => props.configurationInfo,
  stateProvider: (configurationInfo) => configurationInfo,
  entityUpdater: (props) => props.submitForm,
  isValid: (props) => Boolean(props.id) && props.isValid,
})(CreateFlow);

export const handleSubmit = (configurationInfo: ConfigurationPayload, { props }: { props: any }) => {
  if (!configurationInfo.id) {
    const midnightFlowInfo = {
      ...configurationInfo,
      labels: [],
      softwareApplications: [],
    };

    props.saveDraftInfo(configurationInfo, midnightFlowInfo);
    return;
  }

  props.updateFlowInfo(configurationInfo);
};

/* istanbul ignore next */
const ComponentWithFormik = withFormik({
  mapPropsToValues: (props) => props.configurationInfo,
  validationSchema: validationSchemas.flowConfiguration,
  enableReinitialize: true,
  validateOnMount: true,
  handleSubmit,
})(ComponentWithAutosave);

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const flowInformation = flowInformationSelector(state);
  const configurationInfo = createConfigurationInfoSelector(state);
  const flowValidator = flowValidatorSelector(state);
  const flowEntity = flowEntitySelector(state);

  return {
    configurationInfo,
    flowBaseValidation: flowValidator,
    isSaveInProgress: flowEntity.isEntityCommandInProgress,
    changingEntityState: flowEntity.changingEntityState,
    dateModified: flowInformation.info.dateModified,
    flowDefinition: flowDesignerSelector(state).definition.items,
    packContextItems: flowPackContextItemsSelector(state),
    contentPacks: state.licensing?.contentPacks?.items ?? [],
    hasExternalTrigger: ObjectUtils.isNotEmpty(flowDesignerSelector(state).externalTriggers.externalTriggerGroups),
    // Auto save HOC needs the next values
    id: flowInformation.info.id,
    isLoaded: flowInformation.isInfoLoaded,
    isCreating: flowEntitySelector(state).changingEntityState,
    isDraft: flowInformation.info.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    saveDraftInfo: bindAction(saveDraftInfo, dispatch),
    updateFlowInfo: bindAction(updateFlowInfo, dispatch),
    publishNewFlowEntity: bindAction(publishNewFlowEntity, dispatch),
    setFlowErrorModeEnabled: bindAction(
      getActionProvider<{ isErrorViewMode: boolean }>(setIsErrorModeEnabled),
      dispatch,
    ),
    fetchContentPacks: bindAction(fetchContentPacksAction, dispatch),
    fetchGoalOptions: bindAction(fetchGoalOptions, dispatch),
    onUnmount: () => {
      batch(() => {
        dispatch(userPermissionActions(Sections.flows).resetUsersAssignment());
        dispatch(groupPermissionActions(Sections.flows).resetGroupsAssignment());
        dispatch(resetUserFilters());
        dispatch(resetGroupFilters());
        dispatch(resetFlowInfo());
        dispatch(resetFlowEntityState());
        dispatch(resetFlowBase());
        dispatch(resetContext());
        dispatch(resetNotifyState());
        dispatch(resetDefinition());
        dispatch(resetGoal());
      });
    },
    resetSearchFilters: () => resetSearchFilters(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withNotifyConfig(ComponentWithFormik, initialNotifyConfigDefault));
