import PropTypes from "prop-types";
import cn from "classnames";

import CardThumbnail from "./cardThumbnail/CardThumbnail";
import CardContent from "./cardContent/CardContent";
import { PublishedStatus } from "../common/publishedStatus";
import { PublishedStatusTypes } from "../../enums";
import EllipsisPopupButton from "../buttons/ellipsisPopupButton/EllipsisPopupButton";

import "./publishStatusCard.scss";
import SelectableCard from "./selectableCard/SelectableCard";
import RestrictedByTooltip from "../restrictedByTooltip/RestrictedByTooltip";

function PublishStatusCard(props) {
  const {
    url,
    id,
    thumbnailUrl,
    placeholderContent,
    title,
    publisher,
    published,
    children,
    selected,
    onTitleClick,
    onSelectionChanged,
    popupMenuButtons,
    popupMenu,
    className,
    icons,
    disabled,
    hasEditPermission,
    disablePopupMenu,
    renderMeta,
    isRadioButton,
    selectable,
    thumbnailContent,
  } = props;

  const hasPermission = hasEditPermission ?? true;
  const status = published ? PublishedStatusTypes.published : PublishedStatusTypes.draft;

  const renderPopupMenu = () => {
    if (popupMenu) return popupMenu;

    return (
      popupMenuButtons && (
        <RestrictedByTooltip hasPermission={hasPermission}>
          <EllipsisPopupButton circle disabled={disabled || disablePopupMenu}>
            {popupMenuButtons}
          </EllipsisPopupButton>
        </RestrictedByTooltip>
      )
    );
  };

  const renderCardBottom = () => {
    if (renderMeta) {
      return renderMeta(renderPopupMenu);
    }

    return (
      <div className="bottom-row">
        <PublishedStatus publishedStatus={status} />
        <div onClick={(e) => e.preventDefault()}>{renderPopupMenu()}</div>
      </div>
    );
  };

  return (
    <SelectableCard
      url={url}
      onCardClick={!url ? onTitleClick : undefined}
      id={id}
      className={cn("publish-status-card", className)}
      selected={selected}
      onSelect={onSelectionChanged}
      disabled={disabled}
    >
      <CardThumbnail
        disabled={disabled}
        thumbnailUrl={thumbnailUrl}
        selected={selected}
        hasEditPermission={hasPermission}
        placeholderContent={placeholderContent}
        isRadioButton={isRadioButton}
        selectable={selectable}
      >
        {thumbnailContent}
      </CardThumbnail>
      <CardContent title={title} meta={publisher} icons={icons}>
        <div className="top-row">{children}</div>
        {renderCardBottom()}
      </CardContent>
    </SelectableCard>
  );
}

PublishStatusCard.propTypes = {
  id: PropTypes.number,
  thumbnailUrl: PropTypes.string,
  title: PropTypes.string,
  publisher: PropTypes.string,
  published: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  isRadioButton: PropTypes.bool,
  onTitleClick: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  popupMenuButtons: PropTypes.arrayOf(PropTypes.any),
  popupMenu: PropTypes.any,
  disablePopupMenu: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  thumbnailContent: PropTypes.any,
  icons: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        iconClassName: PropTypes.string.isRequired,
        iconSize: PropTypes.string,
        tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
        tooltipPosition: PropTypes.string,
        tooltipSize: PropTypes.string,
        tooltipClassName: PropTypes.string,
      }),
    ]),
  ),
  hasEditPermission: PropTypes.bool,
  renderMeta: PropTypes.func,
  url: PropTypes.string,
  placeholderContent: PropTypes.any,
  selectable: PropTypes.bool,
};

export default PublishStatusCard;
