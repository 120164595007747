import { Block } from "./Block";
import { Root } from "./Root";
import { SubBlock } from "./SubBlock";
import { Input } from "./Input";

const Form: {
  Block: typeof Block;
  SubBlock: typeof SubBlock;
  Root: typeof Root;
  Input: typeof Input;
} = { Block, SubBlock, Root, Input };

export default Form;
