import React from "react";
import { Icon } from "semantic-ui-react";
import DragAndDropSection from "./DragAndDropSection";

import "./multipleFileErrorDrapAndDropSection.scss";

const multipleFileError = "multiple-file-error";

class MultipleFileErrorDrapAndDropSection implements DragAndDropSection {
  getSection(): React.ReactElement {
    return (
      <div className="drop-section error">
        <div className="overlay"></div>
        <div className="drop-box multiple-file-error">
          <div>
            <Icon className="fa-exclamation-triangle fa-lg" />
            <span>Only one file can be uploaded at a time</span>
          </div>
        </div>
      </div>
    );
  }
  compareType(type: string | null): boolean {
    return multipleFileError === type;
  }
}

export { MultipleFileErrorDrapAndDropSection, multipleFileError };
