import { serialCommaJoin } from "../utils/stringUtils";

const properNouns = ["flow", "flows", "pack", "packs", "user", "group"];

export default class Strings {
  static ellipsis = "…";
  static notAvailable = "Not available";
  static modalTitles = {
    licenseConfirmation: "License Confirmation",
    addPeople: "Add People",
    addContent: "Add Content",
    issueLicense: "Issue License",
    issueLicenseToUsers: "Issue Licenses to Users",
    issueLicenseToGroups: "Issue Licenses to Groups",
    licensing: "Licensing",
  };
  static tooltipMessages = {
    alreadyAssigned: {
      userToFlow: {
        oneUser: "This user already belongs to this Flow",
        multiUsers: "Selected user already belongs to this Flow",
      },
      groupToFlow: {
        oneGroup: "This group already belongs to this Flow",
        multiGroups: "Selected group already belongs to this Flow",
      },
      userToEvent: {
        oneUser: "This user already belongs to this event",
        multiUsers: "Selected user already belongs to this event",
      },
      groupToEvent: {
        oneGroup: "This group already belongs to this event",
        multiGroups: "Selected group already belongs to this event",
      },
      userToCollection: {
        oneUser: "This user already belongs to this collection",
      },
      userToVideo: {
        oneUser: "This user already belongs to this video",
      },
      groupToVideo: {
        oneGroup: "This group already belongs to this video",
      },
      userToAssessment: {
        oneUser: "This user already belongs to this assessment",
      },
      groupToAssessment: {
        oneGroup: "This group already belongs to this assessment",
      },
      userToGroup: {
        oneUser: "This user already belongs to this group",
      },
      someOfUsers: " of the users selected already belong to this group and will be skipped.",
    },
    deleteDisabled: (entity: string, dependecies: string[]) => {
      if (properNouns.includes(entity)) {
        entity = `${entity[0].toUpperCase()}${entity.slice(1).toLowerCase()}`;
      }
      return `One or more selected ${entity} are associated with ${serialCommaJoin(
        dependecies,
        "or",
      )} and cannot be deleted.`;
    },

    purchased: {
      deleteDisabled: (entity: string) => {
        if (properNouns.includes(entity)) {
          entity = `${entity[0].toUpperCase()}${entity.slice(1).toLowerCase()}`;
        }
        return `One or more selected ${entity} is from a Marketplace Pack and cannot be deleted.`;
      },
      duplicateDisabled: (entity: string) => {
        if (properNouns.includes(entity)) {
          entity = `${entity[0].toUpperCase()}${entity.slice(1).toLowerCase()}`;
        }
        return `One or more selected ${entity} is from a Marketplace Pack and cannot be duplicated.`;
      },
      operationsDisabled: (entity: string) => {
        if (properNouns.includes(entity)) {
          entity = `${entity[0].toUpperCase()}${entity.slice(1).toLowerCase()}`;
        }
        return `One or more selected ${entity} is from a Marketplace Pack and cannot be duplicated or deleted.`;
      },
      operationDisabled: (entity: string) => {
        if (properNouns.includes(entity)) {
          entity = `${entity[0].toUpperCase()}${entity.slice(1).toLowerCase()}`;
        }
        return `This ${entity} is from a Marketplace Pack and cannot be duplicated or deleted.`;
      },
      manyDeleteDisabled: (entity: string) => {
        if (properNouns.includes(entity)) {
          entity = `${entity[0].toUpperCase()}${entity.slice(1).toLowerCase()}`;
        }
        return `One or more selected ${entity} is from a Marketplace Pack and cannot be deleted.`;
      },
      oneDeleteDisabled: (entity: string) => {
        if (properNouns.includes(entity)) {
          entity = `${entity[0].toUpperCase()}${entity.slice(1).toLowerCase()}`;
        }
        return `This ${entity} is from a Marketplace Pack and cannot be deleted.`;
      },
    },
    people: {
      removeAssignmet: {
        oneRemoveDisabled: (peopleType: string) => {
          if (properNouns.includes(peopleType)) {
            peopleType = `${peopleType[0].toUpperCase()}${peopleType.slice(1).toLowerCase()}`;
          }
          return `Issued by full access license and cannot be removed. Click Licenses to change the ${peopleType} license from full to partial access.`;
        },
        manyRemoveDisabled: (entity: string) => {
          if (properNouns.includes(entity)) {
            entity = `${entity[0].toUpperCase()}${entity.slice(1).toLowerCase()}s`;
          } else {
            return `One or more selected ${entity} were received via full access license and cannot be removed.`;
          }
          return `One or more selected ${entity} are issued by full access license and cannot be removed.`;
        },
      },
    },
  };
  static library = {
    purchased: {
      noResult: {
        title: "You haven’t purchased any listings",
        descrption: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      },
    },
    threatDefence: {
      verification: {
        verified: "This profile has been verified successfully and can be used in campaigns.",
        unverified:
          "Enter your email address below and we will send you a verification email to confirm this sender profile is working properly.",
        failed: "Verification for this profile failed due to connection timeout. Enter an email address and try again.",
      },
    },
  };
  static people = {
    assignments: {
      changePriority: {
        title: "Saving updated priority",
        successTitle: "Priority settings saved!",
        successMessage: "Content priority settings have been saved successfully.",
        failedMessage: "Failed to save content priority settings.",
      },
    },
    changePriority: {
      title: "Saving changes. Lower priority settings won't apply until this user is removed from the group(s).",
      successTitle: "Changes saved.",
      successMessage: "Lower priority settings won't apply until this user is removed from the group(s).",
      failedMessage: "Failed to save content priority settings.",
    },
  };
}
