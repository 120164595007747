import { Dispatch } from "@reduxjs/toolkit";
import { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { CommunicationSegmentationDropdown } from "../../../../components";
import { ContentTypesEnum } from "../../../../enums";
import { RootState } from "../../../Application/globaltypes/redux";
import MessagesOverview from "../../Communications/Messages/Overview/MessagesOverview";
import EmailsOverview from "../Emails/Overview/EmailsOverview";

export type CommunicationsOverviewProps = PropsFromRedux;

export const CommunicationsOverview: FC<CommunicationsOverviewProps> = (props) => {
  const [communicationContext, setCommunicationContext] = useState<string>(ContentTypesEnum.Emails);

  const onContentSegmentationChange = (context: string) => {
    setCommunicationContext(context);
  };

  const renderContextSwitcher = (): React.ReactElement => (
    <CommunicationSegmentationDropdown
      onContextChange={onContentSegmentationChange}
      defaultValue={communicationContext as ContentTypesEnum}
    />
  );

  const communicationsListItemsComponents: { [key in string]: React.ReactElement } = {
    Emails: <EmailsOverview customHeaderContent={renderContextSwitcher()} {...props} />,
    Messages: <MessagesOverview customHeaderContent={renderContextSwitcher()} />,
  };

  return communicationsListItemsComponents[communicationContext];
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CommunicationsOverview);
