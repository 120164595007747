import React from "react";
import { withFormik } from "formik";

import { Modal } from "../../../../components/modal";
import { ValidatedForm } from "../../../../components";
import { PackRequest } from "../types/state";
import validationSchemas from "../../../../utils/validationSchemas";
import { PackTrialRequestModalProps, PackTrialRequestModalWithFormProps } from "./types";
import { RequestOrEnableTrialForm } from "./RequestOrEnableTrialForm";
import { useHasPurchasingPower } from "../hooks/useHasPurchasingPower";
import RequestPackModalActions from "./RequestPackModalActions";
import ModalSizes from "../../../../enums/modalSizes";

import "./packRequestModal.scss";

export const PackTrialRequestModal: React.FC<PackTrialRequestModalWithFormProps> = (props) => {
  const { values, isValid, resetForm, onCancelPackRequest, onTrialRequest } = props;

  const hasPurchasingPower = useHasPurchasingPower();

  const renderModalActions = (closeModal: any) => {
    return (
      <RequestPackModalActions
        onSubmit={() => {
          onTrialRequest(values);
          closeModal();
        }}
        onCancel={() => {
          closePackRequestModal();
          closeModal();
        }}
        isValid={isValid}
        submitText="Start Trial"
      />
    );
  };

  const closePackRequestModal = () => {
    resetForm();
    onCancelPackRequest?.();
  };

  return (
    <Modal
      className={"pack-request-modal"}
      title="Start Pack Trial"
      open={props.isOpen}
      renderModalActions={renderModalActions}
      onCancel={closePackRequestModal}
      size={ModalSizes.tiny}
      dataTestId="request-pack-trial-modal"
    >
      <ValidatedForm formWidthComputer={16} formWidthTablet={16} {...props}>
        <RequestOrEnableTrialForm {...props} hasPurchasingPower={hasPurchasingPower} />
      </ValidatedForm>
    </Modal>
  );
};

/* istanbul ignore next */
export default withFormik<PackTrialRequestModalProps, PackRequest>({
  validationSchema: validationSchemas.packTrialRequestValidationSchema,
  validateOnMount: true,
  mapPropsToValues: (props: PackTrialRequestModalProps) => {
    return {
      packId: props.packId,
    } as PackRequest;
  },
  handleSubmit: () => {}, //NOSONAR
  enableReinitialize: true,
})(PackTrialRequestModal);
