import CommunicationTypes from "../../../../../../../../enums/communicationTypes";
import { DesignerContextSwitcherOption } from "./models";

export const communicationOptions = (): DesignerContextSwitcherOption[] => {
  const options = [
    {
      key: CommunicationTypes.Email,
      label: "Emails",
      iconName: "fa-envelope",
    },
    {
      key: CommunicationTypes.Message,
      label: "Messages",
      iconName: "fa-messages",
    }
  ];

  return options;
};
