import { connect } from "react-redux";
import { FiltersMap } from "../../../../../utils/filterUtils";
import GenericItemsView, { GenericItemsViewProps } from "../../../../../views/ItemsView/GenericItemsView";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { resetAppliedFilter, setAppliedFilter } from "../../state/slices/surveyFiltersSlice";
import { surveysStateSelector } from "../../state/surveyReducer";
import { getOverviewFilterOptions } from "../../state/thunks/surveyFiltersThunk";
import { SurveyOverview } from "../../types/models";

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const surveysState = surveysStateSelector(state);
  const overview = surveysState.overview;
  const entityState = surveysState.base.surveysEntityStateReducer;

  return {
    items: overview.surveysOverview.surveys,
    selectedIds: overview.surveysOverview.selectedItems,
    isLoading: overview.surveysOverview.isLoading || entityState.changingEntityState,
    dataCount: overview.surveysOverview.totalCount,
    filterOptionsLoading: overview.filters.isLoading,
    filterOptions: overview.filters.filterOptions,
    appliedFilter: overview.filters.appliedFilter,
    className: "alignment-padding",
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getFilterOptions: () => dispatch(getOverviewFilterOptions()),
  applyFilter: (filters: FiltersMap) => dispatch(setAppliedFilter(filters)),
  resetFilter: () => dispatch(resetAppliedFilter()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<SurveyOverview>) => React.ReactElement);
