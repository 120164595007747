import UpdateParams from "../../../../interfaces/updateParams";
import { pluralize } from "../../../../utils/stringUtils";
import usersDataService from "../../../Application/services/dataServices/usersDataService";

export const assignUsersToRoleTask = (userIds: number[], roleIds: number[]): UpdateParams | null => {
  if (!userIds.length || !roleIds.length) {
    return null;
  }

  const roleCount = roleIds.length;
  const userCount = userIds.length;
  const isSingle = roleCount === 1;

  return {
    id: `AssignUsersToRole_${new Date()}`,
    title: `Assign ${pluralize("role", roleCount)} to ${pluralize("user", userCount)}`,
    getMessageIds: async () => {
      const response = await usersDataService.assignUsersToRoles(userIds, roleIds);
      return [response.data];
    },
    successTransientMessage: `${pluralize("Role", roleCount)} ${isSingle ? "has" : "have"} been assigned to ${pluralize(
      "user",
      userCount,
    )} successfully`,
    failureTransientMessage: `Assigning ${pluralize("role", roleCount)} to ${pluralize("user", userCount)} failed!`,
  };
};
