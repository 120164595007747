import React from "react";

import { PdfCard } from "../../../../../components/cards/PdfCard/PdfCard";
import { PdfOverview } from "../../types/state";
import { PdfCardBaseProps } from "../../../../../components/cards/PdfCard/types";
import { PdfContentItemMenu } from "../PdfContentItemMenu/PdfContentItemMenu";
import { PdfPublishConfirmationParams } from "../../types/models";

export interface PdfOverviewCardProps extends PdfCardBaseProps<PdfOverview> {
  handleEditClick: (id: number, isDraft: boolean) => void;
  handlePublishClick: (params: PdfPublishConfirmationParams) => void;
  handleDeleteClick: (ids: number[], flowsCount?: number, packsCount?: number) => void;
  readonly?: boolean;
}

export const PdfOverviewCard: React.FC<PdfOverviewCardProps> = (props): React.ReactElement => {
  const { item, selected, onSelect, disabled, ...popupMenuProps } = props;

  return (
    <PdfCard
      item={item}
      disabled={disabled}
      url={props.item.id.toString()}
      onSelect={onSelect}
      popupMenu={<PdfContentItemMenu {...popupMenuProps} circle item={item} outlinedEllipsis isChecked={selected} />}
      selected={selected}
    />
  );
};
