export enum ExtrasTypes {
  SectionHeader = "section-header",
  FlowEnd = "FlowEnd",
  Extras = "extras",
  Deleted = "Deleted",
}
export interface ExtrasBase {
  id: string;
  cardType: ExtrasTypes;
  title: string;
}

export interface ISectionHeader extends ExtrasBase {
  type: ExtrasTypes.SectionHeader;
}

export interface FlowEnd extends ExtrasBase {
  type: ExtrasTypes.FlowEnd;
}

// extend here like type ExtrasOverview = ISectionHeader | IMilestone and so on ...
export type ExtrasOverview = ISectionHeader | FlowEnd;
