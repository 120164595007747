import React, { FC, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import { bindAction } from "../../../../interfaces/redux";

import { AppDispatch, RootState } from "../../../../features/Application/globaltypes/redux";
import { SortingDirection } from "../../../../enums";
import { GenericFiltersMap } from "../../../../utils/filterUtils";
import { AssignmentListFiltersEnum, AssignmentListPropsBase } from "../../types";
import { Columns, ColumnToParamMap, getColumnOptions } from "../assetColumnOptions/assetColumnOptions";
import { SurveyFilterForm } from "../../../filterForms";
import { ContentType, PeopleType } from "../../../../features/Library/PeopleAssignments/types";
import { resetContentAssignmentsFilterOptions } from "../../../../features/People/ContentAssignments/state/thunks/contentAssignmentsFilterThunk";
import { getContentAssignmentSurveyFilterOptions } from "../../../../features/Library/Surveys/state/thunks/surveyFiltersThunk";
import { setAppliedFilter } from "../../../../features/People/ContentAssignments/state/slices/contentAssignmentsFiltersSlice";

import SearchInput from "../../../searchInput/SearchInput";
import AssignmentList from "../AssignmentList";
import SurveysNoResults from "../../../../views/library/surveys/Overview/SurveysNoResults";

interface SurveyListOwn extends AssignmentListPropsBase {
  fetchSurveys: (
    skip: number,
    top: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: GenericFiltersMap<AssignmentListFiltersEnum>,
    term?: string,
  ) => void;
  contextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  hidePriorityColumn?: boolean;
  showExpirationDate?: boolean;
  deepLink?: boolean;
}

export type SurveyListProps = SurveyListOwn & PropsFromRedux;

export const SurveyList: FC<SurveyListProps> = ({
  fetchSurveys,
  resetFilter,
  createButton,
  accessRestricted,
  areFiltersApplied,
  isReadOnly,
  showExpirationDate,
  resetList,
  onSelectedChanged,
  disablePopupMenu,
  deepLink,
  ...rest
}) => {
  const reloadSurveyListItems = useRef<(condition: boolean) => void>();

  const [search, setSearch] = useState<string>("");

  const getSurveys = (
    skip: number = 0,
    top: number = 10,
    orderBy: string = Columns.Modified.toLowerCase(),
    sortingDirection: SortingDirection = SortingDirection.Descending,
    appliedFilter?: GenericFiltersMap<AssignmentListFiltersEnum>,
  ): void => {
    const sortColumn = ColumnToParamMap[orderBy as Lowercase<Columns>];
    fetchSurveys(skip, top, sortColumn, sortingDirection, appliedFilter, search);
  };

  const onSearchChange = (newSearchText: string) => {
    onSelectedChanged([]);
    setSearch(newSearchText);
    refetchItemsFromStart(isEmpty(newSearchText));
  };

  const refetchItemsFromStart = (enableSorting: boolean) => {
    reloadSurveyListItems.current?.(enableSorting);
  };

  const createReloadListItems = (reloadListItems: (condition: boolean) => void) => {
    reloadSurveyListItems.current = reloadListItems;
  };

  // @ts-ignore
  const getFilterForm = () => <SurveyFilterForm />;

  // @ts-ignore
  const getSearchBar = () => (
    <SearchInput
      placeholder="Search for Surveys..."
      disabled={accessRestricted}
      onChange={onSearchChange}
      defaultValue={search}
    />
  );

  useEffect(() => {
    return () => resetFilter();
  }, [resetFilter]);

  return (
    <AssignmentList
      {...rest}
      resetFilter={resetFilter}
      resetList={resetList}
      onSelectedChanged={onSelectedChanged}
      fetchData={getSurveys}
      setReloadListItems={createReloadListItems}
      columnOptions={getColumnOptions({
        assetType: "surveys",
        peopleType: rest.peopleType,
        contentType: ContentType.Survey,
        selectedIds: rest.selectedIds,
        buttonHandlers: {},
        isPriorityHide: true,
        isReadOnly: isReadOnly,
        isExpiresHide: true,
        isAddedHide: true,
        disablePopupMenu: disablePopupMenu,
      })}
      getFilterForm={getFilterForm}
      getSearchBar={getSearchBar}
      noResultsContent={
        <SurveysNoResults
          filtered={areFiltersApplied}
          createSurveyButton={createButton}
          description={`Looks like you don't have any survey assets for this ${
            rest.peopleType === PeopleType.User ? "user" : "group"
          }.`}
        />
      }
      defaultSortingColumnName={Columns.Modified}
      sortingDirection={SortingDirection.Descending}
      accessRestricted={accessRestricted}
      isReadOnly
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.people.contentAssignment.surveyList.items,
    isLoading: state.people.contentAssignment.surveyList.isLoading,
    dataCount: state.people.contentAssignment.surveyList.itemsCount,
    filterOptions: state.people.contentToPeople.filters.filterOptions,
    filterOptionsLoading: state.people.contentToPeople.filters.isLoading,
    appliedFilter: state.people.contentToPeople.filters.appliedFilter,
    areFiltersApplied: !isEmpty(state.people.contentToPeople.filters.appliedFilter),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetFilter: bindAction(resetContentAssignmentsFilterOptions, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    getFilterOptions: bindAction(getContentAssignmentSurveyFilterOptions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SurveyList);
