import EmailDetails from "./EmailDetails";
import { useParams } from "react-router-dom";

export const EmailDetailsWrapper = () => {
  const params = useParams();
  const id = Number(params.id);
  return <EmailDetails emailId={id} key={id} />;
};

export default EmailDetailsWrapper;
