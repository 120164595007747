import React, { FC, useEffect, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { Loader } from "semantic-ui-react";

import { AppDispatch, RootState } from "./features/Application/globaltypes/redux";
import { setUserProfileMoboData } from "./features/Application/thunks/userProfileThunk";
import moboConfigurator from "./moboConfigurator";

export interface Props extends PropsFromRedux {
  children?: React.ReactNode
}

export const MoboProvider: FC<Props> = ({ isUserMoboDataSet, originId, onSetUserMoboData, children }) => {
  const [isMoboDataLoaded, setIsMoboDataLoaded] = useState(false);

  useEffect(() => {
    const moboId = moboConfigurator.getMoboId();

    if (moboId && moboId !== originId) {
      moboConfigurator.configureMoboMode(moboId);
      onSetUserMoboData(moboId);
    } else {
      setIsMoboDataLoaded(true);
    }

    return () => moboConfigurator.clearMoboData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUserMoboDataSet && !isMoboDataLoaded) {
      setIsMoboDataLoaded(true);
    }
  }, [isUserMoboDataSet, isMoboDataLoaded]);

  return isMoboDataLoaded ? <>{children}</> : <Loader active />;
};

const mapStateToProps = (state: RootState) => ({
  isUserMoboDataSet: state.userProfile.isUserMoboDataSet,
  originId: state.userProfile.originAccountId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSetUserMoboData: bindActionCreators(setUserProfileMoboData, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MoboProvider);
