import { combineReducers } from "redux";
import { threatDefenceLandingPagesOverview } from "./threatDefenceLandingPagesOverviewReducer";
import { threatDefenceLandingPageDetails } from "./threatDefenceLandingPageDetailsReducer";
import threatDefenceLandingPageEntityStateReducer from "./threatDefenceLandingPageEntityStateReducer";
import overviewFilters from "../slices/landingPagesFiltersSlice";

export default combineReducers({
  threatDefenceLandingPagesOverview,
  threatDefenceLandingPageDetails,
  threatDefenceLandingPageEntityStateReducer,
  overviewFilters: overviewFilters,
});
