import React from "react";

import { SingleSessionFormPropsOwn } from "../../../types";
import { SessionsView } from "../../../../types/state";
import { FieldArray, FormikProps } from "formik";
import { ValidatedForm } from "../../../../../../../components/forms";
import { SingleSessionBuilder } from "./SingleSessionBuilder";

export type SingleSessionFormProps = SingleSessionFormPropsOwn & {
  formik: FormikProps<SessionsView>;
};

export const SingleSessionForm = (props: SingleSessionFormProps) => {
  const { isLoading, formik } = props;

  const renderFields = (): React.ReactNode => {
    const { disabled, onBlur, save } = props;

    return (
      <div className="multiple-single-sessions">
        <FieldArray
          name={"sessions"}
          render={(arrayHelpers) => (
            <SingleSessionBuilder
              sessions={formik.values.sessions}
              validatedFieldProps={formik}
              disabled={disabled}
              arrayHelpers={arrayHelpers}
              onBlur={onBlur}
              save={save}
            />
          )}
        />
      </div>
    );
  };

  return (
    <ValidatedForm
      dirty={formik.dirty}
      isLoading={isLoading}
      formWidthComputer={16}
      parentWithFormik={true}
      disablePreventTransitionPrompt={true}
    >
      {renderFields}
    </ValidatedForm>
  );
};

export default SingleSessionForm;
