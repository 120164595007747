import { TriggerableConfirmationModal } from "components";
import { ModalSizes } from "enums";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { ICreateUserModel } from "features/People/types";
import { FormikProps, withFormik } from "formik";
import { bindAction, IObservable } from "interfaces";
import { connect, ConnectedProps } from "react-redux";
import { OmitFormik } from "types";
import { validationSchemas } from "utils";
import { accountEditAddUserSelector } from "../state/slices/addUser/addUserReducer";
import { addManagedUser } from "../state/thunks/addUserThunk";
import AddUserForm from "./AddUserForm";

export interface CreateUserModalProps extends FormikProps<ICreateUserModel>, PropsFromRedux {
  accountId: number;
  onTriggerModalObserver: IObservable<(onConfirm: () => void) => void>;
}

export const CreateUserModal = (props: CreateUserModalProps) => {
  const { onTriggerModalObserver, save, resetForm, values, validateForm, isValid, accountId } = props;
  const handleOnConfirmed = () => {
    save(accountId, values);
    resetForm();
  };

  return (
    <TriggerableConfirmationModal
      onTriggerModalObserver={onTriggerModalObserver}
      title={"Create User"}
      content={<AddUserForm {...props} />}
      confirmButtonLabel={"Save"}
      onTrigger={validateForm}
      onConfirmed={handleOnConfirmed}
      onClose={resetForm}
      scrolling={false}
      disabled={!isValid}
      size={ModalSizes.small}
    />
  );
};

const component = withFormik<CreateUserModalProps, ICreateUserModel>({
  validationSchema: validationSchemas.getAddManagedUserSchema(),
  enableReinitialize: true,
  mapPropsToValues: () => {
    return {} as ICreateUserModel;
  },
  validateOnMount: true,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(CreateUserModal) as React.ComponentType<OmitFormik<CreateUserModalProps, ICreateUserModel>>;

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const addUserState = accountEditAddUserSelector(state);
  return {
    departments: addUserState.departments.items,
    jobTitles: addUserState.jobTitles.items,
    countries: addUserState.countries.items,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  save: bindAction(addManagedUser, dispatch),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(component);
