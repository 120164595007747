import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { flowFilterTypes } from "features/Library/Flows/types/models";
import { videoFilterTypes } from "features/Library/Videos/types/models";
import { isEmpty, isString } from "lodash";
import buildQuery from "odata-query";
import qs from "qs";
import { DefaultConfigApi, GroupInfo, NotifyStepSettings } from "../../../interfaces";
import { WithPagedDataV2 } from "../../../interfaces/getPagedDataRequest";
import { formatFilters } from "../../../utils/filterMapUtils";
import { FiltersMap } from "../../../utils/filterUtils";
import {
  Filters,
  formatFiltersV2api,
  mapToV2Filters,
  serializeArrayAndFilterNullable,
} from "../../../utils/queryUtils";
import { escapeTerm } from "../../../utils/searchUtils";
import KnownHttpHeaders from "../../Application/services/knownHttpHeaders";
import { GetFlowsRequest } from "../../Library/Flows/types/requests";
import {
  PeopleAvailableAssessment,
  PeopleAvailableEvent,
  PeopleAvailableFlow,
  PeopleAvailablePdf,
  PeopleAvailableSurvey,
  PeopleAvailableVideo,
} from "../ContentAssignments/models";
import {
  GetGroupEnrollmentUsersPreviewRequest,
  GetGroupsConfigApi,
  GetGroupsOptions,
  GetGroupsUsersToAddApi,
  GetPeopleContentOptions,
  GetPeopleFlowsOptions,
  GetUsersParamsApiV2,
  v2FilterMap,
} from "../types";
import { IGroupsDataService } from "./IGroupsDataService";

export const groupsDataService: IGroupsDataService = {
  /* istanbul ignore next */
  getGroupContent(groupId: number, payload: GetPeopleContentOptions) {
    const { top, skip, filters = {}, sortBy, sortOrder, contentType } = payload;
    const request = {
      top,
      skip,
      sortBy,
      sortOrder,
      contentType,
      term: escapeTerm(payload.searchTerm) ?? "",
      showPurchased: true,
    };

    return axios.get(`/api/groups/${groupId}/content`, {
      //  https://brainstorm.atlassian.net/browse/SAAS-25637
      params: { ...request, ...formatFilters(filters, videoFilterTypes) },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getGroupFlows(groupId: number, options: GetPeopleFlowsOptions) {
    const [sortBy, sortOrder] = options.orderBy?.split(" ") || [];
    const requestV3: GetFlowsRequest = {
      top: options.top,
      skip: options.skip,
      sortBy,
      sortOrder,
      term: escapeTerm(options.searchTerm) ?? "",
    };
    const endpoint = `/api/v4/groups/${groupId}/flows?showPurchased=true`;

    return axios.get(endpoint, {
      params: { ...requestV3, ...formatFilters(options.filters as FiltersMap, flowFilterTypes) },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  async getGroups(options: GetGroupsOptions) {
    const { skip, top, orderBy, filter, owners, fields } = options;

    const config: GetGroupsConfigApi = {
      params: {
        skip: skip,
        top: top,
        filter: "IsTemplate eq false",
      },
    };

    if (orderBy) {
      config.params.orderBy = orderBy;
    }

    if (filter) {
      config.params.filter = config.params.filter + " and " + filter;
    }

    if (owners) {
      config.params.owners = owners;
    }

    if (fields) {
      config.params.fields = fields;
    }

    const { data, headers } = await axios.get<GroupInfo[]>("/api/people/groups", config);

    return {
      items: data,
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  getGroupAvailableFlows<T>(
    groupIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailableFlow[]>> {
    return axios.get(`/api/v3/groups/available-flows`, {
      params: {
        ...pagingOptions,
        ids: groupIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getGroupAvailableFlowsV4<T>(
    groupIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailableFlow[]>> {
    return axios.get(`/api/v4/groups/available-flows`, {
      params: {
        ...pagingOptions,
        id: groupIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getGroupAvailableSurveys<T>(
    groupIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailableSurvey[]>> {
    const config = {
      params: {
        ...pagingOptions,
        id: groupIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get(`/api/v2/groups/available-surveys`, config);
  },

  getGroupAvailableAssessments<T>(
    groupIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailableAssessment[]>> {
    const config = {
      params: { ...pagingOptions, id: groupIds },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get(`/api/v2/groups/available-assessments`, config);
  },

  getGroupAvailableEvents<T>(
    groupIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailableEvent[]>> {
    return axios.get(`/api/v3/groups/available-external-events`, {
      params: {
        ...pagingOptions,
        peopleIds: groupIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  getGroupAvailableVideos<T>(
    groupIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailableVideo[]>> {
    const config = {
      params: {
        ...pagingOptions,
        id: groupIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get(`/api/v3/groups/available-videos`, config);
  },

  getGroupAvailablePdfs<T>(
    groupIds: number[],
    pagingOptions: WithPagedDataV2<T>,
  ): Promise<AxiosResponse<PeopleAvailablePdf[]>> {
    const config: AxiosRequestConfig = {
      params: {
        ...pagingOptions,
        id: groupIds,
      },
      paramsSerializer: (params) => serializeArrayAndFilterNullable(params),
    };

    return axios.get(`/api/groups/available-pdfs`, config);
  },

  async getGroupMembersV2(
    groupId: number,
    skip: number,
    top: number,
    orderByParams?: string,
    filter?: string | Filters,
    search?: string,
  ) {
    const isV2Service = !!search;
    if (isV2Service) {
      const [sortBy, sortOrder] = orderByParams?.split(" ") || [undefined, undefined];
      const v2config: GetUsersParamsApiV2 = {
        params: {
          skip,
          top,
          term: escapeTerm(search),
          sortBy,
          sortOrder,
          ...formatFiltersV2api(mapToV2Filters(filter as Filters, v2FilterMap)),
        },
      };

      return axios.get(`/api/v2/groups/${groupId}/members`, {
        params: { ...v2config.params },
        paramsSerializer: serializeArrayAndFilterNullable,
      });
    }
    const config: DefaultConfigApi = {
      params: {
        skip: skip,
        top: top,
      },
      paramsSerializer: qs.stringify,
    };

    if (orderByParams) {
      config.params.orderBy = orderByParams;
    }

    if (!isEmpty(filter) && isString(filter)) {
      config.params.filter = filter;
    } else {
      config.params = { ...config.params, ...(filter as Filters) };
    }

    return axios.get(`/api/people/groups/${groupId}/members`, config);
  },

  getGroupInfo(groupId: number) {
    return axios.get(`/api/people/groups/${groupId}/info`);
  },

  async getGroupUsersToAdd(groupIds: number[], skip: number, top: number, orderByParams?: string, filter?: any) {
    const config: GetGroupsUsersToAddApi = {
      params: {
        groupId: groupIds,
        skip: skip,
        top: top,
      },
      paramsSerializer: qs.stringify,
    };

    if (orderByParams) {
      config.params.orderBy = orderByParams;
    }

    if (!isEmpty(filter) && isString(filter)) {
      config.params.filter = filter;
    } else {
      config.params = { ...config.params, ...filter };
    }

    return axios.get(`/api/groups/available-users`, {
      ...config,
      paramsSerializer: (params) => qs.stringify(params),
    });
  },

  async getGroupUsersToAddV2(
    groupIds: number[],
    skip: number,
    top: number,
    orderByParams?: string,
    filter?: any,
    search?: string,
  ) {
    const [sortBy, sortOrder] = orderByParams?.split(" ") || [undefined, undefined];
    return axios.get(`/api/v2/groups/available-users`, {
      params: {
        skip,
        top,
        term: escapeTerm(search),
        sortBy,
        sortOrder,
        ...formatFiltersV2api(mapToV2Filters(filter as Filters, v2FilterMap)),
        groupId: groupIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  async updateGroupUsers(groupId: number, userIds: number[]) {
    return axios.post(`/api/groups/${groupId}/members`, {
      userIds,
    });
  },

  async addUsersToGroups(groupIds: number[], userIds: number[], notificationSettings?: NotifyStepSettings) {
    return axios.post("/api/v2/users/groups", {
      groupIds,
      userIds,
      notificationSettings,
    });
  },

  async removeUserFromGroups(userId: number, groupIds: number[]) {
    const config = {
      params: {
        id: groupIds,
      },
    };
    return axios.delete(`/api/users/${userId}/groups`, config);
  },

  updateGroupEnrollmentRules(groupId: number, enrollmentRules: any[], templateUsersOnly?: boolean | null) {
    return axios.put(`/api/groups/${groupId}/rules`, {
      rules: enrollmentRules,
      templateUsersOnly,
    });
  },

  async createGroup(groupPayload: any) {
    return axios.post("/api/v2/groups", groupPayload);
  },

  async deleteGroups(groupIds: number[]) {
    return axios.delete("/api/groups/delete", {
      data: { groupIds: [...groupIds] },
    });
  },

  async duplicateGroups(groupIds: number[]) {
    return axios.post(`/api/groups/duplicate`, {
      groupIds,
    });
  },

  async updateGroupInfo(groupId: number, data: any) {
    return axios.put(`/api/groups/${groupId}/info`, data);
  },

  async removeEnrolledGroupMembers(groupId: number, userIds: number[]) {
    return axios.delete(`/api/groups/${groupId}/members`, {
      data: { userIds },
    });
  },

  async getOwners(groupId: number, queryParams?: object) {
    const query = buildQuery(queryParams);
    const response = await axios.get(`/api/groups/${groupId}/owners${query}`);

    return {
      items: response.data.value,
      totalCount: response.data["@odata.count"],
    };
  },

  async isGroupNameExists(name: string, groupId?: number) {
    const filter = groupId ? `name eq "${name}" and id ne ${groupId}` : `name eq "${name}"`;

    const response = await axios.get("/api/people/groups", {
      params: { filter },
    });
    return response.data && !!response.data.length;
  },

  async getGroupEnrollmentUsersPreview(request: GetGroupEnrollmentUsersPreviewRequest) {
    /* istanbul ignore next */
    return axios.post(`/api/groups/rules/evaluate`, request);
  },
};

export default groupsDataService;
