import React from "react";
import PropTypes from "prop-types";
import { Input, Ref } from "semantic-ui-react";

import ValidatedFieldExperimental from "./ValidatedFieldExperimental";

function InputField(props) {
  const { value, placeholder, propertyName, handleChange, handleBlur, onBlur, disabled, autoFocus, maxLength } = props;

  const inputRef = React.useRef();

  const handleFieldBlur = (e) => {
    handleBlur(e);
    onBlur && onBlur(propertyName);
  };

  return (
    <ValidatedFieldExperimental {...props} inputRef={inputRef}>
      <Ref innerRef={inputRef}>
        <Input
          fluid
          name={propertyName}
          type="text"
          value={value || ""}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleFieldBlur}
          disabled={disabled}
          autoFocus={autoFocus}
          maxLength={maxLength}
        />
      </Ref>
    </ValidatedFieldExperimental>
  );
}

InputField.defaultProps = {
  placeholder: "",
  disabled: false,
  autoFocus: false,
};

InputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  propertyName: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,

  // validated field props, to be replaced with type definition from Formik
  markAsRequired: PropTypes.bool,
  isFormValid: PropTypes.bool,
  onIsFormValidChange: PropTypes.func,
  errorPosition: PropTypes.string,

  // formik props
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
};

export default InputField;
