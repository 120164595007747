import { FilterTypesMap, FilterType } from "utils/filterUtils";
import { TagsEnum } from "../../../../interfaces/TagsEnum";
import { Filters } from "../../../../utils/queryUtils";
import { PaginationAndSortingOptions } from "features/Library/Common/models";
import { SortingDirection } from "enums";

export interface FlowOverview extends PaginationAndSortingOptions {
  id: number;
  title: string;
  publisher: string;
  publisherId: number;
  thumbnailUrl?: string;
  dateCreated: string;
  dateModified: string;
  published: boolean;
  isEditable: boolean;
  hasBeenPublished: boolean;
  canBeDeleted: boolean;
  canAutoStart: boolean;
  isDraft: boolean; // to-do needs refactoring
  dependencies?: { packsCount: number; totalAssignments: number; [key: string]: number };
  pagination: number;
  sortingColumnName: string;
  sortingDirection: SortingDirection;
  shouldSort: boolean;
  hidden: boolean;
  goals?: FlowGoals;
}

export interface FlowOverviewRequest {
  skip?: number;
  top?: number;
  sortBy?: string;
  sortDirection?: string;
  appliedFilter?: Filters;
  append?: boolean;
}

export enum FlowObjective {
  Onboarding = 1,
  Remediation = 2,
  Optimization = 3,
}

export interface Goal<T = number> {
  id: T;
  label: string;
}

export interface FlowGoals {
  objective?: Goal<FlowObjective>;
}

export enum FlowFiltersEnum {
  SoftwareApplications = "softwareApplications",
  Labels = "labels",
  Packs = "packIds",
  Publishers = "publisherIds",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
  GoalObjectives = "goalObjectiveIds",
  GoalTypes = "goalTypeIds",
}

export const flowFilterTypes: FilterTypesMap<FlowFiltersEnum> = {
  dateCreated: FilterType.DateRange,
  dateModified: FilterType.DateRange,
  labels: FilterType.Array,
  packIds: FilterType.Array,
  publisherIds: FilterType.Array,
  softwareApplications: FilterType.Array,
  goalObjectiveIds: FilterType.Array,
  goalTypeIds: FilterType.Array,
};

export interface TagsToContent {
  ids: number[];
  tags: string[];
  tagType: TagsEnum;
}

export interface GoalView {
  objectiveId?: Nullable<number>;
  typeId?: Nullable<number>;
  dataSourceId?: Nullable<number>;
  metricId?: Nullable<number>;
  applicationId?: Nullable<number>;
  measureId?: Nullable<number>;
}

export interface GoalItem {
  id: number;
  label: string;
  parentId?: number;
}

export interface GoalOptions {
  objective: GoalItem[];
  type: GoalItem[];
  dataSource: GoalItem[];
  successMetric: GoalItem[];
  application: GoalItem[];
  measure: GoalItem[];
}
