import { bindActionCreators } from "@reduxjs/toolkit";
import { FormikProps, withFormik } from "formik";
import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { ValidatedForm } from "../../../../../components";
import { bindAction, WizardForm } from "../../../../../interfaces";
import { validationSchemas, formikUtils, AutosaveProps, withAutosave } from "../../../../../utils";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { TagsPayloadCamelPlural } from "../../../../../interfaces/Tags";
import * as messageEntityStateActions from "../state/actions/messageEntityStateActions";
import { reset, saveMessage } from "../state/slices/messageDetailsSlice";
import { MessageDetails } from "../types";

import styles from "./MessageInfoForm.module.scss";

type OwnProps = {
  normalizedTags?: TagsPayloadCamelPlural;
};

export type Props = OwnProps &
  FormikProps<MessageDetails> &
  AutosaveProps &
  PropsFromRedux &
  WizardForm<MessageDetails>;

const utmCampaignField = "utmCampaign";

const MessageInfoForm: React.FC<Props> = (props) => {
  const {
    values,
    isLoading,
    dirty,
    acceptHandlers,
    entityStateActions,
    clearInfo,
    id,
    saveMessage,
    onIsValidChange,
    onIsDirtyChanged,
    isValid,
    onBlur,
    disabled,
  } = props;
  const validatedFieldProps = formikUtils.getValidateFieldProps(props);

  const save = () => {
    saveMessage(values);
    if (id <= 0) {
      entityStateActions.create({ title: values.title, utmCampaign: values.utmCampaign });
    }
  };

  const onClose = () => {
    entityStateActions.resetMessageEntityState();
    clearInfo();
  };

  useEffect(() => {
    acceptHandlers?.({
      onNext: save,
      onClose: onClose,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    onIsValidChange?.(isValid);
  }, [isValid, onIsValidChange]);

  useEffect(() => {
    onIsDirtyChanged?.(dirty);
  }, [dirty, onIsDirtyChanged]);

  const onTitleBlur = (propertyName: string) => {
    const { values, setFieldValue, onBlur, touched } = props;
    if (values.id <= 0 && !touched[utmCampaignField]) {
      setFieldValue(utmCampaignField, values.title, true);
    }

    onBlur(propertyName);
  };

  return (
    <ValidatedForm
      disablePreventTransitionPrompt={id > 0}
      unsavedChangesPrompt={{
        title: "Exit Without Saving?",
        message: "Are you sure you want to exit without saving this message? All information entered will be lost.",
      }}
      dirty={dirty}
      isLoading={isLoading}
      isSubmitting={isLoading}
      formWidthComputer={8}
    >
      <div className={styles.root}>
        <ValidatedForm.InputField
          {...validatedFieldProps}
          label="Title"
          value={values.title}
          propertyName="title"
          markAsRequired
          placeholder="Enter Title"
          onBlur={onTitleBlur}
          disabled={disabled}
        />
        <ValidatedForm.InputField
          {...validatedFieldProps}
          label="Campaign Name"
          value={values.utmCampaign}
          propertyName="utmCampaign"
          markAsRequired
          placeholder="Campaign Name"
          onBlur={onBlur}
          disabled={disabled}
          tooltip={{
            info: "Sets the campaign name for tracking in analytics. Defaults to your email title but can be customized.",
            width: 20,
          }}
        />
      </div>
    </ValidatedForm>
  );
};

const mapToMessagePayload = (values: MessageDetails): MessageDetails => {
  return {
    id: values.id,
    title: values.title.trim(),
    utmCampaign: values.utmCampaign.trim(),
  };
};

const mapState = (state: RootState, ownProps: any) => ({
  message: state.library.messages.details.values.message,
  id: ownProps.entityId || state.library.messages.details.messageEntityStateReducer.entityId,
  isLoading: state.library.emails.emailDetailsReducer.isLoading,
  isLoaded: state.library.emails.emailDetailsReducer.isEmailLoaded,
  isDraft: state.library.emails.emailDetailsReducer.email.isDraft,
  isCreating: state.library.emails.emailEntityStateReducer.changingEntityState,
  hasBeenPublished: state.library.emails.emailDetailsReducer.email.hasBeenPublished,
});

const mapDispatch = (dispatch: AppDispatch) => ({
  entityStateActions: bindActionCreators(messageEntityStateActions, dispatch),
  saveMessage: bindAction(saveMessage, dispatch),
  clearInfo: bindAction(reset, dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const MessageInfoWithAutosave = withAutosave<Props, MessageDetails>({
  getInitValues: (props) => mapToMessagePayload(props.message),
  stateProvider: mapToMessagePayload,
  entityUpdater: (props) => props.entityStateActions.updateMessage,
  isValid: (props) => props.id && props.isValid && props.dirty,
})(MessageInfoForm);

const MessageInfoWithFormik = withFormik({
  mapPropsToValues: (props: OwnProps & PropsFromRedux) => props.message,
  handleSubmit: () => {},
  enableReinitialize: true,
  validationSchema: validationSchemas.messageInfo,
  validateOnMount: true,
})(MessageInfoWithAutosave);

export default connector(MessageInfoWithFormik);
