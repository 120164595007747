import React from "react";
import ModalSizes from "../../../enums/modalSizes";
import ActionConfirmationModal from "../../modal/ActionConfirmationModal/ActionConfirmationModal";

export interface DeleteRoleConfirmationModalProps {
  onCancel(): void;
  onContinue(): void;
  size?: ModalSizes;
  open: boolean;
}

const DeleteRoleConfirmationModal = ({
  onCancel,
  onContinue,
  size,
  open = false,
}: DeleteRoleConfirmationModalProps) => (
  <ActionConfirmationModal
    open={open}
    onCancel={onCancel}
    onContinue={onContinue}
    title="Delete Role"
    size={size}
    withWarningLabel={false}
    confirmLabel="Delete"
  >
    Delete this role? This will remove the role permissions from all assigned users.
  </ActionConfirmationModal>
);

export default DeleteRoleConfirmationModal;
