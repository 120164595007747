import { FC, MutableRefObject, SyntheticEvent, useEffect, useRef, useState, useCallback } from "react";
import { Header, TabProps } from "semantic-ui-react";
import { StripoInitOpts } from "../../../lib/stripo";
import cn from "classnames";

import Tabs from "../../tabs/Tabs";
import { EditorControls } from "./EditorControls";
import { useEmailEditor } from "../hooks/useEmailEditor";
import { IEmailEditorController } from "./emailEditorController";
import NotifySettingsForm, { EmailNotificationFormProps } from "../NotifySettingsForm/EmailNotificationForm";
import { SubjectPreviewControl } from "./SubjectPreviewControl";
import { datadogRum } from "@datadog/browser-rum";

import editorTabsStyles from "../editorTabs.module.scss";
import "./emailEditor.scss";

export interface EmailEditorProps {
  opts: StripoInitOpts;
  onLoaded(controller: IEmailEditorController): void;
  form: EmailNotificationFormProps;
}

const PREVIEW_TAB_INDEX = 1;

export const EmailEditor: FC<EmailEditorProps> = ({ onLoaded, opts, form }) => {
  const { settingsId, previewId } = opts;
  const [isLoaded, controller, sasUrl, emailPreview] = useEmailEditor({ ...opts });
  const [iframeHeight, setIframeHeight] = useState<string>("0");

  const iFrameRef = useRef<HTMLIFrameElement>() as MutableRefObject<HTMLIFrameElement>;
  const hiddenDiv = iFrameRef?.current?.closest(".step-content, .invisible")?.classList.contains("invisible");
  const pollRate = 500;

  const onUnhide = useCallback(() => {
    setIframeHeight(iFrameRef?.current?.contentWindow?.document?.body?.scrollHeight.toString() || "0");
  }, []);

  useEffect(() => {
    if (hiddenDiv) {
      const interval = setInterval(() => {
        onUnhide();
      }, pollRate);
      return () => clearInterval(interval);
    } else {
      onUnhide();
    }
  }, [onUnhide, hiddenDiv]);

  useEffect(() => {
    datadogRum.addAction("On EmailEditor useEffect", {
      isLoaded: isLoaded,
      controller: controller,
      sasUrl: sasUrl,
    });

    if (!isLoaded || !controller || !sasUrl) return;

    onLoaded(controller);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, controller, sasUrl]);

  const handleTabChange = async (_: SyntheticEvent, data: TabProps) => {
    const isNotPreviewTab = data.activeIndex !== PREVIEW_TAB_INDEX;

    if (isNotPreviewTab || !isLoaded) {
      return;
    }

    controller?.renderPreview();
  };

  return (
    <div className="email-editor">
      <Tabs
        className={cn(editorTabsStyles["editor-tabs"], "email")}
        renderActiveOnly={false}
        onTabChange={handleTabChange}
        defaultActiveIndex={PREVIEW_TAB_INDEX}
      >
        <Tabs.Pane label="Edit">
          <NotifySettingsForm {...form} />
          <Header>Content Body</Header>
          <div className="edit-pane">
            <div id={settingsId} className="settings" />
            <div className="editor-area">
              <EditorControls />
              <label className="editor-label">Email Body</label>
              <div id={previewId} className="edit" />
            </div>
          </div>
        </Tabs.Pane>
        <Tabs.Pane label="Preview">
          <SubjectPreviewControl subject={form.subject} previewText={form.previewText} />
          <iframe
            ref={iFrameRef}
            height={iframeHeight}
            scrolling="no"
            frameBorder="0"
            className="preview"
            title="Stripo Email Editor"
            srcDoc={emailPreview}
          />
        </Tabs.Pane>
      </Tabs>
    </div>
  );
};
