import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import ReactCrop, { getPixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Grid, Ref } from "semantic-ui-react";

import "./imageCropper.scss";

import { default as imageUtils } from "../../../../utils/imageUtils";
import CroppedImagePreview from "./croppedImagePreview/CroppedImagePreview";
import autoCrop from "./autoCrop";
import ImageAspectRatio from "../../../../enums/imageAspectRatio";

let fileUrl;

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);

    const crop = {
      aspect: props.aspectRatio,
      width: 90,
      x: 5,
      y: 5,
    };

    this.state = {
      imageRef: null,
      crop,
      initialCrop: crop,
      croppedImageUrl: null,
      cropperVisible: false,
    };

    this.cropper = React.createRef();
  }

  makeClientCrop = async (_, pixelCrop) => {
    const { imageRef } = this.state;
    const { onCroppedImageChange } = this.props;

    if (imageRef && pixelCrop.width && pixelCrop.height) {
      const croppedImage = await imageUtils.getCroppedImg(imageRef, pixelCrop, "newFile", fileUrl);
      this.setState({ croppedImageUrl: croppedImage.newFileUrl });
      onCroppedImageChange(croppedImage.blob);
    }
  };

  onImageLoaded = (image) => {
    const { initialCrop } = this.state;
    let crop = autoCrop(image, initialCrop);

    this.setState({ imageRef: image, cropperVisible: false }, () => {
      this.setState({ crop: crop }, () => {
        // the "nestedness" is on purpose, because "onChange" callback will overwrite calculated crop if set too early
        this.makeClientCrop(crop, getPixelCrop(image, crop));
      });
    });
  };

  onCropChange = (crop) => {
    this.setState({ crop: crop });
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onCropperBlur = () => {
    this.setState({ cropperVisible: false });
  };

  renderCropper = (_) => {
    const { crop } = this.state;
    return (
      <Grid className="cropper-container" onBlur={this.onCropperBlur}>
        <Grid.Row>
          <Grid.Column computer={10} tablet={14} mobile={16}>
            <Ref innerRef={this.cropper}>
              <ReactCrop
                src={this.props.file}
                crop={crop}
                crossorigin="anonymous"
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            </Ref>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  onPreviewClick = (_) => {
    this.setState({ cropperVisible: true }, () => {
      this.cropper.current.focus();
    });
  };

  renderCroppedImage = () => {
    const { croppedImageUrl } = this.state;
    const { aspectRatio } = this.props;
    if (croppedImageUrl) {
      return (
        <Grid className="preview">
          <Grid.Row>
            <Grid.Column computer={8} tablet={12} mobile={16}>
              <CroppedImagePreview
                thumbnailUrl={croppedImageUrl}
                onClick={this.onPreviewClick}
                aspectRatio={aspectRatio}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    }
    return null;
  };

  render() {
    return (
      <div
        className={cn("image-cropper", {
          "cropper-active": this.state.cropperVisible,
        })}
        title="cropper"
      >
        {this.renderCroppedImage()}
        {this.renderCropper()}
      </div>
    );
  }
}

ImageCropper.defaultProps = {
  aspectRatio: ImageAspectRatio.HD_16X9,
};

ImageCropper.propTypes = {
  file: PropTypes.string.isRequired,
  onCroppedImageChange: PropTypes.func.isRequired,
  aspectRatio: PropTypes.oneOf([ImageAspectRatio.HD_16X9, ImageAspectRatio.SQUARE_1X1]),
};

export default ImageCropper;
