import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { WizardWrapper as Wizard } from "../../../../../WizardWrapper";
import { withRouter, WithRouterProps } from "../../../../../../adapters/withRouter/withRouter";

import { CreateDirectSendSendingState } from "./types";

import * as sendingProfileEntityStateActions from "../state/actions/threatDefenceSendingProfileEntityStateActions";
import * as sendingProfileDetailsActions from "../state/actions/threatDefenceSendingProfileDetailsActions";

import Configure from "../DirectSendSendingProfileDetails/Configure/Configure";
import WizardStepsManager from "../../../../../../utils/WizardStepsManager";
import RtnEventsEmitter from "../../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";

import Verification from "../DirectSendSendingProfileDetails/Verification/Verification";
import navigationUtils from "../../../../../../utils/navigationUtils";

const sendingProfilesOverviewUrl = "/content/simulated-phishing/sending-profiles";

export type CreateDirectSendSendingProfilePropsAll = PropsFromRedux & WithRouterProps;

export class CreateThreatDefenceDirectSendSendingProfile extends Component<
  CreateDirectSendSendingProfilePropsAll,
  CreateDirectSendSendingState
> {
  private stepsManager = new WizardStepsManager();

  constructor(props: CreateDirectSendSendingProfilePropsAll) {
    super(props);
    this.state = {
      isValid: false,
      canPublish: false,
    };
  }

  onCancel = () => {
    navigationUtils.goBackOrDefault(
      this.props.location,
      this.props.navigate,
      "/content/simulated-phishing/sending-profiles",
    );
  };

  componentDidMount() {
    RtnEventsEmitter.subscribe([rtnEvents.DirectSendSendingProfilePublishSuccess], this.onPublishedEvent);
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe([rtnEvents.DirectSendSendingProfilePublishSuccess], this.onPublishedEvent);
  }

  onPublishedEvent = () => {
    this.props.navigate(sendingProfilesOverviewUrl);
  };

  onIsValidChange = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  };

  onContentIsValidChange = (isValid: boolean) => {
    if (this.state.canPublish !== isValid) {
      this.setState({ canPublish: isValid });
    }
  };

  render() {
    const { isValid, canPublish } = this.state;
    const { isSaving, dateModified } = this.props;

    return (
      <Wizard
        onCancel={this.onCancel}
        onProgressAsync={(_: any, nextIndex: number) => this.stepsManager.onNext(nextIndex)}
        onRegressAsync={(_: any, nextIndex: number) => this.stepsManager.onPrevious(nextIndex)}
        onFinishAsync={this.stepsManager.onFinish}
        isSaveInProgress={isSaving}
        progressSavedDate={dateModified}
        isFinishButtonDisabled={!canPublish}
        title="Create Direct Send Sending Profile"
        finishButtonLabel="Publish"
      >
        <Wizard.Step label="Configure" className="scrollable-content" isLocked={!isValid} required>
          <Configure
            onIsValidChange={this.onIsValidChange}
            disabled={false}
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 0)}
          />
        </Wizard.Step>
        <Wizard.Step label="Verification" className="scrollable-content" isLocked={!isValid}>
          <Verification
            onIsValidChange={this.onContentIsValidChange}
            acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, 1)}
            disabled={false}
          />
        </Wizard.Step>
      </Wizard>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isSaving:
    state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileEntityStateReducer
      .isEntityCommandInProgress,
  dateModified:
    state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileEntityStateReducer
      .lastModifiedDateTime,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(sendingProfileEntityStateActions, dispatch),
  detailsActions: bindActionCreators(sendingProfileDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(CreateThreatDefenceDirectSendSendingProfile));
