import { bindActionCreators } from "@reduxjs/toolkit";
import { AxisDomain, scaleLog } from "d3";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ChartWrapper, HorizontalBarChart, LineChart, TickValueType } from "../../../../../components/charts";
import { DataPoint } from "../../../../../components/charts/types/HorizontalBarChart";
import { RequestStatusRenderer } from "../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { fetchTeamsUserActivityCounts } from "../../../state/msgraph/graphActions";
import { selectTeamsDeviceUsage, selectTeamsUserActivityCounts } from "../../../state/msgraph/graphSlice";
import {
  callDeviceLineData,
  meetingDeviceLineData,
  selectActivityOverTimeLine,
  totalActivityBarFormatted,
  totalActivityCounts,
} from "../../../state/msgraph/selectors/activitySelectors";
import { selectFormattedBarData } from "../../../state/msgraph/selectors/deviceSelectors";
import {
  barDataHasValues,
  deviceList,
  deviceUsageColorRange,
  FormatActivityTick,
  formatNumberString,
  leftBlackBoxFormatter,
  lookupDeviceFriendlyName,
} from "../../utils/utils";

import "../teamsReport.scss";
import {
  PerformanceFilter,
  createDateRange,
  getFormattedTimeStringFromPeriod,
  lineChartMargins,
  noData,
  totalActivity,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { timeScale } from "components/charts/shared/bsi-time-scale";
import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { sharedAccountReportingHorizontalBarProps } from "features/Reporting/Content/shared";

enum ChartType {
  Line,
  Bar,
}

export interface Props extends PropsFromRedux {
  dateFilter: PerformanceFilter;
}

export const Activity: FC<Props> = ({
  dateFilter,
  teamsDeviceUsage,
  teamsUserActivityCount,
  totalActivityCounts,
  totalActivityBarData,
  activityOverTimeLineData,
  meetingDeviceLineData,
  callDeviceLineData,
  barData,
  getTeamsUserActivityCounts,
}) => {
  const [meetingsChartType, setMeetingsChartType] = useState<ChartType>(ChartType.Line);
  const [callsChartType, setCallsChartType] = useState<ChartType>(ChartType.Line);
  const pieColors = useMemo(() => ["#4789e6", "#e3a23a", "#e17264", "#6ba37e"], []);

  const dateRange = useMemo(
    () => createDateRange(dateFilter.dateFrom, dateFilter.dateTo),
    [dateFilter.dateFrom, dateFilter.dateTo],
  );

  const [chartPeriod, measureRef] = useChartPeriodMeasure(dateRange, lineChartMargins.left + lineChartMargins.right);

  const lineChartTickFormat = useCallback(
    (xValue: string) => getFormattedTimeStringFromPeriod(xValue, chartPeriod, dateRange),
    [chartPeriod, dateRange],
  );

  useEffect(() => {
    if (chartPeriod !== "UNSET") getTeamsUserActivityCounts({ ...dateFilter, aggregation: chartPeriod });
  }, [chartPeriod, dateFilter, getTeamsUserActivityCounts]);

  const activityLineTooltipFormatter = useCallback((_x: Date, y: number): string => {
    return formatNumberString(y);
  }, []);

  const meetingsTooltipFormatter = useCallback(
    (date: Date, percentage: number): string => {
      // Convert date object to "YYYY-MM-DD" with padded 0's if needed
      // This is the format of the API "ReportDate"
      const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      const valueIndex = teamsUserActivityCount.value.findIndex((v) => v.ReportDate === dateString);
      return `Total Meetings: ${teamsUserActivityCount.value[valueIndex].Meetings} | ${percentage}%`;
    },
    [teamsUserActivityCount.value],
  );

  const callsTooltipFormatter = useCallback(
    (date: Date, percentage: number): string => {
      // Convert date object to "YYYY-MM-DD" with padded 0's if needed
      // This is the format of the API "ReportDate"
      const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      const valueIndex = teamsUserActivityCount.value.findIndex((v) => v.ReportDate === dateString);
      return `Total Calls: ${teamsUserActivityCount.value[valueIndex].Calls} | ${percentage}%`;
    },
    [teamsUserActivityCount.value],
  );

  const handleMeetingsDropdownChange = useCallback((s: string) => {
    s === "Meetings By Device - Line Chart"
      ? setMeetingsChartType(ChartType.Line)
      : setMeetingsChartType(ChartType.Bar);
  }, []);

  const handleCallsDropdownChange = useCallback((s: string) => {
    s === "Audio Calls By Device - Line Chart" ? setCallsChartType(ChartType.Line) : setCallsChartType(ChartType.Bar);
  }, []);

  const barMeetingsTooltipFormatter = useCallback(
    (d: DataPoint) => {
      const roundedValue = Math.round((totalActivityCounts.Meetings * d.value) / 100);

      // Pie data gives total count of meetings
      return `${formatNumberString(roundedValue)} | ${d.value}%`;
    },
    [totalActivityCounts.Meetings],
  );

  const barCallsTooltipFormatter = useCallback(
    (d: DataPoint) => {
      const roundedValue = Math.round((totalActivityCounts.Calls * d.value) / 100);

      // Pie data gives total count of meetings
      return `${formatNumberString(roundedValue)} | ${d.value}%`;
    },
    [totalActivityCounts.Calls],
  );

  const totalActivityBarFormatter = useCallback(
    ({ value, category }: DataPoint): string => {
      let toDisplay = totalActivityCounts[category as keyof typeof totalActivityCounts];
      return `${FormatActivityTick(category)} | ${formatNumberString(toDisplay)} | ${value}%`;
    },
    [totalActivityCounts],
  );

  // Showing every other tick on x because of small width causing tick overlap
  const xAxisTickFormat = useCallback((d: AxisDomain, i: number) => (i % 2 === 0 ? `${d.toString()}%` : ""), []);
  const yAxisTickFormat = useCallback((d: AxisDomain) => FormatActivityTick(d.toLocaleString()), []);

  // Chart states
  const totalActivityBarChart = useMemo(
    () =>
      barDataHasValues(totalActivityBarData) ? (
        <HorizontalBarChart
          {...sharedAccountReportingHorizontalBarProps}
          data={totalActivityBarData}
          tooltipFormatter={totalActivityBarFormatter}
          yAxisTickFormat={yAxisTickFormat}
          xAxisTickFormat={xAxisTickFormat}
          transitionDurationInMs={0}
        />
      ) : (
        noData(dateFilter)
      ),
    [totalActivityBarData, totalActivityBarFormatter, xAxisTickFormat, yAxisTickFormat, dateFilter],
  );

  const activityOverTimeLineChart = useMemo(
    () =>
      validLineData([
        activityOverTimeLineData.PrivateChatMessages,
        activityOverTimeLineData.Calls,
        activityOverTimeLineData.Meetings,
        activityOverTimeLineData.PostMessages,
      ]) ? (
        <LineChart
          margins={lineChartMargins}
          xData={[
            activityOverTimeLineData.dates,
            activityOverTimeLineData.dates,
            activityOverTimeLineData.dates,
            activityOverTimeLineData.dates,
          ]}
          yData={[
            activityOverTimeLineData.PrivateChatMessages,
            activityOverTimeLineData.Calls,
            activityOverTimeLineData.Meetings,
            activityOverTimeLineData.PostMessages,
          ]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yScaleRatio={scaleLog}
          yFormatterFunc=".1s"
          yTickValueType={TickValueType.IntegersOnly}
          colorRange={pieColors}
          tooltipFormatter={activityLineTooltipFormatter}
          transitionDuration={0}
        />
      ) : (
        noData(dateFilter)
      ),
    [
      activityOverTimeLineData.PrivateChatMessages,
      activityOverTimeLineData.Calls,
      activityOverTimeLineData.Meetings,
      activityOverTimeLineData.PostMessages,
      activityOverTimeLineData.dates,
      lineChartTickFormat,
      pieColors,
      activityLineTooltipFormatter,
      dateFilter,
    ],
  );

  const meetingsLineChart = useMemo(
    () =>
      validLineData([
        meetingDeviceLineData.Android,
        meetingDeviceLineData.ChromeOS,
        meetingDeviceLineData.iOS,
        meetingDeviceLineData.Linux,
        meetingDeviceLineData.Mac,
        meetingDeviceLineData.Web,
        meetingDeviceLineData.Windows,
        meetingDeviceLineData.WindowsPhone,
      ]) ? (
        <LineChart
          margins={lineChartMargins}
          xData={[
            meetingDeviceLineData.dates,
            meetingDeviceLineData.dates,
            meetingDeviceLineData.dates,
            meetingDeviceLineData.dates,
            meetingDeviceLineData.dates,
            meetingDeviceLineData.dates,
            meetingDeviceLineData.dates,
            meetingDeviceLineData.dates,
          ]}
          yData={[
            meetingDeviceLineData.Android,
            meetingDeviceLineData.ChromeOS,
            meetingDeviceLineData.iOS,
            meetingDeviceLineData.Linux,
            meetingDeviceLineData.Mac,
            meetingDeviceLineData.Web,
            meetingDeviceLineData.Windows,
            meetingDeviceLineData.WindowsPhone,
          ]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yExtraTickFormat={xAxisTickFormat}
          yTickValueType={TickValueType.IntegersOnly}
          yBlackBoxFormat={leftBlackBoxFormatter}
          tooltipFormatter={meetingsTooltipFormatter}
          colorRange={deviceUsageColorRange}
          transitionDuration={0}
        />
      ) : (
        noData(dateFilter)
      ),
    [
      meetingDeviceLineData.Android,
      meetingDeviceLineData.ChromeOS,
      meetingDeviceLineData.iOS,
      meetingDeviceLineData.Linux,
      meetingDeviceLineData.Mac,
      meetingDeviceLineData.Web,
      meetingDeviceLineData.Windows,
      meetingDeviceLineData.WindowsPhone,
      meetingDeviceLineData.dates,
      lineChartTickFormat,
      xAxisTickFormat,
      meetingsTooltipFormatter,
      dateFilter,
    ],
  );

  const meetingsBarChart = useMemo(
    () =>
      barDataHasValues(barData) && teamsUserActivityCount.value.length > 0 ? (
        <HorizontalBarChart
          {...sharedAccountReportingHorizontalBarProps}
          data={barData}
          domain={[0, Math.max(...barData.map((d) => d.value))]}
          xAxisTickFormat={xAxisTickFormat}
          yAxisTickFormat={lookupDeviceFriendlyName}
          tooltipFormatter={barMeetingsTooltipFormatter}
          transitionDurationInMs={0}
        />
      ) : (
        noData(dateFilter)
      ),
    [barData, barMeetingsTooltipFormatter, teamsUserActivityCount.value.length, xAxisTickFormat, dateFilter],
  );

  const callsLineChart = useMemo(
    () =>
      validLineData([
        callDeviceLineData.Android,
        callDeviceLineData.ChromeOS,
        callDeviceLineData.iOS,
        callDeviceLineData.Linux,
        callDeviceLineData.Mac,
        callDeviceLineData.Web,
        callDeviceLineData.Windows,
        callDeviceLineData.WindowsPhone,
      ]) ? (
        <LineChart
          margins={lineChartMargins}
          xData={[
            callDeviceLineData.dates,
            callDeviceLineData.dates,
            callDeviceLineData.dates,
            callDeviceLineData.dates,
            callDeviceLineData.dates,
            callDeviceLineData.dates,
            callDeviceLineData.dates,
            callDeviceLineData.dates,
          ]}
          yData={[
            callDeviceLineData.Android,
            callDeviceLineData.ChromeOS,
            callDeviceLineData.iOS,
            callDeviceLineData.Linux,
            callDeviceLineData.Mac,
            callDeviceLineData.Web,
            callDeviceLineData.Windows,
            callDeviceLineData.WindowsPhone,
          ]}
          xScaleRatio={timeScale}
          xFormatterFunc={lineChartTickFormat}
          yExtraTickFormat={xAxisTickFormat}
          yBlackBoxFormat={leftBlackBoxFormatter}
          yTickValueType={TickValueType.IntegersOnly}
          tooltipFormatter={callsTooltipFormatter}
          colorRange={deviceUsageColorRange}
          transitionDuration={0}
        />
      ) : (
        noData(dateFilter)
      ),
    [
      callDeviceLineData.Android,
      callDeviceLineData.ChromeOS,
      callDeviceLineData.iOS,
      callDeviceLineData.Linux,
      callDeviceLineData.Mac,
      callDeviceLineData.Web,
      callDeviceLineData.Windows,
      callDeviceLineData.WindowsPhone,
      callDeviceLineData.dates,
      lineChartTickFormat,
      xAxisTickFormat,
      callsTooltipFormatter,
      dateFilter,
    ],
  );

  const callsBarChart = useMemo(
    () =>
      barDataHasValues(barData) && teamsUserActivityCount.value.length > 0 ? (
        <HorizontalBarChart
          {...sharedAccountReportingHorizontalBarProps}
          data={barData}
          domain={[0, Math.max(...barData.map((d) => d.value))]}
          xAxisTickFormat={xAxisTickFormat}
          yAxisTickFormat={lookupDeviceFriendlyName}
          tooltipFormatter={barCallsTooltipFormatter}
          transitionDurationInMs={0}
        />
      ) : (
        noData(dateFilter)
      ),
    [barCallsTooltipFormatter, barData, teamsUserActivityCount.value.length, xAxisTickFormat, dateFilter],
  );

  return (
    <>
      <div className="teams-chart-container">
        <div className="pie-container">
          <ChartWrapper titles={[totalActivity]}>
            <RequestStatusRenderer state={teamsUserActivityCount}>{totalActivityBarChart}</RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div className="right" ref={measureRef}>
          <ChartWrapper
            titles={["Activity Over Time"]}
            showLegend
            legendLabels={["Private Chat Messages", "Calls", "Meetings", "Post Messages"]}
            colorRange={pieColors}
          >
            <RequestStatusRenderer state={teamsUserActivityCount}>{activityOverTimeLineChart}</RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
      <div className="teams-chart-container">
        <ChartWrapper
          titles={["Meetings By Device - Line Chart", "Meetings By Device - Bar Chart"]}
          onChange={handleMeetingsDropdownChange}
          showLegend={meetingsChartType === ChartType.Line}
          legendLabels={deviceList}
          colorRange={deviceUsageColorRange}
        >
          <RequestStatusRenderer state={[teamsDeviceUsage, teamsUserActivityCount]}>
            {meetingsChartType === ChartType.Line ? meetingsLineChart : meetingsBarChart}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className="teams-chart-container">
        <ChartWrapper
          titles={["Audio Calls By Device - Line Chart", "Audio Calls By Device - Bar Chart"]}
          onChange={handleCallsDropdownChange}
          showLegend={callsChartType === ChartType.Line}
          legendLabels={deviceList}
          colorRange={deviceUsageColorRange}
        >
          <RequestStatusRenderer state={[teamsDeviceUsage, teamsUserActivityCount]}>
            {callsChartType === ChartType.Line ? callsLineChart : callsBarChart}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  teamsDeviceUsage: selectTeamsDeviceUsage(state),
  teamsUserActivityCount: selectTeamsUserActivityCounts(state),
  totalActivityCounts: totalActivityCounts(state),
  totalActivityBarData: totalActivityBarFormatted(state),
  activityOverTimeLineData: selectActivityOverTimeLine(state),
  barData: selectFormattedBarData(state),
  meetingDeviceLineData: meetingDeviceLineData(state),
  callDeviceLineData: callDeviceLineData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTeamsUserActivityCounts: bindActionCreators(fetchTeamsUserActivityCounts, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Activity);
