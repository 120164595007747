import ValidatedForm from "../../../../../../../components/forms/ValidatedForm";
import RestrictedByTooltip from "../../../../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { Button } from "components/buttons/button/Button";

export interface SendEmailFormProps {
  email: string;
  onSubmit: (email: string) => void;
  disabled: boolean;
  hasPermission: boolean;
}

export const SendEmailForm = (props: any) => {
  const { validatedFieldProps, values, disabled, hasPermission } = props;
  const { email } = values;

  return (
    <div className={"send-email-input-button-container"}>
      <div className="send-email-form-input">
        <ValidatedForm.InputField
          disabled={disabled || !hasPermission}
          value={email}
          propertyName="email"
          placeholder="Enter Email"
          errorPosition="bottom"
          {...validatedFieldProps}
        />
      </div>
      <div className={"send-email-button-container"}>
        <RestrictedByTooltip hasPermission={hasPermission}>
          <Button
            blur
            onClick={() => props.onSubmit(email)}
            primary
            fluid
            className="ui fluid primary button next control"
            disabled={disabled || !hasPermission || !validatedFieldProps.isFormValid}
          >
            <span className="title">Send Test Email</span>
          </Button>
        </RestrictedByTooltip>
      </div>
    </div>
  );
};
