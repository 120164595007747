import TriggerableConfirmationModal from "components/triggeredConfirmationModal/TriggerableConfirmationModal";
import ModalSizes from "enums/modalSizes";
import { IObservable } from "interfaces/IObservable";
import { FC, useState } from "react";
import PackAccountItems from "./packAccountItems/PackAccountItems";
import { PackMap } from "./types";
import cn from "classnames";
import styles from "./groupTemplatePacksConfirmationModal.module.scss";

export interface GroupTemplatePacksConfirmationModalProps {
  isEdit?: boolean;
  packAccountItemsText: string;
  onTriggerModalObserver: IObservable<(onConfirm: () => void, packMap: PackMap) => void>;
}

export const GroupTemplatePacksConfirmationModal: FC<GroupTemplatePacksConfirmationModalProps> = (props) => {
  const { isEdit, packAccountItemsText, onTriggerModalObserver } = props;
  const [packMap, setPackMap] = useState<PackMap>(new Map());
  const [isDisabled, setIsDisabled] = useState(true);

  const getContent = (
    <PackAccountItems isEdit={isEdit} infoText={packAccountItemsText} packMap={packMap} setIsDisabled={setIsDisabled} />
  );

  return (
    <TriggerableConfirmationModal
      title={isEdit ? "Edit Licensing" : "Provision Licenses"}
      scrolling
      content={getContent}
      confirmButtonLabel={isEdit ? "Save" : "Finish"}
      onTriggerModalObserver={onTriggerModalObserver}
      disabled={isDisabled}
      size={ModalSizes.small}
      styles={styles}
      classNames={cn({ [styles["multiple-packs"]]: packMap.size > 2 })}
      onTrigger={(input: PackMap) => {
        setPackMap(input);
      }}
    />
  );
};
