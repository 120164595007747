import {
  createCancelingLazyFetchingItemsSlice,
  CancelingLazyFetchingItemsState,
} from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import { PackOverview } from "../../types/state";
import { fetchOverviewGridPacks } from "../thunks/packsOverviewThunk";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { PayloadAction } from "@reduxjs/toolkit";

export type OverviewState = CancelingLazyFetchingItemsState<PackOverview>;

export const initialState: OverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const packsOverviewGridSlice = createCancelingLazyFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "overviewGrid",
  },
  initialState: initialState,
  reducers: {
    markCardAsTrialStarted: (state: OverviewState, action: PayloadAction<number>) => {
      const pack = state.items.find((item) => item.id === action.payload);
      if (pack) {
        pack.isTrial = true;
        pack.isPurchased = true;
      }
    },
    markCardAsRequested: (state: OverviewState, action: PayloadAction<number>) => {
      const pack = state.items.find((item) => item.id === action.payload);

      if (pack) {
        pack.isRequested = true;
      }
    },
    markCardAsPurchased: (state: OverviewState, action: PayloadAction<number>) => {
      const pack = state.items.find((item) => item.id === action.payload);

      if (pack) {
        pack.isPurchased = true;
      }
    },
  },
  thunk: fetchOverviewGridPacks,
});

export const { reset, markCardAsTrialStarted, markCardAsRequested, markCardAsPurchased } =
  packsOverviewGridSlice.actions;

export default packsOverviewGridSlice.reducer;
