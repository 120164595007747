import React from "react";
import { useDrag, useDrop, DropTargetMonitor } from "react-dnd";
import { XYCoord } from "dnd-core";
import cn from "classnames";

import { RemovableCard } from "../../cards";
import DragNDropItemTypes from "../../../enums/dragNDropItemTypes";

import "./draggableListItem.scss";

interface CompatibleDrop {
  id: number;
  type: DragNDropItemTypes;
  index?: number;
}

export interface Props {
  id: number;
  type?: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  acceptType: DragNDropItemTypes;
  title?: string;
  description?: string;
  thumbnailUrl?: string;
  meta?: string;
  onRemoveItemClick?: (id: number, type?: string) => void;
  isReadOnly?: boolean;
}

export default function DraggableListItem(props: Props) {
  const { id, index, moveCard, acceptType } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<CompatibleDrop, any, any>({
    accept: acceptType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: CompatibleDrop, monitor: DropTargetMonitor) {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      // If dragndropin' from the panel
      if (dragIndex === undefined) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      // @ts-ignore, ref instance will never be undefined
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      // @ts-ignore
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DragNDropItemTypes.PACK,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <div ref={ref} className={cn("draggable-list-item", { dragging: isDragging })} data-handler-id={handlerId}>
      <RemovableCard {...props} />
    </div>
  );
}
