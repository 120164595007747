import React from "react";
import { func, string, object, array } from "prop-types";
import MultiSelectWithAddition from "./MultiSelectWithAddition";
import uniqBy from "lodash/uniqBy";

function FilterMultiSelectWithAddition(props) {
  const {
    id,
    additionLabel,
    additionPosition,
    handleMultiSelectAddition,
    handleMultiSelectClick,
    className,
    placeholder,
    updateFilter,
    filter,
    propertyName,
  } = props;
  const { items, ...multiSelectProps } = props;

  const onValueChange = (_, { value }) => {
    let newFilter = { ...filter };

    if (value.length !== 0) {
      newFilter[propertyName] = value;
    } else {
      delete newFilter[propertyName];
    }

    updateFilter(newFilter);
  };

  const selected = filter[propertyName] ? filter[propertyName] : [];
  const options = items ? uniqBy([...items, ...selected.map((x) => ({ text: x, value: x }))], "value") : null;

  return (
    <MultiSelectWithAddition
      id={id}
      className={className}
      additionLabel={additionLabel}
      additionPosition={additionPosition}
      onAddItem={handleMultiSelectAddition}
      onClick={handleMultiSelectClick}
      selected={selected}
      placeholder={placeholder}
      handleMultiSelectChange={onValueChange}
      items={options}
      {...multiSelectProps}
    />
  );
}

FilterMultiSelectWithAddition.propTypes = {
  id: string,
  additionLabel: string,
  handleMultiSelectClick: func,
  handleMultiSelectAddition: func,
  className: string,
  placeholder: string,
  updateFilter: func,
  filter: object,
  propertyName: string,
  items: array,
};

export default FilterMultiSelectWithAddition;
