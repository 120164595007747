import { Dispatch } from "@reduxjs/toolkit";
import { err, got, req } from "../slices/emailSettingsSlice";
import accountsDataService from "features/Accounts/services/accountsDataService";
import { EmailSetting } from "features/Accounts/types";
import { getAxiosError } from "utils/axiosError";

export const fetchEmailSettings = (accountId: number) => async (dispatch: Dispatch) => {
  dispatch(req());
  try {
    const settings = await accountsDataService.getEmailSettings(accountId);
    dispatch(got(settings));
  } catch (e) {
    dispatch(err(new Error(e.message)));
  }
};

export const upsertEmailSettings = (settings: EmailSetting) => async (dispatch: Dispatch) => {
  dispatch(req());
  try {
    if (!settings.id) {
      await accountsDataService.createEmailSettings(settings);
    } else {
      await accountsDataService.updateEmailSettings(settings);
    }
    await fetchEmailSettings(settings.accountId)(dispatch);
  } catch (e) {
    dispatch(err(new Error(e.message)));
  }
};

export const deleteEmailSettings = (accountId: number) => async (dispatch: Dispatch) => {
  dispatch(req());
  try {
    await accountsDataService.deleteEmailSetting(accountId);
    await fetchEmailSettings(accountId)(dispatch);
  } catch (e) {
    dispatch(err(new Error(e.message)));
  }
};

export const verifyEmailSettings = (receiverValidationEmail: string, settings: EmailSetting) => async () => {
  try {
    await accountsDataService.verifyEmailSettings({ ...settings, receiverValidationEmail });
    return { verified: true };
  } catch (e) {
    const errorMessage: string = getAxiosError(e) || e?.response?.data;
    return { verified: false, message: errorMessage };
  }
};
