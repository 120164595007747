import React from "react";
import { Table } from "semantic-ui-react";
import PermissionsList from "./../Permissions/PermissionsList";
import RoleIcon from "./RoleIcon/RoleIcon";
import { AddToLinkButton, EditLinkButton, DeleteLinkButton } from "../../../../components/buttons/linkButtons";
import TitleSubtitle from "./../../../../components/listViewTemplates/titleSubtitle/TitleSubtitle";
import EllipsisPopupButton from "./../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import dateTimeUtils from "../../../../utils/dateTimeUtils";

import "./roleRow.scss";
import Role from "../../../../interfaces/role";
import { ColumnOptions } from "../../../../interfaces";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";

export const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 6,
    isSortable: true,
    className: "role-name-header",
  },
  {
    name: "People",
    width: 2,
    isSortable: false,
  },
  {
    name: "Permissions",
    width: 4,
    isSortable: false,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export type ButtonHandler = (role: Role) => void;

export interface RoleRowProps {
  role: Role;
  onNavigate?(id: number): void;
  rowButtonHandlers?: {
    onDeleteRole: ButtonHandler;
    onEditRole: ButtonHandler;
    onAddUsers: ButtonHandler;
  };
  rowButtonsDisabled?: boolean;
  hasPermission?: boolean;
}

const RoleRow = ({ role, onNavigate, rowButtonHandlers, rowButtonsDisabled, hasPermission = true }: RoleRowProps) => {
  const renderDeleteButton = () => {
    if (!role.isDefault) {
      return <DeleteLinkButton onClick={() => rowButtonHandlers?.onDeleteRole(role)} />;
    }

    return null;
  };

  return (
    <React.Fragment>
      <Table.Cell width={columnOptions[0].width} className="role-info">
        <RoleIcon isDefaultRole={role.isDefault} />
        <div className="role-title-subtitle">
          <TitleSubtitle
            id={role.id}
            title={role.name}
            subtitle={role.description}
            subtitleClamp={2}
            onNavigate={onNavigate}
          />
        </div>
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{role.usersCount}</Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <PermissionsList areas={role.areas} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(role.added)}</Table.Cell>
      <Table.Cell className="align-right-role-overview" width={columnOptions[4].width}>
        {rowButtonHandlers && (
          <RestrictedByTooltip hasPermission={hasPermission}>
            <EllipsisPopupButton circle outlinedEllipsis disabled={rowButtonsDisabled}>
              {!role.isDefault && <EditLinkButton onClick={() => rowButtonHandlers.onEditRole(role)} />}
              {rowButtonHandlers.onAddUsers && (
                <AddToLinkButton text="Add People" onClick={() => rowButtonHandlers.onAddUsers(role)} />
              )}
              {renderDeleteButton()}
            </EllipsisPopupButton>
          </RestrictedByTooltip>
        )}
      </Table.Cell>
    </React.Fragment>
  );
};

export default RoleRow;
