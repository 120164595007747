import * as d3 from "d3";
import { Margin } from "../types/Shared";
import { wrappedGetComputedTextLength } from "./textLength";
import moment from "moment";

export function getTranslateString(x: number, y: number) {
  return `translate(${x},${y})`;
}

// d3 requires data in order to determine if it should "enter" or "update".
// This ensures that a d3 selection will only "enter" once
export const APPEND_ONCE = [true];

export function isLogScale(scale: unknown) {
  return scale === d3.scaleLog || scale === d3.scaleDivergingLog || scale === d3.scaleSequentialLog;
}

/**
 * Protects log scales from resulting in
 * a solitary '1' tick in the center
 * @param topDataValue
 * @returns
 */
export function getLogScaleDomain(topDataValue: number): [number, number] {
  const minimum = 1;
  const defaultTop = 10;
  if (topDataValue === 1) {
    return [minimum, defaultTop];
  }
  return [minimum, topDataValue];
}

export function hideTicksWithNoText(selection: d3.Selection<d3.BaseType, unknown, SVGGElement, unknown>) {
  selection.nodes().forEach((node) => {
    const text = node as SVGTextElement;
    if (!text.textContent) {
      (text.parentNode as SVGGElement).style.display = "none";
    } else {
      (text.parentNode as SVGGElement).style.display = "block";
    }
  });
}

export function calculateChartDimensions(containerRect: DOMRect | null, margins: Margin) {
  const totalWidth = containerRect?.width ?? 0;
  const totalHeight = containerRect?.height ?? 0;
  return {
    totalWidth,
    totalHeight,
    innerWidth: totalWidth - margins.left - margins.right,
    innerHeight: totalHeight - margins.top - margins.bottom,
  };
}

export function getColorScale(colorRange: string[], dataLength: number) {
  // Temporary until I can figure out why a domain of one element
  // isn't returning a proper number
  if (dataLength === 1) {
    return () => colorRange[0];
  }

  const interpolator = d3.interpolateRgbBasis(colorRange);
  const interpDenominator = Math.max(dataLength - 1, 1);
  let domain = [];
  for (let i = 0; i < dataLength; i++) {
    domain.push(i);
  }
  return d3
    .scaleLinear<string>()
    .domain(domain)
    .range(
      domain.map((index) => {
        // Avoid divide by 0 error
        return interpolator(index / interpDenominator);
      }),
    );
}

type D3SvgSelection = d3.Selection<SVGSVGElement, unknown, null, undefined>;

export function drawCenteringGroup(svg: D3SvgSelection, leftMargin: number, topMargin: number, transitionDuration = 0) {
  return svg
    .selectAll(".centering-group")
    .data(APPEND_ONCE)
    .join(
      (enter) =>
        enter.append("g").attr("transform", getTranslateString(leftMargin, topMargin)).attr("class", "centering-group"),
      (update) => {
        update.transition().duration(transitionDuration).attr("transform", getTranslateString(leftMargin, topMargin));
        return update;
      },
    );
}

export function drawXAxis(
  mainArea: d3.Selection<d3.BaseType, boolean, d3.BaseType, unknown>,
  xAxis: d3.Axis<any>,
  innerHeight: number,
  transitionDurationRef?: React.MutableRefObject<number>,
) {
  return mainArea
    .selectAll<SVGGElement, boolean[]>(".x-axis-group")
    .data(APPEND_ONCE)
    .join(
      (enter) =>
        enter.append("g").attr("class", "x-axis-group").attr("transform", `translate(0, ${innerHeight})`).call(xAxis),
      (update) => {
        update
          .transition()
          .duration(transitionDurationRef ? transitionDurationRef.current : 0)
          .attr("transform", `translate(0, ${innerHeight})`)
          .call(xAxis);
        return update;
      },
    );
}

export function drawYAxis(
  mainArea: d3.Selection<d3.BaseType, boolean, d3.BaseType, unknown>,
  yAxis: d3.Axis<d3.AxisDomain>,
  transitionDurationRef: React.MutableRefObject<number>,
) {
  return mainArea
    .selectAll<SVGGElement, boolean[]>(".y-axis-group")
    .data(APPEND_ONCE)
    .join(
      (enter) => enter.append("g").attr("class", "y-axis-group").call(yAxis),
      (update) => {
        update.transition().duration(transitionDurationRef.current).call(yAxis);
        return update;
      },
    );
}

export function drawXAxisForBarChart(
  mainArea: d3.Selection<d3.BaseType, boolean, d3.BaseType, unknown>,
  xAxis: d3.Axis<string>,
  innerHeight: number,
  transitionDurationRef: React.MutableRefObject<number>,
  ellipsisFunc: (_data: unknown, index: number, elements: ArrayLike<SVGTextElement>) => void,
) {
  return mainArea
    .selectAll<SVGGElement, boolean[]>(".x-axis-group")
    .data(APPEND_ONCE)
    .join(
      (enter) =>
        enter.append("g").attr("class", "x-axis-group").attr("transform", `translate(0, ${innerHeight})`).call(xAxis),
      (update) => {
        update
          .transition()
          .duration(transitionDurationRef.current)
          // Ensures ellipsis carry over on hover
          .tween("text ellipsis", function () {
            const innerText = d3.select(this).selectAll<SVGTextElement, unknown>("text");

            return function () {
              innerText.each(ellipsisFunc);
            };
          })
          .attr("transform", `translate(0, ${innerHeight})`)
          .call(xAxis);
        return update;
      },
    );
}

// https://stackoverflow.com/questions/15975440/add-ellipses-to-overflowing-text-in-svg
export function textEllipsis(
  _this: SVGTextElement,
  maxWidth: number,
  padding: number,
  textNodes: [
    node1: SVGTextContentElement | null,
    node2: SVGTextContentElement | null,
    node3: SVGTextContentElement | null,
  ] = [null, null, null],
  stringToAppend = "\u2026",
) {
  const self = d3.select(_this),
    node = self.node();

  if (!node) return;

  // If only one node, return whole string
  if (textNodes[1] && !textNodes[2] && !textNodes[0]) {
    return;
  }

  let leftNodeDist = 0,
    rightNodeDist = 0;
  if (textNodes[0] && textNodes[1]) {
    leftNodeDist = textNodes[1].getBoundingClientRect().x - textNodes[0].getBoundingClientRect().x;
  }
  if (textNodes[1] && textNodes[2]) {
    rightNodeDist = textNodes[2].getBoundingClientRect().x - textNodes[1].getBoundingClientRect().x;
  }
  if (rightNodeDist || leftNodeDist) {
    maxWidth = Math.min(...[leftNodeDist, rightNodeDist].filter((val) => val > 0));
    padding = 1.25;
  }

  let textLength = wrappedGetComputedTextLength(node),
    text = self.text();

  // This is a temporary fix until I can figure out why
  // getComputedTextLength() is returning incorrectly on its
  // first iteration. Previously was maxWidth - 1.75
  while (textLength > maxWidth - 2 * padding && text.length > 0) {
    text = text.slice(0, -1);
    self.text(text + stringToAppend);
    textLength = wrappedGetComputedTextLength(node);
  }
}

const TINY = 300;
const SMALL = 550;
const MEDIUM_SMALL = 700;
const MEDIUM = 1000;
const LARGE = 1500;

type Pair<T> = [T, T];

export function getTickCountBySize(size: number, range?: Pair<unknown>) {
  let rangeDifference = Infinity;
  if (range) {
    const firstDate = moment(range[0]!, moment.ISO_8601, true),
      secondDate = moment(range[1]!, moment.ISO_8601, true);
    if (firstDate.isValid() && secondDate.isValid()) {
      const diff = Math.abs(secondDate.diff(firstDate, "day"));
      rangeDifference = diff;
    }
  }
  let tickCountFromWidth = 10;
  if (size < TINY) tickCountFromWidth = 1;
  else if (size < SMALL) tickCountFromWidth = 3;
  else if (size < MEDIUM) tickCountFromWidth = 5;

  return Math.max(1, Math.min(rangeDifference, tickCountFromWidth));
}

// Used to compute day division for date ranges
// less than a month of time (30 days),
// which fixes some issues with x-axis alignment
// when dates are involved
export class DayDividerCalculator {
  private m_count: number = 0;
  // Excluding primes
  private factors: number[] = [];

  constructor() {
    this.m_count = 0;
  }

  public updateCount(count: number): void {
    if (this.m_count !== count) {
      this.m_count = count;
      this.generateFactors();
    }
  }

  private generateFactors(): void {
    let newFactors: number[] = [];
    // Exclude 1 & itself
    for (let i = 2; i < this.m_count; i++) {
      if (this.m_count % i === 0) {
        newFactors.push(i);
      }
    }
    this.factors = newFactors;
  }

  getDividerCountForSize(size: number): number {
    const { factors, m_count } = this;

    // For prime numbers,
    if (factors.length === 0) {
      if (size < LARGE) return m_count;
      return 1;
    }

    if (size < SMALL) {
      return m_count;
    }
    if (size < MEDIUM_SMALL) {
      if (factors.length > 2) return factors[2];
      return m_count;
    }
    if (size < MEDIUM) {
      if (factors.length > 1) return factors[1];
      return m_count;
    }
    if (size < LARGE) {
      return factors[0];
    }

    return 1;
  }
}
