import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import * as filterActionTypes from "./editUserGroupsFilterActionTypes";
import { fetchGroupsFilterOptions } from "../../../Groups/GroupsOverview/state/fetchGroupsFilterOptions";

export const setGroupsFilter = makeActionCreator(filterActionTypes.USER_EDIT_GROUPS_SET_ITEMS_FILTER, "filter");

export const resetGroupsFilter = makeActionCreator(filterActionTypes.USER_EDIT_GROUPS_RESET_ITEMS_FILTER);

export const fetchFilterOptions = () =>
  fetchGroupsFilterOptions(
    filterActionTypes.USER_EDIT_GROUPS_FILTER_OPTIONS_FETCH_BEGIN,
    filterActionTypes.USER_EDIT_GROUPS_FILTER_OPTIONS_FETCH_SUCCESS,
    filterActionTypes.USER_EDIT_GROUPS_FILTER_OPTIONS_FETCH_FAILURE,
  );
