import React, { FC } from "react";
import { Icon } from "semantic-ui-react";
import cn from "classnames";

import { TextDotDotDot } from "../../textTruncators/TextTruncators";
import { Tooltip } from "../../common/tooltip";
import { ExpandableLinksProps, Link } from "../types";

import ExpandableItems from "../ExpandableItems";
import EmptyState from "./EmptyState/EmptyState";

import styles from "./expandableLinks.module.scss";

const ExpandableLinks: FC<ExpandableLinksProps> = (props) => {
  const handleLinkClick = (id: number) => () => {
    window.open(props.linkFormatter(id));
  };

  const renderLink = ({ id, title }: Link) => (
    <div key={id} role="listitem" className={styles["links-list-item"]} onClick={handleLinkClick(id)}>
      <Icon className={cn(props.iconName)} size="large" />
      <Tooltip
        className={styles["tooltip-content"]}
        target={<TextDotDotDot clamp={1}>{title}</TextDotDotDot>}
        content={title}
      />
    </div>
  );

  return (
    <div className={styles["expandable-links-root"]}>
      <ExpandableItems contentClassName={styles["links-list-items"]} label={props.label}>
        {props.links.length ? props.links.map(renderLink) : <EmptyState label={props.label} />}
      </ExpandableItems>
    </div>
  );
};

export default ExpandableLinks;
