import { useEffect } from "react";
import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { CheckboxProps, Radio } from "semantic-ui-react";

import MultiSelect from "../../../../components/multiSelect/MultiSelect";

import { DropdownProps } from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import { ContentSettingsEnum, VivaIntegrationConfigs } from "../types/viva";
import { RootState } from "../../../Application/globaltypes/redux";
import { bindAction } from "../../../../interfaces";
import { fetchPacksAction } from "../state/thunks/integrationPacksThunk";
import { reset } from "../state/slices/integrationPacksSlice";
import { integrationBaseSelector } from "../state/selectors";

import { useParams } from "react-router-dom";
import Form from "components/forms/layout";

export interface VivaIntegrationConfigurationProps extends PropsFromRedux {
  isCreating?: boolean;
  integrationName: string;
  setConfigs: (configs: VivaIntegrationConfigs) => void;
  configs: VivaIntegrationConfigs;
}

export const VivaIntegrationConfiguration: React.FC<VivaIntegrationConfigurationProps> = (props) => {
  const { integrationName, setConfigs, configs, fetchPacks, packs, isLoading, resetPacks, isCreating } = props;
  const { moboId } = useParams();

  useEffect(() => {
    fetchPacks(moboId);
    return resetPacks;
  }, [fetchPacks, resetPacks, moboId]);

  const handleChangePacks = (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setConfigs({
      ...configs,
      packContentSettings: {
        ...configs.packContentSettings,
        packIds: data.value as number[],
      },
    });
  };

  const onHandleCustomContentChange = (_: any, data: CheckboxProps) =>
    setConfigs({ ...configs, customContentSettings: { contentType: data.value as ContentSettingsEnum } });

  const onHandlePackContentChange = (_: any, data: CheckboxProps) => {
    setConfigs({
      ...configs,
      packContentSettings: {
        ...configs.packContentSettings,
        contentType: data.value as ContentSettingsEnum,
      },
    });
  };

  return (
    <Form.Root>
      {isCreating && <Form.Block title="Integration type">{integrationName}</Form.Block>}
      <Form.Block title="Purchased Content" tooltip={{ content: "Push content contained within a pack." }}>
        <div>
          <span>Pack selection</span>
          <MultiSelect
            items={packs}
            handleMultiSelectChange={handleChangePacks}
            selected={isLoading ? [] : configs.packContentSettings.packIds}
            placeholder="Select pack(s) for more options"
            loading={isLoading}
          />
        </div>
        {configs.packContentSettings.packIds.length !== 0 && (
          <Form.SubBlock>
            <Radio
              onChange={onHandlePackContentChange}
              label="Push all content (excludes communications)"
              checked={configs.packContentSettings.contentType === ContentSettingsEnum.AllContent}
              value={ContentSettingsEnum.AllContent}
            />
            <Radio
              onChange={onHandlePackContentChange}
              label="Push only flows"
              checked={configs.packContentSettings.contentType === ContentSettingsEnum.FlowsOnly}
              value={ContentSettingsEnum.FlowsOnly}
            />
          </Form.SubBlock>
        )}
      </Form.Block>

      <Form.Block
        title="Custom content"
        tooltip={{ content: "Push custom created content not contained within a pack." }}
      >
        <Radio
          onChange={onHandleCustomContentChange}
          label="None"
          checked={configs.customContentSettings.contentType === ContentSettingsEnum.None}
          value={ContentSettingsEnum.None}
        />
        <Radio
          onChange={onHandleCustomContentChange}
          label="Push all content (excludes communications)"
          checked={configs.customContentSettings.contentType === ContentSettingsEnum.AllContent}
          value={ContentSettingsEnum.AllContent}
        />
        <Radio
          onChange={onHandleCustomContentChange}
          label="Push only flows"
          checked={configs.customContentSettings.contentType === ContentSettingsEnum.FlowsOnly}
          value={ContentSettingsEnum.FlowsOnly}
        />
      </Form.Block>
    </Form.Root>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = integrationBaseSelector(state).base;
  return {
    isLoading: base.packs.isLoading,
    packs: base.packs.items,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchPacks: bindAction(fetchPacksAction, dispatch),
    resetPacks: bindAction(reset, dispatch),
  };
};

const stateConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof stateConnector>;

export default stateConnector(VivaIntegrationConfiguration);
