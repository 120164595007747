import { useCallback, useMemo, useState } from "react";
import { DropdownProps } from "semantic-ui-react";

import { useToggle } from "../../../../../../hooks/useToggle";
import Dropdown from "../../../../Common/Fields/Dropdown";
import { ValidationThrottler } from "../../../../Common/Fields/ValidationThrottler";
import { WithFormikPayload } from "../../../../Common/Fields/WithFormikPayload";
import { QuestionTypeOptions } from "../../../../Common/models";
import { QuestionHeaderPropsBase } from "../../../../Common/props";
import { MapQuestion } from "./Providers/QuestionMapper";
import { ISurveyQuestion } from "./types";
import { SurveyQuestionType } from "../../../types/models";
import { isAllowedBranchingQuestionType, nameofWithPrefix } from "../utils";

import { BranchingToggle } from "./Header/BranchingToggle";
import { RequiredToggle } from "./Header/RequiredToggle";
import { OtherOptionToggle } from "./Header/OtherOptionToggle";

export type SurveyQuestionHeaderProps = QuestionHeaderPropsBase<ISurveyQuestion<any>>;

const questionTypeOptions: Array<QuestionTypeOptions> = [
  { text: "Multiple Choice Text - Multi-Select", value: SurveyQuestionType.MultipleChoiceTextQuestion },
  { text: "Multiple Choice Text - Single-Select", value: SurveyQuestionType.SingleChoiceTextQuestion },
];

export const SurveyQuestionHeader = (props: SurveyQuestionHeaderProps) => {
  const {
    question,
    validatedFieldProps,
    index,
    onChanged,
    disabled,
    onQuestionTypeChanged,
    propertyNamePrefix,
    onBlur,
  } = props;

  const [showMapperError, toggleShowMapperError] = useToggle();
  const [previousQuestion, setPreviousQuestion] = useState<ISurveyQuestion<any> | undefined>(undefined);

  const nameof = useMemo(() => {
    return nameofWithPrefix<ISurveyQuestion<any>>(propertyNamePrefix);
  }, [propertyNamePrefix]);

  const onCheckboxChanged = useCallback(() => {
    if (showMapperError) {
      toggleShowMapperError();
      setPreviousQuestion(undefined);
    }
    onChanged();
  }, [showMapperError, onChanged, toggleShowMapperError, setPreviousQuestion]);

  const handleTypeDropdownChange = (_: any, data: DropdownProps) => {
    const value = data.value as SurveyQuestionType;
    const questionToMap = previousQuestion ?? question;
    const mapResult = MapQuestion(value, questionToMap);

    if (!mapResult.success) {
      toggleShowMapperError();
      setPreviousQuestion(questionToMap);
    }

    onQuestionTypeChanged(mapResult.question);
    onChanged();
  };

  const { setFieldValue } = validatedFieldProps;
  const { isBranching } = question;

  const setDropdownValue = useCallback(
    async (field: string, value: any, shouldValidate?: boolean) => {
      await (setFieldValue(field, value, shouldValidate) as unknown as Promise<void>);
      if (!isAllowedBranchingQuestionType(value as SurveyQuestionType) && isBranching) {
        await (setFieldValue(nameof("isBranching"), false, shouldValidate) as unknown as Promise<void>);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBranching, setFieldValue, nameof],
  );

  const togglePropertiesBase = {
    onBlur,
    propertyNamePrefix,
    validatedFieldProps,
    onChanged: onCheckboxChanged,
    question,
    disabled,
  };

  return (
    <>
      {showMapperError && (
        <div className="mapping-error" style={{ color: "red" }}>
          ERROR
        </div>
      )}
      <div className="question-type-block">
        <div className="dropdown-field">
          <WithFormikPayload
            {...validatedFieldProps}
            propertyName={nameof("questionType")}
            pathToValidate={`questions.${index}`}
            selfValidation={true}
            render={(formikProps) => (
              <ValidationThrottler {...formikProps} value={question.questionType}>
                <Dropdown
                  {...formikProps}
                  label="Question type"
                  value={question.questionType}
                  options={questionTypeOptions}
                  errorPosition={"bottom"}
                  disabled={disabled}
                  setFieldTouched={validatedFieldProps.setFieldTouched}
                  setFieldValue={setDropdownValue}
                  onChange={handleTypeDropdownChange}
                  disableValidation={true}
                />
              </ValidationThrottler>
            )}
          />
        </div>
        <RequiredToggle {...togglePropertiesBase} />
        <OtherOptionToggle {...togglePropertiesBase} />
        <BranchingToggle {...togglePropertiesBase} index={index} />
      </div>
    </>
  );
};
