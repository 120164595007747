import React from "react";
import NoResults from "../../../../components/noResults/NoResults";
import NoSearchResults from "../../../../components/noSearchResults/NoSearchResults";

export interface ContentUsersNoResultsProps {
  filtered: boolean;
}

const ContentUsersNoResults: React.FC<ContentUsersNoResultsProps> = ({ filtered }) => {
  return filtered ? (
    <NoSearchResults />
  ) : (
    <NoResults title="You don't have any added users yet" iconClassName="fal fa-users" />
  );
};

export default ContentUsersNoResults;
