import { BaseIntegrationConfigs } from "./types";

export enum CustomContent {
  None = 0,
  FlowsOnly = 1,
  AllContent = 2,
}

export enum PackContent {
  FlowsOnly = 1,
  AllContent = 2,
}

export interface ContentSettings {
  contentType: CustomContent;
}

export interface PackSettings {
  contentType: PackContent;
  packIds: number[];
}

export interface SftpSettings {
  host: string;
  username: string;
  password: string;
  fileLocation?: string | null;
}

export interface CompletionHistorySettings {
  learningAdministrationUrl: string;
  companyId: string;
  clientId: string;
  clientSecret: string;
  adminUserId: string;
}

export interface ColumnValues {
  domain?: string | null;
}

export interface SfIntegrationConfigs extends BaseIntegrationConfigs {
  tenantId: string;
  contentSettings: ContentSettings;
  packSettings: PackSettings;
  sftpSettings: SftpSettings;
  isHistoryEnabled: boolean;
  completionHistorySettings: Nullable<CompletionHistorySettings>;
  columnValues: Nullable<ColumnValues>;
}
