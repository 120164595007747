import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import environmentConfig from "configuration/environmentConfig";
import { queryFlow } from "../GoalForm";
import { serializeArrayAndFilterNullable } from "utils/queryUtils";
import { escapeTerm } from "utils/searchUtils";

export type FlowInformation = {
  "flowId": number,
  "vendorFlowId": number,
  "packId": number,
};

const BRAINSTORM_GLOBAL_ACCOUNT_ID = 15;

export const getFlowQuery = async ({ queryKey }: queryFlow): Promise<FlowInformation[]> => {

  try {
    let data;

    if (queryKey[2] === BRAINSTORM_GLOBAL_ACCOUNT_ID) {
      data = [{
        "flowId": environmentConfig.quickStartCourseId,
        "vendorFlowId": 0,
        "packId": 0
      }];
    } else {
      data = (await axios.get("/api/flows/purchased", {
        headers: {
          MoboId: null,
        },
        params: {
          vendorFlowIds: environmentConfig.quickStartCourseId,
        },
      })).data;
    }

    const usersAssignedToFlow = (await axios.get(`/api/v3/content/flow/${data[0].flowId}/users`, {
      headers: {
        MoboId: null,
      },
      params: {
        skip: 0,
        top: 5,
        sortBy: 'firstName',
        sortOrder: 'asc',
        term: escapeTerm(queryKey[1]),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    })).data;

    if (usersAssignedToFlow.length === 1) {
      return data;
    }
    return [];
  } catch {
    return [];
  }
};

export const useGetFlowQuery = ({ currentUserEmail, originAccountId }: { currentUserEmail: string; originAccountId: number; }) => {
  return useQuery({
    queryKey: ["getFlowQuery", currentUserEmail, originAccountId],
    queryFn: getFlowQuery,
  });
};