import React, { FC, useCallback } from "react";
import { Grid, InputOnChangeData } from "semantic-ui-react";

import { ValidatedForm } from "../../../../../components";
import { getFormattedPhoneNumber } from "../../../../../utils/stringUtils";
import { PackRequestFormFieldProps } from "./types";

export const ContactPhoneField: FC<PackRequestFormFieldProps> = ({
  values,
  onIsValidChange,
  handleChange,
  setFieldValue,
  ...props
}) => {
  const handleContactPhoneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const formattedPhoneNumber = getFormattedPhoneNumber(data.value);
      e.target.value = formattedPhoneNumber;
      handleChange(e);
    },
    [handleChange],
  );

  const setContactPhoneValue = useCallback(
    (field: string, value: string, shouldValidate?: boolean) => {
      const formattedPhoneNumber = getFormattedPhoneNumber(value);
      setFieldValue(field, formattedPhoneNumber, shouldValidate);
    },
    [setFieldValue],
  );

  return (
    <Grid.Row>
      <Grid.Column>
        <ValidatedForm.InputField
          label="Contact Phone"
          value={values.contactPhone}
          propertyName="contactPhone"
          markAsRequired
          errorPosition="top"
          onIsFormValidChange={onIsValidChange}
          placeholder="Enter Phone Number"
          {...props}
          handleChange={handleContactPhoneChange}
          setFieldValue={setContactPhoneValue}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
