import React, { memo } from "react";
import cn from "classnames";

import { FlowItemStartOfTheFlow } from "../types";
import StartOfTheFlowCardWrapper from "./StartOfTheFlowCardWrapper/StartOfTheFlowCardWrapper";

import "./StartOfTheFlow.scss";

interface IStartOfTheFlowNodeOwnProps {
  data: FlowItemStartOfTheFlow;
}

const StartOfTheFLow: React.FC<IStartOfTheFlowNodeOwnProps> = (): React.ReactElement => {
  return (
    <div className={cn("sof-placeholder")}>
      <StartOfTheFlowCardWrapper />
      <div className="divider" />
      <div className="sof-drop-here">
        <div className="sof-section-header">
          Section Header will appear
          <br /> when asset is added below
        </div>
        <div className="sof-devider"></div>
        <div className="sof-node">
          Drag and drop first asset
          <br /> in the Flow here
        </div>
      </div>
    </div>
  );
};

export default memo(StartOfTheFLow);
