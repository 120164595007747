import { FC } from "react";
import { pluralize } from "../../../utils/stringUtils";
import ModalSizes from "../../../enums/modalSizes";
import ActionConfirmationModal from "../../../components/modal/ActionConfirmationModal/ActionConfirmationModal";

export interface DeleteGroupsConfirmationModalProps {
  groupsCount?: number;
  open?: boolean;
  size?: ModalSizes;
  onContinue: () => void;
  onCancel: () => void;
}

const DeleteGroupsConfirmationModal: FC<DeleteGroupsConfirmationModalProps> = (
  props: DeleteGroupsConfirmationModalProps,
) => {
  const { open, groupsCount, size, onContinue, onCancel } = props;
  const hasSingleGroup = groupsCount === 1;

  return (
    <ActionConfirmationModal
      open={open}
      title={`Delete ${pluralize("Group", groupsCount!)}?`}
      confirmLabel={"Delete"}
      size={size}
      withWarningLabel={false}
      onContinue={onContinue}
      onCancel={onCancel}
    >
      This will permantently delete all reporting data and permissions. Assets associated with{" "}
      {hasSingleGroup ? "a group" : "groups"} may be removed from users unless the asset is given to a user outside of{" "}
      {hasSingleGroup ? "a deleted group" : "deleted groups"}. Do you wish to continue?
    </ActionConfirmationModal>
  );
};

DeleteGroupsConfirmationModal.defaultProps = {
  groupsCount: 1,
  open: false,
};

export default DeleteGroupsConfirmationModal;
