import React from "react";
import { progressTimestampPropTypes } from "./progressTimestampPropTypes";

import { ReactComponent as ProgressSavedIcon } from "./progress-saved-icon.svg";

function ProgressTimestamp({ timestampLabel, progressText }) {
  const renderConditionallyProgressLabel = (title) => {
    return title ? (
      <div className="progress-saved-text">
        <div className="title">{title}</div>
        {timestampLabel}
      </div>
    ) : (
      <>{timestampLabel}</>
    );
  };
  return (
    <>
      <ProgressSavedIcon />
      {renderConditionallyProgressLabel(progressText)}
    </>
  );
}

ProgressTimestamp.propTypes = progressTimestampPropTypes;

export default ProgressTimestamp;
