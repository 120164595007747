import { Component } from "react";
import { isEmpty } from "lodash";
import { connect, ConnectedProps } from "react-redux";

import EventsNoResults from "../../../views/library/events/Overview/EventsNoResults";
import GenericItemsView from "../../../views/ItemsView/GenericItemsView";
import eventListUtils from "../../../utils/eventListUtils";
import { PeopleEventCard } from "../cards";
import { AppDispatch, RootState } from "../../../features/Application/globaltypes/redux";
import { Filters } from "../../../utils/queryUtils";
import { IEventAssignmentModelItem } from "../../../features/People/types";
import { defaultSortingColumn, SortDirectionLong, sortOptionsByIdListView } from "../ContentAssignmentConstants";
import SearchInput from "../../searchInput/SearchInput";

import { getColumnOptions } from "./getColumnOptions";
import { SortingDirection, ItemsTypes, SortOptions, ViewType } from "../../../enums";
import { updateSelectedContentItems } from "../utils/contentAssignmentUtils";
import { ItemsViewGrid, ItemsViewList } from "../../../features/Application/globaltypes/ItemsView";
import { setTerm } from "../../../features/Library/Events/state/slices/eventSearchSlice";
import { EventFilterForm } from "../../filterForms/EventFilterForm/EventFilterForm";
import { CardsViewerItem } from "../../cardsViewer/types";
import { AssignmentListPropsBase, SelectedItem } from "../types";
import { bindAction } from "../../../interfaces";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import "./eventsAssignmentList.scss";
import { LDProps } from "../../../features/LDProps";
import { getFilterOptions } from "features/Library/Events/state/thunks/eventFiltersThunk";

const takeDefaultCount = Number.parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);

export interface EventsAssignmentListPropsBase
  // NOSONAR TODO: would be nice to have uniform props for all assignment lists
  extends Omit<AssignmentListPropsBase, "onSelectedChanged" | "selectedIds" | "resetList"> {
  selectedEventsIds: number[];
  onSelectedEventsChanged: (selectedItems: SelectedItem[]) => void;
  fetchEventsList: (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    searchTerm?: string,
  ) => void;
  fetchEventsGrid: (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
    searchTerm?: string,
  ) => void;
  resetEventsGrid: () => void;
  resetEventsList: () => void;
  gridState: ItemsViewGrid<IEventAssignmentModelItem>;
  listState: ItemsViewList<IEventAssignmentModelItem>;
  appliedFilter: Filters;
  getFilterOptions: () => void;
  applyFilter: (filter: Filters) => void;
  resetFilter: () => void;
  contextMenuButtonHandlers?: {
    onEditPriority?: (id: number) => void;
    onRemove: (id: number) => void;
  };
  hidePriorityColumn?: boolean;
  showExpirationDate?: boolean;
  deepLink?: boolean;
}

export interface EventsAssignmentListState {
  selectedViewType: ViewType;
  orderBy: SortOptions;
  bypassSortingForSearch: boolean;
}

export type EventsAssignmentListAllProps = EventsAssignmentListPropsBase & PropsFromRedux & LDProps;

export class EventsAssignmentList extends Component<EventsAssignmentListAllProps, EventsAssignmentListState> {
  constructor(props: EventsAssignmentListAllProps) {
    super(props);

    this.state = {
      selectedViewType: ViewType.GRID,
      orderBy: SortOptions.ModifiedDateDesc,
      bypassSortingForSearch: false,
    };
  }

  reloadListItems?: (enableSorting: boolean) => void;

  componentDidMount() {
    this.props.setSearch("");
  }

  componentWillUnmount() {
    this.props.resetEventsGrid();
    this.props.resetEventsList();
    this.props.setSearch("");
  }

  onSortChange = (_: any, data: { value?: SortOptions }) => {
    this.props.resetEventsGrid();
    this.setState(
      {
        orderBy: data.value ?? SortOptions.ModifiedDateDesc,
        bypassSortingForSearch: false,
      },
      this.getEvents,
    );
  };

  onViewTypeChange = (viewType: ViewType) => {
    const { onViewTypeChange } = this.props;
    this.setState(
      {
        selectedViewType: viewType,
        orderBy: SortOptions.ModifiedDateDesc,
      },
      () => {
        if (viewType === ViewType.GRID) {
          this.props.resetEventsGrid();
          this.getEvents(0);
        }
      },
    );
    onViewTypeChange?.(viewType);
  };

  /* istanbul ignore next */
  createReloadListItems = (reloadListItems: (enableSorting: boolean) => void) => {
    this.reloadListItems = reloadListItems;
  };

  onSearchChange = (newSearchText: string) => {
    let isEmptySearchText = isEmpty(newSearchText);

    this.onSelectedEventsGridChanged([]);
    this.props.setSearch(newSearchText);

    this.setState(
      {
        bypassSortingForSearch: !isEmptySearchText,
      },
      () => {
        this.reloadListItems?.(isEmpty(newSearchText));
        if (this.state.selectedViewType === ViewType.GRID) {
          this.props.resetEventsGrid();
          this.getEvents(0);
        }
      },
    );
  };

  checkSortParameter(sortParameter: any) {
    return this.state.bypassSortingForSearch ? undefined : sortParameter;
  }

  getEvents = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
  ) => {
    const { fetchEventsGrid, fetchEventsList, gridState, search, appliedFilter } = this.props;
    const { selectedViewType, orderBy } = this.state;

    if (selectedViewType === ViewType.GRID) {
      let [sortBy, sortOrderString] = orderBy.split(" ");
      fetchEventsGrid(
        skip ?? gridState.items.length,
        takeDefaultCount,
        this.checkSortParameter(sortBy),
        this.checkSortParameter(SortDirectionLong[sortOrderString]),
        filters ?? appliedFilter,
        search,
      );
    } else {
      fetchEventsList(
        skip,
        top,
        this.checkSortParameter(eventListUtils.formatOrderColumn(sortingColumnName ?? "modified")),
        this.checkSortParameter(sortingDirection),
        filters ?? appliedFilter,
        search,
      );
    }
  };

  // @ts-ignore
  getFilterForm = () => <EventFilterForm />;

  applyFilter = (filter: Filters) => {
    const { onSelectedEventsChanged, resetEventsGrid, applyFilter } = this.props;

    onSelectedEventsChanged([]);
    if (this.state.selectedViewType === ViewType.GRID) {
      resetEventsGrid();
      this.getEvents(0, undefined, undefined, undefined, filter);
    }
    applyFilter(filter);
  };

  resetFilter = () => {
    if (this.state.selectedViewType === ViewType.GRID) {
      this.props.resetEventsGrid();
      this.getEvents(0, undefined, undefined, undefined, {});
    }
    this.props.resetFilter();
  };

  onSelectedEventsGridChanged = (ids: number[]) => {
    this.updateSelectedItems(this.props.gridState.items, ids);
  };

  onSelectedEventsListChanged = (ids: number[]) => {
    this.updateSelectedItems(this.props.listState.items, ids);
  };

  updateSelectedItems = (collection: IEventAssignmentModelItem[], ids: number[]) => {
    const { onSelectedEventsChanged } = this.props;

    const updatedSelectedEvents = updateSelectedContentItems(collection, ids);
    onSelectedEventsChanged?.(updatedSelectedEvents);
  };

  render() {
    const {
      getFilterOptions,
      contextMenuButtonHandlers,
      filterOptions,
      gridState,
      listState,
      selectedEventsIds,
      customHeaderContent,
      isReadOnly,
      createButton,
      appliedFilter,
      peopleType,
      disablePopupMenu,
      accessRestricted,
      deepLink,
    } = this.props;

    const viewState = this.state.selectedViewType === ViewType.GRID ? gridState : listState;

    return (
      <GenericItemsView
        viewType={this.state.selectedViewType}
        columnOptions={getColumnOptions(
          peopleType,
          contextMenuButtonHandlers,
          selectedEventsIds,
          true,
          false,
          true,
          false,
        )}
        noResultsContent={<EventsNoResults filtered={false} createEventButton={createButton} />}
        fetchData={this.getEvents}
        setReloadListItems={this.createReloadListItems}
        blur
        onSortChange={this.onSortChange}
        sortOptions={sortOptionsByIdListView}
        orderBy={this.state.orderBy}
        isLoading={viewState.isLoading}
        items={viewState.items}
        itemsType={ItemsTypes.Event}
        dataCount={viewState.itemsCount}
        getFilterForm={this.getFilterForm}
        resetFilter={this.resetFilter}
        applyFilter={this.applyFilter}
        appliedFilter={appliedFilter}
        filterOptionsLoading={false}
        filterOptions={filterOptions}
        getFilterOptions={getFilterOptions}
        onSelectedItemChanged={this.onSelectedEventsListChanged}
        onSelectedGridItemsChanged={this.onSelectedEventsGridChanged}
        renderCard={(props: CardsViewerItem<IEventAssignmentModelItem>) => {
          return (
            <PeopleEventCard
              {...props}
              peopleType={this.props.peopleType}
              showExpirationDate={false}
              disablePopupMenu={disablePopupMenu}
              showAdded={false}
              disabled={isReadOnly || props.item.inherited}
              selectable={false}
              deepLink={deepLink}
            />
          );
        }}
        selectedIds={selectedEventsIds}
        isAllDataLoaded={gridState.isAllLoaded}
        onViewTypeChange={this.onViewTypeChange}
        sortingDirection={SortingDirection.Descending}
        defaultSortingColumnName={defaultSortingColumn}
        onLoad={this.getEvents}
        tabAlias="events"
        customHeaderContent={customHeaderContent}
        isSelectDisabled={() => true}
        renderSearch={() => (
          <SearchInput
            placeholder="Search for Events..."
            onChange={this.onSearchChange}
            defaultValue={this.props.search}
          />
        )}
        accessRestricted={accessRestricted}
      />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    gridState: state.people.contentAssignment.eventGrid,
    listState: state.people.contentAssignment.eventList,
    filterOptions: state.library.events.filters.filterOptions,
    search: state.library.events.search.term,
    filtersLoading: state.library.events.filters.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setSearch: bindAction(setTerm, dispatch),
    getFilterOptions: bindAction(getFilterOptions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withLDConsumer()(EventsAssignmentList));
