import { SurveyGroup } from "../../types/models";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { createSelectedItemsSlice, SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface SurveyGroupsState extends SelectedItemsState<SurveyGroup> {}

const initialState: SurveyGroupsState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const surveyGroupsSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Surveys, name: "groups" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  surveyGroupsSlice.actions;

export default surveyGroupsSlice.reducer;
export type surveyGroupsSliceStateType = ReturnType<typeof surveyGroupsSlice.reducer>;
