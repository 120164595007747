import { IdentityProvider } from "../../types";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../Application/slices/createFetchingItemsSlice";
import { fetchIdentityProviders, fetchIdentityProvidersPrefixPayload } from "./identityProvidersOverviewThunk";

const identityProvidersInitialState: FetchingItemsState<IdentityProvider> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
};

const identityProvidersOverviewSlice = createFetchingItemsSlice({
  namePayload: fetchIdentityProvidersPrefixPayload,
  initialState: identityProvidersInitialState,
  reducers: {},
  thunk: fetchIdentityProviders,
});

export default identityProvidersOverviewSlice.reducer;

export type IdentityProvidersOverviewState = ReturnType<typeof identityProvidersOverviewSlice.reducer>;
