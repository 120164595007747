import React, { useMemo } from "react";
import { Icon } from "semantic-ui-react";
import Tooltip from "../../../../../../components/common/tooltip/Tooltip";
import DeleteLinkButton from "../../../../../../components/buttons/linkButtons/deleteLinkButton/DeleteLinkButton";

export interface Props {
  isDisabled: boolean | undefined;
  onClick: () => void;
  multiple?: boolean;
  showTooltip: boolean;
}
const RemoveClosedCaptionLinkButton: React.FC<Props> = (props) => {
  const { isDisabled, multiple, showTooltip, onClick } = props;
  const message = multiple
    ? "The default CC file was selected and it cannot be deleted"
    : "The default CC file cannot be deleted";
  const position = multiple ? "left center" : "top center";
  const targetContent = useMemo(() => {
    return multiple ? (
      <DeleteLinkButton
        after={showTooltip && <Icon size="large" className="fa fa-exclamation-circle" />}
        isDisabled={isDisabled}
        onClick={onClick}
      />
    ) : (
      <Icon
        data-testid="remove-icon"
        link
        color="blue"
        size="large"
        className="fa-trash-alt"
        disabled={isDisabled}
        onClick={onClick}
      />
    );
  }, [isDisabled, showTooltip, multiple, onClick]);

  return (
    <Tooltip
      inverted
      hideOnScroll
      position={position}
      showAlways={showTooltip}
      target={targetContent}
      content={message}
    />
  );
};

export default RemoveClosedCaptionLinkButton;
