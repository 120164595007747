import { IAnswerBase } from "../../../../Common/models";
import { Answer, AssessmentAnswerType, AssessmentQuestionType } from "../../../types/state";

const defaultAnswerData = ((): { [key in AssessmentAnswerType]: () => IAnswerBase } => {
  const singleTextAnswer = (): IAnswerBase<Answer> => ({
    answer: { text: "", isCorrect: false },
    answerType: AssessmentAnswerType.SingleText,
    answerOrder: undefined,
  });

  const multipleTextAnswer = (): IAnswerBase<Answer> => ({
    answer: { text: "", isCorrect: false },
    answerType: AssessmentAnswerType.MultipleText,
    answerOrder: undefined,
  });

  return {
    [AssessmentAnswerType.SingleText]: singleTextAnswer,
    [AssessmentAnswerType.MultipleText]: multipleTextAnswer,
    /* istanbul ignore next */
    [AssessmentAnswerType.Picture]: () => {
      throw new Error("Not implemented exception");
    },
  };
})();

export const getAnswerDefaultData = (questionType: number): IAnswerBase => {
  const questionTypeToAnswerType = {
    [AssessmentQuestionType.SingleChoiceTextQuestion]: AssessmentAnswerType.SingleText,
    [AssessmentQuestionType.MultipleChoiceTextQuestion]: AssessmentAnswerType.MultipleText,
    [AssessmentQuestionType.PictureQuestion]: AssessmentAnswerType.Picture,
  };
  return defaultAnswerData[questionTypeToAnswerType[questionType as AssessmentQuestionType]]();
};
