import { FC, Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { PackAccountsContextItem, PackMap } from "../types";
import CardThumbnail from "components/cards/cardThumbnail/CardThumbnail";
import LicensePicker from "components/LicensePicker/LicensePicker";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { fetchPackLicenseTypes } from "features/Licensing/Packs/state/thunks/packLicenseTypesThunk";
import { connect, ConnectedProps } from "react-redux";
import { Loader } from "semantic-ui-react";
import { PackLicenseType } from "features/Licensing/Packs/types/state";
import { LicenseTypes } from "enums";
import cn from "classnames";
import moment from "moment";
import styles from "./packAccountItems.module.scss";

interface PackAccountItemsPropsOwn {
  packMap: PackMap;
  setIsDisabled: (value: boolean) => void;
  isEdit?: boolean;
  infoText: string;
}

interface PackItemProps {
  item: PackAccountsContextItem;
  options: PackLicenseType[];
  onExpirationDateChange: (selectedPackId: number, optionId?: number, date?: Date | null) => void;
  isEdit?: boolean;
  idx: number;
}

export type PackAccountItemsProps = PackAccountItemsPropsOwn & PropsFromRedux;

const PackItem = memo((props: PackItemProps) => {
  const { item, options, onExpirationDateChange, isEdit, idx } = props;

  const licenseOptions = useMemo(
    () =>
      options
        .filter((option) => item.isTrialAllowed || option.id !== LicenseTypes.Trial)
        .map((option) => ({
          title: option.id === LicenseTypes.Trial ? `${item.trialPeriod?.name} ${option.name}` : option.name,
          id: option.id,
          duration: option.monthsCount,
        }))
        .reverse(),
    [item, options],
  );

  return (
    <Fragment key={item.id}>
      <div className={styles.item} key={item.id}>
        <div className={styles.thumbnail}>
          <CardThumbnail thumbnailUrl={item.thumbnailImageUrl} />
        </div>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.licensing}>
          <span className={styles.licenses}>
            <span className="required-asterisk">Licenses</span>
            <b>Auto-increment</b>
          </span>
          {item.isTrial ? (
            <span className={cn(styles.licenses, styles.expiration)}>
              <span className="required-asterisk">Expiration</span>
              <b className={styles.trial}>Trial</b>
            </span>
          ) : (
            <span className={styles.expiration}>
              <LicensePicker
                options={licenseOptions}
                onOptionChange={(optionId?: number) => onExpirationDateChange(item.id, optionId)}
                onExpirationDateChange={(date?: null | Date) => onExpirationDateChange(item.id, undefined, date)}
                preselectedOptionId={isEdit ? item.licenseTypeId : undefined}
                preselectedExpirationDate={isEdit ? item.expirationDate : undefined}
                verticalPosition={idx > 0 ? "top" : "bottom"}
                highlightExpired={isEdit}
              />
            </span>
          )}
        </div>
      </div>
    </Fragment>
  );
});

export const PackAccountItems: FC<PackAccountItemsProps> = (props) => {
  const { packMap, setIsDisabled, licenseTypes, isLoading, loadLicenseTypes, isEdit, infoText } = props;
  const [items, setSelectedItems] = useState<PackAccountsContextItem[]>([]);
  useEffect(() => {
    loadLicenseTypes();
    setSelectedItems([...packMap.values()]);
    // eslint-disable-next-line
  }, []);

  const validateFinishBtnEnabling = useCallback(() => {
    const hasInvalidItems = items.some(
      (p) => !p.expirationDate && (!p.licenseTypeId || p.licenseTypeId === LicenseTypes.CustomDate),
    );

    const nonTrialItems = items.filter((p) => p.licenseTypeId !== LicenseTypes.Trial);
    const allNonTrialItemsUnchanged = nonTrialItems.length > 0 && nonTrialItems.every((p) => !p.isChanged);

    const hasOnlyTrialItemsOnEdit = !!isEdit && items.every((p) => p.isTrial);

    const isNotValid = !items.length || hasInvalidItems || allNonTrialItemsUnchanged || hasOnlyTrialItemsOnEdit;

    setIsDisabled(isNotValid);
  }, [items, isEdit, setIsDisabled]);

  useEffect(() => {
    validateFinishBtnEnabling();
  }, [validateFinishBtnEnabling]);

  const onExpirationDateChange = (selectedPackId: number, optionId?: number, date?: Date | null) => {
    const packs = [...items];

    const packIndex = packs.findIndex((el) => el.id === selectedPackId);

    if (packIndex !== -1) {
      const updatedPack = {
        ...packs[packIndex],
        licenseTypeId: optionId ?? packs[packIndex].licenseTypeId,
        expirationDate: date ?? undefined,
        isChanged:
          date !== undefined &&
          (!moment(date).isSame(packs[packIndex].expirationDate, "day") || packs[packIndex].isChanged),
      };

      packs[packIndex] = updatedPack;

      setSelectedItems(packs);
      packMap.set(updatedPack.id, updatedPack);
    }
  };

  const renderInfoSection = () => {
    const hasNoItems = !items.length;

    return <div className={styles.info}>{hasNoItems ? "The Customer doesn't have any available packs" : infoText}</div>;
  };

  return (
    <form className={styles.root} autoComplete="off">
      {!isLoading && renderInfoSection()}
      {!isLoading &&
        items.map((item, idx) => (
          <PackItem
            key={idx}
            item={item}
            options={licenseTypes}
            onExpirationDateChange={onExpirationDateChange}
            isEdit={isEdit}
            idx={idx}
          />
        ))}
      <Loader active={isLoading} />
    </form>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    licenseTypes: state.packs.packLicenseTypes.items,
    isLoading: state.packs.packLicenseTypes.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadLicenseTypes: bindActionCreators(fetchPackLicenseTypes, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PackAccountItems);
