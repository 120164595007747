import React, { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { ElementAndPosition, EntityType, FlowEndInfo, FlowSectionHeader } from "../../types";
import { FlowItemPlaceholder, IFlowEdge, IFlowNode } from "../nodes/types";
import { getConnectedEdges, useReactFlow, useStore } from "reactflow";
import { useSaving } from "../hooks/useSaving";
import { FlowValidator } from "../../validator/FlowValidator";
import { ReactFlowEmitter } from "../emitter/ReactFlowEmitter";
import { PLACEHOLDER_ELEMENT_ID, PLACEHOLDER_NODES_NUMBER, START_OF_THE_FLOW_ELEMENT_ID } from "../../constants";
import { FlowValidationError, ValidatorErrorTypes } from "../../validator/flowValidatorReducer";
import { useReactFlowActions } from "./ReactFlowStateProvider";
import { differenceBy } from "lodash";

const defaultBounds = { x: 0, y: 0, left: 0, right: 0, top: 0, bottom: 0, width: 0, height: 0 };

const defaultAction = (funcName: string) => () => {
  throw new Error(`'${funcName}()' is not defined`);
};

interface ReactFlowCanvasContextProps {
  isReadOnly: boolean;
  flowId: number;
  headId: string | undefined;
  reactFlowWrapper: React.RefObject<HTMLDivElement> | null;
  reactFlowBounds: Omit<DOMRect, "toJSON">;
  showTriggers: boolean;
  itemToReplace: ElementAndPosition | undefined;
  itemForSectionHeader: ElementAndPosition | undefined;
  sectionHeaderView: string | undefined;
  flowEndView: string | undefined;
  draggedItemFromInspector: EntityType | undefined;
}

type ReactFlowCanvasContextEventsType = ReactFlowEmitter | undefined;

interface ReactFlowCanvasContextActionsProps {
  setHeadId: Dispatch<SetStateAction<string | undefined>>;
  save: () => void;
  removeNode: (id: string) => void;
  setShowTriggers: (flag: boolean) => void;
  setSectionHeaderView: (id: string | undefined) => void;
  setFlowEndView: (id: string | undefined) => void;
  setItemToReplace: (element?: ElementAndPosition) => void;
  setItemForSectionHeader: (element?: ElementAndPosition) => void;
  setElementInvalid: (id: string, error?: FlowValidationError) => void;
  setSectionHeader: (id: string, sectionHeader: FlowSectionHeader) => void;
  removeSectionHeader: (id: string) => void;
  setFlowEnd: (id: string, flowEndInfo: FlowEndInfo) => void;
  setDraggedItemFromInspector: (type: EntityType) => void;
}

export const ReactFlowCanvasContext = React.createContext<ReactFlowCanvasContextProps>({
  flowId: 0,
  isReadOnly: false,
  headId: undefined,
  reactFlowBounds: defaultBounds,
  reactFlowWrapper: null,
  showTriggers: false,
  sectionHeaderView: undefined,
  flowEndView: undefined,
  itemToReplace: undefined,
  itemForSectionHeader: undefined,
  draggedItemFromInspector: undefined,
});

const ReactFlowCanvasContextActions = React.createContext<ReactFlowCanvasContextActionsProps>({
  setHeadId: defaultAction("setHeadId"),
  removeNode: defaultAction("removeNode"),
  setItemToReplace: defaultAction("setItemToReplace"),
  setItemForSectionHeader: defaultAction("setItemForSectionHeader"),
  save: defaultAction("save"),
  setShowTriggers: defaultAction("setShowTriggers"),
  setSectionHeaderView: defaultAction("setSectionHeaderView"),
  setFlowEndView: defaultAction("setFlowEndView"),
  setElementInvalid: defaultAction("setElementInvalid"),
  setSectionHeader: defaultAction("setSectionHeader"),
  removeSectionHeader: defaultAction("removeSectionHeader"),
  setFlowEnd: defaultAction("setFlowEnd"),
  setDraggedItemFromInspector: defaultAction("setDraggedItemFromInspector"),
});

export const ReactFlowCanvasContextEvents = React.createContext<ReactFlowCanvasContextEventsType>(undefined);

interface IReactFlowCanvasProviderProps {
  isReadOnly: boolean;
  flowId: number;
  flowDataHeadId: string | undefined;
  children: React.ReactNode;
  flowValidator: FlowValidator;
  onSavingProcessBegin: () => void;
  onSavingProcessSuccess: (id?: string) => void;
  flowDataRef: MutableRefObject<{ nodes: IFlowNode[]; edges: IFlowEdge[]; headId?: string } | undefined>;
  reactFlowWrapper: React.RefObject<HTMLDivElement>;
}

export const ReactFlowCanvasProvider: React.FC<IReactFlowCanvasProviderProps> = ({
  flowId,
  flowDataHeadId,
  children,
  flowValidator,
  onSavingProcessBegin,
  onSavingProcessSuccess,
  flowDataRef,
  isReadOnly,
  reactFlowWrapper,
}) => {
  // keep same instance of EventEmitter via useState workaround
  const [events] = useState(() => new ReactFlowEmitter());
  const [headId, setHeadId] = useState(flowDataHeadId);
  const [showTriggers, setShowTriggers] = useState(false);
  const [sectionHeaderView, setSectionHeaderView] = useState<string | undefined>(undefined);
  const [flowEndView, setFlowEndView] = useState<string | undefined>(undefined);
  const [itemToReplace, setItemToReplace] = useState<ElementAndPosition>();
  const [itemForSectionHeader, setItemForSectionHeader] = useState<ElementAndPosition>();
  const [draggedItemFromInspector, setDraggedItemFromInspector] = useState<EntityType>();
  const resetSelectedElements = useStore((store) => store.resetSelectedElements);
  const { deleteElements, getNode, getEdges } = useReactFlow();
  const { setNodes } = useReactFlowActions();

  const reactWrapper = reactFlowWrapper.current;
  const reactFlowBounds: Omit<DOMRect, "toJSON"> = useMemo(() => {
    return reactWrapper?.getBoundingClientRect() || defaultBounds;
  }, [reactWrapper]);

  useEffect(() => {
    setHeadId(flowDataHeadId);
  }, [flowDataHeadId]);

  const save = useSaving({
    id: flowId,
    headId,
    flowValidator,
    onSavingProcessBegin,
    onSavingProcessSuccess,
    flowDataRef,
  });

  const updateNodes = useCallback(
    (id: string, nodeToRemove: IFlowNode) => {
      const edges = getEdges() || [];
      const edgesToRemove = getConnectedEdges([nodeToRemove], edges);

      setNodes((nds) => {
        const result = nds.filter((node) => node.id !== id);
        return result.map((node) => {
          if (edgesToRemove.some((edge) => edge.id === node.id)) {
            return { ...node, data: { ...node.data, connected: { target: false, source: false } } };
          }
          if (result.length <= PLACEHOLDER_NODES_NUMBER && node.id === PLACEHOLDER_ELEMENT_ID) {
            node.hidden = false;
            (node.data as FlowItemPlaceholder).isActive = false;
          }
          if (edgesToRemove.some((edge) => node.id === edge.data?.outId && edge.data?.isAction)) {
            const hasActionEdge = differenceBy(edges, edgesToRemove, "id").some(
              (edge) => edge.data.outId === node.id && edge.data?.isAction,
            );
            return { ...node, data: { ...node.data, canConnect: !hasActionEdge } };
          }
          return node;
        });
      });
    },
    [getEdges, setNodes],
  );

  const removeNode = useCallback(
    (id: string) => {
      const nodeToRemove = getNode(id);
      if (nodeToRemove) {
        if (nodeToRemove.id === headId) {
          setHeadId(undefined);
        }

        deleteElements({ nodes: [{ id: id }] });
        updateNodes(id, nodeToRemove);
        resetSelectedElements();
        setShowTriggers(false);
        setFlowEndView(undefined);
        save();
      }
    },
    [headId, resetSelectedElements, save, getNode, updateNodes, deleteElements],
  );

  const getErrorType = (error?: FlowValidationError) => {
    switch (error?.type) {
      case ValidatorErrorTypes.SectionHeaderNameError:
      case ValidatorErrorTypes.SectionHeaderDescriptionError:
        return "section-header";
      case ValidatorErrorTypes.StartError:
        return "start-error";
      default:
        return "item";
    }
  };

  const setElementInvalid = useCallback(
    (id: string, error?: FlowValidationError) => {
      setNodes((nodes): IFlowNode[] => {
        const searchValue = /invalid|error-type-item|error-type-section-header|error-type-start-error/gi;
        return nodes.map((node) => {
          let errorType = getErrorType(error);
          let className = node.className ? node.className.replaceAll(searchValue, "").trim() : "";

          const classNameInvalid = node.id === id ? `error-type-${errorType} invalid` : "";

          return { ...node, className: className ? `${className} ${classNameInvalid}` : classNameInvalid };
        });
      });
    },
    [setNodes],
  );

  const setSectionHeader = useCallback(
    (id: string, sectionHeader: FlowSectionHeader) => {
      setNodes((nodes: IFlowNode[]): IFlowNode[] =>
        nodes.map((node) => {
          if (node.id === id && node.id !== START_OF_THE_FLOW_ELEMENT_ID) {
            const _node = node;
            return { ..._node, data: { ..._node.data, sectionHeader } };
          } else {
            return { ...node };
          }
        }),
      );
      save();
    },
    [save, setNodes],
  );

  const setFlowEnd = useCallback(
    (id: string, flowEndInfo: FlowEndInfo) => {
      setNodes((nodes: IFlowNode[]): IFlowNode[] =>
        nodes.map((node) => {
          if (node.id === id && node.id !== START_OF_THE_FLOW_ELEMENT_ID) {
            const _node = node;
            return { ..._node, data: { ..._node.data, flowEndInfo } };
          } else {
            return { ...node };
          }
        }),
      );
      save();
    },
    [save, setNodes],
  );

  const removeSectionHeader = useCallback(
    (id: string) => {
      setNodes((nodes: IFlowNode[]): IFlowNode[] =>
        nodes.map((node) => {
          if (node.id === id) {
            const { sectionHeader, ...rest } = node.data;
            return { ...node, data: rest };
          } else {
            return node;
          }
        }),
      );
      save();
    },
    [save, setNodes],
  );

  const canvasContextValue = useMemo(
    () => ({
      flowId,
      headId,
      events,
      isReadOnly,
      flowEndView,
      showTriggers,
      itemToReplace,
      reactFlowBounds,
      reactFlowWrapper,
      sectionHeaderView,
      itemForSectionHeader,
      draggedItemFromInspector,
    }),
    [
      flowId,
      headId,
      events,
      isReadOnly,
      flowEndView,
      showTriggers,
      itemToReplace,
      reactFlowBounds,
      reactFlowWrapper,
      sectionHeaderView,
      itemForSectionHeader,
      draggedItemFromInspector,
    ],
  );
  const canvasContextValueActions = useMemo(
    () => ({
      save,
      removeNode,
      setFlowEnd,
      setSectionHeader,
      setElementInvalid,
      removeSectionHeader,
      setHeadId,
      setFlowEndView,
      setShowTriggers,
      setItemToReplace,
      setSectionHeaderView,
      setItemForSectionHeader,
      setDraggedItemFromInspector,
    }),
    [
      save,
      removeNode,
      setFlowEnd,
      setSectionHeader,
      setElementInvalid,
      removeSectionHeader,
      setHeadId,
      setFlowEndView,
      setShowTriggers,
      setItemToReplace,
      setSectionHeaderView,
      setItemForSectionHeader,
      setDraggedItemFromInspector,
    ],
  );
  const canvasContextValueEvents = useMemo(() => events, [events]);
  return (
    <ReactFlowCanvasContextActions.Provider value={canvasContextValueActions}>
      <ReactFlowCanvasContextEvents.Provider value={canvasContextValueEvents}>
        <ReactFlowCanvasContext.Provider value={canvasContextValue}>{children}</ReactFlowCanvasContext.Provider>
      </ReactFlowCanvasContextEvents.Provider>
    </ReactFlowCanvasContextActions.Provider>
  );
};

export function useReactFlowCanvasEventEmitter() {
  return React.useContext(ReactFlowCanvasContextEvents);
}

export function useReactFlowCanvasState() {
  return React.useContext(ReactFlowCanvasContext);
}

export function useReactFlowCanvasActions() {
  return React.useContext(ReactFlowCanvasContextActions);
}
