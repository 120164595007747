import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { withFormik } from "../../../../../../utils/formikUtils";
import { FormikProps } from "formik";
import validationSchemas from "../../../../../../utils/validationSchemas";
import * as emailEntityStateActions from "../../state/actions/emailEntityStateActions";
import * as emailDetailsActions from "../../state/actions/emailDetailsActions";

import { RootState } from "../../../../../Application/globaltypes/redux";
import { ContentProps } from "../types";
import { Template } from "../../types/state";
import { TemplateForm } from "../../../../../../poc/TemplateForm/TemplateForm";
import { Dimmer, Loader } from "semantic-ui-react";
import schemasUtils from "../../../../../../utils/validationSchemasUtils";
import { SendEmailFormContainer } from "../../Edit/sendTestEmail/sendEmailFormContainer";
import { AutosaveProps, withAutosave } from "../../../../../../utils/withAutosave";
import Restricted from "../../../../../Application/Restricted";
import RolePermissions from "../../../../../../enums/rolePermissions";
import { StripoApi } from "../../../../../../poc/TemplateForm/stripo/stripoApi";
import { templateUtils } from "../../../../../../components/notifyStep/utils/templateUtils";

import "./content.scss";

export type ContentPropsAll = ContentProps & PropsFromRedux & FormikProps<Template> & AutosaveProps;

export class Content extends Component<ContentPropsAll> {
  state = {
    stripoInitialized: false,
    forceReinitialize: false,
  };

  componentDidMount() {
    this.props.acceptHandlers?.({
      onPrevious: this.onPrevious,
      onFinish: this.publishEmail,
    });

    //Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(this.props.isValid);
  }

  componentWillUnmount() {
    this.props.onIsDirtyChanged?.(false);
    StripoApi.stop();
  }

  componentDidUpdate(prevProps: ContentPropsAll, prevState: { stripoInitialized: boolean }) {
    if (prevProps.dirty !== this.props.dirty) {
      this.props.onIsDirtyChanged?.(this.props.dirty);
    }

    if (prevState.stripoInitialized !== this.state.stripoInitialized) {
      this.props.save();
    }

    if (this.props.isParentReverting) {
      this.setForceReinitialize(true);
      this.props.detailsActions.getEmailTemplate(this.props.id);
      this.props.onParentReverting?.(false);
    }
  }

  onPrevious = () => {
    this.saveTemplate();
  };

  publishEmail = () => {
    this.props.entityStateActions.publishDraftEmailEntity(this.props.id);
  };

  saveTemplate = () => {
    this.props.detailsActions.saveTemplate(this.props.values);
  };

  setStripoInitialized = (initialized: boolean) => {
    this.setState({
      stripoInitialized: initialized,
    });
  };

  setForceReinitialize = (reinitialize: boolean) => {
    this.setState({ forceReinitialize: reinitialize });
  };

  static getTemplate = (values: Template): Template => {
    return {
      id: values.id,
      subject: values.subject.trim(),
      previewText: values.previewText.trim(),
      template: values.template.trim(),
      callToAction: values.callToAction?.trim(),
      destinationUrl: values.destinationUrl?.trim(),
      compiledTemplate: values.compiledTemplate?.trim(),
    };
  };

  static getTemplateWithCallback = async (
    values: Template,
    props: ContentProps & PropsFromRedux,
    callback: (state: Template) => void,
  ) => {
    const { html, css } = await props.stripoApi.getTemplate();
    const templateBody = templateUtils.extractTemplateFromLayout(html.trim());

    if (templateBody === values.template) {
      callback(values);
    } else {
      const compiledTemplate = await props.stripoApi.compileEmail({
        css: css,
        html: html,
        minimize: true,
      });
      const compiledTemplateBody = templateUtils.extractTemplateFromLayout(compiledTemplate);
      callback({ ...values, template: templateBody, compiledTemplate: compiledTemplateBody });
    }
  };

  submitSendTestEmail = (email: string) => {
    const sendEmail = async () => {
      this.props.entityStateActions.sendEmailEntity(this.props.id, email);
    };

    sendEmail();
  };

  handleFormChange = () => {
    this.props.save();
  };

  render() {
    return (
      <>
        <Dimmer active={this.props.isCreating} inverted>
          <Loader />
        </Dimmer>
        <div className="email-content-editor-container">
          <TemplateForm
            {...this.props}
            initialized={this.state.stripoInitialized}
            setInitialized={this.setStripoInitialized}
            onDataChanged={this.handleFormChange}
            accountId={this.props.accountId}
            forceReinitialize={this.state.forceReinitialize}
            setForceReinitialize={this.setForceReinitialize}
            templateBody={this.props.templateBody}
          />
        </div>
        {!!this.props.id && (
          <div className="email-content-send-form">
            <Restricted
              permissions={[RolePermissions.CommunicationsCreate, RolePermissions.CommunicationsManage]}
              renderContent={(hasPermission) => (
                <SendEmailFormContainer
                  hasPermission={hasPermission}
                  email={this.props.name}
                  onSubmit={this.submitSendTestEmail}
                  disabled={!this.state.stripoInitialized}
                />
              )}
            />
          </div>
        )}
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ContentProps) => {
  const detailsReducer = state.library.emails.emailDetailsReducer;
  const email = detailsReducer.email;
  const id = ownProps.entityId || state.library.emails.emailEntityStateReducer.entityId;
  return {
    id,
    templateSubject: email.subject,
    templatePreview: email.previewText,
    templateBody: detailsReducer.template,
    compiledTemplate: detailsReducer.compiledTemplate,
    callToAction: detailsReducer.callToAction,
    destinationUrl: detailsReducer.destinationUrl,
    isLoaded: detailsReducer.isTemplateLoaded,
    isLoading: detailsReducer.isLoading,
    isCreating: state.library.emails.emailEntityStateReducer.changingEntityState,
    name: state.userProfile.name,
    isDraft: email.isDraft,
    accountId: state.userProfile.accountId,
  };
};

/* istanbul ignore next */
const mapPropsToTemplate = (props: ContentProps & PropsFromRedux): Template => {
  return {
    id: props.id,
    subject: props.templateSubject,
    previewText: props.templatePreview,
    template: props.templateBody,
    callToAction: props.callToAction,
    destinationUrl: props.destinationUrl,
    compiledTemplate: props.compiledTemplate,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(emailEntityStateActions, dispatch),
  detailsActions: bindActionCreators(emailDetailsActions, dispatch),
  stripoApi: StripoApi,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ContentPropsAll, Template>({
  getInitValues: mapPropsToTemplate,
  stateProvider: Content.getTemplate,
  stateProviderWithCallback: Content.getTemplateWithCallback,
  entityUpdater: (props) => props.entityStateActions.updateEmailTemplate,
  isPeriodicUpdateEnabled: false,
})(Content);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.emailTemplate,
  enableReinitialize: true,
  isInitialValid: (props: ContentProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.emailTemplate, mapPropsToTemplate(props)),
  mapPropsToValues: (props: ContentProps & PropsFromRedux) => mapPropsToTemplate(props),
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
