import UserInfoCell from "../../../../../../../components/userInfoCell/UserInfoCell";

import { ColumnOption } from "../../../../../../../interfaces/columnOptions";

import { VideoUser } from "../../../../types";

export enum Columns {
  Name = "Name",
}

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "firstName",
};

/* istanbul ignore next */
export const getColumnOptions = (): ColumnOption<VideoUser>[] => {
  return [
    {
      name: Columns.Name,
      width: 5,
      isSortable: true,
      disabled: (item) => item.isInherited,
      render: (item) => <UserInfoCell deepLink={!item.isInherited} user={item} />,
    },
  ];
};
