import * as actionTypes from "../actionTypes/FeedbackPageDetailsActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import { FeedbackPage, FeedbackPageView } from "../../types/state";
import { Dispatcher } from "../../../../../../interfaces/redux";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { setFeedbackPageEntityIdAction, updateFeedbackPageCommandSuccess } from "./FeedbackPageEntityStateActions";

export const saveFeedbackPage = (feedbackPageMetadata: FeedbackPageView) => {
  const saveFeedbackPageAction = getActionProvider<FeedbackPageView>(actionTypes.saveFeedbackPage);

  return (dispatch: Dispatcher) => {
    dispatch(saveFeedbackPageAction(feedbackPageMetadata));
  };
};

export const clearFeedbackPage = () => {
  const resetFeedbackPageAction = getActionBaseProvider(actionTypes.clearFeedbackPage);

  return (dispatch: Dispatcher) => {
    dispatch(resetFeedbackPageAction());
  };
};

export const getFeedbackPage = (id: number) => {
  const begin = getActionBaseProvider(actionTypes.getFeedbackPageBegin);
  const success = getActionProvider<FeedbackPage>(actionTypes.getFeedbackPageSuccess);
  const failure = getActionProvider<Error>(actionTypes.getFeedbackPageFailure);

  return async (dispatch: Function) => {
    dispatch(begin());
    try {
      const result = await dataService.threatDefence.getFeedbackPageAsync(id);
      const feedbackPage: FeedbackPage = {
        ...result.data,
        uploadedVideos: [{ name: result.data.videoName, size: result.data.videoSize, type: "" }],
      };
      dispatch(success(feedbackPage));
      dispatch(setFeedbackPageEntityIdAction(result.data.id || 0));
      dispatch(updateFeedbackPageCommandSuccess({ id: result.data.id || 0 }));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const setFeedbackPageAction = getActionProvider<FeedbackPage>(actionTypes.setFeedbackPage);
