import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { useReactFlow } from "reactflow";
import cn from "classnames";

import { ExternalTriggerGroup, ExternalTriggerType } from "../../../../../types/externallTriggersTypes";
import { ExternalTriggerItem } from "./ExternalTriggerItem/ExternalTriggerItem";
import { getDesignerPosition } from "../../../../../utils/getDefaultPosition";

import styles from "./externalTriggerCard.module.scss";
import ConnectedFlows from "../ConnectedFlows/ConnectedFlows";

export interface ExternalTriggerCardProps {
  externalTriggerGroups: ExternalTriggerGroup<ExternalTriggerType[]>[];
  isReadOnly: boolean;
  showExternalTriggersModal: () => void;
  removeExternalTrigger: (groupId: string, triggerId: string) => Promise<void>;
}

export const ExternalTriggerCard = (props: ExternalTriggerCardProps) => {
  const { externalTriggerGroups, isReadOnly } = props;
  const { setViewport, getViewport } = useReactFlow();

  useEffect(() => {
    const getFirsGroupTriggersLength = externalTriggerGroups[0]?.triggers.length;
    const shouldTransform = getViewport().y === 0 && getFirsGroupTriggersLength > 1;
    shouldTransform && setViewport(getDesignerPosition(getFirsGroupTriggersLength));
  }, [externalTriggerGroups, getViewport, setViewport]);

  return (
    <div
      className={cn(styles.root)}
      data-hook-type="flow-designer-external-trigger-card"
      data-testid="external-trigger-card"
      id="external-trigger-card"
    >
      <div className={styles.header} onClick={props.showExternalTriggersModal}>
        <div className={styles["header-left-block"]}>
          <ConnectedFlows />
          <span> Start of Flow</span>
        </div>
        <Icon className={styles["header-setting-icon"]} name="setting" />
      </div>
      <div className={styles.body}>
        <span className={styles["body-description"]}>
          Add users to this Flow who meet <b>all</b> of the following conditions:
        </span>
        {externalTriggerGroups.map((group) =>
          group.triggers.map((trigger) => (
            <ExternalTriggerItem
              groupId={group.groupId}
              key={trigger.id}
              item={trigger}
              isReadOnly={isReadOnly}
              removeExternalTrigger={props.removeExternalTrigger}
              onOpenTriggerPanel={props.showExternalTriggersModal}
            />
          )),
        )}
      </div>
    </div>
  );
};

export default ExternalTriggerCard;
