import { getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import dataService from "../../../../../Application/services/dataServices/typedDataService";
import { PublishInfo } from "../../types/models";
import * as actionTypes from "../actionTypes/emailPublishInfoActionTypes";

export const setIsLoading = getActionProvider<boolean>(actionTypes.setEmailPublishInfoLoading);

export const setPublishInfo = getActionProvider<PublishInfo>(actionTypes.setEmailPublishInfo);

export const failure = getActionProvider<{ error: Error }>(actionTypes.emailPublishInfoFetchingFailure);

export const fetchPublishInfo = (emailId: number) => async (dispatch: Function) => {
  try {
    dispatch(setIsLoading(true));

    const result = await dataService.emails.getEmailPublishInfoAsync(emailId);

    dispatch(setPublishInfo(result.data));

    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(failure({ error }));
  }
};
