import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as fetchingItemsActionTypes from "../../../../Application/actions/fetchingItems/fetchingItemsActionTypes";
import * as filteringItemsActionTypes from "../../../../Application/actions/filteringItems/filteringItemsActionTypes";
import * as searchActionTypes from "../../../../Application/actions/search/searchActionTypes";

export const LIBRARY_USER_ASSIGNMENTS_FETCH_USERS_BEGIN = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.User_Assignments,
  fetchingItemsActionTypes.FETCH_ITEMS_BEGIN,
);
export const LIBRARY_USER_ASSIGNMENTS_FETCH_USERS_SUCCESS = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.User_Assignments,
  fetchingItemsActionTypes.FETCH_ITEMS_SUCCESS,
);
export const LIBRARY_USER_ASSIGNMENTS_FETCH_USERS_FAILURE = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.User_Assignments,
  fetchingItemsActionTypes.FETCH_ITEMS_FAILURE,
);

export const LIBRARY_USER_ASSIGNMENTS_RESET_USERS_TO_BE_ASSIGNED = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.User_Assignments,
  "RESET_USERS_TO_BE_ASSIGNED",
);

export const LIBRARY_USER_ASSIGNMENTS_SET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.User_Assignments,
  filteringItemsActionTypes.SET_ITEMS_FILTER,
);

export const LIBRARY_USER_ASSIGNMENTS_RESET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.User_Assignments,
  filteringItemsActionTypes.RESET_ITEMS_FILTER,
);

export const LIBRARY_USER_ASSIGNMENTS_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.User_Assignments,
  searchActionTypes.SET_SEARCH,
);

export const LIBRARY_USER_ASSIGNMENTS_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.User_Assignments,
  searchActionTypes.RESET_SEARCH,
);
