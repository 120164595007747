import Form from "components/forms/layout";
import React from "react";

interface Props {
  image: React.ReactNode;
  name: React.ReactNode;
}

export const AccountInfoLayout = (props: Props) => {
  const { image, name } = props;
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "1rem", alignItems: "flex-end" }}>
      <Form.Input style={{ width: "6rem" }}>{image}</Form.Input>
      <Form.Input style={{ flexGrow: 1 }}>{name}</Form.Input>
    </div>
  );
};
