import React from "react";
import { AnswerPropsBase } from "../types";

export interface Picture {
  url: string;
}

export type PictureAnswerProps = AnswerPropsBase<Picture>;

export const PictureAnswer = (props: PictureAnswerProps) => {
  return <div className="answer-picture-block">{props.answer.answer.url}</div>;
};
