import { FC, useEffect } from "react";
import { RolePermissions, UsersGroupsContext } from "../../../../../enums";
import GroupsOverview from "./Overview/GroupsOverview/GroupsOverview";
import UsersOverview from "./Overview/UsersOverview/UsersOverview";
import PeopleWrapper from "../../../PeopleAssignments/Overview/PeopleWrapper/PeopleWrapper";

import { IWizardStep } from "../../../../../interfaces";

export interface EditVideoPeoplePropsBase extends IWizardStep {
  videoId: number;

  usersGroupsContext: UsersGroupsContext;
  onContextChanged: (value: UsersGroupsContext) => void;
}

export type EditVideoPeopleProps = EditVideoPeoplePropsBase;

export const EditVideoPeople: FC<EditVideoPeopleProps> = ({
  videoId,
  onContextChanged,
  usersGroupsContext,
  acceptHandlers,
}) => {
  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PeopleWrapper
        permission={RolePermissions.AssetsManage}
        usersGroupsContext={usersGroupsContext}
        onContextChanged={onContextChanged}
        renderGroupsOverview={(customHeaderContent: React.ReactElement) => (
          <GroupsOverview videoId={videoId} customHeaderContent={customHeaderContent} />
        )}
        renderUsersOverview={(customHeaderContent: React.ReactElement) => (
          <UsersOverview videoId={videoId} customHeaderContent={customHeaderContent} />
        )}
      />
    </>
  );
};

export default EditVideoPeople;
