import { combineReducers } from "redux";

import TemplateOverviewSlice from "features/People/GroupTemplate/state/TemplateOverviewSlice";
import TemplateFiltersSlice from "features/People/GroupTemplate/state/TemplateFiltersSlice";

const groupTemplatesAssignmentReducer = combineReducers({
  templatesToBeAssigned: TemplateOverviewSlice,
  filters: TemplateFiltersSlice,
});

export default groupTemplatesAssignmentReducer;
