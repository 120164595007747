import { isEmpty } from "lodash";

import ExpandableLinks from "../../../../../../components/ExpandableItems/ExpandableLinks/ExpandableLinks";
import MultiSelectWithAdditionField from "../../../../../../components/forms/MultiSelectWithAdditionField";

import { NormalizedDropdown } from "../../../../../../utils/miscellaneousUtils";
import { ValidatedForm } from "../../../../../../components/forms";

import styles from "./settingsForm.module.scss";
import { TagsPayloadCamelPlural } from "../../../../../../interfaces/Tags";
import { SettingsView } from "../../../types/state";
import { FormikProps } from "formik";

export interface SettingsFormProps {
  eventId: number;
  eventSettings: SettingsView;
  normalizedTags?: TagsPayloadCamelPlural;
  isDisabled: boolean;
  isLoading?: boolean;
  updateTags: (id: number, tags: string[], type: "labels" | "softwareApplications") => void;
  tagsFormik: FormikProps<SettingsView>;
}

const SettingsForm = (props: SettingsFormProps) => {
  const { eventSettings, isLoading, tagsFormik, eventId } = props;

  const handleOnTagChange = async (
    field: "softwareApplications" | "labels",
    value: NormalizedDropdown,
    shouldValidate?: boolean,
  ) => {
    const errors = await (tagsFormik.setFieldValue(field, value.selected, shouldValidate) as unknown as Promise<void>);

    if (isEmpty(errors?.[field])) {
      props.updateTags(eventId, value.selected, field);
    }
  };

  const renderFields = () => {
    const { isDisabled, normalizedTags } = props;
    return (
      <div className={styles.root}>
        <div className={styles["settings-fields"]}>
          <MultiSelectWithAdditionField
            {...tagsFormik}
            options={normalizedTags?.softwareApplications}
            value={tagsFormik.values.softwareApplications}
            shouldValidate
            isCaseSensitive
            label="Software Application"
            propertyName="softwareApplications"
            disabled={isDisabled}
            setFieldValue={handleOnTagChange}
          />
          <MultiSelectWithAdditionField
            {...tagsFormik}
            label="Labels"
            propertyName="labels"
            isCaseSensitive
            shouldValidate
            options={normalizedTags?.labels}
            value={tagsFormik.values.labels}
            disabled={isDisabled}
            setFieldValue={handleOnTagChange}
          />
          <ExpandableLinks
            linkFormatter={(id) => `/content/flows/${id}/items`}
            label="Associated Flows"
            links={eventSettings.flows}
            iconName="fa-code-branch"
          />
        </div>
      </div>
    );
  };
  return (
    <ValidatedForm parentWithFormik isLoading={isLoading} formWidthComputer={6} disablePreventTransitionPrompt>
      {renderFields}
    </ValidatedForm>
  );
};
export default SettingsForm;
