import { combineReducers, Action } from "redux";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import {
  IFetchingItemsState,
  withFetchingEntityListItems,
} from "../../../../Application/reducers/hoc/withFetchingItems";
import withFilteringItems from "../../../../Application/reducers/hoc/withFilteringItems";
import withFilterOptions from "../../../../Application/reducers/hoc/withFilterOptions";
import {
  LazyLoadableState,
  withFetchingEntityGridItems,
} from "../../../../Application/reducers/hoc/withFetchingItemsLazy";
import * as groupsOverviewActionTypes from "./groupsOverviewActionTypes";
import { mergeReducers } from "../../../../../utils/reduxUtils";
import { mergeOwners } from "../../../../../utils/groupsUtils";
import { Group, GroupOwner } from "../../../../../interfaces";
import { Reducer } from "@reduxjs/toolkit";

const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.Groups;

type GroupsState = IFetchingItemsState<Group>;
type GroupsStateGrid = GroupsState & LazyLoadableState<Group>;

interface GroupOverviewAction extends Action {
  owners: GroupOwner[];
  error?: string;
}

const overviewReducer = <State extends IFetchingItemsState<Group>>(state: State, action: GroupOverviewAction) => {
  state = state || {};
  const { type, error } = action;
  switch (type) {
    case groupsOverviewActionTypes.FETCH_GROUP_OWNERS.FETCH_ITEMS_FAILURE:
      return { ...state, error };

    case groupsOverviewActionTypes.FETCH_GROUP_OWNERS.FETCH_ITEMS_SUCCESS: {
      return {
        ...state,
        items: mergeOwners(state.items, action.owners),
      };
    }

    default:
      return state;
  }
};

export const groupsOverviewReducer = combineReducers({
  groupsList: mergeReducers<GroupsState, GroupOverviewAction>(
    withFetchingEntityListItems<GroupsState, GroupOverviewAction>(namespace, entityPrefix),
    overviewReducer,
  ) as Reducer<GroupsState, GroupOverviewAction>,
  groupsGrid: mergeReducers<GroupsStateGrid, GroupOverviewAction>(
    withFetchingEntityGridItems(namespace, entityPrefix),
    overviewReducer,
  ) as Reducer<GroupsStateGrid, GroupOverviewAction>,
  groupsFilterState: withFilteringItems(namespace, entityPrefix),
  filterOptions: withFilterOptions(namespace, entityPrefix),
});

export type PeopleGroupsOverviewState = ReturnType<typeof groupsOverviewReducer>;
