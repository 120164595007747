import packDataService from "../../services/packsDataService";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/requestedPacksLicensesSlice";

export const fetchRequestedPacksLicenses =
  (assignedAccountId: number, packIds: number[]) => async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const res = await packDataService.getRequestedPacksLicensesAsync(assignedAccountId, packIds);
      dispatch(fetchSuccess({ items: res.data, totalCount: res.data.length }));
    } catch (e) {
      dispatch(fetchFailure(e));
    }
  };
