import { useCallback } from "react";
import PropTypes from "prop-types";

import BackTitle from "../../backTitle/BackTitle";
import navigationUtils from "../../../utils/navigationUtils";
import { useLocation, useNavigate } from "react-router-dom";

import "./detailsHeaderTitle.scss";

function DetailsHeaderTitle(props) {
  const { title, pageTitle, titleForGA, backButton, defaultURL } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = useCallback(() => {
    if (typeof backButton === "string") {
      navigate(backButton);
    } else if (typeof backButton === "function") {
      navigationUtils.goBackOrDefault(location, navigate, backButton());
    } else {
      navigationUtils.goBackOrDefault(location, navigate, defaultURL);
    }
  }, [backButton, navigate, location, defaultURL]);

  return (
    <BackTitle
      title={title}
      pageTitle={pageTitle}
      titleForGA={titleForGA}
      className="details-header-title"
      renderBackButton={!!backButton}
      onClick={handleBackClick}
    />
  );
}

DetailsHeaderTitle.propTypes = {
  title: PropTypes.node,
  pageTitle: PropTypes.string,
  titleForGA: PropTypes.string,
  backButton: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.func]),
  defaultURL: PropTypes.string,
};

export default DetailsHeaderTitle;
