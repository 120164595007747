import { FC } from "react";

import GroupAwarenessIcon from "./groupAwarenessIcon/GroupAwarenessIcon";
import GroupRestrictionIcon from "./groupRestrictionIcon/GroupRestrictionIcon";
import GroupVisibilityIcon from "./groupVisibilityIcon/GroupVisibilityIcon";
import GroupEnrollIcon from "./groupEnrollIcon/GroupEnrollIcon";

import { Access, Awareness, Enrollment, Visibility } from "../../enums/groups";

import "./groupIcons.scss";

interface Props {
  isAware?: Awareness;
  isOpen?: Access;
  isVisibleToAll?: Visibility;
  isWithAutoEnroll?: Enrollment;
}

const GroupIcons: FC<Props> = ({
  isAware = Awareness.None,
  isOpen = Access.None,
  isVisibleToAll = Visibility.None,
  isWithAutoEnroll = Enrollment.None,
}) => (
  <div className="group-icons">
    <GroupAwarenessIcon isAware={isAware === Awareness.Aware} />
    {isOpen !== Access.None && <GroupRestrictionIcon isOpen={isOpen === Access.Open} />}
    {isVisibleToAll !== Visibility.None && (
      <GroupVisibilityIcon isVisibleToAll={isVisibleToAll === Visibility.VisibleToAll} />
    )}
    <GroupEnrollIcon isWithAutoEnroll={isWithAutoEnroll === Enrollment.Automatic} />
  </div>
);

export default GroupIcons;
