import { IExceptionTelemetry, SeverityLevel } from "@microsoft/applicationinsights-web";
export interface DropdownItem {
  text: string;
  value: string | number;
  disabled?: boolean;
}

export interface NormalizedDropdown {
  items: DropdownItem[];
  selected: string[];
}

function logError(errorMessage: string, errorStack?: string | null) {
  const error = new Error(errorMessage);
  console.error(error);
  errorStack && console.log(errorStack);
  if (window.appInsights) {
    const appInsightsException: IExceptionTelemetry = { error: error, severityLevel: SeverityLevel.Error };
    window.appInsights.trackException(appInsightsException);
  }
}

export const unlimitedNumber = 2147483647;

const utils = {
  getItemsPageRange: (activePage: number, itemsPerPageAmount: number, itemsAmount: number) => {
    let loadedItemsAmount = itemsPerPageAmount * (activePage - 1) + 1;
    if (activePage < 1) {
      loadedItemsAmount = 0;
      logError("List view. Items page range exception, negative active page");
    }
    let from = itemsAmount === 0 ? 0 : loadedItemsAmount;

    let to = from + itemsPerPageAmount - 1;
    to = to > itemsAmount ? itemsAmount : to;

    return { from, to };
  },

  normalizeDropdownItems: function (items: string[]): NormalizedDropdown {
    return {
      items: items.map((item) => {
        if (typeof item === "string") {
          return {
            text: item,
            value: item,
          };
        }
        return item;
      }),
      selected: items,
    };
  },

  getEmptyMultiSelectItems: function (): NormalizedDropdown {
    return { items: [], selected: [] };
  },

  setPageTitle: function (title?: string) {
    const newTitle = `${title}`;
    if (title && document.title !== newTitle) {
      document.title = newTitle;
    }
  },

  unlimitedNumber: function (number: number) {
    return number >= unlimitedNumber ? "Unlimited" : number.toLocaleString();
  },

  logError: logError,
};

export default utils;
