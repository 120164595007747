import React, { FC, useEffect, useRef, useState } from "react";
import { Dropdown, Icon, Ref } from "semantic-ui-react";
import cn from "classnames";

import { EllipsisPopupOption } from "./EllipsisPopup";

enum Pointing {
  Left = "left",
  Right = "right",
}

export interface DropdownMenuProps {
  handleItemClick: (options: EllipsisPopupOption) => () => void;
  renderDropdown: (options: EllipsisPopupOption) => React.ReactElement;
  option: EllipsisPopupOption;
}

export const DropdownMenu: FC<DropdownMenuProps> = (props) => {
  const [pointing, setPointing] = useState<Pointing>(Pointing.Left);
  const [open, setOpen] = useState<boolean>(false);
  const [isTransparent, setIsTransparent] = useState<boolean>(true);

  const dropdownRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const dropdown = dropdownRef.current;
    const menu = dropdown?.querySelector(".menu.visible");

    if (!menu || !dropdown) return;
    const dropdownRect = dropdown.getBoundingClientRect();
    const dropdownMenuRect = menu.getBoundingClientRect();
    const isOffscreenRight = dropdownRect.right + dropdownMenuRect.width > window.innerWidth;

    setPointing(isOffscreenRight ? Pointing.Right : Pointing.Left);
    setIsTransparent(false);
  }, [open]);

  const renderContent = (options: EllipsisPopupOption) => (
    <>
      {options.iconName && <Icon className={options.iconName} size="big" />}
      <span className="text"> {options.label}</span>
    </>
  );

  const renderItem = (option: EllipsisPopupOption) => (
    <Dropdown.Item
      disabled={option.disabledItem}
      onClick={props.handleItemClick(option)}
      key={option.label}
      content={renderContent(option)}
    />
  );

  const renderMenuItems = (options: EllipsisPopupOption[]) =>
    options.map((option) => (option.options ? props.renderDropdown(option) : renderItem(option)));

  return (
    <Ref innerRef={dropdownRef}>
      <Dropdown
        item
        pointing={pointing}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        key={props.option.label}
        trigger={renderContent(props.option)}
        icon={<Icon className="fa-chevron-right" color="blue" />}
      >
        <Dropdown.Menu className={cn({ transparent: isTransparent })}>
          {renderMenuItems(props.option.options!)}
        </Dropdown.Menu>
      </Dropdown>
    </Ref>
  );
};
