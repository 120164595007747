import { Component } from "react";
import { PropTypes } from "prop-types";
import { ValidatedForm, FormSectionHeader, validatedFormPropTypes } from "../../../../../components";
import EndpointsToGetSasLink from "../../../../../enums/endpointsToGetSasLink";
import GroupSettings from "./GroupSettings/GroupSettings";
import cn from "classnames";

import styles from "./groupConfiguration.module.scss";

export default class GroupConfiguration extends Component {
  isInitialValid = this.props.valid;
  state = {
    showModal: false,
    isWithAutoEnroll: true,
  };

  componentDidMount() {
    if (this.props.onDirtyChanged) {
      this.props.onDirtyChanged(this.props.dirty);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dirty !== this.props.dirty && this.props.onDirtyChanged) {
      this.props.onDirtyChanged(this.props.dirty);
    }
  }

  renderFormBody = () => {
    const {
      groupSettings,
      onIsAwareChanged,
      onIsWithAutoEnrollChanged,
      onVisibleToAllChanged,
      onIsOpenChanged,
      disableEnrollmentEdit,
      autoEnroll,
      hideSettings,
      values,
      errors,
      touched,
      isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,

      onIsValidChange,
      isLoadingGroupInfo,
      isReadOnly,
    } = this.props;

    const validatedFieldProps = {
      errors,
      touched,
      isFormValid: isValid,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };

    return (
      <section className="group-configuration">
        <FormSectionHeader title="Group Information" />
        <ValidatedForm.InputField
          label="Name"
          value={values.name}
          propertyName="name"
          placeholder="Enter Group Name"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          disabled={isReadOnly}
          {...validatedFieldProps}
        />
        <ValidatedForm.TextAreaField
          label="Description"
          markAsRequired
          value={values.description}
          propertyName="description"
          placeholder="Enter Brief Description"
          disabled={isReadOnly}
          onIsFormValidChange={onIsValidChange}
          {...validatedFieldProps}
        />

        <div className={cn(styles["custom-image"])}>
          <ValidatedForm.ImagePreview
            label="Image"
            value={values.imageUrl}
            propertyName="imageUrl"
            endpointToGetSasLink={EndpointsToGetSasLink.People.TempImages}
            disabled={isReadOnly}
            shouldValidate={false}
            className="custom-image-preview"
            headerPlaceholder="Upload Image"
            placeholder="PNG, JPG, GIF up to 5MB"
            combinedIcon
            {...validatedFieldProps}
          />
        </div>

        {!hideSettings && (
          <>
            <FormSectionHeader title="Settings" />

            <GroupSettings
              onIsAwareChanged={onIsAwareChanged}
              onIsWithAutoEnrollChanged={onIsWithAutoEnrollChanged}
              onVisibleToAllChanged={onVisibleToAllChanged}
              onIsOpenChanged={onIsOpenChanged}
              disableEnrollmentEdit={disableEnrollmentEdit}
              isWithAutoEnroll={groupSettings.isWithAutoEnroll}
              isAware={groupSettings.isAware}
              isOpen={groupSettings.isOpen}
              isVisibleToAll={groupSettings.isVisibleToAll}
              disabled={isLoadingGroupInfo || isReadOnly}
              autoEnroll={autoEnroll}
            />
          </>
        )}
      </section>
    );
  };

  render() {
    const { handleSubmit, dirty, isLoading } = this.props;

    return (
      <section className="group-info-form scrollable-content">
        <ValidatedForm
          title="Group information"
          handleSubmit={handleSubmit}
          dirty={dirty}
          isInitialValid={this.isInitialValid}
          unsavedChangesPrompt={{
            title: "Exit Without Saving?",
            message: "Are you sure you want to exit without saving this group? All information entered will be lost.",
          }}
          isLoading={isLoading}
        >
          {this.renderFormBody()}
        </ValidatedForm>
      </section>
    );
  }
}

GroupConfiguration.propTypes = {
  groupSettings: PropTypes.shape({
    isAware: PropTypes.bool,
    isWithAutoEnroll: PropTypes.bool,
    isVisibleToAll: PropTypes.bool,
    isOpen: PropTypes.bool,
  }),
  onIsAwareChanged: PropTypes.func,
  onIsWithAutoEnrollChanged: PropTypes.func,
  onVisibleToAllChanged: PropTypes.func,
  onIsOpenChanged: PropTypes.func,
  disableEnrollmentEdit: PropTypes.bool,

  onIsValidChange: PropTypes.func,
  isLoadingGroupInfo: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  autoEnroll: PropTypes.bool,
  ...validatedFormPropTypes,
};
