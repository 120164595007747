import { FormikProps } from "formik";
import nameof from "utils/nameof";
import { GoalItem, GoalOptions, GoalView } from "../types/models";
import { ValidatedForm } from "../../../../components/forms";
import Form from "components/forms/layout";
import { groupBy, set } from "lodash";
import { useMemo, useState } from "react";
import environmentConfig from "configuration/environmentConfig";
import { useGetFlowQuery } from "./Queries/queries";
import { RootState } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { QueryFunctionContext } from "@tanstack/react-query";

export type queryFlow = QueryFunctionContext<
  [_: string, currentUserEmail: string, originalAccountId: number]
>;

export interface Props extends PropsFromRedux {
  currentUserEmail: string;
  originAccountId: number;
  options: GoalOptions;
  isLoading: boolean;
  disabled: boolean;
  formik: FormikProps<GoalView>;
  onChanged: () => void;
}

const GOALS_URL = `${environmentConfig.helpCenterUrl}/Enterprise/Goals.htm`;
const MEASURE_URL = `${environmentConfig.helpCenterUrl}/Enterprise/Goals.htm?section=Measure`;

export const GoalForm = (props: Props) => {
  const { isLoading, options, disabled, formik, onChanged, currentUserEmail, originAccountId } = props;
  const [foundFlow, setFoundFlow] = useState(false);

  const { values, setFieldValue, setValues } = formik;

  const flowData = useGetFlowQuery({ currentUserEmail, originAccountId });

  const QUICK_START_URL = useMemo(() => {
    if (flowData.isSuccess && flowData.data.length === 1) {
      setFoundFlow(true);
      return `${environmentConfig.eupUrl}/discover/flows/${flowData.data[0]?.flowId}`;
    }
    return GOALS_URL;
  }, [flowData.data, flowData.isSuccess]);

  const setFieldValueWithDeps = (deps: FieldDependencies) => (name: string, value: any, shouldValidate?: boolean) => {
    const field = name as keyof GoalView;
    const index = deps.indexOf(field);

    if (index === deps.length - 1) {
      return setFieldValue(name, value, shouldValidate);
    }
    const restFields = {};
    deps.slice(index + 1).forEach((x) => set(restFields, x, null));

    return setValues((prev) => {
      const result = { ...prev, ...restFields, [name]: value };
      return result;
    }, shouldValidate);
  };

  const setGoalField = setFieldValueWithDeps(goalDeps);
  const setSuccessField = setFieldValueWithDeps(successDeps);

  const mappedOptions = useMemo(() => {
    return {
      objective: options.objective,
      type: groupBy(options.type, (x) => x.parentId),
      successMetric: options.successMetric,
      dataSource: groupBy(options.dataSource, (x) => x.parentId),
      application: groupBy(options.application, (x) => x.parentId),
      measure: groupBy(options.measure, (x) => x.parentId),
    };
  }, [options]);

  return (
    <Form.Root isLoading={isLoading}>
      <Form.Block title="Define Goal">
        <Form.Input>
          <ValidatedForm.DropdownField
            placeholder="Select Objective"
            label="Objective"
            value={values.objectiveId}
            propertyName={nameof<GoalView>("objectiveId")}
            options={getOptions(mappedOptions.objective)}
            disabled={disabled}
            clearable
            {...formik}
            onChangeCallback={onChanged}
            setFieldValue={setGoalField}
            tooltip={{
              info: (
                <>
                  BrainStorm's value-based objectives. Learn more in our&nbsp;
                  <a href={QUICK_START_URL} target="_blank" rel="noopener noreferrer">
                    {foundFlow ? "Quick Start" : "Help Center"}
                  </a>
                  {foundFlow ? " course" : ""}.
                </>
              ),
              width: 18,
              hoverable: true,
            }}
            defaultValue={null}
          />
        </Form.Input>
        <Form.Input>
          <ValidatedForm.DropdownField
            placeholder="Select Type"
            label="Type"
            value={values.typeId}
            propertyName={nameof<GoalView>("typeId")}
            options={getOptions(mappedOptions.type[values.objectiveId ?? 0])}
            disabled={disabled || !values.objectiveId}
            clearable
            {...formik}
            onChangeCallback={onChanged}
            setFieldValue={setGoalField}
            tooltip={{
              info: (
                <>
                  Enhance objectives with a flow type. Learn more in our&nbsp;
                  <a href={QUICK_START_URL} target="_blank" rel="noopener noreferrer">
                    {foundFlow ? "Quick Start" : "Help Center"}
                  </a>
                  {foundFlow ? " course" : ""}.
                </>
              ),
              width: 18,
              hoverable: true,
            }}
            defaultValue={null}
          />
        </Form.Input>
      </Form.Block>
      <Form.Block title="Add success metrics">
        <Form.Input>
          <ValidatedForm.DropdownField
            placeholder="Select Success Metric"
            label="Success Metric"
            value={values.metricId}
            propertyName={nameof<GoalView>("metricId")}
            options={getOptions(mappedOptions.successMetric)}
            disabled
            clearable
            {...formik}
            onChangeCallback={onChanged}
            setFieldValue={setSuccessField}
            tooltip={{
              info: "The type of data used to gauge the success of this goal.",
              width: 18,
            }}
            defaultValue={null}
          />
        </Form.Input>
        <Form.Input>
          <ValidatedForm.DropdownField
            placeholder="Select Data Source"
            label="Data Source"
            value={values.dataSourceId}
            propertyName={nameof<GoalView>("dataSourceId")}
            options={getOptions(mappedOptions.dataSource[values.metricId ?? 0])}
            disabled={disabled}
            clearable
            {...formik}
            onChangeCallback={onChanged}
            setFieldValue={setSuccessField}
            tooltip={{
              info: "The origin of information on application usage.",
              width: 18,
            }}
            defaultValue={null}
          />
        </Form.Input>
        <Form.Input>
          <ValidatedForm.DropdownField
            placeholder="Select Application"
            label="Application"
            value={values.applicationId}
            propertyName={nameof<GoalView>("applicationId")}
            options={getOptions(mappedOptions.application[values.dataSourceId ?? 0])}
            disabled={disabled || !values.dataSourceId}
            clearable
            {...formik}
            markAsRequired={!!values.dataSourceId}
            onChangeCallback={onChanged}
            setFieldValue={setSuccessField}
            defaultValue={null}
          />
        </Form.Input>
        <Form.Input>
          <ValidatedForm.DropdownField
            placeholder="Select Measure"
            label="Measure"
            value={values.measureId}
            propertyName={nameof<GoalView>("measureId")}
            options={getOptions(mappedOptions.measure[values.applicationId ?? 0])}
            disabled={disabled || !values.applicationId}
            clearable
            {...formik}
            markAsRequired={!!values.dataSourceId}
            onChangeCallback={onChanged}
            setFieldValue={setSuccessField}
            tooltip={{
              info: (
                <>
                  The particular&nbsp;
                  <a href={MEASURE_URL} target="_blank" rel="noopener noreferrer">
                    measure
                  </a>
                  &nbsp;to monitor for the chosen application.
                </>
              ),
              width: 18,
              hoverable: true,
            }}
            defaultValue={null}
          />
        </Form.Input>
      </Form.Block>
    </Form.Root>
  );
};

const getOptions = (options: GoalItem[]) => {
  return options?.map((x) => ({
    key: x.id,
    value: x.id,
    text: x.label,
  }));
};

type FieldDependencies = Array<keyof GoalView>;
const goalDeps: FieldDependencies = ["objectiveId", "typeId"];
const successDeps: FieldDependencies = ["metricId", "dataSourceId", "applicationId", "measureId"];

const mapStateToProps = (state: RootState) => ({
  currentUserEmail: state.userProfile.name,
  originAccountId: state.userProfile.originAccountId,
});

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(GoalForm);