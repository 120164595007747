import { createAsyncThunk } from "@reduxjs/toolkit";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { FetchActionPayload } from "../../../../../interfaces/redux";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { getPrefix } from "../../../../Application/slices/models";
import { GetPacksRequest, UserPacksRequest } from "../../types/requests";
import { PackOverview } from "../../types/state";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const loadGridItemsCount: any = process.env.REACT_APP_LOAD_ITEMS_COUNT;

export const fetchUserPacksGrid = createAsyncThunk<FetchActionPayload<PackOverview>, UserPacksRequest>(
  getPrefix({ namespace: ReducerNamespaceTypes.Licensing, entity: ReducerEntityPrefixTypes.Packs, name: "userPacks" }),
  async (requestData: UserPacksRequest, { signal }) => {
    const request: GetPacksRequest = {
      top: requestData.top ?? loadGridItemsCount,
      skip: requestData.skip,
      orderBy: requestData.orderBy,
      searchTerm: requestData.searchTerm,
      sortBy: requestData.sortBy,
      sortOrder: requestData.sortOrder,
    };

    const { getDataPromise, cancel } = dataService.packs.getUserPacksWithCancel(requestData.userId, request);

    signal.addEventListener("abort", cancel);

    const result = await getDataPromise;
    const recordsCount = Number.parseInt(result.headers[countHeaderName]);

    return {
      items: result.data,
      totalCount: recordsCount,
    };
  },
);

export type UserPacksGridFetchType = ReturnType<ReturnType<typeof fetchUserPacksGrid>>;
