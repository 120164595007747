import { FC, useEffect, useState } from "react";
import { Button } from "components/buttons/button/Button";
import ModalWithSteps from "../../../../../../../components/modal/ModalWithSteps";
import { SortingDirection } from "../../../../../../../enums";
import { FiltersMap } from "../../../../../../../utils/filterUtils";
import { Filters } from "../../../../../../../utils/queryUtils";
import PackVisibilityForAccountsStep from "./PackVisibilityForAccountsStep";
import { PackVisibilityForAccountsModalOneStepProps } from "../types";
import "./packVisibilityForAccountsModalOneStep.scss";

export const PackVisibilityForAccountsModalOneStep: FC<PackVisibilityForAccountsModalOneStepProps> = (
  props: PackVisibilityForAccountsModalOneStepProps,
) => {
  const [selectedIds, setSelectionIds] = useState<number[]>([]);
  const [appliedFilter, setFilter] = useState<Filters>({});

  const { showModal, onCancel, id, accounts, accountsAmount, isListLoading } = props;

  useEffect(() => {
    if (!showModal) {
      setSelectionIds([]);
      setFilter({});
    }
  }, [showModal, accounts]);

  const onFetchPotentialAccounts = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    filter: FiltersMap,
  ) => {
    const { loadPage } = props;
    loadPage(skip, top, sortingColumnName, sortingDirection, filter);
  };

  const renderModalActions = () => {
    const { onConfirm } = props;
    return (closeModal: Function) => {
      return (
        <>
          <Button
            basic
            className="cancel"
            content="Cancel"
            onClick={() => {
              closeModal();
              onCancel();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Finish"
            disabled={selectedIds.length === 0}
            onClick={() => {
              onConfirm(selectedIds);
              closeModal();
            }}
          />
        </>
      );
    };
  };

  const applyAccountFilter = (filter: Filters) => setFilter(filter);

  const resetAccountFilter = () => setFilter({});

  return (
    <ModalWithSteps
      className="pack-account-visibility-modal-one-step"
      scrolling={false}
      isLoading={false}
      showModal={showModal}
      onCancel={onCancel}
    >
      <PackVisibilityForAccountsStep
        id={id}
        header="Add Accounts"
        renderModalActions={renderModalActions}
        loadPage={onFetchPotentialAccounts}
        isLoading={isListLoading}
        accounts={accounts}
        accountsCount={accountsAmount}
        onSelectedListItemsChanged={(ids: number[]) => setSelectionIds(ids)}
        selectedIds={selectedIds}
        applyFilter={applyAccountFilter}
        filter={appliedFilter}
        resetFilter={resetAccountFilter}
      />
    </ModalWithSteps>
  );
};
