import React from "react";
import { NoResultsWithButton } from "../../../../components";
import { ReactComponent as AssetIcon } from "../../../../images/assets-icon.svg";

export interface VideosNotFoundProps {
  createAssetButton?: React.ReactElement;
  filtered?: boolean;
}

const VideosNotFound: React.FC<VideosNotFoundProps> = ({ createAssetButton, filtered }) => {
  return (
    <NoResultsWithButton
      title="You don't have any videos"
      description="Looks like you don't have any videos yet."
      icon={<AssetIcon />}
      actionButton={createAssetButton}
      filtered={filtered}
    />
  );
};

export default VideosNotFound;
