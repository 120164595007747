import React, { ErrorInfo } from "react";
import { IObservable } from "../../interfaces/IObservable";
import miscellaneousUtils from "../../utils/miscellaneousUtils";

export interface Props {
  errorView: React.ReactNode;
  onErrorStateChangedObserver: IObservable<(hasError: boolean) => void>;
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    miscellaneousUtils.logError(error.message, info.componentStack);
  }

  onErrorStateChanged = (hasError: boolean) => {
    this.setState({ hasError: hasError });
  };

  componentDidMount() {
    this.props.onErrorStateChangedObserver.subscribe(this.onErrorStateChanged);
  }

  componentWillUnmount() {
    this.props.onErrorStateChangedObserver.unsubscribe(this.onErrorStateChanged);
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorView;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
