import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { GenericFiltersMap } from "../../../../../utils/filterUtils";
import { createFiltersSlice, FiltersState } from "../../../../Application/slices/createFiltersSlice";
import { EventFilterPropertyNames } from "../../types/state";

export const initialState: FiltersState<EventFilterPropertyNames> = {
  filterOptions: {} as GenericFiltersMap<EventFilterPropertyNames>,
  appliedFilter: {} as GenericFiltersMap<EventFilterPropertyNames>,
  isLoading: false,
};

const eventFilterSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Events,
    name: "overviewFilters",
  },
  initialState: initialState,
  reducers: {},
});

export const { setAppliedFilter, resetAppliedFilter, setIsLoading, setError, setFilterOptions } =
  eventFilterSlice.actions;

export type EventFiltersState = ReturnType<typeof eventFilterSlice.reducer>;

export const eventFiltersReducer = eventFilterSlice.reducer;
