import StatusCode from "../../../../enums/httpStatusCodes";
import { AppDispatch } from "../../../Application/globaltypes/redux";
import accountsDataService from "../../services/accountsDataService";
import { req, err, got, forbidden } from "../slices/subscriptionAgreementSlice";

export const fetchSubscriptionAgreement = (accountId: number) => async (dispatch: AppDispatch) => {
  dispatch(req());
  try {
    const agreement = await accountsDataService.getSubscriptionAgreement(accountId);
    dispatch(got(agreement));
  } catch (error) {
    let errorMessage = (error as Error).message;
    const errorAction = error?.response.status === StatusCode.Forbidden ? forbidden(errorMessage) : err(errorMessage);
    dispatch(errorAction);
  }
};
