import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "../../../../../interfaces/redux";
import { AssignmentPeopleContext } from "../../../../../enums";

export interface PeopleIssueLicenseModalState {
  selectedPeopleType?: AssignmentPeopleContext;
}

const initialState: PeopleIssueLicenseModalState = {
  selectedPeopleType: undefined,
};

const peopleIssueLicenseModalSlice = createSlice({
  name: "peopleIssueLicenseModal",
  initialState: initialState,
  reducers: {
    setPeopleType: (state: PeopleIssueLicenseModalState, action: PayloadAction<AssignmentPeopleContext>) => {
      state.selectedPeopleType = action.payload;
    },
    resetPeopleType: (state: PeopleIssueLicenseModalState) => {
      state.selectedPeopleType = undefined;
    },
  },
});

export const { setPeopleType, resetPeopleType } = peopleIssueLicenseModalSlice.actions;

export type peopleIssueLicenseModalSliceType = ReturnType<typeof peopleIssueLicenseModalSlice.reducer>;
export default peopleIssueLicenseModalSlice.reducer;
