import {
  ThreatDefenceCampaignOption,
  ThreatDefenceCampaignOptions,
  ThreatDefenceCampaignOptionsState,
} from "../../types/state";
import * as actionTypes from "../actionTypes/threatDefenceCampaignOptionsActionTypes";
import { PayloadAction } from "../../../../../../interfaces/redux";
import { createReducer } from "../../../../../../utils/reduxUtils";

const initialState: ThreatDefenceCampaignOptionsState = {
  senderProfiles: [],
  types: [],
  emailTemplates: [],
  landingPages: [],
  areOptionsLoading: false,
};

const getHandlers = () => {
  const {
    getThreatDefenceCampaignOptions,
    getThreatDefenceCampaignOptionsBegin,
    getThreatDefenceCampaignOptionsFailure,
    getThreatDefenceCampaignEmailTemplatesFailure,
    getThreatDefenceCampaignEmailTemplatesSuccess,
    getThreatDefenceCampaignEmailTemplatesBegin,
    getThreatDefenceCampaignLandingPagesFailure,
    getThreatDefenceCampaignLandingPagesSuccess,
    getThreatDefenceCampaignLandingPagesBegin,
  } = actionTypes;

  const beginHandler = (state: ThreatDefenceCampaignOptionsState) => ({
    ...state,
    areOptionsLoading: true,
  });

  const failureHandler = (state: ThreatDefenceCampaignOptionsState) => ({
    ...state,
    areOptionsLoading: false,
  });

  const getCampaignOptionsSuccessHandler = (
    state: ThreatDefenceCampaignOptionsState,
    action: PayloadAction<ThreatDefenceCampaignOptions>,
  ): ThreatDefenceCampaignOptionsState => ({
    ...state,
    areOptionsLoading: false,
    ...action.payload,
  });

  const getCampaignEmailTemplatesSuccessHandler = (
    state: ThreatDefenceCampaignOptionsState,
    action: PayloadAction<ThreatDefenceCampaignOption[]>,
  ): ThreatDefenceCampaignOptionsState => ({
    ...state,
    areOptionsLoading: false,
    emailTemplates: action.payload,
  });

  const getCampaignLandingPagesSuccessHandler = (
    state: ThreatDefenceCampaignOptionsState,
    action: PayloadAction<ThreatDefenceCampaignOption[]>,
  ): ThreatDefenceCampaignOptionsState => ({
    ...state,
    areOptionsLoading: false,
    landingPages: action.payload,
  });

  return {
    [getThreatDefenceCampaignOptions]: getCampaignOptionsSuccessHandler,
    [getThreatDefenceCampaignOptionsBegin]: beginHandler,
    [getThreatDefenceCampaignOptionsFailure]: failureHandler,
    [getThreatDefenceCampaignEmailTemplatesSuccess]: getCampaignEmailTemplatesSuccessHandler,
    [getThreatDefenceCampaignEmailTemplatesBegin]: beginHandler,
    [getThreatDefenceCampaignEmailTemplatesFailure]: failureHandler,
    [getThreatDefenceCampaignLandingPagesSuccess]: getCampaignLandingPagesSuccessHandler,
    [getThreatDefenceCampaignLandingPagesBegin]: beginHandler,
    [getThreatDefenceCampaignLandingPagesFailure]: failureHandler,
  };
};

export const threatDefenceCampaignOptionsReducer = createReducer(initialState, [getHandlers]);
