import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { TemplateStatusEnum } from "../types/models";
import { faClock, faCircleXmark, faCircleCheck } from "@fortawesome/pro-solid-svg-icons";

import styles from "./TemplateStatus.module.scss";

const iconMap = {
  [TemplateStatusEnum.Approved]: [faClock, "Approved", "approved"],
  [TemplateStatusEnum.Rejected]: [faCircleXmark, "Declined", "rejected"],
  [TemplateStatusEnum.Pending]: [faCircleCheck, "Requested", "pending"],
} as const;

interface Props {
  status: TemplateStatusEnum;
}

export const TemplateStatus = (props: Props) => {
  const { status } = props;

  const [icon, text, className] = iconMap[status];

  return (
    <div className={`${styles.root} ${styles[className]}`}>
      <FontAwesomeIcon className={styles.icon} icon={icon} /> {text}
    </div>
  );
};
