import React, { FC, useCallback, useLayoutEffect, useRef, useState } from "react";
import { Divider, Icon } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import cn from "classnames";

import { ExpandableItemsProps } from "./types";
import { calculateHiddenItems } from "./helpers";
import useOnResize from "../../hooks/useOnResize";

import styles from "./expandableItems.module.scss";

const ExpandableItems: FC<ExpandableItemsProps> = (props) => {
  const length = React.Children.toArray(props.children).length;
  const listRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [hiddenItemsCount, setHiddenItemsCount] = useState(0);

  const handleShowMore = () => {
    setExpanded(true);
  };

  const handleResize = useCallback(() => {
    setHiddenItemsCount(calculateHiddenItems(length, listRef.current));
  }, [length]);

  useOnResize(handleResize, listRef);

  useLayoutEffect(() => {
    setHiddenItemsCount(calculateHiddenItems(length, listRef.current));
  }, [length]);

  return (
    <div role="listbox" className={styles["expandable-items-root"]}>
      <label>{props.label}</label>
      <div
        ref={listRef}
        className={cn(styles["list-content"], { [styles["expanded"]]: expanded }, props.contentClassName)}
      >
        {props.children}
      </div>
      {hiddenItemsCount > 0 && !expanded && (
        <Divider horizontal>
          <Button onClick={handleShowMore} className={styles["show-more-button"]}>
            <Icon circular className="fa-ellipsis-h" />
            <span className={styles["label"]}>{`Show More (${hiddenItemsCount})`}</span>
          </Button>
        </Divider>
      )}
    </div>
  );
};

export default ExpandableItems;
