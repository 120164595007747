import { FC, useEffect, useRef, useState } from "react";
import { Button } from "components/buttons/button/Button";
import { isNil } from "lodash";
import intersection from "lodash/intersection";

import { ConfirmLicensingStep, NotifyStep } from "../commonSteps";
import { UserListStep } from "../commonSteps/index";
import { TemplateTypes, RolePermissions, SortingDirection, CommunicationChannels } from "../../../enums";
import Strings from "../../../enums/strings";
import { Context } from "../../../features/Licensing/ContentAssignmentModalLicensingSteps/LicenseConfirmationModalContent/licenseConfirmationInfo/LicenseConfirmationInfo";
import { AssignedUser, NotifyStepSettings, User } from "../../../interfaces";
import ModalWithSteps from "../../modal/ModalWithSteps";
import { Filters } from "../../../utils/queryUtils";
import PeopleListFirstStepButtons from "../commonSteps/peopleListFirstStepButtons/PeopleListFirstStepButtons";
import useNotifyConfig from "../../../features/SystemNotifications/hooks/useNotifyConfig";
import { initialNotifyConfigDefault } from "../../../features/SystemNotifications/config";
import { NotifyStepSwitch } from "../../notifyStep/NotifyStepSwitch";
import SearchInput from "../../searchInput/SearchInput";

import "./usersAssignmentModal.scss";

export interface UsersAssignmentModalProps {
  loadPage: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    appliedFilter: Filters,
  ) => void;
  usersAmount: number;
  isListLoading: boolean;
  isStepLoading: boolean;
  users: User[];
  onSecondStep: (selectedIds: number[]) => Promise<boolean>;
  onConfirm: (notificationSettings?: NotifyStepSettings) => Promise<void>;
  showModal: boolean;
  onCancel: () => void;
  renderTrigger?: (closeModal: () => void) => React.ReactElement;
  alreadyAssignedUserTooltip: string;
  assignmentEntityType?: string;
  multipleAssignment: boolean;
  focusedEntityIds: number[];
  usersAssignments: {
    items: { userId: number }[];
    comparer: (assignment: { userId: number }, id: number) => boolean;
  };
  search: string;
  onSearchChanged: (value: string) => void;
  setReloadListItems: (reloadItems: (enableSorting: boolean) => void) => void;
  showRoleFilter: boolean;
  disableNotifications?: boolean;
}

const initNotifyConfig = {
  ...initialNotifyConfigDefault,
  shouldNotifyUsers: false,
};

export const UsersAssignmentModal: FC<UsersAssignmentModalProps> = (props: UsersAssignmentModalProps) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [appliedFilter, setFilter] = useState<Filters>({});
  const [isDataValid, setIsDataValid] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const onPreviousNotifyStepRef = useRef<() => Promise<void>>();
  const getNotifySettingsRef = useRef<() => Promise<NotifyStepSettings | undefined>>();
  const [notifyConfig, { setNotifyConfig, resetNotifyConfig, shouldNotify, communicationChannel }] =
    useNotifyConfig(initNotifyConfig);

  const [skipLicensingStep, setSkipLicensingStep] = useState(false);

  const { showModal } = props;

  useEffect(() => {
    if (!showModal) {
      setSelectedIds([]);
      setFilter({});
    }
  }, [showModal]);

  useEffect(() => {
    !showModal && resetNotifyConfig();
  }, [showModal, resetNotifyConfig]);

  const renderUserListStepActions = (nextStep: () => void) => {
    const { onSecondStep } = props;
    return (closeModal: () => void) => {
      return (
        <PeopleListFirstStepButtons
          onCancel={() => {
            closeModal();
            onCancel();
          }}
          onNext={() => {
            onSecondStep(selectedIds).then((shouldShowModal) => {
              setSkipLicensingStep(!shouldShowModal);
              return nextStep();
            });
          }}
          nextDisabled={selectedIds.length === 0 || isStepLoading}
        />
      );
    };
  };

  const renderLicensingConfirmationStepActions = (nextStep: () => void, prevStep: () => void) => {
    return () => {
      return (
        <>
          <Button primary className="previous" content="Previous" onClick={prevStep} />
          <Button primary disabled={!isDataValid} className="next" content="Next" onClick={nextStep} />
        </>
      );
    };
  };

  const renderNotifyStepActions = (_nextStep: () => void, prevStep: () => void) => {
    const { onConfirm } = props;
    return (closeModal: () => void) => (
      <>
        <Button
          primary
          className="previous"
          content="Previous"
          onClick={() => {
            onPreviousNotifyStepRef?.current?.();
            prevStep();
          }}
        />
        <Button
          primary
          className="confirm"
          content="Finish"
          disabled={
            shouldNotify && ((communicationChannel === CommunicationChannels.Email && !isDataLoaded) || !isDataValid)
          }
          onClick={async () => {
            const notifySettings = await getNotifySettingsRef?.current?.();
            await onConfirm(notifySettings);
            closeModal();
          }}
        />
      </>
    );
  };

  const resetUserFilter = () => setFilter({});

  const getUsersForAssign = () => {
    const {
      users,
      usersAssignments: { items, comparer },
    } = props;
    return users.map((user) => {
      const assignedCount = focusedEntityIds.filter(
        (id) => !isNil(items.find((a) => comparer(a, id) && a.userId === user.id)),
      ).length;
      const hasAssignments = !!assignedCount;
      const isAssigned = hasAssignments && assignedCount === focusedEntityIds.length;

      return {
        ...user,
        isAssigned,
        hasAssignments,
        assignedToNumberOfEntities: items.filter((x) => x.userId === user.id).length,
      } as AssignedUser;
    });
  };

  const {
    onCancel,
    renderTrigger,
    isStepLoading,
    usersAmount,
    loadPage,
    isListLoading,
    assignmentEntityType,
    focusedEntityIds,
    multipleAssignment,
    search,
    onSearchChanged,
    setReloadListItems,
    alreadyAssignedUserTooltip,
    showRoleFilter,
    disableNotifications,
  } = props;

  return (
    <ModalWithSteps
      className={"users-assignment-modal"}
      scrolling
      renderTrigger={renderTrigger}
      isLoading={isStepLoading}
      showModal={showModal}
      onCancel={onCancel}
    >
      <UserListStep
        renderSearch={() => (
          <SearchInput placeholder="Search for users..." onChange={onSearchChanged} defaultValue={search} />
        )}
        header="Add People"
        renderModalActions={renderUserListStepActions}
        loadPage={loadPage}
        isLoading={isListLoading}
        users={getUsersForAssign()}
        usersCount={usersAmount}
        onSelectedListItemsChanged={setSelectedIds}
        selectedIds={selectedIds}
        applyFilter={setFilter}
        filter={appliedFilter}
        resetFilter={resetUserFilter}
        alreadyAssignedUserTooltip={alreadyAssignedUserTooltip}
        assignmentEntityType={assignmentEntityType}
        selectedParentEntitiesLength={focusedEntityIds.length}
        multipleAssignment={multipleAssignment}
        showRoleFilter={showRoleFilter}
        hasPermissionPredicate={(userPermissions) =>
          intersection(userPermissions, [RolePermissions.UsersView]).length > 0
        }
        setReloadListItems={setReloadListItems}
      />
      {!skipLicensingStep && (
        <ConfirmLicensingStep
          header={Strings.modalTitles.licenseConfirmation}
          renderModalActions={renderLicensingConfirmationStepActions}
          onIsDataValidChange={setIsDataValid}
          info={{
            groupIds: focusedEntityIds,
            userIds: selectedIds,
          }}
          context={Context.AddPeopleToGroups}
        />
      )}
      <NotifyStep
        preRender
        header="Notify"
        renderModalActions={renderNotifyStepActions}
        onIsDataValidChange={setIsDataValid}
        onIsDataLoaded={setIsDataLoaded}
        isDataLoaded={isDataLoaded}
        onPreviousNotifyStepRef={onPreviousNotifyStepRef}
        getNotifySettingsRef={getNotifySettingsRef}
        notifyStepDisabled={disableNotifications}
        notifyTemplateType={TemplateTypes.AddedToGroup}
        renderSwitch={(switchProps) => (
          <NotifyStepSwitch config={notifyConfig} onNotifyConfigChange={setNotifyConfig} switchProps={switchProps} />
        )}
        shouldNotify={shouldNotify}
        communicationChannel={communicationChannel}
      />
    </ModalWithSteps>
  );
};

UsersAssignmentModal.defaultProps = {
  focusedEntityIds: [],
};

export default UsersAssignmentModal;
