import axios from "axios";
import qs from "qs";
import KnownHttpHeaders from "../../Application/services/knownHttpHeaders";
import { escapeTerm } from "../../../utils/searchUtils";
import StatusCode from "../../../enums/httpStatusCodes";
import { formatFiltersV2api, mapToV2Filters, serializeArrayAndFilterNullable } from "../../../utils/queryUtils";
import { v2FilterMap } from "../types";
import { isEmpty, isString } from "lodash";

const rolesUrl = "/api/roles";

const service = {
  async getRoles(pagingOptions) {
    const config = {
      params: { ...pagingOptions, searchTerm: escapeTerm(pagingOptions.searchTerm) },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    const response = await axios.get(rolesUrl, config);

    return {
      items: response.data.items,
      itemsCount: response.data.count,
    };
  },

  async isRoleNameExists(name) {
    const encodedRolename = encodeURIComponent(name);
    try {
      await axios.head(`${rolesUrl}/${encodedRolename}`);
      return true;
    } catch (error) {
      if (error.response?.status === StatusCode.NotFound) {
        return false;
      }
      throw error;
    }
  },

  async createRole(accountId, roleConfig) {
    const response = await axios.post(`${rolesUrl}`, {
      ...roleConfig,
      accountId,
    });

    return response.data;
  },

  async getRoleInfo(id) {
    const response = await axios.get(`${rolesUrl}/${id}`);
    return response.data;
  },

  async getRoleUsers(roleId, skipCount, loadCount, orderByParams, filterParams) {
    const config = {
      params: {
        skip: skipCount,
        top: loadCount,
      },
    };

    if (orderByParams) {
      config.params.orderBy = orderByParams;
    }

    if (filterParams) {
      config.params.filter = filterParams;
    }

    const response = await axios.get(`${rolesUrl}/${roleId}/users`, config);
    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async unassignRolesFromUsers(userIds, roleIds) {
    const userToRoleUnassignments = [];

    for (const userId of userIds) {
      for (const roleId of roleIds) {
        userToRoleUnassignments.push({ userId, roleId });
      }
    }

    const response = await axios.delete(`${rolesUrl}/users`, {
      data: {
        userToRoleUnassignments,
      },
    });

    return response.data;
  },

  async updateRoleInfo(roleId, name, description) {
    const response = await axios.put(`${rolesUrl}/${roleId}/info`, { name, description });
    return response.data;
  },

  async updateRolePermissions(roleId, permissions) {
    const response = await axios.put(`${rolesUrl}/${roleId}/permissions`, permissions);
    return response.data;
  },

  async deleteRole(roleId) {
    const response = await axios.delete(`${rolesUrl}/${roleId}`);
    return response.data;
  },

  async getRoleUsersAssignments(roleIds) {
    const response = await axios.get(`/api/users/roles`, {
      params: {
        roleIds,
      },
      paramsSerializer: qs.stringify,
    });

    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  async getRolesUsersToAdd(roleIds, skip, top, orderByParams, filter) {
    const config = {
      params: {
        id: roleIds,
        skip: skip,
        top: top,
      },
      paramsSerializer: qs.stringify,
    };

    if (orderByParams) {
      config.params.orderBy = orderByParams;
    }

    if (!isEmpty(filter) && isString(filter)) {
      config.params.filter = filter;
    } else {
      config.params = { ...config.params, ...filter };
    }

    return axios.get(`/api/roles/available-users`, {
      ...config,
      paramsSerializer: qs.stringify,
    });
  },

  async getRolesUsersToAddV2(roleIds, skip, top, orderByParams, filter, search) {
    const [sortBy, sortOrder] = orderByParams?.split(" ") || [undefined, undefined];
    return axios.get(`/api/v2/roles/available-users`, {
      params: {
        skip,
        top,
        term: escapeTerm(search),
        sortBy,
        sortOrder,
        ...formatFiltersV2api(mapToV2Filters(filter, v2FilterMap)),
        id: roleIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },
};

export default service;
