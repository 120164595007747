import { ContentTypesEnum } from "enums";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { noop } from "lodash";
import { batch } from "react-redux";
import { setFilterOptions, setError, setIsLoading } from "../TemplateContentFiltersSlice";
import { getFilterOptions as getVideoFilters } from "features/Library/Videos/state/thunks/videoFiltersThunk";
import { getFilterOptions as getAssessmentFilters } from "features/Library/Assessments/state/thunks/assessmentFiltersThunk";
import { getFilterOptions as getFlowFilters } from "features/Library/Flows/state/thunks/flowFiltersThunk";
import { getFilterOptions as getPdfsFilters } from "features/Library/Pdfs/state/thunks/pdfFiltersThunk";
import { getFilterOptions as getSurveyFilters } from "features/Library/Surveys/state/thunks/surveyFiltersThunk";
import { getFilterOptions as getEventFilters } from "features/Library/Events/state/thunks/eventFiltersThunk";

export const fetchTemplateContentFilters = (contentType: ContentTypesEnum) => (dispatch: AppDispatch) => {
  const provider =
    <T extends (option: any, showPurchased: boolean) => Promise<void>>(action: T) =>
    (dispatch: AppDispatch) => {
      return action({ ...fetchOptionsTemplate(dispatch), showPurchased: true }, true);
    };

  const map: { [key in ContentTypesEnum]?: ReturnType<typeof provider> } = {
    [ContentTypesEnum.Videos]: provider(getVideoFilters),
    [ContentTypesEnum.Assessments]: provider(getAssessmentFilters),
    [ContentTypesEnum.PDFs]: provider(getPdfsFilters),
    [ContentTypesEnum.Surveys]: provider(getSurveyFilters),
    [ContentTypesEnum.Flows]: provider(getFlowFilters),
    [ContentTypesEnum.Events]: (dispatch: AppDispatch) => {
      return dispatch(getEventFilters(fetchOptionsTemplate(dispatch), true, true));
    },
  };

  return contentType in map ? map[contentType]!(dispatch) : noop();
};

const fetchOptionsTemplate = (dispatch: AppDispatch) => {
  return {
    begin: () => dispatch(setIsLoading(true)),
    success: (options: any) =>
      batch(() => {
        dispatch(setFilterOptions(options));
        dispatch(setIsLoading(false));
      }),
    failure: (error: any) =>
      batch(() => {
        dispatch(setError(error));
        dispatch(setIsLoading(false));
      }),
  };
};
