import { FC, useMemo } from "react";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import { CroppedThumbnail, TextTruncate } from "../../../../../../components";
import EventTypes from "../../../../../../enums/eventTypes";
import { Tooltip } from "../../../../../../components/common/tooltip";
import styles from "./thumbnail.module.scss";

export interface Props {
  label: string;
  type: string;
  duration?: number;
  url?: string;
}

export const Thumbnail: FC<Props> = ({ type, url, duration, label }) => {
  const formattedType = useMemo(() => {
    return type === EventTypes.ExternalEvent ? "Event" : type;
  }, [type]);

  if (type === "Email" || type === "Message") {
    return <CommonThumbnail type={formattedType} title={label} />;
  }

  return (
    <CroppedThumbnail thumbnailUrl={url}>
      <div className="item-type" data-testid="context-items-row-type">
        {formattedType}
      </div>
      {Number.isInteger(duration) && (
        <div className="item-duration">{dateTimeUtils.durationSecondsToTime(duration!)}</div>
      )}
      <div className="title">
        <Tooltip className="container" content={label} target={<TextTruncate lines={2}>{label}</TextTruncate>} />
      </div>
    </CroppedThumbnail>
  );
};

interface CommonThumbnailProps {
  type: string;
  title: string;
}

const iconClassNames: Record<string, string> = {
  Email: "huge icon fa-envelope",
  Message: "huge icon fa-message",
};

function CommonThumbnail({ type, title }: CommonThumbnailProps) {
  return (
    <div className={styles["email-thumbnail"]}>
      <div className={styles["asset-type"]} data-testid="context-items-row-type">
        {type}
      </div>
      <i className={iconClassNames[type]}></i>
      <div className={styles.title}>
        <Tooltip className="container" content={title} target={<TextTruncate lines={2}>{title}</TextTruncate>} />
      </div>
    </div>
  );
}
