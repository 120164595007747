import { ComponentType } from "react";
import FilterSidebar from "../filterSidebar/FilterSidebar";
import { FiltersMap, GenericFiltersMap } from "../../utils/filterUtils";
import { EntityType } from "../../features/Library/Flows/Designer/types";

export type ContentFilterSidebarProps<T extends number | string> = {
  showFilter: boolean;
  setShowFilter: (value: boolean) => void;
  filterOptions: GenericFiltersMap<T>;
  appliedFilter: GenericFiltersMap<T>;
  isLoading: boolean;
  applyFilter: (filter: FiltersMap) => void;
  resetAppliedFilter: () => void;
  getFilters: () => void;
  resetItems: () => void;
  entityTypeSelect: EntityType;
  filterFormsMap: { [key in EntityType]?: ComponentType<any> };
};

export type ContentFilterSidebarPropsAll<T extends number | string> = ContentFilterSidebarProps<T>;

export const ContentFilterSidebar = <T extends number | string>(props: ContentFilterSidebarPropsAll<T>) => {
  const { entityTypeSelect, resetItems, filterFormsMap } = props;

  const getFilterForm = () => {
    const Filters = filterFormsMap[entityTypeSelect];
    return Filters ? <Filters /> : undefined;
  };

  const onFilterChangedHandler =
    (action: (...args: any[]) => void) =>
    (...args: any[]) => {
      resetItems();
      action(...args);
    };

  const {
    showFilter,
    filterOptions,
    appliedFilter,
    isLoading,
    setShowFilter,
    getFilters,
    applyFilter,
    resetAppliedFilter,
  } = props;

  return (
    <FilterSidebar
      className={"flow-content-view__filter-sidebar"}
      visible={showFilter}
      isCompact
      filterOptions={filterOptions}
      appliedFilter={appliedFilter}
      getFilterOptions={getFilters}
      hideFilter={() => setShowFilter(false)}
      isLoading={isLoading}
      applyFilter={onFilterChangedHandler(applyFilter)}
      resetFilter={onFilterChangedHandler(resetAppliedFilter)}
    >
      {getFilterForm()}
    </FilterSidebar>
  );
};

export default ContentFilterSidebar;
