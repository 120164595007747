import {
  getActionBaseProvider,
  getActionProvider,
  makeActionCreator,
} from "../../../../../Application/actions/actionsBuilder";
import * as actionTypes from "../actionTypes/emailAssignedUsersActionTypes";
import { EmailAssignedUser } from "../../types/models";

export const setAssignedUsers = getActionProvider<EmailAssignedUser[]>(actionTypes.setEmailAssignedUsers);

export const setCount = getActionProvider<number>(actionTypes.setEmailAssignedUsersCount);
export const setAddedUsersCount = getActionProvider<number>(actionTypes.setEmailAddedUsersCount);

export const setIsLoading = getActionProvider<boolean>(actionTypes.setAreUsersLoading);
export const reset = getActionBaseProvider(actionTypes.reset);

export const begin = getActionBaseProvider(actionTypes.emailAssignedUsersFetchingBegin);
export const failure = getActionProvider<{ error: Error }>(actionTypes.emailAssignedUsersFetchingFailure);

export const success = getActionProvider<{
  items: EmailAssignedUser[];
  itemsCount: number;
}>(actionTypes.emailAssignedUsersFetchingSuccess);

export const setFilter = makeActionCreator(actionTypes.emailAssignedUsersSetFilter, "filter");

export const resetFilter = getActionBaseProvider(actionTypes.emailAssignedUsersResetFilter);
