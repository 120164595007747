import cn from "classnames";
import { PacksContextItem } from "../../../../../interfaces";
import { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { setContentPackAction } from "../../state/thunks/assignmentModalLicensingThunk";
import { RootState } from "../../../../Application/globaltypes/redux";
import { PacksContextItemsBlock } from "../packsContextBlock/PacksContextItemsBlock";
import { resolveFlowConflict as resolveFlowConflictAction } from "../../state/slices/packsContextSlice";

export interface PacksContextInfoProps {
  info: Array<PacksContextItem>;
  onIsDataValidChange: (isValid: boolean) => void;
  noDataLoaded: () => void;
  setModalStepHeader: () => void;
  fetchContentPacks: (items: PacksContextItem[]) => void;
}

export type PacksContextInfoAllProps = PacksContextInfoProps & PropsFromRedux;

export const PacksContextInfo: FC<PacksContextInfoAllProps> = ({
  info,
  isLoading,
  items,
  flowConflicts,
  flowAssignments,
  fetchContentPacks,
  isAllDataLoaded,
  onIsDataValidChange,
  setContentPack,
  noDataLoaded,
  setModalStepHeader,
  resolveFlowConflict,
  error,
}) => {
  const targetItems = items.filter((i) => i.packs.length > 1);
  const allFlowsResolved =
    flowAssignments.length > 0 &&
    Object.keys(flowConflicts).every((flowId) =>
      flowAssignments.some((assignment) => assignment.flowId === parseInt(flowId)),
    );

  useEffect(() => {
    if (Object.keys(flowConflicts).length) {
      return;
    }

    !isAllDataLoaded && fetchContentPacks(info);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const haveFlowConflicts = !!Object.keys(flowConflicts).length;

    if (targetItems.length) {
      const allPacksSelected = isAllDataLoaded && !targetItems.some((i) => !i.packId);
      onIsDataValidChange(allPacksSelected);
    } else if (haveFlowConflicts) {
      onIsDataValidChange(allFlowsResolved);
    }
  }, [targetItems, isAllDataLoaded, onIsDataValidChange, allFlowsResolved, flowConflicts]);

  useEffect(() => {
    isAllDataLoaded && targetItems.length === 0 && Object.keys(flowConflicts).length === 0 && noDataLoaded();
  }, [targetItems, isAllDataLoaded, noDataLoaded, flowConflicts]);

  useEffect(() => {
    isAllDataLoaded && setModalStepHeader();
  }, [isAllDataLoaded, setModalStepHeader]);

  if (error) {
    return <div data-testid="error-info">Error loading data!</div>;
  }

  return (
    <div id="pack-context-info" className={cn("pack-context-info")} data-testid="pack-context-info">
      <PacksContextItemsBlock
        isLoading={isLoading}
        items={targetItems}
        setPack={setContentPack}
        info={info}
        flowConflicts={flowConflicts}
        flowAssignments={flowAssignments}
        resolveFlowConflict={resolveFlowConflict}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.licensing?.contentPacks?.items ?? [],
    flowConflicts: state.licensing.contentPacks.flowConflicts,
    flowAssignments: state.licensing.contentPacks.flowAssignments,
    isLoading: state.licensing?.contentPacks?.isLoading ?? false,
    error: state.licensing?.contentPacks?.error,
    isAllDataLoaded: state.licensing?.contentPacks?.isAllDataLoaded ?? false,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
  resolveFlowConflict: bindActionCreators(resolveFlowConflictAction, dispatch),
  setContentPack: bindActionCreators(setContentPackAction, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PacksContextInfo);
