import { WizardNew, Section } from "components/wizard";
import { ExistingAccount } from "../Configuration/ExistingAccount";
import { useWizardStepsManager } from "hooks/useWizardStepsManager";
import CreateAccountTemplates from "./Templates/CreateAccountTemplates";
import { useNavigate, useResolvedPath } from "react-router-dom";
import {
  resetAppliedFilter as resetTemplatesFilter,
  resetOverview as resetTemplates,
} from "../state/slices/availableTemplatesSlice";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { useEffect } from "react";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

interface Props extends PropsFromRedux {
  account: { name: string; id: number; logoUri: Nullable<string> };
  onRemoveName: () => void;
}

enum Pages {
  Configuration,
  Templates,
}
const pageIndexes = [Pages.Configuration, Pages.Templates];

export const ConfigureExisting = (props: Props) => {
  const { onRemoveName, account, reset } = props;
  const [wizardPages, { onNext, onPrevious, onFinish }] = useWizardStepsManager(pageIndexes);
  const rootPath = useResolvedPath("..").pathname;
  const navigate = useNavigate();
  const handleFinish = () => {
    onFinish();
    navigate(rootPath);
  };
  const templateFlag = useFeatureFlag(FeatureFlags.GroupTemplates);

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WizardNew
      id="create-account-wizard"
      className="create-account"
      title="Create Account"
      finishButtonLabel="Finish"
      isSaveInProgress={false}
      onCancel={() => navigate(rootPath)}
      onFinish={handleFinish}
      onProgress={onNext}
      onRegress={onPrevious}
    >
      <Section label="Configuration" required isLocked={!templateFlag}>
        <ExistingAccount {...account} acceptHandlers={wizardPages[Pages.Configuration]} onRemoved={onRemoveName} />
      </Section>
      {templateFlag && (
        <Section label="Templates">
          <CreateAccountTemplates acceptHandlers={wizardPages[Pages.Templates]} accountId={account.id} />
        </Section>
      )}
    </WizardNew>
  );
};

/* istanbul ignore next */
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    reset: () => {
      dispatch(resetTemplatesFilter());
      dispatch(resetTemplates());
    },
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConfigureExisting);
