import { get } from "lodash";
import { batch } from "react-redux";
import validationSchemas from "../../../../../../../utils/validationSchemas";
import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";
import { setErrorMode, setResolved, setActiveErrors, reset } from "../slices/sendingProfileSettingsValidationSlice";
import { publishDraftThreatDefenceSmtpAuthSendingProfileEntity } from "../actions/threatDefenceSmtpAuthSendingProfileEntityStateActions";
import { getAllErrorsLeafs } from "../../../../../Common/utils/validationUtils";

const getValidationState = (getState: () => RootState) =>
  getState().library.threatDefenceSmtpAuthSendingProfiles.settingsValidation;

const turnErrorMode = (on: boolean) => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(setErrorMode(on));
    dispatch(setResolved(!on));
  });
};

export const tryPublish = (id: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const sendingProfile =
    getState().library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileDetailsReducer;
  const sendingProfileValidation = getValidationState(getState);

  if (sendingProfileValidation.initialized && !sendingProfileValidation.isFormValid) {
    dispatch(turnErrorMode(true));
    return;
  }

  if (!sendingProfileValidation.initialized) {
    try {
      await validationSchemas.smtpAuthSendingProfileSettings.validate(sendingProfile.settings, { abortEarly: true });
    } catch {
      dispatch(turnErrorMode(true));
      return;
    }
  }

  if (!sendingProfile.verification.isVerified) {
    dispatch(setErrorMode(true));
    return;
  }

  batch(() => {
    dispatch(publishDraftThreatDefenceSmtpAuthSendingProfileEntity(id));
    dispatch(reset());
  });
};

export const setActiveErrorsAction = (errors: object) => (dispatch: AppDispatch) => {
  let activeErrors: { [key: string]: string } = {};
  getAllErrorsLeafs(errors, (key, value) => {
    activeErrors[key] = value;
  });

  batch(() => {
    dispatch(setActiveErrors(activeErrors));
  });
};

export const checkForResolvedErrors = (errors: object) => (dispatch: AppDispatch, getState: () => RootState) => {
  const sendingProfileValidation = getValidationState(getState);

  for (let item in sendingProfileValidation.activeErrors) {
    if (get(errors, item)) {
      return;
    }
  }

  batch(() => {
    dispatch(setResolved(true));
    dispatch(setActiveErrors({}));
  });
};
