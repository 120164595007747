import { RootState, AppDispatch } from "features/Application/globaltypes/redux";
import { IWizardStep, bindAction } from "interfaces";
import React, { useEffect, useRef } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useEventCallback } from "hooks/useEventCallback";
import Configuration, { ConfigurationRef } from "../Details/Configuration";
import { saveTemplate } from "../state/thunks/TemplateThunk";

interface Props extends IWizardStep {
  onIsDirtyChange: (isDirty: boolean) => void;
  isDirty: boolean;
}

type AllProps = Props & PropsFromRedux;

export const CreateConfiguration = (props: AllProps) => {
  const { isDirty } = props;
  const formRef = useRef<ConfigurationRef>(null);

  const saveConfiguration = useEventCallback(() => {
    if (!isDirty) return;

    props.saveTemplate(props.template.groupId, formRef.current!.getValues());
  }, [isDirty]);

  useEffect(() => {
    const cb = saveConfiguration;

    props.acceptHandlers?.({
      onNext: cb,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Configuration
      ref={formRef}
      onIsValidChange={props.onIsValidChange}
      onIsDirtyChange={props.onIsDirtyChange}
      isLoadingGroupInfo={props.template.isLoading}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { groupTemplate } = state.people;

  return {
    template: groupTemplate.base, // populate from 'View' component
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    saveTemplate: bindAction(saveTemplate, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateConfiguration);
