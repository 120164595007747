import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as searchActionTypes from "../../../../Application/actions/search/searchActionTypes";

export const PEOPLE_USERS_OVERVIEW_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Users_Overview,
  searchActionTypes.SET_SEARCH,
);
export const PEOPLE_USERS_OVERVIEW_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Users_Overview,
  searchActionTypes.RESET_SEARCH,
);
