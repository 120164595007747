import { isEmpty } from "lodash";
import { Table } from "semantic-ui-react";
import { SortingDirection, ViewType } from "../../../../../../enums";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import OwnerInfoCell from "../OwnerInfoCell/OwnerInfoCell";
import NoResults from "../../../../../../components/noResults/NoResults";
import NoSearchResults from "../../../../../../components/noSearchResults";
import { UsersFilterForm } from "../../../../../../components/filterForms";
import { SearchInput, TextTruncate } from "../../../../../../components";
import { ItemsView } from "../../../../../../views";
import { ColumnOptions } from "../../../../../../interfaces";
import { Filters } from "../../../../../../utils/queryUtils";
import { IOwnerItemModel } from "../../../../types";
import { AssignUsersToRoles } from "../../../../../Application/services/realTimeNotification/events/people/peopleEvents";

import "./groupOwnersList.scss";
import { Tooltip } from "components/common/tooltip";

const commonColumnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 4,
    isSortable: true,
  },
  {
    name: "Department",
    width: 4,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 4,
    isSortable: true,
  },
];

const columnOptions: ColumnOptions[] = [
  ...commonColumnOptions,
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
];

export interface GroupOwnersListProps {
  owners: IOwnerItemModel[];
  ownersCount: number;
  loadPage: (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    filterParams: Filters,
    currentUser: string,
  ) => void;
  appliedOwnerFilter: Filters;
  filterOptions: Filters;
  setOwnerFilter: (filter: Filters) => void;
  resetOwnerFilter: () => void;
  isLoading: boolean;
  updateSelectedItems: (ids: number[]) => void;
  selectedItemIds: number[];
  getFilterOptions: () => void;
  markAllAsOwners?: boolean;
  onNavigate?: (id: number) => void;
  search?: string;
  onSearchChanged?: (search: string) => void;
  setReloadListItems?: (reloadItems: (enableSorting: boolean) => void) => void;
  disableSearch?: boolean;
  disableSelect?: () => boolean;
}

const GroupOwnersList = (props: GroupOwnersListProps) => {
  const defaultSortingColumnName = "Added";

  const buildTableBody = (user: IOwnerItemModel) => {
    const { markAllAsOwners, selectedItemIds, onNavigate } = props;
    return (
      <>
        <Table.Cell width={columnOptions[0].width}>
          <OwnerInfoCell
            user={user}
            isSelected={markAllAsOwners || selectedItemIds.includes(user.id)}
            onNavigate={onNavigate}
          />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <Tooltip target={<TextTruncate>{user.department}</TextTruncate>} content={user.department} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>
          <Tooltip target={<TextTruncate>{user.title}</TextTruncate>} content={user.title} />
        </Table.Cell>
        <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(user.createDate)}</Table.Cell>
      </>
    );
  };

  const noResultsContent = () => {
    return !!props.search || !isEmpty(props.appliedOwnerFilter) ? (
      <NoSearchResults />
    ) : (
      <NoResults title="You don’t have any owners" iconClassName="fal fa-user" />
    );
  };

  // @ts-ignore
  const getFilterForm = () => <UsersFilterForm />;

  const getSearchInput = () => (
    <SearchInput
      placeholder="Search for People..."
      defaultValue={props.search}
      onChange={props.onSearchChanged}
      disabled={props.disableSearch}
    />
  );

  return (
    <ItemsView
      className="owners-selection-list"
      viewType={ViewType.LIST}
      columnOptions={columnOptions}
      getData={props.loadPage}
      itemsInlineCount={props.ownersCount}
      isLoading={props.isLoading}
      items={props.owners}
      buildTableBody={(user: any) => buildTableBody(user)}
      onSelectedListItemsChanged={props.updateSelectedItems}
      renderFilterForm={getFilterForm}
      filterOptionsLoading={props.filterOptions.isLoading}
      appliedFilter={props.appliedOwnerFilter}
      filterOptions={props.filterOptions}
      getFilterOptions={props.getFilterOptions}
      applyFilter={props.setOwnerFilter}
      resetFilter={props.resetOwnerFilter}
      sortingColumnName={defaultSortingColumnName}
      sortingDirection={SortingDirection.Descending}
      noResultsContent={noResultsContent()}
      selectedIds={props.selectedItemIds}
      renderSearch={getSearchInput}
      setReloadListItems={props.setReloadListItems}
      listViewRtnEvents={[AssignUsersToRoles]}
      isSelectDisabled={props.disableSelect}
    />
  );
};
export default GroupOwnersList;
