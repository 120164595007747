import { SliceCaseReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortingDirection } from "../../../enums";
import { GenericFiltersMap } from "../../../utils/filterUtils";
import { CreateSlicePayload, getPrefix } from "./models";

export interface FiltersState<T extends number | string> {
  filterOptions: GenericFiltersMap<T>;
  appliedFilter: GenericFiltersMap<T>;
  search?: string;
  pagination?: number;
  sortingDirection?: SortingDirection;
  sortingColumnName?: string;
  isLoading: boolean;
  error?: Error;
  shouldSort?: boolean;
}

export const createFiltersSlice = <
  T extends number | string,
  Reducers extends SliceCaseReducers<TFiltersState>,
  TFiltersState extends FiltersState<T> = FiltersState<T>,
>({
  namePayload,
  initialState,
  reducers,
}: CreateSlicePayload<TFiltersState, Reducers>) => {
  return createSlice({
    name: getPrefix(namePayload),
    initialState,
    reducers: {
      setAppliedFilter(state: TFiltersState, action: PayloadAction<GenericFiltersMap<T>>) {
        state.appliedFilter = action.payload;
      },
      resetAppliedFilter(state: TFiltersState) {
        state.appliedFilter = {} as GenericFiltersMap<T>;
      },
      setIsLoading(state, action: PayloadAction<boolean>) {
        state.isLoading = action.payload;
      },
      setFilterOptions(state: TFiltersState, action: PayloadAction<GenericFiltersMap<T>>) {
        state.filterOptions = action.payload;
      },
      setError(state, action: PayloadAction<Error>) {
        state.error = action.payload;
      },
      resetFilter() {
        return initialState;
      },
      setSearch(state, action: PayloadAction<string>) {
        state.search = action.payload;
      },
      setPagination(state, action: PayloadAction<number>) {
        state.pagination = action.payload;
      },
      resetPagination(state) {
        state.pagination = initialState.pagination;
      },
      setShouldSort(state, action: PayloadAction<boolean>) {
        state.shouldSort = action.payload;
      },
      resetShouldSort(state) {
        state.shouldSort = initialState.shouldSort;
      },
      setSortingDirection(state, action: PayloadAction<SortingDirection>) {
        state.sortingDirection = action.payload;
      },
      resetSortingDirection(state) {
        state.sortingDirection = initialState.sortingDirection;
      },
      setSortingColumnName(state, action: PayloadAction<string>) {
        state.sortingColumnName = action.payload;
      },
      resetSortingColumnName(state) {
        state.sortingColumnName = initialState.sortingColumnName;
      },
      resetSearch(state) {
        state.search = "";
      },
      ...reducers,
    },
  });
};
