import AssetTypes from "../../../../../enums/assetTypes";

export const START_OF_THE_FLOW_ELEMENT_ID = "start-of-the-flow-element";
export const PLACEHOLDER_ELEMENT_ID = "placeholder-element";
export const SECTION_HEADER_HEIGHT = 80;

export const ELEMENT_WIDTH = 322;
export const ELEMENT_HEIGHT = 116;
export const ELEMENT_HALF_WIDTH = ELEMENT_WIDTH / 2;
export const ELEMENT_HALF_HEIGHT = ELEMENT_HEIGHT / 2;

// Cursor position on dragging element in pixels (when you drag new item from inspector)
export const DRAG_SHIFT = 50;

export const defaultTitle = "Default Title";
export const defaultPublisher = "PUBLISHER";
export const defaultType = AssetTypes.Video;
export const defaultDuration = 0;
export const defaultThumbnailUrl = null;
export const defaultSectionHeader = {
  name: "",
  description: "",
};

export const defaultFlowEndInfo = {
  completedMessage: "",
};
export const DEFAULT_NODE_POSITION = 20;
export const NODE_INTERVAL = 32;
export const DEFAULT_HANDLER_ID = "default_handle_id";

export const OTHER_ANSWER = {
  id: DEFAULT_HANDLER_ID,
  answerText: "Other",
};

export const PLACEHOLDER_NODES_NUMBER = 2;
export const PANZOOM_IGNORE_CLASSNAME = "panzoom-exclude";
