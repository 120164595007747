import { EmailTemplateFiltersEnum } from "../../../features/Library/SimulatedPhishing/EmailTemplates/types/models";
import { FilterFormBuilder, FilterItemBase, FilterViewType } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterFormPropsBase } from "../types/props";

export interface EmailTemplatesFilterFormProps extends FilterFormPropsBase<EmailTemplateFiltersEnum> {}

const EmailTemplatesFilterForm: React.FC<EmailTemplatesFilterFormProps> = ({ filterOptions, filter, updateFilter }) => {
  const filters: FilterItemBase<EmailTemplateFiltersEnum>[] = [
    {
      type: FilterViewType.DateRange,
      label: "Date Added",
      propertyName: EmailTemplateFiltersEnum.DateCreated,
    },
    {
      type: FilterViewType.DateRange,
      label: "Last Modified",
      propertyName: EmailTemplateFiltersEnum.DateModified,
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Software Applications",
      propertyName: EmailTemplateFiltersEnum.SoftwareApplications,
      placeholder: "All Applications",
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Labels",
      propertyName: EmailTemplateFiltersEnum.Labels,
      placeholder: "All Labels",
    },
  ];

  return (
    <FilterFormBuilder filterOptions={filterOptions} filter={filter} updateFilter={updateFilter} items={filters} />
  );
};

export default EmailTemplatesFilterForm;
