import { Filter, operators, FiltersMap, FilterOption } from "./filterUtils";
import { RolePermissions, SortingDirection } from "../enums";
import { ContentType } from "../features/Library/PeopleAssignments/types";

export const defaultGroupFilterParameters: Array<FilterOption> = [
  {
    key: "IsAware",
    operator: operators.equal,
  },
  {
    key: "IsOpen",
    operator: operators.equal,
  },
  {
    key: "IsVisibleToAll",
    operator: operators.equal,
  },
  {
    key: "IsWithAutoEnroll",
    operator: operators.equal,
  },
  {
    key: "DateCreated",
    operator: operators.range,
  },
  {
    key: "IsTemplate",
    operator: operators.equal,
  },
];

export const groupFilterParametersWithOwners: Array<FilterOption> = [
  ...defaultGroupFilterParameters,
  {
    key: "owners",
    settings: {
      collectionName: "Owners",
      propName: "UserId",
    },
    operator: operators.any,
  },
];

const utils = {
  formatOrderParams: (sortingColumnName: string, sortingDirection: SortingDirection) => {
    let columnName = sortingColumnName.toLowerCase();
    let orderParams;

    switch (columnName) {
      case "collections":
        orderParams = `CollectionsCount ${sortingDirection}`;
        break;
      case "name":
        orderParams = `Name ${sortingDirection}`;
        break;
      case "members":
        orderParams = `MembersCount ${sortingDirection}`;
        break;
      case "flows":
        orderParams = `FlowsCount ${sortingDirection}`;
        break;
      case "added":
        orderParams = `DateCreated ${sortingDirection}`;
        break;
      default:
        orderParams = `${columnName} ${sortingDirection}`;
    }

    return orderParams;
  },
  formatFilterParams: (appliedFilter: FiltersMap, params?: Array<FilterOption>) => {
    let filter = new Filter(params ?? defaultGroupFilterParameters);
    return {
      filter: filter.buildFilterQuery(appliedFilter),
      owners: appliedFilter.owners,
    };
  },
  getCanEditContent: (
    groupContent: Record<
      ContentType,
      {
        ids: number[];
        undeletableIds: number[];
      }
    >,
    userPermissions: RolePermissions[],
  ) => {
    return (
      (!groupContent.flow.ids.length || userPermissions.includes(RolePermissions.FlowsManage)) &&
      ((!groupContent.video.ids.length &&
        !groupContent.assessment.ids.length &&
        !groupContent.survey.ids.length &&
        !groupContent.pdf.ids.length) ||
        userPermissions.includes(RolePermissions.AssetsManage)) &&
      (!groupContent.externalEvent.ids.length || userPermissions.includes(RolePermissions.EventsManage))
    );
  },
};

export default utils;
