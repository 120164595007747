import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { List, Checkbox, Icon, Loader, Input } from "semantic-ui-react";
import { TextDotDotDot } from "../../../components/textTruncators/TextTruncators";
import cn from "classnames";
import globalEvents from "../../../utils/globalEvents";
import MultipleItems from "../../multipleItems/MultipleItems";
import { sortBy, invoke, intersectionBy, isEqual, xorWith, difference } from "lodash";

import "./checklistDropdown.scss";

class ChecklistDropdown extends Component {
  ref = createRef();

  state = {
    isOpen: false,
    items: this.props.items,
    value: this.props.value || "",
    checkedItems: this.getInitialCheckedItems(this.props.items, this.props.initialCheckedItems),
  };

  componentDidUpdate(prevProps) {
    const { initialCheckedItems, items } = this.props;

    if (xorWith(prevProps.items, items, isEqual).length > 0) {
      this.setState({
        items,
      });
    }
    if (!isEqual(prevProps.initialCheckedItems, initialCheckedItems) || !isEqual(prevProps.items, items)) {
      this.setState({
        checkedItems: this.getInitialCheckedItems(this.props.items, initialCheckedItems),
      });
    }
  }

  componentWillUnmount() {
    globalEvents.unsubscribe(this.globalClickListenerId);
  }

  getInitialCheckedItems(items, initialCheckedItems) {
    return intersectionBy(items, initialCheckedItems, (i) => i.id);
  }

  toggle = () => {
    if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  open = () => {
    this.globalClickListenerId = globalEvents.subscribeToOutsideClick(this.ref.current, this.close);
    this.setState({ isOpen: true });
    this.setOrderedItems();
  };

  close = () => {
    globalEvents.unsubscribe(this.globalClickListenerId);
    this.setState({ isOpen: false });
    this.clearSearchInput();
  };

  handleBlur = (event) => {
    const { currentTarget } = event;
    if (currentTarget && currentTarget.contains(document.activeElement)) {
      return;
    }
  };

  searchItem = (_, { value }) => {
    const items = this.getOrderedItems(this.props.items, this.state.checkedItems);
    const newItems = items.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));

    this.setState({ items: newItems, value });
  };

  clearSearchInput = () => {
    const orderedItems = this.getOrderedItems(this.props.items, this.state.checkedItems);
    this.setState({ items: orderedItems, value: "" });
  };

  isClearable = () => {
    return this.state.value.length !== 0;
  };

  checkItem = (item) => {
    const checkedItems = [...this.state.checkedItems];
    const checkedItemIndex = checkedItems.findIndex((ci) => ci.id === item.id);

    if (checkedItemIndex >= 0) {
      checkedItems.splice(checkedItemIndex, 1);
    } else {
      checkedItems.push(item);
    }

    this.setState({ checkedItems });

    invoke(this.props, "onCheckedItemsChanged", checkedItems);
  };

  renderInputContent() {
    const { placeholder } = this.props;
    const { checkedItems } = this.state;

    if (checkedItems.length > 0) {
      const checkedNames = checkedItems.map((item) => item.name);

      return <MultipleItems className="input-content" items={checkedNames} singleLine />;
    }

    if (placeholder) {
      return <span className="placeholder">{placeholder}</span>;
    }

    return null;
  }

  renderDescription(item) {
    if (item.description) {
      // TextTruncate with lines={2} is not working correct here
      return (
        <TextDotDotDot clamp={2} className="item-description text-muted">
          {item.description}
        </TextDotDotDot>
      );
    }

    return null;
  }

  renderInputIcon = (isLoading) => {
    return isLoading ? <Loader active size="mini" /> : <Icon className="expander" />;
  };

  renderChecklist() {
    const { isOpen, items, value } = this.state;

    if (isOpen) {
      return (
        <List selection className="checklist">
          <Input
            onChange={this.searchItem}
            autoFocus={true}
            icon="search"
            iconPosition="left"
            action={{
              icon: this.isClearable() ? <Icon disabled className="times" /> : undefined,
              onClick: this.clearSearchInput,
            }}
            value={value}
            fluid
          />
          {items.map((item) => (
            <List.Item key={item.id}>
              <List.Content>
                <Checkbox
                  label={item.name}
                  onChange={() => this.checkItem(item)}
                  checked={this.state.checkedItems.some((i) => i.id === item.id)}
                />
                {this.renderDescription(item)}
              </List.Content>
            </List.Item>
          ))}
        </List>
      );
    }

    return null;
  }

  sortByName(filteredUncheckedItems) {
    return sortBy(filteredUncheckedItems, (item) => item.name.toLowerCase());
  }

  getOrderedItems(items, checkedItems) {
    const filteredUncheckedItems = difference(items, checkedItems);
    const sortedUncheckedItems = this.sortByName(filteredUncheckedItems);

    return this.sortByName(checkedItems).concat(sortedUncheckedItems);
  }

  setOrderedItems() {
    this.setState({
      items: this.getOrderedItems(this.state.items, this.state.checkedItems),
    });
  }

  render() {
    const { isLoading, isReadOnly } = this.props;

    return (
      <div
        ref={this.ref}
        className={cn("checklist-dropdown", {
          disabled: isLoading || isReadOnly,
        })}
        tabIndex="1"
        onBlur={this.handleBlur}
      >
        <div className={cn("checklist-input", { open: this.state.isOpen })} onClick={this.toggle}>
          {this.renderInputContent()}
          {this.renderInputIcon(isLoading)}
        </div>
        {this.renderChecklist()}
      </div>
    );
  }
}

ChecklistDropdown.defaultProps = {
  items: [],
  initialCheckedItems: [],
  placeholder: "Select items",
  isLoading: false,
  isReadOnly: false,
};

ChecklistDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
  initialCheckedItems: PropTypes.array,
  placeholder: PropTypes.string,
  onCheckedItemsChanged: PropTypes.func,
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default ChecklistDropdown;
