import React from "react";
import "./alignmentHelpers.scss";
import { AlignedWith } from "../types";
import { XYCoord } from "react-dnd";
import { useReactFlow } from "reactflow";
import { ELEMENT_HALF_HEIGHT, ELEMENT_HALF_WIDTH, ELEMENT_HEIGHT, ELEMENT_WIDTH } from "../../constants";
import { Viewport } from "@reactflow/core/dist/esm/types/general";

interface Props {
  leftAligned: AlignedWith | null;
  middleVerticalAligned: AlignedWith | null;
  rightAligned: AlignedWith | null;
  topAligned: AlignedWith | null;
  middleHorizontalAligned: AlignedWith | null;
  bottomAligned: AlignedWith | null;
  nodePosition?: XYCoord;
  containerBounds?: XYCoord;
}

interface IStyleOptions {
  nodePosition?: XYCoord;
  containerBounds?: XYCoord;
  viewport: Viewport;
  shift: number;
}

const getDelta = (axis: "x" | "y"): number => (axis === "x" ? ELEMENT_HEIGHT : ELEMENT_WIDTH);

export const getDeltaByAxis = (alignedOptions: AlignedWith | null, axis: "x" | "y", options: IStyleOptions): number => {
  if (!alignedOptions) {
    return 0;
  }

  if (axis === "x") {
    return options.shift > ELEMENT_HALF_WIDTH && alignedOptions.distance > 0 ? 10 : 0;
  } else {
    return options.shift === 0 && alignedOptions.distance < 0 ? 10 : 0;
  }
};

export const getBaseStyles = (alignedOptions: AlignedWith | null, axis: "x" | "y", delta: number) => {
  if (!alignedOptions) {
    return {};
  }

  return alignedOptions.distance > 0
    ? {
        [axis === "x" ? "height" : "width"]: `${alignedOptions.distance + delta}px`,
        [axis === "x" ? "top" : "left"]: "0px",
      }
    : {
        [axis === "x" ? "height" : "width"]: `${-alignedOptions.distance + delta}px`,
        [axis === "x" ? "top" : "left"]: `${alignedOptions.distance}px`,
      };
};

export const getStyles = (alignedOptions: AlignedWith | null, axis: "x" | "y", options: IStyleOptions) => {
  if (!alignedOptions) {
    return {};
  }

  const delta = getDelta(axis);
  const deltaByAxis = getDeltaByAxis(alignedOptions, axis, options);
  const styles = getBaseStyles(alignedOptions, axis, delta);

  if (options.nodePosition) {
    const { x, y } = options.nodePosition;
    const { x: containerBoundX, y: containerBoundY } = options.containerBounds || { x: 0, y: 0 };
    const { x: transformX, y: transformY, zoom: scale } = options.viewport;
    const distance = Math.abs(alignedOptions.distance);

    if (axis === "x") {
      styles.height = `${(distance + delta - deltaByAxis) * scale}px`;
      styles.left = `${(x + options.shift) * scale + transformX + containerBoundX}px`;
      styles.top = `${
        (y + deltaByAxis - (alignedOptions.distance < 0 ? -alignedOptions.distance : 0)) * scale +
        transformY +
        containerBoundY
      }px`;
    }

    if (axis === "y") {
      styles.width = `${(distance + delta - deltaByAxis) * scale}px`;
      styles.top = `${(y + options.shift) * scale + transformY + containerBoundY}px`;
      styles.left = `${
        (x - (alignedOptions.distance < 0 ? -alignedOptions.distance : 0)) * scale + transformX + containerBoundX
      }px`;
    }
  }

  return styles;
};

export const AlignmentHelpers: React.FC<Props> = ({
  leftAligned,
  middleVerticalAligned,
  rightAligned,
  topAligned,
  middleHorizontalAligned,
  nodePosition,
  containerBounds,
  bottomAligned,
}) => {
  const { getViewport } = useReactFlow();
  const viewport = getViewport();

  const leftStyles = getStyles(leftAligned, "x", { nodePosition, viewport, containerBounds, shift: 0 });
  const middleVerticalStyles = getStyles(middleVerticalAligned, "x", {
    nodePosition,
    viewport,
    containerBounds,
    shift: ELEMENT_HALF_WIDTH,
  });
  const rightStyles = getStyles(rightAligned, "x", {
    nodePosition,
    viewport,
    containerBounds,
    shift: ELEMENT_WIDTH - 2,
  });

  const topStyles = getStyles(topAligned, "y", { nodePosition, viewport, containerBounds, shift: 0 });
  const middleHorizontalStyles = getStyles(middleHorizontalAligned, "y", {
    nodePosition,
    viewport,
    containerBounds,
    shift: ELEMENT_HALF_HEIGHT,
  });
  const bottomStyles = getStyles(bottomAligned, "y", {
    nodePosition,
    viewport,
    containerBounds,
    shift: ELEMENT_HEIGHT - 2,
  });

  return (
    <>
      {leftAligned && <div className="x-alignment-helper left" style={leftStyles}></div>}
      {middleVerticalAligned && <div className="x-alignment-helper middle" style={middleVerticalStyles}></div>}
      {rightAligned && <div className="x-alignment-helper right" style={rightStyles}></div>}

      {topAligned && <div className="y-alignment-helper top" style={topStyles}></div>}
      {middleHorizontalAligned && <div className="y-alignment-helper middle" style={middleHorizontalStyles}></div>}
      {bottomAligned && <div className="y-alignment-helper bottom" style={bottomStyles}></div>}
    </>
  );
};
