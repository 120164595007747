import cs from "classnames";
import { pluralize } from "utils/stringUtils";
import { PriorityLevels } from "../../../enums";
import { ContentType, PeopleType } from "../../../features/Library/PeopleAssignments/types";
import Icon from "../../../interfaces/icon";
import dateTimeUtils from "../../../utils/dateTimeUtils";

export interface CardPriorityOptions {
  peopleType: PeopleType;
  contentType: ContentType;
  priority?: PriorityLevels;
  daysToComplete?: number;
  dueDate?: Nullable<string>;
  tooltipClass: string;
  showNone: boolean;
}

const getCardPriorityIcon = (options: CardPriorityOptions): Icon | null => {
  const icon = getPriorityIcon(
    options.priority!,
    options.dueDate,
    options.daysToComplete,
    options.peopleType,
    options.showNone,
  );
  return icon
    ? {
      ...icon,
      iconSize: "big",
      tooltipPosition: "top right",
      tooltipSize: "tiny",
      tooltipClassName: cs(options.tooltipClass, "inverted"),
    }
    : null;
};

const getPriorityIcon = (
  priority: PriorityLevels,
  dueDate: string | undefined | null,
  daysToComplete: number | undefined,
  peopleType: PeopleType,
  showNone: boolean,
) => {
  switch (priority) {
    case PriorityLevels.none:
      return showNone
        ? {
          iconClassName: "priority-icon fal fa-minus-square",
          tooltipContent: "None Priority",
        }
        : null;
    case PriorityLevels.recommended:
      return {
        iconClassName: "priority-icon fal fa-arrow-square-down",
        tooltipContent: "Recommended Priority",
      };
    case PriorityLevels.important:
      return {
        iconClassName: "priority-icon fal fa-arrow-square-up",
        tooltipContent: "Important Priority",
      };
    case PriorityLevels.required:
      return {
        iconClassName: "priority-icon fal fa-exclamation-square",
        tooltipContent: getRequiredTooltipText(dueDate, daysToComplete, peopleType),
      };
  }
};

const getRequiredTooltipText = (
  dueDate: string | undefined | null,
  daysToComplete: number | undefined,
  peopleType: PeopleType,
) => {
  switch (peopleType) {
    case PeopleType.User:
      return `Required - This Flow must be completed by ${dateTimeUtils.formatDate(dueDate)}.`;
    case PeopleType.Group:
      return daysToComplete != null
        ? `Required - This Flow must be completed within ${daysToComplete} ${pluralize("day", daysToComplete)} of users being added to the group.`
        : `Required - This Flow must be completed by ${dateTimeUtils.formatDate(dueDate)}.`;
  }
};

export default getCardPriorityIcon;
