import React, { Component } from "react";
import PropTypes from "prop-types";
import { Label, Transition } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import cn from "classnames";

import { calculateAppliedFilters } from "../../../utils/filterUtils";
import { ButtonWithIcon } from "../../buttons/buttonWithIcon";

import "./filterButtons.scss";

class FilterButtons extends Component {
  appliedFiltersCount = 0;

  shouldComponentUpdate(nextProps) {
    return this.props.appliedFilter !== nextProps.appliedFilter || this.props.fluid !== nextProps.fluid;
  }

  updateAppliedFiltersCount = (appliedFilters) => {
    let newAppliedFiltersCount = calculateAppliedFilters(appliedFilters);

    if (newAppliedFiltersCount !== 0) {
      this.appliedFiltersCount = newAppliedFiltersCount;
    } else {
      newAppliedFiltersCount = this.appliedFiltersCount;
    }

    return newAppliedFiltersCount;
  };

  render() {
    const { appliedFilter, onFilterPress, onResetFilter, fluid, className, onlyIcon, onlyResetIcon, disabled } =
      this.props;
    let isFilterApplied = !isEmpty(appliedFilter);
    const resetButton = (
      <Transition visible={isFilterApplied} animation="scale" duration={200} unmountOnHide>
        <ButtonWithIcon
          id="reset-filter-button"
          basic
          className={onlyResetIcon ? "apply-filter-button-no-text" : "reset-filter-button"}
          iconName={onlyResetIcon ? "fa-undo" : "fa-redo"}
          label={onlyResetIcon ? null : "Reset"}
          onClick={onResetFilter}
        />
      </Transition>
    );

    return (
      <div className={cn("filter-buttons", className, { fluid: fluid })}>
        {onlyResetIcon && resetButton}
        <ButtonWithIcon
          blur
          id="show-filter-button"
          disabled={disabled}
          basic
          className={onlyIcon ? "apply-filter-button-no-text" : "apply-filter-button filter-button"}
          iconName="fa-filter"
          label={onlyIcon ? null : "Filters"}
          onClick={onFilterPress}
        >
          <Transition visible={isFilterApplied} animation="scale" duration={200}>
            <Label circular>{this.updateAppliedFiltersCount(appliedFilter)}</Label>
          </Transition>
        </ButtonWithIcon>
        {!onlyResetIcon && resetButton}
      </div>
    );
  }
}

FilterButtons.defaultProps = {
  appliedFilter: {},
  fluid: false,
  onlyIcon: false,
};

FilterButtons.propTypes = {
  appliedFilter: PropTypes.object,
  onFilterPress: PropTypes.func,
  onResetFilter: PropTypes.func,
  fluid: PropTypes.bool,
  onlyResetIcon: PropTypes.bool,
  className: PropTypes.string,
  onlyIcon: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FilterButtons;
