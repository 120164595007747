import React from "react";
import cn from "classnames";
import PublishStatusCard from "./../PublishStatusCard";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import { CardBase } from "../../../interfaces/CardPropsBase";

import "./eventCard.scss";

export interface EventCardProps extends CardBase {}

export const EventCard: React.FC<EventCardProps> = (props: EventCardProps): React.ReactElement => {
  const {
    item,
    className,
    selected,
    popupMenu,
    url,
    onSelect,
    disabled,
    disablePopupMenu,
    icons,
    onTitleClick,
    popupMenuButtons,
  } = props;
  const { id, dateCreated, isDraft } = item;

  return (
    <PublishStatusCard
      {...item}
      url={url}
      id={id}
      className={cn("event-grid-card", className)}
      onSelectionChanged={onSelect}
      popupMenu={popupMenu}
      published={!isDraft}
      selected={selected}
      disabled={disabled}
      disablePopupMenu={disablePopupMenu}
      icons={icons}
      onTitleClick={onTitleClick}
      popupMenuButtons={popupMenuButtons}
    >
      <div className="date-added">Added {dateTimeUtils.formatDate(dateCreated)}</div>
    </PublishStatusCard>
  );
};
