import { AppDispatch } from "../../../../../Application/globaltypes/redux";
import { batch } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { FiltersMap, GenericFiltersMap } from "../../../../../../utils/filterUtils";
import { MessageFiltersEnum } from "../../types/models";
import {
  setError as setOverviewError,
  setFilterOptions as setOverviewFilterOptions,
  setIsLoading as setOverviewIsLoading,
} from "../slices/messageFiltersSlice";

export interface GetFilterOptionsProps {
  showPurchased?: boolean;
  filters?: MessageFiltersEnum[];
}

interface BaseFilterOptionsProps {
  begin: () => void;
  success: (filterOptions: FiltersMap) => void;
  failure: (error: Error) => void;
}

export const getFilterOptions = async (props: BaseFilterOptionsProps & GetFilterOptionsProps) => {
  const { begin, success } = props;
  let filterOptions: FiltersMap = {};
  begin();
  success(filterOptions);
};

const getFilterOptionsWrapper = (
  setIsLoading: ActionCreatorWithPayload<boolean>,
  setFilterOptions: ActionCreatorWithPayload<GenericFiltersMap<string>>,
  setError: ActionCreatorWithPayload<Error>,
) => {
  return (dispatch: AppDispatch) =>
    getFilterOptions({
      showPurchased: true,
      filters: [],
      begin: () => dispatch(setIsLoading(true)),
      success: (filterOptions: FiltersMap) =>
        batch(() => {
          dispatch(setFilterOptions(filterOptions));
          dispatch(setIsLoading(false));
        }),
      failure: (error: Error) =>
        batch(() => {
          dispatch(setError(error));
          dispatch(setIsLoading(false));
        }),
    });
};

export const getOverviewFilterOptions = () =>
  getFilterOptionsWrapper(setOverviewIsLoading, setOverviewFilterOptions, setOverviewError);
