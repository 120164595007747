import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPrefix } from "../../../Application/slices/models";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import { CommunicationChannels, TemplateTypes } from "../../../../enums";

export interface TeamsForm {
  headline: string;
  body: string;
  callToAction: string;
  destinationUrl: string;
  imageUrl: string;
}

export interface NotifyStepState {
  isTemplateLoaded: boolean;
  form: {
    subject: string;
    previewText: string;
    customizeSender: boolean;
    senderName: string;
    senderEmail: string;
    defaultSenderName: string;
    defaultSenderEmail: string;
    smtpSettingsExist: boolean;
  };
  teamsForm: TeamsForm;
  updatedTemplate: string;
  updatedRawTemplate: string;
  initialTemplate: string;
  layout: string;
  isPreviewRendering: boolean;
  isPreviewRendered: boolean;
  templateType: TemplateTypes;
  templateSasUrl: string;
  rawTemplateSasUrl: string;
  emailPreview: string;
  initialShouldNotifyUsers: boolean;
  initialCommunicationChannel: CommunicationChannels;
  initialForm: {
    subject: string;
    previewText: string;
    customizeSender: boolean;
    senderName: string;
    senderEmail: string;
    defaultSenderName: string;
    defaultSenderEmail: string;
    smtpSettingsExist: boolean;
  };
  initialTeamsForm: TeamsForm;
}

export const initialState: NotifyStepState = {
  form: {
    subject: "",
    previewText: "",
    customizeSender: false,
    senderName: "",
    senderEmail: "",
    defaultSenderName: "",
    defaultSenderEmail: "",
    smtpSettingsExist: false,
  },
  teamsForm: {
    headline: "",
    imageUrl: "",
    body: "",
    callToAction: "",
    destinationUrl: "",
  },
  updatedTemplate: "",
  updatedRawTemplate: "",
  initialTemplate: "",
  layout: "",
  isTemplateLoaded: false,
  isPreviewRendering: false,
  isPreviewRendered: false,
  templateSasUrl: "",
  rawTemplateSasUrl: "",
  emailPreview: "",
  templateType: TemplateTypes.Empty,
  initialShouldNotifyUsers: false,
  initialCommunicationChannel: CommunicationChannels.Email,
  initialForm: {
    subject: "",
    previewText: "",
    customizeSender: false,
    senderName: "",
    senderEmail: "",
    defaultSenderName: "",
    defaultSenderEmail: "",
    smtpSettingsExist: false,
  },
  initialTeamsForm: {
    headline: "",
    imageUrl: "",
    body: "",
    callToAction: "",
    destinationUrl: "",
  },
};

export const notifyStepSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.SystemNotifications,
    entity: ReducerEntityPrefixTypes.NotifyStep,
    name: "notifyDetails",
  }),
  initialState: initialState,
  reducers: {
    fetchTemplateSuccess: (
      state: NotifyStepState,
      action: PayloadAction<{
        template: string;
        layout: string;
        subject: string;
        previewText: string;
        templateType: TemplateTypes;
        shouldNotifyUsers?: boolean;
        communicationChannel?: CommunicationChannels;
        templateSasUrl?: string;
        rawtemplateSasUrl?: string;
        headline?: string;
        body?: string;
        imageUrl?: string;
        destinationUrl?: string;
        callToAction?: string;
      }>,
    ) => {
      state.isTemplateLoaded = true;
      state.initialTemplate = action.payload.template;
      state.layout = action.payload.layout;
      state.templateType = action.payload.templateType;
      state.form.subject = action.payload.subject || state.form.subject;
      state.form.previewText = action.payload.previewText || state.form.previewText;
      state.initialForm.subject = action.payload.subject || state.initialForm.subject;
      state.initialForm.previewText = action.payload.previewText || state.initialForm.previewText;
      state.initialShouldNotifyUsers = action.payload.shouldNotifyUsers || state.initialShouldNotifyUsers;
      state.initialCommunicationChannel =
        (action.payload.communicationChannel as CommunicationChannels) || state.initialCommunicationChannel;
      state.templateSasUrl = action.payload.templateSasUrl || state.templateSasUrl;
      state.rawTemplateSasUrl = action.payload.rawtemplateSasUrl || state.rawTemplateSasUrl;
      state.teamsForm.headline = state.initialTeamsForm.headline = action.payload.headline || state.teamsForm.headline;
      state.teamsForm.body = state.initialTeamsForm.body = action.payload.body || state.teamsForm.body;
      state.teamsForm.imageUrl = state.initialTeamsForm.imageUrl = action.payload.imageUrl || state.teamsForm.imageUrl;
      state.teamsForm.destinationUrl = state.initialTeamsForm.destinationUrl =
        action.payload.destinationUrl || state.teamsForm.destinationUrl;
      state.teamsForm.callToAction = state.initialTeamsForm.callToAction =
        action.payload.callToAction || state.teamsForm.callToAction;
    },

    fetchTemplateFail: (state: NotifyStepState) => {
      state.isTemplateLoaded = false;
    },

    fetchSmtpSenderSettingsSuccess: (
      state: NotifyStepState,
      action: PayloadAction<{
        customizeSender: boolean;
        defaultSenderName: string;
        defaultSenderEmail: string;
        smtpSettingsExist: boolean;
      }>,
    ) => {
      state.form.defaultSenderName = action.payload.defaultSenderName || state.form.defaultSenderName;
      state.form.defaultSenderEmail = action.payload.defaultSenderEmail || state.form.defaultSenderEmail;
      state.form.smtpSettingsExist = action.payload.smtpSettingsExist || state.form.smtpSettingsExist;
      state.form.customizeSender = action.payload.customizeSender || state.form.customizeSender;
      state.initialForm.defaultSenderName = action.payload.defaultSenderName || state.form.defaultSenderName;
      state.initialForm.defaultSenderEmail = action.payload.defaultSenderEmail || state.form.defaultSenderEmail;
      state.initialForm.smtpSettingsExist = action.payload.smtpSettingsExist || state.form.smtpSettingsExist;
      state.initialForm.customizeSender = action.payload.customizeSender || state.form.customizeSender;
    },

    fetchSmtpSenderSettingsFail: (state: NotifyStepState) => {
      state.form.smtpSettingsExist = false;
    },
    fetchSmtpSenderSettingsNotExist: (state: NotifyStepState) => {
      state.form.smtpSettingsExist = false;
    },

    renderPreviewBegin: (state: NotifyStepState) => {
      state.isPreviewRendering = true;
      state.isPreviewRendered = false;
    },

    renderPreviewSuccess: (
      state: NotifyStepState,
      action: PayloadAction<{ templateSasUrl: string; rawTemplateSasUrl: string; emailPreview: string }>,
    ) => {
      state.isPreviewRendering = false;
      state.isPreviewRendered = true;
      state.templateSasUrl = action.payload.templateSasUrl;
      state.rawTemplateSasUrl = action.payload.rawTemplateSasUrl;
      state.emailPreview = action.payload.emailPreview;
    },

    renderPreviewFail: (state: NotifyStepState) => {
      state.isPreviewRendered = false;
    },

    updateForm: (
      state: NotifyStepState,
      action: PayloadAction<{
        subject: string;
        previewText: string;
        customizeSender: boolean;
        senderName: string;
        senderEmail: string;
        defaultSenderName: string;
        defaultSenderEmail: string;
        smtpSettingsExist: boolean;
      }>,
    ) => {
      const {
        subject,
        previewText,
        customizeSender,
        senderName,
        senderEmail,
        defaultSenderName,
        defaultSenderEmail,
        smtpSettingsExist,
      } = action.payload;
      state.form.subject = subject;
      state.form.previewText = previewText;
      state.form.customizeSender = customizeSender;
      state.form.senderName = customizeSender && senderName ? senderName : defaultSenderName;
      state.form.senderEmail = customizeSender && senderEmail ? senderEmail : defaultSenderEmail;
      state.form.defaultSenderName = defaultSenderName;
      state.form.defaultSenderEmail = defaultSenderEmail;
      state.form.smtpSettingsExist = smtpSettingsExist;
    },

    updateTeamsForm: (state: NotifyStepState, { payload }: PayloadAction<TeamsForm>) => {
      state.teamsForm = payload;
    },

    updateTemplate: (state: NotifyStepState, action: PayloadAction<string>) => {
      state.updatedTemplate = action.payload;
    },

    updateRawTemplate: (state: NotifyStepState, action: PayloadAction<string>) => {
      state.updatedRawTemplate = action.payload;
    },

    setInitialNotifySettings: (
      state: NotifyStepState,
      action: PayloadAction<{
        template: string;
        subject: string;
        previewText: string;
        shouldNotifyUsers: boolean;
        communicationChannel: CommunicationChannels;
      }>,
    ) => {
      state.initialTemplate = action.payload.template;
      state.initialForm.subject = action.payload.subject;
      state.initialForm.previewText = action.payload.previewText;
      state.initialShouldNotifyUsers = action.payload.shouldNotifyUsers;
      state.initialCommunicationChannel = action.payload.communicationChannel;
    },

    setTeamsInitialNotifySettings: (
      state: NotifyStepState,
      action: PayloadAction<{
        headline: string;
        body: string;
        imageUrl: string;
        callToAction: string;
        destinationUrl: string;
        shouldNotifyUsers: boolean;
        communicationChannel: CommunicationChannels;
      }>,
    ) => {
      state.initialTeamsForm.headline = action.payload.headline;
      state.initialTeamsForm.body = action.payload.body;
      state.initialTeamsForm.imageUrl = action.payload.imageUrl;
      state.initialTeamsForm.callToAction = action.payload.callToAction;
      state.initialTeamsForm.destinationUrl = action.payload.destinationUrl;
      state.initialShouldNotifyUsers = action.payload.shouldNotifyUsers;
      state.initialCommunicationChannel = action.payload.communicationChannel;
    },

    resetState: () => {
      return initialState;
    },
  },
});

export const {
  renderPreviewBegin,
  renderPreviewSuccess,
  renderPreviewFail,
  fetchTemplateFail,
  fetchTemplateSuccess,
  fetchSmtpSenderSettingsSuccess,
  fetchSmtpSenderSettingsFail,
  fetchSmtpSenderSettingsNotExist,
  updateForm,
  updateTeamsForm,
  updateTemplate,
  updateRawTemplate,
  setInitialNotifySettings,
  setTeamsInitialNotifySettings,
  resetState,
} = notifyStepSlice.actions;
