const disableMode = "disabled";

const utils = {
  getTranslatingCaption: (player) => {
    const track = player.addTextTrack("captions", "TranslatingWrapper", "tc");
    track.mode = disableMode;
    const start = player.currentTime();
    let end = start + player.remainingTime();
    end = end !== 0 && !isNaN(end) ? end : 0.001;
    const Cue = window.VTTCue || window.TextTrackCue; // IE, Edge support
    track.addCue(new Cue(start, end, "Translating..."));

    return track;
  },
};

export default utils;
