import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PropTypes } from "prop-types";

import * as sidebarActionsRedux from "./state/sidebarActions";
import * as backgroundTasksActionsRedux from "../BackgroundTasks/state/backgroundTasksActions";
import { Navigation } from "../../components/navigation";
import * as editAccountActions from "../Accounts/EditAccount/state/editAccountActions";

export class Sidebar extends Component {
  render() {
    const {
      isWizardOpened,
      collapsed,
      isCollapsedByUser,
      sidebarActions,
      notifications,
      children,
      onSignout,
      isProgressDrawerVisible,
      backgroundTasks,
      backgroundTasksActions,
      moboAccountName,
      moboAccountLogoUrl,
      accountId,
    } = this.props;

    return (
      <Navigation
        accountId={accountId}
        isWizardOpened={isWizardOpened}
        collapsed={collapsed}
        isCollapsedByUser={isCollapsedByUser}
        sidebarActions={sidebarActions}
        notifications={notifications}
        children={children}
        onSignout={onSignout}
        backgroundTasks={backgroundTasks}
        isProgressDrawerVisible={isProgressDrawerVisible}
        onDismissAllClick={backgroundTasksActions.dismissCompleted}
        showProgressDrawer={backgroundTasksActions.showProgressDrawer}
        hideProgressDrawer={backgroundTasksActions.hideProgressDrawer}
        moboAccountName={moboAccountName}
        moboAccountLogoUrl={moboAccountLogoUrl}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  accountId: state.userProfile.accountId,
  isWizardOpened: state.wizard.isWizardOpened,
  collapsed: state.sidebar.collapsed,
  isCollapsedByUser: state.sidebar.isCollapsedByUser,
  moboAccountName: state.userProfile.moboAccountName,
  moboAccountLogoUrl: state.userProfile.moboAccountLogoUrl,
  backgroundTasks: state.backgroundTasks.tasks,
  isProgressDrawerVisible: state.backgroundTasks.isProgressDrawerVisible,
});

const mapDispatchToProps = (dispatch) => ({
  editAccountActions: bindActionCreators(editAccountActions, dispatch),
  sidebarActions: bindActionCreators(sidebarActionsRedux, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActionsRedux, dispatch),
});

Sidebar.propTypes = {
  isWizardOpened: PropTypes.bool,
  collapsed: PropTypes.bool,
  isCollapsedByUser: PropTypes.bool,
  moboAccountName: PropTypes.string,
  moboAccountLogoUrl: PropTypes.string,
  sidebarActions: PropTypes.object,
  notifications: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onSignout: PropTypes.func.isRequired,
  backgroundTasks: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
