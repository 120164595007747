import { Component } from "react";
import { Button } from "components/buttons/button/Button";
import RoleListStep from "./roleAssignmentSteps/roleListStep/RoleListStep";
import ModalWithSteps from "../../modal/ModalWithSteps";
import { Filters } from "../../../utils/queryUtils";
import Role from "../../../interfaces/role";

import "./rolesAssignmentModal.scss";
import { difference, filter, some, intersection } from "lodash";
import { RolePermissions } from "../../../enums";

export interface UserRolesAssignment {
  userId: number;
  roleIds: number[];
}

interface RolesAssignmentModalProps {
  dataLoader: () => void;
  onConfirm: (roleIds: number[]) => void;
  onCancel: () => void;
  showModal: boolean;
  accountId: number;
  roles: Role[];
  rolesCount: number;
  isListLoading: boolean;
  filterOptions: Filters;
  filterActions: any;
  userRolesAssignments: UserRolesAssignment[];
  selectedUserIds: number[];
}

interface RolesAssignmentModalState {
  selectedIds: any[];
  rolesToAddAppliedFilter: Filters;
  issuingUsers: any[];
}

export default class RolesAssignmentModal extends Component<RolesAssignmentModalProps, RolesAssignmentModalState> {
  state = {
    selectedIds: [],
    rolesToAddAppliedFilter: {},
    issuingUsers: [],
  };

  componentDidUpdate(prevProps: RolesAssignmentModalProps) {
    const { showModal } = this.props;

    if (showModal !== prevProps.showModal && !this.props.showModal) {
      this.setState({ selectedIds: [] });
      this.resetFilter();
    }
  }

  componentWillUnmount() {
    this.resetFilter();
  }

  onSelectedItemsChanged = (selectedIds: number[]) => {
    const { selectedUserIds, userRolesAssignments } = this.props;
    const filteredIssuingUsers = filter(selectedUserIds, (userId) =>
      some(filter(userRolesAssignments, { userId }), (x) => some(difference(selectedIds, x.roleIds))),
    );
    this.setState({
      selectedIds,
      issuingUsers: filteredIssuingUsers,
    });
  };

  resetFilter = () => {
    this.setState({ rolesToAddAppliedFilter: {} });
  };

  applyFilter = (filterToApply: Filters) => {
    this.setState({ rolesToAddAppliedFilter: filterToApply });
  };

  renderModalActions = (_nextStep: () => void, _prevStep: () => void) => {
    const { onCancel, onConfirm } = this.props;
    const { selectedIds } = this.state;

    return (closeModal: () => void) => {
      return (
        <>
          <Button
            basic
            color="blue"
            className="cancel"
            content="Cancel"
            onClick={() => {
              closeModal();
              onCancel();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Add"
            disabled={selectedIds.length === 0}
            onClick={() => {
              onConfirm(selectedIds);
              closeModal();
            }}
          />
        </>
      );
    };
  };

  render() {
    const {
      accountId,
      showModal,
      onCancel,
      roles,
      rolesCount,
      filterOptions,
      isListLoading,
      filterActions,
      dataLoader,
      userRolesAssignments,
      selectedUserIds,
    } = this.props;

    const { selectedIds, rolesToAddAppliedFilter } = this.state;

    return (
      <ModalWithSteps
        className="roles-assignment-modal"
        scrolling
        isLoading={false}
        showModal={showModal}
        onCancel={onCancel}
      >
        <RoleListStep
          header="Add to Role"
          renderModalActions={this.renderModalActions}
          dataLoader={dataLoader}
          isLoading={isListLoading}
          roles={roles}
          rolesCount={rolesCount}
          onSelectedListItemsChanged={this.onSelectedItemsChanged}
          filterOptions={filterOptions}
          accountId={accountId}
          selectedRoleIds={selectedIds}
          appliedFilter={rolesToAddAppliedFilter}
          filterActions={filterActions}
          applyFilter={this.applyFilter}
          resetFilter={this.resetFilter}
          userRolesAssignments={userRolesAssignments}
          selectedUserIds={selectedUserIds}
          hasPermissionPredicate={(userPermissions) =>
            intersection(userPermissions, [RolePermissions.RolesView]).length > 0
          }
        />
        <></>
      </ModalWithSteps>
    );
  }
}
