import React from "react";
import { ProgressSaved } from "../../progressSaved";
import { TimestampLabel } from "../../timestampLabel";
import { PublishedStatusTypes } from "../../../enums";
import { PublishedStatusType } from "../../../enums/publishedStatusTypes";

import "./sectionSubHeader.scss";

export interface Props {
  publishedStatus: PublishedStatusType;
  lastModifiedDateTime: Date | undefined | string;
  isUpdateInProgress: boolean;
  children: React.ReactNode;
}

function SectionSubHeader(props: Props) {
  const { children, publishedStatus, lastModifiedDateTime, isUpdateInProgress } = props;

  return (
    <div className="section-sub-header">
      {children}
      {lastModifiedDateTime && (
        <ProgressSaved
          timestampLabel={
            <TimestampLabel
              label={publishedStatus === PublishedStatusTypes.published ? "Last Modified" : "Last Saved"}
              dateTime={lastModifiedDateTime}
              format="MM/DD/YYYY h:mm A"
            />
          }
          inProgress={isUpdateInProgress}
        />
      )}
    </div>
  );
}

export default SectionSubHeader;
