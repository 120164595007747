import React, { useEffect, useState } from "react";
import { RolePermissions } from "../../enums";
import usersDataService from "../../features/Application/services/dataServices/usersDataService";

export interface RestrictedToAnyAccountProps {
  permissions: RolePermissions[];
  placeholder?: React.ReactElement;
  renderContent?: (hasAnyPermissions: boolean) => React.ReactElement;
  children?: React.ReactNode;
}

const RestrictedToAnyAccount: React.FC<RestrictedToAnyAccountProps> = ({
  permissions,
  placeholder: Placeholder,
  children,
  renderContent,
}): React.ReactElement | null => {
  const renderPlaceholder = () => (Placeholder ? Placeholder : null);

  const [hasAnyPermission, setHasAnyPermission] = useState(true);

  useEffect(() => {
    const checkHasAnyPermission = async () => {
      const results = await Promise.all(permissions.map((p) => usersDataService.hasAnyAccountPermission(p)));
      const hasAny = results.some((r) => r);
      setHasAnyPermission(hasAny);
    };

    checkHasAnyPermission();
  }, [permissions]);

  if (renderContent) {
    return renderContent(hasAnyPermission);
  }

  if (hasAnyPermission) {
    return <>{children}</>;
  }

  return renderPlaceholder();
};

export default RestrictedToAnyAccount;
