import { AppDispatch, AppThunk, RootState } from "../../../../Application/globaltypes/redux";
import { EntityType } from "../../../../Library/Flows/Designer/types";
import { packContentTypeSelectSelector } from "../selectors";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/packContentTabItemsToDropSlice";
import {
  ItemsToDropPayload,
  fetchVideoItemsV2,
  fetchSurveyItemsV2,
  fetchAssessmentsItemsV2,
  fetchEventsItemsV2,
  fetchPdfsItemsV2,
  fetchFlowsItemsV2,
  fetchEmailsItemsV2,
  fetchMessagesItemsV2,
} from "../../../../../utils/fetchItemsToDrop";

export const fetchItemsToDropV2 = (payload: ItemsToDropPayload) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const map: { [key in EntityType]?: () => AppThunk } = {
      Video: () => fetchVideoItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, packContentTypeSelectSelector),
      Survey: () => fetchSurveyItemsV2(payload, fetchSuccess, fetchFailure, packContentTypeSelectSelector),
      Assessment: () =>
        fetchAssessmentsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, packContentTypeSelectSelector),
      Flow: () => fetchFlowsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure),
      Email: () => fetchEmailsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, packContentTypeSelectSelector),
      Message: () =>
        fetchMessagesItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, packContentTypeSelectSelector),
      ExternalEvent: () =>
        fetchEventsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, packContentTypeSelectSelector),
      Pdf: () => fetchPdfsItemsV2(payload, fetchBegin, fetchSuccess, fetchFailure, packContentTypeSelectSelector),
    };

    const context = packContentTypeSelectSelector(getState());

    if (!context || !map[context]) {
      return;
    } else {
      await dispatch(map[context]());
    }
  };
};
