import { Radio, Table } from "semantic-ui-react";

import Contact from "../../../../../../interfaces/contact";
import { MultipleItems, TextTruncate } from "../../../../../../components";
import MinusButton from "../../../../../../components/buttons/iconButtons/minusButton/MinusButton";
import { Tooltip } from "../../../../../../components/common/tooltip";
import UserInfoCell from "../../../../../../components/userInfoCell/UserInfoCell";
import columnOptions from "../columnOptions";

import "./ContactRow.scss";

export interface ContactRowProps {
  contact: Contact;
  isReadOnly: boolean;
  goToEditUser?: (userId: number) => void;
  onContactRemoved: (contactId: number) => void;
  onMainContactChanged: (contactId: number) => void;
  isMainContact: boolean;
}

export default function ContactRow(props: ContactRowProps) {
  const { contact, isMainContact } = props;

  const renderRemoveButton = (c: Contact) => {
    return (
      <Table.Cell className="align-right-contacts" width={columnOptions[5].width}>
        {
          <Tooltip
            position="top center"
            hideOnScroll
            showAlways
            target={
              <MinusButton
                onClick={() => props.onContactRemoved(c.id)}
                isDisabled={props.isReadOnly || isMainContact}
              />
            }
            content={
              isMainContact ? "Cannot remove main contact. Assign another main contact to remove this one." : "Remove"
            }
          />
        }
      </Table.Cell>
    );
  };

  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <Radio
          checked={isMainContact}
          disabled={props.isReadOnly}
          onClick={() => !isMainContact && props.onMainContactChanged(contact.id)}
        />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <UserInfoCell user={contact} goToEditUser={props.goToEditUser && (() => props.goToEditUser!(contact.id))} />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <Tooltip target={<TextTruncate>{contact.department}</TextTruncate>} content={contact.department} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>
        <Tooltip target={<TextTruncate>{contact.title}</TextTruncate>} content={contact.title} />
      </Table.Cell>
      <Table.Cell width={columnOptions[4].width}>
        <MultipleItems items={contact.roles.map((role) => role.name)} />
      </Table.Cell>
      {renderRemoveButton(contact)}
    </>
  );
}
