import videojs from "video.js";
const ClickableComponent = videojs.getComponent("ClickableComponent");

class QhNavigationBackMenuItem extends ClickableComponent {
  createEl(_, props, attrs) {
    const el = super.createEl(
      "div",
      Object.assign(
        {
          className: "vjs-navigation-back-menu-header",
        },
        props,
      ),
      attrs,
    );

    const chevronIcon = document.createElement("i");
    chevronIcon.className = "chevron left icon";
    el.appendChild(chevronIcon);

    const label = document.createElement("span");
    label.className = "vjs-menu-item-label";
    label.innerHTML = this.localize(this.options_.label);
    el.appendChild(label);

    return el;
  }

  handleClick() {
    if (this.options_.onSelected) {
      this.options_.onSelected(this);
    }
  }
}

export default QhNavigationBackMenuItem;
