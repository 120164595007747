import React from "react";

function childrenType(type: React.ComponentType) {
  return oneOfChildrenType([type]);
}

function oneOfChildrenType(types: React.ComponentType[]) {
  return (props: any, propName: string, componentName: string) => {
    let error;
    const prop = props[propName];
    const typesNames = types.map((t) => t.name);

    const invalidChildren = React.Children.toArray(prop).filter((child) => {
      if (typeof child === "object" && "type" in child && typeof child.type === "function") {
        return typesNames.indexOf(child.type.name) === -1;
      }
      return true;
    });

    if (invalidChildren.length > 0) {
      const separator = ", ";
      const allTypesNames = typesNames.join(separator);
      const invalidChildrenNames = invalidChildren
        .map((c) => {
          return typeof c === "object" && "type" in c && typeof c.type === "function" ? c.type.name : c;
        })
        .join(separator);

      error = new Error(
        `Invalid children type(s) \`${invalidChildrenNames}\`. \`${componentName}\` only accepts children of type(s) \`${allTypesNames}\`.`,
      );
    }

    return error;
  };
}

const utils = {
  childrenType,
  oneOfChildrenType,
};

export default utils;
