import { ReactComponent as NoSearchResultsIcon } from "../../images/no-results-search-icon-big.svg";
import NoResults from "../noResults/NoResults";

const NoSearchResults: React.FC = () => (
  <NoResults
    title="No results found"
    description="Your search returned no results. Expand your criteria and try again."
    icon={<NoSearchResultsIcon />}
  />
);

export default NoSearchResults;
