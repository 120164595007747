import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { getAxiosError } from "../../../../utils/axiosError";
import { AppDispatch } from "../../../Application/globaltypes/redux";
import DataService from "../../../Application/services/dataServices/typedDataService";
import {
  reqOwnerPacksLicensing,
  setOwnerPacksLicensing,
  setOwnerPacksLicensingError,
  reqPackLicenses,
  setPackLicenses,
  setPackLicensesError,
  setCustomerPacksError,
  setCustomerPacks,
  setCustomerCards,
  setCustomerCardsError,
  reqCustomerPacks,
  reqCustomerCards,
} from "./packSlice";

export const fetchOwnerPacksLicensing = (customerId: number, filter?: PerformanceFilter) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqOwnerPacksLicensing());
    try {
      const { data } = await DataService.reportingService.getOwnerPacksLicensing(customerId, filter);
      dispatch(setOwnerPacksLicensing({ data }));
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(setOwnerPacksLicensingError({ errorMessage }));
    }
  };
};

export const fetchPackLicenses = (packId: number, customerId?: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqPackLicenses());
    try {
      const { data } = await DataService.reportingService.getPackLicenses(packId, customerId);
      dispatch(setPackLicenses({ data }));
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(setPackLicensesError({ errorMessage }));
    }
  };
};

export const fetchCustomerPacks = (customerId: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqCustomerPacks());
    try {
      const { data } = await DataService.reportingService.getCustomerPacks(customerId);
      dispatch(setCustomerPacks({ data }));
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(setCustomerPacksError({ errorMessage }));
    }
  };
};

export const fetchCustomerCards = (customerId: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqCustomerCards());
    try {
      const { data } = await DataService.reportingService.getCustomerCards(customerId);
      dispatch(setCustomerCards({ data }));
    } catch (err) {
      const errorMessage = getAxiosError(err);
      dispatch(setCustomerCardsError({ errorMessage }));
    }
  };
};
