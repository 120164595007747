import { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useFormik } from "formik";
import { noop } from "lodash";

import { bindAction } from "../../../../../interfaces/redux";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { IWizardStep } from "../../../../../interfaces/IWizardStepsManager";

import SettingsForm from "./SettingsForm/SettingsForm";
import Restricted from "../../../../Application/Restricted";
import { RolePermissions } from "../../../../../enums";
import { fetchEventsTags } from "../../state/thunks/eventCommonThunk";
import { eventsTagsSelector } from "../../state/selectors/eventCommonSelector";
import { saveSettingsAction } from "../../state/actions/eventDetailsActions";
import { updateTags } from "../../state/thunks/eventTagsThunk";
import { eventSettings as eventSettingsValidationSchema } from "../../../../../utils/validationSchemas/eventValidationSchemas";

export type SettingsProps = IWizardStep &
  PropsFromRedux & {
    eventId?: number;
  };

export const SettingsTab = (props: SettingsProps) => {
  const { eventSettings, normalizedTags, isLoading, acceptHandlers, eventId, disabled } = props;

  const settingsFormik = useFormik({
    initialValues: eventSettings,
    validationSchema: eventSettingsValidationSchema,
    onSubmit: noop,
    enableReinitialize: true,
  });

  const settingsValuesRef = useRef(settingsFormik.values);
  useEffect(() => {
    settingsValuesRef.current = settingsFormik.values;
  }, [settingsFormik.values]);

  useEffect(() => {
    acceptHandlers?.({
      onNext: saveTags,
      onPrevious: saveTags,
    });
    props.fetchAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveTags = () => {
    props.saveSettings(settingsValuesRef.current);
  };

  return (
    <Restricted
      permissions={[RolePermissions.AssetsCreate]}
      renderContent={(hasPermission) => (
        <SettingsForm
          eventId={eventId}
          tagsFormik={settingsFormik}
          normalizedTags={normalizedTags}
          updateTags={props.updateTags}
          eventSettings={eventSettings}
          isDisabled={disabled || !hasPermission}
          isLoading={isLoading}
        />
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: { eventId?: number }) => {
  const base = state.library.events;

  return {
    eventId: ownProps.eventId || base.eventEntityState.entityId,
    eventSettings: base.eventDetails.settings,
    normalizedTags: eventsTagsSelector(state),
    isLoading: base.eventDetails.isLoading,
    disabled: base.eventDetails.event.isPurchased,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchAll: bindAction(fetchEventsTags, dispatch),
  saveSettings: bindAction(saveSettingsAction, dispatch),
  updateTags: bindAction(updateTags, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SettingsTab);
