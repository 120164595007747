export const GET_VIDEO_INFO_BEGIN = "GET_VIDEO_INFO_BEGIN";
export const GET_VIDEO_INFO_SUCCESS = "GET_VIDEO_INFO_SUCCESS";
export const GET_VIDEO_INFO_FAIL = "GET_VIDEO_INFO_FAIL";

export const SAVE_VIDEO_INFO = "SAVE_VIDEO_INFO";
export const SAVE_VIDEO_INFO_TAGS = "SAVE_VIDEO_INFO_TAGS";
export const RESET_VIDEO_INFO = "RESET_VIDEO_INFO";

export const CREATE_THUMBNAIL_BEGIN = "CREATE_THUMBNAIL_BEGIN";
export const CREATE_THUMBNAIL_SUCCESS = "CREATE_THUMBNAIL_SUCCESS";
export const CREATE_THUMBNAIL_FAIL = "CREATE_THUMBNAIL_FAIL";

export const SET_THUMBNAIL_FROM_POSITION = "SET_THUMBNAIL_FROM_POSITION";

export const UPDATE_VIDEO_BEGIN = "UPDATE_VIDEO_BEGIN";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const UPDATE_VIDEO_FAIL = "UPDATE_VIDEO_FAIL";

export const UPLOAD_VIDEO_BEGIN = "UPLOAD_VIDEO_BEGIN";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_FAIL = "UPLOAD_VIDEO_FAIL";
export const UPLOADING_VIDEO_PROGRESS = "UPLOADING_VIDEO_PROGRESS";
export const CANCEL_VIDEO_UPLOAD = "CANCEL_VIDEO_UPLOAD";

export const GET_STREAMING_INFO_BEGIN = "GET_STREAMING_INFO_BEGIN";
export const GET_STREAMING_INFO_SUCCESSED = "GET_STREAMING_INFO_SUCCESSED";
export const GET_STREAMING_INFO_FAILED = "GET_STREAMING_INFO_FAILED";

export const SET_IMAGE_PROCESSING_STATE = "SET_IMAGE_PROCESSING_STATE";
export const GENERATE_VIDEO_SUCCESS = "GENERATE_VIDEO_SUCCESS";
export const GET_THUMBNAIL_SUCCESS = "GET_THUMBNAIL_SUCCESS";

export const CHANGE_IMAGE_PROCESSING_STATE = "CHANGE_IMAGE_PROCESSING_STATE";

export const UPDATE_VIDEO_IMAGE_THUMBNAIL_BEGIN = "UPDATE_VIDEO_IMAGE_THUMBNAIL_BEGIN";
export const UPDATE_VIDEO_IMAGE_THUMBNAIL_SUCCESSED = "UPDATE_VIDEO_IMAGE_THUMBNAIL_SUCCESSED";
export const UPDATE_VIDEO_IMAGE_THUMBNAIL_FAILED = "UPDATE_VIDEO_IMAGE_THUMBNAIL_FAILED";

export const MEDIA_ENCODING_CREATED_ALERT = "MEDIA_ENCODING_CREATED_ALERT";
export const MEDIA_THUMBNAIL_CREATED_ALERT = "MEDIA_THUMBNAIL_CREATED_ALERT";
