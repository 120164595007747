import { FC, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import ModalWithSteps from "components/modal/ModalWithSteps";
import AddCustomersModalStep from "./AddCustomersModalStep";
import { renderSteps } from "components/assignmentModals/utils/renderSteps";
import {
  RenderActions,
  RenderModalActionsType,
  StepsOptions,
} from "components/assignmentModals/types";
import { isEmpty } from "lodash";
import {
  renderModalActionsFirstStep,
  renderModalActionsMiddleStep,
} from "components/assignmentModals/contentAssignmentModal/utils/contentAssignmentModalActions";
import { savePacks } from "../../state/thunks/TemplateAvailablePacksThunk";
import { bindAction } from "interfaces";
import { getTemplatePacks } from "../../state/thunks/TemplatePacksThunk";
import { PacksLicensingModalStep } from "../Packs/PacksLicensingModalStep";
import { reset as resetPacks } from "../../state/TemplateAddCustomersPacksSlice";
import { reset as resetCustomers, setCustomersToAdd } from "../../state/TemplateAvailableCustomersSlice";
import { saveCustomers } from "../../state/thunks/TemplateAvailableCustomersThunk";
import cn from "classnames";
import styles from "./addCustomersModalProvisionLicensesStep.module.scss";
import "./AddCustomersModal.scss";
import { renderModalActionsLastStep } from "../Packs/AddPacksModal";

export interface AddCustomersModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  templateId: number;
}

export type AddCustomersModalPropsAll = PropsFromRedux & AddCustomersModalProps;

export const AddCustomersModal: FC<AddCustomersModalPropsAll> = ({
  showModal,
  setShowModal,
  templateId,
  selectedIds,
  packs,
  isPacksLoading,
  addPacksToGroupTemplate,
  saveCustomers,
  setCustomersToAdd,
  getTemplatePacks,
  resetPacks,
  resetCustomers,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isProvisionLicensesStep, setIsProvisionLicensesStep] = useState(false);

  useEffect(() => {
    showModal && getTemplatePacks(templateId);
  }, [templateId, showModal, getTemplatePacks]);

  const packMap = useMemo(() => {
    const map = new Map();
    packs.forEach((pack) => {
      map.set(pack.id, pack);
    });
    return map;
  }, [packs]);

  const renderCustomersStep = (renderModalActions: RenderModalActionsType) => (
    <AddCustomersModalStep templateId={templateId} header="Add Customers" renderModalActions={renderModalActions} />
  );

  const renderTemplatePacksStep = (renderModalActions: RenderModalActionsType) => (
    <PacksLicensingModalStep
      packMap={packMap}
      setIsDisabled={setIsDisabled}
      header="Provision Licenses"
      renderModalActions={renderModalActions}
    />
  );

  const onModalClose = () => {
    setShowModal(false);
    setCustomersToAdd([]);
    resetPacks();
    resetCustomers();
    setIsProvisionLicensesStep(false);
  };

  const stepsOptions: StepsOptions[] = [
    {
      renderStep: renderCustomersStep,
      modalActionConfig: {
        previous: {
          onClick: onModalClose,
        },
        next: {
          onClick: () => setIsProvisionLicensesStep(true),
          disabled: isEmpty(selectedIds) || isPacksLoading,
        },
      },
    },
    {
      renderStep: renderTemplatePacksStep,
      modalActionConfig: {
        previous: {
          onClick: () => setIsProvisionLicensesStep(false),
        },
        confirm: {
          disabled: isDisabled,
          onClick: async () => {
            await saveCustomers(templateId);
            await addPacksToGroupTemplate(templateId, [...packMap.values()], selectedIds);
            onModalClose();
          },
        },
      },
    },
  ];

  const renderActions: RenderActions = {
    renderModalActionsFirstStep,
    renderModalActionsLastStep,
    renderModalActionsMiddleStep,
  };

  return (
    <ModalWithSteps
      className={cn(isProvisionLicensesStep ? [styles.root] : "add-customers-to-template-modal", {
        [styles["multiple-packs"]]: packs.length > 2,
      })}
      scrolling={isProvisionLicensesStep}
      isLoading={false}
      showModal={showModal}
      onCancel={onModalClose}
    >
      {renderSteps(stepsOptions, renderActions)}
    </ModalWithSteps>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    selectedIds: state.people.groupTemplate.availableCustomers.customersToAdd,
    packs: state.people.groupTemplate.addCustomersPacks.items,
    isPacksLoading: state.people.groupTemplate.addCustomersPacks.isLoading,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addPacksToGroupTemplate: bindAction(savePacks, dispatch),
    setCustomersToAdd: (ids: number[]) => dispatch(setCustomersToAdd(ids)),
    getTemplatePacks: (templateId: number) => dispatch(getTemplatePacks(templateId)),
    saveCustomers: (templateId: number) => dispatch(saveCustomers(templateId)),
    resetPacks: () => dispatch(resetPacks()),
    resetCustomers: () => dispatch(resetCustomers()),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddCustomersModal);
