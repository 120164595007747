import { ReactNode, useState } from "react";
import { Popup, StrictPopupProps } from "semantic-ui-react";

import "./chartWrapper.scss";

export interface Props {
  label: string;
  tooltipPosition?: StrictPopupProps["position"];
  tooltipFormatter?: (label: string) => ReactNode;
}

export const LegendLabel: React.FC<Props> = ({ label, tooltipPosition = "top center", tooltipFormatter }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popup
      inverted
      data-testid="legend label popup"
      content={tooltipFormatter ? tooltipFormatter(label) : label}
      trigger={<label className="label">{label}</label>}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      position={tooltipPosition}
    />
  );
};
