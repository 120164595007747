import { useState } from "react";
import ScheduleTypes from "../../../../../enums/scheduleTypes";
import { FeatureFlags } from "../../../../../featureFlags";
import { useFeatureFlag } from "../../../../../hooks/useFeatureFlag";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";

export function useScheduleStep(timeZone?: string) {
  const [scheduleType, setScheduleType] = useState(ScheduleTypes.SendNow);
  const [dateString, setDateString] = useState(dateTimeUtils.formatCurrentDate("MM/DD/YYYY"));
  const [timeString, setTimeString] = useState(
    dateTimeUtils.dateToTimeString(dateTimeUtils.roundTime(new Date(), 5), "hh:mm A"),
  );
  const communicationTimeZoneFeature = useFeatureFlag(FeatureFlags.CommunicationTimezone);
  const [timeZoneStrig, setTimeZoneStrig] = useState(communicationTimeZoneFeature ? (timeZone ?? "UTC") : null);

  const onChangeSchedule = (newScheduleType: ScheduleTypes, newDate: string, newTime: string, newTimeZone: string | null) => {
    setScheduleType(newScheduleType);
    setDateString(newDate);
    setTimeString(newTime);
    setTimeZoneStrig(newTimeZone)
  };


  return {
    type: scheduleType,
    date: dateString,
    time: timeString,
    timeZone: timeZoneStrig,
    onChange: onChangeSchedule,
  };
}
