import React from "react";

import { FlowItemEmail } from "../../../../features/Library/Flows/Designer/types";

import TopContent from "../BaseCard/TopContent";
import BottomContent from "../BaseCard/BottomContent";

export interface IEmailCardOwnProps {
  item: FlowItemEmail;
}

const EmailCard: React.FC<IEmailCardOwnProps> = ({ item }) => (
  <>
    <TopContent {...item} />
    <BottomContent cardLabel={item.elementType} />
  </>
);

export default EmailCard;
