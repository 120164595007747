import { connect } from "react-redux";
import GenericItemsView, { GenericItemsViewProps } from "../../../../../../../../views/ItemsView/GenericItemsView";
import { AppDispatch, RootState } from "../../../../../../../Application/globaltypes/redux";
import { AssessmentUser } from "../../../../../types/state";
import { selectedItemsChanged } from "../../../../../state/slices/assessmentUsersSlice";
import { assessmentsStateSelector } from "../../../../../state/selectors";

const mapStateToProps = (state: RootState) => {
  const edit = assessmentsStateSelector(state).edit;
  return {
    items: edit.users.items,
    isLoading: edit.users.isLoading,
    dataCount: edit.users.itemsCount,
    selectedIds: edit.users.selectedIds,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSelectedItemChanged: (ids: number[]) => dispatch(selectedItemsChanged(ids)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<AssessmentUser>) => React.ReactElement);
