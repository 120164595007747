import { ComponentType, useMemo, useState } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import PanelComponent from "./PanelComponent/PanelComponent";
import { ContentTab, ContentView } from "../../../../../../components";
import { AppDispatch, RootState } from "../../../../../Application/globaltypes/redux";
import {
  activeViewTabSelector,
  flowInspectorTypeSelectSelector,
  isFlowReadOnlySelector,
  itemsToDropFiltersSelector,
  itemsToDropSelector,
} from "../../selectors";
import {
  resetAppliedFilter,
  resetFilter,
  setSearch as setSearchAction,
} from "../../state/slices/flowDesignerFiltersSlice";
import { reset as resetItemsAction } from "../../state/slices/flowDesignerItemsToDropSlice";
import {
  AssessmentFilterForm,
  VideosFilterForm,
  PdfsFilterForm,
  SurveyFilterForm,
} from "../../../../../../components/filterForms";
import { EventFilterForm } from "../../../../../../components/filterForms/EventFilterForm/EventFilterForm";
import { EntityType } from "../../types";
import { getContentViewTabsOptions } from "./PanelComponent/helpers/contentViewOptions";
import DragNDropItemTypes from "../../../../../../enums/dragNDropItemTypes";
import { fetchItemsToDropV2 } from "../../state/thunks/flowDesignerItemsToDropThunk";
import FlowContentFilterSidebarContainer from "./FlowContentFilterSidebarContainer";
import { setActiveTab, setEntityType } from "../../state/slices/flowDesignerContextSlice";
import { EmailFilterForm } from "../../../../../../components/filterForms/EmailFilterForm/EmailFilterForm";
import { flowDesignerSelector } from "../../../state/selectors";
import MessagesFilterForm from "../../../../../../components/filterForms/MessagesFilterForm/MessagesFilterForm";
import { bindAction } from "../../../../../../interfaces";
import "./flowContentView.scss";

export function FlowContentView(props: PropsFromRedux) {
  const [showFilter, setShowFilter] = useState(false);
  const {
    filters,
    resetAppliedFilters,
    dispose,
    isReadOnly,
    activeViewTab,
    changeViewTab,
    loadItemsV2,
    itemsToDrop,
    search,
    entityTypeSelect,
    setSearch,
    changeEntityType,
    resetItems,
  } = props;

  const onTabChange = (_: React.MouseEvent<HTMLDivElement>, r: { activeIndex: number }) => {
    if (r.activeIndex !== activeViewTab) {
      changeViewTab(r.activeIndex);
      dispose();
    }
  };

  const filterFormsMap: { [key in EntityType]?: ComponentType<any> } = {
    Video: VideosFilterForm,
    Survey: SurveyFilterForm,
    Assessment: AssessmentFilterForm,
    ExternalEvent: EventFilterForm,
    Email: EmailFilterForm,
    Pdf: PdfsFilterForm,
    Message: MessagesFilterForm,
  };

  const contentViewTabs: ContentTab[] = useMemo(() => {
    return getContentViewTabsOptions().map((tab) => ({
      label: tab.label,
      id: tab.id,
      component: (
        <PanelComponent
          id={tab.id}
          className="flow-components-section"
          key={tab.id}
          permissions={tab.permissions}
          onFilterButtonClick={() => setShowFilter(true)}
          appliedFilters={filters.appliedFilter}
          resetAppliedFilter={resetAppliedFilters}
          renderCard={tab.renderCard(isReadOnly)}
          filterOptions={tab.filterOptions}
          loadItems={loadItemsV2}
          itemsToDrop={itemsToDrop}
          search={search}
          entityTypeSelect={entityTypeSelect}
          changeEntityType={changeEntityType}
          setSearch={setSearch}
          resetItems={resetItems}
          dispose={dispose}
          dragNDropItemType={DragNDropItemTypes.DESIGNER}
          showPurchased
        />
      ),
    }));
  }, [
    filters.appliedFilter,
    resetAppliedFilters,
    isReadOnly,
    loadItemsV2,
    itemsToDrop,
    search,
    entityTypeSelect,
    changeEntityType,
    setSearch,
    resetItems,
    dispose,
  ]);

  return (
    <div className={"flow-content-view"}>
      <ContentView tabs={contentViewTabs} onTabChange={onTabChange} activeIndex={activeViewTab} />

      <FlowContentFilterSidebarContainer
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        filterFormsMap={filterFormsMap}
      />
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isReadOnly: isFlowReadOnlySelector(state),
  activeViewTab: activeViewTabSelector(state),
  filters: flowDesignerSelector(state).filters,
  itemsToDrop: itemsToDropSelector(state),
  search: itemsToDropFiltersSelector(state).search,
  entityTypeSelect: flowInspectorTypeSelectSelector(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeViewTab: bindAction(setActiveTab, dispatch),
    resetAppliedFilters: bindAction(resetAppliedFilter, dispatch),
    loadItemsV2: bindAction(fetchItemsToDropV2, dispatch),
    changeEntityType: bindAction(setEntityType, dispatch),
    setSearch: bindAction(setSearchAction, dispatch),
    resetItems: bindAction(resetItemsAction, dispatch),
    dispose: () =>
      batch(() => {
        dispatch(resetFilter());
        dispatch(resetItemsAction());
      }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowContentView);
