import ActionConfirmationModal from "../../../../components/modal/ActionConfirmationModal/ActionConfirmationModal";
import { pluralize } from "../../../../utils/stringUtils";

export interface DeleteIdentityProvidersConfirmationModalProps {
  isOpen: boolean;
  selectedProviderIds: number[];
  onCancel: () => void;
  onContinue: () => void;
}

const DeleteIdentityProvidersConfirmationModal: React.FC<DeleteIdentityProvidersConfirmationModalProps> = ({
  isOpen,
  selectedProviderIds,
  onCancel,
  onContinue,
}) => {
  const title = `Delete ID ${pluralize("Provider", selectedProviderIds.length)}`;
  const providersCount = selectedProviderIds.length;
  const providersCountStr = providersCount > 1 ? `(${providersCount}) ` : "";
  const providerPluralized = pluralize("Provider", providersCount);
  const isProviderSingular = providersCount === 1;

  return (
    <ActionConfirmationModal
      open={isOpen}
      title={title}
      onCancel={onCancel}
      onContinue={onContinue}
      withWarningLabel={false}
      confirmLabel="Delete"
    >
      {`Delete ID ${providersCountStr}${providerPluralized}?
        This will remove the configuration for ${
          isProviderSingular ? "this" : "these"
        } ${providerPluralized.toLowerCase()}
        and those users who use ${isProviderSingular ? "it" : "them"} will no longer be able to log in.`}
    </ActionConfirmationModal>
  );
};

export default DeleteIdentityProvidersConfirmationModal;
