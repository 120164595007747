import { RolePermissions } from "../../../../enums";
import Rights, { hasContentRight, hasUserRight } from "../../../../enums/rights";

export const issueLicensePermissionPredicate = (userPermissions: RolePermissions[]): boolean => {
  return (
    userPermissions.includes(RolePermissions.AccountsSettingsView) &&
    hasContentRight(userPermissions, Rights.View) &&
    hasUserRight(userPermissions, Rights.Manage)
  );
};
