import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { bindActionCreators, Dispatch } from "redux";

import { ThreatDefenceFeedbackPagesOverviewState, ThreatDefenceFeedbackPagesOverviewOwnProps } from "./types";
import TableBody from "./ThreatDefenceFeedbackPageOverviewTableBodyBuilder";

import { RootState } from "../../../../Application/globaltypes/redux";
import columnOptions from "./columnOptions";

import { OverviewHeader } from "../../../../../components/sectionHeader";
import { SearchInput } from "../../../../../components";

import ViewType from "../../../../../enums/ViewType";
import { SortingDirection } from "../../../../../enums";
import { ItemsView } from "../../../../../views";

import FeedbackPagesNoResults from "../../../../../views/library/feedbackPages/Overview/ThreatDefenceFeedbackPagesNoResults";
import * as feedbackPagesOverviewActions from "../state/actions/ThreatDefenceFeedbackPagesOverviewActions";
import threatDefenceFetchDataUtils from "../../../../../utils/threatDefenceFetchDataUtils";
import { ThreatDefenceFeedbackPageOverview } from "../types/state";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const defaultSortColumn = "datecreated";

export type ThreatDefenceFeedbackPagesOverviewProps = ThreatDefenceFeedbackPagesOverviewOwnProps &
  PropsFromRedux &
  WithRouterProps;

export class ThreatDefenceFeedbackPagesOverview extends Component<
  ThreatDefenceFeedbackPagesOverviewProps,
  ThreatDefenceFeedbackPagesOverviewState
> {
  constructor(props: ThreatDefenceFeedbackPagesOverviewProps) {
    super(props);
    this.state = {
      selectedFeedbackPageIds: [],
    };
  }

  onSelectedItemsChanged = (ids: Array<number>) => {
    this.setState({ selectedFeedbackPageIds: ids });
  };

  fetchFeedbackPages = (skip: number, top: number, orderBy: string) => {
    this.props.feedbackPagesOverviewActions.getThreatDefenceFeedbackPagesAsync(top, skip, orderBy);
  };

  getFeedbackPages = (skip?: number, top?: number, sortingColumnName?: string, sortingDirection?: SortingDirection) => {
    const orderBy = threatDefenceFetchDataUtils.formatOrderParams(
      sortingColumnName || defaultSortColumn,
      sortingDirection || SortingDirection.Descending,
    );

    this.fetchFeedbackPages(skip ?? 0, top ?? 10, orderBy);
  };

  renderCreateFeedbackPageBtn = (): React.ReactElement | null => {
    if (this.state.selectedFeedbackPageIds.length === 0) {
      return (
        <Button
          primary
          className="create-feedbackPage create-button"
          onClick={() => {
            this.props.navigate("create");
          }}
        >
          Create Feedback Page
        </Button>
      );
    }

    return null;
  };

  render() {
    const { feedbackPages, totalCount, isLoading } = this.props;
    return (
      <section className="nested-content feedbackPages">
        <OverviewHeader title="Feedback Pages">{this.renderCreateFeedbackPageBtn()}</OverviewHeader>
        <ItemsView
          items={feedbackPages}
          viewType={ViewType.LIST}
          getData={this.getFeedbackPages}
          noResultsContent={<FeedbackPagesNoResults createButton={this.renderCreateFeedbackPageBtn()} />}
          isLoading={isLoading}
          isAllDataLoaded={false}
          selectedIds={this.state.selectedFeedbackPageIds}
          className="feedbackPages-items-view"
          filterOptionsLoading={false}
          applyFilter={/* istanbul ignore next */ () => {}} //NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} //NOSONAR
          buildTableBody={(feedbackPage: ThreatDefenceFeedbackPageOverview) => (
            <TableBody feedbackPage={feedbackPage} {...this.props} />
          )}
          columnOptions={columnOptions}
          itemsInlineCount={totalCount}
          onSelectedListItemsChanged={this.onSelectedItemsChanged}
          sortingColumnName={defaultSortColumn}
          sortingDirection={SortingDirection.Descending}
          renderSearch={() => <SearchInput placeholder="Search for Feedback Pages..." />}
        />
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const overview = state.library.threatDefenceFeedbackPages.threatDefenceFeedbackPagesOverview;
  const entityState = state.library.threatDefenceFeedbackPages.feedbackPageEntityState;
  return {
    feedbackPages: overview.feedbackPages,
    isLoading: overview.isLoading || entityState.changingEntityState,
    totalCount: overview.totalCount,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  feedbackPagesOverviewActions: bindActionCreators(feedbackPagesOverviewActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(ThreatDefenceFeedbackPagesOverview));
