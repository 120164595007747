import React from "react";
import ReadyToPublish from "./ReadyToPublish";
import UnableToPublish from "./UnableToPublish";

export interface Props {
  unableToPublish: boolean;
  readyToPublish: boolean;
  isVerified: boolean;
  onErrorButtonClick: () => void;
  onVerifyButtonClick: () => void;
}

const PublishSmtpSendingProfileValidationInfo: React.FC<Props> = ({
  unableToPublish,
  readyToPublish,
  isVerified,
  onErrorButtonClick,
  onVerifyButtonClick,
}) => {
  let errorLabel: string;
  let errorMessage: string;

  const shouldVerify = !unableToPublish && !isVerified;

  if (shouldVerify) {
    errorLabel = "Verify First";
    errorMessage = "Verify before publishing";
  }

  const renderSuccessInfo = () => readyToPublish && isVerified && <ReadyToPublish />;

  const renderErrorInfo = () => (
    <UnableToPublish
      isVisible={unableToPublish || !isVerified}
      onErrorButtonClick={shouldVerify ? onVerifyButtonClick : onErrorButtonClick}
      errorLabel={errorLabel}
      errorMessage={errorMessage}
    />
  );

  return (
    <>
      {renderSuccessInfo()}
      {renderErrorInfo()}
    </>
  );
};

export default PublishSmtpSendingProfileValidationInfo;
