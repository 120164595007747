import { FC } from "react";
import { Placeholder } from "semantic-ui-react";

interface Props {
  isDataLoaded: boolean;
  text: string;
}

export const Headline: FC<Props> = ({ isDataLoaded, text }) => {
  return (
    <div className="info-text" data-testid="info-text">
      {isDataLoaded ? (
        text
      ) : (
        <Placeholder data-testid="pack-context-info-text-placeholder">
          <Placeholder.Header>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      )}
    </div>
  );
};
