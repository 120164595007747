import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

import ValidatedField from "./ValidatedField";

function CheckboxField(props) {
  const {
    value,
    propertyName,
    setFieldValue,
    handleChange,
    label,
    errors,
    touched,
    toggle,
    disabled,
    withoutErrorMessage,
    shouldValidate,
    fieldClassName,
    testId,
  } = props;

  const onChange = async (e, target) => {
    await setFieldValue(target.id, target.checked, shouldValidate);
    handleChange?.(e);
  };

  return (
    <ValidatedField
      propertyName={propertyName}
      errors={errors}
      touched={touched}
      withoutErrorMessage={withoutErrorMessage}
      className={fieldClassName}
    >
      <Checkbox
        id={propertyName}
        type="checkbox"
        checked={value}
        onChange={onChange}
        label={label}
        toggle={toggle}
        disabled={disabled}
        data-testid={testId}
      />
    </ValidatedField>
  );
}

CheckboxField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  propertyName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  toggle: PropTypes.bool,
  disabled: PropTypes.bool,
  withoutErrorMessage: PropTypes.bool,
  setFieldValue: PropTypes.func,
  fieldClassName: PropTypes.string,
  testId: PropTypes.string,
};

export default CheckboxField;
