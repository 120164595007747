import { AlignedWith, ElAndPos } from "../types";
import { useRef, useCallback } from "react";
import isParalellWith from "../helpers/isParalellWith";
import getSortedByAxis from "../helpers/getSortedByAxis";
import { IFlowNode } from "../../ReactFlowCanvas/nodes/types";
import { useReactFlowCanvasEventEmitter } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import { useNodesFilter, ViewPortType } from "./useNodesFilter";
import { EmitterEventTypes, ReactFlowEmitter } from "../../ReactFlowCanvas/emitter/ReactFlowEmitter";
import {
  ELEMENT_HALF_HEIGHT,
  ELEMENT_HALF_WIDTH,
  ELEMENT_HEIGHT,
  ELEMENT_WIDTH,
  SECTION_HEADER_HEIGHT,
} from "../../constants";
import { useReactFlow } from "reactflow";

function useCalculation(events: ReactFlowEmitter | undefined, sortedByX: ElAndPos[], sortedByY: ElAndPos[]) {
  const prevResultRef = useRef<{
    leftAligned: AlignedWith | null;
    middleVerticalAligned: AlignedWith | null;
    rightAligned: AlignedWith | null;
    topAligned: AlignedWith | null;
    middleHorizontalAligned: AlignedWith | null;
    bottomAligned: AlignedWith | null;
  }>({
    leftAligned: null,
    middleVerticalAligned: null,
    rightAligned: null,
    topAligned: null,
    middleHorizontalAligned: null,
    bottomAligned: null,
  });

  const trackNodeForHelperGuides = useCallback(
    (node: IFlowNode, config: { dragFinished: boolean }) => {
      if (!events) {
        return;
      }

      let middleVerticalAligned;
      let rightAligned;
      let middleHorizontalAligned;
      let bottomAligned;
      const _sortedByX = sortedByX.filter((item) => item.id !== node.id);
      const _sortedByY = sortedByY.filter((item) => item.id !== node.id);
      let x = node.position.x;
      let y = node.position.y;

      if (node.data?.sectionHeader) {
        y = y + SECTION_HEADER_HEIGHT;
      }

      const leftAligned = isParalellWith(_sortedByX, "x", Math.round(x), y);

      if (leftAligned?.edge === 1 || leftAligned?.edge === 2) {
        middleVerticalAligned = leftAligned;
      } else {
        middleVerticalAligned = isParalellWith(_sortedByX, "x", Math.round(x + ELEMENT_HALF_WIDTH), y);
      }

      if (middleVerticalAligned?.edge === 1) {
        rightAligned = middleVerticalAligned;
      } else {
        rightAligned = isParalellWith(_sortedByX, "x", Math.round(x + ELEMENT_WIDTH), y);
      }

      const topAligned = isParalellWith(_sortedByY, "y", Math.round(y), x);

      if (topAligned?.edge === 1 || topAligned?.edge === 2) {
        middleHorizontalAligned = topAligned;
      } else {
        middleHorizontalAligned = isParalellWith(_sortedByY, "y", Math.round(y + ELEMENT_HALF_HEIGHT), x);
      }

      if (middleHorizontalAligned?.edge === 1) {
        bottomAligned = middleHorizontalAligned;
      } else {
        bottomAligned = isParalellWith(_sortedByY, "y", Math.round(y + ELEMENT_HEIGHT), x);
      }

      if (
        prevResultRef.current.leftAligned !== leftAligned ||
        prevResultRef.current.rightAligned !== rightAligned ||
        prevResultRef.current.topAligned !== topAligned ||
        prevResultRef.current.bottomAligned !== bottomAligned
      ) {
        events.emitNodeEvent(node.id, {
          type: EmitterEventTypes.AlignmentUpdate,
          payload: {
            topAligned,
            middleHorizontalAligned,
            bottomAligned,
            leftAligned,
            middleVerticalAligned,
            rightAligned,
            dragFinished: config.dragFinished,
            position: node.position,
          },
        });
      }
      prevResultRef.current = {
        leftAligned,
        middleVerticalAligned,
        rightAligned,
        topAligned,
        middleHorizontalAligned,
        bottomAligned,
      };
    },
    [events, sortedByX, sortedByY],
  );

  return { trackNodeForHelperGuides };
}

const nodeFilterOptions = { viewPortType: ViewPortType.SmartGuidesOnCanvas };

export const useGuidesOnCanvas = () => {
  let sortedByX: ElAndPos[] = [];
  let sortedByY: ElAndPos[] = [];

  const { getNodes } = useReactFlow();
  const events = useReactFlowCanvasEventEmitter();
  const { nodesFilter, refreshViewPort } = useNodesFilter(nodeFilterOptions);

  const nodes = getNodes().filter(nodesFilter);
  const sorted = getSortedByAxis(nodes);

  sortedByX = sorted.sortedByX;
  sortedByY = sorted.sortedByY;

  const { trackNodeForHelperGuides } = useCalculation(events, sortedByX, sortedByY);

  return { events, trackNodeForHelperGuides, refreshViewPort };
};
