import { Integration } from "../../../Integrations/types";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

export type IntegrationOverviewState = FetchingItemsState<Integration>;

const initialState: IntegrationOverviewState = {
  items: [],
  isLoading: false,
  itemsCount: 0,
  error: undefined,
};

const integrationOverviewSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.Integrations,
    name: "overview",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = integrationOverviewSlice.actions;

export default integrationOverviewSlice.reducer;
export type integrationOverviewSliceType = ReturnType<typeof integrationOverviewSlice.reducer>;
