import { combineReducers } from "redux";
import allAccountsOverviewReducer from "../AllAccountsOverview/state/slices/allAccountsOverviewSlice";
import createAccountReducer from "../CreateAccount/state/slices/createAccountSlice";
import editAccountReducer from "../EditAccount/state/editAccountReducer";
import mostAccessedAccountsReducer from "../MostAccessedAccounts/state/mostAccessedAccountsReducer";
import identityProvidersReducer from "./reducers/identityProvidersReducer";
import contractTypesReducer from "./slices/contractTypesSlice";
import subscriptionAgreementReducer from "./slices/subscriptionAgreementSlice";
import apiKeysReducer from "../Api/state/slices/apiKeysSlice";
import integrationReducer from "../Integrations/integrationReducer";
import accountInformationReducer from "../AccountInformation/state/accountInformationReducer";
import emailSettingsReducer from "../EmailSettings/state/slices/emailSettingsSlice";
import parentAccountsReducer from "./slices/parentAccountSlice";
import availableTemplatesSlice from "./slices/availableTemplatesSlice";

const accountsReducer = combineReducers({
  edit: editAccountReducer,
  accountInformation: accountInformationReducer,
  creation: createAccountReducer,
  overview: allAccountsOverviewReducer,
  mostAccessed: mostAccessedAccountsReducer,
  identityProviders: identityProvidersReducer,
  contractTypes: contractTypesReducer,
  subscriptionAgreement: subscriptionAgreementReducer,
  apiKeys: apiKeysReducer,
  integrations: integrationReducer,
  emailSettings: emailSettingsReducer,
  parentAccounts: parentAccountsReducer,
  availableTemplates: availableTemplatesSlice,
});

export type AccountsState = ReturnType<typeof accountsReducer>;

export default accountsReducer;
