import React from "react";
import { Icon } from "semantic-ui-react";
import DragAndDropSection from "./DragAndDropSection";

class DefaultDragAndDropSection implements DragAndDropSection {
  getSection(): React.ReactElement {
    return (
      <div className="drop-section">
        <div className="overlay"></div>
        <div className="drop-box">
          <div>
            <Icon className="fa-cloud-upload fa-lg" />
            <span>Drop Files Here to Upload</span>
          </div>
        </div>
      </div>
    );
  }

  compareType(_type: string | null): boolean {
    return true;
  }
}

export default DefaultDragAndDropSection;
