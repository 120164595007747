import videojs from "video.js";
import QhMenu from "./qhMenu";
import SettingsMenuConfigurator from "./settingsMenuConfigurator.js";

import "./menu.scss";
const MenuButton = videojs.getComponent("MenuButton");

class QhMenuButton extends MenuButton {
  createEl() {
    return super.createEl("div", {});
  }

  update() {
    const menu = this.createMenu();

    if (this.menu) {
      this.player().removeChild(this.menu);
    }

    this.menu = menu;
    this.player().addChild(menu);

    this.buttonPressed_ = false;
    this.el_.setAttribute("aria-expanded", "false");

    if (this.items && this.items.length === 0) {
      this.hide();
    } else if (this.items && this.items.length > 1) {
      this.show();
    }
  }

  createMenu() {
    const menu = new QhMenu(this.player_);
    const menuConfigurator = new SettingsMenuConfigurator(this.player_, menu, this.options_.videoInfo);
    menuConfigurator.onMenuClose = this.unpressButton.bind(this);
    return menuConfigurator.renderMenu();
  }

  handleClick() {
    if (this.buttonPressed_) {
      this.unpressButton();
    } else {
      // force update to recreate menu every time when menu open
      this.update();
      this.pressButton();
    }
  }

  pressButton() {
    super.pressButton();
    if (this.enabled_) {
      this.on(this.el_.ownerDocument, ["mousedown", "touchstart"], this._handleOuterClick);
    }
  }

  unpressButton() {
    super.unpressButton();
    if (this.enabled_) {
      this.off(this.el_.ownerDocument, ["mousedown", "touchstart"], this._handleOuterClick);
    }
  }

  _handleOuterClick(event) {
    const element = event.target;
    if (
      !(
        this._isDescendant(this.menu.el(), element) ||
        this._isDescendant(this.el(), element) ||
        this.el() === element ||
        this.menu.el() === element
      )
    ) {
      this.menu.unlockShowing();
      if (this.buttonPressed_) {
        this.unpressButton();
      }
      event.preventDefault();
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }

  _isDescendant(parent, child) {
    let node = child.parentNode;
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }
}

QhMenuButton.prototype.controlText_ = "Settings Menu";

export default QhMenuButton;
