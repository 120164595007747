import BackgroundTaskLocalStorage from "../../BackgroundTasks/storage/BackgroundTaskLocalStorage";
import videoAssetTasks from "../../BackgroundTasks/videoAssetTasks";

const storage = new BackgroundTaskLocalStorage();

const generateThumbnailInternal = (propsProvider, thumbnailGenerator) => {
  const { videosActions, values } = propsProvider();
  videosActions.saveVideoInfo(values);
  videosActions.changeImageProcessingState(false);
  thumbnailGenerator();
};

function videosThumbnailUploader(propsProvider) {
  const onCroppedThumbnailClick = () => {
    const { entityId, videosActions, streamingManifests } = propsProvider();
    if (streamingManifests.length === 0) {
      videosActions.getStreamingInfo(entityId);
    }
  };

  const generateThumbnail = (sas) => {
    generateThumbnailInternal(propsProvider, () => {
      const { videosActions, entityId } = propsProvider();
      videosActions.updateImageThumbnail(sas, entityId);
    });
  };

  const generateThumbnailFromPosition = (position, thumbnailUri) => {
    generateThumbnailInternal(propsProvider, () => {
      const { videosActions, backgroundTasksActions, entityId, values } = propsProvider();
      videosActions.setThumbnailFromPosition(thumbnailUri);
      videosActions.generateThumbnailFromPosition(position, entityId);
      const task = videoAssetTasks.getProcessing(entityId);
      task.title = values.title;
      backgroundTasksActions.addOrUpdate(task);
      storage.saveTask(entityId);
    });
  };

  return {
    onCroppedThumbnailClick,
    generateThumbnail,
    generateThumbnailFromPosition,
  };
}

export default videosThumbnailUploader;
