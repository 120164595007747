import EventEmitter from "events";
import rtnHandler from "./rtnHandler";

const eventEmitter = new EventEmitter();

const handleMultipleEvents = (event, handler, action) => {
  let events = Array.isArray(event) ? event : [event];
  events.forEach((eventItem) => {
    eventEmitter[action](eventItem, handler);
  });
};

const RtnEventsEmitter = {
  subscribe: (event, handler) => handleMultipleEvents(event, handler, "on"),

  subscribeOnce: (event, handler) => handleMultipleEvents(event, handler, "once"),

  prependSubscribe: (event, handler) => handleMultipleEvents(event, handler, "prependListener"),

  unsubscribe: (event, handler) => handleMultipleEvents(event, handler, "off"),

  subscribeFromMapping(eventTypes, handlersMapping) {
    eventTypes.forEach((event) => {
      const handler = handlersMapping[event];
      if (handler) {
        this.subscribe(event, handler);
      }
    });
  },

  subscribeOnceAndWait(event, timeout) {
    return new Promise((resolve, reject) => {
      let timeoutHandle = -1;

      const handler = (payload) => {
        if (timeoutHandle > 0) {
          clearTimeout(timeoutHandle);
        }
        resolve(payload);
      };

      timeoutHandle = setTimeout(() => {
        this.unsubscribe(event, handler);
        reject();
      }, timeout);

      this.subscribeOnce(event, handler);
    });
  },
};

export default RtnEventsEmitter;

export const createRtnHandler = (asyncActionsProvider, dispatch) =>
  rtnHandler(eventEmitter, asyncActionsProvider, dispatch);
