import PacksFilters from "../../../../../enums/licensing/PacksFilters";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { GenericFiltersMap } from "../../../../../utils/filterUtils";
import { RootState } from "../../../../Application/globaltypes/redux";
import { createFiltersSlice, FiltersState } from "../../../../Application/slices/createFiltersSlice";

export const initialState: FiltersState<PacksFilters> = {
  filterOptions: {} as GenericFiltersMap<PacksFilters>,
  appliedFilter: {} as GenericFiltersMap<PacksFilters>,
  isLoading: false,
};

export const packsOverviewFiltersSlice = createFiltersSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Licensing, entity: ReducerEntityPrefixTypes.Packs, name: "filters" },
  initialState: initialState,
  reducers: {},
});

export const { setAppliedFilter, resetAppliedFilter, setIsLoading, setError, setFilterOptions } =
  packsOverviewFiltersSlice.actions;

export const packsOverviewFiltersSelector = (state: RootState) => state.packs.packsOverviewFilters;

export default packsOverviewFiltersSlice.reducer;
