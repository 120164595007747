import { Table } from "semantic-ui-react";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { UserEllipsisPopupButton } from "../../../../components/people";
import UserInfoCell from "../../../../components/userInfoCell/UserInfoCell";
import { Title } from "../../../../components/listViewTemplates";
import { ColumnOptions } from "../../../../interfaces";
import { AddPeopleToContentTypes } from "../../../../enums";
import RestrictedByTooltip from "../../../../components/restrictedByTooltip/RestrictedByTooltip";

export const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 4,
    isSortable: true,
    className: "user-name-header",
  },
  {
    name: "Department",
    width: 3,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 3,
    isSortable: true,
  },
  {
    name: "Added",
    width: 3,
    isSortable: true,
  },
  {
    name: "Last Login",
    width: 3,
    isSortable: true,
  },
  {
    name: "",
    width: 2,
  },
];

interface UserListRowProps {
  user: any;
  onDeleteUser: (id: number) => void;
  onAddToGroup: (id: number) => void;
  onAddToRole: (id: number) => void;
  onAddToContent: (id: number, contentType: AddPeopleToContentTypes) => void;
  goToEditUser: (id: number) => void;
  isEllipsisMenuDisabled: boolean;
  hasPermission?: boolean;
}

export function UserListRow(props: UserListRowProps) {
  const { user, isEllipsisMenuDisabled, hasPermission = true } = props;

  const buttonHandlers = {
    goToEditUser: props.goToEditUser,
    onDeleteUser: props.onDeleteUser,
    onAddToGroup: props.onAddToGroup,
    onAddToRole: props.onAddToRole,
    onAddToContent: props.onAddToContent,
  };

  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <UserInfoCell user={user} goToEditUser={buttonHandlers.goToEditUser} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <Title title={user.department} />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <Title title={user.title} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(user.createDate)}</Table.Cell>
      <Table.Cell width={columnOptions[4].width}>{dateTimeUtils.formatEmptyDate(user.lastLoginDate)}</Table.Cell>
      <Table.Cell className="align-right" width={columnOptions[5].width}>
        <RestrictedByTooltip hasPermission={hasPermission}>
          <UserEllipsisPopupButton disabled={isEllipsisMenuDisabled} user={user} {...buttonHandlers} />
        </RestrictedByTooltip>
      </Table.Cell>
    </>
  );
}

export default UserListRow;
