import React from "react";
import { Form } from "semantic-ui-react";
import cn from "classnames";

import "./validatedField.scss";
import ValidationErrorPrompt from "../../../../components/forms/ValidationErrorPrompt";
import LabelWithInfoTooltip from "../../../../components/labelWithInfoTooltip/labelWithInfoTooltip";
import { ValidatedFieldProps } from "./models";

export const ValidatedField = (props: ValidatedFieldProps) => {
  const { children, label, tooltip, error, errorPosition, markAsRequired, pushdown, withoutErrorMessage, showError } =
    props;
  const isInErrorState = !!error && showError;
  const labelClasses = {
    "validated-field-label": true,
    error: isInErrorState,
    "required-asterisk": markAsRequired,
  };

  return (
    <React.Fragment>
      {label && !tooltip && <label className={cn(labelClasses)}>{label}</label>}
      {label && tooltip && (
        <LabelWithInfoTooltip label={label} width={tooltip.width} info={tooltip.info} labelClass={cn(labelClasses)} />
      )}
      <Form.Field
        error={isInErrorState}
        className={cn("validated-field", errorPosition, {
          pushdown: pushdown,
        })}
      >
        {children}
        {!withoutErrorMessage && isInErrorState && (
          <ValidationErrorPrompt errorPosition={errorPosition ?? "right"} text={error} />
        )}
      </Form.Field>
    </React.Fragment>
  );
};

export default React.memo(ValidatedField);
