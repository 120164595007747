import axios from "axios";
import { IAssessmentsDataService } from "../types/services";
import { AssessmentPayload, ContentPayload, AssessmentPeopleDetails } from "../types/state";
import { formatFiltersV2api, mapToV2Filters, serializeArrayAndFilterNullable } from "../../../../utils/queryUtils";
import { GetPagedDataByIdRequestV3, WithPagedDataV2 } from "../../../../interfaces/getPagedDataRequest";
import { TagsToContent } from "../../../../interfaces";
import { v2FilterMap } from "../../../People/types";
import { escapeTerm } from "../../../../utils/searchUtils";
import { BasePerformanceRequestFilterParams } from "../../Common/models";

let questionResponsesController = new AbortController();
let questionResponsesPeopleController = new AbortController();
let exportAbortController = new AbortController();

// Added to prevent issues with launch darkly resolving late
// with new endpoint versions
let densityAbortController = new AbortController();
let engagementAbortController = new AbortController();
let cardsAbortController = new AbortController();
let questionsAbortController = new AbortController();
let peopleDetailsAbortController = new AbortController();

const assessmentsDataService: IAssessmentsDataService = {
  getPublishedAssessmentsByIdsV2(ids: string[], showPurchased: boolean) {
    const config = {
      params: {
        showPurchased,
        ids,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get("/api/v2/assets/assessments/published", config);
  },

  updateAssessmentAsync(data: AssessmentPayload) {
    return axios.put(`/api/assets/assessments/${data.id}`, data);
  },

  updateContentAsync(data: ContentPayload) {
    return axios.put(`/api/assets/assessments/${data.id}/questions`, data);
  },

  getAssessmentV2Async(id: number) {
    return axios.get(`/api/v2/assets/assessments/${id}`);
  },

  getAssessmentContentAsync(id: number) {
    return axios.get(`/api/assets/assessments/${id}/questions`);
  },

  getAssessmentUsersV2Async<T>(entityId: number, request: WithPagedDataV2<T>) {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
    };
    return axios.get(`/api/v4/content/assessment/${entityId}/users`, config);
  },

  getAssessmentGroupsV2Async(request: GetPagedDataByIdRequestV3) {
    const { entityId, skip, top, term, filter } = request;
    return axios.get(`/api/v4/content/assessment/${entityId}/groups`, {
      params: {
        skip,
        top,
        ...formatFiltersV2api(mapToV2Filters(filter!, v2FilterMap)),
        term: escapeTerm(term),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  duplicateAssessmentsAsync(assessmentIds: number[]) {
    return axios.post(`/api/assets/assessments/duplicate`, { assessmentIds });
  },

  getTagsFilterOptions(showPurchased: boolean) {
    return axios.get("/api/assets/assessments/tags", { params: { showPurchased } });
  },

  getPublishersFilterOptions() {
    return axios.get("/api/assets/assessments/publishers");
  },

  deleteAssessmentAsync(ids: number[]) {
    return axios.delete("/api/assets/assessments", {
      data: { ids },
    });
  },

  // Performance
  async getAssessmentCardData(entityId: number, filterParams: BasePerformanceRequestFilterParams) {
    cardsAbortController.abort();
    cardsAbortController = new AbortController();
    const response = await axios.get("/api/reports/v2/assessments/cards", {
      params: {
        assessmentId: entityId,
        ...filterParams,
      },
      signal: cardsAbortController.signal,
    });

    return response.data;
  },

  async getQuestionResponses(
    assessmentId: number,
    questionId: string,
    filterParams: BasePerformanceRequestFilterParams,
  ) {
    questionResponsesController.abort();
    questionResponsesController = new AbortController();
    const response = await axios.get("/api/reports/v2/assessments/question-responses", {
      params: {
        assessmentId,
        questionId,
        ...filterParams,
      },
      signal: questionResponsesController.signal,
    });
    return response.data;
  },

  async getAssessmentEngagementLine(entityId: number, filterParams: BasePerformanceRequestFilterParams) {
    densityAbortController.abort();
    densityAbortController = new AbortController();
    const response = await axios.get("/api/reports/v2/assessments/linechart", {
      params: {
        assessmentId: entityId,
        ...filterParams,
      },
      signal: densityAbortController.signal,
    });
    return response.data;
  },

  async getAssessmentEngagementFunnel(entityId: number, filterParams: BasePerformanceRequestFilterParams) {
    engagementAbortController.abort();
    engagementAbortController = new AbortController();
    const response = await axios.get("/api/reports/v2/assessments/engagement", {
      params: {
        assessmentId: entityId,
        ...filterParams,
      },
      signal: engagementAbortController.signal,
    });
    return response.data;
  },

  async getAssessmentEngagementQuestions(entityId: number, filterParams: BasePerformanceRequestFilterParams) {
    questionsAbortController.abort();
    questionsAbortController = new AbortController();
    const response = await axios.get("/api/reports/v2/assessments/question-answers", {
      params: {
        assessmentId: entityId,
        ...filterParams,
      },
      signal: questionsAbortController.signal,
    });
    return response.data;
  },

  async getAssessmentEngagementQuestionsPeople(
    entityId: number,
    questionId: string,
    answerId: string,
    filterParams: BasePerformanceRequestFilterParams,
  ) {
    questionResponsesPeopleController.abort();
    questionResponsesPeopleController = new AbortController();

    return axios.get("/api/reports/v2/assessments/question-response-people", {
      params: {
        assessmentId: entityId,
        questionId,
        answerId,
        ...filterParams,
      },
      signal: questionResponsesPeopleController.signal,
    });
  },

  cancelAssessmentQuestionsPeople() {
    questionResponsesPeopleController.abort();
  },

  addAssessmentTags(data: TagsToContent) {
    return axios.post(`/api/assets/assessments/tags`, data);
  },

  saveAssessmentTags(id: number, payload: TagsToContent) {
    return axios.put(`/api/assets/assessments/${id}/tags`, payload);
  },

  async getAssessmentPeopleDetails(
    assessmentId: number,
    filterParams: BasePerformanceRequestFilterParams,
  ): Promise<AssessmentPeopleDetails[]> {
    peopleDetailsAbortController.abort();
    peopleDetailsAbortController = new AbortController();

    const response = await axios.get<AssessmentPeopleDetails[]>("/api/reports/v2/assessments/people-details", {
      params: { assessmentId, ...filterParams },
      signal: peopleDetailsAbortController.signal,
    });

    return response.data;
  },

  getExport(assessmentId: number, filterParams: BasePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();
    return axios.get("/api/dataexport/v2/assessments/user-progress", {
      responseType: "blob",
      params: {
        assessmentId,
        ...filterParams,
      },
      signal: exportAbortController.signal,
    });
  },
};

export default assessmentsDataService;
