import { FC } from "react";
import { Table } from "semantic-ui-react";
import dateTimeUtils from "../../../utils/dateTimeUtils";
import { ColumnOptions } from "../../../interfaces";
import { PackOverview } from "../../../features/Licensing/Packs/types/state";
import { PackTitleCell } from "./packTitleCell/PackTitleCell";
import { partial } from "lodash";
import { ExpirationCell } from "../../../features/Accounts/EditAccount/EditPacks/AccountPacksList/ExpirationCell/ExpirationCell";
import { issueLicensePermissionPredicate } from "features/People/Users/EditUser/issueLicensePermissionPredicate";
import { RemoveLicenseButton } from "./removeLicenseButton/RemoveLicenseButton";
import styles from "./packListRow.module.scss";

export const getColumnOptions = (licenseRemovalEnabled: boolean): ColumnOptions[] => {
  const options: ColumnOptions[] = [
    {
      name: "Pack",
      width: 5,
      isSortable: true,
      className: styles["pack-title-header"],
    },
    {
      name: "License Issued",
      width: 4,
      isSortable: true,
    },
    {
      name: "Expiration Date",
      width: 4,
      isSortable: true,
    },
  ];

  if (licenseRemovalEnabled) {
    options.push({
      name: "",
      width: 1,
      isSortable: false,
    });
  }

  return options;
};

export interface UserPackListRowProps {
  pack: PackOverview;
  accountId: number;
  onTitleClick: (id: number) => void;
  onRemoveLicenseClick: (id: number) => void;
  licenseRemovalEnabled: boolean;
  isSelected: boolean;
}

export const UserPackListRow: FC<UserPackListRowProps> = ({
  pack,
  accountId,
  onTitleClick,
  onRemoveLicenseClick,
  licenseRemovalEnabled,
  isSelected,
}) => {
  const handleTitleClick = partial(onTitleClick, pack.id);
  const isOwnPack = pack.publisherId === accountId;
  const columnOptions = getColumnOptions(licenseRemovalEnabled);

  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <PackTitleCell pack={pack} handleTitleClick={handleTitleClick} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{dateTimeUtils.formatDate(pack.licenseIssued)}</Table.Cell>
      <ExpirationCell
        width={columnOptions[2].width}
        isExpired={pack.isExpired}
        date={isOwnPack ? "Never" : dateTimeUtils.formatDate(pack.expirationDate)}
      />
      {licenseRemovalEnabled && (
        <Table.Cell className={styles["align-right"]} width={columnOptions[3].width}>
          <RemoveLicenseButton
            id={pack.id}
            isSelected={isSelected}
            onRemoveLicenseClick={onRemoveLicenseClick}
            issueLicensePermissionPredicate={issueLicensePermissionPredicate}
          />
        </Table.Cell>
      )}
    </>
  );
};

export default UserPackListRow;
