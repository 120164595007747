import React from "react";

import { QuestionRendererPropsBase } from "../../../../Common/props";
import { QuestionsMap } from "../Providers/QuestionsMap";
import { IQuestion } from "../../../../Common/models";
import { AssessmentQuestionType } from "../../../types/state";

export type QuestionBuilderProps<T> = QuestionRendererPropsBase<IQuestion<T>, T>;

export const QuestionBuilder = <T,>(props: QuestionBuilderProps<T>) => {
  const { question, validatedFieldProps, index, onBlur, onChanged, disabled, propertyNamePrefix } = props;
  const { Component } = QuestionsMap[question.questionType as AssessmentQuestionType];
  return (
    <Component
      disabled={disabled}
      index={index}
      onBlur={onBlur}
      onChanged={onChanged}
      question={question.question as any}
      validatedFieldProps={validatedFieldProps}
      propertyNamePrefix={propertyNamePrefix}
    />
  );
};
