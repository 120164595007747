import { FC, useMemo } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../Application/globaltypes/redux";
import CustomerPackReport from "./Customer/CustomerPackReport";
import SpPackReport from "./SolutionProvider/SpPackReport";
import { AccountTypes } from "features/Accounts/types";
import { NotImplemented } from "../../../components";

import "./reportingPacks.scss";

export const ReportingPacks: FC<PropsFromRedux> = ({ accountType }) => {
  const body = useMemo(() => {
    switch (accountType) {
      case AccountTypes.Customer:
      case AccountTypes.CSP:
      case AccountTypes.Copilot:
        return <CustomerPackReport />;
      case AccountTypes.BSI:
      case AccountTypes.MSP:
      case AccountTypes.ChannelPartner:
      case AccountTypes.StrategicPartner:
        return (
          <SpPackReport />
        );
      default:
        return <NotImplemented message="Check back for analytics, reports and more!" />;
    }
  }, [accountType]);
  return <div className="nested-content reportingPacksRoot">{body}</div>;
};

const mapStateToProps = (state: RootState) => ({
  accountType: state.userProfile.accountTypeId,
});

const connector = connect(mapStateToProps, {});
export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ReportingPacks);
