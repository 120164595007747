import { FC, useCallback, useEffect } from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import cn from "classnames";
import { ListViewWithHeaderAndPanel } from "../../listView";
import UserInfoCell from "../../userInfoCell/UserInfoCell";
import { Tooltip } from "../../common/tooltip";
import Restricted from "../../../features/Application/Restricted";
import AccessRestrictedMessage from "../../restrictedRoute/AccessRestrictedMessage";
import { ListViewWithHeaderAndPanelProps } from "../../listView/listViewWithHeaderAndPanel/listViewWithHeaderAndPanelProps";
import { ColumnOptions, UserWithPackAccessType } from "../../../interfaces";
import { AppDispatch, RootState } from "../../../features/Application/globaltypes/redux";
import { isEmpty } from "lodash";
import UsersNoResultsDragNDrop from "../../../views/people/users/UsersNoResultsDragNDrop";
import { RolePermissions, SortingDirection } from "../../../enums";
import { bindActionCreators } from "redux";
import * as usersFilterActions from "../../../features/People/Users/UsersOverview/state/filterActionCreators";
import { connect, ConnectedProps } from "react-redux";
import { ModalWithStepsStep } from "../../modal/ModalWithSteps";
import { Filters } from "../../../utils/queryUtils";
import { fetchUsersAvailableForLicensing } from "../../../features/Licensing/Packs/state/thunks/packUsersAvailableForLicensingThunk";
import SearchInput from "../../searchInput/SearchInput";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import { UsersFilterForm } from "../../filterForms";
import "../assignmentListStep.scss";
import styles from "./availableUsersStep.module.scss";

export interface Props extends ModalWithStepsStep, ListViewWithHeaderAndPanelProps<UserWithPackAccessType> {
  search?: string;
  filter?: object;
  onSearchChanged(value: string): void;
}

const columnOptions: ColumnOptions[] = [
  {
    name: "Name",
    width: 6,
    isSortable: true,
  },
  {
    name: "Department",
    width: 5,
    isSortable: true,
  },
  {
    name: "Job title",
    width: 5,
    isSortable: true,
  },
];

export const AvailableUsersStep: FC<Omit<Props, "loadPage"> & PropsFromRedux> = ({
  packId,
  searchTerm,
  onSearchChanged,
  getAvailableUsers,
  filterOptions,
  ...props
}) => {
  const isFiltered = !!props.search || !isEmpty(props.filter);

  const fetchUsers = useCallback(
    (
      skip: number,
      top: number,
      sortingColumnName: string,
      sortingDirection: SortingDirection,
      appliedFilter: Filters,
    ) =>
      getAvailableUsers(packId, {
        skip,
        top,
        sortingColumnName,
        sortingDirection,
        appliedFilter,
        search: searchTerm,
      }),
    [searchTerm, packId, getAvailableUsers],
  );

  useEffect(() => {
    const {
      usersFilterActions: { getFilterOptions },
    } = props;
    getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSelectDisabled = (u: UserWithPackAccessType) => u.isFullAccess || u.hasLicense;
  const isWarning = (u: UserWithPackAccessType) => u.isFullAccess || u.hasLicense;

  const renderFilterForm = () => {
    return (
      // @ts-ignore
      <UsersFilterForm />
    );
  };

  const buildTableBody = () => {
    return (user: UserWithPackAccessType) => {
      const readOnlyClass = cn({
        rowDisabled: user.isFullAccess || user.hasLicense,
      });

      return (
        <>
          <Table.Cell width={columnOptions[0].width} className={readOnlyClass}>
            <UserInfoCell user={user} />
          </Table.Cell>
          <Table.Cell width={columnOptions[1].width} className={readOnlyClass}>
            <Tooltip target={<TextTruncate>{user.department}</TextTruncate>} content={user.department} />
          </Table.Cell>
          <Table.Cell width={columnOptions[2].width} className={cn(readOnlyClass, styles.warnings)}>
            <Tooltip target={<TextTruncate>{user.jobTitle}</TextTruncate>} content={user.jobTitle} />
            {(user.isFullAccess || user.hasLicense) && (
              <Popup
                hideOnScroll
                position="top center"
                size="small"
                className="popup-warning warning"
                content="The user already has full access to this pack."
                trigger={<Icon link className="fa fa-exclamation-circle" size="huge" />}
              />
            )}
          </Table.Cell>
        </>
      );
    };
  };
  return (
    <Restricted permissions={[RolePermissions.UsersView]} placeholder={<AccessRestrictedMessage />}>
      <div className="assignment-list-step">
        <ListViewWithHeaderAndPanel
          {...props}
          isSelectDisabled={isSelectDisabled}
          isWarning={isWarning}
          renderFilterForm={renderFilterForm}
          filterOptions={{ showRoleFilter: false, ...filterOptions }}
          columnOptions={columnOptions}
          sortingColumnName={columnOptions[0].name}
          sortingDirection={SortingDirection.Ascending}
          loadPage={fetchUsers}
          noResultsContent={<UsersNoResultsDragNDrop filtered={isFiltered} />}
          buildTableBody={buildTableBody()}
          renderSearch={() => (
            <SearchInput placeholder="Search for users..." onChange={onSearchChanged} defaultValue={searchTerm} />
          )}
        />
      </div>
    </Restricted>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    packId: state.packs.purchasedPack.packPurchasedView.pack.id,
    filterOptions: state.people.usersOverview.filterOptions,
    filterOptionsLoading: state.people.usersOverview.filterOptions.isLoading,
    items: state.packs.purchasedPack.issueLicenseModal.usersAvailableForLicensing.items,
    itemsAmount: state.packs.purchasedPack.issueLicenseModal.usersAvailableForLicensing.itemsCount,
    isLoading: state.packs.purchasedPack.issueLicenseModal.usersAvailableForLicensing.isLoading,
    searchTerm: state.packs.purchasedPack.issueLicenseModal.usersAvailableForLicensing.usersSearchTerm,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  usersFilterActions: bindActionCreators(usersFilterActions, dispatch),
  getAvailableUsers: bindActionCreators(fetchUsersAvailableForLicensing, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AvailableUsersStep);
