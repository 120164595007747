import accountsDataService from "../../../services/accountsDataService";
import { Dispatcher } from "../../../../../interfaces/redux";
import { SortingDirection } from "../../../../../enums";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slices/allAccountsOverviewSlice";

export const fetchAccounts =
  (
    skip: number,
    take: number,
    orderProp: string,
    orderDirection: SortingDirection,
    searchText?: string,
    filter?: string,
  ) =>
  async (dispatch: Dispatcher) => {
    dispatch(fetchBegin());

    try {
      const { items, itemsCount } = await accountsDataService.getAccounts(
        skip,
        take,
        orderProp,
        orderDirection,
        searchText,
        filter,
      );

      dispatch(fetchSuccess({ items, totalCount: itemsCount }));
    } catch (error) {
      dispatch(fetchFailure(error));
    }
  };
