import { createReducer } from "../../../../../utils/reduxUtils";
import * as actionTypes from "../actionTypes/packDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/packEntityStateActionType";
import { PackDetailsState, PackView, Pack } from "../../types/state";
import { PayloadAction, MidnightActionPayload } from "../../../../../interfaces/redux";
import { GetPackDetailsResponse } from "../../types/requests";
import { DraggableAsset, DroppedAsset, DroppedItem } from "../../../../../interfaces/assetToDropInfo";
import { WithId } from "types";

const initialState: PackDetailsState = {
  pack: {
    id: -1,
    hasBeenPublished: false,
    title: "",
    thumbnailImageUrl: "",
    description: "",
    mediaItems: [
      { url: "", orderNumber: 1, typeId: 1 },
      { url: "", orderNumber: 2, typeId: 1 },
      { url: "", orderNumber: 3, typeId: 1 },
      { url: "", orderNumber: 4, typeId: 1 },
    ],
    overviewVideoUrl: "",
    longDescription: "",
    dateModified: undefined,
    isDraft: true,
    packFilters: [],
    items: [],
    typeId: undefined,
    displayItemsCount: 0,
    isTrialAllowed: false,
    trialPeriodId: undefined,
  },
  isLoading: false,
  isContentLoading: false,
  isPrivatePackLoading: false,
  isPackLoaded: false,
  selectedCards: [],
};

const normalizePackPayload = (initial: PackView, payload: Pack): PackView => {
  const mediaItems = [...initial.mediaItems];
  for (const mi of payload.mediaItems) {
    const index = mi.orderNumber ? mi.orderNumber - 1 : null;
    if (index != null) {
      mediaItems[index] = {
        url: mi.url,
        orderNumber: mi.orderNumber,
        typeId: mi.typeId,
      };
    }
  }

  mediaItems.forEach((mi) => {
    if (mi.url !== "" && !payload.mediaItems.some((x) => x.orderNumber === mi.orderNumber)) {
      mi.url = "";
    }
  });

  return {
    ...initial,
    ...payload,
    trialPeriodId: payload.trialPeriodId ?? null,
    mediaItems,
  };
};

function replaceIfExistsById<Type extends WithId, Type1 extends WithId>(
  originalArray: Type[] | Type1[],
  newArray: Type1[],
  predicate?: (item: Type | Type1, updatedItem: Type1) => boolean,
) {
  const result = originalArray.map((a) => ({ ...a, id: Number(a.id) })); // clone the original array
  newArray.forEach((newItem) => {
    result.forEach((item, index) => {
      if (Number(newItem.id) === item.id) {
        if (predicate) {
          if (predicate(item, newItem)) {
            result[index] = newItem;
          }
        } else {
          result[index] = newItem;
        }
      }
    });
  });
  return result;
}

const getHandlers = (failureHandler: Function) => {
  const { getPackBegin, getPackSuccess, getPackFailure } = actionTypes;

  const getPackBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getPackSuccessHandler = (state: PackDetailsState, action: PayloadAction<Pack>): PackDetailsState => ({
    ...state,
    pack: {
      ...normalizePackPayload(state.pack, action.payload),
    },
    isPackLoaded: true,
    isLoading: false,
  });

  return {
    [getPackBegin]: getPackBeginHandler,
    [getPackSuccess]: getPackSuccessHandler,
    [getPackFailure]: failureHandler,
  };
};

const getIsPrivatePackHandlers = (failureHandler: Function) => {
  const { getPackIsPrivateBegin, getPackIsPrivateSuccess, getPackIsPrivateFailure } = actionTypes;

  const getIsPrivatePackBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
    isPrivatePackLoading: true,
  });

  const getIsPrivatePackSuccessHandler = (
    state: PackDetailsState,
    action: PayloadAction<boolean>,
  ): PackDetailsState => ({
    ...state,
    isPrivatePackLoading: false,
  });

  return {
    [getPackIsPrivateBegin]: getIsPrivatePackBeginHandler,
    [getPackIsPrivateSuccess]: getIsPrivatePackSuccessHandler,
    [getPackIsPrivateFailure]: failureHandler,
  };
};

const getContentHandlers = (failureHandler: Function) => {
  const { getPackContentBegin, getPackContentSuccess, getPackContentFailure } = actionTypes;

  const getPackContentBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
    isContentLoading: true,
    pack: {
      ...state.pack,
      items: [],
    },
  });

  const getPackContentSuccessHandler = (
    state: PackDetailsState,
    action: PayloadAction<GetPackDetailsResponse>,
  ): PackDetailsState => ({
    ...state,
    pack: {
      ...state.pack,
      items: action.payload.items.map((a) => {
        return { id: Number(a.id), type: a.type, isReadOnly: a.isReadOnly };
      }),
      displayItemsCount: Number(process.env.REACT_APP_LOAD_ITEMS_COUNT),
    },
    isContentLoading: false,
  });

  return {
    [getPackContentBegin]: getPackContentBeginHandler,
    [getPackContentSuccess]: getPackContentSuccessHandler,
    [getPackContentFailure]: failureHandler,
  };
};

const matchingTypePredicate = (item: DroppedItem, assetWithDetails: DroppedItem) => item.type === assetWithDetails.type;

const addPlaceholderImages = (items: DroppedItem[]) =>
  items.map((item) => {
    if (!item.thumbnailUrl) {
      item.thumbnailUrl = process.env.REACT_APP_PLACEHOLDER_IMAGE_URL;
    }
    return item;
  });

const updatePackDisplayItemsCountHandler = (
  state: PackDetailsState,
  action: PayloadAction<number>,
): PackDetailsState => {
  return {
    ...state,
    pack: {
      ...state.pack,
      displayItemsCount: action.payload,
    },
  };
};

const getItemDetailsSuccessHandler = (
  state: PackDetailsState,
  action: PayloadAction<DroppedAsset[]>,
): PackDetailsState => {
  return {
    ...state,
    pack: {
      ...state.pack,
      items: addPlaceholderImages(replaceIfExistsById(state.pack.items, action.payload, matchingTypePredicate)),
    },
  };
};

const getPdfsDetailsHandlers = (failureHandler: Function) => {
  const { getPdfsDetailsBegin, getPdfsDetailsSuccess, getPdfsDetailsFailure } = actionTypes;

  const getPdfsDetailsBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [getPdfsDetailsBegin]: getPdfsDetailsBeginHandler,
    [getPdfsDetailsSuccess]: getItemDetailsSuccessHandler,
    [getPdfsDetailsFailure]: failureHandler,
  };
};

const getAssetsDetailsHandlers = (failureHandler: Function) => {
  const { getAssetsDetailsBegin, getAssetsDetailsSuccess, getAssetsDetailsFailure } = actionTypes;

  const getAssetsDetailsBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [getAssetsDetailsBegin]: getAssetsDetailsBeginHandler,
    [getAssetsDetailsSuccess]: getItemDetailsSuccessHandler,
    [getAssetsDetailsFailure]: failureHandler,
  };
};

const getSurveysDetailsHandlers = (failureHandler: Function) => {
  const { getSurveysDetailsBegin, getSurveysDetailsSuccess, getSurveysDetailsFailure } = actionTypes;

  const getSurveysDetailsBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [getSurveysDetailsBegin]: getSurveysDetailsBeginHandler,
    [getSurveysDetailsSuccess]: getItemDetailsSuccessHandler,
    [getSurveysDetailsFailure]: failureHandler,
  };
};

const getAssessmentsDetailsHandlers = (failureHandler: Function) => {
  const { getAssessmentsDetailsBegin, getAssessmentsDetailsSuccess, getAssessmentsDetailsFailure } = actionTypes;

  const getAssessmentsDetailsBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [getAssessmentsDetailsBegin]: getAssessmentsDetailsBeginHandler,
    [getAssessmentsDetailsSuccess]: getItemDetailsSuccessHandler,
    [getAssessmentsDetailsFailure]: failureHandler,
  };
};

const getFlowsDetailsHandlers = (failureHandler: Function) => {
  const { getFlowsDetailsBegin, getFlowsDetailsSuccess, getFlowsDetailsFailure } = actionTypes;

  const getFlowsDetailsBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [getFlowsDetailsBegin]: getFlowsDetailsBeginHandler,
    [getFlowsDetailsSuccess]: getItemDetailsSuccessHandler,
    [getFlowsDetailsFailure]: failureHandler,
  };
};

const getEmailsDetailsHandlers = (failureHandler: Function) => {
  const { getEmailsDetailsBegin, getEmailsDetailsSuccess, getEmailsDetailsFailure } = actionTypes;

  const getEmailsDetailsBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [getEmailsDetailsBegin]: getEmailsDetailsBeginHandler,
    [getEmailsDetailsSuccess]: getItemDetailsSuccessHandler,
    [getEmailsDetailsFailure]: failureHandler,
  };
};

const getMessagesDetailsHandlers = (failureHandler: Function) => {
  const { getMessagesDetailsBegin, getMessagesDetailsSuccess, getMessagesDetailsFailure } = actionTypes;

  const getMessagesDetailsBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [getMessagesDetailsBegin]: getMessagesDetailsBeginHandler,
    [getMessagesDetailsSuccess]: getItemDetailsSuccessHandler,
    [getMessagesDetailsFailure]: failureHandler,
  };
};

const getEventsDetailsHandlers = (failureHandler: Function) => {
  const { getEventsDetailsBegin, getEventsDetailsSuccess, getEventsDetailsFailure } = actionTypes;

  const getEventsDetailsBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [getEventsDetailsBegin]: getEventsDetailsBeginHandler,
    [getEventsDetailsSuccess]: getItemDetailsSuccessHandler,
    [getEventsDetailsFailure]: failureHandler,
  };
};

const manipulatePackBeginHandler = (state: PackDetailsState): PackDetailsState => ({
  ...state,
  isLoading: true,
  pack: {
    ...state.pack,
    items: [],
    displayItemsCount: 0,
  },
});

const fetchLockedHandlers = (failureHandler: Function) => {
  const { fetchLockedPackBegin, fetchLockedPackSuccess, fetchLockedPackFailure } = entityStateActionTypes;

  const fetchLockedPackSuccessHandler = (
    state: PackDetailsState,
    action: PayloadAction<PackView>,
  ): PackDetailsState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;
    const entityId = payload.entityId as number;
    if (state.pack.id !== entityId) {
      return { ...state };
    }

    return {
      ...state,
      pack: {
        ...state.pack,
        isDraft: true,
      },
      isLoading: false,
    };
  };

  return {
    [fetchLockedPackBegin]: manipulatePackBeginHandler,
    [fetchLockedPackSuccess]: fetchLockedPackSuccessHandler,
    [fetchLockedPackFailure]: failureHandler,
  };
};

const publishHandlers = (failureHandler: Function) => {
  const { publishPackBegin, publishPackSuccess, publishPackFailure } = entityStateActionTypes;

  const publishPackSuccessHandler = (state: PackDetailsState, action: PayloadAction<PackView>): PackDetailsState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;
    const entityId = payload.entityId as number;
    if (state.pack.id !== entityId) {
      return { ...state };
    }

    return {
      ...state,
      pack: {
        ...state.pack,
        isDraft: false,
      },
      isLoading: false,
    };
  };

  return {
    [publishPackBegin]: manipulatePackBeginHandler,
    [publishPackSuccess]: publishPackSuccessHandler,
    [publishPackFailure]: failureHandler,
  };
};

const discardHandlers = (failureHandler: Function) => {
  const { fetchDiscardPackBegin, fetchDiscardPackSuccess, fetchDiscardPackFailure } = entityStateActionTypes;

  const fetchDiscardPackBeginHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchDiscardPackSuccessHandler = (state: PackDetailsState): PackDetailsState => ({
    ...state,
  });

  return {
    [fetchDiscardPackBegin]: fetchDiscardPackBeginHandler,
    [fetchDiscardPackSuccess]: fetchDiscardPackSuccessHandler,
    [fetchDiscardPackFailure]: failureHandler,
  };
};

const packDetailsHandlers = () => {
  const {
    savePack,
    clearPack,
    setPack,
    addItemToPack,
    removeItemFromPack,
    updatePackDisplayItemsCount,
    addSelectedCards,
    removeSelectedCard,
    clearSelectedCards,
  } = actionTypes;

  const failureHandler = (state: PackDetailsState, action: PayloadAction<Error>): PackDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const savePackHandler = (state: PackDetailsState, action: PayloadAction<PackView>): PackDetailsState => ({
    ...state,
    pack: action.payload,
  });

  const clearPackHandler = (): PackDetailsState => ({
    ...initialState,
  });

  const setPackHandler = (state: PackDetailsState, action: PayloadAction<Pack>): PackDetailsState => ({
    ...state,
    pack: {
      ...normalizePackPayload(state.pack, action.payload),
    },
  });

  const addItemHandler = (state: PackDetailsState, action: PayloadAction<DroppedAsset[]>): PackDetailsState => {
    return {
      ...state,
      pack: {
        ...state.pack,
        items: [...action.payload, ...state.pack.items],
        displayItemsCount: state.pack.displayItemsCount + 1,
      },
    };
  };

  const removeItemHandler = (state: PackDetailsState, action: PayloadAction<DroppedAsset>): PackDetailsState => ({
    ...state,
    pack: {
      ...state.pack,
      items: state.pack.items.filter((i) => !(i.id === action.payload.id && i.type === action.payload.type)),
      displayItemsCount: state.pack.displayItemsCount - 1,
    },
  });

  const addSelectedCardsHandler = (
    state: PackDetailsState,
    action: PayloadAction<DraggableAsset[]>,
  ): PackDetailsState => ({
    ...state,
    selectedCards: [
      ...state.selectedCards,
      ...action.payload.map((item) => ({ ...item, isReadOnly: !state.pack.isDraft })),
    ],
  });

  const removeSelectedCardHandler = (state: PackDetailsState, action: PayloadAction<number>): PackDetailsState => ({
    ...state,
    selectedCards: state.selectedCards.filter((item) => item.id !== action.payload),
  });

  const clearSelectedCardsHandlers = (state: PackDetailsState): PackDetailsState => ({
    ...state,
    selectedCards: [],
  });

  return {
    [savePack]: savePackHandler,
    [clearPack]: clearPackHandler,
    [setPack]: setPackHandler,
    [addItemToPack]: addItemHandler,
    [removeItemFromPack]: removeItemHandler,
    [updatePackDisplayItemsCount]: updatePackDisplayItemsCountHandler,
    [addSelectedCards]: addSelectedCardsHandler,
    [removeSelectedCard]: removeSelectedCardHandler,
    [clearSelectedCards]: clearSelectedCardsHandlers,
    ...getIsPrivatePackHandlers(failureHandler),
    ...getHandlers(failureHandler),
    ...getContentHandlers(failureHandler),
    ...getPdfsDetailsHandlers(failureHandler),
    ...getAssetsDetailsHandlers(failureHandler),
    ...getSurveysDetailsHandlers(failureHandler),
    ...getAssessmentsDetailsHandlers(failureHandler),
    ...getFlowsDetailsHandlers(failureHandler),
    ...getEventsDetailsHandlers(failureHandler),
    ...getEmailsDetailsHandlers(failureHandler),
    ...getMessagesDetailsHandlers(failureHandler),
    ...publishHandlers(failureHandler),
    ...fetchLockedHandlers(failureHandler),
    ...discardHandlers(failureHandler),
  };
};

export const packDetailsReducer = createReducer(initialState, [packDetailsHandlers]);
