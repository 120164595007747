import { DetailsSubHeader } from "../../../../components/sectionHeader";
import { Props as DetailsSubHeaderProps } from "../../../../components/sectionHeader/sectionSubHeader/SectionSubHeader";
import { RootState } from "../../../Application/globaltypes/redux";
import { connect } from "react-redux";
import { surveysStateSelector } from "../state/surveyReducer";

const mapStateToProps = (state: RootState, ownProps: DetailsSubHeaderProps & { showLastModified: boolean }) => {
  const base = surveysStateSelector(state).base;
  return {
    isUpdateInProgress: base.surveysEntityStateReducer.isEntityCommandInProgress && ownProps.isUpdateInProgress,
    lastModifiedDateTime: ownProps.showLastModified
      ? base.surveysEntityStateReducer.lastModifiedDateTime ?? ownProps.lastModifiedDateTime
      : undefined,
  };
};

export default connect(mapStateToProps)(DetailsSubHeader);
