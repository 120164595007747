import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";

import { useDimensions } from "../../../hooks/useDimensions";
import { Margin } from "../types/Shared";
import { ChartPopup } from "../shared/ChartPopup/ChartPopup";
import { Props, EnterTransitionType, UpdateTransitionType } from "../types/PieChart";
import { DEFAULT_COLOR_RANGE } from "../shared/constants";
import { APPEND_ONCE, calculateChartDimensions, getColorScale, getTranslateString } from "../shared/helpers";
import { widthAndHeightInvalid } from "../utils/utils";

import "./pieChart.scss";

const DEFAULT_MARGIN: Margin = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

const DEFAULT_TOOLTIP_FORMAT = (key: string, value: number) => `${key}: ${value}`;

export const PieChart: React.FC<Props> = ({
  margins = DEFAULT_MARGIN,
  data,
  sizePercentage: size = 75,
  colorRange = DEFAULT_COLOR_RANGE,
  onClick,
  transitionDurationInMs = 1000,
  padAngle = 0,
  innerRadiusPercentage = 0,
  tooltipFormatter = DEFAULT_TOOLTIP_FORMAT,
  innerText,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [popupPosition, setPopupPosition] = useState({
    left: -1,
    top: -1,
  });

  const onClickRef = useRef(onClick);
  const tooltipFormatterRef = useRef(tooltipFormatter);

  useEffect(() => {
    onClickRef.current = onClick;
    tooltipFormatterRef.current = tooltipFormatter;
  }, [onClick, tooltipFormatter]);

  const [measurements, measuredRef] = useDimensions();

  const { totalWidth, totalHeight, innerWidth, innerHeight } = calculateChartDimensions(measurements, margins);

  const outerRadius = useMemo(
    () => (Math.min(innerWidth, innerHeight) * (size / 100)) / 2,
    [innerWidth, innerHeight, size],
  );

  const calculatedInnerRadius = useMemo(
    () => (outerRadius * innerRadiusPercentage) / 100,
    [outerRadius, innerRadiusPercentage],
  );

  // Storing offset as a ref instead of memoized value
  // prevents issue where entrance transition fails on resize
  const centerOffsetRef = useRef({
    x: margins.left + innerWidth / 2,
    y: margins.top + innerHeight / 2,
  });
  useEffect(() => {
    centerOffsetRef.current = {
      x: margins.left + innerWidth / 2,
      y: margins.top + innerHeight / 2,
    };
  }, [margins.left, margins.top, innerWidth, innerHeight]);

  /**
   * Start D3 Functions
   */
  const keys = useMemo(() => Object.keys(data), [data]);
  const values = useMemo(() => Object.values(data), [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colorScale = useMemo(() => getColorScale(colorRange, keys.length), [keys.length]);

  const pieGenerator = useMemo(() => d3.pie<number>().padAngle(padAngle).sort(null), [padAngle]);

  const arcs = useMemo(() => pieGenerator(values), [pieGenerator, values]);
  const arc = useMemo(
    () => d3.arc<d3.PieArcDatum<number>>().innerRadius(calculatedInnerRadius).outerRadius(outerRadius),
    [calculatedInnerRadius, outerRadius],
  );

  const mouseOver = useCallback(
    (d: d3.PieArcDatum<number>, index: number) => {
      setPopupOpen(true);
      setPopupText(tooltipFormatterRef.current(keys[index], d.value));
    },
    [keys],
  );

  const mouseMove = useCallback(() => {
    const mouseEvent = d3.event as MouseEvent;

    setPopupPosition({
      left: mouseEvent.clientX,
      top: mouseEvent.clientY,
    });
  }, []);

  const mouseLeave = useCallback(() => setPopupOpen(false), []);

  const onClickHandler = useCallback(
    (d: d3.PieArcDatum<number>, index: number) => {
      onClickRef.current?.(keys[index], d.value);
    },
    [keys],
  );

  const preflightChecksFailed = useMemo<boolean>(
    () => widthAndHeightInvalid(innerWidth, innerHeight),
    [innerHeight, innerWidth],
  );

  /**
   * End D3 Functions
   */

  useEffect(() => {
    if (preflightChecksFailed) return;

    d3.select(svgRef.current!)
      .selectAll(".centering-group")
      .data(APPEND_ONCE)
      .join(
        (enter) =>
          enter
            .append("g")
            .attr("class", "centering-group")
            .attr("transform", getTranslateString(centerOffsetRef.current.x, centerOffsetRef.current.y)),
        (update) => update.attr("transform", getTranslateString(centerOffsetRef.current.x, centerOffsetRef.current.y)),
      );
  }, [preflightChecksFailed, innerWidth, innerHeight]);

  // Draw paths
  useEffect(() => {
    if (preflightChecksFailed) return;
    const svg = d3.select(svgRef.current!).select<SVGGElement>(".centering-group");
    const angleInterpolation = d3.interpolate(pieGenerator.startAngle()([]), pieGenerator.endAngle()([]));

    // https://codepen.io/jongeorge1/pen/jONqjad
    const enterTransition = (selection: EnterTransitionType) => {
      selection
        .transition()
        .duration(transitionDurationInMs)
        .attrTween("d", (d) => {
          let originalEnd = d.endAngle;
          return (t) => {
            let currentAngle = angleInterpolation(t);
            if (currentAngle < d.startAngle) return "";

            d.endAngle = Math.min(currentAngle, originalEnd);
            return arc(d)!;
          };
        });
    };

    const updatePaths = (selection: UpdateTransitionType) => {
      selection.attr("d", (d) => arc(d)!).attr("fill", (_, i) => colorScale(i)!);
    };

    const paths = svg
      .selectAll("path")
      .data(arcs)
      .join(
        (enter) => {
          return enter
            .append("path")
            .attr("fill", (_, i) => colorScale(i)!)
            .call(enterTransition);
        },
        (update) => update.call(updatePaths),
      )
      .on("mouseover", mouseOver)
      .on("mousemove", mouseMove)
      .on("mouseleave", mouseLeave)
      .on("click", onClickHandler);

    return () => {
      paths.on("mouseover", null).on("mousemove", null).on("mouseleave", null).on("click", null);
    };
  }, [
    arc,
    arcs,
    colorScale,
    mouseLeave,
    mouseMove,
    mouseOver,
    onClickHandler,
    pieGenerator,
    preflightChecksFailed,
    transitionDurationInMs,
  ]);

  // Draw text
  useEffect(() => {
    if (preflightChecksFailed) return;

    const svg = d3.select(svgRef.current!).select(".centering-group");
    if (innerText) {
      const TITLE_CLASS = "innerTextTitle";
      const VALUE_CLASS = "innerTextValue";

      const upperClass = innerText.reverseStyle ? VALUE_CLASS : TITLE_CLASS;
      const lowerClass = innerText.reverseStyle ? TITLE_CLASS : VALUE_CLASS;

      const textContainer = svg
        .selectAll<SVGTextElement, boolean>(".innerText")
        .data(APPEND_ONCE)
        .join(
          (enter) =>
            enter
              .append("text")
              .attr("class", "innerText")
              .attr("x", "0")
              .attr("y", "0")
              .attr("dy", innerText.reverseStyle ? 0 : "-12"),
          (update) => update.attr("dy", innerText.reverseStyle ? 0 : "-12"),
        );
      textContainer
        .selectAll<SVGTSpanElement, boolean>(".title")
        .data([innerText.title])
        .join(
          (enter) => enter.append("tspan").attr("class", `title ${upperClass}`).attr("x", "0").text(innerText.title),
          (update) => update.attr("class", `title ${upperClass}`).text(innerText.title),
        );
      textContainer
        .selectAll<SVGTSpanElement, string>(".value")
        .data([innerText.value])
        .join(
          (enter) =>
            enter
              .append("tspan")
              .attr("class", `value ${lowerClass}`)
              .text(innerText.value)
              .attr("x", "0")
              .attr("dy", "1.5em"),
          (update) => update.attr("class", `value ${lowerClass}`).text(innerText.value),
        );
    } else {
      svg.selectAll(".innerText").remove();
    }
  }, [innerText, preflightChecksFailed]);

  return (
    <div className="pieChartRoot" ref={measuredRef}>
      <svg ref={svgRef} data-testid="pie chart svg" viewBox={`0 0 ${totalWidth} ${totalHeight}`} />
      <ChartPopup open={popupOpen} {...popupPosition}>
        {popupText}
      </ChartPopup>
    </div>
  );
};
