import { FieldArray } from "formik";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import Expand from "../expand/Expand";
import ExpressionBuilder from "./ExpressionBuilder";

import cn from "classnames";
import "./collapsibleExpressionBuilder.scss";

class CollapsibleExpressionBuilder extends Component {
  calculateAppliedExpressions = (items) => {
    return items.filter((item) =>
      Object.values(item).every((x) => {
        if (Array.isArray(x)) {
          return x.length > 0;
        }
        const isMultiSelectInput = x && Array.isArray(x.selected);
        if (isMultiSelectInput) {
          return x.selected.length > 0;
        }

        return x || x === 0 || x === undefined;
      }),
    ).length;
  };

  render() {
    const {
      title,
      onChange,
      getInputs,
      items,
      arrayName,
      columnTitles,
      getDefaultNewItem,
      columnsAmount,
      calculateAppliedExpressions,
      className,
      isReadOnly,
      renderHeader,
    } = this.props;

    const appliedExpressionsCount = calculateAppliedExpressions
      ? calculateAppliedExpressions(items)
      : this.calculateAppliedExpressions(items);

    return (
      <div className={cn("collapsible-expression-builder", className)}>
        <Expand title={title} label={appliedExpressionsCount > 0 ? appliedExpressionsCount : null} expandedByDefault>
          {renderHeader && renderHeader()}
          <FieldArray
            name={arrayName}
            render={(arrayHelpers) => (
              <ExpressionBuilder
                onChange={onChange}
                onAdd={() => {
                  arrayHelpers.unshift({ ...getDefaultNewItem() });
                }}
                onRemove={arrayHelpers.remove}
                getInputs={getInputs}
                items={items}
                columnTitles={columnTitles}
                getDefaultNewItem={getDefaultNewItem}
                columnsAmount={columnsAmount}
                isReadOnly={isReadOnly}
                className="expression-builder-in-expand"
              />
            )}
          />
        </Expand>
      </div>
    );
  }
}

CollapsibleExpressionBuilder.propTypes = {
  title: PropTypes.string.isRequired,
  getInputs: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  arrayName: PropTypes.string.isRequired,
  columnTitles: PropTypes.array.isRequired,
  getDefaultNewItem: PropTypes.func.isRequired,
  columnsAmount: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  calculateAppliedExpressions: PropTypes.func,
  className: PropTypes.string,
  isReadOnly: PropTypes.bool,
  renderHeader: PropTypes.func,
};

export default CollapsibleExpressionBuilder;
