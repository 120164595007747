import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import { useCallback, useMemo, useState } from "react";

import CardReporting from "components/cards/CardReporting/CardReporting";
import { ChartWrapper, LineChart } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "featureFlags";
import { usePerformanceBreadcrumbs } from "features/Library/Common/Hooks/usePerformanceBreadcrumbs";
import { noData, validLineData } from "features/Library/Common/utils/performanceUtils";
import { sharedAccountReportingLineProps } from "features/Reporting/Content/shared";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { AccountsTable } from "./components/AccountsTable";
import { EnterpriseTable } from "./components/EnterpriseTable";
import {
  fetchPeopleOverviewLineCharts,
  fetchPeopleOverviewStatistics,
  sharedOverviewLineProps,
  yearDateRange,
} from "./utils";
import moment from "moment";
import { RootState } from "features/Application/globaltypes/redux";
import { connect } from "react-redux";

import "features/Library/Common/utils/performanceSCSSUtils.scss";
import "../peopleStyles.scss";

export type Props = PropsFromRedux & {
  scrollToTop: () => void;
};

// Remove account id once data team is finished with bsi table.
export const PeopleOverviewReport = ({ scrollToTop, accountId }: Props) => {
  const reportEnabled = !!useFeatureFlag(FeatureFlags.MauReports);
  const dateFilter = useMemo(() => yearDateRange(), []);
  // Remove account id once data team is finished with bsi table.
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(accountId);

  const lineChartQuery = useQuery({
    queryFn: fetchPeopleOverviewLineCharts,
    queryKey: ["PeopleOverviewLineCharts", dateFilter, selectedAccountId, [], true],
    enabled: reportEnabled,
  });

  const cardsQuery = useQuery({
    queryFn: fetchPeopleOverviewStatistics,
    queryKey: ["PeopleOverviewStatistics", dateFilter, selectedAccountId, [], true],
    enabled: reportEnabled,
  });

  const { registerBreadcrumb, hasBreadcrumbsToDisplay, domElements } = usePerformanceBreadcrumbs();

  const handleAccountSelect = useCallback(
    (id: number, name: string) => {
      setSelectedAccountId(id);
      registerBreadcrumb({ text: name, action: () => setSelectedAccountId(null) });
      scrollToTop();
    },
    [registerBreadcrumb, scrollToTop],
  );

  const tooltipFormatter = useCallback((x: Date, y: number) => {
    return `${moment(x).format("MMM 'YY")}: ${y.toLocaleString()} Users`;
    // Below is future state for when Interaction % are added to the endpoint
    // const xToString = x.toString();
    // const i = lineChartQuery.data!.activeUsers.xData[0].findIndex((date: Date) => date.toString() === xToString);
    // return i === -1 ? y.toLocaleString() : lineChartQuery.data!.activeUsersTooltips[i];
  }, []);

  return (
    <div className="performanceRoot">
      <div className="performanceHeader peopleActivityHeader">
        {hasBreadcrumbsToDisplay ? (
          <div className="breadCrumbs">{domElements}</div>
        ) : (
          <h2 className="performanceTitle">Summary</h2>
        )}
      </div>

      <div className={cn("lineChartContainer")}>
        <ChartWrapper titles={["Monthly Active Users"]} showLegend legendLabels={["Users"]}>
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isFetched && validLineData(lineChartQuery.data!.activeUsers.yData) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineChartQuery.data!.activeUsers}
                {...sharedOverviewLineProps}
                tooltipFormatter={tooltipFormatter}
              />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={cn("lineChartContainer", "tabSpacer")}>
        <ChartWrapper titles={["Monthly Interactions"]} showLegend legendLabels={["Interactions"]}>
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isFetched && validLineData(lineChartQuery.data!.interactions.yData) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineChartQuery.data!.interactions}
                {...sharedOverviewLineProps}
              />
            ) : (
              noData(dateFilter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className="tabSpacer">
        <RequestStatusRenderer state={cardsQuery.status}>
          <CardReporting items={cardsQuery.data!} />
        </RequestStatusRenderer>
      </div>

      {selectedAccountId === null ? (
        <AccountsTable
          selectedAccountId={selectedAccountId}
          dateFilter={dateFilter}
          handleAccountSelection={handleAccountSelect}
        />
      ) : (
        <EnterpriseTable selectedAccountId={selectedAccountId} dateFilter={dateFilter} />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
  };
};

export type PropsFromRedux = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PeopleOverviewReport);
