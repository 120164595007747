import { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Button } from "components/buttons/button/Button";
import { Strings } from "enums";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces/redux";
import { resetContentPacksAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { RenderModalActionsParams } from "components/assignmentModals/commonSteps/packsContextStep/PacksContextStep";
import { ModalWithSteps } from "components";
import { ConfirmLicensingStep } from "components/assignmentModals/commonSteps";
import { IModalManager, PublishPackContextItem } from "interfaces";
import { PropertyChangedEvent } from "interfaces/functionTypes/PropertyChangedEvent";
import DeletePackItemsStep, {
  DeletePackItemsRenderModalActionsParams,
  getConfirmDeletePackItemsStepFunction,
} from "./deletePackItemsStep/DeletePackItemsStep";
import DraftPackContextStep, {
  DraftPackContextRenderModalActionsParams,
  getDraftPackContextRenderModalActions,
} from "./draftPackContextStep/DraftPackContextStep";

interface ConfirmLicensingRenderModalActionsParams extends RenderModalActionsParams {
  hasPreviousStep: boolean;
  hasNextStep: boolean;
}

export interface PublishPackConfirmationModalProps {
  pack: PublishPackContextItem;
  modalManager: IModalManager;
  setIssueLicensePackIds: (ids: number[]) => void;
}

export type PublishPackConfirmationModalAllProps = PropsFromRedux & PublishPackConfirmationModalProps;

export const PublishPackConfirmationModal: FC<PublishPackConfirmationModalAllProps> = ({
  pack,
  modalManager,
  setIssueLicensePackIds,
  resetContentPacks,
}) => {
  const { skipDeletePackItemsStep } = pack;
  const [isDataValid, setIsDataValid] = useState(false);
  const [skipPacksContextStep, setSkipPacksContextStep] = useState(false);
  const [packsContextStepHeader, setPacksContextStepHeader] = useState<string>();
  const [open, setOpen] = useState(modalManager.isVisible);

  useEffect(() => {
    const onIsValidChanged: PropertyChangedEvent<boolean> = (_, isVisible: boolean) => setOpen(isVisible);
    modalManager.subscribeOnIsVisibleChanged(onIsValidChanged);
    return () => modalManager.unsubscribeOnIsVisibleChanged(onIsValidChanged);
  }, [modalManager]);

  const packContextStepModalActionsParams: DraftPackContextRenderModalActionsParams = {
    isDataValid: isDataValid,
    onIsDataValidChange: setIsDataValid,
    onCancel: modalManager.onCancel,
  };

  const confirmLicensingStepModalActionsParams: ConfirmLicensingRenderModalActionsParams = {
    isDataValid: isDataValid,
    onIsDataValidChange: setIsDataValid,
    hasPreviousStep: !skipPacksContextStep,
    hasNextStep: !skipDeletePackItemsStep,
  };

  const confirmDeletePackItemsStepModalActionsParams: DeletePackItemsRenderModalActionsParams = {
    isDataValid: isDataValid,
    onIsDataValidChange: setIsDataValid,
    onConfirm: modalManager.onConfirm,
  };

  const onClose = () => {
    setSkipPacksContextStep(false);
    resetContentPacks();
  };

  const getPreviousButton = (onIsDataValidChange: (isDataValid: boolean) => void, prevStep: () => void) => (
    <Button
      primary
      className={"previous"}
      content={"Previous"}
      onClick={() => {
        onIsDataValidChange(true);
        prevStep();
      }}
    />
  );

  const getCancelButton = (closeModal: Function) => (
    <Button
      basic
      className={"cancel"}
      content={"Cancel"}
      onClick={() => {
        modalManager.onCancel();
        closeModal();
      }}
    />
  );

  const getNextButton = (
    onIsDataValidChange: (isDataValid: boolean) => void,
    nextStep: () => void,
    isValid: boolean,
  ) => (
    <Button
      primary
      className={"next"}
      content={"Next"}
      onClick={() => {
        onIsDataValidChange(false);
        nextStep();
      }}
      disabled={!isValid}
    />
  );

  const getConfirmButton = (closeModal: Function, isValid: boolean) => (
    <Button
      primary
      className={"confirm"}
      content={"Confirm"}
      onClick={() => {
        modalManager.onConfirm();
        closeModal();
      }}
      disabled={!isValid}
    />
  );

  const getConfirmLicensingStepRenderModalActions = (params: ConfirmLicensingRenderModalActionsParams) => {
    return (nextStep: () => void, prevStep: () => void) => {
      return (closeModal: Function) => {
        const { isDataValid, onIsDataValidChange, hasPreviousStep, hasNextStep } = params;
        return (
          <>
            {hasPreviousStep ? getPreviousButton(onIsDataValidChange, prevStep) : getCancelButton(closeModal)}
            {hasNextStep
              ? getNextButton(onIsDataValidChange, nextStep, isDataValid)
              : getConfirmButton(closeModal, isDataValid)}
          </>
        );
      };
    };
  };

  const form = (
    <ModalWithSteps
      className="people-license-modal"
      scrolling
      showModal={open}
      onCancel={modalManager.onCancel}
      onBeforeClose={onClose}
    >
      {!skipPacksContextStep && (
        <DraftPackContextStep
          header={packsContextStepHeader}
          renderModalActions={getDraftPackContextRenderModalActions(packContextStepModalActionsParams)}
          info={[pack]}
          onIsDataValidChange={setIsDataValid}
          noDataLoaded={() => setSkipPacksContextStep(true)}
          setModalStepHeader={() => setPacksContextStepHeader(Strings.modalTitles.licensing)}
          onCancel={modalManager.onCancel}
        />
      )}
      <ConfirmLicensingStep
        onIsDataValidChange={setIsDataValid}
        header="License Confirmation"
        renderModalActions={getConfirmLicensingStepRenderModalActions(confirmLicensingStepModalActionsParams)}
        info={{ packId: parseInt(pack.id) }}
        setIssueLicensePackIds={setIssueLicensePackIds}
      />
      {!skipDeletePackItemsStep && (
        <DeletePackItemsStep
          onIsDataValidChange={setIsDataValid}
          header="Remove and Publish?"
          renderModalActions={getConfirmDeletePackItemsStepFunction(confirmDeletePackItemsStepModalActionsParams)}
        />
      )}
    </ModalWithSteps>
  );

  return modalManager.renderForm(form);
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetContentPacks: bindAction(resetContentPacksAction, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PublishPackConfirmationModal);
