import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as filteringItemsActionTypes from "../../../../Application/actions/filteringItems/filteringItemsActionTypes";

export const LIBRARY_USER_EVENT_ASSIGNMENTS_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  filteringItemsActionTypes.SET_SEARCH,
);

export const LIBRARY_USER_EVENT_ASSIGNMENTS_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.Events,
  filteringItemsActionTypes.RESET_SEARCH,
);
