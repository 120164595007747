import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { createFetchingItemsSlice, FetchingItemsState } from "features/Application/slices/createFetchingItemsSlice";
import { fetchJobTitles } from "../../thunks/addUserThunk";

export const initialState: FetchingItemsState<string> = {
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const jobTitlesSlice = createFetchingItemsSlice({
  initialState,
  namePayload: {
    entity: ReducerEntityPrefixTypes.EditAccount,
    name: "JobTitles",
    namespace: ReducerNamespaceTypes.Accounts,
  },
  thunk: fetchJobTitles,
  reducers: {},
});

export default jobTitlesSlice.reducer;
