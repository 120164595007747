import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { DropdownItem } from "../../../../../utils/miscellaneousUtils";

export type IntegrationPacksState = FetchingItemsState<DropdownItem>;

const initialState: IntegrationPacksState = {
  items: [],
  isLoading: false,
  itemsCount: 0,
  error: undefined,
};

const integrationPacksSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.Integrations,
    name: "packs",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = integrationPacksSlice.actions;

export default integrationPacksSlice.reducer;
