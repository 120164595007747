import React, { SyntheticEvent, useState } from "react";
import { Image, Placeholder } from "semantic-ui-react";
import cn from "classnames";

import ImageAspectRatio from "../../enums/imageAspectRatio";

import placeholderImage from "../../images/placeholder-16_9.png";

import "./croppedThumbnail.scss";

enum stretchMode {
  fullWidth = "full-width",
  fullHeight = "full-height",
}

interface Dimensions {
  width?: number;
  height?: number;
}

export interface Props {
  thumbnailUrl?: string;
  onClick?: () => void;
  placeholderContent?: React.ReactElement;
  className?: string;
  aspectRatio?: ImageAspectRatio;
  altText?: string;
  draggable?: boolean;
  children?: React.ReactNode;
}

const CroppedThumbnail: React.FC<Props> = ({
  thumbnailUrl,
  onClick,
  placeholderContent,
  className,
  altText,
  draggable,
  children,
  aspectRatio = ImageAspectRatio.HD_16X9,
}: React.PropsWithChildren<Props>) => {
  const [visible, setVisible] = useState(placeholderContent || !thumbnailUrl);
  const [dimensions, setDimensions] = useState<Dimensions>({});

  const onImgLoad = (event: React.SyntheticEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    setDimensions({ width: target.offsetWidth, height: target.offsetHeight });
    setVisible(true);
  };

  const renderContent = () => {
    if (placeholderContent && !thumbnailUrl) {
      return placeholderContent;
    }
    return (
      <Image
        className="thumbnail"
        src={thumbnailUrl ? thumbnailUrl : placeholderImage}
        onLoad={onImgLoad}
        onError={(e: SyntheticEvent<HTMLElement>) => ((e.target as HTMLImageElement).src = placeholderImage)}
        alt={altText}
        draggable={draggable}
      />
    );
  };

  const { width, height } = dimensions;
  let ratio: stretchMode = stretchMode.fullWidth;
  if (width && height) {
    ratio = height / width >= 9 / 16 ? stretchMode.fullWidth : stretchMode.fullHeight;
  }
  const visibility = visible ? "visible" : "";
  const aspectRatioClassName =
    aspectRatio === ImageAspectRatio.SQUARE_1X1
      ? "thumbnail-container-1x1-aspect-ratio"
      : "thumbnail-container-16x9-aspect-ratio";

  return (
    <div className={cn("thumbnail-container", aspectRatioClassName, className)} onClick={onClick}>
      <div
        className={cn("thumbnail-wrapper", ratio, visibility, {
          "placeholder-content": placeholderContent && !thumbnailUrl,
        })}
        data-testid="thumbnail-wrapper"
      >
        {renderContent()}
      </div>
      {!visible && (
        <Placeholder fluid className="image-placeholder">
          <Placeholder.Image />
        </Placeholder>
      )}
      {children}
    </div>
  );
};

export default CroppedThumbnail;
