import { useEffect, useMemo, useState } from "react";
import {
  getFormattedTimeStringFromPeriod,
  noBarData,
  noData,
  validLineData,
  PerformanceFilter,
  opensColor,
  startsColor,
  horizontalBarChartMargins
} from "../../../Library/Common/utils/performanceUtils";
import { sharedGoogleReportingBarProps } from "features/Reporting/Google/utils/shared";
import { ChartWrapper, GroupedBarChart, HorizontalBarChart, LineChart } from "components/charts";
import CardReporting from "components/cards/CardReporting/CardReporting";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";

import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import { useGoogleTooltipFormats } from "features/Reporting/Google/utils/useGoogleTooltipFormats";
import { UserPerformanceList } from "./UserPerformanceList";

import { useChartPeriodMeasure } from "hooks/useChartPeriodMeasure";
import { useQuery } from "@tanstack/react-query";
import { SelectedView, UserAssetTypes } from "./types";
import { getActivityByTypeQuery, getLineChartQuery } from "./Queries/queries";

import styles from "./userPerformanceBody.module.scss";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { bindAction } from "interfaces";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";

export type ChartState = "line" | "column";

export type UserPerformanceBodyProps = PropsFromRedux & {
  filter: PerformanceFilter;
  activeTab: number;
  setActiveTab: (num: number) => void;
  userId: number;
  dateRange: Date[];
  handleContentSelection: (type: UserAssetTypes, view: SelectedView) => void;
};

export const handleDropdownChange = (dropdownValue: string, setFunc: (newState: ChartState) => void) => {
  dropdownValue.indexOf("Bar") === -1 ? setFunc("line") : setFunc("column");
};

export const UserPerformanceBody: React.FC<UserPerformanceBodyProps> = ({
  filter,
  activeTab,
  setActiveTab,
  dateRange,
  userId,
  handleContentSelection,
  setHasExportData,
}) => {
  const [activityChartState, setActivityChartState] = useState<ChartState>("line");
  const reportEnabled = !!useFeatureFlag(FeatureFlags.UserPerformance);
  const [chartPeriod, measureRef] = useChartPeriodMeasure(
    dateRange,
    sharedAccountReportingLineProps.margins!.left + sharedAccountReportingLineProps.margins!.right,
  );
  const { groupedBarFormatter } = useGoogleTooltipFormats(chartPeriod, dateRange);

  const lineChartQuery = useQuery({
    queryKey: ["userCollaborationLine", filter, userId, chartPeriod],
    queryFn: getLineChartQuery,
    enabled: reportEnabled,
  });

  const activityByTypeQuery = useQuery({
    queryKey: ["userActivityByType", filter, userId],
    queryFn: getActivityByTypeQuery,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setHasExportData(activityByTypeQuery.isSuccess && (!!activityByTypeQuery.data.cards.platformOutreach || !!activityByTypeQuery.data.cards.userInteractions));
  }, [setHasExportData, activityByTypeQuery.isSuccess, activityByTypeQuery.data]);

  const activeUsersChart = useMemo(() => {
    if (!lineChartQuery.data) {
      return <></>;
    }
    return activityChartState === "line" ? (
      <LineChart
        {...sharedAccountReportingLineProps}
        xData={[lineChartQuery.data.lineData.Date, lineChartQuery.data.lineData.Date]}
        yData={[lineChartQuery.data.lineData.platformOutreach, lineChartQuery.data.lineData.userInteractions]}
        xFormatterFunc={(d) => getFormattedTimeStringFromPeriod(d, chartPeriod, dateRange)}
        colorRange={[opensColor, startsColor]}
      />
    ) : (
      <GroupedBarChart
        {...sharedGoogleReportingBarProps}
        data={lineChartQuery.data.activityBarData}
        order={lineChartQuery.data.order}
        xTickFormatter={(d) => getFormattedTimeStringFromPeriod(d.toLocaleString(), chartPeriod, dateRange)}
        tooltipFormatter={groupedBarFormatter}
        colorRange={[opensColor, startsColor]}
      />
    );
  }, [lineChartQuery.data, activityChartState, groupedBarFormatter, chartPeriod, dateRange]);

  return (
    <div className={styles.root}>
      <div className={styles.activity} ref={measureRef}>
        <ChartWrapper
          titles={["Activity - Line Chart", "Activity - Bar Chart"]}
          legendLabels={["Platform Outreach", "User Interactions"]}
          showLegend
          onChange={(option) => handleDropdownChange(option, setActivityChartState)}
          colorRange={[opensColor, startsColor]}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
              validLineData([
                lineChartQuery.data.lineData.cumulativeUserInteractions,
                lineChartQuery.data.lineData.cumulativePlatformOutreach,
              ])
              ? activeUsersChart
              : noData(filter)}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.activityByTypeQuery}>
        <ChartWrapper titles={["Activity By Interaction Type"]}>
          <RequestStatusRenderer state={activityByTypeQuery.status}>
            {activityByTypeQuery.isSuccess && !noBarData(...activityByTypeQuery.data.barData.map((d) => d.value)) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                margins={{ ...horizontalBarChartMargins, left: 177 }}
                domain={activityByTypeQuery.data.barDomain}
                data={activityByTypeQuery.data.barData}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.cards}>
        <RequestStatusRenderer state={activityByTypeQuery.status}>
          {activityByTypeQuery.isSuccess && (
            <CardReporting
              items={[
                {
                  statDescription: "Platform Outreach",
                  stat: activityByTypeQuery.data.cards.platformOutreach,
                },
                {
                  statDescription: "User Interactions",
                  stat: isNaN(activityByTypeQuery.data.cards.userInteractions)
                    ? 0
                    : activityByTypeQuery.data.cards.userInteractions,
                },
                {
                  statDescription: "Groups",
                  stat: activityByTypeQuery.data.cards.groups,
                },
                {
                  statDescription: "Licenses",
                  stat: activityByTypeQuery.data.cards.licenses,
                },
              ]}
            />
          )}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <UserPerformanceList
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          filter={filter}
          userId={userId}
          handleContentSelection={handleContentSelection}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UserPerformanceBody);