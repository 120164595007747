import React, { FunctionComponent, SVGProps } from "react";
import cn from "classnames";
import { Icon } from "semantic-ui-react";

import { ExternalTriggerType, SourceType } from "../../../../../../types/externallTriggersTypes";
import { ReactComponent as Google } from "../../../../../../../../../../images/brands/google.svg";
import { ReactComponent as BrainStorm } from "../../../../../../../../../../images/brands/brainstorm.svg";
import { ReactComponent as Microsoft } from "../../../../../../../../../../images/brands/microsoft.svg";
import { Integration } from "../../../Integration/Integration";

import styles from "./externalTriggerItem.module.scss";

const TriggerIcons: { [key in SourceType]: FunctionComponent<SVGProps<SVGSVGElement>> } = {
  Google: Google,
  Microsoft: Microsoft,
  BrainStorm: BrainStorm,
};

export interface ExternalTriggersItemProps {
  item: ExternalTriggerType;
  groupId: string;
  isReadOnly: boolean;
  onOpenTriggerPanel: () => void;
  removeExternalTrigger: (groupId: string, triggerId: string) => Promise<void>;
}

export const ExternalTriggerItem = (props: ExternalTriggersItemProps) => {
  const { item, groupId, isReadOnly } = props;
  const TriggerIcon = TriggerIcons[item.software];

  const onRemove = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await props.removeExternalTrigger(groupId, item.id);
  };

  return (
    <div
      className={cn(styles.root, { [styles.warning]: !item.hasIntegration })}
      onClick={props.onOpenTriggerPanel}
      data-testid="external-trigger-item"
    >
      {!isReadOnly && (
        <Icon
          data-testid="remove-trigger"
          className={styles["remove-button"]}
          name="remove circle"
          size="small"
          onClick={onRemove}
        />
      )}
      {item.hasIntegration ? <TriggerIcon className={styles.logo} /> : <Integration />}
      <span className={styles.description}>{item.fullText}</span>
    </div>
  );
};
