import { IObservable, IObserver } from "../interfaces/IObservable";

export default class Observable<TObserver extends IObserver> implements IObservable<TObserver> {
  private observers: TObserver[] = [];

  subscribe = (observer: TObserver) => {
    this.observers.push(observer);
  };

  unsubscribe = (observer: TObserver) => {
    const indexForDelete = this.observers.findIndex((existedObserver) => existedObserver === observer);
    if (indexForDelete >= 0) {
      this.observers.splice(indexForDelete, 1);
    }
  };

  notify = (...args: Parameters<TObserver>) => {
    this.observers.forEach((observer) => observer.call(this, ...args));
  };

  dispose = () => {
    this.observers = [];
  };
}
