import { Dispatch } from "redux";
import { FiltersMap } from "../../../../../utils/filterUtils";
import accountsDataService from "../../../../Accounts/services/accountsDataService";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import dataService from "../../../../Application/services/dataServices/dataService";
import rolesDataService from "../../../services/rolesDataService";
import * as filterActionTypes from "./filterActionTypes";

export interface FilterOptionParams {
  shouldGetRoles: boolean;
  roleSearchQuery: string;
  shouldGetOfficeLocations: boolean;
  shouldGetManagers: boolean;
  shouldGetLicenses: boolean;
  shouldGetDefaultRoles: boolean;
  accountTypeId: number;
  shouldGetGroups: boolean;
}

export const setUserFilter = makeActionCreator(filterActionTypes.PEOPLE_USERS_OVERVIEW_SET_ITEMS_FILTER, "filter");

export const resetUserFilter = makeActionCreator(filterActionTypes.PEOPLE_USERS_OVERVIEW_RESET_ITEMS_FILTER);

export const getFilterOptions = (
  filterOptionParams?: FilterOptionParams
) => {
  filterOptionParams = filterOptionParams || {
    shouldGetRoles: false,
    roleSearchQuery: "",
    shouldGetOfficeLocations: false,
    shouldGetManagers: false,
    shouldGetLicenses: false,
    shouldGetDefaultRoles: false,
    accountTypeId: 1,
    shouldGetGroups: false
  };
  const getFilterOptionsBegin = () => ({
    type: filterActionTypes.PEOPLE_USERS_OVERVIEW_FILTER_OPTIONS.FETCH_OPTIONS_BEGIN,
  });

  const getFilterOptionsSuccess = (filterOptions: FiltersMap) => ({
    type: filterActionTypes.PEOPLE_USERS_OVERVIEW_FILTER_OPTIONS.FETCH_OPTIONS_SUCCESS,
    payload: { ...filterOptions },
  });

  const getFilterOptionsFailed = (error: any) => ({
    type: filterActionTypes.PEOPLE_USERS_OVERVIEW_FILTER_OPTIONS.FETCH_OPTIONS_FAILURE,
    payload: { error },
  });

  const mapRoles = (data: any) => data.items.map((x: any) => ({ text: x.name, value: x.id }));

  const mapData = (data: any) =>
    data.data.filter((x: string) => x !== "" && x !== null).map((x: string) => ({ text: x, value: x }));

  const mapLicenses = (data: any) => data.data.map((x: any) => ({ text: x.title, value: x.skuId }));

  const mapDefaultRoles = (data: any) => data.map((x: any) => ({ text: x.name, value: x.name }));

  const mapGroups = (data: any) => data.data.map((x: any) => ({ text: x.name, value: x.id }));

  return async (dispatch: Dispatch) => {
    dispatch(getFilterOptionsBegin());

    try {
      let roles = null;
      let officeLocations = null;
      let managers = null;
      let licenses = null;
      let defaultRoles = null;
      let groups = null;

      if (filterOptionParams.shouldGetRoles) {
        const result = await rolesDataService.getRoles(filterOptionParams.roleSearchQuery);
        roles = mapRoles(result);
      }
      if (filterOptionParams.shouldGetOfficeLocations) {
        const result = await dataService.getOfficeLocations();
        officeLocations = mapData(result);
      }
      if (filterOptionParams.shouldGetManagers) {
        const result = await dataService.getManagers();
        managers = mapData(result);
      }
      if (filterOptionParams.shouldGetLicenses) {
        const result = await dataService.getLicenses();
        licenses = mapLicenses(result);
      }
      if (filterOptionParams.shouldGetDefaultRoles) {
        const result = await accountsDataService.getAccountTypeRoles(filterOptionParams.accountTypeId);
        defaultRoles = mapDefaultRoles(result);
      }
      if (filterOptionParams.shouldGetGroups) {
        const result = await dataService.getAccountGroups();
        groups = mapGroups(result);
      }

      const departments = await dataService.getDepartments();
      const jobTitles = await dataService.getJobTitles();
      const filterOptions = {
        departments: mapData(departments),
        jobTitles: mapData(jobTitles),
        officeLocations,
        managers,
        roles,
        licenses,
        defaultRoles,
        groups,
      };

      dispatch(getFilterOptionsSuccess(filterOptions));
    } catch (error) {
      dispatch(getFilterOptionsFailed(error));
    }
  };
};
