import { createReducer } from "../../../../../../utils/reduxUtils";
import * as actionTypes from "../actionTypes/threatDefenceLandingPageDetailsActionTypes";
import * as entityStateActionTypes from "../actionTypes/threatDefenceLandingPageEntityStateActionTypes";
import {
  ThreatDefenceLandingPageDetailsState,
  ThreatDefenceLandingPageView,
  ThreatDefenceLandingPage,
  ThreatDefenceLandingPageContent,
  LoadThreatDefenceLandingPageContentResult,
} from "../../types/state";
import { PayloadAction } from "../../../../../../interfaces/redux";
import miscUtils from "../../../../../../utils/miscellaneousUtils";
import { ThreatDefenceEmailTemplateDetailsState } from "../../../EmailTemplates/types/state";

const initialState: ThreatDefenceLandingPageDetailsState = {
  landingPage: {
    id: -1,
    isDraft: true,
    hasBeenPublished: false,
    name: "",
    dateModified: undefined,
    labels: miscUtils.getEmptyMultiSelectItems(),
    softwareApplications: miscUtils.getEmptyMultiSelectItems(),
  },
  html: "",
  contentUrlWithSas: "",
  isLoading: false,
  isLandingPageLoaded: false,
  isLandingPageContentLoaded: false,
};

const normalizeLandingPagePayload = (
  initial: ThreatDefenceLandingPageView,
  payload: ThreatDefenceLandingPage,
): ThreatDefenceLandingPageView => ({
  ...initial,
  ...payload,
  labels: miscUtils.normalizeDropdownItems(payload.labels),
  softwareApplications: miscUtils.normalizeDropdownItems(payload.softwareApplications),
});

const getHandlers = (failureHandler: Function) => {
  const {
    getThreatDefenceLandingPageBegin,
    getThreatDefenceLandingPageSuccess,
    getThreatDefenceLandingPageFailure,
    getThreatDefenceLandingPageContentBegin,
    getThreatDefenceLandingPageContentSuccess,
    getThreatDefenceLandingPageContentFailure,
  } = actionTypes;

  const getThreatDefenceLandingPageBeginHandler = (
    state: ThreatDefenceLandingPageDetailsState,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getThreatDefenceLandingPageSuccessHandler = (
    state: ThreatDefenceLandingPageDetailsState,
    action: PayloadAction<ThreatDefenceLandingPage>,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    landingPage: {
      ...normalizeLandingPagePayload(state.landingPage, action.payload),
    },
    isLandingPageLoaded: true,
    isLoading: false,
  });

  const getThreatDefenceLandingPageContentBeginHandler = (
    state: ThreatDefenceLandingPageDetailsState,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    isLoading: true,
  });

  const getThreatDefenceLandingPageContentSuccessHandler = (
    state: ThreatDefenceLandingPageDetailsState,
    action: PayloadAction<LoadThreatDefenceLandingPageContentResult>,
  ): ThreatDefenceLandingPageDetailsState => {
    const content = action.payload.content;
    const newState: ThreatDefenceLandingPageDetailsState = {
      ...state,
      html: content.html,
      contentUrlWithSas: content.contentUrlWithSas,
      isLandingPageLoaded: true,
      isLoading: false,
      isLandingPageContentLoaded: true,
    };
    if (!action.payload.getLandingPageOnly) {
      newState.landingPage = {
        ...state.landingPage,
      };
    }
    return newState;
  };

  return {
    [getThreatDefenceLandingPageBegin]: getThreatDefenceLandingPageBeginHandler,
    [getThreatDefenceLandingPageSuccess]: getThreatDefenceLandingPageSuccessHandler,
    [getThreatDefenceLandingPageFailure]: failureHandler,
    [getThreatDefenceLandingPageContentBegin]: getThreatDefenceLandingPageContentBeginHandler,
    [getThreatDefenceLandingPageContentSuccess]: getThreatDefenceLandingPageContentSuccessHandler,
    [getThreatDefenceLandingPageContentFailure]: failureHandler,
  };
};

const fetchLockedHandlers = (failureHandler: Function) => {
  const {
    fetchLockedThreatDefenceLandingPageBegin,
    fetchLockedThreatDefenceLandingPageSuccess,
    fetchLockedThreatDefenceLandingPageFailure,
  } = entityStateActionTypes;

  const fetchLockedThreatDefenceLandingPageBeginHandler = (
    state: ThreatDefenceLandingPageDetailsState,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchLockedThreatDefenceLandingPageSuccessHandler = (
    state: ThreatDefenceLandingPageDetailsState,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    landingPage: {
      ...state.landingPage,
      isDraft: true,
    },
    isLoading: false,
  });

  return {
    [fetchLockedThreatDefenceLandingPageBegin]: fetchLockedThreatDefenceLandingPageBeginHandler,
    [fetchLockedThreatDefenceLandingPageSuccess]: fetchLockedThreatDefenceLandingPageSuccessHandler,
    [fetchLockedThreatDefenceLandingPageFailure]: failureHandler,
  };
};

const discardHandlers = (failureHandler: Function) => {
  const {
    fetchDiscardThreatDefenceLandingPageBegin,
    fetchDiscardThreatDefenceLandingPageSuccess,
    fetchDiscardThreatDefenceLandingPageFailure,
  } = entityStateActionTypes;

  const fetchDiscardThreatDefenceLandingPageBeginHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
    isLoading: true,
  });

  const fetchDiscardThreatDefenceLandingPageSuccessHandler = (
    state: ThreatDefenceEmailTemplateDetailsState,
  ): ThreatDefenceEmailTemplateDetailsState => ({
    ...state,
  });

  return {
    [fetchDiscardThreatDefenceLandingPageBegin]: fetchDiscardThreatDefenceLandingPageBeginHandler,
    [fetchDiscardThreatDefenceLandingPageSuccess]: fetchDiscardThreatDefenceLandingPageSuccessHandler,
    [fetchDiscardThreatDefenceLandingPageFailure]: failureHandler,
  };
};

const publishHandlers = (failureHandler: Function) => {
  const {
    publishThreatDefenceLandingPageBegin,
    publishThreatDefenceLandingPageSuccess,
    publishThreatDefenceLandingPageFailure,
  } = entityStateActionTypes;

  const publishThreatDefenceLandingPageBeginHandler = (
    state: ThreatDefenceLandingPageDetailsState,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    isLoading: true,
  });

  const publishThreatDefenceLandingPageSuccessHandler = (
    state: ThreatDefenceLandingPageDetailsState,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    landingPage: {
      ...state.landingPage,
      isDraft: false,
    },
    isLoading: false,
  });

  return {
    [publishThreatDefenceLandingPageBegin]: publishThreatDefenceLandingPageBeginHandler,
    [publishThreatDefenceLandingPageSuccess]: publishThreatDefenceLandingPageSuccessHandler,
    [publishThreatDefenceLandingPageFailure]: failureHandler,
  };
};

const threatDefenceLandingPageDetailsHandlers = () => {
  const {
    saveThreatDefenceLandingPage,
    saveThreatDefenceLandingPageContent,
    clearThreatDefenceLandingPage,
    setLandingPage,
  } = actionTypes;

  const failureHandler = (
    state: ThreatDefenceLandingPageDetailsState,
    action: PayloadAction<Error>,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  const saveThreatDefenceLandingPageHandler = (
    state: ThreatDefenceLandingPageDetailsState,
    action: PayloadAction<ThreatDefenceLandingPageView>,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    landingPage: action.payload,
  });

  const saveThreatDefenceLandingPageContentHandler = (
    state: ThreatDefenceLandingPageDetailsState,
    action: PayloadAction<ThreatDefenceLandingPageContent>,
  ): ThreatDefenceLandingPageDetailsState => {
    const payload = action.payload;
    return {
      ...state,
      landingPage: {
        ...state.landingPage,
      },
      html: payload.html,
    };
  };

  const clearThreatDefenceLandingPageHandler = (): ThreatDefenceLandingPageDetailsState => ({
    ...initialState,
  });

  const setLandingPageHandler = (
    state: ThreatDefenceLandingPageDetailsState,
    action: PayloadAction<ThreatDefenceLandingPage>,
  ): ThreatDefenceLandingPageDetailsState => ({
    ...state,
    landingPage: {
      ...normalizeLandingPagePayload(state.landingPage, action.payload),
    },
  });

  return {
    [saveThreatDefenceLandingPage]: saveThreatDefenceLandingPageHandler,
    [saveThreatDefenceLandingPageContent]: saveThreatDefenceLandingPageContentHandler,
    [clearThreatDefenceLandingPage]: clearThreatDefenceLandingPageHandler,
    [setLandingPage]: setLandingPageHandler,
    ...getHandlers(failureHandler),
    ...publishHandlers(failureHandler),
    ...fetchLockedHandlers(failureHandler),
    ...discardHandlers(failureHandler),
  };
};

export const threatDefenceLandingPageDetails = createReducer(initialState, [threatDefenceLandingPageDetailsHandlers]);
