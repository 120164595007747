import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchActionPayload } from "../../../../../interfaces/redux";
import { RootState } from "../../../../Application/globaltypes/redux";
import { PackLicenseType } from "../../types/state";

export interface PackLicenseTypesState {
  items: PackLicenseType[];
  itemsCount: number;
  isLoading: boolean;
  error: string | undefined;
}

const initialState: PackLicenseTypesState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const packLicenseTypesSlice = createSlice({
  name: "packLicenseTypes",
  initialState: initialState,
  reducers: {
    req(state: PackLicenseTypesState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: PackLicenseTypesState, action: PayloadAction<FetchActionPayload<PackLicenseType>>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
        itemsCount: action.payload.totalCount,
      };
    },
    err(state: PackLicenseTypesState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = packLicenseTypesSlice.actions;

export const packLicenseTypesSelector = (state: RootState) => state.packs.packLicenseTypes.items;

export default packLicenseTypesSlice.reducer;
export type packLicenseTypesStateSliceType = ReturnType<typeof packLicenseTypesSlice.reducer>;
