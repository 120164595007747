import { Component } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import isEmpty from "lodash/isEmpty";

import AssessmentsNoResults from "../../../../views/library/assessments/Overview/AssessmentsNoResults";
import SearchInput from "../../../../components/searchInput/SearchInput";
import AssessmentOverviewHeader from "./AssessmentOverviewHeader/AssessmentOverviewHeader";
import Restricted from "../../../Application/Restricted";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import DeleteContentConfirmationModal, {
  RemoveContentObserverInput,
} from "../../../../components/deleteContentConfirmationModal/DeleteContentConfirmationModal";
import Observable from "../../../../utils/Observable";
import GenericItemsView from "../../../../views/ItemsView/GenericItemsView";

import { AssessmentsOverviewOwnProps, AssessmentsOverviewState } from "./types";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { Columns, getColumnOptions } from "./getColumnOptions";
import { AssessmentFilterForm } from "../../../../components/filterForms";
import { ContentTypesEnum, RolePermissions, SortingDirection } from "../../../../enums";
import {
  assessmentsStateSelector,
  assessmentFilterOptionsSelector,
  assessmentSearchSelector,
} from "../state/selectors";
import { deleteAssessments, duplicateAssessments, loadAssessments } from "../state/thunks/assessmentsOverviewThunk";
import { AssessmentLockSuccess } from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { bindAction, IObservable } from "../../../../interfaces";
import { CreateAssessmentButton } from "./AssessmentOverviewHeader/CreateAssessmentButton";
import { resetAppliedFilter, setAppliedFilter, setSearch, resetSearch } from "../state/slices/assessmentFiltersSlice";
import { getOverviewFilterOptions } from "../state/thunks/assessmentFiltersThunk";
import { HandleOnSelectionChanged, SelectionChangedArgs } from "../../../../interfaces/onSelectionChanged";
import { AssessmentOverview } from "../types/state";
import { withRouter } from "../../../../adapters/withRouter/withRouter";

import * as assessmentsOverviewActions from "../../Assessments/state/actions/assessmentsOverviewActions";
import * as assessmentEntityStateActions from "../state/actions/assessmentEntityStateActions";
import * as backgroundTasksActions from "../../../BackgroundTasks/state/backgroundTasksActions";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { FeatureFlags } from "featureFlags";
import { LDProps } from "../../../LDProps";

export type AssessmentsOverviewProps = AssessmentsOverviewOwnProps & PropsFromRedux & LDProps;

export class AssessmentsOverview extends Component<AssessmentsOverviewProps, AssessmentsOverviewState> {
  private purchasedSelectedCount = 0;
  private undeletableSelectedCount = 0;
  private draftSelectedCount = 0;
  private readonly deleteContentWithDependenciesFeatureEnabled: boolean;
  private readonly onTriggerAssessmentRemovalObserver: IObservable<
    (onRemoveConfirm: () => void, removeContentInput: RemoveContentObserverInput) => void
  >;

  constructor(props: AssessmentsOverviewProps) {
    super(props);
    this.state = {
      selectedAssessmentsIds: [],
      purchasedSelected: false,
      undeletableSelected: false,
      draftSelected: false,
      searchHasValue: false,
    };
    this.onTriggerAssessmentRemovalObserver = new Observable<
      (onRemoveConfirm: () => void, removeContentInput: RemoveContentObserverInput) => void
    >();
    this.deleteContentWithDependenciesFeatureEnabled =
      !!this.props.flags?.[FeatureFlags.DeleteContentWithDependenciesFeature];
  }

  componentDidMount() {
    RtnEventsEmitter.subscribe(AssessmentLockSuccess, this.goToAssessmentEdit);
  }

  componentWillUnmount() {
    RtnEventsEmitter.unsubscribe(AssessmentLockSuccess, this.goToAssessmentEdit);
    batch(() => {
      this.props.resetFilter();
      this.props.resetSearch();
    });
  }

  reloadListItems?: (enableSorting: boolean) => void;

  getFilterOptions = () => {
    const { filterOptions, getFilterOptions } = this.props;

    if (isEmpty(filterOptions)) {
      getFilterOptions();
    }
  };

  onSelectedItemsChanged = (ids: Array<number>) => {
    this.setState({ selectedAssessmentsIds: [...(ids ?? [])] });
  };

  onClearedSelection = () => {
    this.purchasedSelectedCount = this.undeletableSelectedCount = this.draftSelectedCount = 0;
    this.setState({
      purchasedSelected: false,
      undeletableSelected: false,
      draftSelected: false,
      selectedAssessmentsIds: [],
    });
  };

  onSelectionChanged = (args: SelectionChangedArgs<AssessmentOverview>) => {
    const onAdded = (assessment: AssessmentOverview) => {
      assessment.isPurchased && ++this.purchasedSelectedCount;
      !assessment.canBeDeleted && ++this.undeletableSelectedCount;
      assessment.isDraft && ++this.draftSelectedCount;
    };
    const onRemoved = (assessment: AssessmentOverview) => {
      assessment.isPurchased && --this.purchasedSelectedCount;
      !assessment.canBeDeleted && --this.undeletableSelectedCount;
      assessment.isDraft && --this.draftSelectedCount;
    };

    HandleOnSelectionChanged(args, onAdded, onRemoved, this.onClearedSelection);

    this.setState({
      purchasedSelected: this.purchasedSelectedCount > 0,
      undeletableSelected: this.undeletableSelectedCount > 0,
      draftSelected: this.draftSelectedCount > 0,
    });
  };

  createReloadListMethod = (reloadListItems: (enableSorting: boolean) => void) => {
    this.reloadListItems = reloadListItems;
  };

  refetchItemsFromStart = (enableSorting: boolean) => {
    this.reloadListItems && this.reloadListItems(enableSorting);
  };

  onSearchChange = (search: string) => {
    this.setState({
      searchHasValue: !!search,
    });
    this.props.setSearch(search);
    this.refetchItemsFromStart(isEmpty(search));
  };

  renderSearchInput = (): React.ReactElement => (
    <SearchInput
      placeholder="Search for Assessments..."
      onChange={this.onSearchChange}
      defaultValue={this.props.search}
    />
  );

  goToAssessmentEdit = (payload: { id: number; }) => this.props.navigate(`${payload.id.toString()}/configuration`);

  handleEditClick = (id: number, isDraft?: boolean) => () => {
    if (!isDraft) this.props.entityStateActions.fetchDraftAssessmentEntity(id);
    this.goToAssessmentEdit({ id });
  };

  handleTitleClick = (id: number) => () => this.goToAssessmentEdit({ id });

  handleDuplicateClick = (ids: number[]) => () => {
    this.onClearedSelection();
    this.props.duplicateAssessments(ids, this.props.loadAssessments);
  };

  handleDeleteClick = (ids: number[], flowsCount?: number, packsCount?: number) => () => {
    this.onTriggerAssessmentRemovalObserver.notify(
      () => {
        this.props.deleteAssessments(ids, this.props.loadAssessments);
        this.onClearedSelection();
      },
      {
        selectedItemsCount: ids.length,
        flowsCount,
        packsCount,
      },
    );
  };

  loadAssessmentList = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
  ) => {
    this.props.loadAssessments(skip, top, sortingColumnName, sortingDirection);
  };

  render() {
    const { assessments, totalCount, isLoading, filterOptions, appliedFilter, isFilterLoading } = this.props;
    return (
      <section className="nested-content assessments">
        <AssessmentOverviewHeader
          selectedIds={this.state.selectedAssessmentsIds}
          purchasedSelected={this.state.purchasedSelected}
          undeletableSelected={this.state.undeletableSelected}
          draftSelected={this.state.draftSelected}
          onDuplicate={this.handleDuplicateClick}
          deletionHandler={this.handleDeleteClick}
          clearSelection={this.onClearedSelection}
        />
        <Restricted
          permissions={[RolePermissions.AssetsCreate, RolePermissions.AssetsManage]}
          renderContent={(hasPermission) => (
            <GenericItemsView
              className="alignment-padding"
              items={assessments}
              isLoading={isLoading}
              dataCount={totalCount}
              appliedFilter={appliedFilter}
              filterOptions={filterOptions}
              filterOptionsLoading={isFilterLoading}
              selectedIds={this.state.selectedAssessmentsIds}
              // This is handled in other overview pages by the
              // useSearchSorting hook. Use that same pattern
              // once this component is updated to a function
              defaultSortingColumnName={!!this.state.searchHasValue ? "" : Columns.Added}
              fetchData={this.loadAssessmentList}
              getFilterOptions={this.getFilterOptions}
              renderSearch={this.renderSearchInput}
              setReloadListItems={this.createReloadListMethod}
              onSelectedItemChanged={this.onSelectedItemsChanged}
              onSelectionChanged={this.onSelectionChanged}
              isSelectDisabled={() => !hasPermission}
              applyFilter={this.props.applyFilter}
              resetFilter={this.props.resetFilter}
              // @ts-ignore
              getFilterForm={() => <AssessmentFilterForm />}
              noResultsContent={
                <AssessmentsNoResults
                  filtered={!isEmpty(appliedFilter) || !isEmpty(this.props.search)}
                  createButton={<CreateAssessmentButton />}
                />
              }
              columnOptions={getColumnOptions({
                handleTitleClick: this.handleTitleClick,
                handleEditClick: this.handleEditClick,
                handleDuplicateClick: this.handleDuplicateClick,
                handleDeleteClick: this.handleDeleteClick,
                readonly: !hasPermission,
                deleteContentWithDependenciesFeatureEnabled: this.deleteContentWithDependenciesFeatureEnabled,
              })}
              permissions={[RolePermissions.AssetsManage]}
            />
          )}
        />
        <DeleteContentConfirmationModal
          contentType={ContentTypesEnum.Assessments}
          onTriggerRemoveContentObserver={this.onTriggerAssessmentRemovalObserver}
        />
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const assessments = assessmentsStateSelector(state);
  const overview = assessments.overview;
  const entityState = assessments.base.assessmentEntityStateReducer;

  return {
    assessments: overview.assessmentsOverviewReducer.assessments,
    isLoading: overview.assessmentsOverviewReducer.isLoading || entityState.changingEntityState,
    totalCount: overview.assessmentsOverviewReducer.totalCount,
    filterOptions: assessmentFilterOptionsSelector(state),
    appliedFilter: overview.filters.appliedFilter,
    isFilterLoading: overview.filters.isLoading,
    search: assessmentSearchSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  assessmentsOverviewActions: bindActionCreators(assessmentsOverviewActions, dispatch),
  entityStateActions: bindActionCreators(assessmentEntityStateActions, dispatch),
  backgroundTasksActions: bindActionCreators(backgroundTasksActions, dispatch),
  getFilterOptions: bindAction(getOverviewFilterOptions, dispatch),
  setSearch: bindAction(setSearch, dispatch),
  resetSearch: bindAction(resetSearch, dispatch),
  applyFilter: bindAction(setAppliedFilter, dispatch),
  resetFilter: bindAction(resetAppliedFilter, dispatch),
  loadAssessments: bindActionCreators(loadAssessments, dispatch),
  duplicateAssessments: bindAction(duplicateAssessments, dispatch),
  deleteAssessments: bindAction(deleteAssessments, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withLDConsumer()(AssessmentsOverview)));
