import { FilterItemBase, FilterViewType } from "../FilterFormBuilder/FilterFormBuilder";
import { PdfFiltersEnum } from "../../../features/Library/Pdfs/types/models";

export const getPdfsFilters = (
  includePublisher: boolean = true,
  disableDateFilters: boolean = false,
): FilterItemBase<PdfFiltersEnum>[] => {
  let filters: FilterItemBase<PdfFiltersEnum>[] = [
    {
      type: FilterViewType.DateRange,
      label: "Date Added",
      propertyName: PdfFiltersEnum.DateCreated,
      otherProps: { disabled: disableDateFilters },
    },
    {
      type: FilterViewType.DateRange,
      label: "Last Modified",
      propertyName: PdfFiltersEnum.DateModified,
      otherProps: { disabled: disableDateFilters },
    },
  ];

  if (includePublisher) {
    filters.push({
      type: FilterViewType.MultiSelect,
      label: "Publisher",
      propertyName: PdfFiltersEnum.Publishers,
      placeholder: "All Publishers",
    });
  }

  filters.push(
    {
      type: FilterViewType.MultiSelect,
      label: "Packs",
      propertyName: PdfFiltersEnum.Packs,
      placeholder: "All Packs",
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Software Applications",
      propertyName: PdfFiltersEnum.SoftwareApplications,
      placeholder: "All Applications",
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Labels",
      propertyName: PdfFiltersEnum.Labels,
      placeholder: "All Labels",
    },
  );

  return filters;
};
