import { FC } from "react";
import { CroppedThumbnail } from "../../../../../components";
import { Placeholder } from "semantic-ui-react";

export const ContentPlaceholder: FC = () => {
  return (
    <div className="pack-context-item">
      <div>
        <CroppedThumbnail
          placeholderContent={
            <Placeholder data-testid="context-item-image-placeholder">
              <Placeholder.Image />
            </Placeholder>
          }
        />
      </div>
      <div className="pack-context-placeholder">
        <Placeholder data-testid="pack-context-selection-placeholder">
          <Placeholder.Line />
        </Placeholder>
      </div>
    </div>
  );
};
