import { find, isNil } from "lodash";
import videojs from "video.js";
import languageUtils from "../../plugins/settingsMenu/utils/languageUtils";
import captionsUtil from "./utils/captionsUtil";

const vjsButton = videojs.getComponent("Button"); //NOSONAR https://github.com/videojs/video.js/pull/7410

const toggleClasses = "vjs-control vjs-button vjs-captions-button";
const toggleOnClass = " vjs-captions-toggle-on-button";
const toggleOffClass = " vjs-captions-toggle-off-button";
const ccToggleButtonPositionIndex = 5;

const showingMode = "showing";
const disableMode = "disabled";

class CloseCaptionToggleButton extends vjsButton {
  /* istanbul ignore next */
  constructor(player, options) {
    super(player, options);
    this.toggled = false;
    this.defaultLanguage = languageUtils.browserCurrentLanguageOrDefault();
    this.controlText("CC toggle button");

    if (this.getSavedCaptionsState() && !this.toggled) {
      this.toggleCaptions();
    }
  }

  /* istanbul ignore next */
  setLanguage(lang) {
    this.tryToAddTextTrack(lang.textTrack);

    if (this.toggled) {
      this.setTrackVisibility(disableMode);
      this.defaultLanguage = lang.langObject;
      this.setTrackVisibility(showingMode);
    } else {
      this.defaultLanguage = lang.langObject;
    }
  }

  /* istanbul ignore next */
  tryToAddTextTrack(txtTrack) {
    if (txtTrack) {
      const tracks = this.player_.textTracks().tracks_;

      const existingTrack = find(tracks, (elem) => {
        return txtTrack.language === elem.language;
      });

      if (existingTrack) {
        this.player_.removeRemoteTextTrack(existingTrack);
      }

      this.player_.addRemoteTextTrack(txtTrack, false);
    }
  }

  /* istanbul ignore next */
  setTrackVisibility(visibleState) {
    const tracks = this.player_.textTracks().tracks_;

    if (tracks.length > 0) {
      const track = find(tracks, (el) => {
        return el.language === this.defaultLanguage.code;
      });
      if (track) {
        track.mode = visibleState;
        this.translatingCaption.mode = !isNil(track.loaded_) && track.loaded_ ? disableMode : showingMode;

        track.addEventListener(
          "loadeddata",
          ((tr) => {
            this.translatingCaption.mode = disableMode;
            tr.removeEventListener("loadeddata");
          }).bind(this, track),
        );
      }
    }
  }

  /* istanbul ignore next */
  toggleCaptions() {
    this.toggled = !this.toggled;

    if (!this.translatingCaption) {
      this.translatingCaption = captionsUtil.getTranslatingCaption(this.player_);
    }

    this.el_.className = this.toggled ? toggleClasses + toggleOnClass : toggleClasses + toggleOffClass;

    if (this.toggled) {
      this.setTrackVisibility(showingMode);
    } else {
      this.setTrackVisibility(disableMode);
      this.translatingCaption.mode = disableMode;
    }

    localStorage.setItem("playerCaptionsState", this.toggled);
  }

  /* istanbul ignore next */
  getSavedCaptionsState() {
    return JSON.parse(localStorage.getItem("playerCaptionsState"));
  }

  /* istanbul ignore next */
  createEl() {
    const props = { className: toggleClasses + toggleOffClass };
    const attr = { "aria-live": "polite" };

    return super.createEl("div", props, attr);
  }

  /* istanbul ignore next */
  handleClick() {
    this.toggleCaptions();

    this.player_.trigger("captionstoggle", this.toggled);
  }
}

/* istanbul ignore next */
const closeCaptionToggleButtonPlugin = function initToggleButton() {
  const player = this;

  videojs.registerComponent("CloseCaptionToggleButton", CloseCaptionToggleButton);
  player.controlBar.addChild("CloseCaptionToggleButton", {}, ccToggleButtonPositionIndex);
};

const button = {
  init() {
    const registeredPlugin = videojs.getPlugin("closeCaptionToggleButtonPlugin");
    /* istanbul ignore next */
    if (isNil(registeredPlugin)) {
      videojs.registerPlugin("closeCaptionToggleButtonPlugin", closeCaptionToggleButtonPlugin);
    }
  },
};

export default button;
