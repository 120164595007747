import { AppDispatch } from "features/Application/globaltypes/redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { PacksContextItem } from "../../../../../../interfaces";
import { ModalWithStepsStep } from "../../../../../../components/modal/ModalWithSteps";
import { Button } from "semantic-ui-react";
import { RenderModalActionsParams } from "components/assignmentModals/commonSteps/packsContextStep/PacksContextStep";
import { fetchDraftPackContextAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import PacksContextInfo from "features/Licensing/ContentAssignmentModalLicensingSteps/PackContextModalContent/packsContextInfo/PacksContextInfo";

import "./draftPackContextStep.scss";

export interface DraftPackContextRenderModalActionsParams extends RenderModalActionsParams {
  onCancel(): void;
}

export interface DraftPackContextStepProps extends ModalWithStepsStep {
  info: Array<PacksContextItem>;
  onIsDataValidChange: (isValid: boolean) => void;
  noDataLoaded: () => void;
  setModalStepHeader: () => void;
  onCancel(): void;
}

export type DraftPackContextStepAllProps = DraftPackContextStepProps & PropsFromRedux;

function DraftPackContextStep(props: DraftPackContextStepAllProps) {
  return (
    <div className="packs-context-step">
      <PacksContextInfo {...props} />
    </div>
  );
}

export const getDraftPackContextRenderModalActions = (params: DraftPackContextRenderModalActionsParams) => {
  return (nextStep: () => void) => {
    return (closeModal: Function) => {
      const { isDataValid, onIsDataValidChange, onCancel } = params;
      return (
        <>
          <Button
            basic
            className="cancel"
            content="Cancel"
            onClick={() => {
              onCancel();
              closeModal();
            }}
          />
          <Button
            primary
            className="next"
            content="Next"
            onClick={() => {
              onIsDataValidChange(false);
              nextStep();
            }}
            disabled={!isDataValid}
          />
        </>
      );
    };
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchContentPacks: bindActionCreators(fetchDraftPackContextAction, dispatch),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DraftPackContextStep);
