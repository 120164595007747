import { FC } from "react";
import { Card, CardProps } from "semantic-ui-react";
import cn from "classnames";
import "./selectableAssetCard.scss";

export interface Props extends CardProps {
  isSelected?: boolean;
}

export const SelectableAssetCard: FC<Props> = ({ children, className, isSelected, ...props }) => {
  const propsWithoutTitle = { ...props, title: null };

  return (
    <Card
      as="div"
      className={cn(className, "c-selectable-asset-card", { selected: isSelected })}
      {...propsWithoutTitle}
    >
      {children}
    </Card>
  );
};
