import React from "react";
import NoResults from "../../../../components/noResults/NoResults";
import { ThreatDefenceCampaignsNoResultsProps } from "../types";

function ThreatDefenceCampaignsNoResults(props: ThreatDefenceCampaignsNoResultsProps) {
  const description = (
    <span>
      Looks like you don't have any campaigns yet.
      <br />
      Add some using the button below.
    </span>
  );

  return (
    <NoResults title="You don’t have any campaigns" description={description} iconClassName="fa-envelope">
      {props.createButton}
    </NoResults>
  );
}

export default ThreatDefenceCampaignsNoResults;
