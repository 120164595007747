import { FC, useEffect } from "react";
import AssessmentPerformanceBody from "features/Library/Assessments/Performance/AssessmentPerformanceBody";
import { RegisterBreadcrumbCallback } from "features/Library/Common/Hooks/usePerformanceBreadcrumbs";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import EmailPerformanceBody from "features/Library/Communications/Emails/EmailDetails/Performance/EmailPerformanceBody";
import FlowPerformanceBody from "features/Library/Flows/Performance/FlowPerformanceBody";
import PdfPerformanceBody from "features/Library/Pdfs/Performance/PdfPerformanceBody";
import SurveyPerformanceBody from "features/Library/Surveys/Performance/SurveyPerformanceBody";
import VideoPerformanceBody from "features/Library/Videos/Performance/VideoPerformanceBody";
import { EntityType } from "features/Library/Flows/Designer/types";

export interface Props {
  id: number;
  type: EntityType;
  name: string;
  filter: PerformanceFilter;
  registerBreadcrumb: RegisterBreadcrumbCallback;
  onReset: () => void;
  includeAccounts: boolean;
}

export const ContentReportDrilldown: FC<Props> = ({ id, type, name, filter, registerBreadcrumb, onReset, includeAccounts }) => {
  useEffect(() => {
    registerBreadcrumb({ text: name, action: onReset });
  }, [name, onReset, registerBreadcrumb]);

  switch (type) {
    case EntityType.Flow:
      return (
        <FlowPerformanceBody flowId={id} flowTitle={name} dateFilter={filter} registerBreadcrumb={registerBreadcrumb} />
      );
    case EntityType.Video:
      return <VideoPerformanceBody includeAccounts={includeAccounts} videoId={id} videoTitle={name} videoFilter={filter} />;
    case EntityType.Assessment:
      return (
        <AssessmentPerformanceBody
          entityId={id}
          entityTitle={name}
          dateFilter={filter}
          registerBreadcrumb={registerBreadcrumb}
          hideDrilldownHeader
        />
      );
    case EntityType.Survey:
      return (
        <SurveyPerformanceBody
          surveyId={id}
          surveyTitle={name}
          dateFilter={filter}
          registerBreadcrumb={registerBreadcrumb}
          includeAccounts={includeAccounts}
          hideDrilldownHeader
        />
      );
    case EntityType.Email:
      return (
        <EmailPerformanceBody
          emailId={id}
          emailTitle={name}
          dateFilter={filter}
          registerBreadcrumb={registerBreadcrumb}
        />
      );
    case EntityType.Pdf:
      return <PdfPerformanceBody pdfId={id} pdfTitle={name} pdfFilter={filter} />;
    default:
      return <></>;
  }
};
