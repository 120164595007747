import { FC, useEffect, useState, useRef } from "react";
import { ButtonProps, Icon, Input } from "semantic-ui-react";
import cn from "classnames";

import "./CopyableInput.scss";

export interface Props {
  value?: string;
  placeholder?: string;
  timeout?: number;
  loading?: boolean;
  readonly?: boolean;
}

const copyToClipboard = (text: string) => window.navigator.clipboard.writeText(text);

const baseActionProps = {
  className: "action-button",
  toggle: true,
};

export const CopyableInput: FC<Props> = ({ value, placeholder, loading, timeout = 3000, readonly }) => {
  const timeoutId = useRef<number>();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied && !timeoutId.current) {
      const id = setTimeout(() => {
        timeoutId.current = undefined;
        setCopied(false);
      }, timeout);

      // @ts-ignore
      timeoutId.current = id;

      return () => clearTimeout(id);
    }
  }, [copied, timeout]);

  let actionButton: ButtonProps;

  if (copied) {
    actionButton = {
      ...baseActionProps,
      active: true,
      content: (
        <span>
          <Icon name="check circle" className="copied-icon" /> Copied
        </span>
      ),
    };
  } else {
    actionButton = {
      ...baseActionProps,
      disabled: !value || loading,
      color: "blue",
      content: "Copy",
      onClick: async (e) => {
        if (value) {
          const { currentTarget } = e;
          await copyToClipboard(value);
          setCopied(true);
          currentTarget.blur();
        }
      },
    };
  }

  return (
    <Input
      className={cn("copyable-input", { copied })}
      action={actionButton}
      value={value}
      placeholder={placeholder}
      loading={loading}
      iconPosition={loading ? "left" : undefined}
      readOnly={!!readonly}
    />
  );
};
