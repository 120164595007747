import { string, array, func, bool } from "prop-types";

export const multiSelectPropTypes = {
  placeholder: string,
  items: array,
  selected: array,
  handleMultiSelectChange: func,
  className: string,
  name: string,
  onBlur: func,
  disabled: bool,
  loading: bool,
};
