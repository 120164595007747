import { useEffect } from "react";

import UploadClosedCaptions from "./UploadClosedCaptions/UploadClosedCaptions";
import ClosedCaptionsList from "./ClosedCaptionsList/ClosedCaptionsList";

import { IObservable, IWizardStep } from "../../../../../interfaces";

import "./closedCaptions.scss";

export interface ClosedCaptionsProps extends IWizardStep {
  isReadOnly?: boolean;
  entityId?: number;
  onDiscardedObserver?: IObservable<() => void>;
  onTriggerClosedCaptionsRemovalObserver: IObservable<
    (onRemoveConfirm: () => void, selectedItemsCount: number) => void
  >;
}

const ClosedCaptions = (props: ClosedCaptionsProps) => {
  const { isReadOnly, entityId, onTriggerClosedCaptionsRemovalObserver, onDiscardedObserver, acceptHandlers } = props;

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="closed-captions-content">
      <UploadClosedCaptions isReadOnly={isReadOnly} entityId={entityId} />
      <ClosedCaptionsList
        entityId={entityId}
        isReadOnly={isReadOnly}
        onTriggerClosedCaptionsRemovalObserver={onTriggerClosedCaptionsRemovalObserver}
        onDiscardedObserver={onDiscardedObserver}
      />
    </div>
  );
};

export default ClosedCaptions;
