import { uniq } from "lodash";

import midnightService from "../../../../Application/services/midnightService/midnightService";
import draftEntityTypes from "../../../../../enums/draftEntityTypes";

import * as flowEntityStateActionTypes from "../actionTypes/flowEntityStateActionTypes";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import {
  FlowPublishSuccess,
  FlowLockSuccess,
  FlowDiscardSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";

export const updateFlowEntity = (payload) => ({
  type: flowEntityStateActionTypes.LOCKED_FLOW_UPDATED,
  payload,
});

export const resetFlowEntityState = makeActionCreator(flowEntityStateActionTypes.RESET_FLOW_ENTITY_STATE);

export const updateLockedFlowCommandBegin = makeActionCreator(
  flowEntityStateActionTypes.UPDATE_LOCKED_FLOW_COMMAND_BEGIN,
);

export const updateLockedFlowCommandCompleted = makeActionCreator(
  flowEntityStateActionTypes.UPDATE_LOCKED_FLOW_COMMAND_SUCCESS,
  "entityId",
);

export const resetUpdateLockedFlowCommandState = makeActionCreator(
  flowEntityStateActionTypes.RESET_UPDATE_LOCKED_FLOW_COMMAND_STATE,
);

export const fetchDraftFlowEntity = (id) => {
  const fetchLockedFlowBegin = makeActionCreator(flowEntityStateActionTypes.FETCH_LOCKED_FLOW_BEGIN);
  const fetchLockedFlowFailure = makeActionCreator(flowEntityStateActionTypes.FETCH_LOCKED_FLOW_FAILURE, "error");

  return async (dispatch) => {
    dispatch(fetchLockedFlowBegin());
    dispatch(beginAsyncOperation({ id, action: FlowLockSuccess }));
    try {
      await midnightService.getEntityLock(draftEntityTypes.Flows, id);
    } catch (error) {
      dispatch(fetchLockedFlowFailure(error));
    }
  };
};

export const fetchLockedFlowSuccess = makeActionCreator(
  flowEntityStateActionTypes.FETCH_LOCKED_FLOW_SUCCESS,
  "entityId",
);

export const setExistingFlowEntityAsDraft = makeActionCreator(
  flowEntityStateActionTypes.USE_EXISTING_LOCKED_FLOW_ENTITY,
  "entityId",
);

export const useExistingDraftFlowEntity = (navigate, id) => {
  return (dispatch) => {
    dispatch(setExistingFlowEntityAsDraft(id));
    navigate(`${id.toString()}/configuration`);
  };
};

export const publishDraftFlowEntity = (id, packIds, notificationConfirmPayload) => {
  const { notificationSettings, notifyTypes } = notificationConfirmPayload || {};
  const publishDraftFlowBegin = makeActionCreator(flowEntityStateActionTypes.PUBLISH_LOCKED_FLOW_BEGIN);
  const publishDraftFlowFailure = makeActionCreator(flowEntityStateActionTypes.PUBLISH_LOCKED_FLOW_FAILURE, "error");

  return async (dispatch) => {
    dispatch(publishDraftFlowBegin());
    dispatch(beginAsyncOperation({ id, action: FlowPublishSuccess }));
    try {
      const payload = { packIds, notificationSettings, notifyTypes };
      await midnightService.releaseLock(draftEntityTypes.Flows, id, payload);
    } catch (error) {
      dispatch(publishDraftFlowFailure(error));
    }
  };
};

export const publishNewFlowEntity = (id, payload) => {
  const publishBegin = makeActionCreator(flowEntityStateActionTypes.PUBLISH_LOCKED_FLOW_BEGIN);
  return async (dispatch, getState) => {
    try {
      const contentPacks = getState().licensing?.contentPacks?.items ?? [];
      const packIds = uniq(contentPacks.map((p) => p.packId));
      dispatch(publishBegin());
      dispatch(beginAsyncOperation({ id, action: FlowPublishSuccess }));
      await midnightService.releaseLock(draftEntityTypes.Flows, id, { ...payload, packIds });
    } catch (error) {}
  };
};

export const publishDraftFlowSuccess = makeActionCreator(
  flowEntityStateActionTypes.PUBLISH_LOCKED_FLOW_SUCCESS,
  "entityId",
);

export const revertFlowEntityToPublished = (id) => {
  const revertFlowEntityToPublishedBegin = makeActionCreator(
    flowEntityStateActionTypes.REVERT_LOCKED_FLOW_TO_PUBLISHED_BEGIN,
  );

  const revertFlowEntityToPublishedFailure = makeActionCreator(
    flowEntityStateActionTypes.REVERT_LOCKED_FLOW_TO_PUBLISHED_FAILURE,
    "error",
  );

  return async (dispatch) => {
    dispatch(revertFlowEntityToPublishedBegin());
    dispatch(beginAsyncOperation({ id, action: FlowDiscardSuccess }));
    try {
      await midnightService.discardLock(draftEntityTypes.Flows, id);
    } catch (error) {
      dispatch(revertFlowEntityToPublishedFailure(error));
    }
  };
};

export const revertFlowEntityToPublishedSuccess = makeActionCreator(
  flowEntityStateActionTypes.REVERT_LOCKED_FLOW_TO_PUBLISHED_SUCCESS,
  "entityId",
);
