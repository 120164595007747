import { ChartWrapper, HorizontalBarChart, HorizontalBarProps, LineChart } from "components/charts";
import "../flowPerformance.scss";
import { LineChartInfo } from "../../state/slices/flowPerformanceSlice";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { RequestState } from "features/Application/globaltypes/fetchRequest";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import CardReporting, { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { noBarData, noData } from "features/Library/Common/utils/performanceUtils";

export interface Props {
  header: string;
  goalLineChart: LineChartInfo;
  goalLineState: RequestState<any>;
  goalEngagement: HorizontalBarProps;
  goalTotalsState: RequestState<any>;
  goalCardsItems: CardReportingItem[];
  goalCardsState: RequestState<any>;
}

export const UsageCharts = ({
  header,
  goalLineChart,
  goalLineState,
  goalEngagement,
  goalTotalsState,
  goalCardsItems,
  goalCardsState,
}: Props) => {
  return (
    <div className="flowPerformanceApplication">
      <h2>{header}</h2>
      <div className="ct">
        <ChartWrapper {...goalLineChart.chartWrapperProps}>
          <RequestStatusRenderer state={goalLineState}>
            {goalLineChart.lineProps.xData.length > 0 ? (
              <LineChart {...sharedAccountReportingLineProps} {...goalLineChart.lineProps} />
            ) : (
              noData(null)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
        <ChartWrapper titles={["Activity by Type"]}>
          <RequestStatusRenderer state={goalTotalsState}>
            {noBarData(...(goalEngagement?.data?.map((d) => d.value) || [])) ? (
              noData(null)
            ) : (
              <HorizontalBarChart {...sharedAccountReportingHorizontalBarProps} {...goalEngagement} />
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className="cr">
        <RequestStatusRenderer state={goalCardsState}>
          <CardReporting items={goalCardsItems} />
        </RequestStatusRenderer>
      </div>
    </div>
  );
};
