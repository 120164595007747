import { XYCoord } from "react-dnd";
import {
  useReactFlowCanvasActions,
  useReactFlowCanvasState,
} from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import { ExtrasTypes } from "../../Extras/types";
import { useIntersection } from "../../intersection/hooks/useIntersection";

type UseDragLayerItem = any; // @see useDragLayer typings

export const useSectionHeaderOnOuterLayer = ({
  draggedItem,
  clientOffset,
}: {
  draggedItem: UseDragLayerItem;
  clientOffset: XYCoord | null;
}) => {
  const { headId, itemForSectionHeader } = useReactFlowCanvasState();
  const { setItemForSectionHeader } = useReactFlowCanvasActions();
  const { intersectedElement } = useIntersection({
    intersectionItem: itemForSectionHeader,
    updateIntersectionItem: setItemForSectionHeader,
    createIntersectionFilter: (currentNode) => (el) => {
      return currentNode.type === ExtrasTypes.SectionHeader && el.type !== ExtrasTypes.FlowEnd;
    },
    headId,
    draggedItem,
    clientOffset,
  });

  return { elementToAttachSectionHeader: intersectedElement };
};
