import {
  QueryFilter,
  completes,
  completesColor,
  dailyActivity,
  lineChartFactory,
  lineChartTitles,
  noBarData,
  noData,
  totalActivity,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import { FC, useEffect, useMemo, useState } from "react";

import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { useQuery } from "@tanstack/react-query";
import { getPdfsDailyData, getPdfsEngagement, getPdfsTable } from "../../../services/contentReportingService";
import { EngagementLineData } from "features/Library/Pdfs/types/state";
import { DataPoint } from "components/charts/types/HorizontalBarChart";

import { GenericPerformanceList } from "../../shared/GenericPerformanceList";
import { ColumnOption } from "interfaces/columnOptions";
import { PdfDetails } from "features/Reporting/types/content";
import { Tooltip } from "components/common/tooltip";
import { TextTruncate } from "components";
import dateTimeUtils from "utils/dateTimeUtils";
import { mockEmptyPdfEngagementData } from "features/Reporting/mocks/contentData";
import { CommonAccountReportPropsExtended } from "../../types";
import { EntityType } from "features/Library/Flows/Designer/types";
import { sharedAccountReportingHorizontalBarProps, sharedAccountReportingLineProps } from "../../shared";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { ConnectedProps, connect } from "react-redux";
import { bindAction } from "interfaces";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { bindActionCreators } from "@reduxjs/toolkit";
import { doPdfExport } from "features/Reporting/state/content/contentActions";

import styles from "../../contentOverviewReports.module.scss";
import "features/Library/Common/utils/performanceSCSSUtils.scss";
import { setIsReportEnabled, reset } from "features/Reporting/state/toolbar/toolbarSlice";

export type Props = CommonAccountReportPropsExtended & PropsFromRedux;

export type ExtractsEvents<T, K extends keyof T> = T[K];
export type Events = ExtractsEvents<EngagementLineData, "Events">;
export type RestructuredDay = Events & Pick<EngagementLineData, "Date">;

const fetchDailyData = async ({ queryKey }: QueryFilter) => {
  const result = await getPdfsDailyData(queryKey[1]);
  let newResult: RestructuredDay[] = [];

  for (const day of result.data) {
    const obj: Partial<RestructuredDay> = {};
    obj["Date"] = day.Date;
    for (const [key, value] of Object.entries(day.Events)) {
      obj[key as keyof Events] = value as any;
    }
    newResult.push(obj as RestructuredDay);
  }

  return lineChartFactory(newResult);
};

const fetchEngagementData = async ({ queryKey }: QueryFilter) => {
  let result = (await getPdfsEngagement(queryKey[1])).data;
  result = {
    ...mockEmptyPdfEngagementData,
    ...result,
  };
  const usageBarData: DataPoint[] = [
    {
      id: completes.toLowerCase(),
      category: completes,
      value: result.CompletedCount,
      fillColor: completesColor,
    },
  ];

  return usageBarData;
};

export const fetchTableData = async ({ queryKey }: QueryFilter) => {
  const result = (await getPdfsTable(queryKey[1])).data;

  return result.map((item) => {
    return {
      ...item,
      id: item.PdfId,
    };
  });
};

export const PdfReport: FC<Props> = ({
  filter,
  setSelectedContent,
  setHasExportData,
  setExportAction,
  pdfExport,
  setIsReportEnabled,
  resetIsReportEnabled,
}) => {
  const [chartState, setChartState] = useState(totalActivity);
  const reportEnabled = useFeatureFlag(FeatureFlags.PdfOverviewEndpoints);
  const lineChartQuery = useQuery({
    queryKey: ["AccountPdfLineReport", filter],
    queryFn: fetchDailyData,
    enabled: reportEnabled,
  });

  const engagementQuery = useQuery({
    queryKey: ["AccountPdfEngagementReport", filter],
    queryFn: fetchEngagementData,
    enabled: reportEnabled,
  });

  const detailsQuery = useQuery({
    queryKey: ["AccountPdfDetailsReport", filter],
    queryFn: fetchTableData,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setHasExportData(!!detailsQuery.data && detailsQuery.data.length > 0);
  }, [detailsQuery.data, setHasExportData]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: pdfExport,
      args: [filter],
      isExportEnabled: true,
    });
  }, [pdfExport, filter, setExportAction]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const lineData = useMemo(() => {
    if (chartState === dailyActivity) {
      return {
        xData: [lineChartQuery.data?.Date],
        yData: [lineChartQuery.data?.CompletedCount],
      };
    }
    return {
      xData: [lineChartQuery.data?.Date],
      yData: [lineChartQuery.data?.CumulativeCompletedCount],
    };
  }, [
    chartState,
    lineChartQuery.data?.CompletedCount,
    lineChartQuery.data?.CumulativeCompletedCount,
    lineChartQuery.data?.Date,
  ]);

  const barDomain = useMemo(() => {
    if (engagementQuery.isSuccess) {
      const maxValue = Math.max(...engagementQuery.data.map((d) => d.value));
      return [0, Math.max(maxValue, 1)];
    }
  }, [engagementQuery.data, engagementQuery.isSuccess]);

  const columns: ColumnOption<PdfDetails>[] = useMemo(
    () => [
      {
        name: "PDF",
        width: 7,
        isSortable: false,
        render(item) {
          return (
            <Tooltip
              target={
                filter.showCustomers ? (
                  <TextTruncate lines={2} className={styles.wordBreak}>
                    {item.PdfTitle}
                  </TextTruncate>
                ) : (
                  <button
                    className={styles.linkButton}
                    onClick={() => setSelectedContent({ id: item.PdfId, type: EntityType.Pdf, name: item.PdfTitle })}
                  >
                    <TextTruncate lines={2} className={styles.wordBreak}>
                      {item.PdfTitle}
                    </TextTruncate>
                  </button>
                )
              }
              content={item.PdfTitle}
              showAlways
            />
          );
        },
      },
      {
        name: `Total ${completes}`,
        width: 7,
        isSortable: false,
        render(item) {
          return <div>{item.CompletedCount.toLocaleString()}</div>;
        },
      },
      {
        name: "Last Activity",
        width: 7,
        isSortable: false,
        render(item) {
          return <div>{dateTimeUtils.formatDate(item.LastActivityDate)}</div>;
        },
      },
    ],
    [setSelectedContent, filter.showCustomers],
  );

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={styles.reportBody}>
      <div className={styles.lineChartContainer}>
        <ChartWrapper
          titles={lineChartTitles}
          colorRange={[completesColor]}
          showLegend
          onChange={setChartState}
          legendLabels={[completes]}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
              validLineData([lineChartQuery.data.CompletedCount, lineChartQuery.data.CumulativeCompletedCount]) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineData}
                xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                colorRange={[completesColor]}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.funnelChartContainer}>
        <ChartWrapper titles={["Engagement"]}>
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess && !noBarData(...engagementQuery.data.map((d) => d.value)) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                domain={barDomain}
                data={engagementQuery.data}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.table}>
        <RequestStatusRenderer state={detailsQuery.status}>
          {detailsQuery.isSuccess && (
            <GenericPerformanceList title="PDFs" rows={detailsQuery.data} columns={columns} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
  pdfExport: bindActionCreators(doPdfExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PdfReport);
