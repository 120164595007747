import React from "react";
import { PropTypes } from "prop-types";
import { Button } from "components/buttons/button/Button";
import cn from "classnames";

import Modal from "./Modal";

import "./InformationModal.scss";

class InformationModal extends React.Component {
  state = {
    suppressPopup: false,
  };

  renderModalActions = (closeModal) => {
    this.closeModal = closeModal;
    return (
      <Button className="ok" onClick={this.onCloseClick} basic>
        OK
      </Button>
    );
  };

  onCloseClick = () => {
    const { onClose } = this.props;
    this.closeModal();
    onClose && onClose();
  };

  render() {
    const { title, message, open, className, onClose } = this.props;

    return (
      <Modal
        className={cn("information-popup", className)}
        title={title}
        renderModalActions={this.renderModalActions}
        open={open}
        onCancel={onClose}
      >
        {message}
      </Modal>
    );
  }
}

InformationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default InformationModal;
