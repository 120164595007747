import React from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import ConfirmationModal from "./ConfirmationModal";
import ModalTypes from "./ModalTypes";

export class SuppressibleConfirmationModal extends React.Component {
  render() {
    if (!this.props.isVisible) {
      return null;
    }

    const {
      className,
      title,
      message,
      modalUtils,
      onClose,
      modalType,
      open,
      userId,
      confirmButtonName,
      cancelButtonName,
      checkboxIsHidden,
    } = this.props;

    const form = (
      <ConfirmationModal
        className={classNames(className)}
        key={modalType}
        title={title}
        message={message}
        confirmButtonName={confirmButtonName}
        cancelButtonName={cancelButtonName}
        checkboxIsHidden={checkboxIsHidden}
        onConfirm={modalUtils.executeWithoutModal}
        onSuppressPopupStatusChanged={modalUtils.updateSuppressStatus}
        onClose={onClose}
        open={open}
      />
    );
    return modalUtils.renderForm(form, userId, modalType);
  }
}

SuppressibleConfirmationModal.defaultProps = {
  isVisible: true,
};

SuppressibleConfirmationModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  confirmButtonName: PropTypes.string,
  cancelButtonName: PropTypes.string,
  checkboxIsHidden: PropTypes.bool,
  modalUtils: PropTypes.shape({
    renderForm: PropTypes.func,
    executeWithoutModal: PropTypes.func,
    updateSuppressStatus: PropTypes.func,
  }).isRequired,
  modalType: PropTypes.oneOf(Object.getOwnPropertyNames(ModalTypes)),
  open: PropTypes.bool,
  isVisible: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userId: state.userProfile.id,
});

export default connect(mapStateToProps)(SuppressibleConfirmationModal);
