import { Component } from "react";
import { isEmpty } from "lodash";
import { connect, ConnectedProps } from "react-redux";

import videoListUtils from "../../../utils/videoListUtils";
import PeopleVideoCard from "../cards/peopleVideoCard/PeopleOverviewVideoCard";
import ItemsView from "../../../views/ItemsView/ItemsView";
import VideosNotFound from "../../../views/library/videos/VideosNotFound/VideosNotFound";
import VideosFilterForm from "../../filterForms/VideosFilterForm/VideosFilterForm";
import SearchInput from "../../searchInput/SearchInput";
import { VideosAssignmentListRow } from "./VideosAssignmentListRow/VideosAssignmentListRow";
import { AppDispatch, RootState } from "../../../features/Application/globaltypes/redux";
import { Filters } from "../../../utils/queryUtils";
import { IVideoAssignmentModelItem } from "../../../features/People/types";
import {
  videoColumnOptionsListView,
  defaultSortingColumn,
  SortDirectionLong,
  sortOptionsListView,
} from "../ContentAssignmentConstants";
import { updateSelectedContentItems } from "../utils/contentAssignmentUtils";
import { bindAction } from "../../../interfaces/redux";
import { AssetViewItemModel, RequestParams, VideosAssignmentListProps, VideosAssignmentListState } from "./types";
import { AddPeopleToContentTypes, ItemsTypes, SortingDirection, SortOptions, ViewType } from "../../../enums";
import { fetchContentAssignmentsOverviewFilterOptions } from "../../../features/People/ContentAssignmentsOverview/thunks/contentAssignmentsOverviewFiltersThunk";
import {
  resetAppliedFilter,
  setAppliedFilter,
} from "../../../features/People/ContentAssignmentsOverview/slices/contentAssignmentsOverviewFiltersSlice";
import { CardsViewerItem } from "../../cardsViewer/types";
import { VideoColumns } from "../../../features/Library/Videos/types/models";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import "./videosAssignmentList.scss";
import { LDProps } from "../../../features/LDProps";

export type VideosAssignmentListAllProps = VideosAssignmentListProps & PropsFromRedux & LDProps;

const START_POSITION = 0;
const takeDefaultCount = Number.parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);

export class VideosAssignmentList extends Component<VideosAssignmentListAllProps, VideosAssignmentListState> {
  reloadListItems?: (enableSorting: boolean) => void;

  state = {
    selectedViewType: ViewType.GRID,
    orderBy: SortOptions.ModifiedDateDesc,
    search: "",
    bypassSortingForSearch: false,
  };

  componentWillUnmount() {
    this.props.resetVideosGrid();
    this.props.resetVideosList();
    this.props.resetFilter();
  }

  getDataWrapper = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
    filters?: Filters,
  ) => {
    this.getAssets({ skip, top, sortingColumnName, sortingDirection, filters });
  };

  getAssets = (params: RequestParams = {}) => {
    const { fetchVideosList, fetchVideosGrid, filters, gridState } = this.props;
    const { orderBy, search } = this.state;

    if (this.isGridView()) {
      const [sortBy, sortDirection] = this.checkSortParameter(orderBy)?.split(" ") ?? [];

      fetchVideosGrid(
        params.skip ?? gridState.items.length,
        takeDefaultCount,
        sortBy,
        SortDirectionLong[sortDirection],
        params.filters ?? filters.appliedFilter,
        search,
      );
    } else {
      fetchVideosList(
        params.skip,
        params.top,
        videoListUtils.getSortBy(params.sortingColumnName as VideoColumns),
        params.sortingDirection || SortingDirection.Descending,
        params.filters ?? filters.appliedFilter,
        search,
      );
    }
  };

  refreshGridItems = () => {
    const { resetVideosGrid } = this.props;
    const { selectedViewType } = this.state;
    if (selectedViewType === ViewType.GRID) {
      resetVideosGrid();
      this.getAssets({ skip: START_POSITION });
    }
  };

  isGridView = () => this.state.selectedViewType === ViewType.GRID;

  checkSortParameter(sortParameter: SortOptions) {
    return this.state.bypassSortingForSearch ? undefined : sortParameter;
  }

  onSortChange = async (_: any, data: any) => {
    this.props.resetVideosGrid();
    this.setState(
      {
        orderBy: data.value ?? SortOptions.ModifiedDateDesc,
        bypassSortingForSearch: false,
      },
      this.getAssets,
    );
  };

  // @ts-ignore
  renderFilterForm = () => <VideosFilterForm />;

  getTableOptions = () => {
    const editPriority = this.getOnEditPriorityHandler(this.props.contextMenuButtonHandlers?.onEditPriority);
    const columnOptions = videoColumnOptionsListView({
      isPriorityHide: true,
      isExpiresHide: true,
      isAddedHide: true,
    });

    return {
      columns: columnOptions,
      buildTableBody: (asset: AssetViewItemModel) => (
        <VideosAssignmentListRow
          showButtons={false}
          asset={asset}
          removeAssignment={this.getOnRemoveHandler(asset.canRemove, this.props.contextMenuButtonHandlers?.onRemove)}
          editPriorityLevel={editPriority}
          columnOptions={columnOptions}
          isEllipsisMenuDisabled={this.props.selectedVideos.includes(asset.id)}
          isReadOnly={this.props.isReadOnly}
          peopleType={this.props.peopleType}
          deepLink={this.props.deepLink}
        />
      ),
    };
  };

  applyFilter = (filter: Filters) => {
    const { onSelectedVideosChanged, resetVideosGrid } = this.props;

    this.props.setFilter(filter);
    onSelectedVideosChanged([]);
    if (this.isGridView()) {
      resetVideosGrid();
      this.getAssets({ skip: START_POSITION, filters: filter });
    }
  };

  resetFilter = () => {
    this.props.resetFilter();
    if (this.isGridView()) {
      this.props.resetVideosGrid();
      this.getAssets({ skip: START_POSITION, filters: {} });
    }
  };

  onViewTypeChange = (viewType: ViewType) => {
    const { onViewTypeChange } = this.props;
    this.props.resetVideosGrid();
    this.setState(
      {
        selectedViewType: viewType,
        orderBy: SortOptions.ModifiedDateDesc,
      },
      () => {
        if (viewType === ViewType.GRID) {
          this.getAssets();
        }
      },
    );

    onViewTypeChange && onViewTypeChange(viewType);
  };

  onSelectedAssetsGridChanged = (ids: number[]) => {
    this.updateSelectedItems(this.props.gridState.items, ids);
  };

  onSelectedAssetsListChanged = (ids: number[]) => {
    this.updateSelectedItems(this.props.listState.items, ids);
  };

  getOnRemoveHandler = (canRemove: boolean, onRemove?: (id: number) => void) => {
    return onRemove
      ? {
        onClick: (item: { id: number }) => onRemove(item.id),
        isDisabled: () => !canRemove,
        showTooltip: !canRemove,
        peopleType: this.props.peopleType,
      }
      : undefined;
  };

  getOnEditPriorityHandler = (onEditPriority?: (id: number) => void) => {
    return onEditPriority ? { onClick: (item: { id: number }) => onEditPriority(item.id) } : undefined;
  };

  updateSelectedItems = (collection: IVideoAssignmentModelItem[], ids: number[]) => {
    const { onSelectedVideosChanged } = this.props;

    const updatedSelectedAssets = updateSelectedContentItems(collection, ids);
    onSelectedVideosChanged && onSelectedVideosChanged(updatedSelectedAssets);
  };

  onSearchChange = (newSearchText: string) => {
    const isEmptySearchText = isEmpty(newSearchText);
    this.onSelectedAssetsListChanged([]);
    this.setState(
      {
        bypassSortingForSearch: !isEmptySearchText,
        search: newSearchText,
      },
      () => {
        this.reloadListItems?.(isEmptySearchText);
        this.refreshGridItems();
      },
    );
  };

  createReloadListItems = (reloadListItems: (enableSorting: boolean) => void) => {
    this.reloadListItems = reloadListItems;
  };

  getFilterOptions = () => {
    this.props.getFilterOptions(AddPeopleToContentTypes.Videos);
  };

  renderSearchInput = (accessRestricted: boolean) => (
    <SearchInput
      placeholder="Search for Videos..."
      disabled={accessRestricted}
      onChange={this.onSearchChange}
      defaultValue={this.state.search}
    />
  );

  render() {
    const {
      gridState,
      listState,
      filters,
      selectedVideos,
      customHeaderContent,
      isReadOnly,
      createButton,
      accessRestricted,
      peopleType,
      disablePopupMenu,
    } = this.props;

    const { selectedViewType } = this.state;
    const viewState = this.isGridView() ? gridState : listState;
    const tableOptions = this.getTableOptions();

    return (
      <ItemsView
        className="create-group-asset"
        viewType={selectedViewType}
        columnOptions={tableOptions.columns}
        isFirstLoad={viewState.items.length === 0}
        noResultsContent={
          <VideosNotFound
            filtered={!isEmpty(filters.appliedFilter) || !!this.state.search}
            createAssetButton={createButton}
          />
        }
        sortOptions={sortOptionsListView}
        orderBy={this.state.orderBy}
        blur
        onSortChange={this.onSortChange}
        setReloadListItems={this.createReloadListItems}
        getData={this.getDataWrapper}
        itemsInlineCount={viewState.itemsCount}
        isLoading={viewState.isLoading}
        items={viewState.items.map((item) => ({ ...item, disabled: isReadOnly }))}
        itemsType={ItemsTypes.Video}
        buildTableBody={tableOptions.buildTableBody}
        renderFilterForm={this.renderFilterForm}
        filterOptions={filters.filterOptions}
        filterOptionsLoading={filters.isLoading}
        resetFilter={this.resetFilter}
        appliedFilter={filters.appliedFilter}
        applyFilter={this.applyFilter}
        getFilterOptions={this.getFilterOptions}
        onSelectedItemsChanged={this.onSelectedAssetsGridChanged}
        onSelectedListItemsChanged={this.onSelectedAssetsListChanged}
        renderCard={(props: CardsViewerItem<IVideoAssignmentModelItem>) => {
          const disabled = isReadOnly || props.item.inherited;
          return (
            <PeopleVideoCard
              {...props}
              showExpirationDate={false}
              peopleType={peopleType}
              handlers={[]}
              disablePopupMenu={disablePopupMenu}
              showAdded={false}
              disabled={disabled}
              selectable={false}
              deepLink={this.props.deepLink}
            />
          );
        }}
        selectedIds={selectedVideos}
        isAllDataLoaded={gridState.isAllLoaded}
        onViewTypeChange={this.onViewTypeChange}
        sortingDirection={SortingDirection.Descending}
        sortingColumnName={defaultSortingColumn}
        onLoad={this.getAssets}
        tabAlias="assets"
        customHeaderContent={customHeaderContent}
        isSelectDisabled={() => true}
        renderSearch={this.renderSearchInput}
        accessRestricted={accessRestricted}
      />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    filters: state.people.contentAssignmentsOverview.filters,
    gridState: state.people.contentAssignment.assetGrid,
    listState: state.people.contentAssignment.assetList,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetFilter: bindAction(resetAppliedFilter, dispatch),
    setFilter: bindAction(setAppliedFilter, dispatch),
    getFilterOptions: bindAction(fetchContentAssignmentsOverviewFilterOptions, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withLDConsumer()(VideosAssignmentList));
