import React from "react";
import { Button } from "components/buttons/button/Button";

import NoResults from "../noResults/NoResults";
import navigationUtils from "../../utils/navigationUtils";
import { ReactComponent as ErrorIcon } from "../../images/bsi-error.svg";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./ErrorView.module.scss";

type Props = {
  children?: React.ReactNode
};

export const ErrorView: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    navigationUtils.goBackOrDefault(location, navigate, "/licensing/packs/available");
  };
  return (
    <div className={styles.root} data-testid={"error-view"}>
      <NoResults
        title="Oooops!"
        customStyles={{ root: styles["no-results"] }}
        description="Something went wrong."
        icon={<ErrorIcon />}
        outline={false}
      >
        <Button primary onClick={goBack}>
          Go Back
        </Button>
      </NoResults>
    </div>
  );
};

export default ErrorView;
