import { AccountInfo } from "features/Accounts/types";

export const UPDATE_DATA = "UPDATE_DATA";

export interface UpdateData {
  type: typeof UPDATE_DATA;
  payload: { accountInfo: AccountInfo };
}

export type UpdateAccountInformation = UpdateData;
