import { FC, useState } from "react";
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../Application/globaltypes/redux";
import { Dispatch } from "@reduxjs/toolkit";
import { DeleteLinkButton } from "../../../../../components";
import { Icon } from "semantic-ui-react";
import { RolePermissions, Strings } from "../../../../../enums";
import { Tooltip } from "../../../../../components/common/tooltip";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import Restricted from "features/Application/Restricted";
import { AddToPacksButton } from "components/buttons/AddToPacksButton/AddToPacksButton";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";

export interface Props {
  undeletableSelected: boolean;
  purchasedMessageSelected: boolean;
  draftMessagesSelected: boolean;
  selectedIds: Array<number>;
  setSelectedIds: (ids: number[]) => void;
  onAddedToPacks: () => void;
  deletionHandler: () => void;
  renderCreateButton: () => React.ReactElement;
}

export type MessagesOverviewHeaderProps = Props & PropsFromRedux;

export const MessagesOverviewHeader: FC<MessagesOverviewHeaderProps> = ({
  selectedIds,
  setSelectedIds,
  onAddedToPacks,
  undeletableSelected,
  deletionHandler,
  purchasedMessageSelected,
  draftMessagesSelected,
  renderCreateButton,
}) => {
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const deleteDisabled = purchasedMessageSelected || (!deleteContentEnabled && undeletableSelected);
  const deleteTooltipMessage = purchasedMessageSelected
    ? Strings.tooltipMessages.purchased.deleteDisabled("messages")
    : Strings.tooltipMessages.deleteDisabled("messages", ["Flows", "Packs"]);
  const [packsModalShown, setPacksModalShown] = useState(false);
  const addToPacksDisabled = draftMessagesSelected || purchasedMessageSelected;


  const handleAddToPacksClick = () => {
    setPacksModalShown(true);
  };

  const handleAddedToPacks = () => {
    setSelectedIds([]);
    onAddedToPacks();
  };

  const renderBatchButtons = () => {
    return (
      <>
        <Restricted permissions={[RolePermissions.PacksManage, RolePermissions.FlowsCreate]}>
          <AddToPacksButton isDisabled={addToPacksDisabled} onClick={handleAddToPacksClick} />
        </Restricted>
        <Tooltip
          showAlways={deleteDisabled}
          inverted
          hideOnScroll
          position="left center"
          target={
            <DeleteLinkButton
              after={deleteDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
              isDisabled={deleteDisabled}
              onClick={deletionHandler}
            />
          }
          content={deleteTooltipMessage}
        />
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={/* istanbul ignore next */ () => setPacksModalShown(false)}
          onComplete={handleAddedToPacks}
          selectedItemIds={selectedIds}
          contentType="Message"
        />
      </>
    );
  };

  const renderHeaderButtons = () => (selectedIds.length ? renderBatchButtons() : renderCreateButton());

  return (
    <OverviewHeader
      title="Communications"
      titleForGA="Communications"
      itemName="Message"
      selectedItemsAmount={selectedIds.length}
    >
      {renderHeaderButtons()}
    </OverviewHeader>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {};
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(MessagesOverviewHeader);
