import React, { memo } from "react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import ButtonWithIcon from "../../../../../../../../../components/buttons/buttonWithIcon/ButtonWithIcon";
import { FeatureFlags } from "../../../../../../../../../featureFlags";

import styles from "./startOfTheFlowCard.module.scss";
import ConnectedFlows from "../ConnectedFlows/ConnectedFlows";
import { LDProps } from "../../../../../../../../LDProps";

export interface StartOfTheFlowCardProps extends LDProps {
  showExternalTriggersModal: () => void;
  isReadOnly: boolean;
}

export const StartOfTheFlowCard = (props: StartOfTheFlowCardProps) => {
  return (
    <div className={styles.root} data-hook-type="flow-designer-start-of-the-flow-card" data-testid="start-flow-card">
      <div className={styles.header}>
        <ConnectedFlows />
        <div className={styles.title}>Start of Flow</div>
      </div>
      <ButtonWithIcon
        id="add-triggers-button"
        disabled={props.isReadOnly || !props.flags?.[FeatureFlags.ExternalTriggerFlow]}
        className={styles["add-triggers-button"]}
        label="External Trigger"
        iconName="plus"
        onClick={props.showExternalTriggersModal}
      />
    </div>
  );
};

export default memo(withLDConsumer()(StartOfTheFlowCard));
