import { RolePermissions } from "../../../enums";
import Restricted from "../../../features/Application/Restricted";
import { SubMenuItem } from "./SubMenuItem";

export interface RestrictedSubMenuAccordionItemProps {
  permissions: RolePermissions[];
  classNames: string;
  expanded: boolean;
  label: string;
  nestedItems: React.ReactElement[];
  path: string;
  onClick: () => void;
}

export const RestrictedSubMenuAccordionItem: React.FC<RestrictedSubMenuAccordionItemProps> = ({
  permissions,
  classNames,
  expanded,
  label,
  nestedItems,
  path,
  onClick,
}: RestrictedSubMenuAccordionItemProps) => (
  <Restricted permissions={permissions}>
    <SubMenuItem
      key={path}
      classNames={classNames}
      expanded={expanded}
      label={label}
      nestedItems={nestedItems}
      onClick={onClick}
      path={path}
    ></SubMenuItem>
  </Restricted>
);
