import { Grid } from "semantic-ui-react";

import ValidatedForm from "../../../../../components/forms/ValidatedForm";
import { RangeFilterValues } from "../../../../../utils/filterUtils";
import { EnrollmentRule } from "../AutomaticallyDetect/AutomaticallyDetect";
import { ErrorPosition } from "components/forms/ValidatedField";

export interface RangePickerProps {
  itemIndex: number;
  onIsValidChange?: (isValid: boolean) => void;
  items: EnrollmentRule[];
  validatedFieldProps: any;
  disabled: boolean;
  rowModelName: string;
  handleChange: (index: number) => void;
}

export const RangePicker = ({
  itemIndex,
  onIsValidChange,
  items,
  validatedFieldProps,
  disabled,
  rowModelName,
  handleChange: handleRuleChange,
}: RangePickerProps) => {
  return (
    <Grid>
      <Grid.Column>
        <ValidatedForm.DatePickerField
          placeholder="From"
          search
          selected={(items[itemIndex].value as RangeFilterValues).from || ""}
          propertyName={`${rowModelName}.value.from`}
          markAsRequired
          allowAdditions
          disabled={disabled || items[itemIndex].filter === null}
          onIsFormValidChange={onIsValidChange}
          onBlur={() => handleRuleChange(itemIndex)}
          onDateChange={() => handleRuleChange(itemIndex)}
          format="MM/DD/YYYY"
          maxDate={(items[itemIndex].value as RangeFilterValues).to}
          errorPosition={ErrorPosition.bottom}
          {...validatedFieldProps}
        />
      </Grid.Column>
      <Grid.Column>
        <span className="delimiter">-</span>
      </Grid.Column>
      <Grid.Column>
        <ValidatedForm.DatePickerField
          placeholder="To"
          search
          selected={(items[itemIndex].value as RangeFilterValues).to || ""}
          propertyName={`${rowModelName}.value.to`}
          markAsRequired
          minDate={(items[itemIndex].value as RangeFilterValues).from}
          allowAdditions
          disabled={disabled || items[itemIndex].filter === null}
          onIsFormValidChange={onIsValidChange}
          onBlur={() => handleRuleChange(itemIndex)}
          onDateChange={() => handleRuleChange(itemIndex)}
          format="MM/DD/YYYY"
          errorPosition={ErrorPosition.bottom}
          {...validatedFieldProps}
        />
      </Grid.Column>
    </Grid>
  );
};
