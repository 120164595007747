import { useRef } from "react";
import { Grid, Icon } from "semantic-ui-react";
import { dayOfWeeks } from "../../../../../../../components/weekDayPicker/WeekDayPicker";
import { PeriodType, SessionView } from "../../../../types/state";
import DescriptionBuilderProvider from "../../Utils/descriptionBuilderProvider";

export interface RecurringSessionDescriptionProps {
  session: SessionView;
  occursOn: string;
  weekDays: number[];
}

export const RecurringSessionDescription = (props: RecurringSessionDescriptionProps) => {
  const { session, occursOn, weekDays } = props;
  const { recurrenceSession, startsAt, endsAt, timeZone } = session;
  const { seriesEndDate, periodType, periodValue } = recurrenceSession || {};

  const builder = useRef(DescriptionBuilderProvider.createDescriptionBuilder()).current;

  builder
    .occursOn(occursOn)
    .endingOn(seriesEndDate)
    .startingAt(startsAt)
    .endingAt(endsAt)
    .usingTimeZone(timeZone)
    .usingPeriod(periodType ?? PeriodType.Day)
    .withRepetition(periodValue ?? 1)
    .onWeekDays(weekDays.map((x) => dayOfWeeks[x]));

  return (
    <Grid.Row>
      <Grid.Column>
        <div className="description-wrapper">
          <Icon link className="fa fa-sync" size="big" />
          <span>{builder.getDescription()}</span>
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};
