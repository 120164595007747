import { InputFieldDigitsOnly } from "../../../../../forms/InputFieldDigitsOnly";
import { ErrorPosition } from "../../../../../forms/ValidatedField";

export interface LicenseCountInputProps {
  packId: number;
  licensesCount?: number;
  assingedLicensesCount?: number;
  maxLicenseCount: number;
  onLicenseCountChange: (selectedPackId: number, licensesCount?: number) => void;
}

export const LicenseCountInput: React.FC<LicenseCountInputProps> = ({
  packId,
  licensesCount,
  assingedLicensesCount,
  onLicenseCountChange,
  maxLicenseCount,
}) => {
  const onValueChange = (value?: number) => {
    onLicenseCountChange(packId, value);
  };

  const validateLicenseCount = (value: number) => {
    if (value > maxLicenseCount) {
      return `Please enter a number smaller than <strong>${maxLicenseCount}</strong>`;
    } else if (assingedLicensesCount && assingedLicensesCount > value && value > 0) {
      return `Value must be greater than or equal to the current number of licenses in use. Current licenses: <strong>${assingedLicensesCount}</strong>`;
    } else {
      return;
    }
  };

  return (
    <>
      <span className="required-asterisk">Licenses</span>
      <InputFieldDigitsOnly
        value={licensesCount || ""}
        onChange={onValueChange}
        validate={validateLicenseCount}
        className="link-load-input license-count-input"
        errorPosition={ErrorPosition.top}
        dataTestId="licenses-count-input"
      />
    </>
  );
};
