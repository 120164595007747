import { DesignerContextSwitcherOption } from "../../../../Library/Flows/Designer/FlowComponentsPanel/contentView/PanelComponent/helpers/models";
import CommunicationTypes from "../../../../../enums/communicationTypes";

export const communicationsOptions = (): DesignerContextSwitcherOption[] => {
  const options = [
    {
      key: CommunicationTypes.Email,
      label: "Emails",
      iconName: "fa-envelope",
    },
    {
      key: CommunicationTypes.Message,
      label: "Messages",
      iconName: "fa-messages",
    }
  ];

  return options;
};
