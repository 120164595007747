import { SortingDirection } from "../enums";
import { VideoColumns } from "../features/Library/Videos/types/models";

const utils = {
  formatOrderParams: (sortingColumnName?: string, sortingDirection?: SortingDirection): string => {
    if (!sortingColumnName || !sortingDirection) {
      return "";
    }
    let name = sortingColumnName.toLowerCase();
    let orderParams;

    switch (name) {
      case "title":
        orderParams = `title ${sortingDirection}`;
        break;
      case "status":
        orderParams = `status ${sortingDirection}`;
        break;
      case "added":
        orderParams = `dateCreated ${sortingDirection}`;
        break;
      case "modified":
        orderParams = `dateModified ${sortingDirection}`;
        break;

      default:
        orderParams = `${name} ${sortingDirection}`;
    }

    return orderParams;
  },
  getSortBy: (sortingColumnName?: VideoColumns): string => {
    if (!sortingColumnName) {
      return "";
    }

    return {
      [VideoColumns.Title.toLowerCase()]: "title",
      [VideoColumns.Status.toLowerCase()]: "isDraft",
      [VideoColumns.Added.toLowerCase()]: "DateCreated",
      [VideoColumns.Modified.toLowerCase()]: "DateModified",
      [VideoColumns.Publisher.toLowerCase()]: "publisher",
    }[sortingColumnName.toLowerCase()];
  },
};

export default utils;
