import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { VideoOverview } from "../../types/models";
import { fetchOverviewVideos } from "../thunks/videoOverviewThunk";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { findIndex } from "lodash";

export type VideoOverviewState = FetchingItemsState<VideoOverview>;

export const initialState: VideoOverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const videoOverviewSlice = createFetchingItemsSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Videos, name: "overview" },
  initialState: initialState,
  reducers: {
    updateVideoAssetBegin(state: VideoOverviewState) {
      state.isLoading = true;
    },
    updateVideoAssetSuccess(state: VideoOverviewState, action: PayloadAction<VideoOverview>) {
      const index = findIndex(state.items, { id: action.payload.id });
      index >= 0 && state.items.splice(index, 1, action.payload);
      state.isLoading = false;
    },

    insertVideoAssetSuccess(
      state: VideoOverviewState,
      action: PayloadAction<{ videoAsset: VideoOverview; position: number; needToRemoveItem: boolean; count: number }>,
    ) {
      const payload = action.payload;
      const updatedVideoAssets = [...state.items];
      updatedVideoAssets.splice(payload.position, 0, payload.videoAsset);
      if (payload.needToRemoveItem) {
        if (payload.position !== updatedVideoAssets.length - 1) {
          updatedVideoAssets.pop();
        } else {
          updatedVideoAssets.shift();
        }
      }
      state.items = updatedVideoAssets;
    },
  },
  thunk: fetchOverviewVideos,
});

export const { reset, fetchFailure, updateVideoAssetBegin, updateVideoAssetSuccess, insertVideoAssetSuccess } =
  videoOverviewSlice.actions;

export default videoOverviewSlice.reducer;
