import SortOptions from "../../../../../enums/SortOptions";

export interface PeoplePacksGridSortingOptions {
  text: string;
  value: SortOptions;
  default: boolean;
}

export const getPeoplePacksGridSortingOptions = (): Array<PeoplePacksGridSortingOptions> => {
  const options = [
    {
      text: "Sorted A-Z",
      value: SortOptions.Title,
      default: false,
    },
    {
      text: "Last Issued",
      value: SortOptions.LicenseIssuedDesc,
      default: true,
    },
  ];
  return options;
};
