import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums/reducer";
import { GenericFiltersMap } from "../../../../../../utils/filterUtils";
import { createFiltersSlice, FiltersState } from "../../../../../Application/slices/createFiltersSlice";
import { SurveyFiltersEnum } from "../../../../Surveys/types/models";
import { PayloadAction } from "@reduxjs/toolkit";
import { VideoFiltersEnum } from "../../../../Videos/types/models";

export type FlowContentFilters = VideoFiltersEnum | SurveyFiltersEnum;

export interface FlowDesignerFiltersState extends FiltersState<FlowContentFilters> {
  search: string;
}

export const initialState: FlowDesignerFiltersState = {
  filterOptions: {} as GenericFiltersMap<FlowContentFilters>,
  appliedFilter: {} as GenericFiltersMap<FlowContentFilters>,
  isLoading: false,
  search: "",
};

const flowDesignerFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "designerFilters",
  },
  initialState: initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
  },
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  resetFilter,
  setSearch,
} = flowDesignerFiltersSlice.actions;

export default flowDesignerFiltersSlice.reducer;
