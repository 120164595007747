import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { PackTrialPeriod } from "../../types/state";
import { RootState } from "../../../../Application/globaltypes/redux";

export interface PackTrialPeriodsState extends FetchingItemsState<PackTrialPeriod> {}

const initialState: PackTrialPeriodsState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const packTrialPeriodsSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.PackTrialPeriods,
    name: "packTrialPeriods",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure } = packTrialPeriodsSlice.actions;

export const packTrialPeriodsSelector = (state: RootState) => state.packs.packTrialPeriods.items;

export default packTrialPeriodsSlice.reducer;
export type packTrialPriodsSliceType = ReturnType<typeof packTrialPeriodsSlice.reducer>;
