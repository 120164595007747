import { NoResultsWithButton, OverviewHeader, RestrictedByTooltip, SearchInput } from "components";
import { Button } from "components/buttons/button/Button";
import { RolePermissions, SortingDirection } from "enums";
import { useRestrictedCheck } from "features/Application/Restricted";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import GenericItemsView from "views/ItemsView/GenericItemsView";
import { Columns, columnToParamMap, getColumnOptions } from "./columnOptions";
import { RootState, AppDispatch } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { connect, ConnectedProps } from "react-redux";
import { reset } from "../state/TemplateOverviewSlice";
import {
  approveTemplate,
  declineTemplate,
  fetchTemplates,
  deleteTemplate,
} from "../state/thunks/TemplateOverviewThunk";
import { TemplatesFilterForm } from "./TemplatesFilterForm";
import { setAppliedFilter, resetAppliedFilter, setSearch } from "../state/TemplateFiltersSlice";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { faBuildingUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Observable } from "utils";
import { ProcessTemplateRequestModal } from "./ProcessTemplateRequestModal";

import { DeleteTemplateModal } from "./DeleteTemplateModal";

type Props = PropsFromRedux & {
  renderContentSwitcher?: () => React.ReactElement;
  onlyOwn?: boolean;
  title?: string;
};

export const TemplatesOverview = (props: Props) => {
  const {
    title = "Groups",
    fetchTemplates,
    items,
    itemsCount,
    isLoading,
    resetOverview,
    onSearchChanged,
    applyFilter,
    resetFilter,
    filters,
    approveTemplate,
    declineTemplate,
    deleteTemplate,
    onlyOwn = false,
  } = props;
  const hasManagePermission = useRestrictedCheck([RolePermissions.GroupsManage]);
  const reloadListItemsRef = useRef<(enableSorting: boolean) => void>();
  const isSearchEmpty = isEmpty(filters.search);
  const navigate = useNavigate();

  const processTemplateRequestObserver = useMemo(
    () => new Observable<(onSubmit: () => void, approve: boolean, owner: string) => void>(),
    [],
  );
  const deleteObserver = useMemo(() => new Observable<(onSubmit: () => void) => void>(), []);

  useEffect(() => {
    return () => {
      resetOverview();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChanged = (search: string) => {
    onSearchChanged(search);
    reloadListItemsRef.current?.(isEmpty(search));
  };

  const handleFetch = useCallback(
    (
      skip: number = 0,
      top: number = 10,
      sortingColumnName: string = Columns.Added,
      sortingDirection: SortingDirection = SortingDirection.Descending,
    ) => {
      const sortBy = columnToParamMap[sortingColumnName.toLowerCase() as Lowercase<Columns>];
      fetchTemplates(skip, top, sortBy, sortingDirection, onlyOwn);
    },
    [fetchTemplates, onlyOwn],
  );

  const handleEdit = useCallback(
    (id: number, isApproved: boolean) => {
      navigate(isApproved ? `/people/groups/${id}/enrollment` : `${id}`);
    },
    [navigate],
  );

  const processTemplate = useCallback(
    (id: number, approve: boolean, owner: string) => () => {
      processTemplateRequestObserver.notify(
        () => {
          if (approve) {
            approveTemplate(id, owner, () => reloadListItemsRef.current?.(isSearchEmpty));
          } else {
            declineTemplate(id, owner, () => reloadListItemsRef.current?.(isSearchEmpty));
          }
        },
        approve,
        owner,
      );
    },
    [approveTemplate, declineTemplate, isSearchEmpty, processTemplateRequestObserver],
  );

  const handleDelete = useCallback(
    (id: number) => () => {
      deleteObserver.notify(() => {
        deleteTemplate(id, () => reloadListItemsRef.current?.(isSearchEmpty));
      });
    },
    [deleteObserver, deleteTemplate, isSearchEmpty],
  );

  const columnOptions = useMemo(() => {
    return getColumnOptions({
      handleEdit,
      hasEditPermission: !hasManagePermission,
      processTemplate,
      handleDelete,
      onlyOwn,
    });
  }, [handleDelete, handleEdit, hasManagePermission, processTemplate, onlyOwn]);

  const renderCreateButton = () => {
    return (
      <RestrictedByTooltip hasPermission={hasManagePermission}>
        <Button
          primary
          className="create-button"
          onClick={() => navigate("add-template")}
          disabled={!hasManagePermission}
        >
          Create Template
        </Button>
      </RestrictedByTooltip>
    );
  };

  const renderNoResults = () => {
    return (
      <NoResultsWithButton
        title="You don’t have any templates"
        description="Looks like you don’t have any templates."
        icon={<FontAwesomeIcon icon={faBuildingUser} className="no-results-icon" />}
        actionButton={renderCreateButton()}
        filtered={!isEmpty(filters.appliedFilter) || !isEmpty(filters.search)}
      />
    );
  };

  return (
    <section className={classNames("nested-content")}>
      <OverviewHeader title={title} itemName="Template">
        {renderCreateButton()}
      </OverviewHeader>
      <GenericItemsView
        items={items}
        isLoading={isLoading}
        columnOptions={columnOptions}
        fetchData={handleFetch}
        dataCount={itemsCount}
        customHeaderContent={props.renderContentSwitcher?.()}
        renderSearch={() => <SearchInput placeholder="Search for template" onChange={handleSearchChanged} />}
        // @ts-ignore
        getFilterForm={() => <TemplatesFilterForm />}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        setReloadListItems={(reloadListItems) => (reloadListItemsRef.current = reloadListItems)}
        appliedFilter={filters.appliedFilter}
        noResultsContent={renderNoResults()}
      />
      <ProcessTemplateRequestModal processTemplateRequestObserver={processTemplateRequestObserver} />
      <DeleteTemplateModal deleteObserver={deleteObserver} />
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { overview, filters } = state.people.groupTemplate;

  return {
    ...overview,
    filters,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    resetOverview: () => dispatch(reset()),
    fetchTemplates: bindAction(fetchTemplates, dispatch),
    onSearchChanged: bindAction(setSearch, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetFilter: bindAction(resetAppliedFilter, dispatch),
    approveTemplate: bindAction(approveTemplate, dispatch),
    declineTemplate: bindAction(declineTemplate, dispatch),
    deleteTemplate: bindAction(deleteTemplate, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TemplatesOverview);
