import { combineReducers } from "redux";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import {
  IFetchingItemsState,
  withFetchingEntityListItems,
} from "../../../../Application/reducers/hoc/withFetchingItems";
import withFilteringItems from "../../../../Application/reducers/hoc/withFilteringItems";
import withSearch from "../../../../Application/reducers/hoc/withSearch";
import { User } from "../../../../../interfaces";

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.User_Assignments;

const usersAssignmentReducer = combineReducers({
  usersToBeAssigned: withFetchingEntityListItems<IFetchingItemsState<User>>(namespace, entityPrefix),
  filter: withFilteringItems(namespace, entityPrefix),
  search: withSearch(namespace, entityPrefix),
});

export default usersAssignmentReducer;
