import React, { FC, useState } from "react";
import { isEmpty } from "lodash";

import { IObservable } from "../../../../../../../interfaces";
import { ModalSizes } from "../../../../../../../enums";
import { FlowEndInfo, NextFlowInfo } from "../../../types";

import TriggeredConfirmationModal from "../../../../../../../components/triggeredConfirmationModal/TriggerableConfirmationModal";
import FlowList from "./FlowList/FlowList";

import styles from "./nextFlowModal.module.scss";

export interface NextFlowModalProps {
  flowEndInfo?: FlowEndInfo;
  onFlowEndChange: (flowEndInfo: FlowEndInfo) => void;
  onTriggerModalObserver: IObservable<(onConfirm: () => void) => void>;
}

const NextFlowModal: FC<NextFlowModalProps> = (props) => {
  const { onTriggerModalObserver, flowEndInfo } = props;
  const [selectedItem, setSelectedItem] = useState<NextFlowInfo | undefined>();

  const onConfirmed = () => {
    props.onFlowEndChange({
      completedMessage: flowEndInfo?.completedMessage || "",
      nextFlow: selectedItem,
    });
    setSelectedItem(undefined);
  };

  const onClose = () => {
    setSelectedItem(undefined);
  };

  return (
    <TriggeredConfirmationModal
      title="Connect Flow"
      size={ModalSizes.large}
      confirmButtonLabel="Connect"
      onConfirmed={onConfirmed}
      onClose={onClose}
      disabled={isEmpty(selectedItem)}
      onTriggerModalObserver={onTriggerModalObserver}
      styles={{ root: styles.root, content: styles.content }}
      content={<FlowList selectedItem={selectedItem || flowEndInfo?.nextFlow} setSelectedItem={setSelectedItem} />}
    />
  );
};

export default NextFlowModal;
