import { Dispatch } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { ReportingExport } from "../../../../components/reportingExport/ReportingExport";
import ReportingFilter from "../../../../components/reportingFilter/ReportingFilter";

import AssessmentPerformanceBody from "./AssessmentPerformanceBody";
import { dateRange30, PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { RootState } from "../../../Application/globaltypes/redux";
import { usePerformanceBreadcrumbs } from "../../Common/Hooks/usePerformanceBreadcrumbs";
import {
  reset,
  selectEngagementQuestionsStatus,
  selectEngagementQuestionsValue,
} from "../state/slices/assessmentPerformanceSlice";

import { FeatureFlags } from "featureFlags";
import {
  reset as resetExport,
  selectExportInfo,
  selectExporting,
  selectHasDataToExport,
  selectIsExportActive,
} from "features/Reporting/state/export/exportSlice";
import { IWizardStep, bindAction } from "interfaces";

import "../../Common/utils/performanceSCSSUtils.scss";
import "./AssessmentPerformance.scss";

export type Props = PropsFromRedux &
  IWizardStep & {
    entityId: number;
    entityTitle: string;
  };

export type CardsDataFormatted = {
  PassingGrade: string;
  AverageGrade: string;
  AverageTime: string;
  CompletedCount: number;
};

export const AssessmentPerformance: React.FC<Props> = ({
  entityId,
  entityTitle,
  resetState,
  resetExport,
  exportInfo,
  acceptHandlers,
  exporting,
  isExportEnabled,
  hasDataToExport,
}) => {
  const [dateFilter, setDateFilter] = useState<PerformanceFilter>(dateRange30());
  const reportEnabled = useFeatureFlag(FeatureFlags.AssessmentReport);
  const { domElements, registerBreadcrumb, hasBreadcrumbsToDisplay } = usePerformanceBreadcrumbs();

  useEffect(() => {
    return () => {
      resetState();
      resetExport();
    };
  }, [resetExport, resetState]);

  useEffect(() => {
    acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExport = useCallback(() => {
    exportInfo?.method(...exportInfo.args);
  }, [exportInfo]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="assessmentPerformanceRoot">
      <header className="assessmentHeader">
        {hasBreadcrumbsToDisplay ? (
          <div className="breadCrumbs">{domElements}</div>
        ) : (
          <h2 className="assessmentTitle">Summary</h2>
        )}
        <div className="assessmentActions">
          <ReportingFilter
            currentFilter={dateFilter}
            callback={({ dateFrom, dateTo }) => setDateFilter({ dateFrom, dateTo })}
          />
          {isExportEnabled && (
            <ReportingExport onClick={handleExport} currentlyExporting={exporting} disabled={!hasDataToExport} />
          )}
        </div>
      </header>
      <div className="performanceRoot">
        <AssessmentPerformanceBody
          entityId={entityId}
          entityTitle={entityTitle}
          registerBreadcrumb={registerBreadcrumb}
          dateFilter={dateFilter}
        />
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    exportInfo: selectExportInfo(state),
    exporting: selectExporting(state),
    engagementQuestionsStatus: selectEngagementQuestionsStatus(state),
    engagementQuestionsData: selectEngagementQuestionsValue(state),
    isExportEnabled: selectIsExportActive(state),
    hasDataToExport: selectHasDataToExport(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    resetState: bindAction(reset, dispatch),
    resetExport: bindAction(resetExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssessmentPerformance);
