import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { EmailSetting } from "features/Accounts/types";
import { RootState } from "features/Application/globaltypes/redux";
import { getPrefix } from "features/Application/slices/models";

export interface EmailSettingsState {
  settings: EmailSetting;
  isLoading: boolean;
  error: Error | undefined;
}

const initialState: EmailSettingsState = {
  settings: {
    id: undefined,
    isSsl: false,
    host: "",
    password: undefined,
    port: 0,
    senderEmail: "",
    senderName: "",
    userName: undefined,
    replyToEmail: "",
    accountId: 0,
  },
  isLoading: false,
  error: undefined,
};

export const editEmailSettingsSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.EmailSettings,
    name: "edit",
  }),
  initialState,
  reducers: {
    req(state: EmailSettingsState) {
      state.isLoading = true;
    },
    got(state: EmailSettingsState, action: PayloadAction<EmailSetting>) {
      return {
        ...state,
        isLoading: false,
        settings: action.payload,
      };
    },
    err(state: EmailSettingsState, action: PayloadAction<Error>) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { req, got, err } = editEmailSettingsSlice.actions;

export const emailSettingsSelector = (state: RootState) => state.accounts.emailSettings;

export default editEmailSettingsSlice.reducer;

export type EditEmailSettingsSliceType = ReturnType<typeof editEmailSettingsSlice.reducer>;
