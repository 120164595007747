import { useFormik } from "formik";
import { noop } from "lodash";
import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { bindAction } from "../../../../interfaces";
import { successFactorValidationSchema } from "../../../../utils/validationSchemas/successFactorValidationSchema";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { integrationBaseSelector } from "../state/selectors";
import { reset } from "../state/slices/integrationsOverviewSlice";
import { fetchPacksAction } from "../state/thunks/integrationPacksThunk";
import { SfIntegrationConfigs } from "../types/successFactors";
import { SfConfigurationForm } from "./Forms/SfConfigurationForm";
import { useParams } from "react-router-dom";

export interface SfIntegrationConfigurationProps {
  integrationName: string;
  onUpdate: (configs: SfIntegrationConfigs) => void;
  initialConfig: SfIntegrationConfigs;
  enableReinitialize?: boolean;
  renderHeader?: boolean;
  onIsValidChange: (isValid: boolean) => void;
  bindResetForm?: (reset: () => void) => void;
}

export const SfIntegrationConfiguration = (props: SfIntegrationConfigurationProps & PropsFromRedux) => {
  const {
    onUpdate,
    initialConfig,
    enableReinitialize,
    integrationName,
    isLoading,
    packs,
    fetchPacks,
    resetPacks,
    renderHeader,
    onIsValidChange,
    bindResetForm,
  } = props;
  const { moboId } = useParams();

  const formik = useFormik({
    validationSchema: successFactorValidationSchema,
    initialValues: initialConfig,
    enableReinitialize: !!enableReinitialize,
    onSubmit: noop,
    validateOnBlur: false,
  });

  const { values, isValid, validateForm, resetForm } = formik;

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  useEffect(() => {
    bindResetForm?.(resetForm);
  }, [bindResetForm, resetForm]);

  useEffect(() => {
    onUpdate(values);
  }, [values, onUpdate]);

  useEffect(() => {
    onIsValidChange(isValid);
  }, [isValid, onIsValidChange]);

  useEffect(() => {
    fetchPacks(moboId);
    return resetPacks;
  }, [fetchPacks, resetPacks, moboId]);

  return (
    <SfConfigurationForm
      formik={formik}
      integrationName={integrationName}
      isPacksLoading={isLoading}
      packs={packs}
      renderHeader={renderHeader}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = integrationBaseSelector(state).base;
  return {
    isLoading: base.packs.isLoading,
    packs: base.packs.items,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchPacks: bindAction(fetchPacksAction, dispatch),
    resetPacks: bindAction(reset, dispatch),
  };
};

const stateConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof stateConnector>;

export default stateConnector(SfIntegrationConfiguration);
