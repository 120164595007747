import { bindActionCreators } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { FC, useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SearchInput } from "../../../../../../../components";

import Restricted from "../../../../../../Application/Restricted";
import UsersListContainer from "./UsersListContainer/UsersListContainer";

import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";
import { EventAssignmentColumns, EventColumnToParamMap, getEventUserColumnOptions } from "./getColumnOptions";

import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";
import { FiltersMap } from "../../../../../../../utils/filterUtils";
import ContentUsersNoResults from "../../../../../Common/Fields/ContentUsersNoResults";
import { DEFAULT_REQUEST_PARAMS } from "../../../../../PeopleAssignments/Overview/helpers/constants";
import {
  resetSearch as resetSearchAction,
  setSearch as setSearchAction,
} from "../../../../state/actions/eventsUserAssignmentActions";
import { resetSelectedItems } from "../../../../state/slices/eventUsersSlice";
import { fetchEventUsers } from "../../../../state/thunks/eventUsersThunk";

export interface UsersOverviewPropsBase {
  eventId: number;
  customHeaderContent: React.ReactElement;
}

export type UsersOverviewProps = UsersOverviewPropsBase & PropsFromRedux;

export const UsersOverview: FC<UsersOverviewProps> = (props: UsersOverviewProps) => {
  const { eventId, customHeaderContent, fetchUsers, onUnmount, setSearch, resetSearch } = props;
  const reloadListItems = useRef<(enableSorting: boolean) => void>();

  // @ts-ignore
  const renderSearchInput = (): React.ReactElement => (
    <SearchInput placeholder="Search for People..." onChange={onSearchChanged} />
  );

  useEffect(
    () => () => {
      resetSearch();
      onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getEventUsers = (
    skip: number = DEFAULT_REQUEST_PARAMS.skip,
    top: number = DEFAULT_REQUEST_PARAMS.top,
    orderBy: string = EventAssignmentColumns.Name,
    sortingDirection: SortingDirection = DEFAULT_REQUEST_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = EventColumnToParamMap[orderBy.toLowerCase() as Lowercase<EventAssignmentColumns>];
    fetchUsers({
      entityId: eventId,
      skip,
      top,
      sortingColumn,
      sortingDirection,
      filter: appliedFilter,
      term: props.search,
    });
  };

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  const onSearchChanged = (search: string): void => {
    setSearch(search);
    reloadListItems.current?.(isEmpty(search));
  };

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.User,
    RolePermissions.EventsManage,
  );

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={() => (
        <UsersListContainer
          defaultSortingColumnName={EventAssignmentColumns.Name}
          sortingDirection={SortingDirection.Ascending}
          columnOptions={getEventUserColumnOptions()}
          fetchData={getEventUsers}
          renderSearch={renderSearchInput}
          setReloadListItems={createReloadListItems}
          customHeaderContent={customHeaderContent}
          isSelectDisabled={() => true}
          noResultsContent={<ContentUsersNoResults filtered={!!props.search} />}
        />
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  search: state.library.events.userAssignment.search,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: bindActionCreators(fetchEventUsers, dispatch),
  onUnmount: () => dispatch(resetSelectedItems()),
  setSearch: (search: string) => dispatch(setSearchAction(search)),
  resetSearch: () => dispatch(resetSearchAction()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersOverview);
