import { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { RenderModalActionsType } from "../../../../../../../components/assignmentModals/types";

import { NotifyStepSettings, PriorityLevel } from "../../../../../../../interfaces";
import { AppDispatch } from "../../../../../../Application/globaltypes/redux";
import { FlowItem, PeopleType } from "../../../../../PeopleAssignments/types";
import { UsersGroupsContext, Strings, TemplateTypes } from "../../../../../../../enums";
import ItemTypes from "../../../../../../../enums/packItemsTypes";

import GroupListStepContainer from "../Groups/GroupListStepContainer";
import EditPeopleAssignments from "../../../../../PeopleAssignments/Edit/EditPeopleAssignments";
import UserListStepContainer from "../Users/UserListStepContainer";

import { assignPeopleOnEdit } from "../../../../state/actions/flowPeopleAssignmentsActions";
import { resetContentPacksAction } from "../../../../../../Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { once } from "lodash";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

const contextToPeopleType = {
  [UsersGroupsContext.Groups]: PeopleType.Group,
  [UsersGroupsContext.Users]: PeopleType.User,
  [UsersGroupsContext.TemplateGroups]: PeopleType.TemplateGroup,
};

export interface EditFlowPeopleAssignmentsPropsBase {
  entityItems: FlowItem[];
  flowIds: number[];
  usersGroupsContext: UsersGroupsContext;
  showModal: boolean;
  onShowModalChanged: (showModal: boolean) => void;
  notifyStepDisabled?: boolean;
}

export type EditFlowPeopleAssignmentsProps = EditFlowPeopleAssignmentsPropsBase & PropsFromRedux;

export const EditFlowPeopleAssignment: FC<EditFlowPeopleAssignmentsProps> = ({
  entityItems,
  flowIds,
  showModal,
  onShowModalChanged,
  usersGroupsContext,
  onLicenseConfirmed,
  resetContentPacks,
  notifyStepDisabled,
}) => {
  const flexiblePriorityDueDate = useFeatureFlag(FeatureFlags.FlexiblePriorityDueDate);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const onAddPeopleConfirm = (
    ids: number[],
    priorityLevels: PriorityLevel[],
    notificationSettings?: NotifyStepSettings,
  ) => {
    if (ids.length === 0) {
      return;
    }

    onLicenseConfirmed(
      priorityLevels,
      ids,
      contextToPeopleType[usersGroupsContext],
      flexiblePriorityDueDate,
      notificationSettings,
    );

    onCloseModal();
  };

  const onCloseModal = () => {
    resetContentPacks();
    onShowModalChanged(false);
    setSelectedIds([]);
  };

  const PeopleListStepContainer = {
    [UsersGroupsContext.Groups]: GroupListStepContainer,
    [UsersGroupsContext.Users]: UserListStepContainer,
    [UsersGroupsContext.TemplateGroups]: () => <></>,
  }[usersGroupsContext];

  const renderFirstCustomStep = (renderModalActions: RenderModalActionsType) => (
    <PeopleListStepContainer
      renderModalActions={renderModalActions}
      setIds={setSelectedIds}
      selectedIds={selectedIds}
      flowIds={flowIds}
      header={Strings.modalTitles.addPeople}
    />
  );

  return (
    <EditPeopleAssignments
      entityItems={entityItems}
      onAddPeopleConfirm={once(onAddPeopleConfirm)}
      usersGroupsContext={usersGroupsContext}
      showModal={showModal}
      onCloseModal={onCloseModal}
      selectedIds={selectedIds}
      renderFirstCustomStep={renderFirstCustomStep}
      contentType={ItemTypes.Flow}
      skipNotifyStep={notifyStepDisabled}
      notifyStepDisabled={notifyStepDisabled}
      notifyTemplateType={TemplateTypes.AddedToFlowV2}
    />
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onLicenseConfirmed: (
    levels: PriorityLevel[],
    ids: number[],
    peopleType: PeopleType,
    useFlexibleDueDate: boolean,
    notificationSettings?: NotifyStepSettings,
  ) => dispatch(assignPeopleOnEdit(levels, ids, peopleType, useFlexibleDueDate, notificationSettings)),
  resetContentPacks: bindActionCreators(resetContentPacksAction, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditFlowPeopleAssignment);
