import PublishStatusCard from "../PublishStatusCard";
import dateTimeUtils from "../../../utils/dateTimeUtils";

import { CardBase } from "../../../interfaces/CardPropsBase";
import { Image } from "semantic-ui-react";

import pdfIcon from "../../../images/pdf-icon.svg";

import styles from "./pdfCard.module.scss";

export type PdfCardProps = CardBase;

export const PdfCard = (props: PdfCardProps) => {
  const { item, onSelect, disabled, url, popupMenu, selected } = props;

  const renderPlaceholderImage = () => (
    <div className={styles["thumbnail"]}>
      <Image className={styles["thumbnail-icon"]} src={pdfIcon} alt={item.title} />
    </div>
  );

  return (
    <PublishStatusCard
      id={item.id}
      title={item.title}
      thumbnailUrl={item.thumbnailUrl}
      placeholderContent={renderPlaceholderImage()}
      publisher={item.publisher}
      published={!item.isDraft}
      disabled={disabled}
      url={url}
      selected={selected}
      popupMenu={popupMenu}
      onSelectionChanged={onSelect}
      className={styles["root"]}
    >
      <div className={styles["date-added"]}>Added {dateTimeUtils.formatDate(item.dateCreated)}</div>
    </PublishStatusCard>
  );
};
