import React from "react";
import { FormikProps } from "formik";
import TimeZonePicker, { TimeZonePickerProps } from "../../../../../../Library/Common/Fields/TimeZonePicker";
import { WithFormikPayload } from "../../../../../Common/Fields/WithFormikPayload";

export interface TimeZonePickerFieldProps {
  propertyName: string;
  label?: string;
  markAsRequired?: boolean;
  onBlur?: (propertyName: string) => void;
  value: string | boolean | number | undefined;
  disabled: boolean | undefined;
  direction?: "left" | "right";
}

export type TimeZonePickerFieldPropsAll<T> = TimeZonePickerProps & TimeZonePickerFieldProps & FormikProps<T>;

export default function TimeZonePickerField<T>(props: TimeZonePickerFieldPropsAll<T>) {
  const { value, setFieldTouched, setFieldValue, onBlur, label, direction } = props;

  return (
    <WithFormikPayload
      {...props}
      render={(formikProps) => {
        return (
          <TimeZonePicker
            selfValidation={true}
            lazyLoad={true}
            value={value}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onBlur={onBlur}
            label={label}
            disabled={props.disabled}
            direction={direction}
            {...formikProps}
          />
        );
      }}
    />
  );
}
