import {
  createCancelingLazyFetchingItemsSlice,
  CancelingLazyFetchingItemsState,
} from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { fetchGroupAvailablePacks } from "../thunks/groupAvailablePacksGridThunk";
import { PackOverview } from "../../types/state";

export type OverviewState = CancelingLazyFetchingItemsState<PackOverview>;

export const initialState: OverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const groupAvailablePacksGridSlice = createCancelingLazyFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "groupAvailablePacks",
  },
  initialState: initialState,
  reducers: {},
  thunk: fetchGroupAvailablePacks,
});

export const { reset } = groupAvailablePacksGridSlice.actions;

export default groupAvailablePacksGridSlice.reducer;
export type groupAvailablePacksGridSliceSliceType = ReturnType<typeof groupAvailablePacksGridSlice.reducer>;
