import { timeInterval } from "./interval.js";

export const millisecond = timeInterval(
  () => {
    // noop
  },
  (date, step) => {
    date.setTime(+date + step);
  },
  (start, end) => {
    return end - start;
  },
);

// An optimized implementation for this simple case.
millisecond.every = (k) => {
  k = Math.floor(k);
  if (!isFinite(k) || !(k > 0)) return null;
  if (!(k > 1)) return millisecond;
  return timeInterval(
    (date) => {
      date.setTime(Math.floor(date / k) * k);
    },
    (date, step) => {
      date.setTime(+date + step * k);
    },
    (start, end) => {
      return (end - start) / k;
    },
  );
};

export const milliseconds = millisecond.range;
