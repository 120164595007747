import axios from "axios";
import { TagsToContent } from "../../../../interfaces";
import { GetPagedDataByIdRequestV3, WithPagedDataV2 } from "../../../../interfaces/getPagedDataRequest";
import {
  Filters,
  formatFiltersV2api as formatUserFiltersV2api,
  mapToV2Filters,
  overviewFiltering,
  serializeArrayAndFilterNullable,
} from "../../../../utils/queryUtils";
import { escapeTerm } from "../../../../utils/searchUtils";
import { v2FilterMap } from "../../../People/types";
import { BasePerformanceRequestFilterParams, VideoPerformanceRequestFilterParams } from "../../Common/models";
import {
  EngagementLineData,
  EngagementPeopleDataSansId,
  VideoEngagementData,
} from "../state/slices/videoPerformanceSlice";
import { TagPayload } from "../types";
import { GetClosedCaptions } from "../types/requests";

let lineController = new AbortController();
let funnelController = new AbortController();
let peopleController = new AbortController();
let exportAbortController = new AbortController();

export interface BackendVideosConfig {
  params: {
    skip?: number;
    top?: number;
    orderBy?: string;
    filter?: string;
    search?: string;
  };
}

export interface BackendVideosConfigV2 {
  params: {
    skip?: number;
    top?: number;
    sortBy?: string;
    sortOrder?: string;
    filter?: Filters;
    search?: string;
  };
}

const axiosWithoutAuthInterceptor = axios.create({
  headers: { "Content-Type": "" }, // Override defaults application/json
});

const service = {
  async getPublishedVideosByIds(ids: string[], showPurchased: boolean) {
    const config = {
      params: {
        showPurchased,
        filter: ids.map((x) => `(PrimaryId eq ${x})`).join(" or "),
      },
    };

    return axios.get("/api/assets/videos/published", config);
  },

  async allocateVideoResources() {
    return axios.post("/api/assets/videos/allocate");
  },

  /* istanbul ignore next */
  uploadFileByPresignedUrl(
    presignedUrl: string,
    fileData: File,
    onUploadProgressHandler: (progressEvent: any) => void,
    abortSignal: AbortSignal,
  ) {
    return axiosWithoutAuthInterceptor.put(presignedUrl, fileData, {
      onUploadProgress: onUploadProgressHandler,
      signal: abortSignal,
    });
  },

  generateSas(id: number) {
    return axios.post(`/api/assets/videos/${id}/generateSas`);
  },

  /* istanbul ignore next */
  generatePresignedUrl(id: number) {
    return axios.post(`/api/assets/videos/${id}/presignedUrl`);
  },

  async getPublishersFilterOptions() {
    return axios.get("/api/assets/videos/publishers");
  },

  async getVideoInfo(id: number) {
    return axios.get(`/api/assets/videos/${id}`);
  },

  async generateThumbnail(id: number, data: { positionInSeconds: number; }) {
    return axios.post(`/api/assets/videos/${id}/thumbnail/position`, data);
  },

  async updateProperties(data: any) {
    return axios.put(`/api/assets/videos/${data.id}`, data);
  },

  saveVideoTagDetails(id: number, payload: TagPayload) {
    return axios.put(`/api/assets/videos/${id}/tags`, payload);
  },

  async updateImageThumbnail(id: number, data: any) {
    return axios.put(`/api/assets/videos/${id}/thumbnail`, data);
  },

  async getTagsFilterOptions(showPurchased: boolean) {
    return axios.get("/api/assets/videos/tags", { params: { showPurchased } });
  },

  async getStreamingInfo(assetId: number) {
    return axios.get(`/api/assets/videos/${assetId}/streaming`);
  },

  async updateVideo(id: number, data: any) {
    return axios.put(`/api/assets/videos/${id}/video`, data);
  },

  async deleteVideos(ids: number[]) {
    return axios.delete("/api/assets/videos", {
      data: { ids },
    });
  },

  getVideoUsersV2<T>(entityId: number, request: WithPagedDataV2<T>) {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
    };
    return axios.get(`/api/v4/content/video/${entityId}/users`, config);
  },

  getVideoGroupsV2(request: GetPagedDataByIdRequestV3) {
    const { entityId, skip, top, term, filter } = request;
    return axios.get(`/api/v4/content/video/${entityId}/groups`, {
      params: {
        skip,
        top,
        ...formatUserFiltersV2api(mapToV2Filters(filter!, v2FilterMap)),
        term: escapeTerm(term),
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  async addTagsToVideo(data: TagsToContent) {
    return axios.post(`/api/assets/videos/tags`, data);
  },

  getAvailableLanguages(videoId: number) {
    return axios.get(`api/assets/videos/${videoId}/closed-captions/available-languages`);
  },

  getAssignedClosedCaptions(request: GetClosedCaptions) {
    return axios.get(`api/assets/videos/${request.videoId}/closed-captions`, {
      params: {
        skip: request.skip,
        top: request.top,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
  },

  setDefaultClosedCaption(id: number) {
    return axios.put(`/api/assets/videos/closed-captions/${id}`);
  },

  deleteClosedCaptions(videoId: number, ids: number[]) {
    return axios.delete(`/api/assets/videos/${videoId}/closed-captions`, {
      data: { ids },
    });
  },

  // Performance section
  async getVideoEngagement(mediaId: number, filterParams: VideoPerformanceRequestFilterParams) {
    lineController.abort();
    lineController = new AbortController();
    const response = await axios.get<EngagementLineData>("/api/reports/v2/media/linechart", {
      params: {
        mediaId,
        ...filterParams,
      },
      paramsSerializer: overviewFiltering,
      signal: lineController.signal,
    });
    return response.data;
  },

  async getVideoEngagementV3(itemId: number, filterParams: VideoPerformanceRequestFilterParams) {
    lineController.abort();
    lineController = new AbortController();
    const response = await axios.get<EngagementLineData>("/api/reports/v3/videos/linechart", {
      params: {
        itemId,
        ...filterParams,
      },
      paramsSerializer: overviewFiltering,
      signal: lineController.signal,
    });
    return response.data;
  },

  async getVideoEngagementFunnel(mediaId: number, filterParams: VideoPerformanceRequestFilterParams) {
    funnelController.abort();
    funnelController = new AbortController();
    const response = await axios.get<VideoEngagementData>("/api/reports/v2/media/engagement", {
      params: {
        mediaId,
        ...filterParams,
      },
      paramsSerializer: overviewFiltering,
      signal: funnelController.signal,
    });
    return response.data;
  },

  async getVideoEngagementFunnelV3(itemId: number, filterParams: VideoPerformanceRequestFilterParams) {
    funnelController.abort();
    funnelController = new AbortController();
    const response = await axios.get<VideoEngagementData>("/api/reports/v3/videos/engagement", {
      params: {
        itemId,
        ...filterParams,
      },
      paramsSerializer: overviewFiltering,
      signal: funnelController.signal,
    });
    return response.data;
  },

  async getVideoEngagementPeople(mediaId: number, filterParams: VideoPerformanceRequestFilterParams) {
    peopleController.abort();
    peopleController = new AbortController();
    const response = await axios.get<EngagementPeopleDataSansId>("/api/reports/v2/media/people-details", {
      params: { mediaId, ...filterParams },
      paramsSerializer: overviewFiltering,
      signal: peopleController.signal,
    });
    return response.data.map((item, index) => {
      return {
        ...item,
        id: `${item.UserId}${index}`,
      };
    });
  },

  async getVideoEngagementPeopleV3(itemId: number, filterParams: VideoPerformanceRequestFilterParams) {
    peopleController.abort();
    peopleController = new AbortController();
    const response = await axios.get<EngagementPeopleDataSansId>("/api/reports/v3/videos/people-details", {
      params: { itemId, ...filterParams },
      paramsSerializer: overviewFiltering,
      signal: peopleController.signal,
    });
    return response.data.map((item, index) => {
      return {
        ...item,
        id: `${item.UserId}${index}`,
      };
    });
  },

  getExport(mediaId: number, filterParams: BasePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();
    return axios.get("/api/dataexport/v2/media/user-progress", {
      responseType: "blob",
      params: {
        mediaId,
        ...filterParams,
      },
      paramsSerializer: overviewFiltering,
      signal: exportAbortController.signal,
    });
  },

  getExportV3(itemId: number, filterParams: BasePerformanceRequestFilterParams) {
    exportAbortController.abort();
    exportAbortController = new AbortController();
    return axios.get("api/dataexport/v3/videos/user-progress", {
      responseType: "blob",
      params: {
        itemId,
        ...filterParams,
      },
      paramsSerializer: overviewFiltering,
      signal: exportAbortController.signal,
    });
  },
  // End performance section
};

export default service;
