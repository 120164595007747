import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { TagsPayload } from "../../../../../../interfaces/Tags";
import { mapToTagsView } from "../../../../Common/Mappers/TagsPayloadToTagsViewMapper";
import { keyBy } from "lodash";

const getItems = (state: RootState) => emailsStateSelector(state).emailsOverviewReducer.emails;

export const emailsStateSelector = (state: RootState) => state.library.emails;

export const emailsTagsSelector = createSelector(
  [(state: RootState): TagsPayload => state.library.emails.emailDetailsReducer.tags],
  mapToTagsView,
);

export const normalizedEmailsSelector = createSelector([getItems], (items) => {
  return keyBy(items, (item) => item.id);
});
