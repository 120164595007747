import PropTypes from "prop-types";
import { Input } from "semantic-ui-react";

import ValidatedField from "./ValidatedField";

function PasswordField(props) {
  const { value, placeholder, propertyName, handleChange, handleBlur, disabled } = props;
  return (
    <ValidatedField {...props}>
      <Input
        fluid
        name={propertyName}
        type="password"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
      />
    </ValidatedField>
  );
}

PasswordField.defaultProps = {
  placeholder: "",
  disabled: false,
};

PasswordField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  propertyName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PasswordField;
