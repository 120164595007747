import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../../Application/actions/actionsBuilder";
import * as entityStateActionTypes from "../../../../../Application/actions/entityState/entityStateActionTypes";

export const createThreatDefenceLandingPageBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_BEGIN,
);

export const createThreatDefenceLandingPageSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_SUCCESS,
);

export const createThreatDefenceLandingPageFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.CREATE_DRAFT_ENTITY_FAILURE,
);

export const updateThreatDefenceLandingPageBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN,
);

export const updateThreatDefenceLandingPageSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS,
);

export const updateThreatDefenceLandingPageFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.UPDATE_LOCKED_ENTITY_COMMAND_FAILURE,
);

export const threatDefenceLandingPageUpdated = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.LOCKED_ENTITY_UPDATED,
);

export const resetThreatDefenceLandingPageEntityState = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.RESET_ENTITY_STATE,
);

export const publishThreatDefenceLandingPageBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_BEGIN,
);
export const publishThreatDefenceLandingPageSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS,
);
export const publishThreatDefenceLandingPageFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.PUBLISH_LOCKED_ENTITY_FAILURE,
);
export const setLandingPageEntityId = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.SET_ENTITY_ID,
);
export const fetchLockedThreatDefenceLandingPageBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_BEGIN,
);
export const fetchLockedThreatDefenceLandingPageSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_SUCCESS,
);
export const fetchLockedThreatDefenceLandingPageFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.FETCH_LOCKED_ENTITY_FAILURE,
);
export const fetchDiscardThreatDefenceLandingPageBegin = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN,
);
export const fetchDiscardThreatDefenceLandingPageSuccess = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS,
);
export const fetchDiscardThreatDefenceLandingPageFailure = buildRootEntityActionType(
  ReducerNamespaceTypes.Library,
  ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
  entityStateActionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE,
);
