import { Dispatch } from "@reduxjs/toolkit";
import { queue } from "async";
import cn from "classnames";
import isEmpty from "lodash/isEmpty";
import React, { Component } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import { batch, connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon } from "semantic-ui-react";
import { v4 } from "uuid";

import { CardsViewerItem } from "../../../../components/cardsViewer/types";
import { PaywallModalTypes } from "../../../../components/restrictedByAddOn/paywallModal/types";
import { ItemsTypes, RolePermissions, SortingDirection } from "../../../../enums";
import { bindAction } from "../../../../interfaces";
import { HandleOnSelectionChanged, SelectionChangedArgs } from "../../../../interfaces/onSelectionChanged";
import { Filters } from "../../../../utils/queryUtils";
import { RootState } from "../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import * as backgroundTasksActionsRedux from "../../../BackgroundTasks/state/backgroundTasksActions";
import { VideoAssetTask } from "../../../BackgroundTasks/taskPool";
import * as videoEntityStateActionsRedux from "../state/actions/videoEntityStateActionCreators";
import * as videosActionsRedux from "../state/actions/videosActions";
import { getVideoAssetRowNumber } from "../state/actions/videosActions";
import { videosStateSelector } from "../state/selectors";
import { videoSearchSelector } from "../state/selectors/overviewSelectors";
import { resetTags } from "../state/slices/videoBaseSlice";
import { resetAppliedFilter, resetSearch, setAppliedFilter, setSearch } from "../state/slices/videoFiltersSlice";
import { reset } from "../state/slices/videoOverviewSlice";
import { getOverviewFilterOptions } from "../state/thunks/videoFiltersThunk";
import {
  deleteVideosAction,
  fetchOverviewVideos,
  insertVideoAsset,
  updateVideoAsset,
} from "../state/thunks/videoOverviewThunk";
import { DEFAULT_SORT_BY, DEFAULT_SORT_BY_PARAMS, DEFAULT_SORT_DIRECTION } from "../types/constants";
import { VideoColumns, VideoOverview, VideoRowNumber } from "../types/models";
import { getColumnOptions, sortOptions } from "./getColumnOptions";
import {
  DragAndDropProps,
  VideoDragObject,
  VideoModalHandler,
  VideosOverviewState,
  VideosProcessingQueue,
  VideoUploader,
} from "./types";
import { VideoOverviewCard } from "./VideoOvervievCard/VideoOverviewCard";
import { CreateVideoButton } from "./VideoOverviewHeader/CreateVideoButton";
import { VideoOverviewHeader } from "./VideoOverviewHeader/VideoOverviewHeader";

import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash";
import { withRouter, WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import DragAndDropArea from "../../../../components/dragAndDrop/DragAndDropArea";
import VideosFilterForm from "../../../../components/filterForms/VideosFilterForm/VideosFilterForm";
import AssetModals from "../../../../components/modal/assetModals/AssetModals";
import RestrictedByAddOn from "../../../../components/restrictedByAddOn/RestrictedByAddOn";
import SearchInput from "../../../../components/searchInput/SearchInput";
import environmentConfig from "../../../../configuration/environmentConfig";
import SortOptions from "../../../../enums/SortOptions";
import TaskActions from "../../../../enums/TaskActions";
import TaskStatuses from "../../../../enums/taskStatuses";
import ViewType from "../../../../enums/ViewType";
import taskPool, { ConfigParams } from "../../../../utils/taskPool";
import validationRules from "../../../../utils/validationRules";
import videoListUtils from "../../../../utils/videoListUtils";
import GenericItemsView from "../../../../views/ItemsView/GenericItemsView";
import VideosNoResults from "../../../../views/library/videos/VideosNoResults";
import Restricted from "../../../Application/Restricted";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import backgroundTasksEventsEmitter from "../../../BackgroundTasks/events/backgroundTasksEventsEmitter";
import VideoUploaderService from "../services/videosUploaderService";
import dragAndDropStyles from "../videosDragAndDropArea.module.scss";

import { FeatureFlags } from "featureFlags";
import { uploadVideoToAws } from "../state/thunks/videoUploadThunk";
import "./videos.scss";
import { LDProps } from "../../../LDProps";
import { uploadedVideos } from "../../../../utils/validationSchemas/filesValidationSchema";

export type VideosOverviewProps = PropsFromRedux & WithRouterProps & LDProps;

export class Videos extends Component<VideosOverviewProps, VideosOverviewState> {
  private readonly videoUploader: VideoUploader;
  private readonly isAwsEncodingEnabled: boolean;

  private purchasedSelectedCount = 0;
  private undeletableSelectedCount = 0;
  private draftSelectedCount = 0;

  private modalHandlers: VideoModalHandler | undefined;
  private videosToInsert: { [key: string]: any; };
  private reloadListItems: ((enableSorting: boolean) => void) | undefined;
  private timerId: NodeJS.Timeout | null;

  constructor(props: VideosOverviewProps) {
    super(props);
    this.state = {
      isFilterVisible: false,
      selectedVideoIds: [],
      selectedViewType: ViewType.GRID,
      gridViewSortByParams: DEFAULT_SORT_BY_PARAMS,
      search: "",
      purchasedSelected: false,
      draftSelected: false,
      undeletableSelected: false,
      getVideosOptions: {
        top: 10,
        skip: 30,
        sortingColumnName: DEFAULT_SORT_BY,
        sortingDirection: DEFAULT_SORT_DIRECTION,
      },
    };
    this.isAwsEncodingEnabled = !!this.props.flags?.[FeatureFlags.AwsEncoding];
    this.videoUploader = VideoUploaderService(
      () => this.props,
      null,
      null,
      this.loadCreatedVideoAsset,
      null,
      this.isAwsEncodingEnabled,
    );
    this.videosToInsert = {};
    this.timerId = null;
  }

  dateAddedColumnName = "Added";
  delayInterval = 1000;
  asyncVideosProcessingQueue = queue((asset: VideosProcessingQueue, onSuccess: () => void) => {
    this.props.insertVideoAsset(asset, onSuccess);
  }, 1);

  componentDidMount() {
    RtnEventsEmitter.subscribe(
      [rtnEvents.VideoAssetPublishSuccess, rtnEvents.VideoAssetsDiscardSuccess],
      this.updateVideoAssetCard,
    );
    RtnEventsEmitter.subscribe(rtnEvents.LockVideoAssetSuccess, this.goToEditEntity);

    taskPool.init({
      concurrentItemsCount: environmentConfig.concurrentUploadingFilesCount,
      taskAction: this.videoUploader.uploadFileHandler,
      onTaskQueued: (
        task: { title: string; label: string; errorLabel: string; canCancel: boolean; id: string; },
        file: { name: string; },
      ) => {
        task.title = file.name;
        task.label = "Uploading...";
        task.errorLabel = "File upload failed!";
        task.canCancel = true;
        this.props.backgroundTasksActions.addTask(task);
        backgroundTasksEventsEmitter.subscribeOnce(TaskActions.cancel, task.id, taskPool.cancelTask.bind(taskPool));
      },
      onTaskCanceled: this.props.backgroundTasksActions.deleteTask,
    } as unknown as ConfigParams);
  }

  componentWillUnmount() {
    this.props.reset();

    RtnEventsEmitter.unsubscribe(
      [rtnEvents.VideoAssetPublishSuccess, rtnEvents.VideoAssetsDiscardSuccess],
      this.updateVideoAssetCard,
    );
    RtnEventsEmitter.unsubscribe(rtnEvents.LockVideoAssetSuccess, this.goToEditEntity);
  }

  isValueInRange = (startIndex: number, endIndex: number, index: number) => index >= startIndex && index <= endIndex;

  loadCreatedVideoAsset = async (videoAssetId: number) => {
    const { videos, appliedFilter, backgroundTasks } = this.props;
    let startIndex = 0;
    let endIndex;
    let skipItems;
    let take;
    let order;
    const isGridView = this.isGridView();

    if (isGridView) {
      skipItems = startIndex;
      endIndex = Videos.length;
      take = parseInt(process.env.REACT_APP_LOAD_ITEMS_COUNT as string);
      order = this.state.gridViewSortByParams;
    } else {
      const { top, skip, sortingColumnName, sortingDirection } = this.state.getVideosOptions;
      skipItems = skip;
      endIndex = take = top;
      order = videoListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    }

    const data = await this.props.getVideoAssetRowNumber(videoAssetId, take, skipItems, order, appliedFilter);

    if (!data) {
      return;
    }

    const { position, count } = data as unknown as VideoRowNumber;
    if (!Number.isInteger(position) || position < 0 || !this.isValueInRange(startIndex, endIndex, position)) {
      return;
    }

    const tasks = Object.values(backgroundTasks.tasks);
    const amountOfTasksInProgress = tasks.filter((task) =>
      TaskStatuses.isInProgress((task as VideoAssetTask).status),
    ).length;

    const video = {
      videoAssetId,
      position,
      count,
      needToRemoveItem: !isGridView && videos.length === this.state.getVideosOptions.top,
    };

    if (amountOfTasksInProgress === 1) {
      this.asyncVideosProcessingQueue.push(video);
    } else {
      this.videosToInsert[position] = video;
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(this.handleVideoAssetInsertion, this.delayInterval);
    }
  };

  handleVideoAssetInsertion = () => {
    this.timerId = null;
    const receivedPositions = Object.keys(this.videosToInsert);
    const sortedValue = sortBy(receivedPositions, (obj) => Number(obj));
    sortedValue.forEach((receivedPosition) => {
      const asset = this.videosToInsert[receivedPosition];
      delete this.videosToInsert[receivedPosition];
      this.asyncVideosProcessingQueue.push(asset);
    });
  };

  updateVideoAssetCard = (payload: { id: number; }) => {
    this.props.videos.length && this.props.updateVideoAsset(payload.id);
  };

  getButtonHandlers = () => ({
    handleEditClick: this.onEdit,
    handlePublishClick: this.handlePublishClick,
    handleDeleteClick: this.handleDeleteClick,
    onNavigate: this.handleTitleClick,
  });

  onEdit = (id: number, isDraft: boolean) => () => {
    if (!isDraft) this.props.videoEntityStateActions.getEntityLock(id);
    this.goToPage(id, "configuration");
  };

  handlePublishClick =
    (id: number, isDraft: boolean, flowsCount: number, assignmentsCount: number, hasBeenPublished: boolean) => () => {
      isDraft && this.modalHandlers?.publish(id, flowsCount, assignmentsCount, hasBeenPublished);
    };

  handleDeleteClick = (ids: number[], flowsCount?: number, packsCount?: number) => () => {
    this.modalHandlers?.delete(ids, flowsCount, packsCount);
  };

  handleTitleClick = (id: number, isLoading: boolean) => {
    !isLoading && this.onTitleClick(id);
  };

  loadVideos = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
  ) => {
    this.setState({ getVideosOptions: { top, skip, sortingColumnName, sortingDirection } });

    this.isGridView()
      ? this.loadVideoCards(this.props.videos.length, top)
      : this.loadVideoList(skip, top, sortingColumnName, sortingDirection);
  };

  isGridView = () => {
    return this.state.selectedViewType === ViewType.GRID;
  };

  loadVideoList = (skip?: number, top?: number, sortingColumnName?: string, sortingDirection?: SortingDirection) => {
    this.props.fetchOverviewVideos({
      skip: skip,
      top: top,
      sortBy: videoListUtils.getSortBy(sortingColumnName as VideoColumns),
      sortDirection: sortingDirection,
    });
  };

  loadVideoCards = (skip?: number, top?: number, sortByParams?: string) => {
    const [sortBy, sortDirection] = (sortByParams || this.state.gridViewSortByParams)?.split(" ") || [];
    this.props.fetchOverviewVideos({ skip, top, sortBy, sortDirection, append: true });
  };

  onSortChange = async (_: React.SyntheticEvent<HTMLElement>, data: { value?: SortOptions; }) => {
    this.props.resetOverviewVideos();
    this.clearSelection();
    this.setState({ gridViewSortByParams: data.value || DEFAULT_SORT_BY_PARAMS }, this.loadVideoCards);
  };

  getFilterOptions = () => {
    const { filterOptions } = this.props;
    if (isEmpty(filterOptions)) {
      this.props.getFilterOptions();
    }
  };

  // @ts-ignore
  renderFilterForm = () => <VideosFilterForm />;

  applyFilter = (filter: Filters) => {
    this.clearSelection();
    this.props.applyFilter(filter);

    if (this.isGridView()) {
      this.props.resetOverviewVideos();
      this.loadVideoCards();
    }
  };

  resetFilter = () => {
    this.clearSelection();
    this.props.resetFilter();
    if (this.isGridView()) {
      this.props.resetOverviewVideos();
      this.loadVideoCards();
    }
  };

  clearSelection = (callback?: () => void) => {
    this.purchasedSelectedCount = this.undeletableSelectedCount = this.draftSelectedCount = 0;

    this.setState(
      {
        selectedVideoIds: [],
        purchasedSelected: false,
        undeletableSelected: false,
        draftSelected: false,
      },
      callback,
    );
  };

  onSelectedItemsChanged = (ids: number[]) => {
    this.setState({ selectedVideoIds: ids });
  };

  onSelectionChanged = (args: SelectionChangedArgs<VideoOverview>) => {
    const onAdded = (video: VideoOverview) => {
      video.isPurchased && ++this.purchasedSelectedCount;
      !video.canBeDeleted && ++this.undeletableSelectedCount;
      video.isDraft && ++this.draftSelectedCount;
    };
    const onRemoved = (video: VideoOverview) => {
      video.isPurchased && --this.purchasedSelectedCount;
      !video.canBeDeleted && --this.undeletableSelectedCount;
      video.isDraft && --this.draftSelectedCount;
    };

    HandleOnSelectionChanged(args, onAdded, onRemoved, this.clearSelection);

    this.setState({
      purchasedSelected: this.purchasedSelectedCount > 0,
      undeletableSelected: this.undeletableSelectedCount > 0,
      draftSelected: this.draftSelectedCount > 0,
    });
  };

  goToEditEntity = (payload: { id: number; }) => this.goToPage(payload.id, "configuration");

  onTitleClick = (id: number) => {
    this.goToPage(id, "performance");
  };

  onViewTypeChange = (viewType: ViewType) => {
    this.props.resetOverviewVideos();
    this.setState({ selectedViewType: viewType });
    this.clearSelection();
    if (viewType === ViewType.GRID) {
      this.loadVideoCards();
    }
  };

  goToPage = (id: number, pageName: string) => {
    const { navigate } = this.props;
    navigate(`${id}/${pageName}`);
  };

  getLoadingStatus = () => {
    const { areVideosLoading, changingEntityState } = this.props;
    return changingEntityState || areVideosLoading;
  };

  isAnyVideoExists = () => {
    const { videos, appliedFilter } = this.props;
    return videos.length > 0 || !isEmpty(appliedFilter);
  };

  renderDragAndDropSection = () => {
    const isAnyVideoExists = this.isAnyVideoExists();
    const isGridView = this.state.selectedViewType === ViewType.GRID;
    return (
      <div className={dragAndDropStyles["drop-section"]}>
        <div
          className={cn(dragAndDropStyles["overlay"], {
            [dragAndDropStyles["list-view"]]: !isGridView,
            [dragAndDropStyles["grid-view"]]: isGridView,
            [dragAndDropStyles["overlay-no-assets-grid-view"]]: isGridView && !isAnyVideoExists,
          })}
        />
        {isAnyVideoExists && (
          <div className={dragAndDropStyles["drop-box"]}>
            <div>
              <Icon className={`fa-cloud-upload fa-lg ${dragAndDropStyles["icon"]}`} />
              <span>Drop Files Here to Upload</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  getDragAndDropProps = () => ({
    acceptType: NativeTypes.FILE,
    canDrop: (item: VideoDragObject) => {
      const items = item.items;
      return !items || items.length === 0 || validationRules.anyMp4FileType(items);
    },
    onDrop: (item: VideoDragObject) => {
      Array.from(item.files).forEach(async (file) => {
        let isValid = await uploadedVideos.isValid([file]);
        if (isValid) {
          taskPool.push(v4(), file);
        }
      });
    },
    renderDragAndDropSection: this.renderDragAndDropSection,
  });

  renderDragAndDrop =
    (props: DragAndDropProps) => (action: (item: VideoDragObject) => void, hasAnyPermission: boolean) =>
      hasAnyPermission ? (
        <DndProvider backend={HTML5Backend}>
          <DragAndDropArea {...props} onDrop={action} />
        </DndProvider>
      ) : undefined;

  renderRestrictedDragAndDrop = () => {
    const props: DragAndDropProps = this.getDragAndDropProps();

    return (
      <RestrictedByAddOn
        permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate]}
        addOnPermissions={[RolePermissions.AssetsCreate]}
        modalType={PaywallModalTypes.UpgradeToCreateContent}
        action={props.onDrop}
        renderContent={this.renderDragAndDrop(props)}
      />
    );
  };

  onDeleted = () => {
    this.refetchItemsFromStart();
    this.setState({ selectedVideoIds: [] });
  };

  createReloadListMethod = (reloadListItems: (enableSorting: boolean) => void) => {
    this.reloadListItems = reloadListItems;
  };

  onSearchChange = (search: string) => {
    this.props.setSearch(search);
    this.setState({ gridViewSortByParams: search ? "" : DEFAULT_SORT_BY_PARAMS }, () =>
      this.refetchItemsFromStart(search),
    );
  };

  refetchItemsFromStart = (search?: string) => {
    if (this.isGridView()) {
      this.props.resetOverviewVideos();
      this.loadVideoCards();
    } else {
      this.reloadListItems?.(isEmpty(search));
    }
  };

  render() {
    const {
      videos,
      totalVideosCount,
      areAllVideosLoaded,
      areFilterOptionsLoading,
      appliedFilter,
      filterOptions,
      videoEntityStateActions,
      backgroundTasksActions,
      deleteVideosAction,
    } = this.props;

    const { selectedVideoIds, purchasedSelected, undeletableSelected, draftSelected } = this.state;
    return (
      <section className="nested-content assets">
        <VideoOverviewHeader
          purchasedSelected={purchasedSelected}
          undeletableSelected={undeletableSelected}
          draftSelected={draftSelected}
          selectedIds={selectedVideoIds}
          isAnyVideoExists={this.isAnyVideoExists()}
          videoDeletionHandler={this.handleDeleteClick}
          clearSelection={this.clearSelection}
        />
        <AssetModals
          acceptHandlers={(handlers: VideoModalHandler) => (this.modalHandlers = handlers)}
          onDeleted={this.onDeleted}
          videoEntityStateActions={videoEntityStateActions}
          backgroundTasksActions={backgroundTasksActions}
          deleteVideosAction={deleteVideosAction}
        />
        <Restricted
          permissions={[RolePermissions.AssetsCreate, RolePermissions.AssetsManage]}
          renderContent={(hasAnyPermission) => (
            <GenericItemsView
              className="alignment-padding"
              blur
              items={videos}
              filterOptionsLoading={areFilterOptionsLoading}
              filterOptions={filterOptions}
              appliedFilter={appliedFilter}
              viewType={this.state.selectedViewType}
              itemsType={ItemsTypes.Video}
              dragAndDrop={this.renderRestrictedDragAndDrop()}
              sortOptions={sortOptions}
              onSortChange={this.onSortChange}
              fetchData={this.loadVideos}
              getFilterOptions={this.getFilterOptions}
              dataCount={totalVideosCount}
              isLoading={this.getLoadingStatus()}
              getFilterForm={this.renderFilterForm}
              resetFilter={this.resetFilter}
              applyFilter={this.applyFilter}
              onSelectedGridItemsChanged={this.onSelectedItemsChanged}
              onSelectedItemChanged={this.onSelectedItemsChanged}
              renderCard={(props: CardsViewerItem<VideoOverview>) => {
                return <VideoOverviewCard {...props} {...this.getButtonHandlers()} />;
              }}
              selectedIds={this.state.selectedVideoIds}
              isAllDataLoaded={areAllVideosLoaded}
              onViewTypeChange={this.onViewTypeChange}
              orderBy={this.state.gridViewSortByParams}
              sortingDirection={SortingDirection.Descending}
              defaultSortingColumnName={this.props.search ? "" : this.dateAddedColumnName}
              onLoad={this.isGridView() ? this.loadVideos : undefined}
              isSelectDisabled={() => !hasAnyPermission}
              setReloadListItems={this.createReloadListMethod}
              columnOptions={getColumnOptions({
                buttonHandlers: this.getButtonHandlers(),
                readonly: !hasAnyPermission,
              })}
              renderSearch={() => (
                <SearchInput
                  placeholder="Search for Videos..."
                  onChange={this.onSearchChange}
                  defaultValue={this.props.search}
                />
              )}
              noResultsContent={
                <VideosNoResults
                  filtered={!isEmpty(appliedFilter) || !isEmpty(this.props.search)}
                  createAssetButton={<CreateVideoButton />}
                />
              }
              permissions={[RolePermissions.AssetsManage]}
              onSelectionChanged={this.onSelectionChanged}
            />
          )}
        />
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = videosStateSelector(state).base;
  const overview = videosStateSelector(state).overview;

  return {
    videos: overview.videoOverview.items,
    search: videoSearchSelector(state),
    totalVideosCount: overview.videoOverview.itemsCount,
    areVideosLoading: overview.videoOverview.isLoading,
    areAllVideosLoaded: overview.videoOverview.areAllLoaded,
    entityId: base.videoEntityStateReducer.entityId,
    areFilterOptionsLoading: overview.filters.isLoading,
    filterOptions: overview.filters.filterOptions,
    appliedFilter: overview.filters.appliedFilter,
    backgroundTasks: state.backgroundTasks,
    userPermissions: state.userProfile.permissions,
    changingEntityState: base.videoEntityStateReducer.changingEntityState,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getFilterOptions: bindAction(getOverviewFilterOptions, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    resetFilter: bindAction(resetAppliedFilter, dispatch),
    getVideoAssetRowNumber: bindAction(getVideoAssetRowNumber, dispatch),
    videosActions: bindActionCreators(videosActionsRedux, dispatch),
    videoEntityStateActions: bindActionCreators(videoEntityStateActionsRedux, dispatch),
    backgroundTasksActions: bindActionCreators(backgroundTasksActionsRedux, dispatch),
    resetOverviewVideos: bindAction(reset, dispatch),
    fetchOverviewVideos: bindAction(fetchOverviewVideos, dispatch),
    updateVideoAsset: bindAction(updateVideoAsset, dispatch),
    insertVideoAsset: bindAction(insertVideoAsset, dispatch),
    setSearch: bindAction(setSearch, dispatch),
    deleteVideosAction: bindAction(deleteVideosAction, dispatch),
    uploadAwsVideo: bindActionCreators(uploadVideoToAws, dispatch),

    reset: () => {
      batch(() => {
        dispatch(reset());
        dispatch(resetSearch());
        dispatch(resetTags());
        dispatch(resetAppliedFilter());
      });
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withLDConsumer()(Videos)));
