import Awareness from "../../../../../enums/groups/awareness";
import Visibility from "../../../../../enums/groups/visibility";
import Access from "../../../../../enums/groups/access";
import Enrollment from "../../../../../enums/groups/enrollment";

const accessRestrictedFilterOption = {
  text: "Restricted",
  value: Access.Restricted,
};

const filterOptions = {
  privacy: [
    { text: "All", value: Awareness.None },
    { text: "Aware", value: Awareness.Aware },
    { text: "Unaware", value: Awareness.Unaware },
  ],
  visibility: [
    { text: "All", value: Visibility.None },
    { text: "Visible to all", value: Visibility.VisibleToAll },
    { text: "Members only", value: Visibility.MembersOnly },
  ],
  access: [{ text: "All", value: Access.None }, { text: "Open", value: Access.Open }, accessRestrictedFilterOption],
  enrollment: [
    { text: "All", value: Enrollment.None },
    { text: "Automatic", value: Enrollment.Automatic },
    { text: "Manual", value: Enrollment.Manual },
  ],
  accessRestrictedFilterOption,
};

export const fetchGroupsFilterOptions = (
  fetchFilterOptionsBeginActionType: string,
  fetchFilterOptionsSuccessActionType: string,
  fetchFilterOptionsFailedActionType: string,
) => {
  const fetchFilterOptionsBegin = () => ({
    type: fetchFilterOptionsBeginActionType,
  });

  const fetchFilterOptionsSuccess = (options: any) => ({
    type: fetchFilterOptionsSuccessActionType,
    payload: options,
  });

  const fetchFilterOptionsFailed = (error: any) => ({
    type: fetchFilterOptionsFailedActionType,
    payload: { error },
  });

  return async (dispatch: any) => {
    dispatch(fetchFilterOptionsBegin());

    try {
      dispatch(fetchFilterOptionsSuccess(filterOptions));
    } catch (error) {
      dispatch(fetchFilterOptionsFailed(error));
    }
  };
};
