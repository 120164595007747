import React from "react";
import { sum } from "lodash";

import { DeleteLinkButton, FlowCard, ContentItemMenu } from "../../../../../components";
import { EditLinkButton, DuplicateLinkButton } from "../../../../../components/buttons/linkButtons";
import { Strings } from "../../../../../enums";
import { FlowOverview } from "../../../../../features/Library/Flows/types/models";
import { FlowCardBaseProps } from "../../../../../components/cards/FlowCard/types";

import DeletionRestrictedTooltip from "../../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { ToggleVisibilityButton } from "components/buttons/linkButtons/toggleVisibilityButton/ToggleVisibilityButton";

export interface OverviewCardProps extends FlowCardBaseProps<FlowOverview> {
  onCopy: (id: number[]) => () => void;
  onDelete: (id: number[], packsCount?: number) => () => void;
  onEdit: (id: number, isDraft?: boolean) => () => void;
  onToggleVisibility: (id: number[], hidden: boolean) => () => void;
  readonly?: boolean;
}

export const OverviewCard: React.FC<OverviewCardProps> = (props: OverviewCardProps): React.ReactElement => {
  const { item, readonly, selected, onEdit, onCopy, onDelete, onSelect, disabled, onToggleVisibility } = props;

  const { id, isEditable, canBeDeleted, published, hidden } = item;

  const entityType = "flow";
  const { packsCount = 0, totalAssignments = 0 } = item.dependencies || {};
  const dependencies = { packs: packsCount, "users or groups": totalAssignments };
  const flowMigrationEnabled = useFeatureFlag(FeatureFlags.FlowVersioningMigration);
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const visibility = useFeatureFlag(FeatureFlags.ContentVisibility);

  const renderPopupMenu = () => (
    <ContentItemMenu
      circle
      outlinedEllipsis
      isChecked={selected}
      item={{
        isPurchased: !isEditable,
        canBeDeleted: canBeDeleted,
      }}
      readonly={readonly}
      entityType={entityType}
      dependencies={dependencies}
      purchasedTooltipContent={Strings.tooltipMessages.purchased.oneDeleteDisabled(entityType)}
    >
      {visibility && <ToggleVisibilityButton onClick={onToggleVisibility([id], hidden)} hidden={hidden} />}
      {isEditable && <EditLinkButton onClick={onEdit(id, published)} />}
      <DuplicateLinkButton onClick={onCopy([id])} isDisabled={flowMigrationEnabled} />
      {isEditable && (
        <DeleteLinkButton
          isDisabled={deleteContentEnabled ? readonly : !canBeDeleted}
          after={
            !deleteContentEnabled &&
            !!sum(Object.values(dependencies)) && (
              <DeletionRestrictedTooltip dependencies={dependencies} entityType={entityType} />
            )
          }
          onClick={onDelete([id], packsCount)}
        />
      )}
    </ContentItemMenu>
  );

  return (
    <FlowCard
      item={item}
      disabled={disabled}
      url={props.item.id.toString()}
      onSelect={onSelect}
      popupMenu={renderPopupMenu()}
      selected={selected}
    />
  );
};
