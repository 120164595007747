import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "semantic-ui-react";
import cn from "classnames";
import MenuItem from "./MenuItem";
import TopMenuAccordionItem from "./TopMenuAccordionItem";
import TopMenuLinkItem from "./TopMenuLinkItem";
import propUtils from "../../../utils/propValidationUtils";
import RestrictedMenuItem from "./RestrictedMenuItem";
import { SubMenuItem } from "./SubMenuItem";
import { RestrictedSubMenuAccordionItem } from "./RestrictedSubMenuAccordionItem";

import "./navigationMenuItem.scss";

const getSubMenuItem = (child, basePath) => {
  const { children, path, className, expanded, container } = child.props;

  const subPath = container ? `${basePath}` : `${basePath}/${path}`; //ignore path if the element in only container
  const { nestedItems } = getNestedMenuItems(children, subPath);
  const classNames = cn("nested-menu-item", { "parent-menu-item": nestedItems }, className);
  const commonProps = {
    classNames,
    expanded,
    label: child.props.label,
    nestedItems,
    path: subPath,
    onClick: child.props.onClick,
  };

  if (child.type === RestrictedSubMenuAccordionItem) {
    return React.cloneElement(child, commonProps);
  }

  return <SubMenuItem key={subPath} {...commonProps} />;
};

const getNestedMenuItem = (child, path, index) => {
  if (child.props.children) {
    return { element: getSubMenuItem(child, path), hasSubmenu: true };
  }

  const childPath = child.props.isTopLevelLink ? child.props.path : `/${path}/${child.props.path}`;

  const commonProps = {
    key: childPath || index,
    path: childPath,
    className: cn("menu-item", "nested-menu-item", child.props.className),
    onClick: child.props.onClick,
    hideNavigation: child.props.hideNavigation,
  };

  if (child.type === RestrictedMenuItem) {
    return { element: React.cloneElement(child, commonProps) };
  }

  return { element: <MenuItem label={child.props.label} {...commonProps} /> };
};

const getNestedMenuItems = (children, path) => {
  let hasSubmenu = false;
  let nestedItems = null;
  const getNestedMenuItemsAndSetHasSubmenu = (child, index) => {
    const menuItemResult = getNestedMenuItem(child, path, index);
    hasSubmenu = hasSubmenu || menuItemResult.hasSubmenu;
    return menuItemResult.element;
  };

  if (children) {
    if (Array.isArray(children)) {
      nestedItems = children
        .filter((x) => x)
        .map((child, index) => {
          return getNestedMenuItemsAndSetHasSubmenu(child, index);
        });
    } else {
      nestedItems = getNestedMenuItemsAndSetHasSubmenu(children, 0);
    }
  }

  return { hasSubmenu, nestedItems };
};

const createParentMenuTitleElement = (label) => {
  return <MenuItem className="menu-item nested-menu-item parent-menu-title" label={label} path="_" />;
};

export function NavigationMenuItem(props) {
  const {
    label,
    path,
    activePath,
    icon,
    className,
    contentClassname,
    children,
    onClick,
    blur,
    expanded,
    notificationsCount,
    external,
    toggleNavigation,
  } = props;
  const { nestedItems, hasSubmenu } = getNestedMenuItems(children, path);
  const classNames = cn("menu-item", { "parent-menu-item": nestedItems }, className);

  if (nestedItems) {
    const contentClassNames = cn(
      { "parent-menu-content": toggleNavigation || expanded },
      {
        "sub-menu-container": hasSubmenu,
      },
      contentClassname,
    );
    const parentMenuTitleElement = createParentMenuTitleElement(label);

    return (
      <>
        <TopMenuAccordionItem
          label={label}
          className={classNames}
          iconName={icon}
          path={path}
          expanded={expanded}
          onClick={onClick}
          notificationsCount={notificationsCount}
        />
        <Accordion.Content className={contentClassNames} active={expanded}>
          {parentMenuTitleElement}
          {nestedItems}
        </Accordion.Content>
      </>
    );
  }

  return (
    <TopMenuLinkItem
      blur={blur}
      label={label}
      className={classNames}
      iconName={icon}
      path={path}
      activePath={activePath}
      notificationsCount={notificationsCount}
      onClick={onClick}
      external={external}
    />
  );
}

NavigationMenuItem.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  activePath: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  contentClassname: PropTypes.string,
  children: propUtils.oneOfChildrenType([NavigationMenuItem, RestrictedMenuItem, RestrictedSubMenuAccordionItem]),
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
  notificationsCount: PropTypes.number,
  isTopLevelLink: PropTypes.bool,
  container: PropTypes.bool,
  external: PropTypes.bool,
  hideNavigation: PropTypes.func,
  toggleNavigation: PropTypes.bool,
  blur: PropTypes.bool,
};

export default NavigationMenuItem;
