import React, { FC } from "react";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { FilterFormPropsBase } from "../types/props";
import { getPdfsFilters } from "./pdfsFilters";
import { PdfFiltersEnum } from "../../../features/Library/Pdfs/types/models";

export interface PdfsFilterFormForPacksProps extends FilterFormPropsBase<PdfFiltersEnum> {}

const PdfsFilterFormForPacks: FC<PdfsFilterFormForPacksProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getPdfsFilters(false)}
  />
);

export default PdfsFilterFormForPacks;
