import { FC, useState } from "react";
import { Button } from "components/buttons/button/Button";
import { batch, connect, ConnectedProps } from "react-redux";
import { noop, partial } from "lodash";

import { ContentType, PeopleType } from "../../../PeopleAssignments/types";
import { RolePermissions } from "../../../../../enums";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { EditPriorityLinkButton } from "../../../../../components/buttons/linkButtons";
import {
  removePeoplePrioritiesAction,
  startFlowAction,
  startGroupFlowAction,
} from "../../state/actions/flowPeopleAssignmentsActions";
import { resetSelectedItems as resetSelectedUserIds } from "../../state/slices/flowUsersSlice";
import { resetSelectedItems as resetSelectedGroupIds } from "../../state/slices/flowGroupsSlice";
import { notifyChangePriorityObserver } from "../../../PeopleAssignments/utils/peopleAssignmentUtils";
import { OnChangePriorityObserver, bindAction, IObservable } from "../../../../../interfaces";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

import Restricted from "../../../../Application/Restricted";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";
import HeaderValidationInfo from "../../common/HeaderValidationInfo/HeaderValidationInfo";
import {
  canAddPeopleSelector,
  disableRemovePrioritySelector,
  flowInformationSelector,
  flowsStateSelector,
} from "../../state/selectors";
import StartFlowButton from "../Common/StartFlowButton/StartFlowButton";
import { Tooltip } from "components/common/tooltip";
import RemovePriorityButton from "components/buttons/linkButtons/RemovePriorityButton/RemovePriorityButton";
import AddAssetsToPackModal from "../../../../../components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { AddPacksButton } from "features/Licensing/Packs/AssociatedPacks/AddPacksButton";
import { FlowEditDetailsSteps } from "../config";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

interface DetailsHeaderContentProps {
  onAddPeopleObserver: IObservable<() => void>;
  onChangePriorityObserver: OnChangePriorityObserver;
  onRemovePeopleObserver: IObservable<
    (onRemoveConfirm: () => void, people: { [key in PeopleType]?: number[] }) => void
  >;
  onFixErrorsClick: () => void;
  flowId: number;
  activeStep: FlowEditDetailsSteps;
  canAddPacks: boolean;
}

export type DetailsHeaderContentPropsBase = DetailsHeaderContentProps & PropsFromRedux & WithRouterProps;

export const DetailsHeaderContent: FC<DetailsHeaderContentPropsBase> = ({
  flowId,
  selectedUserIds,
  selectedGroupIds,
  onChangePriorityObserver,
  onRemovePeopleObserver,
  onAddPeopleObserver,
  onBatchChangePriorityConfirm,
  onBatchRemovePeople,
  isPeopleSelected,
  activeStep,
  canAddPacks,
  onFixErrorsClick,
  canAutoStart,
  startFlow,
  startGroupFlow,
  selectedUsers,
  selectedGroups,
  disableRemovePriotity,
  canAddPeople,
}) => {
  const [packsModalShown, setPacksModalShown] = useState<boolean>(false);
  const flexiblePriorityDueDate = useFeatureFlag(FeatureFlags.FlexiblePriorityDueDate);

  const onEditPriority = () => {
    const peopleType = selectedUsers.length ? PeopleType.User : PeopleType.Group;
    notifyChangePriorityObserver(
      { [PeopleType.User]: selectedUsers, [PeopleType.Group]: selectedGroups },
      peopleType,
      onChangePriorityObserver,
      flexiblePriorityDueDate,
      undefined,
      onBatchChangePriorityConfirm,
    );
  };

  const onRemoveFlowPeople = () => {
    const people = { [PeopleType.User]: selectedUserIds, [PeopleType.Group]: selectedGroupIds };
    onRemovePeopleObserver.notify(() => onBatchRemovePeople(flowId, people), people);
  };

  const onStartFlow = () => {
    startFlow(flowId, selectedUserIds);
  };

  const onStartGroupFlow = () => {
    startGroupFlow(flowId, selectedGroupIds);
  };

  const renderCustomHeader = () => {
    return <HeaderValidationInfo onErrorClick={onFixErrorsClick} />;
  };

  const renderPeopleButtons = (hasAnyPermission: boolean) => {
    const peopleType = selectedUsers.length ? PeopleType.User : PeopleType.Group;

    return (
      <>
        {!isPeopleSelected && (
          <RestrictedByTooltip hasPermission={hasAnyPermission}>
            <Tooltip
              target={
                <Button
                  primary
                  content={"Add People"}
                  className="add-people-button create-button"
                  disabled={!hasAnyPermission || !canAddPeople}
                  onClick={onAddPeopleObserver.notify}
                />
              }
              content={"To add people to this flow, it must first be added to a pack."}
              position="left center"
              styles={{ width: "16rem" }}
              hideOnScroll
              showAlways={hasAnyPermission && !canAddPeople}
            />
          </RestrictedByTooltip>
        )}
        {isPeopleSelected && (
          <>
            <EditPriorityLinkButton onClick={onEditPriority} isDisabled={!hasAnyPermission} />
            <RemovePriorityButton
              onClick={onRemoveFlowPeople}
              isDisabled={!hasAnyPermission || disableRemovePriotity}
              peopleType={peopleType}
              context="content"
              entityType={ContentType.Flow}
              multiple
              showTooltip={disableRemovePriotity}
            />
          </>
        )}
        {isPeopleSelected && (
          <StartFlowButton
            onClick={peopleType === PeopleType.User ? onStartFlow : onStartGroupFlow}
            isDisabled={!hasAnyPermission || !canAutoStart}
          />
        )}
      </>
    );
  };

  const renderAddPackButton = (hasAnyPermission: boolean) => (
    <AddPacksButton
      disabled={!canAddPacks}
      hasPermission={hasAnyPermission}
      onClick={partial(setPacksModalShown, true)}
    />
  );

  return (
    <>
      {renderCustomHeader()}
      {activeStep === FlowEditDetailsSteps.People && (
        <Restricted
          permissions={[RolePermissions.FlowsManage]}
          renderContent={(hasAnyPermission) => renderPeopleButtons(hasAnyPermission)}
        />
      )}
      {activeStep === FlowEditDetailsSteps.AssociatedPacks && (
        <Restricted
          permissions={[RolePermissions.AssetsManage, RolePermissions.PacksManage, RolePermissions.FlowsCreate]}
          renderContent={(hasAnyPermission) => renderAddPackButton(hasAnyPermission)}
        />
      )}
      {packsModalShown && (
        <AddAssetsToPackModal
          showModal={packsModalShown}
          onClose={partial(setPacksModalShown, false)}
          onComplete={noop}
          selectedItemIds={[flowId]}
          contentType="Flow"
        />
      )}
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const flows = flowsStateSelector(state);
  return {
    isPeopleSelected: !!(flows.edit.users.selectedIds.length || flows.edit.groups.selectedIds.length),
    selectedUserIds: flows.edit.users.selectedIds,
    selectedGroupIds: flows.edit.groups.selectedIds,
    selectedUsers: flows.edit.users.selectedItems,
    selectedGroups: flows.edit.groups.selectedItems,
    disableRemovePriotity: disableRemovePrioritySelector(state),
    canAutoStart: flowInformationSelector(state).info.canAutoStart,
    canAddPeople: canAddPeopleSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onBatchChangePriorityConfirm: () =>
    batch(() => {
      dispatch(resetSelectedUserIds());
      dispatch(resetSelectedGroupIds());
    }),
  onBatchRemovePeople: (flowId: number, people: { [key in PeopleType]?: number[] }) =>
    batch(() => {
      dispatch(removePeoplePrioritiesAction([flowId], people));
      dispatch(resetSelectedUserIds());
      dispatch(resetSelectedGroupIds());
    }),
  startFlow: bindAction(startFlowAction, dispatch),
  startGroupFlow: bindAction(startGroupFlowAction, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(DetailsHeaderContent));
