import { batch } from "react-redux";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { groupBy, pick } from "lodash";

import PromiseStatuses from "../../../../../enums/promiseStatuses";
import DataService from "../../../../Application/services/dataServices/typedDataService";

import { FiltersMap, GenericFiltersMap } from "../../../../../utils/filterUtils";
import { AccountBase, PackName } from "../../../../../interfaces";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  setError as setOverviewError,
  setFilterOptions as setOverviewFilterOptions,
  setIsLoading as setOverviewIsLoading,
} from "../slices/flowFiltersSlice";
import { FlowFiltersEnum, GoalOptions } from "../../types/models";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";
import { TagTypes } from "../../types/flowBase";
import { FlowTagsWithType } from "../../types";

import ObjectUtils from "../../../../../utils/objectUtils";
import * as contentAssignments from "../../../../People/ContentAssignments/state/slices/contentAssignmentsFiltersSlice";
import * as nextFlowActions from "../../../Flows/Designer/FlowComponentsPanel/flowEnd/state/slice/nextFlowFiltersSlice";

export enum FiltersToFetch {
  Publishers,
  Tags,
  Packs,
  Goals,
}

export interface GetFilterOptionsProps {
  showPurchased?: boolean;
  filters?: FiltersToFetch[];
}

interface BaseFilterOptionsProps {
  begin: () => void;
  success: (filterOptions: FiltersMap) => void;
  failure: (error: Error) => void;
}

const defaultFiltersOptions = [
  FiltersToFetch.Publishers,
  FiltersToFetch.Tags,
  FiltersToFetch.Packs,
  FiltersToFetch.Goals,
];

const tagToFilterMap: { [key in TagTypes]?: FlowFiltersEnum } = {
  [TagTypes.Label]: FlowFiltersEnum.Labels,
  [TagTypes.SoftwareApplication]: FlowFiltersEnum.SoftwareApplications,
};

const readPublishersData = async () => {
  const publishers = await DataService.flows.getFlowPublishers();
  const filterOptions = {
    [FlowFiltersEnum.Publishers]: publishers.data.map((item: AccountBase) => ({
      text: item.name,
      value: item.id,
    })),
  };
  return filterOptions;
};

const readPacksData = async (showPurchased?: boolean) => {
  const packs = await DataService.packs.getPacksFilterOptions("flow", !!showPurchased);

  const filterOptions = {
    [FlowFiltersEnum.Packs]: packs.data.map((item: PackName) => ({
      text: item.name,
      value: item.id,
    })),
  };
  return filterOptions;
};

const readTagsData = async (showPurchased?: boolean) => {
  const tags = await DataService.flows.getFilterOptions(
    [TagsEnum.Label, TagsEnum.SoftwareApplication],
    !!showPurchased,
  );
  let filterOptions: FiltersMap = {};
  const tagsGropedByTypeType = groupBy(tags.data, "type");
  for (const [type, value] of ObjectUtils.typedEntries<TagTypes, FlowTagsWithType[]>(tagsGropedByTypeType)) {
    filterOptions[tagToFilterMap[type]!] = value.map((item) => ({ text: item.title, value: item.title }));
  }
  return filterOptions;
};

const goalToFilterMap: { [key in keyof GoalOptions]?: FlowFiltersEnum } = {
  objective: FlowFiltersEnum.GoalObjectives,
  type: FlowFiltersEnum.GoalTypes,
};

const readGoalsData = async () => {
  const options = await DataService.flows.getGoalOptions();
  let filterOptions: FiltersMap = {};

  for (const [goal, type] of ObjectUtils.typedEntries<keyof GoalOptions, FlowFiltersEnum>(goalToFilterMap)) {
    filterOptions[type] = options.data[goal].map((item) => ({ text: item.label, value: item.id }));
  }

  return filterOptions;
};

export const getFilterOptions = async (
  props: BaseFilterOptionsProps & GetFilterOptionsProps,
  includePublishers: boolean = true,
) => {
  const { showPurchased, begin, success, failure } = props;
  const selectedFilters = !props.filters?.length ? defaultFiltersOptions : props.filters;

  const filterHandlers: {
    [key in FiltersToFetch]?: (showPurchased?: boolean) => Promise<FiltersMap>;
  } = {
    [FiltersToFetch.Packs]: readPacksData,
    [FiltersToFetch.Tags]: readTagsData,
    [FiltersToFetch.Goals]: readGoalsData,
    ...(includePublishers && { [FiltersToFetch.Publishers]: readPublishersData }),
  };

  begin();

  const filtersHandlersPromises = Object.values(pick(filterHandlers, selectedFilters)).map((handler) =>
    handler(showPurchased),
  );

  const result = await Promise.allSettled(filtersHandlersPromises);

  batch(() => {
    let someFulfilled = false;
    let filterOptions: FiltersMap = {};

    result.forEach((item) => {
      if (item.status === PromiseStatuses.fulfilled) {
        someFulfilled = true;
        filterOptions = {
          ...filterOptions,
          ...item.value,
        };
      }
      if (item.status === PromiseStatuses.rejected) {
        failure(item.reason);
      }
    });

    if (someFulfilled) {
      success(filterOptions);
    }
  });
};

const getFilterOptionsWrapper = (
  setIsLoading: ActionCreatorWithPayload<boolean>,
  setFilterOptions: ActionCreatorWithPayload<GenericFiltersMap<string>>,
  setError: ActionCreatorWithPayload<Error>,
) => {
  return (dispatch: AppDispatch) => {
    return getFilterOptions({
      showPurchased: true,
      begin: () => {
        dispatch(setIsLoading(true));
      },
      success: (filterOptions: FiltersMap) => {
        batch(() => {
          dispatch(setFilterOptions(filterOptions));
          dispatch(setIsLoading(false));
        });
      },
      failure: (error: Error) => {
        batch(() => {
          dispatch(setError(error));
          dispatch(setIsLoading(false));
        });
      },
    });
  };
};

export const getContentAssignmentModalFilterOptions = () =>
  getFilterOptionsWrapper(
    contentAssignments.setIsLoading,
    contentAssignments.setFilterOptions,
    contentAssignments.setError,
  );

export const getNextFlowFilterOptions = () =>
  getFilterOptionsWrapper(nextFlowActions.setIsLoading, nextFlowActions.setFilterOptions, nextFlowActions.setError);

export const getOverviewFilterOptions = () =>
  getFilterOptionsWrapper(setOverviewIsLoading, setOverviewFilterOptions, setOverviewError);
