import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { PackGroupLicenseView } from "../../types/state";

export interface PackGroupLicensesState extends FetchingItemsState<PackGroupLicenseView> {}

const initialState: PackGroupLicensesState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const packGroupLicensesSlice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.PackGroupLicenses,
    name: "packGroupLicenses",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure } = packGroupLicensesSlice.actions;

export default packGroupLicensesSlice.reducer;
export type packGroupLicensesSliceType = ReturnType<typeof packGroupLicensesSlice.reducer>;
