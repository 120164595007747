import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../Application/globaltypes/redux";

export interface ConfigurationPayload {
  id: number;
  title: string;
  description: string;
  thumbnailUrl: string;
}

const flowInfoSelector = (state: RootState) => state.library.flows.base.information.info;

export const createConfigurationInfoSelector = createSelector([flowInfoSelector], (flowInfo) => ({
  id: flowInfo.id,
  title: flowInfo.title,
  description: flowInfo.description,
  thumbnailUrl: flowInfo.thumbnailUrl,
}));
