import * as actionTypes from "../actionTypes/surveyEntityStateActionTypes";
import midnightService from "../../../../Application/services/midnightService/midnightService";
import DraftEntityTypes from "../../../../../enums/draftEntityTypes";
import { Dispatcher, MidnightActionPayload } from "../../../../../interfaces/redux";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { CreateSurveyRequest } from "../../types/requests";
import { SurveyPayload, UpdateContentPayload } from "../../types/models";
import { DataService } from "../../../../Application/services/dataServices/typedDataService";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import {
  SurveyUpdateSuccess,
  SurveyQuestionsUpdateSuccess,
  SurveyPublishSuccess,
  SurveyEditSuccess,
  SurveyDiscardSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { NotifyStepSettings } from "../../../../../interfaces";
import { ShouldNotifyWithProgressIds } from "../../../../SystemNotifications/types";

export const updateBeginAction = getActionBaseProvider(actionTypes.updateSurveyBegin);
export const updateFailureAction = getActionProvider<MidnightActionPayload>(actionTypes.updateSurveyFailure);

export const createBeginAction = getActionBaseProvider(actionTypes.createSurveyBegin);
export const createSuccessAction = getActionProvider<MidnightActionPayload>(actionTypes.createSurveySuccess);
export const createFailureAction = getActionProvider<MidnightActionPayload>(actionTypes.createSurveyFailure);

export const publishBeginAction = getActionBaseProvider(actionTypes.publishSurveyBegin);
export const publishDraftSuccessAction = getActionProvider<MidnightActionPayload>(actionTypes.publishSurveySuccess);
export const publicFailureAction = getActionProvider<MidnightActionPayload>(actionTypes.publishSurveyFailure);

export const fetchDraftBeginAction = getActionBaseProvider(actionTypes.fetchLockedSurveyBegin);
export const fetchDraftSuccessAction = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedSurveySuccess);
export const fetchDraftFailureAction = getActionProvider<MidnightActionPayload>(actionTypes.fetchLockedSurveyFailure);

export const fetchDiscardBeginAction = getActionBaseProvider(actionTypes.fetchDiscardSurveyBegin);
export const fetchDiscardSuccessAction = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchDiscardSurveySuccess,
);
export const fetchDiscardFailureAction = getActionProvider<MidnightActionPayload>(
  actionTypes.fetchDiscardSurveyFailure,
);

export const setSurveyEntityIdAction = getActionProvider<number>(actionTypes.setSurveyEntityId);

export const createSurvey = (data: CreateSurveyRequest) => async (dispatch: Dispatcher) => {
  dispatch(createBeginAction());
  try {
    const result = await midnightService.createLock(DraftEntityTypes.Surveys, data);
    const entityId = result.data as number;
    dispatch(createSuccessAction({ entityId }));
  } catch (error) {
    dispatch(createFailureAction({ error: error as Error }));
  }
};

export const updateSurvey = (metadata: SurveyPayload) => async (dispatch: Dispatcher) => {
  dispatch(updateBeginAction());
  dispatch(beginAsyncOperation({ id: metadata.id!, action: SurveyUpdateSuccess }));
  try {
    await DataService.surveys.updateSurveyAsync(metadata);
  } catch (error) {
    dispatch(updateFailureAction({ error: error as Error }));
  }
};

export const updateContent = (data: UpdateContentPayload) => async (dispatch: Dispatcher) => {
  dispatch(updateBeginAction());
  dispatch(beginAsyncOperation({ id: data.id, action: SurveyQuestionsUpdateSuccess }));
  try {
    await DataService.surveys.updateContentAsync(data);
  } catch (error) {
    dispatch(updateFailureAction({ error: error as Error }));
  }
};

export const updateSurveyCommandSuccess = getActionProvider(
  actionTypes.updateSurveyCommandSuccess,
  (payload: { id: number }) => ({
    payload: {
      entityId: payload.id,
    },
  }),
);

export const surveyUpdated = getActionProvider(actionTypes.surveyUpdated, (payload: { id: number }) => ({
  payload: {
    entityId: payload.id,
  },
}));

export const resetSurveyEntityState = getActionBaseProvider(actionTypes.resetSurveyEntityState);

export const publishDraftSurveyEntity =
  (id: number, notificationSettings?: NotifyStepSettings, peopleToNotifyTypes?: ShouldNotifyWithProgressIds[]) =>
  async (dispatch: Function) => {
    dispatch(publishBeginAction());
    dispatch(beginAsyncOperation({ id, action: SurveyPublishSuccess }));
    try {
      await midnightService.releaseLock(
        DraftEntityTypes.Surveys,
        id,
        notificationSettings ? { notificationSettings, peopleToNotifyTypes } : undefined,
      );
    } catch (error) {
      dispatch(publicFailureAction({ error: error as Error }));
    }
  };

export const fetchDraftSurveyEntity = (id: number) => async (dispatch: Function) => {
  dispatch(fetchDraftBeginAction());
  dispatch(beginAsyncOperation({ id, action: SurveyEditSuccess }));
  try {
    await midnightService.getEntityLock(DraftEntityTypes.Surveys, id);
  } catch (error) {
    dispatch(fetchDraftFailureAction({ error: error as Error }));
  }
};

export const fetchDiscardSurveyEntity = (id: number) => async (dispatch: Function) => {
  dispatch(fetchDiscardBeginAction());
  dispatch(beginAsyncOperation({ id, action: SurveyDiscardSuccess }));
  try {
    await midnightService.discardLock(DraftEntityTypes.Surveys, id);
  } catch (error) {
    dispatch(fetchDiscardFailureAction({ error: error as Error }));
  }
};
