import AlertTypes from "../../../../../enums/alertTypes";
import { getAxiosError } from "../../../../../utils/axiosError";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { BasePerformanceRequestFilterParams } from "../../../Common/models";
import { downloadExcelExport } from "../../../Common/utils/performanceUtils";
import DataService from "../../services/flowsDataService";
import {
  setCardData,
  setEngagementData,
  reqCardData,
  reqEngagementData,
  setEngagementError,
  setCardError,
  reqDateRanges,
  setDateRanges,
  setDateRangesError,
  reqPeopleDetails,
  setPeopleDetails,
  setPeopleDetailsError,
  reqGoalLine,
  reqGoalCards,
  reqGoalTotals,
  setGoalLine,
  setGoalLineError,
  setGoalTotals,
  setGoalTotalsError,
  setGoalCardsError,
  setGoalCards,
  isAllowedGoal,
  FlowGoalFilterParams,
} from "../slices/flowPerformanceSlice";
import { setExporting } from "features/Reporting/state/export/exportSlice";

type PackedGoalInfo = {
  applicationLabel: string | undefined;
  measureLabel: string | undefined;
};

const GOAL_NAME_LOOKUP: Record<string, FlowGoalFilterParams[]> = {
  "Teams All Activity": [
    { application: "Teams", activity: "Meetings" },
    { application: "Teams", activity: "Messages" },
    { application: "Teams", activity: "Calls" },
  ],
  "OneDrive All Activity": [
    { application: "OneDrive", activity: "Files Shared" },
    { application: "OneDrive", activity: "File Synced" },
    { application: "OneDrive", activity: "File Viewed" },
  ],
  "SharePoint All Activity": [
    { application: "SharePoint", activity: "Files Synced" },
    { application: "SharePoint", activity: "Files Viewed" },
    { application: "SharePoint", activity: "Page Visits" },
    { application: "SharePoint", activity: "Shared Files" },
  ],
};

const getGoalNames = (goalInfo: PackedGoalInfo): FlowGoalFilterParams[] => {
  if (Object.values(goalInfo).some((v) => v === undefined)) {
    return [];
  }

  if (goalInfo.measureLabel === "All Activity") {
    return GOAL_NAME_LOOKUP[`${goalInfo.applicationLabel} All Activity`];
  }

  return [{ application: goalInfo.applicationLabel!, activity: goalInfo.measureLabel! }];
};

export const fetchCardData = (flowId: number, filterParams: BasePerformanceRequestFilterParams = {}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqCardData());
    try {
      const { data } = await DataService.getCardData(flowId, filterParams);
      dispatch(
        setCardData({
          data,
        }),
      );
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setCardError({ errorMessage }));
      }
    }
  };
};

export const fetchEngagementData = (flowId: number, filterParams: BasePerformanceRequestFilterParams = {}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqEngagementData());
    try {
      const { data } = await DataService.getFlowEngagement(flowId, filterParams);
      if (data.Relations === undefined) {
        throw new Error(
          "Failed to fetch information for flow engagement. We're working to fix this issue. Please check back in later.",
        );
      }
      dispatch(
        setEngagementData({
          data,
        }),
      );
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(
          setEngagementError({
            errorMessage,
          }),
        );
      }
    }
  };
};

export const fetchFlowDateRanges = (flowId: number) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqDateRanges());
    try {
      const { data } = await DataService.getDateRanges(flowId);
      dispatch(setDateRanges({ data }));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setDateRangesError({ errorMessage }));
      }
    }
  };
};

export const fetchPeopleDetails = (flowId: number, filterParams: BasePerformanceRequestFilterParams = {}) => {
  return async (dispatch: AppDispatch) => {
    dispatch(reqPeopleDetails());
    try {
      const result = (await DataService.getFlowPeopleDetails(flowId, filterParams)).data;

      const resultsWithId = result.map((item, index) => {
        const id = `${item.UserId}${index}`;
        return {
          ...item,
          id,
        };
      });
      dispatch(setPeopleDetails({ data: resultsWithId }));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setPeopleDetailsError({ errorMessage }));
      }
    }
  };
};

export const fetchGoalsLineChart = (
  flowId: number,
  filterParams: BasePerformanceRequestFilterParams,
  goal: PackedGoalInfo,
) => {
  return async (dispatch: AppDispatch) => {
    if (!isAllowedGoal(goal)) {
      return;
    }
    dispatch(reqGoalLine());
    const goalNames = getGoalNames(goal);

    try {
      const res = await Promise.all(
        goalNames.map((goalName) => DataService.getFlowGoalLineChart(flowId, filterParams, goalName)),
      );
      dispatch(setGoalLine(res.map((r) => r.data || [])));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setGoalLineError(errorMessage));
      }
    }
  };
};

export const fetchGoalsTotals = (
  flowId: number,
  filterParams: BasePerformanceRequestFilterParams,
  goal: PackedGoalInfo,
) => {
  return async (dispatch: AppDispatch) => {
    if (!isAllowedGoal(goal)) {
      return;
    }
    dispatch(reqGoalTotals());
    const goalNames = getGoalNames(goal);
    try {
      const res = await Promise.all(
        goalNames.map((goalName) => DataService.getFlowGoalTotals(flowId, filterParams, goalName)),
      );
      dispatch(setGoalTotals(res.map((r) => r.data)));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setGoalTotalsError(errorMessage));
      }
    }
  };
};

export const fetchGoalsCards = (
  flowId: number,
  filterParams: BasePerformanceRequestFilterParams,
  goal: PackedGoalInfo,
) => {
  return async (dispatch: AppDispatch) => {
    if (!isAllowedGoal(goal)) {
      return;
    }
    dispatch(reqGoalCards());
    const goalNames = getGoalNames(goal);
    try {
      const res = await Promise.all(
        goalNames.map((goalName) => DataService.getFlowGoalCards(flowId, filterParams, goalName)),
      );
      dispatch(setGoalCards(res.map((r) => r.data)));
    } catch (err) {
      if (err.code !== "ERR_CANCELED") {
        const errorMessage = getAxiosError(err);
        dispatch(setGoalCardsError(errorMessage));
      }
    }
  };
};

export const handleFlowExport = (flowId: number, dateFilter: BasePerformanceRequestFilterParams, flowTitle: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setExporting(true));
    try {
      const response = await DataService.getFlowExport(flowId, dateFilter);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, flowTitle);
    } catch (err) {
      if (err.code === "ERR_CANCELED") {
        return;
      }
      const errorMessage = getAxiosError(err);
      dispatch(
        sendTransientNotification(
          {
            title: "Export Failed!",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
      console.error("Unable to export", errorMessage);
    } finally {
      dispatch(setExporting(false));
    }
  };
};

export const setDefaultValues = () => {
  return async (dispatch: AppDispatch) => {
    dispatch(
      setCardData({
        data: {
          NotStartedCount: 0,
          InProgressCount: 0,
          CompletedCount: 0,
          RetakenCount: 0,
        },
      }),
    );
    dispatch(
      setEngagementData({
        data: {
          FlowId: -1,
          AccountId: -1,
          Relations: [],
        },
      }),
    );
    dispatch(
      setPeopleDetails({
        data: [],
      }),
    );
  };
};
