import { AppDispatch } from "features/Application/globaltypes/redux";
import { req, got, err } from "../slices/licensingModalSlice";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { PotentialFlowConflictCollection } from "./potentialFlowConflictCollection";
import { LicenseConfirmationItem, PacksContextItem } from "interfaces/licenseConfirmation";
import groupBy from "lodash/groupBy";

export const shouldShowModal = (id: number) => async (dispatch: AppDispatch) => {
  dispatch(req());
  try {
    let shouldShowModal = false;

    const packsResults = await dataService.packs.getDraftPackConflictsInfoAsync(id);
    const conflicts = packsResults.data;
    const [realConflicts, assignments] = new PotentialFlowConflictCollection(conflicts).toRealConflicts();

    if (realConflicts.length > 0) {
      shouldShowModal = true;
    }

    if (assignments.length !== 0) {
      const assignmentsPackIds = assignments.filter((a) => a.flowId === id).flatMap((i) => i.packIds);
      const licenseConfirmationInfo = await dataService.packs.getPackPeopleAssignmentInfoAsync(assignmentsPackIds, id);
      shouldShowModal = hasLicenseConfirmation(licenseConfirmationInfo.data);
    }

    dispatch(got());
    return shouldShowModal;
  } catch (e) {
    dispatch(err(e as Error));
    return true;
  }
};

export const shouldShowModalForCommunications =
  (id: string, type: string, userIds: number[], groupIds: number[]) => async (dispatch: AppDispatch) => {
    dispatch(req());
    try {
      let shouldShowModal = false;
      const packsResults = await dataService.packs.getContentPacksInfoAsync(type, [id]);
      const packsContext = packsResults.data;

      if (packsContext.some((i) => i.packs.length > 1)) {
        shouldShowModal = true;
      }

      if (packsContext.length && packsContext.every((i) => i.packs.length === 1)) {
        const packIds = packsContext.flatMap((i) => i.packs).map((p) => p.id);
        const licenseConfirmationInfo = await dataService.packs.getPackUserContentAssignmentInfoAsync(
          packIds,
          userIds,
          groupIds,
        );
        shouldShowModal = hasLicenseConfirmation(licenseConfirmationInfo.data);
      }

      dispatch(got());
      return shouldShowModal;
    } catch (e) {
      dispatch(err(e as Error));
      return true;
    }
  };

export const shouldShowModalForGroupMembership =
  (groupIds: number[], userIds: number[]) => async (dispatch: AppDispatch) => {
    dispatch(req());
    try {
      let shouldShowModal = false;
      const groupPacksResults = await dataService.packs.getGroupsPacksAsync(groupIds);
      const packIdsModified = groupPacksResults.data.flatMap((gp) => gp.packs).map((p) => p.id);

      if (packIdsModified.length) {
        const licenseConfirmationInfo = await dataService.packs.getPackUserContentAssignmentInfoAsync(
          packIdsModified,
          userIds,
        );

        shouldShowModal = hasLicenseConfirmation(licenseConfirmationInfo.data);
      }

      dispatch(got());
      return shouldShowModal;
    } catch (e) {
      dispatch(err(e as Error));
      return true;
    }
  };

export const shouldShowModalForFlowPublish =
  (flowId: number, items: PacksContextItem[]) => async (dispatch: AppDispatch) => {
    dispatch(req());
    try {
      let shouldShowModal = false;
      const itemsByType = groupBy(items, (i) => i.type);
      const packResults = await Promise.all(
        Object.keys(itemsByType).map((type) => {
          const ids = itemsByType[type].map((i) => i.id);
          return dataService.packs.getContentPacksInfoAsync(type.toLowerCase(), ids);
        }),
      );

      const packsContext = packResults.flatMap((r) => r.data);
      if (packsContext.some((i) => i.packs.length > 1)) {
        shouldShowModal = true;
      }

      if (packsContext.length && packsContext.every((i) => i.packs.length === 1)) {
        const packIds = packsContext.flatMap((i) => i.packs).map((p) => p.id);
        const licenseConfirmationInfo = await dataService.packs.getPackUserFlowAssignmentInfoAsync(packIds, flowId);
        shouldShowModal = hasLicenseConfirmation(licenseConfirmationInfo.data);
      }

      dispatch(got());
      return shouldShowModal;
    } catch (e) {
      dispatch(err(e as Error));
      return true;
    }
  };

const hasLicenseConfirmation = (licenseConfirmationData: LicenseConfirmationItem[]) => {
  return licenseConfirmationData.some(
    (i) => i.issuingLicenses > 0 || i.purchasingLicenses > 0 || i.isAccountPackLicenseExpired,
  );
};
