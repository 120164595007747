import { ColumnDataPoint } from "components/charts/types/ColumnChart";
import { SubgroupData } from "components/charts/types/GroupedBarChart";
import { getFormattedTimeStringFromPeriod } from "features/Library/Common/utils/performanceUtils";
import { ChartPeriod } from "hooks/useChartPeriodMeasure";
import { useCallback } from "react";

export const useGoogleTooltipFormats = (chartPeriod: ChartPeriod, dateRange: Date[]) => {
  const lineChartFormatter = useCallback(
    (xValue: any, yValue: any) => `${getFormattedTimeStringFromPeriod(xValue, chartPeriod, dateRange)}: ${yValue.toLocaleString()}`,
    [chartPeriod, dateRange],
  );

  const groupedBarFormatter = useCallback(
    (d: SubgroupData | ColumnDataPoint) => {
      const dateString = "group" in d ? d.group : d.category;
      return `${getFormattedTimeStringFromPeriod(dateString, chartPeriod, dateRange)}: ${d.value.toLocaleString()}`;
    },
    [chartPeriod, dateRange],
  );
  return {
    lineChartFormatter,
    groupedBarFormatter,
  };
};
