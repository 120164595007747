import { FormikProps, withFormik } from "formik";
import { useEffect, useLayoutEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../Application/globaltypes/redux";
import { SurveyContentView } from "../../types/state";
import { ContentProps } from "../types";
import * as surveyEntityStateActions from "../../state/actions/surveyEntityStateActions";
import * as surveyDetailsActions from "../../state/actions/surveyDetailsActions";
import ContentForm from "../ContentForm/ContentForm";
import validationSchemas from "../../../../../utils/validationSchemas";
import { UpdateContentPayload } from "../../types/models";
import { AutosaveProps, withAutosave } from "../../../../../utils/withAutosave";
import { mapPropsToUpdateContentPayload } from "./mapQuestions";
import Restricted from "../../../../Application/Restricted";
import { RolePermissions } from "../../../../../enums";
import { surveysStateSelector } from "../../state/surveyReducer";
import { IWizardStep } from "interfaces";

export type ContentPropsAll = ContentProps &
  PropsFromRedux &
  FormikProps<SurveyContentView> &
  AutosaveProps &
  IWizardStep;
export type AutosaveInputProps = ContentProps & PropsFromRedux & FormikProps<SurveyContentView>;

export const Content = (props: ContentPropsAll) => {
  const valuesRef = useRef(props.values);
  useEffect(() => {
    valuesRef.current = props.values;
  }, [props.values]);

  //this cleanup logic should be called before useEffect's cleanup (to adjust child-parent cleanup logic)
  useLayoutEffect(() => {
    return () => {
      props.detailsActions.saveContent(valuesRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.subscribeOnDiscarded?.(props.resetForm);
    return () => {
      props.unSubscribeOnDiscarded?.(props.resetForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.acceptHandlers?.({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Restricted
      permissions={[RolePermissions.AssetsCreate]}
      renderContent={(hasPermission) => <ContentForm {...props} disabled={props.disabled || !hasPermission} />}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ContentProps) => {
  const base = surveysStateSelector(state).base;
  const surveyEntityStateReducer = base.surveysEntityStateReducer;
  const surveyDetailsReducer = base.surveysDetailsReducer;

  return {
    id: ownProps.entityId || surveyEntityStateReducer.entityId,
    content: surveyDetailsReducer.content,
    isCreating: surveyEntityStateReducer.changingEntityState,
    isLoaded: surveyDetailsReducer.isContentLoaded,
    isLoading: surveyDetailsReducer.isLoading,
    isDraft: surveyDetailsReducer.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(surveyEntityStateActions, dispatch),
  detailsActions: bindActionCreators(surveyDetailsActions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ContentPropsAll, SurveyContentView, UpdateContentPayload>({
  getInitValues: (props) => ({ id: props.id, questions: props.content.questions }),
  stateProvider: (values, props) => ({ id: props.id, questions: values.questions }),
  entityUpdater: (props) => props.entityStateActions.updateContent,
  isValid: (props) => props.isDraft && props.id > 0,
  stateProjector: mapPropsToUpdateContentPayload,
})(Content);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.surveysContent,
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: (props: ContentProps & PropsFromRedux) => props.content,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
