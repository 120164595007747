import { bindActionCreators } from "@reduxjs/toolkit";
import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DropdownProps, Table } from "semantic-ui-react";
import { SearchInput } from "../../../../../../components";
import AccountPicture from "../../../../../../components/accountPicture/AccountPicture";
import { ButtonWithIcon } from "../../../../../../components/buttons/buttonWithIcon";
import EllipsisPopupButton from "../../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { RemoveLinkButton } from "../../../../../../components/buttons/linkButtons";
import { accountsFilter } from "../../../../../../components/filterForms/AccountsFilterForm/AccountsFilterForm";
import { Title } from "../../../../../../components/listViewTemplates";
import { SortingDirection, ViewType } from "../../../../../../enums";
import { Account } from "../../../../../../interfaces/Account";
import { FiltersMap } from "../../../../../../utils/filterUtils";
import { ItemsView } from "../../../../../../views";
import PackVisibilityForAccountsNoResults from "../../../../../../views/packs/PackVisibility/PackVisibilityForAccountsNoResults";
import { fetchAccounts } from "../../../../../Accounts/AllAccountsOverview/state/thunks/allAccountsOverviewThunk";
import { AppDispatch, RootState } from "../../../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { setAppliedFilter, resetAppliedFilter, accountFilterSlice } from "../../../state/slices/accountFilterSlice";
import { reset } from "../../../state/slices/packVisibilityForAccountsSlice";
import { getFilterOptions } from "../../../state/thunks/accountFilterThunk";
import {
  addPackVisibilityForAccount,
  fetchPackVisibilityAccountIds,
  fetchPackVisibilityAccounts,
  deletePackVisibilityForAccount,
} from "../../../state/thunks/packVisibilityForAccountsThunk";
import { PackVisibilityForAccountsModalOneStep } from "./PackVisibilityForAccountsModal/PackVisibilityForAccountsModalOneStep";
import { AccountVisibility, columnOptions, columnsMap } from "./types";
import { bindAction } from "../../../../../../interfaces/redux";
import RemovePackVisibilityConfirmationModal from "../RemovePackVisibilityConfirmationModal/RemovePackVisibilityConfirmationModal";
import PackVisibilityTypes from "../../../../../../enums/licensing/packVisibilityTypes";
import { IObservable } from "../../../../../../interfaces/IObservable";
import { useObserver } from "../../../../../../hooks/useObserver";
import "./packVisibilityForAccounts.scss";

const defaultSortColumn = "added";

export interface PackVisibilityForAccountsOwnProps {
  id: number;
  isReadOnlyMode: boolean;
  showAddAccountsModal: boolean;
  showRemoveConfirmationModal: boolean;
  setAccountsModalVisibility: (isShow: boolean) => void;
  setRemoveConfirmationModalVisibility: (showModal: boolean) => void;
  setShowRemoveButton: (showModal: boolean) => void;
  onTabChangeObserver: IObservable<() => void>;
}

export type PackVisibilityForAccountsPropsAll = PropsFromRedux & PackVisibilityForAccountsOwnProps;

export const PackVisibilityForAccounts: React.FC<PackVisibilityForAccountsPropsAll> = ({
  id,
  isReadOnlyMode,
  showAddAccountsModal,
  showRemoveConfirmationModal,
  fetchVisibilityAccounts,
  packVisibilityForAccount,
  potentionalPackVisiblityForAccounts,
  resetPackVisibilityForAccounts,
  filterOptions,
  appliedFilter,
  applyFilter,
  resetFilter,
  getAccountsFilterOptions,
  fetchPotentialAllAccounts,
  fetchVisibilityAccountIds,
  deletePackVisibilityAccount,
  addPackVisibilityAccounts,
  resetPackAccounts,
  setAccountsModalVisibility,
  setRemoveConfirmationModalVisibility,
  setShowRemoveButton,
  isSaving,
  onTabChangeObserver,
  isPublicToAll,
}) => {
  const reloadListItemsRef = useRef<() => void>();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const resetAccountsTab = () => {
    setSelectedIds([]);
    setShowRemoveButton(false);
  };

  useEffect(() => {
    return () => {
      resetAccountsTab();
      resetPackAccounts();
    };
    // eslint-disable-next-line
  }, []);

  const [subscribeOnTabChange] = useObserver(onTabChangeObserver);

  useEffect(() => {
    return subscribeOnTabChange(resetAccountsTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnTabChange]);

  useEffect(() => {
    reloadListItemsRef.current && reloadListItemsRef.current();
  }, [searchTerm]);

  const onSetReloadListItems = (callback: () => void) => {
    reloadListItemsRef.current = callback;
  };

  const onSortChange = (_ev: React.SyntheticEvent<HTMLElement>, _data: DropdownProps) => {
    resetPackVisibilityForAccounts();
  };

  const getAccountPackVisibilities = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
  ) => {
    fetchVisibilityAccounts({
      packId: id,
      top: top,
      skip: skip,
      orderBy: columnsMap[sortingColumnName] ?? sortingColumnName,
      orderDirection: sortingDirection,
      filters: { ...appliedFilter },
      searchTerm: searchTerm,
    });
  };

  const onConfirmAddAccountsModal = (accountIds: number[]) => {
    setAccountsModalVisibility(false);
    addPackVisibilityAccounts(id, accountIds);
  };

  const getAccountsWithVisibility = (accounts: Account[], alreadyVisibleAccoutIds: number[]) => {
    return accounts.map(
      (account): AccountVisibility => ({
        ...account,
        isPackVisible: alreadyVisibleAccoutIds?.includes(account.id),
      }),
    );
  };

  const onFetchPotentialContacts = (
    skip: number,
    top: number,
    orderBy: string,
    orderByParams: SortingDirection,
    filterParams: FiltersMap,
  ) => {
    fetchVisibilityAccountIds(id);
    const filterQuery = accountsFilter.buildFilterQuery(filterParams);
    fetchPotentialAllAccounts(skip, top, columnsMap[orderBy] ?? orderBy, orderByParams, filterQuery);
  };

  const onSelectedItemsChanged = (ids: number[]) => {
    const anySelected = ids.length > 0;
    setShowRemoveButton(anySelected);
    setSelectedIds(ids);
  };

  const onRemoveAccounts = (accountId: number) => {
    setSelectedIds([accountId]);
    setRemoveConfirmationModalVisibility(true);
  };

  const onConfirmRemoveAccountTypesModal = () => {
    setShowRemoveButton(false);
    setRemoveConfirmationModalVisibility(false);
    deletePackVisibilityAccount(id, selectedIds);
    setSelectedIds([]);
  };

  const renderModals = () => {
    return (
      <>
        <PackVisibilityForAccountsModalOneStep
          loadPage={onFetchPotentialContacts}
          accountsAmount={potentionalPackVisiblityForAccounts.itemsCount}
          isListLoading={potentionalPackVisiblityForAccounts.isLoading}
          accounts={getAccountsWithVisibility(
            potentionalPackVisiblityForAccounts.items,
            packVisibilityForAccount.visibleAccountIds,
          )}
          onConfirm={onConfirmAddAccountsModal}
          showModal={showAddAccountsModal}
          onCancel={() => setAccountsModalVisibility(false)}
          id={id}
        />
        <RemovePackVisibilityConfirmationModal
          open={showRemoveConfirmationModal}
          onContinue={onConfirmRemoveAccountTypesModal}
          onCancel={() => setRemoveConfirmationModalVisibility(false)}
          packVisibilityType={PackVisibilityTypes.Account}
          itemsCount={selectedIds.length}
        />
      </>
    );
  };

  const renderDeleteButton = (accountId: number) => {
    return <RemoveLinkButton onClick={() => onRemoveAccounts(accountId)} isDisabled={isReadOnlyMode} />;
  };

  const buildTableBody = (accountPackVisibility: Account) => {
    return (
      <React.Fragment>
        <Table.Cell width={columnOptions[0].width}>
          <div className="account-info">
            <AccountPicture className="account-info-picture" imageUrl={accountPackVisibility.logoUri} />
            <Title title={accountPackVisibility.name} clamp={2} />
          </div>
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <Title title={accountPackVisibility.accountTypeName} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>{accountPackVisibility.userCount}</Table.Cell>
        <Table.Cell className="align-right-pack-visibility" width={columnOptions[3].width}>
          <EllipsisPopupButton circle outlinedEllipsis disabled={selectedIds.includes(accountPackVisibility.id)}>
            {renderDeleteButton(accountPackVisibility.id)}
          </EllipsisPopupButton>
        </Table.Cell>
      </React.Fragment>
    );
  };

  const onSearchChange = (searchText: string) => {
    setSearchTerm(searchText);
  };

  const addAccountsButton = (
    <ButtonWithIcon
      disabled={isReadOnlyMode}
      id="add-accounts-modal"
      label="Add Accounts"
      onClick={() => setAccountsModalVisibility(true)}
      primary
    />
  );

  return (
    <section className="scrollable-content assignment-list-step">
      <ItemsView
        viewType={ViewType.LIST}
        columnOptions={columnOptions}
        getData={getAccountPackVisibilities}
        itemsInlineCount={packVisibilityForAccount.itemsCount}
        isLoading={packVisibilityForAccount.isLoading || isSaving}
        items={packVisibilityForAccount.items}
        buildTableBody={buildTableBody}
        appliedFilter={appliedFilter}
        getFilterOptions={getAccountsFilterOptions}
        filterOptions={filterOptions}
        applyFilter={applyFilter}
        resetFilter={resetFilter}
        noResultsContent={
          <PackVisibilityForAccountsNoResults
            addAccountsButton={addAccountsButton}
            filtered={false}
            isPublicToAll={isPublicToAll}
          />
        }
        sortingColumnName={defaultSortColumn}
        sortingDirection={SortingDirection.Descending}
        onSortChange={onSortChange}
        renderSearch={() => <SearchInput placeholder="Search for Accounts..." onChange={onSearchChange} disabled />}
        setReloadListItems={onSetReloadListItems}
        listViewRtnEvents={[rtnEvents.EditAccountPackVisibilitySuccess]}
        onSelectedListItemsChanged={onSelectedItemsChanged}
        selectedIds={selectedIds}
        isSelectDisabled={() => isReadOnlyMode}
      />
      {renderModals()}
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    packVisibilityForAccount: state.packs.packsVisibilityForAccount,
    potentionalPackVisiblityForAccounts: state.accounts.overview,
    filterOptions: state.packs.accountsFilters.filterOptions,
    appliedFilter: state.packs.accountsFilters.appliedFilter,
    isSaving: state.packs.packEntityStateReducer.isEntityCommandInProgress,
    isPublicToAll:
      state.packs.packsVisibilityForAccount.itemsCount === 0 && state.packs.accountTypesVisibility.itemsCount === 0,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchVisibilityAccountIds: bindAction(fetchPackVisibilityAccountIds, dispatch),
    fetchVisibilityAccounts: bindAction(fetchPackVisibilityAccounts, dispatch),
    fetchPotentialAllAccounts: bindAction(fetchAccounts, dispatch),
    resetPackVisibilityForAccounts: bindAction(reset, dispatch),
    applyFilter: bindActionCreators(setAppliedFilter, dispatch),
    resetFilter: bindActionCreators(resetAppliedFilter, dispatch),
    getAccountsFilterOptions: () => dispatch(getFilterOptions(accountFilterSlice)),
    deletePackVisibilityAccount: bindAction(deletePackVisibilityForAccount, dispatch),
    addPackVisibilityAccounts: bindAction(addPackVisibilityForAccount, dispatch),
    resetPackAccounts: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PackVisibilityForAccounts);
