import { useCallback, useEffect, useRef, useState } from "react";

type RefCallback = (node: Element | null) => () => void;

export const useDimensions = (): [DOMRect | null, RefCallback] => {
  const [dimensions, setDimensions] = useState<DOMRect | null>(null);
  const callback = useCallback((entries: ResizeObserverEntry[]) => {
    for (let entry of entries) {
      setDimensions(entry.contentRect);
    }
  }, []);
  const resizeObserverRef = useRef(new ResizeObserver(callback));

  const measuredRef = useCallback((node: Element) => {
    if (node) {
      resizeObserverRef.current.observe(node);
    }
  }, []);

  useEffect(() => {
    const obs = resizeObserverRef.current;
    return () => obs?.disconnect();
  }, []);

  return [dimensions, measuredRef as RefCallback];
};
