export enum EditVideoSteps {
  Performance,
  Configuration,
  Settings,
  ClosedCaptions,
  People,
  AssociatedPacks
}

export const EditVideoStepConfig = {
  [EditVideoSteps.Performance]: { showAutoSaveOnTab: false },
  [EditVideoSteps.Configuration]: { showAutoSaveOnTab: true },
  [EditVideoSteps.ClosedCaptions]: { showAutoSaveOnTab: false },
  [EditVideoSteps.People]: { showAutoSaveOnTab: false },
  [EditVideoSteps.AssociatedPacks]: { showAutoSaveOnTab: false },
};
