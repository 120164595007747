import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../enums/reducer";
import { RootState } from "../../../Application/globaltypes/redux";
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveUsers,
  CompletedUsers,
  StartedUsers,
  AddedUsers,
  ConsumedLicenses,
  EmailActivity,
  EmailPerformance,
} from "../../types/overview";
import { RequestState, RStatus } from "../../../Application/globaltypes/fetchRequest";

const namespace = ReducerNamespaceTypes.Reporting;
const entityPrefix = ReducerEntityPrefixTypes.Reporting_Overview;

export interface OverviewState {
  addedUsers: RequestState<AddedUsers[]>;
  completedUsers: RequestState<CompletedUsers[]>;
  startedUsers: RequestState<StartedUsers[]>;
  activeUsers: RequestState<ActiveUsers[]>;
  consumedLicenses: RequestState<ConsumedLicenses[]>;
  emailActivity: RequestState<EmailActivity[]>;
  emailPerformance: RequestState<EmailPerformance>;
}

const initialRequestState: RequestState<any> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

const initialRequestStateObject: RequestState<any> = {
  status: RStatus.Idle,
  value: {},
  errorMessage: undefined,
};

export const initialState: OverviewState = {
  addedUsers: initialRequestState,
  completedUsers: initialRequestState,
  startedUsers: initialRequestState,
  activeUsers: initialRequestState,
  consumedLicenses: initialRequestState,
  emailActivity: initialRequestState,
  emailPerformance: initialRequestStateObject,
};

const overviewSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    getAddedUsersAction(state) {
      state.addedUsers = {
        ...state.addedUsers,
        status: RStatus.Pending,
      };
    },
    setAddedUsersAction(state, action: PayloadAction<{ items: Array<AddedUsers> }>) {
      state.addedUsers = {
        ...state.addedUsers,
        status: RStatus.Got,
        errorMessage: undefined,
        value: action.payload.items,
      };
    },
    setAddedUsersError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.addedUsers = {
        ...state.addedUsers,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    getCompletedUsersAction(state) {
      state.completedUsers = {
        ...state.completedUsers,
        status: RStatus.Pending,
      };
    },
    setCompletedUsersAction(state, action: PayloadAction<{ items: Array<CompletedUsers> }>) {
      state.completedUsers = {
        ...state.completedUsers,
        status: RStatus.Got,
        errorMessage: undefined,
        value: action.payload.items,
      };
    },
    setCompletedUsersError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.completedUsers = {
        ...state.completedUsers,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    getStartedUsersAction(state) {
      state.startedUsers = {
        ...state.startedUsers,
        status: RStatus.Pending,
      };
    },
    setStartedUsersAction(state, action: PayloadAction<{ items: Array<CompletedUsers> }>) {
      state.startedUsers = {
        ...state.startedUsers,
        status: RStatus.Got,
        errorMessage: undefined,
        value: action.payload.items,
      };
    },
    setStartedUsersError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.startedUsers = {
        ...state.startedUsers,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    getActiveUsersAction(state) {
      state.activeUsers = {
        ...state.activeUsers,
        status: RStatus.Pending,
      };
    },
    setActiveUsersAction(state, action: PayloadAction<{ items: Array<ActiveUsers> }>) {
      state.activeUsers = {
        ...state.activeUsers,
        status: RStatus.Got,
        errorMessage: undefined,
        value: action.payload.items,
      };
    },
    setActiveUsersError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.activeUsers = {
        ...state.activeUsers,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    getConsumedLicensesAction(state) {
      state.consumedLicenses = {
        ...state.consumedLicenses,
        status: RStatus.Pending,
      };
    },
    setConsumedLicensesAction(state, action: PayloadAction<{ items: Array<ConsumedLicenses> }>) {
      state.consumedLicenses = {
        status: RStatus.Got,
        value: action.payload.items,
      };
    },
    setConsumedLicensesError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.consumedLicenses = {
        ...state.consumedLicenses,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    getEmailActivityAction(state) {
      state.emailActivity = {
        ...state.emailActivity,
        status: RStatus.Pending,
      };
    },
    setEmailActivityAction(state, action: PayloadAction<{ items: Array<EmailActivity> }>) {
      state.emailActivity = {
        status: RStatus.Got,
        value: action.payload.items,
      };
    },
    setEmailActivityError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.emailActivity = {
        ...state.emailActivity,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    getEmailPerformanceAction(state) {
      state.emailPerformance = {
        ...state.emailPerformance,
        status: RStatus.Pending,
      };
    },
    setEmailPerformanceAction(state, action: PayloadAction<{ item: EmailPerformance }>) {
      state.emailPerformance = {
        status: RStatus.Got,
        value: action.payload.item,
      };
    },
    setEmailPerformanceError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.emailPerformance = {
        ...state.emailPerformance,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return initialState;
    },
  },
});

// actions
export const {
  getAddedUsersAction,
  setAddedUsersAction,
  setAddedUsersError,
  getCompletedUsersAction,
  setCompletedUsersAction,
  setCompletedUsersError,
  getStartedUsersAction,
  setStartedUsersAction,
  setStartedUsersError,
  getActiveUsersAction,
  setActiveUsersAction,
  setActiveUsersError,
  getConsumedLicensesAction,
  setConsumedLicensesAction,
  setConsumedLicensesError,
  getEmailActivityAction,
  setEmailActivityAction,
  setEmailActivityError,
  getEmailPerformanceAction,
  setEmailPerformanceAction,
  setEmailPerformanceError,
  reset,
} = overviewSlice.actions;

export const selectAddedUsers = (state: RootState) => state.reporting.overview.addedUsers;
export const selectCompletedUsers = (state: RootState) => state.reporting.overview.completedUsers;
export const selectStartedUsers = (state: RootState) => state.reporting.overview.startedUsers;
export const selectActiveUsers = (state: RootState) => state.reporting.overview.activeUsers;
export const selectConsumedLicenses = (state: RootState) => state.reporting.overview.consumedLicenses;
export const selectEmailActivity = (state: RootState) => state.reporting.overview.emailActivity;
export const selectEmailPerformance = (state: RootState) => state.reporting.overview.emailPerformance;

const normalizeDateString = (date: string) => {
  const parsed = new Date(date);
  parsed.setHours(0, 0, 0, 0);
  return parsed;
};

export const selectAddedUsersDates = createSelector(selectAddedUsers, (users) =>
  users.value.map((user) => normalizeDateString(user.Date)),
);
export const selectAddedUsersFlows = createSelector(selectAddedUsers, (users) => users.value.map((user) => user.Flows));

export const selectCompletedUsersDates = createSelector(selectCompletedUsers, (users) =>
  users.value.map((user) => normalizeDateString(user.Date)),
);
export const selectCompletedUsersFlows = createSelector(selectCompletedUsers, (users) =>
  users.value.map((user) => user.Flows),
);

export const selectStartedUsersDates = createSelector(selectStartedUsers, (users) =>
  users.value.map((user) => normalizeDateString(user.Date)),
);
export const selectStartedUsersFlows = createSelector(selectStartedUsers, (users) =>
  users.value.map((user) => user.Flows),
);

export const selectActiveUsersDates = createSelector(selectActiveUsers, (users) =>
  users.value.map((user) => normalizeDateString(user.Date)),
);
export const selectActiveUsersValues = createSelector(selectActiveUsers, (users) =>
  users.value.map((user) => user.Users),
);

export const selectConsumedLicensesGroups = createSelector(selectConsumedLicenses, (licenses) =>
  licenses.value.map((license) => {
    return {
      group: license.Title,
      "Total Count": license.LicenseCount,
      "Licensed Users": license.LicensedUsers,
    };
  }),
);

export const selectEmailActivityDates = createSelector(selectEmailActivity, (activities) =>
  activities.value.map((activity) => normalizeDateString(activity.Date)),
);
export const selectEmailActivityClicks = createSelector(selectEmailActivity, (activities) =>
  activities.value.map((activity) => activity.Clicks),
);
export const selectEmailActivityOpens = createSelector(selectEmailActivity, (activities) =>
  activities.value.map((activity) => activity.Opens),
);
export const selectEmailActivitySends = createSelector(selectEmailActivity, (activities) =>
  activities.value.map((activity) => activity.Sends),
);

export const selectEmailPerformanceClickRate = createSelector(selectEmailPerformance, (email) => email.value.ClickRate);
export const selectEmailPerformanceOpenRate = createSelector(selectEmailPerformance, (email) => email.value.OpenRate);

export default overviewSlice.reducer;
