import moment from "moment";
import { createSelector } from "@reduxjs/toolkit";
import { ColumnDataPoint } from "../../../../../components/charts/types/ColumnChart";
import { DataPoint } from "../../../../../components/charts/types/HorizontalBarChart";
import { selectTeamsDeviceUsage } from "../graphSlice";
import { startsColor } from "features/Library/Common/utils/performanceUtils";

/**
 * Hardcoded order of
 * [Android, ChromeOS, Linux, Mac, Web, Windows, WindowsPhone, iOS]
 */
export const selectFormattedUsageData = createSelector(selectTeamsDeviceUsage, (state) => {
  let returnVal = state.value.map((v) => {
    return {
      date: moment(v.ReportDate).toDate(),
      counts: [v.Android, v.ChromeOS, v.Linux, v.Mac, v.Web, v.Windows, v.WindowsPhone, v.iOS],
    };
  });
  return returnVal;
});

// For top column chart
export const selectActiveUsersSummations = createSelector(selectFormattedUsageData, (state) => {
  return state.map((s) => ({
    date: s.date,
    totalCount: s.counts.reduce((acc, curr) => acc + curr, 0),
  }));
});

/**
 * For lower line chart
 */
export const selectUsageLineData = createSelector(selectFormattedUsageData, (state) => {
  const totalCount = state.length;
  let returnVal = {
    Android: Array<number>(totalCount),
    ChromeOS: Array<number>(totalCount),
    dates: Array<Date>(totalCount),
    Linux: Array<number>(totalCount),
    Mac: Array<number>(totalCount),
    Web: Array<number>(totalCount),
    Windows: Array<number>(totalCount),
    WindowsPhone: Array<number>(totalCount),
    iOS: Array<number>(totalCount),
  };
  state.forEach((item, index) => {
    const totalSum = item.counts.reduce((acc, curr) => acc + curr, 0);
    returnVal.Android[index] = Math.round((item.counts[0] / totalSum) * 100) || 0;
    returnVal.ChromeOS[index] = Math.round((item.counts[1] / totalSum) * 100) || 0;
    returnVal.Linux[index] = Math.round((item.counts[2] / totalSum) * 100) || 0;
    returnVal.Mac[index] = Math.round((item.counts[3] / totalSum) * 100) || 0;
    returnVal.Web[index] = Math.round((item.counts[4] / totalSum) * 100) || 0;
    returnVal.Windows[index] = Math.round((item.counts[5] / totalSum) * 100) || 0;
    returnVal.WindowsPhone[index] = Math.round((item.counts[6] / totalSum) * 100) || 0;
    returnVal.iOS[index] = Math.round((item.counts[7] / totalSum) * 100) || 0;

    returnVal.dates[index] = item.date;
  });

  return returnVal;
});

type PossibleDevices = {
  Android: number;
  ChromeOS: number;
  Linux: number;
  Mac: number;
  Web: number;
  Windows: number;
  WindowsPhone: number;
  iOS: number;
};

export const selectUsageByDevicePercentages = createSelector(selectTeamsDeviceUsage, (state) => {
  const categorySums: PossibleDevices = state.value.reduce(
    (prev, curr) => ({
      Android: prev.Android + curr.Android,
      ChromeOS: prev.ChromeOS + curr.ChromeOS,
      Linux: prev.Linux + curr.Linux,
      Mac: prev.Mac + curr.Mac,
      Web: prev.Web + curr.Web,
      Windows: prev.Windows + curr.Windows,
      WindowsPhone: prev.WindowsPhone + curr.WindowsPhone,
      iOS: prev.iOS + curr.iOS,
    }),
    {
      Android: 0,
      ChromeOS: 0,
      Linux: 0,
      Mac: 0,
      Web: 0,
      Windows: 0,
      WindowsPhone: 0,
      iOS: 0,
    },
  );

  const totalSum = Object.values(categorySums).reduce((a, v) => a + v, 0);

  const categoryPercentages: PossibleDevices = {
    Android: 0,
    ChromeOS: 0,
    Linux: 0,
    Mac: 0,
    Web: 0,
    Windows: 0,
    WindowsPhone: 0,
    iOS: 0,
  };

  (Object.keys(categorySums) as Array<keyof typeof categorySums>).forEach((key) => {
    categoryPercentages[key] = Math.round((categorySums[key] / totalSum) * 100);
  });

  return {
    categorySums,
    categoryPercentages,
  };
});

// For column chart in device usage
export const selectFormattedColumnData = createSelector(selectActiveUsersSummations, (arg): ColumnDataPoint[] => {
  return arg.map((a, i) => ({
    id: i.toString(),
    category: a.date.toISOString(),
    value: a.totalCount,
    fillColor: startsColor,
  }));
});

// For bar chart
export const selectFormattedBarData = createSelector(selectUsageByDevicePercentages, (devices): DataPoint[] => {
  const entries = Object.entries(devices.categoryPercentages).map<DataPoint>(([category, value]) => ({
    id: category,
    category,
    value,
    fillColor: startsColor,
  }));
  entries.sort((a, b) => b.value - a.value);
  return entries;
});
