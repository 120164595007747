import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Icon } from "semantic-ui-react";

import { baseIconButtonPropTypes } from "./baseIconButtonPropTypes";
import "./iconButtons.scss";

function BaseIconButton(props) {
  const { onClick, className, iconClassName, isDisabled, isOutlineIcon, size } = props;
  const newClassName = cn("icon-button", iconClassName, className);

  return (
    <Icon
      size={size}
      className={isOutlineIcon ? cn("fal outline icon-button", newClassName) : newClassName}
      onClick={isDisabled ? null : onClick}
      disabled={isDisabled || !onClick}
      link={!!onClick}
      role="button"
    />
  );
}

BaseIconButton.defaultProps = {
  size: "large",
  isOutlineIcon: false,
};

BaseIconButton.propTypes = {
  ...baseIconButtonPropTypes,
  iconClassName: PropTypes.string,
};

export default BaseIconButton;
