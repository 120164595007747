import { TextTruncate } from "../../../../components";
import { ContentItemMenu } from "../../../../components/buttons/contentItemMenu/ContentItemMenu";
import { EditLinkButton, DuplicateLinkButton, DeleteLinkButton } from "../../../../components/buttons/linkButtons";
import { PublishedStatus } from "../../../../components/common/publishedStatus";
import { Tooltip } from "../../../../components/common/tooltip";
import { Title as TitleComponent } from "../../../../components/listViewTemplates";
import DeletionRestrictedTooltip from "../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import { PublishedStatusTypes } from "../../../../enums";
import SortOptions from "../../../../enums/SortOptions";
import { ColumnOption } from "../../../../interfaces/columnOptions";
import SortingOptions from "../../../../interfaces/SortingOptions";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { EventOverview } from "../types/state";

export enum Columns {
  Title = "Title",
  Publisher = "Publisher",
  Status = "Status",
  Modified = "Modified",
  Added = "Added",
}

export const sortingOptions: SortingOptions[] = [
  {
    text: "Most Recent",
    value: SortOptions.Id,
    default: true,
  },
  {
    text: "Last Modified",
    value: SortOptions.ModifiedDateDesc,
  },
  {
    text: "Sorted A-Z",
    value: SortOptions.Title,
  },
];

export interface GetColumnOptionsParams {
  readonly?: boolean;
  deleteContentWithDependenciesFeatureEnabled: boolean;
  buttonHandlers: {
    handleEditClick: (id: number, isDraft: boolean) => () => void;
    handleDuplicateClick: (selectedIds: number[]) => () => void;
    handleDeleteClick: (selectedIds: number[], flowsCount?: number, packsCount?: number) => () => void;
  };
}

export const getColumnOptions = (params: GetColumnOptionsParams): ColumnOption<EventOverview>[] => [
  {
    name: Columns.Title,
    width: 4,
    isSortable: true,
    render: (event) => (
      <TitleComponent
        url={event.id.toString()}
        title={event.title}
        onTitleClick={params.buttonHandlers.handleEditClick(event.id, true)}
        clamp={2}
        className="normal-color"
      />
    ),
  },
  {
    name: Columns.Publisher,
    width: 3,
    isSortable: false,
    render: (event) => <Tooltip target={<TextTruncate>{event.publisher}</TextTruncate>} content={event.publisher} />,
  },
  {
    name: Columns.Status,
    width: 4,
    isSortable: true,
    render: (event) => (
      <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!event.isDraft)} />
    ),
  },
  {
    name: Columns.Modified,
    width: 2,
    isSortable: true,
    render: (event) => dateTimeUtils.formatDate(event.dateModified),
  },
  {
    name: Columns.Added,
    width: 2,
    isSortable: true,
    render: (event) => dateTimeUtils.formatDate(event.dateCreated),
  },
  {
    name: "",
    width: 1,
    className: "align-right",
    isSortable: false,
    render: (item, isChecked) => {
      const { flowsCount = 0, packsCount = 0, assignmentsCount = 0 } = item.dependencies || {};
      const dependencies = { flows: flowsCount, packs: packsCount, "users or groups": assignmentsCount };
      const entityType = "event";
      return (
        <ContentItemMenu
          circle
          outlinedEllipsis
          isChecked={isChecked}
          item={item}
          readonly={params.readonly || item.isPurchased}
          entityType={entityType}
          dependencies={dependencies}
        >
          <EditLinkButton
            isDisabled={item.isPurchased}
            onClick={params.buttonHandlers.handleEditClick(item.id, item.isDraft)}
          />
          <DuplicateLinkButton
            isDisabled={item.isPurchased}
            onClick={params.buttonHandlers.handleDuplicateClick([item.id])}
          />
          <DeleteLinkButton
            isDisabled={!params.deleteContentWithDependenciesFeatureEnabled && !item.canBeDeleted}
            after={
              !params.deleteContentWithDependenciesFeatureEnabled &&
              !item.isPurchased &&
              hasDeletionDependencies(dependencies) && (
                <DeletionRestrictedTooltip dependencies={dependencies} entityType={entityType} />
              )
            }
            onClick={params.buttonHandlers.handleDeleteClick([item.id], flowsCount, packsCount)}
          />
        </ContentItemMenu>
      );
    },
  },
];

export const hasDeletionDependencies = (dependencies: { [key: string]: number }) => {
  return !!Object.values(dependencies).reduce((sum, value) => sum + value);
};
