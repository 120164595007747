import { combineReducers } from "@reduxjs/toolkit";

import { AccountType } from "../../../types";
import { GlobalRole } from "../../../../../interfaces/role";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";
import {
  fetchAccountTypes,
  accountTypesPrefixPayload,
  fetchGlobalRoles,
  globalRolesStatePrefixPayload,
} from "../thunks/createAccountThunk";

const accountTypesState: FetchingItemsState<AccountType> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
};

const accountTypesSlice = createFetchingItemsSlice({
  namePayload: accountTypesPrefixPayload,
  initialState: accountTypesState,
  reducers: {},
  thunk: fetchAccountTypes,
});

const globalRolesState: FetchingItemsState<GlobalRole> = {
  items: [],
  itemsCount: 0,
  isLoading: false,
};

const globalRolesSlice = createFetchingItemsSlice({
  namePayload: globalRolesStatePrefixPayload,
  initialState: globalRolesState,
  reducers: {},
  thunk: fetchGlobalRoles,
});

const createAccountReducer = combineReducers({
  accountTypes: accountTypesSlice.reducer,
  globalRoles: globalRolesSlice.reducer,
});

export default createAccountReducer;

export type CreateAccountState = ReturnType<typeof createAccountReducer>;
