import { UserSource } from "enums";
import { isEqual } from "lodash";

export interface EnrollmentRule {
  source?: UserSource | null;
  filter?: number | null;
  type: number | null;
  value: any;
}

export const defaultEnrollmentRule: EnrollmentRule = { source: null, filter: null, type: null, value: "" };
export const isDefaultRuleSet = (rules: EnrollmentRule[]): boolean => isEqual(rules, [defaultEnrollmentRule]);
