import * as actionTypes from "../../actions/entityState/entityStateActionTypes";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { EntityState } from "../../../../interfaces/state/entityState";
import { PayloadAction, MidnightActionPayload } from "../../../../interfaces/redux";
import { Action, Reducer } from "@reduxjs/toolkit";

const initialState: EntityState = {
  entityId: 0,
  changingEntityState: false,
  isPublished: false,
  error: undefined,
  lastModifiedDateTime: undefined,
  isEntityCommandInProgress: false,
};

export type EntityDetailsPayload = {
  id: number;
  isDraft: boolean;
  dateModified?: string;
};

const getHandlers = (prefix: string) => {
  const handleBeginAction = (state: EntityState): EntityState => ({
    ...state,
    changingEntityState: true,
    error: undefined,
  });

  const handleSuccess = (state: EntityState, action: Action): EntityState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;
    if (state.entityId > 0 && state.entityId !== payload.entityId) {
      return { ...state };
    }
    return {
      ...state,
      changingEntityState: false,
      isPublished: false,
      entityId: payload.entityId!,
    };
  };

  const handleCreateDraftSuccess = (state: EntityState, action: Action): EntityState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;
    if (state.entityId > 0 && state.entityId !== payload.entityId) {
      return { ...state };
    }
    return {
      ...state,
      changingEntityState: false,
      isPublished: false,
      entityId: payload.entityId!,
      lastModifiedDateTime: dateTimeUtils.getCurrentDate()?.toISOString(),
    };
  };

  const handlePublishSuccess = (state: EntityState, action: Action): EntityState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;

    const entityId = payload.entityId as number;
    return getStateOfPublishOrRevertSuccess(state, entityId);
  };

  const handleRevertSuccess = (state: EntityState, action: Action): EntityState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;

    const entityId = payload.id as number;
    return getStateOfPublishOrRevertSuccess(state, entityId);
  };

  const getStateOfPublishOrRevertSuccess = (state: EntityState, entityId: number): EntityState => {
    if (!state.changingEntityState) {
      return { ...state };
    }
    return {
      ...state,
      lastModifiedDateTime: dateTimeUtils.getEmptyDate(),
      changingEntityState: false,
      isPublished: true,
      entityId,
    };
  };

  const handleUpdated = (state: EntityState, action: Action): EntityState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;

    //entityId can be string or number
    // eslint-disable-next-line eqeqeq
    if (payload.entityId == state.entityId) {
      return {
        ...state,
        lastModifiedDateTime: dateTimeUtils.getCurrentDate().toISOString(),
      };
    }
    return state;
  };

  const handleFailure = (state: EntityState, action: Action): EntityState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;
    return {
      ...state,
      changingEntityState: false,
      error: payload.error,
    };
  };

  const handleReset = (): EntityState => ({ ...initialState });

  const handleUpdateBegin = (state: EntityState): EntityState => ({
    ...state,
    isEntityCommandInProgress: true,
  });
  const handleResetUpdate = (state: EntityState): EntityState => ({
    ...state,
    isEntityCommandInProgress: false,
  });

  const handleUpdateSuccess = (state: EntityState, action: Action): EntityState => {
    const payload = (action as PayloadAction<MidnightActionPayload>).payload;

    //entityId can be string or number
    // eslint-disable-next-line eqeqeq
    if (payload.entityId == state.entityId) {
      return {
        ...state,
        isEntityCommandInProgress: false,
      };
    }
    return state;
  };

  const handleSetEntityId = (state: EntityState, action: Action): EntityState => {
    return {
      ...state,
      entityId: (action as PayloadAction<number>).payload,
    };
  };

  const handleOpenEnityDetails = (state: EntityState, action: Action): EntityState => {
    const payload = (action as PayloadAction<EntityDetailsPayload>).payload;
    return {
      ...state,
      entityId: payload.id,
      isPublished: !payload.isDraft,
      lastModifiedDateTime: payload.dateModified,
    };
  };

  return {
    [`${prefix}${actionTypes.FETCH_LOCKED_ENTITY_BEGIN}`]: handleBeginAction,
    [`${prefix}${actionTypes.PUBLISH_LOCKED_ENTITY_BEGIN}`]: handleBeginAction,
    [`${prefix}${actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN}`]: handleBeginAction,
    [`${prefix}${actionTypes.CREATE_DRAFT_ENTITY_BEGIN}`]: handleBeginAction,

    [`${prefix}${actionTypes.USE_EXISTING_LOCKED_ENTITY}`]: handleSuccess,
    [`${prefix}${actionTypes.FETCH_LOCKED_ENTITY_SUCCESS}`]: handleCreateDraftSuccess,
    [`${prefix}${actionTypes.CREATE_DRAFT_ENTITY_SUCCESS}`]: handleCreateDraftSuccess,

    [`${prefix}${actionTypes.PUBLISH_LOCKED_ENTITY_SUCCESS}`]: handlePublishSuccess,
    [`${prefix}${actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS}`]: handleRevertSuccess,

    [`${prefix}${actionTypes.FETCH_LOCKED_ENTITY_FAILURE}`]: handleFailure,
    [`${prefix}${actionTypes.PUBLISH_LOCKED_ENTITY_FAILURE}`]: handleFailure,
    [`${prefix}${actionTypes.REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE}`]: handleFailure,
    [`${prefix}${actionTypes.CREATE_DRAFT_ENTITY_FAILURE}`]: handleFailure,

    [`${prefix}${actionTypes.LOCKED_ENTITY_UPDATED}`]: handleUpdated,
    [`${prefix}${actionTypes.RESET_ENTITY_STATE}`]: handleReset,
    [`${prefix}${actionTypes.UPDATE_LOCKED_ENTITY_COMMAND_BEGIN}`]: handleUpdateBegin,
    [`${prefix}${actionTypes.UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS}`]: handleUpdateSuccess,
    [`${prefix}${actionTypes.RESET_UPDATE_LOCKED_ENTITY_COMMAND_STATE}`]: handleResetUpdate,

    [`${prefix}${actionTypes.SET_ENTITY_ID}`]: handleSetEntityId,
    [`${prefix}${actionTypes.SET_DATA_FOR_DETAILS_MODE}`]: handleOpenEnityDetails,
  };
};

export const withEntityState = (namespace: string, entityPrefix: string): Reducer<EntityState> => {
  return function entityState(currentState: EntityState | undefined, action: Action) {
    const state = currentState || initialState;
    const prefix = `${namespace}__${entityPrefix}__`;

    const handler = getHandlers(prefix)[action.type];
    return handler?.(state, action) || state;
  };
};
