import * as React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import cn from "classnames";

import Modal, { Props as ModalProps } from "./Modal";

import "./modalWithSteps.scss";

export interface ModalWithStepsStep {
  header?: string;
  preRender?: boolean;
  renderModalActions: (nextStep: () => void, prevStep: () => void) => (closeModal: () => void) => React.ReactElement;
}

export interface ModalWithStepsProps extends Omit<ModalProps, "children"> {
  children: any; // first try was: React.ReactElement<ModalWithStepsStep>[] | React.ReactElement<ModalWithStepsStep>;
  // not working with conditional rendering like: {condition && (<Step/>)}
  showModal?: boolean;
  isLoading?: boolean;
  onNextStep?: (activeStep: number) => void;
  onPrevStep?: (activeStep: number) => void;
}

interface ModalWithStepsState {
  activeStep: number;
}

class ModalWithSteps extends React.Component<ModalWithStepsProps, ModalWithStepsState> {
  state = { activeStep: 1 };

  componentDidUpdate(prevProps: ModalWithStepsProps) {
    if (this.props.showModal !== prevProps.showModal && !this.props.showModal) {
      this.setState({ activeStep: 1 });
    }
  }

  nextStep = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
    this.props.onNextStep?.(activeStep);
  };

  prevStep = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep - 1 });
    this.props.onPrevStep?.(activeStep);
  };

  render() {
    const {
      renderTrigger,
      size,
      className,
      scrolling,
      isLoading,
      onCancel,
      children,
      showModal,
      onBeforeClose,
      alert,
    } = this.props;

    const { activeStep } = this.state;

    let currentStep = React.Children.toArray(children)[
      activeStep - 1
    ] as unknown as React.Component<ModalWithStepsStep>;

    const prerenderedSteps = Array.isArray(children)
      ? children.filter((c) => (c as React.ReactElement<ModalWithStepsStep>).props?.preRender)
      : [];

    return (
      <Modal
        renderTrigger={renderTrigger}
        open={showModal}
        className={cn(className, "modal-with-steps")}
        title={currentStep.props.header}
        scrolling={scrolling}
        renderModalActions={currentStep.props.renderModalActions(this.nextStep, this.prevStep)}
        onCancel={onCancel}
        onBeforeClose={onBeforeClose}
        size={size}
        alert={alert}
      >
        <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
          <>
            <Dimmer active={isLoading} inverted>
              <Loader>Loading</Loader>
            </Dimmer>
            {!currentStep.props.preRender && currentStep}
            {prerenderedSteps.map((step, index: number) => (
              <div
                key={index}
                className={cn({
                  invisible: (step as React.ReactElement<ModalWithStepsStep>).props.header !== currentStep.props.header,
                })}
              >
                {step}
              </div>
            ))}
          </>
        </Dimmer.Dimmable>
      </Modal>
    );
  }
}

export default ModalWithSteps;
