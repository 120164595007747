import { useEffect } from "react";
import ReactDOM from "react-dom";
import { connect, ConnectedProps } from "react-redux";
import StatusCode from "../../enums/httpStatusCodes";
import { AppDispatch, RootState } from "../../features/Application/globaltypes/redux";
import DataService from "../../features/Application/services/dataServices/typedDataService";
import { reset, setError } from "../../features/Application/slices/appErrorSlice";
import { ErrorViewProvider } from "../ErrorViewProvider/ErrorViewProvider";
import { useLocation } from "react-router-dom";

export interface Props extends PropsFromRedux {}

export const AppErrorHandler = (props: Props) => {
  const { reset, errorCode, isErrorState, container, setError } = props;

  const location = useLocation();

  useEffect(() => {
    return DataService.base.registerNotFoundInterceptor(() => setError(404));
  }, [setError]);

  useEffect(() => {
    reset();
  }, [location, reset]);

  const containerNode = document.getElementById(container);

  return containerNode
    ? ReactDOM.createPortal(<ErrorViewProvider shouldRender={isErrorState} errorCode={errorCode} />, containerNode)
    : null;
};

const mapStateToProps = (state: RootState) => ({
  isErrorState: state.appError.isErrorState,
  errorCode: state.appError.errorCode,
  container: state.appError.container,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  reset: () => dispatch(reset()),
  setError: (errorCode: StatusCode) => dispatch(setError({ errorCode: errorCode })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connect(mapStateToProps, mapDispatchToProps)(AppErrorHandler);
