import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../../Application/globaltypes/redux";
import { PackResourceItem } from "../../types/state";

export interface PackFiltersState {
  items: { [packFilterTypeName: string]: PackResourceItem[] };
  isLoading: boolean;
  error: string | undefined;
}

export const initialState: PackFiltersState = {
  items: {},
  isLoading: false,
  error: undefined,
};

const packFiltersSlice = createSlice({
  name: "packFilters",
  initialState: initialState,
  reducers: {
    req(state: PackFiltersState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: PackFiltersState, action: PayloadAction<{ [packFilterTypeName: string]: PackResourceItem[] }>) {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    },
    err(state: PackFiltersState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = packFiltersSlice.actions;

export const packFiltersSelector = (state: RootState) => state.packs.packFilters.items;

export default packFiltersSlice.reducer;
export type packFiltersStateSliceType = ReturnType<typeof packFiltersSlice.reducer>;
