import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { RootState } from "../../../../Application/globaltypes/redux";
import { createSearchSlice, SearchState } from "../../../../Application/slices/createSearchSlice";

export const initialState: SearchState = {
  term: undefined,
};

const accontPacksSearchSlice = createSearchSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AccountPacks,
    name: "search",
  },
  initialState: initialState,
  reducers: {},
});

export const setAccountPacksSearchTerm = accontPacksSearchSlice.actions.setTerm;

export const accontPacksSearchSelector = (state: RootState) => state.packs.accountPacksSearch;

export default accontPacksSearchSlice.reducer;
