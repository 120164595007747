import integrationsDataService from "../../services/integrationsDataService";
import { IntegrationTypes, MsGraphIntegrationConfigs } from "../types";
import IConnector from "./IConnector";
import { open } from "./consentWindowService";

export const login = async (importUsageData: boolean, syncUsers: boolean, importLicenses: boolean, importGroups: boolean) => {
  const { redirectLocation } = await integrationsDataService.authorizeMsGraphIntegration(
    syncUsers,
    importUsageData,
    importLicenses,
    importGroups,
  );
  const redirect = `${window.location.origin}/ms-consent`;
  return open({
    location: redirectLocation + `&redirect_uri=${redirect}`,
    title: "Microsoft Graph Sign In",
    parseResult: (params) => {
      const tenantId = params.get("tenant") as string;
      return {
        isSuccess: !!tenantId,
        error: params.get("error"),
        result: tenantId,
        redirect,
      };
    },
  });
};

export class MsGraphConnector implements IConnector<MsGraphIntegrationConfigs> {
  private _tenantId: string = "";
  private _isConnected: boolean;

  constructor(isConnected: boolean) {
    this._isConnected = isConnected;
  }

  public get info() {
    return {
      id: IntegrationTypes.MsGraph,
      connectorTitle: "Microsoft Graph",
      type: IntegrationTypes.MsGraph,
      description: "Microsoft Graph",
      thumbnailUrl: "/connectors/graph.png",
      isConnected: this._isConnected,
    };
  }

  public get configuration() {
    return {
      licensedOnly: true,
      isActive: false,
      syncUsers: true,
      importUsageData: true,
      importLicenses: true,
      importGroups: true,
      userNotificationId: "",
    };
  }

  public shouldLogin = () => true;

  public shouldNotify = () => true;

  public login = async (config: MsGraphIntegrationConfigs) => {
    const { importUsageData, syncUsers, importLicenses, importGroups } = config;
    const authResult = await login(importUsageData, syncUsers, importLicenses, importGroups);
    this._tenantId = authResult.result;
    return authResult;
  };

  public save = async (config: MsGraphIntegrationConfigs, moboId?: string) => {
    await integrationsDataService.createMsGraphIntegration({ ...config, tenantId: this._tenantId }, moboId);
  };
}
