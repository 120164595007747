import { AbortController } from "@azure/abort-controller";

import TaskActions from "../../../../enums/TaskActions";
import DataService from "../../../Application/services/dataServices/typedDataService";
import backgroundTasksEventsEmitter from "../../../BackgroundTasks/events/backgroundTasksEventsEmitter";
import pdfTasks from "../../../BackgroundTasks/pdfTasks";

import { FileLike } from "../../../../interfaces";
import { AcceptCancelationHandler, OnUploadProgressHandler } from "../../../../utils/fileUtils";
import { PdfTask } from "../../../BackgroundTasks/taskPool";
import { AllocatedResource, SasToken } from "../types/models";
import { CreatePdfRequest, UpdatePdfFileRequest } from "../types/requests";

export interface PdfUploaderServiceParams {
  canCancel: boolean;
  createPdf: (data: CreatePdfRequest) => Promise<void>;
  updatePdfFile: (data: UpdatePdfFileRequest) => Promise<void>;
  uploadPdf: (
    url: string,
    file: File,
    onUploadProgressHandler: OnUploadProgressHandler,
    acceptCancellationHandler: AcceptCancelationHandler,
  ) => Promise<void>;
  uploadPdfCancel: () => void;
  idProvider: React.MutableRefObject<number>;
  onTaskIdChanged: (taskId: number) => void;
  tasksActions: {
    addTask: (task: PdfTask) => void;
    updateTask: (id: number, task: PdfTask) => void;
    deleteTask: (id: number | string) => void;
    updateProgress: (id: number, percent: number, indeterminate: boolean) => void;
    updateId: (oldId: number | string, newId: number) => void;
  };
}

const pdfUploaderService = (params: PdfUploaderServiceParams) => {
  let aborter: AbortController;

  const uploadFileHandler = async (oldTaskId: string, file: FileLike) => {
    const pdfExists = params.idProvider.current > 0;

    const getPdfResource = async (): Promise<AllocatedResource> => {
      const id = params.idProvider.current;
      return pdfExists
        ? {
            sasToken: (await DataService.pdfs.generateSasAsync(id)).data,
            id,
          }
        : (await DataService.pdfs.allocateResourcesAsync()).data;
    };

    const uploadFile = async (taskId: number, sasToken: SasToken) => {
      const onCancel = params.canCancel ? () => params.tasksActions.deleteTask(taskId) : undefined;
      params.tasksActions.updateTask(taskId, pdfTasks.getStarted(taskId, file.name, onCancel));

      const onUploadFileProgress = (progressEvent: { loaded: number; total: number }) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        params.tasksActions.updateProgress(taskId, percentCompleted, false);
      };

      const acceptUploadAborter = (aborterHandler: AbortController) => {
        aborter = aborterHandler;
        backgroundTasksEventsEmitter.updateHandlerOnce(TaskActions.cancel, taskId, () => {
          aborterHandler?.abort();
          params.tasksActions.deleteTask(taskId);
        });
      };

      await params.uploadPdf(sasToken.uri, file as File, onUploadFileProgress, acceptUploadAborter);
      params.tasksActions.updateTask(taskId, pdfTasks.getUploadedSuccessfuly(taskId));
    };

    const { id, sasToken } = await getPdfResource();
    params.tasksActions.updateId(oldTaskId, id);
    params.onTaskIdChanged(id);

    try {
      await uploadFile(id, sasToken);

      if (pdfExists) {
        await params.updatePdfFile({
          id,
          fileKey: sasToken.fileName,
          fileName: file.name,
        });
      } else {
        await params.createPdf({
          id,
          pdfDocumentPath: sasToken.fileName,
          fileName: file.name,
        });
      }
    } catch (error) {
      const task =
        error.message === "The request was aborted"
          ? pdfTasks.getCanceled(id, params.tasksActions.deleteTask)
          : pdfTasks.getFailed(id, params.tasksActions.deleteTask);

      params.tasksActions.updateTask(id, task);
    }
  };

  const uploadFilesHandler = async (files: FileLike[]) =>
    Promise.all(
      Array.from(files).map((file: FileLike) => {
        params.tasksActions.addTask(pdfTasks.getStarted(file.name, file.name));
        return uploadFileHandler(file.name, file);
      }),
    );

  const onCancelFileUploading = () => {
    aborter?.signal.addEventListener("abort", params.uploadPdfCancel);
    aborter?.abort();
  };

  return {
    uploadFilesHandler,
    onCancelFileUploading,
  };
};

export default pdfUploaderService;
