import { QuestionRendererPropsBase } from "../../../../Common/props";
import { QuestionsMap } from "./Providers/QuestionsMap";
import { ISurveyQuestion } from "./types";
import { SurveyQuestionType } from "../../../types/models";

export type SurveyQuestionRendererProps<T> = QuestionRendererPropsBase<ISurveyQuestion<T>, T>;

export const SurveyQuestionRenderer = <T,>(props: SurveyQuestionRendererProps<T>) => {
  const { question, validatedFieldProps, index, onBlur, onChanged, disabled, propertyNamePrefix } = props;
  const { Component } = QuestionsMap[question.questionType as SurveyQuestionType];
  return (
    <Component
      disabled={disabled}
      index={index}
      onBlur={onBlur}
      onChanged={onChanged}
      question={question.question as any}
      validatedFieldProps={validatedFieldProps}
      propertyNamePrefix={propertyNamePrefix}
    />
  );
};
