import moment from "moment";
import { PeriodType } from "../../../types/state";
import { WeekDays } from "../../../../../../components/weekDayPicker";
import { isEmpty } from "lodash";
import { defaultWeekDayOrder } from "../../../../../../components/weekDayPicker/WeekDayPicker";
import { weekLength } from "../../../../../../utils/dateTimeUtils";

interface DescriptionBuilder {
  startingAt: (time?: string | null) => DescriptionBuilder;
  endingAt: (time?: string | null) => DescriptionBuilder;
  usingTimeZone: (timeZone?: string | null) => DescriptionBuilder;
  endingOn: (date?: string | null) => DescriptionBuilder;
  usingPeriod: (type: PeriodType) => DescriptionBuilder;
  occursOn: (option?: string | null) => DescriptionBuilder;
  withRepetition: (number: number) => DescriptionBuilder;
  onWeekDays: (weekDays?: WeekDays[] | null) => DescriptionBuilder;
  getDescription: () => string;
}

const repetitionOptions = [
  { value: 1, humanized: "" },
  { value: 2, humanized: "other" },
  { value: 3, humanized: "third" },
  { value: 4, humanized: "fourth" },
  { value: 5, humanized: "fifth" },
];

class RecurrenceDescriptionBuilder implements DescriptionBuilder {
  concreteDate: string;
  periodType: PeriodType;
  repetitionNumber: number;
  repetition: string;
  startsAt: string;
  endsAt: string;
  endsOn: string;
  timeZone: string;
  weekDays: WeekDays[];
  weekDaysOrder: WeekDays[] = defaultWeekDayOrder;

  constructor() {
    const { value: repetitionNumber, humanized: repetition } = repetitionOptions[0];

    this.concreteDate = "";
    this.startsAt = "";
    this.endsAt = "";
    this.endsOn = "";
    this.timeZone = "";
    this.periodType = PeriodType.Day;
    this.repetitionNumber = repetitionNumber;
    this.repetition = repetition;
    this.weekDays = [];
  }

  startingAt(time?: string | null): DescriptionBuilder {
    if (time) {
      this.startsAt = `from ${time}`;
    }
    return this;
  }

  endingAt(time?: string | null): DescriptionBuilder {
    if (time) {
      this.endsAt = `- ${time}`;
    }
    return this;
  }

  usingTimeZone(timeZone?: string | null): DescriptionBuilder {
    if (timeZone) {
      this.timeZone = `(${timeZone})`;
    }
    return this;
  }

  endingOn(date?: string | null): DescriptionBuilder {
    const endDate = moment.utc(date, "MM/DD/yyyy");
    this.endsOn = endDate.isValid() ? `until ${endDate.format("MMM DD, yyyy")}` : "";
    return this;
  }

  usingPeriod(periodType: PeriodType): DescriptionBuilder {
    this.periodType = periodType;
    return this;
  }

  occursOn(option?: string | null): DescriptionBuilder {
    if (option) {
      this.concreteDate = option.charAt(0).toLowerCase() + option.slice(1);
    }
    return this;
  }

  withRepetition(number: number): DescriptionBuilder {
    const option = repetitionOptions.find((x) => x.value === number);
    if (option) {
      this.repetition = option.humanized;
    }
    return this;
  }

  onWeekDays(weekDays?: WeekDays[] | null): DescriptionBuilder {
    if (weekDays) {
      this.weekDays = weekDays;
    }
    return this;
  }

  getDescription(): string {
    return this.parts.filter((x) => !isEmpty(x)).join(" ");
  }

  private get parts(): string[] {
    const { periodPattern, startsAt, endsAt, timeZone, endsOn } = this;

    let parts: string[] = ["Occurs"];
    parts = parts.concat(periodPattern);
    parts.push(startsAt);
    parts.push(endsAt);
    parts.push(timeZone);
    parts.push(endsOn);

    return parts;
  }

  private get periodPattern(): string[] {
    const { concreteDate, repetition, weekDayString } = this;
    switch (this.periodType) {
      case PeriodType.Day:
        return ["every", repetition, "day"];
      case PeriodType.Week:
        if (weekDayString) {
          return ["every", repetition, weekDayString];
        }
        break;
      case PeriodType.Month:
        return [concreteDate, "of every", repetition, "month"];
      case PeriodType.Year:
        return ["every", repetition, "year", concreteDate];
    }
    return [];
  }

  private get weekDayString(): string {
    const { weekDays, weekDaysOrder: order } = this;
    if (isEmpty(weekDays)) {
      return "";
    }

    if (weekDays.length === 1) {
      return weekDays[0];
    }

    if (weekDays.length === weekLength) {
      return "day";
    }

    weekDays.sort((left, right) => order.indexOf(left) - order.indexOf(right));

    const parts = weekDays.slice(0, weekDays.length - 1);
    const comma = parts.length > 1 ? "," : "";
    return `${parts.join(", ")}${comma} and ${weekDays.slice(-1)}`;
  }
}

const provider = {
  createDescriptionBuilder(): DescriptionBuilder {
    return new RecurrenceDescriptionBuilder();
  },
};

export default provider;
