import { Dispatch } from "redux";
import * as addUserActionTypes from "./addUserActionTypes";
import * as userOverviewActionTypes from "../../UsersOverview/state/usersOverviewActionTypes";
import dataService from "../../../../Application/services/dataServices/dataService";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import { sendTransientNotification } from "../../../../Notifications/state/notificationsActions";
import { TemplateTypes } from "../../../../../enums";
import AlertTypes from "../../../../../enums/alertTypes";
import { NotifyStepSettings } from "../../../../../interfaces";
import { fetchAccountRoles } from "../../../state/peopleActions";
import { UserInfo } from "../../../types";

const rolesReset = makeActionCreator(addUserActionTypes.FETCH_ROLES.RESET_ITEMS);

const permissionReset = makeActionCreator(addUserActionTypes.FETCH_PERMISSIONS.RESET_ITEMS);

const userCancel = makeActionCreator(addUserActionTypes.ADD_USER_CANCEL);

export const fetchRoles = (
  skip: number,
  top: number,
  orderProp: string,
  orderDirection: string,
  filter: any,
  searchText?: string,
) =>
  fetchAccountRoles(
    { skip, top, orderProp, orderDirection, filter, searchText },
    {
      begin: makeActionCreator(addUserActionTypes.FETCH_ROLES.FETCH_ITEMS_BEGIN),
      success: makeActionCreator(addUserActionTypes.FETCH_ROLES.FETCH_ITEMS_SUCCESS, "items", "itemsCount"),
      failure: makeActionCreator(addUserActionTypes.FETCH_ROLES.FETCH_ITEMS_FAILURE, "error"),
    },
  );

export const userInfoSave = (userInfo: UserInfo) => {
  const userInfoSaveBeginAction = makeActionCreator(addUserActionTypes.USER_INFO_SAVE_BEGIN, "userInfo");

  return (dispatch: Dispatch) => {
    dispatch(userInfoSaveBeginAction(userInfo));
    userInfo.id ? dataService.updateUser(userInfo) : dataService.createUser(userInfo);
  };
};

export const onUserInfoSaveFailure = () => {
  const userInfoSaveFailureAction = makeActionCreator(addUserActionTypes.USER_INFO_SAVE_FAILURE, "error");

  return (dispatch: Dispatch) => {
    dispatch(userInfoSaveFailureAction({ message: "Timeout error" }));
  };
};

export const onUserInfoSaveSuccess = (userId: number) => {
  const userInfoSaveSuccessAction = makeActionCreator(addUserActionTypes.USER_INFO_SAVE_SUCCESS, "id");

  return (dispatch: Dispatch) => {
    dispatch(userInfoSaveSuccessAction(userId));
  };
};

export const updateRolesAssignment = (roleIds: number[], userId?: number | null) => {
  const updateRoleAssignmentAction = makeActionCreator(addUserActionTypes.UPDATE_ROLE_ASSIGNMENT, "roleIds");

  return (dispatch: Dispatch) => {
    dispatch(updateRoleAssignmentAction(roleIds));
    dataService.updateRoleAssignment(userId, roleIds);
  };
};

export const finishUserCreation = () => {
  const finishUserCreationAction = makeActionCreator(addUserActionTypes.FINISH_USER_CREATION);
  const userCreatedAction = makeActionCreator(userOverviewActionTypes.PEOPLE_USERS_OVERVIEW_USER_CREATED);
  return (dispatch: Dispatch) => {
    dispatch(finishUserCreationAction());
    dispatch(permissionReset());
    dispatch(rolesReset());
    dispatch(userCreatedAction());
    dispatch(
      sendTransientNotification({
        type: AlertTypes.success,
        message: "User has been added!",
      }),
    );
  };
};

export const notifyUser = (userId: number, notificationSettings: NotifyStepSettings) => {
  const notifyUserAction = makeActionCreator(addUserActionTypes.NOTIFY_USER);

  return (dispatch: Dispatch) => {
    dispatch(notifyUserAction());
    dataService.notifyUser(userId, TemplateTypes.WelcomeEmail, notificationSettings);
  };
};

export const changeRoles = (selectedRoles: number[]) => (dispatch: Dispatch) =>
  dispatch({
    type: addUserActionTypes.USER_INFO_ROLES_CHANGED,
    payload: { selectedRoles },
  });

export const cancel = () => (dispatch: Dispatch) => {
  dispatch(userCancel());
  dispatch(rolesReset());
  dispatch(permissionReset());
};

export const resetLicensesItems = () => {
  const resetLicensesItemsAction = makeActionCreator(addUserActionTypes.RESET_LICENSES_ITEMS);

  return (dispatch: Dispatch) => {
    dispatch(resetLicensesItemsAction());
  };
};
