import { Link } from "../../../../components/ExpandableItems/types";
import { FileLike, PackName } from "../../../../interfaces";
import { FlowBase } from "../../../../interfaces/flowInfo";
import { RequestState } from "../../../Application/globaltypes/fetchRequest";

export interface PdfOverviewBaseDto {
  id: number;
  title: string;
  isDraft: boolean;
  ownerId: number;
  publisher: string;
  isPurchased: boolean;
  hasBeenPublished: boolean;
  canBePublished: boolean;
  canBeDeleted: boolean;
  dateCreated: string;
  dateModified: string;
  isLoading?: boolean;
  dependencies?: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
  };
}

export interface PdfOverviewDto extends PdfOverviewBaseDto {
  thumbnailUrl: string;
}

export interface PdfOverviewDtoV4 extends PdfOverviewBaseDto {
  bag: {
    thumbnailUrl: string;
  };
}

export interface PdfOverview {
  id: number;
  title: string;
  thumbnailUrl: string;
  canBePublished: boolean;
  hasBeenPublished: boolean;
  description: string;
  isDraft: boolean;
  isPurchased: boolean;
  ownerId: number;
  publisher: string;
  dateCreated: string;
  dateModified: string;
  isLoading?: boolean;
  canBeDeleted: boolean;
  dependencies?: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
  };
}

export interface PdfDetailsState {
  pdf: PdfView;
  error?: Error;
  isLoading: boolean;
  isLoaded: boolean;
  isUploading: boolean;
  isUploaded: boolean;
  uploadedPdfs: FileLike[];
}

export interface PdfView {
  id: number;
  title: string;
  description: string;
  hasBeenPublished: boolean;
  fileName: string;
  fileUrl: string;
  thumbnailUrl: string;
  isDraft: boolean;
  isPurchased: boolean;
  dateModified?: string;
  dependencies?: {
    assignmentsCount: number;
    flowsCount: number;
    packsCount: number;
  };
  settings: SettingsView;
}

export interface Pdf {
  id: number;
  title: string;
  description: string;
  hasBeenPublished: boolean;
  fileName: string;
  fileUrl: string;
  thumbnailUrl?: string;
  isDraft: boolean;
  isPurchased: boolean;
  dateModified?: string;
  labels: string[];
  softwareApplications: string[];
  packs: PackName[];
  flows: FlowBase[];
}

export interface SettingsView {
  packs: Link[];
  flows: Link[];
  labels: string[];
  softwareApplications: string[];
}

export interface PdfTags {
  labels: string[];
  softwareApplications: string[];
}

export interface PublishedPdfOverview {
  id: number;
  title: string;
  thumbnailUrl?: string;
  publisherId: number;
  publisher: string;
}

export interface PdfPerformanceState {
  engagementLineData: RequestState<EngagementLineData[]>;
  engagementHorizontalData: RequestState<EngagementHorizontalData>;
  engagementPeopleData: RequestState<EngagementPeopleData>;
}

export type EngagementLineData = {
  Date: string;
  Events: {
    NotStartedCount: number;
    CompletedCount: number;
    CumulativeCompletedCount: number;
    CumulativeNotStartedCount: number;
  };
};

export type EngagementHorizontalData = {
  NotStartedCount: number;
  CompletedCount: number;
};

export enum EngagementStatus {
  Completed = "Completed",
  NotStarted = "Not Started",
}

export interface EngagementPeople {
  DateWatched: string;
  Status: EngagementStatus;
  UserDepartment: string;
  UserEmail: string;
  UserFirstName: string;
  UserId: number;
  UserLastName: string;
}

export type EngagementPeopleData = EngagementPeople[];
