import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faRepeat, faShuffle, faChartLineUp, faBullseyeArrow } from "@fortawesome/pro-solid-svg-icons";

import "./noResults.scss";

function NoResults(props) {
  const extractStyles = (key) => {
    return props.customStyles && props.customStyles[key];
  };

  const renderIcon = () => {
    const { icon, iconClassName, fontAwesomeIcon, outline = false } = props;

    const icons = {
      faEnvelope,
      faRepeat,
      faChartLineUp,
      faShuffle,
      faBullseyeArrow,
    };

    if (fontAwesomeIcon) {
      return <FontAwesomeIcon className={cn("fas no-results-icon", { outline })} icon={icons[fontAwesomeIcon]} />;
    } else if (iconClassName) {
      return <Icon className={cn("fas no-results-icon", { outline }, iconClassName)} />;
    } else if (icon) {
      return <div className="custom-icon-container">{icon}</div>;
    }
    return null;
  };

  const renderTitle = () => {
    const { title } = props;

    if (title) {
      return <span className="no-results-title">{title}</span>;
    }
    return null;
  };

  const renderDescription = () => {
    const { description } = props;

    if (description) {
      return (
        <span
          className={cn("no-results-description", {
            markup: typeof description !== "string",
          })}
          style={extractStyles("description")}
        >
          {description}
        </span>
      );
    }
    return null;
  };

  return (
    <div className={cn("no-results", extractStyles("root"))} data-testid={props.dataId ?? "no-results"}>
      {renderIcon()}
      {renderTitle()}
      {renderDescription()}
      {props.children}
    </div>
  );
}

NoResults.propTypes = {
  icon: PropTypes.any,
  iconClassName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  children: PropTypes.any,
  customStyles: PropTypes.object,
  outline: PropTypes.bool,
  dataId: PropTypes.string,
  fontAwesomeIcon: PropTypes.string,
};

export default NoResults;
