import { DefaultSwitchProps } from "../../components/notifyStep/NotifyStepContent/switches/DefaultSwitch";
import {
  SwitchWithProgress,
  SwitchWithProgressProps,
} from "../../components/notifyStep/NotifyStepContent/switches/SwitchWithProgress";
import { initialNotifyConfigDefault, initialNotifyConfigWithProgress } from "./config";
import { NotifyStepConfig, ShouldNotifyWithProgress, ShouldNotifyWithProgressIds } from "./types";
import { NotifyStepSwitch } from "../../components/notifyStep/NotifyStepSwitch";

export const getNotifyConfig = (type: NotifyStepConfig) => {
  switch (type) {
    case NotifyStepConfig.Default:
      return {
        config: initialNotifyConfigDefault,
        NotifyConfigSwitch: (switchProps: DefaultSwitchProps) => (
          <NotifyStepSwitch
            switchProps={switchProps}
            onNotifyConfigChange={switchProps.onNotifyConfigChange}
            config={initialNotifyConfigDefault}
          />
        ),
      };
    case NotifyStepConfig.WithProgress:
      return {
        config: initialNotifyConfigWithProgress,
        NotifyConfigSwitch: (switchProps: SwitchWithProgressProps) => <SwitchWithProgress {...switchProps} />,
      };
  }
};

export const getNotifyTypesWithProgress = (config: ShouldNotifyWithProgress) => {
  const shouldNotifySet = [
    { id: ShouldNotifyWithProgressIds.ShouldNotifyNotStarted, shouldNotify: config.shouldNotifyNotStarted },
    { id: ShouldNotifyWithProgressIds.ShouldNotifyInProgress, shouldNotify: config.shouldNotifyInProgress },
    { id: ShouldNotifyWithProgressIds.ShouldNotifyCompleted, shouldNotify: config.shouldNotifyCompleted },
  ];
  const activeShouldNotifySet = shouldNotifySet.filter(({ shouldNotify }) => shouldNotify);

  return shouldNotifySet.length === activeShouldNotifySet.length
    ? [ShouldNotifyWithProgressIds.All]
    : activeShouldNotifySet.map(({ id }) => id);
};
