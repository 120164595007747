import { RootState } from "../../../../Application/globaltypes/redux";
import { connect } from "react-redux";
import SendEmailConfirmationModal from "../../../../../components/modal/emailModals/SendEmailConfirmationModal";

const mapStateToProps = (state: RootState) => ({
  emailSubject: state.library.emails.emailDetailsReducer.email.subject,
  publishInfo: state.library.emails.emailPublishInfoReducer.publishInfo,
  isLoading: state.library.emails.emailPublishInfoReducer.isLoading,
});

export default connect(mapStateToProps)(SendEmailConfirmationModal);
