import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { setSearch, setSelectedIds } from "../state/TemplateCustomersSlice";
import { bindAction } from "../../../../interfaces";
import { getCustomers } from "../state/thunks/TemplateCustomersThunk";
import { GroupTemplateCustomers } from "../Details/GroupTemplateCustomers";
import { SortingDirection } from "../../../../enums";
import { Button } from "components/buttons/button/Button";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import AddCustomersModal from "./Customers/AddCustomersModal";
import { Columns, editColumnOptions } from "../Details/Customers/getColumnOptions";
import { Observable } from "utils";
import EditCustomerPacksModal from "./Customers/EditCustomerPacksModal";

type Props = PropsFromRedux & {
  templateId: number;
  handleRemoveClick: (ids: number[]) => void;
};

export type CustomersRef = {
  renderAddCustomersButton: () => void;
};

export const EditTemplateCustomers = forwardRef<CustomersRef, Props>((props, ref) => {
  const {
    items,
    isLoading,
    itemsCount,
    getData,
    onSearchChange,
    templateId,
    handleRemoveClick,
    setSelectedIds,
    selectedIds,
  } = props;
  const [customersModalShown, setCustomersModalShown] = useState(false);
  const onTriggerEditLicensingObserver = useMemo(() => new Observable(), []);

  const loadCustomers = (
    skip: number = 0,
    top: number = 10,
    sortingColumnName: string = Columns.Added.toLowerCase(),
    sortingDirection: SortingDirection = SortingDirection.Descending,
  ) => {
    getData(templateId, skip, top, sortingColumnName, sortingDirection);
  };

  useImperativeHandle(ref, () => ({
    renderAddCustomersButton: () => (
      <Button
        primary
        content={"Add Customers"}
        className="add-customers-button create-button"
        onClick={() => setCustomersModalShown(true)}
      />
    ),
  }));

  const handleEditLicensingClick = (customerId: number) => {
    onTriggerEditLicensingObserver.notify(templateId, customerId);
  };

  return (
    <>
      <GroupTemplateCustomers
        getData={loadCustomers}
        items={items}
        isLoading={isLoading}
        itemsCount={itemsCount}
        setSelectedIds={setSelectedIds}
        onSearchChange={onSearchChange}
        columnOptions={editColumnOptions({
          onEditLicensingClick: (id) => handleEditLicensingClick(id),
          onRemoveClick: (id) => handleRemoveClick([id]),
        })}
        selectedIds={selectedIds}
      />
      <AddCustomersModal
        showModal={customersModalShown}
        setShowModal={setCustomersModalShown}
        templateId={templateId}
      />
      <EditCustomerPacksModal onTriggerModalObserver={onTriggerEditLicensingObserver} />
    </>
  );
});

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    items: state.people.groupTemplate.customers.items,
    itemsCount: state.people.groupTemplate.customers.itemsCount,
    isLoading: state.people.groupTemplate.customers.isLoading,
    selectedIds: state.people.groupTemplate.customers.selectedIds,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getData: bindAction(getCustomers, dispatch),
    setSelectedIds: bindAction(setSelectedIds, dispatch),
    onSearchChange: (search: string) => dispatch(setSearch(search)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditTemplateCustomers);
