import { useState, useEffect } from "react";
import cn from "classnames";
import ValidatedField, { ValidatedFieldProps } from "./ValidatedField";
import { MultiSelect } from "../multiSelect";
import labelsValidationUtils from "../../utils/labelsValidationUtils";
import miscUtils from "../../utils/miscellaneousUtils";
import styles from "./multiSelectField.module.scss";

export interface MultiSelectFieldOption {
  text: string;
  value: number;
  disabled?: boolean;
}

export interface MultiSelectFieldProps extends Omit<ValidatedFieldProps, "value"> {
  value: any[];
  formFieldName: string;
  options: MultiSelectFieldOption[];
  shouldValidate: boolean;
  validator: any;
  disabled: boolean;
  className: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  onBlur?: (propertyName: string) => void;
  onChanged?: () => void;
  onItemRemove?: (propertyName: string) => void;
  maxSelections?: number;
  resetOptionsCallback?: () => void;
}

export const MultiSelectField: React.FC<MultiSelectFieldProps> = (props) => {
  const {
    formFieldName,
    placeholder,
    propertyName,
    value,
    options,
    setFieldValue,
    shouldValidate,
    validator,
    setFieldTouched,
    onBlur,
    onChanged,
    handleBlur,
    disabled,
    resetOptionsCallback,
  } = props;

  const { className, ...validateFieldProps } = props;

  const [items, setItems] = useState(options);
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    if (options) {
      setItems(options);
    }
    setSelected(value);
  }, [options, value]);

  const setValue = (field: string, targetValue: any) => {
    setFieldValue(field, targetValue.selected, shouldValidate);
    onChanged?.();
  };

  const handleChange = (_e: any, data: any) => {
    let selectedValues = data.value;
    setSelected(selectedValues);
    setValue(propertyName, { ...miscUtils.normalizeDropdownItems(selectedValues) });
    setFieldTouched(propertyName, true, false);
    labelsValidationUtils.validateLabels(validator, items, selectedValues, setItems);
  };

  const getValuesConsideringMax = () => {
    if (!props.maxSelections) {
      return items;
    }

    const itemsValues = items.map((i) => i.value);
    const selectedInRange = selected.filter((s: any) => itemsValues.indexOf(s) > -1);

    return selectedInRange.length >= props.maxSelections
      ? items
          .filter((i: MultiSelectFieldOption) => selected.indexOf(i.value) > -1)
          .concat([{ text: `Max ${props.maxSelections} selections`, value: 0, disabled: true }])
      : items;
  };

  const handleFieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur?.(e);
    onBlur?.(propertyName);
    resetOptionsCallback?.();
  };

  return (
    <ValidatedField
      {...validateFieldProps}
      data-testid="validated-field"
      className={styles["multi-select-field-container"]}
    >
      <MultiSelect
        data-testid="multiselect-field"
        name={formFieldName}
        className={cn(styles["multi-select-field"], className)}
        placeholder={placeholder}
        selected={selected}
        handleMultiSelectChange={handleChange}
        onBlur={handleFieldBlur}
        items={getValuesConsideringMax()}
        disabled={disabled}
      />
    </ValidatedField>
  );
};
