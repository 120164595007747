import { Dispatch } from "@reduxjs/toolkit";
import integrationsDataService from "../../../Accounts/services/integrationsDataService";
import { fetchBegin, fetchFailure, fetchSuccess } from "./integrationMenuSlice";

export const fetchIntegrationsAction = (moboId?: string) => async (dispatch: Dispatch) => {
  dispatch(fetchBegin());
  try {
    const result = await integrationsDataService.getIntegrationSettings(moboId);
    const filteredResults = result.map((item) => {
      return { type: item.type, isActive: item.isActive };
    });
    dispatch(fetchSuccess({ items: filteredResults, totalCount: result.length }));
  } catch (error) {
    dispatch(fetchFailure(error as Error));
  }
};
