import FileTypes from "../../enums/fileTypes";
import FileStoragePermissionTypes from "../../enums/fileStoragePermissionTypes";

const fileStoragePermissions = [FileStoragePermissionTypes.Write, FileStoragePermissionTypes.Read];

const config = (endpointToGetSasLink, videoAssetId) => {
  return {
    endpointToGetSasLink: endpointToGetSasLink,
    sasRequest: {
      resourceType: FileTypes.Image,
      permissions: fileStoragePermissions,
      videoAssetId,
    },
  };
};

export default config;
