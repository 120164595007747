import React from "react";
import { func, string, object, bool, PropTypes } from "prop-types";

import DatePicker from "./DatePicker";

function FilterDatePicker(props) {
  const {
    filter,
    propertyName,
    placeholder,
    disabled,
    verticalPosition,
    horizontalPosition,
    popupPosition,
    minDate,
    maxDate,
  } = props;

  const handleFilterChange = (_, { value }) => {
    const { updateFilter } = props;

    let newFilter = { ...filter };

    if (value.length !== 0) {
      if (!newFilter[propertyName]) {
        newFilter[propertyName] = {};
      }
      newFilter = { ...newFilter, [propertyName]: { ...newFilter[propertyName], [placeholder.toLowerCase()]: value } };
    } else {
      delete newFilter[propertyName][placeholder.toLowerCase()];
      if (!newFilter[propertyName]["to"] && !newFilter[propertyName]["from"]) {
        delete newFilter[propertyName];
      }
    }

    updateFilter(newFilter);
  };

  return (
    <DatePicker
      value={filter[propertyName] ? filter[propertyName][placeholder.toLowerCase()] : ""}
      placeholder={placeholder}
      onChangeDate={handleFilterChange}
      disabled={disabled}
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
      popupPosition={popupPosition}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
}

FilterDatePicker.propTypes = {
  filter: object,
  updateFilter: func,
  propertyName: string,
  placeholder: string,
  verticalPosition: string,
  horizontalPosition: string,
  disabled: bool,
  popupPosition: PropTypes.oneOf([
    "top left",
    "top right",
    "bottom left",
    "bottom right",
    "right center",
    "left center",
    "top center",
    "bottom center",
    undefined,
  ]),
  minDate: string,
  maxDate: string,
};

export default FilterDatePicker;
