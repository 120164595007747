import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import {
  PerformanceFilter,
  completesColor,
  noBarData,
  chartLegendLabels,
  dailyActivity,
  lineChartTitles,
  noData,
  startsColor,
  totalActivity,
  validLineData,
} from "features/Library/Common/utils/performanceUtils";
import {
  sharedAccountReportingHorizontalBarProps,
  sharedAccountReportingLineProps,
} from "features/Reporting/Content/shared";
import { useMemo, useState } from "react";
import { COLOR_SCALE } from "features/Reporting/Google/utils/shared";
import { useFlowGoalsEngagementQuery, useFlowGoalsLineChartQuery } from "features/Reporting/queries/sharedQueries";
import { NoResultsWithButton } from "components";

import styles from "./flowGoalsCharts.module.scss";
import { ObjectiveTypes } from "features/Reporting/services/dashboardReportingService";

export interface FlowGoalsProps {
  filter: PerformanceFilter;
  type: ObjectiveTypes;
  icon: "faRepeat" | "faShuffle" | "faChartLineUp";
}

export const FlowGoalsCharts = (props: FlowGoalsProps) => {
  const [chartState, setChartState] = useState(totalActivity);
  const { type, filter, icon } = props;

  const flowGoalsLineChartQuery = useFlowGoalsLineChartQuery();
  const flowGoalsEngagementQuery = useFlowGoalsEngagementQuery();

  const barDomain = useMemo(() => {
    if (flowGoalsEngagementQuery.data !== undefined) {
      let maxValue = Math.max(...flowGoalsEngagementQuery.data.barData[type].map((d) => d.value));
      return [0, Math.max(maxValue, 1)];
    }
  }, [flowGoalsEngagementQuery.data, type]);

  const lineData = useMemo(() => {
    if (flowGoalsLineChartQuery.isSuccess) {
      if (chartState === dailyActivity) {
        return {
          xData: [flowGoalsLineChartQuery.data[type].Date, flowGoalsLineChartQuery.data[type].Date],
          yData: [flowGoalsLineChartQuery.data[type].InProgress, flowGoalsLineChartQuery.data[type].Completed],
        };
      }
      return {
        xData: [flowGoalsLineChartQuery.data[type].Date, flowGoalsLineChartQuery.data[type].Date],
        yData: [flowGoalsLineChartQuery.data[type].InProgressTotal, flowGoalsLineChartQuery.data[type].CompletedTotal],
      };
    }
    return {
      xData: [],
      yData: [],
    };
  }, [chartState, flowGoalsLineChartQuery, type]);

  if (
    flowGoalsEngagementQuery.isSuccess &&
    noBarData(...flowGoalsEngagementQuery.data.barData[type].map((d) => d.value))
  ) {
    return (
      <div className={styles.noRes}>
        <NoResultsWithButton
          title="No flows for this objective yet"
          fontAwesomeIcon={icon}
          description=""
          actionText=""
        />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.charts}>
        <div>
          <ChartWrapper
            titles={lineChartTitles}
            legendLabels={chartLegendLabels}
            showLegend
            colorRange={COLOR_SCALE}
            onChange={setChartState}
          >
            <RequestStatusRenderer state={flowGoalsLineChartQuery.status}>
              {flowGoalsLineChartQuery.isSuccess &&
                validLineData([
                  flowGoalsLineChartQuery.data[type].InProgress,
                  flowGoalsLineChartQuery.data[type].InProgressTotal,
                  flowGoalsLineChartQuery.data[type].Completed,
                  flowGoalsLineChartQuery.data[type].CompletedTotal,
                ]) ? (
                <LineChart
                  {...sharedAccountReportingLineProps}
                  {...lineData}
                  xFormatterFunc={getDateFormatByCount(flowGoalsLineChartQuery.data[type].Date.length)}
                  colorRange={[startsColor, completesColor]}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
        <div>
          <ChartWrapper titles={["Flow Engagement"]}>
            <RequestStatusRenderer state={flowGoalsLineChartQuery.status}>
              {flowGoalsEngagementQuery.isSuccess &&
                !noBarData(...flowGoalsEngagementQuery.data.barData[type].map((d) => d.value)) ? (
                <HorizontalBarChart
                  {...sharedAccountReportingHorizontalBarProps}
                  domain={barDomain}
                  data={flowGoalsEngagementQuery.data.barData[type]}
                />
              ) : (
                noData(filter)
              )}
            </RequestStatusRenderer>
          </ChartWrapper>
        </div>
      </div>
    </div>
  );
};
