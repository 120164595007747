import * as groupPermissionActionTypes from "../actionTypes/groupPermissionActionTypes";
import { getActionProvider, getActionBaseProvider } from "../../../../Application/actions/actionsBuilder";
import Sections from "../../../../../enums/sections";

const groupPermissionActions = (section: Sections) => {
  const entityType = section.slice(0, section.length - 1).toUpperCase();
  let types: { [index: string]: string } = groupPermissionActionTypes;

  return {
    updateGroupsAssignment: getActionProvider<number[]>(types[`${entityType}_ASSIGN_GROUPS_PERMISSIONS`]),
    resetGroupsAssignment: getActionBaseProvider(types[`${entityType}_RESET_GROUPS_PERMISSIONS`]),
  };
};

export default groupPermissionActions;
