import { useCallback, useMemo } from "react";
import moment from "moment";
import { DropdownProps } from "semantic-ui-react";
import dateTimeUtils from "../../utils/dateTimeUtils";
import { TimePicker } from "../timePicker";
import { DatePicker } from "../datePicker";

import "./dateTimePicker.scss";

export interface DateTimePickerProps {
  dateValue: string;
  timeValue: string;
  onChange: (dateString: string, timeString: string) => void;
}

export const DateTimePicker = (props: DateTimePickerProps) => {
  const { dateValue, timeValue, onChange } = props;

  const minDate = dateTimeUtils.formatDate(moment());

  const localTimeValue = useMemo(() => timeValue, [timeValue]);

  const onDateChange = useCallback(
    (_: React.SyntheticEvent<HTMLElement>, data: { name: string; value: string }) => {
      onChange(data.value, timeValue);
    },
    [onChange, timeValue],
  );

  const onTimeChange = useCallback(
    (_: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
      onChange(dateValue, data.value as string);
    },
    [onChange, dateValue],
  );

  return (
    <div className="datetime-picker-container">
      <DatePicker value={dateValue} minDate={minDate} onChange={onDateChange} />
      <TimePicker
        selected={localTimeValue}
        interval={{ unit: "minutes", value: 5 }}
        {...props}
        onTimeChange={onTimeChange}
      />
    </div>
  );
};
