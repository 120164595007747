import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntityType, SankeyData } from "../../../../../components/charts/types/Sankey";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { RequestState, RStatus } from "../../../../Application/globaltypes/fetchRequest";
import { RootState } from "../../../../Application/globaltypes/redux";
import { CardsData, DateRange, FlowPeopleDetails, GoalCards, GoalLineChart, GoalTotals } from "../../types/performance";
import { HorizontalBarProps, LinechartProps } from "components/charts";
import { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { Props } from "components/charts/ChartWrapper/ChartWrapper";
import { selectFlowGoalPerformanceInfo } from "./flowGoalSlice";
import { getGoalCards, getGoalEngagementChart, getGoalLineChart } from "../selectors/flowPerformanceGoalSelectors";
import SortDirections from "enums/sortDirections";
import moment from "moment";

type PackedGoalInfo = {
  applicationLabel: string | undefined;
  measureLabel: string | undefined;
};

const allowedGoalReports: PackedGoalInfo[] = [
  { applicationLabel: "Teams", measureLabel: "Meetings" },
  { applicationLabel: "Teams", measureLabel: "Messages" },
  { applicationLabel: "Teams", measureLabel: "Calls" },
  { applicationLabel: "Teams", measureLabel: "All Activity" },
  { applicationLabel: "OneDrive", measureLabel: "Files Shared" },
  { applicationLabel: "OneDrive", measureLabel: "Files Synced" },
  { applicationLabel: "OneDrive", measureLabel: "Files Viewed" },
  { applicationLabel: "OneDrive", measureLabel: "All Activity" },
  { applicationLabel: "SharePoint", measureLabel: "Files Synced" },
  { applicationLabel: "SharePoint", measureLabel: "Files Viewed" },
  { applicationLabel: "SharePoint", measureLabel: "Page Visits" },
  { applicationLabel: "SharePoint", measureLabel: "Shared Files" },
  { applicationLabel: "SharePoint", measureLabel: "All Activity" },
];

export const isAllowedGoal = (goal: PackedGoalInfo) => {
  return (
    allowedGoalReports.find(
      (g) => g.applicationLabel === goal.applicationLabel && g.measureLabel === goal.measureLabel,
    ) !== undefined
  );
};

const namespace = ReducerNamespaceTypes.Flows;
const entityPrefix = ReducerEntityPrefixTypes.Flows_Performance;

const initialCardState: RequestState<CardsData> = {
  status: RStatus.Idle,
  value: {
    NotStartedCount: 0,
    InProgressCount: 0,
    CompletedCount: 0,
    RetakenCount: 0,
  },
  errorMessage: undefined,
};

const initialEngagementState: RequestState<SankeyData> = {
  status: RStatus.Idle,
  value: {
    AccountId: -1,
    FlowId: -1,
    Relations: [],
  },
  errorMessage: undefined,
};

const initialRequestState: RequestState<any> = {
  status: RStatus.Idle,
  value: {},
  errorMessage: undefined,
};

const initialRequestStateArr: RequestState<any[]> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

export interface FlowPerformanceState {
  cardData: RequestState<CardsData>;
  engagementData: RequestState<SankeyData>;
  dateRanges: RequestState<DateRange[]>;
  flowPeopleDetails: RequestState<FlowPeopleDetails[]>;
  goalLine: RequestState<GoalLineChart[]>;
  goalTotals: RequestState<GoalTotals[]>;
  goalCards: RequestState<GoalCards[]>;
  sortingColumn: string | null;
  sortingDirection: SortDirections | null;
}

export const initialState: FlowPerformanceState = {
  cardData: initialCardState,
  engagementData: initialEngagementState,
  dateRanges: {
    status: RStatus.Idle,
    value: [],
    errorMessage: undefined,
  },
  flowPeopleDetails: initialRequestState,
  goalLine: initialRequestStateArr,
  goalTotals: initialRequestStateArr,
  goalCards: initialRequestStateArr,
  sortingColumn: null,
  sortingDirection: null,
};

const flowPerformanceSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    reqCardData(state: FlowPerformanceState) {
      state.cardData = {
        ...state.cardData,
        status: RStatus.Pending,
      };
    },
    reqEngagementData(state: FlowPerformanceState) {
      state.engagementData = {
        ...state.engagementData,
        status: RStatus.Pending,
      };
    },
    reqDateRanges(state) {
      state.dateRanges.status = RStatus.Pending;
    },
    reqPeopleDetails(state: FlowPerformanceState) {
      state.flowPeopleDetails = {
        ...state.flowPeopleDetails,
        status: RStatus.Pending,
      };
    },
    setCardData(state: FlowPerformanceState, action: PayloadAction<{ data: CardsData }>) {
      state.cardData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setEngagementData(state: FlowPerformanceState, action: PayloadAction<{ data: SankeyData }>) {
      state.engagementData = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setDateRanges(state, action: PayloadAction<{ data: DateRange[] }>) {
      state.dateRanges = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setPeopleDetails(state: FlowPerformanceState, action: PayloadAction<{ data: FlowPeopleDetails[] }>) {
      state.flowPeopleDetails = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setCardError(state: FlowPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.cardData = {
        ...state.cardData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setEngagementError(state: FlowPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.engagementData = {
        ...state.engagementData,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setDateRangesError(state, action: PayloadAction<{ errorMessage: string }>) {
      state.dateRanges = {
        ...state.dateRanges,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    setPeopleDetailsError(state: FlowPerformanceState, action: PayloadAction<{ errorMessage: string }>) {
      state.flowPeopleDetails = {
        ...state.flowPeopleDetails,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqGoalLine(state) {
      state.goalLine.status = RStatus.Pending;
    },
    setGoalLine(state, action: PayloadAction<any[]>) {
      state.goalLine = {
        status: RStatus.Got,
        value: action.payload,
      };
    },
    setGoalLineError(state, action: PayloadAction<string>) {
      state.goalLine.status = RStatus.Error;
      state.goalLine.errorMessage = action.payload;
    },
    reqGoalTotals(state) {
      state.goalTotals.status = RStatus.Pending;
    },
    setGoalTotals(state, action: PayloadAction<Record<string, number>[]>) {
      state.goalTotals = {
        status: RStatus.Got,
        value: action.payload as FlowPerformanceState["goalTotals"]["value"],
      };
    },
    setGoalTotalsError(state, action: PayloadAction<string>) {
      state.goalTotals.status = RStatus.Error;
      state.goalTotals.errorMessage = action.payload;
    },
    reqGoalCards(state) {
      state.goalCards.status = RStatus.Pending;
    },
    setGoalCards(state, action: PayloadAction<Record<string, number>[]>) {
      state.goalCards = {
        status: RStatus.Got,
        value: action.payload as FlowPerformanceState["goalCards"]["value"],
      };
    },
    setGoalCardsError(state, action: PayloadAction<string>) {
      state.goalCards.status = RStatus.Error;
      state.goalCards.errorMessage = action.payload;
    },
    setTableSort(state, action: PayloadAction<[sortColumn: string | null, sortDirection: SortDirections | null]>) {
      state.sortingColumn = action.payload[0];
      state.sortingDirection = action.payload[1];
    },
    reset() {
      return initialState;
    },
  },
});

// Actions
export const {
  reqCardData,
  reqEngagementData,
  reqDateRanges,
  reqPeopleDetails,
  reqGoalLine,
  reqGoalTotals,
  reqGoalCards,
  setCardData,
  setEngagementData,
  setDateRanges,
  setPeopleDetails,
  setGoalLine,
  setGoalTotals,
  setGoalCards,
  setCardError,
  setEngagementError,
  setDateRangesError,
  setPeopleDetailsError,
  setGoalLineError,
  setGoalTotalsError,
  setGoalCardsError,
  setTableSort,
  reset,
} = flowPerformanceSlice.actions;

// Selectors
export const selectCardData = (state: RootState) => state.library.flows.base.performance.cardData;
export const selectEngagementData = (state: RootState) => state.library.flows.base.performance.engagementData;
export const selectDateRanges = (state: RootState) => state.library.flows.base.performance.dateRanges;
export const selectPeopleDetails = (state: RootState) => state.library.flows.base.performance.flowPeopleDetails;
export const selectGoalLine = (state: RootState) => state.library.flows.base.performance.goalLine;
export const selectGoalTotals = (state: RootState) => state.library.flows.base.performance.goalTotals;
export const selectGoalCards = (state: RootState) => state.library.flows.base.performance.goalCards;
export const selectTableSortColumn = (state: RootState) => state.library.flows.base.performance.sortingColumn;
export const selectTableSortDirection = (state: RootState) => state.library.flows.base.performance.sortingDirection;

export const selectCardsStatus = createSelector(selectCardData, (cardData) => cardData.status);
export const selectCardsValue = createSelector(selectCardData, (cardData) => cardData.value);
export const selectCardsError = createSelector(selectCardData, (cardData) => cardData.errorMessage);

export const selectEngagementStatus = createSelector(selectEngagementData, (engagementData) => engagementData.status);
export const selectEngagementValue = createSelector(selectEngagementData, (engagementData) => engagementData.value);
export const selectEngagementError = createSelector(
  selectEngagementData,
  (engagementData) => engagementData.errorMessage,
);

export const selectPeopleDetailsStatus = createSelector(selectPeopleDetails, (peopleDetails) => peopleDetails.status);
export const selectPeopleDetailsValue = createSelector(
  selectPeopleDetails,
  selectTableSortColumn,
  selectTableSortDirection,
  (peopleDetails, sortColumn, sortDirection) => {
    // Manual sorting being done on this for now. This
    // should be removed once the endpoint supports sort params
    if (!sortColumn || sortDirection === null || !peopleDetails.value.length) {
      return peopleDetails.value;
    }

    const copy = [...peopleDetails.value];
    const columnName: keyof FlowPeopleDetails = sortColumn === "date started" ? "StartDate" : "CompletionDate";
    copy.sort((a, b) => {
      if (sortColumn === "completion time") {
        if (sortDirection === SortDirections.Asc) {
          return moment.duration(a.CompletionTime).asSeconds() - moment.duration(b.CompletionTime).asSeconds();
        } else {
          return moment.duration(b.CompletionTime).asSeconds() - moment.duration(a.CompletionTime).asSeconds();
        }
      } else {
        if (sortDirection === SortDirections.Asc) {
          return moment(a[columnName]).diff(moment(b[columnName]));
        } else {
          return moment(b[columnName]).diff(moment(a[columnName]));
        }
      }
    });

    return copy;
  },
);
export const selectPeopleDetailsError = createSelector(
  selectPeopleDetails,
  (peopleDetails) => peopleDetails.errorMessage,
);

export const selectDateRangeStatus = createSelector(selectDateRanges, (state) => state.status);
export const selectDateRangeValue = createSelector(selectDateRanges, (state) => state.value);
export const selectDateRangeError = createSelector(selectDateRanges, (state) => state.errorMessage);

export const selectUniqueEngagementNodes = createSelector(selectEngagementValue, (sankeyData) => {
  const nodeMap = new Map<number, [EntityType, string]>(); // Done to preserve insertion order
  sankeyData.Relations.forEach((relation) => {
    if (!nodeMap.has(relation.ItemId)) {
      nodeMap.set(relation.ItemId, [relation.ItemType, relation.ItemTitle]);
    }
    if (!nodeMap.has(relation.NextItemId)) {
      nodeMap.set(relation.NextItemId, [relation.NextItemType, relation.NextItemTitle]);
    }
  });
  let returnVal: { id: number; type: EntityType; name: string }[] = [];
  nodeMap.forEach((value, key) => {
    returnVal.push({
      id: key,
      type: value[0],
      name: value[1],
    });
  });
  return returnVal;
});

export type LineChartInfo = { chartWrapperProps: Props; lineProps: LinechartProps };

export type ValidFlowGoalHeader =
  | "Teams Meetings"
  | "Teams Messages"
  | "Teams Calls"
  | "OneDrive Files Shared"
  | "OneDrive Files Synced"
  | "OneDrive Files Viewed"
  | "SharePoint Files Synced"
  | "SharePoint Files Viewed"
  | "SharePoint Page Visits"
  | "SharePoint Shared Files";

export type FlowGoalFilterParams = {
  application: string;
  activity: string;
};

export const selectGoalHeaderLabels = createSelector(
  selectFlowGoalPerformanceInfo,
  (flowGoalInfo): ValidFlowGoalHeader[] => {
    const name = `${flowGoalInfo?.applicationLabel} ${flowGoalInfo?.measureLabel}`;
    if (!flowGoalInfo || Object.values(flowGoalInfo).some((v) => !v)) return [];

    switch (`${flowGoalInfo.applicationLabel} ${flowGoalInfo.measureLabel}`) {
      case "Teams All Activity":
        return ["Teams Meetings", "Teams Messages", "Teams Calls"];
      case "OneDrive All Activity":
        return ["OneDrive Files Shared", "OneDrive Files Synced", "OneDrive Files Viewed"];
      case "SharePoint All Activity":
        return [
          "SharePoint Files Synced",
          "SharePoint Files Viewed",
          "SharePoint Page Visits",
          "SharePoint Shared Files",
        ];
      default:
        return [name as ValidFlowGoalHeader];
    }
  },
);
export const selectGoalLineChart = createSelector(
  selectGoalLine,
  selectGoalHeaderLabels,
  ({ value }, flowHeaders): LineChartInfo[] => {
    return flowHeaders.map((h, i) => {
      return getGoalLineChart(value[i], h);
    });
  },
);
export const selectGoalEngagementChartInfo = createSelector(
  selectGoalTotals,
  selectFlowGoalPerformanceInfo,
  ({ value }, flowGoalInfo): HorizontalBarProps[] => {
    return value.map((v) => getGoalEngagementChart(v, flowGoalInfo));
  },
);

export const selectGoalCardsInfo = createSelector(
  selectGoalCards,
  selectFlowGoalPerformanceInfo, // necessary for telling sharepoint reports apart
  ({ value }, flowGoalInfo): CardReportingItem[][] => {
    return value.map((v) => getGoalCards(v, flowGoalInfo));
  },
);

export default flowPerformanceSlice.reducer;
