import cn from "classnames";
import { TextTruncate } from "components";
import CardReporting, { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { ChartWrapper, HorizontalBarChart, LineChart, getDateFormatByCount } from "components/charts";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import {
  QueryFilter, dailyActivity,
  emailReportColorScale,
  getBarDomain, lineChartFactory,
  lineChartTitles,
  noBarData,
  noData,
  roundToTwoDigits,
  totalActivity,
  validLineData
} from "features/Library/Common/utils/performanceUtils";
import { EntityType } from "features/Library/Flows/Designer/types";
import { EmailDetails, EngagementCounts } from "features/Reporting/types/content";
import { ColumnOption } from "interfaces/columnOptions";
import { FC, useEffect, useMemo, useState } from "react";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { GenericPerformanceList } from "../../shared/GenericPerformanceList";
import { CommonAccountReportProps } from "../../types";

import { useQuery } from "@tanstack/react-query";
import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { FeatureFlags } from "featureFlags";
import * as contentReportingService from "features/Reporting/services/contentReportingService";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { sharedAccountReportingHorizontalBarProps, sharedAccountReportingLineProps } from "../../shared";

import { bindActionCreators } from "@reduxjs/toolkit";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { doEmailExport } from "features/Reporting/state/content/contentActions";
import { setExportAction, setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { bindAction } from "interfaces";
import { ConnectedProps, connect } from "react-redux";

import { useEmailEngagementQuery } from "features/Reporting/queries/sharedQueries";
import { reset, setIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import styles from "../../contentOverviewReports.module.scss";
import { Tooltip } from "components/common/tooltip";

export type Props = CommonAccountReportProps & PropsFromRedux;

const fetchDailyData = async ({ queryKey }: QueryFilter) => {
  const result = await contentReportingService.getEmailUsage(queryKey[1]);
  return lineChartFactory(result.data);
};

const fetchDetailsQuery = async ({ queryKey }: QueryFilter) => {
  let result = (await contentReportingService.getEmailDetails(queryKey[1])).data;
  return result.map((item) => {
    return {
      ...item,
      id: item.SendSessionId,
    };
  });
};

export const getCardsReportData = (engagementData: EngagementCounts) => {
  const cards: CardReportingItem[] = [
    {
      statDescription: "Avg. Send Size",
      stat: roundToTwoDigits(engagementData.AvgSendSize),
      popupBody: "The average number of recipients per email send.",
      width: "13.75rem",
    },
    {
      statDescription: "Open Rate",
      stat: `${roundToTwoDigits(engagementData.OpenRate * 100)}%`,
      popupBody: "The percentage of emails opened compared to the total number of emails sent.",
      width: "13.75rem",
    },
    {
      statDescription: "Click-to-open Rate",
      stat: `${roundToTwoDigits(engagementData.ClickToOpen * 100)}%`,
      popupBody: "The percentage of emails clicked compared to the total number of emails opened.",
      width: "13.75rem",
    },
    {
      statDescription: "Clickthrough Rate",
      stat: `${roundToTwoDigits(engagementData.ClickThroughRate * 100)}%`,
      popupBody: "The percentage of emails clicked compared to the total number of email sent.",
      width: "13.75rem",
    },
  ];
  return cards;
};

export const EmailReport: FC<Props> = ({
  filter,
  setSelectedContent,
  setHasExportData,
  setExportAction,
  emailExport,
  setIsReportEnabled,
  resetIsReportEnabled,
}) => {
  const reportEnabled = useFeatureFlag(FeatureFlags.EmailPerformanceOverview);
  const [chartState, setChartState] = useState(totalActivity);
  const lineChartQuery = useQuery({
    queryKey: ["EmailsReportDailyData", filter],
    queryFn: fetchDailyData,
    enabled: reportEnabled,
  });

  const engagementQuery = useEmailEngagementQuery({ filter, enabled: reportEnabled });

  const detailsQuery = useQuery({
    queryKey: ["EmailsReportDetails", filter],
    queryFn: fetchDetailsQuery,
    enabled: reportEnabled,
  });

  useEffect(() => {
    setHasExportData(!!detailsQuery.data && detailsQuery.data.length > 0);
  }, [detailsQuery.data, setHasExportData]);

  // Setting up correct export method if send entity is changed
  useEffect(() => {
    setExportAction({
      method: emailExport,
      args: [filter],
      isExportEnabled: true,
    });
  }, [emailExport, filter, setExportAction]);

  useEffect(() => {
    setIsReportEnabled(reportEnabled);

    return () => {
      resetIsReportEnabled();
    };
  }, [reportEnabled, setIsReportEnabled, resetIsReportEnabled]);

  const columns: ColumnOption<EmailDetails>[] = useMemo(
    () => [
      {
        name: "Email",
        width: 7,
        isSortable: false,
        render(email) {
          return (
            <button
              className={styles.linkButton}
              onClick={() => setSelectedContent({ id: email.EmailId, type: EntityType.Email, name: email.EmailTitle })}
            >
              <Tooltip
                target={
                  <TextTruncate>{email.EmailTitle}</TextTruncate>
                }
                content={`${email.EmailTitle}`}
              />

            </button>
          );
        },
      },
      {
        name: "Send Session",
        width: 7,
        isSortable: false,
        render(email) {
          return (
            <div className={styles["email-send"]}>
              {dateTimeUtils.localFormatWithValidation(new Date(email.SendSession))}
            </div>
          );
        },
      },
      {
        name: "Recipients",
        width: 3,
        isSortable: false,
        render(email) {
          return email.Sends.toLocaleString();
        },
      },
      {
        name: "Opens",
        width: 3,
        isSortable: false,
        render(email) {
          return email.Opens.toLocaleString();
        },
      },
      {
        name: "Clicks",
        width: 3,
        isSortable: false,
        render(email) {
          return email.Clicks.toLocaleString();
        },
      },
      {
        name: "CTOR",
        width: 3,
        isSortable: false,
        render(email) {
          return `${roundToTwoDigits(email.ClickToOpenRate * 100)}%`;
        },
      },
      {
        name: "CTR",
        width: 3,
        isSortable: false,
        render(email) {
          return `${roundToTwoDigits(email.ClickThroughRate * 100)}%`;
        },
      },
    ],
    [setSelectedContent],
  );

  const lineData = useMemo(() => {
    if (chartState === dailyActivity) {
      return {
        xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date, lineChartQuery.data?.Date],
        yData: [lineChartQuery.data?.Sends, lineChartQuery.data?.Opens, lineChartQuery.data?.Clicks],
      };
    }
    return {
      xData: [lineChartQuery.data?.Date, lineChartQuery.data?.Date, lineChartQuery.data?.Date],
      yData: [
        lineChartQuery.data?.CumulativeSends,
        lineChartQuery.data?.CumulativeOpens,
        lineChartQuery.data?.CumulativeClicks,
      ],
    };
  }, [chartState, lineChartQuery.data]);

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className={styles.reportBody}>
      <div className={cn(styles.lineChartContainer)}>
        <ChartWrapper
          titles={lineChartTitles}
          showLegend
          legendLabels={["Sends", "Opens", "Clicks"]}
          colorRange={emailReportColorScale}
          onChange={setChartState}
        >
          <RequestStatusRenderer state={lineChartQuery.status}>
            {lineChartQuery.isSuccess &&
              validLineData([
                lineChartQuery.data.Sends,
                lineChartQuery.data.Opens,
                lineChartQuery.data.Clicks,
                lineChartQuery.data.CumulativeSends,
                lineChartQuery.data.CumulativeOpens,
                lineChartQuery.data.CumulativeClicks,
              ]) ? (
              <LineChart
                {...sharedAccountReportingLineProps}
                {...lineData}
                xFormatterFunc={getDateFormatByCount(lineChartQuery.data.Date.length)}
                colorRange={emailReportColorScale}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={cn(styles.funnelChartContainer)}>
        <ChartWrapper
          titles={["Engagement"]}
          showLegend
          legendLabels={["Sends", "Opens", "Clicks"]}
          colorRange={emailReportColorScale}
        >
          <RequestStatusRenderer state={engagementQuery.status}>
            {engagementQuery.isSuccess &&
              !noBarData(
                engagementQuery.data.raw.Sends,
                engagementQuery.data.raw.Opens,
                engagementQuery.data.raw.Clicks,
              ) ? (
              <HorizontalBarChart
                {...sharedAccountReportingHorizontalBarProps}
                data={engagementQuery.data.barData}
                domain={getBarDomain(engagementQuery.data.barData)}
              />
            ) : (
              noData(filter)
            )}
          </RequestStatusRenderer>
        </ChartWrapper>
      </div>
      <div className={styles.performanceCardSection}>
        <RequestStatusRenderer state={engagementQuery.status}>
          {engagementQuery.isSuccess && <CardReporting items={getCardsReportData(engagementQuery.data.raw)} />}
        </RequestStatusRenderer>
      </div>
      <div className={styles.table}>
        <RequestStatusRenderer state={detailsQuery.status}>
          {detailsQuery.isSuccess && (
            <GenericPerformanceList title="Emails" rows={detailsQuery.data} columns={columns} filter={filter} />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHasExportData: bindAction(setHasDataToExport, dispatch),
  emailExport: bindActionCreators(doEmailExport, dispatch),
  setExportAction: bindAction(setExportAction, dispatch),
  setIsReportEnabled: bindAction(setIsReportEnabled, dispatch),
  resetIsReportEnabled: bindAction(reset, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmailReport);
