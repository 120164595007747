import { useEffect, useMemo, useRef } from "react";
import { XYCoord } from "react-dnd";
import { ElementAndPosition } from "../../types";
import { useNodesFilter, ViewPortType } from "../../AlignmentSmartGuides/hooks/useNodesFilter";
import { DRAG_SHIFT } from "../../constants";
import detectCoveredElement from "../../intersection/helpers/detectCoveredElement";
import getNodesWithPositions from "../../intersection/helpers/getNodesWithPositions";
import { IReactFlowEntityType } from "../../ReactFlowCanvas/nodes/types";
import { useReactFlow } from "reactflow";
import { useReactFlowCanvasState } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";

type UseDragLayerItem = any; // @see useDragLayer typings

const nodesFilterOptions = { viewPortType: ViewPortType.SmartGuidesOnDragLayer };
export const useIntersection = ({
  draggedItem,
  clientOffset,
  headId,
  intersectionItem,
  updateIntersectionItem,
  createIntersectionFilter,
}: {
  headId: string | undefined;
  intersectionItem: ElementAndPosition | undefined;
  updateIntersectionItem: (element?: ElementAndPosition) => void;
  createIntersectionFilter: (currentNode: {
    id: string;
    topLeft: {
      x: number;
      y: number;
    };
    type: IReactFlowEntityType;
  }) => (el: ElementAndPosition) => boolean;
  draggedItem: UseDragLayerItem;
  clientOffset: XYCoord | null;
}) => {
  const { reactFlowBounds } = useReactFlowCanvasState();
  const { project, getNodes } = useReactFlow();
  const { nodesFilter, viewPort, scale } = useNodesFilter(nodesFilterOptions);
  const x = Math.round(clientOffset?.x || 0) - reactFlowBounds.left - DRAG_SHIFT * scale;
  const y = Math.round(clientOffset?.y || 0) - reactFlowBounds.top - DRAG_SHIFT * scale;
  const canvasCoords = project({ x, y });

  const currentNode = useMemo(
    () => ({
      id: "",
      topLeft: {
        x: canvasCoords.x,
        y: canvasCoords.y,
      },
      type: draggedItem?.elementType,
    }),
    [canvasCoords.x, canvasCoords.y, draggedItem?.elementType],
  );

  const isOverCanvas = canvasCoords.x <= viewPort.x2 && canvasCoords.y > viewPort.y1 ? true : null;
  // store nodes data in a ref for reusage between renders
  const nodeCoordsRef = useRef<ElementAndPosition[]>([]);
  const nodes = getNodes().filter(nodesFilter);

  useEffect(() => {
    if (draggedItem === null) {
      return;
    }

    nodeCoordsRef.current = getNodesWithPositions(nodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggedItem !== null]);
  const filterFunc = createIntersectionFilter(currentNode);
  const coveredElement = isOverCanvas && detectCoveredElement(nodeCoordsRef.current, currentNode, headId, filterFunc);

  const intersectedElement = useMemo(
    () =>
      coveredElement
        ? {
            ...coveredElement,
            topLeft: {
              x: coveredElement.topLeft.x,
              y: coveredElement.topLeft.y,
            },
          }
        : null,
    [coveredElement],
  );

  useEffect(() => {
    if (intersectionItem?.id !== intersectedElement?.id) {
      updateIntersectionItem(intersectedElement as ElementAndPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersectedElement]);

  useEffect(() => {
    return () => {
      updateIntersectionItem();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!coveredElement || reactFlowBounds.width === 0) {
    return { intersectedElement: null };
  }

  return { intersectedElement };
};
