import { combineReducers } from "@reduxjs/toolkit";
import overviewReducer from "./overview/overviewSlice";
import packReportingReducer from "./packs/packSlice";
import graphReducer from "./msgraph/graphSlice";
import exportReducer from "./export/exportSlice";
import toolbarReducer from "./toolbar/toolbarSlice";
import integrationMenuReducer from "./menu/integrationMenuSlice";

const menu = combineReducers({
  integrationMenuReducer,
});

const reportingReducer = combineReducers({
  overview: overviewReducer,
  packs: packReportingReducer,
  graph: graphReducer,
  export: exportReducer,
  toolbar: toolbarReducer,
  menu,
});

export type ReportingState = ReturnType<typeof reportingReducer>;

export default reportingReducer;
