import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { Input } from "semantic-ui-react";
import { ClearInputButton } from "../buttons/clearInputButton/ClearInputButton";

import ValidatedField from "./ValidatedField";

function InputField(props) {
  const {
    value,
    propertyName,
    setFieldValue,
    handleChange,
    handleBlur,
    onBlur,
    disabled,
    autoFocus,
    maxLength,
    nullable,
    clearable,
    placeholder = "",
    className,
    mask,
    testId,
    onValueChanged,
    inputType = "text",
    action,
    loading,
    iconPosition,
    autoComplete = undefined,
  } = props;

  const handleFieldBlur = useCallback(
    (e) => {
      handleBlur && handleBlur(e);
      onBlur && onBlur(propertyName);
    },
    [handleBlur, onBlur, propertyName],
  );

  const handleFieldChange = useCallback(
    (event, data) => {
      const maskedValue = (v) => (v?.match(mask) ? v : null);
      const fieldValue = mask ? maskedValue(data.value) : data.value;

      if (fieldValue == null && !!mask) return;

      if (nullable && fieldValue === "") {
        setFieldValue(propertyName, null, true);
      } else {
        setFieldValue(propertyName, fieldValue, true);
      }

      handleChange && handleChange(event, data);
      onValueChanged && onValueChanged(event, data);
    },
    [handleChange, nullable, propertyName, setFieldValue, mask, onValueChanged],
  );

  const renderClearButton = useCallback(() => {
    const clear = () => setFieldValue(propertyName, "");

    if (clearable && !isEmpty(value)) {
      return <ClearInputButton onClick={clear} disabled={disabled} />;
    }
  }, [clearable, disabled, propertyName, setFieldValue, value]);

  return (
    <ValidatedField {...props}>
      <Input
        fluid
        data-testid={testId || "input-field"}
        name={propertyName}
        type={inputType}
        value={value !== null && value !== undefined ? value : ""}
        placeholder={placeholder}
        onChange={handleFieldChange}
        onBlur={handleFieldBlur}
        disabled={disabled}
        autoFocus={autoFocus}
        maxLength={maxLength}
        icon={renderClearButton()}
        className={className}
        action={action}
        loading={loading}
        iconPosition={iconPosition}
        autoComplete={autoComplete}
        onWheel={(e) => e.target.blur()}
      />
    </ValidatedField>
  );
}

InputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
  propertyName: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  setFieldValue: PropTypes.func,
  testId: PropTypes.string,
  inputType: PropTypes.string,
  action: PropTypes.object,
  loading: PropTypes.bool,
  iconPosition: PropTypes.string,
  autoComplete: PropTypes.string,

  // validated field props, to be replaced with type definition from Formik
  markAsRequired: PropTypes.bool,
  clearable: PropTypes.bool,
  isFormValid: PropTypes.bool,
  onIsFormValidChange: PropTypes.func,
  errorPosition: PropTypes.string,
  tooltip: PropTypes.shape({
    info: PropTypes.string,
    width: PropTypes.number,
  }),

  // formik props
  errors: PropTypes.object,
  mask: PropTypes.any,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  onValueChanged: PropTypes.func,
};

export default InputField;
