import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../Application/globaltypes/redux";
import { Dimmer, Loader } from "semantic-ui-react";
import PeopleWrapper from "../../../PeopleAssignments/Overview/PeopleWrapper/PeopleWrapper";
import { RolePermissions } from "../../../../../enums";
import Sections from "../../../../../enums/sections";
import UsersOverview from "../../../PeopleAssignments/Overview/UsersOverview/UsersOverview";
import GroupsOverview from "../../../PeopleAssignments/Overview/GroupsOverview/GroupsOverview";
import TemplateGroupsOverview from "../../../PeopleAssignments/Overview/TemplateGroupsOverview/TemplateGroupsOverview";

export type PeopleProps = PropsFromRedux;

export const People = (props: PeopleProps) => {
  const { areUsersLoading, usersToAssign, groupsToAssign, templateGroupsToAssign } = props;

  return (
    <>
      <Dimmer active={areUsersLoading} inverted>
        <Loader />
      </Dimmer>
      <PeopleWrapper
        permission={RolePermissions.UsersView}
        disableSelectionChange={templateGroupsToAssign.length > 0}
        renderUsersOverview={(customHeader, isReadOnly) => (
          <UsersOverview
            section={Sections.emails}
            customHeaderContent={customHeader}
            isReadOnly={isReadOnly}
            assignedPeopleIds={usersToAssign}
          />
        )}
        renderGroupsOverview={(customHeader, isReadOnly) => (
          <GroupsOverview
            section={Sections.emails}
            customHeaderContent={customHeader}
            isReadOnly={isReadOnly}
            assignedPeopleIds={groupsToAssign}
          />
        )}
        renderTemplateGroupsOverview={(customHeader, isReadOnly) => (
          <TemplateGroupsOverview
            section={Sections.emails}
            customHeaderContent={customHeader}
            isReadOnly={isReadOnly}
            assignedPeopleIds={templateGroupsToAssign}
          />
        )}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  usersToAssign: state.library.emails.assignedUsers.userIds,
  groupsToAssign: state.library.emails.assignedGroups.groupIds,
  templateGroupsToAssign: state.library.groupTemplatesAssignment.templatesToBeAssigned.selected,
  areUsersLoading: state.library.userAssignment.usersToBeAssigned.isLoading,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(People);
