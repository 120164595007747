import { getQuestionDefaultData } from "./defaultQuestionProvider";
import { getMapper } from "../../../../Common/Mappers/QuestionToQuestionMapper";
import { IQuestion, MapResult } from "../../../../Common/models";
import {
  AssessmentAnswerType,
  AssessmentQuestionType,
  MultipleChoiceQuestion,
  SingleChoiceQuestion,
} from "../../../types/state";

const questionToQuestionMap = (() => {
  const mapper = getMapper();
  mapper
    .map()
    .from(AssessmentQuestionType.MultipleChoiceTextQuestion)
    .to(AssessmentQuestionType.SingleChoiceTextQuestion)
    .mapper((input: IQuestion<MultipleChoiceQuestion>): IQuestion<SingleChoiceQuestion> => {
      return {
        ...input,
        questionType: AssessmentQuestionType.SingleChoiceTextQuestion,
        question: { text: input.question.text, correctAnswer: 0 },
        answers: input.answers.map((item) => ({
          answerOrder: item.answer.answerOrder,
          answerType: AssessmentAnswerType.SingleText,
          answer: {
            text: item.answer.text,
            isCorrect: false,
          },
        })),
      };
    });

  mapper
    .map()
    .from(AssessmentQuestionType.SingleChoiceTextQuestion)
    .to(AssessmentQuestionType.MultipleChoiceTextQuestion)
    .mapper((input: IQuestion<SingleChoiceQuestion>): IQuestion<MultipleChoiceQuestion> => {
      return {
        ...input,
        questionType: AssessmentQuestionType.MultipleChoiceTextQuestion,
        question: {
          text: input.question.text,
          requireAllCorrectAnswers: false,
        },
        answers: input.answers.map((item) => ({
          answerOrder: item.answer.answerOrder,
          answerType: AssessmentAnswerType.MultipleText,
          answer: {
            text: item.answer.text,
            isCorrect: false,
          },
        })),
      };
    });

  return mapper.create();
})();

export const MapQuestion = (to: AssessmentQuestionType, input: IQuestion<any>): MapResult<IQuestion<any>> => {
  const mapper = questionToQuestionMap[input.questionType]?.[to];

  if (mapper) {
    return { question: mapper(input), success: true };
  }
  const defaultData = getQuestionDefaultData(to);

  return { question: { ...defaultData }, success: false };
};
