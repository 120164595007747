import moment from "moment";
import * as notificationActionTypes from "./actionTypes";
import * as updateVideoActionTypes from "../../Library/Videos/state/actionTypes/updateVideoActionTypes";
import * as addUserActionType from "../../People/Users/AddUser/state/addUserActionTypes";
import * as editUserActionType from "../../People/Users/EditUser/state/editUserActionTypes";
import environmentConfig from "configuration/environmentConfig";
import { createAction } from "@reduxjs/toolkit";

export const notificationReceived = createAction(notificationActionTypes.NOTIFICATION_RECEIVED);

export const createSendTransientNotification = (payload, dismissInSeconds) => {
  return createAction(notificationActionTypes.TRANSIENT_NOTIFICATION_RECEIVED, () => ({
    payload: {
      title: payload.title,
      message: payload.message,
      type: payload.type,
      dismissInSeconds: dismissInSeconds || environmentConfig.transientNotificationDismissSeconds || 5,
      createTime: moment().valueOf(),
    },
  }));
};

export const sendTransientNotification = (payload, dismissInSeconds) =>
  createSendTransientNotification(payload, dismissInSeconds)();

export const dismissAlerts = createAction(notificationActionTypes.NOTIFICATION_DISMISS, (payload) => ({
  payload: payload
}));

export const thumbnailGenerated = createAction(updateVideoActionTypes.CREATE_THUMBNAIL_SUCCESS, (payload) => ({
  payload: {
    videoAssetId: payload.videoAssetId,
    title: payload.title,
    canBePublished: payload.canBePublished,
    thumbnailUrl: payload.thumbnailUri,
  },
}));

export const videoGenerated = (payload) => ({
  type: updateVideoActionTypes.GENERATE_VIDEO_SUCCESS,
  payload: {
    videoAssetId: payload.videoAssetId,
    title: payload.title,
    canBePublished: payload.canBePublished,
    streamingManifests: payload.manifests,
  },
});

export const mediaEncodingCreatedAlert = (payload) => ({
  type: updateVideoActionTypes.MEDIA_ENCODING_CREATED_ALERT,
  payload,
});

export const mediaThumbnailCreatedAlert = (payload) => ({
  type: updateVideoActionTypes.MEDIA_THUMBNAIL_CREATED_ALERT,
  payload,
});

export const userCreated = (payload) => ({
  type: addUserActionType.USER_INFO_SAVE_SUCCESS,
  payload: { id: payload.CreatedUserId },
});

export const userUpdated = (payload) => ({
  type: editUserActionType.UPDATE_USER_INFO,
  payload: { id: payload.UserId },
});
