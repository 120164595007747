import { get, set } from "lodash";
import { batch } from "react-redux";
import { getActionBaseProvider, getActionProvider } from "../../../../Application/actions/actionsBuilder";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { actionTypes } from "../actionTypes/eventSessionsValidatorActionTypes";
import { publishDraftEventEntity } from "./eventEntityStateActions";
import { getAllErrorsLeafs } from "../../../Common/utils/validationUtils";
import { NotifyStepSettings } from "../../../../../interfaces";
import {
  eventSessionsSchemaCreate,
  eventSessionsSchemaEdit,
} from "../../../../../utils/validationSchemas/eventValidationSchemas";

export const setIsValidAction = getActionProvider<boolean>(actionTypes.setIsValid);
export const setErrorsResolvedAction = getActionProvider<boolean>(actionTypes.setResolved);
export const setActiveErrorsAction = getActionProvider<object>(actionTypes.setActiveErrors);
export const setTouchedSnapshotAction = getActionProvider<object>(actionTypes.setTouchedSnapshot);
export const setErrorModeAction = getActionProvider<boolean>(actionTypes.setErrorMode);
export const setInitializedAction = getActionProvider<boolean>(actionTypes.setInitialized);
export const resetAction = getActionBaseProvider(actionTypes.reset);

const getValidationState = (getState: () => RootState) => getState().library.events.eventSessionsValidationReducer;

const turnErrorMode = (on: boolean) => (dispatch: AppDispatch) => {
  batch(() => {
    dispatch(setErrorModeAction(on));
    dispatch(setErrorsResolvedAction(!on));
  });
};

export const tryPublish =
  (id: number, create: boolean, notifySettings?: NotifyStepSettings) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const { externalEventSessions } = getState().library.events.eventDetails.event;
    const eventSessionsValidation = getValidationState(getState);

    if (eventSessionsValidation.initialized && !eventSessionsValidation.isFormValid) {
      dispatch(turnErrorMode(true));
      return;
    }

    if (!eventSessionsValidation.initialized) {
      try {
        const schema = create ? eventSessionsSchemaCreate : eventSessionsSchemaEdit;
        await schema.validate(externalEventSessions, { abortEarly: false });
      } catch {
        dispatch(turnErrorMode(true));
        return;
      }
    }

    batch(() => {
      dispatch(publishDraftEventEntity(id, notifySettings));
      dispatch(resetAction());
    });
  };

export const setActiveErrors = (errors: object) => (dispatch: AppDispatch) => {
  let activeErrors: { [key: string]: string } = {};
  let touched: object = {};
  getAllErrorsLeafs(errors, (key, value) => {
    activeErrors[key] = value;
    set(touched, key, true);
  });

  batch(() => {
    dispatch(setTouchedSnapshotAction(touched));
    dispatch(setActiveErrorsAction(activeErrors));
  });
};

export const checkForResolvedErrors = (errors: object) => (dispatch: AppDispatch, getState: () => RootState) => {
  const eventSessionsValidation = getValidationState(getState);

  for (let item in eventSessionsValidation.activeErrors) {
    if (get(errors, item)) {
      return;
    }
  }

  batch(() => {
    dispatch(setErrorsResolvedAction(true));
    dispatch(setTouchedSnapshotAction({}));
    dispatch(setActiveErrorsAction({}));
  });
};

export interface IEventSessionsValidationActions {
  setIsValidAction: (isValid: boolean) => void;
  setActiveErrors: (errors: object) => (dispatch: AppDispatch) => void;
  checkForResolvedErrors: (errors: object) => (dispatch: AppDispatch, getState: () => RootState) => void;
  setInitializedAction: (initialized: boolean) => void;
}
