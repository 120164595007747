import { ElAndPos } from "../types";
import { IFlowNode } from "../../ReactFlowCanvas/nodes/types";
import {
  ELEMENT_HALF_HEIGHT,
  ELEMENT_HALF_WIDTH,
  ELEMENT_HEIGHT,
  ELEMENT_WIDTH,
  SECTION_HEADER_HEIGHT,
} from "../../constants";

interface INodesByAxis {
  sortedByX: ElAndPos[];
  sortedByY: ElAndPos[];
}

const sortByAxis = (arr: Array<ElAndPos>, axis: "x" | "y") => [...arr].sort((a, b) => a[axis] - b[axis]);

export default function getSortedByAxis(nodes: IFlowNode[]): INodesByAxis {
  let itemCoordsExtended: ElAndPos[] = [];
  for (let el of nodes) {
    let x = el.position.x;
    let y = el.position.y;
    if (el.data?.sectionHeader) {
      y = SECTION_HEADER_HEIGHT + y;
    }
    // get element coordinates (left top corner)
    itemCoordsExtended.push({
      id: el.id,
      x: Math.round(x),
      y: Math.round(y),
      edge: 1,
    });
    // create element coordinates (center center conrner)
    itemCoordsExtended.push({
      id: el.id,
      x: Math.round(x + ELEMENT_HALF_WIDTH),
      y: Math.round(y + ELEMENT_HALF_HEIGHT),
      edge: 2,
    });
    // create element coordinates (right top conrner)
    itemCoordsExtended.push({
      id: el.id,
      x: Math.round(x + ELEMENT_WIDTH),
      y: Math.round(y + ELEMENT_HEIGHT),
      edge: 3,
    });
  }
  return {
    sortedByX: [...sortByAxis(itemCoordsExtended, "x")],
    sortedByY: [...sortByAxis(itemCoordsExtended, "y")],
  };
}
