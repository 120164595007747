import React from "react";
import cn from "classnames";
import { RolePermissions } from "../../../enums";
import Restricted from "../../../features/Application/Restricted";
import CardContent from "../cardContent/CardContent";
import CardThumbnail from "../cardThumbnail/CardThumbnail";
import SelectableCard from "../selectableCard/SelectableCard";
import "./integrationCard.scss";
import { ConnectorInfo } from "../../../features/Accounts/Integrations/types";

export interface IntegrationCardProps extends ConnectorInfo {
  selected: boolean;
  disabled?: boolean;
  className?: string;
  onSelectionChanged: (id: number) => void;
  permissions?: RolePermissions[];
}

const IntegrationCard: React.FC<IntegrationCardProps> = ({
  id,
  thumbnailUrl,
  isConnected,
  description,
  selected,
  className,
  onSelectionChanged,
  disabled,
  permissions = [],
}) => {
  const renderCardLayout = () => {
    if (isConnected) {
      return (
        <>
          <div data-testid="connected-integration-card-layout" className="connected-integration-card-layout"></div>
          <div data-testid="connected-integration-card-text" className="connected-integration-card-text">
            <i className="check circle icon"></i>
            <span> CONNECTED </span>
          </div>
        </>
      );
    }
  };

  return (
    <Restricted
      permissions={permissions}
      renderContent={(hasPermission) => (
        <SelectableCard
          id={id}
          className={cn("integration-card", className)}
          selected={selected}
          onSelect={onSelectionChanged}
          disabled={false}
          onCardClick={!isConnected ? onSelectionChanged : null}
        >
          <CardThumbnail
            disabled={disabled}
            thumbnailUrl={thumbnailUrl}
            selected={isConnected ? undefined : selected}
            hasEditPermission={hasPermission}
            isRadioButton={true}
          />
          <CardContent title={description}></CardContent>
          {renderCardLayout()}
        </SelectableCard>
      )}
    />
  );
};

export default IntegrationCard;
