import { QueryFunctionContext } from "@tanstack/react-query";
import { DataPoint } from "components/charts/types/HorizontalBarChart";
import { BarChartData } from "components/charts/types/Shared";
import { RouteNames } from "enums";
import usersDataService from "features/Application/services/dataServices/usersDataService";
import {
  PerformanceFilter,
  completes,
  largestToSmallest,
  lineChartFactory,
  starts,
} from "features/Library/Common/utils/performanceUtils";
import { ChartPeriod } from "hooks/useChartPeriodMeasure";
import moment from "moment";
import { BarChartType, SelectedView, UserAssetTypes } from "../types";
import { getColorScale } from "components/charts/shared/helpers";
import { v4 } from "uuid";
import { SERVER_DATE_ONLY_FORMAT } from "utils/dateTimeUtils";

type QueryFilterUserId = QueryFunctionContext<[_: string, filter: PerformanceFilter | null, userId: number], unknown>;
type QueryFilterUserIdPeriod = QueryFunctionContext<
  [_: string, filter: PerformanceFilter | null, userId: number, period: ChartPeriod],
  unknown
>;

type QueryFilterUserIdPeriodWithType = QueryFunctionContext<
  [
    _: string,
    filter: PerformanceFilter | null,
    userId: number,
    selectedType: UserAssetTypes,
    selectedView: SelectedView,
  ],
  unknown
>;

const routes: Record<UserAssetTypes, string> = {
  "Emails Opened": `${RouteNames.contentCommunications}/${RouteNames.emails}`,
  "Emails Clicked": `${RouteNames.contentCommunications}/${RouteNames.emails}`,
  "Videos Started": `${RouteNames.contentVideos}`,
  "Videos Completed": `${RouteNames.contentVideos}`,
  "Event Registered": `${RouteNames.contentEvents}`,
  "Event Attended": `${RouteNames.contentEvents}`,
  "PDFs Completed": `${RouteNames.contentPdfs}`,
  "Surveys Started": `${RouteNames.contentSurveys}`,
  "Surveys Completed": `${RouteNames.contentSurveys}`,
  "Assessments Started": `${RouteNames.contentAssessments}`,
  "Assessments Completed": `${RouteNames.contentAssessments}`,
  "Flows Started": `${RouteNames.contentFlows}`,
  "Flows Completed": `${RouteNames.contentFlows}`,
  "Flow Outreach": `${RouteNames.contentCommunications}/${RouteNames.emails}`,
  "Email Outreach": `${RouteNames.contentCommunications}/${RouteNames.emails}`,
};

export const getLineChartQuery = async ({ queryKey }: QueryFilterUserIdPeriod) => {
  const { data } = await usersDataService.getUserLineChart({
    ...queryKey[1],
    userId: queryKey[2],
    period: queryKey[3],
  });

  const lineData = lineChartFactory(
    data.map((item) => {
      const date = item.Date;
      const platformOutreach = item.Events.EmailSends;
      let userInteractions =
        item.Events.AssessmentCompletes +
        item.Events.AssessmentStarts +
        item.Events.FlowCompletes +
        item.Events.FlowStarts +
        item.Events.PdfCompletes +
        item.Events.SurveyCompletes +
        item.Events.SurveyStarts +
        item.Events.VideoCompletes +
        item.Events.VideoStarts +
        item.Events.EmailClicks +
        item.Events.EmailOpens;

      userInteractions += item.Events.EventAttended ?? 0;
      userInteractions += item.Events.EventRegistered ?? 0;

      const cumulativePlatformOutreach = item.Events.CumulativeEmailSends;
      let cumulativeUserInteractions =
        item.Events.CumulativeAssessmentCompletes +
        item.Events.CumulativeAssessmentStarts +
        item.Events.CumulativeFlowCompletes +
        item.Events.CumulativeFlowStarts +
        item.Events.CumulativePdfCompletes +
        item.Events.CumulativeSurveyCompletes +
        item.Events.CumulativeSurveyStarts +
        item.Events.CumulativeVideoCompletes +
        item.Events.CumulativeVideoStarts +
        item.Events.CumulativeEmailOpens +
        item.Events.CumulativeEmailClicks;

      cumulativeUserInteractions += item.Events.CumulativeEventAttended ?? 0;
      cumulativeUserInteractions += item.Events.CumulativeEventRegistered ?? 0;

      return {
        Date: date,
        platformOutreach,
        userInteractions,
        cumulativePlatformOutreach,
        cumulativeUserInteractions,
      };
    }),
  );

  const activityBarData: BarChartData<BarChartType>[] = data.map((item) => {
    const platformOutreach = item.Events.EmailSends + item.Events.EmailOpens + item.Events.EmailClicks;
    let userInteractions =
      item.Events.AssessmentCompletes +
      item.Events.AssessmentStarts +
      item.Events.FlowCompletes +
      item.Events.FlowStarts +
      item.Events.PdfCompletes +
      item.Events.SurveyCompletes +
      item.Events.SurveyStarts +
      item.Events.VideoCompletes +
      item.Events.VideoStarts +
      item.Events.EmailClicks +
      item.Events.EmailOpens;

    userInteractions += item.Events.EventAttended ?? 0;
    userInteractions += item.Events.EventRegistered ?? 0;

    return {
      group: moment(item.Date).format(SERVER_DATE_ONLY_FORMAT),
      platformOutreach,
      userInteractions,
    };
  });

  const order: (keyof BarChartType)[] = ["platformOutreach", "userInteractions"];

  return {
    lineData,
    activityBarData,
    order,
  };
};

export const getActivityByTypeQuery = async ({ queryKey }: QueryFilterUserId) => {
  const { data } = await usersDataService.getUserActivityByType({ ...queryKey[1], userId: queryKey[2] });

  const barData: DataPoint[] = [
    {
      id: "assessmentCompletes",
      category: `Assessment ${completes}`,
      value: data.AssessmentCompletes,
      fillColor: "#000",
    },
    {
      id: "assessmentStarts",
      category: `Assessment ${starts}`,
      value: data.AssessmentStarts,
      fillColor: "#000",
    },
    {
      id: "flowStarts",
      category: `Flow ${starts}`,
      value: data.FlowStarts,
      fillColor: "#000",
    },
    {
      id: "flowCompletes",
      category: `Flow ${completes}`,
      value: data.FlowCompletes,
      fillColor: "#000",
    },
    {
      id: "pdfCompletes",
      category: `PDF ${completes}`,
      value: data.PdfCompletes,
      fillColor: "#000",
    },
    {
      id: "surveyCompletes",
      category: `Survey ${completes}`,
      value: data.SurveyCompletes,
      fillColor: "#000",
    },
    {
      id: "surveyStarts",
      category: "Survey Starts",
      value: data.SurveyStarts,
      fillColor: "#000",
    },
    {
      id: "videoCompleted",
      category: `Video ${completes}`,
      value: data.VideoCompletes,
      fillColor: "#000",
    },
    {
      id: "videoStarts",
      category: `Video ${starts}`,
      value: data.VideoStarts,
      fillColor: "#000",
    },
    {
      id: "emailClicks",
      category: "Email Clicks",
      value: data.EmailClicks,
      fillColor: "#000",
    },
    {
      id: "emailOpens",
      category: "Email Opens",
      value: data.EmailOpens,
      fillColor: "#000",
    },
    {
      id: "eventRegistrations",
      category: "Event Registrations",
      value: data.EventRegistered,
      fillColor: "#000",
    },
    {
      id: "eventsAttended",
      category: "Events Attended",
      value: data.EventAttended,
      fillColor: "#000",
    },
  ];

  const barDomain = [0, Math.max(1, Math.max(...barData.map((d) => d.value)))];

  const cards = {
    platformOutreach: data.PlatformOutreach,
    userInteractions: data.PlatformInteractions,
    groups: data.Groups,
    licenses: data.Licenses,
  };

  const colorScale = getColorScale(["#7b6fb8", "#f2f1f8"], barData.length);
  barData.sort(largestToSmallest);

  for (const [index, item] of barData.entries()) {
    item.fillColor = colorScale(index);
  }

  return { barData, barDomain, cards };
};

export const getOutreachQuery = async ({ queryKey }: QueryFilterUserId) => {
  const { data } = await usersDataService.getUserOutreach({ ...queryKey[1], userId: queryKey[2] });
  // Add id for ListView key
  return data.map((d) => ({ ...d, id: d.OutreachType })).filter((d) => d.OutreachCount > 0);
};

export const getInteractionQuery = async ({ queryKey }: QueryFilterUserId) => {
  const { data } = await usersDataService.getUserInteraction({ ...queryKey[1], userId: queryKey[2] });
  // Add id for ListView key
  return data.map((d) => ({ ...d, id: d.InteractionType })).filter((d) => d.InteractionCount > 0);
};

export const getUserDetailsQuery = async ({ queryKey }: QueryFilterUserIdPeriodWithType) => {
  let result;
  if (queryKey[4] === "outreach") {
    result = (await usersDataService.getUserOutreachDetails({ ...queryKey[1], userId: queryKey[2], type: queryKey[3] }))
      .data;
  } else {
    result = (
      await usersDataService.getUserInteractionDetails({ ...queryKey[1], userId: queryKey[2], type: queryKey[3] })
    ).data;
  }

  const formattedResults = result.map((item) => {
    return {
      ...item,
      Type: queryKey[3],
      Route: routes[queryKey[3]!],
      // Add id for ListView key
      id: v4(),
    };
  });

  return formattedResults;
};
