import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { TagsPayloadCamelPlural, TagsState } from "../../../../../interfaces/Tags";

type AssessmentTagsPayload = {
  [key: string]: string[];
};

export interface AssessmentBaseState extends TagsState {
  tags: AssessmentTagsPayload;
}

const initialState: AssessmentBaseState = {
  tags: {},
  error: undefined,
};

const assessmentBaseSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Assessments}/base`,
  initialState: initialState,
  reducers: {
    fetchTags(state: AssessmentBaseState, action: PayloadAction<TagsPayloadCamelPlural>) {
      state.tags = action.payload;
    },
    fetchTagsFailure(state: AssessmentBaseState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    resetTags: (state: AssessmentBaseState) => {
      state.tags = initialState.tags;
    },
  },
});

export const { fetchTags, fetchTagsFailure, resetTags } = assessmentBaseSlice.actions;

export default assessmentBaseSlice.reducer;
export type assessmentBaseSliceStateType = ReturnType<typeof assessmentBaseSlice.reducer>;
