import { EventEmitter } from "events";
import { AlignedWith } from "../../AlignmentSmartGuides/types";

export enum EmitterEventTypes {
  AlignmentUpdate,
  // add future Event types here
}
interface NodeEvenBase {
  type: EmitterEventTypes;
}

export interface AlignmentUpdatePayload {
  leftAligned: AlignedWith | null;
  middleVerticalAligned: AlignedWith | null;
  rightAligned: AlignedWith | null;
  topAligned: AlignedWith | null;
  middleHorizontalAligned: AlignedWith | null;
  bottomAligned: AlignedWith | null;
  dragFinished: boolean;
  position: {
    x: number;
    y: number;
  };
}

export interface AlignmentUpdateEvent extends NodeEvenBase {
  type: EmitterEventTypes.AlignmentUpdate;
  payload: AlignmentUpdatePayload;
}
// add other events here in future: NodeEvent = AlignmentUpdateEvent | OtherNewEvent
export type NodeEvent = AlignmentUpdateEvent;

export class ReactFlowEmitter extends EventEmitter {
  emitNodeEvent(nodeId: string, event: NodeEvent) {
    this.emit(`message-[${nodeId}]`, event);
  }

  onNodeEvent(nodeId: string, cb: (m: NodeEvent) => void) {
    this.on(`message-[${nodeId}]`, cb);
    return () => this.removeListener(`message-[${nodeId}]`, cb);
  }
}
