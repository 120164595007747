import { FiltersMap } from "../../../../../utils/filterUtils";
import { setError, setFilterOptions, setIsLoading } from "../slices/eventFilterSlice";
import { IDataItem, EventFilterPropertyNames, IPublisherDataItem, IPackDataItem } from "../../types/state";
import { Dispatch } from "redux";
import eventsDataService from "../../services/eventsDataService";
import { batch } from "react-redux";
import { getRejected, hasFulfilled } from "../../../../../utils/commonThunkUtils";
import { TagsPayloadCamelPlural } from "../../../../../interfaces/Tags";
import { TagsEnumCamelPlural } from "../../../../../interfaces/TagsEnum";
import DataService from "../../../../Application/services/dataServices/typedDataService";

interface TemplateMethod {
  begin: () => void;
  success: (options: any) => void;
  failure: (error: any) => void;
}

function initActions(dispatch: Dispatch, template?: TemplateMethod) {
  if (!template) {
    return {
      begin: () => dispatch(setIsLoading(true)),
      success: (f: FiltersMap) => dispatch(setFilterOptions(f)),
      failure: (item: PromiseRejectedResult) => dispatch(setError(item?.reason)),
    };
  }

  return template;
}

// probably it could be factory or generic
export const getFilterOptions = (
  template?: TemplateMethod,
  includePublishers: boolean = true,
  showPurchased: boolean = true,
) => {
  const filterOptions: FiltersMap = {
    labels: [],
    softwareApplications: [],
    publisherIds: [],
    packIds: [],
  };

  const tagsPropertyMap: { [key in TagsEnumCamelPlural]?: string } = {
    [TagsEnumCamelPlural.Label]: EventFilterPropertyNames.Labels,
    [TagsEnumCamelPlural.SoftwareApplication]: EventFilterPropertyNames.SoftwareApplications,
  };

  const readPublishersData = (data: IPublisherDataItem[], propertyName: string) => {
    generateFilterOption(data, propertyName, (item: IPublisherDataItem) => ({ text: item.name, value: item.id }));
  };

  const readPacksData = (data: IPackDataItem[], propertyName: string) => {
    generateFilterOption(data, propertyName, (item: IPackDataItem) => ({ text: item.name, value: item.id }));
  };

  const readTagsData = (data: string[], propertyName: string) => {
    generateFilterOption(data, propertyName, (item: string) => ({ text: item, value: item }));
  };

  const generateFilterOption = <T>(data: T[], propertyName: string, dataItemMapping: (item: T) => IDataItem): void => {
    filterOptions[propertyName] = data.map((item) => dataItemMapping(item));
  };

  const mapTags = (tagsPayload: TagsPayloadCamelPlural) => {
    for (let [tagType, tags] of Object.entries(tagsPayload)) {
      const tag = tagType as TagsEnumCamelPlural;
      if (Reflect.has(tagsPropertyMap, tag)) {
        readTagsData(tags, tagsPropertyMap[tag]!);
      }
    }
  };

  return async (dispatch: Dispatch) => {
    const dispatchError = (item: PromiseSettledResult<void>) => failure(item as PromiseRejectedResult);
    const { begin, success, failure } = initActions(dispatch, template);

    begin();

    let promises = [];

    if (includePublishers) {
      const publishersPromise = eventsDataService
        .getPublishersFilterOptions(showPurchased)
        .then((getPublishersResult: { data: IPublisherDataItem[] }) =>
          readPublishersData(getPublishersResult.data, EventFilterPropertyNames.Publishers),
        );

      promises.push(publishersPromise);
    }

    const packsPromise = DataService.packs
      .getPacksFilterOptions("externalEvent", showPurchased)
      .then((getPacksResult: { data: IPackDataItem[] }) =>
        readPacksData(getPacksResult.data, EventFilterPropertyNames.Packs),
      );

    const tagsPromise = eventsDataService
      .getEventsTagsAsync(showPurchased)
      .then((getTagsResult: { data: TagsPayloadCamelPlural }) => mapTags(getTagsResult.data));

    promises.push(packsPromise, tagsPromise);

    const result = await Promise.allSettled(promises);
    batch(() => {
      result.filter(getRejected).forEach((item) => dispatchError(item));

      if (result.some(hasFulfilled)) {
        success(filterOptions);
      }

      dispatch(setIsLoading(false));
    });
  };
};
