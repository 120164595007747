import { RootState } from "../../../../Application/globaltypes/redux";
import { connect } from "react-redux";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import { getPeopleEntityLabel } from "../../../../../utils/peopleAssignmentModalUtils";
import { getAssignedClosedCaptions } from "../../state/selectors/detailsSelector";
import { pluralize } from "../../../../../utils/stringUtils";
import { videosStateSelector } from "../../state/selectors";
import SelectedItemsLabel from "../../../../../components/sectionHeader/selectedItemsLabel/SelectedItemsLabel";

const mapStateToProps = (state: RootState, ownProps: any) => {
  const selectedUsersCount = videosStateSelector(state).edit.users.selectedIds.length;
  const selectedGroupsCount = videosStateSelector(state).edit.groups.selectedIds.length;
  const selectedPeopleCount = selectedUsersCount + selectedGroupsCount;
  const closedCaptionsSelectedCount = getAssignedClosedCaptions(state).selectedItems.length;

  let headerTitle;

  if (selectedPeopleCount) {
    headerTitle = (
      <SelectedItemsLabel
        itemName={getPeopleEntityLabel(selectedUsersCount, selectedGroupsCount)}
        selectedItemsAmount={selectedPeopleCount}
        shouldPluralize={false}
      />
    );
  } else if (closedCaptionsSelectedCount) {
    headerTitle = `${closedCaptionsSelectedCount} ${pluralize("File", closedCaptionsSelectedCount)} Selected`;
  } else {
    headerTitle = ownProps.title;
  }

  return {
    title: headerTitle,
  };
};

export default connect(mapStateToProps)(DetailsHeader);
