import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import { batch, connect, ConnectedProps } from "react-redux";
import { noop } from "lodash";
import { Dimmer, Loader } from "semantic-ui-react";

import { EditPdfSteps, showLastModified } from "./types";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { discardPdf as discardPdfAction, publishPdf as publishPdfAction } from "../state/actions/pdfEntityStateActions";
import { ISegment, bindAction, EnumDictionary, NotifyStepSettings } from "../../../../interfaces";
import { RolePermissions, UsersGroupsContext, PublishedStatusTypes, RouteNames } from "../../../../enums";
import { useWizardStepsManager } from "../../../../hooks/useWizardStepsManager";
import { useRtn } from "../../../../hooks/useRtn";
import { ShouldNotifyWithProgressIds } from "../../../SystemNotifications/types";
import { ModalHandlers, PdfPublishConfirmationParams } from "../types/models";
import { getPdf } from "../state/thunks/pdfDetailsThunk";
import { pdfsStateSelector } from "../selectors";
import { clearPdf } from "../state/slices/pdfDetailsSlice";
import { discardAction } from "../state/actions/commonPdfActions";
import { PdfModalsContainer } from "../common/PdfModalsContainer";
import {
  PdfDiscardSuccess,
  PdfLockSuccess,
  PdfPublishSuccess,
} from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import Segments from "../../../../components/navigation/segments/Segments";
import EditPdfSubheaderContainer from "./Containers/EditPdfSubheaderContainer";
import Observable from "../../../../utils/Observable";
import Restricted from "../../../Application/Restricted";
import EditPdfDetailsHeader from "./EditPdfDetailsHeader/EditPdfDetailsHeader";
import SettingsTab from "../Details/Settings/SettingsTab";
import EditPdfPeople from "./People/EditPdfPeople";
import Configure from "../Details/Configure/Configure";
import PdfPerformance from "../Performance/PdfPerformance";
import {
  resetSearch,
  resetPagination,
  resetSortingColumnName,
  resetSortingDirection,
  resetAppliedFilter,
} from "../state/slices/pdfFiltersSlice";
import { reset } from "../state/slices/pdfOverviewSlice";
import { resetTags } from "../state/slices/pdfBaseSlice";
import { ProtectedRoute } from "../../../../components/restrictedRoute/ProtectedRoute";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import AssociatedPacks from "features/Licensing/Packs/AssociatedPacks/AssociatedPacks";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

export type EditPdfPropsAll = PropsFromRedux;

const pdfsOverviewUrl = `/${RouteNames.contentPdfs}`;

export const EditPdf = (props: EditPdfPropsAll) => {
  const { discardPdf, publishPdf } = props;
  const showPacksTab = useFeatureFlag(FeatureFlags.AssociatedPacks);
  const isPublishedAndNotPurchased = !props.pdf.isDraft && !props.pdf.isPurchased;
  const params = useParams();
  const pdfId = Number(params.id);

  const pageIndexes = [
    EditPdfSteps.Performance,
    EditPdfSteps.Configuration,
    EditPdfSteps.Settings,
    EditPdfSteps.People,
    EditPdfSteps.AssociatedPacks,
  ];
  const [wizardPages, { goToPage, goBack }, subscribeOnActiveIndexChanged] = useWizardStepsManager(pageIndexes);
  const onDiscardedObserver = useMemo(() => new Observable<() => void>(), []);

  const [activeTab, setActiveTab] = useState(0);
  const [usersGroupsContext, setUsersGroupsContext] = useState(UsersGroupsContext.Groups);
  const modalHandlers = useRef<ModalHandlers>({ publish: () => noop, revert: () => noop, delete: () => noop });

  const pages: EnumDictionary<EditPdfSteps, ISegment> = useMemo(() => {
    return {
      [EditPdfSteps.Performance]: {
        to: "",
        label: "Performance",
        onClick: () => {
          goToPage(EditPdfSteps.Performance);
        },
      },
      [EditPdfSteps.Configuration]: {
        to: "configuration",
        label: "Configuration",
        onClick: () => {
          goToPage(EditPdfSteps.Configuration);
        },
      },
      [EditPdfSteps.Settings]: {
        to: "settings",
        label: "Settings",
        onClick: () => {
          goToPage(EditPdfSteps.Settings);
        },
      },
      [EditPdfSteps.People]: {
        to: "people",
        label: "People",
        onClick: () => {
          goToPage(EditPdfSteps.People);
        },
      },
      [EditPdfSteps.AssociatedPacks]: {
        to: "packs",
        label: "Associated Packs",
        onClick: () => {
          goToPage(EditPdfSteps.AssociatedPacks);
        },
      },
    };
  }, [goToPage]);

  const [isValid, setIsValid] = useState(true);
  useLayoutEffect(() => {
    const handler = (_: any, activeStepIndex: EditPdfSteps) => {
      pages[activeStepIndex].init?.();
      setActiveTab(activeStepIndex);
    };
    return subscribeOnActiveIndexChanged(handler);
  }, [pages, subscribeOnActiveIndexChanged]);

  useEffect(() => {
    if (!props.isLoaded) {
      props.getPdf(pdfId);
    }

    return () => {
      if (!window.location.pathname.includes(`/${RouteNames.contentPdfs}`)) props.resetSearchFilters();
      props.onUnmount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPublishedOrDiscardEvent = () => {
    onDiscardedObserver.notify();
    props.onDiscard(pdfId);
  };

  useRtn([PdfPublishSuccess, PdfDiscardSuccess, PdfLockSuccess], onPublishedOrDiscardEvent);

  const onRevertClick = () => modalHandlers.current.revert();

  const onPublishTrigger = async () => {
    const { pdf } = props;
    const { hasBeenPublished } = pdf;

    const assignmentsCount = hasBeenPublished ? 1 : 0;

    modalHandlers.current.publish({
      id: pdf.id,
      isDraft: pdf.isDraft,
      flowsCount: pdf.settings.flows.length,
      // https://brainstorm.atlassian.net/browse/SAAS-25549
      assignmentsCount: assignmentsCount,
      hasBeenPublished: hasBeenPublished,
    });
  };

  const handlePublish = useCallback(
    (id: number, notificationSettings?: NotifyStepSettings, peopleToNotifyTypes?: ShouldNotifyWithProgressIds[]) => {
      publishPdf(id, notificationSettings, peopleToNotifyTypes);
    },
    [publishPdf],
  );

  const back = () => {
    if (!goBack()) {
      return pdfsOverviewUrl;
    }
  };

  const handleRevert = useCallback(() => {
    discardPdf(pdfId);
  }, [discardPdf, pdfId]);

  const publishModal = useMemo(() => {
    return {
      acceptHandler: (handler: (params: PdfPublishConfirmationParams) => void) =>
        (modalHandlers.current.publish = handler),
      onConfirm: handlePublish,
    };
  }, [handlePublish]);

  const revertModal = useMemo(() => {
    return {
      acceptHandler: (handler: () => void) => (modalHandlers.current.revert = handler),
      enabled: props.pdf.hasBeenPublished,
      userId: props.userId,
      onConfirm: handleRevert,
    };
  }, [handleRevert, props.pdf.hasBeenPublished, props.userId]);

  return (
    <Restricted
      permissions={[RolePermissions.AssetsCreate]}
      renderContent={(hasPermission: boolean) => (
        <div className="nested-content edit-pdf scrollable-content">
          <EditPdfDetailsHeader
            pdfId={pdfId}
            pdfsOverviewUrl={pdfsOverviewUrl}
            title={props.pdf.title}
            canBeEdited={isPublishedAndNotPurchased}
            crudPermission={hasPermission}
            canBePublished={props.pdf.isDraft}
            isRevertVisible={props.pdf.hasBeenPublished}
            invalidFormDetails={!isValid}
            publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!props.pdf.isDraft)}
            hideEntityStateButtons={activeTab === EditPdfSteps.People}
            entityStateActions={{
              onRevert: onRevertClick,
              onPublish: onPublishTrigger,
            }}
            back={back}
            canAddPacks={isPublishedAndNotPurchased}
            showAddPacksButton={activeTab === EditPdfSteps.AssociatedPacks}
          />
          <PdfModalsContainer publishModal={publishModal} revertModal={revertModal} />
          <Dimmer active={props.isLoading} inverted>
            <Loader />
          </Dimmer>
          <EditPdfSubheaderContainer
            publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!props.pdf.isDraft)}
            isUpdateInProgress={props.pdf.isDraft}
            lastModifiedDateTime={props.pdf.dateModified}
            showLastModified={showLastModified.has(activeTab)}
          >
            <Segments to={`${pdfsOverviewUrl}/${pdfId}`}>
              <Segments.Segment {...pages[EditPdfSteps.Performance]} />
              <Segments.Segment {...pages[EditPdfSteps.Configuration]} />
              <Segments.Segment {...pages[EditPdfSteps.Settings]} />
              <Segments.Segment {...pages[EditPdfSteps.People]} />
              {showPacksTab && <Segments.Segment {...pages[EditPdfSteps.AssociatedPacks]} />}
            </Segments>
          </EditPdfSubheaderContainer>
          <div className="scrollable-content">
            <Routes>
              <Route
                path={pages[EditPdfSteps.Performance].to}
                element={
                  <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                    <div className="edit-form">
                      <PdfPerformance
                        acceptHandlers={wizardPages[EditPdfSteps.Performance]}
                        pdfId={pdfId}
                        pdfTitle={props.pdf.title}
                      />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path={pages[EditPdfSteps.Configuration].to}
                element={
                  <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                    <div className="edit-form">
                      <Configure
                        entityId={pdfId}
                        acceptHandlers={wizardPages[EditPdfSteps.Configuration]}
                        subscribeOnDiscarded={onDiscardedObserver.subscribe}
                        unSubscribeOnDiscarded={onDiscardedObserver.unsubscribe}
                        disabled={!props.pdf.isDraft}
                        mode={"edit"}
                        onIsValidChange={setIsValid}
                      />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path={pages[EditPdfSteps.Settings].to}
                element={
                  <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                    <div className="edit-form">
                      <SettingsTab acceptHandlers={wizardPages[EditPdfSteps.Settings]} />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path={pages[EditPdfSteps.People].to}
                element={
                  <ProtectedRoute permissions={[RolePermissions.AssetsView]}>
                    <EditPdfPeople
                      pdfId={pdfId}
                      acceptHandlers={wizardPages[EditPdfSteps.People]}
                      usersGroupsContext={usersGroupsContext}
                      onContextChanged={setUsersGroupsContext}
                    />
                  </ProtectedRoute>
                }
              />
              {showPacksTab && (
                <Route
                  path={pages[EditPdfSteps.AssociatedPacks].to}
                  element={
                    <AssociatedPacks
                      acceptHandlers={wizardPages[EditPdfSteps.AssociatedPacks]}
                      contentType={"Pdf"}
                      contentId={pdfId}
                      canAddPacks={isPublishedAndNotPurchased}
                      permissions={[RolePermissions.AssetsManage]}
                      permissionPredicate={permissionPredicateForPacks}
                    />}
                />)}
              <Route path="*" element={<Navigate to="../" replace />} />
            </Routes>
          </div>
        </div>
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const pdfs = pdfsStateSelector(state);
  const base = pdfs.base;
  return {
    pdf: base.details.pdf,
    isLoading: base.details.isLoading,
    isLoaded: base.details.isLoaded,
    userId: Number(state.userProfile.id),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  discardPdf: bindAction(discardPdfAction, dispatch),
  getPdf: bindAction(getPdf, dispatch),
  publishPdf: bindAction(publishPdfAction, dispatch),

  onDiscard: (id: number) => {
    batch(() => {
      dispatch(discardAction());
      dispatch(getPdf(id));
    });
  },

  onUnmount: bindAction(clearPdf, dispatch),
  resetSearchFilters: () => {
    batch(() => {
      dispatch(reset());
      dispatch(resetTags());
      dispatch(resetSearch());
      dispatch(resetPagination());
      dispatch(resetAppliedFilter());
      dispatch(resetSortingColumnName());
      dispatch(resetSortingDirection());
    });
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditPdf);
