import React from "react";
import { connect } from "react-redux";
import { FormikProps, withFormik } from "formik";
import { Grid, Header, Icon, InputOnChangeData } from "semantic-ui-react";
import { ValidatedForm } from "../../../../../../components";

import "./SectionHeaderForm.scss";
import { useReactFlowCanvasActions } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import validationSchemas from "../../../../../../utils/validationSchemas";
import { FlowSectionHeader } from "../../types";
import { FlowValidationError, ValidatorErrorTypes } from "../../validator/flowValidatorReducer";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { useStore } from "reactflow";
import { descriptionLength, nameLength } from "../../../../../../utils/validationSchemas/sectionHeaderValidationSchema";
import { flowValidatorSelector } from "../../../state/selectors";

export interface ISectionHeaderFormProps {
  sectionHeaderView: string | undefined;
  sectionHeader: FlowSectionHeader;
  isReadOnly: boolean;
  onTitleChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
  onDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
  isErrorViewMode: boolean;
  errorViewErrors: FlowValidationError[];
}

export const SectionHeaderForm: React.FC<FormikProps<FlowSectionHeader> & ISectionHeaderFormProps> = (props) => {
  const {
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    dirty,
    resetForm,
    isErrorViewMode,
    errorViewErrors,
  } = props;
  const nameErrors =
    isErrorViewMode &&
    errorViewErrors.find(
      (error: FlowValidationError) =>
        error.type === ValidatorErrorTypes.SectionHeaderNameError && error.itemId === props.sectionHeaderView,
    );
  const descriptionErrors =
    isErrorViewMode &&
    errorViewErrors.find(
      (error: FlowValidationError) =>
        error.type === ValidatorErrorTypes.SectionHeaderDescriptionError && error.itemId === props.sectionHeaderView,
    );

  const newErrors = {
    ...errors,
  };
  const newTouched = {
    ...touched,
  };

  if (isErrorViewMode) {
    newErrors.name = nameErrors ? nameErrors?.errorMessage : undefined;
    newErrors.description = descriptionErrors ? descriptionErrors?.errorMessage : undefined;

    newTouched.name = nameErrors ? !!nameErrors : undefined;
    newTouched.description = descriptionErrors ? !!descriptionErrors : undefined;
  }

  const validatedFieldProps = {
    errors: newErrors,
    touched: newTouched,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    dirty,
    resetForm,
  };
  const resetSelectedElements = useStore((actions) => actions.resetSelectedElements);
  const { setSectionHeaderView, setShowTriggers } = useReactFlowCanvasActions();

  const onCloseHandler = () => {
    setShowTriggers(false);
    setSectionHeaderView(undefined);
    resetSelectedElements();
  };

  return (
    <div className="section-header-form" data-testid="section-header-form">
      <div className="section-header-form-header">
        <Header floated="left">Section Header</Header>
        <div className="close-bar-icon" role="button" data-testid="close-section-header-form" onClick={onCloseHandler}>
          <Icon className="times outline with-label" size="large" />
        </div>
      </div>

      <ValidatedForm disablePreventTransitionPrompt formWidthComputer={16}>
        <Grid columns={1}>
          <Grid.Column width={16}>
            <ValidatedForm.InputField
              markAsRequired
              label="Section Header Name"
              propertyName="name"
              value={props.values.name}
              errorPosition="bottom"
              disabled={props.isReadOnly}
              {...validatedFieldProps}
              maxLength={nameLength.max}
              handleChange={props.onTitleChange}
            />
            <ValidatedForm.TextAreaField
              markAsRequired
              label="Description"
              propertyName="description"
              value={props.values.description}
              errorPosition="bottom"
              disabled={props.isReadOnly}
              {...validatedFieldProps}
              handleChange={props.onDescriptionChange}
              maxLength={descriptionLength.max}
            />
          </Grid.Column>
        </Grid>
      </ValidatedForm>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isErrorViewMode: flowValidatorSelector(state).isErrorViewMode,
  errorViewErrors: flowValidatorSelector(state).errorViewErrors,
});

export default connect(mapStateToProps)(
  withFormik<ISectionHeaderFormProps, FlowSectionHeader>({
    validationSchema: validationSchemas.sectionHeaderInfo,
    mapPropsToValues: (props) => {
      const { sectionHeader, ...anotherProps } = props;
      return {
        ...anotherProps,
        ...sectionHeader,
      };
    },
    handleSubmit: () => {}, //NOSONAR
  })(SectionHeaderForm),
);
