import React, { Component } from "react";
import PropTypes from "prop-types";

import Tabs from "../tabs/Tabs";
import UploadImage from "./upload/UploadImage";
import { Modal } from "../modal";
import NoResults from "../noResults/NoResults";
import ImageUploaderModalButtons from "./ImageUploaderModalButtons";

import fileUtils from "../../utils/fileUtils";
import getSasConfig from "./sasConfig";
import ImageAspectRatio from "../../enums/imageAspectRatio";

import "./imageUploaderModal.scss";

class ImageUploaderModal extends Component {
  state = {
    croppedImage: null,
    isUploading: false,
    selectedFile: null,
    fileName: {},
  };

  setSelectedFile = (file) => {
    this.setState({ selectedFile: file });
  };

  setFileName = (fileName) => {
    this.setState({ fileName });
  };

  handleLooksGood = () => {
    this.setState({ isUploading: true });
    const uploadOptions = {
      contentType: this.state.croppedImage.type,
    };

    return fileUtils
      .uploadFileToBlobStorage(
        this.state.croppedImage,
        uploadOptions,
        null,
        null,
        getSasConfig(this.props.endpointToGetSasLink),
      )
      .then((result) => {
        this.props.onImageChange && this.props.onImageChange(result.uri, result.fileName);
        this.setState({ isUploading: false });
        this.resetUploadedFile();

        this.closeModal();
      })
      .catch(() => {
        this.setState({ isUploading: false });
      });
  };

  handleCroppedImageChange = (image) => {
    this.setState({
      croppedImage: image,
    });
  };

  resetUploadedFile() {
    this.setState({
      selectedFile: null,
      fileName: {},
    });
  }

  closeModalWindow(closeModal) {
    this.resetUploadedFile();
    closeModal();
  }

  renderModalActions = (closeModal) => {
    this.closeModal = closeModal;
    return (
      <ImageUploaderModalButtons
        closeModal={() => this.closeModalWindow(closeModal)}
        handleLooksGood={this.handleLooksGood}
        isUploading={this.state.isUploading}
        canSubmit={!!this.state.croppedImage && (!!this.state.selectedFile || !!this.props.value)}
      />
    );
  };

  renderImageUploadSection() {
    const { index } = this.props;
    return (
      <Tabs.Pane label="Image Gallery" key={`${index}.uploadPane`}>
        <div className="image-upload-section">
          <NoResults description="coming soon" />
        </div>
      </Tabs.Pane>
    );
  }

  renderUploadPane() {
    const { selectedFile, fileName } = this.state;
    const { aspectRatio, index, value, getImageCustomValidator } = this.props;
    return (
      <Tabs.Pane label="Upload" key={`${index}.uploadPane`}>
        <div className="image-upload-section upload-image">
          <UploadImage
            onCroppedImageChange={this.handleCroppedImageChange}
            file={selectedFile || value}
            onFileChanged={this.setSelectedFile}
            fileName={fileName}
            onFileNameChanged={this.setFileName}
            aspectRatio={aspectRatio}
            getImageCustomValidator={getImageCustomValidator}
          />
        </div>
      </Tabs.Pane>
    );
  }

  renderTabs = () => {
    const panels = [this.renderUploadPane()];
    if (!this.props.isHidden) {
      panels.push(this.renderImageUploadSection());
    }
    return <Tabs>{panels}</Tabs>;
  };

  render() {
    const { trigger } = this.props;
    return (
      <Modal
        title="Select Image"
        renderTrigger={trigger}
        className="upload-image-modal"
        renderModalActions={this.renderModalActions}
        onCancel={() => this.closeModalWindow(this.closeModal)}
        size={"large"}
      >
        {this.renderTabs()}
      </Modal>
    );
  }
}

ImageUploaderModal.defaultProps = {
  aspectRatio: ImageAspectRatio.HD_16X9,
  isHidden: true,
  getImageCustomValidator: null,
};

ImageUploaderModal.propTypes = {
  value: PropTypes.string,
  index: PropTypes.string,
  trigger: PropTypes.func,
  onImageChange: PropTypes.func,
  endpointToGetSasLink: PropTypes.string,
  isHidden: PropTypes.bool,
  getImageCustomValidator: PropTypes.func,
  aspectRatio: PropTypes.oneOf([ImageAspectRatio.HD_16X9, ImageAspectRatio.SQUARE_1X1]),
};

export default ImageUploaderModal;
