import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DropdownProps, Icon } from "semantic-ui-react";
import { FieldPropsBase } from "./models";

import ValidatedField from "./ValidatedField";
import { DropdownWrapper } from "../../../../components";
import momentTZ from "moment-timezone";
import { isWhiteSpace } from "../../../../utils/stringUtils";
import classNames from "classnames";

import "./timeZonePicker.scss";

export const TimeZonePicker = (props: TimeZonePickerProps) => {
  const {
    classname,
    lazyLoad,
    disableValidation,
    value,
    propertyName,
    onBlur,
    disabled,
    setFieldValue,
    selfValidation,
    setFieldTouched,
    onMount,
    onUnmount,
    direction,
    fluid,
  } = props;

  useEffect(() => {
    onMount?.(propertyName);

    return () => {
      onUnmount?.(propertyName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    setFieldTouched(propertyName, true, false);

    const result = isWhiteSpace(data.value) ? "" : data.value;
    setFieldValue(propertyName, result, !selfValidation && !disableValidation);

    onBlur?.(propertyName);
  };

  const timezones = useMemo(
    () =>
      momentTZ.tz.names().map((item) => {
        return {
          key: item,
          text: `${item}`,
          value: item,
        };
      }),
    [],
  );

  const blurHandler = useCallback(() => {
    onBlur?.(propertyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onBlur]);

  const [isFocused, setIsFocused] = useState(false);
  const handleClose = () => setIsFocused(false);
  const handleOpen = () => setIsFocused(true);

  return (
    <ValidatedField {...props}>
      <div className={classNames("time-zone-picker", classname, { disabled, selected: isFocused, fluid })}>
        <Icon className="fa-clock" disabled={disabled} />
        <DropdownWrapper
          fluid
          inline
          value={value}
          id={propertyName}
          name={propertyName}
          handleOptionChange={handleChange}
          onBlur={blurHandler}
          items={timezones}
          className="field"
          multiple={false}
          clearable={false}
          lazyLoad={lazyLoad}
          disabled={disabled}
          onOpen={handleOpen}
          onClose={handleClose}
          direction={direction}
        />
      </div>
    </ValidatedField>
  );
};

export interface TimeZonePickerProps extends FieldPropsBase<string | boolean | number | undefined> {
  lazyLoad?: boolean;
  classname?: string;
  label?: string;
  direction?: "left" | "right";
  fluid?: boolean;
}

export default React.memo(TimeZonePicker);
