import {
  makeActionCreator,
  buildFetchAction,
  buildRootEntityActionType,
} from "../../../Application/actions/actionsBuilder";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import * as searchActionTypes from "../../../Application/actions/search/searchActionTypes";

const delimiter = "__";
const fetchAction = buildFetchAction(ReducerNamespaceTypes.People, ReducerEntityPrefixTypes.Roles);
const potentialRoleUsers = "POTENTIAL_USERS";

export const fetchRolesItemsActions = {
  begin: makeActionCreator(fetchAction.FETCH_ITEMS_BEGIN),
  success: makeActionCreator(fetchAction.FETCH_ITEMS_SUCCESS, "items", "itemsCount"),
  failure: makeActionCreator(fetchAction.FETCH_ITEMS_FAILURE, "error"),
};

export const fetchPotentialUsers = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Roles,
  potentialRoleUsers,
);

export const FETCH_USER_ROLES_ASSIGNMENTS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Roles,
);

export const FETCH_ROLE_USERS_ASSIGNMENTS = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.RoleUsersAssignments,
);

export const USERS_ADDED_TO_ROLE = "USERS_ADDED_TO_ROLE";

export const PEOPLE_ROLES_USERS_TO_ADD_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Roles.concat(delimiter, ReducerEntityPrefixTypes.RoleUsersToAdd),
  searchActionTypes.SET_SEARCH,
);

export const PEOPLE_ROLES_USERS_TO_ADD_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Roles.concat(delimiter, ReducerEntityPrefixTypes.RoleUsersToAdd),
  searchActionTypes.RESET_SEARCH,
);

export const FETCH_ROLES_USERS_TO_ADD = buildFetchAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.RoleUsersToAdd,
);
