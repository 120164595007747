import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { FlowGroup } from "../../types";
import { createSelectedItemsSlice, SelectedItemsState } from "../../../../Application/slices/createSelectedItemsSlice";

export interface FlowGroupsState extends SelectedItemsState<FlowGroup> {}

const initialState: FlowGroupsState = {
  items: [],
  itemsCount: 0,
  selectedIds: [],
  isLoading: false,
  error: undefined,
  selectedItems: [],
};

const flowGroupsSlice = createSelectedItemsSlice(
  { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Flows, name: "groups" },
  initialState,
);

export const { fetchBegin, fetchSuccess, fetchFailure, selectedItemsChanged, resetSelectedItems } =
  flowGroupsSlice.actions;

export default flowGroupsSlice.reducer;
export type flowGroupsSliceStateType = ReturnType<typeof flowGroupsSlice.reducer>;
