import { withFormik, setIn, FormikProps } from "formik";
import { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../../../Application/globaltypes/redux";
import { SettingsSmtpAuthSendingProfileProps } from "../types";
import { SMTP_PASSWORD_PLACEHOLDER } from "../../types/constants";
import { ThreatDefenceSmtpAuthSendingProfileSettingsView } from "../../types/state";
import * as smtpAuthSendingProfileEntityStateActions from "../../state/actions/threatDefenceSmtpAuthSendingProfileEntityStateActions";
import * as smtpAuthSendingProfileDetailsActions from "../../state/actions/threatDefenceSmtpAuthSendingProfileDetailsActions";
import { sendingProfileSettingsValidationSelector } from "../../state/slices/sendingProfileSettingsValidationSlice";
import schemasUtils from "../../../../../../../utils/validationSchemasUtils";
import { AutosaveProps, withAutosave } from "../../../../../../../utils/withAutosave";
import SettingsForm from "../SmtpAuthSendingProfileSettingsForm/SettingsForm";
import validationSchemas from "../../../../../../../utils/validationSchemas";
import { FormikTouched } from "formik/dist/types";

export type SettingsAllProps = SettingsSmtpAuthSendingProfileProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceSmtpAuthSendingProfileSettingsView> &
  AutosaveProps;

export const Settings = (props: SettingsAllProps) => {
  const valuesRef = useRef(props.values);
  useEffect(() => {
    valuesRef.current = props.values;
  }, [props.values]);

  useEffect(() => {
    props.acceptHandlers?.({
      onNext: onNext,
      onPrevious: onPrevious,
      onClose: onClose,
    });
    props.subscribeOnDiscarded?.(props.resetForm);
    return () => {
      props.unSubscribeOnDiscarded?.(props.resetForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNext = () => {
    saveSettings();
  };

  const onPrevious = () => {
    saveSettings();
  };

  const onClose = () => {
    props.detailsActions.clearThreatDefenceSmtpAuthSendingProfile();
  };

  const saveSettings = () => {
    props.detailsActions.saveThreatDefenceSmtpAuthSendingProfileSettings(valuesRef.current);
  };

  return <SettingsForm {...props} />;
};

Settings.getSendingProfileSettings = (
  values: ThreatDefenceSmtpAuthSendingProfileSettingsView,
): ThreatDefenceSmtpAuthSendingProfileSettingsView => ({
  ...values,
  host: values.host?.trim(),
  userName: values.userName?.trim(),
  password: values.password === SMTP_PASSWORD_PLACEHOLDER ? "" : values.password,
});

Settings.getFieldsTouched = (
  isErrorViewMode: boolean,
  activeErrors: { [id: string]: string },
): FormikTouched<ThreatDefenceSmtpAuthSendingProfileSettingsView> => {
  if (isErrorViewMode) {
    let touched: FormikTouched<ThreatDefenceSmtpAuthSendingProfileSettingsView> = {};
    Object.keys(activeErrors).forEach((key: string) => {
      touched = setIn(touched, key, true);
    });
    return touched;
  }
  return {};
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: SettingsSmtpAuthSendingProfileProps) => {
  const sendingProfileEntityStateReducer =
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileEntityStateReducer;
  const sendingProfileDetailsReducer =
    state.library.threatDefenceSmtpAuthSendingProfiles.threatDefenceSmtpAuthSendingProfileDetailsReducer;

  return {
    id: ownProps.entityId || sendingProfileEntityStateReducer.entityId,
    settings: sendingProfileDetailsReducer.settings,
    isCreating: sendingProfileEntityStateReducer.changingEntityState,
    isLoaded: sendingProfileDetailsReducer.isSendigProfileSettingsLoaded,
    isLoading: sendingProfileDetailsReducer.isLoading,
    isDraft: sendingProfileDetailsReducer.sendingProfile.isDraft,
    validation: sendingProfileSettingsValidationSelector(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(smtpAuthSendingProfileEntityStateActions, dispatch),
  detailsActions: bindActionCreators(smtpAuthSendingProfileDetailsActions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<
  SettingsAllProps,
  ThreatDefenceSmtpAuthSendingProfileSettingsView,
  ThreatDefenceSmtpAuthSendingProfileSettingsView
>({
  getInitValues: (props) => props.settings,
  stateProvider: Settings.getSendingProfileSettings,
  entityUpdater: (props) => props.entityStateActions.updateThreatDefenceSmtpAuthSendingProfileSettings,
})(Settings);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.smtpAuthSendingProfileSettings,
  enableReinitialize: true,
  validateOnMount: true,
  isInitialValid: (props: SettingsSmtpAuthSendingProfileProps & PropsFromRedux) =>
    schemasUtils.isValidSync(validationSchemas.smtpAuthSendingProfileSettings, props.settings),
  mapPropsToValues: (props: SettingsSmtpAuthSendingProfileProps & PropsFromRedux) => props.settings,
  mapPropsToTouched: (props: SettingsSmtpAuthSendingProfileProps & PropsFromRedux) =>
    Settings.getFieldsTouched(props.validation.errorMode, props.validation.activeErrors),
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
