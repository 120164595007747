import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { FormikProps } from "formik";
import { withFormik } from "../../../../../utils/formikUtils";

import * as assessmentEntityStateActions from "../../state/actions/assessmentEntityStateActions";
import * as assessmentDetailsActions from "../../state/actions/assessmentDetailsActions";

import validationSchemas from "../../../../../utils/validationSchemas";
import Restricted from "../../../../Application/Restricted";
import AssessmentForm from "../AssessmentForm/AssessmentForm";

import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { AssessmentPayload, AssessmentView } from "../../types/state";
import { ConfigurePropsOwn } from "../types";
import { AutosaveProps, withAutosave } from "../../../../../utils";
import { RolePermissions } from "../../../../../enums";
import { assessmentsStateSelector } from "../../state/selectors";

export type ConfigurePropsAll = ConfigurePropsOwn & PropsFromRedux & FormikProps<AssessmentView> & AutosaveProps;

export class Configure extends Component<ConfigurePropsAll> {
  componentDidMount() {
    this.props.subscribeOnDiscarded?.(this.props.resetForm);
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });
  }

  componentWillUnmount() {
    this.props.unSubscribeOnDiscarded?.(this.props.resetForm);
  }

  onNext = () => {
    const { entityStateActions, values } = this.props;
    this.saveAssessment();
    if (!this.isAssessmentCreated()) {
      entityStateActions.createAssessment(Configure.getAssessment(values));
    }
  };

  onPrevious = () => {
    this.saveAssessment();
  };

  onClose = () => {
    this.props.entityStateActions.resetAssessmentEntityState();
  };

  saveAssessment = () => {
    this.props.detailsActions.saveAssessment(this.props.values);
  };

  static getAssessment = (values: AssessmentView): AssessmentPayload => {
    return {
      title: values.title.trim(),
      description: values.description.trim(),
      labels: values.labels,
      softwareApplications: values.softwareApplications,
      showCorrectAnswers: values.showCorrectAnswers,
      percentageRequired: values.percentageRequired,
      percentageValue: Number(values.percentageValue),
      attemptsAllowed: values.attemptsAllowed,
      attemptsCount: Number(values.attemptsCount),
      periodValue: Number(values.periodValue),
      periodType: values?.periodType,
    } as AssessmentPayload;
  };

  isAssessmentCreated = () => this.props.id > 0 || this.props.isCreating;

  render() {
    return (
      <Restricted
        permissions={[RolePermissions.AssetsCreate]}
        renderContent={(hasPermission) => (
          <AssessmentForm
            disablePreventTransitionPrompt={this.isAssessmentCreated()}
            {...this.props}
            disabled={this.props.disabled || !hasPermission}
          />
        )}
      />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ConfigurePropsOwn) => {
  const base = assessmentsStateSelector(state).base;

  return {
    id: ownProps.entityId || base.assessmentEntityStateReducer.entityId,
    assessment: base.assessmentDetailsReducer.assessment,
    isLoaded: base.assessmentDetailsReducer.isAssessmentLoaded,
    isLoading: base.assessmentDetailsReducer.isAssessmentLoading || base.assessmentDetailsReducer.isContentLoading,
    isCreating: base.assessmentEntityStateReducer.changingEntityState,
    isDraft: base.assessmentDetailsReducer.assessment.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  entityStateActions: bindActionCreators(assessmentEntityStateActions, dispatch),
  detailsActions: bindActionCreators(assessmentDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ConfigurePropsAll, AssessmentView, AssessmentPayload>({
  getInitValues: (props) => Configure.getAssessment(props.assessment),
  stateProvider: Configure.getAssessment,
  entityUpdater: (props) => props.entityStateActions.updateAssessment,
})(Configure);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.assessmentInfo,
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: (props: ConfigurePropsOwn & PropsFromRedux) => props.assessment,

  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
