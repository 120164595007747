import { connect } from "react-redux";
import { DetailsSubHeader } from "../../../../../components/sectionHeader";
import { Props as DetailsSubHeaderProps } from "../../../../../components/sectionHeader/sectionSubHeader/SectionSubHeader";
import { RootState } from "../../../../Application/globaltypes/redux";
import { pdfsStateSelector } from "../../selectors";

const mapStateToProps = (state: RootState, ownProps: DetailsSubHeaderProps & { showLastModified: boolean }) => {
  const base = pdfsStateSelector(state).base;
  return {
    isUpdateInProgress: base.pdfEntityStateReducer.isEntityCommandInProgress && ownProps.isUpdateInProgress,
    lastModifiedDateTime: ownProps.showLastModified
      ? base.pdfEntityStateReducer.lastModifiedDateTime ?? ownProps.lastModifiedDateTime
      : undefined,
  };
};

export default connect(mapStateToProps)(DetailsSubHeader);
