import * as d3 from "d3";
import { textEllipsis } from "../../shared/helpers";
import { wrappedGetComputedTextLength } from "../../shared/textLength";
import { EntityType } from "../../types/Sankey";

type D3TextSelection = d3.Selection<SVGTextElement, any, d3.BaseType, any>;

export const lookupIconClass = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.Video:
      return "inverted fitted grey video icon";
    case EntityType.Assessment:
      return "inverted fitted grey list ol icon";
    case EntityType.Survey:
      return "inverted fitted grey list ul icon";
    case EntityType.Email:
      return "inverted fitted grey envelope icon";
    case EntityType.Event:
      return "inverted fitted grey calendar alternate icon";
    case EntityType.Pdf:
      return "inverted fitted grey file alternate icon";
    default:
      return "inverted fitted grey archive icon";
  }
};

export const lookupEntityText = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.Video:
      return "video".toLocaleUpperCase();
    case EntityType.Assessment:
      return "assessment".toLocaleUpperCase();
    case EntityType.Survey:
      return "survey".toLocaleUpperCase();
    case EntityType.Email:
      return "email".toLocaleUpperCase();
    case EntityType.Event:
      return "event".toLocaleUpperCase();
    case EntityType.Pdf:
      return "pdf".toLocaleUpperCase();
    default:
      return "unknown".toLocaleUpperCase();
  }
};

export const lookupEntityColor = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.Video:
      return "#288bed";
    case EntityType.Assessment:
      return "#ef9e08";
    case EntityType.Survey:
      return "#60c794";
    case EntityType.Email:
      return "#686569";
    case EntityType.Event:
      return "#f26a5e";
    case EntityType.Pdf:
      return "#232328";
    default:
      return "#288bed";
  }
};

/* istanbul ignore next */
export function wrap(textSelection: D3TextSelection, width: number, padding: number) {
  textSelection.each((_d, i, n) => {
    let text = d3.select(n[i]),
      words = text.text().split(/\s+/).reverse(),
      word: string | undefined,
      line: string[] = [],
      lineNumber = 0,
      lineHeight = 1.2, // ems
      x = text.attr("x"),
      y = text.attr("y"),
      dy = 0,
      tspan = text
        .text(null)
        .append("tspan")
        .attr("class", "titleSpan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");

    const nodeGroup = n[i].parentNode as SVGGElement;
    const rect = d3.select(nodeGroup).select("rect");
    if (rect.empty()) return;
    // 14px tall text elements at the top/bottom, +5 each for spacing
    const maxHeight = +rect.attr("height") - 38;

    while (words.length > 0) {
      word = words.pop() as string;
      line.push(word);
      tspan.text(line.join(" "));
      const node = tspan.node() as SVGTSpanElement;
      // Left/right side need padding
      if (wrappedGetComputedTextLength(node) > width - 2 * padding) {
        // Wrapping needs to occur
        if (line.length === 1) {
          // Only one line of text? Split word for wrapping
          textEllipsis(node, width, padding, [null, null, null], "");
          const amountOfLettersUsed = tspan.text().length;
          word = word.substring(amountOfLettersUsed);
        } else {
          line.pop();
          tspan.text(line.join(" "));
        }
        line = [word];
        tspan = text
          .append("tspan")
          .attr("class", "titleSpan")
          .attr("x", x)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
      }
      // Has the newly added line pushed us over the edge?
      if ((lineNumber + 1) * 14 * lineHeight > maxHeight) {
        // Remove the last line that is overlapping
        tspan.remove();
        // Re-add the long word
        const lastTspan = text
          .selectAll<SVGTSpanElement, unknown>("tspan")
          .filter((_spanData, index, list) => index === list.length - 1);
        lastTspan.text(lastTspan.text() + " " + word);
        // Render ellipsis
        textEllipsis(lastTspan.node() as SVGTSpanElement, width, padding);
        break;
      }
    }
  });
}
