import * as Yup from "yup";

import { labelValues, softwareApplicationValues } from "./tagsValidationSchema";
import { IAnswerBase, IQuestionBase } from "../../features/Library/Common/models";
import { AssessmentQuestionType, Question } from "../../features/Library/Assessments/types/state";
import { requiredTextFieldMinMax } from "./commonValidationSchemas";

const nameLengths = {
  min: 3,
  max: 64,
};

export const descriptionLengths = {
  min: 3,
  max: 256,
};

export const assessmentInfo = Yup.object().shape({
  title: requiredTextFieldMinMax("Name", nameLengths.min, nameLengths.max),
  description: requiredTextFieldMinMax("Description", descriptionLengths.min, descriptionLengths.max),
});

export const assessmentSettings = Yup.object().shape({
  labels: labelValues,
  softwareApplications: softwareApplicationValues,
});

const getQuestionValidationSchema = (type: AssessmentQuestionType) => questionSchemas[type];

const questionSchemas = (() => {
  const pictureQuestionValidation = Yup.object().shape({
    url: Yup.string().trim().required("Url is required"),
  });
  const textQuestionValidation = Yup.object().shape({
    text: Yup.string().trim().required("Question text is required"),
  });

  return {
    [AssessmentQuestionType.MultipleChoiceTextQuestion]: textQuestionValidation,
    [AssessmentQuestionType.SingleChoiceTextQuestion]: textQuestionValidation,
    [AssessmentQuestionType.PictureQuestion]: pictureQuestionValidation,
  };
})();

const getAnswerValidationSchema = (type: AssessmentQuestionType, answers: IAnswerBase[]) =>
  answerSchemas(answers)[type];

const answerSchemas = (answers: IAnswerBase[]) => {
  const pictureQuestionValidation = Yup.object().shape({
    url: Yup.string().trim().required("Url is required"),
  });
  const singleChoiceTextQuestionValidation = Yup.object().shape({
    text: Yup.string().trim().required("Answer text required"),
  });
  const multipleChoiceTextQuestionValidation = Yup.object().shape({
    text: Yup.string().trim().required("Answer text required"),
    isCorrect: Yup.boolean().test("isCorrect", "Please check one checkbox", () =>
      answers.some((a: IAnswerBase) => a.answer.isCorrect),
    ),
  });

  return {
    [AssessmentQuestionType.SingleChoiceTextQuestion]: singleChoiceTextQuestionValidation,
    [AssessmentQuestionType.MultipleChoiceTextQuestion]: multipleChoiceTextQuestionValidation,
    [AssessmentQuestionType.PictureQuestion]: pictureQuestionValidation,
  };
};

export const assessmentContent = Yup.object().shape({
  questions: Yup.array().of(
    Yup.lazy((question: IQuestionBase<Question>) => {
      return Yup.object().shape({
        question: getQuestionValidationSchema(question.questionType),
        answers: Yup.array().of(
          Yup.object().shape({
            answer: getAnswerValidationSchema(question.questionType, question.answers),
          }),
        ),
      });
    }) as any,
  ),
});
