import { FC, useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";

import { RootState } from "../../../../Application/globaltypes/redux";
import * as reportingActions from "../../../state/packs/packActions";
import { selectFormattedPackLicensesData, selectPackLicensesState } from "../../../state/packs/packSlice";

import SpPackReportDrilldownList from "./SpPackReportDrilldownList";
import { RequestStatusRenderer } from "../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { usePerformanceBreadcrumbs } from "../../../../Library/Common/Hooks/usePerformanceBreadcrumbs";
import PackPurchasedPerformance from "../../../../Licensing/Packs/PurchasedView/Performance/PackPurchasedPerformance";

import "./spPackReportDrilldown.scss";
import { AccountTypes } from "features/Accounts/types";
export interface Props extends PropsFromRedux {
  packId: number;
  packName: string;
  resetSelectedPack: () => void;
  customerId: number;
}

const isAllowedAccountTypes = [AccountTypes.BSI, AccountTypes.ChannelPartner, AccountTypes.MSP, AccountTypes.StrategicPartner];

export const SpPackReportDrilldown: FC<Props> = ({
  packName,
  packId,
  packLicensesState,
  packLicensesData,
  resetSelectedPack,
  customerId,
  actions,
  accountType
}) => {
  // Selected customer logic for BSI customers only
  const [selectedCustomer, setSelectedCustomer] = useState<{ customerId: number; customerName: string; }>();
  const { domElements, registerBreadcrumb } = usePerformanceBreadcrumbs();

  useEffect(() => {
    if (accountType === AccountTypes.ChannelPartner || accountType === AccountTypes.MSP) {
      actions.fetchPackLicenses(packId, customerId);
    } else {
      actions.fetchPackLicenses(packId);
    }
  }, [actions, packId, customerId, accountType]);

  const resetCustomerId = useCallback(() => setSelectedCustomer(undefined), []);

  useEffect(() => {
    if (selectedCustomer) {
      registerBreadcrumb({ text: selectedCustomer.customerName, action: resetCustomerId });
    }
  }, [selectedCustomer, resetCustomerId, registerBreadcrumb]);

  useEffect(() => {
    registerBreadcrumb({ text: packName, action: resetSelectedPack });
  }, [packName, resetSelectedPack, registerBreadcrumb]);

  return (
    <>
      <div className="breadcrumbSpacing">{domElements}</div>
      {isAllowedAccountTypes.includes(accountType) && selectedCustomer ? (
        <>
          <PackPurchasedPerformance packId={packId} moboId={selectedCustomer.customerId} />
        </>
      ) : (
        <RequestStatusRenderer state={packLicensesState}>
          <SpPackReportDrilldownList
            reduxFinished
            packLicensesData={packLicensesData}
            setSelectedCustomer={setSelectedCustomer}
          />
        </RequestStatusRenderer>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  packLicensesState: selectPackLicensesState(state),
  packLicensesData: selectFormattedPackLicensesData(state),
  accountType: state.userProfile.accountTypeId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(reportingActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SpPackReportDrilldown);
