import { Tooltip } from "components/common/tooltip";
import Form from "components/forms/layout";
import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Icon, Radio } from "semantic-ui-react";
import { ValidatedForm } from "../../../../components";
import { IntegrationTypes, MsGraphIntegrationConfigs } from "../types";
import "./integrationConfiguration.scss";
import {
  getImportLicensesTooltipMessage,
  getImportUsageDataTooltipMessage,
  getSyncUsersTooltipMessage,
  getImportGroupsTooltipMessage,
} from "./tooltipsHelpers";

export interface MsGraphIntegrationConfigurationProps {
  isCreating?: boolean;
  integrationName: string;
  setConfigs: Function;
  configs: MsGraphIntegrationConfigs;
}

const MsGraphIntegrationConfiguration: React.FC<MsGraphIntegrationConfigurationProps> = (props) => {
  const { integrationName, isCreating, setConfigs, configs } = props;
  const isLicensesFilterEnabled = !!useFeatureFlag(FeatureFlags.LicenseGroupsFilter);
  const setFieldValue = (id: string) => {
    let changedConfigsState: MsGraphIntegrationConfigs = {
      ...configs,
    };

    switch (id) {
      case "syncUsers":
        if (configs.syncUsers && !configs.importUsageData) {
          changedConfigsState.importUsageData = !configs.importUsageData;
        }
        changedConfigsState.importLicenses = !configs.syncUsers;
        changedConfigsState.importGroups = !configs.syncUsers;
        changedConfigsState.licensedOnly = configs.syncUsers ? true : configs.licensedOnly;
        changedConfigsState.syncUsers = !configs.syncUsers;
        break;
      case "importUsageData":
        if (configs.importUsageData && !configs.syncUsers) {
          changedConfigsState.syncUsers = !configs.syncUsers;
        }

        changedConfigsState.importLicenses = !configs.syncUsers ? true : configs.importLicenses;
        changedConfigsState.licensedOnly = !configs.syncUsers ? true : configs.licensedOnly;
        changedConfigsState.importGroups = !configs.syncUsers ? true : configs.importGroups;

        changedConfigsState.importUsageData = !configs.importUsageData;
        break;
      case "isActive":
        changedConfigsState.isActive = !configs.isActive;
        break;
      case "importLicenses":
        changedConfigsState.importLicenses = !configs.importLicenses;
        break;
      case "importGroups":
        changedConfigsState.importGroups = !configs.importGroups;
        break;
    }
    setConfigs({ ...changedConfigsState });
  };

  const setLicensedOnlyFieldValue = (value: boolean) => {
    setConfigs({ ...configs, licensedOnly: value });
  };

  return (
    <>
      <div className="integration-configuration-block">
        {isCreating && <Form.Block title="Integration type">{integrationName}</Form.Block>}
        <div className="options-block">
          <div className="options-title">
            <span>
              <b>DATA IMPORT SETTINGS</b>
              (At least one selection must be made)
            </span>
          </div>
          <div className="option-with-tooltip">
            <ValidatedForm.CheckboxField
              setFieldValue={setFieldValue}
              toggle
              label="Users"
              value={configs.syncUsers}
              propertyName="syncUsers"
            />
            <Tooltip
              hoverable
              tooltipClassName="integraion-setting-tooltip"
              target={<Icon className="info circle" />}
              content={getSyncUsersTooltipMessage(IntegrationTypes.MsGraph)}
              position="top center"
              hideOnScroll
              showAlways
            />
          </div>
          {configs.syncUsers && (
            <>
              <div className="licensed-radio-options">
                <div>
                  <Radio
                    id="allUsersRadio"
                    className="licensed-radio-toggle"
                    label="All"
                    checked={!configs.licensedOnly}
                    onClick={() => setLicensedOnlyFieldValue(false)}
                  />
                </div>
                <div>
                  <Radio
                    id="licensedOnlyRadio"
                    className="licensed-radio-toggle"
                    label="Licensed"
                    checked={configs.licensedOnly}
                    onClick={() => setLicensedOnlyFieldValue(true)}
                  />
                </div>
              </div>
              {isLicensesFilterEnabled && (
                <div className="option-with-tooltip">
                  <ValidatedForm.CheckboxField
                    setFieldValue={setFieldValue}
                    toggle
                    label="Licenses"
                    value={configs.importLicenses}
                    propertyName="importLicenses"
                  />
                  <Tooltip
                    hoverable
                    tooltipClassName="integraion-setting-tooltip"
                    target={<Icon className="info circle" />}
                    content={getImportLicensesTooltipMessage()}
                    position="top center"
                    hideOnScroll
                    showAlways
                  />
                </div>
              )}
              {isLicensesFilterEnabled && ( 
                <div className="option-with-tooltip">
                  <ValidatedForm.CheckboxField
                    setFieldValue={setFieldValue}
                    toggle
                    label="Groups"
                    value={configs.importGroups}
                    propertyName="importGroups"
                  />
                  <Tooltip
                    hoverable
                    tooltipClassName="integraion-setting-tooltip"
                    target={<Icon className="info circle" />}
                    content={getImportGroupsTooltipMessage()}
                    position="top center"
                    hideOnScroll
                    showAlways
                  />
                </div>
                )}
            </>
          )}
          <div className="option-with-tooltip">
            <ValidatedForm.CheckboxField
              setFieldValue={setFieldValue}
              toggle
              label="Usage"
              value={configs.importUsageData}
              propertyName="importUsageData"
            />
            <Tooltip
              hoverable
              tooltipClassName="integraion-setting-tooltip"
              target={<Icon className="info circle" />}
              content={getImportUsageDataTooltipMessage(IntegrationTypes.MsGraph)}
              position="top center"
              hideOnScroll
              showAlways
            />
          </div>
        </div>
        <div className="option-with-tooltip">
          <ValidatedForm.CheckboxField
            setFieldValue={setFieldValue}
            toggle
            label="Make active when finished"
            value={configs.isActive}
            propertyName="isActive"
          />
          <Tooltip
            hoverable
            tooltipClassName="integraion-setting-tooltip"
            target={<Icon className="info circle" />}
            content={
              "If this feature is enabled, the integration will be active and sync daily. If disabled, synchronizing with the BrainStorm platform will be paused."
            }
            position="top center"
            hideOnScroll
            showAlways
          />
        </div>
      </div>
    </>
  );
};

export default MsGraphIntegrationConfiguration;
