import axios from "axios";
import {
  DeleteThreatDefenceCampaignRequest,
  GetThreatDefenceCampaignsRequest,
  GetThreatDefenceCampaignRunsRequest,
} from "../types/requests";
import { serializeArrayAndFilterNullable } from "../../../../../utils/queryUtils";
import { IThreatDefenceCampaignsDataService } from "../types/services";
import { ThreatDefenceCampaign, ThreatDefenceCampaignContent } from "../types/state";

const campaignsUrl = "/api/threat-defence/campaigns";
const campaignUrl = (id?: number) => `${campaignsUrl}/${id}`;

const instance: IThreatDefenceCampaignsDataService = {
  getThreatDefenceCampaignsAsync(data: GetThreatDefenceCampaignsRequest) {
    const config = {
      params: data,
    };
    return axios.get(campaignsUrl, config);
  },
  getThreatDefenceCampaignAsync(id: number) {
    return axios.get(campaignUrl(id));
  },
  getThreatDefenceCampaignContentAsync(id: number) {
    return axios.get(`${campaignUrl(id)}/content`);
  },
  getThreatDefenceCampaignRunsAsync(request: GetThreatDefenceCampaignRunsRequest, campaignId: number) {
    const config = {
      params: request,
    };
    return axios.get(`${campaignUrl(campaignId)}/runs`, config);
  },
  updateThreatDefenceCampaignAsync(data: ThreatDefenceCampaign) {
    return axios.put(`${campaignUrl(data.id)}`, data);
  },
  updateThreatDefenceCampaignContentAsync(data: ThreatDefenceCampaignContent) {
    return axios.put(`${campaignUrl(data.id)}/content`, data);
  },
  deleteThreatDefenceCampaign(request: DeleteThreatDefenceCampaignRequest) {
    return axios.delete(campaignUrl(request.id));
  },
  getCampaignTypes() {
    return axios.get(`${campaignsUrl}/types`);
  },
  getCampaignEmailTemplates() {
    return axios.get(`${campaignsUrl}/email-templates`);
  },
  getCampaignLandingPages() {
    return axios.get(`${campaignsUrl}/landing-pages`);
  },
  async titleExists(name, id) {
    const config = {
      params: { name, id },
    };

    const result = await axios.get(`${campaignsUrl}/exists`, config);
    return result.data;
  },
  async getCampaignsForEmailTemplates(emailTemplateIds: number[]) {
    const config = {
      params: {
        ids: emailTemplateIds,
      },
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    return axios.get(`/api/threat-defence/email-templates/campaigns`, config);
  },
};

export default instance;
