import { useCallback } from "react";

import { defaultFlowEndInfo } from "../../constants";
import { useReactFlowCanvasActions } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import { FlowEndInfo } from "../../types";
import { useReactFlow } from "reactflow";

export const useFlowEnd = (flowEndId: string | undefined) => {
  const { setFlowEnd } = useReactFlowCanvasActions();
  const { getNode } = useReactFlow();

  const currentNode = flowEndId ? getNode(flowEndId) : undefined;
  const flowEndInfo = currentNode?.data.flowEndInfo || defaultFlowEndInfo;

  const onFlowEndChange = useCallback(
    (flowEndInfo: FlowEndInfo) => {
      if (!flowEndId) return;

      setFlowEnd(flowEndId, flowEndInfo);
    },
    [flowEndId, setFlowEnd],
  );

  return { flowEndInfo, onFlowEndChange: onFlowEndChange };
};
