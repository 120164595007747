import SortOptions from "../../../../enums/SortOptions";

const options = [
  {
    text: "Most Recent",
    value: SortOptions.CreatedDateDesc,
    default: true,
  },
  {
    text: "Last Modified",
    value: SortOptions.ModifiedDateDesc,
  },
  {
    text: "Sorted A-Z",
    value: SortOptions.Title,
  },
];

export default options;
