import { useCallback } from "react";
import { useReactFlowCanvasActions } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import { SectionHeaderDescriptionError, SectionHeaderNameError } from "../../validator/flowValidatorReducer";
import useBaseErrorHandler from "./useBaseErrorHandler";
import useErrorNavigationUtils from "./useErrorNavigationUtils";

export default function useSectionHeaderErrorHandler() {
  const handleBaseError = useBaseErrorHandler();
  const { getTargetNode } = useErrorNavigationUtils();
  const { setSectionHeaderView } = useReactFlowCanvasActions();

  return useCallback(
    (error: SectionHeaderNameError | SectionHeaderDescriptionError) => {
      const targetNode = getTargetNode(error.itemId);

      if (targetNode) {
        setSectionHeaderView(error.itemId);
        setTimeout(() => handleBaseError(error), 50);
      }
    },
    [getTargetNode, handleBaseError, setSectionHeaderView],
  );
}
