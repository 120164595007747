import PropTypes from "prop-types";

import OutstandingHeaderTitle from "./OutstandingHeaderTitle";
import SectionHeader from "../SectionHeader";
import EllipsisPopupButton from "../../buttons/ellipsisPopupButton/EllipsisPopupButton";

export function OutstandingHeader(props) {
  const { children, actionButtons, style, publishedStatus, ...headerTitleProps } = props;

  let header = <OutstandingHeaderTitle {...headerTitleProps} />;

  return (
    <SectionHeader header={header} publishedStatus={publishedStatus} className="outstanding-header" style={style}>
      {children}
      {actionButtons && <EllipsisPopupButton>{actionButtons}</EllipsisPopupButton>}
    </SectionHeader>
  );
}

OutstandingHeader.defaultProps = {
  title: "",
  selectedItemsAmount: 0,
  backButton: false,
};

OutstandingHeader.propTypes = {
  title: PropTypes.string,
  titleForGA: PropTypes.string,
  itemName: PropTypes.string,
  selectedItemsAmount: PropTypes.number,
  actionButtons: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.node]),
  style: PropTypes.object,
};

export default OutstandingHeader;
