import { ReactComponent as SectionHeaderIcon } from "../../../../../../images/section-header-icon-blue.svg";
import "./sectionHeaderOverlay.scss";

export const SectionHeaderOverlay: React.FC = () => {
  return (
    <div className="flow-designer-section-header-overlay with-opacity">
      <div className="text">
        <SectionHeaderIcon width={28} height={28} className="header-icon" /> Add section header
      </div>
    </div>
  );
};
