/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { PropertyChangedEvent } from "../interfaces/functionTypes/PropertyChangedEvent";
import { WizardStepHandlers } from "../interfaces/IWizardStepsManager";
import Observable from "../utils/Observable";
import { useLocalContext } from "./useLocalContext";
import { useObserver } from "./useObserver";

interface Navigate {
  onNext: (activeIndex: number, nextStepIndex: number) => void;
  onPrevious: (activeIndex: number, nextStepIndex: number) => void;
  goToPage: (nextStepIndex: number) => void;
  onFinish: () => void;
  goBack: () => boolean;
}

export type WizardStepsManager = [
  Array<(handlers: WizardStepHandlers) => void>,
  Navigate,
  (observer: PropertyChangedEvent<number>) => () => void,
];

export const useWizardStepsManager = (pageIndexes: Array<number>): WizardStepsManager => {
  const [activeStepIndex, setActiveStepIndex] = useState(-1);
  const [stepsHandlers, setStepsHandlers] = useState<{ [key: number]: WizardStepHandlers }>({});
  const activeStepIndexChangedObservable = useMemo(() => new Observable<PropertyChangedEvent<number>>(), []);
  const [subscribeOnActiveIndexChanged] = useObserver(activeStepIndexChangedObservable);
  const context = useLocalContext({ stepsHandlers, activeStepIndex });
  const historyRef = useRef<number[]>([]);

  useEffect(() => {
    return () => {
      Object.keys(context.current.stepsHandlers).forEach((key) => {
        context.current.stepsHandlers[Number.parseInt(key)]?.onClose?.();
      });
      context.current.stepsHandlers[context.current.activeStepIndex]?.onActivePageClose?.();
      activeStepIndexChangedObservable.dispose();
    };
  }, []);

  const addPage = useCallback(
    (handlers: WizardStepHandlers, wizardStepIndex: number) => {
      setStepsHandlers((prev) => {
        return { ...prev, [wizardStepIndex]: handlers };
      });
      if (activeStepIndex === -1) {
        historyRef.current.push(wizardStepIndex);
        updateActiveStepIndexAndNotify(wizardStepIndex);
      }
    },
    [activeStepIndex, stepsHandlers],
  );

  const onNext = useCallback(
    (_: any, nextStepIndex: number) => {
      if (nextStepIndex <= activeStepIndex) {
        return;
      }
      stepsHandlers[activeStepIndex]?.onNext?.();
      updateActiveStepIndexAndNotify(nextStepIndex);
    },
    [activeStepIndex, stepsHandlers],
  );

  const onPrevious = useCallback(
    (_: any, nextStepIndex: number) => {
      if (nextStepIndex >= activeStepIndex) {
        return;
      }
      stepsHandlers[activeStepIndex]?.onPrevious?.();
      updateActiveStepIndexAndNotify(nextStepIndex);
    },
    [activeStepIndex, stepsHandlers],
  );

  const goToPage = useCallback(
    (pageIndex: number, saveHistory = true) => {
      if (pageIndex === activeStepIndex) {
        return;
      }
      if (saveHistory) historyRef.current.push(pageIndex);
      const action = pageIndex > activeStepIndex ? onNext : onPrevious;
      action(undefined, pageIndex);
    },
    [activeStepIndex, onNext, onPrevious],
  );

  // Handles when the user uses the back button
  const goBack = useCallback(() => {
    if (historyRef.current.length > 1) {
      historyRef.current.pop();
      const previousPage = historyRef.current[historyRef.current.length - 1];
      goToPage(previousPage, false);
      return true;
    }
    return false;
  }, [goToPage]);

  const onFinish = useCallback(() => {
    Object.values(stepsHandlers).forEach((handler) => handler.onFinish?.());
  }, [stepsHandlers, activeStepIndex]);

  const updateActiveStepIndexAndNotify = useCallback(
    (nextStepIndex: number) => {
      const oldValue = activeStepIndex;
      setActiveStepIndex(nextStepIndex);
      activeStepIndexChangedObservable.notify(oldValue, nextStepIndex);
    },
    [activeStepIndex],
  );

  const wizardPages = useMemo(() => {
    const pageHandlers: Array<(handlers: WizardStepHandlers) => void> = [];
    pageIndexes.forEach((pageIndex) => {
      pageHandlers.push((handlers: WizardStepHandlers) => {
        addPage(handlers, pageIndex);
      });
    });
    return pageHandlers;
  }, [pageIndexes, addPage]);

  return [wizardPages, { onNext, onPrevious, goBack, goToPage, onFinish }, subscribeOnActiveIndexChanged];
};
