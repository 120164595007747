import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/globaltypes/redux";
import { EncodingType } from "../../types";

export interface EncodingTypesState {
  items: EncodingType[];
  isLoading: boolean;
  error: string | undefined;
}

const initialState: EncodingTypesState = {
  items: [],
  isLoading: false,
  error: undefined,
};

const encodingTypesSlice = createSlice({
  name: "encodingTypes",
  initialState: initialState,
  reducers: {
    req(state: EncodingTypesState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: EncodingTypesState, action: PayloadAction<EncodingType[]>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    },
    err(state: EncodingTypesState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = encodingTypesSlice.actions;

export const encodingTypesSelector = (state: RootState) => state.accounts.identityProviders.encodingTypes.items;
export const encodingTypesLoadingSelector = (state: RootState) =>
  state.accounts.identityProviders.encodingTypes.isLoading;

export default encodingTypesSlice.reducer;
export type encodingTypesStateSliceType = ReturnType<typeof encodingTypesSlice.reducer>;
