import { BaseOauthIntegrationConfigs, BaseIntegrationConfigs } from "./types";

export interface ContentSettings {
  contentType: ContentSettingsEnum;
}

export enum ContentSettingsEnum {
  None = 0,
  FlowsOnly = 1,
  AllContent = 2,
}

export interface PackContentSettings {
  contentType: ContentSettingsEnum;
  packIds: number[];
}

export interface VivaIntegrationConfigs extends BaseIntegrationConfigs, BaseOauthIntegrationConfigs {
  isActive: boolean;
  tenantId?: string;
  customContentSettings: ContentSettings;
  packContentSettings: PackContentSettings;
}
