import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { FeatureFlags } from "featureFlags";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FC, useEffect } from "react";
import { ConnectedProps, connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, SemanticICONS } from "semantic-ui-react";
import { ColumnOption } from "../../../../interfaces/columnOptions";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import {
  EngagementLineStatus,
  EngagementPeopleData,
  selectEngagementPeopleData,
  selectEngagementPeopleValue,
} from "../state/slices/videoPerformanceSlice";
import * as videoPerformanceActions from "../state/thunks/videoPerformanceThunk";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import { PerformanceFilter, completes, isBsi, starts } from "features/Library/Common/utils/performanceUtils";
import { bindAction } from "interfaces";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { Link } from "react-router-dom";
import { RouteNames } from "enums";
import moboConfigurator from "moboConfigurator";

import styles from "./VideoPerformancePeople.module.scss";

export type VideoPerformancePeopleProps = {
  videoId: number;
  flowId?: number;
  filter: PerformanceFilter;
  includeAccounts?: boolean;
} & PropsFromRedux;

type VideoEntity = EngagementPeopleData[number];

export type EmailStatusColors = "icon-send-complete" | "icon-send-progress" | "icon-send-canceled";

type VideoStatusCompute = {
  [key in EngagementLineStatus]: {
    icon: SemanticICONS;
    color: EmailStatusColors;
  };
};
export const VideoPerformancePeopleStatusToCell: VideoStatusCompute = {
  Complete: {
    icon: "check circle",
    color: "icon-send-complete",
  },
  "In Progress": {
    icon: "clock",
    color: "icon-send-progress",
  },
  "Not Started": {
    icon: "circle outline",
    color: "icon-send-canceled",
  },
};

const getColumnOptions = (reportEnabled: boolean, includeAccounts?: boolean) => {
  const baseColumnOptions: ColumnOption<VideoEntity>[] = [
    {
      name: "NAME",
      width: 4,
      isSortable: false,
      render: (entity: VideoEntity) => {
        return (
          <div>
            {includeAccounts ? (
              <Tooltip
                target={<TextTruncate>{entity.UserFirstName} {entity.UserLastName}</TextTruncate>}
                content={`${entity.UserFirstName} ${entity.UserLastName}`}
              />
            ) : (
              <Link to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.peopleUsers}/${entity.UserId}`)} className={styles.linkButton}>
                <Tooltip
                  target={
                    <TextTruncate>
                      {entity.UserFirstName} {entity.UserLastName}
                    </TextTruncate>
                  }
                  content={`${entity.UserFirstName} ${entity.UserLastName}`}
                />
              </Link>
            )}
            <Tooltip
              target={<TextTruncate className="workTitle">{entity.UserDepartment}</TextTruncate>}
              content={entity.UserDepartment}
            />
          </div>
        );
      },
    },
    {
      name: "EMAIL",
      width: 4,
      isSortable: false,
      render: (entity: VideoEntity) => {
        return (
          <Tooltip target={<TextTruncate>{entity.UserEmail}</TextTruncate>} content={entity.UserEmail} />
        );
      },
    },
    {
      name: "DATE WATCHED",
      width: 3,
      isSortable: false,
      render: (entity: VideoEntity) => {
        const UTCDate = entity.DateWatched?.match(/\d{4}-\d{2}-\d{2}/);
        let formattedDate: string;
        if (UTCDate) {
          const splitUTCDate = UTCDate[0].split("-");

          const year = splitUTCDate[0];
          const month = splitUTCDate[1];
          const day = splitUTCDate[2];

          formattedDate = `${month}/${day}/${year}`;
        } else {
          formattedDate = "-";
        }

        return (
          <div>
            <div>{formattedDate}</div>
            <div>&nbsp;</div>
          </div>
        );
      },
    },
    {
      name: "STATUS",
      width: 3,
      isSortable: false,
      render: (entity: VideoEntity) => (
        <div className={styles.status}>
          <div>
            <Icon
              name={VideoPerformancePeopleStatusToCell[entity.Status].icon}
              className={VideoPerformancePeopleStatusToCell[entity.Status].color}
            />
            &nbsp;
            {entity.Status}
          </div>
          <div>&nbsp;</div>
        </div>
      ),
    },
  ];

  if (reportEnabled) {
    baseColumnOptions.splice(
      2,
      0,
      {
        name: `Total ${starts}`,
        width: 3,
        isSortable: false,
        render: (entity) => (
          <div>
            <div>{(entity.VideoStarts?.toLocaleString() || entity.videoStarts?.toLocaleString()) ?? 0}</div>
            <div>&nbsp;</div>
          </div>
        ),
      },
      {
        name: `Total ${completes}`,
        width: 3,
        isSortable: false,
        render: (entity: VideoEntity) => (
          <div>
            <div>{(entity.VideoCompletions?.toLocaleString() || entity.videoCompletions?.toLocaleString()) ?? 0}</div>
            <div>&nbsp;</div>
          </div>
        ),
      },
    );
  }

  return baseColumnOptions;
};

export const VideoPerformancePeopleComponent: FC<VideoPerformancePeopleProps> = ({
  actions,
  videoId,
  peopleDetailsEntities,
  peopleDetailsEntitiesStatus,
  filter,
  flowId,
  includeAccounts,
  setHasExportData,
  accountId,
}) => {
  const reportEnabled = useFeatureFlag(FeatureFlags.VideoReport);

  const columnOptions = getColumnOptions(reportEnabled, includeAccounts);

  useEffect(() => {
    setHasExportData(peopleDetailsEntitiesStatus.status === RStatus.Got && !!peopleDetailsEntities.length);
  }, [setHasExportData, peopleDetailsEntitiesStatus.status, peopleDetailsEntities]);

  // Fetch all sends
  useEffect(() => {
    if (reportEnabled) {
      if (isBsi(accountId)) {
        actions.fetchEngagementPeopleDataV3(videoId, { ...filter, flowId, allItems: !!flowId });
      } else {
        actions.fetchEngagementPeopleData(videoId, { ...filter, flowId });

      }
    }
  }, [videoId, actions, filter, flowId, reportEnabled, accountId]);

  return (
    <RequestStatusRenderer state={peopleDetailsEntitiesStatus}>
      <GenericPerformanceList rows={peopleDetailsEntities} columns={columnOptions} filter={filter} title="People" />
    </RequestStatusRenderer>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    accountId: state.userProfile.accountId,
    peopleDetailsEntities: selectEngagementPeopleValue(state),
    peopleDetailsEntitiesStatus: selectEngagementPeopleData(state),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    actions: bindActionCreators(videoPerformanceActions, dispatch),
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VideoPerformancePeopleComponent);
