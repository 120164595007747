import cn from "classnames";
import { Component } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import { FeatureFlags } from "featureFlags";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { resetSearchFilters } from "views/library/flows/flowsOverview/FlowsOverview";
import { withRouter, WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { DetailsSubHeader, Segments } from "../../../../components";
import ModalTypes from "../../../../components/modal/ModalTypes";
import RevertConfirmationModal from "../../../../components/modal/RevertConfirmationModal";
import { ProtectedRoute } from "../../../../components/restrictedRoute/ProtectedRoute";
import { PublishedStatusTypes, RolePermissions, UsersGroupsContext, ItemsTypes } from "../../../../enums";
import { bindAction, EnumDictionary, IObservable, ISegment, OnChangePriorityObserver } from "../../../../interfaces";
import modalUtilsFactory, { ModalUtils } from "../../../../utils/modalUtilsFactory";
import Observable from "../../../../utils/Observable";
import WizardStepsManager from "../../../../utils/WizardStepsManager";
import { getActionProvider } from "../../../Application/actions/actionsBuilder";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import Restricted from "../../../Application/Restricted";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { PeopleType } from "../../PeopleAssignments/types";
import FlowDesigner from "../Designer/FlowDesigner/FlowDesigner";
import { reset as resetConnectedFlows } from "../Designer/state/slices/flowDesignerConnectedFlowsSlice";
import { resetContext } from "../Designer/state/slices/flowDesignerContextSlice";
import { reset as resetDefinition } from "../Designer/state/slices/flowDesignerDefinitionSlice";
import { reset as resetExternalTriggers } from "../Designer/state/slices/flowDesignerExternalTriggersSlice";
import { fetchConnectedFlows } from "../Designer/state/thunks/flowDesignerConnectedFlowsThunk";
import { fetchFlowDefinition } from "../Designer/state/thunks/flowDesignerDefinitionThunk";
import { fetchExternalTriggerGroupsAction } from "../Designer/state/thunks/flowDesignerExternalTriggersThunk";
import { FlowValidator } from "../Designer/validator/FlowValidator";
import * as actionTypes from "../Designer/validator/flowValidatorActionTypes";
import Goal from "../Goal/Goal";
import FlowPerformance from "../Performance/FlowPerformance";
import {
  fetchDraftFlowEntity,
  resetFlowEntityState,
  revertFlowEntityToPublished,
} from "../state/actions/flowEntityStateActionCreators";
import { fetchFlowInfo, resetFlowInfo } from "../state/actions/infoActionCreators";
import { flowInformationSelector, flowsStateSelector, flowValidatorSelector } from "../state/selectors";
import { reset as resetGoalData } from "../state/slices/flowGoalSlice";
import { fetchGoalData } from "../state/thunks/flowGoalThunk";
import { EditFlowStepConfig, FlowEditDetailsSteps } from "./config";
import ContentAssignmentsContainer from "./Containers/ContentAssignmentsContainer";
import DetailsHeaderContainer from "./Containers/DetailsHeaderContainer";
import DetailsHeaderContent from "./DetailsHeaderContent/DetailsHeaderContent";
import Configuration from "./Info/Configuration";
import Settings from "./Info/Settings";
import EditFlowPeople from "./People/EditFlowPeople";
import AssociatedPacks from "../../../Licensing/Packs/AssociatedPacks/AssociatedPacks";
import "./flowDetails.scss";
import { LDProps } from "../../../LDProps";

const flowsOverviewUrl = "/content/flows";

export type FlowDetailsProps = PropsFromRedux & WithRouterProps & LDProps;

export interface FlowDetailsState {
  activeStepIndex: FlowEditDetailsSteps;
  isValid: {
    [key: number]: boolean;
  };
  showRevertModal: boolean;
  usersGroupsContext: UsersGroupsContext;
}

export class FlowDetails extends Component<FlowDetailsProps, FlowDetailsState> {
  private readonly stepsManager = new WizardStepsManager();
  private readonly pages: EnumDictionary<FlowEditDetailsSteps, ISegment>;
  private readonly revertModal: ModalUtils;
  private readonly flowValidator: FlowValidator;
  private readonly flowId: number;
  private readonly showPacksTab: boolean;
  private readonly flowVersioningMigrationEnabled: boolean;
  private readonly onAddPeopleButtonClickObserver: IObservable<() => void>;
  private readonly onEditPriorityButtonClickObserver: OnChangePriorityObserver;
  private readonly onPublishButtonClickObserver: IObservable<(then: () => void) => void>;
  private readonly onPublishOrDiscardButtonClickObserver: IObservable<() => void>;
  private readonly onResetSelectedElementsObserver: IObservable<() => void>;
  private readonly onRemovePeopleButtonClickObserver: IObservable<
    (onRemoveConfirm: () => void, people: { [key in PeopleType]?: number[] }) => void
  >;

  constructor(props: FlowDetailsProps) {
    super(props);

    this.state = {
      activeStepIndex: FlowEditDetailsSteps.Performance,
      isValid: {},
      showRevertModal: false,
      usersGroupsContext: UsersGroupsContext.Groups,
    };

    this.showPacksTab = !!this.props.flags?.[FeatureFlags.AssociatedPacks];
    this.flowVersioningMigrationEnabled = !!this.props.flags?.[FeatureFlags.FlowVersioningMigration];

    this.onAddPeopleButtonClickObserver = new Observable();
    this.onRemovePeopleButtonClickObserver = new Observable();
    this.onEditPriorityButtonClickObserver = new Observable();
    this.onPublishButtonClickObserver = new Observable();
    this.onPublishOrDiscardButtonClickObserver = new Observable();
    this.onResetSelectedElementsObserver = new Observable();
    this.flowValidator = new FlowValidator();
    this.revertModal = modalUtilsFactory();
    this.flowId = this.getFlowId();

    this.pages = {
      [FlowEditDetailsSteps.Performance]: {
        to: "",
        label: "Performance",
        onClick: () => this.stepsManager.goToPage(FlowEditDetailsSteps.Performance),
        init: () => {
          this.setState({ activeStepIndex: FlowEditDetailsSteps.Performance });
        },
      },
      [FlowEditDetailsSteps.Configuration]: {
        to: "configuration",
        label: "Configuration",
        onClick: () => this.stepsManager.goToPage(FlowEditDetailsSteps.Configuration),
        init: () => {
          this.setState({ activeStepIndex: FlowEditDetailsSteps.Configuration });
        },
      },
      [FlowEditDetailsSteps.Settings]: {
        to: "settings",
        label: "Settings",
        onClick: () => this.stepsManager.goToPage(FlowEditDetailsSteps.Settings),
        init: () => {
          this.setState({ activeStepIndex: FlowEditDetailsSteps.Settings });
        },
      },
      [FlowEditDetailsSteps.Content]: {
        to: "items",
        label: "Content",
        onClick: () => this.stepsManager.goToPage(FlowEditDetailsSteps.Content),
        init: () => {
          this.setState({ activeStepIndex: FlowEditDetailsSteps.Content });
        },
      },
      [FlowEditDetailsSteps.People]: {
        to: "people",
        label: "People",
        onClick: () => this.stepsManager.goToPage(FlowEditDetailsSteps.People),
        init: () => {
          this.setState({ activeStepIndex: FlowEditDetailsSteps.People });
        },
      },
      [FlowEditDetailsSteps.Goal]: {
        to: "goal",
        label: "Goal",
        onClick: () => this.stepsManager.goToPage(FlowEditDetailsSteps.Goal),
        init: () => {
          this.setState({ activeStepIndex: FlowEditDetailsSteps.Goal });
        },
      },
      [FlowEditDetailsSteps.AssociatedPacks]: {
        to: "packs",
        label: "Associated Packs",
        onClick: () => this.stepsManager.goToPage(FlowEditDetailsSteps.AssociatedPacks),
        init: () => {
          this.setState({ activeStepIndex: FlowEditDetailsSteps.AssociatedPacks });
        },
      },
    };

    this.stepsManager.subscribeOnActiveIndexChanged((_: any, activeStepIndex: FlowEditDetailsSteps) => {
      this.pages[activeStepIndex].init?.();
    });
  }

  getFlowId = () => Number.parseInt(this.props.params.id!);

  componentDidMount() {
    batch(() => {
      this.props.fetchFlowInfo(this.flowId);
      this.props.fetchFlowDefinition(this.flowId, this.flowValidator);
      this.props.fetchExternalTriggerGroups(this.flowId);
      this.props.fetchConnectedFlows(this.flowId);
      this.props.fetchGoalData(this.flowId);
    });

    RtnEventsEmitter.subscribe(
      [rtnEvents.FlowPublishSuccess, rtnEvents.FlowDiscardSuccess, rtnEvents.FlowLockSuccess],
      this.onPublishedOrDiscardedFlow,
    );
  }

  componentWillUnmount() {
    this.props.onUnmount();
    if (!window.location.pathname.includes("/content/flows")) this.props.resetSearchFilters();
    this.flowValidator.clearState();
    this.stepsManager.dispose();

    RtnEventsEmitter.unsubscribe(
      [rtnEvents.FlowPublishSuccess, rtnEvents.FlowDiscardSuccess, rtnEvents.FlowLockSuccess],
      this.onPublishedOrDiscardedFlow,
    );
  }

  onPublishedOrDiscardedFlow = () => {
    this.props.onDiscard(this.flowId, this.flowValidator);
  };

  handleClose = () => {
    this.props.navigate(flowsOverviewUrl);
  };

  handleRevert = () => {
    this.onPublishOrDiscardButtonClickObserver.notify();

    this.flowValidator.clearState();
    this.closeRevertPopup();
    this.onResetSelectedElementsObserver.notify();
    this.props.revertFlowEntityToPublished(this.flowId);
  };

  onRevert = () => {
    this.revertModal.execute(this.flowId, this.handleRevert, () =>
      this.setState({
        ...this.state,
        showRevertModal: true,
      }),
    );
  };

  back = () => {
    if (!this.stepsManager.goBack()) {
      return flowsOverviewUrl;
    }
  };

  handlePublish = () => {
    if (!this.props.isFlowValid) {
      this.props.setIsErrorModeEnabled({
        isErrorViewMode: true,
      });
      this.props.toggleErrorMessagesBar({ showErrorMessagesBar: false });
      return;
    }

    const notifyCallback = () => this.flowValidator.clearState();
    this.onPublishButtonClickObserver.notify(notifyCallback);
    this.onPublishOrDiscardButtonClickObserver.notify();
  };

  handleEdit = () => {
    this.onResetSelectedElementsObserver.notify();
    this.props.fetchDraftFlowEntity(this.flowId);
  };

  setValidationState = (tab: number) => (isValid: boolean) => {
    this.setState((prevState) => ({ ...prevState, isValid: { ...prevState.isValid, [tab]: isValid } }));
  };

  setUsersGroupsContext = (viewType: UsersGroupsContext) => {
    this.setState({
      ...this.state,
      usersGroupsContext: viewType,
    });
  };

  setGoalIsValid = this.setValidationState(FlowEditDetailsSteps.Goal);
  setConfigurationIsValid = this.setValidationState(FlowEditDetailsSteps.Configuration);

  isValid = () => {
    return Object.values(this.state.isValid).every((x) => x);
  };

  renderRevertModal = (isRevertVisible: boolean) => {
    return (
      <RevertConfirmationModal
        modalUtils={this.revertModal}
        modalType={ModalTypes.RevertFlow}
        open={this.state.showRevertModal}
        onClose={this.closeRevertPopup}
        isVisible={isRevertVisible}
      />
    );
  };

  closeRevertPopup = () => {
    this.setState({
      ...this.state,
      showRevertModal: false,
    });
  };

  onDetailsHeaderErrorClick = () => {
    const { navigate, location } = this.props;
    const redirectTo = `/content/flows/${this.flowId}/items`;
    if (!location.pathname.includes(redirectTo)) {
      navigate(redirectTo);
      this.stepsManager.goToPage(FlowEditDetailsSteps.Content);
      this.setState({ activeStepIndex: FlowEditDetailsSteps.Content });
    }
  };

  render() {
    const { flowInfo, entityState, isErrorViewMode, isLoadingLicensingInfo, isShouldShowModalLoading } = this.props;
    const { activeStepIndex, usersGroupsContext } = this.state;

    return (
      <div className="nested-content edit-flow-details">
        <Restricted
          permissions={[RolePermissions.FlowsManage, RolePermissions.FlowsCreate]}
          renderContent={(hasAnyPermission) => (
            <DetailsHeaderContainer
              title={flowInfo.title}
              pageTitle={flowInfo.title}
              titleForGA="Flow Details"
              backButton={this.back || flowsOverviewUrl}
              defaultURL={flowsOverviewUrl}
              publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!flowInfo.isDraft)}
              invalidFormDetails={!this.isValid()}
              isRevertVisible={flowInfo.hasOrigin}
              crudPermission={hasAnyPermission}
              entityStateActions={{
                onPublish: this.handlePublish,
                onRevert: this.onRevert,
                onClose: this.handleClose,
                onEdit: this.handleEdit,
              }}
              canBeEdited={flowInfo.isEditable}
              hideEntityStateButtons={activeStepIndex === FlowEditDetailsSteps.People}
              canBePublished={!this.flowVersioningMigrationEnabled && !isErrorViewMode}
            >
              <DetailsHeaderContent
                activeStep={activeStepIndex}
                canAddPacks={flowInfo.isEditable && !flowInfo.isDraft}
                flowId={this.flowId}
                onAddPeopleObserver={this.onAddPeopleButtonClickObserver}
                onChangePriorityObserver={this.onEditPriorityButtonClickObserver}
                onRemovePeopleObserver={this.onRemovePeopleButtonClickObserver}
                onFixErrorsClick={this.onDetailsHeaderErrorClick}
              />
            </DetailsHeaderContainer>
          )}
        />
        {this.renderRevertModal(flowInfo.hasOrigin)}
        <Dimmer active={entityState.changingEntityState || isLoadingLicensingInfo || isShouldShowModalLoading} inverted>
          <Loader>Processing</Loader>
        </Dimmer>
        <DetailsSubHeader
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!flowInfo.isDraft)}
          isUpdateInProgress={entityState.isEntityCommandInProgress && flowInfo.isDraft}
          lastModifiedDateTime={
            EditFlowStepConfig[activeStepIndex]?.showAutoSaveOnTab ? flowInfo.dateModified : undefined
          }
        >
          <Segments to={`${flowsOverviewUrl}/${this.flowId}`}>
            <Segments.Segment {...this.pages[FlowEditDetailsSteps.Performance]} />
            <Segments.Segment {...this.pages[FlowEditDetailsSteps.Configuration]} />
            <Segments.Segment {...this.pages[FlowEditDetailsSteps.Settings]} />
            <Segments.Segment {...this.pages[FlowEditDetailsSteps.Content]} />
            <Segments.Segment {...this.pages[FlowEditDetailsSteps.People]} />
            <Segments.Segment {...this.pages[FlowEditDetailsSteps.Goal]} />
            {this.showPacksTab && <Segments.Segment {...this.pages[FlowEditDetailsSteps.AssociatedPacks]} />}
          </Segments>
        </DetailsSubHeader>
        <div
          className={cn({
            "scrollable-content": this.state.activeStepIndex !== FlowEditDetailsSteps.Content,
            "flow-scrollable": this.state.activeStepIndex === FlowEditDetailsSteps.Content,
          })}
        >
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                  <FlowPerformance
                    acceptHandlers={(handlers) =>
                      this.stepsManager.acceptHandlers(handlers, FlowEditDetailsSteps.Performance)
                    }
                    flowId={this.flowId}
                    flowTitle={flowInfo.title}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="configuration"
              element={
                <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                  <Configuration
                    validationChanged={this.setConfigurationIsValid}
                    acceptHandlers={(handlers) =>
                      this.stepsManager.acceptHandlers(handlers, FlowEditDetailsSteps.Configuration)
                    }
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="settings"
              element={
                <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                  <Settings
                    acceptHandlers={(handlers) =>
                      this.stepsManager.acceptHandlers(handlers, FlowEditDetailsSteps.Settings)
                    }
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="items"
              element={
                <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                  <FlowDesigner
                    flowValidator={this.flowValidator}
                    onResetSelectedElementsObserver={this.onResetSelectedElementsObserver}
                    acceptHandlers={(handlers) =>
                      this.stepsManager.acceptHandlers(handlers, FlowEditDetailsSteps.Content)
                    }
                    publishOrDiscardClickObserver={this.onPublishOrDiscardButtonClickObserver}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="people"
              element={
                <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                  <EditFlowPeople
                    flowId={this.flowId}
                    title={flowInfo.title}
                    thumbnailUrl={flowInfo.thumbnailUrl}
                    usersGroupsContext={usersGroupsContext}
                    setUsersGroupsContext={this.setUsersGroupsContext}
                    addPeopleClickObserver={this.onAddPeopleButtonClickObserver}
                    onChangePriorityObserver={this.onEditPriorityButtonClickObserver}
                    removePeopleClickObserver={this.onRemovePeopleButtonClickObserver}
                    acceptHandlers={(handlers) =>
                      this.stepsManager.acceptHandlers(handlers, FlowEditDetailsSteps.People)
                    }
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="goal"
              element={
                <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                  <div className="stretch scrollable-content edit-form">
                    <Goal
                      flowId={this.flowId}
                      onDiscardObserver={this.onPublishOrDiscardButtonClickObserver}
                      onIsValidChange={this.setGoalIsValid}
                      acceptHandlers={(handlers) =>
                        this.stepsManager.acceptHandlers(handlers, FlowEditDetailsSteps.Goal)
                      }
                    />
                  </div>
                </ProtectedRoute>
              }
            />
            {this.showPacksTab && (
              <Route
                path="packs"
                element={
                  <ProtectedRoute permissions={[RolePermissions.FlowsView]}>
                    <AssociatedPacks
                      acceptHandlers={(handlers) =>
                        this.stepsManager.acceptHandlers(handlers, FlowEditDetailsSteps.AssociatedPacks)
                      }
                      contentType={ItemsTypes.Flow}
                      contentId={this.flowId}
                      canAddPacks={flowInfo.isEditable && !flowInfo.isDraft}
                      permissions={[
                        RolePermissions.AssetsManage,
                        RolePermissions.PacksManage,
                        RolePermissions.FlowsCreate,
                      ]}
                    />
                  </ProtectedRoute>
                }
              />
            )}
            <Route path="*" element={<Navigate to="../" replace />} />
          </Routes>
        </div>
        <ContentAssignmentsContainer
          flowId={this.flowId}
          flowHasOrigin={flowInfo.hasOrigin}
          publishClickObserver={this.onPublishButtonClickObserver}
        />
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const flows = flowsStateSelector(state);
  const flowInformation = flowInformationSelector(state);
  const flowValidator = flowValidatorSelector(state);

  return {
    flowInfo: flowInformation.info,
    entityState: flows.base.entity,
    isErrorViewMode: flowValidator.isErrorViewMode,
    isFlowValid: flowValidator.isFlowValid,
    isLoadingLicensingInfo: flows.base.licensing.isLoading,
    isShouldShowModalLoading: state.licensing.licensingModal.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchFlowInfo: bindAction(fetchFlowInfo, dispatch),
    fetchDraftFlowEntity: bindAction(fetchDraftFlowEntity, dispatch),
    revertFlowEntityToPublished: bindAction(revertFlowEntityToPublished, dispatch),
    setIsErrorModeEnabled: bindAction(
      getActionProvider<{ isErrorViewMode: boolean }>(actionTypes.setIsErrorModeEnabled),
      dispatch,
    ),
    toggleErrorMessagesBar: bindAction(
      getActionProvider<{ showErrorMessagesBar: boolean }>(actionTypes.showErrorMessagesBar),
      dispatch,
    ),
    fetchFlowDefinition: bindAction(fetchFlowDefinition, dispatch),
    fetchExternalTriggerGroups: bindAction(fetchExternalTriggerGroupsAction, dispatch),
    fetchConnectedFlows: bindAction(fetchConnectedFlows, dispatch),
    fetchGoalData: bindAction(fetchGoalData, dispatch),

    onDiscard: (id: number, flowValidator: FlowValidator) => {
      batch(() => {
        dispatch(fetchFlowInfo(id));
        dispatch(fetchGoalData(id));
        dispatch(fetchFlowDefinition(id, flowValidator));
        dispatch(fetchExternalTriggerGroupsAction(id));
      });
    },

    onUnmount: () => {
      batch(() => {
        dispatch(resetContext());
        dispatch(resetFlowInfo());
        dispatch(resetDefinition());
        dispatch(resetExternalTriggers());
        dispatch(resetConnectedFlows());
        dispatch(resetFlowEntityState());
        dispatch(resetGoalData());
      });
    },

    resetSearchFilters: () => resetSearchFilters(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withLDConsumer()(FlowDetails)));
