import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import { RequestState, RStatus } from "../../../../../Application/globaltypes/fetchRequest";
import { RootState } from "../../../../../Application/globaltypes/redux";
import {
  EmailActivity,
  EmailDensityParsed,
  EmailLineData,
  EmailSendDetailEntity,
  EmailSendSession,
} from "../../EmailDetails/Performance/types";
import { OwnProps } from "../../EmailDetails/Performance/Drilldown/EmailPerformanceDrilldown";
import {
  clicksColor,
  findIndexInRange,
  getRange,
  lineChartFactory,
  opensColor,
  sendsColor,
} from "../../../../Common/utils/performanceUtils";
import moment from "moment";
import { DataPoint } from "components/charts/types/HorizontalBarChart";

interface EmailPerformanceSliceState {
  selectedSendSession?: EmailSendSession;
  sendDensity: RequestState<EmailLineData[]>;
  sendFunnel: RequestState<EmailActivity>;
  sendSessions: RequestState<EmailSendSession[]>;
  sendsDetails: RequestState<EmailSendDetailEntity[]>;
}

const initialRequestState: RequestState<any[]> = {
  status: RStatus.Idle,
  value: [],
  errorMessage: undefined,
};

export const initialState: EmailPerformanceSliceState = {
  selectedSendSession: undefined,
  sendDensity: initialRequestState,
  sendFunnel: {
    status: RStatus.Idle,
    value: {
      Sends: 0,
      Opens: 0,
      Clicks: 0,
      OpenRate: 0,
      ClickRate: 0,
    },
    errorMessage: undefined,
  },
  sendsDetails: initialRequestState,
  sendSessions: initialRequestState,
};

const emailPerformanceSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Emails}/emailPerformance`,
  initialState,
  reducers: {
    setSelectedSendSession(state, action: PayloadAction<EmailPerformanceSliceState["selectedSendSession"]>) {
      state.selectedSendSession = action.payload;
    },
    reqSendDensity(state) {
      state.sendDensity.status = RStatus.Pending;
    },
    setSendDensity(state, action: PayloadAction<{ data: EmailLineData[]; }>) {
      state.sendDensity = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSendDensityError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.sendDensity = {
        ...state.sendDensity,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqSendFunnel(state) {
      state.sendFunnel.status = RStatus.Pending;
    },
    setSendFunnel(state, action: PayloadAction<{ data: EmailActivity; }>) {
      state.sendFunnel = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSendFunnelError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.sendFunnel = {
        ...state.sendFunnel,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqSendSessions(state) {
      state.sendSessions.status = RStatus.Pending;
    },
    setSendSessions(state, action: PayloadAction<{ data: EmailPerformanceSliceState["sendSessions"]["value"]; }>) {
      state.sendSessions = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSendSessionsError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.sendSessions = {
        ...state.sendSessions,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reqSendsDetails(state) {
      state.sendsDetails.status = RStatus.Pending;
      state.sendsDetails.value = [];
    },
    setSendsDetails(state, action: PayloadAction<{ data: EmailSendDetailEntity[]; }>) {
      state.sendsDetails = {
        status: RStatus.Got,
        value: action.payload.data,
        errorMessage: undefined,
      };
    },
    setSendsDetailsError(state, action: PayloadAction<{ errorMessage: string; }>) {
      state.sendsDetails = {
        ...state.sendsDetails,
        status: RStatus.Error,
        errorMessage: action.payload.errorMessage,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const emailPerformanceReducer = emailPerformanceSlice.reducer;

export const {
  setSelectedSendSession,
  reqSendDensity,
  setSendDensity,
  setSendDensityError,
  reqSendFunnel,
  setSendFunnel,
  setSendFunnelError,
  reqSendSessions,
  setSendSessions,
  setSendSessionsError,
  reqSendsDetails,
  setSendsDetails,
  setSendsDetailsError,
  reset,
} = emailPerformanceSlice.actions;

export const selectActiveSendSession = (state: RootState) => state.library.emails.performance.selectedSendSession;
export const selectSendDensityState = (state: RootState) => state.library.emails.performance.sendDensity;
export const selectSendFunnelState = (state: RootState) => state.library.emails.performance.sendFunnel;
export const selectSendDetailsState = (state: RootState) => state.library.emails.performance.sendsDetails;
export const selectSendSessions = (state: RootState) => state.library.emails.performance.sendSessions;

export const selectSendDensityStatus = createSelector(selectSendDensityState, (r) => r.status);
export const selectSendDensityData = createSelector(selectSendDensityState, (r) => r.value);
export const selectSendDensityError = createSelector(selectSendDensityState, (r) => r.errorMessage);
export const selectSendFunnelStatus = createSelector(selectSendFunnelState, (r) => r.status);
export const selectSendFunnelData = createSelector(selectSendFunnelState, (r) => r.value);
export const selectSendFunnelError = createSelector(selectSendFunnelState, (r) => r.errorMessage);
export const selectSendDetailsStatus = createSelector(selectSendDetailsState, (r) => r.status);
export const selectSendDetailsData = createSelector(selectSendDetailsState, (r) => r.value);
export const selectSendDetailsError = createSelector(selectSendDetailsState, (r) => r.errorMessage);

export const selectSendDensityLineData = createSelector(selectSendDensityData, (density) => {
  return lineChartFactory(density);
});

// Maybe this should instead be created by the thunk? So it can include the filter dates
export const selectSendDetailsLineData = createSelector(
  [selectSendDetailsData, (_, ownProps: OwnProps) => ownProps.dateRange],
  (entities, dateRange): EmailDensityParsed => {
    const returnVal: EmailDensityParsed = {
      Date: [],
      Sends: [],
      Opens: [],
      Clicks: [],
      CumulativeSends: [],
      CumulativeOpens: [],
      CumulativeClicks: [],
    };
    if (!entities.length) {
      return returnVal;
    }

    const state = getRange(dateRange, { Opens: 0, Sends: 0, Clicks: 0, CumulativeSends: 0, CumulativeOpens: 0, CumulativeClicks: 0 }, "YYYY-MM-DD");



    for (const entity of entities) {
      const sendDateIndex = findIndexInRange(entity.SendDate, state);
      if (sendDateIndex >= 0) {
        state[sendDateIndex].Sends++;
      }

      const openDateIndex = findIndexInRange(entity.OpenDate, state);
      if (openDateIndex >= 0) {
        state[openDateIndex].Opens++;
      }

      const clickDateIndex = findIndexInRange(entity.ClickDate, state);
      if (clickDateIndex >= 0) {
        state[clickDateIndex].Clicks++;
      }
    }

    let sendsRunningTotal = 0;
    let opensRunningTotal = 0;
    let clicksRunningTotal = 0;

    for (const s of state) {
      returnVal.Date.push(moment(s.Date).local(true).toDate());
      returnVal.Sends.push(s.Sends);
      sendsRunningTotal += s.Sends;
      returnVal.Opens.push(s.Opens);
      opensRunningTotal += s.Opens;
      returnVal.Clicks.push(s.Clicks);
      clicksRunningTotal += s.Clicks;
      returnVal.CumulativeSends.push(sendsRunningTotal);
      returnVal.CumulativeOpens.push(opensRunningTotal);
      returnVal.CumulativeClicks.push(clicksRunningTotal);
    }

    return returnVal;
  },
);

export const selectSendDetailsBarData = createSelector(selectSendDetailsData, (entities): DataPoint[] => {
  const returnVal: DataPoint[] = [
    {
      id: "sends",
      category: "Sends",
      value: 0,
      fillColor: sendsColor,
    },
    {
      id: "opens",
      category: "Opens",
      value: 0,
      fillColor: opensColor,
    },
    {
      id: "clicks",
      category: "Clicks",
      value: 0,
      fillColor: clicksColor,
    },
  ];

  entities.forEach((entity) => {
    if (entity.SendDate) returnVal[0].value++;
    if (entity.OpenDate) returnVal[1].value++;
    if (entity.ClickDate) returnVal[2].value++;
  });

  return returnVal;
});
