import { useState, useEffect } from "react";
import { Popup, Transition } from "semantic-ui-react";
import cn from "classnames";
import debounce from "lodash/debounce";

import { TextTruncate } from "../textTruncators/TextTruncators";
import AccountPicture from "../accountPicture/AccountPicture";

import { ReactComponent as BsiLogo } from "../../images/BSI_Symbol.svg";
import { ReactComponent as BRAINSTORM } from "../../images/BRAINSTORM.svg";

import "./logo.scss";

export interface LogoProps {
  moboAccountName?: string;
  moboAccountLogoUrl?: string;
  collapsed?: boolean;
}

function Logo({ moboAccountName, moboAccountLogoUrl, collapsed }: LogoProps) {
  const [collapseClassName, setCollapseClassName] = useState("");
  const [isDynamicBannerOpen, setIsDynamicBannerOpen] = useState(false);
  const [isTransitionFinished, setIsTransitionFinished] = useState(false);
  const [isAccountNameTruncated, setIsAccountNameTruncated] = useState(false);
  const [isAnimationStarted, setIsAnimationStarted] = useState(false);
  const moboAlertText = "You are now managing on behalf of";
  const popupClassName = cn({ collapsed: collapsed });

  const showBannerDelay = 1000;
  const showBannerDuration = 4000;

  useEffect(() => {
    if (moboAccountName) {
      return timeout(() => setIsDynamicBannerOpen(true), showBannerDelay);
    }
  }, [moboAccountName]);

  useEffect(() => {
    if (moboAccountName) {
      return timeout(() => {
        setCollapseClassName("animation");
        setIsAnimationStarted(true);
      }, showBannerDuration);
    }
  }, [moboAccountName]);

  // debounce is deliberately applied here to accomodate navbar transiton,
  // without the delay the component can enter infinite loop of rerenders
  const debouncedTruncate = debounce((isTruncated: boolean) => {
    if (isTruncated !== isAccountNameTruncated) {
      setIsAccountNameTruncated(isTruncated);
    }
  }, showBannerDuration + showBannerDelay);

  const handleAccountNameTruncated = (isTruncated: boolean) => {
    debouncedTruncate(isTruncated);
  };

  const renderBannerAlert = function (accountName?: string, text?: string) {
    const popupContent = `${moboAlertText} ${accountName}`;

    return (
      <div className="banner-alert">
        <Popup
          trigger={
            <div className="content">
              <AccountPicture imageUrl={moboAccountLogoUrl} className="logo">
                <BsiLogo className="logo-placeholder" />
              </AccountPicture>
              <span className="text">
                {text}
                {text ? <span>&nbsp;</span> : ""}
                <TextTruncate className="account-name" onTruncate={handleAccountNameTruncated}>
                  {accountName}
                </TextTruncate>
              </span>
            </div>
          }
          content={popupContent}
          position={collapsed ? "bottom right" : "bottom center"}
          id="mobo-banner-popup"
          className={popupClassName}
          disabled={!isAnimationStarted || (!collapsed && !isAccountNameTruncated)}
        />
      </div>
    );
  };

  const renderStatic = function () {
    return !moboAccountName || !isTransitionFinished ? (
      <div className="branding">
        <BsiLogo className="logo-placeholder" />
        <BRAINSTORM className="brainstorm-label" />
      </div>
    ) : (
      <div className="static-banner">{renderBannerAlert(moboAccountName)}</div>
    );
  };

  const renderDynamic = function () {
    return (
      moboAccountName && (
        <Transition
          directional
          visible={isDynamicBannerOpen}
          animation="slide"
          duration={500}
          onComplete={onTransitionComplete}
        >
          <div className={cn("banner", collapseClassName)}>{renderBannerAlert(moboAccountName, moboAlertText)}</div>
        </Transition>
      )
    );
  };

  const onTransitionComplete = function () {
    setIsTransitionFinished(true);
  };

  return (
    <div className="branding-container">
      {renderDynamic()}
      {renderStatic()}
    </div>
  );
}

function timeout(func: Function, time: number) {
  const timeoutId = setTimeout(() => {
    func();
  }, time);

  return () => {
    clearTimeout(timeoutId);
  };
}

export default Logo;
