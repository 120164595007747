import PropTypes from "prop-types";
import cn from "classnames";

import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import moboConfigurator from "../../../moboConfigurator";

import "./title.scss";
import { Link } from "react-router-dom";

interface TitleProps {
  title?: string;
  onTitleClick?: () => void;
  className?: string;
  clamp?: number;
  url?: string;
}

function Title({ title, onTitleClick, className, clamp = 1, url }: TitleProps) {
  return (
    <>
      {url ? (
        <Link to={moboConfigurator.appendMoboIdToUrl(url)}>
          <Tooltip
            target={
              <TextTruncate
                lines={clamp}
                className={cn("row-title", className, {
                  clickable: onTitleClick,
                })}
              >
                {title}
              </TextTruncate>
            }
            content={title}
            className="list-view-row-title"
          />
        </Link>
      ) : (
        <Tooltip
          target={
            <TextTruncate
              lines={clamp}
              className={cn("row-title", className, {
                clickable: onTitleClick,
              })}
              onClick={onTitleClick}
            >
              {title}
            </TextTruncate>
          }
          content={title}
          className="list-view-row-title"
        />
      )}
    </>
  );
}

Title.defaultProps = {
  clamp: 1,
};

Title.propTypes = {
  title: PropTypes.string,
  onTitleClick: PropTypes.func,
  className: PropTypes.string,
  clamp: PropTypes.number,
  url: PropTypes.string,
};

export default Title;
