import { FormikProps } from "formik";
import { Component } from "react";
import { DropdownItemProps } from "semantic-ui-react";
import { ValidatedForm } from "../../../../../components";
import { PackFormProps } from "../types";
import { PackFormState, PackView } from "../../types/state";
import EndpointsToGetSasLink from "../../../../../enums/endpointsToGetSasLink";
import TrialPeriodSettings from "../TrialPeriodSettings/TrialPeriodSettings";
import "./packForm.scss";

export type PackFormPropsAll = PackFormProps & FormikProps<PackView>;

export default class PackForm extends Component<PackFormPropsAll, PackFormState> {
  constructor(props: PackFormPropsAll) {
    super(props);
    const { values } = this.props;
    this.state = { selectedItemsIds: values.packFilters };
  }

  render() {
    const { dirty, isLoading, disablePreventTransitionPrompt } = this.props;
    return (
      <ValidatedForm
        dirty={dirty}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message: "Are you sure you want to exit without saving this pack? All information entered will be lost.",
        }}
        isLoading={isLoading}
        isSubmitting={isLoading}
        formWidthComputer={9}
        disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      >
        {this.renderFields()}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const {
      values,
      disabled,
      onBlur,
      onIsValidChange,
      packFilters,
      packTypes,
      packTrialPeriods,
      save,
      isPrivatePack,
    } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    const dropdownOptions: DropdownItemProps[] = packTypes
    .filter((option) => option.name !== "Private")
      .map((option) => {
        return {
          text: option.name,
          value: option.id,
        };
      });

    return (
      <div className="pack-form-container">
        {!isPrivatePack && <h4 className="header">MARKETPLACE LISTING INFORMATION</h4>}
        <ValidatedForm.InputField
          label="Title"
          value={values.title}
          propertyName="title"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder="Enter Pack Title"
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Short Description"
          value={values.description}
          propertyName="description"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder="Enter Short Description"
          {...validatedFieldProps}
        />
        {!isPrivatePack && (
          <>
            <ValidatedForm.TextEditorField
              label="Long Description"
              value={values.longDescription}
              propertyName="longDescription"
              markAsRequired
              onIsFormValidChange={onIsValidChange}
              onBlur={onBlur}
              disabled={disabled}
              placeholder="Enter Long Description"
              {...validatedFieldProps}
            />
            <h4 className="header">PACK IMAGES</h4>
            <p>
              Upload up to 5 images. They will be used to showcase this pack in the marketplace. At least one image is
              required.
            </p>
          </>
        )}
        <ValidatedForm.ImagePreview
          label="Main Image"
          placeholder="Upload Images"
          value={values.thumbnailImageUrl}
          propertyName="thumbnailImageUrl"
          endpointToGetSasLink={EndpointsToGetSasLink.Pack}
          className="field"
          {...validatedFieldProps}
          markAsRequired
          onIsFormValidChange={(isValid: boolean) => isValid && onBlur("thumbnailImageUrl")}
          disabled={disabled}
          editBtnEnabled={false}
        />
        {!isPrivatePack && (
          <>
            <p>Supporting Images</p>
            <div className="supporting-images">
              {values.mediaItems.map((mi, index) => (
                <ValidatedForm.ImagePreview
                  key={index}
                  value={mi.url}
                  placeholder={null}
                  propertyName={`mediaItems[${index}].url`}
                  endpointToGetSasLink={EndpointsToGetSasLink.Pack}
                  className="field"
                  onImageChanged={(_: string) => onBlur("mediaItems")}
                  {...validatedFieldProps}
                  disabled={disabled}
                  editBtnEnabled={false}
                  removeBtnEnabled={true}
                />
              ))}
            </div>
            <h4 className="header">PACK SETTINGS</h4>
            <ValidatedForm.DropdownField
              {...validatedFieldProps}
              label="Pack Type"
              value={values.typeId}
              propertyName="typeId"
              options={dropdownOptions}
              placeholder="Select Pack Type"
              onIsFormValidChange={onIsValidChange}
              disabled={disabled}
              onBlur={onBlur}
              onChangeCallback={save}
              clearable
            />
            {Object.entries(packFilters).map(([key, value]) => (
              <ValidatedForm.MultiSelectField
                key={key}
                label={key}
                formFieldName={key}
                propertyName="packFilters"
                placeholder="Select up to 3"
                maxSelections={3}
                options={value.map((item) => ({ text: item.name, value: item.id }))}
                onBlur={onBlur}
                disabled={disabled}
                value={values.packFilters}
                {...validatedFieldProps}
              />
            ))}
            <TrialPeriodSettings
              values={values}
              trialOptions={packTrialPeriods}
              onBlur={onBlur}
              onChanged={save}
              disabled={disabled}
              validatedFieldProps={validatedFieldProps}
            />
          </>
        )}
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const {
      errors,
      touched,
      isValid,
      isValidating,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    } = this.props;

    return {
      errors,
      touched,
      isFormValid: isValid && !isValidating,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}
