import React, { FC } from "react";
import NoResults from "../../../../components/noResults/NoResults";
import { AssessmentsNoResultsProps } from "../types";
import { ReactComponent as NoAssetResult } from "../../../../images/assets-icon.svg";
import NoSearchResults from "../../../../components/noSearchResults";

const noAssessmentsCreated = (createButton: React.ReactElement | null | undefined): React.ReactElement => {
  const description = (
    <span>
      Looks like you don't have any assessments yet.
      {!!createButton && (
        <>
          <br />
          Add some using the button below.
        </>
      )}
    </span>
  );

  return (
    <NoResults title="You don’t have any assessments" description={description} icon={<NoAssetResult />}>
      {createButton}
    </NoResults>
  );
};

const AssessmentsNoResults: FC<AssessmentsNoResultsProps> = ({ createButton, filtered }): React.ReactElement => {
  return filtered ? <NoSearchResults /> : noAssessmentsCreated(createButton);
};

export default AssessmentsNoResults;
