import { combineReducers } from "redux";
import { threatDefenceFeedbackPagesOverview } from "./threatDefenceFeedbackPagesOverviewReducer";
import { feedbackPageDetails } from "./feedbackPageDetailsReducer";
import feedbackPageEntityState from "./feedbackPageEntityState";

export default combineReducers({
  threatDefenceFeedbackPagesOverview,
  feedbackPageDetails,
  feedbackPageEntityState,
});
