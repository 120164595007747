import { Loader } from "semantic-ui-react";
import { RootState, AppDispatch } from "features/Application/globaltypes/redux";
import { bindAction } from "interfaces";
import { connect, ConnectedProps } from "react-redux";
import { approveTemplate } from "../state/thunks/TemplateOverviewThunk";
import { noop } from "lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RouteNames from "enums/routeNames";

type Props = PropsFromRedux;

export const ApproveTemplateGroup = (props: Props) => {
  const { isSignalRConnected, approveTemplate } = props;
  const { id: groupIdToApprove } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignalRConnected && groupIdToApprove) {
      approveTemplate(Number(groupIdToApprove), "", noop);
      navigate("/" + RouteNames.inheritedGroupsUrl);
    }
  }, [isSignalRConnected, groupIdToApprove, approveTemplate, navigate]);

  return <Loader active />;
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    isSignalRConnected: state.appState.isSignalRConnected,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    approveTemplate: bindAction(approveTemplate, dispatch),
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ApproveTemplateGroup);
