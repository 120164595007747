import React, { useCallback, useEffect, useRef } from "react";
import { HorizontalFuncs, Props } from "../../types/HorizontalBarChart";

import { HorizontalBarChart } from "../HorizontalBarChart";

import "./horizontalBarWrapper.scss";

export interface WrapperProps extends Props {
  title: string;
  selectedValue?: string;
  onChange?: (index: number | null) => void;
}

export const HorizontalBarWrapper: React.FC<WrapperProps> = ({
  title,
  data,
  selectedValue,
  onChange,
  ...otherProps
}) => {
  const horizontalBarRef = useRef<HorizontalFuncs>(null);

  useEffect(() => {
    horizontalBarRef.current?.clearSelectedAnswerIndex();
  }, [data]);

  const onBarClick = useCallback(
    (_: unknown, index: number) => {
      horizontalBarRef.current?.setSelectedAnswerIndex(index);
      onChange?.(index);
    },
    [onChange],
  );

  // Have answer selected if selected from the upper page
  useEffect(() => {
    if (selectedValue) {
      const index = data.findIndex((dp) => dp.id === selectedValue);
      if (index !== -1) {
        onBarClick(null, index);
      }
    }
  }, [data, onBarClick, selectedValue]);

  return (
    <div className="horizontalWrapperRoot">
      <div className="headerContainer">
        <h2 className="title">{title}</h2>
      </div>
      <div className="horizontalChildWrapper">
        <HorizontalBarChart ref={horizontalBarRef} data={data} onClick={onBarClick} {...otherProps} />
      </div>
    </div>
  );
};
