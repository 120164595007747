import { FC, useEffect, useCallback } from "react";
import { CheckboxProps, Dimmer, Loader } from "semantic-ui-react";
import { FormikProps } from "formik";
import FormFooter from "../../../../../components/forms/FormFooter";
import GroupConfiguration from "../../AddEditGroupForms/GroupConfiguration/GroupConfiguration";
import { GroupInfoForm } from "../../../types";

interface Props {
  onIsAwareChanged: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  onVisibleToAllChanged: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  onIsOpenChanged: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  groupInfo: GroupInfoForm;
  saveGroupInfo: () => void;
  isInfoLoading: boolean;
  readonly?: boolean;
}

export type EditInfoProps = Props & FormikProps<any>;

const EditInfo: FC<EditInfoProps> = ({
  onIsAwareChanged,
  onVisibleToAllChanged,
  onIsOpenChanged,
  groupInfo,
  saveGroupInfo,
  isInfoLoading,
  errors,
  touched,
  isValid,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  dirty,
  values,
  initialValues,
  resetForm,
}) => {
  const validatedFieldProps = {
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    dirty,
    values,
    resetForm,
  };

  useEffect(
    () => onCancel,
    // eslint-disable-next-line
    [],
  );

  const onSave = useCallback(() => {
    saveGroupInfo();
    resetForm({ values: { ...values } });
  }, [saveGroupInfo, resetForm, values]);

  const onCancel = useCallback(() => {
    resetForm({ values: { ...initialValues } });
  }, [resetForm, initialValues]);

  return (
    <>
      <Dimmer active={isInfoLoading} inverted>
        <Loader />
      </Dimmer>
      <div className="group-info-form-container stretch scrollable-content edit-form">
        <GroupConfiguration
          {...validatedFieldProps}
          onIsAwareChanged={onIsAwareChanged}
          onVisibleToAllChanged={onVisibleToAllChanged}
          onIsOpenChanged={onIsOpenChanged}
          groupSettings={values.settings}
          isReadOnly={!groupInfo.canEdit || groupInfo.readOnly}
          isLoading={isInfoLoading}
          hideSettings={groupInfo.readOnly}
          disableEnrollmentEdit
          isInitialValid
        />
      </div>
      {groupInfo.canEdit && (
        <FormFooter
          isSaveBtnDisabled={!(dirty && isValid) || !groupInfo.canEdit}
          isCancelBtnDisabled={!dirty || !groupInfo.canEdit}
          onSave={onSave}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

export default EditInfo;
