import * as accountsEventTypes from "../../../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import { Dispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../../Application/globaltypes/redux";
import { connect, ConnectedProps } from "react-redux";
import { IntegrationTypes, GoogleIntegrationConfigs } from "../../types";
import { useEffect } from "react";
import integrationsDataService from "../../../services/integrationsDataService";
import { login } from "../../connectors/GoogleConnector";
import EditIntegrationBase from "../EditIntegrationBase/EditIntegrationBase";
import { integrationBaseSelector } from "../../state/selectors";
import { bindAction } from "../../../../../interfaces";
import { fetchIntegrationConfigsAction } from "../../state/thunks/integrationDetailsThunk";
import { reset } from "../../state/slices/integrationDetailsSlice";
import { useParams } from "react-router-dom";
import { IntegrationDtoMap } from "../../Shared";

const updateGoogleWithAuthentication = (requiredAuthorization: boolean, moboId?: string) => {
  return async (originConfigs: GoogleIntegrationConfigs, updatedConfigs: GoogleIntegrationConfigs) => {
    const resultConfig = { ...updatedConfigs };

    if (
      requiredAuthorization ||
      originConfigs.syncUsers !== updatedConfigs.syncUsers ||
      originConfigs.importUsageData !== updatedConfigs.importUsageData
    ) {
      const result = await login(updatedConfigs.syncUsers, updatedConfigs.importUsageData);

      if (!result.isSuccess) {
        return { isSuccess: false };
      }
      resultConfig.redirect = result.redirect;
      resultConfig.authenticationCode = result.result;
    }

    await integrationsDataService.updateIntegrationConfigs(IntegrationTypes.Google, resultConfig, moboId);

    return { isSuccess: true };
  };
};

const events = [accountsEventTypes.UpdateGoogleSuccess, accountsEventTypes.UpdateGoogleFailure];

const EditGoogle: React.FC<PropsFromRedux> = (props) => {
  const { integration, isLoading, fetchConfigs, resetConfigs } = props;
  const type = IntegrationTypes.Google;
  const { moboId } = useParams();

  const ownAccount = Number.isNaN(moboId) || moboId === undefined;

  const integrationUrl = IntegrationDtoMap.google.url(ownAccount, moboId);

  useEffect(() => {
    fetchConfigs(moboId);

    return () => {
      resetConfigs();
    };
  }, [resetConfigs, fetchConfigs, moboId]);

  return (
    <EditIntegrationBase
      moboId={ownAccount ? undefined : moboId}
      integration={integration}
      isLoading={isLoading}
      rtnEvents={events}
      integrationUrl={integrationUrl}
      integrationType={type}
      isAuthorized={integration.isAuthorized}
      onSaveHandler={updateGoogleWithAuthentication(!integration.isAuthorized, moboId)}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const base = integrationBaseSelector(state);

  return {
    integration: base.edit.details.data as GoogleIntegrationConfigs,
    isLoading: base.edit.details.isLoading,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchConfigs: bindAction((id) => fetchIntegrationConfigsAction(IntegrationTypes.Google, id), dispatch),
    resetConfigs: bindAction(reset, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditGoogle);
