import { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Loader } from "semantic-ui-react";

import { ViewType } from "../../enums";
import { pluralize } from "../../utils/stringUtils";
import miscUtils from "../../utils/miscellaneousUtils";
import {
  CardsViewer,
  ListViewBase,
  RestrictedResource,
  FilterSidebar,
  LazyLoader,
  ItemsViewHeader,
} from "../../components";

import "./itemsView.scss";
import { withRouter } from "../../adapters/withRouter/withRouter";

export class ItemsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewType: props.viewType || ViewType.GRID,
      showFilter: false,
      activePage: props.pagination || 1,
      itemsPerPage: 0,
    };
  }

  componentDidMount() {
    if (!this.props.doNotLoadPersistentViewType) {
      this.loadPersistentViewType();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.activePage && this.props.activePage !== prevProps.activePage) {
      this.setState({ activePage: this.props.activePage });
    }
  }

  showFilter = () => {
    if (!this.state.showFilter) {
      this.setState({ showFilter: true });
    }
  };

  hideFilter = () => {
    this.setState({ showFilter: false });
  };

  renderFilter = () => {
    let {
      renderFilterForm,
      resetFilter,
      appliedFilter,
      filterOptionsLoading,
      filterOptions,
      applyFilter,
      getFilterOptions,
    } = this.props;

    let { showFilter } = this.state;
    if (!applyFilter || !resetFilter) {
      return null;
    } else {
      return (
        <FilterSidebar
          isLoading={filterOptionsLoading}
          visible={showFilter}
          hideFilter={this.hideFilter}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          appliedFilter={appliedFilter}
          filterOptions={filterOptions}
          getFilterOptions={getFilterOptions}
        >
          {renderFilterForm && renderFilterForm(this.props)}
        </FilterSidebar>
      );
    }
  };

  renderNoResultsContent = () => {
    const { isLoading, noResultsContent, dragAndDrop } = this.props;
    return isLoading ? (
      <Loader active />
    ) : (
      <>
        {dragAndDrop}
        {noResultsContent}
      </>
    );
  };

  onViewTypeChange = (viewType) => {
    const { onViewTypeChange } = this.props;
    if (onViewTypeChange) {
      this.savePersistentViewType(viewType);
      this.setState({ viewType: viewType });
      onViewTypeChange(viewType);
    }
  };

  savePersistentViewType = (viewType) => {
    const path = this.props.location.pathname;
    const tabAlias = this.props.tabAlias;
    const persistentViewJson = JSON.stringify({ viewType, path });
    localStorage.setItem(`persistentView:${path}/${tabAlias}`, persistentViewJson);
  };

  loadPersistentViewType = () => {
    const { onViewTypeChange, location, tabAlias } = this.props;
    const path = location.pathname;
    const savedSettings = JSON.parse(localStorage.getItem(`persistentView:${path}/${tabAlias}`));
    const savedPath = savedSettings && savedSettings.path;
    const savedViewType = savedSettings && savedSettings.viewType;
    const isSavedMatchesCurrentPath = savedPath === path;

    if (isSavedMatchesCurrentPath && onViewTypeChange) {
      this.setState({ viewType: savedViewType });
      onViewTypeChange(savedViewType);
    } else {
      const { onLoad } = this.props;
      onLoad && onLoad();
    }
  };

  nextPage = () => {
    const { setPagination } = this.props;
    setPagination?.(this.state.activePage);
    this.setState({ activePage: this.state.activePage + 1 });
  };

  prevPage = () => {
    const { setPagination } = this.props;
    setPagination?.(this.state.activePage - 1);
    this.setState({ activePage: this.state.activePage - 1 });
  };

  pageChanged = (newActivePage) => {
    const { setPagination } = this.props;
    setPagination?.(newActivePage);
    this.setState({ activePage: newActivePage });
  };

  itemsPerPageAmountChange = (newItemsPerPage) => {
    const { setResultsPerPage } = this.props;
    this.setState({ itemsPerPage: newItemsPerPage });
    setResultsPerPage?.(newItemsPerPage);
    this.props.itemsPerPageAmountChange && this.props.itemsPerPageAmountChange(newItemsPerPage);
  };

  renderItems = () => {
    const {
      isLoading,
      sortOptions,
      blur,
      onSortChange,
      itemsInlineCount,
      applyFilter,
      appliedFilter,
      customHeaderContent,
      disableListViewButton,
      renderSearch,
      accessRestricted,
      hideListGridViewSwitcherButton,
      renderFilterForm,
      disableViewHeader,
    } = this.props;
    const { activePage, viewType, itemsPerPage } = this.state;
    const isGrid = viewType === ViewType.GRID;

    const itemsPageRange = miscUtils.getItemsPageRange(activePage, itemsPerPage, itemsInlineCount);

    return (
      <>
        <ItemsViewHeader
          disableListViewButton={disableListViewButton}
          hideListGridViewSwitcherButton={hideListGridViewSwitcherButton}
          accessRestricted={accessRestricted}
          viewType={this.state.viewType}
          orderBy={this.props.orderBy}
          onViewTypeChange={this.onViewTypeChange}
          onFilterPress={this.showFilter}
          itemsInlineCount={itemsInlineCount}
          isLoading={isLoading}
          appliedFilter={appliedFilter}
          applyFilter={applyFilter}
          isFiltersButtonDisplayed={!!renderFilterForm}
          sortOptions={sortOptions}
          blur={blur}
          onSortChange={onSortChange}
          onNextPage={this.nextPage}
          onPrevPage={this.prevPage}
          activePage={activePage}
          itemsPageRange={itemsPageRange}
          search={<div className="items-view-search">{renderSearch && renderSearch(accessRestricted)}</div>}
          disabled={disableViewHeader}
        >
          {customHeaderContent}
        </ItemsViewHeader>
        <div className={`${isGrid ? "card" : "list"}-view-content`}>
          <RestrictedResource isAuthorized={!accessRestricted}>{this.renderCurrentView()}</RestrictedResource>
        </div>
      </>
    );
  };

  renderCardView = () => {
    const {
      getData,
      items,
      onSelectedItemsChanged,
      selectedIds,
      isLoading,
      isAllDataLoaded,
      itemsInlineCount,
      dragAndDrop,
      isSelectDisabled,
      itemsType,
      onSelectionChanged,
      hasCardURL,
      renderCard,
    } = this.props;

    return (
      <>
        {items.length > 0 ? (
          <div className="fill-container">
            <LazyLoader
              getData={getData}
              isFirstLoad={items.length === 0}
              loadingData={isLoading}
              isAllDataLoaded={isAllDataLoaded}
              className="right-gutter"
            >
              <div className="number-of-results grid">{`${itemsInlineCount} ${pluralize(
                itemsType || "Result",
                itemsInlineCount,
              )}`}</div>
              {dragAndDrop}
              <CardsViewer
                cardItems={items}
                onSelectedCardsChanged={onSelectedItemsChanged}
                selectedIds={selectedIds}
                isSelectDisabled={isSelectDisabled}
                onSelectionChanged={onSelectionChanged}
                hasCardURL={hasCardURL}
                renderCard={renderCard}
              />
            </LazyLoader>
          </div>
        ) : (
          this.renderNoResultsContent()
        )}
      </>
    );
  };

  renderListView = () => {
    const {
      items,
      columnOptions,
      getData,
      isLoading,
      buildTableBody,
      itemsInlineCount,
      appliedFilter,
      onSelectedListItemsChanged,
      selectedIds,
      noResultsContent,
      applyFilter,
      sortingColumnName,
      setSortingColumnName,
      setSortingDirection,
      sortingDirection,
      withSelection,
      listViewRtnEvents,
      dragAndDrop,
      isSelectDisabled,
      setReloadListItems,
      onRequestParamsUpdated,
      permissions,
      onSelectionChanged,
      resultsPerPage,
      paginateOnLoad,
      rowAs,
    } = this.props;
    return (
      <>
        <ListViewBase
          paginateOnLoad={paginateOnLoad}
          columnOptions={columnOptions}
          loadPage={getData}
          itemsAmount={itemsInlineCount}
          isLoading={isLoading}
          items={items}
          updateSelectedItems={onSelectedListItemsChanged}
          buildTableBody={buildTableBody}
          onFilterPress={this.showFilter}
          filter={appliedFilter}
          selectedItemIds={selectedIds}
          noResultsContent={noResultsContent}
          applyFilter={applyFilter}
          sortingColumnName={sortingColumnName}
          sortingDirection={sortingDirection}
          setSortingColumnName={setSortingColumnName}
          setSortingDirection={setSortingDirection}
          activePage={this.state.activePage}
          withSelection={withSelection}
          onItemsPerPageAmountChange={this.itemsPerPageAmountChange}
          resultsPerPage={resultsPerPage}
          updateComponentRtnEvents={listViewRtnEvents}
          onPageChanged={this.pageChanged}
          isSelectDisabled={isSelectDisabled}
          setReloadItems={setReloadListItems}
          onRequestParamsUpdated={onRequestParamsUpdated}
          permissions={permissions}
          onSelectionChanged={onSelectionChanged}
          rowAs={rowAs}
        />
        {dragAndDrop}
      </>
    );
  };

  renderCurrentView = () => {
    if (this.state.viewType === ViewType.GRID) {
      return this.renderCardView();
    } else {
      return this.renderListView();
    }
  };

  render() {
    return (
      <div className={cn("items-view", this.props.className)}>
        {this.renderFilter()}
        {this.renderItems()}
      </div>
    );
  }
}

ItemsView.defaultProps = {
  noResultsContent: null,
  tabAlias: "",
};

ItemsView.propTypes = {
  location: PropTypes.object,
  getData: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  noResultsContent: PropTypes.node,
  viewType: PropTypes.string,
  onViewTypeChange: PropTypes.func,
  className: PropTypes.string,
  sortOptions: PropTypes.array,
  blur: PropTypes.bool,
  onSortChange: PropTypes.func,
  orderBy: PropTypes.string,
  renderCard: PropTypes.func,
  onSelectedItemsChanged: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  onSelectedListItemsChanged: PropTypes.func,
  isLoading: PropTypes.bool,
  isAllDataLoaded: PropTypes.bool,
  columnOptions: PropTypes.array,
  buildTableBody: PropTypes.func,
  itemsInlineCount: PropTypes.number,
  listViewRtnEvents: PropTypes.array,

  renderFilterForm: PropTypes.func,
  filterOptions: PropTypes.object,
  filterOptionsLoading: PropTypes.bool,
  resetFilter: PropTypes.func,
  appliedFilter: PropTypes.object,
  applyFilter: PropTypes.func,
  getFilterOptions: PropTypes.func,
  sortingColumnName: PropTypes.string,
  sortingDirection: PropTypes.string,
  onItemsPerPageAmountChange: PropTypes.func,
  isSelectDisabled: PropTypes.func,
  withSelection: PropTypes.bool,
  accessRestricted: PropTypes.bool,

  customHeaderContent: PropTypes.node,
  dragAndDrop: PropTypes.node,
  disableListViewButton: PropTypes.bool,
  hideListGridViewSwitcherButton: PropTypes.bool,
  activePage: PropTypes.number,
  onLoad: PropTypes.func,
  tabAlias: PropTypes.string,
  setReloadListItems: PropTypes.func,
  renderSearch: PropTypes.func,
  doNotLoadPersistentViewType: PropTypes.bool,
  permissions: PropTypes.array,
  itemsType: PropTypes.string,
  onSelectionChanged: PropTypes.func,
  paginateOnLoad: PropTypes.bool,
  disableViewHeader: PropTypes.bool,
  rowAs: PropTypes.func,
};

export default withRouter(ItemsView);
