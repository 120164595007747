import { IWizardStep } from "../../../../interfaces";

export interface CreateEventOwnState {
  isValid: boolean;
  showUsersAssignModal: boolean;
  licensingConfirmProceed?: (resolve: boolean) => void;
  isNotifyStepLoaded: boolean;
  isNotifyStepValid: boolean;
  activePage: CreateEventSteps;
  showAddPacksModal: boolean;
}

export interface PeopleOverviewProps extends IWizardStep { }

export interface UserAssignmentModalProps {
  entityId: number;
  eventName: string;
  showModal: boolean;
  closeModal: () => void;
  usersToAssign: number[];
  licensingConfirmProceed?: (resolve: boolean) => void;
}

export enum CreateEventSteps {
  Configure,
  Settings,
  Content,
  Reminders,
  People,
}

export const showLastModified = new Set([
  CreateEventSteps.Configure,
  CreateEventSteps.Content,
  CreateEventSteps.Reminders,
]);
