import ColumnOption from "../../../../../../interfaces/columnOptions";

const columnOptions: Array<ColumnOption> = [
  {
    name: "Id",
    width: 1,
    isSortable: true,
  },
  {
    name: "Status",
    width: 3,
    isSortable: false,
  },
  {
    name: "Start Date",
    width: 3,
    isSortable: false,
  },
];

export default columnOptions;
