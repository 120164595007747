export const operatorsToString = {
  ">": "Is Greater Than",
  "<": "Is Less Than",
  ">=": "Is Greater Than Or Equal To",
  "<=": "Is Less Than Or Equal To",
  "=": "Is Equal To",
  "!=": "Is Not Equal To",
};

export const baseTriggerColumns = [
  {
    title: "Vendor",
    fieldName: "software",
    info: "Vendors with integrations configured within the account.",
  },
  {
    title: "Application",
    fieldName: "application",
    info: "Application triggers available for the integrated vendor.",
  },
  {
    title: "Action",
    fieldName: "action",
    info: "Select an action or item within the application to activate the trigger.",
  },
];

export const googleTriggerColumn = {
  title: "Event",
  fieldName: "event",
  info: "Select an event or item within the application to activate the trigger.",
};

export const expressionsTriggerColumns = [
  {
    title: "Filter",
    fieldName: "filter",
    info: "Select an operand to apply to the selected action and value.",
  },
  {
    title: "Value",
    fieldName: "criteria",
    info: "Enter the number needed to activate the trigger.",
  },
];

export const MAX_TRIGGERS_COUNT = 4;
