import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { RStatus } from "../../../../Application/globaltypes/fetchRequest";
import { EngagementHorizontalData, EngagementPeopleData, PdfPerformanceState } from "../../types/state";

const namespace = ReducerNamespaceTypes.Pdfs;
const entityPrefix = ReducerEntityPrefixTypes.Pdfs_Performance;

export const initialState: PdfPerformanceState = {
  engagementLineData: {
    status: RStatus.Idle,
    value: [],
    errorMessage: undefined,
  },
  engagementHorizontalData: {
    status: RStatus.Idle,
    value: {
      NotStartedCount: 0,
      CompletedCount: 0,
    },
    errorMessage: undefined,
  },
  engagementPeopleData: {
    status: RStatus.Idle,
    value: [],
    errorMessage: undefined,
  },
};

const pdfPerformanceSlice = createSlice({
  name: `${namespace}/${entityPrefix}`,
  initialState,
  reducers: {
    reqEngagementLineData(state) {
      state.engagementLineData.status = RStatus.Pending;
    },
    reqEngagementHorizontalData(state) {
      state.engagementHorizontalData.status = RStatus.Pending;
    },
    reqEngagementPeopleData(state) {
      state.engagementPeopleData.status = RStatus.Pending;
    },
    setEngagementLineData(state, action: PayloadAction<PdfPerformanceState["engagementLineData"]["value"]>) {
      state.engagementLineData = {
        status: RStatus.Got,
        value: action.payload,
        errorMessage: undefined,
      };
    },
    setEngagementHorizontalData(state, action: PayloadAction<EngagementHorizontalData>) {
      state.engagementHorizontalData = {
        status: RStatus.Got,
        value: action.payload,
        errorMessage: undefined,
      };
    },
    setEngagementPeopleData(state, action: PayloadAction<EngagementPeopleData>) {
      state.engagementPeopleData = {
        status: RStatus.Got,
        value: action.payload,
        errorMessage: undefined,
      };
    },
    setEngagementLineError(state, action: PayloadAction<string>) {
      state.engagementLineData.status = RStatus.Error;
      state.engagementLineData.errorMessage = action.payload;
    },
    setEngagementHorizontalError(state, action: PayloadAction<string>) {
      state.engagementHorizontalData.status = RStatus.Error;
      state.engagementHorizontalData.errorMessage = action.payload;
    },
    setEngagementPeopleError(state, action: PayloadAction<string>) {
      state.engagementPeopleData.status = RStatus.Error;
      state.engagementPeopleData.errorMessage = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  reqEngagementLineData,
  reqEngagementHorizontalData,
  reqEngagementPeopleData,
  setEngagementLineData,
  setEngagementHorizontalData,
  setEngagementPeopleData,
  setEngagementLineError,
  setEngagementHorizontalError,
  setEngagementPeopleError,
  reset,
} = pdfPerformanceSlice.actions;

export default pdfPerformanceSlice.reducer;
