import React, { FC } from "react";
import { ReactComponent as NoAssetResult } from "../../../../images/assets-bulk-upload-icon.svg";
import { NoResultsWithButton } from "../../../../components";

export interface PdfsNoResultsProps {
  createButton?: React.ReactElement | null;
  filtered?: boolean;
}

const PdfsNoResults: FC<PdfsNoResultsProps> = ({ createButton, filtered }) => {
  return (
    <NoResultsWithButton
      title="Drag & Drop PDF Here"
      description="You can drag and drop your PDF here, or click"
      actionText="the button below to get started."
      icon={<NoAssetResult />}
      actionButton={createButton}
      filtered={filtered}
    />
  );
};

export default PdfsNoResults;
