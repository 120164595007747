import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { SessionsOwnProps } from "../types";
import { FormikProps, withFormik } from "formik";
import { SessionsPayload, SessionsView, SessionView } from "../../types/state";
import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import * as eventEntityStateActions from "../../state/actions/eventEntityStateActions";
import * as eventDetailsActions from "../../state/actions/eventDetailsActions";
import { RootState } from "../../../../Application/globaltypes/redux";
import SingleSessionForm from "./SessionComponents/SingleSession/SingleSessionForm";
import { AutosaveProps, withAutosave } from "../../../../../utils/withAutosave";
import RecurringSessionForm from "./SessionComponents/RecurringSession/RecurringSessionForm";
import ViewPayloadConversions from "../../utils/ViewPayloadConversions";

import { sessionTypes } from "./constants";
import * as eventSessionsValidatorActions from "../../state/actions/eventSessionsValidatorActions";
import { isEqual } from "lodash";
import Observable from "../../../../../utils/Observable";
import TriggerableConfirmationModal from "../../../../../components/triggeredConfirmationModal/TriggerableConfirmationModal";

import { useLocalContext } from "../../../../../hooks/useLocalContext";
import { ValidatedForm } from "../../../../../components/forms";
import { Divider } from "semantic-ui-react";
import { getPropName } from "./Utils/getSessionPropName";
import { useFormikValidation } from "../../../Common/Hooks/useFormikValidationHook";

import { getDefaultSession } from "./Utils/utils";
import { extractFormikProps } from "../../../../../utils/formikUtils";
import "./sessions.scss";
import ObjectUtils from "../../../../../utils/objectUtils";

export type SessionsProps = SessionsOwnProps & PropsFromRedux & FormikProps<SessionsView> & AutosaveProps;

export const Sessions = (props: SessionsProps) => {
  const context = useLocalContext({ values: props.values });

  const { validateActions, resolved, initialized, activeErrors, isFormValid, onBlur } = props;
  const formik = extractFormikProps<SessionsView, SessionsProps>(props);
  formik.touched = !resolved ? props.touchedSnapshot : {};

  useFormikValidation({ errors: formik.errors, resolved, initialized, activeErrors, isFormValid }, validateActions);

  const onEventTypeChangeObservable = useMemo(() => {
    return new Observable<(onConfirm: () => void) => void>();
  }, []);

  useLayoutEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      props.detailsActions.saveSessions(context.current.values);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.acceptHandlers?.({
      onClose: props.detailsActions.clearEvent,
    });
    props.detailsActions.saveSessions(context.current.values);
    props.subscribeOnDiscarded?.(formik.resetForm);
    return () => {
      props.unSubscribeOnDiscarded?.(formik.resetForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDefaultSession = () => {
    const { sessions } = props.values;
    const first = sessions?.[0];

    return sessions?.length === 1 && isEqual(first, getDefaultSession(first.isPeriodic));
  };

  const handleEventTypeChange = async (isPeriodic: boolean, onConfirm: () => Promise<void>) => {
    const previous = props.values.sessions?.[0]?.isPeriodic;

    if (previous === isPeriodic || isDefaultSession()) {
      await onConfirm();
    } else {
      return new Promise<void>((resolve) => {
        onEventTypeChangeObservable.notify(async () => {
          formik.resetForm();
          props.validateActions.resetAction();
          await onConfirm();

          resolve();
        });
      });
    }
  };

  const onTypeChange = async (_name: string, value: boolean, _?: boolean) => {
    await handleEventTypeChange(value, async () => {
      await (formik.setFieldValue(
        "sessions",
        [{ ...props.values.sessions?.[0], isPeriodic: !!value }],
        true,
      ) as unknown as Promise<void>);
    });
  };

  const renderSessionComponent = (): React.ReactNode => {
    const { isLoading, isCreating, save, disabled } = props;
    const formProps = {
      formik,
      onBlur,
      save,
      isLoading,
      isCreating,
      disabled,
    };
    return !props.values.sessions[0].isPeriodic ? (
      <SingleSessionForm {...formProps} />
    ) : (
      <RecurringSessionForm {...formProps} />
    );
  };

  return (
    <div className="sessions">
      <div className="session-type-group">
        <ValidatedForm.RadioGroupField
          propertyName={getPropName<SessionView>("isPeriodic")}
          value={!!props.values.sessions[0]?.isPeriodic}
          options={sessionTypes}
          disabled={props.disabled}
          {...formik}
          onBlur={onBlur}
          setFieldValue={onTypeChange}
        />
      </div>
      <Divider />

      {renderSessionComponent()}

      <TriggerableConfirmationModal
        title={"Changing Session Type"}
        content={"Are you sure you want to change session type? Some information entered can be lost."}
        onTriggerModalObserver={onEventTypeChangeObservable}
      />
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: SessionsOwnProps) => {
  const eventEntityState = state.library.events.eventEntityState;
  const eventDetails = state.library.events.eventDetails;
  return {
    id: ownProps.entityId || eventEntityState.entityId,
    isDraft: eventDetails.event.isDraft,
    eventSessions: eventDetails.event.externalEventSessions,
    isCreating: eventEntityState.changingEntityState,
    isLoaded: eventDetails.isSessionsLoaded,
    isLoading: eventDetails.isLoading,
    ...state.library.events.eventSessionsValidationReducer,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(eventEntityStateActions, dispatch),
  detailsActions: bindActionCreators(eventDetailsActions, dispatch),
  validateActions: bindActionCreators(eventSessionsValidatorActions, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<SessionsProps, SessionsView, SessionsPayload>({
  getInitValues: (props) => ({
    id: props.id,
    sessions: ViewPayloadConversions.getPayloadFromViewSessions(props.eventSessions.sessions),
  }),
  stateProvider: (values, props) => ({
    id: props.id,
    sessions: ViewPayloadConversions.getPayloadFromViewSessions(values.sessions),
  }),
  entityUpdater: (props) => props.entityStateActions.updateSessions,
  isValid: (props) => props.isDraft && props.id > 0,
  stateProjector: (_, props) => ({
    id: props.id,
    sessions: ViewPayloadConversions.getPayloadFromViewSessions(props.values.sessions),
  }),
})(Sessions);

/* istanbul ignore next */
const component = withFormik({
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: (props: SessionsOwnProps & PropsFromRedux) => {
    return ObjectUtils.isEmptyDeep(props.eventSessions)
      ? { sessions: [getDefaultSession(false)] }
      : props.eventSessions;
  },
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
  validationSchema: (props: SessionsOwnProps & PropsFromRedux) => props.schema,
})(withAutosaveComponent);

export default connector(component);
