import React from "react";
import { PropTypes } from "prop-types";
import { Checkbox } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import cn from "classnames";

import Modal from "./Modal";

import "./ConfirmationModal.scss";

class ConfirmationModal extends React.Component {
  state = {
    suppressPopup: false,
  };

  renderModalActions = (closeModal) => {
    this.closeModal = closeModal;
    const { confirmButtonName, cancelButtonName } = this.props;
    return (
      <>
        <Button className="cancel" onClick={this.onCloseClick} basic color="blue">
          {cancelButtonName ?? "Cancel"}
        </Button>
        <Button className="confirm" onClick={this.onConfirmClick} primary>
          {confirmButtonName ?? "Confirm"}
        </Button>
      </>
    );
  };

  onCloseClick = () => {
    const { onClose } = this.props;
    this.closeModal();
    onClose && onClose();
  };

  onConfirmClick = () => {
    const { onConfirm, onSuppressPopupStatusChanged } = this.props;
    this.closeModal();
    onSuppressPopupStatusChanged?.(this.state.suppressPopup);
    onConfirm();
  };

  onSuppressCheckboxChanged = (_, data) => {
    this.setState({
      suppressPopup: data.checked,
    });
  };

  render() {
    const { title, message, checkboxLabel, open, className, onClose, checkboxIsHidden } = this.props;

    return (
      <Modal
        className={cn("confirmation-popup", className)}
        title={title}
        renderModalActions={this.renderModalActions}
        open={open}
        onCancel={onClose}
      >
        {message}
        {!checkboxIsHidden && (
          <div>
            <Checkbox label={checkboxLabel} onChange={this.onSuppressCheckboxChanged} />
          </div>
        )}
      </Modal>
    );
  }
}

ConfirmationModal.defaultProps = {
  checkboxLabel: "Don't display this message again",
};

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checkboxLabel: PropTypes.string,
  confirmButtonName: PropTypes.string,
  cancelButtonName: PropTypes.string,
  checkboxIsHidden: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSuppressPopupStatusChanged: PropTypes.func,
};

export default ConfirmationModal;
