import { FC } from "react";
import { sum } from "lodash";

import { ContentItemMenu } from "../../../../../components/buttons/contentItemMenu/ContentItemMenu";
import { DeleteLinkButton, EditLinkButton, PublishLinkButton } from "../../../../../components/buttons/linkButtons";
import DeletionRestrictedTooltip from "../../../../../components/tooltips/deletionRestrictedTooltip/DeletionRestrictedTooltip";
import { VideoOverview } from "../../types/models";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

const ENTITY_TYPE = "asset";

export interface VideoContentItemMenuProps {
  item: VideoOverview;
  handleEditClick: (id: number, isDraft: boolean) => () => void;
  handlePublishClick: (
    id: number,
    isDraft: boolean,
    flowsCount: number,
    assignmentsCount: number,
    hasBeenPublished: boolean,
  ) => () => void;
  handleDeleteClick: (id: number[], flowsCount?: number, packsCount?: number) => () => void;
  readonly?: boolean;
  isChecked?: boolean;
  circle?: boolean;
  outlinedEllipsis?: boolean;
  hasPermission?: boolean;
}

export const VideoContentItemMenu: FC<VideoContentItemMenuProps> = (props) => {
  const {
    circle,
    outlinedEllipsis,
    readonly,
    isChecked,
    item,
    handleEditClick,
    handlePublishClick,
    handleDeleteClick,
    hasPermission = true,
  } = props;
  const { flowsCount = 0, packsCount = 0, assignmentsCount = 0 } = item.dependencies || {};
  const dependencies = { flows: flowsCount, packs: packsCount, "users or groups": assignmentsCount };
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);

  return (
    <RestrictedByTooltip hasPermission={hasPermission}>
      <ContentItemMenu
        circle={circle}
        outlinedEllipsis={outlinedEllipsis}
        isChecked={isChecked}
        readonly={readonly}
        dependencies={dependencies}
        entityType={ENTITY_TYPE}
        item={item}
      >
        <EditLinkButton onClick={handleEditClick(item.id, item.isDraft)} isDisabled={item.isPurchased} />
        {item.isDraft && (
          <PublishLinkButton
            onClick={handlePublishClick(item.id, item.isDraft, flowsCount, assignmentsCount, item.hasBeenPublished)}
            isDisabled={!item.canBePublished}
          />
        )}
        <DeleteLinkButton
          isDisabled={!deleteContentEnabled && !item.canBeDeleted}
          after={
            !deleteContentEnabled &&
            !item.isPurchased &&
            !!sum(Object.values(dependencies)) && (
              <DeletionRestrictedTooltip dependencies={dependencies} entityType={ENTITY_TYPE} />
            )
          }
          onClick={handleDeleteClick([item.id], flowsCount, packsCount)}
        />
      </ContentItemMenu>
    </RestrictedByTooltip>
  );
};
