import * as Yup from "yup";

import { labelValues, softwareApplicationValues } from "./tagsValidationSchema";
import { SurveyAnswerType, SurveyQuestionType } from "../../features/Library/Surveys/types/models";
import { requiredTextFieldMinMax } from "./commonValidationSchemas";

const titleLengths = {
  min: 3,
  max: 64,
};

const descriptionLength = {
  max: 256,
  min: 3,
};
const endNoteTextMaxLength = 256;

const endNoteText = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .max(endNoteTextMaxLength, `End Note Text must be maximum of ${endNoteTextMaxLength} characters long`);

export const surveyInfo = Yup.object().shape({
  title: requiredTextFieldMinMax("Title", titleLengths.min, titleLengths.max),
  description: requiredTextFieldMinMax("Description", descriptionLength.min, descriptionLength.max),
  endNoteText: Yup.string().when(
    "endNoteTextEnabled",
    {
      is: true,
      then: (_) => endNoteText()
    }),
});

export const surveySettings = Yup.object().shape({
  labels: labelValues,
  softwareApplications: softwareApplicationValues,
});

const questionSchemas = (() => {
  const textQuestionValidation = Yup.string().trim().required("Question text is required");
  const pictureQuestionValidation = Yup.object().shape({
    url: Yup.string().trim().required("Url is required"),
  });

  return {
    [SurveyQuestionType.MultipleChoiceTextQuestion]: textQuestionValidation,
    [SurveyQuestionType.PictureQuestion]: pictureQuestionValidation,
    [SurveyQuestionType.SingleChoiceTextQuestion]: textQuestionValidation,
  };
})();

const getQuestionValidationSchema = (type: SurveyQuestionType) => questionSchemas[type];

const answerSchemas = (() => {
  const textAnswerValidation = Yup.string().trim().required("Answer text is required");
  const pictureAnswerValidation = Yup.object().shape({
    url: Yup.string().trim().required("Url is required"),
  });

  return {
    [SurveyAnswerType.Text]: textAnswerValidation,
    [SurveyAnswerType.Picture]: pictureAnswerValidation,
  };
})();

const getAnswerValidationSchema = (type: SurveyAnswerType) => answerSchemas[type];

export const surveysContent = Yup.object().shape({
  questions: Yup.array().of(
    Yup.lazy((question: any) => {
      return Yup.object().shape({
        question: getQuestionValidationSchema(question.questionType),
        answers: Yup.array().of(
          Yup.lazy((answer: any) => {
            return Yup.object().shape({
              answer: getAnswerValidationSchema(answer.answerType),
            });
          }) as any,
        ),
      });
    }) as any,
  ),
});
