import { useEffect, useState } from "react";
import { SemanticWIDTHS } from "semantic-ui-react";

import { NCardsScreenWidth } from "./CardsViewer";

const getResponsiveItemsPerRow = (): SemanticWIDTHS => {
  // These breakpoints prevent card widths going below 310px;
  if (window.innerWidth >= NCardsScreenWidth.fiveCardWidth) {
    return 5;
  }
  if (window.innerWidth >= NCardsScreenWidth.fourCardWidth) {
    return 4;
  }
  if (window.innerWidth >= NCardsScreenWidth.threeCardWidth) {
    return 3;
  }
  if (window.innerWidth >= NCardsScreenWidth.twoCardWidth) {
    return 2;
  }
  return 1;
};

export default function useCardsResize() {
  const [responsiveItemsPerRow, setResponsiveItemsPerRow] = useState(() => getResponsiveItemsPerRow());

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setResponsiveItemsPerRow(getResponsiveItemsPerRow());
  };

  return responsiveItemsPerRow;
}
