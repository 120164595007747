import React, { useRef } from "react";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";

import UploadPdfField from "./UploadPdfField";
import EndpointsToGetSasLink from "../../../../../enums/endpointsToGetSasLink";

import { ValidatedForm } from "../../../../../components/forms";
import { PdfFormFields, PdfFormProps } from "../types";
import { FileLike } from "../../../../../interfaces";
import { nameofFactory } from "../../../../../utils/nameof";
import { extractFormikProps } from "../../../../../utils/formikUtils";

export type PdfFormPropsAll = FormikProps<PdfFormFields> & PdfFormProps;

const PdfForm = (props: PdfFormPropsAll) => {
  const { values } = props;
  const descriptionHasInitialValue = useRef(!isEmpty(values.description));
  const formik = extractFormikProps<PdfFormFields, PdfFormPropsAll>(props);

  const onFileUpload = (fileName: string, uploadedPdfs: FileLike[], title: string, description: string) => {
    const newValues = { ...values, fileName, title, description, uploadedPdfs };
    props.setValues(newValues);
    props.onFileUpload(newValues);
  };

  const nameof = nameofFactory<PdfFormFields>();

  const onTitleBlur = (propertyName: string) => {
    const descriptionField = nameof("description");
    if (props.mode === "create" && !descriptionHasInitialValue.current && !props.touched[descriptionField]) {
      props.setFieldValue(descriptionField, values.title, true);
    }

    props.onBlur(propertyName);
  };

  const renderFields = (): React.ReactNode => {
    const isReadOnly = props.disabled || props.id <= 0;
    const firstUpload = props.id <= 0;

    return (
      <div>
        <UploadPdfField
          id={props.id}
          mode={props.mode}
          disabled={!!props.disabled}
          value={values.uploadedPdfs.length > 0 ? values.uploadedPdfs : [{ name: values.fileName } as FileLike]}
          formik={formik}
          firstUpload={firstUpload}
          onFileUpload={onFileUpload}
        />
        <ValidatedForm.InputField
          {...formik}
          label="Title"
          tooltip={{
            info: "This field is visible to end users.",
            width: 17,
          }}
          placeholder={"Enter PDF Title"}
          value={values.title}
          propertyName={nameof("title")}
          onBlur={onTitleBlur}
          markAsRequired
          disabled={isReadOnly}
        />
        <ValidatedForm.TextAreaField
          {...formik}
          tooltip={{
            info: "This field is visible to users and is displayed by the title.",
            width: 17,
          }}
          label="Description"
          placeholder={"Enter Description"}
          value={values.description}
          propertyName={nameof("description")}
          onBlur={props.onBlur}
          disabled={isReadOnly}
          markAsRequired
        />
        <ValidatedForm.ImagePreview
          {...formik}
          label="Thumbnail Image"
          value={values.thumbnailUrl}
          propertyName={nameof("thumbnailUrl")}
          endpointToGetSasLink={EndpointsToGetSasLink.Pdf(props.id)}
          onImageChanged={(_, imageName) => props.onImageChanged(imageName)}
          disabled={isReadOnly}
        />
      </div>
    );
  };

  const { isLoading, disablePreventTransitionPrompt } = props;
  return (
    <ValidatedForm
      dirty={props.dirty}
      unsavedChangesPrompt={{
        title: "Exit Without Saving?",
        message: "Are you sure you want to exit without saving this PDF? All information entered will be lost.",
      }}
      disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      isLoading={isLoading}
      formWidthComputer={6}
      parentWithFormik={true}
    >
      {renderFields}
    </ValidatedForm>
  );
};

export default PdfForm;
