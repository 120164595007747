import moment from "moment";
import isEmpty from "lodash/isEmpty";
import dateTimeUtils from "./dateTimeUtils";

export const operators = {
  equal: "eq",
  notEqual: "ne",
  greaterThan: "gt",
  greaterThanOrEqual: "ge",
  lessThan: "lt",
  lessThanOrEqual: "le",
  or: "or",
  range: "range",
  isBetween: "isBetween",
  isNotBetween: "isNotBetween",
  isOnOrAfter: "isOnOrAfter",
  isOnOrBefore: "isOnOrBefore",
  isWithinLastDays: "isWithinLastDays",
  notIn: Symbol("notIn"),
  any: "any",
  ids: "ids",
};

type OperatorKeys = keyof typeof operators;
type OperatorValues = (typeof operators)[OperatorKeys];
type CollectionKey = { collectionName: string; propName: string };
export type RangeFilterValues = { from: string; to: string };
export type NumberRangeFilterValues = { start: string; finish: string };
export type FilterOption = {
  key: string;
  operator: OperatorValues;
  settings?: CollectionKey;
};
type FilterOptions = Array<FilterOption>;
export type FilterParamValue = string | number | RangeFilterValues;
export type FilterEntity = FilterParamValue | FilterParamValue[] | RangeFilterValues | FilterOperatorValues[] | {};
export type FiltersMap = { [key: string]: FilterEntity };
export type FilterOperatorValues = {
  values: FilterParamValue[];
  operator: OperatorKeys;
};

export enum FilterType {
  DateRange,
  NumberRange,
  Array,
  Value,
}
export type FilterTypesMap<T extends string | number> = { [key in T]: FilterType };
export type GenericFiltersMap<T extends string | number> = { [key in T]?: any };

function buildFilterQuery(this: { filterOptions: FilterOptions }, filters: FiltersMap) {
  let filterWithIds = false;
  let params = Object.create({});
  let filter = "";

  for (let key in filters) {
    let filterOption = this.filterOptions.find((f) => f.key === key);

    if (filterOption) {
      if (filterOption.operator === operators.ids) {
        filterWithIds = true;
        const queryParams = { [key]: filters[key] };
        params = { ...params, ...queryParams };
      } else {
        if (filter) {
          filter = `${filter} and `;
        }

        filter += new Operator(filterOption).buildFilter(filters[key]);
      }
    }
  }
  const complexFilter = Object.assign(params, isEmpty(filter) ? null : { filter });
  return filterWithIds ? complexFilter : filter;
}

function buildODataFilterQuery(this: { filterOptions: FilterOptions }, filters: FiltersMap) {
  let filterQuery: object[] = [];

  for (let key in filters) {
    let filterOption = this.filterOptions.find((f) => f.key === key);

    if (filterOption) {
      filterQuery.push(new Operator(filterOption).buildODataFilter(filters[key]));
    }
  }

  return { and: filterQuery };
}

interface IFilter {
  filterOptions: FilterOptions;
  buildFilterQuery: typeof buildFilterQuery;
  buildODataFilterQuery: typeof buildODataFilterQuery;
  buildFilterQueryMultOperators: typeof buildFilterQueryMultOperators;
}

// an old fashioned way class declaration using a function
export const Filter = function Filter(this: IFilter, filterOptions: FilterOptions) {
  this.filterOptions = filterOptions;
  this.buildFilterQuery = buildFilterQuery;
  this.buildODataFilterQuery = buildODataFilterQuery;
  this.buildFilterQueryMultOperators = buildFilterQueryMultOperators;
} as any as { new (filterOptions: FilterOptions): IFilter };

export function calculateAppliedFilters(appliedFilters: FiltersMap) {
  let newAppliedFiltersCount = 0;

  for (let filterName in appliedFilters) {
    let filter = appliedFilters[filterName];

    if (Array.isArray(filter)) {
      newAppliedFiltersCount += filter.length;
    } else {
      newAppliedFiltersCount += 1;
    }
  }

  return newAppliedFiltersCount;
}

export function formatFilterParams(appliedFilters: FiltersMap | null, filterParameters: FilterOptions | {}) {
  return getFilterQuery(appliedFilters, filterParameters, buildFilterQuery);
}

export function formatFilterParamsMultOperators(appliedFilters: FiltersMap | null) {
  return getFilterQueryMultOperators(appliedFilters, buildFilterQueryMultOperators);
}

export function formatODataFilterParams(appliedFilters: FiltersMap | null, filterParameters: FilterOptions | {}) {
  return getFilterQuery(appliedFilters, filterParameters, buildODataFilterQuery);
}

function getFilterQuery(
  appliedFilters: FiltersMap | null,
  filterParameters: FilterOptions | {},
  formatFunc: (filters: FiltersMap) => any,
) {
  if (isEmpty(appliedFilters)) {
    return null;
  }

  const filter = new Filter(filterParameters as FilterOptions);
  return formatFunc.call(filter, appliedFilters);
}

export function getFilterQueryMultOperators(
  appliedFilters: FiltersMap | null,
  formatFunc: (filters: FiltersMap) => any,
) {
  if (isEmpty(appliedFilters)) {
    return null;
  }

  return formatFunc(appliedFilters);
}

function buildFilterQueryMultOperators(filtersMap: FiltersMap) {
  let filterQuery = "";

  for (let key in filtersMap) {
    const filters = filtersMap[key] as Array<FilterOperatorValues>;

    for (let filterKey in filters) {
      const filterParams = filters[filterKey];
      const filterOption = { key, operator: filterParams.operator } as FilterOption;

      if (filterOption) {
        if (filterQuery) {
          filterQuery = `${filterQuery} and `;
        }

        filterQuery += new Operator(filterOption).buildFilter(filterParams.values);
      }
    }
  }

  return filterQuery;
}

class Operator {
  private readonly option: FilterOption;

  constructor(option: FilterOption) {
    this.option = option;
  }

  buildFilter(value: any) {
    switch (this.option.operator) {
      case operators.or:
        return this.buildOrFilter(this.option.key, value);
      case operators.range:
        return this.buildRangeFilter(value);
      case operators.isBetween:
        return this.buildIsBetweenFilter(value);
      case operators.isNotBetween:
        return this.buildIsNotBetweenFilter(value);
      case operators.notIn:
        return this.buildNotInFilter(value);
      case operators.any:
        return this.buildAnyFilter(value);
      case operators.isOnOrAfter:
        return this.buildIsOnOrAfterFilter(this.option.key, value);
      case operators.isOnOrBefore:
        return this.buildIsOnOrBeforeFilter(this.option.key, value);
      case operators.isWithinLastDays:
        return this.buildIsWithinLastDaysFilter(value);
      default:
        return `${this.option.key} ${this.option.operator as string} ${this.valueFormater(value)}`;
    }
  }

  buildODataFilter(value: any) {
    if (this.option.operator === operators.or) {
      return this.buildOrODataFilter(this.option.key, value);
    }

    return { [this.option.key]: value };
  }

  private buildRangeFilter(values: RangeFilterValues) {
    const name = this.option.key;
    let query = "";

    if (values.from) {
      query = `${name} ${operators.greaterThanOrEqual} ${moment.utc(values.from).toISOString(false)}`;
    }

    if (values.to) {
      if (query) {
        query = `${query} and `;
      }

      query = `${query}${name} ${operators.lessThanOrEqual} ${moment
        .utc(values.to, "MM/DD/YYYY")
        .add(1, "days")
        .toISOString(false)}`;
    }

    query = `(${query})`;

    return query;
  }

  private buildNotInRangeFilter(values: RangeFilterValues) {
    const name = this.option.key;
    let query = "";

    if (values.from) {
      query = `${name} ${operators.lessThan} ${moment.utc(values.from).toISOString(false)}`;
    }

    if (values.to) {
      if (query) {
        query = `${query} or `;
      }

      query = `${query}${name} ${operators.greaterThan} ${moment
        .utc(values.to, "MM/DD/YYYY")
        .add(1, "days")
        .toISOString(false)}`;
    }

    query = `(${query})`;

    return query;
  }

  private buildOrFilter(name: string, values: any[]) {
    let query = "";

    values.forEach((value) => {
      if (query) {
        query = `${query} or `;
      }

      query = `${query}${name} eq ${this.valueFormater(value)}`;
    });

    query = `(${query})`;

    return query;
  }

  private buildNotInFilter(values: string[]) {
    const name = this.option.key;
    const res = values.reduce((query, value) => {
      const queryConnector = query ? `${query} and ` : "";
      return `${queryConnector}${name} ne ${this.valueFormater(value)}`;
    }, "");
    return `(${res})`;
  }

  private buildAnyFilter(values: any[]) {
    if (!this.option.settings) {
      return "";
    }

    const { collectionName, propName } = this.option.settings;
    const objectName = "x";
    const condition = this.buildOrFilter(`${objectName}/${propName}`, values);

    return `${collectionName}/any(${objectName}: ${condition})`;
  }

  private valueFormater(value: any) {
    if (typeof value == "number" || value instanceof Date || typeof value == "boolean") {
      return value;
    }

    return `"${value}"`;
  }

  private buildOrODataFilter(name: string, values: any[]) {
    const query = values.map((value) => ({ [name]: value }));
    return { or: query };
  }

  private buildIsBetweenFilter(values: RangeFilterValues[]) {
    let query = "";

    values.forEach((value) => {
      if (query) {
        query = `${query} or `;
      }

      query = `${query}${this.buildRangeFilter(value)}`;
    });

    return query;
  }

  private buildIsWithinLastDaysFilter(values: NumberRangeFilterValues[]) {
    let filterValues: RangeFilterValues[] = [];

    values.forEach((item) => {
      const start = dateTimeUtils.toLocalFormatDateTime(dateTimeUtils.dateFromNow(-item.start), "MM/DD/YYYY");
      const finish = dateTimeUtils.toLocalFormatDateTime(dateTimeUtils.dateFromNow(-item.finish), "MM/DD/YYYY");

      filterValues.push({
        from: finish,
        to: start,
      });
    });

    return this.buildIsBetweenFilter(filterValues);
  }

  private buildIsNotBetweenFilter(values: RangeFilterValues[]) {
    let query = "";

    values.forEach((value) => {
      if (query) {
        query = `${query} or `;
      }

      query = `${query}${this.buildNotInRangeFilter(value)}`;
    });

    return query;
  }

  private buildIsOnOrAfterFilter(name: string, values: string[]) {
    let query = "";
    values.forEach((value) => {
      if (query) {
        query = `${query} or `;
      }
      query = query = `${query}(${name} ge ${moment.utc(value).toISOString(false)})`;
    });

    return query;
  }

  private buildIsOnOrBeforeFilter(name: string, values: string[]) {
    let query = "";

    values.forEach((value) => {
      if (query) {
        query = `${query} or `;
      }

      dateTimeUtils.toEndDateTime(value);
      query = query = `${query}(${name} le ${dateTimeUtils.toEndDateTime(value)})`;
    });

    return query;
  }
}

export const mapFilterOption = <T>(
  filterOptions: FiltersMap,
  data: T[],
  propertyName: string,
  dataItemMapping: (item: T) => FilterEntity,
) => {
  return {
    ...filterOptions,
    [propertyName]: data.map((item) => dataItemMapping(item)),
  };
};

export const mapRangeToString = (range: RangeFilterValues): string => {
  return `${dateTimeUtils.toServerFormatDate(new Date(range.from))}, ${dateTimeUtils.toServerFormatDate(
    new Date(range.to),
  )}`;
};

export const mapIntRangeToString = (range: NumberRangeFilterValues): string => {
  if(range.start === "0")
  {
    return `${range.finish}`;
  }

  return `${range.start}, ${range.finish}`;
};

export const mapStringToRange = (value: string): RangeFilterValues => {
  const from = dateTimeUtils.toLocalFormatDateTime(new Date(value.split(",")[0]), "MM/DD/YYYY");
  const to = dateTimeUtils.toLocalFormatDateTime(new Date(value.split(",")[1]), "MM/DD/YYYY");
  return { from, to };
};

export const mapStringToIntRange = (value: string): NumberRangeFilterValues => {
  if(value.split(",").length > 1)
  {
    const start = value.split(",")[0];
    const finish = value.split(",")[1];
    return { start, finish };
  }
  else 
  {
    const start = "0";
    const finish = value;
    return { start , finish };
  }
};
