import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../Application/globaltypes/redux";
import { Dimmer, Loader } from "semantic-ui-react";
import PeopleWrapper from "../../../PeopleAssignments/Overview/PeopleWrapper/PeopleWrapper";
import { RolePermissions } from "../../../../../enums";
import Sections from "../../../../../enums/sections";
import UsersOverview from "../../../PeopleAssignments/Overview/UsersOverview/UsersOverview";
import React from "react";
import GroupsOverview from "../../../PeopleAssignments/Overview/GroupsOverview/GroupsOverview";

export type PeopleProps = PropsFromRedux;

export const People = ({ areUsersLoading, usersToAssign, groupsToAssign }: PeopleProps) => {
  return (
    <>
      <Dimmer active={areUsersLoading} inverted>
        <Loader />
      </Dimmer>
      <PeopleWrapper
        permission={RolePermissions.UsersView}
        renderUsersOverview={(customHeader, isReadOnly) => (
          <UsersOverview
            section={Sections.messages}
            customHeaderContent={customHeader}
            isReadOnly={isReadOnly}
            assignedPeopleIds={usersToAssign}
          />
        )}
        renderGroupsOverview={(customHeader, isReadOnly) => (
          <GroupsOverview
            section={Sections.messages}
            customHeaderContent={customHeader}
            isReadOnly={isReadOnly}
            assignedPeopleIds={groupsToAssign}
          />
        )}
      />
    </>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  usersToAssign: state.library.messages.assignedUsers.userIds,
  groupsToAssign: state.library.messages.assignedGroups.groupIds,
  areUsersLoading: state.library.userAssignment.usersToBeAssigned.isLoading,
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(People);
