import { datadogRum } from "@datadog/browser-rum";

const SCRIPT_ID = "stripoScript";

export interface IStripoScript {
  load(): Promise<boolean>;
  readonly loaded: boolean;
}

// Zone will not patch Promise
// https://github.com/angular/zone.js/blob/master/MODULE.md
(window as any).__Zone_disable_ZoneAwarePromise = true;

class StripoScript implements IStripoScript {
  private _loaded = false;
  private promise: Promise<boolean> | null = null;

  public load(): Promise<boolean> {
    if (this._loaded) return this.promise!;

    const scriptElement = document.createElement("script");
    scriptElement.id = SCRIPT_ID;
    scriptElement.type = "text/javascript";
    scriptElement.src = "https://plugins.stripo.email/static/rev/1.81.0/stripo.js";
    scriptElement.integrity = "sha384-aE0/BSNmJbo74ncfeAOS7RGYAmy/NJOIXEbgVems9ftANekp6kZ3v4gHqUlco7+8";
    scriptElement.crossOrigin = "anonymous";

    this.promise = new Promise<boolean>((resolve) => {
      scriptElement.onload = () => {
        this._loaded = true;
        datadogRum.addAction("On stripoScript loaded");
        resolve(true);
      };
    });

    document.body.appendChild(scriptElement);

    return this.promise;
  }

  get loaded(): boolean {
    return this._loaded;
  }
}

export const script = new StripoScript();
