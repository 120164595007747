import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { FormikProps } from "formik";
import { withFormik } from "../../../../../utils/formikUtils";

import { ContentPropsOwn } from "../types";
import { RootState } from "../../../../Application/globaltypes/redux";
import { AssessmentContentView, ContentPayload } from "../../types/state";
import { questionsToContentPayload } from "../Utils/TypesConvertor";
import { AutosaveProps, withAutosave } from "../../../../../utils/withAutosave";

import * as assessmentEntityStateActions from "../../state/actions/assessmentEntityStateActions";
import * as assessmentDetailsActions from "../../state/actions/assessmentDetailsActions";

import AssessmentContentForm from "../AssessmentContentForm/AssessmentContentForm";
import validationSchemas from "../../../../../utils/validationSchemas";
import { assessmentsStateSelector } from "../../state/selectors";

export type ContentProps = ContentPropsOwn & PropsFromRedux & FormikProps<AssessmentContentView> & AutosaveProps;

export class Content extends Component<ContentProps> {
  componentDidMount() {
    this.props.subscribeOnDiscarded?.(this.props.resetForm);
    this.props.acceptHandlers?.({
      onNext: this.onNext,
      onPrevious: this.onPrevious,
      onClose: this.onClose,
    });
  }

  componentWillUnmount() {
    this.props.unSubscribeOnDiscarded?.(this.props.resetForm);
  }

  onNext = () => {
    this.saveContent();
  };

  onPrevious = () => {
    this.saveContent();
  };

  onClose = () => {
    this.props.detailsActions.clearAssessment();
  };

  saveContent = () => {
    this.props.detailsActions.saveContent(this.props.values);
  };

  render() {
    return <AssessmentContentForm {...this.props} />;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: ContentPropsOwn) => {
  const base = assessmentsStateSelector(state).base;

  return {
    id: ownProps.entityId || base.assessmentEntityStateReducer.entityId,
    content: base.assessmentDetailsReducer.content,
    isLoaded: base.assessmentDetailsReducer.isContentLoaded,
    isLoading: base.assessmentDetailsReducer.isContentLoading || base.assessmentDetailsReducer.isAssessmentLoading,
    isDraft: base.assessmentDetailsReducer.assessment.isDraft,
    isCreating: base.assessmentEntityStateReducer.changingEntityState,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(assessmentEntityStateActions, dispatch),
  detailsActions: bindActionCreators(assessmentDetailsActions, dispatch),
});
/* istanbul ignore next */

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<ContentProps, AssessmentContentView, AssessmentContentView, ContentPayload>({
  getInitValues: (props) => ({ questions: props.content.questions }),
  stateProvider: (values) => values,
  entityUpdater: (props) => props.entityStateActions.updateContent,
  isValid: (props) => props.isDraft && props.id > 0,
  stateProjector: (_, props) => questionsToContentPayload(props.id, props.values.questions),
})(Content);

/* istanbul ignore next */
const component = withFormik({
  validationSchema: validationSchemas.assessmentContent,
  enableReinitialize: true,
  validateOnMount: true,
  mapPropsToValues: (props: ContentPropsOwn & PropsFromRedux) => props.content,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);

export default connector(component);
