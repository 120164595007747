import React from "react";
import Restricted from "../../../features/Application/Restricted";
import MenuItem from "./MenuItem";

const RestrictedMenuItem = ({ permissions, ...otherProps }) => (
  <Restricted permissions={permissions}>
    <MenuItem {...otherProps} />
  </Restricted>
);

export default RestrictedMenuItem;
