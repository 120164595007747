import { FC } from "react";
import { Grid } from "semantic-ui-react";

import { ValidatedForm } from "../../../../../components";
import { PackRequestFormFieldProps } from "./types";

export const ContactNameField: FC<PackRequestFormFieldProps> = ({ values, ...props }) => {
  return (
    <Grid.Row>
      <Grid.Column>
        <ValidatedForm.InputField
          label="Contact Name"
          value={values.contactName}
          propertyName="contactName"
          markAsRequired
          errorPosition="top"
          placeholder="Enter Contact Name"
          {...props}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
