import { FC } from "react";
import { TitleSubtitle } from "../listViewTemplates";
import GroupPicture from "../groupPicture/GroupPicture";
import { RouteNames } from "enums";

import styles from "./groupLicenseInfoCell.module.scss";

interface GroupLicenseInfo {
  groupId: number;
  name: string;
  imageUrl?: string | null;
}
export interface GroupLicenseInfoCellProps {
  group: GroupLicenseInfo;
}

const GroupLicenseInfoCell: FC<GroupLicenseInfoCellProps> = ({ group }) => {
  return (
    <div className={styles["container"]}>
      <GroupPicture imageUrl={group.imageUrl} />
      <div className={styles["group-name"]}>
        <TitleSubtitle
          id={group.groupId}
          title={group.name}
          className={styles["group-name-container"]}
          url={`/${RouteNames.peopleGroups}/${group.groupId}`}
          classNameTitle={"normal-color clickable"}
        />
      </div>
    </div>
  );
};

export default GroupLicenseInfoCell;
