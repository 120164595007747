import * as editAccountActionTypes from "./editAccountActionTypes";
import { AccountType, AccountInfo, AddOn, SavingAddOn } from "../../types";
import dataService from "../../services/accountsDataService";
import packsDataService from "../../../Licensing/Packs/services/packsDataService";
import { getActionProvider, makeActionCreator } from "../../../Application/actions/actionsBuilder";
import StatusCode from "../../../../enums/httpStatusCodes";
import { FiltersMap } from "../../../../utils/filterUtils";
import { GetPacksRequest } from "../../../Licensing/Packs/types/requests";
import * as accountInformationActions from "../../AccountInformation/state/accountInformationActions";
import { AppDispatch } from "features/Application/globaltypes/redux";
import dateTimeUtils from "utils/dateTimeUtils";

export type EditAccountAction = (dispatch: AppDispatch) => Promise<void>;

export const fetchAccountInfo = (id: number, forAccountNavigation?: boolean): EditAccountAction => {
  type FetchAccountInfoTypes = editAccountActionTypes.FetchAccountInfoTypes;
  const fetchAccountInfoBegin = (): FetchAccountInfoTypes => ({
    type: editAccountActionTypes.FETCH_ACCOUNT_INFO_BEGIN,
  });

  const fetchAccountInfoSuccess = (info: AccountInfo): FetchAccountInfoTypes => ({
    type: editAccountActionTypes.FETCH_ACCOUNT_INFO_SUCCESS,
    payload: { info },
  });

  const fetchAccountInfoFailure = (error: any): FetchAccountInfoTypes => ({
    type: editAccountActionTypes.FETCH_ACCOUNT_INFO_FAILURE,
    payload: { error },
  });

  const fetchAccountInfoForbidden = (error: any): FetchAccountInfoTypes => ({
    type: editAccountActionTypes.FETCH_ACCOUNT_INFO_FORBIDDEN,
    payload: { error },
  });

  return async (dispatch: AppDispatch) => {
    dispatch(fetchAccountInfoBegin());
    try {
      const account: any = await dataService.getAccountInfo(id);
      const { days, hours, minutes } = dateTimeUtils.convertMinutesToDaysHoursMinutes(
        account?.sessionTimeoutInMinutes ?? 0,
      );
      const result = {
        ...account,
        sessionTimeoutDays: days,
        sessionTimeoutHours: hours,
        sessionTimeoutMinutes: minutes,
      };
      if (forAccountNavigation) {
        dispatch(accountInformationActions.updateData(result));
      } else {
        dispatch(fetchAccountInfoSuccess(result));
      }
    } catch (error) {
      if (forAccountNavigation) {
        dispatch(accountInformationActions.updateData({ name: "Account Settings", logo: "" }));
      } else {
        error.response.status === StatusCode.Forbidden
          ? dispatch(fetchAccountInfoForbidden(error))
          : dispatch(fetchAccountInfoFailure(error));
      }
    }
  };
};

export const fetchAccountTypes = (accountId: number): EditAccountAction => {
  type FetchAccountTypesActionTypes = editAccountActionTypes.FetchAccountTypesActionTypes;
  const fetchAccountTypesBegin = (): FetchAccountTypesActionTypes => ({
    type: editAccountActionTypes.FETCH_ACCOUNT_TYPES_BEGIN,
  });

  const fetchAccountTypesSuccess = (accountTypes: Array<AccountType>): FetchAccountTypesActionTypes => ({
    type: editAccountActionTypes.FETCH_ACCOUNT_TYPES_SUCCESS,
    payload: { accountTypes },
  });

  const fetchAccountTypesFailure = (error: any): FetchAccountTypesActionTypes => ({
    type: editAccountActionTypes.FETCH_ACCOUNT_TYPES_FAILURE,
    payload: { error },
  });

  return async (dispatch: AppDispatch) => {
    dispatch(fetchAccountTypesBegin());
    try {
      const result = await dataService.getReplacementAccountTypes(accountId);
      dispatch(fetchAccountTypesSuccess(result));
    } catch (error) {
      dispatch(fetchAccountTypesFailure(error));
    }
  };
};

export const fetchAddOnsBegin = makeActionCreator(editAccountActionTypes.FETCH_ACCOUNT_ADDONS_BEGIN);
export const fetchAddOnsFailure = makeActionCreator(editAccountActionTypes.FETCH_ACCOUNT_ADDONS_FAILURE, "error");
export const fetchAddOnsForbidden = makeActionCreator(editAccountActionTypes.FETCH_ACCOUNT_ADDONS_FORBIDDEN, "error");
export const fetchAddOnsSuccess = getActionProvider<Array<AddOn>>(editAccountActionTypes.FETCH_ACCOUNT_ADDONS_SUCCESS);
export const resetAddOns = makeActionCreator(editAccountActionTypes.RESET_ACCOUNT_ADDONS);
export const fetchAddOns = (accountId: number): EditAccountAction => {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchAddOnsBegin());
    try {
      const result = await dataService.getAddOns(accountId);
      dispatch(fetchAddOnsSuccess(result));
    } catch (error) {
      error.response.status === StatusCode.Forbidden
        ? dispatch(fetchAddOnsForbidden((error as Error).message))
        : dispatch(fetchAddOnsFailure((error as Error).message));
    }
  };
};

export const updateAccountInfo = makeActionCreator(editAccountActionTypes.UPDATE_ACCOUNT_INFO, "account");

export const updateAccountContacts = makeActionCreator(editAccountActionTypes.UPDATE_ACCOUNT_CONTACTS, "changes");

export const updateAccountAddOns = (enabledAddOns: Array<SavingAddOn>) => {
  return {
    type: editAccountActionTypes.UPDATE_ACCOUNT_ADDONS,
    payload: enabledAddOns,
  };
};

export const fetchAccountContactsBegin = makeActionCreator(editAccountActionTypes.FETCH_ACCOUNT_CONTACTS_BEGIN);
export const fetchAccountContactsFailure = makeActionCreator(
  editAccountActionTypes.FETCH_ACCOUNT_CONTACTS_FAILURE,
  "error",
);
export const fetchAccountContactsForbidden = makeActionCreator(
  editAccountActionTypes.FETCH_ACCOUNT_CONTACTS_FORBIDDEN,
  "error",
);
export const fetchAccountContactsSuccess = makeActionCreator(
  editAccountActionTypes.FETCH_ACCOUNT_CONTACTS_SUCCESS,
  "items",
  "itemsCount",
);
export const resetAccountContacts = makeActionCreator(editAccountActionTypes.RESET_ACCOUNT_CONTACTS);
export const fetchAccountContacts = (
  accountId: number,
  skip: number,
  top: number,
  orderByParams: string,
  filterParams: string,
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(fetchAccountContactsBegin());
    try {
      const response = await dataService.getAccountContacts(accountId, skip, top, orderByParams, filterParams);

      dispatch(fetchAccountContactsSuccess(response.items, response.count));
    } catch (error) {
      error?.response.status === StatusCode.Forbidden
        ? dispatch(fetchAccountContactsForbidden(error))
        : dispatch(fetchAccountContactsFailure(error));
    }
  };
};

export const resetAccountPacksForbidden = makeActionCreator(editAccountActionTypes.RESET_ACCOUNT_PACKS_FORBIDDEN);

export const resetAccountPacksList = makeActionCreator(editAccountActionTypes.fetchAccountPacks.RESET_ITEMS);

export function fetchAccountPacks(accountId: number, request: GetPacksRequest, filterParams: FiltersMap) {
  const { FETCH_ITEMS_BEGIN, FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE } = editAccountActionTypes.fetchAccountPacks;

  const fetchEntitiesBegin = makeActionCreator(FETCH_ITEMS_BEGIN);

  const fetchEntitiesSuccess = makeActionCreator(FETCH_ITEMS_SUCCESS, "items", "itemsCount");

  const fetchEntitiesFailure = makeActionCreator(FETCH_ITEMS_FAILURE, "error");

  const fetchEntitiesForbidden = makeActionCreator(editAccountActionTypes.FETCH_ACCOUNT_PACKS_FORBIDDEN, "error");

  return async (dispatch: AppDispatch) => {
    dispatch(fetchEntitiesBegin());
    try {
      const data = await packsDataService.getAccountPacks(accountId, request, filterParams);
      dispatch(fetchEntitiesSuccess(data.items, data.count));
    } catch (error) {
      error?.response.status === StatusCode.Forbidden
        ? dispatch(fetchEntitiesForbidden(error))
        : dispatch(fetchEntitiesFailure(error));
    }
  };
}

export const setAddContactsSearch = makeActionCreator(
  editAccountActionTypes.ACCOUNTS_EDIT_ACCOUNT_CONTACTS_SET_SEARCH,
  "search",
);

export const resetAddContactsSearch = makeActionCreator(
  editAccountActionTypes.ACCOUNTS_EDIT_ACCOUNT_CONTACTS_RESET_SEARCH,
);
