import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

interface DuplicateLinkButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  after?: React.ReactNode;
}

function DuplicateLinkButton(props: DuplicateLinkButtonProps) {
  const { onClick, isDisabled, after } = props;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <BasicLinkButton text="Duplicate" iconClassName="fa-copy" onClick={onClick} isDisabled={isDisabled} />
      {after}
    </div>
  );
}

export default DuplicateLinkButton;
