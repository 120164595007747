import React from "react";
import { Icon, Placeholder } from "semantic-ui-react";
import { defaultPublisher } from "../../../../features/Library/Flows/Designer/constants";
import {
  FlowItemAssessment,
  FlowItemEmail,
  FlowItemEvent,
  FlowItemMessage,
  FlowItemPdf,
  FlowItemSurvey,
  FlowItemVideo,
} from "../../../../features/Library/Flows/Designer/types";
import { Tooltip } from "../../../common/tooltip";
import { TextTruncate } from "../../../textTruncators/TextTruncators";
import FlowDesignerCardThumbnail from "../../flowDesignerCardThumbnail/FlowDesignerCardThumbnail";

import styles from "./topContent.module.scss";

type ITopContentOwnProps =
  | FlowItemSurvey
  | FlowItemAssessment
  | FlowItemVideo
  | FlowItemEmail
  | FlowItemMessage
  | FlowItemEvent
  | FlowItemPdf;

const TopContent: React.FC<ITopContentOwnProps> = ({ publisher, title, elementType, thumbnailUrl, hasAccess }) => {
  const getImage = () => {
    if (hasAccess === false) {
      return <div className={styles["no-access-block"]}>
        <Icon className="exclamation triangle red" size="large" />
      </div>;
    }
    return thumbnailUrl
      ? <FlowDesignerCardThumbnail thumbnailUrl={thumbnailUrl} />
      : <FlowDesignerCardThumbnail type={elementType} />;
  };

  return <div className={styles.root}>
    {getImage()}
    <div className={styles.description}>
      <div className={styles["description-label"]}>
        <Tooltip
          target={<TextTruncate>{publisher?.toUpperCase() || defaultPublisher}</TextTruncate>}
          content={publisher?.toUpperCase() || defaultPublisher}
        />
      </div>
      <div className={styles["description-title"]} data-testid="card-title">
        {title ? (
          <Tooltip target={<TextTruncate lines={2}>{title}</TextTruncate>} content={title} />
        ) : (
          <Placeholder>
            <Placeholder.Line />
          </Placeholder>
        )}
      </div>
    </div>
  </div>;
};

export default TopContent;
