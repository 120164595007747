export default class TaskStatuses {
  static inProgress = 1;
  static paused = 2;
  static successful = 3;
  static failed = 4;
  static queued = 5;
  static canceled = 6;

  static isFailed = (taskStatus: number | TaskStatuses) => taskStatus === TaskStatuses.failed;

  static isCanceled = (taskStatus: number | TaskStatuses) => taskStatus === TaskStatuses.canceled;

  static isInProgress = (taskStatus: number | TaskStatuses) => taskStatus === TaskStatuses.inProgress;

  static isCompleted = (taskStatus: number | TaskStatuses) =>
    taskStatus === TaskStatuses.successful || taskStatus === TaskStatuses.failed;
}
