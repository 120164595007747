import { Dispatch } from "@reduxjs/toolkit";

import DataService from "../../../../Application/services/dataServices/typedDataService";
import userListUtils from "../../../../../utils/userListUtils";

import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/pdfGroupsSlice";
import { GetContentUsersParams } from "../../../../People/types";
import { SurveyGroup } from "../../../Surveys/types/models";

export const fetchPdfGroups =
  ({ entityId, skip, top, sortingColumn, sortingDirection, filter, term }: GetContentUsersParams) =>
  async (dispatch: Dispatch) => {
    dispatch(fetchBegin());
    try {
      const { filterParams } = userListUtils.getFetchUsersParams(term, sortingColumn, sortingDirection, filter);

      const { items, count } = await DataService.pdfs.getPdfGroupsAsync({
        entityId,
        skip,
        top,
        filter: filterParams,
        term,
      });

      items.forEach((item: SurveyGroup) => (item.id = +item.groupId));
      dispatch(fetchSuccess({ items, totalCount: count }));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
