import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownProps, Icon } from "semantic-ui-react";
import * as d3 from "d3";

import { DEFAULT_COLOR_RANGE } from "../shared/constants";
import "./chartWrapper.scss";
import { getColorScale } from "../shared/helpers";
import { LegendLabel } from "./LegendLabel";
import { Tooltip } from "components/common/tooltip";

type BaseProps = {
  /**
   *  If more than one title provided, this will become a dropdown that calls an onChange callback with the selected label
   */
  titles: string[];
  onChange?: (option: string) => void;
  children?: React.ReactNode;
  tooltipFormatter?: (activeTitle: string) => ReactNode;
};

// This type enforces that if showLegend is a prop, then legendLabels is also required
export type Props =
  | ({
      showLegend: boolean;
      includeOrg?: boolean;
      // Color range could be using the default one
      colorRange?: string[];
      legendLabels: string[];
      legendTooltip?: (hoveredLabel: string) => ReactNode;
    } & BaseProps)
  | ({
      showLegend?: never;
      colorRange?: never;
      legendLabels?: never;
      includeOrg?: never;
      legendTooltip?: never;
    } & BaseProps);

export const ChartWrapper: React.FC<Props> = ({
  children,
  titles,
  onChange,
  tooltipFormatter,
  showLegend,
  colorRange = DEFAULT_COLOR_RANGE,
  legendLabels,
  legendTooltip,
  includeOrg,
}) => {
  // These are necessary in case the dropdown titles change,
  // then the first item in the next batch of titles will be chosen
  const [currentValue, setCurrentValue] = useState(titles[0]);
  useEffect(() => {
    // This should be changed to a more effective way of determining
    // if the titles have really changed
    if (!titles.includes(currentValue)) {
      setCurrentValue(titles[0]);
    }
  }, [currentValue, titles]);

  const colorScale = useMemo(() => {
    if (!legendLabels) return d3.scaleLinear();
    return getColorScale(colorRange, legendLabels.length);
  }, [colorRange, legendLabels]);

  const changeHandler = useCallback(
    (_: unknown, { value }: DropdownProps) => {
      setCurrentValue(value as string);
      onChange?.(value as string);
    },
    [onChange],
  );

  return (
    <div className="chart-wrapper-root">
      <div className="header-container">
        <Tooltip
          target={
            <div className="title-container">
              {titles.length > 1 ? (
                <Dropdown
                  className="title"
                  options={titles.map((option) => ({
                    text: option,
                    value: option,
                  }))}
                  value={currentValue}
                  onChange={changeHandler}
                  icon={<Icon className="left-padding" name="angle down" color="grey" />}
                />
              ) : (
                <span className="title">{titles[0]}</span>
              )}
            </div>
          }
          showAlways
          disabled={!tooltipFormatter}
          content={tooltipFormatter?.(currentValue)}
          position="top center"
        />
        {showLegend && legendLabels && legendLabels.length > 0 && (
          <div className="legend" data-testid="legend">
            {legendLabels.map((label, i) => (
              <div className="item" key={`${label} ${i}`} data-testid="legend item">
                <div
                  className="circle"
                  style={{
                    backgroundColor: `${colorScale(i)}`,
                  }}
                />
                <LegendLabel label={label} tooltipFormatter={legendTooltip} />
              </div>
            ))}
            {includeOrg && (
              <div className="item">
                <div className="dashed-line" />
                <LegendLabel label="Organization" tooltipFormatter={legendTooltip} />
              </div>
            )}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
