import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import cn from "classnames";
import { ImageFallbackPlaceholder } from "../imageFallbackPlaceholder/ImageFallbackPlaceholder";

import "./accountPicture.scss";

const accountPictureClassName = "account-picture";

const AccountPicture = (props) => {
  const { imageUrl, className, children } = props;

  const imageInfo = {
    className: cn(accountPictureClassName, className),
    alt: "account picture",
    src: imageUrl,
  };

  return (
    <ImageFallbackPlaceholder imageInfo={imageInfo}>
      {children ? children : <Icon name="building" className={cn(accountPictureClassName, className)} />}
    </ImageFallbackPlaceholder>
  );
};

AccountPicture.propTypes = {
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default AccountPicture;
