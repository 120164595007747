import { isEmpty } from "lodash";
import { Tags, TagsPayload } from "../../../../interfaces/Tags";

export const mapToTagsView = (payload: TagsPayload): Tags => {
  if (isEmpty(payload)) {
    return {};
  }

  return {
    labels: payload.Label?.map((item) => ({ text: item.title, value: item.title })),
    softwareApplications: payload.SoftwareApplication?.map((item) => ({ text: item.title, value: item.title })),
  };
};
