import { Slice } from "@reduxjs/toolkit";
import { AccountsFilterPropertyNames } from "../../../../../components/filterForms/AccountsFilterForm/AccountsFilterForm";
import { FiltersMap } from "../../../../../utils/filterUtils";
import accountsDataService from "../../../../Accounts/services/accountsDataService";
import { AccountType } from "../../../../Accounts/types";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { FiltersState } from "../../../../Application/slices/createFiltersSlice";

export const getFilterOptions = (slice: Slice<FiltersState<AccountsFilterPropertyNames>>) => {
  let filterOptions: FiltersMap = {};

  const setData = (result: AccountType[], propertyName: string) => {
    filterOptions = {
      ...filterOptions,
      [propertyName]: result.map((item: AccountType) => {
        return { text: item.name, value: item.name };
      }),
    };
  };

  const { setIsLoading, setError, setFilterOptions } = slice.actions;

  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const accountTypes = await accountsDataService.getAccountTypes();
      setData(accountTypes, "accountTypeName");
      dispatch(setFilterOptions(filterOptions));
    } catch (error: any) {
      dispatch(setError(error as Error));
    }

    dispatch(setIsLoading(false));
  };
};
