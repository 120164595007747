import { AppDispatch } from "../../../../Application/globaltypes/redux";
import flowsDataService, { BackendFlowsConfigV2 } from "../../services/flowsDataService";
import { fetchBegin, fetchSuccess, fetchFailure } from "../slices/flowGroupsToAddSlice";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const fetchFlowGroupsToAdd =
  (flowId: number[], config: BackendFlowsConfigV2) => async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const res = await flowsDataService.getFlowGroupsToAddV2(flowId, config);
      dispatch(fetchSuccess({ items: res.data, totalCount: Number(res.headers[countHeaderName]) }));
    } catch (e) {
      dispatch(fetchFailure(e as Error));
    }
  };
