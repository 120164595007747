import { sortBy } from "lodash";
import {
  AssessmentAnswerType,
  AssessmentPayload,
  AssessmentQuestionType,
  AssessmentView,
  ContentPayload,
  MultipleChoicePayloadQuestion,
  MultipleChoiceQuestion,
  PayloadQuestion,
  PayloadQuestions,
  Question,
  SingleChoicePayloadQuestion,
  SingleChoiceQuestion,
} from "../../types/state";
import { IQuestion } from "../../../Common/models";

export const contentPayloadToQuestions = (payload: ContentPayload): IQuestion<Question>[] => {
  let questions: IQuestion<Question>[] = [];

  payload.multipleChoiceTextQuestions.forEach((question) => {
    questions.push({
      questionType: AssessmentQuestionType.MultipleChoiceTextQuestion,
      questionOrder: question.questionOrder,
      question: {
        text: question.text,
        requireAllCorrectAnswers: question.requireAllCorrectAnswers,
      },
      answers: question.answers.map((answer) => ({
        answerType: AssessmentAnswerType.MultipleText,
        answer: answer,
      })),
    } as IQuestion<any>);
  });

  payload.singleChoiceTextQuestions.forEach((question) => {
    questions.push({
      questionType: AssessmentQuestionType.SingleChoiceTextQuestion,
      questionOrder: question.questionOrder,
      question: {
        text: question.text,
        correctAnswer: question.correctAnswer,
      },
      answers: question.answers.map((answer) => ({
        answerType: AssessmentAnswerType.SingleText,
        answer: {
          isCorrect: question.correctAnswer === question.questionOrder,
          text: answer,
        },
      })),
    } as IQuestion<any>);
  });

  return sortBy(questions, "questionOrder");
};

export const questionsToContentPayload = (entityId: number, questions: IQuestion<Question>[]): ContentPayload => {
  const contentPayload: ContentPayload = {
    id: entityId,
    multipleChoiceTextQuestions: [],
    singleChoiceTextQuestions: [],
  };

  questions.forEach((question, i) => {
    const payloadQuestions: PayloadQuestions = {
      [AssessmentQuestionType.MultipleChoiceTextQuestion]: contentPayload.multipleChoiceTextQuestions,
      [AssessmentQuestionType.SingleChoiceTextQuestion]: contentPayload.singleChoiceTextQuestions,
      [AssessmentQuestionType.PictureQuestion]: [],
    }[question.questionType as AssessmentQuestionType];

    const payloadQuestion: PayloadQuestion = {
      [AssessmentQuestionType.MultipleChoiceTextQuestion]: {
        text: question.question.text,
        questionOrder: i,
        requireAllCorrectAnswers: (question.question as MultipleChoiceQuestion).requireAllCorrectAnswers,
        answers: question.answers.map((answer) => ({
          isCorrect: answer.answer.isCorrect,
          text: answer.answer.text,
        })),
      } as MultipleChoicePayloadQuestion,

      [AssessmentQuestionType.SingleChoiceTextQuestion]: {
        text: question.question.text,
        questionOrder: i,
        correctAnswer: (question.question as SingleChoiceQuestion).correctAnswer,
        answers: question.answers.map((answer) => answer.answer.text),
      } as SingleChoicePayloadQuestion,

      [AssessmentQuestionType.PictureQuestion]: {} as any,
    }[question.questionType];

    payloadQuestions.push(payloadQuestion);
  });

  return contentPayload;
};

export const assessmentPayloadToAssessmentView = (payload: AssessmentPayload): AssessmentView => {
  return {
    ...payload,
    packs: payload.packs.map((pack) => ({ title: pack.name, id: pack.id })),
  } as AssessmentView;
};
