import { createSelector } from "@reduxjs/toolkit";
import { selectSharepointActivityFileCounts } from "../../graphSlice";
import moment from "moment";

export const selectFilesViewedLine = createSelector(selectSharepointActivityFileCounts, (state) => {
  let dates = Array<Date>(state.value.length);
  let files = Array<number>(state.value.length);

  state.value.forEach((v, i) => {
    dates[i] = moment(v.ReportDate).toDate();
    files[i] = v.ViewedOrEdited;
  });

  return {
    dates,
    files,
  };
});

export const selectFileSharingLine = createSelector(selectSharepointActivityFileCounts, (state) => {
  let dates = Array<Date>(state.value.length);
  let internal = Array<number>(state.value.length);
  let external = Array<number>(state.value.length);

  state.value.forEach((v, i) => {
    dates[i] = moment(v.ReportDate).toDate();
    internal[i] = v.SharedInternally;
    external[i] = v.SharedExternally;
  });

  return {
    dates,
    internal,
    external,
  };
});

export const selectFileSharingSums = createSelector(selectSharepointActivityFileCounts, (state) => {
  let internalSum = 0,
    externalSum = 0;
  state.value.forEach((v) => {
    internalSum += v.SharedInternally;
    externalSum += v.SharedExternally;
  });

  return {
    internalSum,
    externalSum,
  };
});

export const selectFileSyncedLine = createSelector(selectSharepointActivityFileCounts, (state) => {
  let dates = Array<Date>(state.value.length);
  let files = Array<number>(state.value.length);

  state.value.forEach((v, i) => {
    dates[i] = moment(v.ReportDate).toDate();
    files[i] = v.Synced;
  });

  return {
    dates,
    files,
  };
});
