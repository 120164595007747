export const stripoLayoutStyles = `
body {
    display: flex;
    flex-direction: column-reverse;
    min-height: 100%;
    justify-content: flex-end;
}
body > div:first-child {
    height: 100%;
}
body #cke-toolbar-container {
    position: static;
}
.esd-email-paddings.esd-email-paddings-increased {
    padding: 0 !important;
}`;
