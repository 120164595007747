import { useMemo } from "react";
import { AssignmentPeopleContext, RolePermissions } from "../enums";
import Rights, { hasGroupRight, hasRoleWithRight, hasUserRight } from "../enums/rights";

export const useContentAssignmentsPermissions = (
  itemsType: AssignmentPeopleContext,
  contentPermission: RolePermissions,
) => {
  return useMemo(
    () =>
      (userPermissions: RolePermissions[]): boolean => {
        const hasPeopleRight = itemsType === AssignmentPeopleContext.User ? hasUserRight : hasGroupRight;
        return (
          hasRoleWithRight(userPermissions, [contentPermission], Rights.Manage) &&
          hasPeopleRight(userPermissions, Rights.View)
        );
      },
    [itemsType, contentPermission],
  );
};
