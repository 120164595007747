import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import AssignedGroup from "../../../../../interfaces/assignedGroup";
import { createFetchingItemsSlice, FetchingItemsState } from "../../../../Application/slices/createFetchingItemsSlice";

export interface PdfGroupsToAddState extends FetchingItemsState<AssignedGroup> {}

export const initialState: PdfGroupsToAddState = {
  items: [],
  itemsCount: 0,
  isLoading: false,
  error: undefined,
};

const pdfGroupsToAddSlice = createFetchingItemsSlice({
  namePayload: { namespace: ReducerNamespaceTypes.Library, entity: ReducerEntityPrefixTypes.Pdfs, name: "groupsToAdd" },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = pdfGroupsToAddSlice.actions;

export default pdfGroupsToAddSlice.reducer;
