import * as actionTypes from "../actionTypes/assessmentsOverviewActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import { AssessmentsOverviewState, AssessmentOverview } from "../../types/state";
import { PayloadAction, FetchActionPayload } from "../../../../../interfaces/redux";

const initialState: AssessmentsOverviewState = {
  assessments: [],
  isLoading: false,
  totalCount: 0,
  error: undefined,
};

const assessmentsOverviewHandlers = () => {
  const { getAssessmentsBegin, getAssessmentsSuccess, getAssessmentsFailure } = actionTypes;

  const getAssessmentsBeginHandler = (state: AssessmentsOverviewState): AssessmentsOverviewState => ({
    ...state,
    assessments: new Array<AssessmentOverview>(),
    isLoading: true,
  });

  const getAssessmentsSuccessHandler = (
    state: AssessmentsOverviewState,
    action: PayloadAction<FetchActionPayload<AssessmentOverview>>,
  ): AssessmentsOverviewState => ({
    ...state,
    assessments: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const getAssessmentsFailureHandler = (
    state: AssessmentsOverviewState,
    action: PayloadAction<Error>,
  ): AssessmentsOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  return {
    [getAssessmentsBegin]: getAssessmentsBeginHandler,
    [getAssessmentsSuccess]: getAssessmentsSuccessHandler,
    [getAssessmentsFailure]: getAssessmentsFailureHandler,
  };
};

export const assessmentsOverviewReducer = createReducer(initialState, [assessmentsOverviewHandlers]);
