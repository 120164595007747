import { combineReducers } from "redux";
import { PayloadAction } from "@reduxjs/toolkit";
import { flatten } from "lodash";

import {
  withFetchingEntityListItems,
  withFetchingChildEntityListItems,
  IFetchingItemsState,
} from "../../../Application/reducers/hoc/withFetchingItems";
import withFilteringItems from "../../../Application/reducers/hoc/withFilteringItems";
import withFilterOptions from "../../../Application/reducers/hoc/withFilterOptions";
import withSearch from "../../../Application/reducers/hoc/withSearch";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import { AssignedUser, AssignedUserRoles, Role } from "../../../../interfaces";
import { RoleAssignedUsers } from "../../types";
import { USERS_ADDED_TO_ROLE } from "./rolesOverviewActionTypes";

const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.Roles;
const usersEntityPrefix = "POTENTIAL_USERS";

export type RolesOverviewListState = IFetchingItemsState<Role>;
export type RoleUsersAssignmentsState = IFetchingItemsState<RoleAssignedUsers>;
export type PotentialUsersState = IFetchingItemsState<AssignedUser>;
export type UsersToAddState = IFetchingItemsState<AssignedUserRoles>;

interface UserRolesAssignments {
  userId: number;
  assignedRoleIds: number[];
}

interface UserAddedToRolesPayload {
  usersWithRoles: UserRolesAssignments[];
}

const overviewListAssignmentHandlers = {
  [USERS_ADDED_TO_ROLE]: (state: RolesOverviewListState, action: PayloadAction<UserAddedToRolesPayload>) => {
    const updatedRoleIds = flatten(action.payload.usersWithRoles.map((item) => item.assignedRoleIds));

    return {
      ...state,
      items: state.items.map((role) => {
        const numberOfUsersAddedToRole = updatedRoleIds.filter((updatedRoleId) => updatedRoleId === role.id).length;

        if (numberOfUsersAddedToRole > 0) {
          return {
            ...role,
            usersCount: role.usersCount + numberOfUsersAddedToRole,
          };
        }

        return role;
      }),
    };
  },
};

const delimiter = "__";

const overviewList = withFetchingEntityListItems<RolesOverviewListState>(
  namespace,
  entityPrefix,
  overviewListAssignmentHandlers,
);

const rolesOverviewReducer = combineReducers({
  overviewList,
  filterState: withFilteringItems(namespace, entityPrefix),
  filterOptions: withFilterOptions(namespace, entityPrefix),
  potentialUsers: withFetchingChildEntityListItems<AssignedUser>(namespace, entityPrefix, usersEntityPrefix),
  roleUsersAssignments: withFetchingEntityListItems<RoleUsersAssignmentsState>(
    namespace,
    ReducerEntityPrefixTypes.RoleUsersAssignments,
  ),
  usersToAdd: withFetchingChildEntityListItems<AssignedUserRoles>(
    namespace,
    ReducerEntityPrefixTypes.RoleUsersToAdd,
    "",
  ),
  addUsersSearch: withSearch(namespace, entityPrefix.concat(delimiter, ReducerEntityPrefixTypes.RoleUsersToAdd)),
});

export type RolesOverviewState = ReturnType<typeof rolesOverviewReducer>;

export default rolesOverviewReducer;
