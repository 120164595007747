import InputField from "../../../../Common/Fields/InputField";

import { QuestionPropsBase } from "../../../../Common/Question/types";
import { WithFormikPayload } from "../../../../Common/Fields/WithFormikPayload";
import { MAX_QUESTION_FIELD_LENGTH } from "../../../../Common/constants";
import { Question } from "../../../types/state";

export type TextQuestionProps = QuestionPropsBase<Question>;

export const TextQuestion = (props: TextQuestionProps) => {
  const { index, question, onBlur, disabled, validatedFieldProps, propertyNamePrefix } = props;
  const { setFieldValue, setFieldTouched } = validatedFieldProps;
  const propertyName = `${propertyNamePrefix}question.text`;
  return (
    <div className="question-field">
      <WithFormikPayload
        propertyName={propertyName}
        {...validatedFieldProps}
        selfValidation={true}
        render={(formikProps) => (
          <InputField
            label={`Question ${index + 1}`}
            placeholder={"Question text"}
            value={question.text}
            maxLength={MAX_QUESTION_FIELD_LENGTH}
            errorPosition={"bottom"}
            onBlur={onBlur}
            disabled={disabled}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            {...formikProps}
          />
        )}
      />
    </div>
  );
};
