import { combineReducers } from "redux";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";

import { User } from "../../../../../interfaces";
import {
  IFetchingItemsState,
  withFetchingEntityListItems,
} from "../../../../Application/reducers/hoc/withFetchingItems";
import withSearch from "../../../../Application/reducers/hoc/withSearch";

const namespace = ReducerNamespaceTypes.Library;
const entityPrefix = ReducerEntityPrefixTypes.Events;

const eventsUsersAssignmentReducer = combineReducers({
  usersToBeAssigned: withFetchingEntityListItems<IFetchingItemsState<User>>(namespace, entityPrefix),
  search: withSearch(namespace, entityPrefix),
});

export default eventsUsersAssignmentReducer;
