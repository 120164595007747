import { FC } from "react";

interface SubjectPreviewProps {
  subject: string;
  previewText: string;
}

export const SubjectPreviewControl: FC<SubjectPreviewProps> = ({ subject, previewText }) => {
  return (
    <div className="subject-preview-area">
      {subject && (
        <p>
          <strong>Subject: </strong>
          {subject}
        </p>
      )}
      {previewText && (
        <p>
          <strong>Preview text: </strong>
          {previewText}
        </p>
      )}
    </div>
  );
};
