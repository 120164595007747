import { SourceType } from "../Designer/types/externallTriggersTypes";
import { IntegrationStatuses } from "../../../Accounts/Integrations/types";

export enum TagTypes {
  SoftwareApplication = "Software Applications",
  Label = "Labels",
}

export enum ReceiveUpdatesTypes {
  None,
  All,
  NotStarted,
}

export type Tags = {
  [key: string]: { type: TagTypes; title: string; isPurchased?: boolean }[];
};

export type TagsMap = {
  [key: string]: { title: string; isPurchased: boolean };
};

export interface Integration {
  id: number;
  type: SourceType | string;
  status: IntegrationStatuses;
}

export type FlowBaseEntity<T> = {
  [entity: string]: T & FlowBaseEntity<T>;
};

export type FlowBasePayload = Tags | Integration[];

export enum FlowBaseEntities {
  Tags = "tags",
  Integrations = "integrations",
}
