import dataService from "../../../../Application/services/dataServices/dataService";
import * as videosOverviewActionTypes from "../actionTypes/videosOverviewActionTypes";
import * as updateVideoActionTypes from "../actionTypes/updateVideoActionTypes";
import * as entityStateTypes from "../actionTypes/videoEntityStateActionTypes";
import fileUtils from "../../../../../utils/fileUtils";
import {
  getActionBaseProvider,
  getActionProvider,
  makeActionCreator,
} from "../../../../Application/actions/actionsBuilder";
import { beginAsyncOperation } from "../../../../Application/slices/asyncOperationSlice";
import { VideoAssetEditSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { TagsEnum } from "../../../../../interfaces/TagsEnum";

const updatePropertiesBegin = makeActionCreator(entityStateTypes.UPDATE_VIDEO_PROPERTIES_BEGIN);
const updatePropertiesFailed = makeActionCreator(entityStateTypes.UPDATE_VIDEO_PROPERTIES_FAILED, "error");

export const uploadAssetVideo = (file, onUploadProgressHandler, acceptCancellationHandler, destinationUri) => {
  const uploadAssetVideoBegin = () => ({
    type: updateVideoActionTypes.UPLOAD_VIDEO_BEGIN,
  });

  const uploadAssetVideoSuccess = (videoUrl) => ({
    payload: { videoUrl },
    type: updateVideoActionTypes.UPLOAD_VIDEO_SUCCESS,
  });

  const uploadAssetVideoFail = (error) => ({
    type: updateVideoActionTypes.UPLOAD_VIDEO_FAIL,
    payload: { error },
  });

  return async (dispatch) => {
    dispatch(uploadAssetVideoBegin());

    try {
      await fileUtils.uploadFileToBlob(destinationUri, file, onUploadProgressHandler, acceptCancellationHandler, null);
      dispatch(uploadAssetVideoSuccess(destinationUri));
    } catch (error) {
      if (error.code === "REQUEST_ABORTED_ERROR") {
        return;
      }
      dispatch(uploadAssetVideoFail(error));
    }
  };
};

export const uploadingAssetVideoStatus = (progress) => {
  const onProgress = () => ({
    type: updateVideoActionTypes.UPLOADING_VIDEO_PROGRESS,
    payload: { progress },
  });

  return (dispatch) => {
    dispatch(onProgress());
  };
};

export const cancelVideoAssetUpload = () => {
  return (dispatch) => {
    dispatch({
      type: updateVideoActionTypes.CANCEL_VIDEO_UPLOAD,
    });
  };
};

export const getVideoInfo = (assetId, onSuccess) => {
  const { GET_VIDEO_INFO_BEGIN, GET_VIDEO_INFO_SUCCESS, GET_VIDEO_INFO_FAIL } = updateVideoActionTypes;
  const { GET_VIDEO_LOCK_ENTITY_SUCCESS } = entityStateTypes;

  const getVideoInfoBegin = getActionBaseProvider(GET_VIDEO_INFO_BEGIN);
  const getVideoInfoSuccess = getActionProvider(GET_VIDEO_INFO_SUCCESS);
  const getVideoInfoFailed = getActionProvider(GET_VIDEO_INFO_FAIL);
  const setEntityId = makeActionCreator(GET_VIDEO_LOCK_ENTITY_SUCCESS, "entityId");

  return (dispatch) => {
    dispatch(getVideoInfoBegin());
    dispatch(setEntityId(parseInt(assetId)));

    dataService
      .getVideoInfo(assetId)
      .then((result) => {
        const data = result.data;
        dispatch(getVideoInfoSuccess(data));
        onSuccess && onSuccess(data);
      })
      .catch((error) => dispatch(getVideoInfoFailed(error)));
  };
};

export const generateThumbnailFromPosition = (positionInSeconds, entityId) => {
  const data = {
    positionInSeconds: positionInSeconds,
  };

  const { CREATE_THUMBNAIL_BEGIN, CREATE_THUMBNAIL_FAIL } = updateVideoActionTypes;

  const getProcessBegin = makeActionCreator(CREATE_THUMBNAIL_BEGIN);
  const getProcessFailed = makeActionCreator(CREATE_THUMBNAIL_FAIL, "error");

  return (dispatch) => {
    dispatch(getProcessBegin());
    dataService.generateThumbnail(entityId, data).catch((error) => dispatch(getProcessFailed(error)));
  };
};

export const setThumbnailFromPosition = (thumbnailUrl) => {
  const setThumbnailAction = (thumbnailUrl) => ({
    type: updateVideoActionTypes.SET_THUMBNAIL_FROM_POSITION,
    payload: { thumbnailUrl },
  });

  return (dispatch) => dispatch(setThumbnailAction(thumbnailUrl));
};

export const updateImageThumbnail = (sas, assetid) => {
  const imageData = {
    fileKey: sas.fileName,
  };
  const updateImageThumbnailBegin = makeActionCreator(updateVideoActionTypes.UPDATE_VIDEO_IMAGE_THUMBNAIL_BEGIN);
  const updateImageThumbnailSuccessed = makeActionCreator(
    updateVideoActionTypes.UPDATE_VIDEO_IMAGE_THUMBNAIL_SUCCESSED,
  );
  const updateImageThumbnailFailed = makeActionCreator(
    updateVideoActionTypes.UPDATE_VIDEO_IMAGE_THUMBNAIL_FAILED,
    "error",
  );

  return (dispatch) => {
    dispatch(updateImageThumbnailBegin());
    dataService
      .updateImageThumbnail(assetid, imageData)
      .then(() => dispatch(updateImageThumbnailSuccessed()))
      .catch((error) => dispatch(updateImageThumbnailFailed(error)));
  };
};

export const changeImageProcessingState = (isProcessing) => {
  const changeImageProcessingStateAction = (isProcessing) => ({
    type: updateVideoActionTypes.CHANGE_IMAGE_PROCESSING_STATE,
    payload: { isProcessing },
  });

  return (dispatch) => dispatch(changeImageProcessingStateAction(isProcessing));
};

export const updateProperties = (assetProperties) => {
  return async (dispatch) => {
    dispatch(updatePropertiesBegin());
    dispatch(beginAsyncOperation({ id: assetProperties.id, action: VideoAssetEditSuccess }));
    try {
      await dataService.updateProperties(assetProperties);
    } catch (error) {
      dispatch(updatePropertiesFailed(error));
    }
  };
};

export const updateVideoTags = (id, tags, type) => async (dispatch) => {
  const tagTypeMap = {
    labels: TagsEnum.Label,
    softwareApplications: TagsEnum.SoftwareApplication,
  };

  try {
    dispatch(updatePropertiesBegin());
    dispatch(beginAsyncOperation({ id: id, action: VideoAssetEditSuccess }));
    await dataService.saveVideoTagDetails(id, { tags, tagType: tagTypeMap[type] });
  } catch (error) {
    dispatch(updatePropertiesFailed(error));
  }
};

export const getStreamingInfo = (assetId) => {
  const { GET_STREAMING_INFO_BEGIN, GET_STREAMING_INFO_SUCCESSED, GET_STREAMING_INFO_FAILED } = updateVideoActionTypes;

  const getStreamingInfoBegin = makeActionCreator(GET_STREAMING_INFO_BEGIN);
  const getStreamingInfoSuccessed = makeActionCreator(GET_STREAMING_INFO_SUCCESSED, "streamingManifests");
  const getStreamingInfoFailed = makeActionCreator(GET_STREAMING_INFO_FAILED, "error");

  return (dispatch) => {
    dispatch(getStreamingInfoBegin());
    dataService
      .getStreamingInfo(assetId)
      .then((result) => dispatch(getStreamingInfoSuccessed(result.data)))
      .catch((error) => dispatch(getStreamingInfoFailed(error)));
  };
};

export const saveVideoInfo = (videoInfo) => {
  const saveVideoInfoAction = getActionProvider(updateVideoActionTypes.SAVE_VIDEO_INFO);

  return (dispatch) => {
    dispatch(saveVideoInfoAction(videoInfo));
  };
};

export const resetVideoInfo = () => {
  const resetVideoInfoAction = () => ({
    type: updateVideoActionTypes.RESET_VIDEO_INFO,
  });

  return (dispatch) => dispatch(resetVideoInfoAction());
};

export const updateVideo = (id, data) => {
  const { UPDATE_VIDEO_BEGIN, UPDATE_VIDEO_SUCCESS, UPDATE_VIDEO_FAIL } = updateVideoActionTypes;

  const updateVideoBegin = makeActionCreator(UPDATE_VIDEO_BEGIN);
  const updateVideoSuccess = makeActionCreator(UPDATE_VIDEO_SUCCESS);
  const updateVideoFail = makeActionCreator(UPDATE_VIDEO_FAIL, "error");

  return async (dispatch) => {
    dispatch(updateVideoBegin());
    try {
      await dataService.updateVideo(id, data);
      dispatch(updateVideoSuccess());
    } catch (error) {
      dispatch(updateVideoFail(error));
    }
  };
};

export const getVideoAssetRowNumber = (videoAssetId, top, skip, orderBy, filterParams) => {
  const { GET_VIDEO_ASSET_ROW_NUMBER_BEGIN, GET_VIDEO_ASSET_ROW_NUMBER_SUCCESS, GET_VIDEO_ASSET_ROW_NUMBER_FAILURE } =
    videosOverviewActionTypes;

  const begin = makeActionCreator(GET_VIDEO_ASSET_ROW_NUMBER_BEGIN);
  const success = makeActionCreator(GET_VIDEO_ASSET_ROW_NUMBER_SUCCESS, "videoAssetIndex");
  const failed = makeActionCreator(GET_VIDEO_ASSET_ROW_NUMBER_FAILURE, "error");

  return async (dispatch) => {
    dispatch(begin());
    try {
      const result = await dataService.getVideoIndex(videoAssetId, top, skip, orderBy, filterParams);
      const videoAssetIndex = result.data;
      dispatch(success(videoAssetIndex));
      return videoAssetIndex;
    } catch (error) {
      dispatch(failed(error));
    }
  };
};
