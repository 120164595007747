import { useState, ReactElement, useMemo } from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

import AccountPacksList from "./AccountPacksList/AccountPacksList";
import { AccountPack, AccountPackLicense } from "../../types";
import PackAssignmentModal from "../../../../components/assignmentModals/packAssignmentModal/PackAssignmentModal";
import ExtendLicensesModal from "../../../../components/assignmentModals/packAssignmentModal/extendLicensesModal/ExtendLicensesModal";
import { Filters } from "../../../../utils/queryUtils";
import {
  AccountPackOverview,
  AssignAccountPack,
} from "../../../../components/assignmentModals/packAssignmentModal/types";
import { FiltersMap } from "../../../../utils/filterUtils";
import { PackFilter } from "./types/models";
import { SearchState } from "../../../Application/slices/createSearchSlice";
import { RolePermissions, ViewType } from "../../../../enums";
import { GetPacksRequest, PacksRequest } from "../../../Licensing/Packs/types/requests";
import { AccountPacksGridFetchType } from "../../../Licensing/Packs/state/thunks/accountPacksThunk";
import Observable from "../../../../utils/Observable";
import { AccountPacksOverviewState } from "../../../Licensing/Packs/state/slices/accountPacksSlice";
import EditLicensingModal from "../../../../components/assignmentModals/packAssignmentModal/editLicensingModal/EditLicensingModal";
import { useLocation, useNavigate } from "react-router-dom";

import "./editPacks.scss";
import { AppDispatch } from "features/Application/globaltypes/redux";

export interface EditPacksProps {
  accountId: number;
  selectedAccountId: number;
  packsList: {
    items: Array<AccountPack>;
    isLoading: boolean;
    itemsCount: number;
  };
  packsGrid: AccountPacksOverviewState;
  renderAddPacksButton: () => ReactElement;
  onFetchPacks: (
    accountId: number,
    request: GetPacksRequest,
    filterParams: FiltersMap,
  ) => (dispatch: AppDispatch) => Promise<void>;
  onResetPacksList: () => void;
  onFetchPacksGrid: (requestData: PacksRequest) => AccountPacksGridFetchType;
  onResetPacksGrid: () => void;
  onCloseAddPacksModal: () => void;
  onConfirmAddPacksModal: (packs: AssignAccountPack[], selectedViewType: ViewType) => void;
  onConfirmEditLicensingModal: (accountPackLicense: AccountPackLicense, selectedViewType: ViewType) => void;
  onConfirmExtendLicensesModal: (packs: AssignAccountPack[], selectedViewType: ViewType) => void;
  onConfirmRestorePackModal: (packs: AssignAccountPack[], selectedViewType: ViewType) => void;
  showAddPacksModal: boolean;
  isAccountInfoLoading: boolean;
  isReadOnly: boolean;
  canAddPack: boolean;
  hasPurchasePower: boolean;
  addPacksModal: {
    items: Array<AccountPackOverview>;
    isLoading: boolean;
    itemsCount: number;
    areAllLoaded: boolean;
  };
  loadPacksForModal: (assignedAccountId: number, skip: number, orderBy: string, filters?: Filters) => void;
  loadPackForModal: (assignedAccountId: number, packIds: number[]) => void;
  resetModalGrid: () => void;
  modalFilter: PackFilter;
  accountPacksFilter: PackFilter;
  setPacksModalSearch: (searchTerm: string) => void;
  packsModalSearch: SearchState;
  isPacksModalFiltered: boolean;
  accountPacksSearch?: SearchState;
  setAccountPacksSearch: (term: string) => { payload: string; type: string };
  triggerRefreshPacksGrid: (viewType: ViewType) => void;
  isAccountHome: boolean;
  permissionsToAccount: RolePermissions[];
}

export default function EditPacks(props: EditPacksProps) {
  const [selectedViewType, setSelectedViewType] = useState(ViewType.LIST);
  const location = useLocation();
  const navigate = useNavigate();
  const search = location.search;
  const [assignPredefinedPackId, setAssignPredefinedPackId] = useState(new URLSearchParams(search).get("packId"));

  const onExtendLicensesClickObserver = useMemo(() => new Observable(), []);
  const onEditLicensingClickObserver = useMemo(() => new Observable(), []);
  const onRestorePackClickObserver = useMemo(() => new Observable(), []);

  const loadPacksList = (skip: number, top: number, order: string, appliedFilter: any, searchTerm?: string) => {
    const request: GetPacksRequest = {
      skip: skip,
      top: top,
      orderBy: order,
      searchTerm: searchTerm,
    };
    props.onFetchPacks(props.accountId, request, appliedFilter);
  };

  const loadPacksGrid = (skip: number, order: string, appliedFilter: any, searchTerm?: string) => {
    return props.onFetchPacksGrid({
      accountId: props.accountId,
      skip: skip,
      orderBy: order,
      filters: appliedFilter,
      searchTerm: searchTerm,
    });
  };

  const handleExtendLicensesClick = (id: number) => {
    onExtendLicensesClickObserver.notify(id, props.selectedAccountId);
  };

  const handleClosingPackAssigmentModal = () => {
    if (assignPredefinedPackId) {
      setAssignPredefinedPackId(null);
      navigate(location.pathname);
    }
  };

  const renderPacksAddModal = () => {
    const {
      onCloseAddPacksModal,
      onConfirmAddPacksModal,
      accountId,
      selectedAccountId,
      addPacksModal,
      packsModalSearch,
      isPacksModalFiltered,
      loadPacksForModal,
      loadPackForModal,
      setPacksModalSearch,
      resetModalGrid: resetGrid,
    } = props;
    const { applyFilter, resetFilter, fetchFilterOptions, appliedFilter, filterOptions } = props.modalFilter;

    return (
      <PackAssignmentModal
        onConfirm={(packs: AssignAccountPack[], viewType: ViewType) => {
          onConfirmAddPacksModal(packs, viewType);
          handleClosingPackAssigmentModal();
        }}
        onCancel={() => {
          onCloseAddPacksModal();
          handleClosingPackAssigmentModal();
        }}
        showModal={true}
        selectAccountId={selectedAccountId}
        accountId={accountId}
        loadPacksForModal={loadPacksForModal}
        loadPackForModal={loadPackForModal}
        appliedFilter={appliedFilter}
        applyFilter={applyFilter}
        filterOptions={filterOptions}
        isLoading={addPacksModal.isLoading}
        areAllLoaded={addPacksModal.areAllLoaded}
        items={addPacksModal.items}
        itemsCount={addPacksModal.itemsCount}
        resetFilter={resetFilter}
        fetchFilterOptions={fetchFilterOptions}
        resetGrid={resetGrid}
        packsModalSearch={packsModalSearch}
        setPacksModalSearch={setPacksModalSearch}
        selectedViewType={selectedViewType}
        isPacksModalFiltered={isPacksModalFiltered}
        predefinedPackIds={assignPredefinedPackId ? [Number(assignPredefinedPackId)] : []}
      />
    );
  };

  const renderExtendLicensesModal = () => {
    const { onConfirmExtendLicensesModal } = props;
    return (
      <ExtendLicensesModal
        onConfirm={onConfirmExtendLicensesModal}
        selectedViewType={selectedViewType}
        showModalObserver={onExtendLicensesClickObserver}
        header="Edit Licensing"
      />
    );
  };

  const handleRestorePackClick = (id: number) => {
    onRestorePackClickObserver.notify(id, props.selectedAccountId);
  };

  const renderRestorePackModal = () => {
    const { onConfirmRestorePackModal } = props;
    return (
      <ExtendLicensesModal
        onConfirm={onConfirmRestorePackModal}
        selectedViewType={selectedViewType}
        showModalObserver={onRestorePackClickObserver}
        header="Unarchive & Restore Licenses"
        isRestore={true}
      />
    );
  };

  const handleEditLicensingClick = (packId: number) => {
    onEditLicensingClickObserver.notify(packId, props.selectedAccountId);
  };

  const renderEditLicensingModal = () => {
    const { onConfirmEditLicensingModal } = props;
    return (
      <EditLicensingModal
        onConfirm={onConfirmEditLicensingModal}
        selectedViewType={selectedViewType}
        showModalObserver={onEditLicensingClickObserver}
      />
    );
  };

  const renderAccountPacks = () => {
    const {
      isReadOnly,
      renderAddPacksButton,
      onResetPacksList,
      onResetPacksGrid,
      packsList,
      packsGrid,
      canAddPack,
      showAddPacksModal,
      accountPacksFilter,
      setAccountPacksSearch,
      accountPacksSearch,
      permissionsToAccount,
      hasPurchasePower,
    } = props;

    return (
      <>
        <AccountPacksList
          packsList={packsList}
          packsGrid={packsGrid}
          loadListItems={loadPacksList}
          resetListItems={onResetPacksList}
          loadGridItems={loadPacksGrid}
          resetGridItems={onResetPacksGrid}
          renderAddPacksButton={renderAddPacksButton}
          isReadOnly={isReadOnly}
          handleEditLicensingClick={handleEditLicensingClick}
          handleExtendLicensesClick={handleExtendLicensesClick}
          handleRestorePackClick={handleRestorePackClick}
          canAddPack={canAddPack}
          filter={accountPacksFilter}
          selectedViewType={selectedViewType}
          setSelectedViewType={setSelectedViewType}
          searchState={accountPacksSearch}
          setSearch={setAccountPacksSearch}
          triggerRefreshPacksGrid={props.triggerRefreshPacksGrid}
          isAccountHome={props.isAccountHome}
          selectedAccountId={props.selectedAccountId}
          permissionsToAccount={permissionsToAccount}
          hasPurchasePower={hasPurchasePower}
        />
        {showAddPacksModal && renderPacksAddModal()}
        {assignPredefinedPackId && renderPacksAddModal()}
        {renderExtendLicensesModal()}
        {renderEditLicensingModal()}
        {renderRestorePackModal()}
      </>
    );
  };

  return props.isAccountInfoLoading ? (
    <Dimmer.Dimmable className="edit-packs-dimmer" as={Segment} dimmed={props.isAccountInfoLoading}>
      <Dimmer active={props.isAccountInfoLoading} inverted>
        <Loader>Loading</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  ) : (
    renderAccountPacks()
  );
}
