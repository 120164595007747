import packDataService from "../../services/packsDataService";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/packUserLicensesSlice";
import { GetPackPeopleLicensesRequest } from "../../types/requests";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const fetchPackUserLicenses =
  (packId: number, request: GetPackPeopleLicensesRequest) => async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const res = await packDataService.getPackUserLicensesAsync(packId, request);
      dispatch(
        fetchSuccess({
          items: res.data,
          totalCount: Number.parseInt(res.headers[countHeaderName]),
        }),
      );
    } catch (e: any) {
      dispatch(fetchFailure(e));
    }
  };
