import DragNDropItemTypes from "../../../../../../../enums/dragNDropItemTypes";

export const setDragNDropType = <T extends { dragNDropItemType: DragNDropItemTypes }>(
  items: any[],
  type: DragNDropItemTypes,
): T[] => {
  return items.map((item) => ({ ...item, dragNDropItemType: type }));
};

export default setDragNDropType;
