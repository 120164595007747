import { FC, ReactNode } from "react";
import cn from "classnames";

import "./performanceTabSection.scss";

export type PerformanceTabSectionProps = {
  title: ReactNode;
  controls: ReactNode;
  content: ReactNode;
  className?: string;
  hideControls?: boolean;
};

export const PerformanceTabSection: FC<PerformanceTabSectionProps> = ({
  title,
  controls,
  content,
  className,
  hideControls,
}) => {
  return (
    <>
      <div className="email-performance-tab-header">
        <div className="performance-tab-header-title">{title}</div>
        <div className={cn("performance-tab-controls", { "performance-tab-controls-hidden": hideControls })}>
          {controls}
        </div>
      </div>
      <div className={`performance-tab-section-content ${className}`}>{content}</div>
    </>
  );
};
