import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../Application/globaltypes/redux";
import { Link } from "../../../../../../components/ExpandableItems/types";
import { sortBy } from "lodash";
import { ReceiveUpdatesTypes, TagsMap } from "../../../types/flowBase";

export interface SettingsPayload {
  id: number;
  labels: string[];
  softwareApplications: string[];
  packs: Link[];
  updateType: ReceiveUpdatesTypes;
}

const flowInfoSelector = (state: RootState) => state.library.flows.base.information.info;

const mapTags = (tags: TagsMap) => {
  return sortBy(Object.values(tags), (item) => !item.isPurchased).map((item) => item.title);
};

export const createSettingsInfoSelector = createSelector([flowInfoSelector], (flowInfo) => ({
  id: flowInfo.id,
  labels: mapTags(flowInfo.labels),
  softwareApplications: mapTags(flowInfo.softwareApplications),
  packs: flowInfo.packs,
  updateType: flowInfo.updateType,
}));

export const getReceiveUpdatesModalData = () => {
  const usersWhoHaveNotStartedReceiveUpdatesTooltip = (
    <span>Only users who have not yet started this flow including users added in the future.</span>
  );

  const allUsersReceiveUpdatesTooltip = (
    <span>
      All users who are currently added to this flow, who are added in the future, and have started or not will receive
      flow updates automatically. Any users in progress will have their progress reset.
    </span>
  );

  return {
    usersWhoHaveNotStartedReceiveUpdatesTooltip,
    allUsersReceiveUpdatesTooltip 
  };
};
