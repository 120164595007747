import React from "react";
import NoResults from "../../../components/noResults/NoResults";

export interface PackVisibilityForAccountTypesNoResultsProps {
  addAccountTypesButton?: React.ReactElement | null;
  isPublicToAll?: boolean;
}

const PackVisibilityForAccountTypesNoResults: React.FC<PackVisibilityForAccountTypesNoResultsProps> = ({
  addAccountTypesButton,
  isPublicToAll,
}) => {
  return (
    <NoResults
      title={isPublicToAll ? "Not Customized: Public to All" : "No Account Types Selected"}
      description={
        <span>
          Visibility not yet customized for specific account types.
          <br />
          {addAccountTypesButton ? " You can customize it by clicking the button below." : ""}
        </span>
      }
      iconClassName="building"
    >
      {addAccountTypesButton}
    </NoResults>
  );
};

export default PackVisibilityForAccountTypesNoResults;
