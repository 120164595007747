import PropTypes from "prop-types";
import cn from "classnames";

import FilterDatePicker from "./FilterDatePicker";

import "./filterDateRange.scss";

function FilterDateRange(props) {
  const {
    label,
    propertyName,
    filter,
    updateFilter,
    className,
    disabled,
    verticalPosition,
    horizontalPositionFirstPicker,
    horizontalPositionSecondPicker,
  } = props;

  return (
    <div className={cn("filter-date-range", className)}>
      {label && <div className="label">{label}</div>}
      <div className="bottom-row">
        <FilterDatePicker
          placeholder="From"
          propertyName={propertyName}
          filter={filter}
          updateFilter={updateFilter}
          disabled={disabled}
          verticalPosition={verticalPosition}
          horizontalPosition={horizontalPositionFirstPicker}
          maxDate={filter[propertyName]?.to}
        />
        <span className="delimiter">-</span>
        <FilterDatePicker
          placeholder="To"
          propertyName={propertyName}
          filter={filter}
          updateFilter={updateFilter}
          disabled={disabled}
          verticalPosition={verticalPosition}
          horizontalPosition={horizontalPositionSecondPicker}
          popupPosition="bottom right"
          minDate={filter[propertyName]?.from}
        />
      </div>
    </div>
  );
}

FilterDateRange.propTypes = {
  propertyName: PropTypes.string.isRequired,
  label: PropTypes.string,
  filter: PropTypes.object,
  updateFilter: PropTypes.func,
  verticalPosition: PropTypes.string,
  horizontalPositionFirstPicker: PropTypes.string,
  horizontalPositionSecondPicker: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FilterDateRange;
