import React, { ReactElement } from "react";

import { FlowDesignerEventCard, StaticCommunicationCard } from "../../../../../../../../components";
import { RolePermissions } from "../../../../../../../../enums";
import { assetsOptions } from "./assetsOptions";
import { communicationOptions } from "./communicationOptions";

import StaticAssetCard from "../../../dragndropCards/StaticAssetCard";
import DraggableAssetCard from "../../../dragndropCards/DraggableAssetCard";
import DraggableEventsCard from "../../../dragndropCards/DraggableEventsCard";
import DraggableCommunicationCard from "../../../dragndropCards/DraggableCommunicationCard";
import { ExtrasCard, ExtrasDraggableCard } from "../../Extras";
import { DesignerContextSwitcherOption } from "./models";
import { CardsViewerItem } from "../../../../../../../../components/cardsViewer/types";

interface TabsOptions {
  label: string;
  id: string;
  permissions: RolePermissions[];
  filterOptions?: DesignerContextSwitcherOption[];
  renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) => ReactElement;
}

export const getContentViewTabsOptions = (): TabsOptions[] => [
  {
    label: "Assets",
    id: "flow-designer-assets-filter",
    permissions: [RolePermissions.AssetsView],
    filterOptions: assetsOptions,
    renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) =>
      isReadOnly ? <StaticAssetCard {...props.item} /> : <DraggableAssetCard {...props.item} />,
  },
  {
    label: "Events",
    id: "flow-designer-events-filter",
    permissions: [RolePermissions.EventsView],
    renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) => {
      return isReadOnly ? <FlowDesignerEventCard {...props.item} /> : <DraggableEventsCard {...props.item} />;
    },
  },
  {
    label: "Communications",
    id: "flow-designer-emails-filter",
    permissions: [RolePermissions.CommunicationsView],
    renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) =>
      isReadOnly ? <StaticCommunicationCard {...props.item} /> : <DraggableCommunicationCard {...props.item} />,
    filterOptions: communicationOptions(),
  },
  {
    label: "Extras",
    id: "flow-designer-extras-filter",
    permissions: [RolePermissions.FlowsView],
    renderCard: (isReadOnly: boolean) => (props: CardsViewerItem<any>) =>
      isReadOnly ? <ExtrasCard {...props.item} /> : <ExtrasDraggableCard {...props.item} />,
  },
];
