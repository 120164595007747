import React, { FC, useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { Button } from "../button/Button";
import { PopupProps } from "semantic-ui-react/dist/commonjs/modules/Popup/Popup";

import { BasicLinkButton } from "../linkButtons";
import { DropdownMenu } from "./DropdownMenu";

import "./ellipsisPopup.scss";

export interface EllipsisPopupProps extends PopupProps {
  disabled: boolean;
  options: EllipsisPopupOption[];
  testId?: string;
}

export interface EllipsisPopupOption {
  label: string;
  options?: EllipsisPopupOption[];
  onClick?: () => void;
  disabledItem?: boolean;
  isHideItem?: boolean;
  disabled?: boolean;
  iconName?: string;
}

export const EllipsisPopup: FC<EllipsisPopupProps> = (props) => {
  const { on = "click", position = "bottom right", disabled, testId } = props;
  const [open, setOpen] = useState<boolean>(false);

  const handleItemClick = (option: EllipsisPopupOption) => () => {
    setOpen(false);
    option.onClick?.();
  };

  const renderButton = (option: EllipsisPopupOption) => (
    <BasicLinkButton
      key={option.label}
      iconClassName={option.iconName}
      text={option.label}
      onClick={handleItemClick(option)}
    />
  );

  const renderDropdown = (option: EllipsisPopupOption) => (
    <DropdownMenu
      key={option.label}
      option={option}
      handleItemClick={handleItemClick}
      renderDropdown={renderDropdown}
    />
  );

  const renderPopupContent = (options: EllipsisPopupOption[]) =>
    options.map((option) => !option.isHideItem && (option.options ? renderDropdown(option) : renderButton(option)));

  return (
    <Popup
      on={on}
      open={open}
      position={position}
      hideOnScroll
      className="ellipsis-popup"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button
          className="ellipsis-popup-button circle hover-color-blue"
          disabled={disabled}
          key="show-more-button"
          data-testid={testId}
        >
          <Icon className="fa-ellipsis-h" />
        </Button>
      }
    >
      <div className="ellipsis-popup-content">{renderPopupContent(props.options)}</div>
    </Popup>
  );
};
