import { useState, useMemo, ComponentType, useEffect } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { ContentView, ContentTab } from "../../../../../components";
import { isPackReadOnlySelector } from "../../../../Application/selectors";
import {
  resetAppliedFilter,
  resetFilter,
  setSearch as setSearchAction,
} from "../../state/slices/packsContentFiltersSlice";
import PackContentFilterSidebarContainer from "./PackContentFilterSidebarContainer";
import "./packDrops.scss";
import { EntityType } from "../../../../Library/Flows/Designer/types";
import VideosFilterFormForPacks from "../../../../../components/filterForms/VideosFilterForm/VideosFilterFormForPacks";
import SurveyFilterFormForPacks from "../../../../../components/filterForms/SurveyFilterForm/SurveyFilterFormForPacks";
import AssessmentFilterFormForPacks from "../../../../../components/filterForms/AssessmentFilterForm/AssessmentFilterFormForPacks";
import { EventFilterFormForPacks } from "../../../../../components/filterForms/EventFilterForm/EventFilterFormForPacks";
import PanelComponent from "../../../../Library/Flows/Designer/FlowComponentsPanel/contentView/PanelComponent/PanelComponent";
import {
  activeViewTabSelector,
  itemsToDropFiltersSelector,
  itemsToDropSelector,
  packContentTypeSelectSelector,
} from "../../state/selectors";
import { setActiveTab, setEntityType } from "../../state/slices/packContentTabContextSlice";
import { fetchItemsToDropV2 } from "../../state/thunks/packContentTabItemsToDropThunk";
import { reset as resetItemsAction } from "../../state/slices/packContentTabItemsToDropSlice";
import { getContentViewTabsOptions } from "./contentViewOptions";
import DragNDropItemTypes from "../../../../../enums/dragNDropItemTypes";
import { FlowFilterFormForPacks } from "../../../../../components/filterForms/FlowFilterForm/FlowFilterFormForPacks";
import { bindActionCreators } from "@reduxjs/toolkit";
import { EmailFilterFormForPacks } from "../../../../../components/filterForms/EmailFilterForm/EmailFilterFormForPacks";
import { clearSelectedCards } from "../../state/actions/packDetailsActions";
import PdfsFilterFormForPacks from "../../../../../components/filterForms/PdfsFilterForm/PdfsFilterFormForPacks";
import { MessageFilterFormForPacks } from "../../../../../components/filterForms/MessageFilterForm/MessageFilterFormForPacks";

export function PackDrops({ changeViewTab, clearSelectedItems, dispose, activeViewTab, ...props }: PropsFromRedux) {
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    return () => {
      clearSelectedItems();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterFormsMap: { [key in EntityType]?: ComponentType<any> } = {
    Video: VideosFilterFormForPacks,
    Survey: SurveyFilterFormForPacks,
    Assessment: AssessmentFilterFormForPacks,
    Email: EmailFilterFormForPacks,
    Message: MessageFilterFormForPacks,
    ExternalEvent: EventFilterFormForPacks,
    Flow: FlowFilterFormForPacks,
    Pdf: PdfsFilterFormForPacks,
  };

  const contentViewTabs: ContentTab[] = useMemo(() => {
    const {
      isReadOnly,
      packContentFilter,
      loadItemsV2,
      resetAppliedFilters,
      itemsToDrop,
      search,
      entityTypeSelect,
      changeEntityType,
      setSearch,
      resetItems,
    } = props;

    return getContentViewTabsOptions().map((tab) => ({
      label: tab.label,
      id: tab.tabId,
      component: (
        <PanelComponent
          id={tab.filtersId}
          className="pack-components-section"
          key={tab.filtersId}
          permissions={tab.permissions}
          onFilterButtonClick={() => setShowFilter(true)}
          appliedFilters={packContentFilter.appliedFilter}
          resetAppliedFilter={resetAppliedFilters}
          renderCard={tab.renderCard(isReadOnly)}
          filterOptions={tab.filterOptions}
          loadItems={loadItemsV2}
          itemsToDrop={itemsToDrop}
          search={search}
          entityTypeSelect={entityTypeSelect}
          changeEntityType={(type) => {
            setShowFilter(false);
            clearSelectedItems();
            changeEntityType(type);
          }}
          setSearch={setSearch}
          resetItems={resetItems}
          dispose={dispose}
          dragNDropItemType={DragNDropItemTypes.PACK}
          showPurchased={false}
        />
      ),
    }));
  }, [props, dispose, clearSelectedItems]);

  return (
    <div className={"pack-drops"}>
      <ContentView
        tabs={contentViewTabs}
        activeIndex={activeViewTab}
        onTabChange={(_, r: { activeIndex: number }) => {
          changeViewTab(r.activeIndex);
          dispose();
          clearSelectedItems();
        }}
      />

      <PackContentFilterSidebarContainer
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        filterFormsMap={filterFormsMap}
      />
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  isReadOnly: isPackReadOnlySelector(state),
  packContentFilter: state.packs.packContentTab.filters,
  activeViewTab: activeViewTabSelector(state),
  itemsToDrop: itemsToDropSelector(state),
  search: itemsToDropFiltersSelector(state).search,
  entityTypeSelect: packContentTypeSelectSelector(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    changeViewTab: bindActionCreators(setActiveTab, dispatch),
    resetAppliedFilters: bindActionCreators(resetAppliedFilter, dispatch),
    loadItemsV2: bindActionCreators(fetchItemsToDropV2, dispatch),
    changeEntityType: bindActionCreators(setEntityType, dispatch),
    setSearch: bindActionCreators(setSearchAction, dispatch),
    resetItems: bindActionCreators(resetItemsAction, dispatch),
    clearSelectedItems: bindActionCreators(clearSelectedCards, dispatch),
    dispose: () =>
      batch(() => {
        dispatch(resetFilter());
        dispatch(resetItemsAction());
      }),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PackDrops);
