import React, { useMemo } from "react";
import { DropdownItemProps, DropdownProps } from "semantic-ui-react";
import { Tooltip } from "../../common/tooltip";
import { TextTruncate } from "../../textTruncators/TextTruncators";
import { DropdownWrapper as Dropdown } from "../dropdownWrapper/DropdownWrapper";
import cn from "classnames";

import styles from "./dropdownTrigger.module.scss";

export interface DropdownTriggerProps extends DropdownProps {
  items: DropdownItemProps[];
  direction?: "left" | "right";
}

export const DropdownTrigger = (props: DropdownTriggerProps) => {
  const { items, placeholder, selected } = props;

  const selectedValue = useMemo(() => {
    return items.find((item) => item.value === selected);
  }, [items, selected]);

  return (
    <Dropdown
      {...props}
      className={cn("selection", styles.root, props.className)}
      trigger={
        <Tooltip
          target={
            <TextTruncate className={cn({ [styles["default-text"]]: !selectedValue?.text })}>
              {selectedValue?.text || placeholder}
            </TextTruncate>
          }
          content={selectedValue?.text || placeholder}
        />
      }
    />
  );
};
