import { bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Table } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

import { ThreatDefenceFeedbackPageOverview } from "../types/state";
import columnOptions from "./columnOptions";
import { PublishedStatusTypes } from "../../../../../enums";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { PublishedStatus } from "../../../../../components/common/publishedStatus";
import * as feedbackPageEntityStateActions from "../state/actions/FeedbackPageEntityStateActions";
import FeedbackPageEllipsisPopupButton from "../../../../../components/feedbackPages/FeedbackPageEllipsisPopupButton";
import { Title } from "../../../../../components/listViewTemplates";
import { useRtn } from "../../../../../hooks/useRtn";
import { FeedbackPageLockSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";

interface ThreatDefenceFeedbackPageOverviewTableBuilderOwnProps {
  feedbackPage: ThreatDefenceFeedbackPageOverview;
}

export type ThreatDefenceFeedbackPageOverviewTableBuilderProps = ThreatDefenceFeedbackPageOverviewTableBuilderOwnProps &
  PropsFromRedux;

export const ThreatDefenceFeedbackPageOverviewTableBuilder = (
  props: ThreatDefenceFeedbackPageOverviewTableBuilderProps,
) => {
  const navigate = useNavigate();
  const navigateToEdit = (payload: { id: number }) => navigate(payload.id.toString());
  useRtn([FeedbackPageLockSuccess], navigateToEdit);

  const handleEditClick = (id: number, makeDraft?: boolean) => {
    if (makeDraft) {
      props.feedbackPageEntityStateActions.fetchDraftFeedbackPageEntity(id);
    } else {
      navigateToEdit({ id });
    }
  };

  const { feedbackPage } = props;

  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        <Title title={feedbackPage.name} clamp={2} onTitleClick={() => handleEditClick(feedbackPage.id)} />
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!feedbackPage.isDraft)} />
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>{dateTimeUtils.formatDate(feedbackPage.dateModified)}</Table.Cell>
      <Table.Cell width={columnOptions[3].width}>
        <FeedbackPageEllipsisPopupButton
          feedbackPage={feedbackPage}
          onEdit={() => handleEditClick(feedbackPage.id, true)}
          outlinedEllipsis
          circle
        />
      </Table.Cell>
    </>
  );
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  feedbackPageEntityStateActions: bindActionCreators(feedbackPageEntityStateActions, dispatch),
});

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ThreatDefenceFeedbackPageOverviewTableBuilder);
