import { Icon, Table } from "semantic-ui-react";
import cn from "classnames";

import { ColumnOption } from "../../../../../interfaces/columnOptions";
import { SurveyQuestion, SurveyQuestionAnswer } from "../../types/state";

import { TextTruncate } from "../../../../../components";
import { PerformanceTabSectionList } from "features/Library/Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";
import { ListViewBase } from "../../../../../components/listView";

import "./SurveyPerformanceList.scss";
import { NoFilteredData } from "../../../../../components/charts";
import { completes, roundToTwoDigits } from "features/Library/Common/utils/performanceUtils";
import { Tooltip } from "components/common/tooltip";

const getQuestionType = (type: string): string => {
  if (type === "MultipleChoiceTextQuestion") {
    return "Multiple Select";
  } else {
    return "Single Select";
  }
};

interface Props {
  questions: SurveyQuestion[];
  selectQuestion: (question: SurveyQuestion) => void;
  selectAnswer: (answer: SurveyQuestionAnswer) => void;
  reportEnabled: boolean;
}

export const SurveyPerformanceList = ({
  questions,
  selectQuestion,
  selectAnswer,
  reportEnabled,
}: Props): React.ReactElement => {
  const getQuestionIndex = (question: SurveyQuestion): number => questions.indexOf(question);

  const renderQuestionDrillDownLink = (question: SurveyQuestion): React.ReactElement => (
    <span className="shortened-survey-question">
      <Tooltip
        target={
          <TextTruncate>
            <a
              href="./"
              className="survey-question"
              data-testid="survey-question"
              onClick={(e) => {
                e.preventDefault();
                selectQuestion(question);
              }}
            >
              {question.QuestionText}
            </a>
          </TextTruncate>
        }
        content={question.QuestionText}
      />
    </span>
  );

  const renderAnswers = (question: SurveyQuestion): React.ReactElement => (
    <ul className="survey-answers">
      {question.Answers.map((answer) => (
        <li className="survey-answers__answer" key={answer.AnswerId}>
          <Tooltip
            target={
              <TextTruncate>
                <a
                  href="./"
                  data-testid="survey-answer"
                  onClick={(e) => {
                    e.preventDefault();
                    selectQuestion(question);
                    selectAnswer(answer);
                  }}
                >
                  {answer.AnswerText}
                </a>
              </TextTruncate>
            }
            content={answer.AnswerText}
          />
          <span className="survey-answer-percentage">
            <Icon className="fas fa-comment outline" />
            {`${answer.AnsweredPeople.toLocaleString() ?? 0}${!reportEnabled ? "%" : ""}`}
          </span>
        </li>
      ))}
    </ul>
  );

  const columns: ColumnOption<SurveyQuestion>[] = [
    {
      name: "Question",
      width: 7,
      isSortable: false,
      render(question) {
        return (
          <div className="survey-question-box">
            <div className="survey-question-number">{`${getQuestionIndex(question) + 1}. `}</div>
            <div className="survey-question-answer-container">
              {renderQuestionDrillDownLink(question)}
              {renderAnswers(question)}
            </div>
          </div>
        );
      },
    },
    {
      name: "Type",
      width: 3,
      isSortable: false,
      render(question) {
        return getQuestionType(question.QuestionType);
      },
    },
    {
      name: completes,
      width: 3,
      isSortable: false,
      render(question) {
        return question.Completions?.toLocaleString() ?? 0;
      },
    },
    {
      name: "Skipped",
      width: 3,
      isSortable: false,
      render(question) {
        return question.Skipped?.toLocaleString() ?? 0;
      },
    },
    {
      name: "Completion Rate",
      width: 3,
      isSortable: false,
      render(question) {
        return reportEnabled
          ? `${roundToTwoDigits((question.CompletionRate ?? 0) * 100)}%`
          : `${question.CompletionRate || 0}%`;
      },
    },
  ];

  const buildTableBody = (item: SurveyQuestion) => {
    return columns.map((column, index) => (
      <Table.Cell
        className={cn("table-body-cell", { "table-body-cell--required": item.IsQuestionRequired && index === 0 })}
        width={column.width}
        key={`${column.name}_${index}`}
      >
        {column.render(item)}
      </Table.Cell>
    ));
  };

  return (
    <PerformanceTabSectionList
      title="Questions & Answers"
      count={questions.length}
      renderList={(page) => {
        return !!questions.length ? (
          <ListViewBase
            columnOptions={columns}
            items={questions}
            itemsAmount={questions.length}
            buildTableBody={buildTableBody}
            withFooter={false}
            activePage={page}
            withSelection={false}
          />
        ) : (
          <NoFilteredData />
        );
      }}
    />
  );
};
