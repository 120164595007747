import React, { Component, createRef, MutableRefObject } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { once, isEmpty } from "lodash";
import cn from "classnames";

import NotifyStep from "../commonSteps/notifyStep/NotifyStep";
import { ModalActionConfig, RenderActions, RenderModalActionsType, StepsOptions } from "../types";
import { renderSteps } from "../utils/renderSteps";

import { ContentListStep } from "./contentAssignmentSteps";
import { ConfirmLicensingStep } from "../commonSteps";
import { PriorityLevelsStep } from "../../../components/assignmentModals/commonSteps/index";
import { bindAction, NotifyStepSettings, PriorityLevel } from "../../../interfaces";
import { Filters } from "../../../utils/queryUtils";
import { Filter, GenericFiltersMap, operators } from "../../../utils/filterUtils";
import { AppDispatch, RootState } from "../../../features/Application/globaltypes/redux";
import {
  AddPeopleToContentTypes,
  AssignmentPeopleContext,
  TemplateTypes,
  Strings,
  CommunicationChannels,
} from "../../../enums";
import { detectHasAnyPermissions } from "./helpers/hasPermissions";

import * as addToContentActionsRedux from "../../../features/People/state/slices/addToContentSlice";

import SortOptions from "../../../enums/SortOptions";
import userListUtils, { contentToPayloadPropMap } from "../../../utils/userListUtils";
import ModalWithSteps from "../../modal/ModalWithSteps";

import { fetchPeopleAvailableItems } from "../../../features/People/ContentAssignments/state/contentAssignmentsThunk";
import { reset } from "../../../features/People/ContentAssignments/state/contentAssignmentsSlice";
import {
  fetchContentAssignmentsFilterOptions,
  resetContentAssignmentsFilterOptions,
} from "../../../features/People/ContentAssignments/state/thunks/contentAssignmentsFilterThunk";
import { PriorityItem } from "../../../features/People/types";
import PacksContextStep from "../commonSteps/packsContextStep/PacksContextStep";
import SearchInput from "../../searchInput/SearchInput";
import { resetContentPacksAction } from "../../../features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { renderModalActionsFirstStep, renderModalActionsMiddleStep } from "./utils/contentAssignmentModalActions";
import { resetState } from "../../../features/SystemNotifications/state/slices/notifyStepSlice";
import { toTemplateType } from "./helpers/mapToTemplateType";
import {
  withNotifyConfig,
  WithNotifyConfigProps,
} from "../../../features/SystemNotifications/containers/withNotifyConfig";
import { initialNotifyConfigDefault } from "../../../features/SystemNotifications/config";
import { NotifySettings } from "../../../features/SystemNotifications/types";
import { AvailableItemBase } from "../../../features/People/ContentAssignments/models";
import { NotifyStepSwitch } from "../../notifyStep/NotifyStepSwitch";
import { Button } from "components/buttons/button/Button";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import "./contentAssignmentModal.scss";
import { LDProps } from "../../../features/LDProps";
import { PeopleType } from "features/Library/PeopleAssignments/types";
import { FeatureFlags } from "featureFlags";

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ContentAssignmentState {
  selectedIds: Record<AddPeopleToContentTypes, number[]>;
  appliedFilter: Filters;
  sortOptions: string;
  isDataValid: boolean;
  isNotifyTabValid: boolean;
  isDataLoaded: boolean;
  skipPacksContextStep: boolean;
  packsContextStepHeader?: string;
  search: string;
}

export type ContentAssignmentModalProps = PropsFromRedux & {
  peopleType: PeopleType;
  onConfirm?: (notificationSettings?: NotifyStepSettings) => void;
  notifyTemplateType?: TemplateTypes;
};
export type Props = ContentAssignmentModalProps & WithNotifyConfigProps<NotifySettings> & LDProps;

const initialState: ContentAssignmentState = {
  selectedIds: {
    [AddPeopleToContentTypes.Flows]: [],
    [AddPeopleToContentTypes.Videos]: [],
    [AddPeopleToContentTypes.Events]: [],
    [AddPeopleToContentTypes.Surveys]: [],
    [AddPeopleToContentTypes.Assessments]: [],
    [AddPeopleToContentTypes.PDFs]: [],
  },
  sortOptions: SortOptions.ModifiedDateDesc,
  appliedFilter: {},
  isDataValid: true,
  isNotifyTabValid: false,
  isDataLoaded: false,
  skipPacksContextStep: false,
  packsContextStepHeader: undefined,
  search: "",
};

const INITIAL_ITEM_SKIP = 0;

export const assetFilter = new Filter([
  {
    key: "isDraft",
    operator: operators.equal,
  },
]);

export class ContentAssignmentModal extends Component<Props, ContentAssignmentState> {
  private readonly onPreviousNotifyStepRef: React.MutableRefObject<() => Promise<void>>;
  private readonly getNotifySettingsRef: React.MutableRefObject<() => Promise<NotifyStepSettings | undefined>>;
  private readonly flexiblePriorityDueDate: boolean;

  constructor(props: Props) {
    super(props);

    this.onPreviousNotifyStepRef = createRef() as MutableRefObject<() => Promise<void>>;
    this.getNotifySettingsRef = createRef() as MutableRefObject<() => Promise<NotifyStepSettings | undefined>>;
    this.state = initialState;
    this.flexiblePriorityDueDate = !!this.props.flags?.[FeatureFlags.FlexiblePriorityDueDate];
  }

  componentDidMount() {
    const { filterActions, hasAnyPermission } = this.props;

    if (hasAnyPermission) {
      this.fetchAvailableItems();
      filterActions.fetchFilterOptions();
    }
  }

  componentWillUnmount() {
    const { availableItemsActions, filterActions } = this.props;
    filterActions.resetFilter();
    availableItemsActions.resetGrid();
    this.props.addToContentActions.resetModal();
    this.props.resetNotificationState();
    this.onIsDataLoaded(false);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.showModal !== this.props.showModal && !this.props.showModal) {
      this.props.notifyConfigPayload.resetNotifyConfig();
    }
  }

  onSelectedItemsChanged = (ids: number[]) => {
    const { selectedIds } = this.state;
    const { grid, contentType, addToContentActions, priorityItems } = this.props;
    const payloadContentType = contentToPayloadPropMap[contentType];
    this.setState({ selectedIds: { ...selectedIds, [contentType]: ids } }, () => {
      const filteredPriorityItems = priorityItems.filter(
        (x) => x.blockType !== payloadContentType || ids.includes(x.id),
      );
      const priorityItemsToAdd = ids
        .filter((id) => !filteredPriorityItems.some((x) => x.id === id))
        .map((id) => {
          const items: any[] = grid.items;
          const selected = items.find((item) => item.id === id);
          return {
            ...userListUtils.mapToPriorityItem(selected, payloadContentType),
            durationInSeconds: selected?.durationInSeconds,
          };
        });

      addToContentActions.setPriorityItems([...filteredPriorityItems, ...priorityItemsToAdd]);
      this.setSkipPacksContextStep(false);
      this.props.resetContentPacks();
    });
  };

  fetchAvailableItems = (skip?: number) => {
    const { availableItemsActions, selectedUserIds, selectedPeopleType } = this.props;
    const { sortOptions, appliedFilter, search } = this.state;

    availableItemsActions.fetchAvailableItems(
      skip ?? INITIAL_ITEM_SKIP,
      sortOptions,
      { ...appliedFilter, isDraft: false },
      selectedUserIds,
      selectedPeopleType,
      search,
    );
  };

  onSortOptionsChanged = (_: React.SyntheticEvent<HTMLElement>, data: { [key: string]: any }) => {
    this.onSelectedItemsChanged([]);
    this.props.availableItemsActions.resetGrid();
    this.setState({ sortOptions: data.value }, this.loadItems);
  };

  onContentSegmentationChange = (contentType: AddPeopleToContentTypes) => {
    const { availableItemsActions, addToContentActions, userPermissions, filterActions } = this.props;

    availableItemsActions.resetGrid();
    filterActions.resetFilter();
    this.setState(
      {
        appliedFilter: initialState.appliedFilter,
        sortOptions: initialState.sortOptions,
        search: initialState.search,
      },
      () => {
        addToContentActions.setSelectedContentType(contentType);
        if (detectHasAnyPermissions(userPermissions, contentType)) {
          this.fetchAvailableItems();
          filterActions.fetchFilterOptions();
        }
      },
    );
  };

  onCloseModal = () => {
    this.props.resetContentPacks();
    this.props.addToContentActions.resetModal();
  };

  resetFilter = async () => {
    this.onSelectedItemsChanged([]);

    // await prevents too early new request calling (loadItemsToAddPage) because of the wrong skip parameter increment
    await this.props.availableItemsActions.resetGrid(); //NOSONAR
    this.setState({ appliedFilter: {} }, this.loadItemsToAddPage);
  };

  applyFilter = (filter: Filters) => {
    const { availableItemsActions } = this.props;
    this.onSelectedItemsChanged([]);
    availableItemsActions.resetGrid();
    this.setState({ appliedFilter: filter }, () => this.fetchAvailableItems());
  };

  applySearch = (search: string) => {
    const { availableItemsActions } = this.props;
    this.setState(
      (prevState) => {
        let sortOptions = prevState.sortOptions;
        const searchWasCleared = prevState.search && !search;

        if (searchWasCleared) {
          sortOptions = initialState.sortOptions;
        } else if (search) {
          sortOptions = "";
        }

        return {
          sortOptions,
          search,
        };
      },
      () => {
        availableItemsActions.resetGrid();
        this.fetchAvailableItems();
      },
    );
  };

  loadItemsToAddPage = () => {
    this.fetchAvailableItems(this.props.grid.items.length);
  };

  loadItems = () => {
    this.fetchAvailableItems();
  };

  onPriorityItemsUpdate = (priorityLevel: PriorityLevel[]) => {
    this.props.addToContentActions.setPriorityItems(priorityLevel as PriorityItem[]);
  };

  onIsDataValidChange = (isDataValid: boolean) => {
    if (this.state.isDataValid !== isDataValid) {
      this.setState({ isDataValid });
    }
  };

  onNotifyTabValidChange = (isValid: boolean) => {
    this.setState({ isNotifyTabValid: isValid });
  };

  onIsDataLoaded = (isDataLoaded: boolean) => {
    if (this.state.isDataLoaded !== isDataLoaded) {
      this.setState({ isDataLoaded });
    }
  };

  setSkipPacksContextStep = (skipPacksContextStep: boolean) => {
    this.setState({ skipPacksContextStep });
  };

  onResetNotifyStep = () => {
    this.props.resetNotificationState();
  };

  setPacksContextStepHeader = (packsContextStepHeader?: string) => {
    if (this.state.packsContextStepHeader !== packsContextStepHeader) {
      this.setState({ packsContextStepHeader });
    }
  };

  renderModalActionsLastStep =
    ({ previous, confirm }: ModalActionConfig) =>
    (_: () => void, prevStep: () => void) =>
    (closeModal: Function) => {
      const confirmHandler = async () => {
        const onClickResult = await confirm?.onClick?.();
        this.props.onConfirm?.();
        this.props.addToContentActions.onSubmitModal(this.flexiblePriorityDueDate, onClickResult);
        this.props.resetContentPacks();
        closeModal();
      };
      return (
        <>
          <Button
            blur
            primary
            className="previous"
            content="Previous"
            onClick={async () => {
              await previous?.onClick?.();
              prevStep();
            }}
          />
          <Button
            primary
            className="confirm"
            content="Finish"
            onClick={once(confirmHandler)}
            disabled={confirm?.disabled}
          />
        </>
      );
    };

  renderContentListStep = (renderModalActions: RenderModalActionsType) => {
    const { grid, filterOptions, selectedUserIds, contentType, hasAnyPermission, selectedPeopleType } = this.props;
    const { selectedIds, appliedFilter, sortOptions } = this.state;

    return (
      <ContentListStep
        blur
        key="Add Content"
        renderSearch={() => (
          <SearchInput placeholder="Search..." onChange={this.applySearch} defaultValue={this.state.search} />
        )}
        searchValue={this.state.search}
        orderBy={sortOptions}
        header={Strings.modalTitles.addContent}
        contentType={contentType}
        renderModalActions={renderModalActions}
        loadData={this.loadItemsToAddPage}
        items={grid.items.map((item: AvailableItemBase) => ({
          ...item,
          usersCount: selectedUserIds.length,
          selectedPeopleType: selectedPeopleType,
        }))}
        itemsCount={grid.itemsCount}
        isLoading={grid.isLoading}
        isAllLoaded={grid.isAllLoaded}
        filter={appliedFilter}
        filterOptions={filterOptions[contentType]}
        applyFilter={this.applyFilter}
        resetFilter={this.resetFilter}
        selectedIds={selectedIds[contentType]}
        onSelectedItemsChanged={this.onSelectedItemsChanged}
        onSortOptionsChanged={this.onSortOptionsChanged}
        accessRestricted={!hasAnyPermission}
        onContentSegmentationChange={this.onContentSegmentationChange}
        selectedPeopleType={selectedPeopleType}
      />
    );
  };

  renderPriorityLevelsStep = (renderModalActions: RenderModalActionsType) => (
    <PriorityLevelsStep
      peopleType={this.props.peopleType}
      key="Preferences"
      header="Preferences"
      renderModalActions={renderModalActions}
      priorityItems={this.props.priorityItems}
      onItemsUpdate={this.onPriorityItemsUpdate}
      onIsDataValidChange={this.onIsDataValidChange}
    />
  );

  renderPacksContextStep = (renderModalActions: RenderModalActionsType) => {
    const { packsContextStepHeader } = this.state;

    return (
      <PacksContextStep
        key="Licensing"
        header={packsContextStepHeader}
        renderModalActions={renderModalActions}
        info={this.props.priorityItems.map((i) => {
          return { ...i, id: i.id.toString(), type: i.blockType };
        })}
        onIsDataValidChange={this.onIsDataValidChange}
        noDataLoaded={() => this.setSkipPacksContextStep(true)}
        setModalStepHeader={() => this.setPacksContextStepHeader("Licensing")}
      />
    );
  };

  renderConfirmLicensingStep = (renderModalActions: RenderModalActionsType) => {
    const { selectedUserIds } = this.props;

    return (
      <ConfirmLicensingStep
        key="License Confirmation"
        header="License Confirmation"
        renderModalActions={renderModalActions}
        info={{
          userIds: this.props.selectedPeopleType === AssignmentPeopleContext.User ? selectedUserIds : [],
          groupIds: this.props.selectedPeopleType === AssignmentPeopleContext.Group ? selectedUserIds : [],
        }}
        onIsDataValidChange={this.onIsDataValidChange}
      />
    );
  };

  renderNotifyStep = (renderModalActions: RenderModalActionsType) => {
    const {
      contentType,
      notifyConfig,
      notifyConfigPayload: { setNotifyConfig, shouldNotify, communicationChannel },
    } = this.props;

    return (
      <NotifyStep
        preRender
        key="Notify"
        header="Notify"
        renderModalActions={renderModalActions}
        onIsDataValidChange={this.onNotifyTabValidChange}
        onIsDataLoaded={this.onIsDataLoaded}
        isDataLoaded={this.state.isDataLoaded}
        onPreviousNotifyStepRef={this.onPreviousNotifyStepRef}
        getNotifySettingsRef={this.getNotifySettingsRef}
        notifyTemplateType={toTemplateType(contentType)}
        renderSwitch={(switchProps) => (
          <NotifyStepSwitch config={notifyConfig} onNotifyConfigChange={setNotifyConfig} switchProps={switchProps} />
        )}
        shouldNotify={shouldNotify}
        communicationChannel={communicationChannel}
      />
    );
  };

  getStepsOptions = (): StepsOptions[] => {
    const { selectedIds, isDataValid, isNotifyTabValid, skipPacksContextStep, isDataLoaded } = this.state;
    const {
      addToContentActions,
      selectedUserIds,
      grid,
      resetContentPacks,
      priorityItems,
      notifyConfigPayload: { shouldNotify, communicationChannel },
    } = this.props;
    const notifyStepNotReady =
      communicationChannel !== CommunicationChannels.MsTeams && (!isDataLoaded || !isNotifyTabValid);
    const initialBlockType = priorityItems?.[0]?.blockType;
    const skipNotifyContextStep = priorityItems.some(
      ({ hasBeenPublished, blockType }) => !hasBeenPublished || blockType !== initialBlockType,
    );
    return [
      {
        renderStep: this.renderContentListStep,
        modalActionConfig: {
          previous: {
            onClick: () => {
              resetContentPacks();
              addToContentActions.resetModal();
            },
          },
          next: {
            disabled: isEmpty(Object.values(selectedIds).flat()) || isEmpty(selectedUserIds) || grid.isLoading,
            content: "Add",
          },
        },
      },
      {
        renderStep: this.renderPriorityLevelsStep,
        modalActionConfig: {
          previous: {
            disabled: !isDataValid,
            onClick: () => {
              this.onIsDataValidChange(true);
              this.props.addToContentActions.resetPriorityItems();
            },
          },
          next: {
            disabled: !isDataValid,
            onClick: () => this.onIsDataValidChange(false),
          },
        },
      },
      {
        renderStep: this.renderPacksContextStep,
        skipStep: skipPacksContextStep,
        modalActionConfig: {
          previous: {
            onClick: () => this.onIsDataValidChange(true),
          },
          next: {
            disabled: !isDataValid,
            onClick: () => this.onIsDataValidChange(false),
          },
        },
      },
      {
        renderStep: this.renderConfirmLicensingStep,
        modalActionConfig: {
          previous: {
            onClick: () => this.onIsDataValidChange(true),
          },
          next: {
            disabled: !isDataValid,
            onClick: () => {
              this.onIsDataValidChange(false);
            },
          },
          confirm: {
            disabled: !isDataValid,
          },
        },
      },
      {
        renderStep: this.renderNotifyStep,
        skipStep: skipNotifyContextStep,
        modalActionConfig: {
          previous: {
            onClick: () => this.onPreviousNotifyStepRef?.current?.(),
          },
          confirm: {
            disabled: shouldNotify && notifyStepNotReady,
            onClick: () => {
              return this.getNotifySettingsRef?.current?.();
            },
          },
        },
      },
    ];
  };

  renderActions: RenderActions = {
    renderModalActionsFirstStep: renderModalActionsFirstStep,
    renderModalActionsMiddleStep: renderModalActionsMiddleStep,
    renderModalActionsLastStep: this.renderModalActionsLastStep,
  };

  render() {
    const { showModal, hasAnyPermission } = this.props;

    return (
      <ModalWithSteps
        className={cn("content-assignment-modal", { restricted: !hasAnyPermission })}
        scrolling
        showModal={showModal}
        onCancel={this.onCloseModal}
        onBeforeClose={this.onResetNotifyStep}
      >
        {renderSteps(this.getStepsOptions(), this.renderActions)}
      </ModalWithSteps>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  grid: {
    items: state.people.contentToPeople.assignments.items,
    isLoading: state.people.contentToPeople.assignments.isLoading,
    isAllLoaded: state.people.contentToPeople.assignments.areAllLoaded,
    itemsCount: state.people.contentToPeople.assignments.itemsCount,
  },
  filterOptions: {
    [AddPeopleToContentTypes.Flows]: state.people.contentToPeople.filters.filterOptions,
    [AddPeopleToContentTypes.Videos]: state.people.contentToPeople.filters.filterOptions,
    [AddPeopleToContentTypes.Events]: state.library.events.filters.filterOptions,
    [AddPeopleToContentTypes.Surveys]: state.people.contentToPeople.filters.filterOptions,
    [AddPeopleToContentTypes.Assessments]: state.people.contentToPeople.filters.filterOptions,
    [AddPeopleToContentTypes.PDFs]: state.people.contentToPeople.filters.filterOptions,
  },
  selectedUserIds: state.people.addToContentModal.selectedPeopleIds,
  contentType: state.people.addToContentModal.selectedContentType,
  showModal: state.people.addToContentModal.showModal,
  priorityItems: state.people.addToContentModal.priorityItems,
  selectedPeopleType: state.people.addToContentModal.selectedPeopleType,
  userPermissions: state.userProfile.permissions,
  hasAnyPermission: detectHasAnyPermissions(
    state.userProfile.permissions,
    state.people.addToContentModal.selectedContentType,
  ),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    filterActions: {
      resetFilter: () => dispatch(resetContentAssignmentsFilterOptions()),
      fetchFilterOptions: () => dispatch(fetchContentAssignmentsFilterOptions()),
    },
    availableItemsActions: {
      resetGrid: () => dispatch(reset()),
      fetchAvailableItems: <TFilters extends string | number>(
        skip: number,
        orderBy: string,
        filter: GenericFiltersMap<TFilters>,
        selectedUserIds: number[],
        modalType: AssignmentPeopleContext,
        search?: string,
      ) =>
        dispatch(
          fetchPeopleAvailableItems(
            { userIds: selectedUserIds, skip: skip, orderBy: orderBy, filterParams: filter, search },
            modalType,
          ),
        ),
    },
    addToContentActions: bindActionCreators(addToContentActionsRedux, dispatch),
    resetContentPacks: bindActionCreators(resetContentPacksAction, dispatch),
    resetNotificationState: bindAction(resetState, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(withNotifyConfig(withLDConsumer()(ContentAssignmentModal), initialNotifyConfigDefault));
