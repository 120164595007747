import { Dispatcher } from "../../../../../interfaces/redux";
import { Filters } from "../../../../../utils/queryUtils";
import {
  getActionBaseProvider,
  getActionProvider,
  makeActionCreator,
  makeFetchActionCreatorForItems,
} from "../../../../Application/actions/actionsBuilder";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/people/peopleEvents";
import * as addGroupActionTypes from "./addGroupActionTypes";
import { NotifyStepSettings } from "../../../../../interfaces";

const filter = "filter";

export const fetchEnrollmentUsers = (skip: number, top: number, orderByParams: string, filterParams: Filters) =>
  makeFetchActionCreatorForItems(
    () => dataService.users.getUsers(skip, top, orderByParams, filterParams),
    addGroupActionTypes.FETCH_ENROLLMENT_USERS,
  );

export const resetEnrollmentUsers = makeActionCreator(addGroupActionTypes.RESET_ENROLLMENT_USERS);

export const setEnrollmentUsersFilter = makeActionCreator(
  addGroupActionTypes.PEOPLE_ADD_GROUP_ENROLLMENT_USERS_SET_ITEMS_FILTER,
  filter,
);

export const resetEnrollmentUsersFilter = makeActionCreator(
  addGroupActionTypes.PEOPLE_ADD_GROUP_ENROLLMENT_USERS_RESET_ITEMS_FILTER,
);

export const fetchManualSelectionUsers = (
  skip: number,
  top: number,
  orderByParams: string,
  filterParams: Filters,
  search: string,
) =>
  makeFetchActionCreatorForItems(
    () => dataService.users.getUsersV2(skip, top, orderByParams, filterParams, search),
    addGroupActionTypes.FETCH_MANUAL_SELECTION_USERS,
  );

export const fetchOwners = (
  skip: number,
  top: number,
  orderByParams: string | null,
  filterParams: Filters,
  search: string,
  currentUser?: { email: string; id: number },
) =>
  makeFetchActionCreatorForItems(
    () => dataService.users.getUsersV2(skip, top, orderByParams, filterParams, search, currentUser),
    addGroupActionTypes.FETCH_GROUP_OWNERS,
  );

const createGroupBegin = () => ({
  type: addGroupActionTypes.CREATE_GROUP_BEGIN,
});

const createGroupSuccess = (createdGroup: any) => ({
  type: addGroupActionTypes.CREATE_GROUP_SUCCESS,
  payload: createdGroup,
});

const createGroupFailure = (error: any) => ({
  type: addGroupActionTypes.CREATE_GROUP_FAILURE,
  payload: { error },
});

const updateGroupSuccess = (createdGroup: any) => ({
  type: addGroupActionTypes.UPDATE_GROUP_SUCCESS,
  payload: createdGroup,
});

export const reset = () => ({ type: addGroupActionTypes.RESET_GROUP_STATE });

const fetchAuthenticatedUserActionCreator = makeActionCreator(
  addGroupActionTypes.PEOPLE_FETCH_AUTHENTICATED_USER,
  "user",
);

export const createGroup = (userInfo: any) => {
  return async (dispatch: Dispatcher) => {
    try {
      dispatch(createGroupBegin());
      await dataService.groups.createGroup(userInfo);
      const createdGroup = await RtnEventsEmitter.subscribeOnceAndWait(rtnEvents.GroupCreated, 40000);
      dispatch(createGroupSuccess({ ...userInfo, groupId: createdGroup.GroupId, roleId: createdGroup.RoleId }));
    } catch (error) {
      dispatch(createGroupFailure(error));
    }
  };
};

export const updateGroupInfo = (groupId: number, groupInfo: any) => {
  return async (dispatch: Dispatcher) => {
    try {
      dispatch(createGroupBegin());
      dataService.groups.updateGroupInfo(groupId, groupInfo);
      await RtnEventsEmitter.subscribeOnceAndWait(rtnEvents.GroupInfoUpdated, 40000);
      dispatch(updateGroupSuccess({ ...groupInfo, groupId }));
    } catch (error) {
      console.error(error);
      dispatch(createGroupFailure(error));
    }
  };
};

export const setMembersSearch = makeActionCreator(
  addGroupActionTypes.PEOPLE_ADD_GROUP_MANUAL_USERS_SET_SEARCH,
  "search",
);
export const setOwnersSearch = makeActionCreator(addGroupActionTypes.PEOPLE_ADD_GROUP_OWNERS_SET_SEARCH, "search");

export const resetMembersSearch = makeActionCreator(addGroupActionTypes.PEOPLE_ADD_GROUP_MANUAL_USERS_RESET_SEARCH);
export const resetOwnersSearch = makeActionCreator(addGroupActionTypes.PEOPLE_ADD_GROUP_OWNERS_RESET_SEARCH);

export const fetchAuthenticatedUser = () => {
  return async (dispatch: Dispatcher) => {
    try {
      const { data } = await dataService.users.getDefaultUserProfile();
      dispatch(fetchAuthenticatedUserActionCreator(data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const createNotificationSettings = (settings: NotifyStepSettings) => {
  const begin = getActionBaseProvider(addGroupActionTypes.CREATE_GROUP_NOTIFICATION_SETTINGS_BEGIN);
  const success = getActionProvider<string>(addGroupActionTypes.CREATE_GROUP_NOTIFICATION_SETTINGS_SUCCESS);
  const failure = getActionProvider<Error>(addGroupActionTypes.CREATE_GROUP_NOTIFICATION_SETTINGS_FAILURE);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await dataService.systemNotifications.settings.saveSettings(settings);
      dispatch(success(result.data));
    } catch (error) {
      dispatch(failure(error as Error));
    }
  };
};
