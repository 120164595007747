import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DropdownProps, Table } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { bindActionCreators, Dispatch } from "redux";

import { ThreatDefenceCampaignsOverviewState, ThreatDefenceCampaignsOverviewOwnProps } from "./types";
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { ItemsView } from "../../../../../views";
import ThreatDefenceCampaignsNoResults from "../../../../../views/library/campaigns/Overview/ThreatDefenceCampaignsNoResults";
import columnOptions from "./columnOptions";
import { RootState } from "../../../../Application/globaltypes/redux";
import * as campaignsOverviewActions from "../state/actions/threatDefenceCampaignsOverviewActions";
import * as campaignsEntityStateActions from "../state/actions/threatDefenceCampaignsEntityStateActions";
import { SearchInput } from "../../../../../components";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { Title } from "../../../../../components/listViewTemplates";
import { ThreatDefenceCampaignOverview } from "../types/state";
import { PublishedStatus } from "../../../../../components/common/publishedStatus";
import { SortingDirection, SortOptions, PublishedStatusTypes, ViewType } from "../../../../../enums";
import threatDefenceFetchDataUtils from "../../../../../utils/threatDefenceFetchDataUtils";
import CampaignEllipsisPopupButton from "../../../../../components/campaigns/CampaignEllipsisPopupButton";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { CampaignLockSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const defaultSortColumn = "datecreated";

export type ThreatDefenceCampaignsOverviewProps = ThreatDefenceCampaignsOverviewOwnProps &
  PropsFromRedux &
  WithRouterProps;

export class ThreatDefenceCampaignsOverview extends Component<
  ThreatDefenceCampaignsOverviewProps,
  ThreatDefenceCampaignsOverviewState
> {
  constructor(props: ThreatDefenceCampaignsOverviewProps) {
    super(props);
    this.state = {
      selectedCampaignIds: [],
      orderBy: SortOptions.CreatedDateDesc,
      isDeleteConfirmationOpened: false,
      focusedCampaignId: undefined,
      isEllipsisMenuDisabled: false,
    };
  }

  componentDidMount() {
    RtnEventsEmitter.subscribe(CampaignLockSuccess, this.goToEditEntity);
  }

  componentWillUnmount() {
    RtnEventsEmitter.unsubscribe(CampaignLockSuccess, this.goToEditEntity);
  }

  buildTableBody = (campaign: ThreatDefenceCampaignOverview) => {
    return (
      <React.Fragment>
        <Table.Cell width={columnOptions[0].width}>
          <Title title={campaign.name} onTitleClick={() => this.handleEditClick(campaign.id, true)} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <Title title={campaign.type} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>
          <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!campaign.isDraft)} />
        </Table.Cell>
        <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(campaign.dateModified)}</Table.Cell>
        <Table.Cell width={columnOptions[4].width}>
          <CampaignEllipsisPopupButton
            onEditCampaign={() => this.handleEditClick(campaign.id, campaign.isDraft)}
            disabled={this.state.isEllipsisMenuDisabled}
            campaign={campaign}
            outlinedEllipsis
            circle
          />
        </Table.Cell>
      </React.Fragment>
    );
  };

  onSelectedItemsChanged = (ids: Array<number>) => {
    this.setState({ selectedCampaignIds: ids || [] });
  };

  fetchCampaigns = (skip: number, top: number, orderBy: string) => {
    this.props.campaignsOverviewActions.getThreatDefenceCampaignsAsync(top, skip, orderBy);
  };

  getCampaigns = (skip?: number, top?: number, sortingColumnName?: string, sortingDirection?: SortingDirection) => {
    const orderParams = threatDefenceFetchDataUtils.formatOrderParams(
      sortingColumnName || defaultSortColumn,
      sortingDirection || SortingDirection.Descending,
    );
    this.fetchCampaigns(skip ?? 0, top ?? 10, orderParams);
  };

  onSortChange = async (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    this.setState(
      {
        orderBy: data.value?.toString(),
      },
      this.getCampaigns,
    );
  };

  goToEditEntity = (payload: { id: number }) => this.props.navigate(payload.id.toString());

  handleEditClick = (id: number, isDraft?: boolean) => {
    if (!isDraft) {
      this.props.campaignsEntityStateActions.fetchDraftThreatDefenceCampaignEntity(id);
      return;
    }
    this.goToEditEntity({ id });
  };

  renderCreateButton = (): React.ReactElement | null => {
    const { navigate } = this.props;

    return (
      <Button
        primary
        className="create-button"
        onClick={() => {
          navigate("create");
        }}
      >
        Create Campaign
      </Button>
    );
  };

  render() {
    const { campaigns, totalCount, isLoading } = this.props;
    return (
      <section className="nested-content campaigns">
        <OverviewHeader title="Campaigns" titleForGA="Phishing Campaigns">
          {this.renderCreateButton()}
        </OverviewHeader>
        <ItemsView
          items={campaigns}
          viewType={ViewType.LIST}
          getData={this.getCampaigns}
          noResultsContent={<ThreatDefenceCampaignsNoResults createButton={this.renderCreateButton()} />}
          isFirstLoad={campaigns.length === 0}
          isLoading={isLoading}
          isAllDataLoaded={false}
          selectedIds={this.state.selectedCampaignIds}
          className="campaigns-items-view"
          filterOptionsLoading={false}
          applyFilter={/* istanbul ignore next */ () => {}} //NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} //NOSONAR
          buildTableBody={this.buildTableBody}
          columnOptions={columnOptions}
          itemsInlineCount={totalCount}
          onSelectedListItemsChanged={this.onSelectedItemsChanged}
          onSortChange={this.onSortChange}
          sortingColumnName={defaultSortColumn}
          sortingDirection={SortingDirection.Descending}
          renderSearch={() => <SearchInput placeholder="Search for Campaigns..." />}
        />
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const overview = state.library.threatDefenceCampaigns.threatDefenceCampaignsOverviewReducer;
  const entityState = state.library.threatDefenceCampaigns.threatDefenceCampaignEntityStateReducer;
  return {
    campaigns: overview.campaigns,
    isLoading: overview.isLoading || entityState.changingEntityState,
    totalCount: overview.totalCount,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  campaignsOverviewActions: bindActionCreators(campaignsOverviewActions, dispatch),
  campaignsEntityStateActions: bindActionCreators(campaignsEntityStateActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(ThreatDefenceCampaignsOverview));
