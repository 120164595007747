import React from "react";
import PropTypes, { func, string, object, number } from "prop-types";

import DropdownWrapper from "../dropdownWrapper/DropdownWrapper";

function FilterDropdown(props) {
  /// 'propertyName' will be used in query string while sending request to backend
  /// so backend filter model prop should have the same name
  const { updateFilter, filter, propertyName, updateFilterOnChange, defaultSelected, items, ...dropdownProps } = props;

  const handleFilterChange = (_, { value }) => {
    let newFilter = { ...filter };

    if (value) {
      newFilter[propertyName] = value;
    } else {
      delete newFilter[propertyName];
    }

    updateFilter(newFilter);
  };

  let selected = filter[propertyName] ? filter[propertyName] : defaultSelected;

  return (
    <DropdownWrapper
      blur
      selected={selected}
      items={items}
      handleOptionChange={handleFilterChange}
      {...dropdownProps}
    />
  );
}

FilterDropdown.propTypes = {
  filter: object,
  updateFilter: func,
  propertyName: string,
  defaultSelected: PropTypes.oneOfType([string, number]),
  items: PropTypes.array,
  fluid: PropTypes.bool,
  selection: PropTypes.bool,
};

export default FilterDropdown;
