import Axios from "axios";
import { Dispatch } from "redux";
import * as usersOverviewActionTypes from "./usersOverviewActionTypes";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import {
  makeActionCreator,
  makeFetchActionCreator,
  makeFetchItemsActionCreator,
  makeFetchActionCreatorForItems,
} from "../../../../Application/actions/actionsBuilder";
import { EndpointsToGetSasLink, FileStoragePermissionTypes } from "../../../../../enums";
import fileUtils, { OnUploadProgressHandler, AcceptCancelationHandler } from "../../../../../utils/fileUtils";
import { FiltersMap } from "../../../../../utils/filterUtils";

export const fetchUsers = (
  skip: number,
  top: number,
  orderByParams: string,
  filterParams: FiltersMap,
  search?: string,
) =>
  makeFetchActionCreatorForItems(
    () => dataService.users.getUsersV2(skip, top, orderByParams, filterParams, search!),
    usersOverviewActionTypes.FETCH_USERS,
  );

export const uploadUsersFile = (
  accountId: number,
  actorId: number,
  contentType: string,
  file: File,
  onUploadProgressHandler: OnUploadProgressHandler,
  acceptCancellationHandler: AcceptCancelationHandler,
) => {
  const uploadUsersFileBegin = makeActionCreator(usersOverviewActionTypes.UPLOAD_USERS_FILE_BEGIN);

  const uploadUsersFileSuccess = makeActionCreator(usersOverviewActionTypes.UPLOAD_USERS_FILE_SUCCESS);

  const uploadUsersFileFail = makeActionCreator(usersOverviewActionTypes.UPLOAD_USERS_FILE_FAIL, "error");

  return async (dispatch: Dispatch) => {
    const fileStoragePermissions = [FileStoragePermissionTypes.Write, FileStoragePermissionTypes.Read];

    const sasConfig = {
      endpointToGetSasLink: EndpointsToGetSasLink.People.CsvImport,
      sasRequest: {
        permissions: fileStoragePermissions,
      },
    };
    const uploadOptions = {
      contentType: contentType,
      metadata: {
        account_id: String(accountId),
        actor_id: String(actorId),
      },
      blockSize: file.size / 5,
      parallelism: 1,
    };

    try {
      dispatch(uploadUsersFileBegin());
      const result = await fileUtils.uploadFileToBlobStorage(
        file,
        uploadOptions,
        onUploadProgressHandler,
        acceptCancellationHandler,
        sasConfig,
      );
      dispatch(uploadUsersFileSuccess());
      return result;
    } catch (error: any) {
      if (error.code !== "REQUEST_ABORTED_ERROR" && !Axios.isCancel(error)) {
        dispatch(uploadUsersFileFail(error));
        throw error;
      }
    }
  };
};

export const updateUsersFileUploadProgress = makeActionCreator(
  usersOverviewActionTypes.UPDATE_USERS_FILE_UPLOAD_PROGRESS,
  "progress",
);

export const cancelUsersFileUpload = makeActionCreator(usersOverviewActionTypes.CANCEL_USERS_FILE_UPLOAD);

export const resetUsersFileUpload = makeActionCreator(usersOverviewActionTypes.RESET_USERS_FILE_UPLOAD);

export const fetchUserGroupsAssignments = (userIds: number[]) => {
  const fetchUserGroupsAssignmentsBegin = makeActionCreator(
    usersOverviewActionTypes.FETCH_USER_GROUPS_ASSIGNMENTS.FETCH_ITEMS_BEGIN,
  );

  const fetchUserGroupsAssignmentsSuccess = makeActionCreator(
    usersOverviewActionTypes.FETCH_USER_GROUPS_ASSIGNMENTS.FETCH_ITEMS_SUCCESS,
    "items",
  );

  const fetchUserGroupsAssignmentsFailure = makeActionCreator(
    usersOverviewActionTypes.FETCH_USER_GROUPS_ASSIGNMENTS.FETCH_ITEMS_FAILURE,
    "error",
  );

  return (dispatch: Dispatch) => {
    dispatch(fetchUserGroupsAssignmentsBegin());

    dataService.users
      .getUserGroupsAssignments(userIds)
      .then((result: any) => {
        dispatch(fetchUserGroupsAssignmentsSuccess(result.data));
      })
      .catch((error: any) => {
        dispatch(fetchUserGroupsAssignmentsFailure(error));
      });
  };
};

export const fetchUserRolesAssignments = (userIds: number[]) => {
  const fetchUserRolesAssignmentsBegin = makeActionCreator(
    usersOverviewActionTypes.FETCH_USER_ROLES_ASSIGNMENTS.FETCH_ITEMS_BEGIN,
  );

  const fetchUserRolesAssignmentsSuccess = makeActionCreator(
    usersOverviewActionTypes.FETCH_USER_ROLES_ASSIGNMENTS.FETCH_ITEMS_SUCCESS,
    "items",
  );

  const fetchUserRolesAssignmentsFailure = makeActionCreator(
    usersOverviewActionTypes.FETCH_USER_ROLES_ASSIGNMENTS.FETCH_ITEMS_FAILURE,
    "error",
  );

  return (dispatch: Dispatch) => {
    dispatch(fetchUserRolesAssignmentsBegin());

    dataService.users
      .getUserRolesAssignments(userIds)
      .then((result: any) => {
        dispatch(fetchUserRolesAssignmentsSuccess(result.data));
      })
      .catch((error: any) => {
        dispatch(fetchUserRolesAssignmentsFailure(error));
      });
  };
};

export const fetchUploadedFileColumns = (fileName: string) =>
  makeFetchActionCreator(
    () => dataService.users.getUploadedFileColumns(fileName),
    usersOverviewActionTypes.FETCH_UPLOADED_FILE_COLUMNS,
  );

export const fetchUsersImportPreviewData = (fileName: string, itemsCount: number, mappedFields: any) =>
  makeFetchItemsActionCreator(
    () => dataService.users.getUsersImportPreviewData(fileName, itemsCount, mappedFields),
    usersOverviewActionTypes.FETCH_USERS_IMPORT_PREVIEW_DATA,
  );

export const resetUsersImportPreviewData = makeActionCreator(
  usersOverviewActionTypes.FETCH_USERS_IMPORT_PREVIEW_DATA.RESET_ITEMS,
);
