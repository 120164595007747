import { Table } from "semantic-ui-react";
import { ColumnOption } from "../../../../interfaces/columnOptions";
import { PerformanceTabSectionList } from "features/Library/Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";
import { ListViewBase } from "../../../../components/listView";
import { PerformanceFilter, noData, noTableData } from "features/Library/Common/utils/performanceUtils";
import { useEffect, useRef, useCallback, useState } from "react";
import SortDirections from "enums/sortDirections";

export interface Props<T extends object> {
  rows: T[];
  columns: ColumnOption<T>[];
  filter: PerformanceFilter;
  title?: string;
  hideControls?: boolean;
  onSort?: (sortingColumnName: string, sortingDirection: SortDirections) => void;
}

type ReloadItemsCallback = (condition?: boolean) => void;

export const GenericPerformanceList = <T extends object>({
  rows,
  columns,
  filter,
  hideControls,
  title = "",
  onSort,
}: Props<T>) => {
  const [slicedEntities, setSliceEntities] = useState<Array<T>>([]);
  const reloadItemsRef = useRef<ReloadItemsCallback>();

  const buildTableBody = useCallback(
    (item: T) => {
      return columns.map((column) => (
        <Table.Cell className={"table-body-cell"} width={column.width} key={column.name}>
          {column.render(item)}
        </Table.Cell>
      ));
    },
    [columns],
  );

  const getData = useCallback(
    (skip: number, top: number, sortingColumnName: string, sortingDirection: SortDirections) => {
      setSliceEntities(rows.slice(skip, skip + top));
      if (sortingColumnName && sortingDirection) {
        onSort?.(sortingColumnName, sortingDirection);
      }
    },
    [onSort, rows],
  );
  const reloadItems = (reloadListItems: ReloadItemsCallback) => (reloadItemsRef.current = reloadListItems);

  // Refresh the data list on rows change
  useEffect(() => {
    reloadItemsRef.current?.();
  }, [rows]);

  return (
    <PerformanceTabSectionList
      hideControls={hideControls}
      title={title}
      count={rows.length}
      renderList={(page) => {
        return noTableData(rows) ? (
          noData(filter)
        ) : (
          <ListViewBase
            columnOptions={columns}
            loadPage={getData}
            items={slicedEntities}
            itemsAmount={rows.length}
            buildTableBody={buildTableBody}
            withFooter={false}
            activePage={page}
            withSelection={false}
            setReloadItems={reloadItems}
            // Preserve pagination when sorting
            paginateOnLoad
          />
        );
      }}
    />
  );
};
