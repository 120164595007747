import dataService from "../../../Accounts/services/accountsDataService";
import { makeActionCreator } from "../../../Application/actions/actionsBuilder";
import permissionHelper from "../../CreateRoles/permissionsHelper";

const fetchFilterRolesOptions = (
  fetchFilterOptionsBeginActionType: string,
  fetchFilterOptionsSuccessActionType: string,
  fetchFilterOptionsFailedActionType: string,
) => {
  const fetchFilterOptionsBegin = () => ({
    type: fetchFilterOptionsBeginActionType,
  });

  const fetchFilterOptionsSuccess = makeActionCreator(fetchFilterOptionsSuccessActionType, "areas");
  const fetchFilterOptionsFailed = makeActionCreator(fetchFilterOptionsFailedActionType, "error");

  return async (dispatch: any) => {
    dispatch(fetchFilterOptionsBegin());

    try {
      const result = await dataService.getAccountAreas();
      const allAreas = permissionHelper.addAllArea(result);
      dispatch(fetchFilterOptionsSuccess(allAreas));
    } catch (error) {
      dispatch(fetchFilterOptionsFailed(error));
    }
  };
};

export { fetchFilterRolesOptions };
