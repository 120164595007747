import * as Yup from "yup";
import validationRules from "../validationRules";
import { labels, softwareApplications } from "./tagsValidationSchema";

const nameLengths = {
  min: 3,
  max: 64,
};

const validateFileType = (files:any) => {
  const file = files[0];
  // We do not have fileType returned from backend and assume that an empty value is a valid value
  return !file?.type || validationRules.mp4m4vFileTypes(files);
};

const name = (): Yup.StringSchema<string | undefined, object> =>
  Yup.string()
    .trim()
    .required("Name is required")
    .min(nameLengths.min, `Name must have at least ${nameLengths.min} characters`)
    .max(nameLengths.max, `Name must be maximum of ${nameLengths.max} characters long`);

const uploadedVideos = () =>
  Yup.mixed()
    .test("File does not exist", "Video is required", validationRules.fileListLength)
    .test("File size is equal to zero", "File size is equal to zero", validationRules.fileMinSize)
    .test("File exceeds maximum size", "File exceeds maximum allowed upload size of 4GB", validationRules.fileMaxSize)
    .test("Wrong file type", "Only mp4, m4v file types are allowed", validateFileType)
    .test(
      "File name size exceeds maximum size",
      "File name must be maximum of 120 characters long",
      validationRules.fileNameSize,
    );

export const feedbackPageInfo = Yup.object().shape({
  name: name(),
  labels,
  softwareApplications,
  uploadedVideos: uploadedVideos(),
});
