import React from "react";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import userListUtils from "../../../../../utils/userListUtils";
import GroupOwnersList from "./GroupOwnerList/GroupOwnersList";
import { Filters } from "../../../../../utils/queryUtils";
import { IOwnerItemModel } from "../../../types";
import { SortingDirection } from "../../../../../enums";

import "./groupOwners.scss";
import { RootState } from "features/Application/globaltypes/redux";

export interface IOwnersModel {
  items: IOwnerItemModel[];
  itemsCount: number;
  isLoading: boolean;
}

export interface GroupOwnersProps {
  loadOwners: (
    skip: number,
    top: number,
    orderByParams: string | null,
    filterParams: Filters,
    search: string,
    currentUser?: { email: string; id: number },
  ) => void;
  accountId: number;
  ownersOptions: IOwnersModel;
  setOwnerFilter: (filter: Filters) => void;
  resetOwnerFilter: () => void;
  selectedItemIds: number[];
  updateSelectedItems: (ids: number[]) => void;
  filterOptions: Filters;
  ownersAppliedFilter: Filters;
  getFilterOptions: () => void;
  search: string;
  currentUser: { email: string; id: number };
  onSearchChanged: (search: string) => void;
  isMobo: boolean;
  setReloadListItems?: (reloadItems: (enableSorting: boolean) => void) => void;
  disableSelect?: () => boolean;
}

export const GroupOwners = (props: GroupOwnersProps) => {
  const loadPage = (
    skip: number,
    top: number,
    sortingColumnName: string,
    sortingDirection: SortingDirection,
    filter: Filters,
  ) => {
    const orderByParams = !isEmpty(sortingColumnName)
      ? userListUtils.formatOrderParamsV2(sortingColumnName, sortingDirection)
      : null;

    props.loadOwners(skip, top, orderByParams, filter, props.search, props.isMobo ? undefined : props.currentUser);
  };

  return (
    <section className="group-owners">
      <div className="stretch">
        <GroupOwnersList
          owners={props.ownersOptions.items}
          ownersCount={props.ownersOptions.itemsCount}
          isLoading={props.ownersOptions.isLoading}
          loadPage={loadPage}
          setOwnerFilter={props.setOwnerFilter}
          resetOwnerFilter={props.resetOwnerFilter}
          appliedOwnerFilter={props.ownersAppliedFilter}
          selectedItemIds={props.selectedItemIds}
          updateSelectedItems={props.updateSelectedItems}
          filterOptions={props.filterOptions}
          getFilterOptions={props.getFilterOptions}
          setReloadListItems={props.setReloadListItems}
          search={props.search}
          onSearchChanged={props.onSearchChanged}
          disableSelect={props.disableSelect}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  isMobo: state.userProfile.isMobo,
});

const connector = connect(mapStateToProps);

export default connector(GroupOwners);
