import { ReactNode, MouseEvent } from "react";
import cn from "classnames";
import "./button.scss";

interface Props {
  className?: string;
  children: ReactNode;
  active: boolean;
  onClick: (event: MouseEvent) => void;
}

export const Button = ({ active, ...props }: Props) => <span className={cn("toolbar-btn", { active })} {...props} />;
