import { connect } from "react-redux";
import GenericItemsView, { GenericItemsViewProps } from "../../../../../../../../views/ItemsView/GenericItemsView";
import { AppDispatch, RootState } from "../../../../../../../Application/globaltypes/redux";
import { selectedItemsChanged } from "../../../../../state/slices/eventGroupsSlice";
import { EventGroup } from "../../../../../types/state";

const mapStateToProps = (state: RootState) => {
  const groups = state.library.events.groups;
  return {
    items: groups.items,
    isLoading: groups.isLoading,
    dataCount: groups.itemsCount,
    selectedIds: groups.selectedIds,
    isFirstLoad: groups.itemsCount === 0,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSelectedItemChanged: (ids: number[]) => dispatch(selectedItemsChanged(ids)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<EventGroup>) => React.ReactElement);
