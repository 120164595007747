import axios from "axios";
import {
  AddPacksToAccountRequest,
  GetPacksRequest,
  GetPackPeopleLicensesRequest,
  UpdatePackAccountsVisibilityRequest,
  UpdatePackAccountTypesVisibilityRequest,
  UpdatePackContentRequest,
  UpdateAccountPackLicenseRequest,
  UpdatePackIsPrivate,
} from "../types/requests";
import { IPacksDataService } from "../types/services";
import packFetchDataUtils from "../../../../utils/licensingFetchDataUtils";
import { serializeArrayAndFilterNullable, Filters, buildQueryFromArray } from "../../../../utils/queryUtils";
import { EnablePackTrialRequest, Pack, PackOverview, PackRequest, EnablePackRequest } from "../types/state";
import { escapeTerm } from "../../../../utils/searchUtils";
import { uniq } from "lodash";
import KnownHttpHeaders from "../../../Application/services/knownHttpHeaders";
import {
  AccountPackOverview,
  PeopleAvailablePackOverview,
} from "../../../../components/assignmentModals/packAssignmentModal/types";
import PacksOwnership from "../../../../enums/licensing/packsOwnership";
import PackRequestTypes from "../../../../enums/packRequestTypes";
import { PackContextResponse } from "../../../../interfaces/licenseConfirmation";
import { UserWithPackAccessType } from "../../../../interfaces";
import http from "lib/http";
import { formatFilters } from "utils/filterMapUtils";
import { packFilterTypes } from "../types/urlParams";

const packsDataService: IPacksDataService = {
  getPacksAsync(data: GetPacksRequest, filters: Filters) {
    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    const apiUrl = filters.ownership === PacksOwnership.Available ? "/api/v2/packs/available" : "/api/v2/packs/own";
    const { ownership, ...filtersWithoutOwnership } = filters;
    const formattedFilters = formatFilters(filtersWithoutOwnership, packFilterTypes);

    config.params = { ...config.params, ...formattedFilters, searchTerm: escapeTerm(data.searchTerm) };
    return axios.get(apiUrl, config);
  },

  getPacksWithCancel(data: GetPacksRequest, filters: Filters) {
    const source = axios.CancelToken.source();

    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
      cancelToken: source?.token,
    };

    const apiUrl = filters.ownership === PacksOwnership.Available ? "/api/v2/packs/available" : "/api/v2/packs/own";
    const { ownership, ...filtersWithoutOwnership } = filters;
    const formattedFilters = formatFilters(filtersWithoutOwnership, packFilterTypes);

    config.params = { ...config.params, ...formattedFilters };

    return { getDataPromise: axios.get<PackOverview[]>(apiUrl, config), cancel: source.cancel };
  },

  getPackUserContentAssignmentInfoAsync(packIds: number[], userIds?: number[], groupIds?: number[]) {
    const packsQuery = buildQueryFromArray("packIds", uniq(packIds));
    const usersQuery = userIds && userIds.length > 0 ? "&" + buildQueryFromArray("userIds", uniq(userIds)) : "";
    const groupsQuery = groupIds && groupIds.length > 0 ? "&" + buildQueryFromArray("groupIds", uniq(groupIds)) : "";

    return axios.get(`/api/licenses/available?${packsQuery}${usersQuery}${groupsQuery}`);
  },

  getPackUserFlowAssignmentInfoAsync(packIds: number[], flowId: number) {
    const packsQuery = buildQueryFromArray("packIds", uniq(packIds));

    return axios.get(`/api/licenses/flow-people/available?${packsQuery}&flowId=${flowId}`);
  },

  getPackPeopleAssignmentInfoAsync(packIds: number[], packId: number) {
    const packsQuery = buildQueryFromArray("packIds", uniq(packIds));

    return axios.get(`/api/licenses/pack-people/available?${packsQuery}&packId=${packId}`);
  },

  getContentPacksInfoAsync(type: string, ids: string[]) {
    const query = buildQueryFromArray("ids", ids);
    return axios.get(`/api/content/${type}/packs?${query}`);
  },

  getContentPacksInfoAsyncV2(type: string, ids: string[]) {
    const query = buildQueryFromArray("ids", ids);
    return axios.get<PackContextResponse>(`/api/v2/content/${type}/packs?${query}`);
  },

  getFlowConflictsInfoAsync(ids: string[]) {
    const query = buildQueryFromArray("ids", ids);
    return axios.get(`/api/v4/content/flows/packs/conflicts?${query}`);
  },

  getPackConflictsInfoAsync(ids: string[]) {
    const query = buildQueryFromArray("ids", ids);
    return axios.get(`/api/v4/packs/conflicts?${query}`);
  },

  getDraftPackConflictsInfoAsync(id: number) {
    return axios.get(`/api/v4/packs/${id}/conflicts`);
  },

  getAvailablePacksWithCancel(assignedAccountId: number, data: GetPacksRequest, filters: Filters) {
    const source = axios.CancelToken.source();

    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
      cancelToken: source?.token,
    };

    const isPredefinedPackAssignment = filters.primaryId && filters.primaryId > 0;

    const apiUrl = isPredefinedPackAssignment
      ? `/api/accounts/${assignedAccountId}/available-packs`
      : `/api/v3/accounts/${assignedAccountId}/available-packs`;

    const formattedFilters = isPredefinedPackAssignment
      ? packFetchDataUtils.formatPrimaryIdFilter(filters)
      : formatFilters(filters, packFilterTypes);

    config.params = { ...config.params, ...formattedFilters };

    return { getDataPromise: axios.get<AccountPackOverview[]>(apiUrl, config), cancel: source.cancel };
  },

  getRequestedPacksLicensesAsync(assignedAccountId: number, packIds: number[]) {
    const packsQuery = buildQueryFromArray("packIds", uniq(packIds));
    return axios.get(`/api/packs/requests?accountId=${assignedAccountId}&${packsQuery}`);
  },

  updatePackAsync(data: Pack) {
    return axios.put(`/api/packs/${data.id}/info`, data);
  },

  getPackAsync(id: number) {
    return axios.get(`/api/packs/${id}`);
  },

  getPackContentAsync(id: number) {
    return axios.get(`/api/packs/${id}/content`);
  },

  updatePackContentAsync(id: number, packContent: UpdatePackContentRequest) {
    return axios.put(`/api/packs/${id}/content`, packContent);
  },

  getPackListingAsync(id: number) {
    return axios.get(`/api/packs/${id}/overview`);
  },

  getPurchasedPackAsync(id: number) {
    return axios.get(`/api/packs/${id}/purchased`);
  },

  requestPackAsync(packRequest: PackRequest) {
    return axios.post(`/api/packs/requests`, { ...packRequest, typeId: PackRequestTypes.RequestPack });
  },

  enablePackTrialAsync(req: EnablePackTrialRequest) {
    return axios.post(`/api/v2/accounts/packs/licenses/trial`, {
      packInfos: [{ packId: req.packId, licensesCount: req.licensesCount }],
    });
  },

  enablePackAsync(request: EnablePackRequest) {
    const data: AddPacksToAccountRequest = { packInfos: [{ ...request, isRequested: false, edited: false }] };
    return axios.post(`/api/v2/account/packs`, data);
  },

  addPacksToAccountsAsync(accountId: number, request: AddPacksToAccountRequest) {
    return axios.post(`/api/v2/accounts/${accountId}/packs`, request);
  },

  extendPacksLicensesToAccountsAsync(accountId: number, request: AddPacksToAccountRequest) {
    return axios.post(`/api/accounts/${accountId}/packs/licenses`, request);
  },

  updateAccountPackLicenseAsync(accountId: number, request: UpdateAccountPackLicenseRequest) {
    return axios.put(`/api/accounts/${accountId}/packs/licenses`, request);
  },

  getPackFiltersAsync() {
    return axios.get("/api/packs/filters");
  },

  getPacksPublishersAsync() {
    return axios.get("/api/packs/publishers", {});
  },

  async isTitleUniqueAsync(title: string, id: number) {
    const config = {
      params: { title: title, id: id },
    };

    const response = await axios.get("/api/packs/is-title-unique", config);
    return response.data;
  },

  getPackTypesAsync() {
    return axios.get("/api/packs/types");
  },

  getPackLicensesTypesAsync() {
    return axios.get("/api/packs/license-types");
  },

  getPackTrialPeriodsAsync() {
    return axios.get("/api/packs/trial-periods");
  },

  async getAccountPacks(accountId: number, data: GetPacksRequest, filters: Filters) {
    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    if (filters) {
      const formattedFilters = formatFilters(filters, packFilterTypes);
      config.params = { ...config.params, ...formattedFilters };
    }

    const apiUrl = `/api/v3/accounts/${accountId}/packs`;
    const response = await axios.get(apiUrl, config);
    return {
      items: response.data,
      count: parseInt(response.headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  getAccountPacksWithCancel(accountId: number, data: GetPacksRequest, filters: Filters) {
    const source = axios.CancelToken.source();

    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
      cancelToken: source?.token,
    };

    const apiUrl = `/api/v3/accounts/${accountId}/packs`;
    const formattedFilters = formatFilters(filters, packFilterTypes);
    config.params = { ...config.params, ...formattedFilters };

    return { getDataPromise: axios.get<PackOverview[]>(apiUrl, config), cancel: source.cancel };
  },

  getGroupsPacksAsync(groupIds: number[]) {
    const groupsQuery = buildQueryFromArray("groupIds", uniq(groupIds));
    return axios.get(`/api/groups/packs?${groupsQuery}`);
  },

  getUserPacksWithCancel(userId: number, data: GetPacksRequest) {
    const source = axios.CancelToken.source();

    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
      cancelToken: source?.token,
    };

    const apiUrl = `/api/v4/users/${userId}/packs`;

    return { getDataPromise: axios.get<PackOverview[]>(apiUrl, config), cancel: source.cancel };
  },

  getGroupPacksWithCancel(groupId: number, data: GetPacksRequest) {
    const source = axios.CancelToken.source();

    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
      cancelToken: source?.token,
    };

    const apiUrl = `/api/v4/groups/${groupId}/packs`;

    return { getDataPromise: axios.get<PackOverview[]>(apiUrl, config), cancel: source.cancel };
  },

  getPackUserLicensesAsync: (packId: number, request: GetPackPeopleLicensesRequest) => {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    const apiUrl = `/api/v4/packs/${packId}/users`;

    return axios.get(apiUrl, config);
  },

  getPackGroupLicensesAsync: (packId: number, request: GetPackPeopleLicensesRequest) => {
    const config = {
      params: request,
      paramsSerializer: serializeArrayAndFilterNullable,
    };

    const apiUrl = `/api/v4/packs/${packId}/groups`;

    return axios.get(apiUrl, config);
  },

  getPackVisibilityAccountIdsAsync(packId: number) {
    return axios.get(`/api/packs/${packId}/visibility/accounts`);
  },

  updatePackAccountsVisibilityAsync(packId: number, request: UpdatePackAccountsVisibilityRequest) {
    return axios.put(`/api/packs/${packId}/visibility/accounts`, request);
  },

  getAccountTypesVisibilityForPackAsync(packId: number) {
    return axios.get(`/api/packs/${packId}/visibility/account-types`);
  },

  updateAccountTypesVisibilityForPackAsync(packId: number, request: UpdatePackAccountTypesVisibilityRequest) {
    return axios.put(`/api/packs/${packId}/visibility/account-types`, request);
  },

  getUserAvailablePacksWithCancel(userId: number, data: GetPacksRequest) {
    const source = axios.CancelToken.source();

    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
      cancelToken: source?.token,
    };

    const apiUrl = `/api/users/${userId}/available-packs`;

    return { getDataPromise: axios.get<PeopleAvailablePackOverview[]>(apiUrl, config), cancel: source.cancel };
  },

  getGroupAvailablePacksWithCancel(groupId: number, data: GetPacksRequest) {
    const source = axios.CancelToken.source();

    const config = {
      params: data,
      paramsSerializer: serializeArrayAndFilterNullable,
      cancelToken: source?.token,
    };

    const apiUrl = `/api/groups/${groupId}/available-packs`;

    return { getDataPromise: axios.get<PeopleAvailablePackOverview[]>(apiUrl, config), cancel: source.cancel };
  },

  async getAvailablePackUsers({ packId, ...params }) {
    const url = `/api/v4/packs/${packId}/available-users`;
    const { data, headers } = await axios.get<UserWithPackAccessType[]>(url, {
      params: params,
      paramsSerializer: serializeArrayAndFilterNullable,
    });

    return {
      items: data.map((item) => ({ ...item, id: item.userId })),
      count: parseInt(headers[KnownHttpHeaders.RecordsCount]),
    };
  },

  getAccountPackLicense(packId: number, accountId: number) {
    return axios.get(`/api/accounts/${accountId}/packs/${packId}/licenses`);
  },

  async getPackUsageCount(packId: number) {
    const { data } = await axios.get(`/api/packs/usages?packId=${packId}`);
    return data.usageCount;
  },

  async deletePack(id: number) {
    return axios.delete(`/api/packs/${id}`);
  },

  async archivePack(accountId: number, packId: number) {
    return axios.post(`/api/accounts/${accountId}/packs/${packId}/archive`);
  },

  restoreAccountPackAsync(accountId: number, request: AddPacksToAccountRequest) {
    return axios.post(`/api/accounts/${accountId}/packs/unarchive`, request);
  },

  getTotalAccountLicenses(packId: number, childAccountId?: number) {
    return axios.get("/api/reports/v1/packs/total-account-licenses", {
      params: {
        packId,
        childAccountId,
      },
    });
  },

  getLicenseUsage(packId: number, childAccountId?: number) {
    return axios.get("/api/reports/v1/packs/consumed-licenses", {
      params: {
        packId,
        childAccountId,
      },
    });
  },

  async addContentToPacks(packIds: number[], contentIds: number[], contentType: string) {
    return axios.post("/api/packs/content", { contentType, packIds, contentIds });
  },

  async getPackIsPrivate(packId: number) {
    const result = await axios.get(`/api/packs/${packId}/is-private`);
    return result.data;
  },

  updatePackIsPrivateAsync(packId: number, request: UpdatePackIsPrivate) {
    return axios.put(`/api/packs/${packId}/is-private`, request);
  },

  async getOwnLicenses() {
    const response = await http.get<number[]>(`/api/me/licenses`);
    return response;
  },

  getPacksFilterOptions(
    contentType: "email" | "flow" | "video" | "assessment" | "survey" | "externalEvent" | "pdf",
    showPurchased: boolean,
    moboId?: string,
  ) {
    return axios.get(
      `/api/accounts/content/${contentType}/packs`,
      moboId ? { headers: { moboId }, params: { showPurchased } } : { params: { showPurchased } },
    );
  },

  async getUserGroupLicenses(userIds: number[], packIds: number[]) {
    const response = await axios.get("/api/users/licenses/inherited", {
      params: { userIds, packIds },
      paramsSerializer: serializeArrayAndFilterNullable,
    });
    return response.data;
  },

  getAssociatedPacksAsync(contentType: string, contentId: number) {
    return axios.get(`/api/content/${contentType}/${contentId}/packs`);
  },
};

export default packsDataService;
