import { set, unionBy } from "lodash";

import {
  ContentType,
  EntityItemType,
  EntityToPeopleAssignments,
  EntityToPeopleRemovePriorities,
  EntityToPeopleUnAssignments,
  FlowPayload,
  GroupPeopleAssignment,
  PeopleAssignment,
  PeopleType,
  EntityToContentRemovePriorities,
} from "../types";
import { PriorityLevels, AssetTypes, EventTypes, AssignmentPeopleContext } from "../../../../enums";
import { PacksContextItem, PriorityLevel } from "../../../../interfaces";
import { EntityType, SavedEvent, SavedFlowItemMetadata, SavedVideo } from "../../Flows/Designer/types";
import { ContentPriorityLevel, FlowPriorityLevel } from "../../../../interfaces/priorityLevel";
import nameof from "../../../../utils/nameof";
import { ContentAssignmentModelItem, FlowPriorityItem } from "../../../People/types";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import { WithId } from "../../../../types";
import { IObservable } from "../../../../interfaces/IObservable";

const DAYS_TO_COMPLETE = 1;
const APPROX_ESTIMATE_MINUTES = 60;

const getDaysToCompleteTillDueDate = (priorityLevel?: PriorityLevels, dueDate?: string | Date | null) => {
  if (priorityLevel === PriorityLevels.required && dueDate) {
    const daysToComplete = dateTimeUtils.getDaysTillDate(dueDate) + 1;
    if (daysToComplete > 0) {
      return daysToComplete;
    }
  }
  // makes no sense to have 0 or less days to complete
  return 1;
};

export const getPriorityItem = (props: EntityItemType): PriorityLevel => {
  const { id, thumbnailUrl, title, contentType } = props;
  const result = {
    id: id,
    title: title,
    thumbnailUrl: thumbnailUrl,
    priorityLevel: PriorityLevels.none,
    approxEstimateMinutes: APPROX_ESTIMATE_MINUTES,
    daysToComplete: DAYS_TO_COMPLETE,
    blockType: contentType,
    isFixedDueDate: false,
  };

  if (props.contentType === ContentType.Flow) {
    set(result, nameof<FlowPriorityItem>("canAutoStart"), props.canAutoStart);
  }
  return result;
};

export const toPeopleAssignmentsModel = (
  peopleId: number,
  priority: PriorityLevel,
  peopleType: PeopleType,
  contentType: ContentType,
  packIds: number[],
  useFlexibleDueDate: boolean,
): EntityToPeopleAssignments => {
  const result: EntityToPeopleAssignments = {
    peopleId,
    contentType,
    peopleType,
    contentId: priority.id,
    priority: priority.priorityLevel,
    dueDate: getDueDate(useFlexibleDueDate, priority),
    daysToComplete: getDaysToComplete(useFlexibleDueDate, priority),
    fixedDueDate: getFixedDueDate(useFlexibleDueDate, priority),
    packIds: packIds,
  };

  if (contentType === ContentType.Flow) {
    (result as EntityToPeopleAssignments<FlowPayload>).payload = {
      autoStart: Boolean((priority as FlowPriorityLevel).autoStart),
    };
  }
  return result;
};

export const toPeopleUnAssignmentsModel = (
  peopleId: number,
  contentId: number,
  peopleType: AssignmentPeopleContext,
  contentType: ContentType,
): EntityToPeopleUnAssignments => ({
  peopleId,
  contentType,
  peopleType,
  contentId: contentId,
});

export const toPeopleRemovePriorityModel = (
  peopleId: number,
  contentId: number,
  peopleType: AssignmentPeopleContext,
  contentType: ContentType,
): EntityToPeopleRemovePriorities => ({ //NOSONAR
  peopleId,
  contentType,
  peopleType,
  contentId: contentId,
});

export const toContentRemovePriorityModel = (
  peopleId: number,
  contentId: number,
  peopleType: AssignmentPeopleContext,
  contentType: ContentType,
): EntityToContentRemovePriorities => ({ //NOSONAR
  peopleId,
  contentType,
  peopleType,
  contentId: contentId,
});

export const getPackContextItems = (
  flowId: number,
  flowItems: {
    entityId: string;
    entityType: EntityType;
    entity: SavedFlowItemMetadata;
  }[],
): PacksContextItem[] => {
  return flowItems.map((i) => {
    return {
      id: i.entityId,
      type: i.entityType,
      title: i.entity.title,
      flowIds: [flowId],
      thumbnailUrl: getThumbnailUrl(i.entity, i.entityType),
      durationInSeconds: i.entityType === AssetTypes.Video ? (i.entity as SavedVideo).durationInSeconds : undefined,
    };
  });
};

const getThumbnailUrl = (entity: SavedFlowItemMetadata, entityType: EntityType): string => {
  let thumbnailUrl = "";
  switch (entityType) {
    case AssetTypes.Video: {
      thumbnailUrl = (entity as SavedVideo).thumbnailUrl ?? "";
      break;
    }
    case EventTypes.ExternalEvent: {
      thumbnailUrl = (entity as SavedEvent).thumbnailUrl ?? "";
      break;
    }
  }

  return thumbnailUrl;
};

export const mapUsersToPriorityItems = (
  useFlexibleDueDate: boolean,
  assignments?: PeopleAssignment[],
  thumbnailUrl?: string,
): PriorityLevel[] => {
  if (!assignments) {
    return [];
  }
  return assignments.map(
    (assignment) =>
      ({
        id: assignment.id,
        title: assignment.firstName + " " + assignment.lastName,
        priorityLevel: assignment.priorityLevelId,
        thumbnailUrl: thumbnailUrl,
        daysToComplete: useFlexibleDueDate
          ? assignment.daysToComplete ?? 1
          : getDaysToCompleteTillDueDate(assignment.priorityLevelId, assignment.dueDate),
        fixedDueDate: assignment.daysToComplete == null ? assignment.dueDate : null,
        approxEstimateMinutes: 60,
        inherited: assignment.isInherited,
        isFixedDueDate: assignment.dueDate != null && assignment.daysToComplete == null,
      }) as PriorityLevel,
  );
};

export const mapContentGroupsPriorityItems = (
  useFlexibleDueDate: boolean,
  assignments?: GroupPeopleAssignment[],
  thumbnailUrl?: string,
): PriorityLevel[] => {
  if (!assignments) {
    return [];
  }
  return assignments.map(
    (assignment) =>
      ({
        id: assignment.id,
        thumbnailUrl: thumbnailUrl,
        title: assignment.name,
        approxEstimateMinutes: 60,
        daysToComplete: useFlexibleDueDate
          ? assignment.daysToComplete ?? 1
          : getDaysToCompleteTillDueDate(assignment.priorityLevelId, assignment.dueDate),
        fixedDueDate: assignment.daysToComplete == null ? assignment.dueDate : null,
        priorityLevel: assignment.priorityLevelId,
        isFixedDueDate: assignment.dueDate != null && assignment.daysToComplete == null,
      }) as PriorityLevel,
  );
};

export const notifyChangePriorityObserver = (
  people: { [key in PeopleType]?: any[] },
  peopleType: PeopleType,
  observer: IObservable<
    (onChangePriorityConfirm: () => void, people: { [key in PeopleType]?: PriorityLevel[] }) => void
  >,
  flexiblePriorityDueDate: boolean,
  thumbnailUrl?: string,
  onConfirm: () => void = () => {},
) => {
  if (peopleType === PeopleType.User) {
    const users = people[PeopleType.User];
    let priorityItems = mapUsersToPriorityItems(flexiblePriorityDueDate, users, thumbnailUrl);
    priorityItems = resetPriorityItemsLevel(priorityItems);
    observer.notify(onConfirm, { [PeopleType.User]: priorityItems });
  } else {
    const groups = people[PeopleType.Group];
    let priorityItems = mapContentGroupsPriorityItems(flexiblePriorityDueDate, groups, thumbnailUrl);
    priorityItems = resetPriorityItemsLevel(priorityItems);
    observer.notify(onConfirm, { [PeopleType.Group]: priorityItems });
  }
};

const resetPriorityItemsLevel = (priorityItems: PriorityLevel[]) => {
  if (priorityItems.length > 1) {
    priorityItems = priorityItems.map((i) => ({ ...i, priorityLevel: PriorityLevels.none, daysToComplete: 1 }));
  }
  return priorityItems;
};

export const mapUserAssignmentsToPriorityItems = (
  assignments: ContentAssignmentModelItem[],
  contentType: ContentType,
  flexiblePriorityDueDate: boolean,
): ContentPriorityLevel[] => {
  return assignments.map(
    (assignment) =>
      ({
        id: assignment.id,
        priorityLevel: assignments.length > 1 ? PriorityLevels.none : assignment.priorityLevelId,
        title: assignment.title,
        thumbnailUrl: assignment.thumbnailUrl,
        fixedDueDate: assignment.daysToComplete == null ? assignment.dueDate : null,
        daysToComplete: flexiblePriorityDueDate
          ? assignment.daysToComplete ?? 1
          : getDaysToCompleteTillDueDate(assignment.priorityLevelId, assignment.dueDate),
        approxEstimateMinutes: 60,
        contentType: contentType,
        inherited: assignment.isInherited,
        isFixedDueDate: assignment.dueDate != null && assignment.daysToComplete == null,
      }) as ContentPriorityLevel,
  );
};

export const getAssignmentByContentId = <T extends WithId>(assignments: T[], contentIds: number[]) => {
  return assignments.filter((assignment) => {
    return contentIds.includes(assignment.id);
  });
};

const dropNonRequiredDaysToComplete = (priorityItems: ContentPriorityLevel[]) => {
  return priorityItems.map((item) => ({
    ...item,
    daysToComplete: item.priorityLevel === PriorityLevels.required ? item.daysToComplete : undefined,
  }));
};

export const getUpdatedPriorityItems = (
  people: { [key in PeopleType]?: ContentPriorityLevel[] },
  callback: (items: ContentPriorityLevel[], peopleType: PeopleType) => void,
) => {
  if (people.user) {
    const users = dropNonRequiredDaysToComplete(people.user);
    callback(users, PeopleType.User);
  } else if (people.group) {
    const groups = dropNonRequiredDaysToComplete(people.group);
    callback(groups, PeopleType.Group);
  }
};

export const hasAnySelectedUnremovableAssignments = <
  T extends { id: number; canRemove: boolean },
  V extends { id: number; canRemove: boolean },
>(
  userAssignments: T[],
  groupAssignments: V[],
) => {
  return userAssignments.some((x) => !x.canRemove) || groupAssignments.some((x) => !x.canRemove);
};

export const filterSelectedAssignments = <T extends WithId>(
  assignments: T[],
  selectedAssignments: T[],
  selectedIds: number[],
) => {
  return unionBy(
    assignments.filter((x) => selectedIds.includes(x.id)),
    selectedAssignments.filter((x) => selectedIds.includes(x.id)),
    "id",
  );
};

export const getDueDate = (useFlexibleDueDate: boolean, priority?: PriorityLevel) => 
  priority?.priorityLevel !== PriorityLevels.required || useFlexibleDueDate ? undefined : priority?.daysToComplete;

export const getDaysToComplete = (useFlexibleDueDate: boolean, priority?: PriorityLevel) => 
  priority?.priorityLevel === PriorityLevels.required && useFlexibleDueDate && !priority?.isFixedDueDate ? priority?.daysToComplete : undefined;

export const getFixedDueDate = (useFlexibleDueDate: boolean, priority?: PriorityLevel) => 
  priority?.priorityLevel === PriorityLevels.required && useFlexibleDueDate && priority?.isFixedDueDate ? priority?.fixedDueDate : undefined;

export const selectedStateInitial = {
  [ContentType.Assessment]: { ids: [], undeletableIds: [], inherited: new Set<number>() },
  [ContentType.Event]: { ids: [], undeletableIds: [], inherited: new Set<number>() },
  [ContentType.Flow]: { ids: [], undeletableIds: [], inherited: new Set<number>() },
  [ContentType.Survey]: { ids: [], undeletableIds: [], inherited: new Set<number>() },
  [ContentType.Video]: { ids: [], undeletableIds: [], inherited: new Set<number>() },
  [ContentType.Pdf]: { ids: [], undeletableIds: [], inherited: new Set<number>() },
};
