import { Table } from "semantic-ui-react";
import ColumnOptions from "../../../../../interfaces/columnOptions";
import { TextAlign } from "../../../../../enums/textAlign";
import { LicenseConfirmationItem } from "../../../../../interfaces";
import utils from "../../../../../utils/miscellaneousUtils";

import "./licenseConfirmationBlock.scss";

export interface LicenseConfirmationBlockProps {
  items: LicenseConfirmationItem[];
}

export const LicenseConfirmationBlock: React.FC<LicenseConfirmationBlockProps> = ({ items }) => {
  const columnOptions: ColumnOptions[] = [
    {
      name: "Packs",
      width: 7,
    },
    {
      name: "Issuing",
      width: 3,
      textAlign: TextAlign.Center,
    },
    {
      name: "Purchasing",
      width: 2,
      textAlign: TextAlign.Center,
    },
    {
      name: "Used",
      width: 2,
      textAlign: TextAlign.Center,
    },
  ];

  const buildTableHeader = () => {
    return (
      <Table.Header>
        <Table.Row key={1}>
          {columnOptions.map((c: ColumnOptions) => {
            return (
              <Table.HeaderCell width={c.width} key={c.name} textAlign={c.textAlign}>
                {c.name}
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      </Table.Header>
    );
  };

  const buildTableRow = (i: LicenseConfirmationItem) => {
    return (
      <>
        <Table.Cell
          width={7}
          content={
            <span className="name-cell">{`${i.title}${i.isAccountPackLicenseExpired ? " (Expired)" : ""}`}</span>
          }
        />
        <Table.Cell width={3} textAlign="center" content={i.issuingLicenses} />
        <Table.Cell width={2} textAlign="center" content={i.purchasingLicenses} />
        <Table.Cell
          width={2}
          textAlign="center"
          content={`${i.totalUsedLicenses}/${utils.unlimitedNumber(i.totalLicenses)}`}
        />
      </>
    );
  };

  const buildTableTotal = (issued: number, purchased: number) => {
    return (
      <Table.Row className="total-amount-row">
        <Table.Cell width={7} content="Total" />
        <Table.Cell width={3} textAlign="center" content={issued} />
        <Table.Cell width={2} textAlign="center" content={purchased} />
        <Table.Cell width={2} textAlign="center" />
      </Table.Row>
    );
  };

  const buildTableBody = () => {
    return items.map((item) => {
      return (
        <Table.Row
          key={item.packId}
          data-testid="table-row"
          className={item.purchasingLicenses > 0 || item.isAccountPackLicenseExpired ? "row-error" : ""}
        >
          {buildTableRow(item)}
        </Table.Row>
      );
    });
  };

  const issuingTotal = items.reduce((a, b) => a + b.issuingLicenses, 0);
  const purchasedTotal = items.reduce((a, b) => a + b.purchasingLicenses, 0);

  return (
    <div className="confirmation-block">
      <Table compact={true}>
        {buildTableHeader()}
        <Table.Body className="table-body">
          {buildTableBody()}
          {buildTableTotal(issuingTotal, purchasedTotal)}
        </Table.Body>
      </Table>
    </div>
  );
};
