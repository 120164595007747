import React from "react";
import {
  FlowDesignerAssessmentAssetCard,
  FlowDesignerPdfAssetCard,
  FlowDesignerSurveyAssetCard,
  FlowDesignerVideoAssetCard,
} from "../../../../../../components/cards";
import AssetTypes from "../../../../../../enums/assetTypes";
import { AssetToDropType } from "../../../../../../interfaces/assetToDropInfo";
import { Ref } from "semantic-ui-react";
import { useDraggableAssetCard } from "../../../../../../hooks/useDraggableAssetCard";

function DraggableAssetCard(props: AssetToDropType) {
  const [dragRef, isSelected, { handleClick }] = useDraggableAssetCard({
    type: props.dragNDropItemType,
    item: {
      id: props.id,
      type: props.dragNDropItemType,
      title: props.title,
      elementType: props.cardType,
      durationInSeconds: props.cardType === AssetTypes.Video ? props.durationInSeconds : 0,
      thumbnailUrl: props.cardType === AssetTypes.Video || props.cardType === AssetTypes.Pdf ? props.thumbnailUrl : "",
      questionsCount:
        props.cardType === AssetTypes.Assessment || props.cardType === AssetTypes.Survey ? props.questionsCount : "",
      branchingQuestion: props.cardType === AssetTypes.Survey ? props.branchingQuestion : "",
      publisher: props.publisher,
    },
  });

  if (props.cardType === AssetTypes.Video) {
    return (
      <Ref innerRef={dragRef}>
        <FlowDesignerVideoAssetCard
          id={props.id}
          title={props.title}
          thumbnailUrl={props.thumbnailUrl || ""}
          durationInSeconds={props.durationInSeconds}
          className="asset-draggable-item"
          isSelected={isSelected}
          onClick={handleClick}
        />
      </Ref>
    );
  }

  if (props.cardType === AssetTypes.Pdf) {
    return (
      <Ref innerRef={dragRef}>
        <FlowDesignerPdfAssetCard
          id={props.id}
          title={props.title}
          thumbnailUrl={props.thumbnailUrl || ""}
          className="asset-draggable-item"
          isSelected={isSelected}
          onClick={handleClick}
        />
      </Ref>
    );
  }

  if (props.cardType === AssetTypes.Assessment) {
    return (
      <Ref innerRef={dragRef}>
        <FlowDesignerAssessmentAssetCard
          id={props.id}
          title={props.title}
          className="asset-draggable-item"
          isSelected={isSelected}
          onClick={handleClick}
        />
      </Ref>
    );
  }
  if (props.cardType === AssetTypes.Survey) {
    return (
      <Ref innerRef={dragRef}>
        <FlowDesignerSurveyAssetCard
          id={props.id}
          title={props.title}
          branchingQuestion={props.branchingQuestion}
          className="asset-draggable-item"
          isSelected={isSelected}
          onClick={handleClick}
        />
      </Ref>
    );
  }
  return null;
}

export default DraggableAssetCard;
