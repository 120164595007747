import React, { FC } from "react";

import { Filters } from "../../../utils/queryUtils";
import { FilterFormBuilder } from "../FilterFormBuilder/FilterFormBuilder";
import { getEmailFilters } from "./emailFilters";

export interface EmailFilterFormForPacksProps {
  filterOptions: Filters;
  updateFilter: (filter: Filters) => void;
  filter: Filters;
}
export const EmailFilterFormForPacks: FC<EmailFilterFormForPacksProps> = ({ filterOptions, filter, updateFilter }) => (
  <FilterFormBuilder
    filterOptions={filterOptions}
    filter={filter}
    updateFilter={updateFilter}
    items={getEmailFilters(false)}
  />
);
