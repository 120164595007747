import { FormikProps, withFormik } from "formik";
import { connect, ConnectedProps } from "react-redux";
import TriggerableConfirmationModal from "../../../../../../components/triggeredConfirmationModal/TriggerableConfirmationModal";
import ModalSizes from "../../../../../../enums/modalSizes";
import { IObservable } from "../../../../../../interfaces/IObservable";
import { bindAction } from "../../../../../../interfaces/redux";
import { reminderSchema } from "../../../../../../utils/validationSchemas/eventValidationSchemas";
import { AppDispatch, RootState } from "../../../../../Application/globaltypes/redux";
import { saveReminder } from "../../../state/thunks/remindersThunk";
import { ReminderBase } from "../../../types/models";
import { OmitFormik } from "../../../../../../types";
import CreateReminderForm from "./CreateReminderForm";

export interface CreateReminderPopupProps extends FormikProps<ReminderBase>, PropsFromRedux {
  onTriggerModalObserver: IObservable<(onConfirm: () => void) => void>;
}

export const CreateReminderPopup = (props: CreateReminderPopupProps) => {
  const { onTriggerModalObserver, resetForm, validateForm, isValid, save, values, id } = props;
  const handleOnConfirmed = () => {
    save(values, id);
    resetForm();
  };

  return (
    <TriggerableConfirmationModal
      onTriggerModalObserver={onTriggerModalObserver}
      title={"Add Reminder"}
      content={<CreateReminderForm {...props} />}
      confirmButtonLabel={"Save"}
      onTrigger={validateForm}
      onConfirmed={handleOnConfirmed}
      onClose={resetForm}
      scrolling={false}
      disabled={!isValid}
      size={ModalSizes.tiny}
    />
  );
};

const component = withFormik<CreateReminderPopupProps, ReminderBase>({
  validationSchema: reminderSchema,
  enableReinitialize: true,
  mapPropsToValues: () => {
    return {} as ReminderBase;
  },
  validateOnMount: true,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(CreateReminderPopup) as React.ComponentType<OmitFormik<CreateReminderPopupProps, ReminderBase>>;

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    id: state.library.events.eventEntityState.entityId,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  save: bindAction(saveReminder, dispatch),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(component);
