import { Table } from "semantic-ui-react";
import { NoResults } from "../../../../../components";
import { ListViewBase } from "../../../../../components/listView";
import { ViewType } from "../../../../../enums";
import { ColumnOptions } from "../../../../../interfaces";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { ContractSignature } from "../../../types";
import { ReactComponent as NoSubscriptionResult } from "../../../../../images/no-subscription.svg";

export interface SubscriptionAgreementListProps {
  isLoading: boolean;
  contractSignatures: ContractSignature[];
}

const columnOptions: ColumnOptions[] = [
  {
    name: "Signed by",
    width: 7,
  },
  {
    name: "Date",
    width: 7,
  },
  {
    name: "",
    width: 2,
  },
];

export default function SubscriptionAgreementList(props: SubscriptionAgreementListProps) {
  const { contractSignatures, isLoading } = props;

  const buildTableBody = (signature: ContractSignature) => (
    <>
      <Table.Cell width={columnOptions[0].width}>{signature.signer ?? "User is deleted"}</Table.Cell>
      <Table.Cell width={columnOptions[1].width}>{dateTimeUtils.formatDateWithHour(signature.date!)}</Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        {signature.contracts.map((contract) => (
          <div className="contract" key={contract.id}>
            {signature.contracts.length !== 1 && <label>{contract.name}</label>}
            <a href={contract.downloadEndpoint} target="_blank" rel="noreferrer noopener">
              <i className="download icon" />
            </a>
          </div>
        ))}
      </Table.Cell>
    </>
  );

  return (
    <ListViewBase
      viewType={ViewType.LIST}
      columnOptions={columnOptions}
      buildTableBody={buildTableBody}
      items={contractSignatures}
      itemsAmount={contractSignatures.length}
      noResultsContent={<NoResults icon={<NoSubscriptionResult />} title="No Contract Required" />}
      isLoading={isLoading}
      withSelection={false}
      withFooter={false}
    />
  );
}
