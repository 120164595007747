import { FormikProps, setIn, yupToFormErrors } from "formik";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { ObjectSchema } from "yup";
import { useLocalContext } from "../../../../hooks/useLocalContext";
import { ValidationContext } from "../models";

export type WithSelfValidationProps<T> = FormikProps<T> & {
  render: (props: ValidationContext<T>) => React.ReactElement;
  validationSchema: ObjectSchema<any>;
};

export const WithSelfValidation = <T,>(props: WithSelfValidationProps<T>) => {
  const { render, validationSchema, setFieldError, setErrors, errors, values, validateOnMount } = props;

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const context = useLocalContext({ errors, values });

  const runSingleLevelValidation = (name: string, value: any) =>
    runValidation(name, name, value, setFieldError, (error) => error.message);

  const runMultiLevelValidation = (path: string, name: string, value: any) =>
    runValidation(
      path,
      name,
      value,
      (key, error) => {
        const resultErrors = _.cloneDeep(context.current.errors);
        setErrors(_.set(resultErrors, key, error));
      },
      (error) => _.get(yupToFormErrors(error), path),
    );

  const runValidation = async <TError,>(
    path: string,
    name: string,
    value: any,
    setErrorsCallback: (key: string, error: TError | undefined) => void,
    errorProvider: (error: any) => TError | undefined,
  ) => {
    // Skips validation if form validateOnMount is true
    if (validateOnMount && !mounted.current) return;

    try {
      await validationSchema.validateAt(path, setIn(context.current.values, name, value), { abortEarly: false });

      setErrorsCallback(path, undefined);
    } catch (error: any) {
      setErrorsCallback(path, errorProvider(error));
    }
  };

  return render({ ...props, runMultiLevelValidation, runSingleLevelValidation });
};
