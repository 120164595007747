import { AppDispatch } from "../../../../../Application/globaltypes/redux";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/flowDesignerConnectedFlowsSlice";
import flowsDataService from "../../../services/flowsDataService";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const fetchConnectedFlows = (flowId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(fetchBegin());
    const result = await flowsDataService.getConnectedFlows(flowId);
    dispatch(
      fetchSuccess({
        items: result.data,
        totalCount: Number.parseInt(result.headers[countHeaderName]),
      }),
    );
  } catch (e) {
    dispatch(fetchFailure(e));
  }
};
