import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReducerNamespaceTypes, ReducerEntityPrefixTypes } from "enums";
import DataService from "features/Application/services/dataServices/typedDataService";
import { getPrefix } from "features/Application/slices/models";
import { AvailableTemplate } from "../../CreateAccount/types";
import type { AppThunk, RootState } from "features/Application/globaltypes/redux";
import { FetchSuccessPayload } from "features/Application/slices/createFetchingItemsSlice";
import { escapeTerm } from "utils/searchUtils";
import { formatFilters } from "utils/filterMapUtils";
import { FilterType } from "utils/filterUtils";
import { GetAvailableTemplatesRequest } from "features/People/GroupTemplate/DataService/types";

export const fetchAvailableTemplates = createAsyncThunk<
  FetchSuccessPayload<AvailableTemplate>,
  GetAvailableTemplatesRequest
>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Accounts,
    entity: ReducerEntityPrefixTypes.AccountCreation,
    name: "templates",
  }),
  async (req: GetAvailableTemplatesRequest, { getState }) => {
    const filtersState = (getState() as RootState).accounts.availableTemplates.filters;
    const result = await DataService.groupTemplates.getAvailableTemplates({
      ...req,
      term: escapeTerm(filtersState.search),
      ...formatFilters(filtersState.appliedFilter, {
        dateCreated: FilterType.DateRange,
      }),
    });

    return { items: result.items, totalCount: result.count };
  },
);

export const addTemplatesThunk =
  (accountId: number, templates: number[]): AppThunk =>
  async () => {
    if (templates.length === 0) return;
    try {
      await DataService.accounts.addTemplates(accountId, templates);
    } catch {}
  };
