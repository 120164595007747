import React from "react";
import PropTypes from "prop-types";

import { SectionHeader, DetailsHeaderTitle } from "../sectionHeader";
import { CloseButton } from "../buttons/closeButton";
import { publishedStatusPropType } from "../common/publishedStatus";

import "./wizardHeader.scss";

function WizardHeader(props) {
  const { title, titleForGA, publishedStatus, onClose, renderCustomHeader, changeActiveIndex } = props;
  return (
    <SectionHeader
      header={<DetailsHeaderTitle title={title} titleForGA={titleForGA} />}
      publishedStatus={publishedStatus}
      className="wizard-header"
    >
      <div className="custom-content-wrapper">
        {renderCustomHeader?.(changeActiveIndex)}
        <CloseButton id="close-wizard" testId="close-wizard" onClick={onClose} />
      </div>
    </SectionHeader>
  );
}

WizardHeader.propTypes = {
  title: PropTypes.string,
  titleForGA: PropTypes.string,
  onClose: PropTypes.func,
  onErrorButtonClick: PropTypes.func,
  progressSavedDate: PropTypes.instanceOf(Date),
  publishedStatus: publishedStatusPropType,
  changeActiveIndex: PropTypes.func,
  renderCustomHeader: PropTypes.func,
};

export default WizardHeader;
