import React from "react";
import { Icon } from "semantic-ui-react";
import ErrorButton from "../buttons/errorButton/ErrorButton";
import "./publishValidationInfo.scss";

export interface Props {
  isVisible: boolean;
  errorLabel?: string;
  errorMessage?: string;
  onErrorButtonClick: () => void;
}

function UnableToPublish({ onErrorButtonClick, errorLabel, errorMessage, isVisible = false }: Props) {
  return (
    <>
      {isVisible && (
        <div className="publish-validation-info-error-container">
          <div className="publish-validation-info-error-icon">
            <Icon className="exclamation triangle outline error-icon" size="big" color="red" />
          </div>
          <div>
            <div className="publish-validation-info-header">Unable to publish!</div>
            {errorMessage ?? "Fix errors before publishing"}
          </div>
        </div>
      )}
      {isVisible && onErrorButtonClick && (
        <ErrorButton label={errorLabel ?? "Fix Errors"} data-testid="fix-errors-button" onClick={onErrorButtonClick} />
      )}
    </>
  );
}

export default UnableToPublish;
