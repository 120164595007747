import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

import { buildRootEntityActionType } from "../../../../Application/actions/actionsBuilder";
import * as fetchingItemsActionTypes from "../../../../Application/actions/fetchingItems/fetchingItemsActionTypes";
import * as filteringItemsActionTypes from "../../../../Application/actions/filteringItems/filteringItemsActionTypes";

const groupActionTypeBuilder = (type: string) =>
  buildRootEntityActionType(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Group_Assignments, type);

export const LIBRARY_GROUP_ASSIGNMENTS_FETCH_GROUPS_BEGIN = groupActionTypeBuilder(
  fetchingItemsActionTypes.FETCH_ITEMS_BEGIN,
);
export const LIBRARY_GROUP_ASSIGNMENTS_FETCH_GROUPS_SUCCESS = groupActionTypeBuilder(
  fetchingItemsActionTypes.FETCH_ITEMS_SUCCESS,
);
export const LIBRARY_GROUP_ASSIGNMENTS_FETCH_GROUPS_FAILURE = groupActionTypeBuilder(
  fetchingItemsActionTypes.FETCH_ITEMS_FAILURE,
);
export const LIBRARY_GROUP_ASSIGNMENTS_SET_ITEMS_FILTER = groupActionTypeBuilder(
  filteringItemsActionTypes.SET_ITEMS_FILTER,
);
export const LIBRARY_GROUP_ASSIGNMENTS_RESET_ITEMS_FILTER = groupActionTypeBuilder(
  filteringItemsActionTypes.RESET_ITEMS_FILTER,
);
