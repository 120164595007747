import { isEqual } from "lodash";
import { useState, useCallback, useEffect, useRef } from "react";

import { INotifiable, NotifyStepConfigPayload, NotifyConfigEntry } from "../types";
import { getUpdatedNoficationOptionsClone } from "utils/notificationsUtils";

const useNotifyConfig = <T>(initialValue: INotifiable<T>): [T, NotifyStepConfigPayload<T>] => {
  const [config, setConfig] = useState(initialValue);
  const initialValueRef = useRef(initialValue);

  useEffect(() => {
    if (!isEqual(initialValueRef.current, initialValue)) {
      setConfig(initialValue);
      initialValueRef.current = initialValue;
    }
  }, [initialValue]);

  const setNotifyConfig = useCallback((notifyConfigEntry: NotifyConfigEntry<T>) => {
    setConfig((prevConfig) => {
      return getUpdatedNoficationOptionsClone(prevConfig, notifyConfigEntry);
    });
  }, []);

  const resetNotifyConfig = useCallback(() => {
    setConfig(initialValue);
  }, [initialValue]);

  return [
    config,
    {
      setNotifyConfig,
      resetNotifyConfig,
      shouldNotify: config.getShouldNotify(config),
      communicationChannel: config.getCommunicationChannel(config),
      notifyTypes: config.notifyTypes?.(config),
    },
  ];
};

export default useNotifyConfig;
