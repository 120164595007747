import { FormikProps } from "formik";
import React, { Component } from "react";

import { ValidatedForm } from "../../../../../../components/forms";
import { LandingPageFormProps } from "../types/index";
import { ThreatDefenceLandingPageView } from "../../types/state";

export type LandingPageFormPropsAll = LandingPageFormProps & FormikProps<ThreatDefenceLandingPageView>;

export default class LandingPageForm extends Component<LandingPageFormPropsAll> {
  render() {
    const { dirty, isLoading, disablePreventTransitionPrompt } = this.props;
    return (
      <ValidatedForm
        dirty={dirty}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message:
            "Are you sure you want to exit without saving this landing page? All information entered will be lost.",
        }}
        isLoading={isLoading}
        isSubmitting={isLoading}
        formWidthComputer={6}
        isInitialValid={false}
        disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      >
        {this.renderFields}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, onBlur, disabled, onIsValidChange } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    return (
      <div className="landing-page-form-container">
        <ValidatedForm.InputField
          label="Name"
          value={values.name}
          propertyName="name"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Software Application"
          propertyName="softwareApplications"
          className="software-applications"
          value={values.softwareApplications.selected}
          options={values.softwareApplications.items}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Labels"
          propertyName="labels"
          value={values.labels.selected}
          options={values.labels.items}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const { errors, touched, isValid, dirty, handleChange, handleBlur, setFieldValue, setFieldTouched, resetForm } =
      this.props;

    return {
      errors,
      touched,
      isFormValid: isValid,
      dirty,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      resetForm,
    };
  };
}
