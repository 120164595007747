import { BaseEditor, Descendant, Editor, Element as SlateElement, Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { BlockFormats, MarkFormats } from "./types";
import { jsx } from "slate-hyperscript";

const listTypes = ["numbered-list", "bulleted-list"];

const elementTags: any = {
  H1: () => ({ type: "heading-one" }),
  H2: () => ({ type: "heading-two" }),
  LI: () => ({ type: "list-item" }),
  P: () => ({ type: "paragraph" }),
  UL: () => ({ type: "bulleted-list" }),
};

const textTags: any = {
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  B: () => ({ bold: true }),
};

export const convertToDescendant = (value?: string): Descendant[] => {
  if (value && value.startsWith("<")) {
    const document = convertToHtmlDocument(value);
    return deserialize(document.body);
  }

  if (value) {
    return JSON.parse(value);
  }

  return [
    {
      type: BlockFormats.Paragraph,
      children: [{ text: "" }],
    },
  ];
};

const isBlockActive = (editor: BaseEditor & ReactEditor, format: BlockFormats): boolean => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    }),
  );

  return !!match;
};

const toggleBlock = (editor: BaseEditor & ReactEditor, format: BlockFormats) => {
  const isActive = isBlockActive(editor, format);
  const isList = listTypes.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && listTypes.includes(n.type),
    split: true,
  });

  const getNewProperties = (): Partial<SlateElement> => {
    if (isActive) {
      return {
        type: BlockFormats.Paragraph,
      };
    }

    return {
      type: isList ? BlockFormats.ListItem : format,
    };
  };

  Transforms.setNodes<SlateElement>(editor, getNewProperties());

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const convertToHtmlDocument = (value: string): Document => {
  return new DOMParser().parseFromString(value, "text/html");
};

export const deserialize = (el: HTMLElement | Node): any => {
  const { nodeName, nodeType } = el;
  if (nodeType === Node.TEXT_NODE) {
    return el.textContent;
  } else if (nodeType !== Node.ELEMENT_NODE) {
    return jsx("element", { type: BlockFormats.Paragraph }, [{ text: "" }]);
  }
  let children = Array.from(el.childNodes).map(deserialize).flat();
  if (!children.length) {
    children = [{ text: "" }];
  }
  if (nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }
  if (elementTags[nodeName]) {
    const attrs = elementTags[nodeName](el);
    return jsx("element", attrs, children);
  }
  if (textTags[nodeName]) {
    const attrs = textTags[nodeName](el);
    return children.map((child) => jsx("text", attrs, child));
  }
  return children;
};

const isMarkActive = (editor: BaseEditor & ReactEditor, format: MarkFormats) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor: BaseEditor & ReactEditor, format: MarkFormats) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const utils = {
  isBlockActive,
  toggleBlock,
  isMarkActive,
  toggleMark,
  deserialize,
  convertToHtmlDocument,
  convertToDescendant,
};

export default utils;
