import cn from "classnames";
import { FC } from "react";
import EllipsisPopupButton from "../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";
import { RemoveLinkButton } from "../../../../../components/buttons/linkButtons";

export interface Props {
  id: number;
  disabled: boolean;
  hasLicensingPermissions: boolean;
  onRemoveLicenseClick: (id: number) => void;
}

export const PeoplePackMenu: FC<Props> = (props: Props) => {
  const { id, disabled, hasLicensingPermissions, onRemoveLicenseClick } = props;

  const removeLicenseButton = (
    <RemoveLinkButton
      key={cn("RemoveLicenseButton-", id)}
      onClick={() => {
        onRemoveLicenseClick(id);
      }}
    />
  );

  if (hasLicensingPermissions) {
    return (
      <EllipsisPopupButton disabled={disabled} circle>
        {[removeLicenseButton]}
      </EllipsisPopupButton>
    );
  }

  return (
    <div data-testid="disabledPeopleCardEllipsis" onClick={(e) => e.stopPropagation()}>
      <EllipsisPopupButton disabled={true} circle>
        {[]}
      </EllipsisPopupButton>
    </div>
  );
};
