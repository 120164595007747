import qs from "qs";
import { isEmpty, isString } from "lodash";
import moment from "moment";
import { PageOfItems } from "../interfaces";
import { GetPagedDataByIdRequest, GetPagedDataByIdRequestV2 } from "../interfaces/getPagedDataRequest";

const ampersandSeparator = "&";

export type Filters = { [key: string]: any };

export const getArrayFilters = (filters: Filters) =>
  Object.fromEntries(Object.entries(filters).filter(([_, value]) => value && value.length > 0));

export const buildRangeQuery = (filters: Filters) => {
  return Object.assign(
    {},
    ...Object.keys(filters)
      .filter((key) => {
        let property = filters[key];
        return property.from || property.to;
      })
      .map((key) => {
        let property = filters[key];
        return {
          [`${[key]}.from`]: property.from || null,
          [`${[key]}.to`]: property.to || null,
        };
      }),
  );
};

export const buildQueryFromArray = (key: string, array: any[]) => {
  let result = "";
  array.forEach((item) => {
    if (result) {
      result += ampersandSeparator;
    }
    result += `${key}=${item}`;
  });
  return result;
};

export const serializeArrayAndFilterNullable = <T>(params: T) =>
  qs.stringify(params, {
    indices: false,
    filter: (_, value) => {
      if (isString(value)) {
        return value.length > 0 ? value : undefined;
      }
      return value ?? undefined;
    },
    format: "RFC1738",
  });

/**
 * Used to remove sortBy if sortOrder is specified but not the other
 * @param params
 * @returns
 */
export const filterSortParams = <T extends object>(params: T) => {
  const dup = { ...params };
  const sortByEmpty = !("sortBy" in dup) || !dup.sortBy;
  if ("sortOrder" in dup && sortByEmpty) {
    delete dup.sortOrder;
  }
  return dup;
};

/**
 * Combination of potentially removing sort params, and serialize/filtering array and nullable
 * @param params
 * @returns
 */
export const overviewFiltering = <T extends object>(params: T) => {
  return serializeArrayAndFilterNullable(filterSortParams(params));
};

const INPUT_DATE_FORMAT = "MM/DD/YYYY";
const SERVER_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const formatDateForServer = (date: string, range: "from" | "to"): string => {
  if (range === "from") {
    return moment(date, INPUT_DATE_FORMAT).startOf("day").format(SERVER_DATE_FORMAT);
  }
  return moment(date, INPUT_DATE_FORMAT).endOf("day").format(SERVER_DATE_FORMAT);
};

export const formatFiltersV2api = (filters: Filters): Filters => {
  let queryParams: Filters = {};
  if (!isEmpty(filters)) {
    queryParams = getArrayFilters(filters);
    if (filters.dateCreated?.from) {
      queryParams.dateCreatedFrom = formatDateForServer(filters.dateCreated.from, "from");
    }
    if (filters.dateCreated?.to) {
      queryParams.dateCreatedTo = formatDateForServer(filters.dateCreated.to, "to");
    }
    if (filters.dateModified?.from) {
      queryParams.dateModifiedFrom = formatDateForServer(filters.dateModified.from, "from");
    }
    if (filters.dateModified?.to) {
      queryParams.dateModifiedTo = formatDateForServer(filters.dateModified.to, "to");
    }
    if (filters.dateLogin?.from) {
      queryParams.dateLoginFrom = formatDateForServer(filters.dateLogin.from, "from");
    }
    if (filters.dateLogin?.to) {
      queryParams.dateLoginTo = formatDateForServer(filters.dateLogin.to, "to");
    }
  }
  return queryParams;
};

export const mapToV2Filters = (filters: Filters, v2FilterMap: { [key: string]: string }): Filters => {
  let v2Filters: Filters = {};
  for (let key in filters) {
    let propertyName;
    if (v2FilterMap.hasOwnProperty(key)) {
      propertyName = (v2FilterMap as { [key: string]: string })[key];
    } else {
      propertyName = key;
    }
    v2Filters[propertyName] = filters[key];
  }
  return v2Filters;
};

export const getPagedDataOptimisedFactory = <T>(
  dbEndpoint: (request: GetPagedDataByIdRequest) => Promise<PageOfItems<T>>,
  searchIndexEndpoint: (request: GetPagedDataByIdRequestV2) => Promise<PageOfItems<T>>,
) => {
  return (request: GetPagedDataByIdRequest | GetPagedDataByIdRequestV2) => {
    const searchPage = isEmpty((request as GetPagedDataByIdRequestV2)?.term);
    return searchPage
      ? dbEndpoint(request as GetPagedDataByIdRequest)
      : searchIndexEndpoint(request as GetPagedDataByIdRequestV2);
  };
};
