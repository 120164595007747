import { Dispatch } from "@reduxjs/toolkit";

import {
  startFetchLicensingInfo,
  fetchLicensingInfoSuccess,
  fetchLicensingInfoFailure,
} from "../slices/flowLicensingSlice";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { AddedAsset, FlowChanges } from "../../types/flowLicensing";

export const fetchLicensingInfo =
  (flowId: number, onSuccess: (flowChanged: boolean, addedAssets: AddedAsset[]) => void) =>
  async (dispatch: Dispatch) => {
    dispatch(startFetchLicensingInfo());

    try {
      const flowChangesResponse = await dataService.flows.getFlowChanges(flowId);
      const flowChanges: FlowChanges = {
        isDefinitionChanged: flowChangesResponse.data.isDefinitionChanged,
      };

      const addedAssets = flowChangesResponse.data.addedAssets.map((a: { entityId: string; entityType: string }) => {
        return { id: a.entityId, type: a.entityType };
      });

      dispatch(fetchLicensingInfoSuccess(flowChanges));
      onSuccess(flowChanges.isDefinitionChanged, addedAssets);
    } catch (error) {
      dispatch(fetchLicensingInfoFailure(error as Error));
    }
  };
