import EllipsisPopupButton from "../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { EditLinkButton } from "../buttons/linkButtons";

export interface IPackEllipsisPopupButtonProps {
  onEditClicked: () => void;
  circle: boolean;
  outlinedEllipsis: boolean;
  disabled?: boolean;
}

function PackEllipsisPopupButton(props: IPackEllipsisPopupButtonProps) {
  const { onEditClicked, ...ellipsisProps } = props;

  return (
    <EllipsisPopupButton {...ellipsisProps}>
      <EditLinkButton onClick={onEditClicked} />
    </EllipsisPopupButton>
  );
}

export default PackEllipsisPopupButton;
