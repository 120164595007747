import { EventFilterPropertyNames } from "../../../features/Library/Events/types/state";
import { FilterItemBase, FilterViewType } from "../FilterFormBuilder/FilterFormBuilder";

export const getEventFilters = (includePublisher: boolean = true): FilterItemBase<EventFilterPropertyNames>[] => {
  let filters: FilterItemBase<EventFilterPropertyNames>[] = [
    {
      type: FilterViewType.DateRange,
      label: "Date Added",
      propertyName: EventFilterPropertyNames.DateCreated,
    },
    {
      type: FilterViewType.DateRange,
      label: "Last Modified",
      propertyName: EventFilterPropertyNames.DateModified,
    },
  ];

  if (includePublisher) {
    filters.push({
      type: FilterViewType.MultiSelect,
      label: "Publisher",
      propertyName: EventFilterPropertyNames.Publishers,
      placeholder: "All Publishers",
    });
  }

  filters.push(
    {
      type: FilterViewType.MultiSelect,
      label: "Packs",
      propertyName: EventFilterPropertyNames.Packs,
      placeholder: "All Packs",
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Software Applications",
      propertyName: EventFilterPropertyNames.SoftwareApplications,
      placeholder: "All Applications",
    },
    {
      type: FilterViewType.MultiSelect,
      label: "Labels",
      propertyName: EventFilterPropertyNames.Labels,
      placeholder: "All Labels",
    },
  );

  return filters;
};
