import { Icon } from "semantic-ui-react";
import { Tooltip } from "../../common/tooltip";
import ObjectUtils from "../../../utils/objectUtils";
import React from "react";
const properNouns = ["flow", "flows", "pack", "packs"];
export interface DeletionRestrictedTooltipProps {
  entityType: string;
  dependencies: { [key: string]: number };
}

const getContent = (dependencies: { [key: string]: number }) => {
  const values = ObjectUtils.typedEntries<string, number>(dependencies)
    .filter(([_, amount]) => amount > 0)
    .map(([key, _]) => key);

  let newValues = values.map((item) => {
    if (properNouns.includes(item)) {
      item = `${item[0].toUpperCase()}${item.slice(1).toLowerCase()}`;
    }
    return item;
  });
  return newValues ? newValues.join(", ") : "";
};

const getStyledContent = (content: string) => {
  return <span style={{ fontWeight: "bold" }}>{content}</span>;
};

export const DeletionRestrictedTooltip = (props: DeletionRestrictedTooltipProps) => {
  const { entityType, dependencies } = props;
  const content = getContent(dependencies);
  let newEntityType;
  if (properNouns.includes(entityType)) {
    newEntityType = `${entityType[0].toUpperCase()}${entityType.slice(1).toLowerCase()}`;
  } else {
    newEntityType = entityType;
  }
  return (
    <Tooltip
      showAlways={!!content}
      inverted
      hideOnScroll
      position="bottom left"
      target={<Icon data-testid="delete-tooltip-icon" link className="fa fa-exclamation-circle" size="big" />}
      content={
        content && (
          <>
            This {newEntityType} has dependencies and must be removed from associated {getStyledContent(content)} before
            it can be deleted.
          </>
        )
      }
    />
  );
};

export default React.memo(DeletionRestrictedTooltip);
