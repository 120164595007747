import { Table } from "semantic-ui-react";
import { ColumnOptions } from "../../../../../../interfaces";
import { IntegrationTypes, IntegrationWithCompositeId } from "../../../types";
import SortDirections from "../../../../../../enums/sortDirections";
import dateTimeUtils from "../../../../../../utils/dateTimeUtils";
import Title from "../../../../../../components/listViewTemplates/title/Title";
import StatusCell, { Status } from "../../../../../../components/statusCell";
import { IntegrationDtoMap } from "features/Accounts/Integrations/Shared";
import { useNavigate } from "react-router-dom";

interface IntegrationColumnOption extends ColumnOptions {
  propName: keyof IntegrationWithCompositeId | null;
}

export const defaultSorting = Object.freeze({
  columnName: "last sync date",
  direction: SortDirections.Desc,
});

export const columnOptions: IntegrationColumnOption[] = [
  {
    name: "Integration",
    width: 3,
    isSortable: true,
    propName: "type",
  },
  {
    name: "Last Sync Date",
    width: 3,
    isSortable: true,
    propName: "lastSyncDate",
  },
  {
    name: "Status",
    width: 3,
    isSortable: true,
    propName: "status",
  },
  {
    name: "Details",
    width: 6,
    isSortable: true,
    propName: "details",
  },
  {
    name: "",
    width: 1,
    isSortable: false,
    propName: null,
  },
];

export const mapColumnName = (columnName: string) => {
  const loweredColumnName = columnName.toLowerCase();
  const columnOption = columnOptions.find((c) => c.name.toLowerCase() === loweredColumnName);
  if (!columnOption || !columnOption.propName) return columnName;

  return columnOption.propName;
};

export interface IntegrationRowProps {
  integration: IntegrationWithCompositeId;
  moboId?: string;
  ownAccount: boolean;
  renderOptionsMenu: (integration: IntegrationWithCompositeId) => React.ReactElement;
}

const convertTypeToName = (
  type: string,
  navigate: ReturnType<typeof useNavigate>,
  ownAccount: boolean,
  moboId?: string,
) => {
  const integrationDto = IntegrationDtoMap[type.toLowerCase() as IntegrationTypes];
  if (!integrationDto?.title) {
    return type;
  }

  return (
    <Title
      title={integrationDto.title}
      onTitleClick={() => navigate(integrationDto.url(ownAccount, moboId))}
      className="title"
    />
  );
};

export const IntegrationRow: React.FC<IntegrationRowProps> = ({
  integration,
  renderOptionsMenu,
  ownAccount,
  moboId,
}) => {
  const navigate = useNavigate();
  const emptyCellContent = "—";
  return (
    <>
      <Table.Cell width={columnOptions[0].width}>
        {convertTypeToName(integration.id.type, navigate, ownAccount, moboId)}
      </Table.Cell>
      <Table.Cell width={columnOptions[1].width}>
        {dateTimeUtils.formatWithValidation(integration.lastSyncDate, emptyCellContent)}
      </Table.Cell>
      <Table.Cell width={columnOptions[2].width}>
        <StatusCell status={integration.status.toUpperCase() as Status} />
      </Table.Cell>
      <Table.Cell width={columnOptions[3].width}>
        <Title title={integration.details} />
      </Table.Cell>
      <Table.Cell className="align-right" width={columnOptions[4].width}>
        {renderOptionsMenu(integration)}
      </Table.Cell>
    </>
  );
};

export default IntegrationRow;
