import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import PropTypes from "prop-types";
import React from "react";
import VideoPlayer from "../../../common/video/player/VideoPlayer";
import { getSupportedStream } from "../../../common/video/utils/streamingUtils";
import "./videoThumbnailGenerator.scss";

function VideoThumbnailGenerator(props) {
  const {
    isProcessing,
    videoTracks,
    thumbnails,
    handleGenerateThumbnail,
    selectedIndex,
    handleSelectThumbnail,
    textTracks,
  } = props;
  const streamingSettings = getSupportedStream(videoTracks);
  const isAwsEncodingEnabled = !!useFeatureFlag(FeatureFlags.AwsEncoding);

  return (
    <div className="video-frame-container">
      <div className="video-section">
        <VideoPlayer
          textTracks={textTracks}
          isProcessing={isProcessing || !streamingSettings}
          source={streamingSettings}
          onGenerateThumbnail={handleGenerateThumbnail}
          addGenerateThumbnailButton={!isAwsEncodingEnabled}
        />
      </div>
      <div className="image-section">
        {thumbnails.map((item, index) => {
          return (
            <div
              key={index}
              className={`image-container ${index === selectedIndex ? "selected" : null}`}
              onClick={() => handleSelectThumbnail(index)}
            >
              <img key={index} src={item.src} position={item.position} alt="thumbnail" />
              <div>{item.time}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

VideoThumbnailGenerator.propTypes = {
  isProcessing: PropTypes.bool,
  items: PropTypes.array,
  handleGenerateThumbnail: PropTypes.func,
  selectedIndex: PropTypes.number,
  handleSelectThumbnail: PropTypes.func,
  thumbnails: PropTypes.array,
  textTracks: PropTypes.array,
};

export default VideoThumbnailGenerator;
