import { Tooltip } from "components/common/tooltip";
import { Button } from "components/buttons/button/Button";
import { RestrictedByTooltip } from "components";

interface AddPacksButtonProps {
  disabled: boolean;
  hasPermission: boolean;
  onClick: () => void;
}

export const AddPacksButton = (props: AddPacksButtonProps) => {
  const { disabled, hasPermission, onClick } = props;
  return (
    <RestrictedByTooltip hasPermission={hasPermission}>
      <Tooltip
        target={
          <Button
            primary
            content={"Add Packs"}
            className="add-packs-button create-button"
            disabled={disabled || !hasPermission}
            onClick={onClick}
          />
        }
        content={"Packs can't be added to unpublished or purchased content."}
        position="left center"
        styles={{ width: "16rem" }}
        hideOnScroll
        showAlways={hasPermission && disabled}
      />
    </RestrictedByTooltip>
  );
};
