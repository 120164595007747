import { FC, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";

import { GroupsOverviewProps } from "../types";
import { FiltersMap } from "../../../../../utils/filterUtils";
import { RolePermissions, SortingDirection, ViewType } from "../../../../../enums";
import { default as groupListUtils, groupFilterParametersWithOwners } from "../../../../../utils/groupListUtils";
import { Group } from "../../../../../interfaces";
import { Filters } from "../../../../../utils/queryUtils";
import { DEFAULT_REQUEST_PARAMS, DEFAULT_SORTING_COLUMN_NAME } from "../helpers/constants";
import GroupsTableBody, { groupsColumnOptions } from "../../../../../components/people/overview/groupsTable";
import { SearchInput } from "../../../../../components";
import { GroupFilterForm } from "../../../../../components/filterForms";
import { ItemsView } from "../../../../../views";
import {
  fetchGroups as fetchGroupsAction,
  resetFilter as resetFilterAction,
  setFilter as setFilterAction,
} from "../../state/actions/groupAssignmentActions";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { fetchFilterOptions as fetchFilterOptionsAction } from "../../../../People/Groups/GroupsOverview/state/filterActions";

import sortDirections from "../../../../../enums/sortDirections";
import groupPermissionActions from "../../state/actions/groupPermissionActions";
import Restricted from "../../../../Application/Restricted";

export type GroupsOverviewPropsAll = GroupsOverviewProps & PropsFormRedux;

export const GroupsOverview: FC<GroupsOverviewPropsAll> = (props: GroupsOverviewPropsAll): React.ReactElement => {
  const { fetchFilterOptions, setFilter, resetFilter, fetchGroups, updateGroupsAssignment } = props;

  const getFilterOptionsMemo = useCallback(() => fetchFilterOptions(), [fetchFilterOptions]);
  const onSelectedListItemsChangedMemo = useCallback(
    (groupIds: number[]) => updateGroupsAssignment(groupIds),
    [updateGroupsAssignment],
  );
  const applyFilterMemo = useCallback((filter: Filters) => setFilter(filter), [setFilter]);
  const resetFilterMemo = useCallback(() => resetFilter(), [resetFilter]);

  const getGroups = (
    skip: number = DEFAULT_REQUEST_PARAMS.skip,
    top: number = DEFAULT_REQUEST_PARAMS.top,
    sortingColumnName: string = DEFAULT_REQUEST_PARAMS.sortingColumnName,
    sortingDirection: SortingDirection = DEFAULT_REQUEST_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    let orderParams = groupListUtils.formatOrderParams(sortingColumnName, sortingDirection);
    let filterParams = groupListUtils.formatFilterParams({ ...appliedFilter }, groupFilterParametersWithOwners);

    fetchGroups(skip, top, orderParams, filterParams);
  };

  const buildTableBody = (group: Group): React.ReactElement => <GroupsTableBody group={group} />;
  const renderFilterForm = (): React.ReactElement => <GroupFilterForm />;
  const renderSearchInput = (): React.ReactElement => <SearchInput disabled placeholder="Search for People..." />;

  const renderPeopleList = () => {
    const {
      customHeaderContent,
      isReadOnly,
      assignedPeopleIds,
      isLoading,
      groupsCount,
      groupsToBeAssigned,
      filterOptions,
      appliedFilter,
    } = props;
    return (
      <Restricted
        permissions={[RolePermissions.GroupsView]}
        renderContent={(hasAnyPermission: boolean) => (
          <ItemsView
            viewType={ViewType.LIST}
            columnOptions={groupsColumnOptions}
            getData={getGroups}
            itemsInlineCount={groupsCount}
            isLoading={isLoading}
            items={groupsToBeAssigned}
            buildTableBody={buildTableBody}
            customHeaderContent={customHeaderContent}
            renderSearch={renderSearchInput}
            sortingColumnName={DEFAULT_SORTING_COLUMN_NAME}
            sortingDirection={sortDirections.Desc}
            selectedIds={assignedPeopleIds}
            onSelectedListItemsChanged={onSelectedListItemsChangedMemo}
            filterOptionsLoading={filterOptions.isLoading}
            filterOptions={filterOptions}
            applyFilter={applyFilterMemo}
            resetFilter={resetFilterMemo}
            getFilterOptions={getFilterOptionsMemo}
            appliedFilter={appliedFilter}
            renderFilterForm={renderFilterForm}
            accessRestricted={!hasAnyPermission}
            isSelectDisabled={() => isReadOnly}
          />
        )}
      />
    );
  };

  return renderPeopleList();
};
const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  groupsToBeAssigned: state.library.groupAssignment.groupsToBeAssigned.items,
  groupsCount: state.library.groupAssignment.groupsToBeAssigned.itemsCount,
  isLoading: state.library.groupAssignment.groupsToBeAssigned.isLoading,
  filterOptions: state.people.groupsOverview.filterOptions,
  appliedFilter: state.library.groupAssignment.filter.appliedFilter,
});

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: GroupsOverviewProps) => ({
  fetchGroups: (skip: number, top: number, orderParams: string, filterParams: Filters) =>
    dispatch(fetchGroupsAction(skip, top, orderParams, filterParams)),
  fetchFilterOptions: () => dispatch(fetchFilterOptionsAction()),
  updateGroupsAssignment: (groupsId: number[]) => {
    dispatch(groupPermissionActions(ownProps.section).updateGroupsAssignment(groupsId));
  },
  setFilter: (filter: Filters) => dispatch(setFilterAction(filter)),
  resetFilter: () => dispatch(resetFilterAction()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFormRedux = ConnectedProps<typeof connector>;

export default connector(GroupsOverview);
