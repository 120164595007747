import CommunicationsNoSends from "features/Library/components/CommunicationsNoSends";
import { FC } from "react";

interface Props {
  messageId: number;
  isDraft: boolean;
}

export const MessagePerformance: FC<Props> = ({ isDraft }) => {
  return <CommunicationsNoSends isDraft={isDraft} communicationType="message" />;
};
