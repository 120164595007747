import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { DropdownProps, Table } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { isEmpty } from "lodash";
import { bindActionCreators } from "redux";

import { ThreatDefenceEmailTemplatesOverviewState, ThreatDefenceEmailTemplatesOverviewOwnProps } from "./types";
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { ViewType, SortOptions, PublishedStatusTypes, SortingDirection } from "../../../../../enums";
import { ItemsView } from "../../../../../views";
import ThreatDefenceEmailTemplatesNoResults from "../../../../../views/library/emailTemplates/Overview/ThreatDefenceEmailTemplatesNoResults";
import columnOptions from "./columnOptions";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import * as emailTemplatesOverviewActions from "../state/actions/threatDefenceEmailTemplatesOverviewActions";
import * as emailTemplateEntityStateActions from "../state/actions/threatDefenceEmailTemplateEntityStateActions";
import { TextTruncate, SearchInput } from "../../../../../components";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { Tooltip } from "../../../../../components/common/tooltip";
import { Title } from "../../../../../components/listViewTemplates";
import { ThreatDefenceEmailTemplateOverview, EmailTemplateDeleteModalType } from "../types/state";
import { PublishedStatus } from "../../../../../components/common/publishedStatus";
import threatDefenceFetchDataUtils from "../../../../../utils/threatDefenceFetchDataUtils";
import EmailTemplateEllipsisPopupButton from "../../../../../components/emailTemplates/EmailTemplateEllipsisPopupButton";
import DeleteEmailTemplatesConfirmationModal from "../../../../../components/emailTemplates/DeleteEmailTemplatesConfirmationModal";
import DeleteEmailTemplatesInformationModal from "../../../../../components/emailTemplates/DeleteEmailTemplatesInformationModal";
import EmailTemplatesFilterForm from "../../../../../components/filterForms/EmailTemplatesFilterForm/EmailTemplatesFilterForm";
import { FiltersMap } from "../../../../../utils/filterUtils";
import { getFilterOptions } from "../state/thunks/emailTemplateFiltersThunk";
import { resetAppliedFilter, setAppliedFilter } from "../state/slices/emailTemplateFiltersSlice";
import { closeEmailTemplateDeleteModal } from "../state/slices/emailTemplateDeleteModalSlice";
import * as deleteEmailTemplateActions from "../state/thunks/emailTemplateDeleteModalThunk";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { EmailTemplateLockSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const defaultSortColumn = "datecreated";

export type ThreatDefenceEmailTemplatesOverviewProps = ThreatDefenceEmailTemplatesOverviewOwnProps &
  PropsFromRedux &
  WithRouterProps;

export class ThreatDefenceEmailTemplatesOverview extends Component<
  ThreatDefenceEmailTemplatesOverviewProps,
  ThreatDefenceEmailTemplatesOverviewState
> {
  constructor(props: ThreatDefenceEmailTemplatesOverviewProps) {
    super(props);
    this.state = {
      selectedTemplateIds: [],
      orderBy: SortOptions.CreatedDateDesc,
      focusedEmailTemplateId: undefined,
      isEllipsisMenuDisabled: false,
    };
  }

  componentDidMount() {
    RtnEventsEmitter.subscribe(EmailTemplateLockSuccess, this.goToEditEntity);
  }

  componentWillUnmount() {
    RtnEventsEmitter.unsubscribe(EmailTemplateLockSuccess, this.goToEditEntity);
  }

  buildTableBody = (emailTemplate: ThreatDefenceEmailTemplateOverview) => {
    return (
      <React.Fragment>
        <Table.Cell width={columnOptions[0].width}>
          <Title
            title={emailTemplate.name}
            onTitleClick={() => this.handleEditClick(emailTemplate.id, true)}
            clamp={2}
          />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <Tooltip target={<TextTruncate>{emailTemplate.subject}</TextTruncate>} content={emailTemplate.subject} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>
          <PublishedStatus
            publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!emailTemplate.isDraft)}
          />
        </Table.Cell>
        <Table.Cell width={columnOptions[3].width}>{dateTimeUtils.formatDate(emailTemplate.dateModified)}</Table.Cell>
        <Table.Cell width={columnOptions[4].width}>
          <EmailTemplateEllipsisPopupButton
            onDeleteEmailTemplate={() => this.onDeleteEmailTemplate(emailTemplate.id)}
            onEditEmailTemplate={() => this.handleEditClick(emailTemplate.id, emailTemplate.isDraft)}
            disabled={this.state.isEllipsisMenuDisabled}
            emailTemplate={emailTemplate}
            outlinedEllipsis
            circle
          />
        </Table.Cell>
      </React.Fragment>
    );
  };

  goToEditEntity = (payload: { id: number }) => this.props.navigate(payload.id.toString());

  handleEditClick = (id: number, isDraft?: boolean) => {
    if (!isDraft) {
      this.props.emailTemplateEntityStateActions.fetchDraftThreatDefenceEmailTemplateEntity(id);
      return;
    }
    this.goToEditEntity({ id });
  };

  onSelectedItemsChanged = (ids: Array<number>) => {
    this.setState({ selectedTemplateIds: ids || [] });
  };

  getEmailTemplates = (
    skip?: number,
    top?: number,
    sortingColumnName?: string,
    sortingDirection?: SortingDirection,
  ) => {
    const orderParams = threatDefenceFetchDataUtils.formatOrderParams(
      sortingColumnName || defaultSortColumn,
      sortingDirection || SortingDirection.Descending,
    );
    this.props.emailTemplatesOverviewActions.getThreatDefenceEmailTemplatesAsync(
      top ?? 10,
      skip ?? 0,
      orderParams,
      this.props.appliedFilter,
    );
  };

  onSortChange = async (_: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    this.setState(
      {
        orderBy: data.value?.toString(),
      },
      this.getEmailTemplates,
    );
  };

  renderCreateEmailTemplateBtn = (): React.ReactElement | null => {
    const { navigate } = this.props;

    if (this.state.selectedTemplateIds.length === 0) {
      return (
        <Button
          primary
          className="create-emailTemplate create-button"
          onClick={() => {
            navigate("create");
          }}
        >
          Create Email Template
        </Button>
      );
    }

    return null;
  };

  onDeleteEmailTemplate = (id: number) => {
    this.props.deleteEmailTemplateActions.openDeleteEmailTemplateModal([id]);

    this.setState({
      focusedEmailTemplateId: id,
    });
  };

  cleanStateAfterDeleteModal = () => {
    this.props.closeDeleteModal();
    this.setState({
      focusedEmailTemplateId: undefined,
    });
  };

  onDeleteEmailTemplateConfirm = async () => {
    this.props.emailTemplatesOverviewActions.deleteThreatDefenceEmailTemplatesAsync(this.state.focusedEmailTemplateId!);
    this.cleanStateAfterDeleteModal();
  };

  onDeleteEmailTemplateClose = () => {
    this.cleanStateAfterDeleteModal();
  };

  // This component is being cloned and receives needed props inside FilterPanel component.
  // @ts-ignore
  renderFilterForm = (): React.ReactElement => <EmailTemplatesFilterForm />;

  renderDeleteModal = (): React.ReactElement => {
    return (
      <div>
        <DeleteEmailTemplatesInformationModal
          isOpen={this.props.emailTemplateDeleteModalType === EmailTemplateDeleteModalType.Information}
          onClose={() => {
            this.onDeleteEmailTemplateClose();
          }}
          emailTemplatesCount={1}
          emailTemplateCampaigns={this.props.emailTemplateCampaigns}
        />
        <DeleteEmailTemplatesConfirmationModal
          isOpen={this.props.emailTemplateDeleteModalType === EmailTemplateDeleteModalType.Confirmation}
          selectedEmailTemplate={this.state.focusedEmailTemplateId!}
          onCancel={this.onDeleteEmailTemplateClose}
          onContinue={() => {
            this.onDeleteEmailTemplateConfirm();
          }}
        />
      </div>
    );
  };

  render() {
    const { emailTemplates, totalCount, isLoading, appliedFilter, filterOptions, isFilterLoading } = this.props;
    return (
      <section className="nested-content emailTemplates">
        <OverviewHeader title="Email Templates" titleForGA="Phishing Email Templates">
          {this.renderCreateEmailTemplateBtn()}
        </OverviewHeader>
        <ItemsView
          items={emailTemplates}
          viewType={ViewType.LIST}
          getData={this.getEmailTemplates}
          noResultsContent={
            <ThreatDefenceEmailTemplatesNoResults
              filtered={!isEmpty(appliedFilter)}
              createButton={this.renderCreateEmailTemplateBtn()}
            />
          }
          isFirstLoad={emailTemplates.length === 0}
          isLoading={isLoading}
          isAllDataLoaded={false}
          selectedIds={this.state.selectedTemplateIds}
          className="emailTemplates-items-view"
          buildTableBody={this.buildTableBody}
          columnOptions={columnOptions}
          itemsInlineCount={totalCount}
          onSelectedListItemsChanged={this.onSelectedItemsChanged}
          onSortChange={this.onSortChange}
          sortingColumnName={defaultSortColumn}
          sortingDirection={SortingDirection.Descending}
          onDeleteEmailTemplate={this.onDeleteEmailTemplate}
          renderSearch={() => <SearchInput placeholder="Search for Email Templates..." />}
          renderFilterForm={this.renderFilterForm}
          appliedFilter={appliedFilter}
          getFilterOptions={this.props.getFilterOptions}
          filterOptionsLoading={isFilterLoading}
          filterOptions={filterOptions}
          applyFilter={this.props.applyFilter}
          resetFilter={this.props.resetFilter}
        />
        {this.renderDeleteModal()}
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const overview = state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplatesOverviewReducer;
  const entityState = state.library.threatDefenceEmailTemplates.threatDefenceEmailTemplateEntityStateReducer;
  return {
    emailTemplates: overview.emailTemplates,
    emailTemplateCampaigns: state.library.threatDefenceEmailTemplates.emailTemplateDeleteModal.emailTemplateCampaigns,
    emailTemplateDeleteModalType:
      state.library.threatDefenceEmailTemplates.emailTemplateDeleteModal.emailTemplateDeleteModalType,
    isLoading: overview.isLoading || entityState.changingEntityState,
    totalCount: overview.totalCount,
    filterOptions: state.library.threatDefenceEmailTemplates.overviewFilters.filterOptions,
    appliedFilter: state.library.threatDefenceEmailTemplates.overviewFilters.appliedFilter,
    isFilterLoading: state.library.threatDefenceEmailTemplates.overviewFilters.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  emailTemplatesOverviewActions: bindActionCreators(emailTemplatesOverviewActions, dispatch),
  emailTemplateEntityStateActions: bindActionCreators(emailTemplateEntityStateActions, dispatch),
  deleteEmailTemplateActions: bindActionCreators(deleteEmailTemplateActions, dispatch),
  getFilterOptions: () => dispatch(getFilterOptions()),
  applyFilter: (filters: FiltersMap) => dispatch(setAppliedFilter(filters)),
  resetFilter: () => dispatch(resetAppliedFilter()),
  closeDeleteModal: () => dispatch(closeEmailTemplateDeleteModal()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(ThreatDefenceEmailTemplatesOverview));
