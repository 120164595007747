import React from "react";

import styles from "./emptyState.module.scss";

interface EmptyStateProps {
  label: string;
}

const EmptyState = (props: EmptyStateProps) => {
  return <div className={styles["root"]}>{`No ${props.label}`}</div>;
};

export default EmptyState;
