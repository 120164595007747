import React, { useEffect, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import ViewType from "../../../enums/ViewType";
import SortDirections from "../../../enums/sortDirections";
import RolesNoResults from "./RolesNoResults";
import { ItemsView } from "../../../views";
import RolesFilterForm from "../../../components/filterForms/RolesFilterForm/RolesFilterForm";
import { SearchInput } from "../../../components";
import RoleRow, { columnOptions } from "./RoleRow";
import { ButtonHandler } from "./RoleRow/RoleRow";
import { RoleCreated } from "../../Application/services/realTimeNotification/events/accounts/accountsEvents";
import { RoleDeletedSuccess } from "../../Application/services/realTimeNotification/events/people/peopleEvents";
import { Role } from "../../../interfaces";
import { RolePermissions } from "../../../enums";
import Restricted from "../../Application/Restricted";

export interface RoleListProps {
  roles: Role[];
  rolesCount: number;
  isLoading: boolean;
  onSelectedRolesChanged?(ids: any[]): void;
  filterOptions: {
    isLoading?: boolean;
  };
  appliedFilter?: object;
  selectedRoleIds: number[];
  createRoleButton?: React.ReactNode;
  filterActions: { setRolesFilter(filter: object): void; resetRolesFilter(): void; fetchFilterOptions(): void };
  onGetRoles(
    skip: number,
    top: number,
    orderProp: string,
    orderDirection: SortDirections,
    filter?: object,
    searchText?: string,
  ): void;
  rowButtonHandlers?: {
    onDeleteRole: ButtonHandler;
    onEditRole: ButtonHandler;
    onAddUsers: ButtonHandler;
  };
  onNavigate?(id: number): void;
  isReadOnly?: boolean;
}

const renderFilterForm = () => <RolesFilterForm />;

const RoleList: React.FC<RoleListProps> = (props) => {
  const {
    roles,
    rolesCount,
    isLoading,
    onSelectedRolesChanged,
    filterOptions,
    appliedFilter,
    selectedRoleIds,
    createRoleButton,
    filterActions: { setRolesFilter, resetRolesFilter, fetchFilterOptions },
    onGetRoles,
    rowButtonHandlers,
    onNavigate,
    isReadOnly,
  } = props;

  const reloadListItemsRef = useRef<() => void>();
  const [searchTerm, setSearchTerm] = useState("");
  const [isChangeSearchToEmpty, setIsChangeSearchToEmpty] = useState(false);

  useEffect(() => {
    reloadListItemsRef.current && reloadListItemsRef.current();
  }, [searchTerm]);

  const onSetReloadListItems = (callback: () => void) => {
    reloadListItemsRef.current = callback;
  };

  const onSearchChange = (searchText: string) => {
    setIsChangeSearchToEmpty(isEmpty(searchText) && !isEmpty(searchTerm));
    setSearchTerm(searchText);
  };

  const onGetData = (skip: number, top: number, orderProp: string, orderDirection: SortDirections, filter?: object) => {
    onGetRoles(skip, top, orderProp, orderDirection, filter, searchTerm);
  };

  return (
    <Restricted
      permissions={[RolePermissions.RolesCreate, RolePermissions.RolesManage]}
      renderContent={(hasPermission) => (
        <ItemsView
          className="alignment-padding"
          viewType={ViewType.LIST}
          columnOptions={columnOptions}
          noResultsContent={
            <RolesNoResults
              createRoleButton={createRoleButton}
              filtered={!isEmpty(appliedFilter) || !!searchTerm || isChangeSearchToEmpty}
            />
          }
          getData={onGetData}
          itemsInlineCount={rolesCount}
          isLoading={isLoading}
          items={roles}
          buildTableBody={(role: Role) => (
            <RoleRow
              role={role}
              onNavigate={onNavigate}
              rowButtonHandlers={rowButtonHandlers}
              rowButtonsDisabled={selectedRoleIds.includes(role.id) || isReadOnly}
              hasPermission={hasPermission}
            />
          )}
          onSelectedListItemsChanged={onSelectedRolesChanged}
          renderFilterForm={renderFilterForm}
          applyFilter={setRolesFilter}
          appliedFilter={appliedFilter}
          resetFilter={resetRolesFilter}
          filterOptions={filterOptions}
          filterOptionsLoading={filterOptions.isLoading}
          getFilterOptions={fetchFilterOptions}
          selectedIds={selectedRoleIds}
          sortingDirection={SortDirections.Desc}
          sortingColumnName="Added"
          renderSearch={() => <SearchInput placeholder="Search for Roles..." onChange={onSearchChange} />}
          listViewRtnEvents={[RoleCreated, RoleDeletedSuccess]}
          isSelectDisabled={() => isReadOnly}
          setReloadListItems={onSetReloadListItems}
          permissions={[RolePermissions.RolesManage]}
        />
      )}
    ></Restricted>
  );
};

export default RoleList;
