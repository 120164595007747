import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dimmer, Icon, Loader, Segment, Sidebar } from "semantic-ui-react";
import { default as cn } from "classnames";

import { BackgroundTaskProgress } from "../progress";
import * as stringUtils from "../../utils/stringUtils";
import TaskStatuses from "../../enums/taskStatuses";
import TaskActions from "../../enums/TaskActions";
import backgroundTasksEventsEmitter from "../../features/BackgroundTasks/events/backgroundTasksEventsEmitter";

import "./progressDrawer.scss";

class ProgressDrawer extends Component {
  state = {
    showShadow: false,
  };

  onTaskClick = (id) => {
    backgroundTasksEventsEmitter.raise(TaskActions.click, id);
  };

  onPauseClick = (id) => {
    backgroundTasksEventsEmitter.raise(TaskActions.pause, id);
  };

  onResumeClick = (id) => {
    backgroundTasksEventsEmitter.raise(TaskActions.resume, id);
  };

  onCancelClick = (id) => {
    backgroundTasksEventsEmitter.raise(TaskActions.cancel, id);
  };

  onRestartClick = (id) => {
    backgroundTasksEventsEmitter.raise(TaskActions.restart, id);
  };

  onCloseClick = (id) => {
    backgroundTasksEventsEmitter.raise(TaskActions.close, id);
  };

  buildTaskList = (tasks, taskIds) => {
    return taskIds
      .filter((taskId) => !tasks[taskId].isHidden)
      .map((taskId) => {
        const task = tasks[taskId];
        return (
          <div className="task-wrapper" key={taskId}>
            <BackgroundTaskProgress
              task={task}
              onClick={this.onTaskClick}
              onPause={this.onPauseClick}
              onResume={this.onResumeClick}
              onCancel={this.onCancelClick}
              onRestart={this.onRestartClick}
              onClose={this.onCloseClick}
            />
          </div>
        );
      });
  };

  onScroll = (e) => {
    const { showShadow } = this.state;
    let showShadowNew = e.target.scrollTop > 0;
    if (showShadow !== showShadowNew) {
      this.setState({
        showShadow: showShadowNew,
      });
    }
  };

  isDismissVisible = (tasks) => {
    const values = Object.values(tasks);
    return values.length > 0 && values.every((task) => TaskStatuses.isCompleted(task.status));
  };

  render() {
    const { hideDrawer, visible, tasks, elapsedTime, isLoading, onDismissAllClick } = this.props;

    const { showShadow } = this.state;
    const taskIds = Object.keys(tasks).reverse();

    return (
      <Dimmer.Dimmable as={Segment} dimmed={isLoading}>
        <Dimmer active={isLoading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <Sidebar
          id="progress-drawer"
          className="progress-drawer"
          as={Segment}
          direction="left"
          animation="overlay"
          icon="labeled"
          inverted
          vertical
          visible={visible}
        >
          <Icon name="close" className="close-icon" onClick={hideDrawer} />
          <div className={cn("header", showShadow ? "shadow" : "")}>
            <div className="drawer-title">
              {" "}
              {taskIds.length} background {stringUtils.pluralize("task", taskIds.length)}
            </div>
            <div className="time-dismiss-all-wrapper">
              {elapsedTime && <div className="elapsed-time"> Elapsed time: {elapsedTime}</div>}
              {this.isDismissVisible(tasks) && (
                <div className="dismiss-all" onClick={onDismissAllClick}>
                  Dismiss All
                </div>
              )}
            </div>
          </div>
          <div className="scroll-area" onScroll={this.onScroll}>
            {this.buildTaskList(tasks, taskIds)}
          </div>
        </Sidebar>
      </Dimmer.Dimmable>
    );
  }
}

ProgressDrawer.defaultProps = {
  tasks: {},
};

ProgressDrawer.propTypes = {
  visible: PropTypes.bool,
  elapsedTime: PropTypes.string,
  isLoading: PropTypes.bool,
  tasks: PropTypes.object,
};

export default ProgressDrawer;
