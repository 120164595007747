import { FC } from "react";

import { Enrollment } from "../../../enums/groups";
import { Strings } from "../../../enums";
import { EllipsisPopup, EllipsisPopupOption } from "../../buttons/ellipsisPopup/EllipsisPopup";
import { Group, GroupActionHandlers } from "../../../interfaces";
import { getAddContentEllipsisPopupOptions } from "../addContentButtonEllipsisOptions/AddContentButtonEllipsisOptions";
import { partial } from "lodash";

export interface Props extends GroupActionHandlers {
  group: Group;
  disabled: boolean;
}

const GroupEllipsisPopupButton: FC<Props> = ({
  group,
  disabled,
  onAddMembers,
  onAddToContent,
  onDelete,
  onDuplicate,
  onEdit,
}) => {
  const contentOptions: EllipsisPopupOption[] = [
    {
      label: "Edit",
      iconName: "fal fa-edit",
      isHideItem: !group.canEdit,
      onClick: () => onEdit?.(group.id),
    },
    {
      label: Strings.modalTitles.addContent,
      iconName: "far fa-plus-circle",
      isHideItem: !group.canEdit,
      options: getAddContentEllipsisPopupOptions(partial(onAddToContent!, group.id)),
    },
    {
      label: "Duplicate",
      iconName: "fal fa-copy",
      onClick: () => onDuplicate?.(group.id),
    },
    {
      label: "Add People",
      iconName: "far fa-plus-circle",
      isHideItem: group.isWithAutoEnroll === Enrollment.Automatic || !group.canEdit,
      onClick: () => onAddMembers?.(group.id),
    },
    {
      label: "Delete",
      iconName: "fal fa-trash-alt",
      isHideItem: !group.canEdit,
      onClick: () => onDelete?.(group.id),
    },
  ];

  return <EllipsisPopup options={contentOptions} disabled={disabled} testId="group-overflow-menu-button" />;
};

export default GroupEllipsisPopupButton;
