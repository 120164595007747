import PacksList from "../../../../../components/packs/packsList/PacksList";
import NoResults from "../../../../../components/noResults/NoResults";
import { getPeoplePacksGridSortingOptions } from "./userPackGridSortingOptions";
import { UserPacksState } from "../../../../Licensing/Packs/state/slices/userPacksGridSlice";
import { UserPacksRequest } from "../../../../Licensing/Packs/types/requests";
import { Filters } from "../../../../../utils/queryUtils";
import UserPacksUtils from "../../../../../utils/userPacksUtils";
import { UserPacksGridFetchType } from "../../../../Licensing/Packs/state/thunks/userPacksGridThunk";
import ViewType from "../../../../../enums/ViewType";
import { PackOverview } from "../../../../Licensing/Packs/types/state";
import UserPackListRow, { getColumnOptions } from "../../../../../components/packs/packsList/UserPackListRow";
import { SortingDirection } from "../../../../../enums";
import {
  AddAllPackUserSuccess,
  AddAllUserLicenseMessageSuccess,
  RemoveAllUserLicenseMessageSuccess,
} from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { useNavigate } from "react-router-dom";
import React from "react";
import { sendTransientNotification } from "features/Notifications/state/notificationsActions";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import { issueLicensePermissionPredicate } from "../issueLicensePermissionPredicate";

export interface IUserPacksActions {
  fetchPacksGrid: (requestData: UserPacksRequest) => UserPacksGridFetchType;
  resetPacksGrid: () => void;
}

export interface UserPacksProps {
  userId: number;
  accountId: number;
  packsActions: IUserPacksActions;
  packsGrid: UserPacksState;
  issueLicenseButton: React.ReactElement;
  sendTransientNotification: (
    payload: Parameters<typeof sendTransientNotification>[0],
    dismissInSeconds: number,
  ) => void;
  onRemoveLicenseClick: (id: number) => void;
  isReadOnly: boolean;
  selectedPackIds: number[];
  onSelectedPacksChanged: (ids: number[]) => void;
}

const rtnEvents = [AddAllPackUserSuccess, AddAllUserLicenseMessageSuccess, RemoveAllUserLicenseMessageSuccess];

const UserPacks = (props: UserPacksProps) => {
  const licenseRemovalEnabled = !!useFeatureFlag(FeatureFlags.LicenseRemoval);
  const navigate = useNavigate();
  const columnOptions = getColumnOptions(licenseRemovalEnabled);

  const renderNoResults = () => (
    <NoResults
      title="This user does not have any pack licenses."
      description={
        <span>
          Looks like there are no pack licenses for this user yet. Issue a license now using the button below.
        </span>
      }
      iconClassName="fa-box-full"
    >
      {props.issueLicenseButton}
    </NoResults>
  );

  const handleTitleClick = (id: number) => {
    navigate(`/licensing/packs/purchased/${id}`);
  };

  const loadPacksGrid = (
    skip: number,
    sortBy: string,
    sortDirection: SortingDirection,
    appliedFilter: Filters,
    searchTerm?: string,
    top?: number,
  ) => {
    const params = {
      userId: props.userId,
      accountId: props.accountId,
      top: top,
      skip: skip,
      filters: appliedFilter,
      searchTerm: searchTerm,
    };

    const request: UserPacksRequest = { ...params, sortBy: UserPacksUtils.mapSortBy(sortBy), sortOrder: sortDirection };

    return props.packsActions.fetchPacksGrid(request);
  };

  const getListRow = (pack: PackOverview) => {
    const isSelected = props.selectedPackIds.some((x) => x === pack.id);

    return (
      <UserPackListRow
        pack={pack}
        accountId={props.accountId}
        onTitleClick={handleTitleClick}
        onRemoveLicenseClick={props.onRemoveLicenseClick}
        licenseRemovalEnabled={licenseRemovalEnabled}
        isSelected={isSelected}
      />
    );
  };

  return (
    <section className="scrollable-content">
      <PacksList
        viewType={ViewType.LIST}
        gridItems={props.packsGrid}
        getListRow={getListRow}
        columnOptions={columnOptions}
        loadGridItems={loadPacksGrid}
        resetGridItems={props.packsActions.resetPacksGrid}
        gridSortingOptions={getPeoplePacksGridSortingOptions()}
        renderNoResults={renderNoResults}
        refreshListEvents={rtnEvents}
        withSelection={licenseRemovalEnabled}
        isReadOnly={props.isReadOnly}
        selectedPackIds={props.selectedPackIds}
        onSelectedPacksChanged={props.onSelectedPacksChanged}
        packCardProps={{
          onTitleClick: handleTitleClick,
          onRemoveLicenseClick: props.onRemoveLicenseClick,
          licenseRemovalEnabled: licenseRemovalEnabled,
          issueLicensePermissionPredicate: issueLicensePermissionPredicate,
        }}
      />
    </section>
  );
};

export default UserPacks;
