import { createAsyncThunk } from "@reduxjs/toolkit";

import { StatusCodes, ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums";
import { makeActionCreator } from "../../../../Application/actions/actionsBuilder";
import dataService from "../../../../Application/services/dataServices/typedDataService";
import { getPrefix } from "../../../../Application/slices/models";
import { GetPacksRequest, PacksRequest } from "../../types/requests";
import * as editAccountActionTypes from "../../../../../features/Accounts/EditAccount/state/editAccountActionTypes";
import { FetchActionPayload } from "../../../../../interfaces/redux";
import { AssignAccountPack } from "../../../../../components/assignmentModals/packAssignmentModal/types";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { DistributedOpUpdateParams } from "../../../../../interfaces/updateParams";
import { pluralize } from "../../../../../utils/stringUtils";
import backgroundTask from "../../../../BackgroundTasks/backgroundTask";
import { AccountPack } from "../../../../Accounts/types";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const loadGridItemsCount: any = process.env.REACT_APP_LOAD_ITEMS_COUNT;

const fetchAccountPackForbidden = makeActionCreator(editAccountActionTypes.FETCH_ACCOUNT_PACKS_FORBIDDEN, "error");

export const fetchAccountPacksGrid = createAsyncThunk<FetchActionPayload<AccountPack>, PacksRequest>(
  getPrefix({
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AccountPacks,
    name: "gridItems",
  }),
  async (requestData: PacksRequest, { signal, dispatch }) => {
    const request: GetPacksRequest = {
      top: loadGridItemsCount,
      skip: requestData.skip,
      orderBy: requestData.orderBy,
      searchTerm: requestData.searchTerm,
    };

    try {
      const { getDataPromise, cancel } = dataService.packs.getAccountPacksWithCancel(
        requestData.accountId,
        request,
        requestData.filters || {},
      );

      signal.addEventListener("abort", cancel);

      const result = await getDataPromise;
      const recordsCount = Number.parseInt(result.headers[countHeaderName]);

      return {
        items: result.data,
        totalCount: recordsCount,
      };
    } catch (error: any) {
      if (error.response?.status === StatusCodes.Forbidden) {
        dispatch(fetchAccountPackForbidden(error));
      }

      throw error;
    }
  },
);

export const addPacksToAccount = (packs: AssignAccountPack[], accountId: number, onCompleted?: () => void) => {
  return async (dispatch: AppDispatch) => {
    const updateParams: DistributedOpUpdateParams = {
      id: "AddPacksToAccount",
      title: "Add packs to account",
      getOperationProps: async () => {
        const { data } = await dataService.packs.addPacksToAccountsAsync(accountId, {
          packInfos: packs,
        });
        return {
          operationId: data.operationId,
          stepsIds: data.stepsIds,
          statusUrl: data.statusUrl,
        };
      },
      successTransientMessage: `${pluralize("Pack", packs.length)} has been added to account successfully`,
      failureTransientMessage: `Adding ${pluralize("pack", packs.length)} to account failed!`,
      onCompleted: onCompleted,
    };
    await backgroundTask.initializeDistributedBackgroundTask(updateParams, dispatch);
  };
};

export type AccountPacksGridFetchType = ReturnType<ReturnType<typeof fetchAccountPacksGrid>>;
