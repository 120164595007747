import AssetTypes from "../../../../../enums/assetTypes";
import { DesignerContextSwitcherOption } from "../../../../Library/Flows/Designer/FlowComponentsPanel/contentView/PanelComponent/helpers/models";

export const assetsOptions: DesignerContextSwitcherOption[] = [
  {
    key: AssetTypes.Video,
    label: "Video",
    iconName: "fa-video",
  },
  {
    key: AssetTypes.Survey,
    label: "Survey",
    iconName: "list ul",
  },
  {
    key: AssetTypes.Assessment,
    label: "Assessment",
    iconName: "list ol",
  },
  {
    key: AssetTypes.Pdf,
    label: "PDF",
    iconName: "fa-file-pdf",
  },
];
