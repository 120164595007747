import cn from "classnames";

import styles from "./packTrialLabel.module.scss";
import labelStyles from "../packLabel.module.scss";

export interface PackTrialLabelProps {
  isTrial: boolean;
  className?: string;
}

export const PackTrialLabel: React.FC<PackTrialLabelProps> = ({ isTrial, className }) => {
  if (isTrial) {
    return <div className={cn(styles.trial, labelStyles.label, className)}>Trial</div>;
  }
  return null;
};
