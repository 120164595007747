import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import * as d3 from "d3";

import { ComputedLink, ComputedNode, d3Sankey } from "./src/d3Sankey";
import { useDimensions } from "../../../hooks/useDimensions";

import { Props, SankeyFuncs } from "../types/Sankey";
import {
  DEFAULT_MARGIN,
  ICON_SIZE,
  TEXT_LEFT_PADDING,
  TEXT_VERTICAL_PADDING,
  TOP_TEXT_OFFSET,
} from "./helpers/constants";
import { APPEND_ONCE, getTranslateString } from "../shared/helpers";

import { lookupEntityColor, lookupEntityText, lookupIconClass, wrap } from "./helpers/methods";
import { ChartPopup } from "../shared/ChartPopup/ChartPopup";

import "./sankeyChart.scss";
import { widthAndHeightInvalid } from "../utils/utils";

const ZOOM_TRANSITION_TIME = 500; // ms

type NodeDragEvent = d3.D3DragEvent<SVGRectElement, ComputedNode, ComputedNode>;

export const SankeyChart = forwardRef<SankeyFuncs, Props>(({ margins = DEFAULT_MARGIN, data, onClick }, ref) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [popupText, setPopupText] = useState("");

  const [dimensions, dimRef] = useDimensions();
  const onClickRef = useRef(onClick);

  useEffect(() => {
    onClickRef.current = onClick;
  }, [onClick]);

  const { width, height, margin } = useMemo(() => {
    return {
      margin: { ...margins },
      width: dimensions?.width ?? 0,
      height: dimensions?.height ?? 0,
    };
  }, [dimensions, margins]);

  const innerWidth = useMemo(() => width - margin.left - margin.right, [width, margin.left, margin.right]);
  const innerHeight = useMemo(() => height - margin.top - margin.bottom, [height, margin.top, margin.bottom]);

  const sankey = useMemo(
    () => d3Sankey().nodeWidth(130).nodePadding(10).size([innerWidth, innerHeight]),
    [innerHeight, innerWidth],
  );

  const zoomed = useCallback(
    (_datum: unknown, index: number, nodes: Element[] | ArrayLike<Element>) => {
      if (!svgRef.current) return;
      let transform = d3.zoomTransform(nodes[index]);
      // Because we're working on the root svg, we need to translate
      // by what the main group element's translate is,
      // otherwise the chart snaps to the top left corner when clicked
      transform = transform.translate(margins.left, margins.top);
      const mainArea = d3.select(svgRef.current).select<SVGGElement>(".centering-group");
      // Only transition if scale's are changing
      const scaleRegex = new RegExp(/scale\((\d+\.?\d*)(,)/gm);
      const match = scaleRegex.exec(mainArea.attr("transform"));
      let currentScale = match ? +match[1] : 1; // if no scale(, then scale must be 1
      let transitionTime = 0;
      let differenceTolerance = 0.005; // to account for floating point inconsistencies
      if (Math.abs(currentScale - transform.k) > differenceTolerance) {
        transitionTime = ZOOM_TRANSITION_TIME;
      }

      // Transition should only occur with zooming, not on drag
      mainArea.transition().duration(transitionTime).attr("transform", transform.toString());
    },
    [margins.left, margins.top],
  );

  const zoomHandler = useMemo(
    () =>
      d3
        .zoom<SVGSVGElement, unknown>()
        .extent([
          [0, 0],
          [width, height],
        ])
        .scaleExtent([0.8, 4]),
    [height, width],
  );

  useImperativeHandle<unknown, SankeyFuncs>(
    ref,
    () => ({
      zoomIn() {
        if (!svgRef.current) return;
        d3.select(svgRef.current).call(zoomHandler.scaleBy, 1.25);
      },
      zoomOut() {
        if (!svgRef.current) return;
        d3.select(svgRef.current).call(zoomHandler.scaleBy, 0.8);
      },
    }),
    [zoomHandler],
  );

  const preflightChecksFailed = useMemo(
    () => widthAndHeightInvalid(innerWidth, innerHeight),
    [innerWidth, innerHeight],
  );

  useEffect(() => {
    if (!svgRef.current) return;

    if (preflightChecksFailed) return;

    let path = sankey.link();
    // Run sankey config
    sankey.relations(data.Relations).layout(128);

    const svg = d3.select(svgRef.current);
    // We don't use the shared centering function here, since we can't have the margin
    // update the area
    const mainArea = svg
      .selectAll(".centering-group")
      .data(APPEND_ONCE)
      .join((enter) =>
        enter
          .append("g")
          .attr("transform", getTranslateString(margins.left, margins.top))
          .attr("class", "centering-group"),
      );

    // Setting up zoom/pan
    zoomHandler.on("zoom", zoomed);
    svg.call(zoomHandler).on("wheel.zoom", null);

    // add in the links
    // For whatever reason, this doesn't fire if I do it off linkContainer
    let links = mainArea
      .selectAll(".link")
      .data(sankey.links(), (l) => `${(l as ComputedLink).source.name} - ${(l as ComputedLink).target.name}`)
      .join(
        (enter) =>
          enter
            .append("path")
            .attr("class", "link")
            .attr("d", path)
            .style("stroke-width", (link) => Math.max(1, link.thickness))
            .sort((a, b) => b.thickness - a.thickness),
        (update) => {
          return update
            .attr("d", path)
            .style("stroke-width", (link) =>
              // For now, just putting some spacing between nodes for less overlap
              // Originally was d.height
              Math.max(1, link.thickness / 2),
            )
            .sort((a, b) => b.thickness - a.thickness);
        },
      );

    const addNodeRects = (selection: d3.Selection<SVGGElement, ComputedNode, d3.BaseType | SVGGElement, boolean>) => {
      // Need to apply drag to the group element, rather than rects
      // See https://stackoverflow.com/questions/13078535/stuttering-drag-when-using-d3-behavior-drag-and-transform

      selection
        .append("rect")
        .attr("height", (d) => (d.height <= 0 ? 0 : d.height))
        .attr("width", sankey.nodeWidth())
        .style("fill", (d) => lookupEntityColor(d.type))
        .style("stroke", (d) => lookupEntityColor(d.type));
    };
    const updateNodeRects = (
      selection: d3.Selection<d3.BaseType | SVGGElement, ComputedNode, d3.BaseType | SVGGElement, boolean>,
    ) => {
      selection
        .select("rect")
        .attr("height", (d) => {
          return d.height;
        })
        .attr("width", sankey.nodeWidth())
        .style("fill", (d) => lookupEntityColor(d.type))
        .style("stroke", (d) => lookupEntityColor(d.type));
    };

    const addNodeText = (selection: d3.Selection<SVGGElement, ComputedNode, d3.BaseType, boolean>) => {
      const iconContainer = selection
        .append("g")
        .attr("class", "iconContainer")
        .attr("transform", getTranslateString(TEXT_LEFT_PADDING, TOP_TEXT_OFFSET));

      iconContainer
        .append("foreignObject")
        .attr("class", "nodeIcon")
        // Provides some extra space for icons to render, since they
        // render slightly below where they're supposed to
        .attr("width", ICON_SIZE + 3)
        .attr("height", ICON_SIZE + 3)
        .attr("x", 0)
        .attr("y", -ICON_SIZE - 1)
        .append("xhtml:i")
        .attr("class", (d) => lookupIconClass(d.type));
      iconContainer
        .append("text")
        .attr("x", ICON_SIZE)
        .text((d) => lookupEntityText(d.type));

      selection
        .append("text")
        .attr("class", "nodeTitle")
        .attr("x", TEXT_LEFT_PADDING)
        .attr("y", TOP_TEXT_OFFSET * 2)
        .attr("dy", TEXT_VERTICAL_PADDING)
        .attr("text-anchor", "start")
        .text((d) => d.name)
        .call(wrap, sankey.nodeWidth(), TEXT_LEFT_PADDING)
        .on("mouseover", (d, i, n) => {
          const parentGroup = n[i].parentNode as SVGGElement;
          const { x, y, width: parentWidth } = parentGroup.getBoundingClientRect();
          setPopupText(d.name);
          setPopupPosition({
            // Center node over rectangle
            x: x + parentWidth / 2,
            y: y,
          });
          setPopupOpen(true);
        })
        .on("mouseleave", () => {
          setPopupOpen(false);
        })
        // Prevents title from dragging around the node
        .on("mousedown", () => {
          (d3.event as Event).stopPropagation();
        })
        .on("click", (d) => {
          onClickRef.current?.(d);
        });

      selection
        .append("text")
        .attr("class", "userCount")
        .attr("x", TEXT_LEFT_PADDING)
        .attr("y", (d) => d.height - TEXT_VERTICAL_PADDING)
        .text((d) => `${d.value.toLocaleString()} ${d.value === 1 ? "User" : "Users"}`);
    };

    const updateNodeText = (selection: d3.Selection<d3.BaseType, ComputedNode, d3.BaseType, boolean>) => {
      const iconContainer = selection
        .select(".iconContainer")
        .attr("transform", getTranslateString(TEXT_LEFT_PADDING, TOP_TEXT_OFFSET));

      iconContainer
        .select(".nodeIcon")
        .attr("y", -ICON_SIZE - 1)
        .attr("width", ICON_SIZE + 3)
        .attr("height", ICON_SIZE + 3)
        .select("i")
        .attr("class", (d) => lookupIconClass(d.type));

      iconContainer.select("text").text((d) => lookupEntityText(d.type));

      selection
        .select<SVGTextElement>(".nodeTitle")
        .attr("y", TOP_TEXT_OFFSET * 2)
        .text((d) => d.name)
        .call(wrap, sankey.nodeWidth(), TEXT_LEFT_PADDING);

      selection
        .select(".userCount")
        .attr("y", (d) => d.height - TEXT_VERTICAL_PADDING)
        .text((d) => `${d.value.toLocaleString()} ${d.value === 1 ? "User" : "Users"}`);
    };

    function dragging(datum: ComputedNode, index: number, groups: ArrayLike<SVGGElement>) {
      const rect = d3.select(groups[index]);
      const draggingEvent = d3.event as NodeDragEvent;
      // Update datum
      datum.x = draggingEvent.x;
      datum.y = draggingEvent.y;

      rect.attr(`transform`, `translate(${datum.x}, ${datum.y})`);
      sankey.relayout();
      links.attr("d", path);
    }

    // add in the nodes
    mainArea
      .selectAll(".node")
      .data(sankey.nodes(), (n) => (n as ComputedNode).name)
      .join(
        (enter) =>
          enter
            .append("g")
            .attr("class", "node")
            .attr("transform", (d) => getTranslateString(d.x, d.y))
            .call(addNodeRects)
            .call(addNodeText),
        (update) =>
          update
            .attr("transform", (d) => getTranslateString(d.x, d.y))
            .call(updateNodeRects)
            .call(updateNodeText),
      )
      // Need to apply drag to the group element, rather than rects
      // See https://stackoverflow.com/questions/13078535/stuttering-drag-when-using-d3-behavior-drag-and-transform
      .call(
        // @ts-ignore
        d3.drag<SVGGElement, ComputedNode>().on("drag", dragging),
      );
  }, [
    margins.left,
    margins.top,
    preflightChecksFailed,
    data,
    sankey,
    innerWidth,
    innerHeight,
    height,
    width,
    zoomHandler,
    zoomed,
  ]);

  return (
    <div className={"sankeyRoot"} ref={dimRef} style={{ width: "100%", height: "100%" }}>
      <svg ref={svgRef} viewBox={`0 0 ${width} ${height}`} />
      <ChartPopup open={popupOpen} left={popupPosition.x} top={popupPosition.y} useTop>
        {popupText}
      </ChartPopup>
    </div>
  );
});
