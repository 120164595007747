import PropTypes from "prop-types";
import { MenuAccordionItem } from "./MenuAccordionItem";
import cn from "classnames";

import "./subMenuAccordionItem.scss";

export function SubMenuAccordionItem(props) {
  const { label, path, className, expanded, onClick } = props;

  return (
    <MenuAccordionItem
      expanded={expanded}
      path={path}
      onClick={onClick}
      className={cn("sub-menu-item", { expanded: expanded }, className)}
    >
      <span className="label">{label}</span>
    </MenuAccordionItem>
  );
}

SubMenuAccordionItem.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  expanded: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default SubMenuAccordionItem;
