import React from "react";
import PropTypes from "prop-types";
import { Popup } from "semantic-ui-react";
import cn from "classnames";

import htmlUtils from "../../../utils/htmlUtils";

import "./tooltip.scss";

const style = {
  fontSize: "14px",
  lineHeight: 1.5,
  whiteSpace: "normal",
  maxWidth: "25rem",
};

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.tooltipRef = React.createRef();
    this.state = { shouldDisplayTooltip: false };
  }

  componentDidMount() {
    const node = this.tooltipRef.current.firstChild;

    if (htmlUtils.isTextTruncated(node)) {
      this.setState({ shouldDisplayTooltip: true });
    }
  }

  componentDidUpdate(prevProps) {
    const node = this.tooltipRef.current.firstChild;

    if (prevProps.target !== this.props.target) {
      this.setState({ shouldDisplayTooltip: htmlUtils.isTextTruncated(node) });
    }
  }

  renderTooltip() {
    const { content, target, position, size, styles, hideOnScroll, tooltipClassName, inverted, disabled, hoverable } =
      this.props;

    return (
      <Popup
        trigger={<div ref={this.tooltipRef}>{target}</div>}
        content={content}
        style={{ ...style, ...styles }}
        position={position}
        size={size}
        hideOnScroll={hideOnScroll}
        inverted={inverted}
        className={tooltipClassName}
        disabled={disabled}
        role={"tooltip"}
        hoverable={hoverable}
      />
    );
  }

  renderTargetWithoutTooltip() {
    return <div ref={this.tooltipRef}>{this.props.target}</div>;
  }

  render() {
    return (
      <div data-name={this.props.content} className={cn(this.props.className, "tooltip-container")}>
        {this.state.shouldDisplayTooltip || this.props.showAlways
          ? this.renderTooltip()
          : this.renderTargetWithoutTooltip()}
      </div>
    );
  }
}

Tooltip.defaultProps = {
  showAlways: false,
  hideOnScroll: false,
  inverted: true,
  disabled: false,
};

Tooltip.propTypes = {
  target: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  content: PropTypes.node,
  position: PropTypes.oneOf([
    "top left",
    "top right",
    "bottom right",
    "bottom left",
    "right center",
    "left center",
    "top center",
    "bottom center",
  ]),
  size: PropTypes.string,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  showAlways: PropTypes.bool,
  hideOnScroll: PropTypes.bool,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  hoverable: PropTypes.bool,
};

export default Tooltip;
