import { IObservable } from "interfaces";
import { FC, useState } from "react";
import { pluralize } from "utils/stringUtils";
import TriggeredConfirmationModal from "../../../../../../components/triggeredConfirmationModal/TriggerableConfirmationModal";

export interface DeleteRemindersModalProps {
  onTriggerRemoveRemindersObserver: IObservable<(onRemoveConfirm: () => void, selectedItemsCount: number) => void>;
  onConfirmed?: () => void;
}

export const DeleteRemindersConfirmationModal: FC<DeleteRemindersModalProps> = (props: DeleteRemindersModalProps) => {
  const { onTriggerRemoveRemindersObserver, onConfirmed } = props;

  const [selectedItemsCount, setSelectedItemsCount] = useState(0);

  const remindersLabel = pluralize("reminder", selectedItemsCount);
  const thisLabel = selectedItemsCount > 1 ? "these" : "this";
  const thisCapitalizedLabel = selectedItemsCount > 1 ? "These" : "This";
  const content = `Are you sure you want to delete ${thisLabel} ${remindersLabel}? ${thisCapitalizedLabel} ${remindersLabel} will not be sent to users once deleted`;

  return (
    <TriggeredConfirmationModal
      title={`Delete ${remindersLabel}?`}
      content={content}
      confirmButtonLabel={"Delete"}
      onTriggerModalObserver={onTriggerRemoveRemindersObserver}
      onTrigger={(input: number) => {
        setSelectedItemsCount(input);
      }}
      onConfirmed={onConfirmed}
    />
  );
};

export default DeleteRemindersConfirmationModal;
