import BasicLinkButton from "../baseLinkButton/BasicLinkButton";

interface MakeIntegrationActiveLinkButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
}

function MakeIntegrationActiveLinkButton(props: MakeIntegrationActiveLinkButtonProps) {
  const { onClick, isDisabled } = props;

  return (
    <BasicLinkButton text="Make Active" iconClassName="fal fa-check-circle" onClick={onClick} isDisabled={isDisabled} />
  );
}

export default MakeIntegrationActiveLinkButton;
