import React from "react";

import InputField from "../../../../Common/Fields/InputField";
import CheckboxField from "../../../../Common/Fields/CheckboxField";

import { AnswerPropsBase } from "../../../../Common/Answer/types";
import { Answer } from "../../../types/state";
import { WithFormikPayload } from "../../../../Common/Fields/WithFormikPayload";
import { MAX_ANSWER_FIELD_LENGTH } from "../../../../Common/constants";

export type MultipleTextAnswerProps = AnswerPropsBase<Answer>;

export const MultipleTextAnswer = (props: MultipleTextAnswerProps) => {
  const { index, answer, onChanged, onBlur, disabled, validatedFieldProps, propertyNamePrefix } = props;
  const { setFieldValue, setFieldTouched } = validatedFieldProps;

  const propertyName = `${propertyNamePrefix}answers.${index}.answer`;

  return (
    <>
      <div className="answer-check-block">
        <WithFormikPayload
          propertyName={`${propertyName}.isCorrect`}
          {...validatedFieldProps}
          pathToValidate={`${propertyNamePrefix}answers`}
          render={(formikProps) => (
            <CheckboxField
              withoutErrorMessage
              value={answer.answer.isCorrect}
              disabled={disabled}
              onChange={onChanged}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              {...formikProps}
            />
          )}
        />
      </div>

      <div className="answer-text-block">
        <WithFormikPayload
          propertyName={`${propertyName}.text`}
          {...validatedFieldProps}
          selfValidation={true}
          render={(formikProps) => (
            <InputField
              placeholder={`Answer ${index + 1} text`}
              value={answer.answer.text}
              maxLength={MAX_ANSWER_FIELD_LENGTH}
              errorPosition="bottom"
              onBlur={onBlur}
              disabled={disabled}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              {...formikProps}
            />
          )}
        />
      </div>
    </>
  );
};
