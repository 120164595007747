import React, { memo } from "react";

import BaseNode from "../Base/Base";
import { FlowItemFlowEnd } from "../../../types";
import { FlowItemBase } from "../types";
import { ExtrasTypes } from "../../../Extras/types";
import FlowEndCard from "../../../../../../../components/cards/flowDesignerDropCard/FlowEndCard/FlowEndCard";

export interface FlowEndNodeProps {
  type: ExtrasTypes.FlowEnd;
  data: FlowItemFlowEnd & FlowItemBase;
}

const FlowEndNode: React.FC<FlowEndNodeProps> = ({ type, data }) => {
  return (
    <BaseNode data={{ ...data, canConnect: false }} styles={{ card: "flow-end" }}>
      <FlowEndCard item={{ ...data, elementType: type }} />
    </BaseNode>
  );
};

export default memo(FlowEndNode);
