import React from "react";
import { bindActionCreators } from "redux";

import { ListViewWithHeaderAndPanel } from "../../../listView";
import { GroupFilterForm } from "../../../filterForms";
import { RolePermissions, SortingDirection } from "../../../../enums";
import { GroupListRow, columnOptions } from "./GroupListRow/GroupListRow";
import "../../assignmentListStep.scss";
import AssignedGroup from "../../../../interfaces/assignedGroup";
import { IGroupListStepProps } from "../types";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../../features/Application/globaltypes/redux";
import * as groupsFilterActions from "../../../../features/People/Groups/GroupsOverview/state/filterActions";
import AccessRestrictedMessage from "../../../restrictedRoute/AccessRestrictedMessage";
import Restricted from "../../../../features/Application/Restricted";
import GroupsNoResultsWithCreate from "../../../../features/People/Groups/GroupsOverview/GroupsNoResults/GroupsNoResultsWithCreate";

export type GroupListStepPropsAll = IGroupListStepProps & PropsFromRedux;

export const GroupListStep = (props: GroupListStepPropsAll) => {
  React.useEffect(() => {
    props.filterActions.fetchFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildTableBody =
    (
      alreadyAssignedGroupTooltip?: string,
      multipleAssignment?: boolean,
      assignmentEntityType?: string,
      selectedParentEntitiesLength?: number,
    ) =>
    (group: AssignedGroup) =>
      (
        <GroupListRow
          group={group}
          alreadyAssignedGroupTooltip={alreadyAssignedGroupTooltip}
          multipleAssignment={multipleAssignment}
          assignmentEntityType={assignmentEntityType}
          selectedParentEntitiesLength={selectedParentEntitiesLength}
          getCustomTooltipMessage={props.getCustomTooltipMessage}
        />
      );

  const getFilterForm = () => <GroupFilterForm withEnrollmentFilter={false} />;

  const isDisabled = (g: AssignedGroup) => g.isAssigned;
  const isWarning = (g: AssignedGroup) => g.hasAssignments;

  return (
    <Restricted permissions={[RolePermissions.GroupsView]} placeholder={<AccessRestrictedMessage />}>
      <div className="assignment-list-step">
        <ListViewWithHeaderAndPanel
          columnOptions={columnOptions}
          searchPlaceholder={"Search for Groups..."}
          loadPage={props.loadPage}
          itemsAmount={props.groupsAmount}
          isLoading={props.isLoading}
          items={props.groups}
          onSelectedListItemsChanged={props.onSelectedListItemsChanged}
          buildTableBody={buildTableBody(
            props.alreadyAssignedGroupTooltip,
            props.multipleAssignment,
            props.assignmentEntityType,
            props.selectedParentEntitiesLength,
          )}
          filter={props.filter}
          selectedItemIds={props.selectedIds}
          noResultsContent={<GroupsNoResultsWithCreate />}
          applyFilter={props.applyFilter}
          filterOptions={props.groupsFilterOptions}
          filterOptionsLoading={props.groupsFilterOptions.isLoading}
          resetFilter={props.resetFilter}
          renderFilterForm={getFilterForm}
          sortingColumnName={columnOptions[0].name}
          sortingDirection={SortingDirection.Ascending}
          isSelectDisabled={isDisabled}
          isWarning={isWarning}
        />
      </div>
    </Restricted>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  groupsFilterOptions: state.people.groupsOverview.filterOptions,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: any) => ({
  filterActions: bindActionCreators(groupsFilterActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GroupListStep);
