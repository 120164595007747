import { useSelector } from "react-redux";
import intersection from "lodash/intersection";

import { RootState } from "./globaltypes/redux";
import { RolePermissions } from "../../enums";

export interface RestrictedProps {
  permissions: RolePermissions[];
  children?: React.ReactElement | React.ReactElement[] | boolean | null;
  renderContent?: (hasAnyPermission: boolean) => React.ReactElement;
  placeholder?: React.ReactElement;
  permissionPredicate?: (userPermissions: RolePermissions[], permissionsList?: RolePermissions[]) => boolean;
}

export const useRestrictedCheck = (
  permissions: RolePermissions[],
  permissionPredicate = (userPermissionsList: RolePermissions[], permissionsList: RolePermissions[] = []) =>
    intersection(userPermissionsList, permissionsList).length > 0,
) => {
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);

  const hasAnyPermission = permissionPredicate(userPermissions, permissions);

  return hasAnyPermission;
};

export const Restricted: React.FC<RestrictedProps> = ({
  permissions,
  children,
  renderContent,
  placeholder,
  permissionPredicate = (userPermissionsList: RolePermissions[], permissionsList: RolePermissions[] = []) =>
    intersection(userPermissionsList, permissionsList).length > 0,
}) => {
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);

  const renderPlaceholder = () => (placeholder ? placeholder : null);

  const hasAnyPermission = permissionPredicate(userPermissions, permissions);

  if (renderContent) {
    return renderContent(hasAnyPermission);
  }

  if (hasAnyPermission) {
    return <>{children}</>;
  }

  return renderPlaceholder();
};

export default Restricted;
