enum TriggerType {
  Completion = 0,
  NotConsumed = 1,
  Open = 2,
  ClickThrough = 3,
  Pass = 4,
  Fail = 5,
  Registered = 6,
  Response = 7,
  Sent = 8,
}

export default TriggerType;
