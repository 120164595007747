import React from "react";
import { Ref } from "semantic-ui-react";
import { FlowToDropCard } from ".";
import { DraggableItem } from "../../interfaces/assetToDropInfo";
import ItemTypes from "../../enums/packItemsTypes";
import { useDraggableAssetCard } from "../../hooks/useDraggableAssetCard";

export interface Props extends DraggableItem {
  id: number;
  title: string;
  thumbnailUrl?: string;
}

const DraggableFlowCard: React.FC<Props> = (props) => {
  const [dragRef, isSelected, { handleClick }] = useDraggableAssetCard({
    type: props.dragNDropItemType,
    item: {
      id: props.id,
      type: props.dragNDropItemType,
      title: props.title,
      thumbnailUrl: props.thumbnailUrl,
      elementType: ItemTypes.Flow,
    },
  });

  return (
    <Ref innerRef={dragRef}>
      <FlowToDropCard
        id={props.id}
        title={props.title}
        thumbnailUrl={props.thumbnailUrl}
        isSelected={isSelected}
        onClick={handleClick}
      />
    </Ref>
  );
};

export default DraggableFlowCard;
