import { useState, useRef, useEffect } from "react";
import { Dimmer, Segment } from "semantic-ui-react";
import { isEmpty } from "lodash";

import FormFooter from "../../../../components/forms/FormFooter";
import { AccountType, ContractType, ParentAccount } from "../../types";

import "./editInfo.scss";
import { AccountConfiguration } from "../../Configuration/AccountConfiguration";

export interface EditInfoOwnProps {
  accountId: number;
  currentConfiguration: any;
  isLoading: boolean;
  isOwnAccount: boolean;
  onInfoUpdated(info: object, accountId: number, isReadOnly: boolean): void;
  fetchAccountTypes(): void;
  accountTypes: AccountType[];
  fetchContractTypes(): void;
  contractTypes: ContractType[];
  parentAccounts: {
    items: ParentAccount[];
    isResourceUnavailable: boolean;
    isLoading: boolean;
  };
  isLoadingAccountTypes: boolean;
  isLoadingContractTypes: boolean;
  isMobo: boolean;
  hasGlobalAccess: boolean;
  fetchParentAccounts(accountTypeId: number): void;
}

const EditInfo = ({
  accountId,
  isOwnAccount,
  fetchAccountTypes,
  accountTypes,
  fetchContractTypes,
  contractTypes,
  currentConfiguration,
  isLoading,
  parentAccounts,
  onInfoUpdated,
  isMobo,
  hasGlobalAccess,
  fetchParentAccounts,
}: EditInfoOwnProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const submitFormRef = useRef();
  const resetFormRef = useRef();

  const configuration = Object.assign({}, currentConfiguration);
  configuration.contractTypeId = currentConfiguration.contractTypeId ?? -1;

  useEffect(() => {
    fetchAccountTypes();
  }, [fetchAccountTypes]);

  useEffect(() => {
    fetchContractTypes();
  }, [fetchContractTypes]);

  const bindResetForm = (resForm: any) => {
    resetFormRef.current = resForm;
  };
  const bindSubmitForm = (subForm: any) => {
    submitFormRef.current = subForm;
  };
  const onSubmit = (newAccountInfo: any) => {
    const accountInfo = {
      ...newAccountInfo,
      contractTypeId: newAccountInfo.contractTypeId === -1 ? null : newAccountInfo.contractTypeId,
      alias: isEmpty(newAccountInfo.alias) ? null : newAccountInfo.alias,
      timeZone: isEmpty(newAccountInfo.timeZone) ? null : newAccountInfo.timeZone,
    };
    onInfoUpdated(accountInfo, accountId, isOwnAccount);
  };

  return (
    <>
      <Dimmer.Dimmable as={Segment} dimmed={isLoading} className="account-edit-info-container scrollable-content">
        <Dimmer active={isLoading} inverted />
        <div className="stretch scrollable-content edit-form account-edit-info">
          <AccountConfiguration
            accountId={accountId}
            isOwnAccount={isOwnAccount}
            bindSubmitForm={bindSubmitForm}
            currentConfiguration={configuration}
            bindResetForm={bindResetForm}
            onIsValidChange={setIsValid}
            onDirtyChanged={setIsDirty}
            onSubmit={onSubmit}
            accountTypes={accountTypes}
            parentAccounts={parentAccounts}
            contractTypes={contractTypes}
            isMobo={isMobo}
            hasGlobalAccess={hasGlobalAccess}
            isEdit
            showAccountId
            onFetchParentAccounts={fetchParentAccounts}
            isLoadingParentAccounts={parentAccounts.isLoading}
          />
        </div>
      </Dimmer.Dimmable>
      <FormFooter
        isSaveBtnDisabled={!(isDirty && isValid)}
        isCancelBtnDisabled={!isDirty}
        onSave={submitFormRef.current}
        onCancel={resetFormRef.current}
      />
    </>
  );
};

export default EditInfo;
