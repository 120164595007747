import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import PropTypes from "prop-types";

function AddMemberLinkButton(props) {
  const { onClick } = props;

  return <BasicLinkButton text="Add People" iconClassName="fal fa-plus-circle" onClick={onClick} />;
}

AddMemberLinkButton.propTypes = {
  onClick: PropTypes.func,
};

export default AddMemberLinkButton;
