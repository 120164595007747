import { IQuestionBase } from "../../../../../Common/models";
import { PictureQuestion, Picture } from "../../../../../Common/Question/Questions/PictureQuestion";
import { TextQuestion } from "../../../../../Common/Question/Questions/TextQuestion";
import { asQuestionsMap } from "../../../../../Common/Question/QuestionsBuilders/QuestionProvider";
import { ISurveyQuestion } from "../types";
import { SurveyQuestionType } from "../../../../types/models";

/* istanbul ignore next */
export const QuestionsMap = asQuestionsMap({
  [SurveyQuestionType.MultipleChoiceTextQuestion]: {
    Component: TextQuestion,
    castQuestion: (Question: IQuestionBase) => Question as ISurveyQuestion<string>,
  },
  [SurveyQuestionType.SingleChoiceTextQuestion]: {
    Component: TextQuestion,
    castQuestion: (Question: IQuestionBase) => Question as ISurveyQuestion<string>,
  },
  [SurveyQuestionType.PictureQuestion]: {
    Component: PictureQuestion,
    castQuestion: (Question: IQuestionBase) => Question as ISurveyQuestion<Picture>,
  },
});
