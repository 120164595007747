import integrationsDataService from "../../../services/integrationsDataService";
import { fetchBegin, fetchFailure, fetchSuccess } from "../slices/integrationDetailsSlice";
import { BaseIntegrationConfigs, IntegrationTypes } from "../../types";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { SfIntegrationConfigs } from "../../types/successFactors";
import { cloneDeep, isNil } from "lodash";

export const fetchIntegrationConfigsAction =
  (type: IntegrationTypes, moboId?: string) => async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const result = (await integrationsDataService.getIntegrationConfigs(type, moboId)).data;
      const mapper = mapFetched(type);
      dispatch(fetchSuccess(mapper(result)));
    } catch (error) {
      dispatch(fetchFailure(error as Error));
    }
  };
export const updateIntegrationConfigsAction =
  <T extends BaseIntegrationConfigs>(type: IntegrationTypes, configs: T, moboId?: string) =>
  async (dispatch: AppDispatch) => {
    dispatch(fetchBegin());
    try {
      const mapper = mapUpdated(type);

      await integrationsDataService.updateIntegrationConfigs(type, mapper(configs), moboId);
    } catch (error) {
      dispatch(fetchFailure(error as Error));
    }
  };

const mapFetched = (type: IntegrationTypes) => (config: BaseIntegrationConfigs) => {
  const map: {
    [key in IntegrationTypes]?: (cfg: BaseIntegrationConfigs) => BaseIntegrationConfigs;
  } = {
    [IntegrationTypes.SuccessFactors]: (cfg: BaseIntegrationConfigs) => {
      const c = cfg as SfIntegrationConfigs;
      c.isHistoryEnabled = !isNil(c.completionHistorySettings);
      return c;
    },
  };
  return map[type]?.(cloneDeep(config)) ?? config;
};

const mapUpdated = (type: IntegrationTypes) => (config: BaseIntegrationConfigs) => {
  const map: {
    [key in IntegrationTypes]?: (cfg: BaseIntegrationConfigs) => BaseIntegrationConfigs;
  } = {
    [IntegrationTypes.SuccessFactors]: (cfg: BaseIntegrationConfigs) => {
      const c = cfg as SfIntegrationConfigs;
      c.completionHistorySettings = c.isHistoryEnabled ? c.completionHistorySettings : null;
      return c;
    },
  };
  return map[type]?.(cloneDeep(config)) ?? config;
};
