import { NormalizedDropdown } from "../../../../../utils/miscellaneousUtils";

export interface ThreatDefenceEmailTemplateOverview {
  id: number;
  name: string;
  subject: string;
  isDraft: boolean;
  modifiedByUserId: number;
  dateCreated: Date;
  dateModified: Date;
}

export type ThreatDefenceEmailTemplatesOverviewState = {
  emailTemplates: Array<ThreatDefenceEmailTemplateOverview>;
  isLoading: boolean;
  totalCount: number;
  error?: Error;
};

export interface ThreatDefenceEmailTemplatesDeleteModalState {
  emailTemplateCampaigns: ThreatDefenceCampaignOption[];
  emailTemplateDeleteModalType: EmailTemplateDeleteModalType;
  isLoading: boolean;
  error?: Error;
}

export interface ThreatDefenceCampaignOption {
  id: number;
  name: string;
}

export enum EmailTemplateDeleteModalType {
  None = 0,
  Information = 1,
  Confirmation = 2,
}

export type GetThreatDefenceEmailTemplateOverviewResult = {
  type: string;
  payload: {
    result: Array<ThreatDefenceEmailTemplateOverview>;
    totalCount: number;
  };
};

export type DeleteThreatDefenceEmailTemplateOverviewResult = {
  isSuccess: boolean;
  id: number;
};
export interface ThreatDefenceEmailTemplateView {
  id: number;
  isDraft: boolean;
  hasBeenPublished: boolean;
  name: string;
  subject: string;
  dateCreated?: Date;
  dateModified?: Date;
  labels: NormalizedDropdown;
  softwareApplications: NormalizedDropdown;
}

export interface ThreatDefenceEmailTemplate {
  id?: number;
  isDraft?: boolean;
  name: string;
  subject: string;
  dateCreated?: Date;
  dateModified?: Date;
  labels: string[];
  softwareApplications: string[];
}

export interface ThreatDefenceEmailTemplateDetailsState {
  email: ThreatDefenceEmailTemplateView;
  html: string;
  contentUrlWithSas: string;
  isEmailLoaded: boolean;
  isEmailContentLoaded: boolean;
  isLoading: boolean;
  error?: Error;
}

export interface ThreatDefenceEmailTemplateContent {
  id: number;
  html: string;
  contentUrlWithSas: string;
}

export interface LoadThreatDefenceEmailTemplateContentResult {
  content: ThreatDefenceEmailTemplateContent;
  getTemplateOnly: boolean;
}
