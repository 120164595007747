import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "enums";
import { createFetchingItemsSlice, FetchingItemsState } from "features/Application/slices/createFetchingItemsSlice";
import { getTemplatePacks } from "./thunks/TemplatePacksThunk";
import { Pack } from "../DataService/types";

export interface TemplatePacksState extends FetchingItemsState<Pack> {}

export const initialState: TemplatePacksState = {
  isLoading: false,
  items: [],
  itemsCount: 0,
};

const slice = createFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.People,
    entity: ReducerEntityPrefixTypes.TemplateGroups,
    name: "AddCustomersPacks",
  },
  initialState: initialState,
  reducers: {},
  thunk: getTemplatePacks,
});

export const { fetchBegin, fetchFailure, fetchSuccess, reset } = slice.actions;

export default slice.reducer;
