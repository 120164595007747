export const FETCH_LOCKED_ENTITY_BEGIN = "FETCH_LOCKED_ENTITY_BEGIN";
export const FETCH_LOCKED_ENTITY_SUCCESS = "FETCH_LOCKED_ENTITY_SUCCESS";
export const FETCH_LOCKED_ENTITY_FAILURE = "FETCH_LOCKED_ENTITY_FAILURE";

export const USE_EXISTING_LOCKED_ENTITY = "USE_EXISTING_LOCKED_ENTITY";

export const PUBLISH_LOCKED_ENTITY_BEGIN = "PUBLISH_LOCKED_ENTITY_BEGIN";
export const PUBLISH_LOCKED_ENTITY_SUCCESS = "PUBLISH_LOCKED_ENTITY_SUCCESS";
export const PUBLISH_LOCKED_ENTITY_FAILURE = "PUBLISH_LOCKED_ENTITY_FAILURE";

export const REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN = "REVERT_LOCKED_ENTITY_TO_PUBLISHED_BEGIN";
export const REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS = "REVERT_LOCKED_ENTITY_TO_PUBLISHED_SUCCESS";
export const REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE = "REVERT_LOCKED_ENTITY_TO_PUBLISHED_FAILURE";
export const LOCKED_ENTITY_UPDATED = "LOCKED_ENTITY_UPDATED";
export const RESET_ENTITY_STATE = "RESET_ENTITY_STATE";

export const UPDATE_LOCKED_ENTITY_COMMAND_BEGIN = "UPDATE_LOCKED_ENTITY_COMMAND_BEGIN";
export const UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS = "UPDATE_LOCKED_ENTITY_COMMAND_SUCCESS";
export const UPDATE_LOCKED_ENTITY_COMMAND_FAILURE = "UPDATE_LOCKED_ENTITY_COMMAND_FAILURE";

export const RESET_UPDATE_LOCKED_ENTITY_COMMAND_STATE = "RESET_UPDATE_LOCKED_ENTITY_COMMAND_STATE";

export const CREATE_DRAFT_ENTITY_BEGIN = "CREATE_DRAFT_ENTITY_BEGIN";
export const CREATE_DRAFT_ENTITY_SUCCESS = "CREATE_DRAFT_ENTITY_SUCCESS";
export const CREATE_DRAFT_ENTITY_FAILURE = "CREATE_DRAFT_ENTITY_FAILURE";

export const SET_ENTITY_ID = "SET_ENTITY_ID";

export const SET_DATA_FOR_DETAILS_MODE = "SET_DATA_FOR_DETAILS_MODE";
