import React, { memo } from "react";

import BaseNode from "../Base/Base";
import PdfCard from "../../../../../../../components/cards/flowDesignerDropCard/PdfCard/PdfCard";
import AssetTypes from "../../../../../../../enums/assetTypes";
import { FlowItemPdf } from "../../../types";
import { FlowItemBase } from "../types";

interface IPdfNodeOwnProps {
  type: AssetTypes.Pdf;
  data: FlowItemPdf & FlowItemBase;
}

const PdfNode: React.FC<IPdfNodeOwnProps> = ({ type, data }) => {
  return (
    <BaseNode data={data}>
      <PdfCard item={{ ...data, elementType: type }} />
    </BaseNode>
  );
};

export default memo(PdfNode);
