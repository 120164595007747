import { Button as SemanticButton, ButtonProps } from "semantic-ui-react";

export interface Props extends ButtonProps {
  blur?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => void | Promise<void>;
}

export const Button: React.FC<Props> = ({ onClick, children, blur, ...other }) => {
  const onClickAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: ButtonProps) => {
    onClick?.(e, data);
    if (blur) e.currentTarget.blur();
  };

  return (
    <SemanticButton onClick={onClickAction} {...other}>
      {children}
    </SemanticButton>
  );
};
