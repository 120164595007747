import React from "react";
import { Icon } from "semantic-ui-react";

import styles from "./userStatus.module.scss";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { CompletionStatusEnum } from "../../../../../enums/сompletionStatusEnum";
import { Tooltip } from "../../../../../components/common/tooltip";
import { isEqual } from "lodash";

export const CompletionStatusEnumToString: { [key in CompletionStatusEnum]: string } = {
  [CompletionStatusEnum.Completed]: "Completed",
  [CompletionStatusEnum.InProgress]: "In Progress",
  [CompletionStatusEnum.NotStarted]: "Not Started",
};

const iconMappings = (canAutoStart: boolean): { [key in CompletionStatusEnum]: [SemanticICONS, string] } => ({
  [CompletionStatusEnum.Completed]: ["check circle", "completed"],
  [CompletionStatusEnum.InProgress]: ["clock", "in-progress"],
  [CompletionStatusEnum.NotStarted]: canAutoStart
    ? ["exclamation circle", "not-started--warning"]
    : ["circle outline", "not-started"],
});

export interface UserStatusProps {
  completionStatus: CompletionStatusEnum;
  canAutoStart: boolean;
}

const UserStatus = (props: UserStatusProps) => {
  const [icon, iconClass] = iconMappings(props.canAutoStart)[props.completionStatus] || [];

  return (
    <div className={styles.root}>
      <Tooltip
        hideOnScroll
        showAlways={props.canAutoStart && isEqual(props.completionStatus, CompletionStatusEnum.NotStarted)}
        position="top right"
        content="Action Required: Flows that begin with a communication asset must be started by an administrator unless set to Autostart."
        target={<Icon name={icon} className={styles[iconClass]} />}
        tooltipClassName={styles["completion-status"]}
      />

      <span>{CompletionStatusEnumToString[props.completionStatus]}</span>
    </div>
  );
};

export default UserStatus;
