import React from "react";
import BasicLinkButton from "../baseLinkButton/BasicLinkButton";
import PropTypes from "prop-types";

import "./editLinkButton.scss";

function EditLinkButton(props) {
  const { onClick, isDisabled, text } = props;

  return (
    <BasicLinkButton
      className={"edit-link-button"}
      text={text ?? "Edit"}
      iconClassName="fa-edit"
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}

EditLinkButton.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  text: PropTypes.string,
};

export default EditLinkButton;
