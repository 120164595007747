import { useCallback, useMemo, useState } from "react";
import { ELEMENT_HEIGHT, ELEMENT_WIDTH } from "../../constants";
import { IFlowNode } from "../../ReactFlowCanvas/nodes/types";
import { useReactFlowCanvasState } from "../../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import { useReactFlow } from "reactflow";

export interface IViewPort {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

export enum ViewPortType {
  Actual,
  SmartGuidesOnCanvas,
  SmartGuidesOnDragLayer,
  ValidationErrors,
}

export interface IUseNodesFilterOptions {
  ignoreStartOfTheFlow?: boolean;
  viewPortType?: ViewPortType;
}

function useForceUpdate() {
  const [, update] = useState(false);
  const handleUpdate = useCallback(() => {
    update((s) => !s);
  }, [update]);
  return [handleUpdate];
}

const useViewPort = (viewPortType: ViewPortType) => {
  const {
    reactFlowBounds: { width, height },
  } = useReactFlowCanvasState();
  const { getViewport } = useReactFlow();
  const [refreshViewPort] = useForceUpdate();
  const { x, y, zoom: scale } = getViewport() || { x: 0, y: 0, zoom: 1 };

  const viewPort: IViewPort = useMemo(() => {
    const result = {
      x1: -x / scale,
      y1: (y === 0 ? 0 : -y) / scale,

      x2: (-x + width) / scale,
      y2: (-y + height) / scale,
    };

    /**
     * Following viewports for different layers
     * - to avoid overlapping asset inspector by smart guides (when dragging a card from asset inspector)
     * - to avoid issues when scaling
     */
    if (viewPortType === ViewPortType.SmartGuidesOnCanvas) {
      result.x1 -= ELEMENT_WIDTH;
      result.y1 -= ELEMENT_HEIGHT;
    }

    if (viewPortType === ViewPortType.SmartGuidesOnDragLayer) {
      result.x1 -= ELEMENT_WIDTH;
      result.x2 -= ELEMENT_WIDTH;
      result.y2 -= ELEMENT_HEIGHT;
    }

    if (viewPortType === ViewPortType.ValidationErrors) {
      result.x2 -= ELEMENT_WIDTH;
      result.y2 -= ELEMENT_HEIGHT;
    }
    return result;
  }, [scale, width, height, x, y, viewPortType]);

  return { scale, viewPort, refreshViewPort };
};

export function useNodesFilter(options?: IUseNodesFilterOptions) {
  const viewPortType = options?.viewPortType || ViewPortType.Actual;
  const { scale, viewPort, refreshViewPort } = useViewPort(viewPortType);

  const nodesFilter = useCallback(
    (n: IFlowNode) => {
      const baseFilter =
        n.type !== "Placeholder" &&
        n.position.x > viewPort.x1 &&
        n.position.x < viewPort.x2 &&
        n.position.y > viewPort.y1 &&
        n.position.y < viewPort.y2;

      if (options?.ignoreStartOfTheFlow) {
        return baseFilter;
      } else {
        return baseFilter && n.type !== "StartOfTheFlow";
      }
    },
    [viewPort, options],
  );

  return {
    viewPort,
    scale,
    nodesFilter,
    refreshViewPort,
  };
}
