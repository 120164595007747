export const defaultTemplateBody = `<tr>
    <td class="esd-structure es-p20t es-p20r es-p20l" align="left">
        <table cellpadding="0" cellspacing="0" width="100%">
            <tbody>
            <tr>
                <td width="560" class="esd-container-frame" align="center" valign="top">
                    <table cellpadding="0" cellspacing="0" width="100%">
                        <tbody>
                        <tr>
                            <td align="center" class="esd-empty-container"
                                style="display: none;"></td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    </td>
</tr>`;
