import PropTypes from "prop-types";

const types = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  textOnly: PropTypes.bool,
  action: PropTypes.func,
  actionLabel: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  dismissInSeconds: PropTypes.number,
  visible: PropTypes.bool,
  onDismissCompleted: PropTypes.func,
  animateOnMount: PropTypes.bool,
  animationDuration: PropTypes.number,
};

export default types;
