import { GroupTemplatePacksConfirmationModal } from "components/assignmentModals/groupTemplatePacksConfirmationModal/GroupTemplatePacksConfirmationModal";
import { AppDispatch } from "features/Application/globaltypes/redux";
import { useObserver } from "hooks/useObserver";
import { IObservable } from "interfaces/IObservable";
import { FC, useEffect, useMemo, useRef } from "react";
import { Observable } from "utils";
import { editAccountPacks, getAccountPacks } from "../../state/thunks/TemplatePacksThunk";
import { bindAction } from "interfaces";
import { connect, ConnectedProps } from "react-redux";
import { PackMap } from "components/assignmentModals/groupTemplatePacksConfirmationModal/types";
import { AccountPack } from "../../DataService/types";

type Props = PropsFromRedux & { onTriggerModalObserver: IObservable<(templateId: number, customerId: number) => void> };

export const EditCustomerPacksModal: FC<Props> = (props) => {
  const { onTriggerModalObserver, getAccountPacks, editAccountPacks } = props;
  const onTriggerPacksConfirmationObserver = useMemo(() => new Observable(), []);
  const packMap = useRef<PackMap>(new Map());

  const [subscribeOnModalTrigger] = useObserver(onTriggerModalObserver);

  const initPackMap = (items: AccountPack[]): PackMap => {
    const map = new Map();
    items.forEach((item) => {
      map.set(item.id, item);
    });
    return map;
  };

  useEffect(() => {
    const onTriggerModal = async (templateId: number, customerId: number) => {
      const accountPacks = await getAccountPacks(templateId, customerId);
      packMap.current = initPackMap(accountPacks);
      onTriggerPacksConfirmationObserver.notify(
        () => editAccountPacks(templateId, [...packMap.current.values()], customerId),
        packMap.current,
      );
    };

    return subscribeOnModalTrigger(onTriggerModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribeOnModalTrigger]);
  
  const infoText = `License quantities for each Pack have been calculated based on the users from the 
    existing customer accounts in this template. Quantities may automatically adjust as new users are added.`;

  return <GroupTemplatePacksConfirmationModal isEdit={true} onTriggerModalObserver={onTriggerPacksConfirmationObserver} packAccountItemsText={infoText}/>;
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    getAccountPacks: bindAction(getAccountPacks, dispatch),
    editAccountPacks: bindAction(editAccountPacks, dispatch),
  };
};

/* istanbul ignore next */
const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditCustomerPacksModal);
