import axios from "axios";
import { BasePerformanceRequestFilterParams } from "../../Library/Common/models";
import {
  TeamsDeviceUsage,
  TeamsUserActivityCounts,
  SharepointActivityFileCounts,
  SharepointActivityPages,
  OnedriveActivityFileCounts,
} from "../types/graph";
import { QueryFunctionContext } from "@tanstack/react-query";

const TEAMS_DEVICE_USAGE_ENDPOINT = "/api/reports/v1/msft/teams-deviceusage-users";
const TEAMS_USER_ACTIVITY_COUNT_ENDPOINT = "/api/reports/v1/msft/teams-useractivity-counts";
const SHAREPOINT_ACTIVITY_FILE_COUNT_ENDPOINT = "/api/reports/v1/msft/sharepoint-activity-filecounts";
const SHAREPOINT_ACTIVITY_USER_COUNT_ENDPOINT = "/api/reports/v1/msft/sharepoint-activity-usercounts";
const SHAREPOINT_ACTIVITY_PAGES_ENDPOINT = "/api/reports/v1/msft/sharepoint-activity-pages";
const ONEDRIVE_ACTIVITY_FILE_COUNT_ENDPOINT = "/api/reports/v1/msft/onedrive-activity-filecounts";

export const graphReportingService = {
  getTeamsDeviceUsage(params: BasePerformanceRequestFilterParams = {}): Promise<{ data: TeamsDeviceUsage[] }> {
    return axios.get<TeamsDeviceUsage[]>(TEAMS_DEVICE_USAGE_ENDPOINT, {
      params,
    });
  },
  getTeamsUserActivityCounts(params: BasePerformanceRequestFilterParams = {}) {
    return axios.get<TeamsUserActivityCounts[]>(TEAMS_USER_ACTIVITY_COUNT_ENDPOINT, {
      params,
    });
  },
  getSharepointActivityFileCounts(params: BasePerformanceRequestFilterParams = {}) {
    return axios.get<SharepointActivityFileCounts[]>(SHAREPOINT_ACTIVITY_FILE_COUNT_ENDPOINT, {
      params,
    });
  },
  async getSharepointActivityUserCounts({
    queryKey,
  }: QueryFunctionContext<[_: string, filter: BasePerformanceRequestFilterParams, chartPeriod: string]>) {
    return (
      await axios.get<SharepointActivityFileCounts[]>(SHAREPOINT_ACTIVITY_USER_COUNT_ENDPOINT, {
        params: { ...queryKey[1], period: queryKey[2] },
      })
    ).data;
  },
  getSharepointActivityPages(params: BasePerformanceRequestFilterParams = {}) {
    return axios.get<SharepointActivityPages[]>(SHAREPOINT_ACTIVITY_PAGES_ENDPOINT, {
      params,
    });
  },
  getOnedriveActivityFileCounts(params: BasePerformanceRequestFilterParams = {}) {
    return axios.get<OnedriveActivityFileCounts[]>(ONEDRIVE_ACTIVITY_FILE_COUNT_ENDPOINT, {
      params,
    });
  },
};
