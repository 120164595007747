enum ReducerEntityPrefixTypes {
  // region People
  Users = "USERS",
  Roles = "ROLES",
  Groups = "GROUPS",
  TemplateGroups = "TEMPLATE_GROUPS",
  GroupOwners = "GROUP_OWNERS",
  AddUser = "ADD_USER",
  Users_Overview = "USERS_OVERVIEW",
  UserGroupsAssignments = "USER_GROUP_ASSIGNMENTS",
  UserRolesAssignments = "USER_ROLE_ASSIGNMENTS",
  RoleUsersAssignments = "ROLE_USERS_ASSIGNMENTS",
  RoleUsersToAdd = "ROLES_USERS_TO_ADD",
  UserAvailableFlows = "USER_AVAILABLE_FLOWS",
  UserAvailableAssets = "USER_AVAILABLE_ASSETS",
  UserAvailableEvents = "USER_AVAILABLE_EVENTS",
  GroupAvailableFlows = "GROUP_AVAILABLE_FLOWS",
  GroupAvailableAssets = "GROUP_AVAILABLE_ASSETS",
  UploadedFileColumns = "UPLOADED_FILE_COLUMNS",
  UsersImportPreviewData = "USERS_IMPORT_PREVIEW_DATA",
  AddGroup = "ADD_GROUP",
  EditGroup = "EDIT_GROUP",
  EditTemplate = "EDIT_TEMPLATE",
  EditUser = "EDIT_USER",
  EditRole = "EDIT_ROLE",
  UserCommon = "USER_COMMON",
  Content = "CONTENT",
  AddContent = "ADD_CONTENT",

  // region Collections
  Marketplace = "MARKETPLACE",

  // region Licensing
  Packs = "PACKS",
  AvailablePacks = "AVAILABLE_PACKS",
  AccountPacks = "ACCOUNT_PACKS",
  PackFilters = "PACK_FILTERS",
  AvailableLicenses = "AVAILABLE_LICENSES",
  ContentPacks = "CONTENT_PACKS",
  PackUserLicenses = "PACK_USER_LICENSES",
  PackGroupLicenses = "PACK_GROUP_LICENSES",
  PackUsersAvailableForLicensing = "PACK_USERS_AVAILABLE_FOR_LICENSING",
  PackGroupsAvailableForLicensing = "PACK_GROUPS_AVAILABLE_FOR_LICENSING",
  PackVisibilityForAccount = "PACK_VISIBILITY_FOR_ACCOUNT",
  PackAccountTypesVisibility = "PACK_ACCOUNT_TYPES_VISIBILITY",
  PackTrialPeriods = "PACK_TRIAL_PERIODS",

  // region Library
  User_Assignments = "USERS_ASSIGNMENTS",
  Group_Assignments = "GROUPS_ASSIGNMENTS",
  Purchased = "PURCHASED",
  Flows = "FLOWS",
  Videos = "VIDEOS",
  Events = "EVENTS",
  Emails = "EMAILS",
  Messages = "MESSAGES",
  Pdfs = "PDFS",
  Resources = "RESOURCES",
  Assessments = "ASSESSMENTS",
  ThreatDefenceEmailTemplates = "THREAT_DEFENCE_EMAIL_TEMPLATES",
  ThreatDefenceLandingPages = "THREAT_DEFENCE_LANDING_PAGES",
  FeedbackPages = "FEEDBACK_PAGES",
  ThreatDefenceSendingProfiles = "THREAT_DEFENCE_SENDING_PROFILES",
  ThreatDefenceSmtpAuthSendingProfiles = "THREAT_DEFENCE_SMTP_SENDING_PROFILES",
  ThreatDefenceCampaigns = "THREAT_DEFENCE_CAMPAIGNS",
  Surveys = "SURVEYS",

  // region Accounts
  Accounts = "ACCOUNTS",
  AccountCreation = "ACCOUNT_CREATION",
  ProtectedAccounts = "PROTECTED_ACCOUNTS",
  EditAccount = "EDIT_ACCOUNT",
  AccountUploadCsvFile = "ACCOUNT_UPLOAD_CSV_FILE",
  Integrations = "INTEGRATIONS",
  IdentityProviders = "IDENTITY_PROVIDERS",
  EditMsGraphIntegration = "EDIT_MS_GRAPH",
  EditWebexIntegration = "EDIT_WEBEX",
  API = "API",
  EmailSettings = "EMAIL_SETTINGS",

  // region Reporting
  Added_Users = "ADDED_USERS",
  Active_Users = "ACTIVE_USERS",
  Reporting_Assignments = "ASSIGNMENTS",
  Reporting_Billing = "BILLING",
  Reporting_Engagement = "ENGAGEMENT",
  Reporting_Overview = "OVERVIEW",
  Reporting_Packs = "PACKS",
  Reporting_Usage = "USAGE",
  Reporting_Graph = "GRAPH",
  Reporting_Export = "EXPORT",
  Reporting_Toolbar = "TOOLBAR",

  // region Content Reporting
  Content_Reporting = "CONTENT_REPORTING",

  // region Flows
  Flows_Performance = "FLOWS_PERFORMANCE",

  // region Assets
  Videos_Performance = "VIDEOS_PERFORMANCE",

  // region Assessments
  Assessments_Performance = "ASSESSMENTS_PERFORMANCE",

  // region Surveys
  Surveys_Performance = "SURVEYS_PERFORMANCE",

  // system notifications
  NotifyStep = "NOTIFY_STEP",

  // region PDFs
  Pdfs_Performance = "PDFS_PERFORMANCE",
}
export default ReducerEntityPrefixTypes;
