import {
  CancelingLazyFetchingItemsState,
  createCancelingLazyFetchingItemsSlice,
} from "../../../../Application/slices/createCancelingLazyFetchingItemsSlice";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { fetchAccountPacksGrid } from "../thunks/accountPacksThunk";
import { AccountPack } from "../../../../Accounts/types";

export type AccountPacksOverviewState = CancelingLazyFetchingItemsState<AccountPack>;

export const initialState: AccountPacksOverviewState = {
  areAllLoaded: false,
  isLoading: false,
  items: [],
  itemsCount: 0,
  currentRequestId: "",
};

const accountPacksGridSlice = createCancelingLazyFetchingItemsSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.AccountPacks,
    name: "gridItems",
  },
  initialState: initialState,
  reducers: {},
  thunk: fetchAccountPacksGrid,
});

export const resetAccountPacksGrid = accountPacksGridSlice.actions.reset;

export default accountPacksGridSlice.reducer;
