import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import AssetTypes from "../../../../../enums/assetTypes";
import CommunicationTypes from "../../../../../enums/communicationTypes";
import EventTypes from "../../../../../enums/eventTypes";
import { FlowTypes } from "../../../../../enums/flowTypes";
import { PackContentTab } from "../../../../../enums/packContentTab";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../enums/reducer";
import { getPrefix } from "../../../../Application/slices/models";
import { EntityType } from "../../../../Library/Flows/Designer/types";

export interface PackContentTabContextState {
  activeTab: PackContentTab;
  entityType: { [key in PackContentTab]: EntityType };
}

export const initialState: PackContentTabContextState = {
  activeTab: PackContentTab.Assets,
  entityType: {
    [PackContentTab.Assets]: AssetTypes.Video,
    [PackContentTab.Flows]: FlowTypes.Flow,
    [PackContentTab.Communications]: CommunicationTypes.Email,
    [PackContentTab.Events]: EventTypes.ExternalEvent,
  },
};

const packContentTabContextSlice = createSlice({
  name: getPrefix({
    namespace: ReducerNamespaceTypes.Licensing,
    entity: ReducerEntityPrefixTypes.Packs,
    name: "context",
  }),
  initialState: initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<PackContentTab>) {
      state.activeTab = action.payload;
    },
    setEntityType(state, action: PayloadAction<EntityType>) {
      state.entityType[state.activeTab] = action.payload;
    },
    resetContext() {
      return initialState;
    },
  },
});

export const { setActiveTab, setEntityType, resetContext } = packContentTabContextSlice.actions;

export default packContentTabContextSlice.reducer;
