import { Component, createRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty, isEqual, difference, union } from "lodash";
import { useNavigate } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import * as addUserActions from "./state/addUserActions";
import * as rolesFilterActions from "../../RolesOverview/state/filterActionCreators";
import * as groupsOverviewActions from "../../Groups/GroupsOverview/state/groupsOverviewActions";
import { setSelectedContentType as setSelectedContentTypeAction } from "../../../People/state/slices/addToContentSlice";
import { getFilterOptions as eventGetFilterOptions } from "../../../Library/Events/state/thunks/eventFiltersThunk";
import * as allGroupsFilterActions from "../../Groups/GroupsOverview/state/filterActions";
import { GroupList } from "../../../../components/people/groupList";
import { NoResults } from "../../../../components";
import { INotifyByEmailController } from "../../../../components/notifyStep/notifyByEmailController";
import NotifyWizardStep from "../../../../components/notifyStep/NotifyWizardStep/NotifyWizardStep";
import { withNotifyConfig, WithNotifyConfigProps } from "../../../SystemNotifications/containers/withNotifyConfig";
import GroupsAssignmentModalOneStep from "../../../../components/assignmentModals/groupsAssignmentModal/groupsAssignmentModalOneStep/GroupsAssignmentModalOneStep";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { FlowPriorityItem, PriorityItem, UserInfo } from "../../types";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/people/peopleEvents";
import userListUtils from "../../../../utils/userListUtils";
import UserInfoForm from "../AddEditUserForms/UserInfoForm/UserInfoForm";
import RoleList from "../../RoleList/RoleList";
import { WizardWrapper as Wizard } from "../../../WizardWrapper";
import {
  AssignmentPeopleContext,
  TemplateTypes,
  ViewType,
  RolePermissions,
  Enrollment,
  CommunicationChannels,
} from "../../../../enums";
import { EntityToPeopleAssignments } from "../../../Library/PeopleAssignments/types";
import { initialNotifyConfigDefault } from "../../../SystemNotifications/config";
import { NotifySettings } from "../../../SystemNotifications/types";
import { resetContentPacksAction } from "../../../Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { bindAction } from "../../../../interfaces";
import LicensesStep from "../../../Licensing/Packs/shared/LicensesStep/LicensesStep";
import AccessRestrictedMessage from "../../../../components/restrictedRoute/AccessRestrictedMessage";
import Restricted from "../../../Application/Restricted";
import { issuePackLicenseToPeople } from "../../../Licensing/Packs/state/thunks/packPurchasedViewThunk";
import { addUsersToGroups } from "../../state/thunks/peopleThunk";
import { SelectedItem } from "../../../../components/contentAssignment/types";
import { assignContentDistributed } from "../../../Library/PeopleAssignments/state/actions/commonActions";
import { NotifyStepSwitch } from "../../../../components/notifyStep/NotifyStepSwitch";
import { withRouter, WithRouterProps } from "../../../../adapters/withRouter/withRouter";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { PackOverview } from "features/Licensing/Packs/types/state";
import { SelectionChangedType } from "interfaces/onSelectionChanged";
import AddPeoplePacksIssueLicenseModal from "components/assignmentModals/peopleIssueLicenseModal/AddPeoplePacksIssueLicenseModal";
import { shouldShowModalForGroupMembership } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import navigationUtils from "../../../../utils/navigationUtils";

import "./addUser.scss";
import { LDProps } from "../../../LDProps";

const goToUsersOverview = (navigate: ReturnType<typeof useNavigate>) => navigate("/people/users");
const permanentFilter = { IsWithAutoEnroll: Enrollment.Manual };

type PropsFromRedux = ConnectedProps<typeof connector>;

export enum AddUserTab {
  Configure = 0,
  Roles = 1,
  Groups = 2,
  Licenses = 3,
  Content = 4,
  Notify = 5,
}

enum ContentType {
  Flow = "flow",
  Video = "video",
  Event = "externalEvent",
  Survey = "survey",
  Assessment = "assessment",
  Pdf = "pdf",
}

interface AddUserState {
  isDirty: boolean;
  isUserDataSaved: boolean;
  rolesAppliedFilter: any;

  flowsViewType: ViewType;
  flowsAppliedFilter: any;

  selectedGroupIds: number[];
  groupsAppliedFilter: any;
  groupsViewType: ViewType;

  isUserInfoFormValid: boolean;

  groupsLicenseConfirmation: {
    confirmed: boolean;
    show: boolean;
    proceed?: (value: any) => void;
  };

  licensesStep: {
    showModal: boolean;
    selectedItems: PackOverview[];
    areLicensesConfirmed: boolean;
    issueLicensePackIds: number[];
  };

  confirmation: {
    priorityItems: PriorityItem[];
    confirmed: boolean;
    show: boolean;
    proceed?: (value: any) => void;
  };

  userContent: Record<ContentType, Omit<SelectedItem, "canRemove" | "inherited">[]>;

  notifyStep: {
    isValid: boolean;
    isLoaded: boolean;
  };
}

export type Props = WithNotifyConfigProps<NotifySettings> & WithRouterProps & PropsFromRedux & LDProps;

export class AddUser extends Component<Props, AddUserState> {
  private readonly notifyByEmailControllerRef: React.RefObject<INotifyByEmailController>;

  constructor(props: Props & PropsFromRedux) {
    super(props);

    this.state = {
      isDirty: false,
      isUserDataSaved: false,
      rolesAppliedFilter: {},
      isUserInfoFormValid: false,
      selectedGroupIds: [],
      groupsAppliedFilter: {},
      groupsViewType: ViewType.LIST,
      flowsAppliedFilter: {},
      flowsViewType: ViewType.LIST,
      userContent: {
        [ContentType.Flow]: [],
        [ContentType.Video]: [],
        [ContentType.Event]: [],
        [ContentType.Survey]: [],
        [ContentType.Assessment]: [],
        [ContentType.Pdf]: [],
      },
      confirmation: {
        priorityItems: [],
        confirmed: true,
        show: false,
      },
      groupsLicenseConfirmation: {
        confirmed: true,
        show: false,
      },
      licensesStep: {
        showModal: false,
        selectedItems: [],
        areLicensesConfirmed: true,
        issueLicensePackIds: [],
      },
      notifyStep: {
        isValid: false,
        isLoaded: false,
      },
    };

    this.notifyByEmailControllerRef = createRef<INotifyByEmailController>();
  }

  userInfoProcessedCallback?: (value?: any) => void;

  userInfoNotProcessedCallback?: (reason?: any) => void;

  resolveLicensesStepProgress?: (value?: unknown) => void;
  rejectLicensesStepProgress?: (value?: unknown) => void;

  triggerSubmitUserInfo?: () => void;

  componentWillUnmount() {
    this.props.addUserActions.cancel();
    this.clearCallbacks();
    this.notifyByEmailControllerRef.current?.resetState();
  }

  saveUserInfo = async (userInfo: UserInfo) => {
    const userCreationTimeout = 40000;

    try {
      this.props.addUserActions.userInfoSave(userInfo);
      const event = userInfo.id ? rtnEvents.UserUpdated : rtnEvents.UserCreated;
      await RtnEventsEmitter.subscribeOnceAndWait(event, userCreationTimeout);
      userInfo.id && this.props.addUserActions.onUserInfoSaveSuccess(userInfo.id);
      this.setState({ isUserDataSaved: true });
      this.userInfoProcessedCallback?.();
      this.clearCallbacks();
    } catch {
      this.props.addUserActions.onUserInfoSaveFailure();
      this.userInfoNotProcessedCallback?.();
    }
  };

  clearCallbacks = () => {
    delete this.userInfoProcessedCallback;
    delete this.userInfoNotProcessedCallback;
  };

  onUserInfoSubmit = (userInfo: UserInfo) => {
    if (!isEqual(this.props.userDetails, userInfo)) {
      this.saveUserInfo(userInfo);
    } else {
      this.userInfoProcessedCallback?.();
      this.clearCallbacks();
    }
  };

  onFinishAsync = async () => {
    if (this.props.notifyConfigPayload.shouldNotify) {
      await this.notifyUser();
    }
    this.props.addUserActions.finishUserCreation();
    goToUsersOverview(this.props.navigate);
  };

  notifyUser = async () => {
    const notifyConfig = this.props.notifyConfigPayload;
    const notifyStepState = this.props.notifyStepState;
    let notifySettings;
    if (notifyConfig.communicationChannel === CommunicationChannels.Email) {
      notifySettings = await this.notifyByEmailControllerRef.current!.getSettings(
        notifyConfig.shouldNotify,
        notifyConfig.communicationChannel,
      );
    } else {
      notifySettings = {
        subject: notifyStepState.form.subject,
        previewText: notifyStepState.form.previewText,
        senderName: notifyStepState.form.senderName,
        senderEmail: notifyStepState.form.senderEmail,
        templateUri: notifyStepState.templateSasUrl,
        rawTemplateUri: notifyStepState.rawTemplateSasUrl,
        suppressNotification: !notifyConfig.shouldNotify,
        overrideNotification: notifyConfig.shouldNotify,
        notificationType: notifyStepState.templateType,

        communicationChannel: notifyConfig.communicationChannel,
        headline: notifyStepState.teamsForm.headline,
        body: notifyStepState.teamsForm.body,
        imageUrl: notifyStepState.teamsForm.imageUrl,
        callToAction: notifyStepState.teamsForm.callToAction,
        destinationUrl: notifyStepState.teamsForm.destinationUrl,
      };
    }
    if (!notifySettings.suppressNotification) {
      this.props.addUserActions.notifyUser(this.props.userDetails.id!, notifySettings);
    }
  };

  bindSubmitUserInfo = (submitForm: any) => {
    this.triggerSubmitUserInfo = submitForm;
  };

  onValueChange = (_: any[]) => {
    this.setState({ isUserDataSaved: false });
  };

  onCancel = () => navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, "/people/users");

  onSelectedRolesChanged = (selectedRoleIds: number[]) => {
    this.setState({ isDirty: true });
    this.props.addUserActions.changeRoles(selectedRoleIds);
  };

  onUserInfoValidChange = (isValid: boolean) => {
    this.setState({ isUserInfoFormValid: isValid });
  };

  noGroupsContent = () => <NoResults title="You don’t have any groups" iconClassName="fal fa-users" />;

  onSelectedGroupsChanged = (selectedGroupIds: number[]) => {
    this.setState((state) => ({
      groupsLicenseConfirmation: {
        ...state.groupsLicenseConfirmation,
        confirmed: false,
      },
      selectedGroupIds: selectedGroupIds,
    }));
  };

  resetGroupsFilter = () => {
    this.setState({ groupsAppliedFilter: {} });
  };

  setGroupsFilter = (filter: any) => {
    this.setState({ groupsAppliedFilter: filter });
  };

  onGroupsViewTypeChange = (viewType: ViewType) => {
    this.setState({ groupsViewType: viewType });
  };

  setRolesFilter = (rolesAppliedFilter: any) => this.setState({ rolesAppliedFilter });

  resetRolesFilter = () => this.setState({ rolesAppliedFilter: {} });

  getPackIdsToIssueLicense = () => this.state.licensesStep.issueLicensePackIds;

  onProgressAsync = async (activeStepIndex: AddUserTab) => {
    const { id, roleIds } = this.props.userDetails;
    const {
      isDirty,
      selectedGroupIds,
      groupsLicenseConfirmation: { confirmed: groupsConfirmed },
      confirmation: { priorityItems, confirmed: contentLicenseConfirmed },
      licensesStep: { areLicensesConfirmed },
    } = this.state;

    const packIds = this.getPackIdsToIssueLicense();

    switch (activeStepIndex) {
      case AddUserTab.Configure:
        this.triggerSubmitUserInfo?.();
        return new Promise((resolve, reject) => {
          this.userInfoProcessedCallback = resolve;
          this.userInfoNotProcessedCallback = reject;
        });
      case AddUserTab.Roles:
        if (isDirty) {
          this.props.addUserActions.updateRolesAssignment(roleIds, id);
          this.setState({ isDirty: false });
        }
        break;
      case AddUserTab.Groups:
        if (!groupsConfirmed && !isEmpty(selectedGroupIds)) {
          this.addGroups(id as number, selectedGroupIds);
        }
        break;
      case AddUserTab.Licenses:
        if (!areLicensesConfirmed && !isEmpty(packIds)) {
          this.issuePackLicenses(id as number, packIds);
        }
        break;
      case AddUserTab.Content:
        if (!contentLicenseConfirmed) {
          const { packContextItems, flowAssignments } = this.props;
          let assignments: EntityToPeopleAssignments[] = [];

          if (!!flowAssignments.length) {
            assignments = userListUtils.mapAssignmentFlows(
              id!,
              flowAssignments,
              priorityItems as FlowPriorityItem[],
              AssignmentPeopleContext.User,
            );
          } else {
            const assignmentItems = userListUtils.mapAssignmentItems(priorityItems, packContextItems);
            assignments = assignmentItems.map((item) =>
              userListUtils.mapToAssignmentItem(id!, item, item.blockType, AssignmentPeopleContext.User),
            );
          }
          this.props.assignContentToPeople({ peopleType: AssignmentPeopleContext.User, items: assignments });
          this.props.resetContentPacks();
          this.setState({ confirmation: { ...this.state.confirmation, confirmed: true } });
        }
        break;
      default:
        break;
    }

    return Promise.resolve();
  };

  onRegressAsync = async (activeStepIndex: number): Promise<void> => {
    const { id } = this.props.userDetails;
    const {
      selectedGroupIds,
      groupsLicenseConfirmation: { confirmed: groupsConfirmed },
      licensesStep: { areLicensesConfirmed },
    } = this.state;

    const packIds = this.getPackIdsToIssueLicense();

    if (activeStepIndex === AddUserTab.Groups && !groupsConfirmed && !isEmpty(selectedGroupIds)) {
      this.addGroups(id as number, selectedGroupIds);
    }

    if (activeStepIndex === AddUserTab.Licenses && !areLicensesConfirmed && !isEmpty(packIds)) {
      this.issuePackLicenses(id as number, packIds);
    }

    if (activeStepIndex !== AddUserTab.Notify || !this.state.notifyStep.isLoaded) {
      return;
    }
    await this.notifyByEmailControllerRef.current!.saveChanges();
  };

  issuePackLicenses = (userId: number, selectedIds: number[]) => {
    const assignments = selectedIds.map((packId) =>
      userListUtils.mapToAssignmentItem(userId, { id: packId }, "pack", AssignmentPeopleContext.User),
    );
    this.props.issuePackLicense({
      peopleType: AssignmentPeopleContext.User,
      items: assignments,
    });
    this.setState({ licensesStep: { ...this.state.licensesStep, areLicensesConfirmed: true } });
  };

  addGroups = (userId: number, groupIds: number[]) => {
    this.props.addUsersToGroups([userId], groupIds);
    this.setState({ groupsLicenseConfirmation: { ...this.state.groupsLicenseConfirmation, confirmed: true } });
  };

  // Groups License Confirmation
  triggerGroupLicenseConfirmation = (proceed: (value: any) => void) => {
    this.setState((state) => ({
      groupsLicenseConfirmation: {
        ...state.groupsLicenseConfirmation,
        show: true,
        proceed,
      },
    }));
  };

  onGroupsLicenseConfirmed = () => {
    const {
      groupsLicenseConfirmation: { proceed },
    } = this.state;

    this.setState(
      (state) => ({
        groupsLicenseConfirmation: {
          ...state.groupsLicenseConfirmation,
          show: false,
          proceed: undefined,
        },
      }),
      () => proceed?.(true),
    );

    this.resetGroupsLicensingModal();

    return Promise.resolve();
  };

  onGroupsLicenseConfirmationClosed = () => {
    const {
      groupsLicenseConfirmation: { proceed },
    } = this.state;

    this.setState(
      (state) => ({
        groupsLicenseConfirmation: {
          ...state.groupsLicenseConfirmation,
          show: false,
          proceed: undefined,
        },
      }),
      () => {
        proceed?.(false);
      },
    );
    this.resetGroupsLicensingModal();
  };

  resetGroupsLicensingModal = () => {
    this.props.resetLicensingModal();
  };

  // Licenses step
  handleLicensesModalClose = () => {
    this.setState({ licensesStep: { ...this.state.licensesStep, issueLicensePackIds: [], showModal: false } });
  };

  handleLicensesModalCancel = () => {
    this.handleLicensesModalClose();
    this.rejectLicensesStepProgress?.();
  };

  handleLicensesModalConfirm = () => {
    this.setState({
      licensesStep: { ...this.state.licensesStep, showModal: false },
    });
    this.resolveLicensesStepProgress?.();
  };

  onSelectionChange = (props: { items: PackOverview[]; type: SelectionChangedType }) => {
    const newItems =
      props.type === SelectionChangedType.Added
        ? union(this.state.licensesStep.selectedItems, props.items)
        : this.state.licensesStep.selectedItems.filter((a) => !props.items.map((b) => b.id).includes(a.id));
    this.setState({
      licensesStep: {
        ...this.state.licensesStep,
        selectedItems: newItems,
        areLicensesConfirmed: false,
      },
    });
  };

  onIssueLicensePacksChange = (ids: number[]) => {
    this.setState({
      licensesStep: { ...this.state.licensesStep, issueLicensePackIds: ids },
    });
  };

  // Content License Confirmation
  triggerLicenceConfirmation = (proceed: (value: any) => void) => {
    this.setState((state) => ({
      confirmation: {
        ...state.confirmation,
        show: true,
        proceed,
      },
    }));
  };

  canProceedAsync = (tab: AddUserTab) => {
    const {
      confirmation: { confirmed: contentLicenseConfirmed },
      groupsLicenseConfirmation: { confirmed: groupsLicenseConfirmed },
      licensesStep: { areLicensesConfirmed, selectedItems },
      selectedGroupIds,
    } = this.state;

    if (tab === AddUserTab.Groups && !groupsLicenseConfirmed && selectedGroupIds.length > 0) {
      return new Promise(async (resolve) => {
        const { id } = this.props.userDetails;
        const shouldShow = await this.props.shouldShowLicensingModal(selectedGroupIds, [id as number]);
        if (shouldShow) {
          this.triggerGroupLicenseConfirmation(resolve);
        } else {
          resolve(true);
        }
      });
    }

    if (tab === AddUserTab.Licenses && !isEmpty(selectedItems) && !areLicensesConfirmed) {
      this.setState({ licensesStep: { ...this.state.licensesStep, showModal: true } });

      return new Promise((resolve) => {
        this.resolveLicensesStepProgress = () => resolve(true);
        this.rejectLicensesStepProgress = () => resolve(false);
      });
    }

    if (tab === AddUserTab.Content && !contentLicenseConfirmed) {
      return new Promise((resolve) => {
        this.triggerLicenceConfirmation(resolve);
      });
    }

    return Promise.resolve(true);
  };

  onNotifyStepLoaded = (controller: INotifyByEmailController) => {
    // @ts-ignore
    this.notifyByEmailControllerRef.current = controller;
    this.setState({ notifyStep: { ...this.state.notifyStep, isLoaded: true } });
  };

  onNotifyStepValidChange = (isValid: boolean) => {
    this.setState({ notifyStep: { ...this.state.notifyStep, isValid } });
  };

  getConfigurationStepNextLabel = (): string => {
    const { isUserDataSaved } = this.state;
    return isUserDataSaved ? "Continue" : "Save & Continue";
  };

  render() {
    const {
      userDetails,

      // roles tab
      rolesList,
      fetchRolesFilterOptions,
      rolesFilterOptions,

      // groups tab
      accountId,
      groupsList,
      groupsGrid,
      groupsFilterActions,
      groupsFilterOptions,
      groupsOverviewActions: { fetchGroups, fetchGroupsLazy, resetGridItems },
      notifyConfig,
      notifyConfigPayload: { setNotifyConfig, shouldNotify, communicationChannel },
      isShouldShowModalLoading,
    } = this.props;

    const {
      isUserInfoFormValid,
      selectedGroupIds,
      groupsAppliedFilter,
      groupsViewType,
      groupsLicenseConfirmation,
      rolesAppliedFilter,
      notifyStep,
      isUserDataSaved,
    } = this.state;

    return (
      <>
        <Wizard
          id="create-user-wizard"
          className="create-user"
          title="Create User"
          onProgressAsync={this.onProgressAsync}
          onRegressAsync={this.onRegressAsync}
          canProceedAsync={this.canProceedAsync}
          canRecedeAsync={this.canProceedAsync}
          onCancel={this.onCancel}
          onFinishAsync={this.onFinishAsync}
          finishButtonLabel={shouldNotify ? "Finish & Send" : "Finish"}
        >
          <Wizard.Step
            label="User Info"
            required
            isLocked={!isUserInfoFormValid || userDetails.isLoading}
            nextButtonLabel={this.getConfigurationStepNextLabel()}
          >
            <UserInfoForm
              onSubmit={this.onUserInfoSubmit}
              userDetails={userDetails}
              bindSubmitForm={this.bindSubmitUserInfo}
              onIsValidChange={this.onUserInfoValidChange}
              isInitialValid={isUserDataSaved}
              onFieldValueChanged={this.onValueChange}
            />
          </Wizard.Step>
          <Wizard.Step label="Roles" className="no-scroll no-padding">
            <Restricted
              permissions={[RolePermissions.UsersManage, RolePermissions.RolesView]}
              permissionPredicate={(userPermissionsList, permissionsList) =>
                difference(permissionsList, userPermissionsList).length === 0
              }
              placeholder={<AccessRestrictedMessage />}
            >
              <RoleList
                onGetRoles={this.props.addUserActions.fetchRoles}
                roles={rolesList.items}
                rolesCount={rolesList.itemsCount}
                selectedRoleIds={userDetails.roleIds}
                isLoading={rolesList.isLoading}
                onSelectedRolesChanged={this.onSelectedRolesChanged}
                appliedFilter={rolesAppliedFilter}
                filterActions={{
                  setRolesFilter: this.setRolesFilter,
                  resetRolesFilter: this.resetRolesFilter,
                  fetchFilterOptions: fetchRolesFilterOptions,
                }}
                filterOptions={rolesFilterOptions}
              />
            </Restricted>
          </Wizard.Step>
          <Wizard.Step label="Groups" className="no-scroll no-padding">
            <Restricted
              permissions={[RolePermissions.UsersManage, RolePermissions.GroupsView]}
              permissionPredicate={(userPermissionsList, permissionsList) =>
                difference(permissionsList, userPermissionsList).length === 0
              }
              placeholder={<AccessRestrictedMessage />}
            >
              <GroupList
                accountId={accountId}
                groupsList={groupsList}
                groupsGrid={groupsGrid}
                selectedIds={selectedGroupIds}
                fetchGroups={fetchGroups}
                fetchGroupsLazy={fetchGroupsLazy}
                setGroupsFilter={this.setGroupsFilter}
                resetGroupsFilter={this.resetGroupsFilter}
                appliedGroupsFilter={groupsAppliedFilter}
                onSelectedGroupsChanged={this.onSelectedGroupsChanged}
                viewType={groupsViewType}
                onViewTypeChange={this.onGroupsViewTypeChange}
                noResultsContent={this.noGroupsContent}
                resetGridItems={resetGridItems}
                permanentFilter={permanentFilter}
                withEnrollmentFilter={false}
                filterActions={groupsFilterActions}
                filterOptions={groupsFilterOptions}
                disableViewHeader={isShouldShowModalLoading}
              />
              <GroupsAssignmentModalOneStep
                showModal={groupsLicenseConfirmation.show}
                onCancel={this.onGroupsLicenseConfirmationClosed}
                onConfirm={this.onGroupsLicenseConfirmed}
                selectedUserIds={[userDetails.id!]}
                selectedGroupIds={selectedGroupIds}
              />
            </Restricted>
          </Wizard.Step>
          <Wizard.Step label="Licenses" className="no-scroll no-padding">
            <>
              <LicensesStep
                selection={{ items: this.state.licensesStep.selectedItems, onSelect: this.onSelectionChange }}
                stepContext={AssignmentPeopleContext.User}
              />
              <AddPeoplePacksIssueLicenseModal
                userIds={[userDetails.id!]}
                packs={this.state.licensesStep.selectedItems}
                show={this.state.licensesStep.showModal}
                onCancel={this.handleLicensesModalCancel}
                onConfirm={this.handleLicensesModalConfirm}
                onIssueLicensePacksChange={this.onIssueLicensePacksChange}
              />
            </>
          </Wizard.Step>
          <Wizard.Step label="Notify" isLocked={shouldNotify && !notifyStep.isValid} preRender>
            <NotifyWizardStep
              templateType={TemplateTypes.WelcomeEmail}
              onLoaded={this.onNotifyStepLoaded}
              onValidChange={this.onNotifyStepValidChange}
              shouldNotify={shouldNotify}
              communicationChannel={communicationChannel}
              renderSwitch={(switchProps) => (
                <NotifyStepSwitch
                  config={notifyConfig}
                  onNotifyConfigChange={setNotifyConfig}
                  switchProps={switchProps}
                />
              )}
            />
          </Wizard.Step>
        </Wizard>
        <Dimmer active={isShouldShowModalLoading} inverted>
          <Loader />
        </Dimmer>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const { addUserReducer, groupsOverview, rolesOverview } = state.people;

  return {
    accountId: state.userProfile.accountId,
    rolesList: addUserReducer.rolesList,
    userDetails: addUserReducer.userDetails,
    groupsList: groupsOverview.groupsList,
    groupsGrid: groupsOverview.groupsGrid,
    groupsFilterOptions: groupsOverview.filterOptions,
    rolesFilterOptions: rolesOverview.filterOptions,
    userPermissions: state.userProfile.permissions,
    packContextItems: state.licensing.contentPacks.items,
    flowAssignments: state.licensing.contentPacks.flowAssignments,
    notifyStepState: state.systemNotifications.notifyStep,
    isShouldShowModalLoading: state.licensing.licensingModal.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addUserActions: bindActionCreators(addUserActions, dispatch),
  groupsOverviewActions: bindActionCreators(groupsOverviewActions, dispatch),
  groupsFilterActions: bindActionCreators(allGroupsFilterActions, dispatch),
  eventsGetFilterAction: () => dispatch(eventGetFilterOptions()),
  fetchRolesFilterOptions: bindActionCreators(rolesFilterActions.fetchFilterOptions, dispatch),
  assignContentToPeople: bindAction(assignContentDistributed, dispatch),
  resetContentPacks: bindActionCreators(resetContentPacksAction, dispatch),
  setSelectedContentType: bindAction(setSelectedContentTypeAction, dispatch),
  issuePackLicense: bindActionCreators(issuePackLicenseToPeople, dispatch),
  addUsersToGroups: bindActionCreators(addUsersToGroups, dispatch),
  shouldShowLicensingModal: bindAction(shouldShowModalForGroupMembership, dispatch),
  resetLicensingModal: bindAction(reset, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withNotifyConfig(withRouter(withLDConsumer()(AddUser)), initialNotifyConfigDefault));
