import * as events from "../events/library/libraryEvents";
import {
  updateEmailCommandSuccess,
  emailUpdated,
  publishDraftEmailSuccess,
  revertEmailEntityToPublishedSuccess,
  fetchLockedEmailSuccess,
} from "../../../../Library/Communications/Emails/state/actions/emailEntityStateActions";
import { IMapping } from "./types";
import { Dispatcher, MidnightActionPayload } from "../../../../../interfaces/redux";
import {
  sendTransientNotification,
  createSendTransientNotification,
} from "../../../../Notifications/state/notificationsActions";
import AlertTypes from "../../../../../enums/alertTypes";
import { createDispatcher } from "../dispatcher";

export const emailsMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const {
    dispatch,
    dispatchMap,
    dispatchMany,
    dispatchPublishSuccess,
    dispatchBatchOperationSuccess,
    dispatchBatchOperationFailure,
  } = createDispatcher(dispatcher);

  mapping[events.CancelSendSessionFailure] = (payload) => {
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.error,
        message: `Send wasn’t cancelled! ${payload.errorMessage}`,
      }),
    );
  };

  mapping[events.CancelSendSessionSuccess] = () => {
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.success,
        message: "Send Cancelled! Send has been cancelled successfully.",
      }),
    );
  };

  mapping[events.EmailEditSuccess] = dispatch(emailUpdated);
  mapping[events.EmailContentEditSuccess] = dispatch(emailUpdated);
  mapping[events.EmailUpdateCommandCompleted] = dispatch(updateEmailCommandSuccess);

  mapping[events.EmailPublishSuccess] = dispatchPublishSuccess<{ id: number; title: string }>(
    publishDraftEmailSuccess,
    (data) => `${data.title} was published!`,
  );

  mapping[events.EmailRevertSuccess] = (payload) => {
    dispatcher(revertEmailEntityToPublishedSuccess(payload.id));
  };

  mapping[events.EmailPeopleEditSuccess] = dispatchMany<any>([
    emailUpdated,
    createSendTransientNotification(
      {
        type: AlertTypes.success,
        message: "User(s) successfully added!",
      },
      3,
    ),
  ]);

  mapping[events.EmailTestSuccess] = (payload) => {
    const { testEmail } = payload;
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.success,
        message: `Email to ${testEmail} was sent successfully!`,
      }),
    );
  };

  mapping[events.EmailPeopleDeleteSuccess] = dispatchMany<any>([
    emailUpdated,
    createSendTransientNotification(
      {
        type: AlertTypes.success,
        message: "User(s) successfully deleted!",
      },
      3,
    ),
  ]);

  mapping[events.EmailPeopleDeleteFailure] = () => {
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.error,
        message: "User(s) can't be deleted from email!",
      }),
    );
  };

  mapping[events.EmailEditModeSuccess] = dispatchMap<{ id: number }, MidnightActionPayload>(
    fetchLockedEmailSuccess,
    (message) => ({ entityId: message.id }),
  );

  mapping[events.CreateEmailSendSessionSuccess] = () => {
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.success,
        message: "Send session was successfully created!",
      }),
    );
  };
  mapping[events.CreateEmailSendSessionFailure] = (payload) => {
    dispatcher(
      sendTransientNotification({
        type: AlertTypes.error,
        message: `Failed to create a send session! ${payload.errorMessage}`,
      }),
    );
  };

  mapping[events.EmailDeleteSuccess] = dispatchBatchOperationSuccess;
  mapping[events.EmailDeleteFailure] = dispatchBatchOperationFailure;

  mapping[events.AddEmailTagsSuccess] = dispatchBatchOperationSuccess;
  mapping[events.AddEmailTagsFailure] = dispatchBatchOperationFailure;

  mapping[events.EmailDuplicationSuccess] = dispatchBatchOperationSuccess;
  mapping[events.EmailDuplicationFailure] = dispatchBatchOperationFailure;
};
