import cn from "classnames";
import { max } from "d3";
import { useEffect, useMemo, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { Icon, Table } from "semantic-ui-react";

import CardReporting from "../../../../../components/cards/CardReporting/CardReporting";
import { HorizontalBarWrapper, TickValueType } from "../../../../../components/charts";
import * as assessmentPerformanceActions from "../../state/thunks/assessmentPerformanceThunk";

import { DataPoint } from "../../../../../components/charts/types/HorizontalBarChart";
import { RequestStatusRenderer } from "../../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { RootState } from "../../../../Application/globaltypes/redux";
import {
  resetAnswerData,
  selectQuestionResponsePeople,
  selectQuestionResponses,
  selectQuestionResponsesData,
  selectQuestionResponsesValue,
} from "../../state/slices/assessmentPerformanceSlice";

import { ListViewBase } from "../../../../../components/listView";
import { PerformanceTabSectionList } from "../../../Common/Performance/PerformanceTabSectionList/PerformanceTabSectionList";

import { ColumnOption } from "../../../../../interfaces/columnOptions";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { AssessmentPersonResponse, AssessmentQuestionAnswer, EngagementQuestionsData } from "../../types/state";

import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import dataService from "../../services/assessmentsDataService";

import { ReportUnavailable } from "components/reportUnavailable/ReportUnavailable";
import { RStatus } from "features/Application/globaltypes/fetchRequest";
import { PerformanceFilter, horizontalBarChartMargins, roundToTwoDigits } from "features/Library/Common/utils/performanceUtils";
import { Tooltip } from "components/common/tooltip";
import { TextTruncate } from "components";
import { bindAction } from "interfaces";
import { setHasDataToExport } from "features/Reporting/state/export/exportSlice";
import { Link } from "react-router-dom";
import { RouteNames } from "enums";
import moboConfigurator from "../../../../../moboConfigurator";

import "./AssessmentPerformanceDrillDown.scss";

export interface Props extends PropsFromRedux {
  selectedQuestionIndex: number;
  selectedQuestion: EngagementQuestionsData;
  selectedAnswer?: AssessmentQuestionAnswer;
  onSelectAnswer: (answer: AssessmentQuestionAnswer | undefined) => void;
  totalQuestionCount: number;
  assessmentId: number;
  flowId?: number;
  dateFilter: PerformanceFilter;
  hideHeader?: boolean;
  onNextQuestion: () => void;
  onPrevQuestion: () => void;
}

export const AssessmentPerformanceDrillDown: React.FC<Props> = ({
  selectedQuestionIndex,
  selectedQuestion,
  selectedAnswer,
  onSelectAnswer,
  totalQuestionCount,
  assessmentId,
  flowId,
  dateFilter,
  hideHeader,
  questionResponsesStatus,
  questionResponsesData,
  questionResponsesPeopleState,
  questionResponsesPeopleList,
  actions,
  resetAnswerData,
  onNextQuestion,
  onPrevQuestion,
  setHasExportData,
}) => {
  const [slicedEntities, setSliceEntities] = useState<AssessmentPersonResponse[]>([]);

  const reportEnabled = useFeatureFlag(FeatureFlags.AssessmentReport);

  useEffect(() => {
    setHasExportData(questionResponsesPeopleState.status === RStatus.Got && !!questionResponsesPeopleList.length);
  }, [setHasExportData, questionResponsesPeopleState.status, questionResponsesPeopleList]);

  useEffect(() => {
    if (selectedQuestion && reportEnabled) {
      actions.fetchQuestionResponsesData(assessmentId, selectedQuestion.QuestionId, { ...dateFilter, flowId });
    }
  }, [actions, assessmentId, dateFilter, flowId, selectedQuestion, reportEnabled]);

  useEffect(() => {
    // This should be removed once issue with answer click on v2
    // resolved
    if (selectedAnswer && reportEnabled) {
      dataService.cancelAssessmentQuestionsPeople();
      // Only fetch if there is data
      if (selectedAnswer.ChoicePercent > 0) {
        actions.fetchEngagementQuestionsPeopleData(assessmentId, selectedQuestion.QuestionId, selectedAnswer.AnswerId, {
          ...dateFilter,
          flowId,
        });
      }
      // Clear fetched answer data if we're changing to an answer with no data
      else {
        resetAnswerData();
      }
    }
  }, [
    actions,
    assessmentId,
    selectedQuestion.QuestionId,
    selectedAnswer,
    resetAnswerData,
    dateFilter,
    flowId,
    reportEnabled,
  ]);

  const formattedResponses = useMemo(() => {
    if (questionResponsesStatus.status !== RStatus.Got) return [];

    return questionResponsesData.Answers.map<DataPoint>((answer) => {
      let calculatedValue;
      if (questionResponsesData.CorrectAttempts && questionResponsesData.IncorrectAttempts) {
        calculatedValue = Math.round(
          (questionResponsesData.CorrectAttempts + questionResponsesData.IncorrectAttempts) * answer.ChoicePercent,
        );
      } else if (questionResponsesData.CorrectAttempts) {
        calculatedValue = 100;
      } else {
        calculatedValue = 0;
      }

      if (answer.IsAnswerCorrect) {
        return {
          id: answer.AnswerId,
          category: answer.AnswerTitle,
          value: calculatedValue,
          fillColor: "#62cd6e",
          iconClass: "green check circle",
        };
      } else {
        return {
          id: answer.AnswerId,
          category: answer.AnswerTitle,
          value: calculatedValue,
        };
      }
    });
  }, [questionResponsesData, questionResponsesStatus.status]);

  const columns: ColumnOption<AssessmentPersonResponse>[] = [
    {
      name: "Name",
      width: 7,
      isSortable: false,
      render(user) {
        return (
          <div>
            <Link className="linkButton" to={moboConfigurator.appendMoboIdToUrl(`/${RouteNames.peopleUsers}/${user.UserId}`)}>
              <Tooltip
                content={`${user.FirstName} ${user.LastName}`}
                target={
                  <TextTruncate>
                    {user.FirstName} {user.LastName}
                  </TextTruncate>
                }
              />
            </Link>
            <div className="userDepartmentTitle">
              {/* MISSING: user.Title (job title) */}
              <Tooltip
                content={`${user.Department}`}
                target={
                  <TextTruncate>
                    {user.Department}
                  </TextTruncate>
                }
              />
            </div>
          </div>
        );
      },
    },
    {
      name: "Email",
      width: 7,
      isSortable: false,
      render(user) {
        return <Tooltip
          target={
            <TextTruncate>
              {user.Email}
            </TextTruncate>
          }
          content={`${user.Email}`}
        />;
      },
    },
    {
      name: "Date",
      width: 7,
      isSortable: false,
      render(user) {
        return dateTimeUtils.formatEmptyDate(user.Date);
      },
    },
    {
      name: "Account",
      width: 7,
      isSortable: false,
      render(user) {
        return user.AccountName ?? "-";
      },
    },
  ];

  const buildTableBody = (item: AssessmentPersonResponse) => {
    return columns.map((column, index) => (
      <Table.Cell width={column.width} key={`${column.name}_${index}`}>
        {column.render(item)}
      </Table.Cell>
    ));
  };

  const selectAnswer = (index: number | null) => {
    if (index !== null && index !== -1) {
      const answerId = formattedResponses[index].id;
      const toIndex = selectedQuestion.Answers.findIndex((p) => p.AnswerId === answerId);
      if (toIndex === -1) {
        // This should never happen, if the selectedQuestion and another
        // API request are not in sync
        return;
      }
      onSelectAnswer(selectedQuestion.Answers[toIndex]);
    }
  };

  const getData = (skip: number, top: number) => {
    setSliceEntities(questionResponsesPeopleList.slice(skip, skip + top));
  };

  if (!reportEnabled) {
    return <ReportUnavailable />;
  }

  return (
    <div className="drillDownRoot">
      {!hideHeader && (
        <div className="navigation">
          <div className="questionTitle">{selectedQuestion.QuestionText}</div>
          <div className="assessmentActions">
            <span className="questionCount">
              {selectedQuestionIndex + 1} / {totalQuestionCount} questions
            </span>
            <button
              title="Previous question"
              aria-label="Previous question"
              data-testid="previousQuestion"
              className={cn("invisibleButton", "leftSpacing")}
              onClick={onPrevQuestion}
            >
              <Icon className="chevron left" />
            </button>
            <button
              title="Next question"
              aria-label="Next question"
              data-testid="nextQuestion"
              className={cn("invisibleButton", "leftSpacing")}
              onClick={onNextQuestion}
            >
              <Icon className="chevron right" />
            </button>
          </div>
        </div>
      )}
      <div className="cardSection">
        <RequestStatusRenderer state={questionResponsesStatus}>
          <CardReporting
            items={[
              {
                statDescription: "Incorrect Attempts",
                stat: questionResponsesData.IncorrectAttempts ?? 0,
                popupHeader: "Incorrect Attempts",
                popupBody: "Total number of times the question has been answered incorrectly by users.",
                width: "18rem",
              },
              {
                statDescription: "Correct Answers",
                stat: questionResponsesData.CorrectAttempts ?? 0,
                popupHeader: "Correct Answers",
                popupBody: "Total number of times the question has been answered correctly by users.",
                width: "18rem",
              },
              {
                statDescription: "Avg Attempts",
                stat: questionResponsesData.AvgAttempts ?? 0,
                popupHeader: "Average Attempts",
                popupBody: "Average number of question attempts per user",
                width: "13.1rem",
              },
              {
                statDescription: "Completion Rate",
                stat: questionResponsesData.CompletionRate
                  ? `${roundToTwoDigits(questionResponsesData.CompletionRate * 100)}%`
                  : "0%",
                popupHeader: "Completion Rate",
                popupBody: "Percentage of users who have completed the question",
                width: "7.1rem",
              },
            ]}
          />
        </RequestStatusRenderer>
      </div>
      <div className="barChart">
        <RequestStatusRenderer state={questionResponsesStatus}>
          <HorizontalBarWrapper
            title="Responses"
            margins={horizontalBarChartMargins}
            selectedValue={selectedAnswer?.AnswerId}
            onChange={selectAnswer}
            data={formattedResponses}
            countBarsToShowAtOnce={7}
            domain={[0, max(formattedResponses, (f) => f.value) || 1]}
            tooltipFormatter={(d) => {
              const foundAnswer = questionResponsesData.Answers.find((a) => a.AnswerId === d.id);
              const displayValue = isNaN(d.value) ? "0" : d.value.toLocaleString();
              if (!foundAnswer) return displayValue;
              return `${displayValue} (${Math.round((foundAnswer as any).ChoicePercent * 100)}%)`;
            }}
            xTickValueType={TickValueType.IntegersOnly}
          />
        </RequestStatusRenderer>
      </div>
      <div className="answer-details-container">
        <RequestStatusRenderer state={questionResponsesPeopleState}>
          {questionResponsesPeopleList && (
            <PerformanceTabSectionList
              title="People"
              count={questionResponsesPeopleList.length}
              renderList={(page) => (
                <ListViewBase
                  columnOptions={columns}
                  items={slicedEntities}
                  loadPage={getData}
                  itemsAmount={questionResponsesPeopleList.length}
                  buildTableBody={buildTableBody}
                  withFooter={false}
                  activePage={page}
                  withSelection={false}
                />
              )}
            />
          )}
        </RequestStatusRenderer>
      </div>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    questionResponsesStatus: selectQuestionResponsesData(state),
    questionResponsesData: selectQuestionResponsesValue(state),
    questionResponsesPeopleState: selectQuestionResponsePeople(state),
    questionResponsesPeopleList: selectQuestionResponses(state),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(assessmentPerformanceActions, dispatch),
    resetAnswerData: bindActionCreators(resetAnswerData, dispatch),
    setHasExportData: bindAction(setHasDataToExport, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssessmentPerformanceDrillDown);
