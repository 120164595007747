import * as Yup from "yup";
import { SourceType } from "../../features/Library/Flows/Designer/types/externallTriggersTypes";

const expressions = ["Microsoft", "BrainStorm"];
export const externalTrigger = Yup.object().shape({
  externalTriggerGroups: Yup.array().of(
    Yup.object().shape({
      triggers: Yup.array().of(
        Yup.object().shape({
          software: Yup.string().nullable().required("Software text is required"),
          application: Yup.string().nullable().required("Application text is required"),
          action: Yup.string().nullable().required("Action text is required"),
          filter: Yup.string()
            .nullable()
            .when("software", {
              is: (software: SourceType) => expressions.includes(software),
              then: () => Yup.string().nullable().required("Filter text is required"),
            }),
          criteria: Yup.string()
            .nullable()
            .when("software", {
              is: (software: SourceType) => expressions.includes(software),
              then: () => Yup.string().nullable().required("Criteria text is required"),
            }),
        }),
      ),
    }),
  ),
});
