import React from "react";

import cn from "classnames";
import { baseIconButtonPropTypes } from "../baseIconButtonPropTypes";

import "./plusButton.scss";

function PlusButton(props) {
  const { onClick, className, isDisabled } = props;

  return (
    <div
      data-testid="plus-button"
      className={cn("plus-button", className)}
      onClick={onClick}
      disabled={isDisabled || !onClick}
    />
  );
}

PlusButton.propTypes = baseIconButtonPropTypes;

export default PlusButton;
