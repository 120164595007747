/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { noop } from "lodash";

import Segments from "../../../../components/navigation/segments/Segments";
import { EditSurveySteps, EnumDictionary, showLastModified } from "./types";
import Configure from "../SurveyDetails/Configure/Configure";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import * as surveyEntityStateActions from "../state/actions/surveyEntityStateActions";
import * as surveyDetailsActions from "../state/actions/surveyDetailsActions";
import * as surveyContentValidationActions from "../state/actions/surveyContentValidationActions";
import * as commonSurveyActions from "../state/actions/commonSurveyActions";
import EditSurveySubheaderContainer from "./EditSurveySubheaderContainer";
import Content from "../SurveyDetails/Content/Content";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import ModalManager from "../../../../utils/ModalManager";
import ModalTypes from "../../../../components/modal/ModalTypes";
import RevertConfirmationModalTs from "../../../../components/modal/RevertConfirmationModalTs";
import Observable from "../../../../utils/Observable";
import EditSurveyPeople from "./People/EditSurveyPeople";
import Restricted from "../../../Application/Restricted";
import { TemplateTypes, RolePermissions, UsersGroupsContext, PublishedStatusTypes, RouteNames } from "../../../../enums";
import { useWizardStepsManager } from "../../../../hooks/useWizardStepsManager";
import { surveysStateSelector } from "../state/surveyReducer";
import EditSurveyDetailsHeader from "./EditSurveyDetailsHeader/EditSurveyDetailsHeader";
import { useRtn } from "../../../../hooks/useRtn";
import PublishConfirmationModal, {
  EntityState,
} from "../../../../components/modal/PublishConfirmationModal/PublishConfirmationModal";
import { NotifyStepSettings, bindAction, ISegment } from "../../../../interfaces";
import { fetchSurveyDependencies } from "../state/thunks/surveyCommonThunk";
import { NotifyStepConfig, ShouldNotifyWithProgressIds } from "../../../SystemNotifications/types";
import { SwitchEntityTypes } from "../../../../components/notifyStep/NotifyStepContent/switches/SwitchWithProgress";
import SettingsTab from "../SurveyDetails/Settings/SettingsTab";
import SurveyPerformance from "../Performance/SurveyPerformance";
import {
  resetAppliedFilter,
  resetPagination,
  resetSearch,
  resetSortingColumnName,
  resetSortingDirection,
} from "../state/slices/surveyFiltersSlice";
import * as surveyOverviewActions from "../state/actions/surveyOverviewActions";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";
import AssociatedPacks from "features/Licensing/Packs/AssociatedPacks/AssociatedPacks";
import { permissionPredicateForPacks } from "features/Library/Common/utils/performanceUtils";

export type EditSurveyPropsAll = PropsFromRedux;

export const EditSurvey = (props: EditSurveyPropsAll) => {
  const { resetSearchFilters } = props;
  const surveysOverviewUrl = `/${RouteNames.contentSurveys}`;
  const showPacksTab = useFeatureFlag(FeatureFlags.AssociatedPacks);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const surveyId = Number(params.id);
  const pageIndexes = [
    EditSurveySteps.Performance,
    EditSurveySteps.Configuration,
    EditSurveySteps.Settings,
    EditSurveySteps.Content,
    EditSurveySteps.People,
    EditSurveySteps.AssociatedPacks,
  ];
  const [wizardPages, { goToPage, goBack }, subscribeOnActiveIndexChanged] = useWizardStepsManager(pageIndexes);
  const revertModalManager = useMemo(
    () =>
      new ModalManager(
        ModalTypes.RevertSurvey,
        () => props.entityStateActions.fetchDiscardSurveyEntity(surveyId),
        false,
        props.userId,
      ),
    [surveyId, props.userId],
  );
  const onDiscardedObserver = useMemo(() => new Observable<() => void>(), []);
  const onTriggerPublishContentObserver = useMemo(() => new Observable(), []);

  const [activeTab, setActiveTab] = useState(0);

  const isPublishedAndNotPurchased = !props.survey.isDraft && !props.survey.isPurchased;
  const [usersGroupsContext, setUsersGroupsContext] = useState(UsersGroupsContext.Groups);
  const loadSurveyInfo = () => {
    if (!props.isSurveyInfoLoaded) {
      props.detailsActions.getSurvey(surveyId);
    }
  };

  const loadSurveyContent = () => {
    if (!props.isContentLoaded) {
      props.detailsActions.getSurveyContent(surveyId);
    }
    loadSurveyInfo();
  };

  const pages: EnumDictionary<EditSurveySteps, ISegment> = useMemo(() => {
    return {
      [EditSurveySteps.Performance]: {
        to: "",
        label: "Performance",
        onClick: () => {
          goToPage(EditSurveySteps.Performance);
        },
      },
      [EditSurveySteps.Configuration]: {
        to: "configuration",
        label: "Configuration",
        onClick: () => {
          goToPage(EditSurveySteps.Configuration);
        },
      },
      [EditSurveySteps.Settings]: {
        to: "settings",
        label: "Settings",
        onClick: () => {
          goToPage(EditSurveySteps.Settings);
        },
      },
      [EditSurveySteps.Content]: {
        to: "content",
        label: "Content",
        onClick: () => {
          goToPage(EditSurveySteps.Content);
        },
      },
      [EditSurveySteps.People]: {
        to: "people",
        label: "People",
        onClick: () => {
          goToPage(EditSurveySteps.People);
        },
      },
      [EditSurveySteps.AssociatedPacks]: {
        to: "packs",
        label: "Associated Packs",
        onClick: () => {
          goToPage(EditSurveySteps.AssociatedPacks);
        },
      },
    };
  }, [goToPage]);

  const [isValid, setIsValid] = useState(true);
  useLayoutEffect(() => {
    const handler = (_: any, activeStepIndex: EditSurveySteps) => {
      pages[activeStepIndex].init?.();
      setActiveTab(activeStepIndex);
    };
    return subscribeOnActiveIndexChanged(handler);
  }, [pages, subscribeOnActiveIndexChanged]);

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes(`/${RouteNames.contentSurveys}`)) resetSearchFilters();
    };
  }, [resetSearchFilters]);

  useEffect(() => {
    loadSurveyContent();
    return () => {
      props.onUnmount();
      revertModalManager.dispose();
    };
  }, []);

  const onPublishedOrDiscardEvent = () => {
    onDiscardedObserver.notify();
    props.onDiscard(surveyId);
  };

  useRtn(
    [rtnEvents.SurveyPublishSuccess, rtnEvents.SurveyDiscardSuccess, rtnEvents.SurveyEditSuccess],
    onPublishedOrDiscardEvent,
  );

  const onErrorClick = () => {
    const redirectTo = `${surveysOverviewUrl}/${surveyId}/${pages[EditSurveySteps.Content].to}`;
    if (!location.pathname.includes(redirectTo)) {
      navigate(redirectTo);
      goToPage(EditSurveySteps.Content);
    }
  };

  const back = () => {
    if (!goBack()) {
      return surveysOverviewUrl;
    }
  };

  const onRevertClick = () => revertModalManager.execute();

  const renderRevertModal = () =>
    props.hasBeenPublished && <RevertConfirmationModalTs modalManager={revertModalManager} />;

  const onPublishTrigger = async () => {
    const { survey } = props;
    // https://brainstorm.atlassian.net/browse/SAAS-25549
    let usersCount = survey.hasBeenPublished ? 1 : 0;

    const flowsCount = survey.flows.length;
    const entityState = {
      itemsCount: flowsCount,
      entityId: surveyId,
      assignmentsCount: usersCount,
      templateType: survey.hasBeenPublished ? TemplateTypes.SurveyChanged : TemplateTypes.AddedToSurvey,
      notifyConfigType: survey.hasBeenPublished ? NotifyStepConfig.WithProgress : NotifyStepConfig.Default,
      entityType: SwitchEntityTypes.Survey,
    };

    triggerPublish(Boolean(entityState.assignmentsCount > 0), entityState);
  };

  const triggerPublish = (condition: boolean, entityState: EntityState) => {
    return condition ? onTriggerPublishContentObserver.notify(noop, entityState) : handlePublish(surveyId);
  };

  const handlePublish = (
    id: number,
    notificationSettings?: NotifyStepSettings,
    peopleToNotifyTypes?: ShouldNotifyWithProgressIds[],
  ) => {
    props.validateActions.tryPublish(id, notificationSettings, peopleToNotifyTypes);
  };

  return (
    <Restricted
      permissions={[RolePermissions.AssetsCreate]}
      renderContent={(hasPermission: boolean) => (
        <div className="nested-content edit-assessment scrollable-content">
          <EditSurveyDetailsHeader
            surveyId={surveyId}
            surveysOverviewUrl={surveysOverviewUrl}
            title={props.title}
            canBeEdited={isPublishedAndNotPurchased}
            crudPermission={hasPermission}
            canBePublished={props.isDraft && props.contentResolved}
            isRevertVisible={props.hasBeenPublished}
            invalidFormDetails={!isValid}
            publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!props.isDraft)}
            hideEntityStateButtons={activeTab === EditSurveySteps.People}
            onErrorClick={onErrorClick}
            onPublishClick={onPublishTrigger}
            entityStateActions={{
              onRevert: onRevertClick,
            }}
            back={back}
            canAddPacks={isPublishedAndNotPurchased}
            showAddPacksButton={activeTab === EditSurveySteps.AssociatedPacks}
            permissionPredicate={permissionPredicateForPacks}
          />
          {renderRevertModal()}
          <PublishConfirmationModal
            onTriggerPublishContentObserver={onTriggerPublishContentObserver}
            onConfirmAction={handlePublish}
            contentType="survey"
            dependencyType="flow"
          />
          <Dimmer active={props.isLoading} inverted>
            <Loader />
          </Dimmer>
          <EditSurveySubheaderContainer
            publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!props.isDraft)}
            isUpdateInProgress={props.isDraft}
            lastModifiedDateTime={props.survey.dateModified}
            showLastModified={showLastModified.has(activeTab)}
          >
            <Segments to={`${surveysOverviewUrl}/${surveyId}`}>
              <Segments.Segment {...pages[EditSurveySteps.Performance]} />
              <Segments.Segment {...pages[EditSurveySteps.Configuration]} />
              <Segments.Segment {...pages[EditSurveySteps.Settings]} />
              <Segments.Segment {...pages[EditSurveySteps.Content]} />
              <Segments.Segment {...pages[EditSurveySteps.People]} />
              {showPacksTab && <Segments.Segment {...pages[EditSurveySteps.AssociatedPacks]} />}
            </Segments>
          </EditSurveySubheaderContainer>
          <div className="scrollable-content">
            <Routes>
              <Route
                path={pages[EditSurveySteps.Performance].to}
                element={
                  <div className="edit-form">
                    <SurveyPerformance
                      acceptHandlers={wizardPages[EditSurveySteps.Performance]}
                      surveyId={surveyId}
                      surveyTitle={props.title}
                    />
                  </div>
                }
              />
              <Route
                path={pages[EditSurveySteps.Configuration].to}
                element={
                  <div className="edit-form">
                    <Configure
                      entityId={surveyId}
                      acceptHandlers={wizardPages[EditSurveySteps.Configuration]}
                      subscribeOnDiscarded={onDiscardedObserver.subscribe}
                      unSubscribeOnDiscarded={onDiscardedObserver.unsubscribe}
                      disabled={!props.isDraft}
                      mode="edit"
                      onIsValidChange={setIsValid}
                    />
                  </div>
                }
              />
              <Route
                path={pages[EditSurveySteps.Settings].to}
                element={
                  <div className="edit-form">
                    <SettingsTab entityId={surveyId} acceptHandlers={wizardPages[EditSurveySteps.Settings]} />
                  </div>
                }
              />
              <Route
                path={pages[EditSurveySteps.Content].to}
                element={
                  <div className="edit-form">
                    <Content
                      entityId={surveyId}
                      acceptHandlers={wizardPages[EditSurveySteps.Content]}
                      subscribeOnDiscarded={onDiscardedObserver.subscribe}
                      unSubscribeOnDiscarded={onDiscardedObserver.unsubscribe}
                      disabled={!props.isDraft}
                    />
                  </div>
                }
              />
              <Route
                path={pages[EditSurveySteps.People].to}
                element={
                  <EditSurveyPeople
                    entityId={surveyId}
                    acceptHandlers={wizardPages[EditSurveySteps.People]}
                    usersGroupsContext={usersGroupsContext}
                    onContextChanged={setUsersGroupsContext}
                  />
                }
              />
              {showPacksTab && (
                <Route
                  path={pages[EditSurveySteps.AssociatedPacks].to}
                  element={
                    <AssociatedPacks
                      acceptHandlers={wizardPages[EditSurveySteps.AssociatedPacks]}
                      contentType={"Survey"}
                      contentId={surveyId}
                      canAddPacks={isPublishedAndNotPurchased}
                      permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate, RolePermissions.PacksManage]}
                      permissionPredicate={permissionPredicateForPacks}
                    />}
                />)}
              <Route path="*" element={<Navigate to="../" replace />} />
            </Routes>
          </div>
        </div>
      )}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const surveys = surveysStateSelector(state);
  const base = surveys.base;
  return {
    title: base.surveysDetailsReducer.title,
    survey: base.surveysDetailsReducer.survey,
    isDraft: base.surveysDetailsReducer.isDraft,
    isLoading: base.surveysDetailsReducer.isLoading,
    isSurveyInfoLoaded: base.surveysDetailsReducer.isSurveyInfoLoaded,
    isContentLoaded: base.surveysDetailsReducer.isContentLoaded,
    contentResolved: base.surveysContentValidationReducer.resolved,
    hasBeenPublished: base.surveysDetailsReducer.survey.hasBeenPublished,
    userId: Number(state.userProfile.id),
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  entityStateActions: bindActionCreators(surveyEntityStateActions, dispatch),
  detailsActions: bindActionCreators(surveyDetailsActions, dispatch),
  validateActions: bindActionCreators(surveyContentValidationActions, dispatch),
  fetchDependencies: bindAction(fetchSurveyDependencies, dispatch),
  onDiscard: (id: number) => {
    dispatch(commonSurveyActions.discardAction());
    dispatch(surveyDetailsActions.getSurvey(id));
    dispatch(surveyDetailsActions.getSurveyContent(id));
  },
  onUnmount: () => {
    dispatch(surveyDetailsActions.clearSurvey());
    dispatch(surveyContentValidationActions.resetAction());
  },
  resetSearchFilters: () => {
    dispatch(surveyOverviewActions.resetAction());
    dispatch(resetSearch());
    dispatch(resetAppliedFilter());
    dispatch(resetPagination());
    dispatch(resetSortingColumnName());
    dispatch(resetSortingDirection());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditSurvey);
