import ValidatedField from "./ValidatedField";
import { WeekDayPicker } from "../weekDayPicker";
import { WeekDayPickerProps } from "../weekDayPicker/WeekDayPicker";
import { FormikProps } from "formik";

export interface WeekDayPickerFieldProps {
  propertyName: string;
  label: string;
  markAsRequired?: boolean;
  onBlur?: (propertyName: string) => void;
}

export type WeekDayPickerFieldPropsAll<T> = WeekDayPickerProps & WeekDayPickerFieldProps & FormikProps<T>;

export default function WeekDayPickerField<T>(props: WeekDayPickerFieldPropsAll<T>) {
  const handleChange = async (data: number[]) => {
    const { propertyName, setFieldTouched, setFieldValue, onBlur } = props;
    setFieldTouched(propertyName, true, false);
    await (setFieldValue(propertyName, data) as unknown as Promise<void>);
    onBlur?.(propertyName);
  };

  return (
    <ValidatedField {...props}>
      <WeekDayPicker {...props} onChange={handleChange} />
    </ValidatedField>
  );
}
