export enum FlowEditDetailsSteps {
  Performance,
  Configuration,
  Settings,
  Content,
  People,
  Goal,
  AssociatedPacks,
}

export enum FlowCreateDetailsSteps {
  Configuration,
  Settings,
  Content,
  People,
  Goal,
}

export const EditFlowStepConfig = {
  [FlowEditDetailsSteps.Performance]: { showAutoSaveOnTab: false },
  [FlowEditDetailsSteps.Configuration]: { showAutoSaveOnTab: true },
  [FlowEditDetailsSteps.Settings]: { showAutoSaveOnTab: false },
  [FlowEditDetailsSteps.Content]: { showAutoSaveOnTab: true },
  [FlowEditDetailsSteps.People]: { showAutoSaveOnTab: false },
  [FlowEditDetailsSteps.Goal]: { showAutoSaveOnTab: true },
  [FlowEditDetailsSteps.AssociatedPacks]: { showAutoSaveOnTab: false },
};
