import dataService from "../../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/threatDefenceSendingProfilesOverviewActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import { GetThreatDefenceSendingProfilesRequest } from "../../types/requests";
import { Dispatcher, FetchActionPayload } from "../../../../../../interfaces/redux";
import { ThreatDefenceSendingProfileOverview } from "../../types/state";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;

export const getThreatDefenceSendingProfilesAsync = (take: number, skip: number, orderBy: string) => {
  const begin = getActionBaseProvider(actionTypes.getThreatDefenceSendingProfilesBegin);
  const success = getActionProvider<FetchActionPayload<ThreatDefenceSendingProfileOverview>>(
    actionTypes.getThreatDefenceSendingProfilesSuccess,
  );
  const failure = getActionProvider<Error>(actionTypes.getThreatDefenceSendingProfilesFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    const request: GetThreatDefenceSendingProfilesRequest = {
      top: take,
      skip,
      orderBy,
    };
    try {
      const result = await dataService.threatDefence.getThreatDefenceSendingProfilesAsync(request);
      const recordsCount = Number.parseInt(result.headers[countHeaderName]);

      const data: FetchActionPayload<ThreatDefenceSendingProfileOverview> = {
        items: result.data,
        totalCount: recordsCount,
      };

      dispatch(success(data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};

export const hasAccountDirectSendSendingProfileAsync = () => {
  const begin = getActionBaseProvider(actionTypes.hasAccountDirectSendSendingProfileBegin);
  const success = getActionProvider<boolean>(actionTypes.hasAccountDirectSendSendingProfileSuccess);
  const failure = getActionProvider<Error>(actionTypes.hasAccountDirectSendSendingProfileFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());
    try {
      const result = await dataService.threatDefence.hasAccountDirectSendSendingProfileAsync();

      dispatch(success(result.data));
    } catch (error) {
      dispatch(failure(error));
    }
  };
};
