import { FC, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../globaltypes/redux";
import dataService from "../services/dataServices/typedDataService";

export const ChameleonProvider: FC<PropsFromRedux> = ({ userId, accountId, accountTypeId, packIds, groupIds }) => {
  useEffect(() => {
    if (!window.chmln) {
      return;
    }

    const addChameleon = async () => {
      const { data } = await dataService.users.getUserSigningSecret();
      window.chmln.identify(userId, {
        accountId: accountId,
        accountTypeId: accountTypeId,
        packIds: packIds,
        groupIds: groupIds,
        uid_hash: data,
        company: {
          uid: accountId,
        },
      });
    };

    addChameleon();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.userProfile.id,
  accountId: state.userProfile.accountId,
  accountTypeId: state.userProfile.accountTypeId,
  packIds: state.userProfile.packIds,
  groupIds: state.userProfile.groupIds,
});

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ChameleonProvider);
