import cn from "classnames";
import React, { MutableRefObject, useRef } from "react";
import { IObservable } from "../../../../../interfaces";
import { IFlowInfo } from "../../Info/types";

import { ReactFlowProvider } from "reactflow";
import { FlowValidator } from "../validator/FlowValidator";
import { ReactFlowCanvasProvider } from "../ReactFlowCanvas/Providers/ReactFlowCanvasProvider";
import { Loader } from "semantic-ui-react";
import { ErrorNavigationProvider } from "../FlowComponentsPanel/ErrorNavigationProvider";
import { EntityTriggerTypes, SavedFlowData, TriggerTimeUnit } from "../types";
import { IFlowEdge, IFlowNode } from "../ReactFlowCanvas/nodes/types";

import ControlPanel from "../ControlPanel/ControlPanel";
import ReactFlowCanvas from "../ReactFlowCanvas/ReactFlowCanvas";
import AssetDragLayer from "../AssetDragLayer/AssetDragLayer";
import AssetInspector from "../AssetInspector/AssetInspector";

import "./DropAreaReactFlow.scss";
import { ReactFlowStateProvider } from "../ReactFlowCanvas/Providers/ReactFlowStateProvider";

export interface IDropAreaReactFlowOwnProps {
  flowInfo: IFlowInfo;
  flowDefinition: SavedFlowData;
  flowDefinitionLoading: boolean;
  triggerTypes: EntityTriggerTypes[];
  triggerTimeUnits: TriggerTimeUnit[];
  isReadOnly: boolean;
  onSavingProcessBegin: () => void;
  onSavingProcessSuccess: (id?: string) => void;
  flowValidator: FlowValidator;
  onResetSelectedElementsObserver?: IObservable<() => void>;
  flowDataRef: MutableRefObject<{ nodes: IFlowNode[]; edges: IFlowEdge[]; headId?: string } | undefined>;
  externalTriggerGroupsLength: number;
}

const DropAreaReactFlow: React.FC<IDropAreaReactFlowOwnProps> = ({
  flowInfo,
  flowDefinition,
  flowDefinitionLoading,
  triggerTypes,
  triggerTimeUnits,
  onSavingProcessBegin,
  onSavingProcessSuccess,
  isReadOnly,
  flowValidator,
  onResetSelectedElementsObserver,
  flowDataRef,
  externalTriggerGroupsLength,
}): React.ReactElement => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);

  return (
    <div className="drop-area-react-flow">
      {flowDefinitionLoading && <Loader active />}
      {flowDefinition && (
        <ReactFlowProvider>
          <ReactFlowStateProvider flowData={flowDefinition}>
            <ReactFlowCanvasProvider
              flowId={flowInfo?.id}
              flowDataHeadId={flowDefinition.headId}
              isReadOnly={isReadOnly}
              onSavingProcessBegin={onSavingProcessBegin}
              onSavingProcessSuccess={onSavingProcessSuccess}
              flowValidator={flowValidator}
              flowDataRef={flowDataRef}
              reactFlowWrapper={reactFlowWrapper}
            >
              <AssetDragLayer />
              <ControlPanel flowData={flowDefinition} externalTriggerGroupsLength={externalTriggerGroupsLength} />
              <div
                id="infinite-canvas"
                className={cn("drop-area-react-flow__infinite-canvas", {
                  readonly: isReadOnly,
                })}
              >
                <ErrorNavigationProvider>
                  <ReactFlowCanvas
                    triggerTypes={triggerTypes}
                    triggerTimeUnits={triggerTimeUnits}
                    isReadOnly={isReadOnly}
                    reactFlowWrapper={reactFlowWrapper}
                  />
                  <AssetInspector
                    triggerTypes={triggerTypes}
                    triggerTimeUnits={triggerTimeUnits}
                    isReadOnly={isReadOnly}
                    onResetSelectedElementsObserver={onResetSelectedElementsObserver}
                  />
                </ErrorNavigationProvider>
              </div>
            </ReactFlowCanvasProvider>
          </ReactFlowStateProvider>
        </ReactFlowProvider>
      )}
    </div>
  );
};

export default DropAreaReactFlow;
