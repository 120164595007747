import React, { useRef, useEffect } from "react";
import Dotdotdot, { DotdotdotProps } from "react-dotdotdot";
import cn from "classnames";

import htmlUtils from "../../utils/htmlUtils";

import styles from "./textTruncators.module.scss";

interface TruncateComponentProps {
  className?: string;
  lines?: number;
  onTruncate?: (isTruncated: boolean) => void;
  onClick?: () => void;
  children?: React.ReactNode;
}

/**
 * A component for text / jsx truncation
 * May have performance issues
 * @see https://www.npmjs.com/package/react-dotdotdot
 */
export const TextDotDotDot: React.FC<DotdotdotProps> = (props) => {
  const { children, useNativeClamp, clamp, ...restprops } = props;
  return (
    <Dotdotdot useNativeClamp={useNativeClamp || false} clamp={clamp || 1} {...restprops}>
      {children}
    </Dotdotdot>
  );
};

// This component used to make use of react-truncate 3rd party library under the hood,
// now it's based on CSS line-clamp:
// https://caniuse.com/css-line-clamp
export const TextTruncate: React.FC<TruncateComponentProps> = ({
  className,
  children,
  onClick,
  onTruncate,
  lines = 1,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (onTruncate && ref.current) {
      // at the moment of writing `onTruncate` callback is used in single place (Logo.tsx)
      // this is a rudiment from react-truncate (no longer supported: https://github.com/pablosichert/react-truncate)
      onTruncate(htmlUtils.isTextTruncated(ref.current));
    }
  }, [onTruncate]);

  return (
    <div
      onClick={onClick}
      ref={ref}
      className={cn(styles.ellipsis, className, [styles["break-word"]])}
      style={{ WebkitLineClamp: lines }}
    >
      {children}
    </div>
  );
};
