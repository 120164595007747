import { INotifyByEmailController } from "components/notifyStep/notifyByEmailController";
import { CommunicationChannels } from "enums";
import { initialNotifyConfigDefault } from "features/SystemNotifications/config";
import useNotifyConfig from "features/SystemNotifications/hooks/useNotifyConfig";
import { NotifyConfigEntry, NotifySettings } from "features/SystemNotifications/types";
import { NotifyStepSettings } from "interfaces";
import { useEffect, useRef, useState } from "react";

interface NotyStep {
  shouldNotify: boolean;
  onNotifyStepLoaded: (notifyByEmailController: INotifyByEmailController) => void;
  isNotifyStepLoaded: boolean;
  onNotifyTabValidChanged: (newValue: boolean) => void;
  isNotifyStepValid: boolean;
  notifyConfig: NotifySettings;
  setNotifyConfig: (shouldNotifyConfigEntry: NotifyConfigEntry<NotifySettings>) => void;
  communicationChannel: CommunicationChannels;
  saveNotificationSettings: (notificationId: string) => Promise<void>;
}

export type Notify = [notyStep: NotyStep];

export const useNotify = (addNotificationSettings: (settings: NotifyStepSettings) => Promise<void>): Notify => {
  const notifyByEmailControllerRef = useRef<INotifyByEmailController | undefined>(undefined);
  const [isNotifyStepLoaded, setIsNotifyStepLoaded] = useState(false);
  const [isNotifyStepValid, setIsNotifyStepValid] = useState(false);
  const [notifyConfig, { setNotifyConfig, shouldNotify, communicationChannel }] =
    useNotifyConfig(initialNotifyConfigDefault);

  useEffect(() => {
    return () => {
      notifyByEmailControllerRef.current?.resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNotifyStepLoaded = (notifyByEmailController: INotifyByEmailController) => {
    notifyByEmailControllerRef.current = notifyByEmailController;
    setIsNotifyStepLoaded(true);
  };

  const onNotifyTabValidChanged = (newValue: boolean) => {
    setIsNotifyStepValid(newValue);
  };

  const getNotificationSettings = async (notificationId: string) => {
    const notifySettings = (await notifyByEmailControllerRef.current?.getSettings(
      shouldNotify,
      communicationChannel,
      false,
    )) as NotifyStepSettings;
    notifySettings.id = notificationId;
    return notifySettings;
  };

  const saveNotificationSettings = async (notificationId: string) => {
    if (shouldNotify) {
      const notifySettings = await getNotificationSettings(notificationId);
      await addNotificationSettings(notifySettings);
    }
  };

  return [
    {
      shouldNotify,
      isNotifyStepLoaded,
      isNotifyStepValid,
      notifyConfig,
      communicationChannel,
      onNotifyStepLoaded,
      onNotifyTabValidChanged,
      setNotifyConfig,
      saveNotificationSettings,
    },
  ];
};

export default useNotify;
