import React from "react";
import { NoResultsWithButton } from "../../../../components";

export interface FlowsNoResultsProps {
  byCriteria?: boolean;
  createFlowButton?: React.ReactElement;
}

function FlowsNoResults(props: FlowsNoResultsProps) {
  const { byCriteria, createFlowButton } = props;

  return (
    <NoResultsWithButton
      title="You don't have any flows"
      description="Looks like you don't have any flows yet."
      iconClassName="fa-code-branch outline"
      actionButton={createFlowButton}
      filtered={byCriteria}
    />
  );
}

export default FlowsNoResults;
