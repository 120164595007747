import { combineReducers } from "redux";
import { updateVideoReducer } from "./reducers/updateVideoReducer";
import videoEntityStateReducer from "./reducers/videoEntityStateReducer";
import { videoInfoReducer } from "./reducers/videoInfoReducer";
import videoUsersSlice from "./slices/videoUsersSlice";
import videoGroupsSlice from "./slices/videoGroupsSlice";
import videoBaseSlice from "./slices/videoBaseSlice";
import videoClosedCaptionsSlice from "./slices/videoClosedCaptionsSlice";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import videoOverviewSlice from "./slices/videoOverviewSlice";
import videoFiltersSlice from "./slices/videoFiltersSlice";
import withSearch from "../../../Application/reducers/hoc/withSearch";
import videoPerformanceSlice from "./slices/videoPerformanceSlice";

const base = combineReducers({
  videoEntityStateReducer,
  videoInfoReducer,
  updateVideoReducer,
  tagsBase: videoBaseSlice,
  performance: videoPerformanceSlice,
});

const overview = combineReducers({
  videoOverview: videoOverviewSlice,
  filters: videoFiltersSlice,
  search: withSearch(ReducerNamespaceTypes.Library, ReducerEntityPrefixTypes.Videos),
});

const edit = combineReducers({
  users: videoUsersSlice,
  groups: videoGroupsSlice,
  closedCaptions: videoClosedCaptionsSlice,
});

const reducers = combineReducers({
  base,
  edit,
  overview,
});

export type VideoState = ReturnType<typeof reducers>;

export default reducers;
