import cn from "classnames";
import { FC, useEffect, useMemo, useRef } from "react";
import { Icon } from "semantic-ui-react";

import AssessmentPerformanceBody from "../../../Assessments/Performance/AssessmentPerformanceBody";
import EmailPerformanceBody from "../../../Communications/Emails/EmailDetails/Performance/EmailPerformanceBody";
import PdfPerformanceBody from "../../../Pdfs/Performance/PdfPerformanceBody";
import SurveyPerformanceBody from "../../../Surveys/Performance/SurveyPerformanceBody";
import VideoPerformanceBody from "../../../Videos/Performance/VideoPerformanceBody";

import { EntityType } from "../../../../../components/charts/types/Sankey";
import { RegisterBreadcrumbCallback } from "../../../Common/Hooks/usePerformanceBreadcrumbs";

import { RootState } from "features/Application/globaltypes/redux";
import { PerformanceFilter } from "features/Library/Common/utils/performanceUtils";
import { selectIsNavigationEnabled, selectIsReportEnabled } from "features/Reporting/state/toolbar/toolbarSlice";
import { ConnectedProps, connect } from "react-redux";
import "./flowPerformanceDrilldown.scss";
import EventPerformanceBody from "features/Library/Events/EventDetails/Performance/EventPerformanceBody";

export interface Props extends PropsFromRedux {
  flowId: number;
  assetId: number;
  assetType: EntityType;
  assetName: string;
  onReset: () => void;
  registerBreadcrumb: RegisterBreadcrumbCallback;
  dateFilter: PerformanceFilter;
  onNextAsset: () => void;
  onPrevAsset: () => void;
  currentAssetIndex: number;
  totalAssetsCount: number;
}

export const FlowPerformanceDrilldown: FC<Props> = ({
  flowId,
  assetId,
  assetType,
  assetName,
  dateFilter,
  onReset,
  registerBreadcrumb,
  onNextAsset,
  onPrevAsset,
  currentAssetIndex,
  totalAssetsCount,
  reportEnabled,
  navigationEnabled,
}) => {
  const assetNameRef = useRef<string>("");
  useEffect(() => {
    // Breadcrumb should only be replaced if we are in the drilldown state already,
    // such as when the user presses next/previous asset, which we determine if we have an active assetName already
    registerBreadcrumb({ text: assetName, action: onReset }, assetNameRef.current !== "");
    assetNameRef.current = assetName;
  }, [registerBreadcrumb, onReset, assetName]);

  const drilldownBody = useMemo(() => {
    switch (assetType) {
      case EntityType.Video:
        return (
          <VideoPerformanceBody
            flowId={flowId}
            videoId={assetId}
            videoTitle={assetName}
            videoFilter={dateFilter}
          />
        );
      case EntityType.Event:
        return (
          <EventPerformanceBody
            flowId={flowId}
            eventId={assetId}
            eventTitle={assetName}
            filter={dateFilter}
            registerBreadcrumb={registerBreadcrumb}
            isRecurring={false}
          />
        );
      case EntityType.Assessment:
        return (
          <AssessmentPerformanceBody
            flowId={flowId}
            entityId={assetId}
            entityTitle={assetName}
            dateFilter={dateFilter}
            registerBreadcrumb={registerBreadcrumb}
            hideDrilldownHeader
          />
        );
      case EntityType.Survey:
        return (
          <SurveyPerformanceBody
            flowId={flowId}
            surveyId={assetId}
            surveyTitle={assetName}
            dateFilter={dateFilter}
            registerBreadcrumb={registerBreadcrumb}
            hideDrilldownHeader
          />
        );
      case EntityType.Email:
        return (
          <EmailPerformanceBody
            flowId={flowId}
            emailId={assetId}
            emailTitle={assetName}
            dateFilter={dateFilter}
            registerBreadcrumb={registerBreadcrumb}
          />
        );
      case EntityType.Pdf:
        return <PdfPerformanceBody
          flowId={flowId}
          pdfId={assetId}
          pdfTitle={assetName}
          pdfFilter={dateFilter}
        />;
      default:
        return <></>;
    }
  }, [assetId, assetName, assetType, dateFilter, flowId, registerBreadcrumb]);

  return (
    <>
      {reportEnabled && (
        <div className="toggler">
          <div className="flowAssetTitle">{assetName}</div>
          <div className="flowActions">
            {navigationEnabled && (<>
              <div>
                {currentAssetIndex + 1} / {totalAssetsCount} Flow Assets
              </div>
              <button
                title="Previous Asset"
                type="button"
                data-testid="previous-asset"
                className={cn("invisibleButton", "leftSpacing")}
                onClick={onPrevAsset}
              >
                <Icon className="chevron left" />
              </button>
              <button
                title="Next Asset"
                type="button"
                data-testid="next-asset"
                className={cn("invisibleButton", "leftSpacing")}
                onClick={onNextAsset}
              >
                <Icon className="chevron right" />
              </button>
            </>)
            }
          </div>
        </div>
      )}
      <div className="drilldownBody">{drilldownBody}</div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  reportEnabled: selectIsReportEnabled(state),
  navigationEnabled: selectIsNavigationEnabled(state),
});

const connector = connect(mapStateToProps, {});
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FlowPerformanceDrilldown);
