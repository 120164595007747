import React from "react";
import ModalTypes from "../components/modal/ModalTypes";
import { IModalManager } from "../interfaces/IModalManager";
import noop from "lodash/noop";
import { PropertyChangedEvent } from "../interfaces/functionTypes/PropertyChangedEvent";
import Observable from "./Observable";

export default class ModalManager implements IModalManager {
  private shouldBeSuppressed = false;
  private isModalVisible = false;
  private waitForResponse = false;
  private isVisibleChangedObservable = new Observable<PropertyChangedEvent<boolean>>();
  private readonly type: ModalTypes;
  private readonly action: () => void;
  private readonly userId?: number;

  constructor(type: ModalTypes, action: () => void, waitForResponse?: boolean, userId?: number) {
    this.action = action;
    this.type = type;
    this.userId = userId;
    this.waitForResponse = waitForResponse ?? false;
    this.shouldBeSuppressed = this.getSuppressValue();
  }

  get isVisible() {
    return this.isModalVisible;
  }

  get modalType() {
    return this.type;
  }

  renderForm = (form: React.ReactElement) => (this.shouldBeSuppressed ? null : form);

  execute = () => {
    if (this.shouldBeSuppressed) {
      this.action();
      return noop;
    }
    if (this.waitForResponse) {
      return this.onWaitedForResponse;
    }
    this.setIsVisible(true);
    return noop;
  };

  onConfirm = () => {
    this.setIsVisible(false);
    this.action();
  };

  onCancel = () => {
    this.setIsVisible(false);
  };

  onSuppressStatusChanged = (shouldBeSuppressed: boolean) => {
    this.upsertVisibleToLocalStorage(shouldBeSuppressed);
    this.shouldBeSuppressed = shouldBeSuppressed;
  };

  subscribeOnIsVisibleChanged = this.isVisibleChangedObservable.subscribe;
  unsubscribeOnIsVisibleChanged = this.isVisibleChangedObservable.unsubscribe;

  dispose = () => {
    this.isVisibleChangedObservable.dispose();
  };

  private onWaitedForResponse = (shouldBeVisible: boolean) => {
    this.setIsVisible(shouldBeVisible);
    if (!shouldBeVisible) {
      this.action();
    }
  };

  private setIsVisible = (newValue: boolean) => {
    const oldValue = this.isModalVisible;
    if (oldValue === newValue) {
      return;
    }
    this.isModalVisible = newValue;
    this.isVisibleChangedObservable.notify(oldValue, newValue);
  };

  private getSuppressValue = (): boolean => {
    const storedConfig = this.getSuppressValueFromLocalStorage();
    return storedConfig ? storedConfig[this.type] : false;
  };

  private upsertVisibleToLocalStorage = (value: boolean) => {
    if (!this.userId) {
      return null;
    }
    let storedConfig = this.getSuppressValueFromLocalStorage();
    if (storedConfig) {
      storedConfig[this.type] = value;
    } else {
      storedConfig = { [this.type]: value };
    }
    localStorage.setItem(`${this.userId}`, JSON.stringify(storedConfig));
  };

  private getSuppressValueFromLocalStorage = () => {
    const storedValue = this.userId ? localStorage.getItem(`${this.userId}`) : null;
    return storedValue ? JSON.parse(storedValue) : null;
  };
}
