import RealTimeNotificationClient from "./realTimeNotificationClient";
import RtnEventsEmitter, { createRtnHandler } from "./rtnEventsEmitter";

import { accessTokenFactory } from "../../../../auth/userManager";
import environmentConfig from "../../../../configuration/environmentConfig";
import eventTypes from "./events";
import createMapping from "./handlersMapping";

export default function initializeHubClient(dispatchCallToStore, mobo, asyncActionsProvider, setSignalRState) {
  // extend handlers mapping to have access to store dispatch
  // as there will be actions dispatched to store
  const handlersMapping = createMapping(dispatchCallToStore);
  RtnEventsEmitter.subscribeFromMapping(eventTypes, handlersMapping);
  return new RealTimeNotificationClient(
    environmentConfig.realTimeNotificationUrl,
    accessTokenFactory,
    mobo,
    createRtnHandler(asyncActionsProvider, dispatchCallToStore),
    setSignalRState,
  );
}
