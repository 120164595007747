import * as vendorsActionTypes from "./vendorsActionTypes";

const initialState = {
  allVendors: {
    vendors: [],
    loadingAllVendors: false,
  },
  vendorCollections: {
    vendorId: null,
    collections: [],
    loadingVendorCollections: false,
  },
  vendorAssets: {
    vendorId: null,
    assets: [],
    loadingVendorAssets: false,
    isAllVendorAssetsLoaded: false,
  },
  error: null,
};

export default function vendorsReducer(currentState, action) {
  const state = currentState || initialState;

  switch (action.type) {
    case vendorsActionTypes.FETCH_ALL_VENDORS_BEGIN: {
      let newState = { ...state, error: null };
      newState.allVendors = {
        ...state.allVendors,
        loadingAllVendors: true,
      };

      return newState;
    }

    case vendorsActionTypes.FETCH_ALL_VENDORS_SUCCESS: {
      let newState = { ...state };
      newState.allVendors = {
        ...state.allVendors,
        vendors: action.payload.vendors,
        loadingAllVendors: false,
      };

      return newState;
    }

    case vendorsActionTypes.FETCH_ALL_VENDORS_FAILURE: {
      let newState = { ...state, error: action.payload.error };
      newState.allVendors = {
        ...state.allVendors,
        loadingAllVendors: false,
      };

      return newState;
    }

    case vendorsActionTypes.FETCH_ALL_VENDOR_COLLECTIONS_BEGIN: {
      let newState = { ...state, error: null };
      newState.vendorCollections = {
        ...state.vendorCollections,
        vendorId: action.payload.vendorId,
        loadingVendorCollections: true,
      };

      return newState;
    }

    case vendorsActionTypes.FETCH_ALL_VENDOR_COLLECTIONS_SUCCESS: {
      let newState = { ...state };
      newState.vendorCollections = {
        ...state.vendorCollections,
        collections: action.payload.collections,
        loadingVendorCollections: false,
      };

      return newState;
    }

    case vendorsActionTypes.FETCH_ALL_VENDOR_COLLECTIONS_FAILURE: {
      let newState = { ...state, error: action.payload.error };
      newState.vendorCollections = {
        ...state.vendorCollections,
        loadingVendorCollections: false,
      };

      return newState;
    }

    case vendorsActionTypes.FETCH_LAZY_VENDOR_ASSETS_BEGIN: {
      let newState = { ...state, error: null };
      newState.vendorAssets = {
        ...state.vendorAssets,
        vendorId: action.payload.vendorId,
        loadingVendorAssets: true,
      };

      return newState;
    }

    case vendorsActionTypes.FETCH_LAZY_VENDOR_ASSETS_SUCCESS: {
      let newState = { ...state };
      const currentVendorAssets = state.vendorAssets.assets;
      const newVendorAssets = action.payload.assets;
      const vendorAssets = currentVendorAssets.concat(newVendorAssets);
      const isAllVendorAssetsLoaded = action.payload.isAllVendorAssetsLoaded;
      newState.vendorAssets = {
        ...state.vendorAssets,
        assets: vendorAssets,
        loadingVendorAssets: false,
        isAllVendorAssetsLoaded: isAllVendorAssetsLoaded,
      };

      return newState;
    }

    case vendorsActionTypes.FETCH_LAZY_VENDOR_ASSETS_FAILURE: {
      let newState = { ...state, error: action.payload.error };
      newState.vendorAssets = {
        ...state.vendorAssets,
        loadingVendorAssets: false,
      };

      return newState;
    }

    case vendorsActionTypes.RESET_VENDOR_ASSETS_LIST: {
      let newState = { ...state };
      newState.vendorAssets = {
        ...state.vendorAssets,
        assets: [],
      };

      return newState;
    }

    default:
      return state;
  }
}
