import "./polyfills.js";

import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import "./fontawesome.css";
import "./semanticDist/semantic.css";

import Root from "./Root";
import getStore from "./store";
import initDatadog from "./lib/datadog/init";
import { initPlaywrightMocks } from "./lib/playwright";
import { setupListeners } from "@reduxjs/toolkit/query";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID as string);

const store = getStore();
setupListeners(store.dispatch);
/* istanbul ignore next */
if (process.env.NODE_ENV === "production") {
  initDatadog();
} else {
  initPlaywrightMocks(store);
}
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <Root />
  </Provider>,
);
