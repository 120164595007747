import axios from "axios";
import { TrendingFlow } from "../Dashboard/Overview/Trending/TrendingFlows";
import { TrendingAsset } from "../Dashboard/Overview/Trending/TrendingAssets";

export const dashboardReportEndpoints = {
  trendingFlows: "/api/reports/v2/overview/account/trending/flows",
  trendingAssets: "/api/reports/v2/overview/account/trending/assets",
  flowGoalsLine: "/api/reports/v2/overview/account/flows/linechart",
  flowGoalsEngagement: "/api/reports/v2/overview/account/flows/engagement",
};

export type ObjectiveTypes = "onboarding/transition" | "remediation" | "optimization";

export enum ObjectiveTypesEnum {
  OnboardingTransition = "Onboarding / Transition",
  Remediation = "Remediation",
  Optimization = "Optimization",
}

export interface FlowGoalLineChart {
  Completed: number;
  CompletedTotal: number;
  Date: string;
  InProgress: number;
  InProgressTotal: number;
}

export interface FlowGoalEngagement {
  Completed: number;
  FlowsCount: number;
  InProgress: number;
}

export const fetchTrendingFlowsData = () => {
  return axios.get<TrendingFlow[]>(dashboardReportEndpoints.trendingFlows);
};

export const fetchTrendingAssetsData = () => {
  return axios.get<TrendingAsset[]>(dashboardReportEndpoints.trendingAssets);
};

export const fetchFlowGoalsLineData = (objectiveType: ObjectiveTypesEnum) => {
  return axios.get<FlowGoalLineChart[]>(dashboardReportEndpoints.flowGoalsLine, {
    params: {
      objectiveType,
    },
  });
};

export const fetchFlowGoalsEngagement = (objectiveType: ObjectiveTypesEnum) => {
  return axios.get<FlowGoalEngagement>("/api/reports/v2/overview/account/flows/engagement", {
    params: { objectiveType },
  });
};
