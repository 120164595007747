import ReducerEntityPrefixTypes from "../../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../../enums/reducer/reducerNamespaceTypes";
import { GenericFiltersMap } from "../../../../../../utils/filterUtils";
import { createFiltersSlice, FiltersState } from "../../../../../Application/slices/createFiltersSlice";
import { LandingPagesFiltersEnum } from "../../types/models";

export const initialState: FiltersState<LandingPagesFiltersEnum> = {
  filterOptions: {} as GenericFiltersMap<LandingPagesFiltersEnum>,
  appliedFilter: {} as GenericFiltersMap<LandingPagesFiltersEnum>,
  isLoading: false,
};

const landingPagesFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.ThreatDefenceLandingPages,
    name: "filters",
  },
  initialState: initialState,
  reducers: {},
});

export const { setAppliedFilter, resetAppliedFilter, setIsLoading, setError, setFilterOptions } =
  landingPagesFiltersSlice.actions;

export default landingPagesFiltersSlice.reducer;
