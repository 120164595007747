import integrationsDataService from "../../services/integrationsDataService";
import { IntegrationTypes } from "../types";
import IConnector from "./IConnector";
import { SfIntegrationConfigs, CustomContent, PackContent } from "../types/successFactors";

export class SfConnector implements IConnector<SfIntegrationConfigs> {
  private readonly _isConnected: boolean;

  constructor(isConnected: boolean) {
    this._isConnected = isConnected;
  }

  public get info() {
    return {
      id: IntegrationTypes.SuccessFactors,
      connectorTitle: "SuccessFactors",
      type: IntegrationTypes.SuccessFactors,
      description: "SuccessFactors",
      thumbnailUrl: "/connectors/success-factors.png",
      isConnected: this._isConnected,
    };
  }

  public get configuration() {
    return {
      isActive: true,
      contentSettings: {
        contentType: CustomContent.AllContent,
      },
      packSettings: {
        contentType: PackContent.AllContent,
        packIds: [],
      },
      sftpSettings: {
        host: "",
        username: "",
        password: "",
      },
      columnValues: null,
      tenantId: "",
      isHistoryEnabled: false,
      completionHistorySettings: null,
    };
  }

  public shouldLogin = () => false;

  public shouldNotify = () => false;

  public login = async (_: SfIntegrationConfigs) => {
    throw new Error("SuccessFactors integration does not support login");
  };

  public save = async (config: SfIntegrationConfigs, moboId?: string) => {
    await integrationsDataService.createSfIntegration(config, moboId);
  };
}
