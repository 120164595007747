import { Button } from "components/buttons/button/Button";

import RestrictedByAddOn from "../../../../../components/restrictedByAddOn/RestrictedByAddOn";
import RestrictedByTooltip from "../../../../../components/restrictedByTooltip/RestrictedByTooltip";

import { RolePermissions } from "../../../../../enums";
import { PaywallModalTypes } from "../../../../../components/restrictedByAddOn/paywallModal/types";
import { useNavigate } from "react-router-dom";

export const CreatePdfButton = () => {
  const navigate = useNavigate();

  const onCreateBtnClick = () => navigate("create");

  const createPdfButton = (action: () => void, hasAnyPermission: boolean): React.ReactElement => (
    <RestrictedByTooltip hasPermission={hasAnyPermission}>
      <Button primary onClick={action} disabled={!hasAnyPermission} className="create-button">
        Create PDF
      </Button>
    </RestrictedByTooltip>
  );

  return (
    <RestrictedByAddOn
      permissions={[RolePermissions.AssetsManage, RolePermissions.AssetsCreate]}
      addOnPermissions={[RolePermissions.AssetsCreate]}
      modalType={PaywallModalTypes.UpgradeToCreateContent}
      action={onCreateBtnClick}
      renderContent={createPdfButton}
    />
  );
};
