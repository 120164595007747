import QhMenuItem from "./qhMenuItem";

class QhNavigationMenuItem extends QhMenuItem {
  addChild(component) {
    super.addChild(component);
    if (component.options_.selected) {
      this._updateSelectedLabel(component.options_.label);
    }
  }

  createEl() {
    const elem = super.createEl();
    elem.classList.add("vjs-navigation-menu-item");

    const label = document.createElement("div");
    label.className = "vjs-menu-item-label";
    label.innerHTML = this.localize(this.options_.label);
    elem.appendChild(label);

    const selectedLabel = this._createSelectedLabel();
    elem.appendChild(selectedLabel);

    const chevronIcon = document.createElement("i");
    chevronIcon.className = "chevron right icon";
    elem.appendChild(chevronIcon);

    return elem;
  }

  _createSelectedLabel() {
    this.selectedValueLabel = document.createElement("div");
    this.selectedValueLabel.className = "vjs-menu-item-selected-value";
    this._updateSelectedLabel("");
    return this.selectedValueLabel;
  }

  _updateSelectedLabel(value) {
    this.selectedValueLabel.innerHTML = value;
  }
}

export default QhNavigationMenuItem;
