import { useEffect } from "react";
import { FormikProps, withFormik } from "formik";
import { connect, ConnectedProps } from "react-redux";
import { isEqual } from "lodash";

import { imageUtils, validationSchemas } from "../../../../utils";
import { getValidateFieldProps } from "../../../../utils/formikUtils";
import { AppDispatch, RootState } from "../../../../features/Application/globaltypes/redux";
import { bindAction } from "../../../../interfaces";
import { updateTeamsForm } from "../../../../features/SystemNotifications/state/slices/notifyStepSlice";
import { EndpointsToGetSasLink } from "enums";
import { ValidatedFormWithoutFormik } from "../../../forms";
import { getInitialTeamsFormSelector, getTeamsFormSelector } from "features/SystemNotifications/state/selectrors";

export interface OwnFormProps {
  onValidChange?(isValid: boolean): void;
  onIsDirtyChange?(isDirty: boolean): void;
}

export interface FormValues {
  headline: string;
  body: string;
  callToAction: string;
  destinationUrl: string;
  imageUrl: string;
}

export type FormProps = FormikProps<FormValues> & PropsFromRedux & OwnFormProps;

const tooltipOptions = {
  callToAction: {
    info: "The Call to Action (CTA) is a button with a short description of what you'd like the user to do. CTA's are generally 1-3 words (e.g. Register Now).",
    width: 18,
  },
  destinationUrl: {
    info: "The URL that you'd like to send the user when they click the Call-to-Action (CTA) button.",
    width: 17,
  },
};

const Form = (props: FormProps) => {
  const validatedFieldProps = getValidateFieldProps(props);
  const { values, savedValues, initialFormValues, isValid, onValidChange, onIsDirtyChange, dirty, submitForm } = props;

  const updateTeamsValues = () => {
    submitForm();
  };

  const updateImage = () => {
    // NOSONAR TODO: https://brainstorm.atlassian.net/browse/SAAS-24040
    // formic's setFieldValue is async, so we need to wait for it to finish before submitting the form
    // https://github.com/jaredpalmer/formik/issues/529
    setTimeout(() => {
      submitForm();
    }, 0);
  };

  useEffect(() => {
    onIsDirtyChange?.(!isEqual(savedValues, initialFormValues));
  }, [savedValues, initialFormValues, onIsDirtyChange]);

  useEffect(() => {
    onValidChange?.(isValid);
  }, [isValid, onValidChange]);

  return (
    <ValidatedFormWithoutFormik
      disablePreventTransitionPrompt={true}
      dirty={dirty}
      formWidthComputer={8}
      isLoading={false}
      isSubmitting={false}
      style={{ marginBottom: "1rem" }}
    >
      <ValidatedFormWithoutFormik.InputField
        {...validatedFieldProps}
        label="Heading"
        value={values.headline}
        propertyName="headline"
        markAsRequired
        placeholder="Enter Message Heading"
        onBlur={updateTeamsValues}
      />
      <ValidatedFormWithoutFormik.ImagePreview
        {...validatedFieldProps}
        label="Image Cap"
        editBtnEnabled={false}
        value={imageUtils.formatStorageUrl(values.imageUrl)}
        propertyName="imageUrl"
        endpointToGetSasLink={EndpointsToGetSasLink.SystemNotifications}
        markAsRequired
        getImageCustomValidator={() => {
          return {
            validator: validationSchemas.image.fields.uploadedTeamsImages,
            propName: "uploadedTeamsImages",
          };
        }}
        onImageChanged={updateImage}
      />
      <ValidatedFormWithoutFormik.TextAreaField
        {...validatedFieldProps}
        label="Body"
        placeholder={"Enter Message Here"}
        value={values.body}
        propertyName={"body"}
        markAsRequired
        onBlur={updateTeamsValues}
      />
      <ValidatedFormWithoutFormik.InputField
        {...validatedFieldProps}
        label="Call to Action"
        value={values.callToAction}
        propertyName="callToAction"
        markAsRequired
        placeholder="Enter Call to Action"
        tooltip={tooltipOptions.callToAction}
        onBlur={updateTeamsValues}
      />
      <ValidatedFormWithoutFormik.InputField
        {...validatedFieldProps}
        label="Destination URL"
        value={values.destinationUrl}
        propertyName="destinationUrl"
        markAsRequired
        placeholder="Enter Destination URL"
        tooltip={tooltipOptions.destinationUrl}
        onBlur={updateTeamsValues}
      />
    </ValidatedFormWithoutFormik>
  );
};

/* istanbul ignore next */
const mapState = (state: RootState) => ({
  savedValues: getTeamsFormSelector(state),
  initialFormValues: getInitialTeamsFormSelector(state),
});

/* istanbul ignore next */
const mapDispatch = (dispatch: AppDispatch) => ({
  updateTeamsForm: bindAction(updateTeamsForm, dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
export const FormWithFormik = withFormik({
  mapPropsToValues: (props: PropsFromRedux & OwnFormProps) => {
    return {
      ...props.savedValues,
    };
  },
  enableReinitialize: true,
  handleSubmit: (content: FormValues, { props }: { props: any }) => {
    props.updateTeamsForm(content);
  },
  validationSchema: validationSchemas.msTeamsMessageSchema,
  validateOnMount: true,
})(Form);

export default connector(FormWithFormik);
