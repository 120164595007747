import { ModalWithStepsStep } from "../../../../../components/modal/ModalWithSteps";

export interface PeopleListStepPropsBase extends ModalWithStepsStep {
  selectedIds: number[];
  setIds: (ids: number[]) => void;
  flowIds: number[];
}

export enum FlowUserFiltersEnum {
  Status = "statuses",
  Expired = "expired",
  Priority = "priorities",
  PermissionIssuedDate = "permissionIssuedDate",
}
