import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as FeedbackIcon } from "../../images/feedback.svg";
import RouteNames from "enums/routeNames";

import "./feedback-button.scss";

const FeedbackButton: React.FC<{}> = (): React.ReactElement => (
  <Link to={"/" + RouteNames.productFeedbackUrl} className="feedback-btn">
    <FeedbackIcon className="icon area-icon" /> Feedback
  </Link>
);

export default FeedbackButton;
