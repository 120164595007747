import { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { noop, uniq } from "lodash";
import { Icon } from "semantic-ui-react";
import { SwitchEntityTypes } from "../../../../../components/notifyStep/NotifyStepContent/switches/SwitchWithProgress";
import { useObserver } from "../../../../../hooks/useObserver";
import { IObservable } from "../../../../../interfaces/IObservable";
import { bindAction } from "../../../../../interfaces/redux";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { publishDraftFlowEntity } from "../../state/actions/flowEntityStateActionCreators";
import { resetLicensingInfo } from "../../state/slices/flowLicensingSlice";
import { fetchLicensingInfo } from "../../state/thunks/flowLicensingThunk";
import { resetContentPacksAction } from "../../../../Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { fetchFlowTags as fetchFlowTagsThunk } from "../../state/thunks/flowBaseThunk";
import { fetchFailure as licensesFetchFailure } from "../../../../Licensing/ContentAssignmentModalLicensingSteps/state/slices/licenseConfirmationSlice";
import ContentAssignmentModalTwoSteps from "../../../../../components/assignmentModals/contentAssignmentModal/contentAssignmentModalTwoSteps/ContentAssignmentModalTwoSteps";
import { NotifyStepConfig, NotifyStepConfirmPayload } from "../../../../SystemNotifications/types";
import { TemplateTypes } from "../../../../../enums";
import { flowDesignerSelector, flowPackContextItemsSelector } from "../../state/selectors";
import ObjectUtils from "../../../../../utils/objectUtils";
import { AddedAsset } from "../../types/flowLicensing";
import { PacksContextItem } from "interfaces";
import { shouldShowModalForFlowPublish } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/licensingModalThunk";
import { reset } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/slices/licensingModalSlice";
import styles from "./contentAssignmentsContainer.module.scss";

interface ContentAssignmentsContainerProps {
  flowId: number;
  flowHasOrigin: boolean;
  publishClickObserver: IObservable<(then: () => void) => void>;
}

export type ContentAssignmentsContainerPropsAll = PropsFromRedux & ContentAssignmentsContainerProps;

export const ContentAssignmentsContainer = (props: ContentAssignmentsContainerPropsAll) => {
  const {
    flowId,
    flowHasOrigin,
    onLicenseConfirmed,
    resetContentPacks,
    packContextItems,
    flowAssignmentsItems,
    fetchFlowTags,
    flowChanges: { isDefinitionChanged },
    flowPackContextItems,
    fetchLicensing,
    resetLicensing,
    shouldShowLicensingModal,
    resetLicensingModal,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [skipLicensingSteps, setSkipLicensingSteps] = useState(false);
  const onConfirmRef = useRef(noop);
  const [subsribeOnPublishClick] = useObserver(props.publishClickObserver);
  const packInfoRef = useRef(flowPackContextItems);

  /* istanbul ignore next */
  useEffect(() => {
    const callback = (then: () => void) => {
      onConfirmRef.current = then;
      onPublishButtonClick();
    };

    return subsribeOnPublishClick(callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsribeOnPublishClick]);

  useEffect(() => {
    packInfoRef.current = flowPackContextItems;
  }, [flowPackContextItems]);

  const onPublishButtonClick = () => {
    fetchLicensing(flowId, triggerPublishFlow);
  };

  const triggerPublishFlow = async (flowChanged: boolean, addedAssets: AddedAsset[]) => {
    packInfoRef.current = packInfoRef.current.filter((i: PacksContextItem) =>
      addedAssets.some((a) => a.id === i.id && a.type.toLowerCase() === i.type.toLowerCase()),
    );

    if (flowChanged) {
      const shouldShowModal = await shouldShowLicensingModal(flowId, packInfoRef.current);
      setSkipLicensingSteps(!shouldShowModal);
      setShowModal(true);
    } else {
      onConfirm();
    }
  };

  const onConfirm = (notificationConfirmPayload?: NotifyStepConfirmPayload) => {
    const packIds = uniq([
      ...packContextItems.map((p) => p.packId!),
      ...flowAssignmentsItems.flatMap((item) => item.packIds),
    ]);
    fetchFlowTags();
    onLicenseConfirmed(flowId, packIds, notificationConfirmPayload);
    onConfirmRef.current();
    onCloseModal();
  };

  const onCloseModal = () => {
    setShowModal(false);
    setSkipLicensingSteps(false);
    resetLicensing();
    resetContentPacks();
    resetLicensingModal();
  };

  const renderResetFlowProgressAlert = () => (
    <div className={styles["reset-flow-progress-alert"]}>
      <Icon className="info circle" />
      <div className={styles["reset-flow-progress-alert-content"]}>
        <b>Flow progress will be reset. </b>
        <span>
          Publishing these changes will reset Flow progress to the <br /> beginning for all users in progress with this
          Flow.
        </span>
      </div>
    </div>
  );

  return (
    <ContentAssignmentModalTwoSteps
      showModal={showModal}
      flowId={flowId}
      flowHasOrigin={flowHasOrigin}
      onCancel={onCloseModal}
      onConfirm={onConfirm}
      skipNotifyStep={false}
      notifyStepDisabled={false}
      skipLicensing={skipLicensingSteps}
      packContextItems={packInfoRef.current}
      selectedUserIds={[]}
      selectedGroupIds={[]}
      notifyTemplateType={flowHasOrigin ? TemplateTypes.FlowRepublished : TemplateTypes.AddedToFlowV2}
      notifyConfigType={flowHasOrigin ? NotifyStepConfig.WithProgress : NotifyStepConfig.Default}
      entityType={SwitchEntityTypes.Flow}
      alert={flowHasOrigin && isDefinitionChanged && renderResetFlowProgressAlert()}
      skipPacksContext={skipLicensingSteps}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  packContextItems: state.licensing.contentPacks.items,
  flowAssignmentsItems: state.licensing.contentPacks.flowAssignments,
  flowChanges: state.library.flows.base.licensing.flowChanges,
  flowPackContextItems: flowPackContextItemsSelector(state),
  hasExternalTrigger: ObjectUtils.isNotEmpty(flowDesignerSelector(state).externalTriggers.externalTriggerGroups),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onLicenseConfirmed: bindActionCreators(publishDraftFlowEntity, dispatch),
  fetchLicensing: bindAction(fetchLicensingInfo, dispatch),
  resetLicensing: bindAction(resetLicensingInfo, dispatch),
  setLicensesFetchFailure: (error: Error) => dispatch(licensesFetchFailure(error)),
  resetContentPacks: () => dispatch(resetContentPacksAction()),
  fetchFlowTags: () => dispatch(fetchFlowTagsThunk()),
  shouldShowLicensingModal: bindAction(shouldShowModalForFlowPublish, dispatch),
  resetLicensingModal: bindAction(reset, dispatch),
});

/* istanbul ignore next */
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ContentAssignmentsContainer);
