import { FormikProps, withFormik } from "formik";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { RootState } from "../../../../../../Application/globaltypes/redux";
import { ThreatDefenceDirectSendSendingProfileVerification } from "../../types/state";
import { VerificationSendingProfileProps } from "../types";

import * as sendingProfileEntityStateActions from "../../state/actions/threatDefenceSendingProfileEntityStateActions";
import * as sendingProfileDetailsActions from "../../state/actions/threatDefenceSendingProfileDetailsActions";

import { AutosaveProps, withAutosave } from "../../../../../../../utils/withAutosave";
import { noop } from "lodash";

export type VerificationAllProps = VerificationSendingProfileProps &
  PropsFromRedux &
  FormikProps<ThreatDefenceDirectSendSendingProfileVerification> &
  AutosaveProps;

export class Verification extends Component<VerificationAllProps> {
  componentDidMount() {
    this.props.acceptHandlers?.({
      onPrevious: this.onPrevious,
      onFinish: this.onFinish,
      onClose: this.onClose,
    });

    //Synchronizes parrent component state with formik onMount validation
    this.props.onIsValidChange?.(true);
  }

  onPrevious = () => {
    this.props.isValid && this.saveSendingProfile();
  };

  onPublishSendingProfile = () => {
    this.props.entityStateActions.publishDraftThreatDefenceSendingProfileEntity(this.props.id);
  };

  onFinish = () => {
    this.onPublishSendingProfile();
  };

  onClose = () => {
    this.props.detailsActions.clearThreatDefenceSendingProfile();
  };

  saveSendingProfile = () => {
    this.props.detailsActions.saveThreatDefenceDirectSendSendingProfileVerification(this.props.values);
  };

  static getSendingProfileVerification = (
    values: ThreatDefenceDirectSendSendingProfileVerification,
  ): ThreatDefenceDirectSendSendingProfileVerification => {
    return {
      id: values.id,
      isVerified: values.isVerified,
    };
  };

  render() {
    return <>Coming soon</>;
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState, ownProps: VerificationSendingProfileProps) => {
  const detailsReducer = state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileDetailsReducer;
  const id =
    ownProps.entityId ||
    state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileEntityStateReducer.entityId;
  return {
    id,
    verification: {
      id,
      isVerified: detailsReducer.isVerified,
    },
    isLoaded: detailsReducer.isSendigProfileLoaded,
    isLoading: detailsReducer.isLoading,
    isCreating:
      state.library.threatDefenceDirectSendSendingProfiles.threatDefenceSendingProfileEntityStateReducer
        .changingEntityState,
    isDraft: detailsReducer.sendingProfile.isDraft,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  entityStateActions: bindActionCreators(sendingProfileEntityStateActions, dispatch),
  detailsActions: bindActionCreators(sendingProfileDetailsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

/* istanbul ignore next */
const withAutosaveComponent = withAutosave<VerificationAllProps, ThreatDefenceDirectSendSendingProfileVerification>({
  getInitValues: (props) => props.verification,
  stateProvider: Verification.getSendingProfileVerification,
  entityUpdater: () => noop,
})(Verification);

const component = withFormik({
  enableReinitialize: true,
  isInitialValid: (_: VerificationSendingProfileProps & PropsFromRedux) => true,
  mapPropsToValues: (props: VerificationSendingProfileProps & PropsFromRedux) => props.verification,
  handleSubmit: () => {
    // handler is required in order for submitForm`s returned promise to resolve
  },
})(withAutosaveComponent);
export default connector(component);
