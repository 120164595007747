import { bindActionCreators } from "@reduxjs/toolkit";
import { TextTruncate } from "components";
import { Tooltip } from "components/common/tooltip";
import CollapsibleExpressionBuilder from "components/expressionBuilder/CollapsibleExpressionBuilder";
import { ErrorPosition } from "components/forms/ValidatedField";
import { UserSource } from "enums";
import { AppDispatch, RootState } from "features/Application/globaltypes/redux";
import { useEffect, useMemo } from "react";
import { ConnectedProps, connect } from "react-redux";
import { FilterOptionsEnum } from "utils/enrollmentRulesUtils";
import { default as ValidatedForm } from "../../../../../components/forms/ValidatedForm";
import * as filterActionCreators from "../../../../People/Users/UsersOverview/state/filterActionCreators";
import * as allUserCommonActions from "../../../Users/state/userCommonActions";
import { NumberRangePicker } from "../NumberRangePicker/NumberRangePicker";
import { RangePicker } from "../RangePicker/RangePicker";
import { EnrollmentRule, defaultEnrollmentRule } from "./utils";

import nameof from "utils/nameof";
import "./EnrollmentBuilder.scss";

import CheckboxField from "components/forms/CheckboxField";
import { hasGroupTemplateSettingsAccess } from "features/People/GroupTemplate/utils";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { Icon } from "semantic-ui-react";
import { FeatureFlags } from "../../../../../featureFlags";
import { FilterOptionParams } from "../../../../People/Users/UsersOverview/state/filterActionCreators";
import {
  TypeOptionsEnum,
  getDropdownOptions,
  getFilterOptions,
  getFilterRuleOptions,
  isMappedCriteriaOptions,
  withinLastDaysOptions,
} from "./options";

type Values = {
  enrollmentRules: EnrollmentRule[];
  templateUsersOnly?: boolean | null;
};
const enrollmentRulesFieldName = nameof<Values>("enrollmentRules");
const getEnrollmentRulesStringKey = (i: number) => `${enrollmentRulesFieldName}[${i}]`;

export interface OwnProps {
  disabled: boolean;
  onChange?: (items: EnrollmentRule[]) => void;
  values: Values;
  validatedFieldProps: any;
  dataSourceOptions: { text: string; value: UserSource }[];
  onIsValidChange: (isValid: boolean) => void;
  handleCriteriaChange: (idx: number) => void;
}

type Props = OwnProps & PropsFromRedux;

export const EnrollmentBuilder = (props: Props) => {
  const {
    disabled,
    onChange,
    handleCriteriaChange,
    userCommonActions,
    filterActions,
    values,
    onIsValidChange,
    validatedFieldProps,
    accountType,
  } = props;

  const isRuleEngineOnCosmosEnabled = !!useFeatureFlag(FeatureFlags.RuleEngineOnCosmos);
  const isLicenseGroupsFilterEnabled = !!useFeatureFlag(FeatureFlags.LicenseGroupsFilter);
  const shouldRenderSettings = useMemo(() => hasGroupTemplateSettingsAccess(accountType), [accountType]);

  useEffect(() => {
    userCommonActions.fetchCountries();
    const filterOptionParams: FilterOptionParams = {
      shouldGetRoles: true,
      roleSearchQuery: "",
      shouldGetOfficeLocations: true,
      shouldGetManagers: true,
      shouldGetLicenses: true,
      shouldGetDefaultRoles: true,
      accountTypeId: accountType,
      shouldGetGroups: true,
    };
    filterActions.getFilterOptions(filterOptionParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onIsValidChange(validatedFieldProps.isFormValid);
  }, [validatedFieldProps.isFormValid, onIsValidChange]);

  const getValueField = (type: number, items: EnrollmentRule[], itemIndex: number, filter: number) => {
    switch (type) {
      case TypeOptionsEnum.Department:
      case TypeOptionsEnum.JobTitle:
      case TypeOptionsEnum.Country:
      case TypeOptionsEnum.OfficeLocation:
      case TypeOptionsEnum.Manager:
      case TypeOptionsEnum.License:
      case TypeOptionsEnum.Group:
      case TypeOptionsEnum.Role:
        return renderDropdownField(items, itemIndex);
      case TypeOptionsEnum.CreatedDate:
      case TypeOptionsEnum.HiredDate:
        return getCreateDateField(items, itemIndex, filter);
      default:
        return null;
    }
  };

  const getCreateDateField = (items: EnrollmentRule[], itemIndex: number, filter: number) => {
    switch (filter) {
      case FilterOptionsEnum.IsBetween:
      case FilterOptionsEnum.IsNotBetween:
        return renderRangeDatepickers(items, itemIndex);
      case FilterOptionsEnum.IsOnOrAfter:
      case FilterOptionsEnum.IsOnOrBefore:
        return renderSingleDatepicker(items, itemIndex);
      case FilterOptionsEnum.IsWithinLastDays:
        return renderIsWithinDropdownField(items, itemIndex);
      default:
        return null;
    }
  };

  /* istanbul ignore next */
  const handleSourceChange = async (index: number, value: any) => {
    const { validatedFieldProps } = props;
    await validatedFieldProps.setValues((oldValues: any) => {
      const copy = [...oldValues[enrollmentRulesFieldName]];
      copy.splice(index, 1, { ...copy[index], source: value, type: null, filter: null, value: "" });
      return {
        ...values,
        [enrollmentRulesFieldName]: copy,
      };
    });
  };

  const handleRuleChange = async (index: number, value: any) => {
    const { validatedFieldProps } = props;
    await validatedFieldProps.setValues((oldValues: any) => {
      const copy = [...oldValues[enrollmentRulesFieldName]];
      copy.splice(index, 1, { ...copy[index], type: value, filter: 0, value: "" });
      return {
        ...values,
        [enrollmentRulesFieldName]: copy,
      };
    });
  };

  const handleFilterChange = async (index: number, value: any) => {
    const { validatedFieldProps } = props;
    await validatedFieldProps.setValues((values: any) => {
      const copy = [...values[enrollmentRulesFieldName]];
      copy.splice(index, 1, { ...copy[index], filter: value, value: "" });
      return {
        ...values,
        [enrollmentRulesFieldName]: copy,
      };
    });
  };

  const handleTemplateUsersOnlyChange = async (value: boolean) => {
    const { validatedFieldProps } = props;
    await validatedFieldProps.setValues((values: any) => {
      return {
        ...values,
        templateUsersOnly: value,
      };
    });
  };

  const getCriteriaOptions = (type: number, value: string) => {
    switch (type) {
      case TypeOptionsEnum.JobTitle:
        return getDropdownOptions(value, props.jobTitles);
      case TypeOptionsEnum.Department:
        return getDropdownOptions(value, props.departments);
      case TypeOptionsEnum.Country:
        return getDropdownOptions(
          value,
          props.countryList.items.map((x: any) => ({ key: x.id, value: x.name, text: x.name })),
        );
      case TypeOptionsEnum.OfficeLocation:
        return getDropdownOptions(value, props.officeLocations);
      case TypeOptionsEnum.Manager:
        return getDropdownOptions(value, props.managers);
      case 2:
      case 5:
        return getDropdownOptions(value, withinLastDaysOptions);
      case TypeOptionsEnum.License:
        return getDropdownOptions(value, props.licenses);
      case TypeOptionsEnum.Group:
        return getDropdownOptions(value, props.groups);
      case TypeOptionsEnum.Role:
        return getDropdownOptions(value, props.defaultRoles);
      default:
        return null;
    }
  };

  const renderDropdownField = (items: EnrollmentRule[], itemIndex: number) => {
    const { validatedFieldProps, disabled } = props;
    const rowModelName = getEnrollmentRulesStringKey(itemIndex);
    const rule = items[itemIndex];
    const ruleType = rule.type as TypeOptionsEnum;
    const isCriteriaOptionsLoading = isMappedCriteriaOptions(ruleType) && props.filterOptionsLoading;
    const criteriaValue = !isCriteriaOptionsLoading && typeof rule.value === "string" ? rule.value : "";
    const dropdownOptions = getCriteriaOptions(rule.type as number, criteriaValue);
    const selected = dropdownOptions?.filter((item) => item.value === rule.value);
    const selectedLabel = selected && typeof selected[0]?.text === "string" ? selected[0].text : "";
    const selectedValue = selected ? selected[0]?.value : "";

    return (
      <ValidatedForm.DropdownField
        noResultsMessage=""
        placeholder="Criteria"
        search
        value={selectedValue}
        propertyName={`${rowModelName}.value`}
        markAsRequired
        allowAdditions
        disabled={disabled || !items[itemIndex].filter}
        options={dropdownOptions}
        onChangeCallback={() => handleCriteriaChange(itemIndex)}
        onItemAdded={() => handleCriteriaChange(itemIndex)}
        errorPosition={ErrorPosition.bottom}
        isLoading={isCriteriaOptionsLoading}
        testID="criteria-dropdown"
        {...validatedFieldProps}
        trigger={
          <Tooltip
            target={<TextTruncate className="default-text">{selectedLabel || "Criteria"}</TextTruncate>}
            content={selectedLabel || "Criteria"}
          />
        }
      />
    );
  };

  const renderIsWithinDropdownField = (items: EnrollmentRule[], itemIndex: number) => {
    const { validatedFieldProps, disabled } = props;
    const rowModelName = getEnrollmentRulesStringKey(itemIndex);

    return (
      <div className="is-within-last-days-rule">
        <NumberRangePicker
          itemIndex={itemIndex}
          items={items}
          validatedFieldProps={validatedFieldProps}
          disabled={disabled}
          rowModelName={rowModelName}
          handleChange={handleCriteriaChange}
        />
        <p>Days</p>
      </div>
    );
  };

  const renderRangeDatepickers = (items: EnrollmentRule[], itemIndex: number) => {
    const { validatedFieldProps, disabled } = props;
    const rowModelName = getEnrollmentRulesStringKey(itemIndex);

    return (
      <RangePicker
        itemIndex={itemIndex}
        items={items}
        validatedFieldProps={validatedFieldProps}
        disabled={disabled}
        rowModelName={rowModelName}
        handleChange={handleCriteriaChange}
      />
    );
  };

  const renderSingleDatepicker = (items: EnrollmentRule[], itemIndex: number) => {
    const { validatedFieldProps, disabled } = props;
    const rowModelName = getEnrollmentRulesStringKey(itemIndex);

    return (
      <ValidatedForm.DatePickerField
        label=""
        placeholder="Date"
        selected={items[itemIndex].value || ""}
        propertyName={`${rowModelName}.value`}
        markAsRequired
        disabled={disabled || !items[itemIndex].filter}
        onBlur={() => handleCriteriaChange(itemIndex)}
        onDateChange={() => handleCriteriaChange(itemIndex)}
        format="MM/DD/YYYY"
        errorPosition={ErrorPosition.bottom}
        {...validatedFieldProps}
      />
    );
  };

  const renderSettings = () => {
    const {
      values: { templateUsersOnly },
    } = props;
    return templateUsersOnly !== undefined && shouldRenderSettings ? (
      <div className="option-with-tooltip">
        <CheckboxField
          setFieldValue={(_: string, value: boolean) => handleTemplateUsersOnlyChange(value)}
          toggle
          label="Restrict to template-created users only"
          value={templateUsersOnly}
          propertyName="templateUsersOnly"
        />
        <Tooltip
          hoverable
          tooltipClassName="integraion-setting-tooltip"
          target={<Icon className="info circle" />}
          content={
            "Enable this option to enroll users managed by this template. By default, all users that meet the criteria will be enrolled."
          }
          position="top center"
          hideOnScroll
          showAlways
        />
      </div>
    ) : (
      <></>
    );
  };

  const getInputs = (items: EnrollmentRule[], itemIndex: number) => {
    const { validatedFieldProps, disabled, dataSourceOptions } = props;
    const rowModelName = getEnrollmentRulesStringKey(itemIndex);
    const filter = getFilterOptions(items[itemIndex].type as number);

    const rules = getFilterRuleOptions(
      items[itemIndex].source as UserSource,
      isRuleEngineOnCosmosEnabled,
      isLicenseGroupsFilterEnabled,
    );
    return [
      <ValidatedForm.DropdownField
        placeholder="Select Data Source"
        allowAdditions={false}
        value={items[itemIndex].source}
        minCharacters={3}
        propertyName={`${rowModelName}.source`}
        markAsRequired
        disabled={disabled}
        options={dataSourceOptions}
        testID="data-source-dropdown"
        trigger={
          items[itemIndex].source !== null && (
            <Tooltip
              target={
                <TextTruncate className="default-text">
                  {dataSourceOptions.find((item) => items[itemIndex].source === item.value)?.text}
                </TextTruncate>
              }
              content={dataSourceOptions.find((item) => items[itemIndex].source === item.value)?.text}
            />
          )
        }
        {...validatedFieldProps}
        setFieldValue={(_: string, value: any) => handleSourceChange(itemIndex, value)}
      />,
      <ValidatedForm.DropdownField
        placeholder="Select Rule"
        allowAdditions={false}
        value={items[itemIndex].type}
        minCharacters={3}
        propertyName={`${rowModelName}.type`}
        markAsRequired
        disabled={disabled || items[itemIndex].source === null}
        options={rules}
        testID="rule-dropdown"
        trigger={
          items[itemIndex].type !== null && (
            <Tooltip
              target={
                <TextTruncate className="default-text">
                  {rules?.find((item) => items[itemIndex].type === item.value)?.text}
                </TextTruncate>
              }
              content={rules?.find((item) => items[itemIndex].type === item.value)?.text}
            />
          )
        }
        {...validatedFieldProps}
        setFieldValue={(_: string, value: any) => handleRuleChange(itemIndex, value)}
      />,
      <ValidatedForm.DropdownField
        placeholder="Select Filter"
        value={items[itemIndex].filter}
        propertyName={`${rowModelName}.filter`}
        markAsRequired
        disabled={disabled || items[itemIndex].type === null}
        options={getFilterOptions(items[itemIndex].type as number)}
        testID="filter-dropdown"
        trigger={
          items[itemIndex].filter !== null &&
          items[itemIndex].filter !== 0 &&
          filter && (
            <Tooltip
              target={
                <TextTruncate className="default-text">
                  {filter.find((item) => items[itemIndex].filter === item.value)?.text}
                </TextTruncate>
              }
              content={filter.find((item) => items[itemIndex].filter === item.value)?.text}
            />
          )
        }
        {...validatedFieldProps}
        setFieldValue={(_: string, value: any) => handleFilterChange(itemIndex, value)}
      />,
      getValueField(items[itemIndex].type as number, items, itemIndex, items[itemIndex].filter as number),
    ];
  };

  return (
    <CollapsibleExpressionBuilder
      className="enrollment-rules"
      title="ENROLLMENT RULES"
      isReadOnly={disabled}
      onChange={onChange}
      getInputs={getInputs}
      items={values.enrollmentRules}
      arrayName={enrollmentRulesFieldName}
      columnTitles={[
        { title: "Data Source", fieldName: "source", info: "The source of user information", width: 16 },
        {
          title: "Rule",
          fieldName: "type",
          info: "Select a rule that will trigger a users automatic enrollment",
          width: 18,
        },
        { title: "Filter", fieldName: "filter", info: "Choose a filter to further narrow criteria", width: 16 },
        {
          title: "Criteria",
          fieldName: "value",
          info: "Select a specific criteria for the rule that will trigger a users automatic enrollment",
          width: 22,
        },
      ]}
      getDefaultNewItem={() => ({ ...defaultEnrollmentRule })}
      columnsAmount={4}
      renderHeader={renderSettings}
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  return {
    departments: state.people.usersOverview.filterOptions.departments,
    jobTitles: state.people.usersOverview.filterOptions.jobTitles,
    countryList: state.people.userCommon.countriesList,
    officeLocations: state.people.usersOverview.filterOptions.officeLocations,
    managers: state.people.usersOverview.filterOptions.managers,
    licenses: state.people.usersOverview.filterOptions.licenses,
    defaultRoles: state.people.usersOverview.filterOptions.defaultRoles,
    accountType: state.userProfile.accountTypeId,
    groups: state.people.usersOverview.filterOptions.groups,
    filterOptionsLoading: state.people.usersOverview.filterOptions.isLoading,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    userCommonActions: bindActionCreators(allUserCommonActions, dispatch),
    filterActions: bindActionCreators(filterActionCreators, dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EnrollmentBuilder);
