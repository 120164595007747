import { Reducer } from "redux";
import { PayloadAction } from "../../../../interfaces/redux";
import { WithGroupsAssignmentState } from "../../../../interfaces/state/withGroupsAssignment";
import * as actionTypes from "../../actions/groupsAssignment/groupsAssignmentActionTypes";
import { Action } from "@reduxjs/toolkit";

const initialState: WithGroupsAssignmentState = {
  groupIds: [],
};

const withGroupsAssignment = (namespace: string, entityPrefix: string): Reducer<WithGroupsAssignmentState> => {
  return function groupsAssignment(currentState: WithGroupsAssignmentState | undefined, action: Action) {
    const prefix = `${namespace}__${entityPrefix}__`;
    const state = currentState || initialState;

    switch (action.type) {
      case `${prefix}${actionTypes.ASSIGN_GROUPS_PERMISSIONS}`: {
        return { ...state, groupIds: [...(action as PayloadAction<number[]>).payload] };
      }
      case `${prefix}${actionTypes.RESET_GROUPS_PERMISSIONS}`: {
        return { ...initialState };
      }
      default:
        return state;
    }
  };
};

export default withGroupsAssignment;
