import { PayloadAction as RPayloadAction, Action } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../features/Application/globaltypes/redux";

export type PayloadAction<TResult> = RPayloadAction<TResult>;

export interface FetchActionPayload<T> {
  items: T[];
  totalCount: number;
}

export interface MidnightActionPayload {
  entityId?: number;
  type?: number;
  error?: Error;
  id?: number;
}

export type Dispatcher = AppDispatch;

interface ActionBinder {
  <T extends Array<any>, A extends Action = Action>(
    action: (...args: T) => A,
    dispatch: AppDispatch,
  ): (...args: T) => void;
  <T extends Array<any>, R>(action: (...args: T) => AppThunk<R>, dispatch: AppDispatch): (...args: T) => R;
}

export const bindAction: ActionBinder = (action: (...args: any[]) => any, dispatch: AppDispatch) => {
  return (...args: any[]) => dispatch(action(...args));
};
