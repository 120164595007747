import { Component } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { connect, ConnectedProps } from "react-redux";

import { CreateAssessmentOwnProps, CreateAssessmentState } from "./types";
import { WizardWrapper as Wizard } from "../../../WizardWrapper";
import { AssessmentDetailsCreateSteps, CreateAssessmentStepConfig } from "../types/state";
import { AppDispatch, RootState } from "../../../Application/globaltypes/redux";
import { Configure, Content } from "../index";
import { bindAction } from "../../../../interfaces";
import { assessmentsStateSelector } from "../state/selectors";
import { PublishedStatusTypes } from "../../../../enums";
import { withRouter } from "../../../../adapters/withRouter/withRouter";

import WizardStepsManager from "../../../../utils/WizardStepsManager";
import PublishValidationInfo from "../../../../components/publishValidationInfo/PublishValidationInfo";
import Sections from "../../../../enums/sections";
import navigationUtils from "../../../../utils/navigationUtils";
import SettingsTab from "../Details/Settings/SettingsTab";
import userPermissionActions from "../../PeopleAssignments/state/actions/userPermissionActions";
import groupPermissionActions from "../../PeopleAssignments/state/actions/groupPermissionActions";
import RtnEventsEmitter from "../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as assessmentContentValidatorActions from "../state/actions/assessmentContentValidatorActions";
import * as assessmentDetailsActions from "../state/actions/assessmentDetailsActions";
import * as rtnEvents from "../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { fetchContentPacksAction } from "features/Licensing/ContentAssignmentModalLicensingSteps/state/thunks/assignmentModalLicensingThunk";
import { flowPackContextItemsSelector } from "features/Library/Flows/state/selectors";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { FeatureFlags } from "featureFlags";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { LDProps } from "../../../LDProps";
import { noop } from "lodash";

const assessmentOverviewUrl = "/content/assets/assessments";

export type CreateAssessmentProps = CreateAssessmentOwnProps & PropsFromRedux & LDProps;
export type Props = CreateAssessmentProps;

export class CreateAssessment extends Component<Props, CreateAssessmentState> {
  private stepsManager = new WizardStepsManager();
  private readonly associatedPacksEnabled: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      activeStepIndex: AssessmentDetailsCreateSteps.Configuration,
      isValid: false,
      showAddPacksModal: false,
    };

    this.associatedPacksEnabled = !!this.props.flags?.[FeatureFlags.AssociatedPacks];

    this.stepsManager.subscribeOnActiveIndexChanged((_: any, activeStepIndex: number) => {
      this.setState({ activeStepIndex });
    });
  }

  componentDidMount() {
    RtnEventsEmitter.subscribe(rtnEvents.AssessmentPublishSuccess, this.onPublishedEvent);
  }

  componentWillUnmount() {
    RtnEventsEmitter.unsubscribe(rtnEvents.AssessmentPublishSuccess, this.onPublishedEvent);
    this.stepsManager.dispose();
    this.props.onUnmount();
  }

  handleCancelClick = () => {
    navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, assessmentOverviewUrl);
  };

  handleOnFinish = () => {
    this.stepsManager.onFinish();
    this.props.tryPublish(this.props.id);
  };

  returnToOverview = () => this.props.navigate(assessmentOverviewUrl, { replace: true });

  onPublishedEvent = () =>
    this.associatedPacksEnabled ? this.setState({ showAddPacksModal: true }) : this.returnToOverview();

  onIsValidChange = (isValid: boolean) => {
    this.setState({ isValid });
  };

  renderCustomHeader = (changeActiveIndex: (index: number) => void) => {
    const hasActiveErrors = !this.props.contentResolved;
    const onErrorClick = () => {
      changeActiveIndex(AssessmentDetailsCreateSteps.Content);
      this.stepsManager.goToPage(AssessmentDetailsCreateSteps.Content);
    };
    return (
      this.props.contentErrorMode && (
        <PublishValidationInfo
          unableToPublish={hasActiveErrors}
          readyToPublish={!hasActiveErrors}
          onErrorButtonClick={onErrorClick}
        />
      )
    );
  };

  getEntityState = () => (this.props.id ? PublishedStatusTypes.draft : undefined);

  onProgressAsync = (_: any, nextIndex: number) => {
    this.stepsManager.onNext(nextIndex);
  };

  onRegressAsync = async (_: any, nextIndex: number) => {
    this.stepsManager.onPrevious(nextIndex);
  };

  render() {
    const { isValid, activeStepIndex } = this.state;
    const { isSaving, dateModified, contentResolved } = this.props;

    return (
      <>
        <Dimmer active={this.props.isCreating} inverted>
          <Loader active={this.props.isCreating} />
        </Dimmer>
        <Wizard
          title="Create Assessment"
          finishButtonLabel="Finish"
          onCancel={this.handleCancelClick}
          onFinish={this.handleOnFinish}
          publishedStatus={this.getEntityState()}
          onProgressAsync={this.onProgressAsync}
          onRegressAsync={this.onRegressAsync}
          isSaveInProgress={isSaving}
          progressSavedDate={CreateAssessmentStepConfig[activeStepIndex]?.showAutoSaveOnTab ? dateModified : null}
          isFinishButtonDisabled={!contentResolved}
          renderCustomHeader={this.renderCustomHeader}
        >
          <Wizard.Step label="Configuration" className="scrollable-content" required isLocked={!isValid}>
            <Configure
              acceptHandlers={(handlers) =>
                this.stepsManager.acceptHandlers(handlers, AssessmentDetailsCreateSteps.Configuration)
              }
              onIsValidChange={this.onIsValidChange}
            />
          </Wizard.Step>
          <Wizard.Step label="Settings">
            <SettingsTab
              hasAnyPermission
              acceptHandlers={(handlers) =>
                this.stepsManager.acceptHandlers(handlers, AssessmentDetailsCreateSteps.Settings)
              }
            />
          </Wizard.Step>
          <Wizard.Step label="Content" className="scrollable-content" isLocked={!isValid}>
            <Content
              acceptHandlers={(handlers) =>
                this.stepsManager.acceptHandlers(handlers, AssessmentDetailsCreateSteps.Content)
              }
            />
          </Wizard.Step>
        </Wizard>
        <AddAssetsToPackModal
          showModal={this.state.showAddPacksModal}
          onClose={this.returnToOverview}
          onComplete={noop}
          selectedItemIds={[this.props.id]}
          contentType="Assessment"
        />
      </>
    );
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  tryPublish: bindAction(assessmentContentValidatorActions.tryPublish, dispatch),
  fetchContentPacks: bindAction(fetchContentPacksAction, dispatch),
  onUnmount: () => {
    dispatch(userPermissionActions(Sections.assessments).resetUsersAssignment());
    dispatch(groupPermissionActions(Sections.assessments).resetGroupsAssignment());
    dispatch(assessmentContentValidatorActions.resetAction());
    dispatch(assessmentDetailsActions.clearAssessment());
  },
});

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const base = assessmentsStateSelector(state).base;

  return {
    id: base.assessmentEntityStateReducer.entityId,
    assessment: base.assessmentDetailsReducer.assessment,
    dateModified: base.assessmentEntityStateReducer.lastModifiedDateTime,
    isSaving: base.assessmentEntityStateReducer.isEntityCommandInProgress,
    isCreating: base.assessmentEntityStateReducer.changingEntityState,
    contentErrorMode: base.assessmentContentValidatorReducer.errorMode,
    contentFormIsValid: base.assessmentContentValidatorReducer.isFormValid,
    packContextItems: flowPackContextItemsSelector(state),
    contentResolved: base.assessmentContentValidatorReducer.resolved,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withLDConsumer()(CreateAssessment)));
