import dataService from "../../../../../Application/services/dataServices/typedDataService";
import * as actionTypes from "../actionTypes/emailsOverviewActionTypes";
import { getActionBaseProvider, getActionProvider } from "../../../../../Application/actions/actionsBuilder";
import { Dispatcher, FetchActionPayload } from "../../../../../../interfaces/redux";
import { EmailOverview } from "../../types/state";
import { SortingDirection } from "../../../../../../enums";
import { Filters } from "../../../../../../utils/queryUtils";
import { formatFilters } from "utils/filterMapUtils";
import { emailFilterTypes } from "../../types/models";
import { escapeTerm } from "utils/searchUtils";

const countHeaderName = process.env.REACT_APP_COUNT_HEADER_NAME as string;
const takeItemsCount = process.env.REACT_APP_LOAD_ITEMS_COUNT as string;

export const getEmails = (
  take: number,
  skip: number,
  sortBy: string,
  sortOrder: SortingDirection,
  term?: string,
  filters?: Filters,
) => {
  const begin = getActionBaseProvider(actionTypes.getEmailsBegin);
  const success = getActionProvider<FetchActionPayload<EmailOverview>>(actionTypes.getEmailsSuccess);
  const failure = getActionProvider<Error>(actionTypes.getEmailsFailure);

  return async (dispatch: Dispatcher) => {
    dispatch(begin());

    try {
      let result: EmailOverview[];
      let count: number;

      const response = await dataService.content.getContent({
        top: take || Number.parseInt(takeItemsCount),
        skip,
        sortBy,
        sortOrder,
        term: escapeTerm(term),
        filters: !!filters ? formatFilters(filters, emailFilterTypes) : undefined,
        contentType: "email",
        showPurchased: true,
      });
      result = response.data.map((x) => ({
        ...x,
        canBeDuplicated: !x.isPurchased || x.bag.isPurchasedDuplicationAllowed,
      }));
      count = Number.parseInt(response.headers[countHeaderName]);

      const data: FetchActionPayload<EmailOverview> = {
        items: result,
        totalCount: count,
      };
      dispatch(success(data));
    } catch (error: any) {
      dispatch(failure(error));
    }
  };
};

export const resetEmails = () => ({
  type: actionTypes.resetEmails,
});
