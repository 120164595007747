import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import cn from "classnames";

import styles from "./buttonGroup.module.scss";

export interface ButtonGroupProps {
  items: string[];
  defaultValue: string;
  isDisabled?: boolean;
  onChange(value: string): void;
}

let index = 0;

export const ButtonGroup = ({ items, defaultValue, isDisabled, onChange }: ButtonGroupProps) => {
  const [radioValue, setRadioValue] = useState<string>(defaultValue);
  const id = useMemo(() => {
    return "radio_" + ++index;
  }, []);

  useEffect(() => {
    setRadioValue(defaultValue);
  }, [defaultValue]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) {
        event.preventDefault();
        return;
      }

      setRadioValue(event.target.value);
      onChange(event.target.value);
    },
    [onChange, isDisabled],
  );

  return (
    <span
      className={cn(styles["button-group"], {
        [styles["button-group--disabled"]]: !!isDisabled,
      })}
    >
      {items.map((label) => (
        <label
          key={label}
          className={cn(styles["button-group__item"], {
            [styles["button-group__item--active"]]: label === radioValue,
            [styles["button-group__item--disabled"]]: !!isDisabled,
          })}
        >
          <input type="radio" name={id} checked={label === radioValue} value={label} hidden onChange={handleChange} />
          {label}
        </label>
      ))}
    </span>
  );
};
