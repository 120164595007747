import { FC, useMemo } from "react";
import cn from "classnames";
import PublishStatusCard from "../../../cards/PublishStatusCard";
import { EditPriorityLinkButton } from "../../../buttons/linkButtons";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import getCardPriorityIcon from "../../priority/getCardPriorityIcon";
import { ContentType, PeopleType } from "../../../../features/Library/PeopleAssignments/types";
import ExpiresCardTemplate from "../../../ExpiresTemplate/ExpiresCardTemplate/ExpiresCardTemplate";
import { IEventAssignmentModelItem } from "../../../../features/People/types";
import { CardBase } from "../../../../interfaces/CardPropsBase";
import { RouteNames } from "enums";
import "./peopleEventCard.scss";

export interface PeopleEventCardProps extends CardBase<IEventAssignmentModelItem> {
  onRemove?: (id: number) => void;
  onEditPriority?: (id: number) => void;
  showExpirationDate?: boolean;
  peopleType: PeopleType;
  showAdded?: boolean;
  deepLink?: boolean;
}

export const PeopleEventCard: FC<PeopleEventCardProps> = (props: PeopleEventCardProps): React.ReactElement => {
  const {
    showExpirationDate,
    showAdded,
    onSelect,
    item,
    selected,
    disabled,
    peopleType,
    onEditPriority,
    disablePopupMenu,
    selectable,
    deepLink,
  } = props;

  const { id, isDraft, dateCreated, expirationDate, priorityLevelId, dueDate } = item;

  const eventCardMenuButtons = useMemo(() => {
    const buttons = [];
    if (onEditPriority) {
      buttons.push(<EditPriorityLinkButton key="edit-priority-menu-button" onClick={() => onEditPriority?.(id)} />);
    }
    return buttons.length > 0 ? buttons : null;
  }, [id, onEditPriority]);

  const icons = [
    getCardPriorityIcon({
      peopleType: peopleType,
      contentType: ContentType.Event,
      priority: priorityLevelId,
      dueDate: dueDate,
      tooltipClass: "people-event-priority-tooltip",
      showNone: false,
    }),
  ];

  return (
    <PublishStatusCard
      {...item}
      id={id}
      url={!item.inherited && deepLink ? `/${RouteNames.contentEvents}/${id}` : undefined}
      className={cn("people-event-card")}
      onSelectionChanged={onSelect}
      popupMenuButtons={eventCardMenuButtons}
      disablePopupMenu={selected || disablePopupMenu}
      published={!isDraft}
      icons={icons}
      selected={selected}
      disabled={disabled}
      selectable={selectable}
    >
      {showExpirationDate && <ExpiresCardTemplate expirationDate={expirationDate} />}
      {showAdded && <div>Added {dateTimeUtils.formatDate(dateCreated)}</div>}
    </PublishStatusCard>
  );
};
