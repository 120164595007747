import {
  createLazyFetchingItemsSlice,
  LazyFetchingItemsState,
} from "../../../../../Application/slices/createLazyFetchingItemsSlice";
import { ConnectedFlow } from "../../types";
import { ReducerEntityPrefixTypes, ReducerNamespaceTypes } from "../../../../../../enums";

export type FlowDesignerConnectedFlowsSliceState = LazyFetchingItemsState<ConnectedFlow>;

export const initialState: FlowDesignerConnectedFlowsSliceState = {
  itemsCount: 0,
  items: [],
  isLoading: false,
  areAllLoaded: false,
};

const flowDesignerConnectedFlowsSlice = createLazyFetchingItemsSlice<ConnectedFlow>({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Flows,
    name: "connectedFlows",
  },
  initialState: initialState,
  reducers: {},
});

export const { fetchBegin, fetchSuccess, fetchFailure, reset } = flowDesignerConnectedFlowsSlice.actions;

export default flowDesignerConnectedFlowsSlice.reducer;
