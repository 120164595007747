import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { UseQueryResult } from "@tanstack/react-query";

import { selectSharepointActivityPages } from "../../graphSlice";
import { ColumnDataPoint } from "../../../../../../components/charts/types/ColumnChart";
import { SharepointActivityFileCounts } from "features/Reporting/types/graph";
import { startsColor } from "features/Library/Common/utils/performanceUtils";

export const usageColumn = (result: UseQueryResult<SharepointActivityFileCounts[]>): ColumnDataPoint[] => {
  if (!result.isSuccess) {
    return [];
  }

  return result.data.map<ColumnDataPoint>((d) => ({
    id: d.ReportDate,
    category: d.ReportDate,
    value: d.ViewedOrEdited,
    fillColor: startsColor,
  }));
};

type LineData = {
  dates: Date[];
  values: number[];
};

export const usageLine = (result: UseQueryResult<SharepointActivityFileCounts[]>): LineData => {
  if (!result.isSuccess) {
    return {
      dates: [],
      values: [],
    };
  }
  const dataCount = result.data.length;
  const dates = Array<Date>(dataCount);
  const values = Array<number>(dataCount);
  for (let i = 0; i < dataCount; i++) {
    const value = result.data[i];
    dates[i] = moment(value.ReportDate).toDate();
    values[i] = value.ViewedOrEdited;
  }
  return {
    dates,
    values,
  };
};

export const selectPageVisitsLine = createSelector(selectSharepointActivityPages, (state) => {
  let length = state.value.length;
  let returnVal = {
    dates: Array<Date>(length),
    visits: Array<number>(length),
  };

  state.value.forEach((v, i) => {
    returnVal.dates[i] = moment(v.ReportDate).toDate();
    returnVal.visits[i] = v.VisitedPageCount;
  });

  return returnVal;
});
