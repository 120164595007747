import React, { FC } from "react";
import { ReactComponent as AssetIcon } from "../../../../images/assets-icon.svg";
import { NoResultsWithButton } from "../../../../components";

export interface PdfsNoResultsProps {
  createButton?: React.ReactElement | null;
  filtered?: boolean;
}

const PdfsNoResults: FC<PdfsNoResultsProps> = ({ createButton, filtered }) => {
  return (
    <NoResultsWithButton
      title="You don't have any PDFs"
      description="Looks like you don't have any PDFs yet."
      actionText="the button below to get started."
      icon={<AssetIcon />}
      actionButton={createButton}
      filtered={filtered}
    />
  );
};

export default PdfsNoResults;
