import { isEmpty } from "lodash";
import { v4 } from "uuid";

import {
  BrainStormTrigger,
  ExternalTrigger,
  ExternalTriggerGroup,
  ExternalTriggerGroupView,
  ExternalTriggerType,
  GoogleTrigger,
  MicrosoftTrigger,
  OperatorAlias,
  SourceType,
  TriggerGroupType,
} from "../../../../../types/externallTriggersTypes";
import { baseTriggerColumns, expressionsTriggerColumns, operatorsToString } from "./constants";

export const getDefaultTrigger = (): ExternalTrigger => ({
  id: v4(),
  software: null,
  application: null,
  action: null,
  hasIntegration: true,
});

export const getDefaultGroup = (): ExternalTriggerGroup<ExternalTrigger[]> => ({
  groupId: v4(),
  type: TriggerGroupType.All,
  triggers: [getDefaultTrigger()],
});

export const getOptions = (object: object) => {
  if (object) {
    return Object.keys(object).map((key) => ({ text: key, value: key }));
  }
  return [];
};

export const getInitialValues = (externalTriggerGroups?: ExternalTriggerGroup<ExternalTriggerType[]>[]) => {
  const data: ExternalTriggerGroupView = {
    externalTriggerGroups: [],
  };
  if (isEmpty(externalTriggerGroups)) {
    data.externalTriggerGroups.push(getDefaultGroup());
    return data;
  }
  externalTriggerGroups?.forEach((group) => {
    data.externalTriggerGroups.push({
      type: group.type,
      groupId: group.groupId,
      triggers: getTriggers(group.triggers),
    });
  });

  return data;
};
const getTriggers = (triggers: ExternalTriggerType[]): ExternalTriggerType[] => {
  const data: ExternalTriggerType[] = [];
  triggers.forEach((trigger) => {
    const result = {
      id: trigger.id,
      eventId: trigger.eventId,
      fullText: trigger.fullText,
      software: trigger.software,
      application: trigger.application,
      action: trigger.action,
      hasIntegration: trigger.hasIntegration,
    };

    switch (trigger.software) {
      case "Google":
        data.push({ ...result } as GoogleTrigger);
        break;
      case "BrainStorm":
      case "Microsoft": {
        data.push({
          ...result,
          criteria: trigger.criteria,
          filter: trigger.filter,
        } as MicrosoftTrigger | BrainStormTrigger);
      }
    }
  });
  return data;
};

export const getDependencyFields = (field: string, dependencyFields: string[]) => {
  return dependencyFields.slice(dependencyFields.indexOf(field) + 1);
};

export const getColumnTitles = (sourceType?: SourceType) => {
  switch (sourceType) {
    case "Google":
      return [...baseTriggerColumns];
    case "BrainStorm":
    case "Microsoft":
      return [...baseTriggerColumns, ...expressionsTriggerColumns];
    default:
      return baseTriggerColumns;
  }
};

export const mapOperators = (operators: OperatorAlias[]) => {
  return operators.map((operator) => ({ text: operatorsToString[operator], value: operator }));
};

export const getFormSettings = (
  sourceType: SourceType,
): {
  completionField: string;
  getCompletionPath: (trigger: ExternalTrigger, value: string) => string;
  dependencyFields: string[];
} => {
  switch (sourceType) {
    case "Google":
      return {
        completionField: "action",
        getCompletionPath: (values: ExternalTrigger, value: string) => `${values.application}.${value}`,
        dependencyFields: ["software", "application", "action", "eventId", "fullText"],
      };
    case "BrainStorm":
    case "Microsoft":
      return {
        completionField: "criteria",
        getCompletionPath: (values: ExternalTrigger) => `${values.application}.${values.action}`,
        dependencyFields: ["software", "application", "action", "filter", "criteria", "eventId", "fullText"],
      };
  }
};

export const onAdd = (unshift: (value: ExternalTrigger) => number) => () => {
  unshift({ ...getDefaultTrigger() });
};
