import ReducerEntityPrefixTypes from "../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../enums/reducer/reducerNamespaceTypes";
import Contact from "../../../../interfaces/contact";
import { buildFetchAction, buildRootEntityActionType } from "../../../Application/actions/actionsBuilder";
import { AccountType, AccountInfo, Account, AddOn, SavingAddOn } from "../../types";
import * as searchActionTypes from "../../../Application/actions/search/searchActionTypes";

export const FETCH_ACCOUNT_INFO_BEGIN = "EDIT_FETCH_ACCOUNT_INFO_BEGIN";
export const FETCH_ACCOUNT_INFO_SUCCESS = "EDIT_FETCH_ACCOUNT_INFO_SUCCESS";
export const FETCH_ACCOUNT_INFO_FAILURE = "EDIT_FETCH_ACCOUNT_INFO_FAILURE";
export const FETCH_ACCOUNT_INFO_FORBIDDEN = "EDIT_FETCH_ACCOUNT_INFO_FORBIDDEN";

export const FETCH_ACCOUNT_TYPES_BEGIN = "EDIT_FETCH_ACCOUNT_TYPES_BEGIN";
export const FETCH_ACCOUNT_TYPES_SUCCESS = "EDIT_FETCH_ACCOUNT_TYPES_SUCCESS";
export const FETCH_ACCOUNT_TYPES_FAILURE = "EDIT_FETCH_ACCOUNT_TYPES_FAILURE";

export const AccountContacts = "ACCOUNT_CONTACTS";
export const Contacts = "CONTACTS";
export const AddOns = "ADDONS";

export const UPDATE_ACCOUNT_INFO = "UPDATE_ACCOUNT_INFO";
export const UPDATE_ACCOUNT_CONTACTS = "UPDATE_ACCOUNT_CONTACTS";
export const UPDATE_ACCOUNT_ADDONS = "UPDATE_ACCOUNT_ADDONS";

export const FETCH_ACCOUNT_ADDONS_BEGIN = "FETCH_ACCOUNT_ADDONS_BEGIN";
export const FETCH_ACCOUNT_ADDONS_SUCCESS = "FETCH_ACCOUNT_ADDONS_SUCCESS";
export const FETCH_ACCOUNT_ADDONS_FAILURE = "FETCH_ACCOUNT_ADDONS_FAILURE";
export const FETCH_ACCOUNT_ADDONS_FORBIDDEN = "FETCH_ACCOUNT_ADDONS_FORBIDDEN";
export const RESET_ACCOUNT_ADDONS = "RESET_ACCOUNT_ADDONS";

export const FETCH_ACCOUNT_CONTACTS_BEGIN = "FETCH_ACCOUNT_CONTACTS_BEGIN";
export const FETCH_ACCOUNT_CONTACTS_SUCCESS = "FETCH_ACCOUNT_CONTACTS_SUCCESS";
export const FETCH_ACCOUNT_CONTACTS_FAILURE = "FETCH_ACCOUNT_CONTACTS_FAILURE";
export const FETCH_ACCOUNT_CONTACTS_FORBIDDEN = "FETCH_ACCOUNT_CONTACTS_FORBIDDEN";
export const RESET_ACCOUNT_CONTACTS = "RESET_ACCOUNT_CONTACTS";

export const FETCH_ACCOUNT_PACKS_FORBIDDEN = "FETCH_ACCOUNT_PACKS_FORBIDDEN";
export const RESET_ACCOUNT_PACKS_FORBIDDEN = "RESET_ACCOUNT_PACKS_FORBIDDEN";

export const AccountPacks = "ACCOUNT_PACKS";

export interface FetchAddOnsSuccess {
  type: typeof FETCH_ACCOUNT_ADDONS_SUCCESS;
  payload: Array<AddOn>;
}

export interface UpdateAddOns {
  type: typeof UPDATE_ACCOUNT_ADDONS;
  payload: Array<SavingAddOn>;
}

export interface UpdateAccountInfo {
  type: typeof UPDATE_ACCOUNT_INFO;
  payload: {
    account: Account;
  };
}

export interface FetchBegin {
  type: string;
  payload?: any;
}

export interface FetchFailure {
  type: string;
  payload: {
    error: any;
  };
}

export interface FetchSuccess<Type> {
  type: string;
  payload: Type;
}

export type FetchAccountInfoTypes = FetchBegin | FetchFailure | FetchSuccess<AccountInfo>;
export type FetchAccountTypesActionTypes = FetchBegin | FetchFailure | FetchSuccess<Array<AccountType>>;
export type AddOnsTypes = FetchBegin | FetchFailure | FetchSuccess<Array<AddOn>> | UpdateAddOns;
export type AccountContactsTypes =
  | FetchBegin
  | FetchFailure
  | FetchSuccess<{ items: Array<Contact>; itemsCount: number }>;

export const fetchAvailableAddOns = buildFetchAction(
  ReducerNamespaceTypes.Accounts,
  ReducerEntityPrefixTypes.EditAccount,
  AddOns,
);
export const fetchAccountPacks = buildFetchAction(
  ReducerNamespaceTypes.Accounts,
  ReducerEntityPrefixTypes.EditAccount,
  AccountPacks,
);

const delimiter = "__";

export const ACCOUNTS_EDIT_ACCOUNT_CONTACTS_RESET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.Accounts,
  ReducerEntityPrefixTypes.EditAccount.concat(delimiter, Contacts),
  searchActionTypes.RESET_SEARCH,
);

export const ACCOUNTS_EDIT_ACCOUNT_CONTACTS_SET_SEARCH = buildRootEntityActionType(
  ReducerNamespaceTypes.Accounts,
  ReducerEntityPrefixTypes.EditAccount.concat(delimiter, Contacts),
  searchActionTypes.SET_SEARCH,
);
