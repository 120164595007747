import { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import Usage from "./sections/Usage";
import Activity from "./sections/Activity";
import ReportingFilter from "../../../../components/reportingFilter/ReportingFilter";
import * as performanceUtils from "features/Library/Common/utils/performanceUtils";
import { GoalArea } from "../shared/GoalArea";

import { FeatureFlags } from "featureFlags";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { RootState } from "features/Application/globaltypes/redux";
import { selectMenuItemsData } from "features/Reporting/state/menu/integrationMenuSlice";
import "./teamsReport.scss";

export interface Props extends PropsFromRedux { }

export const TeamsReport: FC<Props> = ({ accountId, integrations }) => {
  const isBsiAccount = performanceUtils.isBsi(accountId);

  // Currently using old accounts reporting filter, since "includeMyData" and "accounts" is not
  // supported for these new functions.
  const [dateFilter, setDateFilter] = useState(
    performanceUtils.defaultDateFilter({ accountId, includeAccounts: isBsiAccount, includeAccountsDropdown: false }),
  );
  const goalAreaEnabled = useFeatureFlag(FeatureFlags.MsGraphGoalReporting);
  const isMicrosoftIntegrationEnabled = integrations["MSGraph"];

  const getAllCustomerAccounts = dateFilter?.showCustomers === true;

  return (
    <div className="teams-root">
      <div className="filter-row">
        <h2>Summary</h2>
        <ReportingFilter currentFilter={dateFilter} callback={setDateFilter} includeAccounts={isBsiAccount} />
      </div>
      {goalAreaEnabled && (
        <>
          <GoalArea
            dateFilter={dateFilter}
            header="Meetings"
            application="Teams"
            activity="Meetings"
            isBsi={getAllCustomerAccounts}
          />
          <GoalArea
            dateFilter={dateFilter}
            header="Messages"
            application="Teams"
            activity="Messages"
            isBsi={getAllCustomerAccounts}
          />
          <GoalArea
            dateFilter={dateFilter}
            header="Calls"
            application="Teams"
            activity="Calls"
            isBsi={getAllCustomerAccounts}
          />
        </>
      )}
      {isMicrosoftIntegrationEnabled && !getAllCustomerAccounts && (
        <>
          <h2>Usage</h2>
          <Usage dateFilter={dateFilter} />
          <h2>Activity</h2>
          <Activity dateFilter={dateFilter} />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  accountId: state.userProfile.accountId,
  integrations: selectMenuItemsData(state),
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TeamsReport);
