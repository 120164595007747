import React, { FormEvent } from "react";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { FieldPropsBase } from "./models";

import ValidatedField from "./ValidatedField";

export const CheckboxField = (props: CheckboxFieldProps) => {
  const {
    withoutErrorMessage,
    touched,
    error,
    label,
    disableValidation,
    selfValidation,
    value,
    id,
    propertyName,
    disabled,
    setFieldValue,
    index,
    radio,
    toggle,
    onChange,
    showError,
  } = props;

  const handleOnChange = async (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const currentValue = data.radio ? data.value : data.checked;
    setFieldValue(propertyName, currentValue, !selfValidation && !disableValidation);
    setTimeout(() => {
      onChange?.(event, data);
    }, 0);
  };

  return (
    <ValidatedField error={error} touched={touched} withoutErrorMessage={withoutErrorMessage} showError={showError}>
      <Checkbox
        fluid="true"
        label={label}
        id={id || propertyName}
        name={propertyName}
        onChange={handleOnChange}
        value={index}
        toggle={toggle}
        radio={radio}
        checked={value}
        disabled={disabled}
      />
    </ValidatedField>
  );
};

export interface CheckboxFieldProps extends FieldPropsBase<boolean> {
  id?: number | string;
  radio?: boolean;
  toggle?: boolean;
  index?: number;
  onChange?: (event: FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

export default React.memo(CheckboxField);
