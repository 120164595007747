import ColumnOption from "../../../../../interfaces/columnOptions";

const columnOptions: Array<ColumnOption> = [
  {
    name: "Sender Name",
    width: 3,
    isSortable: false,
  },
  {
    name: "Sender Email",
    width: 5,
    isSortable: false,
  },
  {
    name: "Type",
    width: 3,
    isSortable: false,
  },
  {
    name: "Verified",
    width: 2,
    isSortable: false,
  },
  {
    name: "Status",
    width: 3,
    isSortable: false,
  },
  {
    name: "Modified",
    width: 4,
    isSortable: false,
  },
  {
    name: "",
    width: 1,
    isSortable: false,
  },
];

export default columnOptions;
