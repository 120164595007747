import { DistributedOpUpdateParams } from "../../../../interfaces/updateParams";
import { pluralize } from "../../../../utils/stringUtils";
import identityProvidersDataService from "../../services/identityProvidersDataService";
import { IdentityProvider } from "../../types";

export default class DeleteIdentityProvidersTask implements DistributedOpUpdateParams {
  id: string;
  title: string;
  successTransientMessage: string;
  failureTransientMessage: string;
  indeterminate: boolean;
  providerIds: number[];
  accountId?: string;

  constructor(identityProviders: IdentityProvider[], accountId?: string) {
    const providerPluralized = pluralize("Provider", identityProviders.length);
    this.id = "DeleteIdentityProvider";
    this.title = `Deleting ID ${providerPluralized} '${identityProviders.map((i) => i.name).join(", ")}'`;
    this.successTransientMessage = `ID ${providerPluralized} ${
      identityProviders.length === 1 ? "has" : "have"
    } been deleted!`;
    this.failureTransientMessage = `ID ${providerPluralized} deletion failed!`;
    this.indeterminate = true;
    this.providerIds = identityProviders.map((i) => i.id);
    this.accountId = accountId;
  }

  getOperationProps = async () =>
    await identityProvidersDataService.deleteIdentityProviders(this.providerIds, this.accountId);
}
