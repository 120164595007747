import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";

import EllipsisPopupButton from "../../../../../components/buttons/ellipsisPopupButton/EllipsisPopupButton";

import { AddPeopleToContentTypes, RolePermissions, Strings } from "../../../../../enums";
import {
  AddMemberLinkButton,
  AddToLinkButton,
  DeleteLinkButton,
  DuplicateLinkButton,
} from "../../../../../components/buttons/linkButtons";
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { MultiStateButton } from "../../../../../components/buttons/multiStateButton/MultiStateButton";
import { RootState } from "../../../../../features/Application/globaltypes/redux";
import { getAddContentHeaderOptions } from "../../../AddContentButtonOptions/AddContentButtonOptions";
import { partial } from "lodash";

export type GroupsOverviewHeaderProps = GroupsOverviewHeaderBaseProps;

export interface GroupsOverviewHeaderBaseProps {
  showAddUsersModal: () => void;
  selectedGroups: any;
  onlyManualGroupsSelected: () => boolean;
  onDuplicateGroups: (groupsIds: number[]) => void;
  onDeleteGroups: (groupsIds: number[]) => void;
  renderCreateButton: () => React.ReactElement;
  onAddToContentModal: (groupIds: number[], contentType: AddPeopleToContentTypes) => void;
}

export const GroupsOverviewHeader: FC<GroupsOverviewHeaderProps> = ({
  showAddUsersModal,
  selectedGroups,
  onlyManualGroupsSelected,
  onDeleteGroups,
  onDuplicateGroups,
  renderCreateButton,
  onAddToContentModal,
}) => {
  const isSelectedGroups = selectedGroups.some(Boolean);
  const selectedGroupsCount = selectedGroups.length;
  const selectedGroupsIds = useMemo(() => selectedGroups.map((group: any) => group.id), [selectedGroups]);
  const userPermissions = useSelector<RootState, RolePermissions[]>((state) => state.userProfile.permissions);
  const hasGroupManagePermissions = userPermissions.includes(RolePermissions.GroupsManage);

  const renderAddContentButton = () => {
    const options = getAddContentHeaderOptions(partial(onAddToContentModal, selectedGroupsIds));

    return (
      <MultiStateButton
        id="add-to"
        isLinkStyle
        trigger={<AddToLinkButton text={Strings.modalTitles.addContent} />}
        items={options}
        simple
        direction="left"
      />
    );
  };

  const renderDuplicateGroupsButton = () => (
    <DuplicateLinkButton onClick={() => onDuplicateGroups(selectedGroupsIds)} />
  );

  const renderDeleteGroupsButton = () => <DeleteLinkButton onClick={() => onDeleteGroups(selectedGroupsIds)} />;

  const renderEllipsisPopupButtons = () => {
    return (
      <EllipsisPopupButton
        trigger={
          <Button className="ellipsis-button">
            <Icon color="blue" className="ellipsis horizontal" />
          </Button>
        }
      >
        {renderDuplicateGroupsButton()}
        {hasGroupManagePermissions && renderDeleteGroupsButton()}
      </EllipsisPopupButton>
    );
  };

  const renderAddPeopleButton = (onAddPeople: () => void) => <AddMemberLinkButton onClick={onAddPeople} />;

  return (
    <OverviewHeader title="Groups" itemName="Group" selectedItemsAmount={selectedGroupsCount}>
      {onlyManualGroupsSelected() && hasGroupManagePermissions && renderAddPeopleButton(showAddUsersModal)}
      {isSelectedGroups && hasGroupManagePermissions && renderAddContentButton()}
      {isSelectedGroups && renderEllipsisPopupButtons()}
      {!isSelectedGroups && renderCreateButton()}
    </OverviewHeader>
  );
};

export default GroupsOverviewHeader;
