import React from "react";
import { Button } from "components/buttons/button/Button";

import NoResults from "../noResults/NoResults";
import navigationUtils from "../../utils/navigationUtils";
import { ReactComponent as NotFoundIcon } from "../../images/bsi-error.svg";
import { useLocation, useNavigate } from "react-router-dom";

import "./notFoundMessage.scss";

export interface NotFoundMessageProps {
  onGoBack?: () => void;
}

export const NotFoundMessage: React.FC<NotFoundMessageProps> = ({ onGoBack }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    navigationUtils.goBackOrDefault(location, navigate, "/licensing/packs/available");
  };

  const onGoBackCb = onGoBack || goBack;
  return (
    <div className="not-found-message" data-testid={"not-found"}>
      <NoResults
        title="Page not found"
        description="The page you requested could not be found."
        icon={<NotFoundIcon />}
        outline={false}
      >
        <Button primary onClick={onGoBackCb}>
          Go Back
        </Button>
      </NoResults>
    </div>
  );
};

export default NotFoundMessage;
