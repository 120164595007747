import * as actionTypes from "../actionTypes/linksOverviewActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import { LinkOverview, LinksOverviewState } from "../../types/state";
import { PayloadAction, FetchActionPayload } from "../../../../../interfaces/redux";

const initialState: LinksOverviewState = {
  links: [],
  isLoading: false,
  totalCount: 0,
  error: undefined,
};

const linksOverviewHandlers = () => {
  const { getLinksBegin, getLinksSuccess, getLinksFailure } = actionTypes;

  const getLinksBeginHandler = (state: LinksOverviewState): LinksOverviewState => ({
    ...state,
    links: new Array<LinkOverview>(),
    isLoading: true,
  });

  const getLinksSuccessHandler = (
    state: LinksOverviewState,
    action: PayloadAction<FetchActionPayload<LinkOverview>>,
  ): LinksOverviewState => ({
    ...state,
    links: action.payload.items,
    totalCount: action.payload.totalCount,
    isLoading: false,
  });

  const getLinksFailureHandler = (state: LinksOverviewState, action: PayloadAction<Error>): LinksOverviewState => ({
    ...state,
    isLoading: false,
    error: action.payload,
  });

  return {
    [getLinksBegin]: getLinksBeginHandler,
    [getLinksSuccess]: getLinksSuccessHandler,
    [getLinksFailure]: getLinksFailureHandler,
  };
};

export const linksOverviewReducer = createReducer(initialState, [linksOverviewHandlers]);
