import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { GoalOptions, GoalView } from "../../types/models";
import { RootState } from "features/Application/globaltypes/redux";

export interface GoalState {
  values: GoalView;
  options: GoalOptions;
  isLoaded: boolean;
  isLoading: boolean;
  isLoadingOptions: boolean;
  isValid: boolean;
}

export const initialState: GoalState = {
  values: {
    metricId: 201, // Default value
    applicationId: null,
    dataSourceId: null,
    measureId: null,
    objectiveId: null,
    typeId: null,
  },
  isLoaded: false,
  isLoading: false,
  isLoadingOptions: false,
  isValid: false,
  options: {
    application: [],
    measure: [],
    dataSource: [],
    objective: [],
    successMetric: [],
    type: [],
  },
};

const flowGoalSlice = createSlice({
  name: `${ReducerNamespaceTypes.Library}/${ReducerEntityPrefixTypes.Flows}/goal`,
  initialState,
  reducers: {
    reqOptions(state) {
      state.isLoadingOptions = true;
    },
    gotOptions(state, action: PayloadAction<GoalOptions>) {
      state.isLoadingOptions = false;
      state.options = action.payload;
    },
    setIsLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },
    reqGoal(state) {
      state.isLoading = true;
    },
    gotGoal(state, action: PayloadAction<GoalView>) {
      state.isLoaded = true;
      state.isLoading = false;
      state.values = action.payload;
    },
    saveGoal(state, action: PayloadAction<GoalView>) {
      state.values = action.payload;
    },

    reset: () => initialState,
  },
});

export const { saveGoal, gotGoal, gotOptions, reqGoal, reqOptions, reset, setIsLoaded } = flowGoalSlice.actions;

const selectFlowGoalValues = (state: RootState) => state.library.flows.base.goal.values;
const selectFlowGoalOptions = (state: RootState) => state.library.flows.base.goal.options;

export const selectFlowGoalPerformanceInfo = createSelector(
  selectFlowGoalValues,
  selectFlowGoalOptions,
  (values, options) => {
    if (!values || !options) return null;

    return {
      applicationLabel: options.application.find((item) => item.id === values.applicationId)?.label,
      measureLabel: options.measure.find((measure) => measure.id === values.measureId)?.label,
    };
  },
);

export default flowGoalSlice.reducer;
