/**
 *
 * @param innerWidth
 * @param innerHeight
 * @returns
 */
export function widthAndHeightInvalid(innerWidth: number, innerHeight: number) {
  // On first run its common for the svg measurements to be 0
  // This causes issues when first drawing the chart
  const invalidDimensions = innerWidth <= 0 || innerHeight <= 0;
  // There are issues when measuring things with JSDOM,
  // so this will always return early in test environments
  return process.env.NODE_ENV !== "test" && invalidDimensions;
}
