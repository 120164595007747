import { ColumnOption } from "../../../../../../../interfaces/columnOptions";
import { SurveyUser } from "../../../../types/models";
import UserInfoCell from "../../../../../../../components/userInfoCell/UserInfoCell";

/* istanbul ignore next */

export enum Columns {
  Name = "Name",
}

export const ColumnToParamMap: {
  [P in Lowercase<Columns>]: string;
} = {
  name: "firstName",
};

export const getColumnOptions = (): ColumnOption<SurveyUser>[] => {
  return [
    {
      name: Columns.Name,
      width: 5,
      isSortable: true,
      disabled: (item) => item.inherited,
      render: (item) => <UserInfoCell deepLink={!item.inherited} user={item} />,
    },
  ];
};
