import { useQuery } from "@tanstack/react-query";
import CardReporting, { CardReportingItem } from "components/cards/CardReporting/CardReporting";
import { RequestStatusRenderer } from "components/requestStatsRenderer/RequestStatusRenderer";
import { PerformanceFilter, QueryFilter, roundToTwoDigits } from "features/Library/Common/utils/performanceUtils";
import { GenericPerformanceList } from "features/Reporting/Content/shared/GenericPerformanceList";
import { peopleReportingService } from "features/Reporting/services/peopleReportingService";
import { PeopleOutreachItem } from "features/Reporting/types/people";
import { ColumnOption } from "interfaces/columnOptions";
import { useMemo } from "react";
import dateTimeUtils from "utils/dateTimeUtils";
import { usePeopleOutreachTableQuery } from "../queries";

const fetchPeopleOutreachStatistics = async ({ queryKey, signal }: QueryFilter): Promise<CardReportingItem[]> => {
  const result = await peopleReportingService.activity.getPeopleOutreachStatistics(queryKey[1], signal!);

  return [
    {
      stat: (result.data.DistinctUsers ?? 0).toLocaleString(),
      statDescription: "Users",
      popupBody:
        "Total number of users who received some form of outreach, flows, email, etc. during the specified time period.",
    },
    {
      stat: (result.data.OutreachAttempts ?? 0).toLocaleString(),
      statDescription: "Outreach Attempts",
      popupBody:
        "Total number of outreach attempts across all outreach types, flows, email, etc. during the specified time period.",
    },
    {
      stat: roundToTwoDigits(result.data.AvgOutreach ?? 0).toLocaleString(),
      statDescription: "Avg. Outreach/User",
      popupBody:
        "Total number of outreach attempts across all types divided by the total number of participating users during the specified time period.",
    },
  ];
};

export interface Props {
  dateFilter: PerformanceFilter;
  setSelectedDrilldown: (category: string) => void;
}

export const PeopleOutreach = ({ dateFilter, setSelectedDrilldown }: Props) => {
  const peopleOutreachStatisticsQuery = useQuery({
    queryFn: fetchPeopleOutreachStatistics,
    queryKey: ["people outreach statistics", dateFilter],
  });

  const peopleOutreachTableQuery = usePeopleOutreachTableQuery(dateFilter);

  const columns: ColumnOption<PeopleOutreachItem>[] = useMemo(
    () => [
      {
        name: "Type",
        width: 7,
        render(item) {
          return (
            <button className="invisibleButton" onClick={() => setSelectedDrilldown(item.OutreachType)}>
              {item.OutreachType}
            </button>
          );
        },
      },
      {
        name: "Users",
        width: 7,
        render(item) {
          return <span>{(item.Users ?? 0).toLocaleString()}</span>;
        },
      },
      {
        name: "Attempts",
        width: 7,
        render(item) {
          return <span>{(item.OutreachCount ?? 0).toLocaleString()}</span>;
        },
      },
      {
        name: "Last Activity",
        width: 7,
        render(item) {
          return <span>{item.LastOutreach ? dateTimeUtils.formatDate(item.LastOutreach) : "-"}</span>;
        },
      },
    ],
    [setSelectedDrilldown],
  );

  return (
    <div className="tabSpacer">
      <RequestStatusRenderer state={peopleOutreachStatisticsQuery.status}>
        <CardReporting items={peopleOutreachStatisticsQuery.data!} />
      </RequestStatusRenderer>

      <div className="tabSpacer">
        <RequestStatusRenderer state={peopleOutreachTableQuery.status}>
          <GenericPerformanceList
            hideControls
            rows={peopleOutreachTableQuery.data!}
            columns={columns}
            filter={dateFilter}
          />
        </RequestStatusRenderer>
      </div>
    </div>
  );
};
