import React from "react";
import NoResults from "../../../components/noResults/NoResults";
import NoSearchResults from "../../../components/noSearchResults";

export interface RolesNoResultsProps {
  filtered: boolean;
  createRoleButton?: React.ReactNode;
}

const RolesNoResults: React.FC<RolesNoResultsProps> = (props) => {
  return props.filtered ? (
    <NoSearchResults />
  ) : (
    <NoResults
      title="You don't have any roles"
      description="Looks like you don't have any roles yet. Create one now using the button below."
      iconClassName="fal fa-user-tie"
    >
      {props.createRoleButton}
    </NoResults>
  );
};

export default RolesNoResults;
