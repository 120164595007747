import IntegrationRow, { columnOptions, mapColumnName, defaultSorting } from "./IntegrationRow/IntegrationRow";
import { IntegrationKey, IntegrationWithCompositeId } from "../../../Integrations/types";
import NoResults from "../../../../../components/noResults/NoResults";
import { ReactComponent as IntegrationsIcon } from "../../../../../images/integrations.svg";
import { OverviewWithInMemorySorting } from "../../../../../components/overviewWithInMemorySorting/OverviewWithInMemorySorting";

const IntegrationsList: React.FC<IntegrationsListProps> = ({
  selectedIds,
  ownAccount,
  moboId,
  isLoading,
  renderAddIntegrationButton,
  integrations,
  renderOptionsMenu,
  updateSelectedItems,
}) => {
  return (
    <OverviewWithInMemorySorting
      columnOptions={columnOptions}
      buildTableBody={(integration: IntegrationWithCompositeId) => (
        <IntegrationRow
          ownAccount={ownAccount}
          moboId={moboId}
          integration={integration}
          renderOptionsMenu={renderOptionsMenu}
        />
      )}
      mapColumnName={mapColumnName}
      items={integrations}
      isLoading={isLoading}
      sortingColumnName={defaultSorting.columnName}
      sortingDirection={defaultSorting.direction}
      updateSelectedItems={updateSelectedItems}
      selectedItemIds={selectedIds}
      noResultsContent={
        <NoResults
          title="No integrations for this account"
          description="Looks like you don't have any integrations yet. Click below to get started."
          icon={<IntegrationsIcon />}
        >
          {renderAddIntegrationButton()}
        </NoResults>
      }
    />
  );
};

export interface IntegrationsListProps {
  integrations: Array<IntegrationWithCompositeId>;
  moboId?: string;
  ownAccount: boolean;
  isLoading: boolean;
  selectedIds: IntegrationKey[];
  renderAddIntegrationButton: () => React.ReactElement;
  renderOptionsMenu: (integration: IntegrationWithCompositeId) => React.ReactElement;
  updateSelectedItems: (newItems: IntegrationKey[]) => void;
}

export default IntegrationsList;
