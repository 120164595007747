import React from "react";
import classNames from "classnames";
import { Button } from "components/buttons/button/Button";

import "./weekDayButton.scss";

export interface IWeekDayButtonProps {
  weekDay: WeekDays;
  onToggle: (weekDay: WeekDays) => void;
  selected: boolean;
  disabled?: boolean;
}

export enum WeekDays {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}

const WeekDayButton: React.FC<IWeekDayButtonProps> = ({ weekDay, onToggle, selected, disabled }): React.ReactElement => (
  <div className={classNames("weekday-button")}>
    <Button blur disabled={disabled} circular toggle active={selected} onClick={() => onToggle?.(weekDay)}>
      {weekDay[0]}
    </Button>
  </div>
);

export default WeekDayButton;
