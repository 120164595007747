import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { Routes, Route, Navigate } from "react-router-dom";

import { RootState } from "../../../../Application/globaltypes/redux";
import { CampaignDetailsState, Pages } from "./types";
import * as campaignDetailsActions from "../state/actions/threatDefenceCampaignDetailsActions";
import * as campaignOptionsActions from "../state/actions/threatDefenceCampaignOptionsActions";
import * as campaignEntityStateActions from "../state/actions/threatDefenceCampaignsEntityStateActions";
import { DetailsHeader } from "../../../../../components/sectionHeader";
import Segments from "../../../../../components/navigation/segments/Segments";
import Configure from "../Details/Configure/Configure";
import Content from "../Details/Content/Content";
import CampaignRuns from "../Details/Runs/CampaignRuns";
import WizardStepsManager from "../../../../../utils/WizardStepsManager";
import CampaignDetailsSubheaderContainer from "./CampaignDetailsSubheaderContainer";
import { RouteNames, PublishedStatusTypes } from "../../../../../enums";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import PublishValidationInfo from "../../../../../components/publishValidationInfo/PublishValidationInfo";
import RevertConfirmationModal from "../../../../../components/modal/RevertConfirmationModal";
import modalUtilsFactory, { ModalUtils } from "../../../../../utils/modalUtilsFactory";
import ModalTypes from "../../../../../components/modal/ModalTypes";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const campaignOverviewUrl = `/${RouteNames.campaignsSimulatedPhishing}`;

export type CampaignDetailsPropsAll = PropsFromRedux & WithRouterProps;

export class EditCampaignDetails extends Component<CampaignDetailsPropsAll, CampaignDetailsState> {
  private readonly pages: Pages;
  private readonly id: number;
  private readonly revertModal: ModalUtils;

  private stepsManager = new WizardStepsManager();

  constructor(props: CampaignDetailsPropsAll) {
    super(props);
    this.state = {
      isSaving: false,
      modifiedDate: undefined,
      isValid: true,
      isContentValid: false,
      isErrorViewMode: false,
      areErrorsResolved: false,
      showRevertModal: false,
    };
    this.pages = {
      configure: {
        index: 0,
        segment: {
          label: "Configuration",
          onClick: this.openConfigurePage,
        },
      },
      content: {
        index: 1,
        segment: {
          to: "content",
          label: "Content",
          onClick: this.openContentPage,
        },
      },
      runs: {
        index: 2,
        segment: {
          to: "runs",
          label: "Runs",
          onClick: this.openRunsPage,
        },
      },
    };
    this.id = this.getCampaignId();
    this.revertModal = modalUtilsFactory();
  }

  async componentDidMount() {
    this.props.campaignDetailsActions.getCampaign(this.id);
    this.props.campaignDetailsActions.getCampaignContent(this.id);

    RtnEventsEmitter.subscribe(
      [rtnEvents.CampaignPublishSuccess, rtnEvents.CampaignDiscardSuccess],
      this.onPublishedEvent,
    );
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe(
      [rtnEvents.CampaignPublishSuccess, rtnEvents.CampaignDiscardSuccess],
      this.onPublishedEvent,
    );
  }

  componentDidUpdate(_: Readonly<CampaignDetailsPropsAll>, prevState: Readonly<CampaignDetailsState>) {
    const isContentValid = !!this.props.content?.emailTemplateId && !!this.props.content?.landingPageId;
    if (prevState.isContentValid !== isContentValid) {
      this.setState({ isContentValid });
    }
  }

  openConfigurePage = () => {
    this.stepsManager.goToPage(this.pages.configure.index);
    if (!this.props.isCampaignLoaded) {
      this.props.campaignDetailsActions.getCampaign(this.id);
    }
  };

  openContentPage = () => {
    this.stepsManager.goToPage(this.pages.content.index);
    if (!this.props.isCampaignContentLoaded) {
      this.props.campaignDetailsActions.getCampaignContent(this.id);
    }
  };

  openRunsPage = () => {
    this.stepsManager.goToPage(this.pages.content.index);
  };

  getCampaignId = () => {
    return Number(this.props.params.id);
  };

  onIsValidChange = (isValid: boolean) => {
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  };

  onContentIsValidChange = (isValid: boolean) => {
    if (this.state.isContentValid !== isValid) {
      this.setState((prevState) => {
        return {
          isContentValid: isValid,
          isErrorViewMode: false,
          areErrorsResolved: prevState.isErrorViewMode && isValid,
        };
      });
    }
  };

  onPublishedEvent = () =>
    [
      this.props.campaignDetailsActions.clearCampaign,
      this.props.campaignDetailsActions.getCampaign,
      this.props.campaignDetailsActions.getCampaignContent,
    ].forEach((action) => action(this.id));

  onRevert = () => {
    this.revertModal.execute(this.getCampaignId(), this.handleRevertConfirmation, () =>
      this.setState({
        ...this.state,
        showRevertModal: true,
      }),
    );
    this.resetValidation();
  };

  handleClose = () => {
    this.props.navigate(campaignOverviewUrl);
  };

  handleEdit = () => {
    this.props.campaignEntityStateActions.fetchDraftThreatDefenceCampaignEntity(this.id);
  };

  handlePublish = () => {
    if (!this.state.isContentValid) {
      this.setState({ isErrorViewMode: true });
      return;
    }
    this.props.campaignEntityStateActions.publishDraftThreatDefenceCampaignEntity(this.id);
    this.resetValidation();
  };

  handleRevertConfirmation = () => {
    this.closeRevertPopup();
    this.props.campaignEntityStateActions.discardCampaign(this.getCampaignId());
  };

  closeRevertPopup = () => {
    this.setState({
      ...this.state,
      showRevertModal: false,
    });
  };

  resetValidation = () => {
    this.setState({ isErrorViewMode: false, areErrorsResolved: false });
  };

  openAddPeopleModal = () => {
    // This is intentional
  };

  renderPublishValidationInfo = () => {
    const { navigate, location } = this.props;
    const { isErrorViewMode, areErrorsResolved } = this.state;
    const redirectTo = `/content/simulated-phishing/campaigns/${this.getCampaignId()}/content`;

    return (
      <PublishValidationInfo
        unableToPublish={isErrorViewMode}
        onErrorButtonClick={() => {
          if (!location.pathname.includes(redirectTo)) {
            navigate(redirectTo);
            this.openContentPage();
          }
        }}
        readyToPublish={areErrorsResolved}
      />
    );
  };

  renderRevertModal = (isRevertVisible: boolean) => {
    return (
      <RevertConfirmationModal
        modalUtils={this.revertModal}
        modalType={ModalTypes.RevertCampaign}
        open={this.state.showRevertModal}
        onClose={this.closeRevertPopup}
        isVisible={isRevertVisible}
      />
    );
  };

  renderRunButton() {
    return (
      <Button primary className="run-button create-button" onClick={this.openAddPeopleModal}>
        Run
      </Button>
    );
  }

  render() {
    const { campaign } = this.props;
    const { configure, content, runs } = this.pages;
    const { isValid, isErrorViewMode } = this.state;
    const disabled = !campaign.isDraft;

    return (
      <div className="nested-content scrollable-content">
        <DetailsHeader
          title={campaign.name}
          titleForGA="Phishing Campaign Details"
          backButton={campaignOverviewUrl}
          canBeEdited={!campaign.isDraft}
          isRevertVisible={campaign.hasBeenPublished}
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!campaign.isDraft)}
          invalidFormDetails={!isValid}
          canBePublished={!isErrorViewMode}
          entityStateActions={{
            onClose: this.handleClose,
            onEdit: this.handleEdit,
            onPublish: this.handlePublish,
            onRevert: this.onRevert,
          }}
        >
          {this.renderPublishValidationInfo()}
          {!campaign.isDraft && this.renderRunButton()}
        </DetailsHeader>
        {this.renderRevertModal(campaign.hasBeenPublished)}
        <CampaignDetailsSubheaderContainer
          publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!campaign.isDraft)}
          isUpdateInProgress={campaign.isDraft}
          lastModifiedDateTime={campaign.dateModified}
        >
          <Segments to={`/${RouteNames.campaignsSimulatedPhishing}/${this.id}`}>
            <Segments.Segment {...configure.segment} />
            <Segments.Segment {...content.segment} />
            <Segments.Segment {...runs.segment} />
          </Segments>
        </CampaignDetailsSubheaderContainer>
        <Dimmer active={this.props.isLoading} inverted>
          <Loader />
        </Dimmer>

        <Routes>
          <Route
            path="/"
            element={
              <div className="edit-form">
                <Configure
                  entityId={this.id}
                  acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, configure.index)}
                  onIsValidChange={this.onIsValidChange}
                  disabled={disabled}
                />
              </div>
            }
          />
          <Route
            path={content.segment.to}
            element={
              <div className="edit-form">
                <Content
                  entityId={this.id}
                  acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, content.index)}
                  onIsValidChange={this.onContentIsValidChange}
                  disabled={disabled}
                />
              </div>
            }
          />
          <Route path={runs.segment.to} element={<CampaignRuns campaignId={this.id} {...this.props} />} />
          <Route path="*" element={<Navigate to="../" replace />} />
        </Routes>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  campaign: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.campaign,
  content: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.content,
  isLoading: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.isLoading,
  isCampaignLoaded: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.isCampaignLoaded,
  isCampaignContentLoaded: state.library.threatDefenceCampaigns.threatDefenceCampaignDetails.isContentLoaded,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => ({
  campaignDetailsActions: bindActionCreators(campaignDetailsActions, dispatch),
  campaignEntityStateActions: bindActionCreators(campaignEntityStateActions, dispatch),
  campaignOptionsActions: bindActionCreators(campaignOptionsActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(EditCampaignDetails));
