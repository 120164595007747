import { RootState } from "../../../Application/globaltypes/redux";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParentAccount, RestrictedResource } from "../../types";

export interface ParentAccountsState {
  items: ParentAccount[],
  isResourceUnavailable: boolean;
  isLoading: boolean;
  error?: string;
}

const initialState: ParentAccountsState = {
  items: [],
  isResourceUnavailable: false,
  isLoading: false,
  error: undefined,
};

const parentAccountSlice = createSlice({
  name: "parentAccounts",
  initialState: initialState,
  reducers: {
    req(state: ParentAccountsState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: ParentAccountsState, action: PayloadAction<RestrictedResource<ParentAccount>>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload.items,
        isResourceUnavailable: action.payload.isResourceUnavailable,
      };
    },
    err(state: ParentAccountsState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = parentAccountSlice.actions;

export const parentAccountsSelector = (state: RootState) => state.accounts.parentAccounts;
export const parentAccountsLoadingSelector = (state: RootState) =>
  state.accounts.parentAccounts.isLoading;

export default parentAccountSlice.reducer;
export type parentAccountsStateSliceType = ReturnType<typeof parentAccountSlice.reducer>;
