import React from "react";
import { Icon, Radio } from "semantic-ui-react";
import cn from "classnames";
import { Tooltip } from "../../../../../../../components/common/tooltip";

export interface Props {
  isReadOnly: boolean;
  onIsActionChange: (value: boolean | undefined, source: string, destination: string, bulletId?: string | null) => void;
  isAction: boolean;
  source: string;
  destination: string;
  bulletId?: string | null;
}

export const TriggerCommunicationContent: React.FC<Props> = ({
  isReadOnly,
  onIsActionChange,
  isAction,
  source,
  destination,
  bulletId,
}) => {
  return (
    <div className="trigger-destination">
      <div className={cn("trigger-destination-type", { disabled: isReadOnly })}>
        <Radio
          id={`connecting-item-${destination}`}
          label="Connecting Item"
          checked={!isAction}
          onChange={(_, data) => {
            onIsActionChange(!data.checked, source, destination, bulletId);
          }}
        />
        <Radio
          id={`action-item-${destination}`}
          label="Action Item"
          checked={isAction}
          onChange={(_, data) => {
            onIsActionChange(data.checked, source, destination, bulletId);
          }}
        />
      </div>
      <Tooltip
        content="Surfaces to the end user without affecting the state of the Flow."
        target={<Icon className="info circle" />}
        tooltipClassName="desination-type-tooltip"
        position="top right"
        showAlways
      />
    </div>
  );
};
