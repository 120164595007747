export default class PublishedStatusTypes {
  static draft: PublishedStatusType = "draft";
  static published: PublishedStatusType = "published";

  static ConvertToPublishedStatusType(status: boolean) {
    return status ? this.published : this.draft;
  }
}

export type PublishedStatusType = "draft" | "published";
