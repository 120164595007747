import React from "react";
import { ThreatDefenceLandingPageOverview } from "../../features/Library/SimulatedPhishing/LandingPages/types/state";
import EllipsisPopupButton from "../buttons/ellipsisPopupButton/EllipsisPopupButton";
import { EditLinkButton } from "../buttons/linkButtons";

export interface ILandingPageEllipsisPopupButtonProps {
  landingPage: ThreatDefenceLandingPageOverview;
  onEditLandingPage: (id: number, isDraft: boolean) => void;
  circle: boolean;
  outlinedEllipsis: boolean;
  disabled: boolean;
}

function LandingPageEllipsisPopupButton(props: ILandingPageEllipsisPopupButtonProps) {
  const { landingPage, onEditLandingPage, ...ellipsisProps } = props;

  return (
    <EllipsisPopupButton {...ellipsisProps}>
      <EditLinkButton onClick={() => onEditLandingPage(landingPage.id, landingPage.isDraft)} />
    </EllipsisPopupButton>
  );
}

LandingPageEllipsisPopupButton.defaultProps = {
  disabled: false,
};

export default LandingPageEllipsisPopupButton;
