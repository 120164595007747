import { ReactComponent as NoAssetResult } from "../../../../../../images/cloud-arrow-up.svg";
import NoResults from "../../../../../../components/noResults/NoResults";

const descriptionMarkup = (
  <span>
    Looks like you don't have any WebVTT (.vtt) files yet.
    <br />
    Browse to select files from your computer.
  </span>
);
export const ClosedCaptionsNoResults = () => {
  return <NoResults title="No Closed Caption Files" description={descriptionMarkup} icon={<NoAssetResult />} />;
};
