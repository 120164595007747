import { Columns } from "../Overview/columnOptions";

const columnsMap: { [key: string]: string } = {
  title: "title",
  subject: "subject",
  status: "isDraft",
  added: "dateCreated",
  modified: "dateModified",
  sent: "dateSent",
};

export const EmailListUtils = {
  formatOrderColumn: (sortingColumnName: Columns): string => columnsMap[sortingColumnName] ?? sortingColumnName,
};
