import { FC, useEffect, useRef, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { SearchInput } from "../../../../../../../components";

import UsersListContainer from "./UsersListContainer/UsersListContainer";
import Restricted from "../../../../../../Application/Restricted";
import ContentUsersNoResults from "../../../../../Common/Fields/ContentUsersNoResults";

import { AssignmentPeopleContext, RolePermissions, SortingDirection } from "../../../../../../../enums";
import { Columns, ColumnToParamMap, getColumnOptions } from "./getColumnOptions";
import { resetSelectedItems } from "../../../../state/slices/assessmentUsersSlice";
import { AppDispatch, RootState } from "../../../../../../Application/globaltypes/redux";

import { DEFAULT_REQUEST_PARAMS } from "../../../../../PeopleAssignments/Overview/helpers/constants";
import { fetchAssessmentUsers } from "../../../../state/thunks/assessmentUsersThunk";
import { FiltersMap } from "../../../../../../../utils/filterUtils";
import { PeopleAssignmentColumns } from "../../../../../PeopleAssignments/Overview/helpers/getColumnOptions";
import { useContentAssignmentsPermissions } from "../../../../../../../hooks/useContentAssignmentsPermissions";

export interface UsersOverviewPropsBase {
  assessmentId: number;
  customHeaderContent: React.ReactElement;
}

export type UsersOverviewProps = UsersOverviewPropsBase & PropsFromRedux;

export const UsersOverview: FC<UsersOverviewProps> = ({ assessmentId, customHeaderContent, fetchUsers, onUnmount }) => {
  const reloadListItems = useRef<(enableSorting: boolean) => void>();
  const [search, setSearch] = useState<string>("");

  const renderSearchInput = (): React.ReactElement => (
    <SearchInput placeholder="Search for People..." onChange={onSearchChange} defaultValue={search} />
  );

  const onSearchChange = async (searchTerm: string) => {
    setSearch(searchTerm);
    reloadListItems.current?.(!searchTerm);
  };

  const createReloadListItems = (reloadListItemsFunc: (enableSorting: boolean) => void) => {
    reloadListItems.current = reloadListItemsFunc;
  };

  useEffect(
    () => () => {
      onUnmount();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getAssessmentUsers = (
    skip: number = DEFAULT_REQUEST_PARAMS.skip,
    top: number = DEFAULT_REQUEST_PARAMS.top,
    orderBy: string = PeopleAssignmentColumns.Name,
    sortingDirection: SortingDirection = DEFAULT_REQUEST_PARAMS.sortingDirection,
    appliedFilter: FiltersMap = DEFAULT_REQUEST_PARAMS.appliedFilter,
  ): void => {
    const sortingColumn = ColumnToParamMap[orderBy.toLowerCase() as Lowercase<Columns>];
    fetchUsers({
      entityId: assessmentId,
      skip,
      top,
      sortingColumn,
      sortingDirection,
      filter: appliedFilter,
      term: search,
    });
  };

  const permissionPredicate = useContentAssignmentsPermissions(
    AssignmentPeopleContext.User,
    RolePermissions.AssetsManage,
  );

  return (
    <Restricted
      permissions={[]}
      permissionPredicate={permissionPredicate}
      renderContent={() => (
        <UsersListContainer
          defaultSortingColumnName={Columns.Name}
          sortingDirection={SortingDirection.Ascending}
          columnOptions={getColumnOptions()}
          applyFilter={/* istanbul ignore next */ () => {}} //NOSONAR
          resetFilter={/* istanbul ignore next */ () => {}} //NOSONAR
          fetchData={getAssessmentUsers}
          renderSearch={renderSearchInput}
          customHeaderContent={customHeaderContent}
          isSelectDisabled={() => true}
          setReloadListItems={createReloadListItems}
          noResultsContent={<ContentUsersNoResults filtered={!!search} />}
        />
      )}
    />
  );
};
/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  selectedIds: state.library.assessments.edit.users.selectedIds,
  search: state.library.userAssignment.search,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: bindActionCreators(fetchAssessmentUsers, dispatch),
  onUnmount: () => dispatch(resetSelectedItems()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersOverview);
