import DataService from "../../../../Application/services/dataServices/typedDataService";

import fileUtils, { AcceptCancelationHandler, OnUploadProgressHandler } from "../../../../../utils/fileUtils";
import { AppDispatch } from "../../../../Application/globaltypes/redux";
import { UpdatePdfFileRequest } from "../../types/requests";
import { setPdfEntityIdAction } from "../actions/pdfEntityStateActions";
import {
  loadPdfBegin,
  loadPdfFailure,
  loadPdfSuccess,
  uploadPdfBegin,
  uploadPdfCancel,
  uploadPdfSuccess,
} from "../slices/pdfDetailsSlice";

export const uploadPdf =
  (
    url: string,
    file: File,
    onUploadProgressHandler: OnUploadProgressHandler,
    acceptCancellationHandler: AcceptCancelationHandler,
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(uploadPdfBegin());
    try {
      await fileUtils.uploadFileToBlob(url, file, onUploadProgressHandler, acceptCancellationHandler, null);
      dispatch(uploadPdfSuccess());
    } catch (error) {
      dispatch(uploadPdfCancel());
      throw error;
    }
  };

export const updatePdfFile = (data: UpdatePdfFileRequest) => async (dispatch: AppDispatch) => {
  dispatch(uploadPdfBegin());
  try {
    await DataService.pdfs.updatePdfFileAsync(data);
    dispatch(uploadPdfSuccess());
  } catch (error) {
    dispatch(uploadPdfCancel());
    throw error;
  }
};

export const uploadPdfThumbnail = (id: number, fileKey: string) => () =>
  DataService.pdfs.updatePdfThumbnailAsync(id, fileKey);

export const getPdf = (id: number) => async (dispatch: Function) => {
  dispatch(loadPdfBegin());
  try {
    const result = await DataService.pdfs.getPdfAsync(id);
    dispatch(loadPdfSuccess(result.data));
    dispatch(setPdfEntityIdAction(id));
  } catch (_) {
    dispatch(loadPdfFailure());
  }
};
