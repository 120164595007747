import { useEffect } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { FormikProps, withFormik } from "formik";
import { connect, ConnectedProps } from "react-redux";

import { ValidatedForm } from "../../../../../components";
import { bindAction, WizardForm } from "../../../../../interfaces";
import { AutosaveProps, validationSchemas, withAutosave } from "../../../../../utils";
import { getValidateFieldProps } from "../../../../../utils/formikUtils";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { MessageContent } from "../types";
import * as messageEntityStateActions from "../state/actions/messageEntityStateActions";
import { saveMessageContent } from "../state/slices/messageDetailsSlice";
import { EndpointsToGetSasLink } from "../../../../../enums";

import styles from "./MessageContentForm.module.scss";

export type Props = FormikProps<MessageContent> & PropsFromRedux & WizardForm<MessageContent> & AutosaveProps;

export const MessageContentForm: React.FC<Props> = (props) => {
  const {
    id,
    values,
    dirty,
    onBlur,
    acceptHandlers,
    submitForm,
    isValid,
    onIsValidChange,
    entityStateActions,
    disabled,
    onIsDirtyChanged,
    isLoading,
    save,
  } = props;
  const validatedFieldProps = getValidateFieldProps(props);

  useEffect(() => {
    acceptHandlers?.({
      onPrevious: () => submitForm(),
      onFinish: () => {
        submitForm();
        save();
        entityStateActions.publishDraftMessageEntity(id);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    onIsValidChange?.(isValid);
  }, [isValid, onIsValidChange]);

  useEffect(() => {
    onIsDirtyChanged?.(dirty);
  }, [dirty, onIsDirtyChanged]);

  return (
    <ValidatedForm
      disablePreventTransitionPrompt={true}
      dirty={dirty}
      formWidthComputer={8}
      isSubmitting={isLoading}
      unsavedChangesPrompt={{
        title: "Exit Without Saving?",
        message: "Are you sure you want to exit without saving this message? All information entered will be lost.",
      }}
    >
      <div className={styles.root}>
        <ValidatedForm.InputField
          {...validatedFieldProps}
          label="Heading"
          value={values.headline}
          propertyName="headline"
          markAsRequired
          placeholder="Enter Message Heading"
          onBlur={onBlur}
          disabled={disabled}
        />
        <ValidatedForm.ImagePreview
          {...validatedFieldProps}
          label="Image Cap"
          editBtnEnabled={false}
          value={values.imageUrl}
          propertyName="imageUrl"
          markAsRequired
          onImageChanged={onBlur}
          endpointToGetSasLink={EndpointsToGetSasLink.Messages(id)}
          disabled={disabled}
          getImageCustomValidator={() => {
            return {
              validator: validationSchemas.image.fields.uploadedTeamsImages,
              propName: "uploadedTeamsImages",
            };
          }}
        />
        <ValidatedForm.TextAreaField
          {...validatedFieldProps}
          label="Body"
          placeholder={"Enter Message Here"}
          value={values.body}
          propertyName={"body"}
          markAsRequired
          onBlur={onBlur}
          disabled={disabled}
        />
        <ValidatedForm.InputField
          {...validatedFieldProps}
          label="Call to Action"
          value={values.callToAction}
          propertyName="callToAction"
          markAsRequired
          placeholder="Enter Call to Action"
          tooltip={{
            info: "The Call to Action (CTA) is a button with a short description of what you'd like the user to do. CTA's are generally 1-3 words (e.g. Register Now).",
            width: 18,
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
        <ValidatedForm.InputField
          {...validatedFieldProps}
          label="Destination URL"
          value={values.destinationUrl}
          propertyName="destinationUrl"
          markAsRequired
          placeholder="Enter Destination URL"
          tooltip={{
            info: "The URL that you'd like to send the user when they click the Call-to-Action (CTA) button.",
            width: 17,
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
      </div>
    </ValidatedForm>
  );
};

const mapState = (state: RootState, ownProps: any) => ({
  messageContent: state.library.messages.details.values.messageContent,
  id: ownProps.entityId || state.library.messages.details.messageEntityStateReducer.entityId,
  isLoading: state.library.messages.details.values.isLoading,
  isLoaded: state.library.messages.details.values.isLoaded,
  isDraft: state.library.messages.details.values.isDraft,
  isCreating: state.library.emails.emailEntityStateReducer.changingEntityState,
});

const mapDispatch = (dispatch: AppDispatch) => ({
  entityStateActions: bindActionCreators(messageEntityStateActions, dispatch),
  saveMessageContent: bindAction(saveMessageContent, dispatch),
});

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapToMessagePayload = (values: MessageContent): MessageContent => {
  return {
    id: values.id,
    headline: values.headline?.trim(),
    body: values.body?.trim(),
    imageUrl: values.imageUrl?.trim(),
    callToAction: values.callToAction?.trim(),
    destinationUrl: values.destinationUrl?.trim(),
  };
};

const MessageContentWithAutosave = withAutosave<Props, MessageContent>({
  getInitValues: (props) => mapToMessagePayload(props.messageContent),
  stateProvider: (values, props) => ({ ...mapToMessagePayload(values), id: props.id }),
  entityUpdater: (props) => props.entityStateActions.updateMessageContent,
  isValid: (props) => Boolean(props.id) && props.isValid && props.isDraft && props.dirty,
})(MessageContentForm);

const MessageContentWithFormik = withFormik({
  mapPropsToValues: (props: PropsFromRedux) => props.messageContent,
  enableReinitialize: true,
  handleSubmit: (content: MessageContent, { props }: { props: any }) => {
    props.saveMessageContent(content);
  },
  validationSchema: validationSchemas.messageContent,
  validateOnMount: true,
})(MessageContentWithAutosave);

export default connector(MessageContentWithFormik);
