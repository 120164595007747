import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Table } from "semantic-ui-react";
import { Button } from "components/buttons/button/Button";
import { bindActionCreators } from "redux";
import { isEmpty } from "lodash";

import { ThreatDefenceLandingPagesOverviewState, ThreatDefenceLandingPagesOverviewOwnProps } from "./types";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import columnOptions from "./columnOptions";
import { OverviewHeader } from "../../../../../components/sectionHeader";
import { SearchInput } from "../../../../../components";
import { PublishedStatus } from "../../../../../components/common/publishedStatus";
import { PublishedStatusTypes, SortingDirection, ViewType } from "../../../../../enums";
import { ItemsView } from "../../../../../views";
import dateTimeUtils from "../../../../../utils/dateTimeUtils";
import { ThreatDefenceLandingPageOverview } from "../types/state";
import LandingPagesNoResults from "../../../../../views/library/landingPages/Overview/ThreatDefenceLandingPagesNoResults";
import * as landingPagesOverviewActions from "../state/actions/ThreatDefenceLandingPagesOverviewActions";
import threatDefenceFetchDataUtils from "../../../../../utils/threatDefenceFetchDataUtils";
import { Title } from "../../../../../components/listViewTemplates";
import LandingPageEllipsisPopupButton from "../../../../../components/landingPage/LandingPageEllipsisPopupButton";
import * as landingPageEntityStateActions from "../state/actions/threatDefenceLandingPageEntityStateActions";
import { LandingPagesFilterForm } from "../../../../../components/filterForms";
import { FiltersMap } from "../../../../../utils/filterUtils";
import { getFilterOptions } from "../state/thunks/landingPagesFiltersThunk";
import { resetAppliedFilter, setAppliedFilter } from "../state/slices/landingPagesFiltersSlice";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { LandingPageLockSuccess } from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import { withRouter, WithRouterProps } from "../../../../../adapters/withRouter/withRouter";

const defaultSortColumn = "datecreated";

export type ThreatDefenceLandingPagesOverviewProps = ThreatDefenceLandingPagesOverviewOwnProps &
  PropsFromRedux &
  WithRouterProps;

export class ThreatDefenceLandingPagesOverview extends Component<
  ThreatDefenceLandingPagesOverviewProps,
  ThreatDefenceLandingPagesOverviewState
> {
  constructor(props: ThreatDefenceLandingPagesOverviewProps) {
    super(props);
    this.state = {
      selectedLandingPageIds: [],
    };
  }

  componentDidMount() {
    RtnEventsEmitter.subscribe(LandingPageLockSuccess, this.goToEditEntity);
  }

  componentWillUnmount() {
    RtnEventsEmitter.unsubscribe(LandingPageLockSuccess, this.goToEditEntity);
  }

  buildTableBody = (landingPage: ThreatDefenceLandingPageOverview) => {
    return (
      <React.Fragment>
        <Table.Cell width={columnOptions[0].width}>
          <Title title={landingPage.name} onTitleClick={() => this.handleEditClick(landingPage.id, true)} clamp={2} />
        </Table.Cell>
        <Table.Cell width={columnOptions[1].width}>
          <PublishedStatus publishedStatus={PublishedStatusTypes.ConvertToPublishedStatusType(!landingPage.isDraft)} />
        </Table.Cell>
        <Table.Cell width={columnOptions[2].width}>{dateTimeUtils.formatDate(landingPage.dateModified)}</Table.Cell>
        <Table.Cell width={columnOptions[3].width}>
          <LandingPageEllipsisPopupButton
            onEditLandingPage={() => this.handleEditClick(landingPage.id, landingPage.isDraft)}
            landingPage={landingPage}
            outlinedEllipsis
            circle
          />
        </Table.Cell>
      </React.Fragment>
    );
  };

  goToEditEntity = (payload: { id: number }) => this.props.navigate(payload.id.toString());

  handleEditClick = (id: number, isDraft: boolean) => {
    if (!isDraft) {
      this.props.landingPageEntityStateActions.fetchDraftThreatDefenceLandingPageEntity(id);
      return;
    }
    this.goToEditEntity({ id });
  };

  onSelectedItemsChanged = (ids: Array<number>) => {
    this.setState({ selectedLandingPageIds: ids });
  };

  getLandingPages = (skip?: number, top?: number, sortingColumnName?: string, sortingDirection?: SortingDirection) => {
    const orderBy = threatDefenceFetchDataUtils.formatOrderParams(
      sortingColumnName || defaultSortColumn,
      sortingDirection || SortingDirection.Descending,
    );
    this.props.landingPagesOverviewActions.getThreatDefenceLandingPagesAsync(
      top ?? 10,
      skip ?? 0,
      orderBy,
      this.props.appliedFilter,
    );
  };

  renderCreateLandingPageBtn = (): React.ReactElement | null => {
    if (this.state.selectedLandingPageIds.length === 0) {
      return (
        <Button
          primary
          className="create-landingPage create-button"
          onClick={() => {
            this.props.navigate("create");
          }}
        >
          Create Landing Page
        </Button>
      );
    }

    return null;
  };

  // This component is being cloned and receives needed props inside FilterPanel component.
  // @ts-ignore
  renderFilterForm = (): React.ReactElement => <LandingPagesFilterForm />;

  render() {
    const { landingPages, totalCount, isLoading, appliedFilter, filterOptions, isFilterLoading } = this.props;
    return (
      <section className="nested-content landingPages">
        <OverviewHeader title="Landing Pages">{this.renderCreateLandingPageBtn()}</OverviewHeader>
        <ItemsView
          items={landingPages}
          viewType={ViewType.LIST}
          getData={this.getLandingPages}
          noResultsContent={
            <LandingPagesNoResults
              filtered={!isEmpty(appliedFilter)}
              createButton={this.renderCreateLandingPageBtn()}
            />
          }
          isLoading={isLoading}
          isAllDataLoaded={false}
          selectedIds={this.state.selectedLandingPageIds}
          className="landingPages-items-view"
          buildTableBody={this.buildTableBody}
          columnOptions={columnOptions}
          itemsInlineCount={totalCount}
          onSelectedListItemsChanged={this.onSelectedItemsChanged}
          sortingColumnName={defaultSortColumn}
          sortingDirection={SortingDirection.Descending}
          renderSearch={() => <SearchInput placeholder="Search for Landing Pages..." />}
          renderFilterForm={this.renderFilterForm}
          appliedFilter={appliedFilter}
          getFilterOptions={this.props.getFilterOptions}
          filterOptionsLoading={isFilterLoading}
          filterOptions={filterOptions}
          applyFilter={this.props.applyFilter}
          resetFilter={this.props.resetFilter}
        />
      </section>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => {
  const overview = state.library.threatDefenceLandingPages.threatDefenceLandingPagesOverview;
  const entityState = state.library.threatDefenceLandingPages.threatDefenceLandingPageEntityStateReducer;
  return {
    landingPages: overview.landingPages,
    isLoading: overview.isLoading || entityState.changingEntityState,
    totalCount: overview.totalCount,
    filterOptions: state.library.threatDefenceLandingPages.overviewFilters.filterOptions,
    appliedFilter: state.library.threatDefenceLandingPages.overviewFilters.appliedFilter,
    isFilterLoading: state.library.threatDefenceLandingPages.overviewFilters.isLoading,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  landingPagesOverviewActions: bindActionCreators(landingPagesOverviewActions, dispatch),
  landingPageEntityStateActions: bindActionCreators(landingPageEntityStateActions, dispatch),
  getFilterOptions: () => dispatch(getFilterOptions()),
  applyFilter: (filters: FiltersMap) => dispatch(setAppliedFilter(filters)),
  resetFilter: () => dispatch(resetAppliedFilter()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(ThreatDefenceLandingPagesOverview));
