import * as eventTypes from "../events/accounts/accountsEvents";
import {
  updateSucceededTaskOperation,
  updateFailedTaskOperation,
  updateSucceededTaskDistributedOperation,
  updateFailedTaskDistributedOperation,
  updateProgressTaskDistributedOperation,
} from "../../../../BackgroundTasks/state/backgroundTasksActions";
import { IMapping } from "./types";
import AlertTypes from "../../../../../enums/alertTypes";
import { Dispatcher } from "../../../../../interfaces";
import { createDispatcher } from "../dispatcher";
import { createClientCompleted, updateClientStatusCompleted } from "../../../../Accounts/Api/state/slices/apiKeysSlice";

interface TrackableMessage {
  executionId: string;
}

export const accountsMapping = (mapping: IMapping, dispatcher: Dispatcher) => {
  const { dispatchTaskOperationSuccess, dispatchTaskOperationFailure, dispatchAlert, dispatch } =
    createDispatcher(dispatcher);

  const operationSucceededHandler = dispatchTaskOperationSuccess<TrackableMessage>((payload) => ({
    messageId: payload.executionId,
  }));
  const operationFailedHandler = dispatchTaskOperationFailure<TrackableMessage>((payload) => ({
    messageId: payload.executionId,
  }));

  const dispatchByType = (type: AlertTypes, messageId: string) => {
    const action = type === AlertTypes.success ? updateSucceededTaskOperation : updateFailedTaskOperation;
    dispatcher(action(messageId));
  };

  mapping[eventTypes.RoleCreated] = (payload) => {
    dispatchByType(payload.type, payload.executionId);
  };

  [
    eventTypes.AccountUpdatedSuccess,
    eventTypes.AddContactsSuccess,
    eventTypes.UpdateContactsSuccess,
    eventTypes.UpdateAddOnsSuccess,
    eventTypes.AddPacksToAccountSuccess,
    eventTypes.AddPacksLicensesToAccountSuccess,
    eventTypes.UpdatePackAccountLicenseSuccess,
    eventTypes.DeleteAccountOperationSuccess,
  ].forEach((eventName) => (mapping[eventName] = operationSucceededHandler));

  [
    eventTypes.UpdateContactsFailure,
    eventTypes.AddContactsFailure,
    eventTypes.AccountUpdatedFailure,
    eventTypes.UpdateAddOnsFailure,
    eventTypes.AddPacksToAccountFailure,
    eventTypes.AddPacksLicensesToAccountFailure,
    eventTypes.UpdatePackAccountLicenseFailure,
    eventTypes.DeleteAccountOperationFailure,
  ].forEach((eventName) => (mapping[eventName] = operationFailedHandler));

  mapping[eventTypes.IntegrationCreateSuccess] = dispatchAlert(
    AlertTypes.success,
    "Integration has been created successfully!",
  );

  mapping[eventTypes.IntegrationCreateFailure] = dispatchAlert(AlertTypes.error, "Integration creation failed!");

  mapping[eventTypes.WebexIntegrationCreateFailure] = dispatchAlert(
    AlertTypes.error,
    "Webex integration creation failed!",
  );
  mapping[eventTypes.WebexIntegrationCreateSuccess] = dispatchAlert(
    AlertTypes.success,
    "Webex integration has been created successfully!",
  );

  mapping[eventTypes.DeleteIntegrationsSuccess] = dispatchAlert(
    AlertTypes.success,
    "Integration has been deleted successfully!",
  );

  mapping[eventTypes.DeleteIntegrationsFailure] = dispatchAlert(AlertTypes.error, "Integration deletion failed!");

  mapping[eventTypes.UpdateMsGraphIntegrationSettingsSuccess] = dispatchAlert(
    AlertTypes.success,
    "Changes has been saved to the Microsoft Graph integration!",
  );

  mapping[eventTypes.UpdateMsGraphIntegrationSettingsFailure] = dispatchAlert(
    AlertTypes.error,
    "Changes has not been saved to the Microsoft Graph integration",
  );

  mapping[eventTypes.UpdateMsGraphIsActiveStateSuccess] = dispatchAlert(
    AlertTypes.success,
    "Activity change been saved to the Microsoft Graph integration",
  );

  mapping[eventTypes.UpdateMsGraphIsActiveStateFailure] = dispatchAlert(
    AlertTypes.error,
    "Activity change has not been saved to the Microsoft Graph integration",
  );

  mapping[eventTypes.GoogleIntegrationCreateSuccess] = dispatchAlert(
    AlertTypes.success,
    "Google integration has been created successfully!",
  );

  mapping[eventTypes.GoogleIntegrationCreateFailure] = dispatchAlert(
    AlertTypes.error,
    "Google integration creation failed!",
  );

  mapping[eventTypes.UpdateGoogleSuccess] = dispatchAlert(
    AlertTypes.success,
    "Changes has been saved to the Google integration!",
  );

  mapping[eventTypes.UpdateGoogleFailure] = dispatchAlert(AlertTypes.error, "Google integration update failed!");

  mapping[eventTypes.UpdateVivaSuccess] = dispatchAlert(
    AlertTypes.success,
    "Changes has been saved to the Viva integration!",
  );

  mapping[eventTypes.UpdateVivaFailure] = dispatchAlert(AlertTypes.error, "Viva integration update failed!");

  mapping[eventTypes.UpdateIntegrationsIsActiveSuccess] = dispatchAlert(
    AlertTypes.success,
    "Changes has been saved to selected integrations!",
  );

  mapping[eventTypes.UpdateIntegrationsIsActiveFailure] = dispatchAlert(
    AlertTypes.error,
    "Changes has not been saved to selected integrations!",
  );

  mapping[eventTypes.VivaIntegrationCreateSuccess] = dispatchAlert(
    AlertTypes.success,
    "Viva integration has been created successfully!",
  );

  mapping[eventTypes.VivaIntegrationCreateFailure] = dispatchAlert(
    AlertTypes.error,
    "Viva integration creation failed!",
  );

  mapping[eventTypes.SfIntegrationCreateSuccess] = dispatchAlert(
    AlertTypes.success,
    "SuccessFactors integration has been created successfully!",
  );

  mapping[eventTypes.SfIntegrationCreateFailure] = dispatchAlert(
    AlertTypes.error,
    "SuccessFactors integration creation failed!",
  );

  mapping[eventTypes.CreateApiClientDistributedSuccess] = dispatch(createClientCompleted);

  mapping[eventTypes.CreateApiClientDistributedFailure] = dispatchAlert(
    AlertTypes.error,
    "Failed to create API client!",
  );

  mapping[eventTypes.EditApiClientDistributedSuccess] = dispatch(updateClientStatusCompleted);

  mapping[eventTypes.EditApiClientDistributedFailure] = dispatchAlert(AlertTypes.error, "Failed to update API client!");

  mapping[eventTypes.UpdateWebexSuccess] = dispatchAlert(
    AlertTypes.success,
    "Changes has been saved to the Webex integration!",
  );

  mapping[eventTypes.UpdateWebexFailure] = dispatchAlert(AlertTypes.error, "Webex integration update failed!");

  mapping[eventTypes.RemovePackFromAccountSuccess] = dispatchAlert(AlertTypes.success, "Pack deleted successfully.");

  mapping[eventTypes.RemovePackFromAccountFailure] = dispatchAlert(AlertTypes.error, "Pack deleting failed.");

  [
    eventTypes.AddAllPacksToOwnAccountSuccess,
    eventTypes.CreateIdentityProviderSuccess,
    eventTypes.DeleteIdentityProvidersSuccess,
    eventTypes.EditIdentityProviderInfoSuccess,
    eventTypes.EditIdentityProviderSamlSettingsSuccess,
    eventTypes.EditIdentityProviderMappingsSuccess,
    eventTypes.AccountCreatedSuccess,
    eventTypes.PackArchiveSuccess,
    eventTypes.UnarchiveAccountPackSuccess,
  ].forEach(
    (eventName) =>
      (mapping[eventName] = (payload) => dispatcher(updateSucceededTaskDistributedOperation(payload.operationId))),
  );

  [
    eventTypes.AddAllPacksToOwnAccountFailure,
    eventTypes.DeleteIdentityProvidersFailure,
    eventTypes.CreateIdentityProviderFailure,
    eventTypes.EditIdentityProviderInfoFailure,
    eventTypes.EditIdentityProviderSamlSettingsFailure,
    eventTypes.EditIdentityProviderMappingsFailure,
    eventTypes.AccountCreatedFailure,
    eventTypes.PackArchiveFailure,
    eventTypes.UnarchiveAccountPackFailure,
  ].forEach(
    (eventName) =>
      (mapping[eventName] = (payload) => dispatcher(updateFailedTaskDistributedOperation(payload.operationId))),
  );

  mapping[eventTypes.AddPacksToOwnAccountSuccess] = (payload) =>
    dispatcher(updateProgressTaskDistributedOperation(payload.operationId, payload.stepId));
};
