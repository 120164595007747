export enum KEY_CODES {
  BACKSPACE = 8,
  SPACE = 32,
  DELETE = 46,
  A = 65,
}

export enum KEY_TEXT {
  BACKSPACE = "Backspace",
  DELETE = "Delete",
}
