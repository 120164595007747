import { SortingDirection } from "../enums";

const utils = {
  formatOrderParams: (sortingColumnName?: string, sortingDirection?: SortingDirection) => {
    if (!sortingColumnName || !sortingDirection) {
      return "";
    }
    const name = sortingColumnName.toLowerCase();
    let orderParams;

    switch (name) {
      case "title":
        orderParams = `title ${sortingDirection}`;
        break;
      case "users":
        orderParams = `usersCount ${sortingDirection}`;
        break;
      case "status":
        orderParams = `isDraft ${sortingDirection}`;
        break;
      case "added":
        orderParams = `dateCreated ${sortingDirection}`;
        break;
      case "modified":
        orderParams = `dateModified ${sortingDirection}`;
        break;
      case "objective":
        orderParams = `goalObjectiveId ${sortingDirection}`;
        break;
      default:
        orderParams = `${name} ${sortingDirection}`;
    }

    return orderParams;
  },
};

export default utils;
