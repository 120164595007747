import { PacksContextItemModel } from "../../../../../../interfaces";
import { FC, useCallback } from "react";
import cn from "classnames";
import { Thumbnail } from "./Thumbnail";
import { PacksDropdown } from "./PacksDropdown";
import styles from "./assetList.module.scss";

export interface Props {
  items: PacksContextItemModel[];
  setPack(assetId: string, type: string, packId?: number | string): void;
}

export const CommonAssetList: FC<Props> = ({ items, setPack }) => {
  const handleOnChange = useCallback(
    (itemId: string, type: string) => {
      return (selectedPack: string) => setPack(itemId, type, parseInt(selectedPack));
    },
    [setPack],
  );

  return (
    <>
      {items.map(({ id, title, type, packs, packId, durationInSeconds, thumbnailUrl }) => (
        <div key={id} className={cn("pack-context-item", styles["asset-item"])} data-testid="context-items-row">
          <Thumbnail label={title} type={type} duration={durationInSeconds} url={thumbnailUrl} />
          <PacksDropdown options={packs} value={packId} onChange={handleOnChange(id, type)} />
        </div>
      ))}
    </>
  );
};
