import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { withEntityState } from "../../../../Application/reducers/hoc/withEntityState";

const namespace = ReducerNamespaceTypes.Licensing;
const entityPrefix = ReducerEntityPrefixTypes.Packs;

const packEntityStateReducer = withEntityState(namespace, entityPrefix);

export default packEntityStateReducer;
