import { combineReducers, Reducer } from "redux";
import { PayloadAction } from "@reduxjs/toolkit";

import * as actionTypes from "./addGroupActionTypes";
import { createReducer } from "../../../../../utils/reduxUtils";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import { withFetchingChildEntityListItems } from "../../../../Application/reducers/hoc/withFetchingItems";
import withFilteringItems from "../../../../Application/reducers/hoc/withFilteringItems";
import withSearch from "../../../../Application/reducers/hoc/withSearch";
import {
  ICreatedGroupState,
  IEnrollmentUsersFilterOptionsState,
  IGroupOwnerOptions,
  IManualUsersItemModel,
  IOwnerItemModel,
  IOwnerOptions,
} from "../../../types";
import { ICollection, ItemsViewList } from "../../../../Application/globaltypes/ItemsView";

const namespace = ReducerNamespaceTypes.People;
const entityPrefix = ReducerEntityPrefixTypes.AddGroup;

interface CreateGroup {
  createdGroup: ICreatedGroupState;
  isLoadingGroupInfo: boolean;
  isContentLoading: boolean;
  error: any;
}

interface AddGroup {
  enrollmentUsersFilterOptions: IEnrollmentUsersFilterOptionsState;
  manualUsersFilterOptions: ItemsViewList<IManualUsersItemModel>;
  createGroup: CreateGroup;
  ownerOptions: ICollection<IOwnerOptions>;
  authenticatedUser: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    imageUrl: string;
  };
  ownersList: IGroupOwnerOptions;
}

const initialState: CreateGroup = {
  isLoadingGroupInfo: false,
  isContentLoading: false,
  error: null,
  createdGroup: {
    name: "",
    description: "",
    isAware: true,
    isVisibleToAll: true,
    isOpen: true,
    isWithAutoEnroll: false,
    ownersIds: [],
    enrolmentRules: [],
    imageUrl: "",
    groupId: -1,
    roleId: -1,
  },
};

const startGroupInfoLoading = (state: AddGroup) => {
  return {
    ...state,
    isLoadingGroupInfo: true,
    error: null,
  };
};

const groupInfoFailed = (state: AddGroup, action: PayloadAction<{ error: string }>) => {
  return {
    ...state,
    isLoadingGroupInfo: false,
    error: action.payload.error,
  };
};

const createGroupHandler = () => {
  const { CREATE_GROUP_BEGIN, CREATE_GROUP_FAILURE, CREATE_GROUP_SUCCESS, UPDATE_GROUP_SUCCESS, RESET_GROUP_STATE } =
    actionTypes;

  const updateGroupSucceeded = (state: AddGroup, action: PayloadAction) => {
    return { ...initialState, createdGroup: action.payload };
  };

  const createGroupSucceeded = (state: AddGroup, action: PayloadAction) => {
    return { ...initialState, createdGroup: action.payload };
  };

  return {
    [CREATE_GROUP_BEGIN]: startGroupInfoLoading,
    [CREATE_GROUP_FAILURE]: groupInfoFailed,
    [CREATE_GROUP_SUCCESS]: createGroupSucceeded,
    [UPDATE_GROUP_SUCCESS]: updateGroupSucceeded,
    [RESET_GROUP_STATE]: () => initialState,
  };
};

const authenticatedUserHandler = () => {
  const { PEOPLE_FETCH_AUTHENTICATED_USER } = actionTypes;

  const setAuthenticatedUser = (state: AddGroup, { payload }: PayloadAction<{ user: any }>) => payload.user;

  return {
    [PEOPLE_FETCH_AUTHENTICATED_USER]: setAuthenticatedUser,
  };
};

const buildChildEntityReducer = <T extends object = {}>(childEntityPrefix: string) =>
  withFetchingChildEntityListItems<T>(namespace, entityPrefix, childEntityPrefix);

const buildFilteringReducer = (childEntityPrefix: string, reducer: Reducer) =>
  withFilteringItems(namespace, `${entityPrefix}__${childEntityPrefix}`, reducer);

const enrollmentUsersList = buildChildEntityReducer(actionTypes.EnrollmentUsers);
const manualSelectionUsersList = buildChildEntityReducer(actionTypes.ManualSelectionUsers);
const ownersSelectionList = buildChildEntityReducer<IOwnerItemModel>(actionTypes.Owners);

const createGroupReducer = createReducer(initialState, [createGroupHandler]);

const delimiter = "__";

export const addGroup = combineReducers({
  enrollmentUsersFilterOptions: buildFilteringReducer(actionTypes.EnrollmentUsers, enrollmentUsersList),
  manualUsersFilterOptions: manualSelectionUsersList,
  ownersList: ownersSelectionList,
  createGroup: createGroupReducer,
  membersSearch: withSearch(namespace, entityPrefix.concat(delimiter, actionTypes.ManualSelectionUsers)),
  ownersSearch: withSearch(namespace, entityPrefix.concat(delimiter, actionTypes.Owners)),
  authenticatedUser: createReducer({}, [authenticatedUserHandler]),
});

export type AddGroupState = ReturnType<typeof addGroup>;
