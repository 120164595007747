import { Dispatch } from "@reduxjs/toolkit";
import packsDataService from "../../../../Licensing/Packs/services/packsDataService";
import { req, got, err } from "../slices/editAccountPackLicenseSlice";

export const fetchAccountPackLicense = (packId: number, accountId: number) => async (dispatch: Dispatch) => {
  dispatch(req());
  try {
    const { data } = await packsDataService.getAccountPackLicense(packId, accountId);
    dispatch(got(data));
  } catch (e) {
    dispatch(err(e as Error));
  }
};
