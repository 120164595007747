interface Item {
  text?: string;
  value: number | string;
}

const removeInvalidItems = (validator: any, items: Item[], selectedValues: any[], setItems: (value: any) => void) => {
  let notSelectedValues = items.filter((item) => selectedValues.indexOf(item.value) === -1);
  if (notSelectedValues.length === 0) {
    return;
  }

  let itemsToRemove: Array<Item> = [];
  notSelectedValues.forEach((item: Item) => {
    try {
      validator.validateSync({ selected: [item.value] });
    } catch (error) {
      itemsToRemove.push(item);
    }
  });

  if (itemsToRemove.length > 0) {
    let result = items.filter((item) => itemsToRemove.indexOf(item) === -1);
    setItems(result);
  }
};

const utils = {
  validateLabels: (validator: any, items: Item[], selectedValues: any[], setItems: (value: any) => void) => {
    if (!validator) {
      return;
    }
    removeInvalidItems(validator, items || [], selectedValues, setItems);
  },

  getItemsToAdd: (value: string, items: Item[], valueComparer: (first: any, second: any) => boolean) => {
    const nullProofItems = items || [];
    const trimedValue = value.trim();
    const needToAdd = trimedValue && nullProofItems.findIndex((item) => valueComparer(item.value, trimedValue)) === -1;

    return needToAdd ? [{ text: trimedValue, value: trimedValue }, ...nullProofItems] : null;
  },
};

export default utils;
