import { FC } from "react";
import { Link } from "./Link";

import dateTimeUtils from "../../../../utils/dateTimeUtils";

interface Props {
  packName: string;
  trialDurationInDays: number;
  hasPurchasingPower: boolean;
}

export const TrialAgreementNotice: FC<Props> = (props) => {
  const { packName, trialDurationInDays, hasPurchasingPower } = props;

  return (
    <span data-testid="license-agreement-notice">
      You are evaluating the <b>{packName}</b> pack. This trial is governed by the&nbsp;
      <Link to="/accounts/home/legal" text="agreement you have on file" />
      &nbsp;with BrainStorm. This trial is set to {hasPurchasingPower ? " renew " : " expire "} automatically on&nbsp;
      <b>{dateTimeUtils.dateFromNow(trialDurationInDays)}</b>.
      {hasPurchasingPower ? "" : " Contact your BrainStorm representative to purchase this Pack for continued access."}
    </span>
  );
};
