import { combineReducers } from "redux";
import { threatDefenceEmailTemplatesOverviewReducer } from "./threatDefenceEmailTemplatesOverviewReducer";
import { threatDefenceEmailTemplateDetailsReducer } from "./threatDefenceEmailTemplateDetailsReducer";
import threatDefenceEmailTemplateEntityStateReducer from "./threatDefenceEmailTemplateEntityStateReducer";
import overviewFilters from "../slices/emailTemplateFiltersSlice";
import emailTemplateDeleteModalSlice from "../slices/emailTemplateDeleteModalSlice";

export default combineReducers({
  threatDefenceEmailTemplatesOverviewReducer,
  threatDefenceEmailTemplateDetailsReducer,
  threatDefenceEmailTemplateEntityStateReducer,
  overviewFilters,
  emailTemplateDeleteModal: emailTemplateDeleteModalSlice,
});
