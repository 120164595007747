import { buildRootEntityActionType, buildFilterOptionsAction } from "../../../../Application/actions/actionsBuilder";
import * as filteringItemsActionTypes from "../../../../Application/actions/filteringItems/filteringItemsActionTypes";

import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";

export const PEOPLE_USERS_OVERVIEW_SET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Users_Overview,
  filteringItemsActionTypes.SET_ITEMS_FILTER,
);

export const PEOPLE_USERS_OVERVIEW_RESET_ITEMS_FILTER = buildRootEntityActionType(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Users_Overview,
  filteringItemsActionTypes.RESET_ITEMS_FILTER,
);

export const PEOPLE_USERS_OVERVIEW_FILTER_OPTIONS = buildFilterOptionsAction(
  ReducerNamespaceTypes.People,
  ReducerEntityPrefixTypes.Users_Overview,
);
