import { combineReducers } from "redux";
import { editUserInfoReducer } from "./editUserInfoReducer";
import { editUserRolesReducer } from "./editUserRolesReducer";
import { editUserGroupsReducer } from "./editUserGroupsReducer";
import userPacksGridSlice from "../../../../Licensing/Packs/state/slices/userPacksGridSlice";

export const editUserReducer = combineReducers({
  userInfo: editUserInfoReducer,
  userRoles: editUserRolesReducer,
  userGroups: editUserGroupsReducer,
  userPacks: combineReducers({
    packsGrid: userPacksGridSlice,
  }),
});

export type EditUserState = ReturnType<typeof editUserReducer>;
