import { AddPeopleToContentTypes, TemplateTypes } from "../../../../enums";

export const toTemplateType = (contentType: AddPeopleToContentTypes): TemplateTypes =>
  ({
    [AddPeopleToContentTypes.Events]: TemplateTypes.EventAssigned,
    [AddPeopleToContentTypes.Flows]: TemplateTypes.AddedToFlowV2,
    [AddPeopleToContentTypes.Assessments]: TemplateTypes.AddedToAssessment,
    [AddPeopleToContentTypes.Surveys]: TemplateTypes.AddedToSurvey,
    [AddPeopleToContentTypes.Videos]: TemplateTypes.AddedToVideo,
    [AddPeopleToContentTypes.PDFs]: TemplateTypes.AddedToPdf,
  }[contentType]);
