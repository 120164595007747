import { SortingDirection } from "enums";
import { AppThunk } from "features/Application/globaltypes/redux";
import DataService from "features/Application/services/dataServices/typedDataService";
import { fetchBegin, fetchSuccess } from "../TemplateAvailableCustomersSlice";

export const getAvailableCustomers =
  (skip: number, top: number, sortBy: string, sortOrder: SortingDirection, templateId?: number ): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBegin());

      const result = await DataService.groupTemplates.getAvailableCustomers({
        top,
        skip,
        sortBy,
        sortOrder,
        term: getState().people.groupTemplate.availableCustomers.search,
        templateId: templateId,
      });

      dispatch(
        fetchSuccess({
          items: result.data.items,
          totalCount: result.data.count,
        }),
      );
    } catch {}
  };

export const saveCustomers =
  (templateId: number): AppThunk =>
  async (_, getState) => {
    const customers = getState().people.groupTemplate.availableCustomers.customersToAdd;
    if (customers.length === 0) return;
    try {
      await DataService.groupTemplates.saveCustomers(templateId, customers);
    } catch {}
  };
