export const FETCH_ITEMS_BEGIN = "FETCH_LIST_ITEMS_BEGIN";
export const FETCH_ITEMS_SUCCESS = "FETCH_LIST_ITEMS_SUCCESS";
export const FETCH_ITEMS_FAILURE = "FETCH_LIST_ITEMS_FAILURE";
export const RESET_ITEMS = "RESET_LIST_ITEMS";
export const UPDATE_ITEMS = "UPDATE_LIST_ITEMS";

export const FETCH_GRID_ITEMS_BEGIN = "FETCH_GRID_ITEMS_BEGIN";
export const FETCH_GRID_ITEMS_SUCCESS = "FETCH_GRID_ITEMS_SUCCESS";
export const FETCH_GRID_ITEMS_FAILURE = "FETCH_GRID_ITEMS_FAILURE";
export const RESET_GRID_ITEMS = "RESET_GRID_ITEMS";
