import ReducerEntityPrefixTypes from "../../../../../enums/reducer/reducerEntityPrefixTypes";
import ReducerNamespaceTypes from "../../../../../enums/reducer/reducerNamespaceTypes";
import { GenericFiltersMap } from "../../../../../utils/filterUtils";
import { createFiltersSlice, FiltersState } from "../../../../Application/slices/createFiltersSlice";
import { VideoFiltersEnum } from "../../types/models";

export interface VideoFiltersState extends FiltersState<VideoFiltersEnum> {
  search?: string;
}

export const initialState: VideoFiltersState = {
  filterOptions: {} as GenericFiltersMap<VideoFiltersEnum>,
  appliedFilter: {} as GenericFiltersMap<VideoFiltersEnum>,
  isLoading: false,
};

const videoFiltersSlice = createFiltersSlice({
  namePayload: {
    namespace: ReducerNamespaceTypes.Library,
    entity: ReducerEntityPrefixTypes.Videos,
    name: "overviewFilters",
  },
  initialState: initialState,
  reducers: {},
});

export const {
  setAppliedFilter,
  resetAppliedFilter,
  setIsLoading,
  setError,
  setFilterOptions,
  resetSearch,
  setSearch,
} = videoFiltersSlice.actions;

export default videoFiltersSlice.reducer;
