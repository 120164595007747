import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../Application/globaltypes/redux";
import { SignatureAlgorithm } from "../../types";

export interface SignatureAlgorithmsState {
  items: SignatureAlgorithm[];
  isLoading: boolean;
  error: string | undefined;
}

const initialState: SignatureAlgorithmsState = {
  items: [],
  isLoading: false,
  error: undefined,
};

const signatureAlgorithmsSlice = createSlice({
  name: "signatureAlgorithms",
  initialState: initialState,
  reducers: {
    req(state: SignatureAlgorithmsState) {
      return {
        ...state,
        isLoading: true,
      };
    },
    got(state: SignatureAlgorithmsState, action: PayloadAction<SignatureAlgorithm[]>) {
      return {
        ...state,
        isLoading: false,
        items: action.payload,
      };
    },
    err(state: SignatureAlgorithmsState, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const { req, got, err } = signatureAlgorithmsSlice.actions;

export const signatureAlgorithmsSelector = (state: RootState) =>
  state.accounts.identityProviders.signatureAlgorithms.items;
export const signatureAlgorithmsLoadingSelector = (state: RootState) =>
  state.accounts.identityProviders.signatureAlgorithms.isLoading;

export default signatureAlgorithmsSlice.reducer;
export type signatureAlgorithmsStateSliceType = ReturnType<typeof signatureAlgorithmsSlice.reducer>;
