import { connect } from "react-redux";

import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import { bindAction } from "../../../../../interfaces/redux";
import { getMapTagsSelector } from "../../../../Application/selectors/tagSelectors";
import { addTagsToVideos, fetchVideoTags } from "../../state/thunks/videoBaseThunk";
import { videosStateSelector } from "../../state/selectors";
import TagsManage from "../../../Common/TagsManage/TagsManage";

const getTags = (state: RootState) => videosStateSelector(state).base.tagsBase.tags;

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  tags: getMapTagsSelector(getTags)(state),
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTags: bindAction(fetchVideoTags, dispatch),
  onConfirmAction: bindAction(addTagsToVideos, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsManage);
