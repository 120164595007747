import { FormikProps } from "formik";
import React, { Component } from "react";
import { ValidatedForm } from "../../../../../../components";
import { CampaignFormProps } from "../types";
import {
  ThreatDefenceCampaignOption,
  ThreatDefenceCampaignOptionsState,
  ThreatDefenceCampaignView,
} from "../../types/state";

export type CampaignFormPropsAll = CampaignFormProps &
  FormikProps<ThreatDefenceCampaignView> &
  ThreatDefenceCampaignOptionsState;

export default class CampaignForm extends Component<CampaignFormPropsAll> {
  render() {
    const { dirty, isLoading, disablePreventTransitionPrompt } = this.props;
    return (
      <ValidatedForm
        dirty={dirty}
        unsavedChangesPrompt={{
          title: "Exit Without Saving?",
          message: "Are you sure you want to exit without saving this campaign? All information entered will be lost.",
        }}
        isLoading={isLoading}
        isSubmitting={isLoading}
        formWidthComputer={6}
        disablePreventTransitionPrompt={disablePreventTransitionPrompt}
      >
        {this.renderFields()}
      </ValidatedForm>
    );
  }

  renderFields = (): any => {
    const { values, onBlur, onIsValidChange, types, senderProfiles, areOptionsLoading, disabled } = this.props;
    const validatedFieldProps = this.getValidateFieldProps();

    const dropdownSelector = ({ id, name }: ThreatDefenceCampaignOption) => ({
      key: id,
      value: id,
      text: name,
    });

    const campaignTypesOptions = types.map(dropdownSelector);
    const senderProfileOptions = senderProfiles.map(dropdownSelector);

    return (
      <div>
        <ValidatedForm.InputField
          label="Title"
          value={values.name}
          propertyName="name"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          disabled={disabled}
          onBlur={onBlur}
          {...validatedFieldProps}
        />
        <ValidatedForm.DropdownField
          label="Campaign Type"
          value={values.campaignTypeId}
          propertyName="campaignTypeId"
          options={campaignTypesOptions}
          isLoading={areOptionsLoading}
          markAsRequired
          disabled={disabled}
          onBlur={onBlur}
          {...validatedFieldProps}
        />
        <ValidatedForm.DropdownField
          label="Sender Profile"
          value={senderProfiles[0]?.id}
          propertyName="senderProfile"
          options={senderProfileOptions}
          isLoading={areOptionsLoading}
          disabled
          onBlur={onBlur}
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Sender Name"
          value={values.senderName}
          propertyName="senderName"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          disabled={disabled}
          onBlur={onBlur}
          {...validatedFieldProps}
        />
        <ValidatedForm.InputField
          label="Sender Email"
          value={values.senderEmail}
          propertyName="senderEmail"
          markAsRequired
          onIsFormValidChange={onIsValidChange}
          disabled={disabled}
          onBlur={onBlur}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Software Application"
          propertyName="softwareApplications"
          className="software-applications"
          value={values.softwareApplications.selected}
          options={values.softwareApplications.items}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
        <ValidatedForm.MultiSelectWithAdditionField
          label="Labels"
          propertyName="labels"
          value={values.labels.selected}
          options={values.labels.items}
          onBlur={onBlur}
          disabled={disabled}
          {...validatedFieldProps}
        />
      </div>
    );
  };

  getValidateFieldProps = (): any => {
    const {
      errors,
      touched,
      isValid,
      isValidating,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    } = this.props;

    return {
      errors,
      touched,
      isFormValid: isValid && !isValidating,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      dirty,
      resetForm,
    };
  };
}
