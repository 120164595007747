const prefix = "notification";

export const AccountCreatedSuccess = `${prefix}:CreateAccountSuccess`;
export const AccountCreatedFailure = `${prefix}:CreateAccountFailure`;
export const RoleCreated = `${prefix}:RoleCreated`;
export const AccountUpdatedFailure = `${prefix}:AccountUpdatedFailure`;
export const AccountUpdatedSuccess = `${prefix}:AccountUpdatedSuccess`;
export const AddContactsSuccess = `${prefix}:AddContactsSuccess`;
export const AddContactsFailure = `${prefix}:AddContactsFailure`;
export const UpdateContactsSuccess = `${prefix}:UpdateContactsSuccess`;
export const UpdateContactsFailure = `${prefix}:UpdateContactsFailure`;
export const UpdateAddOnsSuccess = `${prefix}:UpdateAddOnsSuccess`;
export const UpdateAddOnsFailure = `${prefix}:UpdateAddOnsFailure`;
export const AddPacksToAccountSuccess = `${prefix}:AddPacksToAccountSuccess`;
export const AddPacksToAccountFailure = `${prefix}:AddPacksToAccountFailure`;
export const RemovePackFromAccountSuccess = `${prefix}:RemovePackFromAccountSuccess`;
export const RemovePackFromAccountFailure = `${prefix}:RemovePackFromAccountFailure`;
export const IntegrationCreateSuccess = `${prefix}:CreateMsGraphIntegrationSettingsCommandSuccess`;
export const IntegrationCreateFailure = `${prefix}:CreateMsGraphIntegrationSettingsCommandFailure`;
export const DeleteIntegrationsSuccess = `${prefix}:DeleteIntegrationsSuccess`;
export const DeleteIntegrationsFailure = `${prefix}:DeleteIntegrationsFailure`;
export const CreateIdentityProviderSuccess = `${prefix}:CreateIdentityProviderDistributedSuccess`;
export const CreateIdentityProviderFailure = `${prefix}:CreateIdentityProviderDistributedFailure`;
export const DeleteIdentityProvidersSuccess = `${prefix}:DeleteIdentityProviderDistributedSuccess`;
export const DeleteIdentityProvidersFailure = `${prefix}:DeleteIdentityProviderDistributedFailure`;
export const UpdateMsGraphIntegrationSettingsSuccess = `${prefix}:UpdateMsGraphIntegrationSettingsSuccess`;
export const UpdateMsGraphIntegrationSettingsFailure = `${prefix}:UpdateMsGraphIntegrationSettingsFailure`;
export const EditIdentityProviderInfoSuccess = `${prefix}:EditIdentityProviderDistributedSuccess`;
export const EditIdentityProviderInfoFailure = `${prefix}:EditIdentityProviderDistributedFailure`;
export const EditIdentityProviderSamlSettingsSuccess = `${prefix}:EditSamlIdentityProviderDistributedSuccess`;
export const EditIdentityProviderSamlSettingsFailure = `${prefix}:EditSamlIdentityProviderDistributedFailure`;
export const EditIdentityProviderMappingsSuccess = `${prefix}:EditMappingsIdentityProviderDistributedSuccess`;
export const EditIdentityProviderMappingsFailure = `${prefix}:EditMappingsIdentityProviderDistributedFailure`;
export const UpdateMsGraphIsActiveStateSuccess = `${prefix}:UpdateMsGraphIsActiveStateSuccess`;
export const UpdateMsGraphIsActiveStateFailure = `${prefix}:UpdateMsGraphIsActiveStateFailure`;
export const GoogleIntegrationCreateSuccess = `${prefix}:CreateGoogleIntegrationSettingsSuccess`;
export const GoogleIntegrationCreateFailure = `${prefix}:CreateGoogleIntegrationSettingsFailure`;
export const UpdateGoogleSuccess = `${prefix}:UpdateGoogleIntegrationSettingsSuccess`;
export const UpdateGoogleFailure = `${prefix}:UpdateGoogleIntegrationSettingsFailure`;
export const UpdateVivaSuccess = `${prefix}:UpdateVivaIntegrationSettingsSuccess`;
export const UpdateVivaFailure = `${prefix}:UpdateVivaIntegrationSettingsFailure`;
export const UpdateSfSuccess = `${prefix}:UpdateSfIntegrationSuccess`;
export const UpdateSfFailure = `${prefix}:UpdateSfIntegrationFailure`;
export const UpdateIntegrationsIsActiveSuccess = `${prefix}:UpdateIntegrationsIsActiveSuccess`;
export const UpdateIntegrationsIsActiveFailure = `${prefix}:UpdateIntegrationsIsActiveFailure`;
export const AddPacksLicensesToAccountSuccess = `${prefix}:AddPacksLicensesToAccountSuccess`;
export const AddPacksLicensesToAccountFailure = `${prefix}:AddPacksLicensesToAccountFailure`;
export const UpdatePackAccountLicenseSuccess = `${prefix}:UpdatePackAccountLicenseSuccess`;
export const UpdatePackAccountLicenseFailure = `${prefix}:UpdatePackAccountLicenseFailure`;
export const DeleteAccountOperationSuccess = `${prefix}:DeleteAccountOperationSuccess`;
export const DeleteAccountOperationFailure = `${prefix}:DeleteAccountOperationFailure`;
export const WebexIntegrationCreateSuccess = `${prefix}:CreateWebexIntegrationSettingsSuccess`;
export const WebexIntegrationCreateFailure = `${prefix}:CreateWebexIntegrationSettingsFailure`;
export const UpdateWebexSuccess = `${prefix}:UpdateWebexIntegrationSettingsSuccess`;
export const UpdateWebexFailure = `${prefix}:UpdateWebexIntegrationSettingsFailure`;
export const VivaIntegrationCreateSuccess = `${prefix}:CreateVivaIntegrationSettingsSuccess`;
export const VivaIntegrationCreateFailure = `${prefix}:CreateVivaIntegrationSettingsFailure`;
export const SfIntegrationCreateSuccess = `${prefix}:CreateSfIntegrationSuccess`;
export const SfIntegrationCreateFailure = `${prefix}:CreateSfIntegrationFailure`;

export const CreateApiClientDistributedSuccess = `${prefix}:CreateApiClientDistributedSuccess`;
export const CreateApiClientDistributedFailure = `${prefix}:CreateApiClientDistributedFailure`;
export const EditApiClientDistributedSuccess = `${prefix}:EditApiClientDistributedSuccess`;
export const EditApiClientDistributedFailure = `${prefix}:EditApiClientDistributedFailure`;

export const PackArchiveSuccess = `${prefix}:ArchiveAccountPackMessageSuccess`;
export const PackArchiveFailure = `${prefix}:ArchiveAccountPackMessageFailure`;
export const UnarchiveAccountPackSuccess = `${prefix}:UnarchiveAccountPackMessageSuccess`;
export const UnarchiveAccountPackFailure = `${prefix}:UnarchiveAccountPackMessageFailure`;

export const AddAllPacksToOwnAccountSuccess = `${prefix}:AddAllPacksToOwnAccountSuccess`;
export const AddPacksToOwnAccountSuccess = `${prefix}:AddPacksToOwnAccountSuccess`;
export const AddAllPacksToOwnAccountFailure = `${prefix}:AddAllPacksToOwnAccountFailure`;
