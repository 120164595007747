import ModalTypes from "../components/modal/ModalTypes";
import isEmpty from "lodash/isEmpty";

const modalUtilsFactory = () => {
  interface Config {
    userId?: number;
    modalType?: ModalTypes;
    action?: () => void;
    shouldBeSuppressed?: boolean;
  }
  let config: Config = {};

  function setShouldBeSuppressed(): boolean {
    const fromStorage = config.userId ? localStorage.getItem(`${config.userId}`) : null;
    const storedConfig = fromStorage ? JSON.parse(fromStorage) : null;
    return !storedConfig || !config.modalType ? false : storedConfig[config.modalType];
  }

  const addOrUpdate = (value: boolean) => {
    if (!config.modalType) {
      throw new Error("no modalType was provided");
    }
    const fromStorage = config.userId ? localStorage.getItem(`${config.userId}`) : null;
    let storedConfig = fromStorage ? JSON.parse(fromStorage) : null;
    if (storedConfig) {
      storedConfig[config.modalType] = value;
    } else {
      storedConfig = {
        [config.modalType]: value,
      };
    }
    localStorage.setItem(`${config.userId}`, JSON.stringify(storedConfig));
  };

  const setModalType = (modalType: ModalTypes) => {
    if (!ModalTypes.hasOwnProperty(modalType)) {
      throw new Error("modalType should be defined in ModalTypes");
    }
    config.modalType = modalType;
  };

  return {
    renderForm(form: string, userId: number, modalType: ModalTypes) {
      if (isEmpty(config)) {
        config = { userId };
        setModalType(modalType);
        config.shouldBeSuppressed = setShouldBeSuppressed();
      }

      return config.shouldBeSuppressed ? null : form;
    },

    execute(
      payload: number,
      action: (p: number) => void,
      showModalTrigger: ((p: number, a: Config["action"]) => void) | null | undefined,
    ) {
      config.action = () => action(payload);
      if (showModalTrigger && !setShouldBeSuppressed()) {
        showModalTrigger(payload, config.action);
        return;
      }
      config.action();
    },

    executeWithoutModal() {
      config.action && config.action();
    },

    updateSuppressStatus(s: boolean) {
      addOrUpdate(s);
      config.shouldBeSuppressed = s;
    },
  };
};

export default modalUtilsFactory;

export type ModalUtils = ReturnType<typeof modalUtilsFactory>;
