export const saveThreatDefenceLandingPage = "SAVE_THREAT_DEFENCE_LANDING_PAGE";
export const saveThreatDefenceLandingPageContent = "SAVE_THREAT_DEFENCE_LANDING_PAGE_CONTENT";
export const clearThreatDefenceLandingPage = "CLEAR_THREAT_DEFENCE_LANDING_PAGE";

export const getThreatDefenceLandingPageBegin = "GET_THREAT_DEFENCE_LANDING_PAGE_BEGIN";
export const getThreatDefenceLandingPageSuccess = "GET_THREAT_DEFENCE_LANDING_PAGE_SUCCESS";
export const getThreatDefenceLandingPageFailure = "GET_THREAT_DEFENCE_LANDING_PAGE_FAILURE";

export const getThreatDefenceLandingPageContentBegin = "GET_THREAT_DEFENCE_LANDING_PAGE_CONTENT_BEGIN";
export const getThreatDefenceLandingPageContentSuccess = "GET_THREAT_DEFENCE_LANDING_PAGE_CONTENT_SUCCESS";
export const getThreatDefenceLandingPageContentFailure = "GET_THREAT_DEFENCE_LANDING_PAGE_CONTENT_FAILURE";

export const setLandingPage = "SET_LANDING_PAGE";
