import React from "react";
import { Icon } from "semantic-ui-react";
import { DeleteLinkButton } from "../../../../../../components/buttons/linkButtons";
import { Tooltip } from "../../../../../../components/common/tooltip";
import { Strings } from "../../../../../../enums";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { FeatureFlags } from "featureFlags";

export interface Props {
  purchasedSelected: boolean;
  undeletableSelected: boolean;
  onClick: () => void;
}

export const DeleteFlowButton: React.FC<Props> = (props) => {
  const { purchasedSelected, undeletableSelected, onClick } = props;
  const deleteContentEnabled = useFeatureFlag(FeatureFlags.DeleteContentWithDependenciesFeature);
  const isDisabled = purchasedSelected || (!deleteContentEnabled && undeletableSelected);
  const message = purchasedSelected
    ? Strings.tooltipMessages.purchased.manyDeleteDisabled("Flows")
    : Strings.tooltipMessages.deleteDisabled("Flows", ["Packs", "Users", "Groups"]);

  return (
    <Tooltip
      showAlways={isDisabled}
      inverted
      hideOnScroll
      position="left center"
      target={
        <DeleteLinkButton
          after={isDisabled && <Icon size="large" className="fa fa-exclamation-circle" />}
          isDisabled={isDisabled}
          onClick={onClick}
        />
      }
      content={message}
    />
  );
};
