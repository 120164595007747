import { Component } from "react";
import { ViewType } from "../../../enums";
import AddOnRow from "./AddOnRow/AddOnRow";
import { AddOn } from "../types";
import { ListViewBase } from "../../../components/listView";

class AddOnList extends Component<AddOnListProps> {
  render() {
    const { addOns, isLoading, renderEnabledCell, renderExpirationDateCell } = this.props;

    return (
      <ListViewBase
        viewType={ViewType.LIST}
        columnOptions={AddOnRow.columnOptions}
        buildTableBody={(addOn: AddOn) => (
          <AddOnRow
            addOn={addOn}
            renderEnabledCell={renderEnabledCell}
            renderExpirationDateCell={renderExpirationDateCell}
          />
        )}
        items={addOns}
        itemsAmount={addOns.length}
        isLoading={isLoading}
        withSelection={false}
        withFooter={false}
      />
    );
  }
}

export interface AddOnListProps {
  addOns: Array<AddOn>;
  isLoading: boolean;
  renderEnabledCell(addOn: AddOn): React.ReactElement;
  renderExpirationDateCell(addOn: AddOn): React.ReactElement;
}

export default AddOnList;
