import { connect } from "react-redux";
import GenericItemsView, { GenericItemsViewProps } from "../../../../../../../../views/ItemsView/GenericItemsView";
import { AppDispatch, RootState } from "../../../../../../../Application/globaltypes/redux";
import { selectedItemsChanged } from "../../../../../state/slices/eventUsersSlice";
import { EventUser } from "../../../../../types/state";

const mapStateToProps = (state: RootState) => {
  const users = state.library.events.users;
  return {
    items: users.items,
    isLoading: users.isLoading,
    dataCount: users.itemsCount,
    selectedIds: users.selectedIds,
    isFirstLoad: users.itemsCount === 0,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onSelectedItemChanged: (ids: number[]) => dispatch(selectedItemsChanged(ids)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericItemsView as (props: GenericItemsViewProps<EventUser>) => React.ReactElement);
