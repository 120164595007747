import React, { useCallback, useEffect, useState } from "react";
import { batch, connect, ConnectedProps } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Dispatch } from "redux";

import SortOptions from "../../../../../../../../enums/SortOptions";
import sortOptions from "../../../../../../../../views/library/flows/flowsOverview/sortOptions";
import FlowsNoResults from "../../../../../../../../views/library/flows/flowsOverview/FlowsNoResults";

import { FlowCard, SearchInput } from "../../../../../../../../components";
import { FlowFilterForm } from "../../../../../../../../components/filterForms";
import { CardsViewerItem } from "../../../../../../../../components/cardsViewer/types";
import { LazyCardsViewerWithHeaderAndPanel } from "../../../../../../../../components/cardsViewer";
import { PeopleAvailableFlow, WithPeopleContext } from "../../../../../../../People/ContentAssignments/models";
import { Filters } from "../../../../../../../../utils/queryUtils";
import { CreateFlowButton } from "../../../../../../../../views/library/flows/flowsOverview/FlowsOverviewHeader/CreateFlowButton/CreateFlowButton";
import { RootState } from "../../../../../../../Application/globaltypes/redux";
import { bindAction } from "../../../../../../../../interfaces";
import { fetchFlowsItems } from "../../state/thunk/nextFlowItemsThunk";
import { reset as resetItems } from "../../state/slice/nextFlowItemsSlice";
import {
  resetAppliedFilter,
  setAppliedFilter,
  setSearch,
  resetSearch,
  resetFilter,
} from "../../state/slice/nextFlowFiltersSlice";
import { getNextFlowFilterOptions } from "../../../../../state/thunks/flowFiltersThunk";
import { flowDesignerSelector } from "../../../../../state/selectors";
import { NextFlowInfo } from "../../../../types";
import { formatFilters } from "utils/filterMapUtils";
import { flowFilterTypes } from "features/Library/Flows/types/models";

export interface FlowListProps extends PropsFromRedux {
  selectedItem?: NextFlowInfo;
  setSelectedItem: (item?: NextFlowInfo) => void;
}

export const FlowList = (props: FlowListProps) => {
  const { nextFlowItems, fetchFilterOptions, fetchFlows, filters, onUnmount } = props;
  const [sortByParams, setSortByParams] = useState<string>(SortOptions.ModifiedDateDesc);

  const renderCard = (props: CardsViewerItem<WithPeopleContext<PeopleAvailableFlow>>) => (
    <FlowCard {...props} isRadioButton={true} item={{ ...props.item, published: true }} />
  );
  // @ts-ignore
  const renderFilterForm = () => <FlowFilterForm />;
  const renderSearch = () => (
    <SearchInput placeholder="Search..." onChange={applySearch} defaultValue={filters.search} />
  );

  const lazyLoad = () => {
    getData(nextFlowItems.items.length);
  };

  const getData = useCallback(
    (skip?: number) => {
      const [sortBy, sortOrder] = sortByParams?.split(" ") || [undefined, undefined];
      fetchFlows({
        top: 30,
        skip: skip ?? 0,
        sortBy: sortBy,
        sortOrder: sortOrder,
        filterQueryParams: formatFilters(filters.appliedFilter, flowFilterTypes),
        term: filters.search ?? "",
        showPurchased: true,
        published: true,
      });
    },
    [fetchFlows, sortByParams, filters.search, filters.appliedFilter],
  );

  const onSortOptionsChanged = (_: React.SyntheticEvent<HTMLElement>, data: { [key: string]: any }) => {
    props.setSelectedItem();
    props.resetItems();
    setSortByParams(data.value);
  };

  const applyFilter = (filters: Filters) => {
    props.setSelectedItem();
    props.resetItems();
    props.applyFilter(filters);
  };

  const resetFilter = () => {
    props.setSelectedItem();
    props.onReset();
  };

  const applySearch = (search: string) => {
    props.setSelectedItem();
    props.resetItems();
    props.setSearch(search);
    setSortByParams(search ? "" : SortOptions.ModifiedDateDesc);
  };

  const onSelectedItemsChanged = (_: Array<number>, id: number) => {
    props.setSelectedItem(nextFlowItems.items.find((item) => item.id === id));
  };

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  return (
    <LazyCardsViewerWithHeaderAndPanel
      blur
      hidePopupMenu
      getData={lazyLoad}
      orderBy={sortByParams}
      renderCard={renderCard}
      sortOptions={sortOptions}
      applyFilter={applyFilter}
      resetFilter={resetFilter}
      renderSearch={renderSearch}
      items={nextFlowItems.items}
      selectedItemIds={[props.selectedItem?.id]}
      renderFilterForm={renderFilterForm}
      isLoading={nextFlowItems.isLoading}
      filter={props.filters.appliedFilter}
      filterOptions={filters.filterOptions}
      itemsAmount={nextFlowItems.itemsCount}
      isAllLoaded={nextFlowItems.areAllLoaded}
      onSortOptionsChanged={onSortOptionsChanged}
      onSelectedItemsChanged={onSelectedItemsChanged}
      noResultsContent={
        <FlowsNoResults
          byCriteria={!isEmpty(props.filters.appliedFilter) || !!props.filters.search}
          createFlowButton={<CreateFlowButton />}
        />
      }
    />
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  nextFlowItems: flowDesignerSelector(state).nextFlow.nextFlowItems,
  filters: flowDesignerSelector(state).nextFlow.filters,
});

/* istanbul ignore next */
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchFlows: bindAction(fetchFlowsItems, dispatch),
    resetItems: bindAction(resetItems, dispatch),
    setSearch: bindAction(setSearch, dispatch),
    resetAppliedFilter: bindAction(resetAppliedFilter, dispatch),
    applyFilter: bindAction(setAppliedFilter, dispatch),
    fetchFilterOptions: bindAction(getNextFlowFilterOptions, dispatch),
    onReset: () => {
      batch(() => {
        dispatch(resetItems());
        dispatch(resetAppliedFilter());
      });
    },
    onUnmount: () => {
      batch(() => {
        dispatch(resetItems());
        dispatch(resetFilter());
        dispatch(resetSearch());
      });
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FlowList);
