import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import { bindActionCreators } from "@reduxjs/toolkit";
import { withRouter } from "../../../../../adapters/withRouter/withRouter";
import navigationUtils from "../../../../../utils/navigationUtils";
import WizardStepsManager from "../../../../../utils/WizardStepsManager";
import { AppDispatch, RootState } from "../../../../Application/globaltypes/redux";
import * as rtnEvents from "../../../../Application/services/realTimeNotification/events/library/libraryEvents";
import RtnEventsEmitter from "../../../../Application/services/realTimeNotification/rtnEventsEmitter";
import { WizardWrapper as Wizard } from "../../../../WizardWrapper";
import { PublishAndSendWizardButtons } from "../../../components/PublishAndSendWizardButtons/PublishAndSendWizardButtons";
import { EmailCreateSteps } from "../Edit/types";
import Configure from "../EmailDetails/Configure/Configure";
import Content from "../EmailDetails/EmailContent/Content";
import * as emailsOverviewActions from "../state/actions/emailsOverviewActions";
import {
  resetAppliedFilter,
  resetPagination,
  resetSortingColumnName,
  resetSortingDirection,
} from "../state/slices/emailFilterSlice";
import { setTerm } from "../state/slices/emailSearchSlice";
import { CreateEmailProps, CreateEmailState } from "./types";
import { LDProps } from "../../../../LDProps";
import AddAssetsToPackModal from "components/modal/AddAssetsToPacksModal/AddAssetsToPackModal";
import { FeatureFlags } from "featureFlags";
import { RouteNames } from "enums";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const getCreateSendSessionUrl = (emailId: number) => `/${RouteNames.contentCommunications}/emails/${emailId}/send-sessions/create`;

export type CreateEmailPropsAll = CreateEmailProps & PropsFromRedux & LDProps;

export class CreateEmail extends Component<CreateEmailPropsAll, CreateEmailState> {
  private readonly stepsManager = new WizardStepsManager();
  private isAssociatedPackEnabled: boolean;
  private redirectToCreateSendSessionPage = false;

  constructor(props: CreateEmailPropsAll) {
    super(props);
    this.isAssociatedPackEnabled = !!this.props.flags?.[FeatureFlags.AssociatedPacks];
    this.state = {
      showAddPacksModal: false,
      isValid: false,
      activeStepIndex: EmailCreateSteps.Configuration,
    };
    this.stepsManager.subscribeOnActiveIndexChanged((_: any, activeStepIndex: number) => {
      this.setState({ activeStepIndex });
    });
  }

  componentDidMount() {
    RtnEventsEmitter.subscribe([rtnEvents.EmailPublishSuccess], this.onPublishedEvent);
  }

  componentWillUnmount() {
    this.stepsManager.dispose();
    RtnEventsEmitter.unsubscribe([rtnEvents.EmailPublishSuccess], this.onPublishedEvent);

    if (!window.location.pathname.includes(`/${RouteNames.contentCommunications}`)) this.props.resetSearchFilters();
  }

  navigateToEmailOrSessionCreation = () => this.props.navigate(this.redirectToCreateSendSessionPage ? getCreateSendSessionUrl(this.props.id) : `/${RouteNames.contentCommunications}`);

  onPublishedEvent = () => this.isAssociatedPackEnabled ? this.setState({ showAddPacksModal: true }) : this.navigateToEmailOrSessionCreation();

  onCancel = () => {
    navigationUtils.goBackOrDefault(this.props.location, this.props.navigate, `/${RouteNames.contentCommunications}`);
  };

  onIsValidChange = (isValid: boolean) => {
    this.setState({ isValid });
  };

  getPublishAndSendHandler = (onFinishAsync: () => Promise<void>) => () => {
    this.redirectToCreateSendSessionPage = true;
    return onFinishAsync();
  };

  renderPublishAndSendButtons = (onFinishAsync: () => Promise<void>) => {
    return (
      <PublishAndSendWizardButtons
        onFinishAsync={onFinishAsync}
        getPublishAndSendHandler={this.getPublishAndSendHandler}
        isValid={this.state.isValid}
      />
    );
  };

  render() {
    const { isValid, showAddPacksModal } = this.state;
    const { isSaving, dateModified } = this.props;

    return (
      <>
        <Wizard
          title="Create Email"
          renderCustomFinishButton={this.renderPublishAndSendButtons}
          onCancel={this.onCancel}
          onProgressAsync={(_: any, nextIndex: number) => this.stepsManager.onNext(nextIndex)}
          onRegressAsync={(_: any, nextIndex: number) => this.stepsManager.onPrevious(nextIndex)}
          onFinishAsync={this.stepsManager.onFinish}
          isSaveInProgress={isSaving}
          progressSavedDate={dateModified}
          className="create-email"
        >
          <Wizard.Step label="Configure" className="scrollable-content" isLocked={!isValid} required>
            <Configure
              acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, EmailCreateSteps.Configuration)}
              onIsValidChange={this.onIsValidChange}
              disabled={false}
            />
          </Wizard.Step>
          <Wizard.Step label="Content" className="scrollable-content edit-email-content" isLocked={!isValid} preRender>
            <Content
              acceptHandlers={(handlers) => this.stepsManager.acceptHandlers(handlers, EmailCreateSteps.Content)}
              onIsValidChange={this.onIsValidChange}
              disabled={false}
              emailCreating={true}
            />
          </Wizard.Step>
        </Wizard>
        <AddAssetsToPackModal
          showModal={showAddPacksModal}
          onClose={/* istanbul ignore next */ () => this.navigateToEmailOrSessionCreation()}
          onComplete={/* istanbul ignore next */ () => this.navigateToEmailOrSessionCreation()}
          selectedItemIds={[this.props.id]}
          contentType={"Email"}
        />
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = (state: RootState) => ({
  id: state.library.emails.emailEntityStateReducer.entityId,
  isSaving: state.library.emails.emailEntityStateReducer.isEntityCommandInProgress,
  dateModified: state.library.emails.emailEntityStateReducer.lastModifiedDateTime,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  emailsOverviewActions: bindActionCreators(emailsOverviewActions, dispatch),
  resetSearchFilters: () => {
    emailsOverviewActions.resetEmails();
    dispatch(setTerm(""));
    dispatch(resetPagination());
    dispatch(resetSortingColumnName());
    dispatch(resetSortingDirection());
    dispatch(resetAppliedFilter());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withRouter(withLDConsumer()(CreateEmail)));
