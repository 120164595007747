import "./statusCell.scss";
import { Icon } from "semantic-ui-react";

const IconMappings = {
  INACTIVE: "fa-pause-circle",
  ACTIVE: "fa-check-circle",
  FAILED: "fa-times-circle",
  PENDING: "fa-clock",
};

export type Status = keyof typeof IconMappings;
const StatusCell: React.FC<{ status: string }> = ({ status }) => {
  const icon = IconMappings[status.toUpperCase() as Status];
  return (
    <div className="integration-status">
      <Icon className={icon} />
      <span className="label">{status}</span>
    </div>
  );
};

export default StatusCell;
