import cn from "classnames";
import React from "react";

export interface Props {
  steps: React.ReactElement<IStep>[];
  activeStepIndex: number;
}

export interface IStep {
  preRender?: boolean;
  className?: string;
}

function WizardStep(props: Props) {
  const { steps, activeStepIndex } = props;

  const preRenderedSteps = steps.filter((c) => c.props?.preRender);
  const activeStep = steps[activeStepIndex];

  return (
    <>
      {!activeStep.props.preRender && (
        <div className={cn("step-content", activeStep.props.className)}>{activeStep}</div>
      )}
      {preRenderedSteps.map((step, index) => (
        <div key={index} className={cn("step-content", step.props.className, { invisible: step !== activeStep })}>
          {step}
        </div>
      ))}
    </>
  );
}

export default WizardStep;
