import { bindActionCreators, Dispatch } from "@reduxjs/toolkit";
import { FC, useEffect } from "react";
import { RootState } from "../../../Application/globaltypes/redux";
import {
  selectCustomerCardsState,
  selectCustomerPacksState,
  selectFormattedCardsData,
  selectFormattedCustomerPacksData,
} from "../../state/packs/packSlice";
import * as reportingActions from "../../state/packs/packActions";
import { connect, ConnectedProps } from "react-redux";
import { RequestStatusRenderer } from "../../../../components/requestStatsRenderer/RequestStatusRenderer";
import { CustomerPackReportList } from "./CustomerPackReportList";
import { OverviewHeader } from "../../../../components/sectionHeader";
import CardReporting from "../../../../components/cards/CardReporting/CardReporting";

import styles from "./customerPackReport.module.scss";

export interface Props extends PropsFromRedux {}

export const CustomerPackReport: FC<Props> = ({
  customerId,
  customerPackState,
  customerPackData,
  customerCardsState,
  customerCardsFormattedData,
  actions,
}) => {
  useEffect(() => {
    actions.fetchCustomerPacks(customerId);
    actions.fetchCustomerCards(customerId);
  }, [actions, customerId]);

  return (
    <div className={styles.root}>
      <OverviewHeader title="Summary" />
      <RequestStatusRenderer state={customerCardsState}>
        <CardReporting items={customerCardsFormattedData} />
      </RequestStatusRenderer>
      <RequestStatusRenderer state={customerPackState}>
        <CustomerPackReportList reduxFinished customerPackData={customerPackData} />
      </RequestStatusRenderer>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  customerId: state.userProfile.accountId,
  customerPackState: selectCustomerPacksState(state),
  customerPackData: selectFormattedCustomerPacksData(state),
  customerCardsState: selectCustomerCardsState(state),
  customerCardsFormattedData: selectFormattedCardsData(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(reportingActions, dispatch),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CustomerPackReport);
