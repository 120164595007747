import { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { AddOn, SavingAddOn } from "../../types";
import { Moment } from "moment";
import dateTimeUtils from "../../../../utils/dateTimeUtils";
import FormFooter from "../../../../components/forms/FormFooter";
import PreventTransitionPrompt from "../../../../components/preventTransition/PreventTransitionPrompt";
import AddOnsConfiguration from "../AddOnsConfiguration/AddOnsConfiguration";
export interface EditAddOnsProps {
  fetchAddOns(): void;
  updateAddOns(addOns: Array<SavingAddOn>): void;
  addOns: Array<AddOn>;
  isLoading: boolean;
  isReadOnly: boolean;
}

export class EditingAddOn {
  readonly origin: AddOn;
  isEnabled: boolean;
  expirationDate: string | null;
  canEdit: boolean;
  default: boolean;

  constructor(origin: AddOn) {
    this.origin = origin;
    this.isEnabled = origin.isEnabled;
    this.expirationDate = origin.expirationDate;
    this.canEdit = origin.canEdit;
    this.default = origin.default;
  }

  get id(): number {
    return this.origin.id;
  }

  get name(): string {
    return this.origin.name;
  }

  get description(): string {
    return this.origin.description;
  }

  get isChanged(): boolean {
    return this.isEnabled !== this.origin.isEnabled || this.expirationDate !== this.origin.expirationDate;
  }

  toggle(): void {
    this.isEnabled = !this.isEnabled;
    this.expirationDate = null;
  }

  setExpirationDate(newExpirationDate: Moment): void {
    this.expirationDate = dateTimeUtils.formatDate(newExpirationDate);
  }

  reset(): void {
    this.isEnabled = this.origin.isEnabled;
    this.expirationDate = this.origin.expirationDate;
  }
}

export default function EditAddOns({ fetchAddOns, updateAddOns, addOns, isLoading, isReadOnly }: EditAddOnsProps) {
  const [editingAddOns, setEditingAddons] = useState<Array<EditingAddOn>>([]);

  useEffect(() => {
    setEditingAddons(addOns.map((addOn) => new EditingAddOn(addOn)));
  }, [addOns]);

  const onCancel = () => {
    const newAddOns = cloneDeep(editingAddOns);
    for (let addOn of newAddOns) {
      addOn.reset();
    }
    setEditingAddons(newAddOns);
  };

  const onSave = () => {
    const enabledAddOns = editingAddOns
      .filter((addOn) => addOn.isEnabled && !addOn.default)
      .map((addOn: AddOn) => {
        return { id: addOn.id, expirationDate: addOn.expirationDate };
      });
    updateAddOns(enabledAddOns);
  };

  const onChangeAddons = (newAddOns: EditingAddOn[]) => setEditingAddons(newAddOns);

  const isDirty = editingAddOns.some((addOn) => addOn.isChanged);

  return (
    <>
      <AddOnsConfiguration
        addOns={editingAddOns}
        onMount={fetchAddOns}
        onChangeAddons={onChangeAddons}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
      />
      <FormFooter isSaveBtnDisabled={!isDirty} isCancelBtnDisabled={!isDirty} onCancel={onCancel} onSave={onSave} />
      <PreventTransitionPrompt
        when={isDirty}
        title="Exit Without Saving?"
        message="You have unsaved changes. Are you sure you want to exit? All changes will be lost."
      />
    </>
  );
}
