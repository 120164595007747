import { Permission, PermissionView } from "../types";
import { DropdownWrapper } from "../../../../../components/dropdowns";
import ChecklistDropdown from "../../../../../components/dropdowns/checklistDropdown/v2/ChecklistDropdownV2";
import { Grid } from "semantic-ui-react";
import { useMemo, useRef } from "react";
import AreaIcon from "../../../../../components/areaIcon/AreaIcon";
import { cloneDeep, set } from "lodash";
import ObjectUtils from "../../../../../utils/objectUtils";

export interface PermissionRowProps {
  availablePermissions: Permission[];
  row: PermissionView;
  getDefaultPermission: () => PermissionView;
  initialPermissions?: PermissionView;
  index: number;
  isReadOnly: boolean;
  configuredPermissions: PermissionView[];
  onPermissionsChange: (permissions: PermissionView[]) => void;
  limitationProvider: (id: number) => {
    isLoading: () => boolean;
    items: () => any;
    onFetch: () => void;
  };
}

export const limitationTypes = {
  none: 1,
  specificAccounts: 2,
  group: 4,
  childAccounts: 5,
  ownAccount: 6,
};

const getAvailableAreasWithIcon = (values: { id: number; name: string }[]) => {
  return values.map((x) => ({
    key: x.id,
    value: x.id,
    text: x.name,
    icon: <AreaIcon areaName={x.name} />,
  }));
};

const getOptions = (values: { id: string | number; name: string }[]) => {
  return values.map((x) => ({
    key: x.id,
    value: x.id,
    text: x.name,
  }));
};

const getAvailableFeatures = (row: PermissionView, availablePermissions: Permission[]) => {
  if (!row.area) {
    return [];
  }

  const availableFeatures = availablePermissions
    .find((curr) => curr.areaId === row.area)!
    .features.map((fp) => ({ id: fp.featureId, name: fp.featureName }));
  return getOptions(availableFeatures);
};

const isContextAvailable = (row: PermissionView) => {
  const typesWithLimitationItems = [limitationTypes.specificAccounts, limitationTypes.group];
  return row.limitation !== null && typesWithLimitationItems.includes(row.limitation!);
};

const emptyArr = [] as any[];

const fieldsOrder: Array<keyof PermissionView> = ["area", "feature", "right", "limitation", "limitationItems"];
const handleValueChange = (
  property: keyof PermissionView,
  value: any,
  current: PermissionView,
  defaultObj: PermissionView,
) => {
  const copy = cloneDeep(current);

  fieldsOrder.slice(fieldsOrder.indexOf(property) + 1).forEach((field) => {
    set(copy, field, defaultObj[field]);
  });

  copy[property] = value;
  return copy;
};

export const PermissionRow = (props: PermissionRowProps) => {
  const {
    row,
    isReadOnly,
    availablePermissions,
    limitationProvider,
    configuredPermissions,
    index,
    initialPermissions,
    getDefaultPermission,
  } = props;
  const { area, limitation, feature, right } = row;
  const limitationNames = useRef(new Map());

  const getLimitationName = (row: PermissionView) => {
    const key = `${row.area}:${row.feature}:${row.right}:${row.limitation}`;

    if (limitationNames.current.has(key)) {
      return limitationNames.current.get(key);
    }

    const name = availablePermissions
      .find((fp) => fp.areaId === row.area)!
      .features.find((fp) => fp.featureId === row.feature)!
      .rights.find((r) => r.id === row.right)!
      .limitations.find((lt) => lt.id === row.limitation)!.name;
    limitationNames.current.set(key, name);
    return name;
  };

  const availableAreas = useMemo(() => {
    const availableAreas = availablePermissions
      .filter(
        (a) =>
          a.areaId === area ||
          getAvailableFeatures({ area: a.areaId } as PermissionView, availablePermissions).length > 0,
      )
      .map((ap) => ({ id: ap.areaId, name: ap.areaName }));

    return getAvailableAreasWithIcon(availableAreas);
  }, [area, availablePermissions]);

  const availableFeatures = useMemo(() => {
    if (!area) {
      return [];
    }

    const availableFeatures = availablePermissions
      .find((curr) => curr.areaId === area)!
      .features.map((fp) => ({ id: fp.featureId, name: fp.featureName }));
    return getOptions(availableFeatures);
  }, [area, availablePermissions]);

  const availableRights = useMemo(() => {
    if (!feature) {
      return [];
    }

    const availableRights = availablePermissions
      .find((fp) => fp.areaId === area)!
      .features.find((fp) => fp.featureId === feature)!
      .rights.map((fp) => ({ id: fp.id, name: fp.name }));

    return getOptions(availableRights);
  }, [feature, area, availablePermissions]);

  const availableLimitations = useMemo(() => {
    if (!right) {
      return [];
    }

    const availableLimitations = availablePermissions
      .find((fp) => fp.areaId === area)!
      .features.find((fp) => fp.featureId === feature)!
      .rights.find((r) => r.id === right)!
      .limitations.map((l) => ({ id: l.id, name: l.name }));

    return getOptions(availableLimitations);
  }, [area, availablePermissions, feature, right]);

  const onChange = (name: keyof PermissionView, value: any) => {
    const changed = name === "limitation" ? onSelectLimitationField(value) : onSelectField(name, value);
    props.onPermissionsChange(changed);
  };

  const onSelectField = (name: keyof PermissionView, value: any) => {
    const copy = cloneDeep(configuredPermissions);
    return set(copy, index, handleValueChange(name, value, copy[index], getDefaultPermission()));
  };

  const onSelectLimitationField = (value: any) => {
    const nextConfiguredPermissions = [...configuredPermissions];

    const updatedItem = {
      ...nextConfiguredPermissions[index],
      limitation: value,
      limitationItems: undefined,
    };

    if (value === limitationTypes.none) {
      nextConfiguredPermissions
        .filter(
          (p) =>
            p.area === updatedItem.area &&
            p.feature === updatedItem.feature &&
            p.right === updatedItem.right &&
            !p.limitation,
        )
        .forEach((p) => {
          let i = nextConfiguredPermissions.indexOf(p);
          nextConfiguredPermissions[i] = {
            ...nextConfiguredPermissions[i],
            right: undefined,
          };
        });
    }

    if (isContextAvailable(updatedItem)) {
      updatedItem.limitationItems = [] as any;
      limitationProvider(value).onFetch();
    }

    nextConfiguredPermissions[index] = updatedItem;

    return nextConfiguredPermissions;
  };

  const isLimitationsAvailable = isContextAvailable(row);

  const initialLimitations = useMemo(() => {
    if (!isLimitationsAvailable) {
      return emptyArr;
    }

    if (ObjectUtils.isEqualBy(initialPermissions, row, "area", "feature", "right", "limitation")) {
      return initialPermissions?.limitationItems || emptyArr;
    }

    return emptyArr;
  }, [isLimitationsAvailable, initialPermissions, row]);

  return (
    <>
      <Grid.Column>
        <DropdownWrapper
          blur
          placeholder="Select Area"
          fluid
          selection
          selected={area}
          handleOptionChange={(_, data) => onChange("area", data.value)}
          items={availableAreas}
          disabled={isReadOnly}
        />
      </Grid.Column>
      <Grid.Column>
        <DropdownWrapper
          blur
          placeholder="Select Feature"
          fluid
          selection
          selected={feature}
          handleOptionChange={(_, data) => onChange("feature", data.value)}
          items={availableFeatures}
          disabled={isReadOnly || !row.area}
        />
      </Grid.Column>
      <Grid.Column>
        <DropdownWrapper
          blur
          placeholder="Select Rights"
          fluid
          selection
          selected={right}
          handleOptionChange={(_, data) => onChange("right", data.value)}
          items={availableRights}
          disabled={isReadOnly || !row.feature}
        />
      </Grid.Column>
      <Grid.Column>
        <DropdownWrapper
          blur
          placeholder="Select Type"
          fluid
          selection
          selected={limitation}
          handleOptionChange={(_, data) => onChange("limitation", data.value)}
          items={availableLimitations}
          disabled={isReadOnly || !right}
        />
      </Grid.Column>
      <Grid.Column>
        {isContextAvailable(row) && (
          <ChecklistDropdown
            placeholder={`Select ${getLimitationName(row)}`}
            items={limitationProvider(limitation!).items()}
            isLoading={limitationProvider(limitation!).isLoading()}
            onCheckedItemsChanged={(items: any) => onChange("limitationItems", items)}
            initialCheckedItems={initialLimitations}
            isReadOnly={isReadOnly}
            sortPredicate={(item) => item.name.toLowerCase()}
            searchPredicate={(item, term) => item.name.toLowerCase().includes(term.toLowerCase())}
            getLabel={(item) => item.name}
          />
        )}
      </Grid.Column>
    </>
  );
};
