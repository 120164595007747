import { Dispatch } from "@reduxjs/toolkit";
import { AlertTypes } from "enums";
import { BasePerformanceRequestFilterParams } from "features/Library/Common/models";
import { downloadExcelExport } from "features/Library/Common/utils/performanceUtils";
import { sendTransientNotification } from "features/Notifications/state/notificationsActions";
import { setExporting } from "features/Reporting/state/export/exportSlice";
import DataService from "../../services/eventsDataService";

export const doExport = (eventId: number, filterParams: BasePerformanceRequestFilterParams, eventTitle: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await DataService.getExport(eventId, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, eventTitle);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};

export const doDrilldownExport = (eventId: number, sessionId: string, sessionStart: string, filterParams: BasePerformanceRequestFilterParams, eventTitle: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setExporting(true));

    try {
      const response = await DataService.getDrilldownExport(eventId, sessionId, sessionStart, filterParams);
      dispatch(
        sendTransientNotification(
          { title: "Export Complete!", message: "File download has begun.", type: AlertTypes.success },
          5,
        ),
      );

      downloadExcelExport(response.data, eventTitle);
    } catch (err) {
      // Request was aborted manually, no need to change anything
      if (err.code === "ERR_CANCELED") {
        return;
      }
      dispatch(
        sendTransientNotification(
          {
            title: "Export failed",
            message: "Please try again later. If this persists, please contact BrainStorm support.",
            type: AlertTypes.error,
          },
          5,
        ),
      );
    } finally {
      dispatch(setExporting(false));
    }
  };
};
