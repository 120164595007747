import { Tag } from "../../../../../interfaces/Tag";

export enum EmailTemplateFiltersEnum {
  SoftwareApplications = "softwareApplicationIds",
  Labels = "labelIds",
  DateCreated = "dateCreated",
  DateModified = "dateModified",
}

export interface EmailTemplatesFilters {
  [key: string]: Tag[];
}
