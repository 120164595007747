import { debounce } from "lodash";
import { useEffect, useMemo, useRef } from "react";

function useOnResize(onResize: () => void, component: React.MutableRefObject<HTMLElement | null>) {
  const onResizeRef = useRef(onResize);

  useEffect(() => {
    onResizeRef.current = onResize;
  }, [onResize]);

  const observer = useMemo(() => {
    return new ResizeObserver(
      debounce((entries: ResizeObserverEntry[]) => {
        const entry = entries[0];
        if (entry.contentRect.width === 0 || entry.contentRect.height === 0) {
          return;
        }
        onResizeRef.current?.();
      }, 150),
    );
  }, []);

  useEffect(() => {
    if (!component.current) return;

    const htmlElement = component.current;
    observer.observe(htmlElement);
    return () => observer.unobserve(htmlElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer]);
}

export default useOnResize;
