import { createSelector } from "reselect";
import { RolePermissions } from "../../../../enums";
import { RootState } from "../../../Application/globaltypes/redux";

const getPermissions = (state: RootState) => state.userProfile.permissions;

export const hasPacksManagePermissionSelector = createSelector([getPermissions], (permissions) =>
  permissions.includes(RolePermissions.PacksManage),
);

export const hasFlowsCreatePermissionSelector = createSelector([getPermissions], (permissions) =>
  permissions.includes(RolePermissions.FlowsCreate),
);

export const activeViewTabSelector = (state: RootState) => state.packs.packContentTab.context.activeTab;

export const packContentTypeSelectSelector = (state: RootState) => {
  const contextSelector = state.packs.packContentTab.context;
  return contextSelector.entityType[contextSelector.activeTab];
};

export const itemsToDropSelector = (state: RootState) => state.packs.packContentTab.itemsToDrop;

export const itemsToDropFiltersSelector = (state: RootState) => state.packs.packContentTab.filters;
